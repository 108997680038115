import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Swal from 'sweetalert2';
import copy from 'copy-text-to-clipboard';

class Json extends Component {
	state = {
		loaibotrunglap: true,
		noidung: '[\n  {\n    "domain": ".facebook.com",\n    "hostOnly": false,\n    "httpOnly": false,\n    "name": "act",\n    "path": "/",    "sameSite": "no_restriction",\n    "secure": false,\n    "session": true,\n    "storeId": "0",\n    "value": "323235353533",\n    "id": 1\n }\n]',
		text_placeholder: '[\n  {\n    "domain": ".facebook.com",\n    "hostOnly": false,\n    "httpOnly": false,\n    "name": "act",\n    "path": "/",    "sameSite": "no_restriction",\n    "secure": false,\n    "session": true,\n    "storeId": "0",\n    "value": "323235353533",\n    "id": 1\n }\n]',
		ketqua: "",
	}
	onChangeTrungLap = (e) =>{
		this.setState({
			[e.target.name]: !this.state.loaibotrunglap
		})
	}
	onClick = (e) =>{
		this.setState({
			[e.target.name]: e.target.value
		})
	}
	onChange = (e) =>{
		this.setState({
			[e.target.name]: e.target.value
		})
	}
	onTrigger = (e) => {
		var { loaibotrunglap, noidung } = this.state;
		noidung = noidung.trim();
		noidung = noidung
			.split("\n")
			.filter(item => item)
			.join("\n");
		
		var final_noidung_tmp = this.matchJson(noidung);
		var final_noidung = [];
		final_noidung_tmp.forEach(function(v, i) {
			try {
				var check = JSON.parse(v);
				final_noidung.push(v);
			} catch(e) {
				
			}
		});
		if (loaibotrunglap) {
			final_noidung = final_noidung
				.filter((item, i, allItems) => {
					return i === allItems.indexOf(item);
				});
		}
		noidung = final_noidung.join("\n");
		
		this.setState({
			ketqua: noidung
		});
	}
	
	matchJson = (input_str) => {
		var json_arrs = [];
			
		var arrs1 = input_str ? input_str.toString().split('\n') : [];
		var str = arrs1.join(" ");
		str = str.replace(/("[^"]*")|\s/g, "$1");
		while(true){
			var new_str = str;
			var use_pos = -1;
			var pos1 = str.toString().indexOf('][');
			var pos2 = str.toString().indexOf('{}');
			var pos3 = str.toString().indexOf(']{');
			var pos4 = str.toString().indexOf('}[');
			
			use_pos = pos1;
			if(use_pos == -1 || (use_pos > pos2 && pos2 !== -1)){
				use_pos = pos2;
			}
			if(use_pos == -1 || (use_pos > pos3 && pos3 !== -1)){
				use_pos = pos3;
			}
			if(use_pos == -1 || (use_pos > pos4 && pos4 !== -1)){
				use_pos = pos4;
			}
			if(use_pos !== -1){
				new_str = str.substring(0, use_pos + 1);
				str = str.substring(use_pos + 1);
				json_arrs.push(new_str);
			}else{
				if(new_str){
					json_arrs.push(new_str);
				}
				break;
			}
		}
		return json_arrs;
	}
	
	render() {
		return (
			<Fragment>
				<div className="form-group row">
					<label className="col-sm-2 col-form-label edittext-label-right" htmlFor="">Loại bỏ trùng lặp?</label>
					<div className="col-sm-10" style={{"display": "flex", "alignItems": "center"}}>
						<div className="custom-control custom-switch mb-1">
							<input onChange={(e) => {this.onChangeTrungLap(e)}} type="checkbox" className="custom-control-input" id="loaibotrunglap" name="loaibotrunglap" checked={this.state.loaibotrunglap} />
							<label className="custom-control-label edittext-custom-control-label" htmlFor="loaibotrunglap" ></label>
						</div>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-2 col-form-label edittext-label-right" htmlFor="">Nội dung</label>
					<div className="col-sm-10">
						<textarea rows="5" placeholder={this.state.text_placeholder} className="form-control input-gray edittext-textarea" name="noidung" onChange={this.onChange} value={this.state.noidung} ></textarea>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-2 col-form-label edittext-label-right" htmlFor=""></label>
					<div className="col-sm-10">
						<button type="button" className="btn btn-rounded btn-hero-primary edittext-btn" onClick={this.onTrigger} >Trigger</button>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-2 col-form-label edittext-label-right" htmlFor=""></label>
					<div className="col-sm-10">
						<textarea rows="5" placeholder="" className="form-control input-gray edittext-textarea" name="ketqua" defaultValue={this.state.ketqua} ></textarea>
					</div>
				</div>
			</Fragment>
		);
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
});
const mapDispatchToProps = {  };

export default connect(mapStateToProps, mapDispatchToProps)(Json);
