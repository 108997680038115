import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Swal from 'sweetalert2';
import copy from 'copy-text-to-clipboard';

class Cookie extends Component {
	state = {
		loaibotrunglap: true,
		doicookietu: 1,
		sort: 1,
		noidung: "c_user=123;xs=xxx;sb=xxx;datr=xxx\nc_user=289;xs=yyy;sb=yyy;datr=yyy",
		xuat: 1,
		ketqua: "",
		text_placeholder: "c_user=123;xs=xxx;sb=xxx;datr=xxx\nc_user=289;xs=yyy;sb=yyy;datr=yyy",
	}
	onChangeTrungLap = (e) =>{
		this.setState({
			[e.target.name]: !this.state.loaibotrunglap
		})
	}
	onClick = (e) =>{
		var noidung = this.state.noidung;
		var text_placeholder = this.state.text_placeholder;
		if (e.target.name == "doicookietu" && e.target.value == 1) {
			noidung = "c_user=123;xs=xxx;sb=xxx;datr=xxx\nc_user=289;xs=yyy;sb=yyy;datr=yyy";
			text_placeholder = "c_user=123;xs=xxx;sb=xxx;datr=xxx\nc_user=289;xs=yyy;sb=yyy;datr=yyy";
		}
		if (e.target.name == "doicookietu" && e.target.value == 2) {
			noidung = "c_user=123;xs=xxx;sb=xxx;datr=xxx\nc_user=289;xs=yyy;sb=yyy;datr=yyy\nc_user=385;xs=yyy;sb=yyy;datr=yyy";
			text_placeholder = "c_user=123;xs=xxx;sb=xxx;datr=xxx\nc_user=289;xs=yyy;sb=yyy;datr=yyy\nc_user=385;xs=yyy;sb=yyy;datr=yyy";
		}
		if (e.target.name == "doicookietu" && e.target.value == 3) {
			noidung = "123\n789\n567";
			text_placeholder = "123\n789\n567";
		}
		if (e.target.name == "doicookietu" && e.target.value == 4) {
			noidung = "c_user=123;xs=xxx;sb=xxx;datr=xxx\nc_user=289;xs=yyy;sb=yyy;datr=yyy\nc_user=385;xs=yyy;sb=yyy;datr=yyy";
			text_placeholder = "c_user=123;xs=xxx;sb=xxx;datr=xxx\nc_user=289;xs=yyy;sb=yyy;datr=yyy\nc_user=385;xs=yyy;sb=yyy;datr=yyy";
		}
		if (e.target.name == "doicookietu" && e.target.value == 5) {
			noidung = "uid|pass|cookie1|2fa\nuid|pass|cookie2|2fa";
			text_placeholder = "uid|pass|cookie1|2fa\nuid|pass|cookie2|2fa";
		}
		if (e.target.name == "doicookietu" && e.target.value == 6) {
			noidung = "";
			text_placeholder = "";
		}
		this.setState({
			noidung: noidung,
			text_placeholder: text_placeholder,
			sort: 1,
			[e.target.name]: e.target.value
		})
	}
	onChange = (e) =>{
		this.setState({
			[e.target.name]: e.target.value
		})
	}
	onTrigger = (e) => {
		var { loaibotrunglap, doicookietu, sort, noidung } = this.state;
		noidung = noidung.trim();
		noidung = noidung
			.split("\n")
			.filter(item => item)
			.join("\n");
		if (loaibotrunglap) {
			noidung = noidung
				.split("\n")
				.filter((item, i, allItems) => {
					return i === allItems.indexOf(item);
				})
				.join("\n");
		}
		if (doicookietu == 1) {
			var final_noidung = [];
			var matches_uid = noidung.match(/c_user=(.*?);/g);
			if (matches_uid !== null) {
				if (matches_uid.length > 0) {
					final_noidung = matches_uid.map(r => r.replace('c_user=', '').replace(';', ''));
				}
			}
			if (this.state.sort == 1) {
				final_noidung.sort(function(a, b){return a - b});
			}
			if (this.state.sort == 2) {
				final_noidung.sort(function(a, b){return b - a});
			}
			noidung = final_noidung.join("\n");
		}
		if (doicookietu == 2) {
			var final_noidung_tmp = [];
			var final_noidung = [];
			var matches_uid = noidung.match(/c_user=(.*?);/g);
			if (matches_uid !== null) {
				if (matches_uid.length > 0) {
					final_noidung_tmp = matches_uid.map(r => r.replace('c_user=', '').replace(';', ''));
				}
			}
			if (this.state.sort == 1) {
				final_noidung_tmp.sort(function(a, b){return a - b});
			}
			if (this.state.sort == 2) {
				final_noidung_tmp.sort(function(a, b){return b - a});
			}
			
			final_noidung_tmp.forEach(function(v, i) {
				noidung.split("\n").forEach(function(v2, i2) {
					if (v2.indexOf(v) > -1) {
						final_noidung[i] = v2;
					}
				});
			});
			noidung = final_noidung.join("\n");
		}
		if (doicookietu == 3) {
			var final_noidung = noidung.split("\n");
			if (this.state.sort == 1) {
				final_noidung.sort(function(a, b){return a - b});
			}
			if (this.state.sort == 2) {
				final_noidung.sort(function(a, b){return b - a});
			}
			noidung = final_noidung.join("\n");
		}
		if (doicookietu == 4) {
			noidung = "Chức năng chưa hoạt động!";
		}
		if (doicookietu == 5) {
			var final_noidung_tmp = noidung.split("\n");
			var final_noidung = [];
			final_noidung_tmp.forEach(function(v, i) {
				var v_tmp = v.split("|");
				v_tmp.splice(2, 1);
				final_noidung[i] = v_tmp.join("|");
			});
			noidung = final_noidung.join("\n");
		}
		if (doicookietu == 6) {
			noidung = "Chức năng chưa hoạt động!";
		}
		this.setState({
			ketqua: noidung
		});
	}
	
	render() {
		return (
			<Fragment>
				<div className="form-group row">
					<label className="col-sm-2 col-form-label edittext-label-right" htmlFor="">Loại bỏ trùng lặp?</label>
					<div className="col-sm-10" style={{"display": "flex", "alignItems": "center"}}>
						<div className="custom-control custom-switch mb-1">
							<input onChange={(e) => {this.onChangeTrungLap(e)}} type="checkbox" className="custom-control-input" id="loaibotrunglap" name="loaibotrunglap" checked={this.state.loaibotrunglap} />
							<label className="custom-control-label edittext-custom-control-label" htmlFor="loaibotrunglap" ></label>
						</div>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-2 col-form-label edittext-label-right" htmlFor="">Đổi cookie từ:</label>
					<div className="col-sm-10">
						<div className="btn-group" role="group" aria-label="">
							<button type="button" className={"btn btn-outline-primary " + (this.state.doicookietu == 1 ? "active" : "")} name="doicookietu" value="1" onClick={(e) => {this.onClick(e)}}>Lấy uid từ cookie</button>
							<button type="button" className={"btn btn-outline-primary " + (this.state.doicookietu == 2 ? "active" : "")} name="doicookietu" value="2" onClick={(e) => {this.onClick(e)}}>Sắp xếp cookie theo uid</button>
							<button type="button" className={"btn btn-outline-primary " + (this.state.doicookietu == 3 ? "active" : "")} name="doicookietu" value="3" onClick={(e) => {this.onClick(e)}}>Sắp xếp uid</button>
							{/* <button type="button" className={"btn btn-outline-primary " + (this.state.doicookietu == 4 ? "active" : "")} name="doicookietu" value="4" onClick={(e) => {this.onClick(e)}}>Lấy cookie theo uid</button> */}
							<button type="button" className={"btn btn-outline-primary " + (this.state.doicookietu == 5 ? "active" : "")} name="doicookietu" value="5" onClick={(e) => {this.onClick(e)}}>Bỏ cookie trong acc</button>
							{/* <button type="button" className={"btn btn-outline-primary " + (this.state.doicookietu == 6 ? "active" : "")} name="doicookietu" value="6" onClick={(e) => {this.onClick(e)}}>Tách Token</button> */}
						</div>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-2 col-form-label edittext-label-right" htmlFor=""></label>
					<div className="col-sm-10">
						<div className="btn-group" role="group" aria-label="">
							{
								this.state.doicookietu == 1 || this.state.doicookietu == 2 || this.state.doicookietu == 3 ?
									<button type="button" className={"btn btn-outline-primary " + (this.state.sort == 1 ? "active" : "")} name="sort" value="1" onClick={(e) => {this.onClick(e)}}>A -> Z</button>
								: ""
							}
							{
								this.state.doicookietu == 1 || this.state.doicookietu == 2 || this.state.doicookietu == 3 ?
									<button type="button" className={"btn btn-outline-primary " + (this.state.sort == 2 ? "active" : "")} name="sort" value="2" onClick={(e) => {this.onClick(e)}}>Z - > A</button>
								: ""
							}
							{
								this.state.doicookietu == 1 ?
									<button type="button" className={"btn btn-outline-primary " + (this.state.sort == 3 ? "active" : "")} name="sort" value="3" onClick={(e) => {this.onClick(e)}}>Không sắp xếp</button>
								: ""
							}
						</div>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-2 col-form-label edittext-label-right" htmlFor="">Nội dung</label>
					<div className="col-sm-10">
						<textarea rows="5" placeholder={this.state.text_placeholder} className="form-control input-gray edittext-textarea" name="noidung" onChange={this.onChange} value={this.state.noidung} ></textarea>
					</div>
				</div>
				{
					this.state.doicookietu == 6 ?
						<div className="form-group row">
							<label className="col-sm-2 col-form-label edittext-label-right" htmlFor="">Xuất</label>
							<div className="col-sm-10">
								<div className="btn-group" role="group" aria-label="">
									<button type="button" className={"btn btn-outline-primary " + (this.state.xuat == 1 ? "active" : "")} name="xuat" value="1" onClick={(e) => {this.onClick(e)}}>UID</button>
									<button type="button" className={"btn btn-outline-primary " + (this.state.xuat == 2 ? "active" : "")} name="xuat" value="2" onClick={(e) => {this.onClick(e)}}>Token</button>
									<button type="button" className={"btn btn-outline-primary " + (this.state.xuat == 3 ? "active" : "")} name="xuat" value="3" onClick={(e) => {this.onClick(e)}}>UID|Token</button>
								</div>
							</div>
						</div>
					: ""
				}
				<div className="form-group row">
					<label className="col-sm-2 col-form-label edittext-label-right" htmlFor=""></label>
					<div className="col-sm-10">
						<button type="button" className="btn btn-rounded btn-hero-primary edittext-btn" onClick={this.onTrigger} >Trigger</button>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-2 col-form-label edittext-label-right" htmlFor=""></label>
					<div className="col-sm-10">
						<textarea rows="5" placeholder="" className="form-control input-gray edittext-textarea" name="ketqua" defaultValue={this.state.ketqua} ></textarea>
					</div>
				</div>
			</Fragment>
		);
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
});
const mapDispatchToProps = {  };

export default connect(mapStateToProps, mapDispatchToProps)(Cookie);
