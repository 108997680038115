import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import {
    Modal,
    ModalBody,
    ModalFooter
} from 'reactstrap';
import { saveAddTypeUser,saveEditItemStatusUser } from '../../../../actions/adminPageAction';
import BootstrapTable from 'react-bootstrap-table-next';


class addTypeUser extends Component {

    state = {
        formdata: {
            name: '',
            notes: '',
        },
        dataEdit: [],
    }

    changeValueForm = (e) => {
        this.setState({
            formdata: {
                ...this.state.formdata,
                [e.target.name]: e.target.value
            }
        });
    }

    changeDataEdit = (e, data) => {
        let el = e.target;
        let arrItemEdit = this.state.dataEdit;
        let namekey = 'notes';
        if (el.name === 'editname') {
            namekey = 'name';
        }
        arrItemEdit[data.id] = {
			...arrItemEdit[data.id],
			[namekey]: e.target.value,
			"id": data.id,
			"percent": 0
		}
        this.setState({
            ...this.state,
            dataEdit: arrItemEdit,
        });
    }

    saveEditItemTb = (id_edit) => {
        this.props.saveEditItemStatusUser(this.state.dataEdit[id_edit]);
    }

    render() {
        const {isOpenModal,adminpage} = this.props;
        const {note,name} = this.state.formdata;

        const columns = [{
          dataField: '',
          text: 'STT',
          sort: true,
          formatter: (cell, row, rowIndex) =>
            <span>{rowIndex+1}</span>
        },{
          dataField: 'id',
          text: 'ID',
          sort: true
        },{
          dataField: 'name',
          text: 'Tên',
          sort: true
        },{
          dataField: 'notes',
          text: 'Ghi chú',
          sort: true,
        },{
          dataField: 'edit',
          text: 'Thao tác',
          formatter: (cell, row, rowIndex) =>
            <button className="btn btn-hero-primary text-center p-2 mr-1"><i className="fas fa-fw fa-pen"></i></button>
        }];

        const expandRow = {
            renderer: (row) => (
                <Fragment>
                    <div className="row">
                        <div className="col-lg-4">
                            <span>Tên</span>
                            <input name="editname" onChange={(e) => {this.changeDataEdit(e,row)}} className="form-control" placeholder={row.name} type="text" />
                        </div>
                        <div className="col-lg-4">
                            <span>Ghi chú</span>
                            <input name="editnote" onChange={(e) => {this.changeDataEdit(e,row)}} className="form-control" placeholder={row.notes} type="text" />
                        </div>
                        <div className="col-lg-4">
                            <button onClick={(e)=>{this.saveEditItemTb(row.id)}} className="btn btn-hero-primary text-center p-2 mt-4">Lưu</button>
                        </div>
                    </div>
                </Fragment>
            ),
            
            expandColumnPosition: 'right',
            expandHeaderColumnRenderer: ({ isAnyExpands }) => {
                if (isAnyExpands) {
                    return <i className="fas fa-caret-up"></i>;
                }
                return <b><i className="fas fa-caret-down"></i></b>;
            },
            expandColumnRenderer: ({ expanded }) => {
                if (expanded) {
                    return (
                        <b><i className="fas fa-caret-up"></i></b>
                    );
                }
                return (
                    <b><i className="fas fa-caret-down"></i></b>
                );
            }
        };

        return (
            <Fragment>
                <Modal centered={true} size="lg" toggle={(e) => {this.props.openModal()}} isOpen={isOpenModal}>
                    <div className="modal-header">
                        <p className="mb-0 text-dark">Thêm loại tài khoản</p>
                        <button onClick={(e) => {this.props.openModal()}} type="button" className="close">×</button>
                    </div>
                    <ModalBody>
                        <div className="row mb-3">
                            <div className="col-md-4">
                                <input onChange={(e) => {this.changeValueForm(e)}} name="name" value={name} className="form-control" placeholder="Tên loại" type="text" />
                            </div>
                            <div className="col-md-4">
                                <input onChange={(e) => {this.changeValueForm(e)}} name="note" value={note} className="form-control" placeholder="ghi chú" type="text" />
                            </div>
                            <div className="col-md-2">
                                <button onClick={(e) => {this.props.saveAddTypeUser(this.state.formdata)}} type="button" className="btn btn-success mr-1 mb-3">
                                    Lưu
                                </button>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <BootstrapTable
                                keyField='id'
                                bodyStyle={ { border: 'none' } }
                                headerStyle={ { border: 'red 1px solid' } }
                                data={ this.props.adminpage.listdatausertype }
                                striped
                                hover
                                expandRow={ expandRow }
                                wrapperClasses="table-responsive"
                                columns={ columns } />
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button type="button" className="btn btn-danger" onClick={(e) => {this.props.openModal()}}>Huỷ</button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        );
    }

}

const mapStateToProps = state => ({
    adminpage: state.adminpage
});

export default connect(mapStateToProps, { saveAddTypeUser,saveEditItemStatusUser }) (addTypeUser);
