import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import store from '../../../store';

import ModalAddAccountClone from './modal/addaccountclone';
import ModalEditAccountClone from './modal/editaccountclone';

import { getListBankHistoryThe } from '../../../actions/adminPageAction';
import BootstrapTable from 'react-bootstrap-table-next';
import Moment from 'react-moment';
import paginationFactory from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import NumberFormat from 'react-number-format';
import Modaledit from './modal/editcardhistory';
const { SearchBar } = Search;

class TagBankPaymentHistoy extends Component {
	state = {
        countItemNow: 0,
        isOpenModalAddAcc: false,
        isOpenModalEditAcc: false,
		isOpenModalEdit: false,
        limit: 50,
        search: ''
    }

    componentDidMount(){
        this.props.getListBankHistoryThe(50,'');
    }

    getNameSpecies = (cell) => {
    	let name = 'Không có gói';
    	this.props.adminpage.list_speciesclone.forEach(function(v, i){
    		if (cell === v.id) {
    			name = v.name;
    		}
    	});
    	return name;
    }
    formatMoney(amount, decimalCount = 0, decimal = ".", thousands = ",") {
        try {
          decimalCount = Math.abs(decimalCount);
          decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

          const negativeSign = amount < 0 ? "-" : "";

          let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
          let j = (i.length > 3) ? i.length % 3 : 0;

          return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
        } catch (e) {
          console.log(e)
        }
    }
    getListByLimit = (e) => {
      let valueChange = e.target.value;
      if (valueChange !== '') {
        this.setState({
          [e.target.name]: e.target.value
        });
        this.props.getListBankHistoryThe(valueChange, this.state.search);
      }
    }
    searchData(e){
      this.setState({
        [e.target.name]: e.target.value
      });
      this.props.getListBankHistoryThe(this.state.limit, e.target.value);
    }
    openModalEdit = (data = null) => {
		this.setState({
			...this.state,
			isOpenModalEdit: !this.state.isOpenModalEdit,
		});
		if (data !== null) {
			this.setState({
				...this.state,
				isOpenModalEdit: !this.state.isOpenModalEdit,
				id: data.id,
				status: data.status,
			});
		}
    }
    render() {
    	function formatNhaMang(cell, row) {
			if (cell === 'VMS') {
				return ('Mobifone');
			} else if (cell === 'VTT') {
				return ('Viettel');
			} else if (cell === 'VNP') {
				return ('Vina');
			} else {
				return ('');
			}
		}
		function formatPrice(cell, row) {
			return (
				<Fragment>
					<NumberFormat value={ cell } displayType={'text'} thousandSeparator={true} suffix={' vnđ'} />
				</Fragment>
			);
		}
		function filterValuePrice(cell, row) {
			return cell;
		}
		function formatKhuyenMai(cell, row) {
			return (cell + '%');
		}
		function formatTrangThai(cell, row) {
			var message = '';
			if (cell == 0) {
				return (
					<span className="badge badge-primary"><i className="fas fa-check mr-1"></i>Đã nạp</span>
				);
			} else if (cell == 1) {
				return (
					<span className="badge badge-warning"><i className="fas fa-sync-alt mr-1"></i>Chờ duyệt</span>
				);
			} else if (cell == 2) {
				return (
					<span className="badge badge-danger"><i className="fas fa-ban mr-1"></i>Hủy</span>
				);
			} else {
				return ('');
			}
		}
		function filterValueTrangThai(cell, row) {
			if (cell == 0) {
				return ('Đã nạp');
			} else if (cell == 1) {
				return ('Chờ duyệt');
			} else if (cell == 2) {
				return ('Hủy');
			} else {
				return ('');
			}
		}
		function formatCheckTime(cell, row) {
			return (
				<Fragment>
					<Moment format="DD-MM-YYYY HH:mm:ss">{parseInt(cell)}</Moment>
				</Fragment>
			);
		}
		function filterValueCheckTime(cell, row) {
			var d = new Date(parseInt(cell));
			var month = '' + (d.getMonth() + 1);
			var day = '' + d.getDate();
			var year = d.getFullYear();
			var hour = '' + (d.getHours());
			var min = '' + d.getMinutes();
			var sec = '' + d.getSeconds();

			if (month.length < 2)
				month = '0' + month;
			if (day.length < 2)
				day = '0' + day;
			if (hour.length < 2)
				hour = '0' + hour;
			if (min.length < 2)
				min = '0' + min;
			if (sec.length < 2)
				sec = '0' + sec;
			
			return [day, month, year].join('-') + ' ' + [hour, min, sec].join(':');
		}
		const columns = [
			{
			  dataField: 'id',
			  text: 'STT',
		   	  sort: true,
			},
			{
			  dataField: 'loaithe',
			  text: 'Nhà mạng',
			  sort: true,
			  formatter: formatNhaMang,
			  filterValue: formatNhaMang
			},
			{
			  dataField: 'seri',
			  text: 'Seri',
			  sort: true
			},
			{
			  dataField: 'mathe',
			  text: 'Mã thẻ',
			  sort: true
			},
			{
			  dataField: 'menhgia',
			  text: 'Mệnh giá',
			  sort: true,
			  formatter: formatPrice,
			  filterValue: filterValuePrice
			},
			{
			  dataField: 'khuyen_mai',
			  text: 'Khuyến mãi',
			  sort: true,
			  formatter: formatKhuyenMai,
			  filterValue: formatKhuyenMai
			},{
			  dataField: 'username',
			  text: 'Tên tài khoản',
			  sort: true,
			  classes: 'notranslate',
			},
			{
			  dataField: 'thoigian_nap',
			  text: 'Thời gian nạp',
			  sort: true,
			  formatter: formatCheckTime,
			  filterValue: filterValueCheckTime
			},
			{
				dataField: 'status',
				text: 'Trạng thái',
				sort: true,
				formatter: formatTrangThai,
				filterValue: filterValueTrangThai
			}, {
				dataField: '',
				text: '',
				formatter: (cell, row) => 
					<Fragment>
						<Link to="/admin" className="btn btn-sm btn-primary text-white text-white mr-1" onClick={() => {this.openModalEdit(row)}}>
							<i className="fa fa-fw fa-pen"></i>
						</Link>
					</Fragment>
			}
		];

        const {listbankpaymenthistorythe} = this.props.adminpage;
    	return (
    		<Fragment>
			
				<ToolkitProvider keyField="id" data={ listbankpaymenthistorythe } columns={ columns } search >
					{
						props => (
							<div>
								<SearchBar { ...props.searchProps } />
								<BootstrapTable { ...props.baseProps } pagination={ paginationFactory() } wrapperClasses="table-responsive" striped hover condensed />
								{this.state.isOpenModalEdit ? <Modaledit openModal={this.openModalEdit} isOpenModal={this.state.isOpenModalEdit} dataid={this.state.id} datastatus={this.state.status} /> : ''}
							</div>
						)
					}
				</ToolkitProvider>
			</Fragment>
			
		);
    }

}

const mapStateToProps = state => ({
	adminpage: state.adminpage,
});

export default connect(mapStateToProps, { getListBankHistoryThe }) (TagBankPaymentHistoy);
