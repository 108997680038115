import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import {
    Modal,
    ModalBody,
    ModalFooter
} from 'reactstrap';
import { UpdateEdit } from '../../../actions/buffviewstoryActions';

class Modal_edit extends Component {
    state = {
		count_success: this.props.datacount_success,
		status: this.props.datastatus
    }

    changeValueNdr = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    render() {
        const {isOpenModal, dataid, dataidfb} = this.props;
        const {count_success, status} = this.state;
		const { changetab, loading } = this.props.buffgrouptele;

        return (
            <Fragment>
                <Modal centered={true} size="lg" toggle={(e) => {this.props.openModal()}} isOpen={isOpenModal} className={(loading? ' block block-mode-loading' : '')} style={{"backgroundColor": "inherit", "boxShadow": "none"}}>
                    <div className="modal-header">
                        <p className="mb-0 text-dark">Sửa</p>
                        <button onClick={(e) => {this.props.openModal()}} type="button" className="close">×</button>
                    </div>
                    <ModalBody style={{opacity: loading? '.05':'1'}}>
                        <div className="row">
							<div className="col-md-12">
								<div className="form-group">
									<label>Trạng thái</label>
									<select name="status" value={status} onChange={this.changeValueNdr} className="form-control">
										<option value="0">Hoàn thành</option>
										<option value="1">Đang chạy</option>
										<option value="2">Id lỗi</option>
										<option value="3">Hủy đơn</option>
										<option value="5">Hoàn tiền</option>
									</select>
								</div>
								
								<div className="form-group">
									<label>Số lượng đã chạy</label>
									<input type="number" name="count_success" onChange={this.changeValueNdr} value={count_success} className="form-control" />
								</div>
							</div>
						</div>
                    </ModalBody>
                    <ModalFooter style={{opacity: loading? '.05':'1'}}>
                        <button onClick={() => {this.props.UpdateEdit(status, count_success, dataid, this.props.openModal)}} type="button" className="btn btn-primary">Lưu</button>
                        <button onClick={(e) => {this.props.openModal()}} type="button" className="btn btn-danger">Huỷ</button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    buffgrouptele: state.buffgrouptele
});

export default connect(mapStateToProps, {UpdateEdit}) (Modal_edit);
