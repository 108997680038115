import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import NumberFormat from 'react-number-format';
import store from '../../../store';
import {
    Modal,
    ModalBody,
    ModalFooter
} from 'reactstrap';
import {ProlongItemProxy} from '../../../actions/buyproxyAction';
import Moment from 'react-moment';

class ViewDetailProxy extends Component {
    state = {
        selected: [this.props.buyproxy.datadetailselect.id_order],
        period: '3',
        id_proxy: this.props.buyproxy.datadetailselect.id
    }

	componentDidMount(){
		var period = '3';
		if (this.props.buyproxy.datadetailselect.server == 3) {
			period = '30';
		}
		this.setState(() => ({
			period: period
		}));
	}
	
    SelectOneItem = (row, isSelect) => {
        if (isSelect) {
            this.setState(() => ({
                selected: [...this.state.selected, row.id_order]
            }));
        } else {
            this.setState(() => ({
                selected: this.state.selected.filter(x => x !== row.id_order)
            }));
        }
    }

    SelectAllItem = (isSelect, rows) => {
        const ids = rows.map(r => r.id_order);
        if (isSelect) {
            this.setState(() => ({
                selected: ids
            }));
        } else {
            this.setState(() => ({
                selected: []
            }));
        }
    }

    changeForm = (e) => {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value
        });
    }
	
	calPriceBuyProxy = (count, period) => {
		try {
			var px_price = {"1":1.2,"10":0.9,"50":0.8,"100":0.7,"200":0.6,"300":0.5,"500":0.4};
			var px_discount = {"1":0,"7":20,"14":25,"30":30};
			var i = 0,
				p = 0,
				d = 0,
				f = 0;
			Object.keys(px_price).forEach(function(key, val) {
				if ( count >= parseInt(key) ) i = px_price[key];
			});
			Object.keys(px_discount).forEach(function(key, val) {
				if ( period >= parseInt(key) ) d = px_discount[key];
			});

			p = parseFloat(i * count * period);
			d = parseFloat(p * d / 100);
			f = parseFloat(p - d);
			
			/*if ( discount > 0 ) {
				f -= parseFloat(f * discount / 100);
			}*/
			f = parseFloat(f / 56);
			return Math.ceil(f * 100) / 100;
		} catch(err) {
			// res.send({message: "Lỗi", status: 400, error: []});
			console.log(err);
		}
	}

    render() {
        const {datadetailselect, loadingblock} = this.props.buyproxy;
		var price = this.state.period*800;
		if (datadetailselect.server == 3) {
			price = this.state.period*800*3;
		}

		function formatCheckTime(cell, row) {
			var result = new Date(cell*1000);
			var result_now = new Date();
			
			var difference = (result - result_now)/1000;
			if (difference >= 0) {
				// if (difference/86400 < 1) {
				  // return Math.floor(difference/3600) + ' giờ';
				// }
				const days = Math.floor(difference / 86400);
				const hours = Math.floor(difference / 3600) - (days * 24);
				const minutes = Math.floor(difference / 60) - (days * 24 * 60) - (hours * 60);
				const seconds = Math.floor(difference) - (days * 24 * 60 * 60) - (hours * 60 * 60) - (minutes * 60);
				var datediff = "";
				if (days > 0) {
					datediff += days + ' ngày ';
				}
				if (hours > 0) {
					datediff += hours + ' giờ ';
				}
				if (minutes > 0) {
					datediff += minutes + ' phút';
				}
				return datediff;
				{/* return (
					<Fragment>
						<Moment diff={result_now} unit="days">
								{result}
							</Moment>
						{' '} ngày
					</Fragment>
				); */}
			} else {
				return <span className="badge badge-danger">Hết hạn</span>
			}
		}
		function filterValueCheckTime(cell, row) {
			var result = new Date(cell*1000);
			var result_now = new Date();
			
			var diffTime = (result - result_now)/1000;
			if (diffTime >= 0) {
				if (diffTime/86400 < 1) {
		          	return Math.floor(diffTime/3600) + ' giờ';
		        }
				var diffDays = Math.floor(diffTime / (60 * 60 * 24)) + ' ngày';
				return diffDays;
			} else {
				return ('Hết hạn');
			}
		}
        const columns = [{
          dataField: '',
          text: 'STT',
          sort: true,
          formatter: (cell, row, rowIndex) =>
            <span>{rowIndex+1}</span>
        },{
          dataField: 'id_order',
          text: 'ID order',
          sort: true,
        },{
          dataField: 'version',
          text: 'Version',
          sort: true,
        },{
          dataField: 'ip',
          text: 'Ip',
          sort: true,
        },{
          dataField: 'host',
          text: 'Host',
          sort: true,
        },{
          dataField: 'port',
          text: 'Port',
          sort: true,
        },{
          dataField: 'user',
          text: 'User',
          sort: true,
        },{
          dataField: 'pass',
          text: 'Pass',
          sort: true,
        },{
          dataField: 'date_end',
          text: 'Hết hạn',
          sort: true,
		  formatter: formatCheckTime,
		  filterValue: formatCheckTime
        }];

        const selectRow = {
            mode: 'checkbox',
            selected: this.state.selected,
            clickToSelect: true,
            onSelect: this.SelectOneItem,
            onSelectAll: this.SelectAllItem
        };

        return (
            <Fragment>

                <Modal centered={true} size="lg" toggle={(e) => {this.props.openModal()}} isOpen={this.props.isOpenModal} className={(loadingblock? ' block block-mode-loading' : '')} style={{"backgroundColor": "inherit", "boxShadow": "none"}}>
                    <div className="modal-header">
                        <p className="mb-0 text-dark">Gia hạn thêm proxy</p>
                        <button onClick={(e) => {this.props.openModal()}} type="button" className="close">×</button>
                    </div>
                    <ModalBody style={{opacity: loadingblock? '.05':'1'}}>
						<div className="row form-group">
							<div className="col-md-3">
								<label className="mt-2">Gia hạn thêm:</label>
							</div>
							<div className="col-lg-9">
                                <select name="period" onChange={(e) => {this.changeForm(e)}} className="form-control">
									{
										datadetailselect.server != 3 ?
											<Fragment>
												<option value="3">3 days </option>
												<option value="7">1 week </option>
											</Fragment>
										: ''
									}
									{
										datadetailselect.server != 3 && datadetailselect.server != 4 ?
											<Fragment>
												<option value="14">2 weeks </option>
											</Fragment>
										: ''
									}
									<option value="30">1 month </option>
									<option value="60">2 month </option>
									{
										datadetailselect.server != 4 ?
											<Fragment>
												<option value="90">3 month </option>
											</Fragment>
										: ''
									}
                                </select>
                            </div>
                        </div>

						<div className="row mt-4">
							<div className="col-12 text-center bold">
								<div className="card card-info">
									<div className="card-body">
										<h5 className="font-bold">Thành tiền</h5>
										<h6 className="mb-0">Số tiền phải thanh toán với giá <span className="green bold">{price}</span> Coin</h6>
									</div>
								</div>
							</div>
						</div>
						
                        {/* <BootstrapTable
							keyField='id_order'
							bodyStyle={ { border: 'none' } }
							headerStyle={ { border: 'red 1px solid' } }
							data={ [datadetailselect] }
							striped
							hover
							wrapperClasses="table-responsive"
							columns={ columns }
							selectRow={ selectRow }
							pagination={ paginationFactory() } /> */}
                    </ModalBody>
                    <ModalFooter style={{opacity: loadingblock? '.05':'1'}}>
						<button onClick={() => {this.props.ProlongItemProxy(this.state.selected, this.state.period, this.state.id_proxy, this.props.openModal)}} type="button" className="btn btn-sm btn-success">
							<i className="fa fa-fw fa-redo mr-1"></i> Gia hạn
						</button>
                        <button type="button" className="btn btn-danger" onClick={(e) => {this.props.openModal()}} >Huỷ</button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    buyproxy: state.buyproxy,
});

export default connect(mapStateToProps, {ProlongItemProxy}) (ViewDetailProxy);
