import axios from 'axios';
import Swal from 'sweetalert2';

export const getDataListItem = (viewadmin, limit = 0) => (dispatch, getState) => {
	let param_url = '';
	if (viewadmin === 1) {
		param_url = '?viewall=1';
	}
	if (limit !== 0) {
		if (param_url !== '') {
			param_url += '&limit='+limit;
		} else {
			param_url += '?limit='+limit;
		}
	}

	axios.get('/api/rechagecardphone/list/'+param_url, tokenConfig(getState))
	.then(res => {
		if (res.data.status === 200) {
			dispatch({
				type: 'LIST_DATA_ITEM_RECHARGE_CARDPHONE',
				payload: res.data.data
			});
		}
	});
}

export const DeleteItem = (id_remove) => (dispatch, getState) => {
	Swal.fire({
	  	title: 'Bạn có chắc?',
	  	text: "Bạn có chắc với điều này không ?",
	  	icon: 'warning',
	  	showCancelButton: true,
	  	confirmButtonColor: '#3085d6',
	  	cancelButtonColor: '#d33',
	  	confirmButtonText: 'Đồng ý',
	  	cancelButtonText: 'Không'
	}).then((result) => {
		if (result.value) {
			axios.get('/api/rechagecardphone/delete_item?id_remove='+id_remove, tokenConfig(getState))
			.then(res => {
				if (res.data.status === 200) {
					dispatch({
						type: 'DELETE_ITEM_RECHARGE_CARDPHONE',
						payload: id_remove
					});
					Swal.fire('Success', 'Xóa thành công', 'success');
				} else {
					Swal.fire('Lỗi', res.data.message, 'error');
				}
			})
		}
	});
}

export const creatNewItem = (data) => (dispatch, getState) => {
	let bodysend = data;

	Swal.fire({
	  	title: 'Bạn có chắc?',
	  	text: "Bạn có chắc với thanh toán này không ?",
	  	icon: 'warning',
	  	showCancelButton: true,
	  	confirmButtonColor: '#3085d6',
	  	cancelButtonColor: '#d33',
	  	confirmButtonText: 'Đồng ý',
	  	cancelButtonText: 'Không'
	}).then((result) => {
		if (result.value) {
			axios.post('/api/rechagecardphone/add/', bodysend, tokenConfig(getState))
			.then(res => {
				if (res.data.status === 200) {
					Swal.fire('', 'Thêm thành công', 'success');
				} else {
					Swal.fire('Lỗi', res.data.message, 'error');
				}
			});
		}
	});
}

export const searchDataBuffSub = (data) => (dispatch, getState) => {
	axios.get('/api/rechagecardphone/list/?object_search='+data, tokenConfig(getState))
	.then(res => {
		if (res.data.status === 200) {
			dispatch({
				type: 'LIST_DATA_ITEM_RECHARGE_CARDPHONE',
				payload: res.data.data
			});
		}
	});
}

export const tokenConfig = getState => {
	// Get token from localhost
	const token = getState().auth.token;
	
	// Headers
	const config = {
		headers: {
			"Content-type": "application/json"
		}
	};
	
	// If token, add to headers
	if (token) {
		config.headers['ht-token'] = token;
	}
	
	return config;
};