import {
	LOAD_FABVDX,
	UPDATE_FABVDX,
	UPDATE_FABVDX_TAB,
	LOAD_PRICE_FABVDX,
	LIMIT_PAGI_FABVDX,
	TYPE_FABVDX,
	LOADING_FABVDX,
	LOADING_FABVDX_2,
	LOAD_NHATKYHOATDONG_FABVDX
} from "../actions/types";

const initialState = {
	"taotientrinh": {
		"lvct": "",
		"lsct": "viewdexuat",
		"quocgia": "de",
		"goi": "30",
		"slct": "5000",
		"gc": "",
		"gtmtt": "0",
	},
	"changetab": 0,
	"listtientrinh": [],
	"tanggiathem": {
		"id": "",
		"lhibv": "",
		"price_per": 0,
		"price": 0
	},
	"price": {
		Facebookadbreaksviewdexuat: {
			min_bh_web: 0,
			prices_web: 0,
		},
	},
	"limit_pagi": 0,
	"type": 0,
	"loading": false,
	"loading2": false,
	"nhatkyhoatdong": [],
	facebookadbreaksviewdexuat_sv1_mo_dong: 1,
};

export default function(state = initialState, action) {
	switch (action.type) {
		case LOAD_FABVDX:
			return {
				...state,
				"listtientrinh": action.payload
			};
		case UPDATE_FABVDX:
			return {
				...state,
				"taotientrinh": action.payload
			};
		case UPDATE_FABVDX_TAB:
			return {
				...state,
				"changetab": action.payload
			};
		case LOAD_PRICE_FABVDX:
			return {
				...state,
				"price": merDataPricePage(action.payload),
			};
		case LIMIT_PAGI_FABVDX:
			return {
				...state,
				"limit_pagi": action.payload
			};
		case TYPE_FABVDX:
			return {
				...state,
				"type": action.payload
			};
		case LOADING_FABVDX:
			return {
				...state,
				"loading": action.payload
			};
		case LOADING_FABVDX_2:
			return {
				...state,
				"loading2": action.payload
			};
		case LOAD_NHATKYHOATDONG_FABVDX:
			return {
				...state,
				"nhatkyhoatdong": action.payload
			};
		case 'CONFIG_MO_DONG_FABVDX':
			return {
				...state,
				facebookadbreaksviewdexuat_sv1_mo_dong: action.payload.facebookadbreaksviewdexuat_sv1_mo_dong,
			};
		default:
		  return state;
	}
}

function merDataPricePage(data) {
	let dataNew = {
		...initialState.price
	}
	if (data.length > 0) {
		data.forEach(function(v, i){
			if (v.name_table === 'facebookadbreaksviewdexuat') {
				initialState.taotientrinh.gtmtt = v.prices_web;
				dataNew['Facebookadbreaksviewdexuat'] = v;
			}
		});
	};
	return dataNew;
}
