import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { addIVL, updateIVL, getConfigMoDong } from '../../../actions/instagramviplikeActions';
import Swal from 'sweetalert2';
import parse from 'html-react-parser';
import ServicesDescription from "../ServicesDescription";
import { isMobile } from 'react-device-detect';

class taotientrinh extends Component {
	static propTypes =  {
		addIVL: PropTypes.func.isRequired,
		updateIVL: PropTypes.func.isRequired,
	};
	
	state = {
		showDescription: isMobile? false : true
	};
	
	componentDidMount() {
		this.props.getConfigMoDong();
	};
	
	onChange = (e) => {
		var { taotientrinh } = this.props.instagramviplike;
		if ((e.target.name === "lhibv") && (e.target.value.indexOf("instagram.com/") > -1)) {
			if (e.target.value !== "") {
				const { taotientrinh } = this.props.instagramviplike;
				var id_link = e.target.value.replace('https://www.instagram.com/', '').replace('https://instagram.com/', '').replace('http://www.instagram.com/', '').replace('http://instagram.com/', '').replace('instagram.com/', '').replace(/\//g, '');
				var new_taotientrinh = {
					...taotientrinh,
					[e.target.name]: id_link.split('?')[0]
				};
				this.props.updateIVL(new_taotientrinh);
			}
		} else if (e.target.name === 'lsct') {
			if (e.target.value === "1") {
				var value = this.props.instagramviplike.price.instagrameviplike_viet.prices_web;
				taotientrinh = {
					...taotientrinh,
					"gtmtt": value,
				};
			}
			if (e.target.value === "2") {
				var value = this.props.instagramviplike.price.instagrameviplike_tay.prices_web;
				taotientrinh = {
					...taotientrinh,
					"gtmtt": value,
				};
			}
			var new_taotientrinh = {
				...taotientrinh,
				[e.target.name]: e.target.value,
			};
		} else {
			var new_taotientrinh = {
				...taotientrinh,
				[e.target.name]: e.target.value
			};
		}
		this.props.updateIVL(new_taotientrinh);
	}
	
	onKeyPress = (e) => {
		if (!(e.charCode >= 48 && e.charCode <= 57)) {
			e.preventDefault();
		}
	}
	
	taoTienTrinhfunc = () => {
		const { taotientrinh } = this.props.instagramviplike;
		
		if (taotientrinh.lhibv.trim() === "") {
			Swal.fire({
				icon: 'error',
				title: 'Lỗi',
				text: 'Link hoặc ID Bài viết không được để trống!',
			});
			return false;
		}
		if (taotientrinh.gtmtt === "") {
			Swal.fire({
				icon: 'error',
				title: 'Lỗi',
				text: 'Chọn giá tiền mỗi tương tác',
			});
			return false;
		}
		
		var new_taotientrinh = {
			...taotientrinh,
			"type_api": "instagramviplike"
		};
		
		Swal.fire({
			title: 'Bạn có chắc?',
			html: "Bạn có chắc chắn muốn sẽ mua VIP "+taotientrinh.slllnct+" Like với giá "+taotientrinh.gtmtt+" Coin / Like? Khi đã bấm đồng ý đồng nghĩa với việc bạn đã đọc hết mục thông tin dịch vụ!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Đồng ý',
			cancelButtonText: 'Không'
		}).then((result) => {
			if (result.value) {
				this.props.addIVL(new_taotientrinh);
			}
		})
	}
	
	onClickShowDescription = (data) => {
		this.setState({
			showDescription: data
		});
	}
	
	render() {
		const { lhibv, sllnnct, slllnct, slbvtmn, sncmv, gtmtt, lsct } = this.props.instagramviplike.taotientrinh;
		const { instagramviplike_viet_mo_dong, instagramviplike_tay_mo_dong } = this.props.instagramviplike;
		
		var listDataServicesInfo = {};
		try {
			if (localStorage.getItem("listDataServicesInfo") != null) {
				listDataServicesInfo = JSON.parse(localStorage.getItem("listDataServicesInfo"));
			} else {
				listDataServicesInfo = this.props.adminpage.listDataServicesInfo;
			}
		} catch(e) {
			listDataServicesInfo = {};
		}
		var html_haydoctranhmattien = '';
		var html_haydoctranhmattien_chinh = '';
		var html_haydoctranhmattien_sv = '';
		var html_like_sv1 = '';
		var html_like_sv2 = '';
		if (typeof(listDataServicesInfo.instagram_vip_like_tab) !== 'undefined') {
			html_haydoctranhmattien = parse(listDataServicesInfo.instagram_vip_like_tab.ghichu);
			html_haydoctranhmattien_chinh = parse(listDataServicesInfo.instagram_vip_like_tab.ghichu_chinh);
		}
		if (typeof(listDataServicesInfo.instagram_vip_like_sv1) !== 'undefined') {
			html_like_sv1 = parse(listDataServicesInfo.instagram_vip_like_sv1.ghichu_sv);
			if (lsct.toString() === "1") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.instagram_vip_like_sv1.ghichu);
			}
		}
		if (typeof(listDataServicesInfo.instagram_vip_like_sv2) !== 'undefined') {
			html_like_sv2 = parse(listDataServicesInfo.instagram_vip_like_sv2.ghichu_sv);
			if (lsct.toString() === "2") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.instagram_vip_like_sv2.ghichu);
			}
		}
		
		var price = Math.round((gtmtt * slllnct * slbvtmn * sncmv + Number.EPSILON) * 100) / 100;
		return (
			<Fragment>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Link hoặc ID trang cá nhân:</label>
					<div className="col-sm-8">
						<input type="text" className="form-control" id="lhibv" name="lhibv" placeholder="" onChange={this.onChange} value={lhibv} />
						<h6 style={{"marginTop":"5px", "marginBottom":"0px"}}>Hướng dẫn link instagram: <a href="https://i.imgur.com/XSODcDm.png" target="_blank" className="font-bold"> Tại đây</a></h6>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Loại Seeding cần tăng:</label>
					<div className="col-sm-8">
						<div className="custom-control custom-radio custom-control-inline" style={{opacity: this.props.instagramviplike.price.instagrameviplike_viet.prices_web == 0 ? '0.3' : '1' }}>
							<input onChange={this.onChange} value="1" type="radio" className="custom-control-input" id="lsct_1" name="lsct" checked={lsct.toString() === "1"} disabled={this.props.instagramviplike.price.instagrameviplike_viet.prices_web == 0 ? 'disabled' : ''} />
							<label className="custom-control-label" htmlFor="lsct_1" > Like Việt {html_like_sv1} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.instagramviplike.price.instagrameviplike_viet.prices_web} coin</span> {instagramviplike_viet_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
						</div>
						<div className="custom-control custom-radio custom-control-inline" style={{opacity: this.props.instagramviplike.price.instagrameviplike_tay.prices_web == 0 ? '0.3' : '1' }}>
							<input onChange={this.onChange} value="2" type="radio" className="custom-control-input" id="lsct_2" name="lsct" checked={lsct.toString() === "2"} disabled={this.props.instagramviplike.price.instagrameviplike_tay.prices_web == 0 ? 'disabled' : ''} />
							<label className="custom-control-label" htmlFor="lsct_2" > Like Tây {html_like_sv2} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.instagramviplike.price.instagrameviplike_tay.prices_web} coin</span> {instagramviplike_tay_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
						</div>
					</div>
				</div>
				<div className="form-group row">
					{/* <label className="col-sm-4 col-form-label" htmlFor=""></label> */}
					<div className="col-sm-12">
						<div className="row">
							<div className="col-sm-6">
								<label className="mt-2">Số lượng like nhỏ nhất cần tăng:</label>
								<input type="number" className="form-control" id="sllnnct" name="sllnnct" placeholder="" onChange={this.onChange} value={sllnnct} onKeyPress={this.onKeyPress} />
							</div>
							<div className="col-sm-6">
								<label className="mt-2">Số lượng like lớn nhất cần tăng:</label>
								<input type="number" className="form-control" id="slllnct" name="slllnct" placeholder="" onChange={this.onChange} value={slllnct} onKeyPress={this.onKeyPress} />
							</div>
						</div>
						<div className="row">
							<div className="col-sm-6">
								<label className="mt-2">Số lượng bài viết trong 1 ngày:</label>
								<input type="number" className="form-control" id="slbvtmn" name="slbvtmn" placeholder="" onChange={this.onChange} value={slbvtmn} onKeyPress={this.onKeyPress} />
							</div>
							<div className="col-sm-6">
								<label className="mt-2">Số ngày cần mua VIP:</label>
								{/* <input type="number" className="form-control" id="sncmv" name="sncmv" placeholder="" onChange={this.onChange} value={sncmv} onKeyPress={this.onKeyPress} /> */}
								<select onChange={this.onChange} value={sncmv.toString()} name="sncmv" className="form-control">
									<option value="7">7</option>
									<option value="15">15</option>
									<option value="30">30</option>
									<option value="60">60</option>
									<option value="90">90</option>
									<option value="120">120</option>
								</select>
							</div>
						</div>
					</div>
				</div>
				<div className="row mt-2 mb-3">
				    <div className="col-12 bold">
				        <div className="card card-orange">
				        	<div className="card-body py-2">
				        		<h4 className="mb-0 text-danger text-center">Tổng tiền của gói vip sẽ = (Giá tiền order mỗi like) x (Số lượng like lớn nhất mỗi bài) x (Số lượng bài trong ngày) x (Số ngày đăng ký vip)</h4>
			        		</div>
		        		</div>
				    </div>
				</div>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Giá tiền mỗi tương tác:</label>
					<div className="col-sm-8">
						<input type="number" className="form-control" id="gtmtt" name="gtmtt" placeholder="" onChange={this.onChange} value={gtmtt} onKeyPress={this.onKeyPress} />
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Ghi chú:</label>
					<div className="col-sm-8">
						<textarea rows="2" placeholder="Nhập nội dung ghi chú về tiến trình của bạn" className="form-control input-gray" id="gc" name="gc" onChange={this.onChange} ></textarea>
					</div>
				</div>
				
				<button type="button" className="btn badge-primary-cus btn-rounded btn-show-description-cus" onClick={(e) => {this.onClickShowDescription(!this.state.showDescription)}}>
					{
						this.state.showDescription?
							<i className="fa fa-angle-up" style={{"marginRight": "5px"}} aria-hidden="true"></i>
						:
							<i className="fa fa-angle-down" style={{"marginRight": "5px"}} aria-hidden="true"></i>
					}
					Thông tin dịch vụ
				</button>
				
				<ServicesDescription allservicesinfo={listDataServicesInfo} server={lsct.toString()} tab="instagram_vip_like" giatien={gtmtt} baohanh={0} showDescription={this.state.showDescription} />
				
				<div className="row mt-2" style={{"display": this.state.showDescription? "flex" : "none"}}>
				    <div className="col-md-12 bold" style={{"marginBottom": "10px"}}>
						<div className="card card-select-cus">
							<div className="card-body card-body-select-cus">
								<p className="mb-0 mt-3 text-wrap">
									<ul>
										<li>1 ID KHÔNG CÀI 2 ĐƠN CÙNG LÚC TRONG HỆ THỐNG ! ĐƠN CŨ XONG MỚI CÀI ĐƠN MỚI ! CỐ TÌNH CÀI BỊ HỤT SỐ LƯỢNG SẼ KHÔNG XỬ LÝ !</li>
										<li>Nếu ID đang chạy trên hệ thống mà bạn vẫn mua id đó cá hệ thống bên khác, nếu có tình trạng hụt số lượng giữa 2 bên thì sẽ không được xử lí!</li>
										{html_haydoctranhmattien}
										{html_haydoctranhmattien_chinh}
										{html_haydoctranhmattien_sv}
										<li>Nếu gặp lỗi hãy nhắn tin hỗ trợ phía bên phải góc màn hình hoặc vào mục liên hệ hỗ trợ để được hỗ trợ tốt nhất!</li>
									</ul>
								</p>
								<span className="badge badge-cus position-absolute-cus badge-top-left-cus badge-primary-cus badge-pill-cus"> HÃY ĐỌC ĐỂ TRÁNH MẤT TIỀN KHI SỬ DỤNG! </span>
							</div>
						</div>
				    </div>
				</div>
				<div className="row mt-4">
					<div className="col-12 text-center bold">
						<div className="card card-info">
							<div className="card-body">
								<h5 className="font-bold">Tổng: <span className="bold green"><span>{price}</span> Coin</span></h5>
								<h6 className="mb-0">Bạn sẽ mua VIP <span className="bold green">{slllnct} Like </span> với giá <span className="green bold">{gtmtt}</span> Coin / Like</h6>
							</div>
						</div>
					</div>
				</div>
				<div className="row my-4">
					<div className="col-12 text-center bold">
						<button type="button" className="btn btn-block btn-rounded btn-hero-primary" onClick={this.taoTienTrinhfunc} >Tạo tiến trình</button>
					</div>
				</div>
			</Fragment>
		)
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	instagramviplike: state.instagramviplike,
});
const mapDispatchToProps = { addIVL, updateIVL, getConfigMoDong }

export default connect(mapStateToProps, mapDispatchToProps)(taotientrinh);