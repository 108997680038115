import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import Admin1 from '../../icon/admin1.png';
import Users1 from '../../icon/users1.png';

class sitecontent extends Component {
	render() {
		const { username, money, facebook_id, type, notes } = this.props.auth.user;
		// var ava = "https://graph.facebook.com/" + facebook_id + "/picture?width=100&amp;height=100";
		var ava = Users1;
		if (type == 1) {
			ava = Admin1;
		}
		return (
			<Fragment>
				{/* <div>
					<div className="card card-infor" style={{"boxShadow": "0 7px 14px 0 rgba(65, 69, 88, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07)", "marginTop": "20px"}}>
						<div className="card-body">
							<div className="row align-items-center">
								<div className="col-auto pr-0">
									<a href="" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="profile-pic">
										<img src={ava} width="160" alt="user" className="img-avatars rounded-circle border" />
									</a>
								</div>
								<div className="col text-left">
									<div className="row align-items-center mt-1">
										<div className="col">
											<h6 className="bold font-22 mb-0"><span>{ username }</span></h6>
										</div>
										<div className="col-auto">
											<h6 className="mb-0 font-14 bt-green user-level">
												{notes}
											</h6>
										</div>
									</div>
									<div className="row align-items-center mt-1">
										<div className="col">
											<h6 className="bold font-22 mb-0 green"><span><NumberFormat value={ money } thousandSeparator={true} suffix=" Coin" displayType={'text'} /></span></h6>
										</div>
										<div className="col-auto">
											<h6 className="mb-0 font-14 user-discount"> Giảm 0% </h6>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div> */}
			</Fragment>
		)
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
});
const mapDispatchToProps = { }

export default connect(mapStateToProps, mapDispatchToProps)(sitecontent);