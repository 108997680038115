import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { login } from "../../actions/authActions";
import { clearErrors } from "../../actions/errorActions";
import RegisterModal4 from './Register4';
import ServiceModal4 from './Service4';
import ForgotPasswordModal4 from './ForgotPassword4';
import bp3TunR from '../../iconlanding/4/bp3TunR.png';
import EEsJaNb from '../../iconlanding/4/EEsJaNb.png';
import gBi3lbH from '../../iconlanding/4/gBi3lbH.png';
import hVqQoKi from '../../iconlanding/4/hVqQoKi.png';
import QFizvnl from '../../iconlanding/4/QFizvnl.png';
import vnaKa8X from '../../iconlanding/4/vnaKa8X.png';
import xHUONSh from '../../iconlanding/4/xHUONSh.png';
import {
  BrowserRouter as Router,
  Link
} from "react-router-dom";
import { isMobile } from 'react-device-detect';
class Login extends Component {
  state = {
	modal: 1,
    msg: "",
    username: "",
    password: "",
    maxacminh: "",
    form_maxacminh: false,
    isLoading: false
  };
  componentDidMount(){
	if (this.props.typeform == 3) {
	  this.setState({ modal: 3 });
	}
  }
  componentDidUpdate(prevProps) {
    const { error } = this.props;
    if (error !== prevProps.error) {
      // Check for login error
      if (error.id === "LOGIN_FAIL") {
        this.setState({ msg: error.msg.msg });
      } else if (error.id === "LOGIN_FAIL_2FA") {
        this.setState({ msg: error.msg.msg, form_maxacminh: true });
      } else {
        this.setState({ msg: null });
      }
    }
  }
	clearAlert = () => {
		// Clear errors
		this.props.clearErrors();
		// this.setState({
			// modal: !this.state.modal
		// });
	}
  changeFormLogRegister = (typeform) => {
	this.props.clearErrors();
	this.setState({
		modal: typeform
	});
	
}
  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };
  onSubmit = e => {
    e.preventDefault();
    const { username, password, maxacminh } = this.state;
    this.props.clearErrors();
    this.props.login({ username, password, maxacminh });
  };
  render() {
    let isLoading = this.props.auth.isLoading;
    var domainSite = window.location.hostname;
	var config_website = {};
	if (localStorage.getItem("confignavbar") != null) {
		config_website = JSON.parse(localStorage.getItem("confignavbar"));
	} else {
		config_website = this.props.auth.config_website;
	}
	const formLogin = (
		<Router>
			<Fragment>
				<div className="landingcus4" style={{"zoom": "122%"}}>
					<header className="header-section no-header">
						<div className="header-bottom">
							<div className="container">
								<nav className="navbar navbar-expand-xl p-0 align-items-start">
									<a className="text-white font-w700 fontSize-h3 site-logo site-title" href="/" style={{"padding": "0", "wordBreak": "break-all", "width": "40%"}}>
									  <span className="text-white text-uppercase">{domainSite.indexOf('tools1s.com') < 0 ? domainSite.slice(0, domainSite.lastIndexOf('.')) : domainSite.slice(0, domainSite.lastIndexOf('.')).replace('vip.', '') }</span>
									  <span className="text-white text-uppercase">{domainSite.indexOf('.') > -1 && domainSite.indexOf('tools1s.com') < 0 ? domainSite.slice(domainSite.lastIndexOf('.')) : ''}</span>
									</a>
									<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
										<Link to="/services" onClick={(e) => {this.changeFormLogRegister(3)}} className="btn btn-pill sign-up registercus" style={{"color": "white", "padding": "13px 15px"}}>
											Dịch vụ
										</Link>
									</button>
									<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
										<Link to="/" onClick={(e) => {this.changeFormLogRegister(2)}} className="btn btn-pill sign-up registercus" style={{"color": "white", "padding": "13px 15px"}}>
											Đăng ký
										</Link>
									</button>
									<div className="collapse navbar-collapse" id="navbarSupportedContent">
										<ul className="navbar-nav main-menu ml-auto guest-menu">
											<li className="active">
												<Link to="/services" onClick={(e) => {this.changeFormLogRegister(3)}}>
													Dịch vụ
												</Link>
											</li>
											<li className="active">
												<a href="/" >Đăng nhập</a>
											</li>
											<li>
												<Link to="/" onClick={(e) => {this.changeFormLogRegister(2)}}>
													Đăng ký
												</Link>
											</li>
											{/* <li><a href="/services">Services</a></li>
											<li><a href="/api">API</a></li>
											<li><a href="/terms">Terms</a></li>
											<li><a href="/faq">FAQ</a></li>
											<li><a href="/blog">Blog</a></li>
											<li><a href="/about">About</a></li>
											<li><a href="/howitworks">How it Works</a></li> */}
										</ul>
										<div className="nav-right">
										</div>
									</div>
								</nav>
							</div>
						</div>
					</header>
					<section className="banner-section bg_img" data-background={gBi3lbH} style={{"backgroundImage": "url("+gBi3lbH+")"}}>
						<div className="banner-image-area"> 
							<img src={QFizvnl} alt="image"/>
						</div>
						<div className="container" style={{"marginTop": "-75px"}}>
							<div className="row">
								<div className="col-lg-6">
									<div className="banner-content">
										<h2 className="banner-title">
											{domainSite}
											<span>
												Nền Tảng Tăng Tương Tác Uy Tín Và Tin Cậy Nhất Cho Các Dịch Vụ Truyền Thông Mạng Xã Hội.
											</span>
										</h2>
										<p>Những Gì Bạn Cần Tăng - Chúng Tôi Có Cung Cấp<br/>Phát triển mọi nền tảng mạng xã hội của bạn với dịch vụ của chúng tôi.</p>
										<div className="btn-area">
											<Link to="/" onClick={(e) => {this.changeFormLogRegister(2)}} style={{"fontSize": "15px"}} className="btn btn-secondary btn-radius">
												Đăng ký
											</Link>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					<section className="login-section pt-60 pb-60">
						<div className="container">
							<div className="row">
								<div className="col-lg-12">
									<div id="login-section" className="login-block">
										<div className="login-block-inner">
											<h3 className="title text-center">ĐĂNG NHẬP</h3>
											{isLoading ? (
												<div className="preloader">
													<div className="spinner-grow spinner-grow-sm text-primary" role="status" style={{ "marginTop": "10%" }}>
													  <span className="sr-only"></span>
													</div>
													<div className="spinner-grow spinner-grow-sm text-danger" role="status" style={{ "marginTop": "10%" }}>
													  <span className="sr-only">.</span>
													</div>
													<div className="spinner-grow spinner-grow-sm text-warning" role="status" style={{ "marginTop": "10%" }}>
													  <span className="sr-only"></span>
													</div>
												</div>
											):('')}
											{this.state.msg ? (
												<div className="alert alert-warning d-flex align-items-center justify-content-between" role="alert" >
												  <div className="flex-fill mr-3">
													<p className="mb-0">{this.state.msg}</p>
												  </div>
												  <div className="flex-00-auto">
													<i className="fa fa-fw fa-exclamation-circle"></i>
												  </div>
												</div>
											  ) : null}
											<form className="js-validation-signup" onSubmit={this.onSubmit} method="POST" >
												<div className="row">
													<div className="col-lg-5">
														<div className="frm-group">
															<input name="username" placeholder="Nhập tài khoản" required="" id="username" type="text" className="" onChange={this.onChange} style={(isMobile ? {"marginBottom": "20px"} : {})} />
															{
																!this.state.form_maxacminh ?
																	<span>
																		Bạn chưa có tài khoản?
																		<Link to="/" onClick={(e) => {this.changeFormLogRegister(2)}} style={{"marginLeft": "5px"}}>
																			Đăng ký
																		</Link>
																	</span>
																: ""
															}
														</div>
													</div>
													<div className="col-lg-5">
														<div className="frm-group">
															<input name="password" placeholder="Nhập mật khẩu" required="" id="password" type="password" className="" onChange={this.onChange} style={(isMobile ? {"marginBottom": "20px"} : {})} />
															{
																!this.state.form_maxacminh ?
																	<span style={{"float": "right"}}>
																		<Link to="/" onClick={(e) => {this.changeFormLogRegister(4)}}>
																			Quên mật khẩu?
																		</Link>
																	</span>
																: ""
															}
														</div>
													</div>
													<div className="col-lg-2">
														<button type="submit" className="btn btn-primary btn-sm">Đăng nhập</button>
													</div>
													{
														this.state.form_maxacminh ?
															<div className="col-lg-10">
																<div className="frm-group">
																	<input name="maxacminh" placeholder="Nhập mã xác minh" required="" id="maxacminh" type="text" className="" onChange={this.onChange} style={(isMobile ? {"marginBottom": "20px"} : {})} />
																	<span>
																		Bạn chưa có tài khoản?
																		<Link to="/" onClick={(e) => {this.changeFormLogRegister(2)}} style={{"marginLeft": "5px"}}>
																			Đăng ký
																		</Link>
																	</span>
																	<span style={{"float": "right"}}>
																		<Link to="/" onClick={(e) => {this.changeFormLogRegister(4)}}>
																			Quên mật khẩu?
																		</Link>
																	</span>
																</div>
															</div>
														: ""
													}
												</div>
											</form>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					<section className="features-section pt-60 pb-60">
						<div className="container">
							<div className="row">
								<div className="col-md-6">
									<div className="section-header custom-border">
										<h2 className="section-title text-capitalize border-bottom-right">Các tính năng tốt nhất để tăng trưởng</h2>
									</div>
								</div>
							</div>
							<div className="row mb-none-30">
								<div className="col-xl-4 col-lg-6 col-md-6">
									<div className="icon-item text-center mb-30">
										<div className="item-header">
											<img src={bp3TunR}/> <br/><br/>
											<h3 className="title text-capitalize font-weight-semibold">Giá không thể tin được</h3>
										</div>
										<div className="content">
											<p>Giá của chúng tôi rẻ nhất trên thị trường, bắt đầu từ 1 VNĐ.</p>
										</div>
									</div>
								</div>
								<div className="col-xl-4 col-lg-6 col-md-6">
									<div className="icon-item text-center mb-30">
										<div className="item-header">
											<img src={EEsJaNb}/> <br/><br/>
											<h3 className="title text-capitalize font-weight-semibold">Tốc độ xử lý cao</h3>
										</div>
										<div className="content">
											<p>Dịch vụ của chúng tôi được tự động hóa và thường mất vài phút.</p>
										</div>
									</div>
								</div>
								<div className="col-xl-4 col-lg-6 col-md-6">
									<div className="icon-item text-center mb-30">
										<div className="item-header">
											<img src={xHUONSh}/> <br/><br/>
											<h3 className="title text-capitalize font-weight-semibold">Hỗ trợ 24/7</h3>
										</div>
										<div className="content">
											<p>Bạn có thể tin tưởng vào hệ thống hỗ trợ 24/7 của chúng tôi.</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					<section className="choose-section pb-120">
						<div className="container">
							<div className="row">
								<div className="col-lg-6">
									<div className="choose-thumb">
										<img src={vnaKa8X} alt="image"/>
									</div>
								</div>
								<div className="col-lg-6">
									<div className="choose-content">
										<h2 className="section-title text-capitalize border-bottom-right">Nền tảng hoàn hảo cho tiếp thị truyền thông xã hội </h2>
										<p>{domainSite} là việc sử dụng các nền tảng truyền thông xã hội như Instagram, Facebook, Youtube, TikTok, Shopee và nhiều nền tảng khác để quảng bá bản thân hoặc công ty của bạn. Nếu bạn đang tìm cách tăng cường sự hiện diện trực tuyến của mình, thì lựa chọn tốt nhất của bạn, nơi chúng tôi cung cấp các dịch vụ giúp bạn tăng cường sự hiện diện trực tuyến của mình trên TẤT CẢ các nền tảng truyền thông xã hội với mức giá rẻ nhất.</p>
										<ul className="cmn-list">
											<li>Dịch vụ chất lượng cao nhất trên thị trường!</li>
											<li>Chúng tôi đánh bại mọi giá cả / mọi chất lượng!</li>
											<li>Chấp nhận thanh toán với ngân hàng, momo, thẻ nạp điện thoại!</li>
											<li>Kiếm lại tiền chỉ bằng cách đặt hàng!</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</section>
					<section className="service-section pt-120 pb-120">
						<div className="container">
							<div className="row justify-content-center">
								<div className="col-lg-8">
									<div className="section-header text-center custom-border">
										<h2 className="section-title text-capitalize border-bottom-bottom">dịch vụ {domainSite} tuyệt vời của chúng tôi</h2>
										<p>Một số trang mạng xã hội phổ biến, bao gồm Facebook, Instagram và YouTube đã trở thành những nền tảng ưa thích nhất để tương tác với khán giả. Cho dù bạn đang kinh doanh trực tuyến hay tiếp thị, bạn phải biết cách sử dụng các nền tảng mạng xã hội khác nhau để tiếp cận lượng khán giả lớn hơn nhiều trong thời gian ngắn nhất có thể. Chúng tôi sẵn sàng trợ giúp bạn trong việc sử dụng tất cả các nền tảng quản lý mạng xã hội được nhắm mục tiêu để doanh nghiệp của bạn phát triển nhanh chóng.</p>
									</div>
								</div>
							</div>
							<div className="row mb-none-30">
								<div className="col-lg-4 col-sm-6">
									<div className="icon-item icon-item--style2 text-center mb-30">
										<div className="item-header">
											<div className="icon">
												<div className="icon-inner">
													<i className="fa fa-eye"></i>
												</div>
											</div>
											<h3 className="title text-capitalize font-weight-semibold">lượt xem</h3>
										</div>
										<div className="content">
											<p>Nhận lượt xem cho bài đăng của bạn để tăng và thu hút khán giả mới.</p>
										</div>
									</div>
								</div>
								<div className="col-lg-4 col-sm-6">
									<div className="icon-item icon-item--style2 text-center mb-30">
										<div className="item-header">
											<div className="icon">
												<div className="icon-inner">
													<i className="fa fa-thumbs-up"></i>
												</div>
											</div>
											<h3 className="title text-capitalize font-weight-semibold">thích</h3>
										</div>
										<div className="content">
											<p>Thích cho tất cả các mạng xã hội. Chúng tôi có Dịch vụ tăng lượt thích TỐT NHẤT trên thị trường.</p>
										</div>
									</div>
								</div>
								<div className="col-lg-4 col-sm-6">
									<div className="icon-item icon-item--style2 text-center mb-30">
										<div className="item-header">
											<div className="icon">
												<div className="icon-inner">
													<i className="fa fa-user-plus"></i>
												</div>
											</div>
											<h3 className="title text-capitalize font-weight-semibold">người theo dõi</h3>
										</div>
										<div className="content">
											<p>Thu hút người theo dõi nhanh chóng và dễ dàng. Giao Hàng Nhanh Người Dùng Chất Lượng Cao.</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					<div className="counter-section main-bg-one">
						<div className="container">
							<div className="row mb-none-30">
								<div className="col-lg-3 col-md-3 col-6">
									<div className="counter-item text-center mb-30">
										<div className="counter-item-header">
											<i className="fa fa-check-circle"></i>
											<div className="counter-num">
												<span className="counter">1050</span>
												<span>+</span>
											</div>
										</div>
										<div className="content">
											<span className="caption">Các quốc gia hỗ trợ</span>
										</div>
									</div>
								</div>
								<div className="col-lg-3 col-md-3 col-6">
									<div className="counter-item text-center mb-30">
										<div className="counter-item-header">
											<i className="fa fa-check-circle"></i>
											<div className="counter-num">
												<span className="counter">600</span>
												<span>K</span>
											</div>
										</div>
										<div className="content">
											<span className="caption">Khách hàng hạnh phúc</span>
										</div>
									</div>
								</div>
								<div className="col-lg-3 col-md-3 col-6">
									<div className="counter-item text-center mb-30">
										<div className="counter-item-header">
											<i className="fa fa-check-circle"></i>
											<div className="counter-num">
												<span className="counter">8000K</span>
											</div>
										</div>
										<div className="content">
											<span className="caption">tổng đơn đã hoàn thành</span>
										</div>
									</div>
								</div>
								<div className="col-lg-3 col-md-3 col-6">
									<div className="counter-item text-center mb-30">
										<div className="counter-item-header">
											<i className="fa fa-check-circle"></i>
											<div className="counter-num">
												<span className="counter">10</span>
												<span>+</span>
											</div>
										</div>
										<div className="content">
											<span className="caption">thành viên của nhóm</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<section className="testimonial-section pt-120 pb-120">
						<div className="container">
							<div className="row justify-content-center">
								<div className="col-lg-8">
									<div className="section-header text-center custom-border">
										<h2 className="section-title text-capitalize border-bottom-bottom">nói gì về khách hàng hạnh phúc của chúng tôi</h2>
										<p>Tôi đã thử rất nhiều dịch vụ nhưng tôi cảm thấy {domainSite} là một trong những dịch vụ tốt hơn nếu không muốn nói là tốt nhất. Họ cung cấp rất nhiều dịch vụ với giá tốt và đội ngũ hỗ trợ của họ nhanh chóng trả lời vé của bạn, vì vậy hãy thử xem nhé !.</p>
									</div>
								</div>
							</div>
						</div>
					</section>
					<section className="faq-section pb-120">
						<div className="container">
							<div className="row">
								<div className="col-lg-6">
									<div className="faq-thumb">
										<img src={hVqQoKi} alt="image"/>
									</div>
								</div>
								<div className="col-lg-6">
									<div className="faq-content">
										<h2 className="section-title text-capitalize border-bottom-right">Một số câu hỏi phổ biến</h2>
										<div className="accordion mt-30" id="marever-accordio-one">
											<div className="card">
												<div className="card-header" id="heading-01">
													<button className="accordion-title collapsed" type="button" data-toggle="collapse" data-target="#collapse-01" aria-expanded="false" aria-controls="collapse-01">
														<span className="caption">Tôi có thể tìm thấy danh sách các dịch vụ và giá cả ở đâu?</span>
													</button>
												</div>

												<div id="collapse-01" className="collapse" aria-labelledby="heading-01" data-parent="#marever-accordio-one" style={{"display": "block"}}>
													<div className="card-body">
														<p>Hãy đăng ký rồi đăng nhập vào hệ thống</p>
													</div>
												</div>
											</div>
											<div className="card">
												<div className="card-header" id="heading-02">
													<button className="accordion-title collapsed" type="button" data-toggle="collapse" data-target="#collapse-02" aria-expanded="false" aria-controls="collapse-02">
														<span className="caption">phương thức thanh toán của chúng tôi là gì?</span>
													</button>
												</div>
												<div id="collapse-02" className="collapse" aria-labelledby="heading-02" data-parent="#marever-accordio-one" style={{"display": "block"}}>
													<div className="card-body">
														<p>Ngân hàng có internet banking, momo, thẻ điện thoại... </p>
													</div>           
												</div>
											</div>
											<div className="card">
												<div className="card-header" id="heading-04">
													<button className="accordion-title collapsed" type="button" data-toggle="collapse" data-target="#collapse-04" aria-expanded="false" aria-controls="collapse-04">
														<span className="caption">tại sao mọi người chọn chúng tôi?</span>
													</button>
												</div>
												<div id="collapse-04" className="collapse" aria-labelledby="heading-04" data-parent="#marever-accordio-one" style={{"display": "block"}}>
													<div className="card-body">
														<p>Dịch vụ chất lượng cao nhất, dịch vụ nhanh nhất, dịch vụ rẻ nhất, hỗ trợ tốt nhất 24/7!</p>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					<footer className="footer-section">
						<div className="footer-top">
							<div className="container">
								<div className="row mb-none-30">
									<div className="col-xl-4 col-lg-6 col-md-8">
										<div className="widget footer-widget mb-30">
											<h4 className="widget-title">về chúng tôi</h4>
											<div className="widget-body">
												<p>Tiếp thị Internet SEO &amp; Chuyên gia Tiếp thị Truyền thông Xã hội. Chúng tôi đã làm việc trong lĩnh vực này từ 2 năm qua. Vì vậy, theo chất lượng cao và dịch vụ làm việc 100% của chúng tôi, chúng tôi sẽ cố gắng hết sức cùng doanh nghiệp của bạn để phát triển lên Cấp độ tiếp theo.</p>
											</div>
										</div>
									</div>
									<div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
										<div className="widget footer-widget mb-30">
										</div>
									</div>
									<div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
										<div className="widget footer-widget mb-30">
										</div>
									</div>
									<div className="col-xl-4 col-lg-12 col-md-8">
										<div className="widget footer-widget mb-30">
											<h4 className="widget-title">Dịch vụ của chúng tôi</h4>
											<div className="widget-body">
												<ul className="menu-list" style={{"listStyle": "circle", "paddingLeft": "15px"}}>
													<li className="text-white">Facebook</li>
													<li className="text-white">Youtube</li>
													<li className="text-white">Instagram</li>
													<li className="text-white">Tiktok </li>
													<li className="text-white">Youtube</li>
													<li className="text-white">Shopee</li>
													<li className="text-white">Và nhiều dịch vụ khác</li>
												</ul>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="footer-bottom">
							<div className="container">
								<div className="row">
									<div className="col-lg-6 col-sm-8 text-sm-left text-center">
										<p>© Copyright {domainSite}. All Rights Reserved.</p>
									</div>
								</div>
							</div>
						</div>
					</footer>
				</div>
			</Fragment>
		</Router>
	);
	var path_check = window.location.pathname;
	if (window.location.pathname.length > 1) {
		path_check = window.location.pathname.replace(/\/+$/g, '');
	}
	const formRegister = (
		<Fragment>
			<RegisterModal4 action_login={(e) => {this.changeFormLogRegister(1)}} action_service={(e) => {this.changeFormLogRegister(3)}} />
		</Fragment>
	);
	const formService = (
		<Fragment>
			<ServiceModal4 action_login={(e) => {this.changeFormLogRegister(1)}} action_register={(e) => {this.changeFormLogRegister(2)}} />
		</Fragment>
	);
	const formForgotPassword = (
		<Fragment>
			<ForgotPasswordModal4 action_login={(e) => {this.changeFormLogRegister(1)}} action_register={(e) => {this.changeFormLogRegister(2)}} action_service={(e) => {this.changeFormLogRegister(3)}} action_reset={path_check} />
		</Fragment>
	);
	return (
		<Fragment>
			{
				path_check === '/resetpassword' ?
					formForgotPassword
				: this.state.modal == 1 ?
					formLogin
				: this.state.modal == 2 ?
					formRegister
				: this.state.modal == 3 ?
					formService
				: this.state.modal == 4 ?
					formForgotPassword
				: ''
			}
		</Fragment>
	);
  }
}
Login.propTypes = {
  auth: PropTypes.object.isRequired,
  error: PropTypes.object,
  login: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  error: state.error
});
export default connect(mapStateToProps, { login, clearErrors })(Login);
