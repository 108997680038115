import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import {
    Modal,
    ModalBody,
    ModalFooter
} from 'reactstrap';
import { NhatKyHoatDong, closeModalNhatKy } from '../../../actions/FbbotActions';
import Swal from 'sweetalert2';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import parse from 'html-react-parser';
const { SearchBar } = Search;

class Modal_edit extends Component {
    state = {
		
    }

	componentDidMount() {
		this.props.NhatKyHoatDong('facebookbotlovestory', this.props.dataidfb);
	}
	
    render() {
        const {isOpenModal, dataidfb} = this.props;
        var {list_nhatky} = this.props.Facebookbotlovestory;
		var dataReturn = [];
		// var arrKeyObj = ['username', 'idfb', 'idfb_2', 'idfbtuongtac', 'camxuc', 'ngay_tuongtac'];
		var arrKeyObj = ['idfb', 'idfb_2', 'idfbtuongtac', 'camxuc', 'ngay_tuongtac'];
		list_nhatky = list_nhatky.split('\n').reverse();
		list_nhatky.forEach(function(v, i){
			if (v != '') {
				var arrK = v.split("||");
				var objEnd = Object.assign({},...arrK.map((key,ik) => ({[arrKeyObj[parseInt(ik)]]: key})));
				objEnd = {
					...objEnd,
					id: i,
				}
				dataReturn.push(objEnd);
			}
		});
		function formatFbName(cell, row) {
			return parse('<a href="https://www.facebook.com/'+cell+'" target="_blank" >'+cell+'</a>');
		}
		function formatFbNameTuongTac(cell, row) {
			var idtt_check = cell.match(/([0-9])+\//g);
			var idtt = cell;
			if (idtt_check != null) {
				idtt = idtt_check[0].replace('/', '');
			}
			return parse('<a href="https://www.facebook.com/stories/'+cell+'" target="_blank" >'+idtt+'</a>');
		}
		function formatCamxuc(cell, row) {
			if (cell == 'NO_FRIEND') {
				return 'NO';
			}
			return cell;
		}
		const columns = [{
			dataField: 'idfb',
			text: 'ID Facebook',
			sort: true,
			formatter: formatFbName,
		}, {
			dataField: 'idfbtuongtac',
			text: 'ID Facebook Tương Tác',
			sort: true,
			formatter: formatFbNameTuongTac,
		}, {
			dataField: 'camxuc',
			text: 'Cảm xúc',
			sort: true,
			formatter: formatCamxuc,
		}, {
			dataField: 'ngay_tuongtac',
			text: 'Thời gian tương tác',
			sort: true,
		}];
        return (
            <Fragment>
                <Modal centered={true} size="lg" toggle={(e) => {this.props.closeModalNhatKy('facebookbotlovestory');this.props.openModal()}} isOpen={isOpenModal}>
                    <div className="modal-header">
                        <p className="mb-0 text-dark">Nhật ký hoạt động</p>
                        <button onClick={(e) => {this.props.closeModalNhatKy('facebookbotlovestory');this.props.openModal()}} type="button" className="close">×</button>
                    </div>
                    <ModalBody>
                       <ToolkitProvider keyField="id" data={ dataReturn } columns={ columns } search >
							{
								props => (
									<div>
										<SearchBar { ...props.searchProps } />
										<BootstrapTable { ...props.baseProps } pagination={ paginationFactory() } wrapperClasses="table-responsive" striped hover condensed />
									</div>
								)
							}
						</ToolkitProvider>
                    </ModalBody>
                </Modal>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    Facebookbotlovestory: state.Facebookbotlovestory
});

export default connect(mapStateToProps, {NhatKyHoatDong, closeModalNhatKy}) (Modal_edit);
