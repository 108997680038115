const initialState = {
  loading: false,
  formData: {
    id_baiviet: "",
    type_reaction: "LIKE",
    type_seeding: "LIKE",
    baohanh: 0,
    locnangcao: false,
    locgioitinh: 0,
    loctuoi_min: 13,
    loctuoi_max: 65,
    locsobanbe_min: 100,
    locsobanbe_max: 5000,
    num_need: 1,
    giatien_LIKE: 45,
    giatien_COMMENT: 450,
    giatien_SHARE: 900
  }
};

export default function(state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
}
