import {
  USER_LOADED,
  USER_LOADING,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT_SUCCESS,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  GET_DATA_USER,
  CHANGE_SIDEBAR,
  CHANGE_MENUPROFILE,
  CHANGE_MENUNOTI,
  NOTI_LOADED,
  NOTI_SP_LOADED,
  CHANGE_OPENFBBUFF,
  CHANGE_OPENFBVIP,
  CHANGE_OPENFB,
  CHANGE_OPENFBBOT,
  CHANGE_OPENINSTAGRAM,
  CHANGE_OPENYOUTUBE,
  CHANGE_OPENSHOPEE,
  CHANGE_OPENTIKTOK,
  CHANGE_OPENGOOGLE,
  CHANGE_OPENTELEGRAM,
  CHANGE_OPENFBADBREAKS,
  CHANGE_OPENTWITTER,
  CHANGE_CLOSEALL,
  FORGOTPASSWORD_SEND_SUCCESS,
  FORGOTPASSWORD_SEND_FAIL,
  RESETPASSWORD_SEND_SUCCESS,
  RESETPASSWORD_SEND_FAIL,
  TOKEN_RESET_SUCCESS,
  TOKEN_RESET_FAIL,
} from "../actions/types";

const initialState = {
  token: localStorage.getItem("token"),
  isAuthenticated: localStorage.getItem("isAuthenticated"),
  isDaily: 0,
  isLoading: false,
  isOpenSidebar: true,
  isOpenFbBuff: false,
  isOpenFbVip: false,
  isOpenFb: false,
  isOpenFbBot: false,
  isOpenInstagram: false,
  isOpenYoutube: false,
  isOpenShopee: false,
  isOpenTiktok: false,
  isOpenGoogle: false,
  isOpenTelegram: false,
  isOpenFacebookAdBreaks: false,
  isOpenTwitter: false,
  isOpenMenuProfile: false,
  isOpenMenuNoti: false,
  user: checkObject(localStorage.getItem("user")),
  list_noti: [],
  list_noti_sp: [],
  priceMedium: {
    like: 50,
    comment:500,
    share: 1000,
    buff_sub: 55
  },
  allservices: [],
  config_website: {
	support_id_fanpage: '',
	bg_navbar: '0665d0',
	cf_title: window.location.hostname.replace('https://', '').replace('http://', '').replace('www.', '').toUpperCase(),
	cf_icon: window.location.protocol + "//" + window.location.hostname.replace('https://', '').replace('http://', '').replace('www.', '') + '/favicon.ico',
	cf_description: '',
	cf_og_title: '',
	cf_og_site_name: '',
	cf_og_description: '',
	cf_og_type: '',
	cf_og_url: '',
	cf_og_keywords: '',
	cf_og_image: '',
	title_dndk: 'Chào mừng bạn đến với hệ thống tăng like sub Facebook, Tiktok, Youtube, Instagram...',
	title_color_dndk: '000000',
	landingpage: '1',
	displaytabmuabanfanpagegroup: '1',
	defaultlanguage: '-1',
	naptien_mota: '',
	naptien_chuy: '',
	usertype_default: '0',
	cf_thongbaobuffmat: localStorage.getItem("cf_thongbaobuffmat"),
  },
  loading: false,
  list_history_the: [],
  token_avt: localStorage.getItem("token_avt"),
  renamefanpage_acc: localStorage.getItem("renamefanpage_acc"),
};

function checkObject(obj) {
	try {
		return JSON.parse(obj);
	} catch (e) {
		return {};
	}
}

export default function(state = initialState, action) {
  switch (action.type) {
	case 'LOADING_AUTH':
		return {
			...state,
			"loading": action.payload
		};
    case GET_DATA_USER:
      return {
        ...state
      };
    case CHANGE_SIDEBAR:
      return {
        ...state,
        isOpenSidebar: action.payload
      };
    case CHANGE_MENUPROFILE:
      return {
        ...state,
        isOpenMenuProfile: action.payload
      };
    case CHANGE_MENUNOTI:
      return {
        ...state,
        isOpenMenuNoti: action.payload
      };
    case NOTI_LOADED:
      return {
        ...state,
        list_noti: action.payload
      };
    case NOTI_SP_LOADED:
      return {
        ...state,
        list_noti_sp: action.payload
      };
    case 'SET_DATA_BG_NAVBAR':
      localStorage.setItem("confignavbar", JSON.stringify({
			support_id_fanpage: action.payload.support_id_fanpage,
			bg_navbar: action.payload.bg_navbar_site,
			cf_title: action.payload.cf_title,
			cf_icon: action.payload.cf_icon,
			cf_description: action.payload.cf_description,
			cf_og_title: action.payload.cf_og_title,
			cf_og_site_name: action.payload.cf_og_site_name,
			cf_og_description: action.payload.cf_og_description,
			cf_og_type: action.payload.cf_og_type,
			cf_og_url: action.payload.cf_og_url,
			cf_og_keywords: action.payload.cf_og_keywords,
			cf_og_image: action.payload.cf_og_image,
			title_dndk: action.payload.title_dndk,
			title_color_dndk: action.payload.title_color_dndk,
			landingpage: action.payload.landingpage,
			displaytabmuabanfanpagegroup: action.payload.displaytabmuabanfanpagegroup,
			defaultlanguage: action.payload.defaultlanguage,
			naptien_mota: action.payload.naptien_mota,
			naptien_chuy: action.payload.naptien_chuy,
			usertype_default: action.payload.usertype_default,
			checktime: parseInt(Date.now() / 1000),
		})
	  );
      return {
        ...state,
		config_website: {
			...state.config_website,
			support_id_fanpage: action.payload.support_id_fanpage,
			bg_navbar: action.payload.bg_navbar_site,
			cf_title: action.payload.cf_title,
			cf_icon: action.payload.cf_icon,
			cf_description: action.payload.cf_description,
			cf_og_title: action.payload.cf_og_title,
			cf_og_site_name: action.payload.cf_og_site_name,
			cf_og_description: action.payload.cf_og_description,
			cf_og_type: action.payload.cf_og_type,
			cf_og_url: action.payload.cf_og_url,
			cf_og_keywords: action.payload.cf_og_keywords,
			cf_og_image: action.payload.cf_og_image,
			title_dndk: action.payload.title_dndk,
			title_color_dndk: action.payload.title_color_dndk,
			landingpage: action.payload.landingpage,
			displaytabmuabanfanpagegroup: action.payload.displaytabmuabanfanpagegroup,
			defaultlanguage: action.payload.defaultlanguage,
			naptien_mota: action.payload.naptien_mota,
			naptien_chuy: action.payload.naptien_chuy,
			usertype_default: action.payload.usertype_default,
		}
      };
    case CHANGE_OPENFBBUFF:
      return {
        ...state,
        isOpenFbBuff: action.payload,
        isOpenFbVip: false,
        isOpenFbBot: false,
        isOpenInstagram: false,
        isOpenYoutube: false,
        isOpenShopee: false,
        isOpenTiktok: false,
        isOpenGoogle: false,
        isOpenTelegram: false,
        isOpenFacebookAdBreaks: false,
        isOpenTwitter: false,
      };
    case CHANGE_OPENFBVIP:
      return {
        ...state,
        isOpenFbBuff: false,
        isOpenFbVip: action.payload,
        isOpenFbBot: false,
        isOpenInstagram: false,
        isOpenYoutube: false,
        isOpenShopee: false,
        isOpenTiktok: false,
        isOpenGoogle: false,
        isOpenTelegram: false,
        isOpenFacebookAdBreaks: false,
        isOpenTwitter: false,
      };
    case CHANGE_OPENFB:
      return {
        ...state,
        isOpenFbBuff: false,
        isOpenFbVip: false,
        isOpenFb: action.payload,
        isOpenFbBot: false,
        isOpenInstagram: false,
        isOpenYoutube: false,
        isOpenShopee: false,
        isOpenTiktok: false,
        isOpenGoogle: false,
        isOpenTelegram: false,
        isOpenFacebookAdBreaks: false,
        isOpenTwitter: false,
      };
    case CHANGE_OPENFBBOT:
      return {
        ...state,
        isOpenFb: false,
        isOpenFbBot: action.payload,
        isOpenInstagram: false,
        isOpenYoutube: false,
        isOpenShopee: false,
        isOpenTiktok: false,
        isOpenGoogle: false,
        isOpenTelegram: false,
        isOpenFacebookAdBreaks: false,
        isOpenTwitter: false,
      };
    case CHANGE_OPENINSTAGRAM:
      return {
        ...state,
        isOpenFb: false,
        isOpenFbBot: false,
        isOpenInstagram: action.payload,
        isOpenYoutube: false,
        isOpenShopee: false,
        isOpenTiktok: false,
        isOpenGoogle: false,
        isOpenTelegram: false,
        isOpenFacebookAdBreaks: false,
        isOpenTwitter: false,
      };
    case CHANGE_OPENYOUTUBE:
      return {
        ...state,
        isOpenFb: false,
        isOpenFbBot: false,
        isOpenInstagram: false,
        isOpenYoutube: action.payload,
        isOpenShopee: false,
        isOpenTiktok: false,
        isOpenGoogle: false,
        isOpenTelegram: false,
        isOpenFacebookAdBreaks: false,
        isOpenTwitter: false,
      };
    case CHANGE_OPENSHOPEE:
      return {
        ...state,
        isOpenFb: false,
        isOpenFbBot: false,
        isOpenInstagram: false,
        isOpenYoutube: false,
        isOpenShopee: action.payload,
        isOpenTiktok: false,
        isOpenGoogle: false,
        isOpenTelegram: false,
        isOpenFacebookAdBreaks: false,
        isOpenTwitter: false,
      };
    case CHANGE_OPENTIKTOK:
      return {
        ...state,
        isOpenFb: false,
        isOpenFbBot: false,
        isOpenInstagram: false,
        isOpenYoutube: false,
        isOpenShopee: false,
        isOpenTiktok: action.payload,
        isOpenGoogle: false,
        isOpenTelegram: false,
        isOpenFacebookAdBreaks: false,
        isOpenTwitter: false,
      };
    case CHANGE_OPENGOOGLE:
      return {
        ...state,
        isOpenFb: false,
        isOpenFbBot: false,
        isOpenInstagram: false,
        isOpenYoutube: false,
        isOpenShopee: false,
        isOpenTiktok: false,
        isOpenGoogle: action.payload,
        isOpenTelegram: false,
        isOpenFacebookAdBreaks: false,
        isOpenTwitter: false,
      };
    case CHANGE_OPENTELEGRAM:
      return {
        ...state,
        isOpenFb: false,
        isOpenFbBot: false,
        isOpenInstagram: false,
        isOpenYoutube: false,
        isOpenShopee: false,
        isOpenTiktok: false,
        isOpenGoogle: false,
        isOpenTelegram: action.payload,
        isOpenFacebookAdBreaks: false,
        isOpenTwitter: false,
      };
    case CHANGE_OPENFBADBREAKS:
      return {
        ...state,
        isOpenFb: false,
        isOpenFbBot: false,
        isOpenInstagram: false,
        isOpenYoutube: false,
        isOpenShopee: false,
        isOpenTiktok: false,
        isOpenGoogle: false,
        isOpenTelegram: false,
        isOpenFacebookAdBreaks: action.payload,
        isOpenTwitter: false,
      };
    case CHANGE_OPENTWITTER:
      return {
        ...state,
        isOpenFb: false,
        isOpenFbBot: false,
        isOpenInstagram: false,
        isOpenYoutube: false,
        isOpenShopee: false,
        isOpenTiktok: false,
        isOpenGoogle: false,
        isOpenTelegram: false,
        isOpenFacebookAdBreaks: false,
        isOpenTwitter: action.payload,
      };
    case CHANGE_CLOSEALL:
      return {
        ...state,
        isOpenFbBuff: false,
        isOpenFbVip: false,
        isOpenFb: false,
        isOpenFbBot: false,
        isOpenInstagram: false,
        isOpenYoutube: false,
        isOpenShopee: false,
        isOpenTiktok: false,
        isOpenGoogle: false,
        isOpenTelegram: false,
        isOpenFacebookAdBreaks: false,
        isOpenTwitter: false,
      };
    case 'UPDATE_ISDAILY':
      return {
        ...state,
        isDaily: action.payload
      };
    case USER_LOADING:
      return {
        ...state,
        isLoading: true
      };
    case USER_LOADED:
      localStorage.setItem("user", JSON.stringify(action.payload));
      return {
        ...state,
        isLoading: false,
        isAuthenticated: true,
        user: action.payload
      };
    case FORGOTPASSWORD_SEND_SUCCESS:
    case FORGOTPASSWORD_SEND_FAIL:
    case RESETPASSWORD_SEND_SUCCESS:
    case RESETPASSWORD_SEND_FAIL:
    case TOKEN_RESET_SUCCESS:
    case TOKEN_RESET_FAIL:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
      };
    case LOGIN_SUCCESS:
    case REGISTER_SUCCESS:
      localStorage.setItem("token", action.payload.token);
      localStorage.setItem("isAuthenticated", true);
      localStorage.setItem("user", JSON.stringify(action.payload.user));
      return {
        ...state,
        ...action.payload,
        isLoading: false,
        isAuthenticated: true
      };
    case AUTH_ERROR:
    case LOGIN_FAIL:
    case LOGOUT_SUCCESS:
    case REGISTER_FAIL:
      localStorage.removeItem("token");
      localStorage.removeItem("isAuthenticated");
      localStorage.removeItem("user");
      return {
        ...state,
        token: null,
        user: null,
        isAuthenticated: false,
        isLoading: false
      };
    case 'LOAD_PRICE_MEDIUM_SITE':
      return {
        ...state,
        priceMedium: action.payload,
      };
    case 'GET_HISTORY_DEPOST_THE':
      return {
        ...state,
        list_history_the: action.payload,
      };
    case 'GET_TOKEN_AVT':
		var datenow = parseInt(Date.now() / 1000);
		var arr_avt = [
			{token_avt: action.payload.token_avt},
			{checktime: datenow}
		];
        localStorage.setItem("token_avt", JSON.stringify(arr_avt));
		var arr_renamefanpage_acc = [
			{renamefanpage_acc: action.payload.renamefanpage_acc},
			{checktime: datenow}
		];
        localStorage.setItem("renamefanpage_acc", JSON.stringify(arr_renamefanpage_acc));
		var arr_cf_thongbaobuffmat = [
			{cf_thongbaobuffmat: action.payload.cf_thongbaobuffmat},
			{checktime: datenow}
		];
        localStorage.setItem("cf_thongbaobuffmat", JSON.stringify(arr_cf_thongbaobuffmat));
		return {
			...state,
			token_avt: arr_avt,
			renamefanpage_acc: arr_renamefanpage_acc,
			config_website: {
				...state.config_website,
				cf_thongbaobuffmat: arr_cf_thongbaobuffmat,
			}
		};
    case 'SET_DATA_SERVICES':
		return {
			...state,
			allservices: action.payload,
		};
    default:
      return state;
  }
}
