import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { addVBLT, updateVBLT, getID, loadPriceVBLT } from '../../../actions/VipcommentthangActions';
import { add, getIdFbVip, updateFbVip } from '../../../actions/FbvipActions';
import Swal from 'sweetalert2';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import parse from 'html-react-parser';
import ServicesDescription from "../ServicesDescription";
import { isMobile } from 'react-device-detect';

const date7dnext = () => {
	var result = new Date();
  	result.setDate(result.getDate() + 7);
  	return result;
}

class taotientrinh extends Component {
	static propTypes =  {
		addVBLT: PropTypes.func.isRequired,
		updateVBLT: PropTypes.func.isRequired,
		getID: PropTypes.func.isRequired,
		loadPriceVBLT: PropTypes.func.isRequired,
		add: PropTypes.func.isRequired,
		getIdFbVip: PropTypes.func.isRequired,
		updateFbVip: PropTypes.func.isRequired,
	};
	
	state = {
		showDescription: isMobile? false : true
	}
	
	/*componentDidMount() {
		this.props.loadPriceVBLT();
	};*/
	
	onChange = (e) => {
		if ((e.target.name === "lhipcv") && (e.target.value.indexOf("facebook.com") > -1)) {
			if (e.target.value !== "") {
				const { taotientrinh } = this.props.Vipcommentthang;
				var new_taotientrinh = {
					...taotientrinh,
					[e.target.name]: e.target.value
				};
				this.props.getIdFbVip('vipcommentthang', new_taotientrinh);
			}
		} else {
			const { taotientrinh } = this.props.Vipcommentthang;
			var new_taotientrinh = {
				...taotientrinh,
				[e.target.name]: e.target.value
			};
		}
		this.props.updateFbVip('vipcommentthang', new_taotientrinh);
	}
	
	option_lnc_dotuoi = () => {
		let ar_option_lnc_dotuoi = [];

		for (let i = 13; i <= 65; i++) {
			ar_option_lnc_dotuoi.push(
				<option value={i} key={i}>
					{i}
				</option>
			)
		}
		return ar_option_lnc_dotuoi;
	}
	
	option_lnc_sobanbe = () => {
		let ar_option_lnc_sobanbe = [];

		for (let i = 100; i <= 5000; i+=100) {
			ar_option_lnc_sobanbe.push(
				<option value={i} key={i}>
					{i}
				</option>
			)
		}
		return ar_option_lnc_sobanbe;
	}
	
	taoTienTrinhfunc = () => {
		const { taotientrinh } = this.props.Vipcommentthang;
		const { id, money, percent } = this.props.auth.user;
		const { lhipcv, slcnnct, slclnct, slbvtmn, sncmv, bltk, lnc_battat, lnc_namnu, lnc_dotuoi_tu, lnc_dotuoi_den, lnc_sobanbe_tu, lnc_sobanbe_den, ndr, gtmtt, gc } = this.props.Vipcommentthang.taotientrinh;
		const { prices_web } = this.props.Vipcommentthang.price;
		
		if (taotientrinh.gtmtt === "") {
			Swal.fire({
				icon: 'error',
				title: 'Lỗi',
				text: 'Chọn giá tiền mỗi tương tác',
			});
			return false;
		}
		if (taotientrinh.gtmtt < prices_web) {
			Swal.fire({
				icon: 'error',
				title: 'Lỗi',
				text: 'Giá tiền mỗi tương tác thấp nhất: '+prices_web+' Coin',
			});
			return false;
		}
		var count_review = 0;
		var review = [];
		var check_reviewlength = 0;
		if (ndr.trim() !== "") {
			var res1 = ndr.trim().match(/[!@$%^&*;':\\/|?~"]/g);
			var res2 = ndr.trim().toLowerCase().match(/dm|đm|đ m|d m|địt mẹ|dit me|lol|lừađảo|conchó|trảtiền|mấtdạy|lừa đảo|con chó|trả tiền|mất dạy|lua dao|con cho|tra tien|mat day/g);
			if (res1 !== null) {
				if (res1.length > 0) {
					Swal.fire({
						icon: 'error',
						title: 'Lỗi',
						html: 'Nội dung Review thứ 1 không được chứa ký tự đặc biệt: <span class="text-danger font-bold">' + res1[0] + '</span>',
					});
					return false;
				}
			}
			if (res2 !== null) {
				if (res2.length > 0) {
					Swal.fire({
						icon: 'error',
						title: 'Lỗi',
						html: 'Nội dung Review thứ 1 không được chứa ký tự đặc biệt: <span class="text-danger font-bold">' + res2[0] + '</span>',
					});
					return false;
				}
			}
			count_review = ndr.trim().split('\n').length;
			
			review = ndr.trim().split('\n');
			// review.forEach(function(v, i){
				// if (v.length < 25) {
					// check_reviewlength = 1;
				// }
			// });
		}
		if (check_reviewlength === 1) {
			Swal.fire({
				icon: 'error',
				title: 'Lỗi',
				html: 'Nội dung của một review tối thiểu phải 25 ký tự',
			});
			return false;
		}
		var additional_fee = 0;
		if (lnc_battat === "1" && lnc_namnu !== "0") {
			additional_fee += 20;
		}
		if (lnc_battat === "1" && lnc_dotuoi_tu !== "") {
			additional_fee += 20;
		}
		if (lnc_battat === "1" && lnc_sobanbe_tu !== "") {
			additional_fee += 20;
		}
		var sl = slclnct;
		// if (count_review > slclnct) {
			// sl = count_review;
		// }
		var price = ((gtmtt * sl * slbvtmn * sncmv)) + (((gtmtt * sl * slbvtmn * sncmv)) * (additional_fee / 100));
		
		if (lhipcv === '') {
			Swal.fire({
				icon: 'error',
				title: 'Lỗi',
				text: 'Link hoặc ID Profile cài VIP không được để trống',
			});
			return false;
		}
		if (count_review < 5) {
			Swal.fire({
				icon: 'error',
				title: 'Lỗi',
				text: 'Nhập ít nhất 5 comment',
			});
			return false;
		}
		if (money < price) {
			Swal.fire({
				icon: 'error',
				title: 'Lỗi',
				text: 'Không đủ tiền trong tài khoản',
			});
			return false;
		}
		if (lnc_dotuoi_tu !== "" && parseInt(lnc_dotuoi_tu) > parseInt(lnc_dotuoi_den)) {
			Swal.fire({
				icon: 'error',
				title: 'Lỗi',
				text: 'Số tuổi bắt đầu phải nhỏ hơn số tuổi kết thúc',
			});
			return false;
		}
		if (lnc_sobanbe_tu !== "" && parseInt(lnc_sobanbe_tu) > parseInt(lnc_sobanbe_den)) {
			Swal.fire({
				icon: 'error',
				title: 'Lỗi',
				text: 'Số bạn bè bắt đầu phải nhỏ hơn số bạn bè kết thúc',
			});
			return false;
		}
		var new_taotientrinh = {
			...taotientrinh,
			"id_user": id
		};
		Swal.fire({
			title: 'Bạn có chắc?',
			html: "Bạn có chắc chắn muốn mua? Khi đã bấm đồng ý đồng nghĩa với việc bạn đã đọc hết mục thông tin dịch vụ!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Đồng ý',
			cancelButtonText: 'Không'
		}).then((result) => {
			if (result.value) {
				this.props.add('vipcommentthang', new_taotientrinh);
			}
		})
	}
	
	onKeyPress = (e) => {
		if (!(e.charCode >= 48 && e.charCode <= 57)) {
			e.preventDefault();
		}
	}
	
	onClickShowDescription = (data) => {
		this.setState({
			showDescription: data
		});
	}
	
	render() {
		const { lhipcv, slcnnct, slclnct, slbvtmn, sncmv, bltk, lnc_battat, lnc_namnu, lnc_dotuoi_tu, lnc_dotuoi_den, lnc_sobanbe_tu, lnc_sobanbe_den, ndr, gtmtt, gc, usernamefb } = this.props.Vipcommentthang.taotientrinh;
		const { prices_web } = this.props.Vipcommentthang.price;
		
		var listDataServicesInfo = {};
		try {
			if (localStorage.getItem("listDataServicesInfo") != null) {
				listDataServicesInfo = JSON.parse(localStorage.getItem("listDataServicesInfo"));
			} else {
				listDataServicesInfo = this.props.adminpage.listDataServicesInfo;
			}
		} catch(e) {
			listDataServicesInfo = {};
		}
		var html_haydoctranhmattien = '';
		var html_haydoctranhmattien_chinh = '';
		var html_haydoctranhmattien_sv = '';
		var html_vip_cmtpro = '';
		if (typeof(listDataServicesInfo.facebook_vip_cmtpro_tab) !== 'undefined') {
			html_haydoctranhmattien = parse(listDataServicesInfo.facebook_vip_cmtpro_tab.ghichu);
			html_haydoctranhmattien_chinh = parse(listDataServicesInfo.facebook_vip_cmtpro_tab.ghichu_chinh);
		}
		if (typeof(listDataServicesInfo.facebook_vip_cmtpro) !== 'undefined') {
			html_vip_cmtpro = parse(listDataServicesInfo.facebook_vip_cmtpro.ghichu_sv);
			if ("1" === "1") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.facebook_vip_cmtpro.ghichu);
			}
		}
		
		var count_review = 0;
		if (ndr.trim() !== "") {
			count_review = ndr.trim().split('\n').length;
		}
		var additional_fee = 0;
		if (lnc_battat === "1" && lnc_namnu !== "0") {
			additional_fee += 20;
		}
		if (lnc_battat === "1" && lnc_dotuoi_tu !== "") {
			additional_fee += 20;
		}
		if (lnc_battat === "1" && lnc_sobanbe_tu !== "") {
			additional_fee += 20;
		}
		var sl = slclnct;
		// if (count_review > slclnct) {
			// sl = count_review;
		// }
		var price = Math.round(((gtmtt * sl * slbvtmn * sncmv) + ((gtmtt * sl * slbvtmn * sncmv) * (additional_fee / 100)) + Number.EPSILON) * 100) / 100;
		
		return (
			<Fragment>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Link hoặc ID Profile cài VIP:</label>
					<div className="col-sm-8">
						<input type="text" className="form-control" id="lhipcv" name="lhipcv" placeholder="" onChange={this.onChange} value={lhipcv} />
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Tên Người Dùng hoặc Ghi Chú:</label>
					<div className="col-sm-8">
						<input type="text" className="form-control" id="usernamefb" name="usernamefb" onChange={this.onChange} value={usernamefb} />
					</div>
				</div>
				<div className="row mt-2">
					<div className="col-md-6">
						<div className="form-group">
							<label className="mt-2" htmlFor="">Số lượng comment nhỏ nhất cần tăng:</label>
                            <input type="number" className="form-control" id="slcnnct" name="slcnnct" placeholder="" onChange={this.onChange} value={slcnnct} onKeyPress={this.onKeyPress} />
                        </div>
					</div>
					<div className="col-md-6">
						<div className="form-group">
							<label className="mt-2" htmlFor="">Số lượng comment lớn nhất cần tăng:</label>
                            <input type="number" className="form-control" id="slclnct" name="slclnct" placeholder="" onChange={this.onChange} value={slclnct} onKeyPress={this.onKeyPress} />
                        </div>
					</div>
				</div>
				<div className="row mt-2">
					<div className="col-md-6">
						<div className="form-group">
							<label className="mt-2" htmlFor="">Số lượng bài viết trong 1 ngày:</label>
                            <input type="number" className="form-control" id="slbvtmn" name="slbvtmn" placeholder="" onChange={this.onChange} value={slbvtmn} onKeyPress={this.onKeyPress} />
                        </div>
					</div>
					<div className="col-md-6">
						<div className="form-group">
							<label className="mt-2" htmlFor="">Số ngày cần mua VIP:</label>
                            {/* <input type="number" className="form-control" id="sncmv" name="sncmv" placeholder="" onChange={this.onChange} value={sncmv} onKeyPress={this.onKeyPress} /> */}
                            <select  onChange={this.onChange} value={sncmv} onKeyPress={this.onKeyPress}  id="sncmv" name="sncmv"  className="form-control">
                            	<option value="7">7</option>
                            	<option value="15">15</option>
                            	<option value="30">30</option>
                            	<option value="60">60</option>
                            	<option value="90">90</option>
								<option value="120">120</option>
								<option value="150">150</option>
								<option value="180">180</option>
                        	</select>
                        </div>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">
						<h6 className="bold mb-0">Bộ lọc từ khóa:</h6>
						<h6 className="font-bold green font-14">Nếu không có từ khóa, hệ thống<br/>sẽ Comment tất cả các bài viết</h6>
					</label>
					<div className="col-sm-8">
						<textarea rows="2" placeholder="Mỗi từ khóa cách nhau một dấu phẩy" className="form-control input-gray" id="bltk" name="bltk" onChange={this.onChange} ></textarea>
						<h6 className="green font-14">Chỉ các bài viết có <em>từ khóa trên đây</em> mới tiến hành bình luận, <b className="font-bold">mỗi từ khóa cách nhau một dấu phẩy</b></h6>
					</div>
				</div>
				{/* <div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Lọc nâng cao:</label>
					<div className="col-sm-8">
						<div className="row">
							<div className="col-md-6 mt-2">
								<button type="button" name="lnc_battat" value="1" className={"btn btn-block btn-rounded  " + (lnc_battat === "1" ? "btn-hero-primary" : "btn-hero-light")} onClick={this.onChange} >Bật</button>
							</div>
							<div className="col-md-6 mt-2">
								<button type="button" name="lnc_battat" value="2" className={"btn btn-block btn-rounded  " + (lnc_battat === "2" ? "btn-hero-primary" : "btn-hero-light")} onClick={this.onChange} >Tắt</button>
							</div>
						</div>
						<h6 className="mb-0 mt-3 font-14" style={ lnc_battat === "1" ? {"display":"block"} : {"display":"none"} }>
							Hệ thống sẽ <span className="bold green">thu phí thêm 20%</span> cho <span className="bold green">1 lựa chọn nâng cao</span>
						</h6>
						<div className="card card-gray mt-2" style={ lnc_battat === "1" ? {"display":"block"} : {"display":"none"} }>
							<div className="card-body">
								<div className="row align-items-center">
									<div className="col-md-4">
										<h6 className="bold mb-0">Giới tính:</h6>
									</div>
									<div className="col-md">
										<div className="row">
											<div className="col-md-4 mt-2">
												<button type="submit" name="lnc_namnu" value="1" className={"btn btn-block btn-rounded  " + (lnc_namnu === "1" ? "btn-hero-primary" : "btn-hero-light")} onClick={this.onChange} >Nam</button>
											</div>
											<div className="col-md-4 mt-2">
												<button type="submit" name="lnc_namnu" value="2" className={"btn btn-block btn-rounded  " + (lnc_namnu === "2" ? "btn-hero-primary" : "btn-hero-light")} onClick={this.onChange} >Nữ</button>
											</div>
											<div className="col-md-4 mt-2">
												<button type="submit" name="lnc_namnu" value="0" className={"btn btn-block btn-rounded  " + (lnc_namnu === "0" ? "btn-hero-primary" : "btn-hero-light")} onClick={this.onChange} >Tất Cả</button>
											</div>
										</div>
									</div>
								</div>
								<div className="row align-items-center mt-3">
								<div className="col-md-4">
									<h6 className="bold mb-0">Độ tuổi:</h6>
								</div>
								<div className="col-md">
									<div className="row align-items-center">
										<div className="col-5">
											<div className="input-group">
												<select className="form-control custom-select select-light" id="lnc_dotuoi_tu" name="lnc_dotuoi_tu" onChange={this.onChange} >
													<option value="">Không lọc độ tuổi</option>
													{this.option_lnc_dotuoi()}
												</select>
											</div>
											</div>
												<div className="col-2">
													<h6 className="bold mb-0">đến</h6>
												</div>
												<div className="col-5">
													<div className="input-group">
														<select className="form-control custom-select select-light" id="lnc_dotuoi_den" name="lnc_dotuoi_den" onChange={this.onChange} disabled={lnc_dotuoi_tu === "" ? 'disabled' : ''} >
															<option value=""></option>
															{this.option_lnc_dotuoi()}
														</select>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="row align-items-center mt-3">
										<div className="col-md-4">
											<h6 className="bold mb-0">Số bạn bè:</h6>
										</div>
										<div className="col-md">
										<div className="row align-items-center">
											<div className="col-5">
												<div className="input-group">
													<select className="form-control custom-select select-light" id="lnc_sobanbe_tu" name="lnc_sobanbe_tu" onChange={this.onChange} >
														<option value="">Không lọc bạn bè</option>
														{this.option_lnc_sobanbe()}
													</select>
												</div>
											</div>
											<div className="col-2">
												<h6 className="bold mb-0">đến</h6>
											</div>
											<div className="col-5">
												<div className="input-group">
													<select className="form-control custom-select select-light" id="lnc_sobanbe_den" name="lnc_sobanbe_den" onChange={this.onChange} disabled={lnc_sobanbe_tu === "" ? 'disabled' : ''} >
														<option value=""></option>
														{this.option_lnc_sobanbe()}
													</select>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div> */}
				<div className="form-group row">
					<div className="col-sm-12">
						<div className="card card-orange">
							<div className="card-body">
								<h4 className="text-center text-danger mt-2 mb-0">Tổng tiền của gói vip sẽ = (Giá tiền order mỗi comment: <b className="font-bold">{gtmtt} coin</b>) x (Số lượng comment lớn nhất mỗi bài) x (Số lượng bài trong ngày) x (Số ngày đăng ký vip) </h4>
							</div>
						</div>
					</div>
				</div>
				<div className="form-group row">
					<div className="col-sm-12">
						<label className="mr-2 mt-3">Nhập nội dung Comment (<label className="font-bold">Mỗi dòng tương đương với 1 Comment</label>):</label>
						<span className="badge badge-primary text-white py-1">Số lượng: {count_review}</span>
						<textarea rows="10" placeholder="Nhập nội dung seeding, mỗi dòng 1 nội dung được tính tiền là 1 lần seeding" className="form-control input-gray" id="ndr" name="ndr" onChange={this.onChange} ></textarea>
					</div>
				</div>
				{/* <div className="form-group row mt-4">
					<label className="col-sm-4 col-form-label" htmlFor="">
						Giá tiền mỗi tương tác:
						<h6 className="font-bold mb-0 font-13">Giá thấp nhất: <span className="bold green">{prices_web} Coin</span></h6>
					</label>
					<div className="col-sm-8">
						<div className="card card-orange mt-2">
							<input type="number" className="form-control" id="gtmtt" name="gtmtt" placeholder="" onChange={this.onChange} value={gtmtt} onKeyPress={this.onKeyPress} />
						</div>
					</div>
				</div> */}
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Ghi chú:</label>
					<div className="col-sm-8">
						<textarea rows="2" placeholder="Nhập nội dung ghi chú về tiến trình của bạn" className="form-control input-gray" id="gc" name="gc" onChange={this.onChange} ></textarea>
					</div>
				</div>
				
				<button type="button" className="btn badge-primary-cus btn-rounded btn-show-description-cus" onClick={(e) => {this.onClickShowDescription(!this.state.showDescription)}}>
					{
						this.state.showDescription?
							<i className="fa fa-angle-up" style={{"marginRight": "5px"}} aria-hidden="true"></i>
						:
							<i className="fa fa-angle-down" style={{"marginRight": "5px"}} aria-hidden="true"></i>
					}
					Thông tin dịch vụ
				</button>
				
				<ServicesDescription allservicesinfo={listDataServicesInfo} server={"1"} tab="facebook_vip_cmtpro" giatien={gtmtt} baohanh={0} showDescription={this.state.showDescription} />
				
				<div className="row mt-2" style={{"display": this.state.showDescription? "flex" : "none"}}>
				    <div className="col-md-12 bold" style={{"marginBottom": "10px"}}>
						<div className="card card-select-cus">
							<div className="card-body card-body-select-cus">
								<p className="mb-0 mt-3 text-wrap">
									<ul>
										<li>1 ID KHÔNG CÀI 2 ĐƠN CÙNG LÚC TRONG HỆ THỐNG ! ĐƠN CŨ XONG MỚI CÀI ĐƠN MỚI ! CỐ TÌNH CÀI BỊ HỤT SỐ LƯỢNG SẼ KHÔNG XỬ LÝ !</li>
										<li>Nếu ID đang chạy trên hệ thống mà bạn vẫn mua id đó cá hệ thống bên khác, nếu có tình trạng hụt số lượng giữa 2 bên thì sẽ không được xử lí!</li>
										{html_haydoctranhmattien}
										{html_haydoctranhmattien_chinh}
										{html_haydoctranhmattien_sv}
										<li>Nếu gặp lỗi hãy nhắn tin hỗ trợ phía bên phải góc màn hình hoặc vào mục liên hệ hỗ trợ để được hỗ trợ tốt nhất!</li>
									</ul>
								</p>
								<span className="badge badge-cus position-absolute-cus badge-top-left-cus badge-primary-cus badge-pill-cus"> HÃY ĐỌC ĐỂ TRÁNH MẤT TIỀN KHI SỬ DỤNG! </span>
							</div>
						</div>
				    </div>
				</div>
				<div className="row mt-4">
					<div className="col-12 text-center bold">
						<div className="card card-info">
							<div className="card-body">
								<h5 className="font-bold">Tổng: <span className="bold green"><span>{price}</span> Coin</span></h5>
								{/* <h6 className="mb-0">Bạn sẽ buff <span className="bold green">{count_review} comment</span> với giá <span className="green bold">{gtmtt}</span> Coin</h6> */}
							</div>
						</div>
					</div>
				</div>
				<div className="row my-4">
					<div className="col-12 text-center bold">
						<button type="button" className="btn btn-block btn-rounded btn-hero-primary" onClick={this.taoTienTrinhfunc} >Tạo tiến trình</button>
					</div>
				</div>
			</Fragment>
		)
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	Vipcommentthang: state.Vipcommentthang,
});
const mapDispatchToProps = { addVBLT, updateVBLT, getID, loadPriceVBLT, add, getIdFbVip, updateFbVip }

export default connect(mapStateToProps, mapDispatchToProps)(taotientrinh);