import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import {
    Modal,
    ModalBody,
    ModalFooter
} from 'reactstrap';

import { saveAdminEditDataUser } from '../../../../actions/adminPageAction';

class EditDataUser extends Component {

    state = {
        formdata: {
            type_user: this.props.adminpage.userEditSelect.type,
            id_user: this.props.adminpage.userEditSelect.id,
            nametype: this.props.adminpage.userEditSelect.name_types,
        }
    }

    changeValue = (e) => {
        let valueupdate = e.target.value;
        this.setState({
            formdata: {
                ...this.state.formdata,
                [e.target.name]: valueupdate,
                nametype: e.target.options[e.target.selectedIndex].text
            }
        });
    }

    render() {
        const {isOpenModalEditUser} = this.props;
        const { userEditSelect,listdatausertype } = this.props.adminpage;
        const {type_user} = this.state.formdata;

        const optionUserType = (
            <Fragment>
                <select onChange={(e) => {this.changeValue(e)}} value={type_user} name="type_user" className="form-control">
                    {
                        listdatausertype.map(function (dataList, index) {
                            return (
                                <option value={dataList.id} key={index}>{dataList.name}</option>
                            );
                        })
                    }
                </select>
            </Fragment>
        )
        return (
            <Fragment>
                <Modal centered={true} size="lg" toggle={(e) => {this.props.openModal()}} isOpen={isOpenModalEditUser}>
                    <div className="modal-header">
                        <p className="mb-0 text-dark">Sửa tài khoản {userEditSelect.username}</p>
                        <button onClick={(e) => {this.props.openModal()}} type="button" className="close">×</button>
                    </div>
                    <ModalBody>

                        <div className="row">

                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Loại tài khoản</label>
                                    { optionUserType }
									<h6 className="text-danger" style={{"marginTop":"5px"}}>Lưu ý: Nếu bạn đổi loại tài khoản của tài khoản admin, tài khoản admin sẽ trở thành tài khoản thường và không thể truy cập được vào các mục admin nữa!</h6>
                                </div>
                            </div>
                        </div>

                    </ModalBody>
                    <ModalFooter>
                        <button type="button" className="btn btn-primary" onClick={(e) => {this.props.saveAdminEditDataUser(this.state.formdata, this.props.openModal)}} >Lưu</button>
                        <button type="button" className="btn btn-danger" onClick={(e) => {this.props.openModal()}} >Huỷ</button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        );
    }

}

const mapStateToProps = state => ({
    adminpage: state.adminpage
});

export default connect(mapStateToProps, { saveAdminEditDataUser }) (EditDataUser);
