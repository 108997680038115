import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { login } from "../../actions/authActions";
import { clearErrors } from "../../actions/errorActions";
import RegisterModal1 from './Register1';
import ServiceModal1 from './Service1';
import ForgotPasswordModal1 from './ForgotPassword1';
import best_service from '../../iconlanding/1/best_service.png';
import girl_and_desk from '../../iconlanding/1/girl_and_desk.png';
import icon_emoji_smile from '../../iconlanding/1/icon_emoji_smile.png';
import icon_facebook_circle from '../../iconlanding/1/icon_facebook_circle.png';
import icon_instagram from '../../iconlanding/1/icon_instagram.png';
import icon_red_circle from '../../iconlanding/1/icon_red_circle.png';
import icon_red_heart from '../../iconlanding/1/icon_red_heart.png';
import icon_twitter from '../../iconlanding/1/icon_twitter.png';
import icon_white_heart from '../../iconlanding/1/icon_white_heart.png';
import icon_white_like from '../../iconlanding/1/icon_white_like.png';
import icon_yellow_circle from '../../iconlanding/1/icon_yellow_circle.png';
import icon_yellow_tri from '../../iconlanding/1/icon_yellow_tri.png';
import person1 from '../../iconlanding/1/person1.jpg';
import person2 from '../../iconlanding/1/person2.png';
import person3 from '../../iconlanding/1/person3.png';
import purple_like from '../../iconlanding/1/purple-like.png';
import tree from '../../iconlanding/1/tree.png';
import bg_footer from '../../iconlanding/1/bg-footer.png';
import wave_background from '../../iconlanding/1/wave_background.svg';
import three_dot_circle from '../../iconlanding/1/three_dot_circle.svg';
import {
  BrowserRouter as Router,
  Link
} from "react-router-dom";
class Login extends Component {
  state = {
	modal: 1,
    msg: "",
    username: "",
    password: "",
    maxacminh: "",
    form_maxacminh: false,
    isLoading: false
  };
  componentDidMount(){
	if (this.props.typeform == 3) {
	  this.setState({ modal: 3 });
	}
  }
  componentDidUpdate(prevProps) {
    const { error } = this.props;
    if (error !== prevProps.error) {
      // Check for login error
      if (error.id === "LOGIN_FAIL") {
        this.setState({ msg: error.msg.msg });
      } else if (error.id === "LOGIN_FAIL_2FA") {
        this.setState({ msg: error.msg.msg, form_maxacminh: true });
      } else {
        this.setState({ msg: null });
      }
    }
  }
	clearAlert = () => {
		// Clear errors
		this.props.clearErrors();
		// this.setState({
			// modal: !this.state.modal
		// });
	}
  changeFormLogRegister = (typeform) => {
	this.props.clearErrors();
	this.setState({
		modal: typeform
	});
	
}
  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };
  onSubmit = e => {
    e.preventDefault();
    const { username, password, maxacminh } = this.state;
    this.props.clearErrors();
    this.props.login({ username, password, maxacminh });
  };
  render() {
    let isLoading = this.props.auth.isLoading;
    var domainSite = window.location.hostname;
	var config_website = {};
	if (localStorage.getItem("confignavbar") != null) {
		config_website = JSON.parse(localStorage.getItem("confignavbar"));
	} else {
		config_website = this.props.auth.config_website;
	}
	const formLogin = (
		<Router>
			<Fragment>
				<style>
					{"\
					.landingcus1 section.core-services:before{\
						background: #d5d2fc00 url("+wave_background+");\
						background-repeat: no-repeat;\
						background-size: cover;\
						background-position: center bottom;\
					}\
					.landingcus1 section.our-services .feature-item .animation-box:before{\
						background: url("+three_dot_circle+") no-repeat center center;\
					}\
					"}
				</style>
				<div className="landingcus1" style={{"zoom": "112%", "backgroundColor": "#ffffff"}}>
					<header className="header fixed-top" id="headerNav">
						<div className="container">
							<nav className="navbar navbar-expand-lg ">
								<a className="text-white font-w700 font-size-h3" href="/" style={{"width": "250px", "wordBreak": "break-all"}} >
								  <span className="text-white text-uppercase">{domainSite.indexOf('tools1s.com') < 0 ? domainSite.slice(0, domainSite.lastIndexOf('.')) : domainSite.slice(0, domainSite.lastIndexOf('.')).replace('vip.', '') }</span>
								  <span className="text-white text-uppercase">{domainSite.indexOf('.') > -1 && domainSite.indexOf('tools1s.com') < 0 ? domainSite.slice(domainSite.lastIndexOf('.')) : ''}</span>
								</a>
								<Link to="/services" onClick={(e) => {this.changeFormLogRegister(3)}} className="link btn-login mobile-dichvu-cus" style={{"paddingRight": "0px"}}>
									Dịch vụ
								</Link>
								<Link to="/" onClick={(e) => {this.changeFormLogRegister(2)}} className="btn btn-pill btn-outline-primary sign-up registercus">
									Đăng ký
								</Link>
								<div className="collapse navbar-collapse" id="navbarSupportedContent">
									<ul className="navbar-nav ml-auto">
									</ul> 
									<div className="nav-item d-md-flex btn-login-signup">
										<Link to="/services" onClick={(e) => {this.changeFormLogRegister(3)}} className="link btn-login">
											Dịch vụ
										</Link>
										<a href="/" className="link btn-login" style={{"paddingLeft": "0px"}}>Đăng nhập</a>
										<Link to="/" onClick={(e) => {this.changeFormLogRegister(2)}} className="btn btn-pill btn-outline-primary sign-up">
											Đăng ký
										</Link>
									</div>
								</div>
							</nav>
						</div>
					</header>
					<section className="section banner" id="home">
						<div className="container">
							<div className="animatation-box-1">
							<img className="animated icon1" src={icon_red_circle}/>
							<img className="animated icon2" src={icon_yellow_tri}/>
							<img className="animated icon3" src={icon_yellow_circle}/>
							</div>
							<div className="row">
								<div className="col-lg-7 col-md-12 col-sm-12 col-xs-12">
									<div className="contents">
										<h2 className="head-title">
											🏆 Thu hút người theo dõi và lượt thích trên tài khoản xã hội của bạn tại một nơi, ngay lập tức 👈
										</h2>
										<p>
											Tiết kiệm thời gian quản lý tài khoản xã hội của bạn trong một bảng điều khiển. Nơi mọi người mua các dịch vụ {domainSite} như quản lý quảng cáo Facebook, Instagram, YouTube, Quảng cáo trang web và nhiều hơn nữa!
										</p>
										<div className="auth-login-form-home">

											<div className="form-login" style={{"position": "relative"}}>
												{isLoading ? (
													<div className="preloader" style={{"top": "-35%"}}>
														<div className="spinner-grow spinner-grow-sm text-primary" role="status" style={{ "marginTop": "40%" }}>
														  <span className="sr-only"></span>
														</div>
														<div className="spinner-grow spinner-grow-sm text-danger" role="status" style={{ "marginTop": "40%" }}>
														  <span className="sr-only">.</span>
														</div>
														<div className="spinner-grow spinner-grow-sm text-warning" role="status" style={{ "marginTop": "40%" }}>
														  <span className="sr-only"></span>
														</div>
													</div>
												):('')}
												{this.state.msg ? (
													<div className="alert alert-warning d-flex align-items-center justify-content-between" role="alert" >
													  <div className="flex-fill mr-3">
														<p className="">{this.state.msg}</p>
													  </div>
													  <div className="flex-00-auto">
														<i className="fa fa-fw fa-exclamation-circle"></i>
													  </div>
													</div>
												  ) : null}
												<form className="js-validation-signup" onSubmit={this.onSubmit} method="POST" >
													<div className="form-group" style={{"marginBottom": "5px"}}>
														<div className="input-icon mb-5">
															<span className="input-icon-addon">
																<i className="fa fa-user"></i>
															</span>
															<input name="username" placeholder="Nhập tài khoản" required="" id="username" type="text" className="is-untouched is-pristine av-valid form-control" onChange={this.onChange} />
														</div>    

														<div className="input-icon mb-5">
															<span className="input-icon-addon">
																<i className="fa fa-key"></i>
															</span>
															<input name="password" placeholder="Nhập mật khẩu" required="" id="password" type="password" className="is-untouched is-pristine av-valid form-control" onChange={this.onChange} />
														</div>  

														{
															this.state.form_maxacminh ?
																<div className="input-icon mb-5">
																	<span className="input-icon-addon">
																		<i className="fa fa-lock"></i>
																	</span>
																	<input name="maxacminh" placeholder="Nhập mã xác minh" required="" id="maxacminh" type="text" className="is-untouched is-pristine av-valid form-control" onChange={this.onChange} />
																</div> 
															: ""
														}
													</div>
													<div className="form-group" style={{"textAlign": "right"}}>
														<Link to="/" onClick={(e) => {this.changeFormLogRegister(4)}} style={{"color": "#1f2324"}}>
															Quên mật khẩu?
														</Link>
													</div>
													<div className="form-footer">
														<button type="submit" className="btn btn-pill btn-2 btn-block btn-submit btn-gradient">Đăng nhập</button>
													</div>
												</form>
												<div className="text-center text-muted m-t-20">
													Bạn chưa có tài khoản?
													<Link to="/" onClick={(e) => {this.changeFormLogRegister(2)}} style={{"marginLeft": "5px"}}>
													  <span>
														Đăng ký
													  </span>
													</Link>
												</div>
											</div>
										</div>
									</div>
								</div>          
								<div className="col-lg-5 col-md-12 col-sm-12 col-xs-12 box-image">
									<div className="animation-2">
										<img className="intro-img" src={girl_and_desk} alt="girl-laptop"/>
										<img className="animated icon-1" src={icon_emoji_smile} alt="Emoji Smile"/>
										<img className="animated icon-2" src={icon_white_like} alt="Like icon"/>
										<img className="animated icon-3" src={icon_red_heart} alt="Red Heart Fill"/>
										<img className="animated icon-4" src={purple_like} alt="Like Icon"/>
										<img className="animated icon-5" src={icon_instagram} alt="Instagram icon"/>
										<img className="animated icon-6" src={icon_facebook_circle} alt="Facebook Icon"/>
										<img className="animated icon-7" src={icon_twitter} alt="Twitter"/>
										<img className="animated icon-10" src={icon_white_heart} alt="White Heart Unfill"/>
										<img className="animated icon-tree" src={tree} alt="tree"/>
									</div>
								</div>
							</div>
						</div>
					</section>
					<section className="core-services"></section>
					<section className="about-area">
						<div className="container">
							<div className="row">
								<div className="col-lg-5 col-md-12 col-sm-12 col-xs-12 aos-init" data-aos="fade-left" data-aos-easing="ease-in" data-aos-delay="200">
									<div className="intro-img">
										<img className="img-fluid" src={best_service} alt=""/>
									</div>
								</div>

								<div className="col-lg-7 col-md-12 col-sm-12 col-xs-12 aos-init" data-aos="fade-right" data-aos-easing="ease-in" data-aos-delay="200">
									<div className="contents">
										<h2 className="head-title">
											💪 Dịch vụ Tiếp thị {domainSite} tốt nhất!</h2>
										<p>
											Chúng tôi cung cấp dịch vụ Bảng điều khiển {domainSite} rẻ nhất trong số các đối thủ cạnh tranh của chúng tôi. Nếu bạn đang tìm kiếm một cách cực kỳ dễ dàng để cung cấp các dịch vụ tiếp thị bổ sung cho khách hàng hiện tại và khách hàng mới của mình, thì không cần tìm đâu xa! trang web của chúng tôi cung cấp điều đó và hơn thế nữa!
											<br/><br/>
											Bạn có thể bán lại các dịch vụ của chúng tôi trong bất kỳ trang web nào hoặc Liên kết trang web của bạn thông qua API và bắt đầu bán lại các dịch vụ của chúng tôi trực tiếp bắt đầu xây dựng mối quan hệ bền chặt hơn và giúp bạn kiếm được lợi nhuận lớn đồng thời. Chúng tôi làm công việc để bạn có thể tập trung vào những gì bạn làm tốt nhất! Khi bạn phát triển, lợi nhuận của bạn tăng lên mà không cần phải thuê thêm người. Điều này cho phép bạn mở rộng hoạt động kinh doanh của mình mà không phải trả mọi chi phí và các vấn đề đau đầu thường liên quan đến việc phát triển lớn hơn!
										</p>
										<div className="head-button">
											<Link to="/" onClick={(e) => {this.changeFormLogRegister(2)}} className="btn btn-pill btn-outline-primary sign-up">
												Bắt đầu ngay
											</Link>
										</div>
									</div>
								</div>          
							</div>
						</div>
					</section>
					<section className="our-services text-center" id="features">
						<div className="container">
							<div className="row">
								<div className="col-md-12 mx-auto aos-init" data-aos="fade-down" data-aos-easing="ease-in" data-aos-delay="200">
									<div className="contents">
										<div className="head-title">
											Tại sao chọn {domainSite}</div>
									</div>
								</div>

								<div className="col-lg-4 aos-init" data-aos="fade-right" data-aos-easing="ease-in" data-aos-delay="400">
									<div className="feature-item">
										<div className="animation-box">
											<i className="fa fa-calendar icon"></i>
										</div>
										<h3>Bán lại</h3>
										<p className="text-muted">Bạn có thể bán lại các dịch vụ của chúng tôi và tăng lợi nhuận của mình một cách dễ dàng, Người bán lại là một phần quan trọng của {domainSite}</p>
									</div>
								</div>

								<div className="col-lg-4 aos-init" data-aos="fade-right" data-aos-easing="ease-in" data-aos-delay="600">
									<div className="feature-item">
										<div className="animation-box">
											<i className="fa fa-phone-alt icon"></i>
										</div>
										<h3>Hỗ trợ</h3>
										<p className="text-muted">Hỗ trợ kỹ thuật cho tất cả các dịch vụ của chúng tôi 24/7 để giúp bạn</p>
									</div>
								</div>

								<div className="col-lg-4 aos-init" data-aos="fade-right" data-aos-easing="ease-in" data-aos-delay="800">
									<div className="feature-item">
										<div className="animation-box">
											<i className="fa fa-star icon"></i>
										</div>
										<h3>Dịch vụ chất lượng cao</h3>
										<p className="text-muted">Nhận các dịch vụ chất lượng cao tốt nhất và trong thời gian ngắn hơn tại đây</p>
									</div>
								</div>
								<div className="col-lg-4 aos-init" data-aos="fade-right" data-aos-easing="ease-in" data-aos-delay="1000">
									<div className="feature-item">
										<div className="animation-box">
											<i className="fa fa-upload icon"></i>
										</div>
										<h3>Cập nhật</h3>
										<p className="text-muted">Các dịch vụ được cập nhật hàng ngày để được cải thiện hơn nữa và mang đến cho bạn trải nghiệm tốt nhất</p>
									</div>
								</div>
								<div className="col-lg-4 aos-init" data-aos="fade-right" data-aos-easing="ease-in" data-aos-delay="1200">
									<div className="feature-item">
										<div className="animation-box">
											<i className="fa fa-share icon"></i>
										</div>
										<h3>Đại lý</h3>
										<p className="text-muted">Chúng tôi có trang web đại lý để bạn có thể bán lại dịch vụ của chúng tôi một cách dễ dàng</p>
									</div>
								</div>
								<div className="col-lg-4 aos-init" data-aos="fade-right" data-aos-easing="ease-in" data-aos-delay="1400">
									<div className="feature-item">
										<div className="animation-box">
											<i className="fa fa-dollar-sign icon"></i>
										</div>
										<h3>Phương thức thanh toán</h3>
										<p className="text-muted">Chúng tôi có một phương pháp Phổ biến là ngân hàng, momo và nhiều phương pháp khác có thể được kích hoạt theo yêu cầu</p>
									</div>
								</div>
							</div>
						</div>
					</section>
					<section className="reviews text-center">
						<div className="container">
							<div className="row aos-init" data-aos="fade-down" data-aos-easing="ease-in" data-aos-delay="200">
								<div className="col-md-12 mx-auto">
									<div className="contents">
										<div className="head-title">
											Mọi người nói gì về chúng tôi
										</div>
										<span className="text-muted">Dịch vụ của chúng tôi có một danh sách khách hàng rộng lớn được xây dựng dựa trên sự tin tưởng đáng giá trong nhiều năm. Đọc những gì người mua của chúng tôi nghĩ về phạm vi dịch vụ của chúng tôi.</span>
										<div className="border-line">
											<hr/>
										</div>
									</div>
								</div>

								<div className="col-md-4">
									<div className="card item" style={{"height": "auto"}}>
										<div className="person-info">
											<img src={person1} style={{"maxWidth": "90px", "borderRadius": "50%", "padding": "0px 0px 10px"}}/>
											<h3 className="name">Ms. DIỆP THANH</h3>
											<span className="text-muted">Bán Hàng Online.</span>
										</div>
										<div className="card-body">
											<p className="desc">
												Nhờ có {domainSite} mà mình có thể tăng like bài viết, người theo dõi fb tiện lợi và nhanh chóng.
											</p>
											<div className="star-icon">
												<span><i className="fa fa-star"></i></span>
												<span><i className="fa fa-star"></i></span>
												<span><i className="fa fa-star"></i></span>
												<span><i className="fa fa-star"></i></span>
												<span><i className="fa fa-star"></i></span>
											</div>
										</div>
									</div>
								</div>

								<div className="col-md-4">
									<div className="card item" style={{"height": "auto"}}>
										<div className="person-info">		
											<img src={person2} style={{"maxWidth": "90px", "borderRadius": "50%", "padding": "0px 0px 10px"}}/>
											<h3 className="name">Mr. NÔNG VĂN ĐỨC</h3>
											<span className="text-muted">Nhân viên bảo vệ.</span>
										</div>
										<div className="card-body">
											<p className="desc">
												Phải nói là dịch vụ mình yêu cầu xử lý rất nhanh chóng, lại rất phù hợp túi tiền ,còn được bảo hành nữa.
											</p>
											<div className="star-icon">
												<span><i className="fa fa-star"></i></span>
												<span><i className="fa fa-star"></i></span>
												<span><i className="fa fa-star"></i></span>
												<span><i className="fa fa-star"></i></span>
												<span><i className="fa fa-star"></i></span>
											</div>
										</div>
									</div>
								</div>          
								<div className="col-md-4">
									<div className="card item" style={{"height": "auto"}}>
										<div className="person-info">		
											<img src={person3} style={{"maxWidth": "90px", "borderRadius": "50%", "padding": "0px 0px 10px"}}/>
											<h3 className="name">Ms. LÊ THỊ HỒNG</h3>
											<span className="text-muted">Sinh viên đại học.</span>
										</div>
										<div className="card-body">
											<p className="desc">
												Sau khi dùng dịch vụ thì tương tác facebook của mình tăng lên, đơn hàng bán ra cũng được nhiều hơn.
											</p>
											<div className="star-icon">
												<span><i className="fa fa-star"></i></span>
												<span><i className="fa fa-star"></i></span>
												<span><i className="fa fa-star"></i></span>
												<span><i className="fa fa-star"></i></span>
												<span><i className="fa fa-star"></i></span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					<div className="footer footer_top dark" style={{"background": "#222d3a url("+bg_footer+")"}}>
						<div className="container m-t-60 m-b-50">
							<div className="row">
								<div className="col-lg-12">
									<div className="site-logo m-b-30">
										<a className="link-fx text-white font-w700 font-size-h3" href="/" >
										  <span className="text-white text-uppercase">{domainSite.slice(0, domainSite.lastIndexOf('.'))}</span>
										  <span className="text-white text-uppercase">{domainSite.indexOf('.') > -1 ? domainSite.slice(domainSite.lastIndexOf('.')) : ''}</span>
										</a>
									</div>
								</div>
								<div className="col-lg-8 m-t-30  mt-lg-0">
									<div className="row">
										<div className="col-6 col-md-3  mt-lg-0">
											<ul className="list-unstyled quick-link mb-0">
												<li><a href="/">Đăng nhập</a></li>
												<li>
													<Link to="/" onClick={(e) => {this.changeFormLogRegister(2)}}>
														Đăng ký
													</Link>
												</li>
											</ul>
										</div>
										<div className="col-6 col-md-3">
										</div>
									</div>
								</div>
								<div className="col-lg-4 m-t-30 mt-lg-0" style={{"textTransform": "uppercase"}}>
									<h4 className="title">Thông tin liên hệ</h4>
									<ul className="list-unstyled">
										<li>Fanpage hỗ trợ: <a href={"https://www.facebook.com/"+config_website.support_id_fanpage} target="_blank"> <i className="fab fa-facebook"></i></a></li>
										<li>Làm việc: 24/7</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
					<footer className="footer footer_bottom dark">
						<div className="container">
							<div className="row align-items-center flex-row-reverse">
								<div className="col-auto ml-lg-auto">
									<div className="row align-items-center">
										<div className="col-auto">
											<ul className="list-inline mb-0">
											</ul>
										</div>
									</div>
								</div>
								<div className="col-12 col-lg-auto mt-3 mt-lg-0 text-center">
									Copyright © 2020
								</div>
							</div>
						</div>
					</footer>
				</div>
			</Fragment>
		</Router>
	);
	var path_check = window.location.pathname;
	if (window.location.pathname.length > 1) {
		path_check = window.location.pathname.replace(/\/+$/g, '');
	}
	const formRegister = (
		<Fragment>
			<RegisterModal1 action_login={(e) => {this.changeFormLogRegister(1)}} action_service={(e) => {this.changeFormLogRegister(3)}} />
		</Fragment>
	);
	const formService = (
		<Fragment>
			<ServiceModal1 action_login={(e) => {this.changeFormLogRegister(1)}} action_register={(e) => {this.changeFormLogRegister(2)}} />
		</Fragment>
	);
	const formForgotPassword = (
		<Fragment>
			<ForgotPasswordModal1 action_login={(e) => {this.changeFormLogRegister(1)}} action_register={(e) => {this.changeFormLogRegister(2)}} action_service={(e) => {this.changeFormLogRegister(3)}} action_reset={path_check} />
		</Fragment>
	);
	return (
		<Fragment>
			{
				path_check === '/resetpassword' ?
					formForgotPassword
				: this.state.modal == 1 ?
					formLogin
				: this.state.modal == 2 ?
					formRegister
				: this.state.modal == 3 ?
					formService
				: this.state.modal == 4 ?
					formForgotPassword
				: ''
			}
		</Fragment>
	);
  }
}
Login.propTypes = {
  auth: PropTypes.object.isRequired,
  error: PropTypes.object,
  login: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  error: state.error
});
export default connect(mapStateToProps, { login, clearErrors })(Login);
