import {
	LOAD_FBBC,
	UPDATE_FBBC_TAB,
	UPDATE_FBBC,
	UPDATE_COOKIE_FBBC,
	LOADING_FBBC,
	LOAD_PRICE_FBBC,
	LOAD_PROXY_FBBC,
	LOAD_PROXY_EDIT_FBBC,
	LIMIT_PAGI_FBBC,
	TYPE_FBBC,
	LOAD_NHATKYHOATDONG_FBBC,
	UPDATE_STICKER,
	LOAD_STICKER,
	UPDATE_LIST_STICKER
} from "../actions/types";

const date7dnext = () => {
	var result = new Date();
  	result.setDate(result.getDate() + 7);
  	return result;
}

const initialState = {
	"taotientrinh": {
		"ctkfcc": "",
		"tgctt_tu": "7",
		"tgctt_den": "22",
		// "tgdctt_tu": "60",
		// "tgdctt_den": "600",
		"lnncx": "1",
		"lnncx_type": ["love"],
		"lnncx_tdmn": "200",
		"blbv": "0",
		"blbv_cmt": "",
		"blbv_tdmn": "100",
		"snmcatt": "30",
		"gc": "",
		"userAgent": "",
		"gtmtt": "0",
		"idfb": "",
		"usernamefb": "",
		"dtsg": "",
		"id_proxy": "0",
		"ttv": "FRIEND",
		"gioitinh": "all",
		"bvtp": "1",
		"listid": "",
		"blacklisttukhoa": "",
		"blacklistid": "",
		"sticker": [],
		"commentanh": "",
		"ca_check": "0",
		"s_check": "0",
		"sticker_pack":{},
		"newapi": "0",
	},
	"changetab": 0,
	"listtientrinh": [],
	"loading": false,
	"price": [],
	"listproxy": [],
	"listproxy_edit": [],
	"limit_pagi": 0,
	"type": 0,
	"list_nhatky":"",
	"list_sticker":[],
};

export default function(state = initialState, action) {
	switch (action.type) {
		case LOAD_FBBC:
			return {
				...state,
				"listtientrinh": action.payload
			};
		case UPDATE_FBBC_TAB:
			return {
				...state,
				"changetab": action.payload
			};
		case UPDATE_FBBC:
			return {
				...state,
				"taotientrinh": action.payload
			};
		case UPDATE_COOKIE_FBBC:
			return {
				...state,
				"taotientrinh": {
					...state.taotientrinh,
					"ctkfcc": action.payload.cookie,
					"userAgent": action.payload.userAgent,
				}
			};
		case LOADING_FBBC:
			return {
				...state,
				"loading": action.payload
			};
		case LOAD_PRICE_FBBC:
			return {
				...state,
				"price": action.payload,
				"taotientrinh": {
					...state.taotientrinh,
					"gtmtt": action.payload.prices_web
				}
			};
		case LOAD_PROXY_FBBC:
			return {
				...state,
				"listproxy": action.payload,
			};
		case LOAD_PROXY_EDIT_FBBC:
			return {
				...state,
				"listproxy_edit": action.payload,
			};
		case LIMIT_PAGI_FBBC:
			return {
				...state,
				"limit_pagi": action.payload
			};
		case TYPE_FBBC:
			return {
				...state,
				"type": action.payload
			};
		case LOAD_NHATKYHOATDONG_FBBC:
			return {
				...state,
				"list_nhatky": action.payload
			};
		case UPDATE_STICKER:
			// return {
				// ...state,
				// "sticker_pack": action.payload
			// };
			return {
				...state,
				"taotientrinh": {
					...state.taotientrinh,
					"sticker_pack": action.payload,
				}
			};
		case LOAD_STICKER:
			return {
				...state,
				"list_sticker": action.payload
			};
		case UPDATE_LIST_STICKER:
			return {
				...state,
				"list_sticker": action.payload
			};
		default:
		  return state;
	}
}
