import React from 'react';
import axios from 'axios';
import {
	LOAD_YV,
	UPDATE_YV,
	UPDATE_YV_TAB,
	LOAD_PRICE_YV,
	LOAD_YC,
	UPDATE_YC,
	UPDATE_YC_TAB,
	LOAD_PRICE_YC,
	LIMIT_PAGI_YC,
	TYPE_YC,
	LIMIT_PAGI_YV,
	TYPE_YV,
	LOADING_YC,
	LOADING_YV,
	UPDATE_YL_TAB,
	LOAD_PRICE_YL,
	UPDATE_YL,
	LOAD_YL,
	TYPE_YL,
	LOADING_YL,
	UPDATE_YDL_TAB,
	LOAD_PRICE_YDL,
	UPDATE_YDL,
	LOAD_YDL,
	TYPE_YDL,
	LOADING_YDL,
	UPDATE_YTNS_TAB,
	LOAD_PRICE_YTNS,
	UPDATE_YTNS,
	LOAD_YTNS,
	TYPE_YTNS,
	LOADING_YTNS,
	UPDATE_YLC_TAB,
	LOAD_PRICE_YLC,
	UPDATE_YLC,
	LOAD_YLC,
	TYPE_YLC,
	LOADING_YLC,
	LOAD_YGX,
	LOADING_YGX,
	UPDATE_YGX,
	UPDATE_YGX_TAB,
	LIMIT_PAGI_YGX,
	TYPE_YGX,
	LOADING_REPORTYOUTUBE,
} from "../actions/types";
import Swal from 'sweetalert2';
import { loadUser } from "./authActions";

export const load = (youtube_type, type = 0, page = 1, obj_search = '') => (dispatch, getState) => {
	dispatch({
		type: LOADING_YC,
		payload: true
	});
	dispatch({
		type: LOADING_YV,
		payload: true
	});
	dispatch({
		type: LOADING_YL,
		payload: true
	});
	dispatch({
		type: LOADING_YDL,
		payload: true
	});
	dispatch({
		type: LOADING_YTNS,
		payload: true
	});
	dispatch({
		type: LOADING_YLC,
		payload: true
	});
	dispatch({
		type: LOADING_YGX,
		payload: true
	});
	dispatch({
		type: 'LOADING_BLOCK_BUFF_SUB_YT',
		payload: true
	});
	axios.get('/api/youtube?youtube_type='+youtube_type+'&type='+type+'&page='+page+'&obj_search='+obj_search, tokenConfig(getState))
		.then(res => {
			dispatch({
				type: LOADING_YC,
				payload: false
			});
			dispatch({
				type: LOADING_YV,
				payload: false
			});
			dispatch({
				type: LOADING_YL,
				payload: false
			});
			dispatch({
				type: LOADING_YDL,
				payload: false
			});
			dispatch({
				type: LOADING_YTNS,
				payload: false
			});
			dispatch({
				type: LOADING_YLC,
				payload: false
			});
			dispatch({
				type: LOADING_YGX,
				payload: false
			});
			dispatch({
				type: 'LOADING_BLOCK_BUFF_SUB_YT',
				payload: false
			});
			if (res.data.status === 200 && youtube_type === 'youtubeview') {
				dispatch({
					type: LOAD_YV,
					payload: res.data.data
				});
			}
			if (res.data.status === 200 && youtube_type === 'youtubelike') {
				dispatch({
					type: LOAD_YL,
					payload: res.data.data
				});
			}
			if (res.data.status === 200 && youtube_type === 'youtubedislike') {
				dispatch({
					type: LOAD_YDL,
					payload: res.data.data
				});
			}
			if (res.data.status === 200 && youtube_type === 'youtubetichnghesi') {
				dispatch({
					type: LOAD_YTNS,
					payload: res.data.data
				});
			}
			if (res.data.status === 200 && youtube_type === 'youtubelikecomment') {
				dispatch({
					type: LOAD_YLC,
					payload: res.data.data
				});
			}
			if (res.data.status === 200 && youtube_type === 'youtubecomment') {
				dispatch({
					type: LOAD_YC,
					payload: res.data.data
				});
			}
			if (res.data.status === 200 && youtube_type === 'youtubegioxem') {
				dispatch({
					type: LOAD_YGX,
					payload: res.data.data
				});
			}
			if (res.data.status === 200 && youtube_type === 'youtube_buffsub') {
				dispatch({
					type: 'LIST_BUFF_SUB_USER_YT',
					payload: res.data.data
				});
			}
		});
};

export const add = (youtube_type, data) => (dispatch, getState) => {
	if (youtube_type === 'youtubeview' || youtube_type === 'youtubecomment' || youtube_type === 'youtubelike' || youtube_type === 'youtubedislike' || youtube_type === 'youtubetichnghesi' || youtube_type === 'youtubelikecomment' || youtube_type === 'youtubegioxem') {
		dispatch({
			type: LOADING_YC,
			payload: true
		});
		dispatch({
			type: LOADING_YV,
			payload: true
		});
		dispatch({
			type: LOADING_YL,
			payload: true
		});
		dispatch({
			type: LOADING_YDL,
			payload: true
		});
		dispatch({
			type: LOADING_YTNS,
			payload: true
		});
		dispatch({
			type: LOADING_YLC,
			payload: true
		});
		dispatch({
			type: LOADING_YGX,
			payload: true
		});
		dispatch({
			type: 'LOADING_BLOCK_BUFF_SUB_YT',
			payload: true
		});
		axios.post('/api/youtube/add?youtube_type='+youtube_type, data, tokenConfig(getState))
			.then(res => {
				dispatch({
					type: LOADING_YC,
					payload: false
				});
				dispatch({
					type: LOADING_YV,
					payload: false
				});
				dispatch({
					type: LOADING_YL,
					payload: false
				});
				dispatch({
					type: LOADING_YDL,
					payload: false
				});
				dispatch({
					type: LOADING_YTNS,
					payload: false
				});
				dispatch({
					type: LOADING_YLC,
					payload: false
				});
				dispatch({
					type: LOADING_YGX,
					payload: false
				});
				dispatch({
					type: 'LOADING_BLOCK_BUFF_SUB_YT',
					payload: false
				});
				dispatch(loadUser());
				if (res.data.status === 200) {
					Swal.fire({
						icon: 'success',
						html: 'Tạo tiến trình seeding thành công, hệ thống sẽ tự động tăng theo thời gian bạn đã chọn <br/><br/> Nếu không đủ lượt seeding hệ thống sẽ tự động hoàn lại số tiền chưa chạy'
					});
				}
				if (res.data.status === 400) {
					var message = '';
					if (res.data.error) {
						if (res.data.error.object_id) {
							message = res.data.error.object_id[0];
						} else if (res.data.error.quantity) {
							message = res.data.error.quantity[0];
						} else {
							message = res.data.message;
						}
					} else {
						message = res.data.message;
					}
					Swal.fire({
						icon: 'error',
						title: 'Lỗi',
						text: message,
					});
				}
			});
	}
	if (youtube_type === 'youtube_buffsub') {

		let bodysend = {
			dataform: data,
			id_user: getState().auth.user.id,
		};

		Swal.fire({
			title: 'Bạn có chắc?',
			text: "Bạn có chắc chắn muốn Order "+data.sltang+" FOLLOW với giá: "+data.giatien+" Coin/1 FOLLOW ? Khi đã bấm đồng ý đồng nghĩa với việc bạn đã đọc hết mục thông tin dịch vụ!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Đồng ý',
			cancelButtonText: 'Không'
		}).then((result) => {
			if (result.value) {
				dispatch({
					type: 'LOADING_BLOCK_BUFF_SUB_YT',
					payload: true
				});
				
				axios.post('/api/youtube/add?youtube_type='+youtube_type, bodysend, tokenConfig(getState))
				.then(res => {
					dispatch(loadUser());
					dispatch({
						type: 'LOADING_BLOCK_BUFF_SUB_YT',
						payload: false
					});
					if (res.data.status === 400) {
						if (Array.isArray(res.data.error.object_id)) {
							openBtComfirmAlert(res.data.error.object_id[0], 'error', 'Lỗi');
						} else {
							openBtComfirmAlert(res.data.message, 'error', 'Lỗi');
						}
					} else {
						openBtComfirmAlert('Thêm thành công', 'success', '');
					}
				});
			}
		});
	}
};

export const cancelOrder = (youtube_type, id_order) => (dispatch, getState) => {
	if (id_order === null) {
		openBtComfirmAlert('Không có order được chọn!', 'error', 'Lỗi');
		return false;
	}

	Swal.fire({
	  	title: 'Bạn có chắc?',
	  	text: "Sau khi hủy bạn sẽ được hoàn lại số lượng chưa tăng sau 1 đến 3 ngày và trừ thêm 5.000 Coin phí để tránh Spam!",
	  	icon: 'warning',
	  	showCancelButton: true,
	  	confirmButtonColor: '#3085d6',
	  	cancelButtonColor: '#d33',
	  	confirmButtonText: 'Đồng ý',
	  	cancelButtonText: 'Không'
	}).then((result) => {
	  	if (result.value) {
	  		let bodysend = {
	  			id_remove: id_order,
	  			id_user: getState().auth.user.id
	  		};
	  		dispatch({
				type: 'LOADING_BLOCK_BUFF_SUB_YT',
				payload: true
			});

	  		axios.post('/api/youtube/removeorder?youtube_type='+youtube_type, bodysend, tokenConfig(getState))
			.then(res => {
				dispatch({
					type: 'LOADING_BLOCK_BUFF_SUB_YT',
					payload: false
				});
				if (res.data.status === 200) {
					Swal.fire(
			      		'Thành công!',
			      		'Hủy đơn thành công',
			      		'success'
			    	)
				} else {
					openBtComfirmAlert(res.data.message, 'error', 'Lỗi');
				}
			});
	  	}
	})
}

export const searchDataYoutube = (youtube_type, params) => (dispatch, getState) => {
	dispatch({
		type: 'LOADING_BLOCK_BUFF_SUB_YT',
		payload: true
	});

	axios.get('/api/youtube_buffsub/filter_data/?youtube_type='+youtube_type+'&object_id='+params, tokenConfig(getState))
	.then(res => {
		dispatch({
			type: 'LOADING_BLOCK_BUFF_SUB_YT',
			payload: false
		});
		if (res.data.status === 200) {
			dispatch({
				type: 'LIST_BUFF_SUB_USER_YT',
				payload: res.data.data
			});
		}
	});
}

export const openBtComfirmAlert = (message, type, title = '') => {
	Swal.fire({
		icon: type,
		title: title,
		text: message,
	});
};

export const changeTabYoutube = (youtube_type, body) => (dispatch, getState) => {
	if (youtube_type === 'youtubeview') {
		dispatch({
			type: UPDATE_YV_TAB,
			payload: body
		});
	}
	if (youtube_type === 'youtubecomment') {
		dispatch({
			type: UPDATE_YC_TAB,
			payload: body
		});
	}
	if (youtube_type === 'youtubelike') {
		dispatch({
			type: UPDATE_YL_TAB,
			payload: body
		});
	}
	if (youtube_type === 'youtubedislike') {
		dispatch({
			type: UPDATE_YDL_TAB,
			payload: body
		});
	}
	if (youtube_type === 'youtubetichnghesi') {
		dispatch({
			type: UPDATE_YTNS_TAB,
			payload: body
		});
	}
	if (youtube_type === 'youtubelikecomment') {
		dispatch({
			type: UPDATE_YLC_TAB,
			payload: body
		});
	}
	if (youtube_type === 'youtubegioxem') {
		dispatch({
			type: UPDATE_YGX_TAB,
			payload: body
		});
	}
};

export const updateYoutube = (youtube_type, body) => (dispatch, getState) => {
	if (youtube_type === 'youtubeview') {
		dispatch({
			type: UPDATE_YV,
			payload: body
		});
	}
	if (youtube_type === 'youtubecomment') {
		dispatch({
			type: UPDATE_YC,
			payload: body
		});
	}
	if (youtube_type === 'youtubelike') {
		dispatch({
			type: UPDATE_YL,
			payload: body
		});
	}
	if (youtube_type === 'youtubedislike') {
		dispatch({
			type: UPDATE_YDL,
			payload: body
		});
	}
	if (youtube_type === 'youtubetichnghesi') {
		dispatch({
			type: UPDATE_YTNS,
			payload: body
		});
	}
	if (youtube_type === 'youtubelikecomment') {
		dispatch({
			type: UPDATE_YLC,
			payload: body
		});
	}
	if (youtube_type === 'youtubegioxem') {
		dispatch({
			type: UPDATE_YGX,
			payload: body
		});
	}
};

export const updateLimit = (fbbot_type, body) => (dispatch) => {
	if (fbbot_type === 'youtubeview') {
		dispatch({
			type: LIMIT_PAGI_YV,
			payload: body
		});
	}
	if (fbbot_type === 'youtubecomment') {
		dispatch({
			type: LIMIT_PAGI_YC,
			payload: body
		});
	}
	if (fbbot_type === 'youtubegioxem') {
		dispatch({
			type: LIMIT_PAGI_YGX,
			payload: body
		});
	}
};

export const updateType = (fbbot_type, body) => (dispatch) => {
	if (fbbot_type === 'youtubeview') {
		dispatch({
			type: TYPE_YV,
			payload: body
		});
	}
	if (fbbot_type === 'youtubecomment') {
		dispatch({
			type: TYPE_YC,
			payload: body
		});
	}
	if (fbbot_type === 'youtubegioxem') {
		dispatch({
			type: TYPE_YGX,
			payload: body
		});
	}
	if (fbbot_type === 'youtubelike') {
		dispatch({
			type: TYPE_YL,
			payload: body
		});
	}
	if (fbbot_type === 'youtubedislike') {
		dispatch({
			type: TYPE_YDL,
			payload: body
		});
	}
	if (fbbot_type === 'youtubelikecomment') {
		dispatch({
			type: TYPE_YLC,
			payload: body
		});
	}
	if (fbbot_type === 'youtubetichnghesi') {
		dispatch({
			type: TYPE_YTNS,
			payload: body
		});
	}
};

export const saveEditOrderAdmin = (nametb, data, closeM) => (dispatch, getState) => {
	let bodysend = {
		formdata: data,
		type_api: nametb
	};
	dispatch({
		type: LOADING_YC,
		payload: true
	});
	dispatch({
		type: LOADING_YV,
		payload: true
	});
	dispatch({
		type: LOADING_YL,
		payload: true
	});
	dispatch({
		type: LOADING_YDL,
		payload: true
	});
	dispatch({
		type: LOADING_YTNS,
		payload: true
	});
	dispatch({
		type: LOADING_YLC,
		payload: true
	});
	dispatch({
		type: LOADING_YGX,
		payload: true
	});
	dispatch({
		type: 'LOADING_BLOCK_BUFF_SUB_YT',
		payload: true
	});
	axios.post('/api/youtube/admin_edit_order/', bodysend, tokenConfig(getState))
	.then(res => {
		dispatch({
			type: LOADING_YC,
			payload: false
		});
		dispatch({
			type: LOADING_YV,
			payload: false
		});
		dispatch({
			type: LOADING_YL,
			payload: false
		});
		dispatch({
			type: LOADING_YDL,
			payload: false
		});
		dispatch({
			type: LOADING_YTNS,
			payload: false
		});
		dispatch({
			type: LOADING_YLC,
			payload: false
		});
		dispatch({
			type: LOADING_YGX,
			payload: false
		});
		dispatch({
			type: 'LOADING_BLOCK_BUFF_SUB_YT',
			payload: false
		});
		if (res.data.status === 200) {
			dispatch(load(nametb));
			Swal.fire(
	      		'Thành công!',
	      		'Sửa thành công',
	      		'success'
	    	);
	    	closeM();
		} else {
			Swal.fire( 'Lỗi', res.data.message, 'error' )
		}
	}).catch(err => {
		Swal.fire( 'Lỗi', 'Có lỗi xảy ra!', 'error' );
    });
}

export const getConfigMoDong = (youtube_type) => (dispatch, getState) => {
	dispatch({
		type: LOADING_YV,
		payload: true
	});
	dispatch({
		type: LOADING_YL,
		payload: true
	});
	dispatch({
		type: LOADING_YDL,
		payload: true
	});
	dispatch({
		type: LOADING_YTNS,
		payload: true
	});
	dispatch({
		type: LOADING_YLC,
		payload: true
	});
	dispatch({
		type: LOADING_YC,
		payload: true
	});
	dispatch({
		type: 'LOADING_BLOCK_BUFF_SUB_YT',
		payload: true
	});
	dispatch({
		type: LOADING_YGX,
		payload: true
	});
	dispatch({
		type: LOADING_REPORTYOUTUBE,
		payload: true
	});

	axios.get('/api/youtube/getconfig?youtube_type='+youtube_type, tokenConfig(getState))
	.then(res => {
		dispatch({
			type: LOADING_YV,
			payload: false
		});
		dispatch({
			type: LOADING_YL,
			payload: false
		});
		dispatch({
			type: LOADING_YDL,
			payload: false
		});
		dispatch({
			type: LOADING_YTNS,
			payload: false
		});
		dispatch({
			type: LOADING_YLC,
			payload: false
		});
		dispatch({
			type: LOADING_YC,
			payload: false
		});
		dispatch({
			type: 'LOADING_BLOCK_BUFF_SUB_YT',
			payload: false
		});
		dispatch({
			type: LOADING_YGX,
			payload: false
		});
		dispatch({
			type: LOADING_REPORTYOUTUBE,
			payload: false
		});
		if (res.data.status === 200) {
			if (youtube_type == 'youtube_buffview') {
				dispatch({
					type: 'CONFIG_MO_DONG_YV',
					payload: res.data
				});
			}
			if (youtube_type == 'youtube_bufflike') {
				dispatch({
					type: 'CONFIG_MO_DONG_YL',
					payload: res.data
				});
			}
			if (youtube_type == 'youtube_buffdislike') {
				dispatch({
					type: 'CONFIG_MO_DONG_YDL',
					payload: res.data
				});
			}
			if (youtube_type == 'youtube_bufftichnghesi') {
				dispatch({
					type: 'CONFIG_MO_DONG_YTNS',
					payload: res.data
				});
			}
			if (youtube_type == 'youtube_bufflikecomment') {
				dispatch({
					type: 'CONFIG_MO_DONG_YLC',
					payload: res.data
				});
			}
			if (youtube_type == 'youtube_buffcmt') {
				dispatch({
					type: 'CONFIG_MO_DONG_YC',
					payload: res.data
				});
			}
			if (youtube_type == 'youtube_buffsub') {
				dispatch({
					type: 'CONFIG_MO_DONG_YOUTUBE_BUFF_SUB',
					payload: res.data
				});
			}
			if (youtube_type == 'youtube_buffgioxem') {
				dispatch({
					type: 'CONFIG_MO_DONG_YGX',
					payload: res.data
				});
			}
			if (youtube_type == 'youtube_danhgay') {
				dispatch({
					type: 'CONFIG_MO_DONG_REPORTYOUTUBE',
					payload: res.data
				});
			}
		}
	});
}

export const tokenConfig = getState => {
	// Get token from localhost
	const token = getState().auth.token;
	// Headers
	const config = {
		headers: {
			"Content-type": "application/json"
		}
	};
	
	// If token, add to headers
	if (token) {
		config.headers['ht-token'] = token;
	}
	
	return config;
};