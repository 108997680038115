import axios from 'axios';
import Swal from 'sweetalert2';
import { loadUser } from "./authActions";

export const GetListData = (viewadmin, page = "", obj_search = '') => (dispatch, getState) => {
	dispatch({
        type: 'LOADING_DATA_FB_CHOCBANBE',
        payload: true
    });
    let param_url = '';

	if (page !== "") {
		param_url += '&page='+page;
	}
	param_url += '&obj_search='+obj_search;
	axios.get('/api/fbchocbanbe/list?viewall='+viewadmin+param_url, tokenConfig(getState))
	.then(res => {
		dispatch({
	        type: 'LOADING_DATA_FB_CHOCBANBE',
	        payload: false
	    });
		if (res.data.status === 200) {
			dispatch({
				type: 'LIST_DATA_FB_CHOCBANBE',
				payload: res.data.data
			});
		}
	});
}

export const searchDataBuffSub = (viewadmin, limit = "", obj_search = "") => (dispatch, getState) => {
	dispatch({
        type: 'LOADING_DATA_FB_CHOCBANBE',
        payload: true
    });
    let param_url = '';

	if (limit !== "") {
		param_url += '&limit='+limit;
	}

	if (obj_search.trim() !== "") {
		param_url += '&obj_search='+obj_search
	}
	axios.get('/api/fbchocbanbe/list?viewall='+viewadmin+param_url, tokenConfig(getState))
	.then(res => {
		dispatch({
	        type: 'LOADING_DATA_FB_CHOCBANBE',
	        payload: false
	    });
		if (res.data.status === 200) {
			dispatch({
				type: 'LIST_DATA_FB_CHOCBANBE',
				payload: res.data.data
			});
		}
	});
}

export const CheckCookie = (cookiefb) => (dispatch, getState) => {
    let checkcookiefb = cookiefb.indexOf('c_user');
    if (checkcookiefb > -1) {
        let cookieRmFirst = cookiefb.slice(checkcookiefb+7);
        let cookieEndToken = cookieRmFirst.indexOf(';');
        const cookie_success = cookieRmFirst.substr(0, cookieEndToken);

        let bodysend = {
            cookie: cookiefb,
        }
        return axios.post('/api/fbbot/checkcookie', bodysend, tokenConfig(getState));
    } else {
    	return false;
    }
}

export const CreateBot = (data, clearform) => (dispatch, getState) => {
	let bodysend = {
		dataform: data,
		id: getState().auth.user.id
	};

	Swal.fire({
	  	title: 'Bạn có chắc?',
	  	text: "Bạn có chắc muốn mua gói này? Khi đã bấm đồng ý đồng nghĩa với việc bạn đã đọc hết mục thông tin dịch vụ!",
	  	icon: 'warning',
	  	showCancelButton: true,
	  	confirmButtonColor: '#3085d6',
	  	cancelButtonColor: '#d33',
	  	confirmButtonText: 'Đồng ý',
	  	cancelButtonText: 'Không'
	}).then((result) => {
	  	if (result.value) {
			dispatch({
				type: 'LOADING_DATA_FB_CHOCBANBE',
				payload: true
			});
			axios.post('/api/fbchocbanbe/createbot/', bodysend, tokenConfig(getState))
			.then(res => {
				dispatch(loadUser());
				dispatch({
					type: 'LOADING_DATA_FB_CHOCBANBE',
					payload: false
				});
				if (res.data.status === 200) {
					clearform();
					Swal.fire({ icon: 'success', title: 'Thành công', text: 'Tạo thành công!' });
				} else {
					Swal.fire({ icon: 'error', title: 'Lỗi', text: res.data.message });
				}
			});
		}
	});
}

export const DeleteItem = (id) => (dispatch, getState) => {
	Swal.fire({
	  	title: 'Bạn có chắc?',
	  	text: "Sau khi hủy bạn sẽ không được hoàn lại tiền!",
	  	icon: 'warning',
	  	showCancelButton: true,
	  	confirmButtonColor: '#3085d6',
	  	cancelButtonColor: '#d33',
	  	confirmButtonText: 'Đồng ý',
	  	cancelButtonText: 'Không'
	}).then((result) => {
	  	if (result.value) {
			dispatch({
				type: 'LOADING_DATA_FB_CHOCBANBE',
				payload: true
			});
			let bodysend = {
				id: id,
				id_user: getState().auth.user.id
			};

			axios.post('/api/fbchocbanbe/delete/', bodysend, tokenConfig(getState))
			.then(res => {
				dispatch({
					type: 'LOADING_DATA_FB_CHOCBANBE',
					payload: false
				});
				if (res.data.status === 200) {
					dispatch(GetListData(0));
					dispatch({
						type: 'DELETE_FB_CHOCBANBE',
						payload: id
					});
					Swal.fire({ icon: 'success', title: 'Thành công', text: 'Hủy thành công!' });
				} else {
					Swal.fire({ icon: 'error', title: 'Lỗi', text: res.data.message });
				}
			});
	  	}
  	});
}

export const UpdateItem = (cookie, idE, uidfb, closeM) => (dispatch, getState) => {
	let bodysend = {
		id: idE,
		id_user: getState().auth.user.id,
		cookie: cookie,
		uidfb: uidfb
	};
	dispatch({
		type: 'LOADING_DATA_FB_CHOCBANBE',
		payload: true
	});
	axios.post('/api/fbchocbanbe/edit/', bodysend, tokenConfig(getState))
	.then(res => {
		dispatch({
			type: 'LOADING_DATA_FB_CHOCBANBE',
			payload: false
		});
		if (res.data.status === 200) {
			closeM();
			dispatch(GetListData(0));
			dispatch({
				type: 'EDIT_FB_CHOCBANBE',
				payload: {id: idE, cookie: cookie}
			});
			Swal.fire({ icon: 'success', title: 'Thành công', text: 'Sửa thành công!' });
		} else {
			Swal.fire({ icon: 'error', title: 'Lỗi', text: res.data.message });
		}
	});
}

export const tokenConfig = getState => {
	// Get token from localhost
	const token = getState().auth.token;
	
	// Headers
	const config = {
		headers: {
			"Content-type": "application/json"
		}
	};
	
	// If token, add to headers
	if (token) {
		config.headers['ht-token'] = token;
	}
	
	return config;
};