import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { login } from "../../actions/authActions";
import { clearErrors } from "../../actions/errorActions";
import RegisterModal2 from './Register2';
import ServiceModal2 from './Service2';
import ForgotPasswordModal2 from './ForgotPassword2';
import person1 from '../../iconlanding/2/person1.jpg';
import person2 from '../../iconlanding/2/person2.png';
import person3 from '../../iconlanding/2/person3.png';
import {
  BrowserRouter as Router,
  Link
} from "react-router-dom";
import { isMobile } from 'react-device-detect';
class Login extends Component {
  state = {
	modal: 1,
    msg: "",
    username: "",
    password: "",
    maxacminh: "",
    form_maxacminh: false,
    isLoading: false
  };
  componentDidMount(){
	if (this.props.typeform == 3) {
	  this.setState({ modal: 3 });
	}
  }
  componentDidUpdate(prevProps) {
    const { error } = this.props;
    if (error !== prevProps.error) {
      // Check for login error
      if (error.id === "LOGIN_FAIL") {
        this.setState({ msg: error.msg.msg });
      } else if (error.id === "LOGIN_FAIL_2FA") {
        this.setState({ msg: error.msg.msg, form_maxacminh: true });
      } else {
        this.setState({ msg: null });
      }
    }
  }
	clearAlert = () => {
		// Clear errors
		this.props.clearErrors();
		// this.setState({
			// modal: !this.state.modal
		// });
	}
  changeFormLogRegister = (typeform) => {
	this.props.clearErrors();
	this.setState({
		modal: typeform
	});
	
}
  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };
  onSubmit = e => {
    e.preventDefault();
    const { username, password, maxacminh } = this.state;
    this.props.clearErrors();
    this.props.login({ username, password, maxacminh });
  };
  render() {
    let isLoading = this.props.auth.isLoading;
    var domainSite = window.location.hostname;
	var config_website = {};
	if (localStorage.getItem("confignavbar") != null) {
		config_website = JSON.parse(localStorage.getItem("confignavbar"));
	} else {
		config_website = this.props.auth.config_website;
	}
	const formLogin = (
		<Router>
			<Fragment>
				<div className="landingcus2" style={{"zoom": "112%"}}>
					<div id="block_1">
						<div className="block-wrapper">
							<div className="component_navbar ">
								<div className="component-navbar__wrapper">
									<div className="sidebar-block__top component-navbar component-navbar__navbar-public sommerce-editor__component-wrapper">
										<div>
											<nav className="navbar navbar-expand-lg navbar-light">
												<div className="sidebar-block__top-brand" style={(isMobile ? {"width": "150px", "wordBreak": "break-all"} : {"wordBreak": "break-all"}) }>
													<div className="component-navbar-brand component-navbar-public-brand">
														<a className="link-fx font-w700 fontSize-h3" href="/" style={{"fontSize": "1.25rem"}} >
														  <span className="text-uppercase">{domainSite.indexOf('tools1s.com') < 0 ? domainSite.slice(0, domainSite.lastIndexOf('.')) : domainSite.slice(0, domainSite.lastIndexOf('.')).replace('vip.', '') }</span>
														  <span className="text-uppercase">{domainSite.indexOf('.') > -1 && domainSite.indexOf('tools1s.com') < 0 ? domainSite.slice(domainSite.lastIndexOf('.')) : ''}</span>
														</a>
													</div>
												</div>
												<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar-collapse-1" aria-controls="navbar-collapse-1" aria-expanded="false" aria-label="Toggle navigation" style={{"padding": "0"}}>
													<Link to="/services" onClick={(e) => {this.changeFormLogRegister(3)}} className="component-navbar-nav-link component-navbar-nav-link__navbar-public ">
														<i className="navbar-icon fa fa-list"> </i>  Dịch vụ
													</Link>
												</button>
												<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar-collapse-1" aria-controls="navbar-collapse-1" aria-expanded="false" aria-label="Toggle navigation" style={{"padding": "0"}}>
													<Link to="/" onClick={(e) => {this.changeFormLogRegister(2)}} className="component-navbar-nav-link component-navbar-nav-link__navbar-public ">
														<i className="navbar-icon far fa-edit"></i> Đăng ký
													</Link>
												</button>
												<div className="collapse navbar-collapse" id="navbar-collapse-1">
													<div className="component-navbar-collapse-divider"></div>
													<div className="d-flex component-navbar-collapse">
														<ul className="navbar-nav">
															<li className="nav-item component-navbar-nav-item">
																<Link to="/services" onClick={(e) => {this.changeFormLogRegister(3)}} className="component-navbar-nav-link component-navbar-nav-link__navbar-public ">
																	<i className="navbar-icon fa fa-list"> </i>  Dịch vụ
																</Link>
															</li>
															<li className="nav-item component-navbar-nav-item">
																<a href="/" className="component-navbar-nav-link component-navbar-nav-link__navbar-public component-navbar-nav-link-active__navbar-public"><i className="navbar-icon fab fa-gratipay"></i> Đăng nhập</a>
															</li>
															<li className="nav-item component-navbar-nav-item">
																<Link to="/" onClick={(e) => {this.changeFormLogRegister(2)}} className="component-navbar-nav-link component-navbar-nav-link__navbar-public ">
																	<i className="navbar-icon far fa-edit"></i> Đăng ký
																</Link>
															</li>
															{/*<li className="nav-item component-navbar-nav-item">
																<a className="component-navbar-nav-link component-navbar-nav-link__navbar-public " href="/terms"><i className="navbar-icon fas fa-file-signature"></i> Terms</a>
															</li>
															<li className="nav-item component-navbar-nav-item">
																<a className="component-navbar-nav-link component-navbar-nav-link__navbar-public " href="/services"><i className="navbar-icon fas fa-feather-alt"></i> Services</a>
															</li>
															<li className="nav-item component-navbar-nav-item">
																<a className="component-navbar-nav-link component-navbar-nav-link__navbar-public " href="/api"><i className="navbar-icon fas fa-chalkboard-teacher"></i> API</a>
															</li>*/}
														</ul>
													</div>
												</div>
											</nav>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="component_navbar"></div>
						<div className="component_navbar_sub"></div>
					</div>
					<div className="wrapper-content">
						<div className="wrapper-content__header">
						</div>
						<div className="wrapper-content__body">
							<div id="block_2">
								<div className="block-signin-text ">
									<div className="bg"></div>
									<div className="divider-top"></div>
									<div className="divider-bottom"></div>
									<div className="container">
										<div className="row">
											<div className="col-lg-7">
											<div className="block-signin-text__block-text">
											<div className="block-signin-text__block-text-title"><h1><span style={{"color": "rgba(240, 103, 10, 1)", "textTransform": "uppercase"}}>{domainSite}</span></h1>
											<h1><span style={{"color": "rgba(240, 103, 10, 1)"}}>tốt nhất với giá cả tuyệt vời</span></h1></div>
											<div className="block-signin-text__block-text-description"><p>Một lựa chọn tuyệt vời của các dịch vụ {domainSite} khác nhau để đáp ứng mọi nhu cầu của bạn.</p></div>
											</div>
											</div>
											<div className="col-lg-5">
												<div className="component_card">
													<div className="card">
														{isLoading ? (
															<div className="preloader">
																<div className="spinner-grow spinner-grow-sm text-primary" role="status" style={{ "marginTop": "40%" }}>
																  <span className="sr-only"></span>
																</div>
																<div className="spinner-grow spinner-grow-sm text-danger" role="status" style={{ "marginTop": "40%" }}>
																  <span className="sr-only">.</span>
																</div>
																<div className="spinner-grow spinner-grow-sm text-warning" role="status" style={{ "marginTop": "40%" }}>
																  <span className="sr-only"></span>
																</div>
															</div>
														):('')}
														{this.state.msg ? (
															<div className="alert alert-warning d-flex align-items-center justify-content-between" role="alert" >
															  <div className="flex-fill mr-3">
																<p className="mb-0">{this.state.msg}</p>
															  </div>
															  <div className="flex-00-auto">
																<i className="fa fa-fw fa-exclamation-circle"></i>
															  </div>
															</div>
														  ) : null}
														<form className="js-validation-signup" onSubmit={this.onSubmit} method="POST" >
															<div className="">
																<div className="form-group">
																	<label htmlFor="username" className="">Tên tài khoản</label>
																	<input name="username" placeholder="" required="" id="username" type="text" className="is-untouched is-pristine av-valid form-control" onChange={this.onChange} />
																</div>
																<div className="form-group" style={{"marginBottom": "5px"}}>
																	<label htmlFor="password" className="">Mật khẩu</label>
																	<input name="password" placeholder="" required="" id="password" type="password" className="is-untouched is-pristine av-valid form-control" onChange={this.onChange} />
																</div>
																{
																	this.state.form_maxacminh ?
																		<div className="form-group">
																			<label htmlFor="maxacminh" className="">Mã xác minh</label>
																			<input name="maxacminh" placeholder="" required="" id="maxacminh" type="text" className="is-untouched is-pristine av-valid form-control" onChange={this.onChange} />
																		</div>
																	: ""
																}
																<div className="form-group" style={{"textAlign": "right"}}>
																	<Link to="/" onClick={(e) => {this.changeFormLogRegister(4)}} style={{"color": "#1f2324"}}>
																		Quên mật khẩu?
																	</Link>
																</div>
																<br/>
																<div className="form-group">
																	<div className="component_button_submit">
																		<div className="">
																			<button type="submit" className="btn btn-block btn-big-primary">
																				<span>
																					Đăng nhập
																				</span>
																			</button>
																		</div>
																	</div>
																</div>
															</div>
															<input type="hidden" name="_csrf" value="8lIN0VrS_G0n5ALuzceZRiuQL7n9KlYoQ9mfon6-SiW1BmOgI7TILBa-Oou1qdAwQ-BO1JlFEEIuv_zQSPZnEA=="/>
															<div className="text-center d-flex justify-content-center">
																<div>Bạn chưa có tài khoản?</div>
																<Link to="/" onClick={(e) => {this.changeFormLogRegister(2)}} className="block-signin-text__sign-up-link">
																	<span>
																		Đăng ký
																	</span>
																</Link>
															</div>
														</form>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div id="block_3">
								<div className="header-with-text ">
									<div className="bg"></div>
									<div className="divider-top"></div>
									<div className="divider-bottom"></div>
									<div className="container">
										<div className="row">
											<div className="col-12">
												<div className="text-block__title">
													<h2 className="text-center">Những lý do tại sao bạn nên thử dịch vụ {domainSite} của chúng tôi</h2>
												</div>
											</div>
										</div>
										<div className="row">
											<div className="col-12">
												<div className="text-block__description">
													<p className="text-center">Tìm hiểu lý do tại sao sử dụng trang web của chúng tôi là tốt nhất &amp; cách rẻ nhất để trở nên phổ biến trực tuyến.</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div id="block_4">
								<div className="features-block-icons ">
									<div className="bg"></div>
									<div className="divider-top"></div>
									<div className="divider-bottom"></div>
									<div className="container">
										<div className="row align-items-start justify-content-start">
											<div className="col-lg-3 col-md-6 mb-4">
												<div className="card features-block__card h-100">
													<div className="features-block__header">
														<div className="features-block__header-preview style-bg-primary-alpha-10 style-border-radius-default style-text-primary" style={{"marginBottom": "10px", "height": "96px"}}>
															<span className="feature-block__icon fal fa-trophy-alt" style={{"fontSize": "55px", "transform": "rotate(0deg)", "textShadow": "none", "borderRadius": "0px", "background": "transparent", "padding": "10px"}}></span>
														</div>
														<div className="features-block__header-title" style={{"marginBottom": "8px", "paddingLeft": "0px", "paddingRight": "0px"}}>
															<p><strong style={{"fontWeight": "bold"}}>Dịch vụ chất lượng hàng đầu</strong></p>
														</div>
													</div>
													<div className="features-block__body">
														<div className="features-block__body-description" style={{"paddingLeft": "0px", "paddingRight": "0px"}}>
															<p>Dịch vụ {domainSite} tuyệt vời để đáp ứng nhu cầu của khách hàng.</p>
														</div>
													</div>
												</div>
											</div>
											<div className="col-lg-3 col-md-6 mb-4">
												<div className="card features-block__card h-100">
													<div className="features-block__header">
														<div className="features-block__header-preview style-bg-primary-alpha-10 style-border-radius-default style-text-primary" style={{"marginBottom": "10px", "height": "96px"}}>
															<span className="feature-block__icon fal fa-donate" style={{"fontSize": "55px", "transform": "rotate(0deg)", "textShadow": "none", "borderRadius": "0px", "background": "transparent", "padding": "10px"}}></span>
														</div>
														<div className="features-block__header-title" style={{"marginBottom": "8px", "paddingLeft": "0px", "paddingRight": "0px"}}>
															<p><strong style={{"fontWeight": "bold"}}>Phương thức thanh toán đa dạng</strong></p>
														</div>
													</div>
													<div className="features-block__body">
														<div className="features-block__body-description" style={{"paddingLeft": "0px", "paddingRight": "0px"}}>
															<p>Chọn một tùy chọn thanh toán bạn muốn để thêm tiền.</p>
														</div>
													</div>
												</div>
											</div>
											<div className="col-lg-3 col-md-6 mb-4">
												<div className="card features-block__card h-100">
													<div className="features-block__header">
														<div className="features-block__header-preview style-bg-primary-alpha-10 style-border-radius-default style-text-primary" style={{"marginBottom": "10px", "height": "96px"}}>
															<span className="feature-block__icon fal fa-piggy-bank" style={{"fontSize": "55px", "transform": "rotate(0deg)", "textShadow": "none", "borderRadius": "0px", "background": "transparent", "padding": "10px"}}></span>
														</div>
														<div className="features-block__header-title" style={{"marginBottom": "8px", "paddingLeft": "0px", "paddingRight": "0px"}}>
															<p><strong style={{"fontWeight": "bold"}}>Giá tuyệt vời</strong></p>
														</div>
													</div>
													<div className="features-block__body">
														<div className="features-block__body-description" style={{"paddingLeft": "0px", "paddingRight": "0px"}}>
															<p>Dịch vụ {domainSite} của chúng tôi rẻ đến khó tin.</p>
														</div>
													</div>
												</div>
											</div>
											<div className="col-lg-3 col-md-6 mb-4">
												<div className="card features-block__card h-100">
													<div className="features-block__header">
														<div className="features-block__header-preview style-bg-primary-alpha-10 style-border-radius-default style-text-primary" style={{"marginBottom": "10px", "height": "96px"}}>
															<span className="feature-block__icon fal fa-rocket-launch" style={{"fontSize": "55px", "transform": "rotate(0deg)", "textShadow": "none", "borderRadius": "0px", "background": "transparent", "padding": "10px"}}></span>
														</div>
														<div className="features-block__header-title" style={{"marginBottom": "8px", "paddingLeft": "0px", "paddingRight": "0px"}}>
															<p><strong style={{"fontWeight": "bold"}}>Tốc độ xử lý nhanh</strong></p>
														</div>
													</div>
													<div className="features-block__body">
														<div className="features-block__body-description" style={{"paddingLeft": "0px", "paddingRight": "0px"}}>
															<p>Đơn đặt hàng của khách hàng của chúng tôi được xử lý rất nhanh.</p>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div id="block_5">
								<div className="header-with-text ">
									<div className="bg"></div>
									<div className="divider-top"></div>
									<div className="divider-bottom"></div>
									<div className="container">
										<div className="row">
											<div className="col-12">
												<div className="text-block__title">
													<h2 className="text-center"><span style={{"textAlign": "center"}}>Nơi để bắt đầu?</span></h2>
												</div>
											</div>
										</div>
										<div className="row">
											<div className="col-12">
												<div className="text-block__description">
													<p className="text-center"><span style={{"textAlign": "center"}}>Làm theo 4 bước đơn giản sau để bắt đầu sử dụng dịch vụ {domainSite} của chúng tôi.</span></p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div id="block_6">
								<div className="how-it-works ">
									<div className="bg"></div>
									<div className="divider-top"></div>
									<div className="divider-bottom"></div>
									<div className="container">
										<div className="row how-it-works-row justify-content-start">
											<div className="col-md-3 how-it-works-col">
												<div className="how-it-works-card">
													<div className="how-it-works-arrow-top style-svg-g-primary">
														<svg width="125px" height="31px" viewBox="0 0 125 31" version="1.1" xmlns="http://www.w3.org/2000/svg"><g id="Landing" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
															<g transform="translate(-942.000000, -1387.000000)" fill="#1E79E4" id="Group-10">
															<g transform="translate(165.000000, 1368.000000)">
															<path d="M889.516523,26.5080119 L891.910644,20.9496585 L902,32.9164837 L886.372927,33.807873 L888.723185,28.3469617 C871.347087,21.9210849 854.507984,19.7125409 838.195168,21.7129851 C818.169006,24.1687976 798.907256,32.9719131 780.398868,48.1424468 L779.638673,48.7694781 L778.869195,49.4081513 L777.591849,47.8691952 L778.361327,47.2305219 C797.38492,31.4407805 817.252224,22.2662407 837.951732,19.7278557 C854.622929,17.6834632 871.814783,19.9463129 889.516523,26.5080119 Z" id="Line3"></path>
															</g>
															</g>
															</g>
														</svg>
													</div>
													<div className="how-it-works-arrow-bottom style-svg-g-primary">
														<svg width="125px" height="31px" viewBox="0 0 125 31" version="1.1" xmlns="http://www.w3.org/2000/svg"><g id="Landing" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
															<g transform="translate(-657.000000, -1461.000000)" fill="#1E79E4" id="Group-10">
															<g transform="translate(165.000000, 1368.000000)">
															<path d="M493.869195,93.5918487 L494.638673,94.2305219 C513.37968,109.785715 532.894675,118.797561 553.195168,121.287015 C569.507984,123.287459 586.347087,121.078915 603.723185,114.653038 L601.372927,109.192127 L617,110.083516 L606.910644,122.050341 L604.516523,116.491988 C586.814783,123.053687 569.622929,125.316537 552.951732,123.272144 C532.528218,120.767604 512.914862,111.802694 494.12272,96.3975396 L493.361327,95.7694781 L492.591849,95.1308048 L493.869195,93.5918487 Z" id="Line2"></path>
															</g>
															</g>
															</g>
														</svg>
													</div>
													<div className="d-flex justify-content-center how-it-works-preview" style={{"marginBottom": "12px"}}>
														<div className="how-it-works-number style-box-shadow-default style-bg-color-light style-border-radius-50" style={{"fontSize": "32px", "width": "95px", "height": "95px"}}>
															1
														</div>
													</div>
													<div className="how-it-works-title" style={{"marginBottom": "8px", "paddingLeft": "0px", "paddingRight": "0px"}}>
														<p className="text-center"><span style={{"textAlign": "center"}}><strong style={{"fontWeight": "bold"}}>1. Đăng ký và đăng nhập</strong></span></p>
													</div>
													<div className="how-it-works-description" style={{"paddingLeft": "0px", "paddingRight": "0px"}}>
														<p className="text-center"><span style={{"textAlign": "center"}}>Trước hết, hãy tạo một tài khoản và đăng nhập.</span></p>
													</div>
												</div>
											</div>
											<div className="col-md-3 how-it-works-col">
												<div className="how-it-works-card">
													<div className="how-it-works-arrow-top style-svg-g-primary">
														<svg width="125px" height="31px" viewBox="0 0 125 31" version="1.1" xmlns="http://www.w3.org/2000/svg"><g id="Landing" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
															<g transform="translate(-942.000000, -1387.000000)" fill="#1E79E4" id="Group-10">
															<g transform="translate(165.000000, 1368.000000)">
															<path d="M889.516523,26.5080119 L891.910644,20.9496585 L902,32.9164837 L886.372927,33.807873 L888.723185,28.3469617 C871.347087,21.9210849 854.507984,19.7125409 838.195168,21.7129851 C818.169006,24.1687976 798.907256,32.9719131 780.398868,48.1424468 L779.638673,48.7694781 L778.869195,49.4081513 L777.591849,47.8691952 L778.361327,47.2305219 C797.38492,31.4407805 817.252224,22.2662407 837.951732,19.7278557 C854.622929,17.6834632 871.814783,19.9463129 889.516523,26.5080119 Z" id="Line3"></path>
															</g>
															</g>
															</g>
														</svg>
													</div>
													<div className="how-it-works-arrow-bottom style-svg-g-primary">
														<svg width="125px" height="31px" viewBox="0 0 125 31" version="1.1" xmlns="http://www.w3.org/2000/svg"><g id="Landing" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
															<g transform="translate(-657.000000, -1461.000000)" fill="#1E79E4" id="Group-10">
															<g transform="translate(165.000000, 1368.000000)">
															<path d="M493.869195,93.5918487 L494.638673,94.2305219 C513.37968,109.785715 532.894675,118.797561 553.195168,121.287015 C569.507984,123.287459 586.347087,121.078915 603.723185,114.653038 L601.372927,109.192127 L617,110.083516 L606.910644,122.050341 L604.516523,116.491988 C586.814783,123.053687 569.622929,125.316537 552.951732,123.272144 C532.528218,120.767604 512.914862,111.802694 494.12272,96.3975396 L493.361327,95.7694781 L492.591849,95.1308048 L493.869195,93.5918487 Z" id="Line2"></path>
															</g>
															</g>
															</g>
														</svg>
													</div>
													<div className="d-flex justify-content-center how-it-works-preview" style={{"marginBottom": "12px"}}>
														<div className="how-it-works-number style-box-shadow-default style-bg-color-light style-border-radius-50" style={{"fontSize": "32px", "width": "95px", "height": "95px"}}>
															2
														</div>
													</div>
													<div className="how-it-works-title" style={{"marginBottom": "8px", "paddingLeft": "0px", "paddingRight": "0px"}}>
														<p className="text-center"><span style={{"textAlign": "center"}}><strong style={{"fontWeight": "bold"}}>2. Nạp tiền</strong></span></p>
													</div>
													<div className="how-it-works-description" style={{"paddingLeft": "0px", "paddingRight": "0px"}}>
														<p className="text-center"><span style={{"textAlign": "center"}}>Thêm tiền vào tài khoản của bạn thông qua phương thức thanh toán bạn chọn.</span></p>
													</div>
												</div>
											</div>
											<div className="col-md-3 how-it-works-col">
												<div className="how-it-works-card">
													<div className="how-it-works-arrow-top style-svg-g-primary">
														<svg width="125px" height="31px" viewBox="0 0 125 31" version="1.1" xmlns="http://www.w3.org/2000/svg"><g id="Landing" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
															<g transform="translate(-942.000000, -1387.000000)" fill="#1E79E4" id="Group-10">
															<g transform="translate(165.000000, 1368.000000)">
															<path d="M889.516523,26.5080119 L891.910644,20.9496585 L902,32.9164837 L886.372927,33.807873 L888.723185,28.3469617 C871.347087,21.9210849 854.507984,19.7125409 838.195168,21.7129851 C818.169006,24.1687976 798.907256,32.9719131 780.398868,48.1424468 L779.638673,48.7694781 L778.869195,49.4081513 L777.591849,47.8691952 L778.361327,47.2305219 C797.38492,31.4407805 817.252224,22.2662407 837.951732,19.7278557 C854.622929,17.6834632 871.814783,19.9463129 889.516523,26.5080119 Z" id="Line3"></path>
															</g>
															</g>
															</g>
														</svg>
													</div>
													<div className="how-it-works-arrow-bottom style-svg-g-primary">
														<svg width="125px" height="31px" viewBox="0 0 125 31" version="1.1" xmlns="http://www.w3.org/2000/svg"><g id="Landing" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
															<g transform="translate(-657.000000, -1461.000000)" fill="#1E79E4" id="Group-10">
															<g transform="translate(165.000000, 1368.000000)">
															<path d="M493.869195,93.5918487 L494.638673,94.2305219 C513.37968,109.785715 532.894675,118.797561 553.195168,121.287015 C569.507984,123.287459 586.347087,121.078915 603.723185,114.653038 L601.372927,109.192127 L617,110.083516 L606.910644,122.050341 L604.516523,116.491988 C586.814783,123.053687 569.622929,125.316537 552.951732,123.272144 C532.528218,120.767604 512.914862,111.802694 494.12272,96.3975396 L493.361327,95.7694781 L492.591849,95.1308048 L493.869195,93.5918487 Z" id="Line2"></path>
															</g>
															</g>
															</g>
														</svg>
													</div>
													<div className="d-flex justify-content-center how-it-works-preview" style={{"marginBottom": "12px"}}>
														<div className="how-it-works-number style-box-shadow-default style-bg-color-light style-border-radius-50" style={{"fontSize": "32px", "width": "95px", "height": "95px"}}>
															3
														</div>
													</div>
													<div className="how-it-works-title" style={{"marginBottom": "8px", "paddingLeft": "0px", "paddingRight": "0px"}}>
														<p className="text-center"><span style={{"textAlign": "center"}}><strong style={{"fontWeight": "bold"}}>3. Chọn dịch vụ</strong></span></p>
													</div>
													<div className="how-it-works-description" style={{"paddingLeft": "0px", "paddingRight": "0px"}}>
														<p className="text-center"><span style={{"textAlign": "center"}}>Chọn các dịch vụ bạn muốn.</span></p>
													</div>
												</div>
											</div>
											<div className="col-md-3 how-it-works-col">
												<div className="how-it-works-card">
													<div className="how-it-works-arrow-top style-svg-g-primary">
														<svg width="125px" height="31px" viewBox="0 0 125 31" version="1.1" xmlns="http://www.w3.org/2000/svg"><g id="Landing" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
															<g transform="translate(-942.000000, -1387.000000)" fill="#1E79E4" id="Group-10">
															<g transform="translate(165.000000, 1368.000000)">
															<path d="M889.516523,26.5080119 L891.910644,20.9496585 L902,32.9164837 L886.372927,33.807873 L888.723185,28.3469617 C871.347087,21.9210849 854.507984,19.7125409 838.195168,21.7129851 C818.169006,24.1687976 798.907256,32.9719131 780.398868,48.1424468 L779.638673,48.7694781 L778.869195,49.4081513 L777.591849,47.8691952 L778.361327,47.2305219 C797.38492,31.4407805 817.252224,22.2662407 837.951732,19.7278557 C854.622929,17.6834632 871.814783,19.9463129 889.516523,26.5080119 Z" id="Line3"></path>
															</g>
															</g>
															</g>
														</svg>
													</div>
													<div className="how-it-works-arrow-bottom style-svg-g-primary">
														<svg width="125px" height="31px" viewBox="0 0 125 31" version="1.1" xmlns="http://www.w3.org/2000/svg"><g id="Landing" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
															<g transform="translate(-657.000000, -1461.000000)" fill="#1E79E4" id="Group-10">
															<g transform="translate(165.000000, 1368.000000)">
															<path d="M493.869195,93.5918487 L494.638673,94.2305219 C513.37968,109.785715 532.894675,118.797561 553.195168,121.287015 C569.507984,123.287459 586.347087,121.078915 603.723185,114.653038 L601.372927,109.192127 L617,110.083516 L606.910644,122.050341 L604.516523,116.491988 C586.814783,123.053687 569.622929,125.316537 552.951732,123.272144 C532.528218,120.767604 512.914862,111.802694 494.12272,96.3975396 L493.361327,95.7694781 L492.591849,95.1308048 L493.869195,93.5918487 Z" id="Line2"></path>
															</g>
															</g>
															</g>
														</svg>
													</div>
													<div className="d-flex justify-content-center how-it-works-preview" style={{"marginBottom": "12px"}}>
														<div className="how-it-works-number style-box-shadow-default style-bg-color-light style-border-radius-50" style={{"fontSize": "32px", "width": "95px", "height": "95px"}}>
															4
														</div>
													</div>
													<div className="how-it-works-title" style={{"marginBottom": "8px", "paddingLeft": "0px", "paddingRight": "0px"}}>
														<p className="text-center"><span style={{"textAlign": "center"}}><strong style={{"fontWeight": "bold"}}>4. Kết quả tuyệt vời</strong></span></p>
													</div>
													<div className="how-it-works-description" style={{"paddingLeft": "0px", "paddingRight": "0px"}}>
														<p className="text-center"><span style={{"textAlign": "center"}}>Khi đơn đặt hàng của bạn đã sẵn sàng, bạn sẽ rất ấn tượng với kết quả.</span></p>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div id="block_7">
								<div className="header-with-text ">
									<div className="bg"></div>
									<div className="divider-top"></div>
									<div className="divider-bottom"></div>
									<div className="container">
										<div className="row">
											<div className="col-12">
												<div className="text-block__title">
													<h2 className="text-center"><span style={{"textAlign": "center"}}>Khách hàng của chúng tôi</span></h2>
												</div>
											</div>
										</div>
										<div className="row">
											<div className="col-12">
												<div className="text-block__description">
													<p className="text-center"><span style={{"textAlign": "center"}}>Tìm hiểu cách bạn có thể hưởng lợi từ việc sử dụng bảng điều khiển của chúng tôi bằng cách xem một số đánh giá của khách hàng.</span></p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div id="block_8">
								<div className="reviews-slider ">
									<div className="bg"></div>
									<div className="divider-top"></div>
									<div className="divider-bottom"></div>
									<div className="container">
										<div className="row justify-content-center">
											<div className="col-md-10">
												<div className="reviews-slider">
													<div className="slick-slide slick-cloned" data-slick-index="-2" aria-hidden="true" tabIndex="-1" style={{"width": "300px", "display": "block"}}>
														<div>
															<div className="reviews-slider__slide" style={{"width": "100%", "display": "inline-block"}}>
																<div className="card">
																	<div className="reviews-slider__slide-photo" style={{"marginBottom": "16px", "justifyContent": "flex-start"}}>
																		<div className="reviews-slider__slide-avatar" style={{"backgroundImage": "url("+person1+")"}}></div>
																	</div>
																	<div className="reviews-slider__slide-name" style={{"paddingLeft": "0px", "paddingRight": "0px", "marginBottom": "8px"}}>
																		<p><strong style={{"fontWeight": "bold"}}>Ms. DIỆP THANH</strong></p>
																	</div>
																	<div className="reviews-slider__slide-description" style={{"paddingLeft": "0px", "paddingRight": "0px"}}>
																		<p>Nhờ có {domainSite} mà mình có thể tăng like bài viết, người theo dõi fb tiện lợi và nhanh chóng.</p>
																	</div>
																</div>
															</div>
														</div>
													</div>
													<div className="slick-slide slick-cloned" data-slick-index="-2" aria-hidden="true" tabIndex="-1" style={{"width": "300px", "display": "block"}}>
														<div>
															<div className="reviews-slider__slide" style={{"width": "100%", "display": "inline-block"}}>
																<div className="card">
																	<div className="reviews-slider__slide-photo" style={{"marginBottom": "16px", "justifyContent": "flex-start"}}>
																		<div className="reviews-slider__slide-avatar" style={{"backgroundImage": "url("+person2+")"}}></div>
																	</div>
																	<div className="reviews-slider__slide-name" style={{"paddingLeft": "0px", "paddingRight": "0px", "marginBottom": "8px"}}>
																		<p><strong style={{"fontWeight": "bold"}}>Mr. NÔNG VĂN ĐỨC</strong></p>
																	</div>
																	<div className="reviews-slider__slide-description" style={{"paddingLeft": "0px", "paddingRight": "0px"}}>
																		<p>Phải nói là dịch vụ mình yêu cầu xử lý rất nhanh chóng, lại rất phù hợp túi tiền ,còn được bảo hành nữa.</p>
																	</div>
																</div>
															</div>
														</div>
													</div>
													<div className="slick-slide slick-cloned" data-slick-index="-2" aria-hidden="true" tabIndex="-1" style={{"width": "300px", "display": "block"}}>
														<div>
															<div className="reviews-slider__slide" style={{"width": "100%", "display": "inline-block"}}>
																<div className="card">
																	<div className="reviews-slider__slide-photo" style={{"marginBottom": "16px", "justifyContent": "flex-start"}}>
																		<div className="reviews-slider__slide-avatar" style={{"backgroundImage": "url("+person3+")"}}></div>
																	</div>
																	<div className="reviews-slider__slide-name" style={{"paddingLeft": "0px", "paddingRight": "0px", "marginBottom": "8px"}}>
																		<p><strong style={{"fontWeight": "bold"}}>Ms. LÊ THỊ HỒNG</strong></p>
																	</div>
																	<div className="reviews-slider__slide-description" style={{"paddingLeft": "0px", "paddingRight": "0px"}}>
																		<p>Sau khi dùng dịch vụ thì tương tác facebook của mình tăng lên, đơn hàng bán ra cũng được nhiều hơn.</p>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div id="block_9">
								<div className="header-with-text ">
									<div className="bg"></div>
									<div className="divider-top"></div>
									<div className="divider-bottom"></div>
									<div className="container">
										<div className="row">
											<div className="col-12">
												<div className="text-block__title">
													<h2 className="text-center"><span style={{"textAlign": "center"}}>Câu hỏi thường gặp (FAQ)</span></h2>
												</div>
											</div>
										</div>
										<div className="row">
											<div className="col-12">
												<div className="text-block__description">
													<p className="text-center"><span style={{"textAlign": "center"}}>Dưới đây, bạn có thể nhận được câu trả lời cho một số câu hỏi phổ biến nhất trong dịch vụ của chúng tôi.</span></p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div id="block_10">
								<div className="faq ">
									<div className="bg"></div>
									<div className="divider-top"></div>
									<div className="divider-bottom"></div>
									<div className="container">
										<div className="row justify-content-center">
											<div className="col-lg-8">
												<div className="row">
													<div className="col-lg-12">
														<div className="faq-block__card">
															<div className="card" >
																<div className="faq-block__header collapsed" data-toggle="collapse" data-target="#faq-block-10-1" aria-expanded="false" aria-controls="#faq-block-10-1">
																	<div className="faq-block__header-title">
																		<h4>Tại sao mọi người tìm kiếm {domainSite}?</h4>
																	</div>
																</div>
																<div className="faq-block__body collapse" id="faq-block-10-1" style={{"display": "block"}}>
																	<div className="faq-block__body-description" style={{"paddingTop": "8px"}}>
																		<p>{domainSite} là các dịch vụ trực tuyến bán các loại dịch vụ khác nhau.</p>
																	</div>
																</div>
															</div>
														</div>
													</div>
													<div className="col-lg-12">
														<div className="faq-block__card">
															<div className="card" >
																<div className="faq-block__header collapsed" data-toggle="collapse" data-target="#faq-block-10-1" aria-expanded="false" aria-controls="#faq-block-10-1">
																	<div className="faq-block__header-title">
																		<h4>{domainSite} của bạn có những dịch vụ nào?</h4>
																	</div>
																</div>
																<div className="faq-block__body collapse" id="faq-block-10-1" style={{"display": "block"}}>
																	<div className="faq-block__body-description" style={{"paddingTop": "8px"}}>
																		<p>Có nhiều loại dịch vụ khác nhau mà chúng tôi cung cấp, chẳng hạn như lượt xem, người theo dõi, lượt thích và hơn thế nữa.</p>
																	</div>
																</div>
															</div>
														</div>
													</div>
													<div className="col-lg-12">
														<div className="faq-block__card">
															<div className="card" >
																<div className="faq-block__header collapsed" data-toggle="collapse" data-target="#faq-block-10-1" aria-expanded="false" aria-controls="#faq-block-10-1">
																	<div className="faq-block__header-title">
																		<h4>Sử dụng dịch vụ trên {domainSite} này có an toàn không?</h4>
																	</div>
																</div>
																<div className="faq-block__body collapse" id="faq-block-10-1" style={{"display": "block"}}>
																	<div className="faq-block__body-description" style={{"paddingTop": "8px"}}>
																		<p>Có, nó an toàn, vì vậy bạn không nên lo lắng về việc tài khoản của mình bị cấm hoặc bất cứ điều gì tương tự.</p>
																	</div>
																</div>
															</div>
														</div>
													</div>
													<div className="col-lg-12">
														<div className="faq-block__card">
															<div className="card" >
																<div className="faq-block__header collapsed" data-toggle="collapse" data-target="#faq-block-10-1" aria-expanded="false" aria-controls="#faq-block-10-1">
																	<div className="faq-block__header-title">
																		<h4>Một đơn đặt hàng hàng loạt là gì?</h4>
																	</div>
																</div>
																<div className="faq-block__body collapse" id="faq-block-10-1" style={{"display": "block"}}>
																	<div className="faq-block__body-description" style={{"paddingTop": "8px"}}>
																		<p>Tính năng đặt hàng số lượng lớn giúp việc đặt hàng trở nên dễ dàng hơn rất nhiều vì nó cho phép đặt nhiều đơn hàng với nhiều liên kết khác nhau cùng một lúc.</p>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="wrapper-content__footer">
							<div id="block_11">
								<div className="footer ">
									<div className="component_footer_single_line">
										<div className="component-footer">
											<div className="component-footer__public">
												<div className="container-fluid">
													<div className="row">
														<div className="col-md-12">
															<div className="component-footer__public-copyright"><p className="text-center"><span style={{"textAlign": "center"}}>© Copyright. All Rights Reserved.</span></p></div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Fragment>
		</Router>
	);
	var path_check = window.location.pathname;
	if (window.location.pathname.length > 1) {
		path_check = window.location.pathname.replace(/\/+$/g, '');
	}
	const formRegister = (
		<Fragment>
			<RegisterModal2 action_login={(e) => {this.changeFormLogRegister(1)}} action_service={(e) => {this.changeFormLogRegister(3)}} />
		</Fragment>
	);
	const formService = (
		<Fragment>
			<ServiceModal2 action_login={(e) => {this.changeFormLogRegister(1)}} action_register={(e) => {this.changeFormLogRegister(2)}} />
		</Fragment>
	);
	const formForgotPassword = (
		<Fragment>
			<ForgotPasswordModal2 action_login={(e) => {this.changeFormLogRegister(1)}} action_register={(e) => {this.changeFormLogRegister(2)}} action_service={(e) => {this.changeFormLogRegister(3)}} action_reset={path_check} />
		</Fragment>
	);
	return (
		<Fragment>
			{
				path_check === '/resetpassword' ?
					formForgotPassword
				: this.state.modal == 1 ?
					formLogin
				: this.state.modal == 2 ?
					formRegister
				: this.state.modal == 3 ?
					formService
				: this.state.modal == 4 ?
					formForgotPassword
				: ''
			}
		</Fragment>
	);
  }
}
Login.propTypes = {
  auth: PropTypes.object.isRequired,
  error: PropTypes.object,
  login: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  error: state.error
});
export default connect(mapStateToProps, { login, clearErrors })(Login);
