import {
	LOADING_GCF,
	UPDATE_COOKIE_GCF,
	LOAD_PROXY_GCF
} from "../actions/types";

const initialState = {
	"loading": false,
	"cookie": "",
	"userAgent": "",
	"host": "",
	"port": "",
	"username": "",
	"password": "",
	"listproxy": []
};

export default function(state = initialState, action) {
	switch (action.type) {
		case LOADING_GCF:
			return {
				...state,
				"loading": action.payload
			};
		case UPDATE_COOKIE_GCF:
			return {
				...state,
				"cookie": action.payload.cookie,
				"userAgent": action.payload.userAgent,
				"host": action.payload.host,
				"port": action.payload.port,
				"username": action.payload.username,
				"password": action.payload.password,
			};
		case LOAD_PROXY_GCF:
			return {
				...state,
				"listproxy": action.payload,
			};
		default:
		  return state;
	}
}
