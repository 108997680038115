
const initialState = {
	data_list: [],
	loadding: false,
	itemSelect: {},
	dataprice: {
		min_bh_web: 20000,
		prices_web: 20000,
	},
};

export default function(state = initialState, action) {
  switch (action.type) {
    case 'LOADING_DATA_FB_CHOCBANBE':
    	return {
			...state,
			loadding: action.payload
		};
	case 'LIST_DATA_FB_CHOCBANBE':
    	return {
			...state,
			data_list: action.payload
		};
	case 'DELETE_FB_CHOCBANBE':
		return {
			...state,
			data_list: state.data_list.map(
	           (content, i) => content.id === action.payload ? {...content, status: 3} : content
	       	)
		};
	case 'EDIT_FB_CHOCBANBE':
		return {
			...state,
			data_list: state.data_list.map(
	           (content, i) => content.id === action.payload.id ? {...content, cookie:action.payload.cookie} : content
	       	)
		};
	case 'DATA_PRICE_IS_FUNCTION_CHOCBANBE_YTB':
		return {
			...state,
			dataprice: action.payload
		}
	case 'SELECT_ITEM_FB_CHOCBANBE':
		return {
			...state,
			itemSelect: action.payload
		};
    default:
      return state;
  }
}
