import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { login } from "../../actions/authActions";
import { clearErrors } from "../../actions/errorActions";
import RegisterModal7 from './Register7';
import ServiceModal7 from './Service7';
import ForgotPasswordModal7 from './ForgotPassword7';
import png_2s1114c7jp9qc4km from '../../iconlanding/7/2s1114c7jp9qc4km.png';
import png_8mlgp5ifsd26d004 from '../../iconlanding/7/8mlgp5ifsd26d004.png';
import png_9uso3mtdrh0rfbus from '../../iconlanding/7/9uso3mtdrh0rfbus.png';
import png_362h7e9ds9k3w61x from '../../iconlanding/7/362h7e9ds9k3w61x.png';
import png_b2gnqkk7tfz4mgxy from '../../iconlanding/7/b2gnqkk7tfz4mgxy.png';
import png_cfc4mt2wz5geeloq from '../../iconlanding/7/cfc4mt2wz5geeloq.png';
import png_d5tl6ird1tcwxh8o from '../../iconlanding/7/d5tl6ird1tcwxh8o.png';
import png_eo8w6s61j30mrhi0 from '../../iconlanding/7/eo8w6s61j30mrhi0.png';
import png_k2rpqpy0gc1fgngd from '../../iconlanding/7/k2rpqpy0gc1fgngd.png';
import {
  BrowserRouter as Router,
  Link
} from "react-router-dom";
class Login extends Component {
  state = {
	modal: 1,
    msg: "",
    username: "",
    password: "",
    maxacminh: "",
    form_maxacminh: false,
    isLoading: false
  };
  componentDidMount(){
	if (this.props.typeform == 3) {
	  this.setState({ modal: 3 });
	}
  }
  componentDidUpdate(prevProps) {
    const { error } = this.props;
    if (error !== prevProps.error) {
      // Check for login error
      if (error.id === "LOGIN_FAIL") {
        this.setState({ msg: error.msg.msg });
      } else if (error.id === "LOGIN_FAIL_2FA") {
        this.setState({ msg: error.msg.msg, form_maxacminh: true });
      } else {
        this.setState({ msg: null });
      }
    }
  }
	clearAlert = () => {
		// Clear errors
		this.props.clearErrors();
		// this.setState({
			// modal: !this.state.modal
		// });
	}
  changeFormLogRegister = (typeform) => {
	this.props.clearErrors();
	this.setState({
		modal: typeform
	});
	
}
  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };
  onSubmit = e => {
    e.preventDefault();
    const { username, password, maxacminh } = this.state;
    this.props.clearErrors();
    this.props.login({ username, password, maxacminh });
  };
  render() {
    let isLoading = this.props.auth.isLoading;
    const domainSite = window.location.hostname;
	var config_website = {};
	if (localStorage.getItem("confignavbar") != null) {
		config_website = JSON.parse(localStorage.getItem("confignavbar"));
	} else {
		config_website = this.props.auth.config_website;
	}
	const formLogin = (
		<Router>
			<Fragment>
				<div className="landingcus7" style={{"zoom": "122%"}}>
					<div id="login" className="login unauthorized home-body light" style={{"paddingBottom": "65px"}}>
						<nav className="navbar navbar-default navbar-static-top">
							<div className="container">
								<div className="navbar-header" style={{"marginTop": "38px"}}>
									<a className="link-fx text-white font-w700 fontSize-h3 site-logo site-title" href="/" style={{"padding": "0", "margin": "25px"}}>
									  <span className="text-white text-uppercase">{domainSite.slice(0, domainSite.lastIndexOf('.'))}</span>
									  <span className="text-white text-uppercase">{domainSite.indexOf('.') > -1 ? domainSite.slice(domainSite.lastIndexOf('.')) : ''}</span>
									</a>
								</div>
								<div id="navbar" className="navbar-collapse collapse">
									<ul className="nav navbar-nav navbar-right"><li><i id="nightmode" className="fa fa-moon"></i></li>
										<li className="nav-item  menu_item_14 " id="menu_item_14">
											<Link to="/services" onClick={(e) => {this.changeFormLogRegister(3)}} className="nav-link">
												Dịch vụ
											</Link>
										</li>
										<li className="nav-item active menu_item_13 " id="menu_item_13">
											<a className="nav-link" href="/" >Đăng nhập</a>
										</li>
										<li className="nav-item  menu_item_14 " id="menu_item_14">
											<Link to="/" onClick={(e) => {this.changeFormLogRegister(2)}} className="nav-link">
												Đăng ký
											</Link>
										</li>
									</ul>
								</div>
							</div>
						</nav>
						<div className="home-head">
							<div className="home-head-bg"></div>
							<div className="shapeBox">  
								<img className="shape shape1" src={png_eo8w6s61j30mrhi0}/>
								<img className="shape shape2" src={png_362h7e9ds9k3w61x}/>
								<img className="shape shape3" src={png_cfc4mt2wz5geeloq}/>
							</div>
							<div className="home-head-content">
								<div className="container" style={{"position": "relative"}}>
									<div className="row">
										<div className="col-lg-6 col-12 d-none d-lg-block hide-on-mob">
											<div className="hh-left-badge" style={{"background": "rgb(240, 25, 25, 0.3)"}}>
												<div className="hhlb-icon">
													<i className="fas fa-crown"></i>
												</div>
												<div className="hhlb-text">Giá rẻ nhất trên thị trường</div>
											</div>
											<h1 className="hl-title">{domainSite}</h1>
											<div className="hl-text">Chuyên cung cấp các dịch vụ, giải pháp và tiện ích kinh doanh giúp gia tăng doanh số, mở rộng nhận diện thương hiệu và tầm ảnh hưởng trên các kênh truyền thông và mạng xã hội!</div>
											<div className="hl-btns">
												<Link to="/" className="btn btn-lg btn-outline" onClick={(e) => {this.changeFormLogRegister(2)}}>
													<i className="fas fa-layer-group mr-2"></i> ĐĂNG KÝ NGAY
												</Link>
											</div>
										</div>
										<div className="col-lg-5 col-lg-offset-1">
											<div className="loginSide">
												<div className="round"></div>
												<div className="login-box">
													<div className="lgb-title">Đăng nhập</div>
													<div className="lgb-text">Tuyệt đối không chia sẻ mật khẩu với bên thứ 3.</div>
													{isLoading ? (
														<div className="preloader">
															<div className="spinner-grow spinner-grow-sm text-primary" role="status" style={{ "marginTop": "50%" }}>
															  <span className="sr-only"></span>
															</div>
															<div className="spinner-grow spinner-grow-sm text-danger" role="status" style={{ "marginTop": "50%" }}>
															  <span className="sr-only">.</span>
															</div>
															<div className="spinner-grow spinner-grow-sm text-warning" role="status" style={{ "marginTop": "50%" }}>
															  <span className="sr-only"></span>
															</div>
														</div>
													):('')}
													{this.state.msg ? (
														<div className="alert alert-warning d-flex align-items-center justify-content-between" role="alert" >
														  <div className="flex-fill mr-3">
															<p className="mb-0">{this.state.msg}</p>
														  </div>
														  <div className="flex-00-auto">
															<i className="fa fa-fw fa-exclamation-circle"></i>
														  </div>
														</div>
													  ) : null}
													  
													<form className="js-validation-signup" onSubmit={this.onSubmit} method="POST" >
														<div className="fc-home">
															<label htmlFor="username"><span className="label-text">Tài khoản</span></label>
															<input name="username" placeholder="Nhập tài khoản" required="" id="username" type="text" className="" onChange={this.onChange} />
														</div>
														<div className="fc-home">
															<label htmlFor="password"><span className="label-text">Mật khẩu</span></label>
															<input name="password" placeholder="Nhập mật khẩu" required="" id="password" type="password" className="" onChange={this.onChange} />
															{
																!this.state.form_maxacminh ?
																	<Link to="/" onClick={(e) => {this.changeFormLogRegister(4)}} style={{"color": "#1f2324", "float": "right", "marginTop": "5px", "marginBottom": "15px"}}>
																		Quên mật khẩu?
																	</Link>
																: ""
															}
														</div>
														{
															this.state.form_maxacminh ?
																<div className="fc-home">
																	<label htmlFor="maxacminh"><span className="label-text">Mã xác minh</span></label>
																	<input name="maxacminh" placeholder="Nhập mã xác minh" required="" id="maxacminh" type="text" className="" onChange={this.onChange} />
																	<Link to="/" onClick={(e) => {this.changeFormLogRegister(4)}} style={{"color": "#1f2324", "float": "right", "marginTop": "5px", "marginBottom": "15px"}}>
																		Quên mật khẩu?
																	</Link>
																</div>
															: ""
														}
														<button type="submit" className="btn btn-secondary btn-lg btn-block" style={{"marginTop": "16px"}}>Đăng nhập</button>
														<div className="text-center mt-4 pt-3 d-none d-md-block" style={{"paddingTop":"16px","marginTop":"24px"}}>
															<Link to="/" onClick={(e) => {this.changeFormLogRegister(2)}} className="frgpass">
																Bạn chưa có tài khoản? Đăng ký
															</Link>
														</div>
													</form>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="hs-area mb-5">
							<div className="container">
								<div className="hs-head">
									<h1>Danh Sách Dịch Vụ {domainSite}</h1>
									<small>Chúng tôi luôn cung cấp những dịch vụ tốt nhất tới khách hàng</small>
								</div>
								<div className="hs-area-owl owl-carousel owl-theme owl-loaded owl-drag">
									<div className="owl-stage-outer">
										<div className="owl-stage">
											<div className="owl-item" style={{"width": "330px", "marginTop": "25px", "marginRight": "25px", "display": "inline-block"}}>
												<div className="hsa-item ">
													<div className="row">
														<div className="col-sm-4 align-self-center">
															<div className="hsi-icon">
																<i className="fab fa-facebook-square"></i>
															</div>
														</div>
														<div className="col-sm-8 align-self-center">
															<div className="hsi-title">
																Facebook
															</div>
															<a href="/" className="hsi-more">
																See Services
																<i className="fas fa-arrow-right ml-2"></i>
															</a>
														</div>
													</div>
												</div>
											</div>
											<div className="owl-item" style={{"width": "330px", "marginTop": "25px", "marginRight": "25px", "display": "inline-block"}}>
												<div className="hsa-item">
													<div className="row">
														<div className="col-sm-4 align-self-center">
															<div className="hsi-icon">
																<i className="fab fa-instagram"></i>
															</div>
														</div>
														<div className="col-sm-8 align-self-center">
															<div className="hsi-title">
																Instagram
															</div>
															<a href="/" className="hsi-more">
																<i className="fas fa-arrow-right ml-2"></i>
															</a>
														</div>
													</div>
												</div>
											</div>
											<div className="owl-item" style={{"width": "330px", "marginTop": "25px", "marginRight": "25px", "display": "inline-block"}}>
												<div className="hsa-item">
													<div className="row">
														<div className="col-sm-4 align-self-center">
															<div className="hsi-icon">
																<i className="fab fa-tiktok"></i>
															</div>
														</div>
														<div className="col-sm-8 align-self-center">
															<div className="hsi-title">
																Tiktok
															</div>
															<a href="/" className="hsi-more">
																<i className="fas fa-arrow-right ml-2"></i>
															</a>
														</div>
													</div>
												</div>
											</div>
											<div className="owl-item" style={{"width": "330px", "marginTop": "25px", "marginRight": "25px", "display": "inline-block"}}>
												<div className="hsa-item">
													<div className="row">
														<div className="col-sm-4 align-self-center">
															<div className="hsi-icon">
																<i className="fab fa-youtube"></i>
															</div>
														</div>
														<div className="col-sm-8 align-self-center">
															<div className="hsi-title">
																Youtube
															</div>
															<a href="/" className="hsi-more">
																<i className="fas fa-arrow-right ml-2"></i>
															</a>
														</div>
													</div>
												</div>
											</div>
											<div className="owl-item" style={{"width": "330px", "marginTop": "25px", "marginRight": "25px", "display": "inline-block"}}>
												<div className="hsa-item">
													<div className="row">
														<div className="col-sm-4 align-self-center">
															<div className="hsi-icon">
																<i className="fab fa-twitter"></i>
															</div>
														</div>
														<div className="col-sm-8 align-self-center">
															<div className="hsi-title">
																Twitter
															</div>
															<a href="/" className="hsi-more">
																<i className="fas fa-arrow-right ml-2"></i>
															</a>
														</div>
													</div>
												</div>
											</div>
											<div className="owl-item" style={{"width": "330px", "marginTop": "25px", "marginRight": "25px", "display": "inline-block"}}>
												<div className="hsa-item">
													<div className="row">
														<div className="col-sm-4 align-self-center">
															<div className="hsi-icon">
																<i className="fab fa-telegram"></i>
															</div>
														</div>
														<div className="col-sm-8 align-self-center">
															<div className="hsi-title">
																Telegram
															</div>
															<a href="/" className="hsi-more">
																<i className="fas fa-arrow-right ml-2"></i>
															</a>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<section className="best-price hs-about">
							<div className="ha-item">
								<div className="container">
									<div className="row">
										<div className="col-lg-12 mr-auto">
											<div className="row">
												<div className="col-lg-5 ha-heading">
													<div className="ha-title">
														<div className="row">
															<div className="col-md-3 align-self-center">
																<div className="ha-title-icon">
																	<i className="fal fa-badge-check"></i>
																</div>
															</div>
															<div className="col-md-9 align-self-center">
																<h2>Giá Tốt Nhất và Chất Lượng nhất thị trường!</h2>
															</div>
														</div>
														<div className="ha-text">{domainSite} Chuyên cung cấp các dịch vụ, giải pháp và tiện ích kinh doanh giúp gia tăng doanh số, mở rộng nhận diện thương hiệu và tầm ảnh hưởng trên các kênh truyền thông và mạng xã hội!</div>
														<br/>
														<a href="/" className="btn btn-primary">Dịch vụ</a>
														<Link to="/" className="btn btn-secondary" style={{"marginLeft":"8px"}} onClick={(e) => {this.changeFormLogRegister(2)}}>
															ĐĂNG KÝ MIỄN PHÍ
														</Link>
													</div>
												</div>
												<div className="col-lg-6 col-lg-offset-1 ha-graph">
													<div className="graphics">
														<img src={png_k2rpqpy0gc1fgngd} className="img-fluid" alt=""/>
														<span className="shadow"></span>
														<span className="shadow"></span>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</section>
						<section className="home-info">
							<div className="container">
								<div className="row">
									<div className="col-lg-6 col-md-12 align-self-center">
										<div className="home-image-2 d-none d-lg-block hide-on-mob">
											<img src={png_d5tl6ird1tcwxh8o} className="img-responsive" alt=""/>
										</div>
									</div>
									<div className="col-lg-5 col-lg-offset-1 col-md-12 mb-lg-0 align-self-center pt-5 pl-2 pr-2">
										<div className="hi-title mt-4">Lý do khách hàng tin tưởng {domainSite}</div>
										<br/>
										<div className="hi-text pt-4 pb-5">
											<div className="home-sab-items py-4">
												<div className="hosa-item">
													<div className="row">
														<div className="col-sm-2 align-self-center">
															<div className="hosa-icon">
																<img src={png_b2gnqkk7tfz4mgxy}/>
															</div>
														</div>
														<div className="col-sm-10 align-self-center">
															<div className="hosa-title">Uy Tín & Chất Lượng</div>
														</div>
													</div>
												</div>
												<div className="hosa-item">
													<div className="row">
														<div className="col-sm-2 align-self-center">
															<div className="hosa-icon">
																<img src={png_2s1114c7jp9qc4km}/>
															</div>
														</div>
														<div className="col-sm-10 align-self-center">
															<div className="hosa-title">Tiện lợi & Tốc độ</div>
														</div>
													</div>
												</div>
												<div className="hosa-item">
													<div className="row">
														<div className="col-sm-2 align-self-center">
															<div className="hosa-icon">
																<img src={png_8mlgp5ifsd26d004}/>
															</div>
														</div>
														<div className="col-sm-10 align-self-center">
															<div className="hosa-title">Phương thức thanh toán đa dạng</div>
														</div>
													</div>
												</div>
												<div className="hosa-item">
													<div className="row">
														<div className="col-sm-2 align-self-center">
															<div className="hosa-icon">
																<img src={png_9uso3mtdrh0rfbus}/>
															</div>
														</div>
														<div className="col-sm-10 align-self-center">
															<div className="hosa-title">Hỗ Trợ 24/7</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</section>
						<section className="footer mt-5">
							<div className="footer-top">
								<div className="container">
									<div className="row">
										<div className="col-lg-12 col-12 mt-4 mt-lg-0 align-self-center add-left-border text-lg-center">
											<div className="ftgu-text">Bạn đã sẵn sàng <strong>tham gia?</strong></div><br/>
											<Link to="/" className="btn btn-outline" onClick={(e) => {this.changeFormLogRegister(2)}}>
												<i className="fas fa-mouse-pointer mr-2"></i> ĐĂNG KÝ NGAY
											</Link>
										</div>
									</div>
								</div>
							</div>
							<div className="container">
								<div className="footer-content">
									<div className="footer-content-top">
										<div className="row">
											<div className="col-md-3 text-center text-md-left">
												<div className="footer-links text-center">
													<ul>
														<li>
														</li>
														<li>
														</li>
													</ul>
												</div>
											</div>
											<div className="col-md-9 align-self-center">
												<div className="footer-content-bot">
													<div className="footer-content-bot-text">
														<a href="/">{domainSite}</a> © 2021 All rights reserved.
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</section>
					</div>
				</div>
			</Fragment>
		</Router>
	);
	var path_check = window.location.pathname;
	if (window.location.pathname.length > 1) {
		path_check = window.location.pathname.replace(/\/+$/g, '');
	}
	const formRegister = (
		<Fragment>
			<RegisterModal7 action_login={(e) => {this.changeFormLogRegister(1)}} action_service={(e) => {this.changeFormLogRegister(3)}} />
		</Fragment>
	);
	const formService = (
		<Fragment>
			<ServiceModal7 action_login={(e) => {this.changeFormLogRegister(1)}} action_register={(e) => {this.changeFormLogRegister(2)}} />
		</Fragment>
	);
	const formForgotPassword = (
		<Fragment>
			<ForgotPasswordModal7 action_login={(e) => {this.changeFormLogRegister(1)}} action_register={(e) => {this.changeFormLogRegister(2)}} action_service={(e) => {this.changeFormLogRegister(3)}} action_reset={path_check} />
		</Fragment>
	);
	return (
		<Fragment>
			{
				path_check === '/resetpassword' ?
					formForgotPassword
				: this.state.modal == 1 ?
					formLogin
				: this.state.modal == 2 ?
					formRegister
				: this.state.modal == 3 ?
					formService
				: this.state.modal == 4 ?
					formForgotPassword
				: ''
			}
		</Fragment>
	);
  }
}
Login.propTypes = {
  auth: PropTypes.object.isRequired,
  error: PropTypes.object,
  login: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  error: state.error
});
export default connect(mapStateToProps, { login, clearErrors })(Login);
