import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import store from '../../../store';
import {creatNewItem} from '../../../actions/rechargeCardPhoneAction';

class CreatRechanrgeCardPhone extends Component {
    state = {}

    onChangeDataForm = (e, type=0) => {
        let el = e.target;
        let valueChange = el.value;
        if (type === 1) {
            valueChange = parseInt(el.value);
            if (isNaN(valueChange)) {
                valueChange = '';
            }
        }
        store.dispatch({
            type: 'CHANGE_DATA_FORM_RECHARGE_CARDPHONE',
            payload: {name: el.name, value: valueChange}
        });
    }

    calPricePay = () => {
        let {formData} = this.props.rechargeCardPhone;
        let priceReturn = 0;
        let priceSt = 0;
        if (formData.price_buy !== '') {
            priceReturn = formData.price_buy - (formData.price_buy*0.2);
            priceSt = formData.price_buy;
        }

        if (formData.level_up !== 0) {
            priceReturn = priceReturn + (priceSt*formData.level_up/100)
        }

        if (formData.type_create === 1) {
            priceReturn = priceReturn + (priceSt*0.02)
        }

        return priceReturn;
    }

    render() {
        const {formData} = this.props.rechargeCardPhone;
        return (
            <Fragment>
                <div className="row form-group">
                    <div className="col-md-12">
                        <select onChange={(e) => {this.onChangeDataForm(e)}} name="name_telecom" value={formData.name_telecom} className="form-control">
                            <option value="">Chọn nhà mạng</option>
                            <option value="VTT">Viettel - trả trước: 20% - trả sau: 20%</option>
                            <option value="MBS">Mobifone - trả trước: 20% - trả sau: 20%</option>
                            <option value="VNP">Vina - trả trước: 20% - trả sau: 20%</option>
                        </select>
                    </div>
                </div>

                <div className="row form-group">
                    <div className="col-lg-7 col-md-6">
                        <select onChange={(e) => {this.onChangeDataForm(e, 1)}} value={formData.level_up} name="level_up" className="form-control">
                            <option value="0">Chọn cấp ưu tiên</option>
                            <option value="1">Ưu tiên cấp 1 (-1%)</option>
                            <option value="2">Ưu tiên cấp 2 (-2%)</option>
                            <option value="3">Ưu tiên cấp 3 (-3%)</option>
                            <option value="4">Ưu tiên cấp 4 (-4%)</option>
                            <option value="5">Ưu tiên cấp 5 (-5%)</option>
                        </select>
                    </div>
                    <div className="col-lg-5 col-md-6">
                        <input onChange={(e) => {this.onChangeDataForm(e, 1)}} value={formData.price_buy} name="price_buy" type="number" className="form-control" placeholder="Số tiền. Bội của 10.000"/>
                    </div>
                </div>

                <div className="row form-group">
                    <div className="col-lg-7 col-md-6">
                        <input onChange={(e) => {this.onChangeDataForm(e, 1)}} value={formData.phone_up} name="phone_up" className="form-control" type="number" placeholder="SĐT hoặc FTTH cần nạp" />
                    </div>
                    <div className="col-lg-5 col-md-6">
                        <select onChange={(e) => {this.onChangeDataForm(e, 1)}} value={formData.type_phone} name="type_phone" className="form-control">
                            <option value="">Loại thuê bao</option>
                            <option value="0">Trả trước</option>
                            <option value="1">Trả sau</option>
                        </select>
                    </div>
                </div>

                {
                    (formData.name_telecom === 'VTT' || formData.name_telecom === 'MBS' ? 
                        <div className="row form-group">
                            <div className="col-lg-12">
                                <input className="form-control" onChange={(e) => {this.onChangeDataForm(e)}} value={formData.pass_phone} name="pass_phone" type="text" placeholder={(formData.name_telecom === 'VTT') ? 'Mật Khẩu MyViettel (+3% nếu có)' : 'Mật Khẩu MyMobi (yêu cầu nhập)'} />
                            </div>
                        </div>
                    :   ''
                    )  
                }

                <div className="row form-group">
                    <div className="col-lg-12">
                        <textarea onChange={(e) => {this.onChangeDataForm(e)}} value={formData.note} name="note" className="form-control" rows="4" placeholder="Nhập nội dung ghi chú về tiến trình của bạn"></textarea>
                    </div>
                </div>

                <div className="row form-group">
                    <div className="col-lg-12">
                        <select onChange={(e) => {this.onChangeDataForm(e, 1)}} value={formData.type_create} name="type_create" className="form-control">
                            <option value="0">Nạp tất cả mệnh giá</option>
                            <option value="1">Nạp thẻ bội 50k (-2%)</option>
                        </select>    
                    </div>
                </div>

                <div className="row mt-4">
                    <div className="col-12 text-center bold">
                        <div className="card card-info">
                            <div className="card-body">
                                <h5 className="font-bold">Thành tiền</h5>
                                <h6 className="mb-0">Số tiền phải thanh toán với giá <span className="green bold">{this.calPricePay()}</span> Coin</h6>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row mt-4">
                    <div className="col-md-12">
                        <div className='form-group float-right w-100'>
                            <button onClick={() => {this.props.creatNewItem(formData)}} type="button" className="btn btn-hero-primary btn-rounded btn-block">
                                Nạp tiền ngay
                            </button>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    rechargeCardPhone: state.rechargeCardPhone,
});

export default connect(mapStateToProps, {creatNewItem}) (CreatRechanrgeCardPhone);
