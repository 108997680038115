import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import store from '../../../store';
import {
    Modal,
    ModalBody,
    ModalFooter
} from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import Moment from 'react-moment';

import { saveCreateBankInfo,getlistBankInfoAdmin,deleteItemBankInfo } from '../../../actions/adminPageAction';
import paginationFactory from 'react-bootstrap-table2-paginator';

class Notification extends Component {

    componentDidMount(){
        this.props.getlistBankInfoAdmin();
    }

    state = {
        isOpenModalEditUser: false,
        sotaikhoan: '',
        tentaikhoan: '',
        nganhang: '',
        chinhanh: '',
        gionapnhanh: '',
    }

    isOpenModalAdd = () => {
        this.setState({
            isOpenModalEditUser: !this.state.isOpenModalEditUser,
        });
    }

    onChangeForm = (e) => {
        this.setState({
            [e.target.name] : e.target.value
        })
    }
	
	resetFormdata = (e) => {
        this.setState({
			sotaikhoan: '',
			tentaikhoan: '',
			nganhang: '',
			chinhanh: '',
			gionapnhanh: '',
        });
	}

    render() {

        const {overviewUser, listbankinfo} = this.props.adminpage;
        const {isOpenModalEditUser, sotaikhoan, tentaikhoan, nganhang, chinhanh, gionapnhanh} = this.state;

        const columns = [{
          dataField: 'id',
          text: 'STT',
          sort: true,
        },{
          dataField: 'sotaikhoan',
          text: 'Số tài khoản',
          sort: true
        },{
          dataField: 'tentaikhoan',
          text: 'Tên tài khoản',
          sort: true
        },{
          dataField: 'nganhang',
          text: 'Ngân hàng',
          sort: true
        },{
          dataField: 'chinhanh',
          text: 'Chi nhánh',
          sort: true
        },{
          dataField: 'gionapnhanh',
          text: 'Giờ nạp nhanh',
          sort: true
        },{
          dataField: 'created_at',
          text: 'Ngày tạo',
          sort: true,
          formatter: (cell, row) => 
            <Fragment>
                {typeof cell !== 'undefined' ?
                    <span>
                        <Moment format="DD-MM-YYYY HH:mm:ss">{cell*1000}</Moment>
                    </span>
                    : <Moment format="DD-MM-YYYY HH:mm:ss"></Moment>
                } 
            </Fragment>
        },{
          dataField: 'action',
          text: 'Thao tác',
          sort: true,
          formatter: (cell, row) => 
            <Fragment>
                <a onClick={(e) => {e.preventDefault();this.props.deleteItemBankInfo(row.id)}} href="/#" className="btn btn-hero-danger text-center p-2 mr-1">
                    <i className="fa fa-fw fa-times"></i>
                </a>
            </Fragment>
        }];

        return (
            <Fragment>
                <div className="row">
                    <div className="col-md-12">
                        <button onClick={(e) => {this.resetFormdata(e); this.isOpenModalAdd(e)}} type="button" className="btn btn-sm btn-success mr-1 mb-3"><i className="fa fa-fw fa-plus mr-1"></i> Thêm bank</button>
                        <BootstrapTable
                        keyField='id'
                        bodyStyle={ { border: 'none' } }
                        headerStyle={ { border: 'red 1px solid' } }
                        data={ listbankinfo }
                        striped
                        hover
                        wrapperClasses="table-responsive"
                        columns={ columns }
                        pagination={ paginationFactory() } />
                    </div>
                </div>

                <Modal centered={true} size="lg" toggle={(e) => {this.resetFormdata(e); this.isOpenModalAdd(e)}} isOpen={isOpenModalEditUser}>
                    <div className="modal-header">
                        <p className="mb-0 text-dark">Thêm bank</p>
                        <button onClick={(e) => {this.resetFormdata(e); this.isOpenModalAdd(e)}} type="button" className="close">×</button>
                    </div>
                    <ModalBody>

                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Số tài khoản</label>
                                    <input onChange={(e) => {this.onChangeForm(e)}} type="text" name="sotaikhoan" value={sotaikhoan} className="form-control" placeholder="Số tài khoản" />
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Tên tài khoản</label>
                                    <input onChange={(e) => {this.onChangeForm(e)}} type="text" name="tentaikhoan" value={tentaikhoan} className="form-control" placeholder="Tên tài khoản" />
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Ngân hàng</label>
									<input className="form-control" name="nganhang" defaultValue={nganhang} onChange={(e) => {this.onChangeForm(e)}}list="listDataBank" />
									<datalist onChange={this.onChange} id="listDataBank">
									  <option value="Vietcombank" />
									  <option value="Techcombank" />
									  <option value="Agribank" />
									  <option value="Ngân hàng Á châu, ACB" />
									  <option value="KienLongBank, KLB" />
									  <option value="HDBank, HDB" />
									  <option value="Ngân hàng Quốc tế, VIB" />
									  <option value="VietBank, VBB" />
									  <option value="Eximbank, EIB" />
									  <option value="Sacombank, STB" />
									  <option value="VietCapitalBank, BVB" />
									  <option value="Đông Á Bank" />
									  <option value="Nam A Bank" />
									  <option value="OCB" />
									  <option value="SCB" />
									  <option value="Saigonbank" />
									  <option value="TPBank, TPB" />
									  <option value="BacABank, BAB" />
									  <option value="National Citizen Bank, NVB" />
									  <option value="VPBank, VPB" />
									  <option value="Ngân hàng Sài Gòn-Hà Nội, SHB" />
									  <option value="LienVietPostBank, LPB" />
									  <option value="Military Bank, MBB" />
									  <option value="BIDV, BID" />
									  <option value="SeABank" />
									  <option value="ABBANK" />
									  <option value="MSB" />
									  <option value="VietABank" />
									  <option value="PVcombank" />
									  <option value="BaoVietBank" />
									  <option value="PG Bank" />
									  <option value="MB Bank" />
									  <option value="VietinBank, CTG" />
									</datalist>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Chi nhánh</label>
                                    <input onChange={(e) => {this.onChangeForm(e)}} type="text" name="chinhanh" value={chinhanh} className="form-control" placeholder="Chi nhánh" />
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Giờ nạp nhanh</label>
                                    <input onChange={(e) => {this.onChangeForm(e)}} type="text" name="gionapnhanh" value={gionapnhanh} className="form-control" placeholder="Giờ nạp nhanh" />
                                </div>
                            </div>
                        </div>

                    </ModalBody>
                    <ModalFooter>
                        <button onClick={(e) => {this.props.saveCreateBankInfo(sotaikhoan, tentaikhoan, nganhang, chinhanh, gionapnhanh, this.isOpenModalAdd)}} type="button" className="btn btn-primary">Lưu</button>
                        <button type="button" className="btn btn-danger" onClick={(e) => {this.resetFormdata(e); this.isOpenModalAdd(e)}} >Huỷ</button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        );
    }

}

const mapStateToProps = state => ({
    adminpage: state.adminpage
});

export default connect(mapStateToProps, { saveCreateBankInfo,getlistBankInfoAdmin,deleteItemBankInfo }) (Notification);
