import {
	LOAD_FBBLS,
	UPDATE_FBBLS_TAB,
	UPDATE_FBBLS,
	UPDATE_COOKIE_FBBLS,
	LOADING_FBBLS,
	LOAD_PRICE_FBBLS,
	LIMIT_PAGI_FBBLS,
	TYPE_FBBLS,
	LOAD_NHATKYHOATDONG_FBBLS,
	LOAD_PROXY_FBBLS,
	LOAD_PROXY_EDIT_FBBLS
} from "../actions/types";

const date7dnext = () => {
	var result = new Date();
  	result.setDate(result.getDate() + 7);
  	return result;
}

const initialState = {
	"taotientrinh": {
		"ctkfcc": "",
		"tgctt_tu": "7",
		"tgctt_den": "22",
		// "tgdctt_tu": "60",
		// "tgdctt_den": "600",
		"lnncx_type": ["love"],
		"lnncx_tdmn": "2000",
		"snmcatt": "30",
		"gc": "",
		"userAgent": "",
		"gtmtt": "0",
		"idfb": "",
		"usernamefb": "",
		"dtsg": "",
		"id_proxy": "0",
		"ttv": "FRIEND",
		"gioitinh": "all",
		"bvtp": "1",
		"blacklistid": "",
		"ttms": "2",
		"blbv": "0",
		"blbv_cmt": "",
		"blbv_tdmn": "2000",
	},
	"changetab": 0,
	"listtientrinh": [],
	"loading": false,
	"price": [],
	"listproxy": [],
	"listproxy_edit": [],
	"limit_pagi": 0,
	"type": 0,
	"list_nhatky":""
};

export default function(state = initialState, action) {
	switch (action.type) {
		case LOAD_FBBLS:
			return {
				...state,
				"listtientrinh": action.payload
			};
		case UPDATE_FBBLS_TAB:
			return {
				...state,
				"changetab": action.payload
			};
		case UPDATE_FBBLS:
			return {
				...state,
				"taotientrinh": action.payload
			};
		case UPDATE_COOKIE_FBBLS:
			return {
				...state,
				"taotientrinh": {
					...state.taotientrinh,
					"ctkfcc": action.payload.cookie,
					"userAgent": action.payload.userAgent,
				}
			};
		case LOADING_FBBLS:
			return {
				...state,
				"loading": action.payload
			};
		case LOAD_PRICE_FBBLS:
			return {
				...state,
				"price": action.payload,
				"taotientrinh": {
					...state.taotientrinh,
					"gtmtt": action.payload.prices_web
				}
			};
		case LOAD_PROXY_FBBLS:
			return {
				...state,
				"listproxy": action.payload,
			};
		case LOAD_PROXY_EDIT_FBBLS:
			return {
				...state,
				"listproxy_edit": action.payload,
			};
		case LIMIT_PAGI_FBBLS:
			return {
				...state,
				"limit_pagi": action.payload
			};
		case TYPE_FBBLS:
			return {
				...state,
				"type": action.payload
			};
		case LOAD_NHATKYHOATDONG_FBBLS:
			return {
				...state,
				"list_nhatky": action.payload
			};
		default:
		  return state;
	}
}
