import React from 'react';
import axios from 'axios';
import {
	LOAD_YV,
	UPDATE_YV,
	UPDATE_YL_TAB,
	LOAD_PRICE_YV
} from "../actions/types";
import Swal from 'sweetalert2';

export const loadYV = () => (dispatch, getState) => {
	axios.get('/api/youtubeview', tokenConfig(getState))
		.then(res => {
			if (res.data.status === 200) {
				dispatch({
					type: LOAD_YV,
					payload: res.data.data
				});
			}
		});
};

export const loadPriceYV = () => (dispatch, getState) => {
	axios.get('/api/youtubeview/price', tokenConfig(getState))
		.then(res => {
			if (res.data.status === 200) {
				dispatch({
					type: LOAD_PRICE_YV,
					payload: res.data.data
				});
			}
		});
};

export const updateYV = (body) => (dispatch, getState) => {
	dispatch({
		type: UPDATE_YV,
		payload: body
	});
};

export const addYV = (body) => (dispatch, getState) => {
	axios.post('/api/youtubeview/add', body, tokenConfig(getState))
		.then(res => {
			if (res.data.status === 200) {
				Swal.fire({
					icon: 'success',
					html: 'Tạo tiến trình seeding thành công, hệ thống sẽ tự động tăng theo thời gian bạn đã chọn <br/><br/> Nếu không đủ lượt seeding hệ thống sẽ tự động hoàn lại số tiền chưa chạy'
				});
			}
			if (res.data.status === 400) {
				var message = '';
				if (res.data.error.object_id) {
					message = res.data.error.object_id[0];
				} else if (res.data.error.quantity) {
					message = res.data.error.quantity[0];
				} else {
					message = res.data.message;
				}
				Swal.fire({
					icon: 'error',
					title: 'Lỗi',
					text: message,
				});
			}
		});
};

export const changeTab = (body) => (dispatch, getState) => {
	dispatch({
		type: UPDATE_YL_TAB,
		payload: body
	});
};

export const upHuyOrder = (body) => (dispatch, getState) => {
	// axios.post('/api/youtubeview/huyorder', body, tokenConfig(getState))
		// .then(res => {
			// if (res.data.status === 200) {
				// Swal.fire({
					// icon: 'success',
					// text: res.data.message,
				// });
				// dispatch(loadBSLIO());
			// }
			// if (res.data.status === 400) {
				// var message = '';
				// if (res.data.error.object_id) {
					// message = res.data.error.object_id[0];
				// } else if (res.data.error.quantity) {
					// message = res.data.error.quantity[0];
				// } else if (res.data.error.id) {
					// message = res.data.error.id[0];
				// } else {
					// message = res.data.message;
				// }
				// Swal.fire({
					// icon: 'error',
					// title: 'Lỗi',
					// text: message,
				// });
			// }
		// });
};

export const tokenConfig = getState => {
	// Get token from localhost
	const token = getState().auth.token;
	// Headers
	const config = {
		headers: {
			"Content-type": "application/json"
		}
	};
	
	// If token, add to headers
	if (token) {
		config.headers['ht-token'] = token;
	}
	
	return config;
};