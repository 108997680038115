import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { login } from "../../actions/authActions";
import { clearErrors } from "../../actions/errorActions";
import RegisterModal6 from './Register6';
import aboutbg from '../../iconlanding/6/about-bg.png';
import aboutleftimage from '../../iconlanding/6/about-left-image.png';
import b1 from '../../iconlanding/6/b1.png';
import banerdecleft from '../../iconlanding/6/baner-dec-left.png';
import banerdecright from '../../iconlanding/6/baner-dec-right.png';
import bannerrightimage from '../../iconlanding/6/banner-right-image.png';
import bigblogthumb from '../../iconlanding/6/big-blog-thumb.jpg';
import blogdec from '../../iconlanding/6/blog-dec.png';
import blogthumb01 from '../../iconlanding/6/blog-thumb-01.jpg';
import contactbg from '../../iconlanding/6/contact-bg.png';
import contactdecoration from '../../iconlanding/6/contact-decoration.png';
import portfolioimage from '../../iconlanding/6/portfolio-image.png';
import serviceicon01 from '../../iconlanding/6/service-icon-01.png';
import serviceicon02 from '../../iconlanding/6/service-icon-02.png';
import serviceicon03 from '../../iconlanding/6/service-icon-03.png';
import serviceicon04 from '../../iconlanding/6/service-icon-04.png';
import servicesleftimage from '../../iconlanding/6/services-left-image.png';
import {
  BrowserRouter as Router,
  Link
} from "react-router-dom";
class Login extends Component {
  state = {
	modal: false,
    msg: "",
    username: "",
    password: "",
    maxacminh: "",
    form_maxacminh: false,
    isLoading: false,
	tabopen: 'fb',
	menuopen: false,
  };
  componentDidUpdate(prevProps) {
    const { error } = this.props;
    if (error !== prevProps.error) {
      // Check for login error
      if (error.id === "LOGIN_FAIL") {
        this.setState({ msg: error.msg.msg });
      } else if (error.id === "LOGIN_FAIL_2FA") {
        this.setState({ msg: error.msg.msg, form_maxacminh: true });
      } else {
        this.setState({ msg: null });
      }
    }
  }
	clearAlert = () => {
		// Clear errors
		this.props.clearErrors();
		// this.setState({
			// modal: !this.state.modal
		// });
	}
  changeFormLogRegister = () => {
	this.props.clearErrors();
	this.setState({
		modal: !this.state.modal
	});
	
}
  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };
  onSubmit = e => {
    e.preventDefault();
    const { username, password, maxacminh } = this.state;
    this.props.clearErrors();
    this.props.login({ username, password, maxacminh });
  };
  openTab = (data = null) => {
	if (data !== null) {
		this.setState({
			...this.state,
			tabopen: data,
		});
	}
  }
  openMenu = (data = null) => {
	if (data !== null) {
		this.setState({
			...this.state,
			menuopen: data,
		});
	}
  }
  render() {
    let isLoading = this.props.auth.isLoading;
    const domainSite = window.location.hostname;
	var config_website = {};
	if (localStorage.getItem("confignavbar") != null) {
		config_website = JSON.parse(localStorage.getItem("confignavbar"));
	} else {
		config_website = this.props.auth.config_website;
	}
	const formLogin = (
		<Router>
			<Fragment>
				<div className="landingcus6 notranslate" style={{"zoom": "122%", "backgroundColor": "#fff"}}>
					<header className="header-area header-sticky wow slideInDown background-header" data-wow-duration="0.75s" data-wow-delay="0s">
						<div className="container">
							<div className="row">
								<div className="col-12">
									<nav className="main-nav">
										<a href="/" className="logo" style={{"marginTop": "10px"}}>
											<h4>fb<span>touse</span></h4>
										</a>
										<ul className="nav" style={{"display": this.state.menuopen ? 'block' : 'none'}}>
											<li className="scroll-to-section"><a href="#top" className="active">หน้าหลัก</a></li>
											<li className="scroll-to-section"><a href="#about">เกี่ยวกับเรา</a></li>
											<li className="scroll-to-section"><a href="#services">บริการ</a></li>
											<li className="scroll-to-section"><a href="#question">ถาม-ตอบ</a></li>
											<li className="scroll-to-section"><a href="#howpay">วิธีสั่งซื้อ</a></li> 
											<li className="scroll-to-section"><a href="#contact">ติดต่อเรา</a></li>
											<li className="scroll-to-section">
												<Link to="/" onClick={this.changeFormLogRegister} style={{"marginLeft": "5px"}}>
												  <span>
													สมัครบริการ
												  </span>
												</Link>
											</li>
											<li className="scroll-to-section"><a href="#" className="btn btn-danger">เข้าสู่ระบบ</a></li> 
										</ul>        
										<a onClick={(e) => {e.preventDefault();this.openMenu(!this.state.menuopen)}} className={"menu-trigger "+(this.state.menuopen ? ' active' : '')}>
											<span>Menu</span>
										</a>
									</nav>
								</div>
							</div>
						</div>
					</header>
					<div className="main-banner wow fadeIn" id="top" data-wow-duration="1s" data-wow-delay="0.5s">
						<div className="container">
							<div className="row">
								<div className="col-lg-12">
									<div className="row">
										<div className="col-lg-6 align-self-center">
											<div className="row">
												<div className="left-content header-text wow fadeInLeft" data-wow-duration="1s" data-wow-delay="1s">
													<h6>ยินดีต้อนรับสู่ FBOUSE</h6>
													<h2>บริการ <em>ทำการตลาด</em> &amp; <span>Social</span> Marketing</h2>
													<p>บริการเพิ่มความน่าเชื่อถือให้กับธุรกิจของคุณ เพิ่มคอมเมนต์หน้าม้า เพิ่มยอดคนดู  เพิ่มยอดไลท์ ยอดผู้ติดตาม ยอดวิว เพิ่มจำนวนคนดู  Live สด  เรามีมากกว่า 1000 บริการให้ท่านเลือกใช้</p>

												</div>
												<div className="auth-login-form-home">

													<div className="form-login" style={{"position": "relative"}}>
														{isLoading ? (
															<div className="preloader">
																<div className="spinner-grow spinner-grow-sm text-primary" role="status" style={{ "marginTop": "13%" }}>
																  <span className="sr-only"></span>
																</div>
																<div className="spinner-grow spinner-grow-sm text-danger" role="status" style={{ "marginTop": "13%" }}>
																  <span className="sr-only">.</span>
																</div>
																<div className="spinner-grow spinner-grow-sm text-warning" role="status" style={{ "marginTop": "13%" }}>
																  <span className="sr-only"></span>
																</div>
															</div>
														):('')}
														{this.state.msg ? (
															<div className="alert alert-warning d-flex align-items-center justify-content-between" role="alert" >
															  <div className="flex-fill mr-3">
																  {
																	this.state.msg.indexOf('Vui lòng điền đầy đủ') > -1 ?
																		<p className="mb-0">กรุณากรอกให้ครบถ้วน</p>
																	: ''
																  }
																  {
																	this.state.msg.indexOf('Sai mật khẩu') > -1 ?
																		<p className="mb-0">รหัสผ่านผิด</p>
																	: ''
																  }
															  </div>
															  <div className="flex-00-auto">
																<i className="fa fa-fw fa-exclamation-circle"></i>
															  </div>
															</div>
														  ) : null}
														<form className="js-validation-signup" onSubmit={this.onSubmit} method="POST" >
															<div className="form-group">
																<div className="input-icon mb-5">
																	<span className="input-icon-addon">
																		<i className="fa fa-user"></i>
																	</span>
																	<input name="username" placeholder="เข้าสู่บัญชีของคุณ" required="" id="username" type="text" className="is-untouched is-pristine av-valid form-control" onChange={this.onChange} />
																</div>    

																<div className="input-icon mb-5">
																	<span className="input-icon-addon">
																		<i className="fa fa-key"></i>
																	</span>
																	<input name="password" placeholder="ใส่รหัสผ่าน" required="" id="password" type="password" className="is-untouched is-pristine av-valid form-control" onChange={this.onChange} />
																</div>  

																{
																	this.state.form_maxacminh ?
																		<div className="input-icon mb-5">
																			<span className="input-icon-addon">
																				<i className="fa fa-lock"></i>
																			</span>
																			<input name="maxacminh" placeholder="การรับรองความถูกต้องด้วยสองปัจจัย" required="" id="maxacminh" type="text" className="is-untouched is-pristine av-valid form-control" onChange={this.onChange} />
																		</div>  
																	: ""
																}
															</div>
															<div className="form-footer">
																<button type="submit" className="btn-block btn-submit btn btn-danger">เข้าสู่ระบบ</button>
															</div>
														</form>
														<div className="text-center text-muted m-t-20">
															ไม่มีบัญชี?
															<Link to="/" onClick={this.changeFormLogRegister} style={{"marginLeft": "5px"}}>
															  <span>
																ลงทะเบียนบัญชี
															  </span>
															</Link>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className="col-lg-6">
											<div className="right-image wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">
												<img src={bannerrightimage} alt="team meeting"/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div id="about" className="about-us section">
						<div className="container">
							<div className="row">
								<div className="col-lg-4">
									<div className="left-image wow fadeIn" data-wow-duration="1s" data-wow-delay="0.2s">
										<img src={aboutleftimage} alt="person graphic"/>
									</div>
								</div>
								<div className="col-lg-8 align-self-center">
									<div className="services">
										<div className="row">
											<div className="col-lg-6">
												<div className="item wow fadeIn itemwowcus" data-wow-duration="1s" data-wow-delay="0.5s" style={{"display": "block", "width": "max-content"}}>
													<div className="icon">
														<img src={serviceicon01} alt="reporting"/>
													</div>
													<div className="right-text" style={{"float": "left", "height": "200px", "width": "50%"}}>
														<h4>บริการมืออาชีพ</h4>
														<p>
														บริการเเบบมืออาชีพ ตอบสนองความต้องการกลุ่มลูกค้าของคุณ เข้าถึงกลุ่มเป้าหมายมากที่สุด</p>
													</div>
												</div>
											</div>
											<div className="col-lg-6">
												<div className="item wow fadeIn itemwowcus" data-wow-duration="1s" data-wow-delay="0.7s" style={{"display": "block", "width": "max-content"}}>
													<div className="icon">
														<img src={serviceicon02} alt=""/>
													</div>
													<div className="right-text" style={{"float": "left", "height": "200px", "width": "50%"}}>
														<h4>ความสเถียร</h4>
														<p>
														ระบบการตลาดที่มีความสเถียรสามารถทำงานใช้เวลาทำตลาดน้อยที่สุด เพื่อเข้าถึงกลุ่มเป้าหมายเร็วสุด</p>
													</div>
												</div>
											</div>
											<div className="col-lg-6">
												<div className="item wow fadeIn itemwowcus" data-wow-duration="1s" data-wow-delay="0.9s" style={{"display": "block", "width": "max-content"}}>
													<div className="icon">
														<img src={serviceicon03} alt=""/>
													</div>
													<div className="right-text" style={{"float": "left", "height": "200px", "width": "50%"}}>
														<h4>ระยะเวลา</h4>
														<p>การทำงานตามเป้าหมาย ทำงานได้อย่างมีประสิทธิภาพ  เพื่อการเเข่งขันที่มีประสิทธิภาพ</p>
													</div>
												</div>
											</div>
											<div className="col-lg-6">
												<div className="item wow fadeIn itemwowcus" data-wow-duration="1s" data-wow-delay="1.1s" style={{"display": "block", "width": "max-content"}}>
													<div className="icon">
														<img src={serviceicon04} alt=""/>
													</div>
													<div className="right-text" style={{"float": "left", "height": "200px", "width": "50%"}}>
														<h4>กลุ่มเป้าหมาย</h4>
														<p>เข้าถึงกลุ่มเป้าหมายที่ในระบบออนไลน์อย่าง youtube instragram facebook อื่นๆมากมาย</p>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div id="services" className="our-services section">
						<div className="container">
							<div className="row">
								<div className="col-lg-6 align-self-center  wow fadeInLeft" data-wow-duration="1s" data-wow-delay="0.2s">
									<div className="left-image">
										<img src={servicesleftimage} alt=""/>
									</div>
								</div>
								<div className="col-lg-6 wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.2s">
									<div className="section-heading">
										<h2>บริการของเรา</h2>
										<p>จากประสบการดำเนินการตลาดออนไลน์มายาวนาน เราจึงเล็งเห็นการทำการตลาดออนไลน์ในปัจจุบัน ที่จะช่วยท่านสร้างความน่าเชื่อถือในด้านสินค้า สร้างความน่าเชื่อถือของภาพลักษณ์บริษัท บริการเกี่ยวกับการตลาดออนไลน์ของเราจึงมุ่งเน้นระบบที่ทำงานเอา</p>
										<div className="left-image">
											<img src={b1} alt=""/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div id="howpay" className="our-portfolio section" style={{"marginBottom": "200px"}}>
						<div className="container">
							<div className="row">
							<div className="col-lg-6 offset-lg-3">
							<div className="section-heading  wow bounceIn" data-wow-duration="1s" data-wow-delay="0.2s">
							<h2>ระบบทำงาน <em>อัตโนมัติ</em> &amp; ทันสมัย<span>รวดเร็ว</span></h2>
							</div>
							</div>
							</div>
							<div className="row">
								<div className="col-lg-3 col-sm-6 cusblock">
									<a href="#">
										<div className="item wow bounceInUp" data-wow-duration="1s" data-wow-delay="0.3s" style={{"marginLeft": "20px"}}>
											<div className="hidden-content" style={{"height": "max-content", "width": "300px"}}>
												<h4>สมัครสมาชิก</h4>
												<p>สมาชิกรับชื่อผู้ใช้งาน-รหัสผ่านอัตโนมัติ &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
											</div>
											<div className="showed-content">
												<img src={portfolioimage} alt=""/>
											</div>
										</div>
									</a>
								</div>
								<div className="col-lg-3 col-sm-6 cusblock">
									<a href="#">
										<div className="item wow bounceInUp" data-wow-duration="1s" data-wow-delay="0.4s" style={{"marginLeft": "20px"}}>
											<div className="hidden-content" style={{"height": "max-content", "width": "300px"}}>
												<h4>เข้าระบบสำรวจบริการ</h4>
												<p>เข้าระบบสำรวจบริการความต้องการ การตลาดของท่านได้อัตโนมัติ</p>
											</div>
											<div className="showed-content">
												<img src={portfolioimage} alt=""/>
											</div>
										</div>
									</a>
								</div>
								<div className="col-lg-3 col-sm-6 cusblock">
									<a href="#">
										<div className="item wow bounceInUp" data-wow-duration="1s" data-wow-delay="0.5s" style={{"marginLeft": "20px"}}>
											<div className="hidden-content" style={{"height": "max-content", "width": "300px"}}>
												<h4>สั่งซื้อบริการ</h4>
												<p>ชำระบริการอัตโนมัติ ผ่านช่องทาง การขำระเงิน ตรวจสอบอย่างรวดเร็ว</p>
											</div>
											<div className="showed-content">
												<img src={portfolioimage} alt=""/>
											</div>
										</div>
									</a>
								</div>
								<div className="col-lg-3 col-sm-6">
									<a href="#">
										<div className="item wow bounceInUp" data-wow-duration="1s" data-wow-delay="0.6s" style={{"marginLeft": "20px"}}>
											<div className="hidden-content" style={{"height": "max-content", "width": "300px"}}>
												<h4>ระบบทำงานอัตโนมัติ</h4>
												<p>ทำการตลาด Social marketing ของท่านได้อย่างอัตโนมัติเเละอิสระ.</p>
											</div>
											<div className="showed-content">
												<img src={portfolioimage} alt=""/>
											</div>
										</div>
									</a>
								</div>
							</div>
						</div>
					</div>
					<div id="question" className="our-blog section">
							<div className="container">
							<div className="row">
								<div className="col-lg-6 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.25s">
									<div className="section-heading">
										<h2>ถาม <em>-</em> ตอบ<span>ข้อสงสัย</span></h2>
									</div>
								</div>
								<div className="col-lg-6 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.25s">
									<div className="top-dec">
										<img src={blogdec} alt=""/>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-lg-6 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.25s">
									<div className="left-image">
										<a href="#"><img src={bigblogthumb} alt="Workspace Desktop"/></a>
										<div className="info">
											<div className="inner-content">

												<a href="#"><h4>ทำไมต้องใช้บริการเรา</h4></a>
												<p>เรามีบริการมากมายที่ตอบโจทย์การตลาดยุคปัจจุบันอย่างสูง ด้วยระบบที่ทันสมัย สเถียรสูง มีการตลาดหลายช่องทางให้ลูกค้าเลือกเพื่อธุรกิจของท่านเติบโตอย่างรวจเร็ว เพิ่มยอดขายให้สูงขึ้น</p>
												<div className="main-blue-button">

												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="col-lg-6 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.25s">
									<div className="right-list">
										<ul>
											<li>
												<div className="left-content align-self-center">

													<a href="#"><h4>ทำงานอย่างไร</h4></a>
													<p>เพียงท่านใช้บริการของเรา ทางเรามีทีมงานคอยเเนะนำเเละ ดูเเลระบบ เพื่อช่วยเหลือการตลาดของท่านให้ ทำงานได้อย่างราบรื่น</p>
												</div>
												<div className="right-image">
													<a href="#"><img src={blogthumb01} alt=""/></a>
												</div>
											</li>
											<li>
												<div className="left-content align-self-center">

													<a href="#"><h4>ระบบเเตกต่างจากที่อื่นอย่างไร</h4></a>
													<p>ระบบที่ทำงานอัตโนมัติ ท่านสามารถดูการเปลื่อนเเปลงของธุรกิจของท่านได้ มีรายงานการทำงานในระบบ ท่านสามารถเลือกเเพ็คเก็จเองได้ สามารถทำการตลาดด้วยตนเอง มีรายงานผลให้ดูเเบบ realtime</p>
												</div>
												<div className="right-image">
													<a href="#"><img src={blogthumb01} alt=""/></a>
												</div>
											</li>
											<li>
												<div className="left-content align-self-center">

													<a href="#"><h4>เริ่มต้นอย่างไร</h4></a>
													<p>เพียงเเค่ตัดสินใจ ติดต่อเรา สมัครทำการตลาดกับเรา ธุรกิจของท่านจะเติบโตไปอย่างก้าวกระโดดแน่นอน</p>
												</div>
												<div className="right-image">
													<a href="#"><img src={blogthumb01} alt=""/></a>
												</div>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div id="contact" className="contact-us section">
						<div className="container">
							<div className="row">
								<div className="col-lg-6 align-self-center wow fadeInLeft" data-wow-duration="0.5s" data-wow-delay="0.25s">
									<div className="section-heading">
										<h2>มาร่วมงานกับเรา</h2>
										<p>สามารถติดต่อเรา สอบถามบริการต่างๆ </p>
										<div className="phone-info">
											<h4>โทรศัพท์ : <span><i className="fa fa-phone fa-rotate-90"></i> <a href="#">0639467361</a></span></h4>
										</div>
									</div>
								</div>
								<div className="col-lg-6 wow fadeInRight" data-wow-duration="0.5s" data-wow-delay="0.25s">
									<form id="contact" action="" method="post">
										<div className="row">
											<div className="col-lg-3">
												<fieldset>
													Facebook 
												</fieldset>
											</div>
											<div className="col-lg-9">
												<fieldset>
													https://www.facebook.com/adthaigg
												</fieldset>
											</div>
											<div className="col-lg-3">
												<fieldset>
													Line:
												</fieldset>
											</div>
											<div className="col-lg-9">
												<fieldset>
													https://page.line.me/fbtouse 
												</fieldset>
											</div>

										</div>
										<div className="contact-dec">
											<img src={contactdecoration} alt=""/>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
					<footer>
						<div className="container">
							<div className="row">
								<div className="col-lg-12 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.25s">
									<p>© Copyright 2021  All Rights Reserved. 

									</p>
								</div>
							</div>
						</div>
					</footer>
				</div>
			</Fragment>
		</Router>
	);
	const formRegister = (
		<Fragment>
			<RegisterModal6 action={this.changeFormLogRegister} />
		</Fragment>
	);
	return (
		<Fragment>
			{this.state.modal ? formRegister : formLogin }
		</Fragment>
	);
  }
}
Login.propTypes = {
  auth: PropTypes.object.isRequired,
  error: PropTypes.object,
  login: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  error: state.error
});
export default connect(mapStateToProps, { login, clearErrors })(Login);
