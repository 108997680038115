import {
	LOAD_REPORTYOUTUBE,
	UPDATE_REPORTYOUTUBE_TAB,
	UPDATE_REPORTYOUTUBE,
	UPDATE_REPORTYOUTUBE_IDFB,
	UPDATE_RIPACC_REPORTYOUTUBE_LIENHE,
	LOAD_PRICE_REPORTYOUTUBE,
	LOADING_REPORTYOUTUBE,
	LIMIT_PAGI_REPORTYOUTUBE,
	TYPE_REPORTYOUTUBE,
} from "../actions/types";

const date7dnext = () => {
	var result = new Date();
  	result.setDate(result.getDate() + 7);
  	return result;
}

const initialState = {
	"taotientrinh": {
		"profile_user": "",
		"loaireport": "0",
		"quatay": "0",
		"namtao": "",
		"sdt": "",
		"fb": "",
		"ghichu": "",
		"gtmtt": "0",
	},
	"changetab": 0,
	"listtientrinh": [],
	"price": {
		min_bh_web: 0,
		prices_web: 0,
	},
	"loading": false,
	"limit_pagi": 0,
	"type": 0,
	youtubedanhgay_video_mo_dong: 1,
};

export default function(state = initialState, action) {
	switch (action.type) {
		case LOAD_REPORTYOUTUBE:
			return {
				...state,
				"listtientrinh": action.payload
			};
		case UPDATE_REPORTYOUTUBE_TAB:
			return {
				...state,
				"changetab": action.payload
			};
		case UPDATE_REPORTYOUTUBE:
			return {
				...state,
				"taotientrinh": action.payload
			};
		case UPDATE_REPORTYOUTUBE_IDFB:
			return {
				...state,
				"taotientrinh": {
					...state.taotientrinh,
					"profile_user": action.payload
				}
			};
		case UPDATE_RIPACC_REPORTYOUTUBE_LIENHE:
			return {
				...state,
				"taotientrinh": {
					...state.taotientrinh,
					"fb": action.payload
				}
			};
		case LOAD_PRICE_REPORTYOUTUBE:
			return {
				...state,
				"price": action.payload,
				"taotientrinh": {
					...state.taotientrinh,
					"gtmtt": action.payload.prices_web
				}
			};
		case LOADING_REPORTYOUTUBE:
			return {
				...state,
				"loading": action.payload
			};
		case LIMIT_PAGI_REPORTYOUTUBE:
			return {
				...state,
				"limit_pagi": action.payload
			};
		case TYPE_REPORTYOUTUBE:
			return {
				...state,
				"type": action.payload
			};
		case 'CONFIG_MO_DONG_REPORTYOUTUBE':
			return {
				...state,
				youtubedanhgay_video_mo_dong: action.payload.youtubedanhgay_video_mo_dong,
			};
		default:
		  return state;
	}
}
