import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import {
    Modal,
    ModalBody,
    ModalFooter
} from 'reactstrap';
import { editFbVipClone } from '../../../actions/FbvipActions';

class Modal_edit extends Component {
    state = {
        id: this.props.dataid,
        status: this.props.datastatus,
        bltk: this.props.databo_loc_tu_khoa,
        dsif: this.props.datadanh_sach_id_facebook,
    }
	
	onChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value
		});
	}

    render() {
        const {isOpenModal, dataid} = this.props;
        const {id, status, bltk, dsif} = this.state;
		const { changetab, loadingblock } = this.props.buffviplikegroup;

        return (
            <Fragment>
                <Modal centered={true} size="lg" toggle={(e) => {this.props.openModal()}} isOpen={isOpenModal} className={(loadingblock? ' block block-mode-loading' : '')} style={{"backgroundColor": "inherit", "boxShadow": "none"}}>
                    <div className="modal-header">
                        <p className="mb-0 text-dark">Sửa cookie</p>
                        <button onClick={(e) => {this.props.openModal()}} type="button" className="close">×</button>
                    </div>
                    <ModalBody style={{opacity: loadingblock? '.05':'1'}}>
                        <div className="row">
							{
								this.props.auth.user.type === 1 ?
									<div className="col-md-12">
										<div className="form-group">
											<label>Trạng thái</label>
											<select name="status" onChange={this.onChange} value={status} className="form-control">
												<option value="0">Hoàn thành</option>
												<option value="1">Đang chạy</option>
												<option value="2">Id lỗi</option>
												<option value="3">Hủy đơn</option>
												<option value="5">Hoàn tiền</option>
											</select>
										</div>
									</div>
								: ''
							}
							<div className="row mt-2 col-md-12">
								<div className="col-md-4">
									<label className="mt-2">Bộ lọc từ khóa:</label>
									<h6 className="font-bold green font-14">Nếu không có từ khóa, hệ thống<br/>sẽ Like tất cả các bài viết</h6>
								</div>
								<div className="col-sm-8">
									<div className="form-group">
										<textarea rows="2" placeholder="Mỗi từ khóa cách nhau một dấu phẩy" className="form-control input-gray" id="bltk" name="bltk" onChange={this.onChange} >{bltk}</textarea>
										<h6 className="green font-14">Chỉ các bài viết có <em>từ khóa trên đây</em> mới tiến hành like, <b className="font-bold">mỗi từ khóa cách nhau một dấu phẩy</b></h6>
									</div>
								</div>
							</div>
							<div className="row mt-2 col-md-12">
								<div className="col-md-4">
									<label className="mt-2">Danh sách ID Facebook:</label>
									<h6 className="font-bold green font-14">Nếu không có ID Facebook, hệ thống<br/>sẽ Like tất cả các bài viết có trong nhóm</h6>
								</div>
								<div className="col-sm-8">
									<div className="form-group">
										<textarea rows="2" placeholder="Mỗi ID Facebook cách nhau một dấu phẩy" className="form-control input-gray" id="dsif" name="dsif" onChange={this.onChange} >{dsif}</textarea>
										<h6 className="green font-14">Chỉ các bài viết của tài khoản có trong <em>danh sách ID Facebook trên đây</em> mới tiến hành like, <b className="font-bold">mỗi ID Facebook cách nhau một dấu phẩy</b></h6>
									</div>
								</div>
							</div>
                        </div>
                    </ModalBody>
                    <ModalFooter style={{opacity: loadingblock? '.05':'1'}}>
                        <button onClick={() => {this.props.editFbVipClone('viplike_group', dataid, status, this.props.openModal, bltk, dsif)}} type="button" className="btn btn-primary">Lưu</button>
                        <button onClick={(e) => {this.props.openModal()}} type="button" className="btn btn-danger">Huỷ</button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
	auth: state.auth,
    buffviplikegroup: state.buffviplikegroup
});

export default connect(mapStateToProps, {editFbVipClone}) (Modal_edit);
