import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { login } from "../../actions/authActions";
import { clearErrors } from "../../actions/errorActions";
import RegisterModal3 from './Register3';
import ServiceModal3 from './Service3';
import ForgotPasswordModal3 from './ForgotPassword3';
import buy_comment from '../../iconlanding/3/buy_comment.svg';
import buy_like from '../../iconlanding/3/buy_like.svg';
import buy_user from '../../iconlanding/3/buy_user.svg';
import buy_view from '../../iconlanding/3/buy_view.svg';
import features_accaunts from '../../iconlanding/3/features_accaunts.svg';
import features_bag from '../../iconlanding/3/features_bag.svg';
import features_target from '../../iconlanding/3/features_target.svg';
import instruction_1 from '../../iconlanding/3/instruction_1.svg';
import list_1 from '../../iconlanding/3/list_1.svg';
import list_2 from '../../iconlanding/3/list_2.svg';
import list_3 from '../../iconlanding/3/list_3.svg';
import list_4 from '../../iconlanding/3/list_4.svg';
import main_features_gift from '../../iconlanding/3/main_features_gift.svg';
import main_features_lock from '../../iconlanding/3/main_features_lock.svg';
import main_features_person from '../../iconlanding/3/main_features_person.svg';
import screenshot_after from '../../iconlanding/3/screenshot_after.jpg';
import screenshot_before from '../../iconlanding/3/screenshot_before.jpg';
import star_left from '../../iconlanding/3/star_left.svg';
import star_right from '../../iconlanding/3/star_right.svg';
import bg_full from '../../iconlanding/3/bg_full.svg';
import vEdmaFB from '../../iconlanding/3/vEdmaFB.png';
import MiroJ6t from '../../iconlanding/3/MiroJ6t.png';
import bannerheader from '../../iconlanding/3/bannerheader.png';
import {
  BrowserRouter as Router,
  Link
} from "react-router-dom";
class Login extends Component {
  state = {
	modal: 1,
    msg: "",
    username: "",
    password: "",
    maxacminh: "",
    form_maxacminh: false,
    isLoading: false
  };
  componentDidMount(){
	if (this.props.typeform == 3) {
	  this.setState({ modal: 3 });
	}
  }
  componentDidUpdate(prevProps) {
    const { error } = this.props;
    if (error !== prevProps.error) {
      // Check for login error
      if (error.id === "LOGIN_FAIL") {
        this.setState({ msg: error.msg.msg });
      } else if (error.id === "LOGIN_FAIL_2FA") {
        this.setState({ msg: error.msg.msg, form_maxacminh: true });
      } else {
        this.setState({ msg: null });
      }
    }
  }
	clearAlert = () => {
		// Clear errors
		this.props.clearErrors();
		// this.setState({
			// modal: !this.state.modal
		// });
	}
  changeFormLogRegister = (typeform) => {
	this.props.clearErrors();
	this.setState({
		modal: typeform
	});
	
}
  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };
  onSubmit = e => {
    e.preventDefault();
    const { username, password, maxacminh } = this.state;
    this.props.clearErrors();
    this.props.login({ username, password, maxacminh });
  };
  render() {
    let isLoading = this.props.auth.isLoading;
    var domainSite = window.location.hostname;
	var config_website = {};
	if (localStorage.getItem("confignavbar") != null) {
		config_website = JSON.parse(localStorage.getItem("confignavbar"));
	} else {
		config_website = this.props.auth.config_website;
	}
	const formLogin = (
		<Router>
			<Fragment>
				<style>
					{"\
						.landingcus3 {\
							 background-image: url("+bg_full+");\
							 background-position: center top;\
							 background-origin: padding-box;\
							 background-repeat: no-repeat;\
							 background-size: cover;\
							 background-color: #292946;\
						}\
						.landingcus3 .inner-form-all .single-form-blk:first-child:after {\
							background: #8641bf url("+main_features_person+");\
							background-size: 23px;\
							background-repeat: no-repeat;\
							background-position-x: 12px;\
							background-position-y: 8px;\
						}\
						.landingcus3 .inner-form-all .single-form-blk:nth-child(2):after {\
							background-image: url("+main_features_lock+");\
							background-size: 24px;\
							background-repeat: no-repeat;\
							background-position-x: 12px;\
							background-position-y: 8px;\
						}\
						.landingcus3 .single-form-blk-cusicon:after {\
							background-image: url("+main_features_lock+");\
							background-size: 24px;\
							background-repeat: no-repeat;\
							background-position-x: 12px;\
							background-position-y: 8px;\
						}\
						.landingcus3 .inner-form-all .single-form-blk:first-child:after {\
							background-image: url("+vEdmaFB+");\
							background-position-x: 9px;\
						}\
						.landingcus3 .inner-form-all .single-form-blk:nth-child(2):after {\
							background-image: url("+MiroJ6t+");\
							background-position-x: 9px;\
						}\
						.landingcus3 .single-form-blk-cusicon:after {\
							background-image: url("+MiroJ6t+");\
							background-position-x: 9px;\
						}\
					"}
				</style>
				<div className="landingcus3" style={{"zoom": "122%"}}>
					<div id="backClick" className="relative">
						<div ng-controller="landingController" className="top-background ng-scope">
							<nav className="navbar navbar-expand-lg navbar-dark fixed-top navbar-height" id="mainNav">
								<div className="container padding_0">
									<a className="text-white font-w700 font-size-h3" href="/" style={{"width": "350px", "wordBreak": "break-all"}} >
									  <span className="text-white text-uppercase">{domainSite.indexOf('tools1s.com') < 0 ? domainSite.slice(0, domainSite.lastIndexOf('.')) : domainSite.slice(0, domainSite.lastIndexOf('.')).replace('vip.', '') }</span>
									  <span className="text-white text-uppercase">{domainSite.indexOf('.') > -1 && domainSite.indexOf('tools1s.com') < 0 ? domainSite.slice(domainSite.lastIndexOf('.')) : ''}</span>
									</a>
									<Link to="/services" onClick={(e) => {this.changeFormLogRegister(3)}} className="btn btn-pill sign-up registercus" style={{"background": "#8641bf", "color": "white"}}>
										Dịch vụ
									</Link>
									<Link to="/" onClick={(e) => {this.changeFormLogRegister(2)}} className="btn btn-pill sign-up registercus" style={{"background": "#8641bf", "color": "white"}}>
										Đăng ký
									</Link>
									<div className="collapse navbar-collapse navbar-opened-plashka">
										<ul className="navbar-nav text-uppercase ml-auto">
											{/* <li className="nav-item">
												<a className="nav-link js-scroll-trigger navbar-text ng-binding" href="/"><font style={{"verticalAlign": "inherit"}}>
													<font style={{"verticalAlign": "inherit"}}>HOME</font></font>
												</a>
											</li>
											<li className="nav-item">
												<a className="nav-link js-scroll-trigger navbar-text ng-binding" href="#how-it-works"><font style={{"verticalAlign": "inherit"}}><font style={{"verticalAlign": "inherit"}}>HOW IT WORKS</font></font></a>
											</li>
											<li className="nav-item">
												<a className="nav-link js-scroll-trigger navbar-text" href="#why-us"><font style={{"verticalAlign": "inherit"}}><font style={{"verticalAlign": "inherit"}}>WHY TO CHOOSE</font></font></a>
											</li>
											<li className="nav-item">
												<a className="nav-link js-scroll-trigger navbar-text ng-binding" href="/services"><font style={{"verticalAlign": "inherit"}}><font style={{"verticalAlign": "inherit"}}>SERVICES</font></font></a>
											</li>

											<li className="nav-item">
												<a className="nav-link js-scroll-trigger navbar-text ng-binding" href="/terms"><font style={{"verticalAlign": "inherit"}}><font style={{"verticalAlign": "inherit"}}>TERMS</font></font></a>
											</li>
											<li className="nav-item">
											</li> */}
											<li className="nav-item">
												<Link to="/services" onClick={(e) => {this.changeFormLogRegister(3)}} className="nav-link js-scroll-trigger navbar-text ng-binding">
													Dịch vụ
												</Link>
											</li>
											<li className="nav-item">
												<a href="/" className="nav-link js-scroll-trigger navbar-text ng-binding">Đăng nhập</a>
											</li>
											<li className="nav-item">
												<Link to="/" onClick={(e) => {this.changeFormLogRegister(2)}} className="btn nav-link js-scroll-trigger  my-button-wrap  ng-binding">
													Đăng ký
												</Link>
											</li>
										</ul>
									</div>
								</div>
							</nav>
							<header className="masthead header-background back-section">
								<div className="container padding_0">
									<h1 className="insta_get_like ng-binding"><font style={{"verticalAlign": "inherit"}}><font style={{"verticalAlign": "inherit"}}>Dịch vụ số 1 và DUY NHẤT mà bạn sẽ cần!</font></font></h1>
									<div className="top-container">
										<div className="top-site-description">
											<div className="intro-text text-left">
												<a className="link-fx text-white font-w700 font-size-h1" href="/" style={{"position": "relative"}} >
												  <span className="text-white text-uppercase">{domainSite.slice(0, domainSite.lastIndexOf('.'))}</span>
												  <span className="text-white text-uppercase">{domainSite.indexOf('.') > -1 ? domainSite.slice(domainSite.lastIndexOf('.')) : ''}</span>
												</a>
												<h1 className="inta_get_likes_web ng-binding"><font style={{"verticalAlign": "inherit"}}><font style={{"verticalAlign": "inherit"}}>Dịch vụ số 1 và DUY NHẤT mà bạn sẽ cần!</font></font></h1>
												<p className="top-32 opacity_08 ng-binding"><font style={{"verticalAlign": "inherit"}}><font style={{"verticalAlign": "inherit"}}>Nhận hàng trăm Dịch vụ Truyền thông Xã hội Chất lượng Cao chỉ trong một cú nhấp chuột. {domainSite} là một dịch vụ với hơn 5 năm trên thị trường và hơn 40 triệu đơn đặt hàng được xử lý thành công!
													</font></font>
												</p>
												<p className="top-32 opacity_08 ng-binding"><font style={{"verticalAlign": "inherit"}}><font style={{"verticalAlign": "inherit"}}>🏆 Nền tảng tăng tương tác uy tín và tin cậy nhất cho các dịch vụ truyền thông mạng xã hội. 👈
													</font></font>
												</p>
												<Link to="/" onClick={(e) => {this.changeFormLogRegister(2)}} style={{"background": "#8641bf", "color": "white", "borderRadius": "6px", "padding": "20px 42px", "fontWeight": "bold"}} className="btn top-32 ng-binding">
													<font style={{"verticalAlign": "inherit"}}><font style={{"verticalAlign": "inherit"}}>BẮT ĐẦU NGAY</font></font>
												</Link>
											</div>
										</div>
										<div className="top-site-description">
											<div className="intro-text text-left">
												<div className="video_box">
													<img src={bannerheader} style={{"width": "570px", "marginTop": "-90px"}}/>
												</div>
											</div>
										</div>
										<div className="text-left intro-text-mobile">
											<p className="top-32 opacity_08 ng-binding"><font style={{"verticalAlign": "inherit"}}><font style={{"verticalAlign": "inherit"}}>Nhận lượt thích, bình luận và lượt xem từ những người thực, đồng thời tăng số lượng người đăng ký miễn phí.</font></font></p>
											<p className="top-32 opacity_08 ng-binding"><font style={{"verticalAlign": "inherit"}}><font style={{"verticalAlign": "inherit"}}>Chỉ cần chọn ảnh và video bạn muốn quảng cáo và xem kết quả.</font></font></p>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-lg-12 col-md-12">
										<div className="sign-in-blk">
											{isLoading ? (
												<div className="preloader" style={{"top": "-35%"}}>
													<div className="spinner-grow spinner-grow-sm text-primary" role="status" style={{ "marginTop": "3%" }}>
													  <span className="sr-only"></span>
													</div>
													<div className="spinner-grow spinner-grow-sm text-danger" role="status" style={{ "marginTop": "3%" }}>
													  <span className="sr-only">.</span>
													</div>
													<div className="spinner-grow spinner-grow-sm text-warning" role="status" style={{ "marginTop": "3%" }}>
													  <span className="sr-only"></span>
													</div>
												</div>
											):('')}
											{this.state.msg ? (
												<div className="alert alert-warning d-flex align-items-center justify-content-between" role="alert" >
												  <div className="flex-fill mr-3">
													<p className="mb-0">{this.state.msg}</p>
												  </div>
												  <div className="flex-00-auto">
													<i className="fa fa-fw fa-exclamation-circle"></i>
												  </div>
												</div>
											  ) : null}
											<form className="js-validation-signup" onSubmit={this.onSubmit} method="POST" >
												<div className="inner-form-all">
													<div className="single-form-blk">
														<input name="username" placeholder="Nhập tài khoản" required="" id="username" type="text" className="" onChange={this.onChange} />
													</div>
													<div className="single-form-blk">
														<input name="password" placeholder="Nhập mật khẩu" required="" id="password" type="password" className="" onChange={this.onChange} />
													</div>
													<div className="single-form-blk">
														<button type="submit" className="">Đăng nhập</button>
													</div>
												</div>
												<div className="form-bttom-info" style={{"width": "100%", "display": "block"}}>
													<div className="single-btm-blk-info" style={{"maxWidth": "81%"}}>
														<div className="form-cehck-section">
															{
																this.state.form_maxacminh ?
																	<div className="single-form-blk single-form-blk-cusicon" style={{"maxWidth": "inherit", "padding": "0px"}}>
																		<input name="maxacminh" placeholder="Nhập mã xác minh" required="" id="maxacminh" type="text" className="" onChange={this.onChange} />
																	</div>
																: ""
															}
														</div>
													</div>
												</div>
												<div className="form-bttom-info" style={{"width": "100%", "display": "block"}}>
													<div className="single-btm-blk-info" style={{"maxWidth": "81%"}}>
														<div className="form-cehck-section">
															<span className="pull-left" style={{"float":"left", "width": "50%", "textAlign": "left"}}>Bạn chưa có tài khoản?
																<Link to="/" onClick={(e) => {this.changeFormLogRegister(2)}} amino-styles="color: #aa6dff;" style={{"marginLeft": "5px"}}>
																  <span>
																	Đăng ký
																  </span>
																</Link>
															</span>
															<span className="pull-left" style={{"float":"right", "width": "50%", "textAlign": "right"}}>
																<Link to="/" onClick={(e) => {this.changeFormLogRegister(4)}}>
																	Quên mật khẩu?
																</Link>
															</span>
														</div>
													</div>
												</div>
											</form>
										</div>
									</div>
								</div>
							</header>
						</div>
						<div className="main-background">
							<section className="section-about back-section" id="about_us">
								<div className="container padding_0 container-about">
									<div className="about_us_plashka">
										<img className="img-plashka" src={main_features_gift} alt=""/>
										<h2 className="top-text-plashka ng-binding text-white"><font style={{"verticalAlign": "inherit"}}><font style={{"verticalAlign": "inherit"}}>Giá không thể tin được
											</font></font>
										</h2>
										<p className="regular-text-plashka ng-binding"><font style={{"verticalAlign": "inherit"}}><font style={{"verticalAlign": "inherit"}}>Giá của chúng tôi rẻ nhất trên thị trường, bắt đầu từ 1 VNĐ
											</font></font>
										</p>
									</div>
									<div className="about_us_plashka">
										<img className="img-plashka" src={main_features_person} alt=""/>
										<h2 className="top-text-plashka ng-binding text-white"><font style={{"verticalAlign": "inherit"}}><font style={{"verticalAlign": "inherit"}}>HỖ TRỢ 24/7
											</font></font>
										</h2>
										<p className="regular-text-plashka ng-binding"><font style={{"verticalAlign": "inherit"}}>
											<font style={{"verticalAlign": "inherit"}}>Chúng tôi tự hào có sự hỗ trợ tốt nhất, trả lời bạn 24/7.
											</font><font style={{"verticalAlign": "inherit"}}></font></font>
										</p>
									</div>
									<div className="about_us_plashka">
										<img className="img-plashka" src={main_features_lock} alt=""/>
										<h2 className="top-text-plashka ng-binding text-white"><font style={{"verticalAlign": "inherit"}}><font style={{"verticalAlign": "inherit"}}>Tốc độ xử lý trong vài phút
											</font></font>
										</h2>
										<p className="regular-text-plashka bot_16 ng-binding"><font style={{"verticalAlign": "inherit"}}><font style={{"verticalAlign": "inherit"}}>Việc xử lý của chúng tôi được tự động hóa và thường mất vài phút nếu không phải vài giây để giao đơn hàng của bạn..
											</font></font>
										</p>
									</div>
								</div>
								<div className="container rounded-container">
									<div className="rounded-plashka">
										<img className="img-circle-plashka" src={features_accaunts} alt=""/>
										<h2 className="rounded-plashka-top-text ng-binding text-white"><font style={{"verticalAlign": "inherit"}}><font style={{"verticalAlign": "inherit"}}>TRANG WEB THÂN THIỆN
											</font></font>
										</h2>
										<p className="rounded-plashka-text ng-binding"><font style={{"verticalAlign": "inherit"}}><font style={{"verticalAlign": "inherit"}}>Chúng tôi có bảng điều khiển thân thiện! Được cập nhật thường xuyên với các tính năng thân thiện với người dùng tốt nhất. Chúng tôi đã thực hiện chuyển đổi giữa chúng dễ dàng.
											</font></font>
										</p>
									</div>
									<div className="rounded-plashka">
										<img className="img-circle-plashka" src={features_target} alt=""/>
										<h2 className="rounded-plashka-top-text ng-binding text-white"><font style={{"verticalAlign": "inherit"}}><font style={{"verticalAlign": "inherit"}}>Dịch vụ tốt nhất thế giới trên thị trường
											</font></font>
										</h2>
										<p className="rounded-plashka-text ng-binding"><font style={{"verticalAlign": "inherit"}}><font style={{"verticalAlign": "inherit"}}>Chúng tôi rất nổi tiếng trong việc bán các dịch vụ của mình theo một cách độc đáo trên toàn thế giới</font></font></p>
									</div>
									<div className="rounded-plashka">
										<img className="img-circle-plashka" src={features_bag} alt=""/>
										<h2 className="rounded-plashka-top-text ng-binding text-white"><font style={{"verticalAlign": "inherit"}}><font style={{"verticalAlign": "inherit"}}>Nhà cung cấp {domainSite}</font></font></h2>
										<p className="rounded-plashka-text ng-binding"><font style={{"verticalAlign": "inherit"}}><font style={{"verticalAlign": "inherit"}}>Là một công ty cung cấp dịch vụ đáng tin cậy, chúng tôi cung cấp các dịch vụ có trách nhiệm và đáng tin cậy để đáp ứng nhu cầu thay đổi của bạn của một số lượng lớn khách hàng. Bạn có thể mong đợi các giải pháp tiết kiệm chi phí từ chúng tôi. đó là lý do tại sao {domainSite} tốt nhất thế giới</font></font></p>
									</div>
								</div>
							</section>
							<section className="section-about back-section" id="how-it-works">
								<div className="container padding_0">
									<div className="how-it-works-container">
										<div className="it_works_container">
											<div className="intro-text text-left">
												<p className="it_works_text ng-binding"><font style={{"verticalAlign": "inherit"}}><font style={{"verticalAlign": "inherit"}}>LÀM THẾ NÀO NÓ HOẠT ĐỘNG</font></font></p>
												<div className="flex-text flex-text-active">
													<img className="img-list-number" src={list_1} alt=""/>
													<p className="list-text ng-binding"><font style={{"verticalAlign": "inherit"}}><font style={{"verticalAlign": "inherit"}}>
														Đầu tiên bạn cần có tài khoản để đăng nhập sau đó bạn có thể thấy
														trang tổng quan, chúng tôi giúp đăng ký dễ dàng và cơ bản này, giống như
														bạn tạo tài khoản trên mạng xã hội. và đừng lo lắng tất cả các chi tiết là
														an toàn, chúng tôi không chia sẻ thông tin chi tiết của bạn cho người khác.
													</font></font>
													</p>
												</div>
												<div className="flex-text">
													<img className="img-list-number" src={list_2} alt=""/>
													<p className="list-text ng-binding"><font style={{"verticalAlign": "inherit"}}><font style={{"verticalAlign": "inherit"}}>
														Thứ hai, bạn cần tiền gửi vào tài khoản của mình trong tiền gửi {domainSite} là
														dễ dàng và an toàn, và chúng tôi có nhiều khoản thanh toán
														kênh cho bạn.</font></font>
													</p>
												</div>
												<div className="flex-text">
													<img className="img-list-number" src={list_3} alt=""/>
													<p className="list-text ng-binding"><font style={{"verticalAlign": "inherit"}}><font style={{"verticalAlign": "inherit"}}>Thứ ba sau khi bạn có số dư trong tài khoản, vì vậy bây giờ bạn có thể đặt hàng theo mẫu, xem từng bước để xem tất cả dịch vụ và giá cả.
													</font></font>
													</p>
												</div>
												<div className="flex-text">
													<img className="img-list-number" src={list_4} alt=""/>
													<p className="list-text ng-binding"><font style={{"verticalAlign": "inherit"}}><font style={{"verticalAlign": "inherit"}}>Xem khi mức độ phổ biến của tài khoản của bạn tăng lên.</font></font></p>
												</div>
												
												<Link to="/" style={{"background": "#8641bf", "color": "white", "borderRadius": "6px", "padding": "20px 38px"}} className="btn top-32 left_70 ng-binding jjjjjjjjjjjj" onClick={(e) => {this.changeFormLogRegister(2)}}>
													ĐĂNG KÝ NGAY
												</Link>
											
											</div>
										</div>
										<img title="Simple authorization, no password required.  We give 20 coins per wrap." className="image-instruction" src={instruction_1} alt="Simple authorization, no password required.  We give 20 coins per wrap."/>
									</div>
									<div className="how-it-works-container-mobile">
										<p className="mobile-how-works-title ng-binding"><font style={{"verticalAlign": "inherit"}}><font style={{"verticalAlign": "inherit"}}>LÀM THẾ NÀO NÓ HOẠT ĐỘNG</font></font></p>
										<div className="flex-text">
											<img className="img-list-number" src={list_1} alt=""/>
											<p className="list-text ng-binding">Đầu tiên bạn cần có tài khoản để đăng nhập sau đó bạn có thể thấy
														trang tổng quan, chúng tôi giúp đăng ký dễ dàng và cơ bản này, giống như
														bạn tạo tài khoản trên mạng xã hội. và đừng lo lắng tất cả các chi tiết là
														an toàn, chúng tôi không chia sẻ thông tin chi tiết của bạn cho người khác.</p>
										</div>
										<div className="flex-text">
											<img className="img-list-number" src={list_2} alt=""/>
											<p className="list-text ng-binding">Thứ hai, bạn cần tiền gửi vào tài khoản của mình trong tiền gửi {domainSite} là
														dễ dàng và an toàn, và chúng tôi có nhiều khoản thanh toán
														kênh cho bạn.</p>
										</div>
										<div className="flex-text">
											<img className="img-list-number" src={list_3} alt=""/>
											<p className="list-text ng-binding">Thứ ba sau khi bạn có số dư trong tài khoản, vì vậy bây giờ bạn có thể đặt hàng theo mẫu, xem từng bước để xem tất cả dịch vụ và giá cả.</p>
										</div>
										<div className="flex-text">
											<img className="img-list-number" src={list_4} alt=""/>
											<p className="list-text ng-binding">Xem khi mức độ phổ biến của tài khoản của bạn tăng lên.</p>
										</div>
									</div>
									<div className="container-fluid landing-page middle-section pt-2 pb-0" id="why-us">
										<div className="row features-section">
											<div className="col-md-8 mx-auto">
												<div className="row pt-2 pb-2">
													<div className="col-md-12 text-center">
														<h2 className="h1 nice-title text-white"><font style={{"verticalAlign": "inherit"}}><font style={{"verticalAlign": "inherit"}}>🤩 Tại sao chọn </font></font><span><font style={{"verticalAlign": "inherit"}}><font style={{"verticalAlign": "inherit"}}>{domainSite}</font></font></span><font style={{"verticalAlign": "inherit"}}><font style={{"verticalAlign": "inherit"}}> cho nhu cầu </font></font><span><font style={{"verticalAlign": "inherit"}}><font style={{"verticalAlign": "inherit"}}>của bạn</font></font></span></h2>
														<p className="w-75 mx-auto mb-5"><font style={{"verticalAlign": "inherit"}}><font style={{"verticalAlign": "inherit"}}>Tìm nhà cung cấp tốt nhất với giao diện phù hợp với nhu cầu của bạn! </font><font style={{"verticalAlign": "inherit"}}>Kiểm tra lý do tại sao bạn nên tin tưởng dịch vụ mà chúng tôi cung cấp.</font></font></p>
													</div>
												</div>
												<div className="row">
													<div className="col-md-3 grid-margin stretch-card">
													</div>
													<div className="col-md-6 grid-margin stretch-card">
														<div className="card card-icon-top comparision">
															<div className="comparision-us p-3 goodH">
																<h2 className="nice-title " style={{"color": "mediumspringgreen"}}><i className="far fa-check-circle"></i><font style={{"verticalAlign": "inherit"}}><font style={{"verticalAlign": "inherit"}}> {domainSite}</font></font></h2>
															</div>
															<div className="card-body p-2">
																<p><i className="fa fa-thumbs-up" aria-hidden="true"></i><font style={{"verticalAlign": "inherit"}}><font style={{"verticalAlign": "inherit"}}> Chúng tôi chỉ phục vụ các sản phẩm Chất lượng cao và các sản phẩm hoạt động</font></font></p>
																<hr/>
																<p><i className="fa fa-thumbs-up" aria-hidden="true"></i><font style={{"verticalAlign": "inherit"}}><font style={{"verticalAlign": "inherit"}}> Chúng tôi mang đến cho bạn giá cả cạnh tranh nhất trên thị trường</font></font></p>
																<hr/>
																<p><i className="fa fa-thumbs-up" aria-hidden="true"></i><font style={{"verticalAlign": "inherit"}}><font style={{"verticalAlign": "inherit"}}> Chúng tôi có bộ phận hỗ trợ làm việc hàng ngày để hỗ trợ mọi vấn đề của bạn</font></font></p>
																<hr/>
																<p><i className="fa fa-thumbs-up" aria-hidden="true"></i><font style={{"verticalAlign": "inherit"}}><font style={{"verticalAlign": "inherit"}}> Bạn có thể lấy lại tiền cho mọi đơn hàng bạn thực hiện</font></font></p>
																<hr/>
																<p><i className="fa fa-thumbs-up" aria-hidden="true"></i><font style={{"verticalAlign": "inherit"}}><font style={{"verticalAlign": "inherit"}}> Cập nhật hàng ngày các dịch vụ của chúng tôi với các xu hướng mới nhất</font></font></p>
																<hr/>
																<p><i className="fa fa-thumbs-up" aria-hidden="true"></i><font style={{"verticalAlign": "inherit"}}><font style={{"verticalAlign": "inherit"}}> Giá hữu nghị cho người bán với Đơn đặt hàng số lượng lớn</font></font></p>
															</div>
														</div>
													</div>
													<div className="col-md-3 grid-margin stretch-card">
													</div>
													{/* <div className="col-md-6 grid-margin stretch-card">
														<div className="card card-icon-top comparision comparision_negative">
															<div className="comparision-others p-3 badH">
																<h2 className="nice-title " style={{"color": "indianred"}}><i className="far fa-times-circle"></i><font style={{"verticalAlign": "inherit"}}><font style={{"verticalAlign": "inherit"}}> Các trang khác</font></font></h2>
															</div>
															<div className="card-body pt-2">
																<p><i className="fa fa-thumbs-down" aria-hidden="true"></i><font style={{"verticalAlign": "inherit"}}><font style={{"verticalAlign": "inherit"}}> Dịch vụ chất lượng thấp không bao giờ hoạt động hoặc hoạt động thực sự tồi tệ</font></font></p>
																<hr/>
																<p><i className="fa fa-thumbs-down" aria-hidden="true"></i><font style={{"verticalAlign": "inherit"}}><font style={{"verticalAlign": "inherit"}}> Giá cao và thời gian dịch vụ chậm cho đơn đặt hàng của bạn </font></font></p>
																<hr/>
																<p><i className="fa fa-thumbs-down" aria-hidden="true"></i><font style={{"verticalAlign": "inherit"}}><font style={{"verticalAlign": "inherit"}}> Bộ phận hỗ trợ không bao giờ trả lời các vấn đề của bạn kịp thời</font></font></p>
																<hr/>
																<p><i className="fa fa-thumbs-down" aria-hidden="true"></i><font style={{"verticalAlign": "inherit"}}><font style={{"verticalAlign": "inherit"}}> Không hoàn lại tiền trong trường hợp bạn không hài lòng với đơn đặt hàng của mình</font></font></p>
																<hr/>
																<p><i className="fa fa-thumbs-down" aria-hidden="true"></i><font style={{"verticalAlign": "inherit"}}><font style={{"verticalAlign": "inherit"}}> Các dịch vụ lỗi thời thường không hoạt động hoặc bị hỏng</font></font></p>
																<hr/>
																<p><i className="fa fa-thumbs-down" aria-hidden="true"></i><font style={{"verticalAlign": "inherit"}}><font style={{"verticalAlign": "inherit"}}> Kinh doanh từ một năm trở xuống</font></font></p>
															</div>
														</div>
													</div> */}
												</div>
											</div>
										</div>
									</div>
									<div className="earn-money-section">
										<p className="earn-money-top-text ng-binding"><font style={{"verticalAlign": "inherit"}}><font style={{"verticalAlign": "inherit"}}>Dịch vụ tuyệt vời của chúng tôi
											</font></font>
										</p>
										<div className="container-flex-just-cont">
											<div className="earn-plashka">
												<h2 className="top-text-plashka ng-binding text-white"><font style={{"verticalAlign": "inherit"}}><font style={{"verticalAlign": "inherit"}}>LIKES</font></font></h2>
												<p className="text-earn-plashka ng-binding"><font style={{"verticalAlign": "inherit"}}><font style={{"verticalAlign": "inherit"}}>LIKES cho tất cả các mạng xã hội. Chúng tôi có Dịch vụ tăng lượt LIKES TỐT NHẤT trên thị trường.
													</font></font>
												</p>
												<div className="img-div-earn">
													<img className="earn-img" src={buy_like} title="Like price" alt="Like price"/>
												</div>
											</div>
											<div className="earn-plashka">
												<h2 className="top-text-plashka ng-binding text-white"><font style={{"verticalAlign": "inherit"}}><font style={{"verticalAlign": "inherit"}}>COMMENTS</font></font></h2>
												<p className="text-earn-plashka ng-binding"><font style={{"verticalAlign": "inherit"}}><font style={{"verticalAlign": "inherit"}}>Nhận COMMENTS cho bài đăng của bạn để tăng và thu hút khán giả mới.
													</font></font>
												</p>
												<div className="img-div-earn">
													<img className="earn-img" src={buy_comment} title="Comments wrapping cost" alt="Comments wrapping cost"/>
												</div>
											</div>
											<div className="earn-plashka">
												<h2 className="top-text-plashka ng-binding text-white"><font style={{"verticalAlign": "inherit"}}><font style={{"verticalAlign": "inherit"}}>Followers</font></font></h2>
												<p className="text-earn-plashka ng-binding"><font style={{"verticalAlign": "inherit"}}><font style={{"verticalAlign": "inherit"}}>Thu hút người theo dõi nhanh chóng và dễ dàng, chất Lượng cao.
													</font></font>
												</p>
												<div className="img-div-earn">
													<img className="earn-img" src={buy_user} title="Subscribers wrapping cost" alt="Subscribers wrapping cost"/>
												</div>
											</div>
											<div className="earn-plashka">
												<h2 className="top-text-plashka ng-binding text-white"><font style={{"verticalAlign": "inherit"}}><font style={{"verticalAlign": "inherit"}}>Views</font></font></h2>
												<p className="text-earn-plashka ng-binding"><font style={{"verticalAlign": "inherit"}}><font style={{"verticalAlign": "inherit"}}>Nhận lượt xem cho bài đăng của bạn để tăng và thu hút khán giả mới.
													</font></font>
												</p>
												<div className="img-div-earn">
													<img className="earn-img bot-32" src={buy_view} title="The cost of wrapping views" alt="The cost of wrapping views"/>
												</div>
											</div>
										</div>
									</div>
								</div>
							</section>
							<section className="back-section back_div" id="reviews">
								<div className="container padding_0">
									<div className="row relative">
										<div className="col-lg-12 text-center color_white">
											<p className="earn-money-top-text pad_bot_0 ng-binding"><font style={{"verticalAlign": "inherit"}}><font style={{"verticalAlign": "inherit"}}>Phản hồi khách hàng</font></font></p>
											<p className="top-text-plashka top_0 ng-binding"><font style={{"verticalAlign": "inherit"}}></font></p>
										</div>
									</div>
									<div className="reviews-landscape relative">

										<ul className="pagination-container">
											
										</ul>
										
										<div>
											<div className="owl-carousel owl-theme owl-loaded owl-drag" style={{"width": "max-content"}}>
												<div className="owl-stage-outer" style={{"display": "inline-block"}}>
													<div className="owl-stage">
														<div className="owl-item cloned" style={{"width": "539.563px", "marginRight": "32px", "float": "left"}}>
															<div className="reviews_plashka">
																<p className="top-text-plashka-0"><font style={{"verticalAlign": "inherit"}}><font style={{"verticalAlign": "inherit"}}>Ms. DIỆP THANH</font></font></p>
																<p className="regular-text-plashka"><font style={{"verticalAlign": "inherit"}}><font style={{"verticalAlign": "inherit"}}>Nhờ có {domainSite} mà mình có thể tăng like bài viết, người theo dõi fb tiện lợi và nhanh chóng. </font></font></p>
															</div>
														</div>
													</div>
												</div>
												<div className="owl-stage-outer" style={{"display": "inline-block"}}>
													<div className="owl-stage">
														<div className="owl-item cloned" style={{"width": "539.563px", "marginRight": "32px", "float": "left"}}>
															<div className="reviews_plashka">
																<p className="top-text-plashka-0"><font style={{"verticalAlign": "inherit"}}><font style={{"verticalAlign": "inherit"}}>Mr. NÔNG VĂN ĐỨC</font></font></p>
																<p className="regular-text-plashka"><font style={{"verticalAlign": "inherit"}}><font style={{"verticalAlign": "inherit"}}>Phải nói là dịch vụ mình yêu cầu xử lý rất nhanh chóng, lại rất phù hợp túi tiền ,còn được bảo hành nữa. </font></font></p>
															</div>
														</div>
													</div>
												</div>
												<div className="owl-stage-outer" style={{"display": "inline-block"}}>
													<div className="owl-stage">
														<div className="owl-item cloned" style={{"width": "539.563px", "marginRight": "32px", "float": "left"}}>
															<div className="reviews_plashka">
																<p className="top-text-plashka-0"><font style={{"verticalAlign": "inherit"}}><font style={{"verticalAlign": "inherit"}}>Ms. LÊ THỊ HỒNG</font></font></p>
																<p className="regular-text-plashka"><font style={{"verticalAlign": "inherit"}}><font style={{"verticalAlign": "inherit"}}>Sau khi dùng dịch vụ thì tương tác facebook của mình tăng lên, đơn hàng bán ra cũng được nhiều hơn. </font></font></p>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</section>
							<section className="back-section padding_0" id="cases">
								<div className="container padding_0">
									<div className="text_cases">
										<p className="top-text-plashka many_people ng-binding"><font style={{"verticalAlign": "inherit"}}><font style={{"verticalAlign": "inherit"}}>Rất nhiều người đã sử dụng dịch vụ của chúng tôi.</font></font></p>
										<p className="earn-money-top-text"><font style={{"verticalAlign": "inherit"}}><font style={{"verticalAlign": "inherit"}}>1 110 239</font></font></p>
										<p className="top-text-plashka top_64 ng-binding"><font style={{"verticalAlign": "inherit"}}><font style={{"verticalAlign": "inherit"}}>Các trường hợp khách hàng của chúng tôi:</font></font></p>
									</div>
								</div>
								<div className="screens-container">
									<div className="before_screen">
										<p className="before_text ng-binding"><font style={{"verticalAlign": "inherit"}}><font style={{"verticalAlign": "inherit"}}>Trước</font></font></p>
										<img className="width_100" src={screenshot_before} alt=""/>
									</div>
									<div className="after_screen">
										<p className="after_text ng-binding"><font style={{"verticalAlign": "inherit"}}><font style={{"verticalAlign": "inherit"}}>Sau khi sử dụng dịch vụ</font></font></p>
										<img className="width_100" src={screenshot_after} title="After wrapping up via AutoSm service, 66400 subscribers" alt="After the cheat, there were 66400 subscribers"/>
									</div>
								</div>
								<div className="stars-wrap-acc">
									<img className="left-stars" src={star_left} alt=""/>
									<div className="stars-text">
										<p className="ng-binding"><font style={{"verticalAlign": "inherit"}}><font style={{"verticalAlign": "inherit"}}>Sẵn sàng để bắt đầu với chúng tôi?
											</font></font>
										</p>
										<Link to="/" onClick={(e) => {this.changeFormLogRegister(2)}} style={{"background": "#8641bf", "color": "white", "borderRadius": "6px"}} className="btn top-32 width_50 ng-binding">
											<font style={{"verticalAlign": "inherit"}}><font style={{"verticalAlign": "inherit"}}>THAM GIA NGAY</font></font>
										</Link>
									</div>
									<img className="right-stars" src={star_right} alt=""/>
								</div>
							</section>
						</div>
					</div>
				</div>
			</Fragment>
		</Router>
	);
	var path_check = window.location.pathname;
	if (window.location.pathname.length > 1) {
		path_check = window.location.pathname.replace(/\/+$/g, '');
	}
	const formRegister = (
		<Fragment>
			<RegisterModal3 action_login={(e) => {this.changeFormLogRegister(1)}} action_service={(e) => {this.changeFormLogRegister(3)}} />
		</Fragment>
	);
	const formService = (
		<Fragment>
			<ServiceModal3 action_login={(e) => {this.changeFormLogRegister(1)}} action_register={(e) => {this.changeFormLogRegister(2)}} />
		</Fragment>
	);
	const formForgotPassword = (
		<Fragment>
			<ForgotPasswordModal3 action_login={(e) => {this.changeFormLogRegister(1)}} action_register={(e) => {this.changeFormLogRegister(2)}} action_service={(e) => {this.changeFormLogRegister(3)}} action_reset={path_check} />
		</Fragment>
	);
	return (
		<Fragment>
			{
				path_check === '/resetpassword' ?
					formForgotPassword
				: this.state.modal == 1 ?
					formLogin
				: this.state.modal == 2 ?
					formRegister
				: this.state.modal == 3 ?
					formService
				: this.state.modal == 4 ?
					formForgotPassword
				: ''
			}
		</Fragment>
	);
  }
}
Login.propTypes = {
  auth: PropTypes.object.isRequired,
  error: PropTypes.object,
  login: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  error: state.error
});
export default connect(mapStateToProps, { login, clearErrors })(Login);
