import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Userinformation from "../Userinformation";

class sitecontent extends Component {
	render() {
		return (
			<Fragment>
				<Userinformation />
				<div className="card card-orange mt-3">
					<div className="card-body">
						<p className="font-bold">Chú ý:</p>
						<p className="font-bold text-danger">- Nghiêm cấm Buff các ID Seeding có nội dung vi phạm pháp luật, chính trị, đồ trụy... Nếu cố tình buff bạn sẽ bị trừ hết tiền và ban khỏi hệ thống vĩnh viễn, và phải chịu hoàn toàn trách nhiệm trước pháp luật.</p>
						<p>Dịch vụ chăm sóc nick hay còn gọi là dịch vụ nuôi tài khoản. Hệ thống sẽ thả cảm xúc vào các story của bạn bè hoặc tất cả story trên Facebook</p>
						<p>Auto Like sẽ tương tác với tất cả các story có trên tài khoản cá nhân của bạn.</p><p>Auto tương tác sẽ không thể lọc theo độ tuổi, địa điểm...</p>
						<p>Mỗi tính năng này sẽ được làm cách nhau 1 khoản thời gian ngẫu nhiên để tránh Facebook bắt spam.</p>
					</div>
				</div>
			</Fragment>
		)
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
});
const mapDispatchToProps = { }

export default connect(mapStateToProps, mapDispatchToProps)(sitecontent);