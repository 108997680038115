import React from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';

export const getIDFacebook = (link) => (dispatch, getState) => {
	let bodysend = {
		id_user: getState().auth.user.id,
		link: link
	};
	var dataFormOld = getState().buffsub.formData;
	dispatch({
		type: 'LOADING_FIND_ID_FACEBOOK',
		payload: true
	});
	axios.post('/api/checklinkfb/check/', bodysend, tokenConfig(getState))
	.then(res => {
		dispatch({
			type: 'LOADING_FIND_ID_FACEBOOK',
			payload: false
		});
		if (res.data.status === 200) {
			dispatch({
				type: 'ADD_ID_FACEBOOK',
				payload: res.data.id
			});
			openBtComfirmAlert('ID tìm thấy: '+res.data.id+'!', 'success', 'lấy ID thành công');
		} else {
			Swal.fire({
				icon: 'error',
				title: 'Lỗi',
				text: res.data.msg,
			});
		}
	}).catch(err => {
		openBtComfirmAlert('Có lỗi xảy ra!', 'error', 'Lỗi');
    });
};


export const shieldAvatarUp = (cookie, type) => (dispatch, getState) =>{
	let bodysend = {
		id_user: getState().auth.user.id,
		data: cookie,
		type: type
	};
	dispatch({
		type: 'LOADING_FIND_ID_FACEBOOK',
		payload: true
	});
	axios.post('/api/checklinkfb/upshield/', bodysend, tokenConfig(getState))
	.then(res => {
		dispatch({
			type: 'LOADING_FIND_ID_FACEBOOK',
			payload: false
		});
		if (res.data.status === 200) {
			if (type === 1) {
				openBtComfirmAlert('Đã bật khiên cho avatar!', 'success', 'Bật khiên thành công');
			} else {
				openBtComfirmAlert('Đã tắt khiên cho avatar!', 'success', 'Tắt khiên thành công');
			}
		} else {
			if (type === 1) {
				openBtComfirmAlert('Không thể bật khiên cho avatar!', 'error', 'Lỗi');
			} else {
				openBtComfirmAlert('Không thể tắt khiên cho avatar!', 'error', 'Lỗi');
			}
		}
	}).catch(err => {
		openBtComfirmAlert('Có lỗi xảy ra!', 'error', 'Lỗi');
    });
}
export const tokenConfig = getState => {
	// Get token from localhost
	const token = getState().auth.token;
	
	// Headers
	const config = {
		headers: {
			"Content-type": "application/json"
		}
	};
	
	// If token, add to headers
	if (token) {
		config.headers['ht-token'] = token;
	}
	
	return config;
};
export const openBtComfirmAlert = (message, type, title = '') => {
	Swal.fire({
		icon: type,
		title: title,
		text: message,
	});
	return false;
}