import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import BodyAdmin from './bodyadmin';

class AdminPage extends Component {

    render() {
        return (
            <Fragment>
                { this.props.auth.user.type !== 1 ?
                    <Redirect push to="/"/>
                    : <BodyAdmin />
                }
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, {}) (AdminPage);
