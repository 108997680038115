const date7dnext = () => {
	var result = new Date();
  	result.setDate(result.getDate() + 7);
  	return result;
}

const initialState = {
	loadingblock: false,
	loadingblock2: false,
	listBuffSub: [],
	datapricebuffsub: {
		tiktok_buffmat: {
			min_bh_web: 0,
			prices_web: 0,
		},
		tiktok_buffmat_sv2: {
			min_bh_web: 0,
			prices_web: 0,
		},
	},
	formData: {
		link: '',
		profile_user: '',
		loaiseeding: 'tiktok_buffmat',
		sltang: 100,
		tgdtm: 30,
		giatien: 0,
		ghichu: '',
		list_messages: [],
		infoTiktok: {
			userid_tiktok: "",
			uniqueId_tiktok: "",
			avatarThumb_tiktok: "",
			avatarMedium_tiktok: "",
			verified_tiktok: "",
			secret_tiktok: "",
			secUid_tiktok: "",
			openFavorite_tiktok: "",
			relation_tiktok: "",
			following_tiktok: "",
			fans_tiktok: "",
			heart_tiktok: "",
			video_tiktok: "",
			like_tiktok: "",
		}
	},
	tiktokbuffmat_sv1_mo_dong: 1,
	tiktokbuffmat_sv2_mo_dong: 1,
	"nhatkyhoatdong": []
};

export default function(state = initialState, action) {
  	switch (action.type) {
	    case 'LIST_BUFF_SUB_TIK_TOK_USER':
	    	return {
				...state,
				listBuffSub: action.payload
			};
	    case 'LOADING_BLOCK_BUFF_SUB_TIK_TOK':
			return {
				...state,
				loadingblock: action.payload
			};
    	case 'LIST_BUFF_SUB_USER_TIKTOK':
    		return {
				...state,
				listBuffSub: action.payload
			};
    	case 'DATA_PRICE_IS_FUNCTION_TIKTOK_BUFF':
    		return {
				...state,
				datapricebuffsub: merDataPricePage(action.payload)
			};
    	case 'CHANGE_DATA_FORM_BUFF_SUB_TIKTOK':
    		return {
				...state,
				formData: {...state.formData, [action.payload.name]: action.payload.value }
			};
    	case 'UPDATE_INFO_TIKTOK':
    		return {
				...state,
				formData: {
					...state.formData,
					infoTiktok: action.payload
				}
			};
		case 'CONFIG_MO_DONG_TIKTOK_BUFF_MAT':
			return {
				...state,
				tiktokbuffmat_sv1_mo_dong: action.payload.tiktokbuffmat_sv1_mo_dong,
				tiktokbuffmat_sv2_mo_dong: action.payload.tiktokbuffmat_sv2_mo_dong,
			};
		case 'LOADING_BLOCK_BUFF_SUB_TIK_TOK_2':
			return {
				...state,
				"loadingblock2": action.payload
			};
		case 'LOAD_NHATKYHOATDONG_TIKTOK_BUFF_MAT':
			return {
				...state,
				"nhatkyhoatdong": action.payload
			};
    	default:
      		return state;
  	}
}

function merDataPricePage(data) {
	const dataOld = initialState.datapricebuffsub;
	let dataNew = {
		...dataOld
	}
	if (data.length > 0) {
		data.forEach(function(v, i){
			if (v.name_table === 'tiktok_buffmat') {
				dataNew['tiktok_buffmat'] = v;
				initialState.formData['giatien'] = v.prices_web;
			} else if (v.name_table === 'tiktok_buffmat_sv2') {
				dataNew['tiktok_buffmat_sv2'] = v;
			}
		});
	};
	return dataNew;
}
