import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import { add_task_like_comment_share,getDataPriceFunction } from "../../../actions/BufflikecommentshareActions";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Swal from 'sweetalert2';
import axios from 'axios';
import store from '../../../store';

import iconLikeFb from '../../../iconfb/like.svg';
import iconHahaFb from '../../../iconfb/haha.svg';
import iconAngryFb from '../../../iconfb/angry.svg';
import iconLoveFb from '../../../iconfb/love.svg';
import iconSadFb from '../../../iconfb/sad.svg';
import iconWowFb from '../../../iconfb/wow.svg';
import iconCareFb from '../../../iconfb/care.svg';

class Taotientrinh extends Component {

	/*componentDidMount(){
        this.props.getDataPriceFunction();
	}*/

	handleChangeDateStart = date => {
	    let dataFormOld = this.props.buffsub.formData;
		let dataFormNew = {
			...dataFormOld,
			EndDatebh: this.addDays(date, 7),
			startDatebh: date,
		}

		store.dispatch({
			type: 'CHANGE_DATA_FORM_YTB_LCS',
			payload: dataFormNew
		});
	}

	handleChangeDateEnd = date => {
	    let dataFormOld = this.props.buffsub.formData;
		let dataFormNew = {
			...dataFormOld,
			EndDatebh: date
		}

		store.dispatch({
			type: 'CHANGE_DATA_FORM_YTB_LCS',
			payload: dataFormNew
		});
	}

	addDays = (date, days) => {
	  	var result = new Date(date);
	  	result.setDate(result.getDate() + days);
	  	return result;
	}

	searchUidFacebook = (e) => {
        let valueS = e.target.value;
        let dataFormOld = this.props.buffsub.formData;
        var id = this.getIDFacebook(valueS);
        let dataFormNew = {
            ...dataFormOld,
            profile_user: id
        }
        store.dispatch({
            type: 'CHANGE_DATA_FORM_YTB_LCS',
            payload: dataFormNew
        });
    }
    getIDFacebook(t) {
        let e = null;
        if (t) {
            if (t.indexOf("facebook.com") < 0) e = t;
            else {
                var s = t.match(/(.*)\/posts\/([0-9]{8,})/),
                    a = t.match(/(.*)\/photo.php\?fbid=([0-9]{8,})/),
                    i = t.match(/(.*)\/video.php\?v=([0-9]{8,})/),
                    n = t.match(/(.*)\/watch\/\?v=([0-9]{8,})/),
                    o = t.match(/(.*)\/watch\/live\/\?v=([0-9]{8,})/),
                    r = t.match(/(.*)\/story.php\?story_fbid=([0-9]{8,})/),
                    l = t.match(/(.*)\/permalink.php\?story_fbid=([0-9]{8,})/),
                    c = t.match(/(.*)\/([0-9]{8,})/),
                    f = t.match(/(.*)fbid=([0-9]{8,})/),
					d = t.match(/(.*)comment_id=([0-9]{8,})/),
					g = t.match(/(.*)set=a.([0-9]{8,})/);
                if (s !== null) {
                    return s[2];
                } else if (a !== null) {
                    return a[2];
                } else if (i !== null) {
                    return i[2];
                } else if (n !== null) {
                    return n[2];
                } else if (o !== null) {
                    return o[2];
                } else if (r !== null) {
                    return r[2];
                } else if (l !== null) {
                    return l[2];
                } else if (c !== null) {
                    return c[2];
                } else if (f !== null) {
                    return f[2];
                } else if (d !== null) {
                    return d[2];
                } else if (g !== null) {
                    return g[2];
                }
            }
            return e
        }
    }

	onChangeFormCheckbox = (e) => {
		let valueC = '';
		let pricest = 0;
		let {baohanh, loaiseeding} = this.props.buffsub.formData;

		if (e.target.checked) {
			valueC = e.target.value;
		}
		if (valueC === 'care' || valueC === 'love' ||
			valueC === 'haha' || valueC === 'wow' ||
			valueC === 'sad' || valueC === 'angry' ||
			valueC === 'like') {
			let nameVlKey = valueC;
			if (loaiseeding === 'like_clone') {
				nameVlKey += '_clone';
			}
			if (loaiseeding === 'like_v2') {
				// nameVlKey += '_v2';
				nameVlKey = 'like_v2';
			}
			if (loaiseeding === 'like_v3') {
				// nameVlKey += '_v3';
				nameVlKey = 'like_v3';
			}
			if (loaiseeding === 'like_cmt') {
				// nameVlKey += '_cmt';
				nameVlKey = 'like_cmt';
			}
			if (baohanh===1) {
				pricest = this.props.buffsub.datapricebuffsub[nameVlKey].min_bh_web;
			} else {
				pricest = this.props.buffsub.datapricebuffsub[nameVlKey].prices_web;
			}
		} else {
			var nameloai = 'like';
			if (loaiseeding === 'like_clone') {
				nameloai = 'like_clone';
			}
			if (loaiseeding === 'like_v2') {
				nameloai = 'like_v2';
			}
			if (loaiseeding === 'like_v3') {
				nameloai = 'like_v3';
			}
			if (loaiseeding === 'like_cmt') {
				nameloai = 'like_cmt';
			}
			if (baohanh===1) {
				pricest = this.props.buffsub.datapricebuffsub[nameloai].min_bh_web;
			} else {
				pricest = this.props.buffsub.datapricebuffsub[nameloai].prices_web;
			}
		}

		let dataFormOld = this.props.buffsub.formData;
		let dataFormNew = {
			...dataFormOld,
			type: valueC,
			giatien: pricest
		}
		store.dispatch({
			type: 'CHANGE_DATA_FORM_YTB_LCS',
			payload: dataFormNew
		});
	}

	onChangeForm = (e) => {
		let valueadd = '';
		if (e.target.type === 'radio' || e.target.type === 'select-one' || e.target.type === 'number') {
			if (e.target.value.trim() !== '' || e.target.value !== '') {
				valueadd = Math.abs(parseInt(e.target.value));
				if (isNaN(valueadd)) {
					valueadd = '';
				}
			} else {
				valueadd = '';
			}
		} else {
			valueadd = e.target.value;
		}

		let dataFormOld = this.props.buffsub.formData;
		let dataFormNew = {
			...dataFormOld,
			[e.target.name]: valueadd
		}

		store.dispatch({
			type: 'CHANGE_DATA_FORM_YTB_LCS',
			payload: dataFormNew
		});
	}

	onChangeSpeciesSend = (e) => {
		let pricest = 0;
		let {baohanh} = this.props.buffsub.formData;
		if (e.target.value === 'share') {
			if (baohanh===1) {
				pricest = this.props.buffsub.datapricebuffsub.share.min_bh_web;
			} else {
				pricest = this.props.buffsub.datapricebuffsub.share.prices_web;
			}
		} else if (e.target.value === 'comment') {
			if (baohanh===1) {
				pricest = this.props.buffsub.datapricebuffsub.comment.min_bh_web;
			} else {
				pricest = this.props.buffsub.datapricebuffsub.comment.prices_web;
			}
		} else if (e.target.value === 'like_clone') {
			if (baohanh===1) {
				pricest = this.props.buffsub.datapricebuffsub.like_clone.min_bh_web;
			} else {
				pricest = this.props.buffsub.datapricebuffsub.like_clone.prices_web;
			}
		} else if (e.target.value === 'comment_pro') {
			if (baohanh===1) {
				pricest = this.props.buffsub.datapricebuffsub.comment_pro.min_bh_web;
			} else {
				pricest = this.props.buffsub.datapricebuffsub.comment_pro.prices_web;
			}
		} else if (e.target.value === 'like_v2') {
			if (baohanh===1) {
				pricest = this.props.buffsub.datapricebuffsub.like_v2.min_bh_web;
			} else {
				pricest = this.props.buffsub.datapricebuffsub.like_v2.prices_web;
			}
		} else if (e.target.value === 'like_v3') {
			if (baohanh===1) {
				pricest = this.props.buffsub.datapricebuffsub.like_v3.min_bh_web;
			} else {
				pricest = this.props.buffsub.datapricebuffsub.like_v3.prices_web;
			}
		} else if (e.target.value === 'like_cmt') {
			baohanh = 0;
			if (baohanh===1) {
				pricest = this.props.buffsub.datapricebuffsub.like_cmt.min_bh_web;
			} else {
				pricest = this.props.buffsub.datapricebuffsub.like_cmt.prices_web;
			}
		} else {
			if (baohanh===1) {
				pricest = this.props.buffsub.datapricebuffsub.like.min_bh_web;
			} else {
				pricest = this.props.buffsub.datapricebuffsub.like.prices_web;
			}
		}

		let dataFormOld = this.props.buffsub.formData;
		let dataFormNew = {
			...dataFormOld,
			[e.target.name]: e.target.value,
			giatien: pricest,
			baohanh: baohanh,
			type: ''
		}

		store.dispatch({
			type: 'CHANGE_DATA_FORM_YTB_LCS',
			payload: dataFormNew
		});
	}

	onChangeBH = (e) => {
		let {loaiseeding, giatien, type} = this.props.buffsub.formData;

		let pricest = giatien;

		if (e.target.value === '1') {
			if (loaiseeding=== 'share') {
				pricest = this.props.buffsub.datapricebuffsub.share.min_bh_web;
			} else if (loaiseeding === 'comment') {
				pricest = this.props.buffsub.datapricebuffsub.comment.min_bh_web;
			} else if (loaiseeding === 'comment_pro') {
				pricest = this.props.buffsub.datapricebuffsub.comment_pro.min_bh_web;
			} else if (loaiseeding === 'like') {
				if (type.trim() == '') {
					type = 'like';
				}
				pricest = this.props.buffsub.datapricebuffsub[type].min_bh_web;
			} else if (loaiseeding === 'like_clone') {
				if (type.trim() == '') {
					type = 'like';
				}
				pricest = this.props.buffsub.datapricebuffsub[type+'_clone'].min_bh_web;
			} else if (loaiseeding === 'like_v2') {
				// if (type.trim() == '') {
					type = 'like';
				// }
				pricest = this.props.buffsub.datapricebuffsub[type+'_v2'].min_bh_web;
			} else if (loaiseeding === 'like_v3') {
				// if (type.trim() == '') {
					type = 'like';
				// }
				pricest = this.props.buffsub.datapricebuffsub[type+'_v3'].min_bh_web;
			} else if (loaiseeding === 'like_cmt') {
				// if (type.trim() == '') {
					type = 'like';
				// }
				pricest = this.props.buffsub.datapricebuffsub[type+'_cmt'].min_bh_web;
			} else {
				pricest = this.props.buffsub.datapricebuffsub.like.min_bh_web;
			}
		} else {
			if (loaiseeding=== 'share') {
				pricest = this.props.buffsub.datapricebuffsub.share.prices_web;
			} else if (loaiseeding === 'comment') {
				pricest = this.props.buffsub.datapricebuffsub.comment.prices_web;
			} else if (loaiseeding === 'comment_pro') {
				pricest = this.props.buffsub.datapricebuffsub.comment_pro.prices_web;
			} else if (loaiseeding === 'like') {
				if (type.trim() == '') {
					type = 'like';
				}
				pricest = this.props.buffsub.datapricebuffsub[type].prices_web;
			} else if (loaiseeding === 'like_clone') {
				if (type.trim() == '') {
					type = 'like';
				}
				pricest = this.props.buffsub.datapricebuffsub[type+'_clone'].prices_web;
			} else if (loaiseeding === 'like_v2') {
				// if (type.trim() == '') {
					type = 'like';
				// }
				pricest = this.props.buffsub.datapricebuffsub[type+'_v2'].prices_web;
			} else if (loaiseeding === 'like_v3') {
				// if (type.trim() == '') {
					type = 'like';
				// }
				pricest = this.props.buffsub.datapricebuffsub[type+'_v3'].prices_web;
			} else if (loaiseeding === 'like_cmt') {
				// if (type.trim() == '') {
					type = 'like';
				// }
				pricest = this.props.buffsub.datapricebuffsub[type+'_cmt'].prices_web;
			} else {
				pricest = this.props.buffsub.datapricebuffsub.like.prices_web;
			}
		}

		let dataFormOld = this.props.buffsub.formData;
		let dataFormNew = {
			...dataFormOld,
			[e.target.name]: parseInt(e.target.value),
			giatien: pricest
		}

		store.dispatch({
			type: 'CHANGE_DATA_FORM_YTB_LCS',
			payload: dataFormNew
		});
	}

	getSltangComment = (e) => {
		let el = e.target;

		let dataFormOld = this.props.buffsub.formData;
		let countEnd = el.value.split("\n").length;
		let dataFormNew = {
			...dataFormOld,
			list_messages: el.value.split("\n"),
			sltang: countEnd
		}

		store.dispatch({
			type: 'CHANGE_DATA_FORM_YTB_LCS',
			payload: dataFormNew
		});
	}

	createElementsSelect(start, end, prefix = ''){
	    var elements = [];
	    for(let i = start; i < end; i++){
	        elements.push(<option key={i} value={i+prefix}>{i}{prefix}</option>);
	    }
	    return elements;
	}

	caculPriceEnd = () => {
		const {locnangcao,locnangcao_gt,locnangcao_dotuoi_start,locnangcao_banbe_start,sltang,giatien} = this.props.buffsub.formData;
		let priceShow = sltang*giatien;
		let priceAdvan = sltang*giatien*20/100;
		if (locnangcao === 1) {
			if (locnangcao_gt !== 0) {
				priceShow = priceShow + priceAdvan;
			}

			if (locnangcao_dotuoi_start !== 0) {
				priceShow = priceShow + priceAdvan;
			}

			if (locnangcao_banbe_start !== 0) {
				priceShow = priceShow + priceAdvan;
			}
		}
		return Math.ceil(priceShow);
	}

 	render() {
 		const { locnangcao,locnangcao_gt,locnangcao_dotuoi_start,locnangcao_dotuoi_end,locnangcao_banbe_start,locnangcao_banbe_end,profile_user,loaiseeding,baohanh,sltang,giatien,ghichu,startDatebh,EndDatebh,type } = this.props.buffsub.formData;
    	return (
    		<Fragment>
    			<style>
					{"\
			        	.react-datepicker-wrapper{\
			          		width: 100%;\
			        	}\
			      	"}
		      	</style>
				<div className="row">
					<div className="col-md-3">
						<label className="mt-2">Link hoặc ID bài viết:</label>
					</div>
					<div className="col-md-9">
						<div className="form-group">
                            <input onChange={(e) => {this.searchUidFacebook(e)}} value={profile_user} name="profile_user" type="text" className="form-control"/>
							{
								loaiseeding === "like_cmt" ?
									<h6 style={{"marginTop":"5px"}}>Hướng dẫn lấy ID để buff like comment: <a href="https://i.imgur.com/bume4kV.png" target="_blank" className="font-bold"> Tại đây</a></h6>
								: ''
							}
                        </div>
					</div>
				</div>
				<div className="row mt-2">
					<div className="col-md-3">
						<label className="mt-2">Loại Seeding cần tăng:</label>
					</div>
					{/*<div className="col-md-8">
						<div className="form-group">
							<select onChange={(e) => {this.onChangeSpeciesSend(e)}} value={loaiseeding} name="loaiseeding" className="form-control">
								<option value="share">Chia sẻ bài viết Facebook</option>
								<option value="like">Like bài viết Facebook</option>
								<option value="like_clone">Like CLONE bài viết Facebook </option>
								<option value="comment">Bình luận bài viết Facebook</option>
								<option value="comment_pro">Bình luận nhanh (buff được cho video)</option>
							</select>
						</div>
					</div>*/}
					<div className="col-sm-9">
						<div className="custom-control custom-radio custom-control-inline" style={{opacity: this.props.buffsub.datapricebuffsub['like'].prices_web == 0 ? '0.3' : '1', "marginRight": "65px"}} >
							<input onChange={(e) => {this.onChangeSpeciesSend(e)}} value="like" type="radio" className="custom-control-input" id="loaiseeding_1" name="loaiseeding" checked={loaiseeding === "like"} disabled={this.props.buffsub.datapricebuffsub['like'].prices_web == 0 ? 'disabled' : ''} />
							<label className="custom-control-label" htmlFor="loaiseeding_1" > Tăng <span style={{"color": "red"}}>Like Bài Viết</span> (Like người thật,Like việt)</label>
						</div>
						<div className="custom-control custom-radio custom-control-inline" style={{opacity: this.props.buffsub.datapricebuffsub['like_clone'].prices_web == 0 ? '0.3' : '1' }} >
							<input onChange={(e) => {this.onChangeSpeciesSend(e)}} value="like_clone" type="radio" className="custom-control-input" id="loaiseeding_2" name="loaiseeding" checked={loaiseeding === "like_clone"} disabled={this.props.buffsub.datapricebuffsub['like_clone'].prices_web == 0 ? 'disabled' : ''} />
							<label className="custom-control-label" htmlFor="loaiseeding_2" > Tăng <span style={{"color": "red"}}>Like Bài Viết</span> (Like Clone)</label>
						</div>
						<div className="custom-control custom-radio custom-control-inline" style={{opacity: this.props.buffsub.datapricebuffsub['like_v2'].prices_web == 0 ? '0.3' : '1' }} >
							<input onChange={(e) => {this.onChangeSpeciesSend(e)}} value="like_v2" type="radio" className="custom-control-input" id="loaiseeding_6" name="loaiseeding" checked={loaiseeding === "like_v2"} disabled={this.props.buffsub.datapricebuffsub['like_v2'].prices_web == 0 ? 'disabled' : ''} />
							<label className="custom-control-label" htmlFor="loaiseeding_6" > Tăng <span style={{"color": "red"}}>Like Bài Viết</span> V2 (Like người thật,Like việt,giá rẻ)</label>
						</div>
						<div className="custom-control custom-radio custom-control-inline" style={{opacity: this.props.buffsub.datapricebuffsub['like_v3'].prices_web == 0 ? '0.3' : '1' }} >
							<input onChange={(e) => {this.onChangeSpeciesSend(e)}} value="like_v3" type="radio" className="custom-control-input" id="loaiseeding_7" name="loaiseeding" checked={loaiseeding === "like_v3"} disabled={this.props.buffsub.datapricebuffsub['like_v3'].prices_web == 0 ? 'disabled' : ''} />
							<label className="custom-control-label" htmlFor="loaiseeding_7" > Tăng <span style={{"color": "red"}}>Like Bài Viết</span> V3</label>
						</div>
						<br/>
						<div className="custom-control custom-radio custom-control-inline" style={{opacity: this.props.buffsub.datapricebuffsub['comment'].prices_web == 0 ? '0.3' : '1', "marginRight": "29px"}} >
							<input onChange={(e) => {this.onChangeSpeciesSend(e)}} value="comment" type="radio" className="custom-control-input" id="loaiseeding_3" name="loaiseeding" checked={loaiseeding === "comment"} disabled={this.props.buffsub.datapricebuffsub['comment'].prices_web == 0 ? 'disabled' : ''} />
							<label className="custom-control-label" htmlFor="loaiseeding_3" > Tăng <span style={{"color": "blue"}}>Comment Bài Viết</span> (cmt người thật,cmt việt)</label>
						</div>
						<div className="custom-control custom-radio custom-control-inline" style={{opacity: this.props.buffsub.datapricebuffsub['comment_pro'].prices_web == 0 ? '0.3' : '1' }}>
							<input onChange={(e) => {this.onChangeSpeciesSend(e)}} value="comment_pro" type="radio" className="custom-control-input" id="loaiseeding_4" name="loaiseeding" checked={loaiseeding === "comment_pro"} disabled={this.props.buffsub.datapricebuffsub['comment_pro'].prices_web == 0 ? 'disabled' : ''} />
							<label className="custom-control-label" htmlFor="loaiseeding_4" > Tăng <span style={{"color": "blue"}}>Comment Bài Viết</span> PRO (cmt việt, tăng được cho video)</label>
						</div>
						<br/>
						<div className="custom-control custom-radio custom-control-inline" style={{opacity: this.props.buffsub.datapricebuffsub['share'].prices_web == 0 ? '0.3' : '1' }} >
							<input onChange={(e) => {this.onChangeSpeciesSend(e)}} value="share" type="radio" className="custom-control-input" id="loaiseeding_0" name="loaiseeding" checked={loaiseeding === "share"} disabled={this.props.buffsub.datapricebuffsub['share'].prices_web == 0 ? 'disabled' : ''} />
							<label className="custom-control-label" htmlFor="loaiseeding_0" > Tăng <span style={{"color": "#00c700"}}>Chia Sẻ Bài Viết</span> (share người thật,share việt)</label>
						</div>
						<br/>
						<div className="custom-control custom-radio custom-control-inline" style={{opacity: this.props.buffsub.datapricebuffsub['like_cmt'].prices_web == 0 ? '0.3' : '1', "marginRight": "150px" }}>
							<input onChange={(e) => {this.onChangeSpeciesSend(e)}} value="like_cmt" type="radio" className="custom-control-input" id="loaiseeding_5" name="loaiseeding" checked={loaiseeding === "like_cmt"} disabled={this.props.buffsub.datapricebuffsub['like_cmt'].prices_web == 0 ? 'disabled' : ''} />
							<label className="custom-control-label" htmlFor="loaiseeding_5" > Tăng <span style={{"color": "#ff5dff"}}>Like Comment</span> (Like người thật,Like việt)</label>
						</div>
					</div>
				</div>

				<div style={{display:loaiseeding === 'like' || loaiseeding === 'like_clone' || loaiseeding === 'like_v2' || loaiseeding === 'like_cmt' ? 'flex' : 'none'}} className="row mt-2">
					<div className="col-md-3">
						<label className="mt-2">Chọn cảm xúc:</label>
					</div>
					<div className="col-md-9">
						<div className="form-group">
						    <label className="icon_feed_fb">
						    	<input style={{display: 'none'}} value="like" checked={type === 'like' || type === ""} onChange={(e) => {this.onChangeFormCheckbox(e)}} type="checkbox" name="type" />
						    	<img src={iconLikeFb} alt="icon fb" className="img-responsive image-reaction ml-2" />
					    	</label>
					    	<label className="icon_feed_fb" style={{display: (loaiseeding === 'like_v2' || loaiseeding === 'like_clone') ? 'none' : 'inline-block'}}>
						    	<input style={{display: 'none'}} value="care" checked={type === 'care'} onChange={(e) => {this.onChangeFormCheckbox(e)}} type="checkbox" name="type" />
						    	<img style={{width: '44px'}} src={iconCareFb} alt="icon fb" className="img-responsive image-reaction ml-2" />
					    	</label>
						    <label className="icon_feed_fb">
						    	<input style={{display: 'none'}} value="love" checked={type === 'love'} onChange={(e) => {this.onChangeFormCheckbox(e)}} type="checkbox" name="type" />
						    	<img src={iconLoveFb} alt="icon fb" className="img-responsive image-reaction ml-2" />
					    	</label>
						    <label className="icon_feed_fb">
						    	<input style={{display: 'none'}} value="haha" checked={type === 'haha'} onChange={(e) => {this.onChangeFormCheckbox(e)}} type="checkbox" name="type" />
						    	<img src={iconHahaFb} alt="icon fb" className="img-responsive image-reaction ml-2" />
					    	</label>
						    <label className="icon_feed_fb">
						    	<input style={{display: 'none'}} value="wow" checked={type === 'wow'} onChange={(e) => {this.onChangeFormCheckbox(e)}} type="checkbox" name="type" />
						    	<img src={iconWowFb} alt="icon fb" className="img-responsive image-reaction ml-2" />
					    	</label>
						    <label className="icon_feed_fb">
						    	<input style={{display: 'none'}} value="sad" checked={type === 'sad'} onChange={(e) => {this.onChangeFormCheckbox(e)}} type="checkbox" name="type" />
						    	<img src={iconSadFb} alt="icon fb" className="img-responsive image-reaction ml-2" />
					    	</label>
						    <label className="icon_feed_fb">
						    	<input style={{display: 'none'}} value="angry" checked={type === 'angry'} onChange={(e) => {this.onChangeFormCheckbox(e)}} type="checkbox" name="type" />
						    	<img src={iconAngryFb} alt="icon fb" className="img-responsive image-reaction ml-2" />
					    	</label>
						</div>
					</div>
				</div>

				{ /* loaiseeding === 'like' && (type === 'like' || type === "") ? '' : */
					<div className="row mt-2">
						<div className="col-md-3">
							<label className="mt-2">Chế độ bảo hành:</label>
						</div>
						<div className="col-md-9">
							<div className="form-group">
								<select onChange={(e) => {this.onChangeBH(e)}} value={baohanh.toString()} name="baohanh" className="form-control">
									<option value="0">Không bảo hành</option>
									<option value="1">Bảo hành (Bảo hành nếu tụt quá 10%)</option>
								</select>
							</div>

							<div style={{display: baohanh === 1 ? 'block':'none'}} className="form-group">
								<div className="row">
									<div className="col-md-6">
										<p className="mb-0">Chọn ngày bắt đầu</p>
										<DatePicker className="form-control w-100"
										showTimeSelect minDate={new Date()}
										selected={startDatebh}
										onChange={this.handleChangeDateStart} />
									</div>
									<div className="col-md-6">
										<p className="mb-0">Chọn ngày kết thúc</p>
										<DatePicker className="form-control"
										showTimeSelect minDate={startDatebh}
										maxDate={this.addDays(startDatebh, 7)}
										selected={EndDatebh}
										onChange={this.handleChangeDateEnd} />
									</div>
								</div>
							</div>
						</div>
					</div>
				}

				{ loaiseeding === 'like_clone' || loaiseeding === 'like_v2' || loaiseeding === 'like_v3' || loaiseeding === 'like_cmt' || loaiseeding === 'comment_pro' ? '' :
					<div className="row mt-2">
						<div className="col-md-3">
							<label className="mt-2">Lọc nâng cao:</label>
						</div>
						<div className="col-md-9">
							<div className="form-group">
							    <div className="custom-control custom-radio custom-control-inline">
							        <input onChange={(e) => {this.onChangeForm(e)}} value="1" type="radio" className="custom-control-input" id="locnangcao_1" name="locnangcao" checked={locnangcao === 1} />
							        <label className="custom-control-label" htmlFor="locnangcao_1" >Bật</label>
							    </div>
							    <div className="custom-control custom-radio custom-control-inline">
							        <input onChange={(e) => {this.onChangeForm(e)}} value="0" type="radio" className="custom-control-input" id="locnangcao_0" name="locnangcao" checked={locnangcao === 0}/>
							        <label className="custom-control-label" htmlFor="locnangcao_0" >Tắt</label>
							    </div>
							</div>
							{
								locnangcao === 1 ?
									<div className="form-group">
										<p className="font-15 mt-2">
											Hệ thống sẽ <span style={{color:'#1FAB89'}} className="font-weight-bold">thu phí thêm 20%</span> cho <span style={{color:'#1FAB89'}} className="font-weight-bold">1 lựa chọn nâng cao</span>
										</p>
										<div className="block block-bordered rounded">
											<div className="block-content">
												<div className="row">
													<div className="col-md-2">
														<label>Giới tính:</label>
													</div>
													<div className="col-md-8">
														<div className="form-group">
														    <div className="custom-control custom-radio custom-control-inline">
														        <input onChange={(e) => {this.onChangeForm(e)}} value="2" type="radio" className="custom-control-input" id="locnangcao_gt_2" name="locnangcao_gt" checked={locnangcao_gt === 2}/>
														        <label className="custom-control-label" htmlFor="locnangcao_gt_2" >Nam</label>
														    </div>
															<div className="custom-control custom-radio custom-control-inline">
														        <input onChange={(e) => {this.onChangeForm(e)}} value="1" type="radio" className="custom-control-input" id="locnangcao_gt_1" name="locnangcao_gt" checked={locnangcao_gt === 1}/>
														        <label className="custom-control-label" htmlFor="locnangcao_gt_1" >Nữ</label>
														    </div>
														    <div className="custom-control custom-radio custom-control-inline">
														        <input onChange={(e) => {this.onChangeForm(e)}} value="0" type="radio" className="custom-control-input" id="locnangcao_gt_0" name="locnangcao_gt" checked={locnangcao_gt === 0}/>
														        <label className="custom-control-label" htmlFor="locnangcao_gt_0" >Tất cả</label>
														    </div>
														</div>
													</div>
												</div>
												{ loaiseeding === 'comment_pro' ? '' :
													<Fragment>
														<div className="row">
															<div className="col-md-2">
																<label className="mt-2">Độ tuổi:</label>
															</div>
															<div className="col-md-4">
																<div className="form-group">
																	<select onChange={(e) => {this.onChangeForm(e)}} name="locnangcao_dotuoi_start" value={locnangcao_dotuoi_start} className="form-control">
																		<option value="0">không lọc</option>
																		{this.createElementsSelect(13,66)}
																	</select>
																</div>
															</div>
															<div className="col-md-2 text-center">
																<label className="mt-1">Đến</label>
															</div>
															<div className="col-md-4">
																<div className="form-group">
																	<select disabled={locnangcao_dotuoi_start===0} onChange={(e) => {this.onChangeForm(e)}} name="locnangcao_dotuoi_end" value={locnangcao_dotuoi_end} className="form-control">
																		{this.createElementsSelect(13,66)}
																	</select>
																</div>
															</div>
														</div>

														<div className="row">
															<div className="col-md-2">
																<label className="mt-2">Số bạn bè:</label>
															</div>
															<div className="col-md-4">
																<div className="form-group">
																	<select onChange={(e) => {this.onChangeForm(e)}} name="locnangcao_banbe_start" value={locnangcao_banbe_start.toString()} className="form-control">
																		<option value="0">không lọc</option>
																		{this.createElementsSelect(1,51,'00')}
																	</select>
																</div>
															</div>
															<div className="col-md-2 text-center">
																<label className="mt-1">Đến</label>
															</div>
															<div className="col-md-4">
																<div className="form-group">
																	<select disabled={locnangcao_banbe_start===0} onChange={(e) => {this.onChangeForm(e)}} name="locnangcao_banbe_end" value={locnangcao_banbe_end.toString()} className="form-control">
																		{this.createElementsSelect(1,51,'00')}
																	</select>
																</div>
															</div>
														</div>
													</Fragment>
												}
											</div>
										</div>
									</div>
								: ''
							}
						</div>
					</div>
				}

				{ loaiseeding === 'comment' || loaiseeding === 'comment_pro' ?
					'' :
					<div className="row mt-2">
						<div className="col-md-3">
							<label className="mt-2">Số lượng cần tăng:</label>
						</div>
						<div className="col-md-9">
							<div className="form-group">
	                            <input type="number" className="form-control" onChange={(e) => {this.onChangeForm(e)}} name="sltang" value={sltang}/>
	                        </div>
						</div>
					</div>
				}
				{ loaiseeding === 'like_clone' || loaiseeding === 'comment_pro' || loaiseeding === 'like_v2' || loaiseeding === 'like_v3' || loaiseeding === 'like_cmt' ?
					'' :
					<div className="row mt-2">
						<div className="col-md-9 offset-md-3">
							<div className="card card-orange">
								<div className="form-group">
									<div className="card-body py-2">
										<h6 className="font-14 mb-0">
											<label className="mb-0 text-danger font-bold mr-1">
												Giá thấp nhất: {loaiseeding === 'like' ? this.props.buffsub.datapricebuffsub.like.prices_web
												 : (loaiseeding === 'comment') ? this.props.buffsub.datapricebuffsub.comment.prices_web 
												 : (loaiseeding === 'share') ? this.props.buffsub.datapricebuffsub.share.prices_web 
												 : '' } Coin
											</label>

											<label className="mb-0 ml-1 green font-bold mr-1">
												- Giá trung bình: {loaiseeding === 'like' ? this.props.auth.priceMedium.like.price_medium
												 : (loaiseeding === 'comment') ? this.props.auth.priceMedium.comment.price_medium 
												 : (loaiseeding === 'share') ? this.props.auth.priceMedium.share.price_medium
												 : '' } Coin
											</label>

											{loaiseeding !== 'share' ? <label className="ml-1 text-primary font-bold mr-1"> - Giá bảo hành: {(loaiseeding === 'like') ? this.props.buffsub.datapricebuffsub.like.min_bh_web : this.props.buffsub.datapricebuffsub.share.min_bh_web} Coin</label> : ''}
										</h6>
										<h6 className="font-14 mb-0 text-danger font-bold">Lưu ý: Nên buff dư thêm 20 - 30% trên tổng số lượng để tránh tụt.</h6>
									</div>
								</div>
							</div>
						</div>
					</div>
				}

				<div className="row mt-2">
					<div className="col-md-3">
						<label className="mt-2">Giá tiền mỗi tương tác:</label>
					</div>
					<div className="col-md-9">
						<div className="form-group">
							<input type="number" className="form-control" onChange={(e) => {this.onChangeForm(e)}} name="giatien" value={giatien}/>
						</div>
					</div>
				</div>

				{ loaiseeding === 'comment'?
						<div className="row mt-2">
							<div className="col-md-12">
								<div className="card card-orange mb-3">
									<div className="card-body py-3">
										<h6 className="font-14 mb-0 text-danger font-bold mt-2">Nghiêm cấm bình luận các nội dung có biểu tượng Icon.</h6>
										<h6 className="font-14 mb-0 text-danger font-bold mt-2">Nghiêm cấm bình luận những nội có cử chỉ, lời nói thô bạo, khiêu khích, trêu ghẹo, xúc phạm nhân phẩm, danh dự của Cá nhân hoặc Tổ chức.
Nếu cố tình buff bạn sẽ bị trừ hết tiền và ban khỏi hệ thống api vĩnh viễn, và phải chịu hoàn toàn trách nhiệm trước pháp luật.</h6>
										<h6 className="font-14 mb-0 text-danger font-bold mt-2">Các ngôn từ bị cấm: dm|đm|đ m|d m|địt mẹ|dit me|lol|lừađảo|conchó|trảtiền|mấtdạy|lừa đảo|con chó|trả tiền|mất dạy|lua dao|con cho|tra tien|mat day</h6>
									</div>
								</div>

								<span className="font-13 ml-2">Nhập nội dung Comment (<label className="font-bold font-13">Mỗi dòng tương đương với 1 Comment</label>):</span>
								<span className="badge badge-primary text-white py-1">Số lượng: {sltang}</span>

								<div className="form-group mt-2">
									<textarea rows="10" onChange={(e) => this.getSltangComment(e)} placeholder="Nhập nội dung seeding, mỗi dòng 1 nội dung được tính tiền là 1 lần seeding" className="form-control input-gray">
									</textarea>
								</div>
							</div>
						</div>
					: ''
				}
				{ loaiseeding === 'comment_pro' ?
						<div className="row mt-2">
							<div className="col-md-12">
								<div className="card card-orange mb-3">
									<div className="card-body py-3">
										<h3 className="mb-0 text-danger">Chú ý : </h3>
										<h4 className="mb-0" style={{ "color": "#0014ff" }}>Bình luận nhanh là loại buff tốc độ cao có thể số lượng không lên đủ, khi thanh toán vui lòng cân nhắc trước khi sử dụng! Và có thể buff cho video và tối đa là 50 bình luận</h4>
									</div>
								</div>

								<span className="font-13 ml-2">Nhập nội dung Comment (<label className="font-bold font-13">Mỗi dòng tương đương với 1 Comment</label>):</span>
								<span className="badge badge-primary text-white py-1">Số lượng: {sltang}</span>

								<div className="form-group mt-2">
									<textarea rows="10" onChange={(e) => this.getSltangComment(e)} placeholder="Nhập nội dung seeding, mỗi dòng 1 nội dung được tính tiền là 1 lần seeding" className="form-control input-gray">
									</textarea>
								</div>
							</div>
						</div>
					: ''
				}

				<div className="row mt-2">
					<div className="col-md-3">
						<label className="mt-2"></label>
					</div>
				    <div className="col-md-9 bold">
				        <div className="card card-orange">
				        	<div className="card-body py-2">
								<h3 className="mb-0 text-danger">HÃY ĐỌC ĐỂ TRÁNH MẤT TIỀN KHI SỬ DỤNG!</h3>
								<ul>
									<li>1 ID KHÔNG CÀI 2 ĐƠN CÙNG LÚC TRONG HỆ THỐNG ! ĐƠN CŨ XONG MỚI CÀI ĐƠN MỚI ! CỐ TÌNH CÀI BỊ HỤT SỐ LƯỢNG SẼ KHÔNG XỬ LÝ !</li>
									<li>Hãy chú ý khi mua dịch vụ sau 1 - 5 tiếng mà dịch vụ không chạy hãy nhấn vào nhật kí order hoặc danh sách (có thể mỗi tab hiện 1 kiểu) để theo dõi đơn hàng xem hệ thống có báo lỗi không</li>
									<li>Nếu khi đã ấn vào theo dõi id hãy ấn check id xem hệ thống báo sao nhé ( ấn vào dấu 3 chấm hoặc dấu tam giác để nó mở rộng ra rồi ấn vào) </li>
									<li>Nếu gặp lỗi hãy tìm đến tab Liên hệ hỗ trợ!</li>
								</ul>
			        		</div>
		        		</div>
				    </div>
				</div>
				<div className="row mt-2">
					<div className="col-md-3">
						<label className="mt-2">Ghi chú:</label>
					</div>
					<div className="col-md-9">
						<div className="form-group">
                            <textarea onChange={(e) => {this.onChangeForm(e)}} name="ghichu" value={ghichu} className="form-control" rows="4" placeholder="Nhập nội dung ghi chú về tiến trình của bạn"></textarea>
                        </div>
					</div>
				</div>

				<div className="row mt-4">
				    <div className="col-12 text-center bold">
				        <div className="card card-info">
				            <div className="card-body">
				                <h5 className="font-bold">Tổng: <span className="bold green"><span>{this.caculPriceEnd()}</span> Coin</span></h5>
				                <h6 className="mb-0">Bạn sẽ buff <span className="bold green">{sltang} {loaiseeding}</span> với giá <span className="green bold">{this.caculPriceEnd()}</span> Coin</h6>
				            </div>
				        </div>
				    </div>
				</div>

				<div className="row mt-4">
					<div className="col-md-12">
						<div className='form-group float-right w-100'>
							<button onClick={(e) => {this.props.add_task_like_comment_share(this.props.buffsub.formData)}} type="button" className="btn btn-hero-primary btn-rounded btn-block">
	                            Tạo tiến trình
	                        </button>
                        </div>
					</div>
				</div>
			</Fragment>
		);
  	}
}

const mapStateToProps = state => ({
	buffsub: state.likecommentshare,
	auth: state.auth
});

export default connect(mapStateToProps, {add_task_like_comment_share,getDataPriceFunction})(Taotientrinh);
