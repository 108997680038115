import axios from 'axios';
import Swal from 'sweetalert2';
import { loadUser } from "./authActions";

export const GetListData = (viewall = 0, page = "", obj_search = '') => (dispatch, getState) => {
	let id_user = getState().auth.user.id;
	dispatch({
        type: 'LOADING_DATA_FB_BUFFVIEWSVIDEO',
        payload: true
    });
    let paramUrl = '';
    if (viewall === 1) {
    	paramUrl += '&viewall=1'
    }

	if (page !== "") {
		paramUrl += '&page='+page;
	}
	paramUrl += '&obj_search='+obj_search;
	axios.get('/api/facebook_buff/list/?type_api=buff_view_video'+paramUrl, tokenConfig(getState))
	.then(res => {
		dispatch({
	        type: 'LOADING_DATA_FB_BUFFVIEWSVIDEO',
	        payload: false
	    });
		if (res.data.status === 200) {
			dispatch({
				type: 'LIST_DATA_FB_BUFFVIEWSVIDEO',
				payload: res.data.data
			});
		}
	});
}

export const CreateBot = (data, clearform) => (dispatch, getState) => {
	let bodysend = {
		dataform: data,
		id: getState().auth.user.id,
		type_api: 'buff_view_video'
	};
	Swal.fire({
	  	title: 'Bạn có chắc?',
	  	text: "Bạn có chắc muốn mua gói này? Khi đã bấm đồng ý đồng nghĩa với việc bạn đã đọc hết mục thông tin dịch vụ!",
	  	icon: 'warning',
	  	showCancelButton: true,
	  	confirmButtonColor: '#3085d6',
	  	cancelButtonColor: '#d33',
	  	confirmButtonText: 'Đồng ý',
	  	cancelButtonText: 'Không'
	}).then((result) => {
	  	if (result.value) {
			dispatch({
		        type: 'LOADING_DATA_FB_BUFFVIEWSVIDEO',
		        payload: true
		    });
			axios.post('/api/facebook_buff/create', bodysend, tokenConfig(getState))
			.then(res => {
				dispatch(loadUser());
				dispatch({
			        type: 'LOADING_DATA_FB_BUFFVIEWSVIDEO',
			        payload: false
			    });
				if (res.data.status === 200) {
					Swal.fire({ icon: 'success', title: 'Thành công', text: 'Tạo thành công!' });
				} else {
					Swal.fire({ icon: 'error', title: 'Lỗi', text: res.data.message });
				}
			});
		}
	});
}

export const DeleteItem = (id) => (dispatch, getState) => {
	Swal.fire({
	  	title: 'Bạn có chắc?',
	  	text: "Sau khi hủy bạn sẽ không được hoàn lại tiền!",
	  	icon: 'warning',
	  	showCancelButton: true,
	  	confirmButtonColor: '#3085d6',
	  	cancelButtonColor: '#d33',
	  	confirmButtonText: 'Đồng ý',
	  	cancelButtonText: 'Không'
	}).then((result) => {
	  	if (result.value) {
			dispatch({
				type: 'LOADING_DATA_FB_BUFFVIEWSVIDEO',
				payload: true
			});
			let bodysend = {
				id: id,
				id_user: getState().auth.user.id,
				type_api: 'buff_view_video',
			};

			axios.post('/api/facebook_buff/removeorder/', bodysend, tokenConfig(getState))
			.then(res => {
				dispatch({
			        type: 'LOADING_DATA_FB_BUFFVIEWSVIDEO',
			        payload: false
			    });
				if (res.data.status === 200) {
					dispatch({
						type: 'DELETE_FB_BUFFVIEWSVIDEO',
						payload: id
					});
					Swal.fire({ icon: 'success', title: 'Thành công', text: 'Xóa thành công!' });
				} else {
					Swal.fire({ icon: 'error', title: 'Lỗi', text: res.data.message });
				}
			});
	  	}
  	});
}

export const saveEditOrderAdmin = (id, view_now, status, closeM) => (dispatch, getState) => {
	let bodysend = {
		id: id,
		view_now: view_now,
		status: status,
		type_api: 'buff_view_video'
	};
	dispatch({
		type: 'LOADING_DATA_FB_BUFFVIEWSVIDEO',
		payload: true
	});
	axios.post('/api/facebook_buff/admin_edit_order/', bodysend, tokenConfig(getState))
	.then(res => {
		dispatch({
			type: 'LOADING_DATA_FB_BUFFVIEWSVIDEO',
			payload: false
		});
		dispatch(GetListData());
		closeM();
		if (res.status === 200) {
			Swal.fire(
	      		'Thành công!',
	      		'Sửa thành công',
	      		'success'
	    	)
		} else {
			Swal.fire({ icon: 'error', title: 'Lỗi', text: res.data.message });
		}
	}).catch(err => {
		// console.log(err);
		Swal.fire({ icon: 'error', title: 'Lỗi', text: '' });
    });
}

export const getConfigMoDong = (type = '') => (dispatch, getState) => {
	dispatch({
		type: 'LOADING_DATA_FB_BUFFVIEWSVIDEO',
		payload: true
	});

	axios.get('/api/facebook_buff/getconfig?type_api=buffviewvideo', tokenConfig(getState))
	.then(res => {
		dispatch({
			type: 'LOADING_DATA_FB_BUFFVIEWSVIDEO',
			payload: false
		});
		if (res.data.status === 200) {
			dispatch({
				type: 'CONFIG_MO_DONG_BUFFVIEWSVIDEO',
				payload: res.data
			});
		}
	});
}

export const getID = (body) => (dispatch, getState) => {
	let bodysend = {
		id_user: getState().auth.user.id,
		link: body
	};
	dispatch({
		type: 'LOADING_DATA_FB_BUFFVIEWSVIDEO',
		payload: true
	});
	return new Promise(function(resolve, reject){
		axios.post('/api/checklinkfb/check_video/', bodysend, tokenConfig(getState))
			.then(res => {
				dispatch({
					type: 'LOADING_DATA_FB_BUFFVIEWSVIDEO',
					payload: false
				});
				if (res.data.status === 200) {
					resolve(res.data.id);
				} else {
					resolve('');
					Swal.fire({ icon: 'error', title: 'Lỗi', text: res.data.msg });
				}
			});
	});
};

export const tokenConfig = getState => {
	// Get token from localhost
	const token = getState().auth.token;
	
	// Headers
	const config = {
		headers: {
			"Content-type": "application/json"
		}
	};
	
	// If token, add to headers
	if (token) {
		config.headers['ht-token'] = token;
	}
	
	return config;
};