import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import {
    Modal,
    ModalBody,
    ModalFooter
} from 'reactstrap';
import { UpdateCookie } from '../../../actions/FacebookbotreplyinboxchoActions';
import { updateCookieFb } from '../../../actions/FbbotActions';

class Modal_edit extends Component {
    state = {
        cookiefb: this.props.datacookie,
        ndr: this.props.datandr,
    }

    changeValueNdr = (e) => {
        this.setState({
            ndr: e.target.value
        });
    }
	
    changeValueCookie = (e) => {
        this.setState({
            cookiefb: e.target.value
        });
    }

    render() {
        const {isOpenModal, dataid, dataidfb} = this.props;
        const {cookiefb, ndr} = this.state;
		const { changetab, loading } = this.props.Facebookbotreplyinboxcho;

        return (
            <Fragment>
                <Modal centered={true} size="lg" toggle={(e) => {this.props.openModal()}} isOpen={isOpenModal} className={(loading? ' block block-mode-loading' : '')} style={{"backgroundColor": "inherit", "boxShadow": "none"}}>
                    <div className="modal-header">
                        <p className="mb-0 text-dark">Sửa cookie</p>
                        <button onClick={(e) => {this.props.openModal()}} type="button" className="close">×</button>
                    </div>
                    <ModalBody style={{opacity: loading? '.05':'1'}}>
						<div className="form-group row">
							<label className="col-sm-4 col-form-label" htmlFor="">Nội Dung REPLY:<br/>=> HDSD: {"{"}icon1{"}"}{"{"}icon2{"}"}->{"{"}icon10{"}"}</label>
							<div className="col-sm-8">
								<textarea rows="6" placeholder="Chào bạn đây là bot reply cmt tự động khi rảnh tôi sẽ trả lời bạn ngay {icon1} !" className="form-control input-gray" id="ndr" name="ndr" onChange={this.changeValueNdr} >{ndr}</textarea>
							</div>
						</div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group focused">
                                    <label className="control-label">Cookie</label>
                                    <input className="form-control" placeholder="Nhập cookie" value={cookiefb} onChange={(e)=>{this.changeValueCookie(e)}} />
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter style={{opacity: loading? '.05':'1'}}>
                        <button onClick={() => {this.props.updateCookieFb('facebookbotreplyinboxcho', cookiefb, '[]', dataidfb, dataid, ndr, '', this.props.openModal)}} type="button" className="btn btn-primary">Lưu</button>
                        <button onClick={(e) => {this.props.openModal()}} type="button" className="btn btn-danger">Huỷ</button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    Facebookbotreplyinboxcho: state.Facebookbotreplyinboxcho
});

export default connect(mapStateToProps, {UpdateCookie, updateCookieFb}) (Modal_edit);
