import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { loadMBC, updateMBC, buyMBC } from '../../../actions/MuabancloneActions';
import Swal from 'sweetalert2';

class muaclone extends Component {
	static propTypes =  {
		loadMBC: PropTypes.func.isRequired,
		updateMBC: PropTypes.func.isRequired,
		buyMBC: PropTypes.func.isRequired,
	};
	
	componentDidMount() {
		this.props.loadMBC();
	}
	
	onChange = (e) => {
		const { muaclone } = this.props.Muabanclone;
		if (e.target.name === "price") {
			var index = e.target.selectedIndex;
			var optionElement = e.target.childNodes[index]
			var dataid =  optionElement.getAttribute('data-id');
			var dataname =  optionElement.getAttribute('data-name');
			var new_muaclone = {
				...muaclone,
				"id_llc": dataid,
				"llc": dataname,
				[e.target.name]: e.target.value,
			};
		} else {
			var new_muaclone = {
				...muaclone,
				[e.target.name]: e.target.value,
			};
		}
		
		this.props.updateMBC(new_muaclone);
	}
	
	option_llc = (listloaiclone) => {
		let ar_option_llc = [];

		listloaiclone.forEach(function(v, i) {
			ar_option_llc.push(
				<option value={v.price} data-id={v.id} data-name={v.name} key={i}>
					{v.name}
				</option>
			)
		});
		
		return ar_option_llc;
	}
	
	taoTienTrinhfunc = () => {
		const { muaclone } = this.props.Muabanclone;
		const id_user = this.props.auth.user.id;
		if (muaclone.price  === '0') {
			Swal.fire({
				icon: 'error',
				title: 'Lỗi',
				text: 'Chọn 1 loại clone để thanh toán',
			});
			return false;
		}
		if (parseInt(muaclone.slm) < 1) {
			Swal.fire({
				icon: 'error',
				title: 'Lỗi',
				text: 'Phải mua ít nhất 1 clone!',
			});
			return false;
		}
		Swal.fire({
			title: 'Bạn có chắc?',
			html: "Bạn có chắc chắn sẽ mua "+muaclone.slm+" clone, loại "+muaclone.llc+" với giá: "+muaclone.price+" Coin/1 clone?",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Đồng ý',
			cancelButtonText: 'Không'
		}).then((result) => {
			if (result.value) {
				var new_muaclone = {
					...muaclone,
					"id_user": id_user
				};
				this.props.buyMBC(new_muaclone);
			}
		})
	}
	
	onKeyPress = (e) => {
		if (!(e.charCode >= 48 && e.charCode <= 57)) {
			e.preventDefault();
		}
	}
	
	render() {
		const { listloaiclone } = this.props.Muabanclone;
		const { llc, price, slm } = this.props.Muabanclone.muaclone;
		var final_price = price * slm;
		return (
			<Fragment>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">
						Loại Clone:
					</label>
					<div className="col-sm-8">
						<select className="form-control custom-select select-light" id="price" name="price" onChange={this.onChange} value={price} >
							<option value="0" data-name="--">--</option>
							{this.option_llc(listloaiclone)}
						</select>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Số lượng mua:</label>
					<div className="col-sm-8">
						<input type="number" className="form-control" id="slm" name="slm" placeholder="" onChange={this.onChange} value={slm} onKeyPress={this.onKeyPress} />
					</div>
				</div>
				<div className="row mt-4">
					<div className="col-12 text-center bold">
						<div className="card card-info">
							<div className="card-body">
								<h5 className="font-bold">Tổng: <span className="bold green"><span>{final_price}</span> Coin</span></h5>
								<h6 className="mb-0 bold"><span>Bạn sẽ mua <span className="green">{slm}</span> clone, loại <span className="green">{llc}</span></span></h6>
							</div>
						</div>
					</div>
				</div>
				<div className="row my-4">
					<div className="col-12 text-center bold">
						<button type="button" className="btn btn-block btn-rounded btn-hero-primary" onClick={this.taoTienTrinhfunc} >Thanh toán</button>
					</div>
				</div>
			</Fragment>
		)
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	Muabanclone: state.Muabanclone,
});
const mapDispatchToProps = { loadMBC, updateMBC, buyMBC }

export default connect(mapStateToProps, mapDispatchToProps)(muaclone);