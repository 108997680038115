import {
	LOAD_FABTT,
	UPDATE_FABTT,
	UPDATE_FABTT_TAB,
	LOAD_PRICE_FABTT,
	LIMIT_PAGI_FABTT,
	TYPE_FABTT,
	LOADING_FABTT,
	LOADING_FABTT_2,
	LOAD_NHATKYHOATDONG_FABTT
} from "../actions/types";

const initialState = {
	"taotientrinh": {
		"lvct": "",
		"lsct": "tuongtac",
		"quocgia": "de",
		"slct": "15000",
		"gc": "",
		"gtmtt": "0",
	},
	"changetab": 0,
	"listtientrinh": [],
	"tanggiathem": {
		"id": "",
		"lhibv": "",
		"price_per": 0,
		"price": 0
	},
	"price": {
		Facebookadbreakstuongtac: {
			min_bh_web: 0,
			prices_web: 0,
		},
	},
	"limit_pagi": 0,
	"type": 0,
	"loading": false,
	"loading2": false,
	"nhatkyhoatdong": [],
	facebookadbreakstuongtac_sv1_mo_dong: 1,
};

export default function(state = initialState, action) {
	switch (action.type) {
		case LOAD_FABTT:
			return {
				...state,
				"listtientrinh": action.payload
			};
		case UPDATE_FABTT:
			return {
				...state,
				"taotientrinh": action.payload
			};
		case UPDATE_FABTT_TAB:
			return {
				...state,
				"changetab": action.payload
			};
		case LOAD_PRICE_FABTT:
			return {
				...state,
				"price": merDataPricePage(action.payload),
			};
		case LIMIT_PAGI_FABTT:
			return {
				...state,
				"limit_pagi": action.payload
			};
		case TYPE_FABTT:
			return {
				...state,
				"type": action.payload
			};
		case LOADING_FABTT:
			return {
				...state,
				"loading": action.payload
			};
		case LOADING_FABTT_2:
			return {
				...state,
				"loading2": action.payload
			};
		case LOAD_NHATKYHOATDONG_FABTT:
			return {
				...state,
				"nhatkyhoatdong": action.payload
			};
		case 'CONFIG_MO_DONG_FABTT':
			return {
				...state,
				facebookadbreakstuongtac_sv1_mo_dong: action.payload.facebookadbreakstuongtac_sv1_mo_dong,
			};
		default:
		  return state;
	}
}

function merDataPricePage(data) {
	let dataNew = {
		...initialState.price
	}
	if (data.length > 0) {
		data.forEach(function(v, i){
			if (v.name_table === 'facebookadbreakstuongtac') {
				initialState.taotientrinh.gtmtt = v.prices_web;
				dataNew['Facebookadbreakstuongtac'] = v;
			}
		});
	};
	return dataNew;
}
