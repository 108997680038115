import React from 'react';
import axios from 'axios';
import {
	LOAD_MBC,
	LOADING_MBC,
	UPDATE_MBC,
	LIST_MBC,
} from "../actions/types";
import Swal from 'sweetalert2';
import { loadUser } from "./authActions";

export const loadMBC = () => (dispatch, getState) => {
	axios.get('/api/muabanclone', tokenConfig(getState))
		.then(res => {
			if (res.data.status === 200) {
				dispatch({
					type: LOAD_MBC,
					payload: res.data.data
				});
			}
		});
};

export const listMBC = () => (dispatch, getState) => {
	dispatch({
		type: LOADING_MBC,
		payload: true
	});
	const config = tokenConfig(getState);
	axios.get('/api/muabanclone/user', config)
		.then(res => {
			dispatch({
				type: LOADING_MBC,
				payload: false
			});
			if (res.data.status === 200) {
				dispatch({
					type: LIST_MBC,
					payload: res.data.data
				});
			}
		});
};

export const updateMBC = (body) => (dispatch, getState) => {
	dispatch({
		type: UPDATE_MBC,
		payload: body
	});
};

export const buyMBC = (body) => (dispatch, getState) => {
	dispatch({
		type: LOADING_MBC,
		payload: true
	});
	axios.post('/api/muabanclone/mua', body, tokenConfig(getState))
		.then(res => {
			dispatch({
				type: LOADING_MBC,
				payload: false
			});
			dispatch(loadUser());
			if (res.data.status === 200) {
				dispatch(listMBC());
				Swal.fire({
					icon: 'success',
					html: res.data.data
				});
			}
			if (res.data.status === 400) {
				Swal.fire({
					icon: 'error',
					title: 'Lỗi',
					text: res.data.message,
				});
			}
		});
};

export const tokenConfig = getState => {
	// Get token from localhost
	const token = getState().auth.token;
	
	// Headers
	const config = {
		headers: {
			"Content-type": "application/json"
		}
	};
	
	// If token, add to headers
	if (token) {
		config.headers['ht-token'] = token;
	}
	
	return config;
};