import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { getListItemBuffSub,searchDataBuffSub} from '../../../actions/buffViplikeCloneAction';
import BootstrapTable from 'react-bootstrap-table-next';
import Moment from 'react-moment';
import { load, searchDataFbVip, cancelOrder} from '../../../actions/FbvipActions';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Modalgiahan from './modal_giahan';
import Modaledit from './modal_edit';
import Modalnhatky from './modal_nhatky';
import parse from 'html-react-parser';
import { RiExchangeDollarLine } from "react-icons/ri";
const { SearchBar } = Search;
class Nhatkyorder extends Component {
	state = {
		isOpenModalEdit: false,
		isOpenModalGiahan: false,
		isOpenModalNhatKy: false,
		limit: 0,
		viewall: 0,
		page: 1,
		obj_search: "",
		id: 0,
		status: 0,
		idfb: 0,
		lsct: "",
	}
	componentDidMount(){
        this.props.load('viplike_clone');
	}

	searchDataBuff = (e) => {
		if (e.key === 'Enter') {
	      	let value_search = e.target.value;
	      	this.props.searchDataFbVip('viplike_clone', value_search);
	    }
	}
	openModalGiahan = (data = null) => {
		this.setState({
			...this.state,
			isOpenModalGiahan: !this.state.isOpenModalGiahan,
		});
		if (data !== null) {
			this.setState({
				...this.state,
				isOpenModalGiahan: !this.state.isOpenModalGiahan,
				id: data.id,
				lsct: data.lsct,
				max_like: data.max_like,
				days: data.days,
				quantity_baiviet: data.quantity_baiviet,
			});
		}
    }
    openModalNhatKy = (data = null) => {
		this.setState({
			...this.state,
			isOpenModalNhatKy: !this.state.isOpenModalNhatKy,
		});
		if (data !== null) {
			this.setState({
				...this.state,
				isOpenModalNhatKy: !this.state.isOpenModalNhatKy,
				idfb: data.object_id,
				lsct: data.lsct,
			});
		}
    }
    openModalEdit = (data = null) => {
		this.setState({
			...this.state,
			isOpenModalEdit: !this.state.isOpenModalEdit,
		});
		if (data !== null) {
			this.setState({
				...this.state,
				isOpenModalEdit: !this.state.isOpenModalEdit,
				id: data.id,
				status: data.cancel_order,
			});
		}
    }
	
	onChange = (e) => {
		this.setState({
			"obj_search": e.target.value,
			"page": 1
		});
	}
    getListByLimit = (page, type_button) => {
        var valueChange = parseInt(page);
		if (type_button == "prev") {
			valueChange -= 1;
			if (valueChange <= 0) {
				valueChange = 1;
			}
		}
		if (type_button == "next") {
			valueChange += 1;
		}
		this.setState({
			page: valueChange,
		});
		this.props.load('viplike_clone', this.state.viewall, valueChange, this.state.obj_search);
    }
	
    getListByView = (e) => {
        let valueChange = e;
		this.setState({
			viewall: valueChange,
			"page": 1,
		});
		this.props.load('viplike_clone', valueChange, 1, this.state.obj_search);
    }
	
    getListBySearch = (e) => {
		this.setState({
			"page": 1,
		});
		this.props.load('viplike_clone', this.state.viewall, 1, this.state.obj_search);
    }

	formatCheckTime(cell, row) {
		var result = new Date(row.created_at*1000);
		result.setDate(result.getDate() + row.songaymua);
		var result_now = new Date();
		
		var difference = result - result_now;
		if (difference >= 0) {
			return (
				<Fragment>
					<Moment diff={result_now} unit="days">
						{result}
					</Moment>
				</Fragment>
			);
		} else {
			return 0;
		}
	}
 	render() {
		var token_avt = {};
		try {
			if (localStorage.getItem("token_avt") != null) {
				token_avt = JSON.parse(localStorage.getItem("token_avt"))[0].token_avt;
			} else {
				token_avt = this.props.auth.token_avt[0].token_avt;
			}
		} catch(e) {
			token_avt = localStorage.getItem("token_avt");
		}
		var that = this;
		function formatAvatar(cell, row) {
			var link_avt = "https://graph.facebook.com/"+row.object_id+"/picture?height=100&width=100&access_token="+token_avt;
			return (
				<img src={link_avt} className="avatar-user" />
			)
		};
		function formatTrangThai(cell, row) {
			var message = '';
			if (cell === 0) {
				return (
					<span className="badge badge-primary"><i className="fas fa-check mr-1"></i>Hoàn thành</span>
				);
			} else if (cell === 1) {
				return (
					<span className="badge badge-success"><i className="fas fa-sync-alt mr-1"></i>Đang chạy</span>
				);
			} else if (cell === 2) {
				return (
					<span className="badge badge-danger"><i className="fas fa-ban mr-1"></i>ID die không thể chạy</span>
				);
			} else if (cell === 3) {
				return (
					<span className="badge badge-warning"><i className="fas fa-times mr-1"></i>Hủy đơn</span>
				);
			} else if (cell === 5) {
				return (
					<span className="badge badge-info"><RiExchangeDollarLine size={12} className="mr-1" />Hoàn tiền</span>
				);
			} else {
				return ('');
			}
		}
		function filterValueTrangThai(cell, row) {
			if (cell === 0) {
				return ('Hoàn thành');
			} else if (cell === 1) {
				return ('Đang chạy');
			} else if (cell === 2) {
				return ('ID die không thể chạy');
			} else if (cell === 3) {
				return ('Hủy đơn');
			} else if (cell === 5) {
				return ('Hoàn tiền');
			} else {
				return ('');
			}
		}
		
		function formatLoaiServer(cell, row) {
			if (cell === 0) {
				return ('SV4');
			} else if (cell === 1) {
				return ('SV3');
			} else if (cell === 2) {
				return ('SV2');
			} else if (cell === 3) {
				return ('SV1');
			} else if (cell === 4) {
				return ('SV5');
			} else {
				return ('');
			}
		}
		
		function formatCheckTime(cell, row) {
			return (
				<Fragment>
					<Moment format="DD-MM-YYYY HH:mm:ss">{cell*1000}</Moment>
				</Fragment>
			);
		}
		function filterValueCheckTime(cell, row) {
			var d = new Date(cell*1000);
			var month = '' + (d.getMonth() + 1);
			var day = '' + d.getDate();
			var year = d.getFullYear();
			var hour = '' + (d.getHours());
			var min = '' + d.getMinutes();
			var sec = '' + d.getSeconds();

			if (month.length < 2)
				month = '0' + month;
			if (day.length < 2)
				day = '0' + day;
			if (hour.length < 2)
				hour = '0' + hour;
			if (min.length < 2)
				min = '0' + min;
			if (sec.length < 2)
				sec = '0' + sec;
			
			return [day, month, year].join('-') + ' ' + [hour, min, sec].join(':');
		}
		// function formatCheckTimeUse(cell, row) {
			// let date_now = Math.floor(Date.now() / 1000);
			// let calDateDay = (cell - date_now);
			// if (calDateDay >= 0) {
				// if (calDateDay/86400 < 1) {
					// return Math.floor(calDateDay/3600) + ' giờ';
				// }
				// return (
					// <Fragment>
						// <Moment diff={date_now*1000} unit="days">{cell*1000}</Moment> ngày
					// </Fragment>
				// );
			// } else {
				// return <span className="badge badge-danger">Hết hạn</span>
			// }
		// }
		// function filterValueCheckTimeUse(cell, row) {
			// let date_now = Math.floor(Date.now() / 1000);
			// let calDateDay = (cell - date_now);
			// if (calDateDay >= 0) {
				// if (calDateDay/86400 < 1) {
					// return Math.floor(calDateDay/3600) + ' giờ';
				// }
				// var diffDays = Math.floor(calDateDay / (60 * 60 * 24)) + ' ngày';
				// return diffDays;
			// } else {
				// return ('Hết hạn');
			// }
		// }
		function formatCheckTimeUse(cell, row) {
			var result = new Date(row.created_at*1000);
			result.setDate(result.getDate() + parseInt(row.days));
			var result_now = new Date();
			
			var difference = (result - result_now)/1000;
			if (difference >= 0) {
				// if (difference/86400 < 1) {
		          	// return Math.floor(difference/3600) + ' giờ';
		        // }
				const days = Math.floor(difference / 86400);
				const hours = Math.floor(difference / 3600) - (days * 24);
				const minutes = Math.floor(difference / 60) - (days * 24 * 60) - (hours * 60);
				const seconds = Math.floor(difference) - (days * 24 * 60 * 60) - (hours * 60 * 60) - (minutes * 60);
				var datediff = "";
				if (days > 0) {
					datediff += days + ' ngày ';
				}
				if (hours > 0) {
					datediff += hours + ' giờ ';
				}
				if (minutes > 0) {
					datediff += minutes + ' phút';
				}
				return datediff;
				{/* return (
					<Fragment>
						<Moment diff={result_now} unit="days">
								{result}
							</Moment>
						{' '} ngày
					</Fragment>
				); */}
			} else {
				return <span className="badge badge-danger">Hết hạn</span>
			}
		}
		function formatLoaiQuantityBaiViet(cell, row) {
			if (row.lsct === 0) {
				return ('5');
			} else if (row.lsct === 2) {
				return ('7');
			} else if (row.lsct === 1 || row.lsct === 3 || row.lsct === 4) {
				return cell;
			} else {
				return ('');
			}
		}
		function formatFbName(cell, row) {
			return parse('<img src="https://graph.facebook.com/'+row.object_id+'/picture?height=100&amp;width=100&access_token='+token_avt+'" class="avatar-user"> <a target="_blank" href="https://www.facebook.com/'+row.object_id+'">'+row.usernamefb+' - '+row.object_id+'</a>');
		}
		function filterValueFbName(cell, row) {
			return (row.usernamefb + ' - ' + row.object_id);
		}
		
		function formatLimitPost(cell, row) {
			var quantity_baiviet = "";
			if (row.lsct === 0) {
				quantity_baiviet = "5";
			} else if (row.lsct === 2) {
				quantity_baiviet = "7";
			} else if (row.lsct === 1 || row.lsct === 3 || row.lsct === 4) {
				quantity_baiviet = row.quantity_baiviet;
			} else {
				quantity_baiviet = "";
			}
			return (row.limit_post + '/' + quantity_baiviet);
		}
		
 		const columns = [{
			dataField: 'id',
			text: 'ID Seeding',
			sort: true,
		},{
		  text: 'Thao tác',
		  dataField: '-',
		  style: {
			'white-space' : 'nowrap'
		  },
		  formatter: (cell, row) => 
		  	<Fragment>
				{this.props.auth.user.type === 1 ?
					<Fragment>
						<Link to="/tool/vip-like-clone" className="btn btn-hero-success text-center p-2 mr-1" onClick={() => {this.openModalEdit(row)}}>
							<i className="fa fa-fw fa-pen"></i>
						</Link>
					</Fragment>
				: ''}
	    	 	{row.cancel_order !== 3 ?
					<Fragment>
						<Link to="/tool/vip-like-clone" className="btn btn-hero-primary text-center p-2 mr-1" onClick={() => {this.openModalGiahan(row)}}>
							<i className="fa fa-fw fa-clock"></i>
						</Link>
						<a onClick={(e)=>{e.preventDefault();this.props.cancelOrder(row.id,'viplike_clone')}} href="/#" className="btn btn-hero-danger text-center p-2 mr-1">
							<i className="fa fa-fw fa-trash"></i>
						</a>
						<a onClick={(e) => {e.preventDefault();this.openModalNhatKy(row)}} href="/#" className="btn btn-hero-primary text-center p-2 mr-1">
							<i className="fa fa-fw fa-book"></i>
						</a>
					</Fragment>
				:
					<Fragment>
						<a onClick={(e) => {e.preventDefault();this.openModalNhatKy(row)}} href="/#" className="btn btn-hero-primary text-center p-2 mr-1">
							<i className="fa fa-fw fa-book"></i>
						</a>
					</Fragment>
				}
	    	</Fragment>
		},{
		  dataField: 'usernamefb',
		  text: 'ID Facebook',
		  sort: true,
		  formatter: formatFbName,
		  filterValue: filterValueFbName
		},{
		  dataField: 'lsct',
		  text: 'Loại server',
		  sort: true,
		  formatter: formatLoaiServer,
		  filterValue: formatLoaiServer
		},{
		  dataField: 'quantity',
		  text: 'Số lượng like',
		  sort: true
		},{
			dataField: 'quantity_baiviet',
			text: 'Số lượng bài viết',
			sort: true,
			formatter: formatLoaiQuantityBaiViet,
			filterValue: formatLoaiQuantityBaiViet
		},{
		  dataField: 'limit_post',
		  text: 'Post/Ngày',
		  sort: true,
		  formatter: formatLimitPost,
		  filterValue: formatLimitPost
		},{
		  dataField: 'days',
		  text: 'Số ngày',
		  sort: true
		},{
		  dataField: 'per_price',
		  text: 'Giá tiền',
		  sort: true
		},{
		  dataField: 'created_at',
		  text: 'Khởi tạo',
		  sort: true,
		  formatter: formatCheckTime,
		  filterValue: filterValueCheckTime
		},{
		  dataField: 'time_expired',
		  text: 'Còn lại',
		  sort: true,
		  formatter: formatCheckTimeUse,
		  filterValue: formatCheckTimeUse,
		},{
		  text: 'username',
		  dataField: 'username',
		  sort: true,
		  classes: 'notranslate',
		},{
		  dataField: 'cancel_order',
		  text: 'Trạng thái',
		  sort: true,
		  formatter: formatTrangThai,
		  filterValue: filterValueTrangThai
		},{
		  dataField: 'notes',
		  hidden: true
		}];

		const expandRow = {
			renderer: (row) => (
				<Fragment>
					<div className="row">
						<div className="col-lg-8 row">
							<div className="col-lg-4">
								<div className="form-group">
									<label htmlFor="example-text-input">Ghi chú</label>
									<textarea className="form-control" id="gc" name="gc" placeholder="" rows="2" defaultValue={row.notes}></textarea>
								</div>
							</div>
						</div>
					</div>
				</Fragment>
			),
			showExpandColumn: true,
			expandColumnPosition: 'right',
			expandHeaderColumnRenderer: ({ isAnyExpands }) => {
				if (isAnyExpands) {
					return <i className="fas fa-caret-up"></i>;
				}
				return <b><i className="fas fa-caret-down"></i></b>;
			},
			expandColumnRenderer: ({ expanded }) => {
				if (expanded) {
					return (
						<b><i className="fas fa-caret-up"></i></b>
					);
				}
				return (
					<b><i className="fas fa-caret-down"></i></b>
				);
			}
		};

 		const {listBuffSub} = this.props.buffsub;
    	return (
    		<Fragment>
				{/*<div className="row mb-4">
                    <div className="col-md-5">
                        { this.props.auth.user.type === 1 ?
                            <Fragment>
                                <button onClick={() => {this.props.load('viplike_clone',1)}} type="button" className="btn btn-primary mr-1">Xem tất cả</button>
								<button onClick={() => {this.props.load('viplike_clone')}} type="button" className="btn btn-primary mr-1">Xem riêng</button>
                            </Fragment>
                        : ''}
                    </div>
                    <div className="col-md-3">
                        <select onChange={(e) => {this.getListByLimit(e)}} className="form-control">
                            <option value="">Số lịch sử hiển thị tối đa</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="200">200</option>
                            <option value="500">500</option>
                            <option value="1000">1000</option>
                        </select>
                    </div>
                    <div className="col-md-4">
                    	<input onKeyDown={(e) => {this.searchDataBuff(e)}} type="text" name="search_user" className="form-control" placeholder="Nhập id tìm kiếm"/>
            		</div>
                </div>*/}
				
				<ToolkitProvider keyField="id" data={ listBuffSub } columns={ columns } search >
					{
						props => (
							<div>
								<SearchBar { ...props.searchProps } />
								<div className="row mb-4">
									<div className="col-md-12">
										{ this.props.auth.user.type === 1 ?
											<Fragment>
												<button onClick={(e) => {this.getListByView(1)}} name="view" value="1" type="button" className="btn btn-primary mr-1">Xem tất cả</button>
												<button onClick={(e) => {this.getListByView(0)}} name="view" value="0" type="button" className="btn btn-primary mr-1">Xem riêng</button>
											</Fragment>
										: ''}
										<button onClick={(e) => {this.getListByView('tatca')}} name="view" value="tatca" type="button" className="btn btn-secondary mr-1">Tất cả</button>
										<button onClick={(e) => {this.getListByView('dangchay')}} name="view" value="dangchay" type="button" className="btn btn-success mr-1">Đang chạy</button>
										<button onClick={(e) => {this.getListByView('hethan')}} name="view" value="hethan" type="button" className="btn btn-danger mr-1">Hết hạn</button>
										<button onClick={(e) => {this.getListByView('hoanthanh')}} name="view" value="hoanthanh" type="button" className="btn btn-primary mr-1">Hoàn thành</button>
										{/* <select onChange={(e) => {this.getListByLimit(e)}} className="form-control custom-select select-light col-md-3 custom-limit_pagi">
											<option value="0">Số lịch sử hiển thị (0-500)</option>
											<option value="1">500-1000</option>
											<option value="2">1000-1500</option>
											<option value="3">1500-2000</option>
											<option value="4">2000-2500</option>
											<option value="5">2500-3000</option>
											<option value="7">3000-3500</option>
											<option value="8">3500-4000</option>
											<option value="9">4500-5000</option>
										</select> */}
										<button onClick={(e) => {this.getListByLimit(this.state.page, "prev")}} name="page" value={this.state.page} type="button" className="btn btn-primary mr-1">{"<"}</button>
										<button onClick={(e) => {this.getListByLimit(this.state.page, "next")}} name="page" value={this.state.page} type="button" className="btn btn-primary mr-1">{">"}</button>
										<button onClick={(e) => {this.getListBySearch(e)}} name="view" value="0" type="button" className="btn btn-primary mr-1">Tìm kiếm</button>
										<input  onChange={this.onChange} value={this.state.obj_search}  name="obj_search" type="text" className="form-control mr-1" style={{"width": "100px", "display": "inline-block"}}/>
									</div>
								</div>
								<BootstrapTable
									{ ...props.baseProps }
									bodyStyle={ { border: 'none' } }
									headerStyle={ { border: 'red 1px solid' } }
									striped
									hover
									wrapperClasses="table-responsive"
									expandRow={ expandRow }
									pagination={ paginationFactory() } />
								{this.state.isOpenModalEdit ? <Modaledit openModal={this.openModalEdit} isOpenModal={this.state.isOpenModalEdit} dataid={this.state.id} datastatus={this.state.status} /> : ''}
								{this.state.isOpenModalGiahan ? <Modalgiahan openModal={this.openModalGiahan} isOpenModal={this.state.isOpenModalGiahan} dataid={this.state.id} datalsct={this.state.lsct} datamax_like={this.state.max_like} datadays={this.state.days} dataquantity_baiviet={this.state.quantity_baiviet} /> : ''}
								{this.state.isOpenModalNhatKy ? <Modalnhatky openModal={this.openModalNhatKy} isOpenModal={this.state.isOpenModalNhatKy} dataidfb={this.state.idfb} datalsct={this.state.lsct} /> : ''}
							</div>
						)
					}
				</ToolkitProvider>

			</Fragment>
		);
  	}
}

const mapStateToProps = state => ({
	buffsub: state.buffviplikeclone,
	auth: state.auth
});

export default connect(mapStateToProps, {getListItemBuffSub,searchDataBuffSub,load,searchDataFbVip, cancelOrder})(Nhatkyorder);