import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Swal from 'sweetalert2';
import copy from 'copy-text-to-clipboard';

class Catchuoi extends Component {
	state = {
		loaibotrunglap: true,
		noidung: "DAAAAE....|user1|pass1\nDAAAAG....|user2|pass2\nDAAAAH....|user3|pass3",
		text_placeholder: "DAAAAE....|user1|pass1\nDAAAAG....|user2|pass2\nDAAAAH....|user3|pass3",
		ngancachboikytu: "|",
		loaicat: 1,
		batdaucat: 1,
		dencum: 1,
		catnhungcum: "1,2",
		ketqua: "",
	}
	onChangeTrungLap = (e) =>{
		this.setState({
			[e.target.name]: !this.state.loaibotrunglap
		})
	}
	onClick = (e) =>{
		this.setState({
			[e.target.name]: e.target.value
		})
	}
	onChange = (e) =>{
		this.setState({
			[e.target.name]: e.target.value
		})
	}
	onTrigger = (e) => {
		var { loaibotrunglap, noidung, ngancachboikytu, loaicat, batdaucat, dencum, catnhungcum } = this.state;
		noidung = noidung.trim();
		noidung = noidung
			.split("\n")
			.filter(item => item)
			.join("\n");
		if (loaibotrunglap) {
			noidung = noidung
				.split("\n")
				.filter((item, i, allItems) => {
					return i === allItems.indexOf(item);
				})
				.join("\n");
		}
		if (loaicat == 1) {
			var final_noidung_tmp = noidung.split("\n");
			var final_noidung = [];
			final_noidung_tmp.forEach(function(v, i) {
				var v_tmp = v.split(ngancachboikytu);
				var v_final = v_tmp.filter(function(v, i) {
					return (batdaucat <= i+1 && i+1 <= dencum);
				});
				final_noidung[i] = v_final.join(ngancachboikytu);
			});
			noidung = final_noidung.join("\n");
		}
		if (loaicat == 2) {
			var final_noidung_tmp = noidung.split("\n");
			var final_noidung = [];
			final_noidung_tmp.forEach(function(v, i) {
				var v_tmp = v.split(ngancachboikytu);
				var v_final = v_tmp.filter(function(v, i) {
					var catnhungcum_tmp = catnhungcum.split(",");
					return (catnhungcum_tmp.indexOf((i+1).toString()) < 0);
				});
				final_noidung[i] = v_final.join(ngancachboikytu);
			});
			noidung = final_noidung.join("\n");
		}
		this.setState({
			ketqua: noidung
		});
	}
	
	render() {
		return (
			<Fragment>
				<div className="form-group row">
					<label className="col-sm-2 col-form-label edittext-label-right" htmlFor="">Loại bỏ trùng lặp?</label>
					<div className="col-sm-10" style={{"display": "flex", "alignItems": "center"}}>
						<div className="custom-control custom-switch mb-1">
							<input onChange={(e) => {this.onChangeTrungLap(e)}} type="checkbox" className="custom-control-input" id="loaibotrunglap" name="loaibotrunglap" checked={this.state.loaibotrunglap} />
							<label className="custom-control-label edittext-custom-control-label" htmlFor="loaibotrunglap" ></label>
						</div>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-2 col-form-label edittext-label-right" htmlFor="">Nội dung</label>
					<div className="col-sm-10">
						<textarea rows="5" placeholder={this.state.text_placeholder} className="form-control input-gray edittext-textarea" name="noidung" onChange={this.onChange} value={this.state.noidung} ></textarea>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-2 col-form-label edittext-label-right" htmlFor="">Ngăn cách bởi ký tự</label>
					<div className="col-sm-10">
						<input type="text" className="form-control" name="ngancachboikytu" value={this.state.ngancachboikytu} onChange={this.onChange} />
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-2 col-form-label edittext-label-right" htmlFor="">Loại cắt</label>
					<div className="col-sm-10">
						<div className="btn-group" role="group" aria-label="">
							<button type="button" className={"btn btn-outline-primary " + (this.state.loaicat == 1 ? "active" : "")} name="loaicat" value="1" onClick={(e) => {this.onClick(e)}}>Cắt từ x tới y</button>
							<button type="button" className={"btn btn-outline-primary " + (this.state.loaicat == 2 ? "active" : "")} name="loaicat" value="2" onClick={(e) => {this.onClick(e)}}>Cắt cụm x,y,z</button>
						</div>
					</div>
				</div>
				{
					this.state.loaicat == 1 ?
						<Fragment>
							<div className="form-group row">
								<label className="col-sm-2 col-form-label edittext-label-right" htmlFor="">Bắt đầu cắt</label>
								<div className="col-sm-10">
									<input type="text" className="form-control" name="batdaucat" value={this.state.batdaucat} onChange={this.onChange} />
								</div>
							</div>
							<div className="form-group row">
								<label className="col-sm-2 col-form-label edittext-label-right" htmlFor="">Đến cụm</label>
								<div className="col-sm-10">
									<input type="text" className="form-control" name="dencum" value={this.state.dencum} onChange={this.onChange} />
								</div>
							</div>
						</Fragment>
					: ""
				}
				{
					this.state.loaicat == 2 ?
						<Fragment>
							<div className="form-group row">
								<label className="col-sm-2 col-form-label edittext-label-right" htmlFor="">Cắt những cụm</label>
								<div className="col-sm-10">
									<input type="text" className="form-control" name="catnhungcum" value={this.state.catnhungcum} onChange={this.onChange} />
								</div>
							</div>
						</Fragment>
					: ""
				}
				<div className="form-group row">
					<label className="col-sm-2 col-form-label edittext-label-right" htmlFor=""></label>
					<div className="col-sm-10">
						<button type="button" className="btn btn-rounded btn-hero-primary edittext-btn" onClick={this.onTrigger} >Trigger</button>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-2 col-form-label edittext-label-right" htmlFor=""></label>
					<div className="col-sm-10">
						<textarea rows="5" placeholder="" className="form-control input-gray edittext-textarea" name="ketqua" defaultValue={this.state.ketqua} ></textarea>
					</div>
				</div>
			</Fragment>
		);
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
});
const mapDispatchToProps = {  };

export default connect(mapStateToProps, mapDispatchToProps)(Catchuoi);
