import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import {
    Modal,
    ModalBody,
    ModalFooter
} from 'reactstrap';
import { GiaHanFb } from '../../../actions/FbvipActions';
import Swal from 'sweetalert2';

class Modal_edit extends Component {
    state = {
        giahanthem: 7,
    }

    changeValue = (e) => {
        this.setState({
            giahanthem: e.target.value
        });
    }
	
	onChangeGiahan = (giahanthem, dataid, gtmtt, dataghltmn, datagoivip, openModal) => {
		Swal.fire({
			title: 'Bạn có chắc?',
			html: "Bạn có chắc chắn muốn mua thêm "+Math.floor(giahanthem)+" ngày với giá "+gtmtt+" Coin / ngày?",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Đồng ý',
			cancelButtonText: 'Không'
		}).then((result) => {
			if (result.value) {
				var array_data = [];
				array_data['ghltmn'] = dataghltmn;
				array_data['goivip'] = datagoivip;
				// ["ghltmn": dataghltmn, "goivip": datagoivip]
				this.props.GiaHanFb('facebookvipmatlivestreamv2', giahanthem, dataid, gtmtt, array_data, openModal);
			}
		})
	}
	
	onKeyPress = (e) => {
		if (!(e.charCode >= 48 && e.charCode <= 57)) {
			e.preventDefault();
		}
	}

    render() {
        const {isOpenModal, dataid, dataghltmn, datagoivip, datatimelive} = this.props;
        const {giahanthem} = this.state;
		const {gtmtt} = this.props.Facebookvipmatlivestreamv2.taotientrinh;
		const { loading } = this.props.Facebookvipmatlivestreamv2;
		var price = (datagoivip * giahanthem * datatimelive * gtmtt * dataghltmn);

        return (
            <Fragment>
                <Modal centered={true} size="lg" toggle={(e) => {this.props.openModal()}} isOpen={isOpenModal} className={(loading? ' block block-mode-loading' : '')} style={{"backgroundColor": "inherit", "boxShadow": "none"}}>
                    <div className="modal-header">
                        <p className="mb-0 text-dark">Gia hạn</p>
                        <button onClick={(e) => {this.props.openModal()}} type="button" className="close">×</button>
                    </div>
                    <ModalBody style={{opacity: loading? '.05':'1'}}>
						<div className="row">
                            <div className="col-md-12">
								<select className="form-control custom-select select-light" id="giahanthem" name="giahanthem" onChange={this.changeValue} value={this.state.giahanthem} >
									<option value="7">7 ngày</option>
									<option value="10">10 ngày</option>
									<option value="15">15 ngày</option>
									<option value="30">30 ngày</option>
								</select>
							</div>
						</div>
						<div className="row mt-4">
							<div className="col-12 text-center bold">
								<div className="card card-info">
									<div className="card-body">
										<h5 className="font-bold">Tổng: <span className="bold green"><span>{price}</span> Coin</span></h5>
										<h6 className="mb-0 bold"><span>Gia hạn thêm <span className="green">{giahanthem} ngày</span> với giá <span className="green">{gtmtt}</span> Coin / ngày</span></h6>
									</div>
								</div>
							</div>
						</div>
                    </ModalBody>
                    <ModalFooter style={{opacity: loading? '.05':'1'}}>
                        <button onClick={() => {this.onChangeGiahan(giahanthem, dataid, gtmtt, dataghltmn, datagoivip, this.props.openModal)}} type="button" className="btn btn-primary">Lưu</button>
                        <button onClick={(e) => {this.props.openModal()}} type="button" className="btn btn-danger">Huỷ</button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    Facebookvipmatlivestreamv2: state.Facebookvipmatlivestreamv2
});

export default connect(mapStateToProps, {GiaHanFb}) (Modal_edit);
