import React from 'react';
import axios from 'axios';
import {
	LOAD_SPAMSMS,
	UPDATE_SPAMSMS_TAB,
	UPDATE_SPAMSMS,
	// LOAD_PRICE_SHOPEE,
	LIMIT_PAGI_SPAMSMS,
	TYPE_SPAMSMS,
	LOADING_SPAMSMS,
	UPDATE_PRICE_BAOHANH_SHOPEE
} from "../actions/types";
import Swal from 'sweetalert2';
import { loadUser } from "./authActions";

export const loadSpamsms = (type = 0, page = 1, obj_search = '') => (dispatch, getState) => {
	dispatch({
		type: LOADING_SPAMSMS,
		payload: true
	});
	axios.get('/api/ho-tro-nhan-tin?type='+type+'&page='+page+'&obj_search='+obj_search, tokenConfig(getState))
		.then(res => {
			dispatch({
				type: LOADING_SPAMSMS,
				payload: false
			});
			if (res.data.status === 200) {
				dispatch({
					type: LOAD_SPAMSMS,
					payload: res.data.data
				});
			}
		});
};

// export const loadPriceFLBBKTT = () => (dispatch, getState) => {
	// axios.get('/api/facebooklocbanbekhongtuongtac/price', tokenConfig(getState))
		// .then(res => {
			// if (res.data.status === 200) {
				// dispatch({
					// type: LOAD_PRICE_FLBBKTT,
					// payload: res.data.data
				// });
			// }
		// });
// };

export const changeTab = (body) => (dispatch, getState) => {
	dispatch({
		type: UPDATE_SPAMSMS_TAB,
		payload: body
	});
};

export const updateSpamsms = (body) => (dispatch, getState) => {
	dispatch({
		type: UPDATE_SPAMSMS,
		payload: body
	});
};

export const updateLimitSpamsms = (body) => (dispatch) => {
	dispatch({
		type: LIMIT_PAGI_SPAMSMS,
		payload: body
	})
};

export const updateTypeSpamsms = (body) => (dispatch) => {
	dispatch({
		type: TYPE_SPAMSMS,
		payload: body
	})
};

export const updatePriceBaoHanh = (body) => (dispatch) => {
	dispatch({
		type: UPDATE_PRICE_BAOHANH_SHOPEE,
		payload: body
	})
};

export const addSpamsms = (body) => (dispatch, getState) => {
	dispatch({
		type: LOADING_SPAMSMS,
		payload: true
	});
	axios.post('/api/ho-tro-nhan-tin/add', body, tokenConfig(getState))
		.then(res => {
			dispatch({
				type: LOADING_SPAMSMS,
				payload: false
			});
			dispatch(loadUser());
			if (res.data.status === 200) {
				Swal.fire({
					icon: 'success',
					html: res.data.message
				});
			} else {
				var message = '';
				if (res.data.error) {
					if (res.data.error.object_id) {
						message = res.data.error.object_id[0];
					} else if (res.data.error.quantity) {
						message = res.data.error.quantity[0];
					} else {
						message = res.data.message;
					}
				} else {
					message = res.data.message;
				}
				Swal.fire({
					icon: 'error',
					title: 'Lỗi',
					text: message,
				});
			}
		});
};

// export const updateFLBBKTTactive = (body) => (dispatch, getState) => {
	// axios.post('/api/facebooklocbanbekhongtuongtac/active', body, tokenConfig(getState))
		// .then(res => {
			// dispatch(loadFLBBKTT());
		// });
// };


export const deleteSpamsms = (body) => (dispatch, getState) => {
	dispatch({
		type: LOADING_SPAMSMS,
		payload: true
	});
	axios.post('/api/ho-tro-nhan-tin/delete', body, tokenConfig(getState))
		.then(res => {
			dispatch({
				type: LOADING_SPAMSMS,
				payload: false
			});
			dispatch(loadSpamsms());
			if (res.data.status === 200) {
				Swal.fire({
					icon: 'success',
					html: res.data.message
				});
			} else {
				var message = '';
				if (res.data.error) {
					if (res.data.error.object_id) {
						message = res.data.error.object_id[0];
					} else if (res.data.error.quantity) {
						message = res.data.error.quantity[0];
					} else {
						message = res.data.message;
					}
				} else {
					message = res.data.message;
				}
				Swal.fire({
					icon: 'error',
					title: 'Lỗi',
					text: message,
				});
			}
		});
};

export const getInfoSpamsms = (sdt) => (dispatch, getState) => {
	let bodysend = {
		sdt: sdt,
	}
	return axios.post('/api/ho-tro-nhan-tin/getinfo', bodysend, tokenConfig(getState));
};

export const UpdateNdr = (ndr, dataid, closeM) => (dispatch, getState) => {
	let body = {
		id: dataid,
		ndr: ndr,
	};
	dispatch({
		type: LOADING_SPAMSMS,
		payload: true
	});
    axios.post('/api/ho-tro-nhan-tin/updatendr', body, tokenConfig(getState))
		.then(res => {
			dispatch({
				type: LOADING_SPAMSMS,
				payload: false
			});
			closeM();
			if (res.data.status === 200) {
				dispatch(loadSpamsms());
				Swal.fire({
					icon: 'success',
					html: 'Cập nhật thành công'
				});
			}
			if (res.data.status === 400) {
				var message = '';
				if (res.data.error) {
					if (res.data.error.object_id) {
						message = res.data.error.object_id[0];
					} else if (res.data.error.quantity) {
						message = res.data.error.quantity[0];
					}
				} else {
					message = res.data.message;
				}
				Swal.fire({
					icon: 'error',
					title: 'Lỗi',
					text: message,
				});
			}
		});
};

export const tokenConfig = getState => {
	// Get token from localhost
	const token = getState().auth.token;
	// Headers
	const config = {
		headers: {
			"Content-type": "application/json"
		}
	};
	
	// If token, add to headers
	if (token) {
		config.headers['ht-token'] = token;
	}
	
	return config;
};