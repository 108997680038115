import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import axios from 'axios';
import {
	LOAD_BSR,
	ADD_BSR,
	UPDATE_BSR,
	UPDATE_BSR_GETID,
	UPDATE_BSR_TAB,
	UPDATE_BSR_TGT,
	LOAD_PRICE_BSR,
	LIMIT_PAGI_BSR,
	TYPE_BSR,
	LOADING_BSR
} from "../actions/types";
import Swal from 'sweetalert2';
import { loadUser } from "./authActions";

export const loadBSR = (type = 0, page = 1, obj_search = '') => (dispatch, getState) => {
	dispatch({
		type: LOADING_BSR,
		payload: true
	});
	axios.get('/api/facebook_buff/list?type_api=review_fanpage&type='+type+'&page='+page+'&obj_search='+obj_search, tokenConfig(getState))
		.then(res => {
			dispatch({
				type: LOADING_BSR,
				payload: false
			});
			if (res.data.status === 200) {
				dispatch({
					type: LOAD_BSR,
					payload: res.data.data
				});
			}
		});
};

export const loadPriceBSR = () => (dispatch, getState) => {
	axios.get('/api/buffseedingreview/price', tokenConfig(getState))
		.then(res => {
			if (res.data.status === 200) {
				dispatch({
					type: LOAD_PRICE_BSR,
					payload: res.data.data
				});
			}
		});
};

export const addBSR = (body) => (dispatch, getState) => {
	dispatch({
		type: LOADING_BSR,
		payload: true
	});
	axios.post('/api/facebook_buff/create', body, tokenConfig(getState))
		.then(res => {
			dispatch({
				type: LOADING_BSR,
				payload: false
			});
			dispatch(loadUser());
			if (res.data.status === 200) {
				Swal.fire({
					icon: 'success',
					html: 'Tạo tiến trình seeding thành công, hệ thống sẽ tự động tăng theo thời gian bạn đã chọn <br/><br/> Nếu không đủ lượt seeding hệ thống sẽ tự động hoàn lại số tiền chưa chạy'
				});
			}
			if (res.data.status === 400) {
				var message = '';
				if (res.data.error) {
					if (res.data.error.object_id) {
						message = res.data.error.object_id[0];
					} else if (res.data.error.quantity) {
						message = res.data.error.quantity[0];
					} else {
						message = res.data.message;
					}
				} else {
					message = res.data.message;
				}
				{/*confirmAlert({
					customUI: ({ onClose }) => {
						return	(
							<div aria-labelledby="swal2-title" aria-describedby="swal2-content" className="swal2-popup swal2-modal swal2-icon-warning swal2-show an-confirm-alert" tabIndex="-1" role="dialog" aria-live="assertive" aria-modal="true" style={{"display": "flex"}}>
								<div className="swal2-header">
									<div className="swal2-icon swal2-warning swal2-icon-show" style={{"display": "flex"}}>
										<div className="swal2-icon-content"><i className="fas fa-times"></i></div>
									</div>
									<h2 className="swal2-title" id="swal2-title" style={{"display": "flex"}}>
										Lỗi
									</h2>
								</div>
								<div className="swal2-content">
									<div id="swal2-content" className="swal2-html-container" style={{"display": "block"}}>{ message }</div>
									<div className="swal2-validation-message" id="swal2-validation-message"></div>
								</div>
								<div className="swal2-actions">
									<button type="button" className="swal2-cancel btn btn-secondary m-1" aria-label="" style={{"display": "inline-block"}} onClick={() => { onClose(); }} >OK</button>
								</div>
							</div>
						)
					}
				});*/}
				
				Swal.fire({
					icon: 'error',
					title: 'Lỗi',
					text: message,
				});
			}
		});
};

export const updateBSR = (body) => (dispatch) => {
	dispatch({
		type: UPDATE_BSR,
		payload: body
	})
};

export const updateLimitBSR = (body) => (dispatch) => {
	dispatch({
		type: LIMIT_PAGI_BSR,
		payload: body
	})
};

export const updateTypeBSR = (body) => (dispatch) => {
	dispatch({
		type: TYPE_BSR,
		payload: body
	})
};

export const getID = (body) => (dispatch, getState) => {
	let bodysend = {
		id_user: getState().auth.user.id,
		link: body.lhibv
	};
	dispatch({
		type: LOADING_BSR,
		payload: true
	});
	axios.post('/api/checklinkfb/check/', bodysend, tokenConfig(getState))
	.then(res => {
		dispatch({
			type: LOADING_BSR,
			payload: false
		});
		if (res.data.status === 200) {
			dispatch({
				type: UPDATE_BSR_GETID,
				payload: res.data.id
			});
		} else {
			Swal.fire({
				icon: 'error',
				title: 'Lỗi',
				text: res.data.msg,
			});
		}
	}).catch(err => {
		dispatch({
			type: LOADING_BSR,
			payload: false
		});
		openBtComfirmAlert('Có lỗi xảy ra!', 'error', 'Lỗi');
    });
};

export const changeTab = (body) => (dispatch, getState) => {
	dispatch({
		type: UPDATE_BSR_TAB,
		payload: body
	});
};

export const updateTangGiaThem = (body) => (dispatch) => {
	dispatch({
		type: UPDATE_BSR_TGT,
		payload: body
	})
};

export const openBtComfirmAlert = (message, type, title = '') => {
	Swal.fire({
		icon: type,
		title: title,
		text: message,
	});
	return false;
}
export const upTangGiaThem = (body) => (dispatch, getState) => {
	dispatch({
		type: LOADING_BSR,
		payload: true
	});
	axios.post('/api/buffseedingreview/tanggiathem', body, tokenConfig(getState))
		.then(res => {
			dispatch({
				type: LOADING_BSR,
				payload: false
			});
			if (res.data.status === 200) {
				Swal.fire({
					icon: 'success',
					html: 'Tạo tiến trình seeding thành công, hệ thống sẽ tự động tăng theo thời gian bạn đã chọn <br/><br/> Nếu không đủ lượt seeding hệ thống sẽ tự động hoàn lại số tiền chưa chạy'
				});
				dispatch(loadBSR());
			}
			if (res.data.status === 400) {
				var message = '';
				if (res.data.error.object_id) {
					message = res.data.error.object_id[0];
				} else {
					message = res.data.message;
				}
				Swal.fire({
					icon: 'error',
					title: 'Lỗi',
					text: message,
				});
			}
		});
};

export const upHuyOrder = (body) => (dispatch, getState) => {
	dispatch({
		type: LOADING_BSR,
		payload: true
	});
	axios.post('/api/facebook_buff/removeorder', body, tokenConfig(getState))
		.then(res => {
			dispatch({
				type: LOADING_BSR,
				payload: false
			});
			if (res.data.status === 200) {
				Swal.fire({
					icon: 'success',
					text: res.data.message,
				});
				dispatch(loadBSR());
			}
			if (res.data.status === 400) {
				var message = '';
				if (res.data.error.object_id) {
					message = res.data.error.object_id[0];
				} else if (res.data.error.quantity) {
					message = res.data.error.quantity[0];
				} else if (res.data.error.id) {
					message = res.data.error.id[0];
				} else {
					message = res.data.message;
				}
				Swal.fire({
					icon: 'error',
					title: 'Lỗi',
					text: message,
				});
			}
		});
};

export const saveEditOrderAdmin = (data, closeM) => (dispatch, getState) => {
	let bodysend = {
		formdata: data,
		type_api: 'review_fanpage'
	};
	dispatch({
		type: LOADING_BSR,
		payload: true
	});
	axios.post('/api/facebook_buff/admin_edit_order/', bodysend, tokenConfig(getState))
	.then(res => {
		dispatch({
			type: LOADING_BSR,
			payload: false
		});
		if (res.data.status === 200) {
			dispatch(loadBSR());
			closeM();
			Swal.fire(
	      		'Thành công!',
	      		'Sửa thành công',
	      		'success'
	    	)
		} else {
			openBtComfirmAlert(res.data.message, 'error', 'Lỗi');
		}
	}).catch(err => {
		console.log(err);
		openBtComfirmAlert('Có lỗi xảy ra!', 'error', 'Lỗi');
    });
};

export const getConfigMoDong = (type = '') => (dispatch, getState) => {
	dispatch({
		type: LOADING_BSR,
		payload: true
	});

	axios.get('/api/facebook_buff/getconfig?type_api=buffreviewcheckinfanpage', tokenConfig(getState))
	.then(res => {
		dispatch({
			type: LOADING_BSR,
			payload: false
		});
		if (res.data.status === 200) {
			dispatch({
				type: 'CONFIG_MO_DONG_BSR',
				payload: res.data
			});
		}
	});
}

export const tokenConfig = getState => {
	// Get token from localhost
	const token = getState().auth.token;
	// Headers
	const config = {
		headers: {
			"Content-type": "application/json"
		}
	};
	
	// If token, add to headers
	if (token) {
		config.headers['ht-token'] = token;
	}
	
	return config;
};