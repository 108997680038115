import React, { Component, Fragment } from "react";
import { Route, Redirect, Link } from "react-router-dom";
import { connect } from 'react-redux';
import { loadEditSP, updateSP, editSP, closeSP, openSP } from '../../../../actions/SupportActions';
import Swal from 'sweetalert2';
import copy from 'copy-text-to-clipboard';
import ModalImage from "react-modal-image";
import Admin1 from '../../../../icon/admin1.png';
import Users1 from '../../../../icon/users1.png';

class Edit extends Component {
    state = {
		
    }
	constructor(props) {
		super(props);

		this.state = {
			file: ""
		};

		this.onChangeFile = this.onChangeFile.bind(this);
	}
	
    componentDidMount(){
		this.props.loadEditSP(this.props.id);
    }
	
	copyID(id) {
		copy(id);
		Swal.fire({
			icon: 'success',
			title: '',
			text: 'Đã copy',
		});
		return false;
	}
	
	onChange = (e) => {
		const { taotientrinh } = this.props.Support;
		
		var new_taotientrinh = {
			...taotientrinh,
			[e.target.name]: e.target.value
		};
		
		this.props.updateSP(new_taotientrinh);
	}
	
	onClick = (e) => {
		this.setState({
			file: ""
		});
		
		const { taotientrinh } = this.props.Support;
		
		var new_taotientrinh = {
			...taotientrinh,
			file: ""
		};
		
		this.props.updateSP(new_taotientrinh);
	}

	onClickCreate = (e) => {
		var { taotientrinh } = this.props.Support;
		Swal.fire({
			title: 'Bạn có chắc?',
			html: "Bạn có chắc chắn muốn gửi trả lời không?",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Đồng ý',
			cancelButtonText: 'Không'
		}).then((result) => {
			if (result.value) {
				taotientrinh = {
					...taotientrinh,
					"id": this.props.id
				};
				this.props.editSP(taotientrinh);
				
				this.setState({
					file: ""
				});
			}
		});
	}

	onClickClose = (e) => {
		Swal.fire({
			title: 'Bạn có chắc?',
			html: "Bạn có chắc chắn muốn đóng hỗ trợ này?",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Đồng ý',
			cancelButtonText: 'Không'
		}).then((result) => {
			if (result.value) {
				this.props.closeSP({"id": this.props.id});
			}
		});
	}
	
	onClickOpen = (e) => {
		Swal.fire({
			title: 'Bạn có chắc?',
			html: "Bạn có chắc chắn muốn mở lại hỗ trợ này?",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Đồng ý',
			cancelButtonText: 'Không'
		}).then((result) => {
			if (result.value) {
				this.props.openSP({"id": this.props.id});
			}
		});
	}
	
	onChangeFile(e) {
		this.setState({
			file: URL.createObjectURL(e.target.files[0])
		});
		
		const { taotientrinh } = this.props.Support;
		
		var new_taotientrinh = {
			...taotientrinh,
			file: e.target.files[0]
		};
		
		this.props.updateSP(new_taotientrinh);
	}
	
	timeSupportTicket(cell) {
		if (Number.isInteger(cell)) {
			let date_now = Math.floor(Date.now() / 1000);
			let calDateDay = (date_now - cell)/86400;
			if (calDateDay > 1) {
				return Math.ceil(calDateDay) + ' ngày trước';
			} else {
				let calDatehour = (date_now - cell)/3600;
				if (calDatehour < 0.6) {
					return Math.ceil(calDatehour*100) + ' phút trước';
				} else {
					return Math.ceil(calDatehour) + ' giờ trước';
				}
			}
		}
	}
	
    render() {
		var { mota } = this.props.Support.taotientrinh;
		var { edittientrinh } = this.props.Support;
		
		var that = this;
		var list_chat = (
			<Fragment>
				{
					edittientrinh.data_chat.map(function(v, i) {
						return (
							<Fragment key={i}>
								<div>
									<div className="card-gray mt-2 card-support" style={{"background" : i%2 == 0 ? '#0553ab26' : '', "borderRadius": ".25rem", "whiteSpace": "pre-line"}}>
										<div className="card-body py-2">
											<div className="row">
												<div className="col-auto px-1">
													<img src={v.id_user == '-1' ? Admin1 : Users1 } width="160" alt="user" className="img-avatars rounded-circle border" />
												</div>
												<div className="col pl-2 text-left">
													<h6 className="bold cl-green b200 block-text mb-0">{v.username}</h6>
													<h6 className="font-13 b200 mb-0">{that.timeSupportTicket(v.created_at)}</h6>
													<div className="mt-2">
														<p>{v.mota}</p>
													</div>
													{
														v.anh != '' ?
															<ModalImage
																className="modalimage-cus"
																small={ v.anh }
																large={ v.anh }
																alt={ v.id }
															/>
														: ''
													}
												</div>
											</div>
										</div>
									</div>
								</div>
							</Fragment>
						);
					})
				}
			</Fragment>
		);
        return (
            <Fragment>
				{
					edittientrinh.data.length <= 0 ?
						<div className="block-content tab-content overflow-hidden">
							<h1 className="text-danger">Đơn hỗ trợ này không tồn tại!</h1>
						</div>
					:
						<Fragment>
							<div className="form-group row">
								<div className="col-auto pr-0" style={{"margin": "auto"}}>
									<a href="" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="profile-pic">
										<img src={edittientrinh.data[0].id_user == '-1' ? Admin1 : Users1 } width="160" alt="user" className="img-avatars rounded-circle border" />
									</a>
								</div>
								<div className="col text-left">
									<label className="col-sm-10 col-form-label" htmlFor="">
										<h3 className="font-bold">{edittientrinh.data[0].tieude}</h3>
									</label>
									<label className="col-sm-2 col-form-label text-right" htmlFor="">
										<i className="fa fa-comments"></i> {edittientrinh.data_chat.length}
										<h5>{this.timeSupportTicket(edittientrinh.data[0].created_at)}</h5>
									</label>
								</div>
							</div>
							<div className="form-group row">
								<div className={edittientrinh.data[0].anh != "" ? "col-sm-8" : "col-sm-12"}>
									<div className="form-group row">
										<label className="col-auto pr-2">
											<i className="fas fa-circle mr-2 cl-green" style={{"fontSize": "5px", "verticalAlign": "middle"}}></i>Tên tài khoản:
										</label>
										<div className="col pl-0">
											<span className="badge text-white font-14 px-3 badge-success">{edittientrinh.data[0].username}</span>
										</div>
									</div>
									<div className="form-group row">
										<label className="col-auto pr-2">
											<i className="fas fa-circle mr-2 cl-green" style={{"fontSize": "5px", "verticalAlign": "middle"}}></i>Loại hỗ trợ:
										</label>
										<div className="col pl-0">
											<span className="badge text-white font-14 px-3 badge-info">{edittientrinh.data[0].vande}</span>
										</div>
									</div>
									{
										edittientrinh.data[0].idbv != "" ?
											<div className="form-group row">
												<label className="col-auto pr-2">
													<i className="fas fa-circle mr-2 cl-green" style={{"fontSize": "5px", "verticalAlign": "middle"}}></i>Object Id:
												</label>
												<div className="col pl-0">
													<span className="badge text-white font-14 px-3 bg-danger">{edittientrinh.data[0].idbv}</span>
													<span className="badge text-white font-14 px-3 badge-primary" style={{"marginLeft": "7px", "cursor": "pointer"}} onClick={()=>{this.copyID(edittientrinh.data[0].idbv)}}>COPY</span>
												</div>
											</div>
										: ''
									}
									<div className="form-group row">
										<label className="col-auto pr-2 ">
											<i className="fas fa-circle mr-2 cl-green" style={{"fontSize": "5px", "verticalAlign": "middle"}}></i>Mô tả:
										</label>
										<div className="col pl-0">
											<div className="card-gray ml-md-3" style={{"borderRadius": ".25rem", "whiteSpace": "pre-line"}}>
												<div className="card-body px-2 py-1 content-support">
													<div className="font-14 cl-gray mb-0">
														<p>{edittientrinh.data[0].mota}</p>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="col-sm-4" style={{"float": "right", "maxHeight": "200px", "display": edittientrinh.data[0].anh != "" ? 'block' : 'none'}}>
									<ModalImage
										className="modalimage-cus-2"
										small={ edittientrinh.data[0].anh }
										large={ edittientrinh.data[0].anh }
										alt={ edittientrinh.data[0].id }
									/>
								</div>
							</div>
							<div className="form-group row">
								<div className="col-sm-10">
									<div className="form-group">
										<input type="text" name="huongdan_1" className="card-gray form-control" disabled value={window.location.protocol + '//' + window.location.hostname + '/support/' + this.props.id} />
									</div>
								</div>
								<div className="col-sm-2">
									<div className="form-group">
										<button className="btn btn-primary" style={{"width": "100%"}} onClick={()=>{this.copyID(window.location.protocol + '//' + window.location.hostname + '/support/' + this.props.id)}}>Copy link hỗ trợ</button>
									</div>
								</div>
							</div>
							<hr/>
							{list_chat}
							<hr/>
							{
								edittientrinh.data[0].status == 0 ?
									<Fragment>
										<div className="form-group row">
											<div className="col-sm-2">
												<div className='form-group float-left'>
													<Link to="/support" className="btn btn-hero-warning btn-rounded btn-block" >
														Trở về
													</Link>
												</div>
											</div>
											<div className="col-sm-1"></div>
											<div className="col-sm-6"></div>
											<div className="col-sm-1"></div>
											<div className="col-sm-2">
												<div className='form-group float-right'>
													<button type="button" className="btn btn-hero-danger btn-rounded btn-block" onClick={this.onClickOpen} >
														Mở hỗ trợ
													</button>
												</div>
											</div>
										</div>
									</Fragment>
								:
									<Fragment>
										<div className="form-group row">
											<label className="col-sm-4 col-form-label" htmlFor="">
												Mô tả chi tiết:
											</label>
											<div className="col-sm-8">
												<textarea rows="10" onChange={this.onChange} placeholder="Nhập câu trả lời" className="form-control input-gray" name="mota" value={mota}></textarea>
											</div>
										</div>
										<div className="form-group row">
											<label className="col-sm-4 col-form-label" htmlFor="">
												Thêm hình ảnh mô tả<span className="text-danger">{(this.state.file !== "" ? ' (Click vào ảnh để xóa ảnh)' : '')}</span>:
											</label>
											<div className="col-sm-8">
												<div className="form-group row">
													<div className="col-sm-9">
														<img src={this.state.file} style={{"maxWidth": "300px"}} onClick={this.onClick} />
													</div>
													<div className="col-sm-3">
														<input type="file" onChange={this.onChangeFile} style={{ display: "none" }} ref="fileInput" />
														<button type="button" className="btn btn-hero-secondary btn-rounded btn-block" onClick={() => this.refs.fileInput.click()} >
															<i className="fas fa-cloud-upload-alt text-white"></i> Tải ảnh lên
														</button>
													</div>
												</div>
											</div>
										</div>
										<div className="form-group row">
											<div className="col-sm-2">
												<div className='form-group float-left'>
													<Link to="/support" className="btn btn-hero-warning btn-rounded btn-block" >
														Trở về
													</Link>
												</div>
											</div>
											<div className="col-sm-1"></div>
											<div className="col-sm-6">
												<div className='form-group float-right w-100'>
													<button type="button" className="btn btn-hero-primary btn-rounded btn-block" onClick={this.onClickCreate} >
														Trả lời
													</button>
												</div>
											</div>
											<div className="col-sm-1"></div>
											<div className="col-sm-2">
												<div className='form-group float-right'>
													<button type="button" className="btn btn-hero-danger btn-rounded btn-block" onClick={this.onClickClose} >
														Đóng hỗ trợ
													</button>
												</div>
											</div>
										</div>
									</Fragment>
							}
						</Fragment>
				}
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    adminpage: state.adminpage,
    Support: state.Support,
});

export default connect(mapStateToProps, { loadEditSP, updateSP, editSP, closeSP, openSP }) (Edit);
