import React, { Component, Fragment } from 'react';
import {
  BrowserRouter as Router,
  // Switch,
  // Route,
  Link
} from "react-router-dom";
import {
	// Button,
	// Modal,
	// ModalHeader,
	// ModalBody,
	// Form,
	// FormGroup,
	// Label,
	// Input,
	// NavLink,
	Alert
} from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { forgotpassword, resetpassword, checkTokenReset } from '../../actions/authActions';
import { clearErrors } from '../../actions/errorActions';
import Layer2 from '../../iconlanding/5/Layer-2.png';
import parse from 'html-react-parser';

class ForgotpasswordModal extends Component {
	state = {
		// modal: false,
		username: '',
		email: '',
		password: '',
		repeatpassword: '',
		msg: null,
		msg2: null,
		checktoken: 1,
	}
	
	static propTypes =  {
		auth: PropTypes.object.isRequired,
		error: PropTypes.object.isRequired,
		register: PropTypes.func.isRequired,
		clearErrors: PropTypes.func.isRequired
	}
	
	componentDidMount() {
		if (this.props.action_reset === '/resetpassword') {
			var search_param = new URLSearchParams(window.location.search);
			var token = search_param.get('token');
			if (token == null) {
				token = '';
			}
			var data = {
				token,
			};
			this.props.checkTokenReset(data);
		}
	}
	
	componentDidUpdate(prevProps) {
		const { error } = this.props;
		if (error !== prevProps.error) {
			// Check for register error
			if (error.id === 'FORGOTPASSWORD_SEND_FAIL' || error.id === 'RESETPASSWORD_SEND_FAIL') {
				this.setState({ msg: error.msg.msg, msg2: null });
			} else if (error.id === 'FORGOTPASSWORD_SEND_SUCCESS' || error.id === 'RESETPASSWORD_SEND_SUCCESS') {
				this.setState({ msg: null, msg2: error.msg.msg });
			} else if (error.id === 'TOKEN_RESET_SUCCESS') {
				this.setState({ checktoken: 1 });
			} else if (error.id === 'TOKEN_RESET_FAIL') {
				this.setState({ checktoken: 2 });
			} else {
				this.setState({ msg: null, msg2: null });
			}
		}
	}
	
	clearAlert = () => {
		// Clear errors
		this.props.clearErrors();
	}
	
	onChange = e => {
		this.setState({ [e.target.name]: e.target.value });
	};
	
	onSubmit = e => {
		e.preventDefault();
		
		const { username, email, password, repeatpassword } = this.state;
		var search_param = new URLSearchParams(window.location.search);
		var token = search_param.get('token');
		if (token == null) {
			token = '';
		}
		
		if (this.props.action_reset === '/resetpassword') {
			var newUser = {
				token,
				password,
				repeatpassword,
			};
			this.props.resetpassword(newUser);
		} else {
			var newUser = {
				username,
				email,
			};
			this.props.forgotpassword(newUser);
		}
	};
	openMenu = (data = null) => {
		if (data !== null) {
			this.setState({
				...this.state,
				menuopen: data,
			});
		}
	}
	
	render() {
		let loading = this.props.auth.loading;
		const domainSite = window.location.hostname;
		var config_website = {};
		if (localStorage.getItem("confignavbar") != null) {
			config_website = JSON.parse(localStorage.getItem("confignavbar"));
		} else {
			config_website = this.props.auth.config_website;
		}
		var search_param = new URLSearchParams(window.location.search);
		var token = search_param.get('token');
		if (token == null) {
			token = '';
		}
		var html_form = "";
		if ((this.props.action_reset === '/resetpassword' && token.trim() == '') || (this.props.action_reset === '/resetpassword' && this.state.checktoken == 2)) {
			html_form = (
				<Fragment>
					<div className="alert alert-danger d-flex align-items-center justify-content-between" role="alert" >
						<div className="flex-fill mr-3">
							<p className="mb-0">Link đặt lại mật khẩu đã hết hạn! #tk</p>
						</div>
						<div className="flex-00-auto">
							<i className="fa fa-fw fa-exclamation-circle"></i>
						</div>
					</div>
				</Fragment>
			)
		} else {
			html_form = (
				<Fragment>
					<div className="frm">
						<h2 className="login-area-head">Sign In</h2>
						<h2>
							{
								this.props.action_reset === '/resetpassword' ?
									"Đặt lại mật khẩu"
								:
									"Quên mật khẩu"
							}
						</h2>
						<p></p>
						{loading ? (
							<div className="preloader">
								<div className="spinner-grow spinner-grow-sm text-primary" role="status" style={{ "marginTop": "20%" }}>
								  <span className="sr-only"></span>
								</div>
								<div className="spinner-grow spinner-grow-sm text-danger" role="status" style={{ "marginTop": "20%" }}>
								  <span className="sr-only">.</span>
								</div>
								<div className="spinner-grow spinner-grow-sm text-warning" role="status" style={{ "marginTop": "20%" }}>
								  <span className="sr-only"></span>
								</div>
							</div>
						):('')}
						{this.state.msg ? (
							<div className="alert alert-warning d-flex align-items-center justify-content-between" role="alert" >
							  <div className="flex-fill mr-3">
								<p className="mb-0">{parse(this.state.msg)}</p>
							  </div>
							  <div className="flex-00-auto">
								<i className="fa fa-fw fa-exclamation-circle"></i>
							  </div>
							</div>
						  ) : null}
						{this.state.msg2 ? (
							<div className="alert d-flex align-items-center justify-content-between" role="alert" style={{"color": "#445e27", "backgroundColor": "#e6f0db", "borderColor": "#dceacd"}} >
								<div className="flex-fill mr-3">
									<p className="mb-0">{parse(this.state.msg2)}</p>
								</div>
							</div>
						) : null}
						<form className="js-validation-signup" onSubmit={this.onSubmit} method="POST" >
							{
								this.props.action_reset === '/resetpassword' ?
									<Fragment>
										<div className="form-group">
											<span className="input-icon">
												<input name="password" className="form-control" id="password" type="password" placeholder="Nhập mật khẩu" onChange={this.onChange} />
												<i className="fa fa-lock" style={{"top": "-5px", "left": "20px"}}></i>
											</span>
										</div>
										<div className="form-group">
											<span className="input-icon">
												<input name="repeatpassword" className="form-control" id="repeatpassword" type="password" placeholder="Nhập lại mật khẩu" onChange={this.onChange} />
												<i className="fa fa-lock" style={{"top": "-5px", "left": "20px"}}></i>
											</span>
										</div>
										
										<div className="formlast">
											<input type="submit" className="btn btn-fans" value="Đặt lại mật khẩu"/>
										</div>
									</Fragment>
								:
									<Fragment>
										<div className="form-group">
											<span className="input-icon">
												<input name="username" className="form-control" id="username" type="text" placeholder="Nhập tên tài khoản" onChange={this.onChange} />
												<i className="fa fa-user" style={{"top": "-5px", "left": "20px"}}></i>
											</span>
											<p className="help-block help-block-error"></p>
										</div>
										<div className="form-group">
											<span className="input-icon">
												<input name="email" className="form-control" id="email" type="email" placeholder="Nhập email" onChange={this.onChange} />
												<i className="fa fa-envelope" style={{"top": "-5px", "left": "20px"}}></i>
											</span>
											<p className="help-block help-block-error"></p>
										</div>
										
										<div className="formlast">
											<input type="submit" className="btn btn-fans" value="Gửi"/>
										</div>
									</Fragment>
							}
						</form>
					</div>
				</Fragment>
			)
		}
		return (
			<Router>
				<Fragment>
					<div className="landingcus5" style={{"zoom": "122%", "backgroundColor": "#fff"}}>
						<nav className="navbar navbar-default navbar-static-top">
							<div className="container">
								<div className="navbar-header">
									<a className="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar" aria-expanded="false" aria-controls="navbar" onClick={(e) => {e.preventDefault();this.openMenu(!this.state.menuopen)}}>
										<span className="sr-only"></span>
										<span className="icon-bar"></span>
										<span className="icon-bar"></span>
										<span className="icon-bar"></span>
									</a>
									<a className="link-fx font-w700 fontSize-h3 site-logo site-title" href="/" style={{"padding": "0", "top": "12px", "color": "#fa329c"}}>
									  <span className="text-uppercase">{domainSite.slice(0, domainSite.lastIndexOf('.'))}</span>
									  <span className="text-uppercase">{domainSite.indexOf('.') > -1 ? domainSite.slice(domainSite.lastIndexOf('.')) : ''}</span>
									</a>
								</div>
								<div id="navbar" className={"navbar-collapse collapse "+(this.state.menuopen ? ' in' : '')}>
									<ul className="nav navbar-nav navbar-right">
										<li className="nav-item menu_item_14 " id="menu_item_14">
											<Link to="/services" onClick={this.props.action_service} className="nav-link">
												Dịch vụ
											</Link>
										</li>
										<li className="nav-item active menu_item_14 " id="menu_item_14">
											<Link to="/" className="nav-link" onClick={this.props.action_register}>
												Đăng ký
											</Link>
										</li>
										<li className="nav-item menu_item_13 " id="menu_item_13">
											<Link to="/" className="nav-link" onClick={this.props.action_login}>
												Đăng nhập
											</Link>
										</li>
									</ul>
								</div>
							</div>
						</nav>
						<div className="top-content-area">	
							<div className="container">
								<div className="row">
									<div className="col-sm-6 ">
										{html_form}
									</div>
									<div className="col-sm-6 text-right">
										<div className="img-wrap">
											<img className="" src={Layer2} alt="Social Media Services " />
										</div>
									</div>
								</div>
								<p><br/></p>
							</div>
						</div>
						<footer>
							<div className="container">
								<div className="col-sm-6 ftr-left text-left">
									<p>copyright 2021&nbsp;&nbsp;<span>HackLikePro</span>. All Rights Reserved.</p>
								</div>
								<div className="col-sm-6 ftr-right text-right">
									<ul className="social-icon">
										<li><a href="https://anon.ws/?https://www.facebook.com/100010750668234" target="_blank" className=""><i className="fab fa-facebook-f"></i></a></li>
									</ul>
								</div>
							</div>
						</footer>
					</div>
				</Fragment>
			</Router>
		);
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	error: state.error
})

export default connect(mapStateToProps, { forgotpassword, resetpassword, checkTokenReset, clearErrors })(ForgotpasswordModal);