import React, { Component, Fragment } from 'react';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import store from '../../../store';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { loadVV, updateLimitVV, updateTypeVV, deleteVV } from '../../../actions/VimeoviewActions';
import Moment from 'react-moment';
import {
	Modal,
	ModalBody,
	ModalFooter
} from 'reactstrap';
import Swal from 'sweetalert2';
import { confirmAlert } from 'react-confirm-alert';
import Modaledit from './modal_edit';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { RiExchangeDollarLine } from "react-icons/ri";
const { SearchBar } = Search;

class nhatky extends Component {
	static propTypes =  {
		loadVV: PropTypes.func.isRequired,
		updateLimitVV: PropTypes.func.isRequired,
		updateTypeVV: PropTypes.func.isRequired,
		deleteVV: PropTypes.func.isRequired,
	};
	
	state = {
		isOpenModalEdit: false,
		idfb: 0,
		status: "",
		count_success: 0,
	}
	
	componentDidMount() {
		this.props.loadVV();
	}
	
	onChangeLimit = e => {
		const { type } = this.props.Vimeoview;
		this.props.updateLimitVV(e.target.value);
		this.props.loadVV(type, e.target.value);
	};
	
	onCheckList = (type) => {
		const { limit_pagi } = this.props.Vimeoview;
		this.props.updateTypeVV(type);
		this.props.loadVV(type, limit_pagi);
	}
	
    openModalEdit = (data = null) => {
		this.setState({
			...this.state,
			isOpenModalEdit: !this.state.isOpenModalEdit,
		});
		if (data !== null) {
			this.setState({
				...this.state,
				isOpenModalEdit: !this.state.isOpenModalEdit,
				id: data.id,
				status: data.status,
				count_success: data.count_success,
			});
		}
    }
	
	render() {
		const { listtientrinh, limit_pagi } = this.props.Vimeoview;
		function formatTrangThai(cell, row) {
			var message = '';
			if (cell === 0) {
				return (
					<span className="badge badge-primary"><i className="fas fa-check mr-1"></i>Hoàn thành</span>
				);
			} else if (cell === 1) {
				return (
					<span className="badge badge-success"><i className="fas fa-sync-alt mr-1"></i>Đang chạy</span>
				);
			} else if (cell === 2) {
				return (
					<span className="badge badge-danger"><i className="fas fa-ban mr-1"></i>ID die không thể chạy</span>
				);
			} else if (cell === 3) {
				return (
					<span className="badge badge-warning"><i className="fas fa-times mr-1"></i>Hủy đơn</span>
				);
			} else if (cell === 5) {
				return (
					<span className="badge badge-info"><RiExchangeDollarLine size={12} className="mr-1" />Hoàn tiền</span>
				);
			} else {
				return ('');
			}
		}
		function filterValueTrangThai(cell, row) {
			if (cell === 0) {
				return ('Hoàn thành');
			} else if (cell === 1) {
				return ('Đang chạy');
			} else if (cell === 2) {
				return ('ID die không thể chạy');
			} else if (cell === 3) {
				return ('Hủy đơn');
			} else if (cell === 5) {
				return ('Hoàn tiền');
			} else {
				return ('');
			}
		}
		
		function formatQuantity(cell, row) {
			return (cell);
		}
		
		function formatDachay(cell, row) {
			return (cell);
		}
		
		function onClickXoa(row) {
			confirmAlert({
				// closeOnClickOutside: false,
				customUI: ({ onClose }) => {
					return	(
						<div aria-labelledby="swal2-title" aria-describedby="swal2-content" className="swal2-popup swal2-modal swal2-icon-warning swal2-show an-confirm-alert" tabIndex="-1" role="dialog" aria-live="assertive" aria-modal="true" style={{"display": "flex"}}>
							<div className="swal2-header">
								<div className="swal2-icon swal2-warning swal2-icon-show" style={{"display": "flex"}}>
									<div className="swal2-icon-content">?</div>
								</div>
							</div>
							<div className="swal2-content">
								<div id="swal2-content" className="swal2-html-container" style={{"display": "block"}}>Sau khi hủy bạn sẽ được hoàn lại số lượng chưa tăng sau 1 đến 3 ngày và trừ thêm 5.000 Coin phí để tránh Spam!</div>
								<div className="swal2-validation-message" id="swal2-validation-message"></div>
							</div>
							<div className="swal2-actions">
								<button type="button" className="swal2-confirm btn btn-danger m-1" aria-label="" style={{"display": "inline-block"}} onClick={() => { store.dispatch(deleteVV(row.id)); onClose(); }} >Đồng ý</button>
								<button type="button" className="swal2-cancel btn btn-secondary m-1" aria-label="" style={{"display": "inline-block"}} onClick={() => { onClose(); }} >Hủy</button>
							</div>
						</div>
					)
				}
			});
		}
		
		const columns = [{
			dataField: 'id',
			text: 'ID',
			sort: true,
		}, {
		  text: 'Thao tác',
		  dataField: '-',
		  style: {
			'white-space' : 'nowrap'
		  },
		  formatter: (cell, row) => 
		  	<Fragment>
				{
					this.props.auth.user.type === 1 ?
						<Fragment>
							<button type="button" onClick={() => {this.openModalEdit(row)}} className="btn btn-hero-success text-center p-2 mr-1"><i className="fa fa-fw fa-pen"></i></button>
						</Fragment>
					: ''
				}
				{
					row.status !== 3 && row.status !== 0 ?
						<Link to="/tool/vimeoview" className="btn btn-hero-danger text-center p-2 mr-1" onClick={() => { onClickXoa(row) }}>
							<i className="fa fa-fw fa-times"></i>
						</Link>
					: ''
				}
	    	</Fragment>
		}, {
			dataField: 'object_id',
			text: 'ID seeding',
			sort: true,
		}, {
			dataField: 'start',
			text: 'Start',
			sort: true,
		}, {
			dataField: 'quantity',
			text: 'Số lượng',
			sort: true,
			formatter: formatQuantity,
			filterValue: formatQuantity
		}, {
			dataField: 'price_per',
			text: 'Giá',
			sort: true
		}, {
			dataField: 'prices',
			text: 'Tổng tiền',
			sort: true
		}, {
			dataField: 'count_success',
			text: 'Đã chạy',
			sort: true,
			formatter: formatDachay,
			filterValue: formatDachay
		}, {
			dataField: 'username',
			text: 'Người Add',
			sort: true,
			classes: 'notranslate',
		}, {
			dataField: 'created_at',
			text: 'Khởi tạo',
			sort: true,
			formatter: (cell, row) => 
				<Fragment>
					<span>
						<Moment format="DD-MM-YYYY HH:mm:ss">{cell*1000}</Moment>
					</span>
				</Fragment>
		}, {
			dataField: 'status',
			text: 'Trạng thái',
			sort: true,
			formatter: formatTrangThai,
			filterValue: filterValueTrangThai
		}];

		const expandRow = {
			renderer: (row) => (
				<Fragment>
					<div className="row">
						<div className="col-lg-8 row">
							<div className="col-lg-4">
								<div className="form-group">
									<label htmlFor="example-text-input">Ghi chú</label>
									<textarea className="form-control" id="gc" name="gc" placeholder="" rows="2" defaultValue={row.note}></textarea>
								</div>
							</div>
						</div>
					</div>
				</Fragment>
			),
			showExpandColumn: true,
			expandColumnPosition: 'right',
			expandHeaderColumnRenderer: ({ isAnyExpands }) => {
				if (isAnyExpands) {
					return <i className="fas fa-caret-up"></i>;
				}
				return <b><i className="fas fa-caret-down"></i></b>;
			},
			expandColumnRenderer: ({ expanded }) => {
				if (expanded) {
					return (
						<b><i className="fas fa-caret-up"></i></b>
					);
				}
				return (
					<b><i className="fas fa-caret-down"></i></b>
				);
			}
		};

		return (
			<Fragment>
				<ToolkitProvider keyField="id" data={ listtientrinh } columns={ columns } search >
					{
						props => (
							<div>
								<SearchBar { ...props.searchProps } />
								{ this.props.auth.user.type === 1 ?
									<Fragment>
										<button onClick={() => {this.onCheckList(1)}} type="button" className="btn btn-primary mr-1">Xem tất cả</button>
										<button onClick={() => {this.onCheckList(0)}} type="button" className="btn btn-primary mr-1">Xem riêng</button>
									</Fragment>
								: ''}
								<select className="form-control custom-select select-light col-md-3 custom-limit_pagi" id="limit_pagi" name="limit_pagi" onChange={this.onChangeLimit} value={limit_pagi} >
									<option value="0">Số lịch sử hiển thị (0-500)</option>
									<option value="1">500-1000</option>
									<option value="2">1000-1500</option>
									<option value="3">1500-2000</option>
									<option value="4">2000-2500</option>
									<option value="5">2500-3000</option>
									<option value="7">3000-3500</option>
									<option value="8">3500-4000</option>
									<option value="9">4500-5000</option>
								</select>
								<BootstrapTable { ...props.baseProps } expandRow={ expandRow } pagination={ paginationFactory() } wrapperClasses="table-responsive" striped hover condensed />
								{this.state.isOpenModalEdit ? <Modaledit openModal={this.openModalEdit} isOpenModal={this.state.isOpenModalEdit} dataid={this.state.id} datastatus={this.state.status} datacount_success={this.state.count_success} /> : ''}
							</div>
						)
					}
				</ToolkitProvider>
				<div>
					{/*<BootstrapTable keyField='id' data={ listtientrinh } columns={ columns } pagination={ paginationFactory() } wrapperClasses="table-responsive" striped hover condensed />*/}
				</div>
			</Fragment>
		)
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	Vimeoview: state.Vimeoview,
});
const mapDispatchToProps = { loadVV, updateLimitVV, updateTypeVV, deleteVV }

export default connect(mapStateToProps, mapDispatchToProps)(nhatky);