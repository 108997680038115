import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import {
    Modal,
    ModalBody,
    ModalFooter
} from 'reactstrap';
import { updateFVC } from '../../../actions/FacebookvipcommentActions';
import { updateSticker, loadSticker, clearListSticker, updateFbVip } from '../../../actions/FbvipActions';
import {
  BrowserRouter as Router,
  Link
} from "react-router-dom";

class Modal_edit extends Component {
    state = {
        id: this.props.dataid,
		sticker: ""
    }
	componentDidMount() {
		this.props.loadSticker('facebookvipcomment', this.state.id);
	}

    onChange = (e) => {
        var { taotientrinh } = this.props.Facebookvipcomment;
		if (e.target.name === "sticker") {
			var type = taotientrinh.sticker;
			if (type.indexOf(e.target.value) === -1) {
				type.push(e.target.value);
			} else {
				var position = type.indexOf(e.target.value);
				type.splice(position, 1);
			}
			var new_taotientrinh = {
				...taotientrinh,
				"sticker": type
			};
			this.props.updateFbVip('facebookvipcomment', new_taotientrinh);
			
			var type = taotientrinh.sticker_pack[this.state.id];
			if (typeof taotientrinh.sticker_pack[this.state.id] === 'undefined') {
				taotientrinh.sticker_pack[this.state.id] = [];
				taotientrinh.sticker_pack[this.state.id].push(e.target.value);
			} else {
				if (type.indexOf(e.target.value) === -1) {
					type.push(e.target.value);
				} else {
					var position = type.indexOf(e.target.value);
					type.splice(position, 1);
				}
				taotientrinh.sticker_pack[this.state.id] = type;
				if (taotientrinh.sticker_pack[this.state.id].length <= 0) {
					delete taotientrinh.sticker_pack[this.state.id];
				}
			}
			this.props.updateSticker('facebookvipcomment', taotientrinh.sticker_pack);
		}
    }

    render() {
        const {isOpenModal} = this.props;
        const {id, sticker} = this.state;
		const { changetab, loading, list_sticker } = this.props.Facebookvipcomment;
		const ar_sticker = this.props.Facebookvipcomment.taotientrinh.sticker;
		
		var sticker_modal = '';
		var that = this;
		if (list_sticker.length > 0) {
			sticker_modal = list_sticker.map(function(v, i) {
				// return <Link to='/tool/facebookvipcomment' onClick={() => {}} className="btn text-center p-2 mr-1 col-lg-2"><img src={v.node.image.uri} width="50" height="50" /><br/>{v.node.label}</Link>
				// var sticker_label = (
					// <label className="btn text-center p-2 mr-1 col-lg-2" key={i} style={{"border": (ar_sticker.indexOf(v.node.id) > -1) ? "3px solid green" : '0px solid green'}}>
						// <input type="checkbox" name="sticker" onChange={that.onChange} value={v.node.id} checked="" style={{"display": "none"}} />
						// <img src={v.node.image.uri} width="64" height="64" /><br/>{v.node.label}
					// </label>
				// );
				// return sticker_label;
				var sticker_label = (
					<label className="btn text-center p-2 mr-1 col-lg-2" key={i} style={{"border": (ar_sticker.indexOf(v.id_sticker_child) > -1) ? "3px solid green" : '0px solid green'}}>
						<input type="checkbox" name="sticker" onChange={that.onChange} value={v.id_sticker_child} checked="" style={{"display": "none"}} />
						<img src={process.env.PUBLIC_URL + '/images/stickerfb/sticker/'+v.id_sticker_package+'/'+v.id_sticker_child+'.png'} width="64" height="64" /><br/>{v.name}
					</label>
				);
				return sticker_label;
			})
		}
        return (
            <Fragment>
                <Modal centered={true} size="lg" toggle={(e) => {this.props.clearListSticker('facebookvipcomment'); this.props.openModal()}} isOpen={isOpenModal} className={(loading? ' block block-mode-loading' : '')} style={{"backgroundColor": "inherit", "boxShadow": "none"}}>
                    <div className="modal-header">
                        <p className="mb-0 text-dark">List Sticker</p>
                        <button onClick={(e) => {this.props.clearListSticker('facebookvipcomment'); this.props.openModal()}} type="button" className="close">×</button>
                    </div>
                    <ModalBody style={{opacity: loading? '.05':'1'}}>
                        <div className="row">
                            <div className="col-md-12">
								{sticker_modal}
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter style={{opacity: loading? '.05':'1'}}>
                        {/* <button onClick={() => {this.props.updateSticker('facebookvipcomment', this.props.openModal, sticker)}} type="button" className="btn btn-primary">Thêm</button> */}
                        <button onClick={(e) => {this.props.clearListSticker('facebookvipcomment'); this.props.openModal()}} type="button" className="btn btn-danger">Đóng</button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    Facebookvipcomment: state.Facebookvipcomment
});

export default connect(mapStateToProps, { updateSticker, loadSticker, updateFbVip, clearListSticker }) (Modal_edit);
