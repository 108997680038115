const initialState = {
	loadingblock: false,
	listItem: [],
	formData: {
		name_telecom: '',
		level_up: 0,
		price_buy: '',
		phone_up: '',
		type_phone: '',
		pass_phone: '',
		note: '',
		type_create: 0,
	}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case 'CHANGE_DATA_FORM_RECHARGE_CARDPHONE':
    	return {
			...state,
			formData: {...state.formData, [action.payload.name]: action.payload.value}
		};
	case 'DELETE_ITEM_RECHARGE_CARDPHONE':
		return {
			...state,
			listItem: state.listItem.map(
	           (content, i) => content.id === action.payload ? {...content, type: 0} : content
	       	)
		}
	case 'LIST_DATA_ITEM_RECHARGE_CARDPHONE':
		return {
			...state,
			listItem: action.payload
		}
	default:
      	return state;
  }
}
