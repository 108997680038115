import {
	LOAD_FLBBKTT,
	UPDATE_FLBBKTT_TAB,
	UPDATE_FLBBKTT,
	LOAD_PRICE_FLBBKTT,
	LOADING_FLBBKTT,
	LIMIT_PAGI_FLBBKTT,
	TYPE_FLBBKTT,
	UPDATE_IDFB_FLBBKTT
} from "../actions/types";

const date7dnext = () => {
	var result = new Date();
  	result.setDate(result.getDate() + 7);
  	return result;
}

const initialState = {
	"taotientrinh": {
		"idfb": "",
		"usernamefb": "",
		"cookie": "",
		"dtsg": "",
		"gtmtt": "0",
	},
	"changetab": 0,
	"listtientrinh": [],
	"price": [],
	"loading": false,
	"limit_pagi": 0,
	"type": 0,
};

export default function(state = initialState, action) {
	switch (action.type) {
		case LOAD_FLBBKTT:
			return {
				...state,
				"listtientrinh": action.payload
			};
		case UPDATE_FLBBKTT_TAB:
			return {
				...state,
				"changetab": action.payload
			};
		case UPDATE_FLBBKTT:
			return {
				...state,
				"taotientrinh": action.payload
			};
		case UPDATE_IDFB_FLBBKTT:
			return {
				...state,
				"taotientrinh": {
					...state.taotientrinh,
					"idfb": action.payload
				}
			};
		case LOAD_PRICE_FLBBKTT:
			return {
				...state,
				"price": action.payload,
				"taotientrinh": {
					...state.taotientrinh,
					"gtmtt": action.payload.prices_web
				}
			};
		case LOAD_PRICE_FLBBKTT:
			return {
				...state,
				"price": action.payload,
				"taotientrinh": {
					...state.taotientrinh,
					"gtmtt": action.payload.prices_web
				}
			};
		case LOADING_FLBBKTT:
			return {
				...state,
				"loading": action.payload
			};
		case LIMIT_PAGI_FLBBKTT:
			return {
				...state,
				"limit_pagi": action.payload
			};
		case TYPE_FLBBKTT:
			return {
				...state,
				"type": action.payload
			};
		default:
		  return state;
	}
}
