import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import TaoTientrinh from "./tabFacebookvipmatlivestream/taotientrinh";
import NhatKy from "./tabFacebookvipmatlivestream/nhatky";
import DanhSach from "./tabFacebookvipmatlivestream/danhsach";

import { changeTab, loadFVMLS } from '../../actions/FacebookvipmatlivestreamActions';
import { load, changeTabFbVip } from '../../actions/FbvipActions';

class Facebookvipmatlivestream extends Component {
	static propTypes =  {
		changeTab: PropTypes.func.isRequired,
		loadFVMLS: PropTypes.func.isRequired,
		changeTabFbVip: PropTypes.func.isRequired,
	};
	componentDidMount() {
		this.props.changeTabFbVip('facebookvipmatlivestream', 0);
	};
	onClick = (value) => {
		this.props.changeTabFbVip('facebookvipmatlivestream', value);
		// this.props.loadFVMLS();
		this.props.load('facebookvipmatlivestream');
	};
	render() {
		const { changetab, loading } = this.props.Facebookvipmatlivestream;
		return (
			<Fragment>
				<div className={"block block-bordered shadow"+ (loading? ' block-mode-loading' : '')}>
					<ul className="nav nav-tabs nav-tabs-block" data-toggle="tabs" role="tablist" >
						<li className="nav-item col-lg-6 licus">
							<Link className={"nav-link " + (changetab === 0 ? "active": "" )} to="/tool/facebookvipmatlivestream" onClick={() => { this.onClick(0) }} >
								Tạo Tiến Trình
							</Link>
						</li>
						{/*<li className="nav-item col-lg-6 licus">
							<Link className={"nav-link " + (changetab === 2 ? "active": "" )} to="/tool/facebookvipmatlivestream" onClick={() => { this.onClick(2) }} >
								Danh sách tài khoản
							</Link>
						</li>*/}
						<li className="nav-item col-lg-6 licus">
							<Link className={"nav-link " + (changetab === 1 ? "active": "" )} to="/tool/facebookvipmatlivestream" onClick={() => { this.onClick(1) }} >
								Danh Sách Order
							</Link>
						</li>
					</ul>
					<div className="block-content tab-content overflow-hidden">
						<div className={"tab-pane fade fade-left " + (changetab === 0 ? "show active": "" )} id="btabs-animated-slideleft-home" role="tabpanel" >
							<TaoTientrinh />
						</div>
						{/*<div className={"tab-pane fade fade-left " + (changetab === 2 ? "show active": "" )} id="btabs-animated-slideleft-list" role="tabpanel" >
							<DanhSach />
						</div>*/}
						<div className={"tab-pane fade fade-left " + (changetab === 1 ? "show active": "" )} id="btabs-animated-slideleft-profile" role="tabpanel" >
							<NhatKy />
						</div>
					</div>
				</div>
			</Fragment>
		);
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	Facebookvipmatlivestream: state.Facebookvipmatlivestream,
});
const mapDispatchToProps = { changeTab, loadFVMLS, load, changeTabFbVip };

export default connect(mapStateToProps, mapDispatchToProps)(Facebookvipmatlivestream);
