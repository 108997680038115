import {
	LOAD_BLSC,
	UPDATE_BLSC,
	UPDATE_BLSC_GETID,
	UPDATE_BLSC_TAB,
	LIMIT_PAGI_BLSC,
	TYPE_BLSC,
	LOAD_PRICE_BLSC,
	LOADING_BLSC
} from "../actions/types";

const initialState = {
	"taotientrinh": {
		"lhi": "",
		"loai": "sub",
		"goi": "0",
		"slct": "100",
		"gtmtt": "0",
		"gc": "",
		"camxuc": "like",
		"ndcmt": "",
	},
	"changetab": 0,
	"listtientrinh": [],
	"tanggiathem": {
		"id": "",
		"orders_id": "",
		"per_price": 0,
		"price": 0,
		"quantity": 0,
		"loai": "",
	},
	"price": {
		bufflikesubcomment_likepost_1: {
			min_bh_web: 0,
			prices_web: 0,
		},
		bufflikesubcomment_sub_1: {
			min_bh_web: 0,
			prices_web: 0,
		},
		bufflikesubcomment_comment_1: {
			min_bh_web: 0,
			prices_web: 0,
		},
		bufflikesubcomment_likefanpage_1: {
			min_bh_web: 0,
			prices_web: 0,
		},
	},
	"limit_pagi": 0,
	"type": 0,
	"loading": false,
	facebookbuffsubtay_sv1_mo_dong: 1,
	facebookbufflikefanpagetay_sv1_mo_dong: 1,
	facebookbufflikeposttay_sv1_mo_dong: 1,
};

export default function(state = initialState, action) {
	switch (action.type) {
		case LOAD_BLSC:
			return {
				...state,
				"listtientrinh": action.payload.data,
			};
		case UPDATE_BLSC:
			return {
				...state,
				"taotientrinh": action.payload
			};
		case UPDATE_BLSC_GETID:
			return {
				...state,
				"taotientrinh": {
					...state.taotientrinh,
					"lhi": action.payload
				}
			};
		case UPDATE_BLSC_TAB:
			return {
				...state,
				"changetab": action.payload
			};
		case LIMIT_PAGI_BLSC:
			return {
				...state,
				"limit_pagi": action.payload
			};
		case TYPE_BLSC:
			return {
				...state,
				"type": action.payload
			};
		case LOAD_PRICE_BLSC:
			return {
				...state,
				"price": merDataPricePage(action.payload),
			};
		case LOADING_BLSC:
			return {
				...state,
				"loading": action.payload
			};
		case 'CONFIG_MO_DONG_BLSC':
			return {
				...state,
				facebookbuffsubtay_sv1_mo_dong: action.payload.facebookbuffsubtay_sv1_mo_dong,
				facebookbufflikefanpagetay_sv1_mo_dong: action.payload.facebookbufflikefanpagetay_sv1_mo_dong,
				facebookbufflikeposttay_sv1_mo_dong: action.payload.facebookbufflikeposttay_sv1_mo_dong,
			};
		default:
		  return state;
	}
}

function merDataPricePage(data) {
	let dataNew = {
		...initialState.price
	}
	if (data.length > 0) {
		data.forEach(function(v, i){
			if (v.name_table === 'bufflikesubcomment_likepost_1') {
				dataNew['bufflikesubcomment_likepost_1'] = v;
			} else if (v.name_table === 'bufflikesubcomment_sub_1') {
				initialState.taotientrinh.gtmtt = v.prices_web;
				dataNew['bufflikesubcomment_sub_1'] = v;
			} else if (v.name_table === 'bufflikesubcomment_comment_1') {
				dataNew['bufflikesubcomment_comment_1'] = v;
			} else if (v.name_table === 'bufflikesubcomment_likefanpage_1') {
				dataNew['bufflikesubcomment_likefanpage_1'] = v;
			}
		});
	};
	return dataNew;
}
