import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import axios from 'axios';
import {
	LOAD_SP,
	UPDATE_SP,
	LIMIT_PAGI_SP,
	TYPE_SP,
	LOADING_SP,
	LOAD_EDIT_SP,
} from "../actions/types";
import Swal from 'sweetalert2';
import { loadUser, getNotiSP } from "./authActions";

export const loadSP = (type = 0, limit = 0) => (dispatch, getState) => {
	dispatch({
		type: LOADING_SP,
		payload: true
	});
	axios.get('/api/support?type='+type+'&limit='+limit, tokenConfig(getState))
		.then(res => {
			dispatch({
				type: LOADING_SP,
				payload: false
			});
			if (res.data.status === 200) {
				dispatch({
					type: LOAD_SP,
					payload: res.data.data
				});
			}
		});
};

export const loadEditSP = (id = 0) => (dispatch, getState) => {
	dispatch({
		type: LOADING_SP,
		payload: true
	});
	axios.get('/api/support/chat?id='+id, tokenConfig(getState))
		.then(res => {
			dispatch({
				type: LOADING_SP,
				payload: false
			});
			if (res.data.status === 200) {
				dispatch({
					type: LOAD_EDIT_SP,
					payload: res.data
				});
			}
		});
};

export const addSP = (body) => (dispatch, getState) => {
	dispatch({
		type: LOADING_SP,
		payload: true
	});
	let formData = new FormData();
    formData.append("vande", body.vande);
    formData.append("tieude", body.tieude);
    formData.append("idbv", body.idbv);
    formData.append("mota", body.mota);
    formData.append("file", body.file);
	
	axios.post('/api/support/add', formData, tokenConfig(getState))
		.then(res => {
			dispatch({
				type: LOADING_SP,
				payload: false
			});
			if (res.data.status === 200) {
				Swal.fire({
					icon: 'success',
					html: 'Gửi đơn hỗ trợ thành công'
				}).then((result) => {
					window.location.href = '/support';
				});
			}
			if (res.data.status === 400) {
				var message = '';
				if (res.data.error) {
					if (res.data.error.object_id) {
						message = res.data.error.object_id[0];
					} else if (res.data.error.quantity) {
						message = res.data.error.quantity[0];
					} else {
						message = res.data.message;
					}
				} else {
					message = res.data.message;
				}
				
				Swal.fire({
					icon: 'error',
					title: 'Lỗi',
					html: message,
				});
			}
		}).catch(err => {
			dispatch({
				type: LOADING_SP,
				payload: false
			});
			Swal.fire({
				icon: 'error',
				title: 'Lỗi',
				html: 'Có lỗi xảy ra!',
			});
		});
};

export const editSP = (body) => (dispatch, getState) => {
	dispatch({
		type: LOADING_SP,
		payload: true
	});
	let formData = new FormData();
    formData.append("mota", body.mota);
    formData.append("id", body.id);
    formData.append("file", body.file);
	
	axios.post('/api/support/edit', formData, tokenConfig(getState))
		.then(res => {
			dispatch({
				type: LOADING_SP,
				payload: false
			});
			dispatch(loadEditSP(body.id));
			dispatch(updateSP({
				"vande": "",
				"tieude": "",
				"idbv": "",
				"mota": "",
				"file": "",
			}));
			dispatch(getNotiSP());
			if (res.data.status === 200) {
				Swal.fire({
					icon: 'success',
					html: 'Trả lời thành công'
				});
			}
			if (res.data.status === 400) {
				var message = '';
				if (res.data.error) {
					if (res.data.error.object_id) {
						message = res.data.error.object_id[0];
					} else if (res.data.error.quantity) {
						message = res.data.error.quantity[0];
					} else {
						message = res.data.message;
					}
				} else {
					message = res.data.message;
				}
				
				Swal.fire({
					icon: 'error',
					title: 'Lỗi',
					html: message,
				});
			}
		}).catch(err => {
			dispatch({
				type: LOADING_SP,
				payload: false
			});
			dispatch(updateSP({
				"vande": "",
				"tieude": "",
				"idbv": "",
				"mota": body.mota,
				"file": "",
			}));
			Swal.fire({
				icon: 'error',
				title: 'Lỗi',
				html: 'Vui lòng không tải ảnh quá 5MB!',
			});
		});
};

export const closeSP = (body) => (dispatch, getState) => {
	dispatch({
		type: LOADING_SP,
		payload: true
	});
	
	axios.post('/api/support/close', body, tokenConfig(getState))
		.then(res => {
			dispatch({
				type: LOADING_SP,
				payload: false
			});
			if (res.data.status === 200) {
				Swal.fire({
					icon: 'success',
					html: 'Đóng hỗ trợ thành công',
				}).then((result) => {
					window.location.href = '/support';
				});
			}
			if (res.data.status === 400) {
				var message = '';
				if (res.data.error) {
					if (res.data.error.object_id) {
						message = res.data.error.object_id[0];
					} else if (res.data.error.quantity) {
						message = res.data.error.quantity[0];
					} else {
						message = res.data.message;
					}
				} else {
					message = res.data.message;
				}
				
				Swal.fire({
					icon: 'error',
					title: 'Lỗi',
					html: message,
				});
			}
		}).catch(err => {
			dispatch({
				type: LOADING_SP,
				payload: false
			});
			Swal.fire({
				icon: 'error',
				title: 'Lỗi',
				html: 'Có lỗi xảy ra!',
			});
		});
};

export const openSP = (body) => (dispatch, getState) => {
	dispatch({
		type: LOADING_SP,
		payload: true
	});
	
	axios.post('/api/support/open', body, tokenConfig(getState))
		.then(res => {
			dispatch({
				type: LOADING_SP,
				payload: false
			});
			dispatch(loadEditSP(body.id));
			dispatch(getNotiSP());
			if (res.data.status === 200) {
				Swal.fire({
					icon: 'success',
					html: 'Mở hỗ trợ thành công',
				});
			}
			if (res.data.status === 400) {
				var message = '';
				if (res.data.error) {
					if (res.data.error.object_id) {
						message = res.data.error.object_id[0];
					} else if (res.data.error.quantity) {
						message = res.data.error.quantity[0];
					} else {
						message = res.data.message;
					}
				} else {
					message = res.data.message;
				}
				
				Swal.fire({
					icon: 'error',
					title: 'Lỗi',
					html: message,
				});
			}
		}).catch(err => {
			dispatch({
				type: LOADING_SP,
				payload: false
			});
			Swal.fire({
				icon: 'error',
				title: 'Lỗi',
				html: 'Có lỗi xảy ra!',
			});
		});
};

export const updateSP = (body) => (dispatch) => {
	dispatch({
		type: UPDATE_SP,
		payload: body
	})
};

export const updateLimitSP = (body) => (dispatch) => {
	dispatch({
		type: LIMIT_PAGI_SP,
		payload: body
	})
};

export const updateTypeSP = (body) => (dispatch) => {
	dispatch({
		type: TYPE_SP,
		payload: body
	})
};

export const tokenConfig = getState => {
	// Get token from localhost
	const token = getState().auth.token;
	// Headers
	const config = {
		headers: {
			"Content-type": "application/json"
		}
	};
	
	// If token, add to headers
	if (token) {
		config.headers['ht-token'] = token;
	}
	
	return config;
};
