import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import {
    Modal,
    ModalBody,
    ModalFooter
} from 'reactstrap';

import { saveEditOrderAdmin } from '../../../actions/buffviewsvideoAction';

class Modal_edit extends Component {
    state = {
        id: this.props.dataid,
        view_now: this.props.dataview_now,
        status: this.props.datastatus,
    }

    changeValue = (e) => {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value
        });
    }

    render() {
        const {isOpenModal} = this.props;
        const {loadding} = this.props.buffviewsvideo;
        const {id,view_now,status} = this.state;

        return (
            <Fragment>
                <Modal centered={true} size="lg" toggle={(e) => {this.props.openModal()}} isOpen={isOpenModal} className={(loadding? ' block block-mode-loading' : '')} style={{"backgroundColor": "inherit", "boxShadow": "none"}}>
					<div className="modal-header">
						<p className="mb-0 text-dark">Sửa</p>
						<button onClick={(e) => {this.props.openModal()}} type="button" className="close">×</button>
					</div>
                    <ModalBody style={{opacity: loadding? '.05':'1'}}>
                        <div className="row">
							<div className="col-md-12">
								<div className="form-group">
									<label>Trạng thái</label>
									<select name="status" value={status} onChange={(e) => {this.changeValue(e)}} className="form-control">
										<option value="0">Hoàn thành</option>
										<option value="1">Đang chạy</option>
										<option value="2">Id lỗi</option>
										<option value="3">Hủy đơn</option>
										<option value="5">Hoàn tiền</option>
									</select>
								</div>

								<div className="form-group">
									<label>Số lượng đã chạy</label>
									<input type="number" name="view_now" onChange={(e) => {this.changeValue(e)}} value={view_now} className="form-control" />
								</div>

							</div>
						</div>
                    </ModalBody>
                    <ModalFooter style={{opacity: loadding? '.05':'1'}}>
                        <button onClick={(e) => {this.props.saveEditOrderAdmin(id, view_now, status, this.props.openModal)}} type="button" className="btn btn-primary">Lưu</button>
                        <button onClick={(e) => {this.props.openModal()}} type="button" className="btn btn-danger">Huỷ</button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    buffviewsvideo: state.buffviewsvideo
});

export default connect(mapStateToProps, {saveEditOrderAdmin}) (Modal_edit);
