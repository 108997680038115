import {
	LOAD_FBB,
	UPDATE_FBB_TAB,
	UPDATE_FBB,
	UPDATE_COOKIE_FBB,
	LOADING_FBB,
	LOAD_PRICE_FBB,
	LIMIT_PAGI_FBB,
	TYPE_FBB
} from "../actions/types";

const date7dnext = () => {
	var result = new Date();
  	result.setDate(result.getDate() + 7);
  	return result;
}

const initialState = {
	"taotientrinh": {
		"ctkfcc": "",
		"tgctt_tu": "7",
		"tgctt_den": "22",
		"tgdctt_tu": "60",
		"tgdctt_den": "600",
		"lnncx": "0",
		"lnncx_type": [],
		"lnncx_tdmn": "200",
		"blbv": "0",
		"blbv_tu": "1",
		"blbv_den": "5",
		"blbv_tdmn": "100",
		"tdcbb": "0",
		"tdcbb_tdmn": "100",
		"tdglmkb": "0",
		"tdglmkb_tdmn": "100",
		"tdcnlmkb": "0",
		"tdcnlmkb_tdmn": "100",
		"snmcatt": "30",
		"gc": "",
		"userAgent": "",
		"gtmtt": "0",
		"idfb": "",
		"usernamefb": "",
		"dtsg": "",
	},
	"changetab": 0,
	"listtientrinh": [],
	"loading": false,
	"price": [],
	"limit_pagi": 0,
	"type": 0,
};

export default function(state = initialState, action) {
	switch (action.type) {
		case LOAD_FBB:
			return {
				...state,
				"listtientrinh": action.payload
			};
		case UPDATE_FBB_TAB:
			return {
				...state,
				"changetab": action.payload
			};
		case UPDATE_FBB:
			return {
				...state,
				"taotientrinh": action.payload
			};
		case UPDATE_COOKIE_FBB:
			return {
				...state,
				"taotientrinh": {
					...state.taotientrinh,
					"ctkfcc": action.payload.cookie,
					"userAgent": action.payload.userAgent,
				}
			};
		case LOADING_FBB:
			return {
				...state,
				"loading": action.payload
			};
		case LOAD_PRICE_FBB:
			return {
				...state,
				"price": action.payload,
				"taotientrinh": {
					...state.taotientrinh,
					"gtmtt": action.payload.prices_web
				}
			};
		case LIMIT_PAGI_FBB:
			return {
				...state,
				"limit_pagi": action.payload
			};
		case TYPE_FBB:
			return {
				...state,
				"type": action.payload
			};
		default:
		  return state;
	}
}
