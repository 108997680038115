import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import Moment from 'react-moment';
import ModalAddPriceFunction from './modal/addpricefunction';
import paginationFactory from 'react-bootstrap-table2-paginator';
import store from '../../../store';

import { editconfigsupport, saveAddSuppot, listSupport} from '../../../actions/adminPageAction';

class TagSupport extends Component {
	state = {
        isOpenModalAdd: false,
        isOpenModalEdit: false,
    }
    
	 componentDidMount(){
       this.props.listSupport();
    }
    onChange = (e) =>{
      const { support } = this.props.adminpage;
      var data = {
        ...support,
        [e.target.name]: e.target.value
      }
      this.props.editconfigsupport(data);
    }
    saveConfig(){
        const { support } = this.props.adminpage;
        this.props.saveAddSuppot(support);
    }
    render() {
        const { support } = this.props.adminpage;
        return (
            <Fragment>
              <div className="form-group">
                <label>Link hỗ trợ facebook</label>
                <input className="form-control" name="link_facebook" value={support.link_facebook} onChange={this.onChange} placeholder="" />
              </div>
              <div className="form-group">
                <label>Link hỗ trợ zalo</label>
                <input className="form-control" name="link_zalo" value={support.link_zalo}  onChange={this.onChange} placeholder="" />
              </div>
              <div className="form-group">
                <label>Link hỗ trợ telegram</label>
                <input className="form-control" name="link_telegram" value={support.link_telegram}  onChange={this.onChange} placeholder="" />
              </div>
              <div className="form-group">
                <label>Link hỗ trợ skype</label>
                <input className="form-control" name="link_skype" value={support.link_skype}  onChange={this.onChange} placeholder="" />
              </div>
              <div className="form-group">
                <label>Link hỗ trợ khác</label>
                <input className="form-control" name="link_other" value={support.link_other}  onChange={this.onChange} placeholder="" />
              </div>
              <div className="form-group">
                <label>Số điện thoại 1</label>
                <input className="form-control" name="phone_1" value={support.phone_1}  onChange={this.onChange} placeholder="" />
              </div>
              <div className="form-group">
                <label>Số điện thoại 2</label>
                <input className="form-control" name="phone_2" value={support.phone_2}  onChange={this.onChange} placeholder="" />
              </div>
              <div className="form-group">
                <button className="btn btn-success" onClick={()=>{this.saveConfig()}}>Lưu</button>
              </div>
            </Fragment>
        );
    }

}

const mapStateToProps = state => ({
    adminpage: state.adminpage,
});

export default connect(mapStateToProps, {editconfigsupport, saveAddSuppot, listSupport}) (TagSupport);
