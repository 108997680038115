import React, { Component, Fragment } from 'react';
import {
  BrowserRouter as Router,
  // Switch,
  // Route,
  Link
} from "react-router-dom";
import {
	// Button,
	// Modal,
	// ModalHeader,
	// ModalBody,
	// Form,
	// FormGroup,
	// Label,
	// Input,
	// NavLink,
	Alert
} from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { register } from '../../actions/authActions';
import { clearErrors } from '../../actions/errorActions';
import parse from 'html-react-parser';

class RegisterModal extends Component {
	state = {
		// modal: false,
		username: '',
		email: '',
		password: '',
		repeatpassword: '',
		facebook_id: '4',
		phone: '',
		msg: null,
	}
	
	static propTypes =  {
		auth: PropTypes.object.isRequired,
		error: PropTypes.object.isRequired,
		register: PropTypes.func.isRequired,
		clearErrors: PropTypes.func.isRequired
	}
	
	componentDidUpdate(prevProps) {
		const { error } = this.props;
		if (error !== prevProps.error) {
			// Check for register error
			if (error.id === 'REGISTER_FAIL') {
				this.setState({ msg: error.msg.msg });
			} else {
				this.setState({ msg: null });
			}
		}
	}
	
	clearAlert = () => {
		// Clear errors
		this.props.clearErrors();
	}
	
	onChange = e => {
		this.setState({ [e.target.name]: e.target.value });
	};
	
	onSubmit = e => {
		e.preventDefault();
		
		const { username, email, password, repeatpassword, facebook_id, phone } = this.state;
		
		// Create user object
		const newUser = {
			username,
			email,
			password,
			repeatpassword,
			facebook_id,
			phone
		};
		// console.log(newUser);
		// Attempt to register
		this.props.register(newUser);
	};
	
	render() {
		let loading = this.props.auth.loading;
		var domainSite = window.location.hostname;
		return (
			<Router>
			<Fragment>
				<main id="main-container" style={{"height": "100%"}}>
					<div className="bg-image" style={{"height": "100%"}}>
						<div className="row no-gutters justify-content-center">
							<div className="col-sm-8 col-md-6 col-xl-4 align-items-center p-2 px-sm-0" style={{ "top": "100px" }}>
								<div className="block block-transparent block-rounded w-100 mb-0 overflow-hidden">
									<div className="justify-content-center row">
										<div className="col-lg-9-custom-login col-lg-9">
											<h2 style={{"textAlign":"center", "color": "#"+this.props.auth.config_website.title_color_dndk}}>{this.props.auth.config_website.title_dndk}</h2>
											<br/><br/>
											<div className={'card' + (this.props.auth.loading? ' block block-bordered shadow block-mode-loading' : '')}>
												<div className="card-header card-header-login pt-4 pb-4 text-center bg-primary bt-bg-navbar">
													<a className="link-fx text-white font-w700 font-size-h3" href="index.html" >
													  <span className="text-white text-uppercase">{domainSite.indexOf('tools1s.com') < 0 ? domainSite.slice(0, domainSite.lastIndexOf('.')) : domainSite.slice(0, domainSite.lastIndexOf('.')).replace('vip.', '') }</span>
												  	  <span className="text-white text-uppercase">{domainSite.indexOf('.') > -1 && domainSite.indexOf('tools1s.com') < 0 ? domainSite.slice(domainSite.lastIndexOf('.')) : ''}</span>
													</a>
												</div>
												<div className="position-relative card-body card-body-login bg-white" style={{ "padding": "36px" }}>
													<div className="text-center w-75 m-auto" style={{opacity: this.props.auth.loading? '.05':'1'}}>
														<h4 className="text-dark-50 text-center mt-0 font-weight-bold" style={{ "color": "#6c757d", "marginBottom": "10px", "fontSize": "1.125rem" }}>Đăng ký</h4>
														<p className="text-muted mb-4" style={{ "fontSize": "0.9rem" }}>Vui lòng điền đầy đủ thông tin để đăng ký</p>
													</div>
													{loading ? (
														<div className="preloader">
															<div className="spinner-grow spinner-grow-sm text-primary" role="status" style={{ "marginTop": "40%" }}>
															  <span className="sr-only"></span>
															</div>
															<div className="spinner-grow spinner-grow-sm text-danger" role="status" style={{ "marginTop": "40%" }}>
															  <span className="sr-only">.</span>
															</div>
															<div className="spinner-grow spinner-grow-sm text-warning" role="status" style={{ "marginTop": "40%" }}>
															  <span className="sr-only"></span>
															</div>
														</div>
													):('')}
													{this.state.msg ? (
														<div className="alert alert-warning d-flex align-items-center justify-content-between" role="alert" >
														  <div className="flex-fill mr-3">
															<p className="mb-0">{parse(this.state.msg)}</p>
														  </div>
														  <div className="flex-00-auto">
															<i className="fa fa-fw fa-exclamation-circle"></i>
														  </div>
														</div>
													  ) : null}
													<form className="js-validation-signup" onSubmit={this.onSubmit} method="POST" style={{opacity: this.props.auth.loading? '.05':'1'}}>
														<div className="form-group">
															<label htmlFor="username" className="">Tên tài khoản</label>
															<input name="username" placeholder="" required="" id="username" type="text" className="is-untouched is-pristine av-valid form-control" onChange={this.onChange} />
														</div>
														<div className="form-group">
															<label htmlFor="email" className="">Email</label>
															<input name="email" placeholder="" required="" id="email" type="email" className="is-untouched is-pristine av-valid form-control" onChange={this.onChange} />
														</div>
														{/* <div className="form-group">
															<label htmlFor="email" className="">Facebook ID</label>
															<input name="facebook_id" placeholder="" required="" id="facebook_id" type="text" className="is-untouched is-pristine av-valid form-control" onChange={this.onChange} />
														</div> */}
														<div className="form-group">
															<label htmlFor="phone" className="">Số điện thoại</label>
															<input name="phone" placeholder="" required="" id="phone" type="text" className="is-untouched is-pristine av-valid form-control" onChange={this.onChange} />
														</div>
														<div className="form-group">
															<label htmlFor="password" className="">Mật khẩu</label>
															{/*<a href="/account/forget-password" className="text-muted float-right"><small>Forgot your password?</small></a>*/}
															<input name="password" placeholder="" required="" id="password" type="password" className="is-untouched is-pristine av-valid form-control" onChange={this.onChange} />
														</div>
														<div className="form-group">
															<label htmlFor="password" className="">Nhập lại mật khẩu</label>
															{/*<a href="/account/forget-password" className="text-muted float-right"><small>Forgot your password?</small></a>*/}
															<input name="repeatpassword" placeholder="" required="" id="repeatpassword" type="password" className="is-untouched is-pristine av-valid form-control" onChange={this.onChange} />
														</div>
														<div className="form-group">
															<button type="submit" className="btn btn-success" style={{ "boxShadow": "0px 2px 6px 0px rgba(10, 207, 151, 0.5)", "color": "#fff", "backgroundColor": "#0acf97", "borderColor": "#0acf97" }}>
															  <span>
																Đăng ký
															  </span>
															</button>
															<Link to="/" onClick={this.props.action_login} className="btn btn-success float-right" style={{ "boxShadow": "0px 2px 6px 0px rgba(207, 10, 10, 0.5)", "color": "#fff", "backgroundColor": "#f00", "borderColor": "#f00" }}>
															  <span>
																Đăng nhập
															  </span>
															</Link>
														</div>
													</form>
												</div>
											</div>
										</div>
									</div>
									<div className="mt-4 row">
										<div className="col-12 text-center col">
											<p className="text-muted">
												Bạn đã có tài khoản?
												<Link to="/" className="text-danger ml-1 font-bold" onClick={this.props.action_login} style={{"fontSize": "20px"}}>Đăng nhập</Link>
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</main>
			</Fragment>
			</Router>
		);
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	error: state.error
})

export default connect(mapStateToProps, { register, clearErrors })(RegisterModal);