import axios from 'axios';
import {
	LOAD_FABVDX,
	ADD_FABVDX,
	UPDATE_FABVDX,
	UPDATE_FABVDX_TAB,
	LOAD_PRICE_FABVDX,
	LIMIT_PAGI_FABVDX,
	TYPE_FABVDX,
	LOADING_FABVDX,
	LOADING_FABVDX_2,
	LOAD_NHATKYHOATDONG_FABVDX
} from "../actions/types";
import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Swal from 'sweetalert2';
import { loadUser } from "./authActions";

export const loadFABVDX = (type = 0, page = 1, obj_search = '') => (dispatch, getState) => {
	dispatch({
		type: LOADING_FABVDX,
		payload: true
	});
	axios.get('/api/facebookadbreaks?type_api=facebookadbreaks_viewdexuat&type='+type+'&page='+page+'&obj_search='+obj_search, tokenConfig(getState))
		.then(res => {
			dispatch({
				type: LOADING_FABVDX,
				payload: false
			});
			if (res.data.status === 200) {
				dispatch({
					type: LOAD_FABVDX,
					payload: res.data.data
				});
			}
		});
};

export const addFABVDX = (body) => (dispatch, getState) => {
	dispatch({
		type: LOADING_FABVDX,
		payload: true
	});
	axios.post('/api/facebookadbreaks/create', body, tokenConfig(getState))
		.then(res => {
			dispatch({
				type: LOADING_FABVDX,
				payload: false
			});
			dispatch(loadUser());
			if (res.data.status === 200) {
				Swal.fire({
					icon: 'success',
					html: 'Tạo tiến trình thành công'
				});
			} else {
				var message = '';
				if (res.data.message) {
					message = res.data.message;
				} else {
					message = res.data.error;
				}
				Swal.fire({
					icon: 'error',
					title: 'Lỗi',
					text: message,
				});
			}
		});
};

export const deleteFABVDX = (id) => (dispatch, getState) => {
	let body = {
		id: id,
		'type_api': 'facebookadbreaks_viewdexuat'
	};
	dispatch({
		type: LOADING_FABVDX,
		payload: true
	});
	axios.post('/api/facebookadbreaks/delete', body, tokenConfig(getState))
		.then(res => {
			dispatch({
				type: LOADING_FABVDX,
				payload: false
			});
			dispatch(loadFABVDX());
			if (res.data.status === 200) {
				Swal.fire({
					icon: 'success',
					html: res.data.message
				});
			} else {
				var message = '';
				if (res.data.error) {
					if (res.data.error.object_id) {
						message = res.data.error.object_id[0];
					} else if (res.data.error.quantity) {
						message = res.data.error.quantity[0];
					} else {
						message = res.data.message;
					}
				} else {
					message = res.data.message;
				}
				Swal.fire({
					icon: 'error',
					title: 'Lỗi',
					text: message,
				});
			}
		});
};

export const UpdateEdit = (status, count_success, dataid, closeM) => (dispatch, getState) => {
	let body = {
		id: dataid,
		status: status,
		count_success: count_success,
		'type_api': 'facebookadbreaks_viewdexuat'
	};
	dispatch({
		type: LOADING_FABVDX,
		payload: true
	});
    axios.post('/api/facebookadbreaks/updateedit', body, tokenConfig(getState))
		.then(res => {
			dispatch({
				type: LOADING_FABVDX,
				payload: false
			});
			closeM();
			if (res.data.status === 200) {
				dispatch(loadFABVDX());
				Swal.fire({
					icon: 'success',
					html: 'Cập nhật thành công'
				});
			}
			if (res.data.status === 400) {
				var message = '';
				if (res.data.error) {
					if (res.data.error.object_id) {
						message = res.data.error.object_id[0];
					} else if (res.data.error.quantity) {
						message = res.data.error.quantity[0];
					}
				} else {
					message = res.data.message;
				}
				Swal.fire({
					icon: 'error',
					title: 'Lỗi',
					text: message,
				});
			}
		});
};

export const updateFABVDX = (body) => (dispatch) => {
	dispatch({
		type: UPDATE_FABVDX,
		payload: body
	})
};

export const updateLimitFABVDX = (body) => (dispatch) => {
	dispatch({
		type: LIMIT_PAGI_FABVDX,
		payload: body
	})
};

export const updateTypeFABVDX = (body) => (dispatch) => {
	dispatch({
		type: TYPE_FABVDX,
		payload: body
	})
};

export const changeTab = (body) => (dispatch, getState) => {
	dispatch({
		type: UPDATE_FABVDX_TAB,
		payload: body
	});
};

export const getConfigMoDong = (type = '') => (dispatch, getState) => {
	dispatch({
		type: LOADING_FABVDX,
		payload: true
	});

	axios.get('/api/facebookadbreaks/getconfig?type_api=facebookadbreaks_viewdexuat', tokenConfig(getState))
	.then(res => {
		dispatch({
			type: LOADING_FABVDX,
			payload: false
		});
		if (res.data.status === 200) {
			dispatch({
				type: 'CONFIG_MO_DONG_FABVDX',
				payload: res.data
			});
		}
	});
}

export const tokenConfig = getState => {
	// Get token from localhost
	const token = getState().auth.token;
	// Headers
	const config = {
		headers: {
			"Content-type": "application/json"
		}
	};
	
	// If token, add to headers
	if (token) {
		config.headers['ht-token'] = token;
	}
	
	return config;
};