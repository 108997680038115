import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import {
    Modal,
    ModalBody,
    ModalFooter
} from 'reactstrap';
import { GiaHanFb } from '../../../actions/FbvipActions';
import Swal from 'sweetalert2';

class Modal_edit extends Component {
    state = {
        giahanthem: 30,
    }

    changeValue = (e) => {
        this.setState({
            giahanthem: e.target.value
        });
    }
	
	onChangeGiahan = (giahanthem, dataid, gtmtt, datamax_like, datadays, datalsct, openModal) => {
		Swal.fire({
			title: 'Bạn có chắc?',
			html: "Bạn có chắc chắn muốn mua thêm "+giahanthem+" ngày với giá "+gtmtt+" Coin / 1 like/ 1 tháng?",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Đồng ý',
			cancelButtonText: 'Không'
		}).then((result) => {
			if (result.value) {
				var array_data = [];
				array_data['max_like'] = datamax_like;
				array_data['days'] = datadays;
				var lsct = 'viplike_group';
				// if (datalsct === 1) {
					// lsct = 'viplike_clone_ava';
				// }
				// if (datalsct === 2) {
					// lsct = 'viplike_clone_speed';
				// }
				// if (datalsct === 3) {
					// lsct = 'viplike_clone_via_ava';
				// }
				this.props.GiaHanFb(lsct, giahanthem, dataid, gtmtt, array_data, openModal);
			}
		})
	}
	
	option_tgsd = () => {
		let ar_option_tgsd = [];

		for (let i = 1; i <= 1; i++) {
			ar_option_tgsd.push(
				<option value={i} key={i}>
					{i} Tháng
				</option>
			)
		}
		return ar_option_tgsd;
	}
	
	onKeyPress = (e) => {
		if (!(e.charCode >= 48 && e.charCode <= 57)) {
			e.preventDefault();
		}
	}

    render() {
        const {isOpenModal, dataid, datalsct, datamax_like, datadays, dataquantity_baiviet} = this.props;
        const {giahanthem} = this.state;
		// const {gtmtt} = this.props.buffviplikegroup.taotientrinh;
		var gtmtt = 0;
		// if (datalsct === 0) {
			gtmtt = this.props.buffviplikegroup.datapricebuffsub.viplike_group['prices_web'];
		// } else if (datalsct === 1) {
			// gtmtt = this.props.buffviplikegroup.datapricebuffsub.viplike_clone_ava['prices_web'];
		// } else if (datalsct === 2) {
			// gtmtt = this.props.buffviplikegroup.datapricebuffsub.viplike_clone_speed['prices_web'];
		// } else {
			// gtmtt = this.props.buffviplikegroup.datapricebuffsub.viplike_clone_via_ava['prices_web'];
		// }
		const { loadingblock } = this.props.buffviplikegroup;
		var tile_slbv = dataquantity_baiviet*2/10;
		var price = Math.ceil((gtmtt/30)*datamax_like*30*tile_slbv);

        return (
            <Fragment>
                <Modal centered={true} size="lg" toggle={(e) => {this.props.openModal()}} isOpen={isOpenModal} className={(loadingblock? ' block block-mode-loading' : '')} style={{"backgroundColor": "inherit", "boxShadow": "none"}}>
                    <div className="modal-header">
                        <p className="mb-0 text-dark">Gia hạn</p>
                        <button onClick={(e) => {this.props.openModal()}} type="button" className="close">×</button>
                    </div>
                    <ModalBody style={{opacity: loadingblock? '.05':'1'}}>
						<div className="row">
                            <div className="col-md-12">
								<select className="form-control custom-select select-light" id="giahanthem" name="giahanthem" onChange={this.changeValue} value={this.state.giahanthem} >
									{/* this.option_tgsd() */}
									<option value="30">1 Tháng</option>
								</select>
							</div>
						</div>
						<div className="row mt-4">
							<div className="col-12 text-center bold">
								<div className="card card-info">
									<div className="card-body">
										<h5 className="font-bold">Tổng: <span className="bold green"><span>{price}</span> Coin</span></h5>
										<h6 className="mb-0 bold"><span>Gia hạn thêm <span className="green">{giahanthem} ngày</span> với giá <span className="green">{gtmtt}</span> Coin / 1 like/ 1 tháng</span></h6>
									</div>
								</div>
							</div>
						</div>
                    </ModalBody>
                    <ModalFooter style={{opacity: loadingblock? '.05':'1'}}>
                        <button onClick={() => {this.onChangeGiahan(giahanthem, dataid, gtmtt, datamax_like, datadays, datalsct, this.props.openModal)}} type="button" className="btn btn-primary">Lưu</button>
                        <button onClick={(e) => {this.props.openModal()}} type="button" className="btn btn-danger">Huỷ</button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    buffviplikegroup: state.buffviplikegroup
});

export default connect(mapStateToProps, {GiaHanFb}) (Modal_edit);