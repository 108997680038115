import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import {
    Modal,
    ModalBody,
    ModalFooter
} from 'reactstrap';
import { GiaHan } from '../../../actions/viplikeTiktok';
import Swal from 'sweetalert2';

class Modal_edit extends Component {
    state = {
        giahanthem: 30,
    }

    changeValue = (e) => {
        this.setState({
            giahanthem: e.target.value
        });
    }
	
	onChangeGiahan = (giahanthem, dataItemSelect, openModal) => {
		Swal.fire({
			title: 'Bạn có chắc?',
			html: "Bạn có chắc chắn muốn mua thêm "+giahanthem+" ngày với giá "+dataItemSelect.price_per+" Coin / ngày?",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Đồng ý',
			cancelButtonText: 'Không'
		}).then((result) => {
			if (result.value) {
				var array_data = [];
				this.props.GiaHan(giahanthem, dataItemSelect.id, openModal);
			}
		})
	}
	
	onKeyPress = (e) => {
		if (!(e.charCode >= 48 && e.charCode <= 57)) {
			e.preventDefault();
		}
	}

    render() {
        const {isOpenModal, dataItemSelect} = this.props;
        const {giahanthem} = this.state;
		const { loadingblock } = this.props.vipliketiktok;
		
        var price = Math.ceil(dataItemSelect.price_per * dataItemSelect.max_like * dataItemSelect.quantity * giahanthem);
		
        return (
            <Fragment>
                <Modal centered={true} size="lg" toggle={(e) => {this.props.openModal()}} isOpen={isOpenModal} className={(loadingblock? ' block block-mode-loading' : '')} style={{"backgroundColor": "inherit", "boxShadow": "none"}}>
                    <div className="modal-header">
                        <p className="mb-0 text-dark">Gia hạn</p>
                        <button onClick={(e) => {this.props.openModal()}} type="button" className="close">×</button>
                    </div>
                    <ModalBody style={{opacity: loadingblock? '.05':'1'}}>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group focused">
                                    <label className="control-label">Gia hạn</label>
                                    {/* <input type="text" className="form-control" placeholder="Nhập số ngày gia hạn thêm" value={giahanthem} onChange={(e)=>{this.changeValue(e)}} /> */}
									<select onChange={(e)=>{this.changeValue(e)}} value={giahanthem.toString()} name="giahanthem" className="form-control">
										<option value="7">7</option>
										<option value="15">15</option>
										<option value="30">30</option>
										<option value="60">60</option>
										<option value="90">90</option>
										<option value="120">120</option>
									</select>
                                </div>
                            </div>
                        </div>
						<div className="row mt-4">
							<div className="col-12 text-center bold">
								<div className="card card-info">
									<div className="card-body">
										<h5 className="font-bold">Tổng: <span className="bold green"><span>{price}</span> Coin</span></h5>
										<h6 className="mb-0 bold"><span>Gia hạn thêm <span className="green">{giahanthem} ngày</span> với giá <span className="green">{dataItemSelect.price_per}</span> Coin / ngày</span></h6>
									</div>
								</div>
							</div>
						</div>
                    </ModalBody>
                    <ModalFooter style={{opacity: loadingblock? '.05':'1'}}>
                        <button onClick={() => {this.onChangeGiahan(giahanthem, dataItemSelect, this.props.openModal)}} type="button" className="btn btn-primary">Lưu</button>
                        <button onClick={(e) => {this.props.openModal()}} type="button" className="btn btn-danger">Huỷ</button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    vipliketiktok: state.vipliketiktok
});

export default connect(mapStateToProps, {GiaHan}) (Modal_edit);
