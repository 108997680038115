import React from 'react';
import axios from 'axios';
import {
	LOAD_BVS,
	UPDATE_BVS_TAB,
	UPDATE_BVS,
	LIMIT_PAGI_BVS,
	TYPE_BVS,
	LOADING_BVS
} from "../actions/types";
import Swal from 'sweetalert2';
import { loadUser } from "./authActions";

export const loadBuffviewstory = (type = 0, page = 1, obj_search = '') => (dispatch, getState) => {
	dispatch({
		type: LOADING_BVS,
		payload: true
	});
	axios.get('/api/facebook_buff/list?type_api=buffviewstory&type='+type+'&page='+page+'&obj_search='+obj_search, tokenConfig(getState))
		.then(res => {
			dispatch({
				type: LOADING_BVS,
				payload: false
			});
			if (res.data.status === 200) {
				dispatch({
					type: LOAD_BVS,
					payload: res.data.data
				});
			}
		});
};

export const changeTab = (body) => (dispatch, getState) => {
	dispatch({
		type: UPDATE_BVS_TAB,
		payload: body
	});
};

export const updateBuffviewstory = (body) => (dispatch, getState) => {
	dispatch({
		type: UPDATE_BVS,
		payload: body
	});
};

export const updateLimitBuffviewstory = (body) => (dispatch) => {
	dispatch({
		type: LIMIT_PAGI_BVS,
		payload: body
	})
};

export const updateTypeBuffviewstory = (body) => (dispatch) => {
	dispatch({
		type: TYPE_BVS,
		payload: body
	})
};

export const addBuffviewstory = (body) => (dispatch, getState) => {
	dispatch({
		type: LOADING_BVS,
		payload: true
	});
	var lhi_tmp = body.lhi.split('_');
	body = {
		...body,
		"lhi": lhi_tmp[0]
	}
	axios.post('/api/facebook_buff/create', body, tokenConfig(getState))
		.then(res => {
			dispatch({
				type: LOADING_BVS,
				payload: false
			});
			dispatch(loadUser());
			if (res.data.status === 200) {
				Swal.fire({
					icon: 'success',
					html: res.data.message
				});
			} else {
				var message = '';
				if (res.data.error) {
					if (res.data.error.object_id) {
						message = res.data.error.object_id[0];
					} else if (res.data.error.quantity) {
						message = res.data.error.quantity[0];
					} else {
						message = res.data.message;
					}
				} else {
					message = res.data.message;
				}
				Swal.fire({
					icon: 'error',
					title: 'Lỗi',
					text: message,
				});
			}
		});
};

export const deleteBuffviewstory = (body) => (dispatch, getState) => {
	dispatch({
		type: LOADING_BVS,
		payload: true
	});
	var bodysend = {
		...body,
		"type_api": "buffviewstory"
	};
	axios.post('/api/buffgrouptele/delete', body, tokenConfig(getState))
		.then(res => {
			dispatch({
				type: LOADING_BVS,
				payload: false
			});
			dispatch(loadBuffviewstory());
			if (res.data.status === 200) {
				Swal.fire({
					icon: 'success',
					html: res.data.message
				});
			} else {
				var message = '';
				if (res.data.error) {
					if (res.data.error.object_id) {
						message = res.data.error.object_id[0];
					} else if (res.data.error.quantity) {
						message = res.data.error.quantity[0];
					} else {
						message = res.data.message;
					}
				} else {
					message = res.data.message;
				}
				Swal.fire({
					icon: 'error',
					title: 'Lỗi',
					text: message,
				});
			}
		});
};

export const openBtComfirmAlert = (message, type, title = '') => {
	Swal.fire({
		icon: type,
		title: title,
		text: message,
	});
	return false;
}

export const UpdateEdit = (status, count_success, dataid, closeM) => (dispatch, getState) => {
	let body = {
		id: dataid,
		count_success: count_success,
		status: status,
		type_api: 'buffviewstory'
	};
	dispatch({
		type: LOADING_BVS,
		payload: true
	});
    axios.post('/api/facebook_buff/admin_edit_order/', body, tokenConfig(getState))
		.then(res => {
			dispatch({
				type: LOADING_BVS,
				payload: false
			});
			closeM();
			if (res.data.status === 200) {
				dispatch(loadBuffviewstory());
				Swal.fire({
					icon: 'success',
					html: 'Cập nhật thành công'
				});
			}
			if (res.data.status === 400) {
				var message = '';
				if (res.data.error) {
					if (res.data.error.object_id) {
						message = res.data.error.object_id[0];
					} else if (res.data.error.quantity) {
						message = res.data.error.quantity[0];
					}
				} else {
					message = res.data.message;
				}
				Swal.fire({
					icon: 'error',
					title: 'Lỗi',
					text: message,
				});
			}
		});
};

export const getConfigMoDong = (type = '') => (dispatch, getState) => {
	dispatch({
		type: LOADING_BVS,
		payload: true
	});

	axios.get('/api/facebook_buff/getconfig?type_api=buffviewstory', tokenConfig(getState))
	.then(res => {
		dispatch({
			type: LOADING_BVS,
			payload: false
		});
		if (res.data.status === 200) {
			dispatch({
				type: 'CONFIG_MO_DONG_BVS',
				payload: res.data
			});
		}
	});
}

export const tokenConfig = getState => {
	// Get token from localhost
	const token = getState().auth.token;
	// Headers
	const config = {
		headers: {
			"Content-type": "application/json"
		}
	};
	
	// If token, add to headers
	if (token) {
		config.headers['ht-token'] = token;
	}
	
	return config;
};