import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Userinformation from "../Userinformation";

class sitecontent extends Component {
	render() {
		return (
			<Fragment>
				<Userinformation />
				<div className="card card-orange mt-3">
					<div className="card-body">
						<p className="font-bold">Chú ý:</p>
						<p className="font-bold text-danger">- Nghiêm cấm Buff các ID Seeding có nội dung vi phạm pháp luật, chính trị, đồ trụy... Nếu cố tình buff bạn sẽ bị trừ hết tiền và ban khỏi hệ thống vĩnh viễn, và phải chịu hoàn toàn trách nhiệm trước pháp luật.</p>
						<p>Khi Bật Bot CMT ảnh thì cmt sticker không hoạt động .</p>
						<p>Hãy Chú Ý cài nhiều nội dung hãy viết cách giữa các nội dung với dấu | VD: ND1 | ND2 .</p>
						<p>Ví dụ Nội Dung CMT là: này {"{"}tag{"}"} mình ngồi đếm nãy giờ thấy stt đc có {"{"}solike{"}"} Like.</p>
						<p>Thì khi CMT nội dung sẽ là : này (tag chủ stt) mình ngồi đếm nãy giờ thấy stt đc có 99 Like.</p>
						<p>Lọc Từ Khóa Là STT Nào Chứa Từ Khóa Đó Sẽ Không CMT Vào Bài Đó.</p>
						<p>Cám ơn đã sử dụng dịch vụ của chúng tôi.</p>
					</div>
				</div>
			</Fragment>
		)
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
});
const mapDispatchToProps = { }

export default connect(mapStateToProps, mapDispatchToProps)(sitecontent);