import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import {
	Modal,
	ModalBody,
	ModalFooter
} from 'reactstrap';

import { saveEditOrderAdmin } from '../../../actions/youtubeActions';

class ModalEditAdmin extends Component {
	state = {
		formdata: {
			E_status: this.props.dataItemSelect.status,
			E_count: this.props.dataItemSelect.count_success,
			E_notes: this.props.dataItemSelect.notes,
			id_edit: this.props.dataItemSelect.id,
			E_mail: this.props.dataItemSelect.mail,
		}
	}

	changeDataForm = (e, C_number = 0) => {
		let valueChange = e.target.value;
		if (C_number === 1) {
			valueChange = parseInt(valueChange);
		}

		this.setState({
			formdata: {
				...this.state.formdata,
				[e.target.name]: valueChange
			}
		});
	}

	render() {
		const {dataItemSelect,isOpenModal} = this.props;
		const {formdata} = this.state;
		var mail = 1;
		if (formdata.E_mail == "thuytien.thi@gmail.com") {
			mail = 1;
		} else if (formdata.E_mail == "bear.luxury1996@gmail.com") {
			mail = 2;
		} else if (formdata.E_mail == "thanhcansamson@gmail.com") {
			mail = 3;
		} else if (formdata.E_mail == "mr.dung1103@gmail.com") {
			mail = 4;
		} else if (formdata.E_mail == "miusun401@gmail.com") {
			mail = 5;
		} else if (formdata.E_mail == "vhviet90@gmail.com") {
			mail = 6;
		} else if (formdata.E_mail == "aromantic.mylove@gmail.com") {
			mail = 7;
		} else if (formdata.E_mail == "meongoz.tieuthu95@gmail.com") {
			mail = 8;
		} else if (formdata.E_mail == "thanhhuytoday@gmail.com") {
			mail = 9;
		} else if (formdata.E_mail == "hooanhnam@gmail.com") {
			mail = 10;
		}
		return (
			<Fragment>
				{dataItemSelect !== {} ?
					<Fragment>
						<Modal centered={true} size="lg" toggle={(e) => {this.props.openModal()}} isOpen={isOpenModal}>
		                    <div className="modal-header">
		                        <p className="mb-0 text-dark">Sửa</p>
		                        <button onClick={(e) => {this.props.openModal()}} type="button" className="close">×</button>
		                    </div>
		                    <ModalBody>
		                        <div className="row">
		                            <div className="col-md-12">
		                                <div className="form-group">
		                                    <label>Trạng thái</label>
		                                    <select name="E_status" value={formdata.E_status} onChange={(e) => {this.changeDataForm(e, 1)}} className="form-control">
		                                    	<option value="0">Hoàn thành</option>
		                                    	<option value="1">Đang chạy</option>
		                                    	<option value="2">Id lỗi</option>
		                                    	<option value="3">Hủy đơn</option>
												<option value="5">Hoàn tiền</option>
		                                    </select>
		                                </div>
										
		                                <div className="form-group">
		                                    <label>Mail người quản lý</label>
		                                    <select name="E_mail" value={mail} onChange={(e) => {this.changeDataForm(e, 1)}} className="form-control">
		                                    	<option value="1">thuytien.thi@gmail.com</option>
												<option value="2">bear.luxury1996@gmail.com</option>
												<option value="3">thanhcansamson@gmail.com</option>
												<option value="4">mr.dung1103@gmail.com</option>
												<option value="5">miusun401@gmail.com</option>
												<option value="6">vhviet90@gmail.com</option>
												<option value="7">aromantic.mylove@gmail.com</option>
												<option value="8">meongoz.tieuthu95@gmail.com</option>
												<option value="9">thanhhuytoday@gmail.com</option>
												<option value="10">hooanhnam@gmail.com</option>
		                                    </select>
		                                </div>
										
		                                <div className="form-group">
		                                    <label>Ghi chú</label>
		                                    <input type="text" name="E_notes" onChange={(e) => {this.changeDataForm(e)}} value={formdata.E_notes} className="form-control" />
		                                </div>
		                            </div>
		                        </div>
		                    </ModalBody>
		                    <ModalFooter>
		                        <button type="button" className="btn btn-primary" onClick={(e) => {this.props.saveEditOrderAdmin('youtubetichnghesi', formdata, this.props.openModal)}}>Lưu</button>
		                        <button type="button" className="btn btn-danger" onClick={(e) => {this.props.openModal()}}>Huỷ</button>
		                    </ModalFooter>
		                </Modal>
					</Fragment>
				: ''}
    		</Fragment>
		);
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	youtubetichnghesi: state.youtubetichnghesi,
});

export default connect(mapStateToProps, {saveEditOrderAdmin})(ModalEditAdmin);