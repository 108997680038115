import React, { Component, Fragment } from 'react';
import {
  BrowserRouter as Router,
  // Switch,
  // Route,
  Link
} from "react-router-dom";
import {
	// Button,
	// Modal,
	// ModalHeader,
	// ModalBody,
	// Form,
	// FormGroup,
	// Label,
	// Input,
	// NavLink,
	Alert
} from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { forgotpassword, resetpassword, checkTokenReset } from '../../actions/authActions';
import { clearErrors } from '../../actions/errorActions';
import bg_footer from '../../iconlanding/1/bg-footer.png';
import parse from 'html-react-parser';

class ForgotpasswordModal extends Component {
	state = {
		// modal: false,
		username: '',
		email: '',
		password: '',
		repeatpassword: '',
		msg: null,
		msg2: null,
		checktoken: 1,
	}
	
	static propTypes =  {
		auth: PropTypes.object.isRequired,
		error: PropTypes.object.isRequired,
		register: PropTypes.func.isRequired,
		clearErrors: PropTypes.func.isRequired
	}
	
	componentDidMount() {
		if (this.props.action_reset === '/resetpassword') {
			var search_param = new URLSearchParams(window.location.search);
			var token = search_param.get('token');
			if (token == null) {
				token = '';
			}
			var data = {
				token,
			};
			this.props.checkTokenReset(data);
		}
	}
	
	componentDidUpdate(prevProps) {
		const { error } = this.props;
		if (error !== prevProps.error) {
			// Check for register error
			if (error.id === 'FORGOTPASSWORD_SEND_FAIL' || error.id === 'RESETPASSWORD_SEND_FAIL') {
				this.setState({ msg: error.msg.msg, msg2: null });
			} else if (error.id === 'FORGOTPASSWORD_SEND_SUCCESS' || error.id === 'RESETPASSWORD_SEND_SUCCESS') {
				this.setState({ msg: null, msg2: error.msg.msg });
			} else if (error.id === 'TOKEN_RESET_SUCCESS') {
				this.setState({ checktoken: 1 });
			} else if (error.id === 'TOKEN_RESET_FAIL') {
				this.setState({ checktoken: 2 });
			} else {
				this.setState({ msg: null, msg2: null });
			}
		}
	}
	
	clearAlert = () => {
		// Clear errors
		this.props.clearErrors();
	}
	
	onChange = e => {
		this.setState({ [e.target.name]: e.target.value });
	};
	
	onSubmit = e => {
		e.preventDefault();
		
		const { username, email, password, repeatpassword } = this.state;
		var search_param = new URLSearchParams(window.location.search);
		var token = search_param.get('token');
		if (token == null) {
			token = '';
		}
		
		if (this.props.action_reset === '/resetpassword') {
			var newUser = {
				token,
				password,
				repeatpassword,
			};
			this.props.resetpassword(newUser);
		} else {
			var newUser = {
				username,
				email,
			};
			this.props.forgotpassword(newUser);
		}
	};
	
	render() {
		let loading = this.props.auth.loading;
		var domainSite = window.location.hostname;
		var config_website = {};
		if (localStorage.getItem("confignavbar") != null) {
			config_website = JSON.parse(localStorage.getItem("confignavbar"));
		} else {
			config_website = this.props.auth.config_website;
		}
		var search_param = new URLSearchParams(window.location.search);
		var token = search_param.get('token');
		if (token == null) {
			token = '';
		}
		var html_form = "";
		if ((this.props.action_reset === '/resetpassword' && token.trim() == '') || (this.props.action_reset === '/resetpassword' && this.state.checktoken == 2)) {
			html_form = (
				<Fragment>
					<div className="form-login" style={{"position": "relative"}}>
						<div className="alert alert-danger d-flex align-items-center justify-content-between" role="alert" >
							<div className="flex-fill mr-3">
								Link đặt lại mật khẩu đã hết hạn! #tk
							</div>
							<div className="flex-00-auto">
								<i className="fa fa-fw fa-exclamation-circle"></i>
							</div>
						</div>
					</div>
				</Fragment>
			)
		} else {
			html_form = (
				<Fragment>
					<div className="form-login" style={{"position": "relative"}}>
						{loading ? (
							<div className="preloader">
								<div className="spinner-grow spinner-grow-sm text-primary" role="status" style={{ "marginTop": "40%" }}>
								  <span className="sr-only"></span>
								</div>
								<div className="spinner-grow spinner-grow-sm text-danger" role="status" style={{ "marginTop": "40%" }}>
								  <span className="sr-only">.</span>
								</div>
								<div className="spinner-grow spinner-grow-sm text-warning" role="status" style={{ "marginTop": "40%" }}>
								  <span className="sr-only"></span>
								</div>
							</div>
						):('')}
						{this.state.msg ? (
							<div className="alert alert-warning d-flex align-items-center justify-content-between" role="alert" >
							  <div className="flex-fill mr-3">
								<p className="mb-0">{parse(this.state.msg)}</p>
							  </div>
							  <div className="flex-00-auto">
								<i className="fa fa-fw fa-exclamation-circle"></i>
							  </div>
							</div>
						  ) : null}
						{this.state.msg2 ? (
							<div className="alert d-flex align-items-center justify-content-between" role="alert" style={{"color": "#445e27", "backgroundColor": "#e6f0db", "borderColor": "#dceacd"}} >
								<div className="flex-fill mr-3">
									<p className="mb-0">{parse(this.state.msg2)}</p>
								</div>
							</div>
						) : null}
						<form className="js-validation-signup" onSubmit={this.onSubmit} method="POST" >
							{
								this.props.action_reset === '/resetpassword' ?
									<Fragment>
										<div className="form-group">
											<div className="input-icon mb-5">
												<span className="input-icon-addon">
													<i className="fa fa-key"></i>
												</span>
												<input name="password" placeholder="Nhập mật khẩu" required="" id="password" type="password" className="is-untouched is-pristine av-valid form-control" onChange={this.onChange} />
											</div>
											<div className="input-icon mb-5">
												<span className="input-icon-addon">
													<i className="fa fa-key"></i>
												</span>
												<input name="repeatpassword" placeholder="Nhập lại mật khẩu" required="" id="repeatpassword" type="password" className="is-untouched is-pristine av-valid form-control" onChange={this.onChange} />
											</div>
										</div>
										
										<div className="form-footer">
											<button type="submit" className="btn btn-pill btn-2 btn-block btn-submit btn-gradient">Đặt lại mật khẩu</button>
										</div>
									</Fragment>
								:
									<Fragment>
										<div className="form-group">
											<div className="input-icon mb-5">
												<span className="input-icon-addon">
													<i className="fa fa-user"></i>
												</span>
												<input name="username" placeholder="Nhập tài khoản" required="" id="username" type="text" className="is-untouched is-pristine av-valid form-control" onChange={this.onChange} />
											</div>
											<div className="input-icon mb-5">
												<span className="input-icon-addon">
													<i className="fa fa-envelope"></i>
												</span>
												<input name="email" placeholder="Nhập email" required="" id="email" type="email" className="is-untouched is-pristine av-valid form-control" onChange={this.onChange} />
											</div>
										</div>
										
										<div className="form-footer">
											<button type="submit" className="btn btn-pill btn-2 btn-block btn-submit btn-gradient">Gửi</button>
										</div>
									</Fragment>
							}
						</form>
					</div>
				</Fragment>
			)
		}
		return (
			<Router>
				<Fragment>
					<div className="landingcus1" style={{"zoom": "112%", "backgroundColor": "#ffffff"}}>
						<header className="header fixed-top" id="headerNav">
							<div className="container">
								<nav className="navbar navbar-expand-lg ">
									<a className="text-white font-w700 font-size-h3" href="/" >
									  <span className="text-white text-uppercase">{domainSite.indexOf('tools1s.com') < 0 ? domainSite.slice(0, domainSite.lastIndexOf('.')) : domainSite.slice(0, domainSite.lastIndexOf('.')).replace('vip.', '') }</span>
									  <span className="text-white text-uppercase">{domainSite.indexOf('.') > -1 && domainSite.indexOf('tools1s.com') < 0 ? domainSite.slice(domainSite.lastIndexOf('.')) : ''}</span>
									</a>
									<Link to="/" onClick={this.props.action_login} className="link btn-login mobile-dichvu-cus">
										Đăng nhập
									</Link>
									<div className="collapse navbar-collapse" id="navbarSupportedContent">
										<ul className="navbar-nav ml-auto">
										</ul> 
										<div className="nav-item d-md-flex btn-login-signup">
											<Link to="/services" onClick={this.props.action_service} className="link btn-login">
												Dịch vụ
											</Link>
											<Link to="/" onClick={this.props.action_login} className="link btn-login" style={{"paddingLeft": "0px"}}>
												Đăng nhập
											</Link>
											<Link to="/" onClick={this.props.action_register} className="btn btn-pill btn-outline-primary sign-up">
												Đăng ký
											</Link>
										</div>
									</div>
								</nav>
							</div>
						</header>
						<section className="section banner" id="home">
							<div className="container">
								<div className="row">
									<div className="col-lg-3 col-md-12 col-sm-12 col-xs-12">
									</div>
									<div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
										<div className="contents">
											<div className="auth-login-form-home">
												{html_form}
											</div>
										</div>
									</div>
									<div className="col-lg-3 col-md-12 col-sm-12 col-xs-12">
									</div>
								</div>
							</div>
						</section>
						<div className="footer footer_top dark" style={{"background": "#222d3a url("+bg_footer+")"}}>
							<div className="container m-t-60 m-b-50">
								<div className="row">
									<div className="col-lg-12">
										<div className="site-logo m-b-30">
											<a className="link-fx text-white font-w700 font-size-h3" href="/" >
											  <span className="text-white text-uppercase">{domainSite.slice(0, domainSite.lastIndexOf('.'))}</span>
											  <span className="text-white text-uppercase">{domainSite.indexOf('.') > -1 ? domainSite.slice(domainSite.lastIndexOf('.')) : ''}</span>
											</a>
										</div>
									</div>
									<div className="col-lg-8 m-t-30  mt-lg-0">
										<div className="row">
											<div className="col-6 col-md-3  mt-lg-0">
												<ul className="list-unstyled quick-link mb-0">
													<li><a href="/">Đăng nhập</a></li>
													<li>
														<Link to="#">
															Đăng ký
														</Link>
													</li>
												</ul>
											</div>
											<div className="col-6 col-md-3">
											</div>
										</div>
									</div>
									<div className="col-lg-4 m-t-30 mt-lg-0" style={{"textTransform": "uppercase"}}>
										<h4 className="title">Thông tin liên hệ</h4>
										<ul className="list-unstyled">
											<li>Fanpage hỗ trợ: <a href={"https://www.facebook.com/"+config_website.support_id_fanpage} target="_blank"> <i className="fab fa-facebook"></i></a></li>
											<li>Làm việc: 24/7</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
						<footer className="footer footer_bottom dark">
							<div className="container">
								<div className="row align-items-center flex-row-reverse">
									<div className="col-auto ml-lg-auto">
										<div className="row align-items-center">
											<div className="col-auto">
												<ul className="list-inline mb-0">
												</ul>
											</div>
										</div>
									</div>
									<div className="col-12 col-lg-auto mt-3 mt-lg-0 text-center">
										Copyright © 2020
									</div>
								</div>
							</div>
						</footer>
					</div>
				</Fragment>
			</Router>
		);
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	error: state.error
})

export default connect(mapStateToProps, { forgotpassword, resetpassword, checkTokenReset, clearErrors })(ForgotpasswordModal);