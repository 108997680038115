import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import { creatItemBuffSub, searchIdFb, getConfigMoDong } from '../../../actions/buffsub';
import axios from  'axios';
import store from '../../../store';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import parse from 'html-react-parser';
import ServicesDescription from "../ServicesDescription";
import { isMobile } from 'react-device-detect';

class Taotientrinh extends Component {
	componentDidMount(){
        this.props.getConfigMoDong();
	}
 
	state = {
		showDescription: isMobile? false : true
	}
	
 	handleChangeDateStart = date => {
	    let dataFormOld = this.props.buffsub.formData;
		let dataFormNew = {
			...dataFormOld,
			EndDatebh: this.addDays(date, 7),
			startDatebh: date,
		}

		store.dispatch({
			type: 'CHANGE_DATA_FORM_FB_BUFFSUB',
			payload: dataFormNew
		});
	}

	handleChangeDateEnd = date => {
	    let dataFormOld = this.props.buffsub.formData;
		let dataFormNew = {
			...dataFormOld,
			EndDatebh: date
		}

		store.dispatch({
			type: 'CHANGE_DATA_FORM_FB_BUFFSUB',
			payload: dataFormNew
		});
	}

	addDays = (date, days) => {
	  	var result = new Date(date);
	  	result.setDate(result.getDate() + days);
	  	return result;
	}

	onChangeForm = async (e) => {
		let valueadd = '';
		if (e.target.type === 'radio' || e.target.type === 'select-one' || e.target.type === 'number') {
			valueadd = Math.abs(parseInt(e.target.value));
		} else {
			valueadd = e.target.value;
		}

		let namvl = e.target.name;

		let dataFormOld = this.props.buffsub.formData;
		let dataFormNew = {
			...dataFormOld,
			[namvl]: valueadd
		};

		let {loaiseeding,baohanh} = this.props.buffsub.formData;

		if (namvl === 'baohanh') {
			let namePriceGet = 'buffsub';
			if (loaiseeding === 2) {
				namePriceGet = 'buffsub_sv2';
			}
			if (loaiseeding === 3) {
				namePriceGet = 'buffsub_sv3';
			}
			if (loaiseeding === 4) {
				namePriceGet = 'buffsub_sv4';
			}
			if (loaiseeding === 5) {
				namePriceGet = 'buffsub_sv5';
			}
			if (loaiseeding === 6) {
				namePriceGet = 'buffsub_sv6';
			}
			if (loaiseeding === 7) {
				namePriceGet = 'buffsub_sv7';
			}
			if (loaiseeding === 8) {
				namePriceGet = 'buffsub_sv8';
			}
			if (loaiseeding === 9) {
				namePriceGet = 'buffsub_sv9';
			}
			if (valueadd === 1) {
			 	dataFormNew.giatien = this.props.buffsub.datapricebuffsub[namePriceGet].min_bh_web;
			} else {
			 	dataFormNew.giatien = this.props.buffsub.datapricebuffsub[namePriceGet].prices_web;
			}
		}

		if (namvl === 'loaiseeding') {
			let namePriceGet = 'prices_web';
			if (baohanh === 1) {
				namePriceGet = 'min_bh_web';
			}
			if (valueadd === 2) {
				dataFormNew.locnangcao = 0;
				dataFormNew.giatien = this.props.buffsub.datapricebuffsub.buffsub_sv2[namePriceGet];
			} else if (valueadd === 3) {
				dataFormNew.locnangcao = 0;
				dataFormNew.giatien = this.props.buffsub.datapricebuffsub.buffsub_sv3[namePriceGet];
			} else if (valueadd === 4) {
				dataFormNew.locnangcao = 0;
				dataFormNew.giatien = this.props.buffsub.datapricebuffsub.buffsub_sv4[namePriceGet];
			} else if (valueadd === 5) {
				dataFormNew.locnangcao = 0;
				dataFormNew.giatien = this.props.buffsub.datapricebuffsub.buffsub_sv5[namePriceGet];
			} else if (valueadd === 6) {
				dataFormNew.locnangcao = 0;
				dataFormNew.giatien = this.props.buffsub.datapricebuffsub.buffsub_sv6[namePriceGet];
			} else if (valueadd === 7) {
				dataFormNew.locnangcao = 0;
				dataFormNew.giatien = this.props.buffsub.datapricebuffsub.buffsub_sv7[namePriceGet];
			} else if (valueadd === 8) {
				dataFormNew.locnangcao = 0;
				dataFormNew.giatien = this.props.buffsub.datapricebuffsub.buffsub_sv8[namePriceGet];
			} else if (valueadd === 9) {
				dataFormNew.locnangcao = 0;
				dataFormNew.giatien = this.props.buffsub.datapricebuffsub.buffsub_sv9[namePriceGet];
			} else {
				dataFormNew.giatien = this.props.buffsub.datapricebuffsub.buffsub[namePriceGet];
			}
		}

		store.dispatch({
			type: 'CHANGE_DATA_FORM_FB_BUFFSUB',
			payload: dataFormNew
		});
	}

	searchUidFacebook = (e) => {
		let dataFormOld = this.props.buffsub.formData;
		let valueS = e.target.value;
		let indexofLinkFb = valueS.lastIndexOf('facebook.com');
		if (indexofLinkFb > -1) {
			this.props.searchIdFb(valueS);
		} else {
			let dataFormNew = {
				...dataFormOld,
				[e.target.name]: valueS
			}
			store.dispatch({
				type: 'CHANGE_DATA_FORM_FB_BUFFSUB',
				payload: dataFormNew
			});
		}
	}

	calTotalPriceAll = () => {
		let { locnangcao,locnangcao_gt,locnangcao_dotuoi_start,locnangcao_banbe_start,sltang,giatien } = this.props.buffsub.formData;
		let price = sltang * giatien;
		let priceA = price;
		if (locnangcao === 1) {
			if (locnangcao_dotuoi_start !== 0) {
				price = price + priceA;
			}

			if (locnangcao_banbe_start !== 0) {
				price = price + priceA;
			}

			if (locnangcao_gt !== 0) {
				price = price + priceA;
			}
		}
		return Math.round((price + Number.EPSILON) * 100) / 100;
	}

	createElementsSelect(start, end, prefix = ''){
	    var elements = [];
	    for(let i = start; i < end; i++){
	        elements.push(<option key={i} value={i+prefix}>{i}{prefix}</option>);
	    }
	    return elements;
	}

	onClickShowDescription = (data) => {
		this.setState({
			showDescription: data
		});
	}
	
 	render() {
 		const { locnangcao,locnangcao_gt,locnangcao_dotuoi_start,locnangcao_dotuoi_end,locnangcao_banbe_start,locnangcao_banbe_end,profile_user,loaiseeding,baohanh,sltang,giatien,ghichu,startDatebh,EndDatebh } = this.props.buffsub.formData;
		const { facebookbuffsub_sv1_mo_dong, facebookbuffsub_sv2_mo_dong, facebookbuffsub_sv3_mo_dong, facebookbuffsub_sv4_mo_dong, facebookbuffsub_sv5_mo_dong, facebookbuffsub_sv6_mo_dong, facebookbuffsub_sv7_mo_dong, facebookbuffsub_sv8_mo_dong, facebookbuffsub_sv9_mo_dong } = this.props.buffsub;
		
		var listDataServicesInfo = {};
		try {
			if (localStorage.getItem("listDataServicesInfo") != null) {
				listDataServicesInfo = JSON.parse(localStorage.getItem("listDataServicesInfo"));
			} else {
				listDataServicesInfo = this.props.adminpage.listDataServicesInfo;
			}
		} catch(e) {
			listDataServicesInfo = {};
		}
		var html_haydoctranhmattien = '';
		var html_haydoctranhmattien_chinh = '';
		var html_haydoctranhmattien_sv = '';
		var html_subspeed_sv1 = '';
		var html_subspeed_sv2 = '';
		var html_subspeed_sv3 = '';
		var html_subspeed_sv4 = '';
		var html_subspeed_sv5 = '';
		var html_subspeed_sv6 = '';
		var html_subspeed_sv7 = '';
		var html_subspeed_sv8 = '';
		var html_subspeed_sv9 = '';
		if (typeof(listDataServicesInfo.facebook_buff_subspeed_tab) !== 'undefined') {
			html_haydoctranhmattien = parse(listDataServicesInfo.facebook_buff_subspeed_tab.ghichu);
			html_haydoctranhmattien_chinh = parse(listDataServicesInfo.facebook_buff_subspeed_tab.ghichu_chinh);
		}
		if (typeof(listDataServicesInfo.facebook_buff_subspeed_sv1) !== 'undefined') {
			html_subspeed_sv1 = parse(listDataServicesInfo.facebook_buff_subspeed_sv1.ghichu_sv);
			if (loaiseeding.toString() === "1") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.facebook_buff_subspeed_sv1.ghichu);
			}
		}
		if (typeof(listDataServicesInfo.facebook_buff_subspeed_sv2) !== 'undefined') {
			html_subspeed_sv2 = parse(listDataServicesInfo.facebook_buff_subspeed_sv2.ghichu_sv);
			if (loaiseeding.toString() === "2") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.facebook_buff_subspeed_sv2.ghichu);
			}
		}
		if (typeof(listDataServicesInfo.facebook_buff_subspeed_sv3) !== 'undefined') {
			html_subspeed_sv3 = parse(listDataServicesInfo.facebook_buff_subspeed_sv3.ghichu_sv);
			if (loaiseeding.toString() === "3") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.facebook_buff_subspeed_sv3.ghichu);
			}
		}
		if (typeof(listDataServicesInfo.facebook_buff_subspeed_sv4) !== 'undefined') {
			html_subspeed_sv4 = parse(listDataServicesInfo.facebook_buff_subspeed_sv4.ghichu_sv);
			if (loaiseeding.toString() === "4") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.facebook_buff_subspeed_sv4.ghichu);
			}
		}
		if (typeof(listDataServicesInfo.facebook_buff_subspeed_sv5) !== 'undefined') {
			html_subspeed_sv5 = parse(listDataServicesInfo.facebook_buff_subspeed_sv5.ghichu_sv);
			if (loaiseeding.toString() === "5") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.facebook_buff_subspeed_sv5.ghichu);
			}
		}
		if (typeof(listDataServicesInfo.facebook_buff_subspeed_sv6) !== 'undefined') {
			html_subspeed_sv6 = parse(listDataServicesInfo.facebook_buff_subspeed_sv6.ghichu_sv);
			if (loaiseeding.toString() === "6") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.facebook_buff_subspeed_sv6.ghichu);
			}
		}
		if (typeof(listDataServicesInfo.facebook_buff_subspeed_sv7) !== 'undefined') {
			html_subspeed_sv7 = parse(listDataServicesInfo.facebook_buff_subspeed_sv7.ghichu_sv);
			if (loaiseeding.toString() === "7") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.facebook_buff_subspeed_sv7.ghichu);
			}
		}
		if (typeof(listDataServicesInfo.facebook_buff_subspeed_sv8) !== 'undefined') {
			html_subspeed_sv8 = parse(listDataServicesInfo.facebook_buff_subspeed_sv8.ghichu_sv);
			if (loaiseeding.toString() === "8") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.facebook_buff_subspeed_sv8.ghichu);
			}
		}
		if (typeof(listDataServicesInfo.facebook_buff_subspeed_sv9) !== 'undefined') {
			html_subspeed_sv9 = parse(listDataServicesInfo.facebook_buff_subspeed_sv9.ghichu_sv);
			if (loaiseeding.toString() === "9") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.facebook_buff_subspeed_sv9.ghichu);
			}
		}
		
    	return (
    		<Fragment>
    			<style>
					{"\
			        	.react-datepicker-wrapper{\
			          		width: 100%;\
			        	}\
			      	"}
		      	</style>
				<div className="row">
					<div className="col-md-4">
						<label className="mt-2">Link hoặc ID trang cá nhân:</label>
					</div>
					<div className="col-md-8">
						<div className="form-group">
                            <input onChange={(e) => {this.searchUidFacebook(e)}} value={profile_user} name="profile_user" type="text" className="form-control"/>
                        </div>
					</div>
				</div>
				<div className="row mt-2">
					<div className="col-md-4">
						<label className="mt-2">Loại Seeding cần tăng:</label>
					</div>
					{/* <div className="col-md-8">
						<div className="form-group">
							<select onChange={(e) => {this.onChangeForm(e)}} value={loaiseeding.toString()} name="loaiseeding" className="form-control">
								<option value="1">Tăng Sub - Theo dõi trang cá nhân Facebook</option>
							</select>
						</div>
					</div> */}
					<div className="col-sm-8">
						<div className="custom-control custom-radio" style={{opacity: this.props.buffsub.datapricebuffsub['buffsub'].prices_web == 0 ? '0.3' : '1' }}>
							<input onChange={(e) => {this.onChangeForm(e)}} value="1" type="radio" className="custom-control-input" id="loaiseeding_1" name="loaiseeding" checked={loaiseeding.toString() === "1"} disabled={this.props.buffsub.datapricebuffsub['buffsub'].prices_web == 0 ? 'disabled' : ''} />
							<label className="custom-control-label" htmlFor="loaiseeding_1" > Tăng <span style={{"color": "#3ecf8e"}}>Sub</span> - SV1 {html_subspeed_sv1} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.buffsub.datapricebuffsub['buffsub'].prices_web} coin</span> {facebookbuffsub_sv1_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
						</div>
						<div className="custom-control custom-radio" style={{opacity: this.props.buffsub.datapricebuffsub['buffsub_sv2'].prices_web == 0 ? '0.3' : '1' }}>
							<input onChange={(e) => {this.onChangeForm(e)}} value="2" type="radio" className="custom-control-input" id="loaiseeding_2" name="loaiseeding" checked={loaiseeding.toString() === "2"} disabled={this.props.buffsub.datapricebuffsub['buffsub_sv2'].prices_web == 0 ? 'disabled' : ''} />
							<label className="custom-control-label" htmlFor="loaiseeding_2" > Tăng <span style={{"color": "#3ecf8e"}}>Sub</span> - SV2 {html_subspeed_sv2} <span className="text-danger">tốt nhất nên dùng</span> <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.buffsub.datapricebuffsub['buffsub_sv2'].prices_web} coin</span> {facebookbuffsub_sv2_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
						</div>
						<div className="custom-control custom-radio" style={{opacity: this.props.buffsub.datapricebuffsub['buffsub_sv3'].prices_web == 0 ? '0.3' : '1' }}>
							<input onChange={(e) => {this.onChangeForm(e)}} value="3" type="radio" className="custom-control-input" id="loaiseeding_3" name="loaiseeding" checked={loaiseeding.toString() === "3"} disabled={this.props.buffsub.datapricebuffsub['buffsub_sv3'].prices_web == 0 ? 'disabled' : ''} />
							<label className="custom-control-label" htmlFor="loaiseeding_3" > Tăng <span style={{"color": "#3ecf8e"}}>Sub</span> - SV3 {html_subspeed_sv3} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.buffsub.datapricebuffsub['buffsub_sv3'].prices_web} coin</span> {facebookbuffsub_sv3_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
						</div>
						<div className="custom-control custom-radio" style={{opacity: this.props.buffsub.datapricebuffsub['buffsub_sv3'].prices_web == 0 ? '0.3' : '1' }}>
							<input onChange={(e) => {this.onChangeForm(e)}} value="4" type="radio" className="custom-control-input" id="loaiseeding_4" name="loaiseeding" checked={loaiseeding.toString() === "4"} disabled={this.props.buffsub.datapricebuffsub['buffsub_sv4'].prices_web == 0 ? 'disabled' : ''} />
							<label className="custom-control-label" htmlFor="loaiseeding_4" > Tăng <span style={{"color": "#3ecf8e"}}>Sub</span> - SV4 {html_subspeed_sv4} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.buffsub.datapricebuffsub['buffsub_sv4'].prices_web} coin</span> {facebookbuffsub_sv4_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
						</div>
						<div className="custom-control custom-radio" style={{opacity: this.props.buffsub.datapricebuffsub['buffsub_sv5'].prices_web == 0 ? '0.3' : '1' }}>
							<input onChange={(e) => {this.onChangeForm(e)}} value="5" type="radio" className="custom-control-input" id="loaiseeding_5" name="loaiseeding" checked={loaiseeding.toString() === "5"} disabled={this.props.buffsub.datapricebuffsub['buffsub_sv5'].prices_web == 0 ? 'disabled' : ''} />
							<label className="custom-control-label" htmlFor="loaiseeding_5" > Tăng <span style={{"color": "#3ecf8e"}}>Sub</span> - SV5 {html_subspeed_sv5} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.buffsub.datapricebuffsub['buffsub_sv5'].prices_web} coin</span> {facebookbuffsub_sv5_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
						</div>
						<div className="custom-control custom-radio" style={{opacity: this.props.buffsub.datapricebuffsub['buffsub_sv6'].prices_web == 0 ? '0.3' : '1' }}>
							<input onChange={(e) => {this.onChangeForm(e)}} value="6" type="radio" className="custom-control-input" id="loaiseeding_6" name="loaiseeding" checked={loaiseeding.toString() === "6"} disabled={this.props.buffsub.datapricebuffsub['buffsub_sv6'].prices_web == 0 ? 'disabled' : ''} />
							<label className="custom-control-label" htmlFor="loaiseeding_6" > Tăng <span style={{"color": "#3ecf8e"}}>Sub</span> - SV6 {html_subspeed_sv6} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.buffsub.datapricebuffsub['buffsub_sv6'].prices_web} coin</span> {facebookbuffsub_sv6_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
						</div>
						<div className="custom-control custom-radio" style={{opacity: this.props.buffsub.datapricebuffsub['buffsub_sv7'].prices_web == 0 ? '0.3' : '1' }}>
							<input onChange={(e) => {this.onChangeForm(e)}} value="7" type="radio" className="custom-control-input" id="loaiseeding_7" name="loaiseeding" checked={loaiseeding.toString() === "7"} disabled={this.props.buffsub.datapricebuffsub['buffsub_sv7'].prices_web == 0 ? 'disabled' : ''} />
							<label className="custom-control-label" htmlFor="loaiseeding_7" > Tăng <span style={{"color": "#3ecf8e"}}>Sub</span> - SV7 {html_subspeed_sv7} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.buffsub.datapricebuffsub['buffsub_sv7'].prices_web} coin</span> {facebookbuffsub_sv7_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
						</div>
						<div className="custom-control custom-radio" style={{opacity: this.props.buffsub.datapricebuffsub['buffsub_sv8'].prices_web == 0 ? '0.3' : '1' }}>
							<input onChange={(e) => {this.onChangeForm(e)}} value="8" type="radio" className="custom-control-input" id="loaiseeding_8" name="loaiseeding" checked={loaiseeding.toString() === "8"} disabled={this.props.buffsub.datapricebuffsub['buffsub_sv8'].prices_web == 0 ? 'disabled' : ''} />
							<label className="custom-control-label" htmlFor="loaiseeding_8" > Tăng <span style={{"color": "#3ecf8e"}}>Sub</span> - SV8 {html_subspeed_sv8} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.buffsub.datapricebuffsub['buffsub_sv8'].prices_web} coin</span> {facebookbuffsub_sv8_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
						</div>
						<div className="custom-control custom-radio" style={{opacity: this.props.buffsub.datapricebuffsub['buffsub_sv9'].prices_web == 0 ? '0.3' : '1' }}>
							<input onChange={(e) => {this.onChangeForm(e)}} value="9" type="radio" className="custom-control-input" id="loaiseeding_9" name="loaiseeding" checked={loaiseeding.toString() === "9"} disabled={this.props.buffsub.datapricebuffsub['buffsub_sv9'].prices_web == 0 ? 'disabled' : ''} />
							<label className="custom-control-label" htmlFor="loaiseeding_9" > Tăng <span style={{"color": "#3ecf8e"}}>Sub</span> - SV9 {html_subspeed_sv9} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.buffsub.datapricebuffsub['buffsub_sv9'].prices_web} coin</span> {facebookbuffsub_sv9_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
						</div>
					</div>
				</div>
				
				<div className="row mt-2">
					<div className="col-md-4">
						<label className="mt-2">Chế độ bảo hành:</label>
					</div>
					<div className="col-md-8">
						<div className="form-group">
							<select onChange={(e) => {this.onChangeForm(e)}} value={baohanh.toString()} name="baohanh" className="form-control">
								<option value="0">Không bảo hành</option>
								<option value="1">Bảo hành (Bảo hành nếu tụt quá 10%)</option>
							</select>
						</div>

						{ baohanh === 1 ?
							<div className="form-group">
								<div className="row">
									<div className="col-md-6">
										<p className="mb-0">Chọn ngày bắt đầu</p>
										<DatePicker className="form-control w-100"
										showTimeSelect minDate={new Date()}
										selected={startDatebh}
										onChange={this.handleChangeDateStart} />
									</div>
									<div className="col-md-6">
										<p className="mb-0">Chọn ngày kết thúc</p>
										<DatePicker className="form-control"
										showTimeSelect minDate={startDatebh}
										maxDate={this.addDays(startDatebh, 7)}
										selected={EndDatebh}
										onChange={this.handleChangeDateEnd} />
									</div>
								</div>
							</div>
							: ''
						}
					</div>
				</div>
				<div className="row mt-2" style={{display: loaiseeding.toString() === "1" ? "none" : "none"}}>
					<div className="col-md-4">
						<label className="mt-2">Lọc nâng cao:</label>
					</div>
					<div className="col-md-8">
						<div className="form-group">
						    <div className="custom-control custom-radio custom-control-inline">
						        <input onChange={(e) => {this.onChangeForm(e)}} value="1" type="radio" className="custom-control-input" id="locnangcao_1" name="locnangcao" checked={locnangcao === 1} />
						        <label className="custom-control-label" htmlFor="locnangcao_1" >Bật</label>
						    </div>
						    <div className="custom-control custom-radio custom-control-inline">
						        <input onChange={(e) => {this.onChangeForm(e)}} value="0" type="radio" className="custom-control-input" id="locnangcao_0" name="locnangcao" checked={locnangcao === 0}/>
						        <label className="custom-control-label" htmlFor="locnangcao_0" >Tắt</label>
						    </div>
						</div>
						{
							locnangcao === 1 ?
								<div className="form-group">
									<p className="font-15 mt-2">
										Hệ thống sẽ <span style={{color:'#1FAB89'}} className="font-weight-bold">thu phí thêm 100%</span> cho <span style={{color:'#1FAB89'}} className="font-weight-bold">1 lựa chọn nâng cao</span>
									</p>
									<div className="block block-bordered rounded">
										<div className="block-content">
											<div className="row">
												<div className="col-md-2">
													<label>Giới tính:</label>
												</div>
												<div className="col-md-8">
													<div className="form-group">
													    <div className="custom-control custom-radio custom-control-inline">
													        <input onChange={(e) => {this.onChangeForm(e)}} value="2" type="radio" className="custom-control-input" id="locnangcao_gt_2" name="locnangcao_gt" checked={locnangcao_gt === 2}/>
													        <label className="custom-control-label" htmlFor="locnangcao_gt_2" >Nam</label>
													    </div>
														<div className="custom-control custom-radio custom-control-inline">
													        <input onChange={(e) => {this.onChangeForm(e)}} value="1" type="radio" className="custom-control-input" id="locnangcao_gt_1" name="locnangcao_gt" checked={locnangcao_gt === 1}/>
													        <label className="custom-control-label" htmlFor="locnangcao_gt_1" >Nữ</label>
													    </div>
													    <div className="custom-control custom-radio custom-control-inline">
													        <input onChange={(e) => {this.onChangeForm(e)}} value="0" type="radio" className="custom-control-input" id="locnangcao_gt_0" name="locnangcao_gt" checked={locnangcao_gt === 0}/>
													        <label className="custom-control-label" htmlFor="locnangcao_gt_0" >Tất cả</label>
													    </div>
													</div>
												</div>
											</div>

											<div className="row">
												<div className="col-md-2">
													<label className="mt-2">Độ tuổi:</label>
												</div>
												<div className="col-md-4">
													<div className="form-group">
														<select onChange={(e) => {this.onChangeForm(e)}} name="locnangcao_dotuoi_start" value={locnangcao_dotuoi_start} className="form-control">
															<option value="0">không lọc</option>
															{this.createElementsSelect(13,66)}
														</select>
													</div>
												</div>
												<div className="col-md-2 text-center">
													<label className="mt-1">Đến</label>
												</div>
												<div className="col-md-4">
													<div className="form-group">
														<select disabled={locnangcao_dotuoi_start===0} onChange={(e) => {this.onChangeForm(e)}} name="locnangcao_dotuoi_end" value={locnangcao_dotuoi_end} className="form-control">
															{this.createElementsSelect(13,66)}
														</select>
													</div>
												</div>
											</div>

											<div className="row">
												<div className="col-md-2">
													<label className="mt-2">Số bạn bè:</label>
												</div>
												<div className="col-md-4">
													<div className="form-group">
														<select onChange={(e) => {this.onChangeForm(e)}} name="locnangcao_banbe_start" value={locnangcao_banbe_start.toString()} className="form-control">
															<option value="0">không lọc</option>
															{this.createElementsSelect(1,51,'00')}
														</select>
													</div>
												</div>
												<div className="col-md-2 text-center">
													<label className="mt-1">Đến</label>
												</div>
												<div className="col-md-4">
													<div className="form-group">
														<select disabled={locnangcao_banbe_start===0} onChange={(e) => {this.onChangeForm(e)}} name="locnangcao_banbe_end" value={locnangcao_banbe_end.toString()} className="form-control">
															{this.createElementsSelect(1,51,'00')}
														</select>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							: ''
						}
					</div>
				</div>
				<div className="row mt-2">
					<div className="col-md-4">
						<label className="mt-2">Số lượng cần tăng:</label>
					</div>
					<div className="col-md-8">
						<div className="form-group">
                            <input type="number" className="form-control" onChange={(e) => {this.onChangeForm(e)}} name="sltang" value={sltang}/>
                        </div>
					</div>
				</div>
				<div className="row mt-2" style={{display: loaiseeding.toString() === "1" ? "none" : "none"}}>
					<div className="col-md-8 offset-md-4">
						<div className="card card-orange">
							<div className="form-group">
								<div className="card-body py-2">
									<h6 className="font-14 mb-0">
										<label className="mb-0 text-danger font-bold mr-1">
											Giá thấp nhất: {this.props.buffsub.datapricebuffsub.prices_web} Coin
										</label>

										<label className="mb-0 ml-1 green font-bold mr-1">
											- Giá trung bình: {this.props.auth.priceMedium.buff_sub.price_medium} Coin
									 	</label>

										<label className="ml-1 text-primary font-bold mr-1"> - Giá bảo hành: {this.props.buffsub.datapricebuffsub.min_bh_web} Coin</label>
									</h6>
									<h6 className="font-14 mb-0 text-danger font-bold">Lưu ý: Nên buff dư thêm 20 - 30% trên tổng số lượng để tránh tụt.</h6>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="row mt-2">
					<div className="col-md-4">
						<label className="mt-2">Giá tiền mỗi tương tác:</label>
					</div>
					<div className="col-md-8">
						<div className="form-group">
							<input type="number" className="form-control" onChange={(e) => {this.onChangeForm(e)}} name="giatien" value={giatien}/>
						</div>
					</div>
				</div>
				<div className="row mt-2">
					<div className="col-md-4">
						<label className="mt-2">Ghi chú:</label>
					</div>
					<div className="col-md-8">
						<div className="form-group">
                            <textarea onChange={(e) => {this.onChangeForm(e)}} name="ghichu" value={ghichu} className="form-control" rows="4" placeholder="Nhập nội dung ghi chú về tiến trình của bạn"></textarea>
                        </div>
					</div>
				</div>

				<button type="button" className="btn badge-primary-cus btn-rounded btn-show-description-cus" onClick={(e) => {this.onClickShowDescription(!this.state.showDescription)}}>
					{
						this.state.showDescription?
							<i className="fa fa-angle-up" style={{"marginRight": "5px"}} aria-hidden="true"></i>
						:
							<i className="fa fa-angle-down" style={{"marginRight": "5px"}} aria-hidden="true"></i>
					}
					Thông tin dịch vụ
				</button>
				
				<ServicesDescription allservicesinfo={listDataServicesInfo} server={loaiseeding.toString()} tab="facebook_buff_subspeed" giatien={giatien} baohanh={baohanh} showDescription={this.state.showDescription} />

				<div className="row mt-2" style={{"display": this.state.showDescription? "flex" : "none"}}>
				    <div className="col-md-12 bold" style={{"marginBottom": "10px"}}>
						<div className="card card-select-cus">
							<div className="card-body card-body-select-cus">
								<p className="mb-0 mt-3 text-wrap">
									<ul>
										<li>1 ID KHÔNG CÀI 2 ĐƠN CÙNG LÚC TRONG HỆ THỐNG ! ĐƠN CŨ XONG MỚI CÀI ĐƠN MỚI ! CỐ TÌNH CÀI BỊ HỤT SỐ LƯỢNG SẼ KHÔNG XỬ LÝ !</li>
										<li>Nếu ID đang chạy trên hệ thống mà bạn vẫn mua id đó cá hệ thống bên khác, nếu có tình trạng hụt số lượng giữa 2 bên thì sẽ không được xử lí!</li>
										{html_haydoctranhmattien}
										{html_haydoctranhmattien_chinh}
										{html_haydoctranhmattien_sv}
										<li>Nếu gặp lỗi hãy nhắn tin hỗ trợ phía bên phải góc màn hình hoặc vào mục liên hệ hỗ trợ để được hỗ trợ tốt nhất!</li>
									</ul>
								</p>
								<span className="badge badge-cus position-absolute-cus badge-top-left-cus badge-primary-cus badge-pill-cus"> HÃY ĐỌC ĐỂ TRÁNH MẤT TIỀN KHI SỬ DỤNG! </span>
							</div>
						</div>
				    </div>
				</div>
				<div className="row mt-4">
				    <div className="col-12 text-center bold">
				        <div className="card card-info">
				            <div className="card-body">
				                <h5 className="font-bold">Tổng: <span className="bold green"><span>{this.calTotalPriceAll()}</span> Coin</span></h5>
				                <h6 className="mb-0">Bạn sẽ buff <span className="bold green">{sltang} sub</span> với giá <span className="green bold">{this.calTotalPriceAll()}</span> Coin</h6>
				            </div>
				        </div>
				    </div>
				</div>

				<div className="row mt-4">
					<div className="col-md-12">
						<div className='form-group float-right w-100'>
							<button onClick={(e) => {this.props.creatItemBuffSub(this.props.buffsub.formData)}} type="button" className="btn btn-hero-primary btn-rounded btn-block">
	                            Tạo tiến trình
	                        </button>
                        </div>
					</div>
				</div>
			</Fragment>
		);
  	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	buffsub: state.buffsub,
});

export default connect(mapStateToProps, {creatItemBuffSub, searchIdFb, getConfigMoDong})(Taotientrinh);
