const initialState = {
	data_list: [],
	loadding: false,
	taotientrinh: {}
};


export default function(state = initialState, action) {
  switch (action.type) {
    case 'LOADING_DATA_HISTORY_ORDER':
    	return {
			...state,
			data_list: action.payload
		};
	case 'LOADING_BLOCK_HISTORY_ORDER':
    	return {
			...state,
			loadding: action.payload
		};
	case 'UPDATE_PRICE_REFUND':
    	return {
			...state,
			data_list: action.payload
		};
    default:
      return state;
  }
}