import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Swal from 'sweetalert2';
import copy from 'copy-text-to-clipboard';

class Locchu extends Component {
	state = {
		loaibotrunglap: true,
		noidung: "sb=Bdi-ue_fQZ; datr=BgVm_eecFso; c_user=100003266100440; xs=32%3AiwE4eOw%3A2%3A183%3A8676%3A81; pl=n; m_pixel_ratio=1;",
		text_placeholder: "sb=Bdi-ue_fQZ; datr=BgVm_eecFso; c_user=100003266100440; xs=32%3AiwE4eOw%3A2%3A183%3A8676%3A81; pl=n; m_pixel_ratio=1;",
		tutu: "c_user",
		dentu: ";",
		doicookietu: 1,
		ketqua: "",
	}
	onChangeTrungLap = (e) =>{
		this.setState({
			[e.target.name]: !this.state.loaibotrunglap
		})
	}
	onClick = (e) =>{
		this.setState({
			[e.target.name]: e.target.value
		})
	}
	onChange = (e) =>{
		this.setState({
			[e.target.name]: e.target.value
		})
	}
	onTrigger = (e) => {
		var { loaibotrunglap, noidung, tutu, dentu, doicookietu } = this.state;
		noidung = noidung.trim();
		noidung = noidung
			.split("\n")
			.filter(item => item)
			.join("\n");
		if (loaibotrunglap) {
			noidung = noidung
				.split("\n")
				.filter((item, i, allItems) => {
					return i === allItems.indexOf(item);
				})
				.join("\n");
		}
		
		var final_noidung_tmp = noidung.split("\n");
		var final_noidung = [];
		final_noidung_tmp.forEach(function(v, i) {
			var v_tmp = v;
			var index_from = v.indexOf(tutu);
			if (index_from > -1) {
				v = v.substring(index_from);
			}
			var index_to = v.indexOf(dentu);
			if (index_from > -1) {
				v = v.substring(0, index_to+dentu.length);
			}
			if (doicookietu == 1) {
				var replace = v;
				var re = new RegExp(replace,"g");
				v_tmp = v_tmp.replace(re, "");
				final_noidung.push(v_tmp);
			}
			if (doicookietu == 2) {
				final_noidung.push(v);
			}
		});
		noidung = final_noidung.join("\n");
		
		this.setState({
			ketqua: noidung
		});
	}
	
	render() {
		return (
			<Fragment>
				<div className="form-group row">
					<label className="col-sm-2 col-form-label edittext-label-right" htmlFor="">Loại bỏ trùng lặp?</label>
					<div className="col-sm-10" style={{"display": "flex", "alignItems": "center"}}>
						<div className="custom-control custom-switch mb-1">
							<input onChange={(e) => {this.onChangeTrungLap(e)}} type="checkbox" className="custom-control-input" id="loaibotrunglap" name="loaibotrunglap" checked={this.state.loaibotrunglap} />
							<label className="custom-control-label edittext-custom-control-label" htmlFor="loaibotrunglap" ></label>
						</div>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-2 col-form-label edittext-label-right" htmlFor="">Nội dung</label>
					<div className="col-sm-10">
						<textarea rows="5" placeholder={this.state.text_placeholder} className="form-control input-gray edittext-textarea" name="noidung" onChange={this.onChange} value={this.state.noidung} ></textarea>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-2 col-form-label edittext-label-right" htmlFor="">Từ từ</label>
					<div className="col-sm-10">
						<input type="text" className="form-control" name="tutu" value={this.state.tutu} onChange={this.onChange} />
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-2 col-form-label edittext-label-right" htmlFor="">Đến từ</label>
					<div className="col-sm-10">
						<input type="text" className="form-control" name="dentu" value={this.state.dentu} onChange={this.onChange} />
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-2 col-form-label edittext-label-right" htmlFor="">Đổi cookie từ</label>
					<div className="col-sm-10">
						<div className="btn-group" role="group" aria-label="">
							<button type="button" className={"btn btn-outline-primary " + (this.state.doicookietu == 1 ? "active" : "")} name="doicookietu" value="1" onClick={(e) => {this.onClick(e)}}>Loại bỏ</button>
							<button type="button" className={"btn btn-outline-primary " + (this.state.doicookietu == 2 ? "active" : "")} name="doicookietu" value="2" onClick={(e) => {this.onClick(e)}}>Giữ lại</button>
						</div>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-2 col-form-label edittext-label-right" htmlFor=""></label>
					<div className="col-sm-10">
						<button type="button" className="btn btn-rounded btn-hero-primary edittext-btn" onClick={this.onTrigger} >Trigger</button>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-2 col-form-label edittext-label-right" htmlFor=""></label>
					<div className="col-sm-10">
						<textarea rows="5" placeholder="" className="form-control input-gray edittext-textarea" name="ketqua" defaultValue={this.state.ketqua} ></textarea>
					</div>
				</div>
			</Fragment>
		);
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
});
const mapDispatchToProps = {  };

export default connect(mapStateToProps, mapDispatchToProps)(Locchu);
