import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import {
    Modal,
    ModalBody,
    ModalFooter
} from 'reactstrap';
import { saveAddPriceSpeedFunction } from '../../../../actions/adminPageAction';
import parse from 'html-react-parser';

class ModalAddPriceFunction extends Component {
    state = {
        formdata: {
            nametype: this.props.adminpage.listdatausertype[0].name,
            usertype: this.props.adminpage.listdatausertype[0].id,
			percent: 10,
			lamtrongiaban: 1,
        }
    }

    onChangeFormFunction = (e) => {
		if (e.target.name === 'usertype') {
			var datanew = {
				...this.state.formdata,
				nametype: e.target.options[e.target.selectedIndex].text,
				[e.target.name]: parseInt(e.target.value),
			}
		} else {
			var datanew = {
				...this.state.formdata,
				[e.target.name]: parseInt(e.target.value),
			}
		}
        this.setState({
            formdata: datanew
        });
    }
	resetFormdata = (e) => {
        this.setState({
            formdata: {
				nametype: this.props.adminpage.listdatausertype[0].name,
				usertype: this.props.adminpage.listdatausertype[0].id,
				percent: 10,
				lamtrongiaban: 1,
			}
        });
	}
	onKeyPress = (e) => {
		if (!(e.charCode >= 48 && e.charCode <= 57)) {
			e.preventDefault();
		}
	}

    render() {
        const {isOpenModal} = this.props;
        const {formdata} = this.state;
        const { listdatausertype, loadingblock } = this.props.adminpage;
        const optionUserType = (
            <Fragment>
				{
					listdatausertype.map(function (dataList, index) {
						return (
							<option value={dataList.id} key={index}>{dataList.name}</option>
						);
					})
				}
            </Fragment>
        )
        return (
            <Fragment>
                <Modal centered={true} size="lg" toggle={(e) => {this.resetFormdata(e); this.props.actionOpenModal()}} isOpen={isOpenModal} className={(loadingblock? ' block block-mode-loading' : '')} style={{"backgroundColor": "inherit", "boxShadow": "none"}}>
                    <div className="modal-header">
                        <p className="mb-0 text-dark">Thêm giá chức năng (tự động)</p>
                        <button onClick={(e) => {this.props.actionOpenModal()}} type="button" className="close">×</button>
                    </div>
                    <ModalBody style={{opacity: loadingblock? '.05':'1'}}>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label className="mr-4">Làm tròn giá bán nếu lẻ:</label>
									<div className="custom-control custom-radio custom-control-inline" >
										<input onChange={(e) => {this.onChangeFormFunction(e)}} value="1" type="radio" className="custom-control-input" id="lamtrongiaban_1" name="lamtrongiaban" checked={formdata.lamtrongiaban == 1} />
										<label className="custom-control-label" htmlFor="lamtrongiaban_1" >Có</label>
									</div>
									<div className="custom-control custom-radio custom-control-inline" >
										<input onChange={(e) => {this.onChangeFormFunction(e)}} value="2" type="radio" className="custom-control-input" id="lamtrongiaban_2" name="lamtrongiaban" checked={formdata.lamtrongiaban == 2} />
										<label className="custom-control-label" htmlFor="lamtrongiaban_2" >Không</label>
									</div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label className="mr-4">Loại tài khoản:</label>
                                    <select onChange={(e) => {this.onChangeFormFunction(e)}} name="usertype" className="form-control">
                                        {optionUserType}
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label className="mr-4">Tăng % so với site gốc:</label>
                                    <input onChange={(e) => {this.onChangeFormFunction(e)}} value={formdata.percent} type="number" name="percent" className="form-control" onKeyPress={this.onKeyPress} />
									<h6 className="text-danger" style={{"marginTop": "15px", "marginRight": "5px", "display": "inline-block"}}>(Giá mới ở trình duyệt này sẽ hiển thị sau 5-10 phút, nếu muốn kiểm tra giá mới ngay hãy bật trình duyệt ẩn danh)</h6>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter style={{opacity: loadingblock? '.05':'1'}}>
                        <button type="button" className="btn btn-primary" onClick={(e) => {this.resetFormdata(e); this.props.saveAddPriceSpeedFunction(formdata, this.props.actionOpenModal)}} >Lưu</button>
                        <button type="button" className="btn btn-danger" onClick={(e) => {this.resetFormdata(e); this.props.actionOpenModal()}} >Huỷ</button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        );
    }

}

const mapStateToProps = state => ({
    adminpage: state.adminpage
});

export default connect(mapStateToProps, {saveAddPriceSpeedFunction}) (ModalAddPriceFunction);
