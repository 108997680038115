import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { loadYV, upHuyOrder } from '../../../actions/youtubelikeActions';
import { load, updateLimit, updateType } from '../../../actions/youtubeActions';
import Swal from 'sweetalert2';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import ModalEditAdmin from './modalEditAdmin';
import Moment from 'react-moment';
import { RiExchangeDollarLine } from "react-icons/ri";
const { SearchBar } = Search;

class nhatky extends Component {
	static propTypes =  {
		loadYV: PropTypes.func.isRequired,
		upHuyOrder: PropTypes.func.isRequired,
		updateLimit: PropTypes.func.isRequired,
		updateType: PropTypes.func.isRequired,
	};
	
	state = {
        dataItemSelect: {},
		page: 1,
		obj_search: "",
        isOpenModalEditAdmin: false,
	}
	
	componentDidMount() {
		// this.props.loadYV();
	}
	
	onChange = (e) => {
		this.setState({
			"obj_search": e.target.value,
			"page": 1
		});
	}
    getListByLimit = (page, type_button) => {
        var valueChange = parseInt(page);
		if (type_button == "prev") {
			valueChange -= 1;
			if (valueChange <= 0) {
				valueChange = 1;
			}
		}
		if (type_button == "next") {
			valueChange += 1;
		}
		this.setState({
			page: valueChange,
		});
		const { type } = this.props.youtubelike;
		this.props.load('youtubelike', type, valueChange, this.state.obj_search);
    }
	getListByView = (type) => {
		this.setState({
			"page": 1,
		});
		this.props.updateType('youtubelike', type);
		this.props.load('youtubelike', type, 1, this.state.obj_search);
	}
    getListBySearch = (e) => {
		this.setState({
			"page": 1,
		});
		const { type } = this.props.youtubelike;
		this.props.load('youtubelike', type, 1, this.state.obj_search);
    }
	
	// onChangeLimit = e => {
		// const { type } = this.props.youtubelike;
		// this.props.updateLimit('youtubelike', e.target.value);
		// this.props.load('youtubelike', type, e.target.value);
	// };
	
	// onCheckList = (type) => {
		// const { limit_pagi } = this.props.youtubelike;
		// this.props.updateType('youtubelike', type);
		// this.props.load('youtubelike', type, limit_pagi);
	// }
	
	huyOrder = (id, object_id) => {
		const swalWithBootstrapButtons = Swal.mixin({
			customClass: {
				confirmButton: 'btn btn-success an-margin-btn',
				cancelButton: 'btn btn-danger an-margin-btn'
				},
			buttonsStyling: false
		})

		swalWithBootstrapButtons.fire({
			title: 'HỦY LIKE ID: '+object_id+' ?',
			text: "Sau khi hủy bạn sẽ được hoàn lại số lượng chưa tăng sau 1 đến 3 ngày và trừ thêm 5.000 Coin phí để tránh Spam!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Đồng ý',
			cancelButtonText: 'Không',
			reverseButtons: true
		}).then((result) => {
			if (result.value) {
				let body = {
					"id": id
				};
				this.props.upHuyOrder(body);
			} else if (result.dismiss === Swal.DismissReason.cancel) {
				
			}
		})
	}
	
    openModalEditAdmin = (data = {}) => {
        this.setState({
            ...this.state,
            isOpenModalEditAdmin: !this.state.isOpenModalEditAdmin,
            dataItemSelect: data,
        })
    }
	
	render() {
		const { listtientrinh, limit_pagi } = this.props.youtubelike;
		const { username } = this.props.auth.user;
 		const {isOpenModalEditAdmin,dataItemSelect} = this.state;
		var ar_listtientrinh = [];
		listtientrinh.forEach(function(v, i) {
			var tmp = {
				...v,
				"site_username": username
			};
			ar_listtientrinh.push(tmp);
		});
		
		function formatBaoHanh(cell, row) {
			if (cell === 0) {
				return (
					<span className="badge badge-warning">Không</span>
				)
			} else {
				return (
					<span className="badge badge-success">Có</span>
				)
			}
		}
		function filterValueBaoHanh(cell, row) {
			if (cell === 0) {
				return ("Không")
			} else {
				return ("Có")
			}
		}
		
		function formatTrangThai(cell, row) {
			var message = '';
			if (cell === 0) {
				return (
					<span className="badge badge-primary"><i className="fas fa-check mr-1"></i>Hoàn thành</span>
				);
			} else if (cell === 1) {
				return (
					<span className="badge badge-success"><i className="fas fa-sync-alt mr-1"></i>Đang chạy</span>
				);
			} else if (cell === 2) {
				return (
					<span className="badge badge-danger"><i className="fas fa-ban mr-1"></i>ID die không thể chạy</span>
				);
			} else if (cell === 3) {
				return (
					<span className="badge badge-warning"><i className="fas fa-times mr-1"></i>Hủy đơn</span>
				);
			} else if (cell === 5) {
				return (
					<span className="badge badge-info"><RiExchangeDollarLine size={12} className="mr-1" />Hoàn tiền</span>
				);
			} else {
				return ('');
			}
		}
		function filterValueTrangThai(cell, row) {
			if (cell === 0) {
				return ('Hoàn thành');
			} else if (cell === 1) {
				return ('Đang chạy');
			} else if (cell === 2) {
				return ('ID die không thể chạy');
			} else if (cell === 3) {
				return ('Hủy đơn');
			} else if (cell === 5) {
				return ('Hoàn tiền');
			} else {
				return ('');
			}
		}

		function formatCheckTime(cell, row) {
			let date_now = Math.floor(Date.now());
			var result = new Date(cell);
			let calDateDay = (result - date_now);
			if (calDateDay >= 0) {
				if (calDateDay/86400 < 1) {
		          	return Math.ceil(calDateDay/3600) + ' giờ';
		        }
				return (
					<Fragment>
						<Moment diff={date_now} unit="days">{result}</Moment>
						{' '} ngày
					</Fragment>
				);
			} else {
				return <span className="badge badge-danger">Hết hạn</span>;
			}
		}

		function filterValueCheckTime(cell, row) {
			let date_now = Math.floor(Date.now());
			var result = new Date(cell);
			let calDateDay = (result - date_now);
			if (calDateDay >= 0) {
				if (calDateDay/86400 < 1) {
		          	return Math.floor(calDateDay/3600) + ' giờ';
		        }
				var diffDays = Math.floor(calDateDay / (60 * 60 * 24)) + ' ngày';
				return diffDays;
			} else {
				return ('Hết hạn');
			}
		}
		
		function calBwDateToNow(cell, row) {
			let date_now = Math.floor(Date.now() / 1000);
			let calDateDay = (date_now - cell)/86400;
			if (calDateDay > 1) {
				return Math.ceil(calDateDay) + ' ngày trước';
			} else {
				let calDatehour = (date_now - cell)/3600;
				if (calDatehour < 0.6) {
					return Math.ceil(calDatehour*100) + ' phút trước';
				} else {
					return Math.ceil(calDatehour) + ' giờ trước';
				}
			}
		}
		
		function formatGoi(cell, row) {
			if (cell === "1") {
				return ('LIKE SV1');
			} else if (cell === "2") {
				return ('DISLIKE');
			} else if (cell === "3") {
				return ('LIKE SV2');
			} else {
				return ('');
			}
		}
		
		const expandRow = {
			renderer: (row) => (
				<div className="offset-lg-7 col-lg-8 row">
					{/* <div className={"col-lg-4 " + (row.is_removed === 0 ? '' : 'offset-lg-4')}>
							<div className="form-group">
								<label htmlFor="example-text-input">Nội dung</label>
								<textarea className="form-control" id="nd" name="nd" placeholder="" rows="2" ></textarea>
							</div>
					</div> */}
					<div className="col-lg-4">
						<div className="form-group">
							<label htmlFor="example-text-input">Ghi chú</label>
							<textarea className="form-control" id="gc" name="gc" placeholder="" rows="2" defaultValue={row.notes}></textarea>
						</div>
					</div>
					{/* {
						row.is_removed === 0 ?
							<div className="col-lg-4">
								<div className="form-group">
									<button type="button" name="huy_order" className="btn btn-block btn-sm btn-rounded btn-hero-light" onClick={() => {this.huyOrder(row.id, row.object_id)}}>Hủy Order</button>
								</div>
							</div>
						: ''
					}
					<div className="col-lg-4 d-flex align-items-center justify-content-center">
						<div className="form-group">
							{ this.props.auth.user.type === 1 ?
								<button type="button" onClick={() => {this.openModalEditAdmin(row)}} className="btn btn-block btn-sm btn-rounded btn-hero-success">Cài đặt admin</button>
								: ''
							}
						</div>
					</div> */}
				</div>
			),
			showExpandColumn: true,
			expandColumnPosition: 'right',
			expandHeaderColumnRenderer: ({ isAnyExpands }) => {
				if (isAnyExpands) {
					return <b>-</b>;
				}
				return <b>+</b>;
			},
			expandColumnRenderer: ({ expanded }) => {
				if (expanded) {
					return (
						<b>-</b>
					);
				}
				return (
					<b>...</b>
				);
			}
		};
		
		const columns = [{
			dataField: 'id',
			text: 'ID',
			sort: true,
		}, {
		    text: 'Thao tác',
		    dataField: '-',
		    style: {
			  'white-space' : 'nowrap'
		    },
			formatter: (cell, row) => 
				<Fragment>
					{ this.props.auth.user.type === 1 ?
						<button type="button" onClick={() => {this.openModalEditAdmin(row)}} className="btn btn-hero-success text-center p-2 mr-1"><i className="fa fa-fw fa-pen"></i></button>
						: ''
					}
					{
						row.is_removed === 0 ?
							<button type="button" name="huy_order" className="btn btn-hero-danger text-center p-2 mr-1" onClick={() => {this.huyOrder(row.id, row.object_id)}}><i className="fa fa-fw fa-trash"></i></button>
						: ''
					}
				</Fragment>
		}, {
			dataField: 'object_id',
			text: 'Youtube',
			sort: true,
			formatter: (cell, row) => 
				<Fragment>
					<a href={"https://www.youtube.com/watch?v="+cell} target="_blank" rel="noopener noreferrer">{cell}</a>
				</Fragment>
		}/* , {
			dataField: 'is_warranty',
			text: 'Bảo hành',
			sort: true,
			formatter: formatBaoHanh,
			filterValue: filterValueBaoHanh
		} */, {
			dataField: 'type',
			text: 'Loại',
			sort: true,
			formatter: formatGoi,
			filterValue: formatGoi
		}, {
			dataField: 'start_like',
			text: 'Start',
			sort: true,
		}, {
			dataField: 'quantity',
			text: 'Số lượng',
			sort: true,
		}, {
			dataField: 'price_per',
			text: 'Giá',
			sort: true,
		}, {
			dataField: 'prices',
			text: 'Tổng tiền',
			sort: true,
		}, {
			dataField: 'count_success',
			text: 'Đã chạy',
			sort: true,
		}, {
			dataField: 'created_at',
			text: 'Khởi tạo',
			sort: true,
			formatter: calBwDateToNow,
			filterValue: calBwDateToNow
		}, {
			dataField: 'time_expired',
			text: 'Hết hạn',
			sort: true,
			formatter: formatCheckTime,
			filterValue: filterValueCheckTime
		}, {
			dataField: 'username',
			text: 'Người Add',
			sort: true,
		    classes: 'notranslate',
		}, {
			dataField: 'status',
			text: 'Trạng thái',
			sort: true,
			formatter: formatTrangThai,
			filterValue: filterValueTrangThai
		}];
		return (
			<Fragment>
				<ToolkitProvider keyField="id" data={ ar_listtientrinh } columns={ columns } search >
					{
						props => (
							<div>
								<SearchBar { ...props.searchProps } />
								<div className="row mb-4">
									<div className="col-md-12">
										{ this.props.auth.user.type === 1 ?
											<Fragment>
												<button onClick={() => {this.getListByView(1)}} type="button" className="btn btn-primary mr-1">Xem tất cả</button>
												<button onClick={() => {this.getListByView(0)}} type="button" className="btn btn-primary mr-1">Xem riêng</button>
											</Fragment>
										: ''}
										{/* <select className="form-control custom-select select-light col-md-3 custom-limit_pagi" id="limit_pagi" name="limit_pagi" onChange={this.onChangeLimit} value={limit_pagi} >
											<option value="0">Số lịch sử hiển thị (0-500)</option>
											<option value="1">500-1000</option>
											<option value="2">1000-1500</option>
											<option value="3">1500-2000</option>
											<option value="4">2000-2500</option>
											<option value="5">2500-3000</option>
											<option value="7">3000-3500</option>
											<option value="8">3500-4000</option>
											<option value="9">4500-5000</option>
										</select> */}
										<button onClick={(e) => {this.getListByLimit(this.state.page, "prev")}} name="page" value={this.state.page} type="button" className="btn btn-primary mr-1">{"<"}</button>
										<button onClick={(e) => {this.getListByLimit(this.state.page, "next")}} name="page" value={this.state.page} type="button" className="btn btn-primary mr-1">{">"}</button>
										<button onClick={(e) => {this.getListBySearch(e)}} name="view" value="0" type="button" className="btn btn-primary mr-1">Tìm kiếm</button>
										<input  onChange={this.onChange} value={this.state.obj_search}  name="obj_search" type="text" className="form-control mr-1" style={{"width": "100px", "display": "inline-block"}}/>
									</div>
								</div>
								<BootstrapTable { ...props.baseProps } expandRow={ expandRow } pagination={ paginationFactory() } wrapperClasses="table-responsive" striped hover condensed />
								{(this.props.auth.user.type === 1 && isOpenModalEditAdmin) ? <ModalEditAdmin openModal={this.openModalEditAdmin} isOpenModal={isOpenModalEditAdmin} dataItemSelect={dataItemSelect} /> : '' }
							</div>
						)
					}
				</ToolkitProvider>
				<div>
					{/*<BootstrapTable keyField='id' data={ ar_listtientrinh } columns={ columns } expandRow={ expandRow } pagination={ paginationFactory() } wrapperClasses="table-responsive" striped hover condensed />*/}
				</div>
			</Fragment>
		)
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	youtubelike: state.youtubelike,
});
const mapDispatchToProps = { loadYV, upHuyOrder, load, updateLimit, updateType }

export default connect(mapStateToProps, mapDispatchToProps)(nhatky);