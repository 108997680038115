import {
	LOAD_BSLIO,
	UPDATE_BSLIO,
	UPDATE_BSLIO_TAB,
	LOAD_PRICE_BSLIO,
	LIMIT_PAGI_BSLIO,
	TYPE_BSLIO,
	LOADING_BSLIO
} from "../actions/types";

const date7dnext = () => {
	var result = new Date();
  	result.setDate(result.getDate() + 7);
  	return result;
}

const initialState = {
	"taotientrinh": {
		"lhibv": "",
		"lsct": "1",
		"cdbh": "false",
		"cnbd": new Date(),
		"cnkt": date7dnext(),
		"slct": "10",
		"gtmtt": "0",
		"gc": "",
	},
	"changetab": 0,
	"listtientrinh": [],
	// "tanggiathem": {
		// "id": "",
		// "lhibv": "",
		// "price_per": 0,
		// "price": 0
	// },
	"price": {
		buffseedinglikeinstagramorder: {
			min_bh_web: 0,
			prices_web: 0,
		},
		buffseedinglikeinstagramorder_sv2: {
			min_bh_web: 0,
			prices_web: 0,
		},
		buffseedinglikeinstagramorder_sv3: {
			min_bh_web: 0,
			prices_web: 0,
		},
		buffseedinglikeinstagramorder_sv4: {
			min_bh_web: 0,
			prices_web: 0,
		},
		buffseedinglikeinstagramorder_sv5: {
			min_bh_web: 0,
			prices_web: 0,
		},
		buffseedinglikeinstagramorder_sv6: {
			min_bh_web: 0,
			prices_web: 0,
		},
	},
	"limit_pagi": 0,
	"type": 0,
	"loading": false,
	instagrambufflike_sv1_mo_dong: 1,
	instagrambufflike_sv2_mo_dong: 1,
	instagrambufflike_sv3_mo_dong: 1,
	instagrambufflike_sv4_mo_dong: 1,
	instagrambufflike_sv5_mo_dong: 1,
	instagrambufflike_sv6_mo_dong: 1,
};

export default function(state = initialState, action) {
	switch (action.type) {
		case LOAD_BSLIO:
			return {
				...state,
				"listtientrinh": action.payload
			};
		case UPDATE_BSLIO:
			return {
				...state,
				"taotientrinh": action.payload
			}
			
		case UPDATE_BSLIO_TAB:
			return {
				...state,
				"changetab": action.payload
			};
		case LOAD_PRICE_BSLIO:
			return {
				...state,
				"price": merDataPricePage(action.payload),
			};
		case LIMIT_PAGI_BSLIO:
			return {
				...state,
				"limit_pagi": action.payload
			};
		case TYPE_BSLIO:
			return {
				...state,
				"type": action.payload
			};
		case LOADING_BSLIO:
			return {
				...state,
				"loading": action.payload
			};
		case 'CONFIG_MO_DONG_BSLIO':
			return {
				...state,
				instagrambufflike_sv1_mo_dong: action.payload.instagrambufflike_sv1_mo_dong,
				instagrambufflike_sv2_mo_dong: action.payload.instagrambufflike_sv2_mo_dong,
				instagrambufflike_sv3_mo_dong: action.payload.instagrambufflike_sv3_mo_dong,
				instagrambufflike_sv4_mo_dong: action.payload.instagrambufflike_sv4_mo_dong,
				instagrambufflike_sv5_mo_dong: action.payload.instagrambufflike_sv5_mo_dong,
				instagrambufflike_sv6_mo_dong: action.payload.instagrambufflike_sv6_mo_dong,
			};
		default:
		  return state;
	}
}

function merDataPricePage(data) {
	let dataNew = {
		...initialState.price
	}
	if (data.length > 0) {
		data.forEach(function(v, i){
			if (v.name_table === 'buffseedinglikeinstagramorder') {
				dataNew['buffseedinglikeinstagramorder'] = v;
				initialState.taotientrinh.gtmtt = v.prices_web;
			} else if (v.name_table === 'buffseedinglikeinstagramorder_sv2') {
				dataNew['buffseedinglikeinstagramorder_sv2'] = v;
			} else if (v.name_table === 'buffseedinglikeinstagramorder_sv3') {
				dataNew['buffseedinglikeinstagramorder_sv3'] = v;
			} else if (v.name_table === 'buffseedinglikeinstagramorder_sv4') {
				dataNew['buffseedinglikeinstagramorder_sv4'] = v;
			} else if (v.name_table === 'buffseedinglikeinstagramorder_sv5') {
				dataNew['buffseedinglikeinstagramorder_sv5'] = v;
			} else if (v.name_table === 'buffseedinglikeinstagramorder_sv6') {
				dataNew['buffseedinglikeinstagramorder_sv6'] = v;
			}
		});
	};
	return dataNew;
}
