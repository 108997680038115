import {
	LOAD_VLT,
	UPDATE_VLT,
	UPDATE_VLT_TAB,
	UPDATE_VLT_GETID,
	LOAD_PRICE_VLT,
	LIMIT_PAGI_VLT,
	TYPE_VLT,
	LOADING_VLT,
	LOAD_NHATKYHOATDONG_VLT,
} from "../actions/types";

const date7dnext = () => {
	var result = new Date();
  	result.setDate(result.getDate() + 7);
  	return result;
}

const initialState = {
	"taotientrinh": {
		"lhipcv": "",
		"lsct": "viplikethang_speed",
		"sllnnct": "40",
		"slllnct": "50",
		"slbvtmn": "5",
		"sncmv": "30",
		"gtmtt": "0",
		"slbv": "5",
		"gc": "",
		"usernamefb": "",
	},
	datapricebuffLike: {
		like_user: {
			min_bh_web: 0,
			prices_web: 0,
		},
		like_via: {
			min_bh_web: 0,
			prices_web: 0,
		},
		like_user_speed: {
			min_bh_web: 0,
			prices_web: 0,
		}
	},
	"changetab": 0,
	"listtientrinh": [],
	// "tanggiathem": {
		// "id": "",
		// "lhibv": "",
		// "price_per": 0,
		// "price": 0
	// },
	"price": [],
	"limit_pagi": 0,
	"type": 0,
	"loading": false,
	"list_nhatky":"",
	facebookviplikepro_sv1_mo_dong: 1,
	facebookviplikepro_sv2_mo_dong: 1,
	facebookviplikepro_sv3_mo_dong: 1,
};

export default function(state = initialState, action) {
	switch (action.type) {
		case LOAD_VLT:
			return {
				...state,
				"listtientrinh": action.payload
			};
		case UPDATE_VLT:
			return {
				...state,
				"taotientrinh": action.payload
			};
		case UPDATE_VLT_TAB:
			return {
				...state,
				"changetab": action.payload
			};
		case UPDATE_VLT_GETID:
			return {
				...state,
				"taotientrinh": {
					...state.taotientrinh,
					"lhipcv": action.payload.id,
					"fb_name": action.payload.name
				}
			};
		case 'LOAD_PRICE_VIP_LIKE_PRO':
			return {
				...state,
				datapricebuffLike: merDataPricePage(action.payload),
			};
		case 'CHANGE_DATA_FRICE_VIP_LIKE_PRO':
			return {
				...state,
				taotientrinh: {
					...state.taotientrinh,
					"gtmtt": action.payload.giatien,
					"lsct": action.payload.type,
					"slbv": action.payload.slbv,
				}
			};
		case LIMIT_PAGI_VLT:
			return {
				...state,
				"limit_pagi": action.payload
			};
		case TYPE_VLT:
			return {
				...state,
				"type": action.payload
			};
		case LOADING_VLT:
			return {
				...state,
				"loading": action.payload
			};
		case LOAD_NHATKYHOATDONG_VLT:
			return {
				...state,
				"list_nhatky": action.payload
			};
		case 'CONFIG_MO_DONG_VIP_LIKE_PRO':
			return {
				...state,
				facebookviplikepro_sv1_mo_dong: action.payload.facebookviplikepro_sv1_mo_dong,
				facebookviplikepro_sv2_mo_dong: action.payload.facebookviplikepro_sv2_mo_dong,
				facebookviplikepro_sv3_mo_dong: action.payload.facebookviplikepro_sv3_mo_dong,
			};
		default:
		  return state;
	}

}
function merDataPricePage(data) {
	const dataOld = initialState.datapricebuffLike;
	let dataNew = {
		...dataOld
	}
	if (data.length > 0) {
		data.forEach(function(v, i){
			if (v.name_table === 'viplikethang') {
				dataNew['like_user'] = v;
			} else if (v.name_table === 'viplikethang_likevia') {
				dataNew['like_via'] = v;
			} else if (v.name_table === 'viplikethang_speed') {
				dataNew['like_user_speed'] = v;
				initialState.price = v;
				initialState.taotientrinh['gtmtt'] = v.prices_web;
			}
		});
	};
	return dataNew;
}
