import {
	LOAD_SP,
	UPDATE_SP,
	LIMIT_PAGI_SP,
	TYPE_SP,
	LOADING_SP,
	LOAD_EDIT_SP,
} from "../actions/types";

const initialState = {
	"taotientrinh": {
		"vande": "",
		"tieude": "",
		"idbv": "",
		"mota": "",
		"file": "",
	},
	"listtientrinh": [],
	"edittientrinh": {
		"data": [],
		"data_chat": [],
	},
	"limit_pagi": 0,
	"type": 0,
	"loading": false,
};

export default function(state = initialState, action) {
	switch (action.type) {
		case LOAD_SP:
			return {
				...state,
				"listtientrinh": action.payload
			};
		case UPDATE_SP:
			return {
				...state,
				"taotientrinh": action.payload
			};
		case LIMIT_PAGI_SP:
			return {
				...state,
				"limit_pagi": action.payload
			};
		case TYPE_SP:
			return {
				...state,
				"type": action.payload
			};
		case LOADING_SP:
			return {
				...state,
				"loading": action.payload
			};
		case LOAD_EDIT_SP:
			return {
				...state,
				"edittientrinh": {
					"data": action.payload.data,
					"data_chat": action.payload.data_chat,
				}
			};
		default:
		  return state;
	}
}
