import {
	LOAD_THUESIM,
	UPDATE_THUESIM_TAB,
	UPDATE_THUESIM,
	LOAD_PRICE_THUESIM,
	LOADING_THUESIM,
	LIMIT_PAGI_THUESIM,
	TYPE_THUESIM,
	LOAD_NHATKYHOATDONG_THUESIM,
} from "../actions/types";

const date7dnext = () => {
	var result = new Date();
  	result.setDate(result.getDate() + 7);
  	return result;
}

const initialState = {
	"taotientrinh": {
		// "slct": "1",
		"lsct": "1",
		"gtmtt": "0",
	},
	"changetab": 0,
	"listtientrinh": [],
	"price": {
		thuesim_sv1: {
			min_bh_web: 0,
			prices_web: 0,
		},
	},
	"loading": false,
	"limit_pagi": 0,
	"type": 0,
	"list_nhatky":"",
	thuesim_sv1_mo_dong: 1,
};

export default function(state = initialState, action) {
	switch (action.type) {
		case LOAD_THUESIM:
			return {
				...state,
				"listtientrinh": action.payload
			};
		case UPDATE_THUESIM_TAB:
			return {
				...state,
				"changetab": action.payload
			};
		case UPDATE_THUESIM:
			return {
				...state,
				"taotientrinh": action.payload
			};
		case LOAD_PRICE_THUESIM:
			return {
				...state,
				"price": merDataPricePage(action.payload),
			};
		case LOADING_THUESIM:
			return {
				...state,
				"loading": action.payload
			};
		case LIMIT_PAGI_THUESIM:
			return {
				...state,
				"limit_pagi": action.payload
			};
		case TYPE_THUESIM:
			return {
				...state,
				"type": action.payload
			};
		case LOAD_NHATKYHOATDONG_THUESIM:
			return {
				...state,
				"list_nhatky": action.payload
			};
		case 'CONFIG_MO_DONG_THUESIM':
			return {
				...state,
				thuesim_sv1_mo_dong: action.payload.thuesim_sv1_mo_dong,
			};
		default:
		  return state;
	}
}

function merDataPricePage(data) {
	let dataNew = {
		...initialState.price
	}
	if (data.length > 0) {
		data.forEach(function(v, i){
			if (v.name_table === 'thuesim_sv1') {
				initialState.taotientrinh.gtmtt = v.prices_web;
				dataNew['thuesim_sv1'] = v;
			}
		});
	};
	return dataNew;
}
