import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { updateYGX, addYGX, loadPriceYGX } from '../../../actions/youtubegioxemActions';
import { add, updateYoutube, getConfigMoDong } from '../../../actions/youtubeActions';
import Swal from 'sweetalert2';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import parse from 'html-react-parser';
import ServicesDescription from "../ServicesDescription";
import { isMobile } from 'react-device-detect';

const date7dnext = () => {
	var result = new Date();
  	result.setDate(result.getDate() + 7);
  	return result;
}

class taotientrinh extends Component {
	static propTypes =  {
		updateYGX: PropTypes.func.isRequired,
		addYGX: PropTypes.func.isRequired,
		loadPriceYGX: PropTypes.func.isRequired,
		add: PropTypes.func.isRequired,
		updateYoutube: PropTypes.func.isRequired,
	};
	
	state = {
		showDescription: isMobile? false : true,
		dodaivideo: "",
		sogiomongmuon: "",
	}
	
	componentDidMount() {
		// this.props.loadPriceYGX();
		this.props.getConfigMoDong('youtube_buffgioxem');
	};
	
 	handleChangeDateStart = date => {
		const { taotientrinh } = this.props.youtubegioxem;
		var new_taotientrinh = {
			...taotientrinh,
			"cnbd": date,
			"cnkt": this.addDays(date, 7)
		};
		this.props.updateYoutube('youtubegioxem', new_taotientrinh);
	}

	handleChangeDateEnd = date => {
		const { taotientrinh } = this.props.youtubegioxem;
		var new_taotientrinh = {
			...taotientrinh,
			"cnkt": date
		};
		this.props.updateYoutube('youtubegioxem',new_taotientrinh);
	}
	
	addDays = (date, days) => {
	  	var result = new Date(date);
	  	result.setDate(result.getDate() + days);
	  	return result;
	}
	
	onChange = (e) => {
		if ((e.target.name === "lhibv") && (e.target.value.indexOf("youtube.com") > -1 || e.target.value.indexOf("youtu.be") > -1)) {
			if (e.target.value !== "") {
				var id = e.target.value.match(/v=(.*?)(&|$)/);
				if (e.target.value.indexOf("youtu.be") > -1 && !id) {
					id = e.target.value.match(/youtu\.be\/(.*?)(&|\?|$)/);
				}
				if (e.target.value.indexOf("shorts") > -1 && !id) {
					id = e.target.value.match(/shorts\/(.*?)(&|\?|$)/);
				}
				const { taotientrinh } = this.props.youtubegioxem;
				if (id) {
					var new_taotientrinh = {
						...taotientrinh,
						[e.target.name]: id[1]
					};
				} else {
					var new_taotientrinh = {
						...taotientrinh,
						[e.target.name]: e.target.value
					};
				}
				this.props.updateYoutube('youtubegioxem', new_taotientrinh);
			}
		} else if (e.target.name === "lsct") {
			var { taotientrinh } = this.props.youtubegioxem;
			if (e.target.value === "1") {
				var value = this.props.youtubegioxem.price.youtubegioxem_sv1.prices_web;
				taotientrinh = {
					...taotientrinh,
					"cdbh": "false",
					"gtmtt": value,
				};
			}
			if (e.target.value === "2") {
				var value = this.props.youtubegioxem.price.youtubegioxem_sv2.prices_web;
				taotientrinh = {
					...taotientrinh,
					"cdbh": "false",
					"gtmtt": value,
				};
			}
			if (e.target.value === "3") {
				var value = this.props.youtubegioxem.price.youtubegioxem_sv3.prices_web;
				taotientrinh = {
					...taotientrinh,
					"cdbh": "false",
					"gtmtt": value,
				};
			}
			if (e.target.value === "4") {
				var value = this.props.youtubegioxem.price.youtubegioxem_sv4.prices_web;
				taotientrinh = {
					...taotientrinh,
					"cdbh": "false",
					"gtmtt": value,
				};
			}
			if (e.target.value === "5") {
				var value = this.props.youtubegioxem.price.youtubegioxem_sv5.prices_web;
				taotientrinh = {
					...taotientrinh,
					"cdbh": "false",
					"gtmtt": value,
				};
			}
			var new_taotientrinh = {
				...taotientrinh,
				[e.target.name]: e.target.value,
			};
			this.props.updateYoutube('youtubegioxem', new_taotientrinh);
		} else {
			const { taotientrinh } = this.props.youtubegioxem;
			var new_taotientrinh = {
				...taotientrinh,
				[e.target.name]: e.target.value
			};
			this.props.updateYoutube('youtubegioxem', new_taotientrinh);
		}
	}
	
	onChangeState = (e) => {
		this.setState({
			[e.target.name]: e.target.value
		});
	}
	
	onClickUocLuong = (e) => {
		const { taotientrinh } = this.props.youtubegioxem;
		var { dodaivideo, sogiomongmuon } = this.state;
		
		var dodaivideo_sv = 120;
		var lsct_sv = 5;
		var value_sv = this.props.youtubegioxem.price.youtubegioxem_sv5.prices_web;
		if (dodaivideo < 15) {
			dodaivideo_sv = dodaivideo;
			lsct_sv = 3;
			value_sv = this.props.youtubegioxem.price.youtubegioxem_sv3.prices_web;
		} else if (dodaivideo < 30) {
			dodaivideo_sv = 15;
			lsct_sv = 3;
			value_sv = this.props.youtubegioxem.price.youtubegioxem_sv3.prices_web;
		} else if (dodaivideo < 60) {
			dodaivideo_sv = 30;
			lsct_sv = 4;
			value_sv = this.props.youtubegioxem.price.youtubegioxem_sv4.prices_web;
		} else if (dodaivideo < 120) {
			dodaivideo_sv = 60;
			lsct_sv = 1;
			value_sv = this.props.youtubegioxem.price.youtubegioxem_sv1.prices_web;
		}
		var soview = Math.ceil(sogiomongmuon/dodaivideo_sv*60);
		
		var new_taotientrinh = {
			...taotientrinh,
			"slct": soview,
			"lsct": lsct_sv,
			"cdbh": "false",
			"gtmtt": value_sv,
		};
		this.props.updateYoutube('youtubegioxem', new_taotientrinh);
	}
	
	taoTienTrinhfunc = () => {
		const { taotientrinh } = this.props.youtubegioxem;
		const { money } = this.props.auth.user;
		
		if (taotientrinh.gtmtt === "") {
			Swal.fire({
				icon: 'error',
				title: 'Lỗi',
				text: 'Chọn giá tiền mỗi tương tác',
			});
			return false;
		}
		var final_price = Math.ceil((taotientrinh.gtmtt * taotientrinh.slct));
		
		if (money < final_price) {
			Swal.fire({
				icon: 'error',
				title: 'Lỗi',
				text: 'Không đủ tiền trong tài khoản',
			});
			return false;
		}
		
		const id_user = this.props.auth.user.id;
		var new_taotientrinh = {
			...taotientrinh,
			"id_user": id_user
		};
		Swal.fire({
			title: 'Bạn có chắc?',
			html: "Bạn có chắc chắn muốn mua buff "+taotientrinh.slct+" view với giá "+taotientrinh.gtmtt+" Coin / view? Khi đã bấm đồng ý đồng nghĩa với việc bạn đã đọc hết mục thông tin dịch vụ!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Đồng ý',
			cancelButtonText: 'Không'
		}).then((result) => {
			if (result.value) {
				this.props.add('youtubegioxem', new_taotientrinh);
			}
		})
	}
	
	onKeyPress = (e) => {
		if (!(e.charCode >= 48 && e.charCode <= 57)) {
			e.preventDefault();
		}
	}
	
	onClickShowDescription = (data) => {
		this.setState({
			showDescription: data
		});
	}
	
	render() {
 		const { lhibv, lsct, cdbh, cnbd, cnkt, slct, gtmtt } = this.props.youtubegioxem.taotientrinh;
 		const { youtubebuffgioxem_sv1_mo_dong, youtubebuffgioxem_sv2_mo_dong, youtubebuffgioxem_sv3_mo_dong, youtubebuffgioxem_sv4_mo_dong, youtubebuffgioxem_sv5_mo_dong } = this.props.youtubegioxem;
		
		var listDataServicesInfo = {};
		try {
			if (localStorage.getItem("listDataServicesInfo") != null) {
				listDataServicesInfo = JSON.parse(localStorage.getItem("listDataServicesInfo"));
			} else {
				listDataServicesInfo = this.props.adminpage.listDataServicesInfo;
			}
		} catch(e) {
			listDataServicesInfo = {};
		}
		var html_haydoctranhmattien = '';
		var html_haydoctranhmattien_chinh = '';
		var html_haydoctranhmattien_sv = '';
		var html_4kgioxem_sv1 = '';
		var html_4kgioxem_sv2 = '';
		var html_4kgioxem_sv3 = '';
		var html_4kgioxem_sv4 = '';
		var html_4kgioxem_sv5 = '';
		if (typeof(listDataServicesInfo.youtube_buff_4kgioxem_tab) !== 'undefined') {
			html_haydoctranhmattien = parse(listDataServicesInfo.youtube_buff_4kgioxem_tab.ghichu);
			html_haydoctranhmattien_chinh = parse(listDataServicesInfo.youtube_buff_4kgioxem_tab.ghichu_chinh);
		}
		if (typeof(listDataServicesInfo.youtube_buff_4kgioxem_sv1) !== 'undefined') {
			html_4kgioxem_sv1 = parse(listDataServicesInfo.youtube_buff_4kgioxem_sv1.ghichu_sv);
			if (lsct.toString() === "1") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.youtube_buff_4kgioxem_sv1.ghichu);
			}
		}
		if (typeof(listDataServicesInfo.youtube_buff_4kgioxem_sv2) !== 'undefined') {
			html_4kgioxem_sv2 = parse(listDataServicesInfo.youtube_buff_4kgioxem_sv2.ghichu_sv);
			if (lsct.toString() === "2") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.youtube_buff_4kgioxem_sv2.ghichu);
			}
		}
		if (typeof(listDataServicesInfo.youtube_buff_4kgioxem_sv3) !== 'undefined') {
			html_4kgioxem_sv3 = parse(listDataServicesInfo.youtube_buff_4kgioxem_sv3.ghichu_sv);
			if (lsct.toString() === "3") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.youtube_buff_4kgioxem_sv3.ghichu);
			}
		}
		if (typeof(listDataServicesInfo.youtube_buff_4kgioxem_sv4) !== 'undefined') {
			html_4kgioxem_sv4 = parse(listDataServicesInfo.youtube_buff_4kgioxem_sv4.ghichu_sv);
			if (lsct.toString() === "4") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.youtube_buff_4kgioxem_sv4.ghichu);
			}
		}
		if (typeof(listDataServicesInfo.youtube_buff_4kgioxem_sv5) !== 'undefined') {
			html_4kgioxem_sv5 = parse(listDataServicesInfo.youtube_buff_4kgioxem_sv5.ghichu_sv);
			if (lsct.toString() === "5") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.youtube_buff_4kgioxem_sv5.ghichu);
			}
		}
		
		var price = Math.round((gtmtt * slct + Number.EPSILON) * 100) / 100;
		
		return (
			<Fragment>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Link video hoặc id cần tăng giờ xem:</label>
					<div className="col-sm-8">
						<input type="text" className="form-control" id="lhibv" name="lhibv" placeholder="" onChange={this.onChange} value={lhibv} />
						<h6 style={{"marginTop":"5px", "marginBottom":"0px"}}>Hướng dẫn link youtube: <a href="https://i.imgur.com/A8bPs9U.png" target="_blank" className="font-bold"> Tại đây</a></h6>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Ước lượng:</label>
					<div className="col-sm-8">
						<div className="form-group">
							<div className="row">
								<div className="col-md-4">
									<p className="mb-0">Độ dài video</p>
									<input type="number" className="form-control" id="dodaivideo" name="dodaivideo" onChange={this.onChangeState} value={ this.state.dodaivideo } onKeyPress={this.onKeyPress} placeholder="Thời lượng video (phút)" />
								</div>
								<div className="col-md-4">
									<p className="mb-0">Số giờ mong muốn</p>
									<input type="number" className="form-control" id="sogiomongmuon" name="sogiomongmuon" onChange={this.onChangeState} value={ this.state.sogiomongmuon } onKeyPress={this.onKeyPress} placeholder="Số giờ mong muốn" />
								</div>
								<div className="col-md-4">
									<p className="mb-0">&nbsp;</p>
									<button type="button" className="btn btn-block btn-rounded btn-hero-primary" onClick={this.onClickUocLuong}>Ước lượng</button>
								</div>
							</div>
							<div className="alert alert-info" style={{"marginTop": "5px"}}>
								<strong>Hướng dẫn:</strong> Vui lòng nhập độ dài video (phút) và số giờ mong muốn.<br/>Sau khi nhấn vào nút "Ước lượng", hệ thống sẽ tự chọn server và đưa ra đề xuất "Số lượng view cần tăng" hợp lý!
							</div>
						</div>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Loại Seeding cần tăng:</label>
					<div className="col-sm-8">
						<div className="custom-control custom-radio custom-control-inline" style={{opacity: this.props.youtubegioxem.price.youtubegioxem_sv1.prices_web == 0 ? '0.3' : '1' }}>
							<input onChange={this.onChange} value="1" type="radio" className="custom-control-input" id="loaiseeding_1" name="lsct" checked={lsct.toString() === "1"} disabled={this.props.youtubegioxem.price.youtubegioxem_sv1.prices_web == 0 ? 'disabled' : ''} />
							<label className="custom-control-label" htmlFor="loaiseeding_1" >SV1 {html_4kgioxem_sv1} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.youtubegioxem.price.youtubegioxem_sv1.prices_web} coin</span> {youtubebuffgioxem_sv1_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
						</div><br/>
						<div className="custom-control custom-radio custom-control-inline" style={{opacity: this.props.youtubegioxem.price.youtubegioxem_sv2.prices_web == 0 ? '0.3' : '1' }}>
							<input onChange={this.onChange} value="2" type="radio" className="custom-control-input" id="loaiseeding_2" name="lsct" checked={lsct.toString() === "2"} disabled={this.props.youtubegioxem.price.youtubegioxem_sv2.prices_web == 0 ? 'disabled' : ''} />
							<label className="custom-control-label" htmlFor="loaiseeding_2" >SV2 {html_4kgioxem_sv2} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.youtubegioxem.price.youtubegioxem_sv2.prices_web} coin</span> {youtubebuffgioxem_sv2_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
						</div><br/>
						<div className="custom-control custom-radio custom-control-inline" style={{opacity: this.props.youtubegioxem.price.youtubegioxem_sv3.prices_web == 0 ? '0.3' : '1' }}>
							<input onChange={this.onChange} value="3" type="radio" className="custom-control-input" id="loaiseeding_3" name="lsct" checked={lsct.toString() === "3"} disabled={this.props.youtubegioxem.price.youtubegioxem_sv3.prices_web == 0 ? 'disabled' : ''} />
							<label className="custom-control-label" htmlFor="loaiseeding_3" >SV3 {html_4kgioxem_sv3} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.youtubegioxem.price.youtubegioxem_sv3.prices_web} coin</span> {youtubebuffgioxem_sv3_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
						</div><br/>
						<div className="custom-control custom-radio custom-control-inline" style={{opacity: this.props.youtubegioxem.price.youtubegioxem_sv4.prices_web == 0 ? '0.3' : '1' }}>
							<input onChange={this.onChange} value="4" type="radio" className="custom-control-input" id="loaiseeding_4" name="lsct" checked={lsct.toString() === "4"} disabled={this.props.youtubegioxem.price.youtubegioxem_sv4.prices_web == 0 ? 'disabled' : ''} />
							<label className="custom-control-label" htmlFor="loaiseeding_4" >SV4 {html_4kgioxem_sv4} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.youtubegioxem.price.youtubegioxem_sv4.prices_web} coin</span> {youtubebuffgioxem_sv4_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
						</div><br/>
						<div className="custom-control custom-radio custom-control-inline" style={{opacity: this.props.youtubegioxem.price.youtubegioxem_sv5.prices_web == 0 ? '0.3' : '1' }}>
							<input onChange={this.onChange} value="5" type="radio" className="custom-control-input" id="loaiseeding_5" name="lsct" checked={lsct.toString() === "5"} disabled={this.props.youtubegioxem.price.youtubegioxem_sv5.prices_web == 0 ? 'disabled' : ''} />
							<label className="custom-control-label" htmlFor="loaiseeding_5" >SV5 {html_4kgioxem_sv5} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.youtubegioxem.price.youtubegioxem_sv5.prices_web} coin</span> {youtubebuffgioxem_sv5_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
						</div>
					</div>
				</div>
				{/* <div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Chế độ bảo hành:</label>
					<div className="col-sm-8">
						<select className="form-control" id="cdbh" name="cdbh" onChange={this.onChange} >
							<option value="false">Không bảo hành</option>
							<option value="true">Bảo hành (Bảo hành nếu tụt quá 10%)</option>
						</select>
					</div>
				</div> */}
				<div className="form-group row" style={cdbh === "true" ? { "display":"flex" } : { "display":"none" }}>
					<label className="col-sm-4 col-form-label" htmlFor=""></label>
					<div className="col-sm-8">
						<div className="form-group">
							<div className="row">
								<div className="col-md-6">
									<p className="mb-0">Chọn ngày bắt đầu</p>
									<DatePicker className="form-control w-100"
										showTimeSelect minDate={new Date()}
										selected={cnbd}
										onChange={this.handleChangeDateStart}
									/>
								</div>
								<div className="col-md-6">
									<p className="mb-0">Chọn ngày kết thúc</p>
									<DatePicker className="form-control"
										showTimeSelect minDate={cnbd}
										maxDate={this.addDays(cnbd, 7)}
										selected={cnkt}
										onChange={this.handleChangeDateEnd}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Số lượng view cần tăng:</label>
					<div className="col-sm-8">
						<input type="number" className="form-control" id="slct" name="slct" onChange={this.onChange} value={ slct } onKeyPress={this.onKeyPress} />
						<div className="card card-orange mt-2">
							<div className="card-body py-2">
								<h6 className="font-14 mb-0 text-danger font-bold">
									Lưu ý: Trong lúc buff đơn 4000 giờ xem, không nên chạy bất kì loại view vào khác, nếu không số lượng giờ xem có thể không lên đủ như đã đặt!
								</h6>
							</div>
						</div>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">
						Giá tiền mỗi tương tác:
					</label>
					<div className="col-sm-8">
						<div className="card card-orange mt-2">
							<input type="number" className="form-control" id="gtmtt" name="gtmtt" placeholder="" onChange={this.onChange} value={gtmtt} onKeyPress={this.onKeyPress} />
						</div>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Ghi chú:</label>
					<div className="col-sm-8">
						<textarea rows="2" placeholder="Nhập nội dung ghi chú về tiến trình của bạn" className="form-control input-gray" id="gc" name="gc" onChange={this.onChange} ></textarea>
					</div>
				</div>
				
				<button type="button" className="btn badge-primary-cus btn-rounded btn-show-description-cus" onClick={(e) => {this.onClickShowDescription(!this.state.showDescription)}}>
					{
						this.state.showDescription?
							<i className="fa fa-angle-up" style={{"marginRight": "5px"}} aria-hidden="true"></i>
						:
							<i className="fa fa-angle-down" style={{"marginRight": "5px"}} aria-hidden="true"></i>
					}
					Thông tin dịch vụ
				</button>
				
				<ServicesDescription allservicesinfo={listDataServicesInfo} server={lsct.toString()} tab="youtube_buff_4kgioxem" giatien={gtmtt} baohanh={cdbh === 'true'? 1 : 0} showDescription={this.state.showDescription} />
				
				<div className="row mt-2" style={{"display": this.state.showDescription? "flex" : "none"}}>
				    <div className="col-md-12 bold" style={{"marginBottom": "10px"}}>
						<div className="card card-select-cus">
							<div className="card-body card-body-select-cus">
								<p className="mb-0 mt-3 text-wrap">
									<ul>
										<li>1 ID KHÔNG CÀI 2 ĐƠN CÙNG LÚC TRONG HỆ THỐNG ! ĐƠN CŨ XONG MỚI CÀI ĐƠN MỚI ! CỐ TÌNH CÀI BỊ HỤT SỐ LƯỢNG SẼ KHÔNG XỬ LÝ !</li>
										<li>Nếu ID đang chạy trên hệ thống mà bạn vẫn mua id đó cá hệ thống bên khác, nếu có tình trạng hụt số lượng giữa 2 bên thì sẽ không được xử lí!</li>
										{html_haydoctranhmattien}
										{html_haydoctranhmattien_chinh}
										{html_haydoctranhmattien_sv}
										<li>Nếu gặp lỗi hãy nhắn tin hỗ trợ phía bên phải góc màn hình hoặc vào mục liên hệ hỗ trợ để được hỗ trợ tốt nhất!</li>
									</ul>
								</p>
								<span className="badge badge-cus position-absolute-cus badge-top-left-cus badge-primary-cus badge-pill-cus"> HÃY ĐỌC ĐỂ TRÁNH MẤT TIỀN KHI SỬ DỤNG! </span>
							</div>
						</div>
				    </div>
				</div>
				<div className="row mt-4">
					<div className="col-12 text-center bold">
						<div className="card card-info">
							<div className="card-body">
								<h5 className="font-bold">Tổng: <span className="bold green"><span>{price}</span> Coin</span></h5>
								<h6 className="mb-0">Bạn sẽ buff <span className="bold green">{slct} view</span> với giá <span className="green bold">{price}</span> Coin</h6>
							</div>
						</div>
					</div>
				</div>
				<div className="row my-4">
					<div className="col-12 text-center bold">
						<button type="button" className="btn btn-block btn-rounded btn-hero-primary" onClick={this.taoTienTrinhfunc} >Tạo tiến trình</button>
					</div>
				</div>
			</Fragment>
		)
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	youtubegioxem: state.youtubegioxem,
});
const mapDispatchToProps = { updateYGX, addYGX, loadPriceYGX, add, updateYoutube, getConfigMoDong }

export default connect(mapStateToProps, mapDispatchToProps)(taotientrinh);