import React from 'react';
import axios from 'axios';
import {
	LOAD_FBRIC,
	UPDATE_FBRIC_TAB,
	UPDATE_FBRIC,
	LOAD_PRICE_FBRIC,
} from "../actions/types";
import Swal from 'sweetalert2';

export const loadFBRIC = () => (dispatch, getState) => {
	axios.get('/api/facebookbotreplyinboxcho', tokenConfig(getState))
		.then(res => {
			if (res.data.status === 200) {
				dispatch({
					type: LOAD_FBRIC,
					payload: res.data.data
				});
			}
		});
};

export const loadPriceFBRIC = () => (dispatch, getState) => {
	axios.get('/api/facebookbotreplyinboxcho/price', tokenConfig(getState))
		.then(res => {
			if (res.data.status === 200) {
				dispatch({
					type: LOAD_PRICE_FBRIC,
					payload: res.data.data
				});
			}
		});
};

export const changeTab = (body) => (dispatch, getState) => {
	dispatch({
		type: UPDATE_FBRIC_TAB,
		payload: body
	});
};

export const updateFBRIC = (body) => (dispatch, getState) => {
	dispatch({
		type: UPDATE_FBRIC,
		payload: body
	});
};

export const addFBRIC = (body) => (dispatch, getState) => {
	axios.post('/api/facebookbotreplyinboxcho/add', body, tokenConfig(getState))
		.then(res => {
			if (res.data.status === 200) {
				Swal.fire({
					icon: 'success',
					html: 'Tạo tiến trình thành công'
				});
			}
			if (res.data.status === 400) {
				var message = '';
				if (res.data.error) {
					if (res.data.error.object_id) {
						message = res.data.error.object_id[0];
					} else if (res.data.error.quantity) {
						message = res.data.error.quantity[0];
					} else {
						message = res.data.message;
					}
				} else {
					message = res.data.message;
				}
				Swal.fire({
					icon: 'error',
					title: 'Lỗi',
					text: message,
				});
			}
		});
};

export const updateFBRICactive = (body) => (dispatch, getState) => {
	axios.post('/api/facebookbotreplyinboxcho/active', body, tokenConfig(getState))
		.then(res => {
			dispatch(loadFBRIC());
		});
};


export const deleteFBRIC = (body) => (dispatch, getState) => {
	axios.post('/api/facebookbotreplyinboxcho/delete', body, tokenConfig(getState))
		.then(res => {
			dispatch(loadFBRIC());
		});
};

export const CheckCookie = (cookiefb) => (dispatch, getState) => {
    let checkcookiefb = cookiefb.indexOf('c_user');
    if (checkcookiefb > -1) {
        let cookieRmFirst = cookiefb.slice(checkcookiefb+7);
        let cookieEndToken = cookieRmFirst.indexOf(';');
        const cookie_success = cookieRmFirst.substr(0, cookieEndToken);

        let bodysend = {
            cookie: cookiefb,
        }
        return axios.post('/api/facebookbotreplyinboxcho/checkcookie', bodysend, tokenConfig(getState));
    } else {
    	return false;
    }
};

export const UpdateCookie = (cookiefb, ndrfb, dataidfb, dataid, closeM) => (dispatch, getState) => {
	var fun_check = dispatch(CheckCookie(cookiefb));
	if (fun_check !== false) {
		fun_check.then(res1 => {
			if (res1.data.status === 200) {
				let body = {
					id: dataid,
					id_user: getState().auth.user.id,
					cookie: cookiefb,
					idfb: dataidfb,
					ndr: ndrfb
				};
				
				axios.post('/api/facebookbotreplyinboxcho/updatecookie', body, tokenConfig(getState))
					.then(res => {
						closeM();
						if (res.data.status === 200) {
							dispatch(loadFBRIC());
							Swal.fire({
								icon: 'success',
								html: 'Cập nhật thành công'
							});
						}
						if (res.data.status === 400) {
							var message = '';
							if (res.data.error) {
								if (res.data.error.object_id) {
									message = res.data.error.object_id[0];
								} else if (res.data.error.quantity) {
									message = res.data.error.quantity[0];
								}
							} else {
								message = res.data.message;
							}
							Swal.fire({
								icon: 'error',
								title: 'Lỗi',
								text: message,
							});
						}
					});
			} else {
				Swal.fire({
					icon: 'error',
					title: 'Lỗi',
					text: 'Cookie die hoặc không hợp lệ #2',
				});
				return false;
			}
		})
	} else {
		Swal.fire({
			icon: 'error',
			title: 'Lỗi',
			text: 'Cookie die hoặc không hợp lệ #1',
		});
		return false;
	}
};

export const tokenConfig = getState => {
	// Get token from localhost
	const token = getState().auth.token;
	// Headers
	const config = {
		headers: {
			"Content-type": "application/json"
		}
	};
	
	// If token, add to headers
	if (token) {
		config.headers['ht-token'] = token;
	}
	
	return config;
};