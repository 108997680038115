import React, { Component, Fragment } from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import { connect } from 'react-redux';
import {
	Modal,
	ModalBody,
	ModalFooter
} from 'reactstrap';
import Moment from 'react-moment';
import Swal from 'sweetalert2';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { laylichsumua} from '../../../../actions/adminPageAction';


class History extends Component {
	componentDidMount(){
        this.props.laylichsumua(50);
	}
	state = {
		isOpenModalAdd: false,
		dataItemSelect: {},
		priceChange: 0,
		viewall: 0,
	}

	openModalAddPrice = (status, data = {}) => {
		this.setState({
			isOpenModalAdd: !this.state.isOpenModalAdd,
			dataItemSelect: data,
		});
	}

	saveEditOrder = (e) => {
		if (this.state.dataItemSelect.price_per >= this.state.priceChange) {
			Swal.fire({
				icon: 'error',
				title: 'Lỗi',
				text: 'Giá tăng phải cao hơn giá hiện tại',
			});
			return false;
		} else {
			this.props.updatePriceOrBuffSub(this.state.dataItemSelect, this.state.priceChange);
		}
	}

	changeValuePrice = (e) => {
		this.setState({
			[e.target.name] : e.target.value
		});
	}
	getListByLimit = (e) => {
		let valueChange = e.target.value;
		if (valueChange !== '') {
			this.props.laylichsumua(valueChange);
		}
	}
	formatMoney(amount, decimalCount = 0, decimal = ".", thousands = ",") {
		  try {
		    decimalCount = Math.abs(decimalCount);
		    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

		    const negativeSign = amount < 0 ? "-" : "";

		    let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
		    let j = (i.length > 3) ? i.length % 3 : 0;

		    return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
		  } catch (e) {
		    console.log(e)
		  }
	}
	convertDate(date_create){
		var date = new Date(date_create);
		var date_cv = date.getDate() + '-' + (date.getMonth()+1) + '-'+date.getFullYear()+ ' '+date.getHours()+':'+date.getMinutes();
		return date_cv;
	}
 	render() {
		const columns = [
			{
			  dataField: '',
			  text: 'STT',
		   	  sort: true,
			  formatter: (cell, row, rowIndex) =>
			    <span>{rowIndex+1}</span>
			},{
			  text: 'username',
			  dataField: 'Tên tài khoản',
			  sort: true,
			  formatter: (cell, row) =>
			    <span> {this.props.auth.user.username} </span>
			},
			{
			  dataField: 'noidung',
			  text: 'Nội dung',
			  sort: true,
			  formatter: (cell, row) =>
            	<span> {cell.substring(0, 45)}...</span>
			},
			{
			  dataField: 'tien_nap',
			  text: 'Tiền nạp',
			  sort: true,
			  formatter: (cell, row) =>
			    <span> {this.formatMoney(row.tien_nap)} VND</span>
			},
			{
			  dataField: 'khuyen_mai',
			  text: 'Khuyến mãi',
			  sort: true,
			  formatter: (cell, row) =>
			    <span> {row.khuyen_mai}%</span>
			},
			{
			  dataField: 'thoigian_nap',
			  text: 'Thời gian nạp',
			  sort: true,
			  formatter: (cell, row) => 
			  	<Fragment>
		    	 <Moment format="DD-MM-YYYY HH:mm:ss">{parseInt(cell)}</Moment>
		    	</Fragment>
			}
		];

		var {history_depost} = this.props.payment;
    	return (
    		<Fragment>
    			<div className="row mb-4">
					<div className="col-md-3">
						<select onChange={(e) => {this.getListByLimit(e)}} className="form-control">
							<option value="">Số lịch sử hiển thị tối đa</option>
						    <option value="50">50</option>
						    <option value="100">100</option>
						    <option value="200">200</option>
						    <option value="500">500</option>
						    <option value="1000">1000</option>
						</select>
					</div>
				</div>
				<BootstrapTable
			 	keyField='id'
			 	bodyStyle={ { border: 'none' } }
			 	headerStyle={ { border: 'red 1px solid' } }
			  	data={ history_depost }
			  	striped
			  	hover
			  	wrapperClasses="table-responsive"
			  	pagination={ paginationFactory() }
			   	columns={ columns } />
			</Fragment>
		);
  	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	payment: state.payment
});

export default connect(mapStateToProps, {laylichsumua})(History);
