import axios from 'axios';
import Swal from 'sweetalert2';
var fileDownload = require('js-file-download');

export const getDataOrder = (limit = 50, viewall = 0) => (dispatch, getState) => {
	dispatch({
		type: 'LOADING_BLOCK_HISTORY_ORDER',
		payload: true
	});
	let paramUrl = '';
	if (viewall === 1) {
		paramUrl += '&viewall=1'
	}
	axios.get('/api/refund-order/?limit='+limit+paramUrl, tokenConfig(getState))
	.then(res => {
		dispatch({
			type: 'LOADING_BLOCK_HISTORY_ORDER',
			payload: false
		})
		if (res.data.status === 200) {
			dispatch({
				type: 'LOADING_DATA_HISTORY_ORDER',
				payload: res.data.data
			});
		}
	});
};

export const acceptRefund = (id, value, closeM) => (dispatch, getState) => {
	dispatch({
		type: 'LOADING_BLOCK_HISTORY_ORDER',
		payload: true
	});
	let bodysend = {
		id: id,
		value: value,
	};
	axios.post('/api/refund-order/acceptrefund', bodysend, tokenConfig(getState))
		.then(res => {
			dispatch({
				type: 'LOADING_BLOCK_HISTORY_ORDER',
				payload: false
			})
			dispatch(getDataOrder());
			closeM();
			if (res.data.status === 200) {
				Swal.fire({ icon: 'success', title: 'Thành công', text: 'Hoàn tiền thành công' });
			} else {
				Swal.fire({ icon: 'error', title: 'Lỗi', text: res.data.message });
			}
		});
};

export const downloadFile = () => (dispatch, getState) => {
	axios.get('/api/download-backup', tokenConfig(getState))
		.then(res => {
			fileDownload(res.data.data, 'log.txt');
		});
};

export const tokenConfig = getState => {
	// Get token from localhost
	const token = getState().auth.token;
	
	// Headers
	const config = {
		headers: {
			"Content-type": "application/json"
		}
	};
	
	// If token, add to headers
	if (token) {
		config.headers['ht-token'] = token;
	}
	
	return config;
};