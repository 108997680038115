import React, { Component, Fragment } from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import { connect } from 'react-redux';
import { getListBuffSub, cancelOrderBuffsub, updatePriceOrBuffSub, searchDataBuffSub } from '../../../actions/buffsub_slow';
import {
	Modal,
	ModalBody,
	ModalFooter
} from 'reactstrap';
import Moment from 'react-moment';
import Swal from 'sweetalert2';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ModalEditAdmin from './modalEditAdmin';
import { RiExchangeDollarLine } from "react-icons/ri";
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
const { SearchBar } = Search;

class Nhatkyorder extends Component {
	componentDidMount(){
        this.props.getListBuffSub(0, 1, '');
	}

	state = {
		isOpenModalAdd: false,
		dataItemSelect: {},
		priceChange: 0,
		limit: 0,
		viewall: 0,
		page: 1,
		obj_search: "",
		isOpenModalEditAdmin: false,
	}

	openModalAddPrice = (status, data = {}) => {
		this.setState({
			isOpenModalAdd: !this.state.isOpenModalAdd,
			dataItemSelect: data,
		});
	}

	saveEditOrder = (e) => {
		if (this.state.dataItemSelect.price_per >= this.state.priceChange) {
			Swal.fire({
				icon: 'error',
				title: 'Lỗi',
				text: 'Giá tăng phải cao hơn giá hiện tại',
			});
			return false;
		} else {
			this.props.updatePriceOrBuffSub(this.state.dataItemSelect, this.state.priceChange);
		}
	}

	changeValuePrice = (e) => {
		this.setState({
			[e.target.name] : e.target.value
		});
	}

	searchDataBuff = (e) => {
		if (e.key === 'Enter') {
	      	let value_search = e.target.value;
	      	this.props.searchDataBuffSub(value_search);
	    }
	}

	onChange = (e) => {
		this.setState({
			"obj_search": e.target.value,
			"page": 1
		});
	}
    getListByLimit = (page, type_button) => {
        var valueChange = parseInt(page);
		if (type_button == "prev") {
			valueChange -= 1;
			if (valueChange <= 0) {
				valueChange = 1;
			}
		}
		if (type_button == "next") {
			valueChange += 1;
		}
		this.setState({
			page: valueChange,
		});
		this.props.getListBuffSub(this.state.viewall, valueChange, this.state.obj_search);
    }
	
    getListByView = (e) => {
        let valueChange = parseInt(e);
		this.setState({
			viewall: valueChange,
			"page": 1,
		});
		this.props.getListBuffSub(valueChange, 1, this.state.obj_search);
    }
	
    getListBySearch = (e) => {
		this.setState({
			"page": 1,
		});
		this.props.getListBuffSub(this.state.viewall, 1, this.state.obj_search);
    }

	openModalEditAdmin = (data = {}) => {
		this.setState({
			...this.state,
			isOpenModalEditAdmin: !this.state.isOpenModalEditAdmin,
			dataItemSelect: data,
		})
	}

	calBwDateToNow = (date_check = null) => {
		if (Number.isInteger(date_check)) {
			let date_now = Math.floor(Date.now() / 1000);
			let calDateDay = (date_now - date_check)/86400;
			if (calDateDay > 1) {
				return Math.ceil(calDateDay) + ' ngày trước';
			} else {
				let calDatehour = (date_now - date_check)/3600;
				if (calDatehour < 0.6) {
					return Math.ceil(calDatehour*100) + ' phút trước';
				} else {
					return Math.ceil(calDatehour) + ' giờ trước';
				}
			}
		}
	}

 	render() {
		function formatBaoHanh(cell, row) {
			if (cell === 0) {
				return (
					<span className="badge badge-warning">Không</span>
				)
			} else {
				return (
					<span className="badge badge-success">Có</span>
				)
			}
		}
		function filterValueBaoHanh(cell, row) {
			if (cell === 0) {
				return ("Không")
			} else {
				return ("Có")
			}
		}
		function formatTrangThai(cell, row) {
			var message = '';
			if (cell === 0) {
				return (
					<span className="badge badge-primary"><i className="fas fa-check mr-1"></i>Hoàn thành</span>
				);
			} else if (cell === 1) {
				return (
					<span className="badge badge-success"><i className="fas fa-sync-alt mr-1"></i>Đang chạy</span>
				);
			} else if (cell === 2) {
				return (
					<span className="badge badge-danger"><i className="fas fa-ban mr-1"></i>ID die không thể chạy</span>
				);
			} else if (cell === 3) {
				return (
					<span className="badge badge-warning"><i className="fas fa-times mr-1"></i>Hủy đơn</span>
				);
			} else if (cell === 5) {
				return (
					<span className="badge badge-info"><RiExchangeDollarLine size={12} className="mr-1" />Hoàn tiền</span>
				);
			} else {
				return ('');
			}
		}
		function filterValueTrangThai(cell, row) {
			if (cell === 0) {
				return ('Hoàn thành');
			} else if (cell === 1) {
				return ('Đang chạy');
			} else if (cell === 2) {
				return ('ID die không thể chạy');
			} else if (cell === 3) {
				return ('Hủy đơn');
			} else if (cell === 5) {
				return ('Hoàn tiền');
			} else {
				return ('');
			}
		}
		
		function formatCheckTime(cell, row) {
			let date_now = Math.floor(Date.now() / 1000);
			let calDateDay = (cell - date_now);
			if (calDateDay >= 0) {
				if (calDateDay/86400 < 1) {
					return Math.floor(calDateDay/3600) + ' giờ';
				}
				return (
					<Fragment>
						<Moment diff={date_now*1000} unit="days">{cell*1000}</Moment> ngày
					</Fragment>
				);
			} else {
				return <span className="badge badge-danger">Hết hạn</span>
			}
		}
		function filterValueCheckTime(cell, row) {
			let date_now = Math.floor(Date.now() / 1000);
			let calDateDay = (cell - date_now);
			if (calDateDay >= 0) {
				if (calDateDay/86400 < 1) {
					return Math.floor(calDateDay/3600) + ' giờ';
				}
				var diffDays = Math.floor(calDateDay / (60 * 60 * 24)) + ' ngày';
				return diffDays;
			} else {
				return ('Hết hạn');
			}
		}
		function formatType(cell, row) {
			if (cell === 1) {
				return ('Basic');
			}
			if (cell === 2) {
				return ('V3');
			}
			if (cell === 3) {
				return ('V1');
			}
			if (cell === 4) {
				return ('V2');
			}
		}
		const columns = [{
		  dataField: '',
		  text: 'STT',
	   	  sort: true,
		  formatter: (cell, row, rowIndex) =>
		    <span>{rowIndex+1}</span>
		}, {
		  text: 'Thao tác',
		  dataField: '-',
		  style: {
			'white-space' : 'nowrap'
		  },
		  formatter: (cell, row) => 
		  	<Fragment>
				{ this.props.auth.user.type === 1 ?
					<button type="button" onClick={() => {this.openModalEditAdmin(row)}} className="btn btn-hero-success text-center p-2 mr-1"><i className="fa fa-fw fa-pen"></i></button>
					: ''
				}
				{/* row.status !== 3 && row.status != 0 && row.time_expired_format > Math.floor(Date.now() / 1000) ?
					<Fragment>
						<button type="button" onClick={() => {this.openModalAddPrice(true, row)}} name="tang_them_gia" className="btn btn-hero-primary text-center p-2 mr-1"><i className="fas fa-hand-holding-usd"></i></button>
					</Fragment>
					: ''
				 */}
				{row.status === 2 || row.status === 1 || (row.time_expired_format < Math.floor(Date.now() / 1000) && row.status !== 3 && row.status !== 0)?
					<Fragment>
						<button type="button" onClick={() => {this.props.cancelOrderBuffsub(row.id)}} name="huy_order" className="btn btn-hero-danger text-center p-2 mr-1"><i className="fa fa-fw fa-trash"></i></button>
					</Fragment>
					: ''
				}
	    	</Fragment>
		},{
		  dataField: 'object_id',
		  text: 'ID seeding',
		  sort: true,
		  formatter: (cell, row) => 
		  	<Fragment>
	    	 	<a href={"https://facebook.com/"+cell} target="_blank" rel="noopener noreferrer">{cell}</a>
	    	</Fragment>
		},{
		  text: 'Type',
		  dataField: 'type_run',
		  sort: true,
		  classes: 'text-center',
		  formatter: formatType,
		  filterValue: formatType
		},{
		  text: 'Bảo hành',
		  dataField: 'is_warranty',
		  sort: true,
		  classes: 'text-center',
		  formatter: formatBaoHanh,
		  filterValue: filterValueBaoHanh
		},{
		  dataField: 'subscribers',
		  text: 'Start',
		  sort: true
		},{
		  dataField: 'friends',
		  text: 'Bạn bè',
		  sort: true
		},{
		  dataField: 'quantity',
		  text: 'Số lượng',
		  sort: true
		},{
		  dataField: 'price_per',
		  text: 'Giá',
		  sort: true
		},{
		  dataField: 'prices',
		  text: 'Tổng tiền',
		  sort: true
		},{
		  dataField: 'count_success',
		  text: 'Đã chạy',
		  sort: true,
		},{
		  dataField: 'created_at',
		  text: 'Khởi tạo',
		  sort: true,
		  formatter: (cell, row) => 
		  	<Fragment>
	    	 	{this.calBwDateToNow(cell)}
	    	</Fragment>
		},{
		  dataField: 'time_expired_format',
		  text: 'Hết hạn',
		  sort: true,
		  formatter: formatCheckTime,
		  filterValue: filterValueCheckTime
		},{
		  text: 'username',
		  dataField: 'username',
		  sort: true,
		  classes: 'notranslate',
		},{
		  dataField: 'status',
		  text: 'Trạng thái',
		  sort: true,
		  formatter: formatTrangThai,
		  filterValue: filterValueTrangThai
		}];

		const expandRow = {
			renderer: (row) => (
				<Fragment>
					<div className="row">
						<div className="col-lg-4 mt-2">
						    {/*<h6 className="mb-0 font-14">Lọc nâng cao</h6>
						    <div className="row mt-3">
								{ row.gender == '0' ?
									<div className="col-4">
										<h6 className="mb-0 font-14"><i className="fas fa-venus-mars font-16"></i> Tất cả</h6>
									</div>
								: '' }
								{ row.gender == '1' ?
									<div className="col-4">
										<h6 className="mb-0 font-14"><i className="fas fa-venus-mars font-16"></i> Nữ</h6>
									</div>
								: '' }
								{ row.gender == '2' ?
									<div className="col-4">
										<h6 className="mb-0 font-14"><i className="fas fa-venus-mars font-16"></i> Nam</h6>
									</div>
								: '' }
					    		{ row.age_min !== null ? 
							        <div className="col-4">
							            <h6 className="mb-0 font-14"><i className="fas fa-birthday-cake"></i> {row.min_age} - {row.max_age}</h6>
							        </div>
			    				: ''
					    		}
					    		{ row.friend_min !== null ? 
							        <div className="col-4">
							            <h6 className="mb-0 font-14"><i className="fas fa-user-friends"></i> {row.friend_min} - {row.friend_max}</h6>
							        </div>
			    				: ''
					    		}
						    </div>*/}
						</div>
						<div className="col-lg-8 row">
							<div className="col-lg-4">
								<div className="form-group">
									<label htmlFor="example-text-input">Nội dung</label>
									<textarea className="form-control" id="nd" name="nd" placeholder="" rows="2" ></textarea>
								</div>
							</div>
							<div className="col-lg-4">
								<div className="form-group">
									<label htmlFor="example-text-input">Ghi chú</label>
									<textarea className="form-control" id="gc" name="gc" placeholder="" rows="2" defaultValue={row.notes}></textarea>
								</div>
							</div>
							{/* <div className="col-lg-4 d-flex align-items-center justify-content-center">
								<div className="form-group">
									{row.status !== 3 && row.status != 0 && row.time_expired_format > Math.floor(Date.now() / 1000) ?
										<Fragment>
											<button type="button" onClick={() => {this.openModalAddPrice(true, row)}} name="tang_them_gia" className="btn btn-block btn-sm btn-rounded btn-hero-primary">Tăng thêm giá</button>
										</Fragment>
										: ''
									}
									{row.status === 2 || row.status === 1 || (row.time_expired_format < Math.floor(Date.now() / 1000) && row.status !== 3 && row.status !== 0)?
										<Fragment>
											<button type="button" onClick={() => {this.props.cancelOrderBuffsub(row.id)}} name="huy_order" className="btn btn-block btn-sm btn-rounded btn-hero-light">Hủy Order</button>
										</Fragment>
										: ''
									}
									{ this.props.auth.user.type === 1 ?
										<button type="button" onClick={() => {this.openModalEditAdmin(row)}} className="btn btn-block btn-sm btn-rounded btn-hero-success">Cài đặt admin</button>
										: ''
									}
								</div>
							</div> */}
						</div>
					</div>
				</Fragment>
			),
			showExpandColumn: true,
			expandColumnPosition: 'right',
			expandHeaderColumnRenderer: ({ isAnyExpands }) => {
				if (isAnyExpands) {
					return <i className="fas fa-caret-up"></i>;
				}
				return <b><i className="fas fa-caret-down"></i></b>;
			},
			expandColumnRenderer: ({ expanded }) => {
				if (expanded) {
					return (
						<b><i className="fas fa-caret-up"></i></b>
					);
				}
				return (
					<b><i className="fas fa-caret-down"></i></b>
				);
			}
		};

		const {listBuffSub} = this.props.buffsub;
		const {priceChange, dataItemSelect, isOpenModalEditAdmin} = this.state;

    	return (
    		<Fragment>
    			{/*<div className="row mb-4">
    				<div className="col-md-5">
						{ this.props.auth.user.type === 1 ?
							<Fragment>
								<button onClick={() => {this.props.getListBuffSub(1);this.setState({...this.state,viewall: 1})}} type="button" className="btn btn-primary mr-1">Xem tất cả</button>
								<button onClick={() => {this.props.getListBuffSub();this.setState({...this.state,viewall: 1})}} type="button" className="btn btn-primary mr-1">Xem riêng</button>
							</Fragment>
						: ''}
					</div>
					<div className="col-md-3">
						<select onChange={(e) => {this.getListByLimit(e)}} className="form-control">
							<option value="">Số lịch sử hiển thị tối đa</option>
						    <option value="50">50</option>
						    <option value="100">100</option>
						    <option value="200">200</option>
						    <option value="500">500</option>
						    <option value="1000">1000</option>
						</select>
					</div>
					
					<div className="col-md-4">
	        			<input onKeyDown={(e) => {this.searchDataBuff(e)}} type="text" name="search_user" className="form-control" placeholder="Nhập id tìm kiếm"/>
	        		</div>
				</div>*/}
				
				<ToolkitProvider keyField="id" data={ listBuffSub } columns={ columns } search >
					{
						props => (
							<div>
								<SearchBar { ...props.searchProps } />
								<div className="row mb-4">
									<div className="col-md-12">
										{ this.props.auth.user.type === 1 ?
											<Fragment>
												<button onClick={(e) => {this.getListByView(1)}} name="view" value="1" type="button" className="btn btn-primary mr-1">Xem tất cả</button>
												<button onClick={(e) => {this.getListByView(0)}} name="view" value="0" type="button" className="btn btn-primary mr-1">Xem riêng</button>
											</Fragment>
										: ''}
										{/* <select onChange={(e) => {this.getListByLimit(e)}} className="form-control custom-select select-light col-md-3 custom-limit_pagi">
											<option value="0">Số lịch sử hiển thị (0-500)</option>
											<option value="1">500-1000</option>
											<option value="2">1000-1500</option>
											<option value="3">1500-2000</option>
											<option value="4">2000-2500</option>
											<option value="5">2500-3000</option>
											<option value="7">3000-3500</option>
											<option value="8">3500-4000</option>
											<option value="9">4500-5000</option>
										</select> */}
										<button onClick={(e) => {this.getListByLimit(this.state.page, "prev")}} name="page" value={this.state.page} type="button" className="btn btn-primary mr-1">{"<"}</button>
										<button onClick={(e) => {this.getListByLimit(this.state.page, "next")}} name="page" value={this.state.page} type="button" className="btn btn-primary mr-1">{">"}</button>
										<button onClick={(e) => {this.getListBySearch(e)}} name="view" value="0" type="button" className="btn btn-primary mr-1">Tìm kiếm</button>
										<input  onChange={this.onChange} value={this.state.obj_search}  name="obj_search" type="text" className="form-control mr-1" style={{"width": "100px", "display": "inline-block"}}/>
									</div>
								</div>
								<BootstrapTable
									{ ...props.baseProps }
									bodyStyle={ { border: 'none' } }
									headerStyle={ { border: 'red 1px solid' } }
									striped
									hover
									expandRow={ expandRow }
									wrapperClasses="table-responsive"
									pagination={ paginationFactory() } />
							</div>
						)
					}
				</ToolkitProvider>

			   	<Modal className="bt-customWidth-dtable" toggle={(e) => {this.openModalAddPrice( e)}} isOpen={this.state.isOpenModalAdd}>
					<div className="modal-header">
						<p className="mb-0 text-dark">Tăng giá Job ID {dataItemSelect.object_id}</p>
						<button onClick={(e) => {this.openModalAddPrice(e)}} type="button" className="close">×</button>
					</div>
					<ModalBody>
						<h6>Giá cũ là: <span className="font-bold">{dataItemSelect.price_per}</span> Coin. Bạn muốn tăng lên ?</h6>
						<div className="col-md-12 px-0">
							<input value={priceChange} onChange={(e)=>{this.changeValuePrice(e)}} name="priceChange" type="text" placeholder="Nhập giá cần tăng" className="form-control form-control-line mt-2" />
						</div>
						{ priceChange > dataItemSelect.price_per
						?
							<div>
							    <h6 className="mt-3">
							    	Bạn muốn tăng giá từ 
							    	<span className="font-bold"> {dataItemSelect.price_per}</span> lên <span className="font-bold">{priceChange}</span> Coin ?
						    	</h6>
							    <h6 className="mt-3">
							    	Phí tăng giá thành công: 
							    	<span className="font-bold"> 5,000</span> Coin
						    	</h6>
							    <h6 className="mt-3">
							    	Tổng phí tăng giá cho <span className="font-bold">{dataItemSelect.quantity}
							    	<span className="text-uppercase text-danger"> follow </span></span>
							    	là: <span className="font-bold text-info">{5000 + (priceChange - dataItemSelect.price_per)*dataItemSelect.quantity}</span> Coin
						    	</h6>
							</div>
						: ''
						}
					</ModalBody>
					<ModalFooter>
						<button type="button" className="btn btn-danger" onClick={(e) => {this.openModalAddPrice(e)}} >Huỷ</button>
						<button type="button" className="btn btn-primary" onClick={(e) => {this.saveEditOrder(e)}}>Đồng ý</button>
					</ModalFooter>
				</Modal>
				{(this.props.auth.user.type === 1 && isOpenModalEditAdmin) ? <ModalEditAdmin openModal={this.openModalEditAdmin} isOpenModal={isOpenModalEditAdmin} dataItemSelect={dataItemSelect} /> : '' }
			</Fragment>
		);
  	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	buffsub: state.buffsub_slow,
});

export default connect(mapStateToProps, {getListBuffSub, cancelOrderBuffsub, updatePriceOrBuffSub, searchDataBuffSub})(Nhatkyorder);
