import React, { Component, Fragment } from 'react';
import {
  BrowserRouter as Router,
  // Switch,
  // Route,
  Link
} from "react-router-dom";
import {
	// Button,
	// Modal,
	// ModalHeader,
	// ModalBody,
	// Form,
	// FormGroup,
	// Label,
	// Input,
	// NavLink,
	Alert
} from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { register } from '../../actions/authActions';
import { clearErrors } from '../../actions/errorActions';
import VwxYEYx from '../../iconlanding/4/VwxYEYx.png';
import parse from 'html-react-parser';

class RegisterModal extends Component {
	state = {
		// modal: false,
		username: '',
		email: '',
		password: '',
		repeatpassword: '',
		facebook_id: '4',
		phone: '',
		msg: null,
	}
	
	static propTypes =  {
		auth: PropTypes.object.isRequired,
		error: PropTypes.object.isRequired,
		register: PropTypes.func.isRequired,
		clearErrors: PropTypes.func.isRequired
	}
	
	componentDidUpdate(prevProps) {
		const { error } = this.props;
		if (error !== prevProps.error) {
			// Check for register error
			if (error.id === 'REGISTER_FAIL') {
				this.setState({ msg: error.msg.msg });
			} else {
				this.setState({ msg: null });
			}
		}
	}
	
	clearAlert = () => {
		// Clear errors
		this.props.clearErrors();
	}
	
	onChange = e => {
		this.setState({ [e.target.name]: e.target.value });
	};
	
	onSubmit = e => {
		e.preventDefault();
		
		const { username, email, password, repeatpassword, facebook_id, phone } = this.state;
		
		// Create user object
		const newUser = {
			username,
			email,
			password,
			repeatpassword,
			facebook_id,
			phone
		};
		// console.log(newUser);
		// Attempt to register
		this.props.register(newUser);
	};
	
	render() {
		let loading = this.props.auth.loading;
		const domainSite = window.location.hostname;
		var config_website = {};
		if (localStorage.getItem("confignavbar") != null) {
			config_website = JSON.parse(localStorage.getItem("confignavbar"));
		} else {
			config_website = this.props.auth.config_website;
		}
		return (
			<Router>
				<Fragment>
					<div className="landingcus7" style={{"zoom": "122%"}}>
						<div id="signup" className="signup unauthorized home-body light">
							<nav className="navbar navbar-default navbar-static-top">
								<div className="container">
									<div className="navbar-header" style={{"marginTop": "38px"}}>
										<a className="link-fx text-white font-w700 fontSize-h3 site-logo site-title" href="/" style={{"padding": "0", "margin": "25px"}}>
										  <span className="text-white text-uppercase">{domainSite.slice(0, domainSite.lastIndexOf('.'))}</span>
										  <span className="text-white text-uppercase">{domainSite.indexOf('.') > -1 ? domainSite.slice(domainSite.lastIndexOf('.')) : ''}</span>
										</a>
									</div>
									<div id="navbar" className="navbar-collapse collapse">
										<ul className="nav navbar-nav navbar-right"><li><i id="nightmode" className="fa fa-moon"></i></li>
											<li className="nav-item menu_item_13 " id="menu_item_13">
												<Link to="/services" onClick={this.props.action_service}>
													Dịch vụ
												</Link>
											</li>
											<li className="nav-item active menu_item_13 " id="menu_item_13">
												<Link to="/" onClick={this.props.action_login}>
													Đăng nhập
												</Link>
											</li>
											<li className="nav-item  menu_item_14 " id="menu_item_14">
												<Link to="#">
													Đăng ký
												</Link>
											</li>
										</ul>
									</div>
								</div>
							</nav>
							<div className="home-head">
								<div className="home-head-bg"></div>
								<div className="home-head-content">
									<div className="container" style={{"position": "relative"}}>
										<div className="row">
											<div className="col-lg-2 col-12 d-none d-lg-block hidden-smm"></div>
											<div className="col-lg-5 col-lg-offset-1">
												<div className="loginSide">
													<div className="round"></div>
													<div className="login-box">
														{loading ? (
															<div className="preloader">
																<div className="spinner-grow spinner-grow-sm text-primary" role="status" style={{ "marginTop": "50%" }}>
																  <span className="sr-only"></span>
																</div>
																<div className="spinner-grow spinner-grow-sm text-danger" role="status" style={{ "marginTop": "50%" }}>
																  <span className="sr-only">.</span>
																</div>
																<div className="spinner-grow spinner-grow-sm text-warning" role="status" style={{ "marginTop": "50%" }}>
																  <span className="sr-only"></span>
																</div>
															</div>
														):('')}
														{this.state.msg ? (
															<div className="alert alert-warning d-flex align-items-center justify-content-between" role="alert" >
															  <div className="flex-fill mr-3">
																<p className="mb-0">{parse(this.state.msg)}</p>
															  </div>
															  <div className="flex-00-auto">
																<i className="fa fa-fw fa-exclamation-circle"></i>
															  </div>
															</div>
														  ) : null}
														  
														<form className="registration-form" onSubmit={this.onSubmit} method="POST" >
															<div className="fc-home">
																<label htmlFor="username"><span className="label-text">Tài khoản</span></label>
																<input name="username" placeholder="Nhập tài khoản" required="" id="username" type="text" onChange={this.onChange} />
															</div>
															<div className="fc-home">
																<label htmlFor="email"><span className="label-text">Email</span></label>
																<input name="email" placeholder="Nhập email" required="" id="email" type="email" onChange={this.onChange} />
															</div>
															<div className="fc-home" >
																<label htmlFor="phone"><span className="label-text">Số điện thoại</span></label>
																<input name="phone" placeholder="Nhập số điện thoại" required="" id="phone" type="text" onChange={this.onChange} />
															</div>
															<div className="fc-home">
																<label htmlFor="password"><span className="label-text">Mật khẩu</span></label>
																<input name="password" placeholder="Nhập mật khẩu" required="" id="password" type="password" onChange={this.onChange} />
															</div>
															<div className="fc-home">
																<label htmlFor="repeatpassword"><span className="label-text">Xác nhận mật khẩu</span></label>
																<input name="repeatpassword" placeholder="Nhập lại mật khẩu" required="" id="repeatpassword" type="password" onChange={this.onChange} />
															</div>
															<button type="submit" name="login_btn" className="btn btn-secondary btn-lg btn-block" style={{"marginTop":"16px"}}>Đăng ký</button>
															<div className="text-center m-t">
																<span>Bạn đã có tài khoản?</span>
																<Link to="/" onClick={this.props.action_login} className="btn btn-default m-l" style={{"marginLeft": "5px"}}>
																	Đăng nhập
																</Link>
															</div>
														</form>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</Fragment>
			</Router>
		);
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	error: state.error
})

export default connect(mapStateToProps, { register, clearErrors })(RegisterModal);