
const initialState = {
	data_list: [],
	loadding: false,
	dataprice: {
		0: {
			prices_web: 0
		},
		1: {
			prices_web: 0
		},
		2: {
			prices_web: 0
		},
		3: {
			prices_web: 0
		},
		4: {
			prices_web: 0
		},
		5: {
			prices_web: 0
		},
		99: {
			prices_web: 0
		},
	},
	giatien: 0,
	facebookbuffviewvideo_3s_mo_dong: 1,
	facebookbuffviewvideo_1p_mo_dong: 1,
	facebookbuffviewvideo_3snhanh_mo_dong: 1,
	facebookbuffviewvideo_sv4_mo_dong: 1,
	facebookbuffviewvideo_sv5_mo_dong: 1,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case 'LOADING_DATA_FB_BUFFVIEWSVIDEO':
    	return {
			...state,
			loadding: action.payload
		};
	case 'LIST_DATA_FB_BUFFVIEWSVIDEO':
    	return {
			...state,
			data_list: action.payload
		};
	case 'DATA_PRICE_IS_FUNCTION_BFVIEWVIDEO_YTB':
		return {
			...state,
			dataprice: merDataPricePage(action.payload)
		}
	case 'DELETE_FB_BUFFVIEWSVIDEO':
		return {
			...state,
			data_list: state.data_list.map(
	           (content, i) => content.id === action.payload ? {...content, status: 3} : content
	       	)
		};
	case 'CONFIG_MO_DONG_BUFFVIEWSVIDEO':
		return {
			...state,
			facebookbuffviewvideo_3s_mo_dong: action.payload.facebookbuffviewvideo_3s_mo_dong,
			facebookbuffviewvideo_1p_mo_dong: action.payload.facebookbuffviewvideo_1p_mo_dong,
			facebookbuffviewvideo_3snhanh_mo_dong: action.payload.facebookbuffviewvideo_3snhanh_mo_dong,
			facebookbuffviewvideo_sv4_mo_dong: action.payload.facebookbuffviewvideo_sv4_mo_dong,
			facebookbuffviewvideo_sv5_mo_dong: action.payload.facebookbuffviewvideo_sv5_mo_dong,
		};
    default:
      return state;
  }
}

function merDataPricePage(data) {
	const dataOld = initialState.dataprice;
	let dataNew = {
		...dataOld
	}
	if (data.length > 0) {
		data.forEach(function(v, i){
			if (v.name_table === 'fbbuffviewsvideo_0') {
				dataNew[0] = v;
				initialState.giatien = v.prices_web;
			} else if (v.name_table === 'fbbuffviewsvideo_1') {
				dataNew[1] = v;
			} else if (v.name_table === 'fbbuffviewsvideo_2') {
				dataNew[2] = v;
			} else if (v.name_table === 'fbbuffviewsvideo_3') {
				dataNew[3] = v;
			} else if (v.name_table === 'fbbuffviewsvideo_4') {
				dataNew[4] = v;
			} else if (v.name_table === 'fbbuffviewsvideo_5') {
				dataNew[5] = v;
			} else if (v.name_table === 'fbbuffviewsvideo_99') {
				dataNew[99] = v;
			}
		});
	};
	return dataNew;
}