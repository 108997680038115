import {
	LOAD_EMAILTOUID,
	UPDATE_EMAILTOUID_TAB,
	UPDATE_EMAILTOUID,
	LOAD_PRICE_EMAILTOUID,
	LOADING_EMAILTOUID,
	LIMIT_PAGI_EMAILTOUID,
	TYPE_EMAILTOUID,
	LOAD_NHATKYHOATDONG_EMAILTOUID,
	UPDATE_UID_EMAILTOUID,
} from "../actions/types";

const date7dnext = () => {
	var result = new Date();
  	result.setDate(result.getDate() + 7);
  	return result;
}

const initialState = {
	"taotientrinh": {
		"email": "",
		"gtmtt": "0",
	},
	"changetab": 0,
	"listtientrinh": [],
	"price": [],
	"loading": false,
	"limit_pagi": 0,
	"type": 0,
	"list_nhatky":"",
	"uid": "",
	"usernamefb": "",
};

export default function(state = initialState, action) {
	switch (action.type) {
		case LOAD_EMAILTOUID:
			return {
				...state,
				"listtientrinh": action.payload
			};
		case UPDATE_EMAILTOUID_TAB:
			return {
				...state,
				"changetab": action.payload
			};
		case UPDATE_EMAILTOUID:
			return {
				...state,
				"taotientrinh": action.payload
			};
		case UPDATE_UID_EMAILTOUID:
			return {
				...state,
				"uid": action.payload.uid,
				"usernamefb": action.payload.usernamefb,
			};
		case LOAD_PRICE_EMAILTOUID:
			return {
				...state,
				"price": action.payload,
				"taotientrinh": {
					...state.taotientrinh,
					"gtmtt": action.payload.prices_web
				}
			};
		case LOADING_EMAILTOUID:
			return {
				...state,
				"loading": action.payload
			};
		case LIMIT_PAGI_EMAILTOUID:
			return {
				...state,
				"limit_pagi": action.payload
			};
		case TYPE_EMAILTOUID:
			return {
				...state,
				"type": action.payload
			};
		case LOAD_NHATKYHOATDONG_EMAILTOUID:
			return {
				...state,
				"list_nhatky": action.payload
			};
		default:
		  return state;
	}
}
