import {
	LOAD_BG,
	UPDATE_BG,
	UPDATE_BG_GETID,
	UPDATE_BG_TAB,
	UPDATE_BG_TGT,
	LOAD_PRICE_BG,
	LIMIT_PAGI_BG,
	TYPE_BG,
	LOADING_BG
} from "../actions/types";

const initialState = {
	"taotientrinh": {
		"lhi": "",
		"lsct": "1",
		"tennhom": "",
		"slct": "1000",
		"gtmtt": "0",
		"gc": "",
	},
	"changetab": 0,
	"listtientrinh": [],
	"tanggiathem": {
		"id": "",
		"orders_id": "",
		"per_price": 0,
		"price": 0,
		"quantity": 0,
		"loai": "",
	},
	"price": {
		buffgroup: {
			min_bh_web: 0,
			prices_web: 0,
		},
		buffgroup_sv2: {
			min_bh_web: 0,
			prices_web: 0,
		},
		buffgroup_sv3: {
			min_bh_web: 0,
			prices_web: 0,
		},
		buffgroup_sv4: {
			min_bh_web: 0,
			prices_web: 0,
		},
		buffgroup_sv5: {
			min_bh_web: 0,
			prices_web: 0,
		},
		buffgroup_sv6: {
			min_bh_web: 0,
			prices_web: 0,
		},
	},
	"limit_pagi": 0,
	"type": 0,
	"loading": false,
	facebookbuffmemgroup_sv1_mo_dong: 1,
	facebookbuffmemgroup_sv2_mo_dong: 1,
	facebookbuffmemgroup_sv3_mo_dong: 1,
	facebookbuffmemgroup_sv4_mo_dong: 1,
	facebookbuffmemgroup_sv5_mo_dong: 1,
	facebookbuffmemgroup_sv6_mo_dong: 1,
};

export default function(state = initialState, action) {
	switch (action.type) {
		case LOAD_BG:
			return {
				...state,
				"listtientrinh": action.payload.data,
			};
		case UPDATE_BG:
			return {
				...state,
				"taotientrinh": action.payload
			};
		case UPDATE_BG_GETID:
			return {
				...state,
				"taotientrinh": {
					...state.taotientrinh,
					"lhi": action.payload.data.group_id,
					"tennhom": action.payload.data.group_name,
				}
			};
		case UPDATE_BG_TAB:
			return {
				...state,
				"changetab": action.payload
			};
		case UPDATE_BG_TGT:
			return {
				...state,
				"tanggiathem": {
					...state.tanggiathem,
					"id": action.payload.id,
					"orders_id": action.payload.orders_id,
					"per_price": action.payload.per_price,
					"price": action.payload.price,
					"quantity": action.payload.quantity,
					"loai": action.payload.loai,
				}
			};
		case LOAD_PRICE_BG:
			return {
				...state,
				"price": merDataPricePage(action.payload),
			};
		case LIMIT_PAGI_BG:
			return {
				...state,
				"limit_pagi": action.payload
			};
		case TYPE_BG:
			return {
				...state,
				"type": action.payload
			};
		case LOADING_BG:
			return {
				...state,
				"loading": action.payload
			};
		case 'CONFIG_MO_DONG_BG':
			return {
				...state,
				facebookbuffmemgroup_sv1_mo_dong: action.payload.facebookbuffmemgroup_sv1_mo_dong,
				facebookbuffmemgroup_sv2_mo_dong: action.payload.facebookbuffmemgroup_sv2_mo_dong,
				facebookbuffmemgroup_sv3_mo_dong: action.payload.facebookbuffmemgroup_sv3_mo_dong,
				facebookbuffmemgroup_sv4_mo_dong: action.payload.facebookbuffmemgroup_sv4_mo_dong,
				facebookbuffmemgroup_sv5_mo_dong: action.payload.facebookbuffmemgroup_sv5_mo_dong,
				facebookbuffmemgroup_sv6_mo_dong: action.payload.facebookbuffmemgroup_sv6_mo_dong,
			};
		default:
		  return state;
	}
}

function merDataPricePage(data) {
	let dataNew = {
		...initialState.price
	}
	if (data.length > 0) {
		data.forEach(function(v, i){
			if (v.name_table === 'buffgroup') {
				initialState.taotientrinh.gtmtt = v.prices_web;
				dataNew['buffgroup'] = v;
			} else if (v.name_table === 'buffgroup_sv2') {
				dataNew['buffgroup_sv2'] = v;
			} else if (v.name_table === 'buffgroup_sv3') {
				dataNew['buffgroup_sv3'] = v;
			} else if (v.name_table === 'buffgroup_sv4') {
				dataNew['buffgroup_sv4'] = v;
			} else if (v.name_table === 'buffgroup_sv5') {
				dataNew['buffgroup_sv5'] = v;
			} else if (v.name_table === 'buffgroup_sv6') {
				dataNew['buffgroup_sv6'] = v;
			}
		});
	};
	return dataNew;
}