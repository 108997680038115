import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import {getDataOrder, downloadFile} from '../../../actions/refundOrderAction';
import NumberFormat from 'react-number-format';
import Moment from 'react-moment';
import Modaledit from './modal_edit';

class History_order extends Component {
    state = {
        limit: 50,
		isOpenModalEdit: false,
		id: 0,
		value: 0,
    }
	
    componentDidMount () {
        this.props.getDataOrder();
    }
    getListByLimit = (e) => {
        let valueChange = e.target.value;
        if (valueChange != '0') {
            this.setState({
                limit: valueChange,
            })
            if (valueChange !== '') {
                this.props.getDataOrder(valueChange);
            }
        }
    }
    openModalEdit = (data = null, rowIndex) => {
		this.setState({
			...this.state,
			isOpenModalEdit: !this.state.isOpenModalEdit,
		});
		if (data !== null) {
			this.setState({
				...this.state,
				isOpenModalEdit: !this.state.isOpenModalEdit,
				id: data.id,
				value: data.price_buy,
			});
		}
    }

    render() {
        const columns = [{
            dataField: 'id',
            text: 'STT',
        },{
            dataField: 'nameuser',
            text: 'Người dùng',
        },{
            dataField: 'id_seeding',
            text: 'ID Seeding',
        },{
            dataField: 'action',
            text: 'Hành động',
        },{
            dataField: 'refund',
            text: 'Tiền Hoàn',
            formatter: (cell, row, rowIndex) =>
                <Fragment>
					<span className="py-1 ml-1 badge badge-pill badge-danger">
						<NumberFormat value={ row.price_buy } displayType={'text'} thousandSeparator={true} />
					</span>
                </Fragment>
        },{
          dataField: 'create_at',
          text: 'Ngày tạo',
          formatter: (cell, row) => 
            <Fragment>
                <Moment format="DD-MM-YYYY HH:mm:ss">{cell*1000}</Moment>
            </Fragment>
        },{
          dataField: 'button',
          text: 'Thao tác',
          formatter:  (cell, row, rowIndex) => 
				<Fragment>
					<a onClick={(e) => {e.preventDefault();this.openModalEdit(row, rowIndex)}} href="/#" className="btn btn-hero-primary text-center p-2 mr-1">
						<i className="fa fa-fw fa-check"></i>
					</a>
				</Fragment>
        }];
        const {data_list, loadding} = this.props.refundOrder;
		// var new_data_list = [];
		// data_list.forEach(function(v,i) {
			// var v = {
				// ...v,
				// uniqueID: i
			// }
			// new_data_list.push(v);
		// });
		// // console.log(new_data_list);
		// const custom_sizeperpagelist = {
			// sizePerPageList: [
					// {
						// text: 'All', value: 9999999999
					// }
				// ],
		// }
        return (
            <Fragment>
                <div className="row">
                    <div className="col-md-12">
                        <div className={"block block-bordered shadow"+(loadding ? ' block-mode-loading' : '')}>
                            <ul className="nav nav-tabs nav-tabs-block">
                                <li className="nav-item col-lg-12 licus">
                                    <a className={'nav-link active'} href="/">
                                        Danh Sách Order
                                    </a>
                                </li>
                            </ul>
                            <div className="block-content tab-content overflow-hidden">


                                <div className="row mb-2">
                                    <div className="col-md-9">
                                        {this.props.auth.user.type === 1 ?
                                            <div>
												{/*<button onClick={(e) => {this.props.getDataOrder(this.state.limit, 1)}} type="button" className="btn btn-primary mr-1">Xem tất cả</button>
                                                <button onClick={(e) => {this.props.getDataOrder(this.state.limit)}} type="button" className="btn btn-primary mr-1">Xem riêng</button>
                                                <button onClick={(e) => {this.props.downloadFile()}} type="button" className="btn btn-primary mr-1">Tải Log</button>*/}
                                            </div>
                                         : ''}
                                    </div>
                                    <div className="col-md-3">
                                        <select onChange={(e) => {this.getListByLimit(e)}} className="form-control">
                                            <option value="0">Số lịch sử hiển thị tối đa</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                            <option value="200">200</option>
                                            <option value="500">500</option>
                                            <option value="1000">1000</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <h3 className="block-title mb-2">Danh sách giao dịch</h3>
                                        <BootstrapTable
											keyField='id'
											bodyStyle={ { border: 'none' } }
											headerStyle={ { border: 'red 1px solid' } }
											data={ data_list }
											striped
											hover
											wrapperClasses="table-responsive"
											columns={ columns }
											pagination={ paginationFactory() } />
										{this.state.isOpenModalEdit ? <Modaledit openModal={this.openModalEdit} isOpenModal={this.state.isOpenModalEdit} dataid={this.state.id} datavalue={this.state.value} /> : ''}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    refundOrder: state.refundOrder,
    auth: state.auth
});

export default connect(mapStateToProps, {getDataOrder, downloadFile}) (History_order);
