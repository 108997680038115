import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Swal from 'sweetalert2';
import copy from 'copy-text-to-clipboard';
// import LichSu from "./tabMuabanclone/lichsu";

import { shieldAvatarUp } from '../../actions/findmyidActions';
class ShieldAvatar extends Component {
	state = {
		data_cookie: ''
	}
	onChange = (e) =>{
		this.setState({
			[e.target.name]:e.target.value
		})
	}
	shieldavatar(type){
		this.props.shieldAvatarUp(this.state.data_cookie.replace(/%/g,'@tien@'), type);
	}
	render() {
		var text = '';
		return (
			<Fragment>
				<div className={"block block-bordered shadow"}>
					<div className="block-header block-header-default">
						<h3 className="block-title">Bật khiên avatar</h3>
					</div>
					<div className="block-content">
						<div className="form-group">
							<label>Cookie facebook:</label>

							<textarea className="form-control" name="data_cookie" value={this.state.data_cookie} onChange={this.onChange} placeholder="COOKIE"></textarea>
						</div>
						<div className="form-group"  style={{"textAlign":"center"}}>
							<button className="btn btn-primary" style={{"width":"15%","marginRight":"3px"}} onClick={()=>{this.shieldavatar(1)}}>Bật khiên</button>
							<button className="btn btn-primary" style={{"width":"15%","marginRight":"3px","background":"#b54e4e","border":"none"}} onClick={()=>{this.shieldavatar(0)}}>Tắt khiên</button>
						</div>
						{text}
					</div>
				</div>
			</Fragment>
		);
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
});
const mapDispatchToProps = { shieldAvatarUp };

export default connect(mapStateToProps, mapDispatchToProps)(ShieldAvatar);
