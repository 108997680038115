import React, { Component, Fragment } from 'react';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import store from '../../../store';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { loadEmailtouid, updateLimitEmailtouid, updateTypeEmailtouid } from '../../../actions/EmailtouidActions';
import Swal from 'sweetalert2';
import parse from 'html-react-parser';
import { confirmAlert } from 'react-confirm-alert';
import Modaledit from './modal_edit';
import Modalnhatky from './modal_nhatky';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import copy from 'copy-text-to-clipboard';
import { RiExchangeDollarLine } from "react-icons/ri";
const { SearchBar } = Search;

class nhatky extends Component {
	static propTypes =  {
		loadEmailtouid: PropTypes.func.isRequired,
		updateLimitEmailtouid: PropTypes.func.isRequired,
		updateTypeEmailtouid: PropTypes.func.isRequired,
	};
	
	state = {
		isOpenModalEdit: false,
		isOpenModalNhatKy: false,
		id: 0,
		ndr: "",
		idfb: 0,
		sdt: "",
	}
	
	componentDidMount() {
		this.props.loadEmailtouid();
	}
	
	onChangeLimit = e => {
		const { type } = this.props.Emailtouid;
		this.props.updateLimitEmailtouid(e.target.value);
		this.props.loadEmailtouid(type, e.target.value);
	};
	
	onCheckList = (type) => {
		const { limit_pagi } = this.props.Emailtouid;
		this.props.updateTypeEmailtouid(type);
		this.props.loadEmailtouid(type, limit_pagi);
	}
	
    openModalEdit = (data = null) => {
		this.setState({
			...this.state,
			isOpenModalEdit: !this.state.isOpenModalEdit,
		});
		if (data !== null) {
			this.setState({
				...this.state,
				isOpenModalEdit: !this.state.isOpenModalEdit,
				id: data.id,
				ndr: data.ndr,
			});
		}
    }
	
	calBwDateToNow = (date_check = null) => {
		if (Number.isInteger(date_check)) {
			let date_now = Math.floor(Date.now() / 1000);
			let calDateDay = (date_now - date_check)/86400;
			if (calDateDay > 1) {
				return Math.ceil(calDateDay) + ' ngày trước';
			} else {
				let calDatehour = (date_now - date_check)/3600;
				if (calDatehour < 0.6) {
					return Math.ceil(calDatehour*100) + ' phút trước';
				} else {
					return Math.ceil(calDatehour) + ' giờ trước';
				}
			}
		}
	}
	
    openModalNhatKy = (data = null) => {
		this.setState({
			...this.state,
			isOpenModalNhatKy: !this.state.isOpenModalNhatKy,
		});
		if (data !== null) {
			this.setState({
				...this.state,
				isOpenModalNhatKy: !this.state.isOpenModalNhatKy,
				sdt: data.sdtn,
			});
		}
    }
	
	copyUID(uid) {
		copy(uid);
		Swal.fire({
			icon: 'success',
			title: '',
			text: 'Đã copy UID',
		});
		return false;
	}
	
	render() {
		var token_avt = {};
		try {
			if (localStorage.getItem("token_avt") != null) {
				token_avt = JSON.parse(localStorage.getItem("token_avt"))[0].token_avt;
			} else {
				token_avt = this.props.auth.token_avt[0].token_avt;
			}
		} catch(e) {
			token_avt = localStorage.getItem("token_avt");
		}
		const { listtientrinh, limit_pagi } = this.props.Emailtouid;
		const { username } = this.props.auth.user;
		var ar_listtientrinh = [];
		listtientrinh.forEach(function(v, i) {
			ar_listtientrinh.push(v);
		});
		
		function formatTrangThai(cell, row) {
			var message = '';
			if (cell === 0) {
				return (
					<span className="badge badge-primary"><i className="fas fa-check mr-1"></i>Hoàn thành</span>
				);
			} else if (cell === 1) {
				return (
					<span className="badge badge-success"><i className="fas fa-sync-alt mr-1"></i>Đang chạy</span>
				);
			} else if (cell === 2) {
				return (
					<span className="badge badge-danger"><i className="fas fa-ban mr-1"></i>ID die không thể chạy</span>
				);
			} else if (cell === 3) {
				return (
					<span className="badge badge-warning"><i className="fas fa-times mr-1"></i>Hủy đơn</span>
				);
			} else if (cell === 5) {
				return (
					<span className="badge badge-info"><RiExchangeDollarLine size={12} className="mr-1" />Hoàn tiền</span>
				);
			} else {
				return ('');
			}
		}
		function filterValueTrangThai(cell, row) {
			if (cell === 0) {
				return ('Hoàn thành');
			} else if (cell === 1) {
				return ('Đang chạy');
			} else if (cell === 2) {
				return ('ID die không thể chạy');
			} else if (cell === 3) {
				return ('Hủy đơn');
			} else if (cell === 5) {
				return ('Hoàn tiền');
			} else {
				return ('');
			}
		}
		function formatCheckTime(cell, row) {
			return (
				<Fragment>
					<Moment format="DD-MM-YYYY HH:mm:ss">{cell*1000}</Moment>
				</Fragment>
			);
		}
		function filterValueCheckTime(cell, row) {
			var d = new Date(cell*1000);
			var month = '' + (d.getMonth() + 1);
			var day = '' + d.getDate();
			var year = d.getFullYear();
			var hour = '' + (d.getHours());
			var min = '' + d.getMinutes();
			var sec = '' + d.getSeconds();

			if (month.length < 2)
				month = '0' + month;
			if (day.length < 2)
				day = '0' + day;
			if (hour.length < 2)
				hour = '0' + hour;
			if (min.length < 2)
				min = '0' + min;
			if (sec.length < 2)
				sec = '0' + sec;
			
			return [day, month, year].join('-') + ' ' + [hour, min, sec].join(':');
		}
		function formatNoiDung(cell, row) {
			cell = Buffer.from(cell, 'base64').toString('utf8');
			return cell.length > 45 ? cell.slice(0, 45 - 1) + "…" : cell;
		}
		function filterValueNoiDung(cell, row) {
			return Buffer.from(cell, 'base64').toString('utf8');
		}
		function formatMang(cell, row) {
			if (cell == '') {
				return 'Việt Nam';
			}
			return cell;
		}
		function formatFbName(cell, row) {
			return parse('<img src="https://graph.facebook.com/'+row.uid+'/picture?height=100&amp;width=100&access_token='+token_avt+'" class="avatar-user"> <a target="_blank" href="https://www.facebook.com/'+row.uid+'">'+row.usernamefb+' - '+row.uid+'</a>');
		}
		function filterValueFbName(cell, row) {
			return (row.usernamefb + ' - ' + row.uid);
		}
		const columns = [{
			dataField: 'id',
			text: 'ID',
			sort: true,
		}, {
		    text: 'Thao tác',
		    dataField: '-',
		    style: {
			  'white-space' : 'nowrap'
		    },
			formatter: (cell, row) => 
				<Fragment>
					<a onClick={(e) => {e.preventDefault();this.copyUID(row.uid)}} href="/#" className="btn btn-hero-primary text-center p-2 mr-1">
						<i className="fa fa-fw fa-clipboard"></i>
					</a>
				</Fragment>
		},{
			dataField: 'email',
			text: 'Email',
			sort: true,
		},{
			dataField: 'uid',
			text: 'UID',
			sort: true,
		    formatter: formatFbName,
		    filterValue: filterValueFbName
		}, {
			dataField: 'created_at',
			text: 'Ngày mua',
			sort: true,
			formatter: (cell, row) => 
				<Fragment>
					{this.calBwDateToNow(cell)}
				</Fragment>
		}, {
			dataField: 'username',
			text: 'Người Add',
			sort: true,
			classes: 'notranslate',
		}];
		{/* , {
			dataField: '',
			text: '',
			formatter: (cell, row) => 
				<Fragment>
					{
						row.status !== 3 ?
							<Link to="/tool/ho-tro-cuoc-goi" className="btn btn-sm btn-danger text-white text-white mr-1" onClick={() => { onClickXoa(row) }}>
								<i className="fa fa-fw fa-times"></i>
							</Link>
							<Link to="/tool/ho-tro-cuoc-goi" className="btn btn-sm btn-primary text-white text-white mr-1" onClick={() => {this.openModalEdit(row)}}>
								<i className="fa fa-fw fa-pen"></i>
							</Link>
						: ''
					}
				</Fragment>
		} */}

		return (
			<Fragment>
				<ToolkitProvider keyField="id" data={ ar_listtientrinh } columns={ columns } search >
					{
						props => (
							<div>
								<SearchBar { ...props.searchProps } />
								<div className="row mb-4">
									<div className="col-md-12">
										{ this.props.auth.user.type === 1 ?
											<Fragment>
												<button onClick={() => {this.onCheckList(1)}} type="button" className="btn btn-primary mr-1">Xem tất cả</button>
												<button onClick={() => {this.onCheckList(0)}} type="button" className="btn btn-primary mr-1">Xem riêng</button>
											</Fragment>
										: ''}
										<select className="form-control custom-select select-light col-md-3 custom-limit_pagi" id="limit_pagi" name="limit_pagi" onChange={this.onChangeLimit} value={limit_pagi} >
											<option value="0">Số lịch sử hiển thị (0-500)</option>
											<option value="1">500-1000</option>
											<option value="2">1000-1500</option>
											<option value="3">1500-2000</option>
											<option value="4">2000-2500</option>
											<option value="5">2500-3000</option>
											<option value="7">3000-3500</option>
											<option value="8">3500-4000</option>
											<option value="9">4500-5000</option>
										</select>
									</div>
								</div>
								<BootstrapTable { ...props.baseProps } pagination={ paginationFactory() } wrapperClasses="table-responsive" striped hover condensed />
								{this.state.isOpenModalEdit ? <Modaledit openModal={this.openModalEdit} isOpenModal={this.state.isOpenModalEdit} dataid={this.state.id} datandr={this.state.ndr} /> : ''}
								{this.state.isOpenModalNhatKy ? <Modalnhatky openModal={this.openModalNhatKy} isOpenModal={this.state.isOpenModalNhatKy} datasdt={this.state.sdt} /> : ''}
							</div>
						)
					}
				</ToolkitProvider>
				<div>
					{/*<BootstrapTable keyField='id' data={ ar_listtientrinh } columns={ columns } pagination={ paginationFactory() } wrapperClasses="table-responsive" striped hover condensed />
					{this.state.isOpenModalEdit ? <Modaledit openModal={this.openModalEdit} isOpenModal={this.state.isOpenModalEdit} dataid={this.state.id} datacookie={this.state.cookie} /> : ''}*/}
				</div>
			</Fragment>
		)
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	Emailtouid: state.Emailtouid,
});
const mapDispatchToProps = { loadEmailtouid, updateTypeEmailtouid, updateLimitEmailtouid }

export default connect(mapStateToProps, mapDispatchToProps)(nhatky);