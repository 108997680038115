import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import {
    Modal,
    ModalBody,
    ModalFooter
} from 'reactstrap';
import { GiaHanFb } from '../../../actions/FbvipActions';
import Swal from 'sweetalert2';

class Modal_edit extends Component {
    state = {
        giahanthem: 30,
    }

    changeValue = (e) => {
        this.setState({
            giahanthem: e.target.value
        });
    }
	
	onChangeGiahan = (giahanthem, dataid, gtmtt, dataslllnct, dataslbvtmn, loai_seeding, openModal) => {
		Swal.fire({
			title: 'Bạn có chắc?',
			html: "Bạn có chắc chắn muốn mua thêm "+giahanthem+" ngày với giá "+gtmtt+" Coin / 1 like/ 1 tháng?",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Đồng ý',
			cancelButtonText: 'Không'
		}).then((result) => {
			if (result.value) {
				var array_data = [];
				array_data['slllnct'] = dataslllnct;
				array_data['slbvtmn'] = dataslbvtmn;
				this.props.GiaHanFb(loai_seeding, giahanthem, dataid, gtmtt, array_data, openModal);
			}
		})
	}
	
	onKeyPress = (e) => {
		if (!(e.charCode >= 48 && e.charCode <= 57)) {
			e.preventDefault();
		}
	}

    render() {
        const {isOpenModal, dataid, dataslllnct, dataslbvtmn, dataquantity_baiviet, loai_seeding} = this.props;
        const {giahanthem} = this.state;
		const { loading } = this.props.Viplikethang;
		
		var {gtmtt} = this.props.Viplikethang.taotientrinh;
		if (loai_seeding == 'viplikethang') {
			gtmtt = this.props.Viplikethang.datapricebuffLike.like_user.prices_web
		} else if(loai_seeding == 'viplikethang_likevia'){
			gtmtt = this.props.Viplikethang.datapricebuffLike.like_via.prices_web
		} else if(loai_seeding == 'viplikethang_speed'){
			gtmtt = this.props.Viplikethang.datapricebuffLike.like_user_speed.prices_web
		}
		var tile_slbv = dataquantity_baiviet*2/10;
        var price = Math.ceil((gtmtt/30) * dataslllnct * giahanthem * tile_slbv);
		
        return (
            <Fragment>
                <Modal centered={true} size="lg" toggle={(e) => {this.props.openModal()}} isOpen={isOpenModal} className={(loading? ' block block-mode-loading' : '')} style={{"backgroundColor": "inherit", "boxShadow": "none"}}>
                    <div className="modal-header">
                        <p className="mb-0 text-dark">Gia hạn</p>
                        <button onClick={(e) => {this.props.openModal()}} type="button" className="close">×</button>
                    </div>
                    <ModalBody style={{opacity: loading? '.05':'1'}}>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group focused">
                                    <label className="control-label">Gia hạn</label>
                                    {/* <input type="number" className="form-control" placeholder="Nhập số ngày gia hạn thêm" value={giahanthem} onChange={(e)=>{this.changeValue(e)}} onKeyPress={this.onKeyPress} /> */}
									<select className="form-control custom-select select-light" id="giahanthem" name="giahanthem"onChange={(e)=>{this.changeValue(e)}} value={giahanthem} >
										<option value="7">7 ngày</option>
										<option value="15">15 ngày</option>
										<option value="30">30 ngày</option>
										<option value="60">60 ngày</option>
										<option value="90">90 ngày</option>
									</select>
                                </div>
                            </div>
                        </div>
						<div className="row mt-4">
							<div className="col-12 text-center bold">
								<div className="card card-info">
									<div className="card-body">
										<h5 className="font-bold">Tổng: <span className="bold green"><span>{price}</span> Coin</span></h5>
										<h6 className="mb-0 bold"><span>Gia hạn thêm <span className="green">{giahanthem} ngày</span> với giá <span className="green">{gtmtt}</span> Coin / 1 like/ 1 tháng</span></h6>
									</div>
								</div>
							</div>
						</div>
                    </ModalBody>
                    <ModalFooter style={{opacity: loading? '.05':'1'}}>
                        <button onClick={() => {this.onChangeGiahan(giahanthem, dataid, gtmtt, dataslllnct, dataslbvtmn, loai_seeding, this.props.openModal)}} type="button" className="btn btn-primary">Lưu</button>
                        <button onClick={(e) => {this.props.openModal()}} type="button" className="btn btn-danger">Huỷ</button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    Viplikethang: state.Viplikethang
});

export default connect(mapStateToProps, {GiaHanFb}) (Modal_edit);
