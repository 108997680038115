import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { addFABVDX, updateFABVDX, getConfigMoDong } from '../../../actions/FacebookadbreaksviewdexuatActions';
import Swal from 'sweetalert2';
import parse from 'html-react-parser';
import ServicesDescription from "../ServicesDescription";
import { isMobile } from 'react-device-detect';

class taotientrinh extends Component {
	static propTypes =  {
		addFABVDX: PropTypes.func.isRequired,
		updateFABVDX: PropTypes.func.isRequired,
	};
	
	componentDidMount() {
        this.props.getConfigMoDong();
	};
	
	state = {
		showDescription: isMobile? false : true
	}
	
	onChange = (e) => {
		var { taotientrinh } = this.props.Facebookadbreaksviewdexuat;
		var new_taotientrinh = {
			...taotientrinh,
			[e.target.name]: e.target.value
		};
		if (e.target.name === 'lvct') {
			var matches_idvideo = e.target.value.match(/videos\/[0-9]+/);
			// console.log(matches_idvideo);
			if (matches_idvideo != null) {
				var new_taotientrinh = {
					...taotientrinh,
					"lvct": matches_idvideo[0].replace('videos/', '')
				};
			} else {
				matches_idvideo = e.target.value.match(/v=[0-9]+/);
				// console.log(matches_idvideo);
				if (matches_idvideo != null) {
					var new_taotientrinh = {
						...taotientrinh,
						"lvct": matches_idvideo[0].replace('v=', '')
					};
				}
			}
		} else if (e.target.name === "lsct") {
			if (e.target.value === "viewdexuat") {
				var value = this.props.Facebookadbreaksviewdexuat.price.Facebookadbreaksviewdexuat.prices_web;
				taotientrinh = {
					...taotientrinh,
					"gtmtt": value,
				};
			}
			// if (e.target.value === "2") {
				// var value = this.props.Facebookadbreaksviewdexuat.price.Facebookadbreaksviewdexuat_sv2.prices_web;
				// taotientrinh = {
					// ...taotientrinh,
					// "gtmtt": value,
				// };
			// }
			// if (e.target.value === "3") {
				// var value = this.props.Facebookadbreaksviewdexuat.price.Facebookadbreaksviewdexuat_sv3.prices_web;
				// taotientrinh = {
					// ...taotientrinh,
					// "gtmtt": value,
				// };
			// }
			var new_taotientrinh = {
				...taotientrinh,
				[e.target.name]: e.target.value,
			};
		}
		this.props.updateFABVDX(new_taotientrinh);
	}
	taoTienTrinhfunc = () => {
		const { taotientrinh } = this.props.Facebookadbreaksviewdexuat;
		const id_user = this.props.auth.user.id;
		if (taotientrinh.lvctm === "") {
			Swal.fire({
				icon: 'error',
				title: 'Lỗi',
				text: 'ID không được để trống',
			});
			return false;
		}
		var new_taotientrinh = {
			...taotientrinh,
			'type_api': 'facebookadbreaks_viewdexuat'
		};
		Swal.fire({
			title: 'Bạn có chắc?',
			html: "Bạn có chắc chắn muốn mua "+taotientrinh.slct+" view với giá "+taotientrinh.gtmtt+" Coin / View? Khi đã bấm đồng ý đồng nghĩa với việc bạn đã đọc hết mục thông tin dịch vụ!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Đồng ý',
			cancelButtonText: 'Không'
		}).then((result) => {
			if (result.value) {
				this.props.addFABVDX(new_taotientrinh);
			}
		})
	}
	
	onKeyPress = (e) => {
		if (!(e.charCode >= 48 && e.charCode <= 57)) {
			e.preventDefault();
		}
	}
	
	onClickShowDescription = (data) => {
		this.setState({
			showDescription: data
		});
	}
	
	render() {
		const { lvct, lsct, quocgia, goi, slct, gc, gtmtt } = this.props.Facebookadbreaksviewdexuat.taotientrinh;
		const { facebookadbreaksviewdexuat_sv1_mo_dong } = this.props.Facebookadbreaksviewdexuat;
		var price = Math.round((goi * slct * gtmtt+ Number.EPSILON) * 100) / 100;
		
		var listDataServicesInfo = {};
		try {
			if (localStorage.getItem("listDataServicesInfo") != null) {
				listDataServicesInfo = JSON.parse(localStorage.getItem("listDataServicesInfo"));
			} else {
				listDataServicesInfo = this.props.adminpage.listDataServicesInfo;
			}
		} catch(e) {
			listDataServicesInfo = {};
		}
		var html_haydoctranhmattien = '';
		var html_haydoctranhmattien_chinh = '';
		var html_haydoctranhmattien_sv = '';
		var html_viewdexuat = '';
		if (typeof(listDataServicesInfo.facebook_buff_adbreaks_viewdexuat_tab) !== 'undefined') {
			html_haydoctranhmattien = parse(listDataServicesInfo.facebook_buff_adbreaks_viewdexuat_tab.ghichu);
			html_haydoctranhmattien_chinh = parse(listDataServicesInfo.facebook_buff_adbreaks_viewdexuat_tab.ghichu_chinh);
		}
		if (typeof(listDataServicesInfo.facebook_buff_adbreaks_viewdexuat_sv1) !== 'undefined') {
			html_viewdexuat = parse(listDataServicesInfo.facebook_buff_adbreaks_viewdexuat_sv1.ghichu_sv);
			if (lsct.toString() === "viewdexuat") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.facebook_buff_adbreaks_viewdexuat_sv1.ghichu);
			}
		}
		
		return (
			<Fragment>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Link video hoặc id_video (hệ thống tự động get IDVIDEO):</label>
					<div className="col-sm-8">
						<input type="text" className="form-control" id="lvct" name="lvct" placeholder="" onChange={this.onChange} value={lvct} />
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Chọn Máy Chủ cần tăng:</label>
					<div className="col-sm-8">
						<div className="custom-control custom-radio custom-control-inline" style={{opacity: this.props.Facebookadbreaksviewdexuat.price.Facebookadbreaksviewdexuat.prices_web == 0 ? '0.3' : '1' }}>
							<input onChange={this.onChange} value="viewdexuat" type="radio" className="custom-control-input" id="loaiseeding_1" name="lsct" checked={lsct.toString() === "viewdexuat"} disabled={this.props.Facebookadbreaksviewdexuat.price.Facebookadbreaksviewdexuat.prices_web == 0 ? 'disabled' : ''}/>
							<label className="custom-control-label" htmlFor="loaiseeding_1" >SV1 {html_viewdexuat} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.Facebookadbreaksviewdexuat.price.Facebookadbreaksviewdexuat.prices_web} coin</span>{facebookadbreaksviewdexuat_sv1_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
						</div>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Quốc gia xem:</label>
					<div className="col-sm-8">
						<select className="form-control" id="quocgia" name="quocgia" onChange={this.onChange} value={quocgia} >
							<option value="de">Mặc định</option>
						</select>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Thời gian duy trì view:</label>
					<div className="col-sm-8">
						<select className="form-control" id="goi" name="goi" onChange={this.onChange} value={goi} >
							<option value="30">30 phút</option>
							<option value="45">45 phút</option>
							<option value="60">60 phút</option>
							<option value="90">90 phút</option>
							<option value="120">120 phút</option>
							<option value="150">150 phút</option>
							<option value="180">180 phút</option>
							<option value="210">210 phút</option>
							<option value="240">240 phút</option>
						</select>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Số lượng:</label>
					<div className="col-md-8">
						<div className="form-group">
                            <input type="number" className="form-control" onChange={this.onChange} name="slct" value={slct} onKeyPress={this.onKeyPress}/>
							<h6 style={{"marginTop":"5px", "marginBottom":"0px"}} className="text-danger">Yêu cầu video có thời gian lớn hơn bằng 10s</h6>
                        </div>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Ghi chú:</label>
					<div className="col-sm-8">
						<textarea type="text" className="form-control" id="gc" name="gc" placeholder="Nhập nội dung ghi chú về tiến trình của bạn" onChange={this.onChange} value={gc} ></textarea>
					</div>
				</div>
				<div className="form-group row">
				    <div className="col-md-12 bold">
				        <div className="card card-orange">
				        	<div className="card-body py-2">
								<h4 className="mb-0 text-danger text-center">Tổng số tiền = (Số lượng) x (Thời gian duy trì view) x (Giá tiền 1 mắt)</h4>
			        		</div>
		        		</div>
				    </div>
				</div>
				
				<button type="button" className="btn badge-primary-cus btn-rounded btn-show-description-cus" onClick={(e) => {this.onClickShowDescription(!this.state.showDescription)}}>
					{
						this.state.showDescription?
							<i className="fa fa-angle-up" style={{"marginRight": "5px"}} aria-hidden="true"></i>
						:
							<i className="fa fa-angle-down" style={{"marginRight": "5px"}} aria-hidden="true"></i>
					}
					Thông tin dịch vụ
				</button>
				
				<ServicesDescription allservicesinfo={listDataServicesInfo} server={lsct.toString()} tab="facebook_buff_adbreaks_viewdexuat" giatien={gtmtt} baohanh={0} showDescription={this.state.showDescription} />
				
				<div className="row mt-2" style={{"display": this.state.showDescription? "flex" : "none"}}>
				    <div className="col-md-12 bold" style={{"marginBottom": "10px"}}>
						<div className="card card-select-cus">
							<div className="card-body card-body-select-cus">
								<p className="mb-0 mt-3 text-wrap">
									<ul>
										<li>1 ID KHÔNG CÀI 2 ĐƠN CÙNG LÚC TRONG HỆ THỐNG ! ĐƠN CŨ XONG MỚI CÀI ĐƠN MỚI ! CỐ TÌNH CÀI BỊ HỤT SỐ LƯỢNG SẼ KHÔNG XỬ LÝ !</li>
										<li>Nếu ID đang chạy trên hệ thống mà bạn vẫn mua id đó cá hệ thống bên khác, nếu có tình trạng hụt số lượng giữa 2 bên thì sẽ không được xử lí!</li>
										{html_haydoctranhmattien}
										{html_haydoctranhmattien_chinh}
										{html_haydoctranhmattien_sv}
										<li>Nếu gặp lỗi hãy nhắn tin hỗ trợ phía bên phải góc màn hình hoặc vào mục liên hệ hỗ trợ để được hỗ trợ tốt nhất!</li>
									</ul>
								</p>
								<span className="badge badge-cus position-absolute-cus badge-top-left-cus badge-primary-cus badge-pill-cus"> HÃY ĐỌC ĐỂ TRÁNH MẤT TIỀN KHI SỬ DỤNG! </span>
							</div>
						</div>
				    </div>
				</div>
				<div className="row mt-4">
					<div className="col-12 text-center bold">
						<div className="card card-info">
							<div className="card-body">
								<h5 className="font-bold">Tổng: <span className="bold green"><span>{price}</span> Coin</span></h5>
								<h6 className="mb-0">Bạn sẽ mua <span className="green bold">{slct} view</span> với giá <span className="green bold">{gtmtt}</span>  Coin / View</h6>
							</div>
						</div>
					</div>
				</div>
				<div className="row my-4">
					<div className="col-12 text-center bold">
						<button type="button" className="btn btn-block btn-rounded btn-hero-primary" onClick={this.taoTienTrinhfunc} >Tạo tiến trình</button>
					</div>
				</div>
			</Fragment>
		)
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	Facebookadbreaksviewdexuat: state.Facebookadbreaksviewdexuat,
});
const mapDispatchToProps = { addFABVDX, updateFABVDX, getConfigMoDong }

export default connect(mapStateToProps, mapDispatchToProps)(taotientrinh);