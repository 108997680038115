import React, { Component, Fragment } from 'react';
import {
  BrowserRouter as Router,
  // Switch,
  // Route,
  Link
} from "react-router-dom";
import {
	// Button,
	// Modal,
	// ModalHeader,
	// ModalBody,
	// Form,
	// FormGroup,
	// Label,
	// Input,
	// NavLink,
	Alert
} from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { register } from '../../actions/authActions';
import { clearErrors } from '../../actions/errorActions';
import parse from 'html-react-parser';

class RegisterModal extends Component {
	state = {
		// modal: false,
		username: '',
		email: '',
		password: '',
		repeatpassword: '',
		facebook_id: '4',
		phone: '',
		msg: null,
	}
	
	static propTypes =  {
		auth: PropTypes.object.isRequired,
		error: PropTypes.object.isRequired,
		register: PropTypes.func.isRequired,
		clearErrors: PropTypes.func.isRequired
	}
	
	componentDidUpdate(prevProps) {
		const { error } = this.props;
		if (error !== prevProps.error) {
			// Check for register error
			if (error.id === 'REGISTER_FAIL') {
				this.setState({ msg: error.msg.msg });
			} else {
				this.setState({ msg: null });
			}
		}
	}
	
	clearAlert = () => {
		// Clear errors
		this.props.clearErrors();
	}
	
	onChange = e => {
		this.setState({ [e.target.name]: e.target.value });
	};
	
	onSubmit = e => {
		e.preventDefault();
		
		const { username, email, password, repeatpassword, facebook_id, phone } = this.state;
		
		// Create user object
		const newUser = {
			username,
			email,
			password,
			repeatpassword,
			facebook_id,
			phone
		};
		// console.log(newUser);
		// Attempt to register
		this.props.register(newUser);
	};
	
	render() {
		let loading = this.props.auth.loading;
		var domainSite = window.location.hostname;
		var config_website = {};
		if (localStorage.getItem("confignavbar") != null) {
			config_website = JSON.parse(localStorage.getItem("confignavbar"));
		} else {
			config_website = this.props.auth.config_website;
		}
		return (
			<Router>
				<Fragment>
					<div className="landingcus2" style={{"zoom": "112%"}}>
						<div id="block_1">
							<div className="block-wrapper">
								<div className="component_navbar ">
									<div className="component-navbar__wrapper">
										<div className="sidebar-block__top component-navbar component-navbar__navbar-public sommerce-editor__component-wrapper">
											<div>
												<nav className="navbar navbar-expand-lg navbar-light">
													<div className="sidebar-block__top-brand" style={{"width": "250px", "wordBreak": "break-all"}}>
														<div className="component-navbar-brand component-navbar-public-brand">
															<a className="link-fx font-w700 font-size-h3" href="/" style={{"fontSize": "1.25rem"}} >
															  <span className="text-uppercase">{domainSite.indexOf('tools1s.com') < 0 ? domainSite.slice(0, domainSite.lastIndexOf('.')) : domainSite.slice(0, domainSite.lastIndexOf('.')).replace('vip.', '') }</span>
															  <span className="text-uppercase">{domainSite.indexOf('.') > -1 && domainSite.indexOf('tools1s.com') < 0 ? domainSite.slice(domainSite.lastIndexOf('.')) : ''}</span>
															</a>
														</div>
													</div>
													<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar-collapse-1" aria-controls="navbar-collapse-1" aria-expanded="false" aria-label="Toggle navigation">
														<Link to="/" onClick={this.props.action_login} className="component-navbar-nav-link component-navbar-nav-link__navbar-public component-navbar-nav-link-active__navbar-public">
															<i className="navbar-icon fab fa-gratipay"></i> Đăng nhập
														</Link>
													</button>
													<div className="collapse navbar-collapse" id="navbar-collapse-1">
														<div className="component-navbar-collapse-divider"></div>
														<div className="d-flex component-navbar-collapse">
															<ul className="navbar-nav">
																<li className="nav-item component-navbar-nav-item">
																	<Link to="/services" onClick={this.props.action_service} className="component-navbar-nav-link component-navbar-nav-link__navbar-public ">
																		<i className="navbar-icon fa fa-list"> </i>  Dịch vụ
																	</Link>
																</li>
																<li className="nav-item component-navbar-nav-item">
																	<Link to="/" onClick={this.props.action_login} className="component-navbar-nav-link component-navbar-nav-link__navbar-public component-navbar-nav-link-active__navbar-public">
																		<i className="navbar-icon fab fa-gratipay"></i> Đăng nhập
																	</Link>
																</li>
																<li className="nav-item component-navbar-nav-item">
																	<Link to="#" className="component-navbar-nav-link component-navbar-nav-link__navbar-public ">
																		<i className="navbar-icon far fa-edit"></i> Đăng ký
																	</Link>
																</li>
																{/*<li className="nav-item component-navbar-nav-item">
																	<a className="component-navbar-nav-link component-navbar-nav-link__navbar-public " href="/terms"><i className="navbar-icon fas fa-file-signature"></i> Terms</a>
																</li>
																<li className="nav-item component-navbar-nav-item">
																	<a className="component-navbar-nav-link component-navbar-nav-link__navbar-public " href="/services"><i className="navbar-icon fas fa-feather-alt"></i> Services</a>
																</li>
																<li className="nav-item component-navbar-nav-item">
																	<a className="component-navbar-nav-link component-navbar-nav-link__navbar-public " href="/api"><i className="navbar-icon fas fa-chalkboard-teacher"></i> API</a>
																</li>*/}
															</ul>
														</div>
													</div>
												</nav>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="component_navbar"></div>
							<div className="component_navbar_sub"></div>
						</div>
						<div className="wrapper-content">
							<div className="wrapper-content__header">
							</div>
							<div className="wrapper-content__body">
								<div id="block_13">
									<div className="sign-in">
										<div className="bg"></div>
										<div className="divider-top"></div>
										<div className="divider-bottom"></div>
										<div className="container">
											<div className="row sign-up-center-alignment">
												<div className="col-lg-8">
													<div className="component_card">
														<div className="card">
															{loading ? (
																<div className="preloader">
																	<div className="spinner-grow spinner-grow-sm text-primary" role="status" style={{ "marginTop": "40%" }}>
																	  <span className="sr-only"></span>
																	</div>
																	<div className="spinner-grow spinner-grow-sm text-danger" role="status" style={{ "marginTop": "40%" }}>
																	  <span className="sr-only">.</span>
																	</div>
																	<div className="spinner-grow spinner-grow-sm text-warning" role="status" style={{ "marginTop": "40%" }}>
																	  <span className="sr-only"></span>
																	</div>
																</div>
															):('')}
															{this.state.msg ? (
																<div className="alert alert-warning d-flex align-items-center justify-content-between" role="alert" >
																  <div className="flex-fill mr-3">
																	<p className="mb-0">{parse(this.state.msg)}</p>
																  </div>
																  <div className="flex-00-auto">
																	<i className="fa fa-fw fa-exclamation-circle"></i>
																  </div>
																</div>
															  ) : null}
															<form className="js-validation-signup" onSubmit={this.onSubmit} method="POST" >
																<div className="">
																	<div className="form-group">
																		<label htmlFor="username" className="control-label">Tên tài khoản</label>
																		<input name="username" placeholder="" required="" id="username" type="text" className="form-control" onChange={this.onChange} />
																	</div>
																	<div className="form-group">
																		<label htmlFor="email" className="control-label">Email</label>
																		<input name="email" placeholder="" required="" id="email" type="email" className="form-control" onChange={this.onChange} />
																	</div>
																	<div className="form-group">
																		<label htmlFor="phone" className="control-label">Số điện thoại</label>
																		<input name="phone" placeholder="" required="" id="phone" type="text" className="form-control" onChange={this.onChange} />
																	</div>
																	<div className="form-group">
																		<label htmlFor="password" className="control-label">Mật khẩu</label>
																		<input name="password" placeholder="" required="" id="password" type="password" className="form-control" onChange={this.onChange} />
																	</div>
																	<div className="form-group">
																		<label htmlFor="confirm" className="control-label">Nhập lại mật khẩu</label>
																		<input name="repeatpassword" placeholder="" required="" id="repeatpassword" type="password" className="form-control" onChange={this.onChange} />	
																	</div>
																</div>
																<div className="component_button_submit">
																	<div className="form-group">
																		<div className="">
																			<button type="submit" className="btn btn-block btn-big-primary">Đăng ký</button>
																		</div>
																	</div>
																</div>
																<div className="text-center">Bạn đã có tài khoản?
																	<Link to="/" onClick={this.props.action_login} className="sign-up-center-signin-link">
																	  <span>
																		Đăng nhập
																	  </span>
																	</Link>
																</div>
															</form>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="wrapper-content__footer">
								<div id="block_11">
									<div className="footer ">
										<div className="component_footer_single_line">
											<div className="component-footer">
												<div className="component-footer__public">
													<div className="container-fluid">
														<div className="row">
															<div className="col-md-12">
																<div className="component-footer__public-copyright"><p className="text-center"><span style={{"textAlign": "center"}}>© Copyright. All Rights Reserved.</span></p></div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</Fragment>
			</Router>
		);
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	error: state.error
})

export default connect(mapStateToProps, { register, clearErrors })(RegisterModal);