import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import axios from 'axios';
import {
	LOAD_BLSC,
	// ADD_BSR,
	UPDATE_BLSC,
	UPDATE_BLSC_GETID,
	UPDATE_BLSC_TAB,
	LIMIT_PAGI_BLSC,
	TYPE_BLSC,
	LOADING_BLSC
} from "../actions/types";
import Swal from 'sweetalert2';
import { loadUser } from "./authActions";

export const loadBLSC = (type = 0, page = 1, obj_search = '') => (dispatch, getState) => {
	dispatch({
		type: LOADING_BLSC,
		payload: true
	});
	axios.get('/api/facebook_buff/list?type_api=bufflikesubcomment&type='+type+'&page='+page+'&obj_search='+obj_search, tokenConfig(getState))
		.then(res => {
			dispatch({
				type: LOADING_BLSC,
				payload: false
			});
			if (res.data.status === 200) {
				dispatch({
					type: LOAD_BLSC,
					payload: res.data
				});
			}
		});
};

export const addBLSC = (body) => (dispatch, getState) => {
	dispatch({
		type: LOADING_BLSC,
		payload: true
	});
	axios.post('/api/facebook_buff/create', body, tokenConfig(getState))
		.then(res => {
			dispatch({
				type: LOADING_BLSC,
				payload: false
			});
			dispatch(loadUser());
			if (res.data.status === 200) {
				Swal.fire({
					icon: 'success',
					html: 'Tạo tiến trình thành công!'
				});
			}
			if (res.data.status === 400) {
				var message = '';
				if (res.data.error) {
					if (res.data.error.object_id) {
						message = res.data.error.object_id[0];
					} else if (res.data.error.quantity) {
						message = res.data.error.quantity[0];
					} else {
						message = res.data.message;
					}
				} else {
					message = res.data.message;
				}
				
				Swal.fire({
					icon: 'error',
					title: 'Lỗi',
					text: message,
				});
			}
		}).catch(err => {
			dispatch({
				type: LOADING_BLSC,
				payload: false
			});
			console.log(err);
			openBtComfirmAlert('Có lỗi xảy ra!', 'error', 'Lỗi');
		});
};

export const updateBLSC = (body) => (dispatch) => {
	dispatch({
		type: UPDATE_BLSC,
		payload: body
	})
};

export const updateLimitBLSC = (body) => (dispatch) => {
	dispatch({
		type: LIMIT_PAGI_BLSC,
		payload: body
	})
};

export const updateTypeBLSC = (body) => (dispatch) => {
	dispatch({
		type: TYPE_BLSC,
		payload: body
	})
};

// export const getID = (body) => (dispatch, getState) => {
	// let bodysend = {
		// id_user: getState().auth.user.id,
		// link: body.lhi
	// };
	// dispatch({
		// type: LOADING_BLSC,
		// payload: true
	// });
	// axios.post('/api/checklinkfb/check/', bodysend, tokenConfig(getState))
	// .then(res => {
		// dispatch({
			// type: LOADING_BLSC,
			// payload: false
		// });
		// if (res.data.status === 200) {
			// dispatch({
				// type: UPDATE_BG_GETID,
				// payload: res.data
			// });
		// } else {
			// Swal.fire({
				// icon: 'error',
				// title: 'Lỗi',
				// text: res.data.msg,
			// });
		// }
	// }).catch(err => {
		// dispatch({
			// type: LOADING_BLSC,
			// payload: false
		// });
		// openBtComfirmAlert('Có lỗi xảy ra!', 'error', 'Lỗi');
    // });
// };

export const changeTab = (body) => (dispatch, getState) => {
	dispatch({
		type: UPDATE_BLSC_TAB,
		payload: body
	});
};

export const openBtComfirmAlert = (message, type, title = '') => {
	Swal.fire({
		icon: type,
		title: title,
		text: message,
	});
	return false;
}

export const upHuyOrder = (body) => (dispatch, getState) => {
	dispatch({
		type: LOADING_BLSC,
		payload: true
	});
	axios.post('/api/facebook_buff/removeorder', body, tokenConfig(getState))
		.then(res => {
			dispatch({
				type: LOADING_BLSC,
				payload: false
			});
			if (res.data.status === 200) {
				Swal.fire({
					icon: 'success',
					text: res.data.message,
				});
				dispatch(loadBLSC());
			}
			if (res.data.status === 400) {
				var message = '';
				if (res.data) {
					message = res.data.message;
				}
				Swal.fire({
					icon: 'error',
					title: 'Lỗi',
					text: message,
				});
			}
		});
};

export const saveEditOrderAdmin = (data, closeM) => (dispatch, getState) => {
	let bodysend = {
		formdata: data,
		type_api: 'bufflikesubcomment'
	};
	dispatch({
		type: LOADING_BLSC,
		payload: true
	});
	axios.post('/api/facebook_buff/admin_edit_order/', bodysend, tokenConfig(getState))
	.then(res => {
		dispatch({
			type: LOADING_BLSC,
			payload: false
		});
		if (res.data.status === 200) {
			dispatch(loadBLSC());
			closeM();
			Swal.fire(
	      		'Thành công!',
	      		'Sửa thành công',
	      		'success'
	    	)
		} else {
			openBtComfirmAlert(res.data.message, 'error', 'Lỗi');
		}
	}).catch(err => {
		dispatch({
			type: LOADING_BLSC,
			payload: false
		});
		console.log(err);
		openBtComfirmAlert('Có lỗi xảy ra!', 'error', 'Lỗi');
    });
};

export const getID = (body) => (dispatch, getState) => {
	let bodysend = {
		id_user: getState().auth.user.id,
		link: body.lhi
	};
	dispatch({
		type: LOADING_BLSC,
		payload: true
	});
	axios.post('/api/checklinkfb/check/', bodysend, tokenConfig(getState))
	.then(res => {
		dispatch({
			type: LOADING_BLSC,
			payload: false
		});
		if (res.data.status === 200) {
			dispatch({
				type: UPDATE_BLSC_GETID,
				payload: res.data.id
			});
		} else {
			Swal.fire({
				icon: 'error',
				title: 'Lỗi',
				text: res.data.msg,
			});
		}
	}).catch(err => {
		dispatch({
			type: LOADING_BLSC,
			payload: false
		});
		openBtComfirmAlert('Có lỗi xảy ra!', 'error', 'Lỗi');
    });
};

export const getConfigMoDong = (type = '') => (dispatch, getState) => {
	dispatch({
		type: LOADING_BLSC,
		payload: true
	});

	axios.get('/api/facebook_buff/getconfig?type_api=bufflikesubcomment', tokenConfig(getState))
	.then(res => {
		dispatch({
			type: LOADING_BLSC,
			payload: false
		});
		if (res.data.status === 200) {
			dispatch({
				type: 'CONFIG_MO_DONG_BLSC',
				payload: res.data
			});
		}
	});
}

export const getIDVideo = (body) => (dispatch, getState) => {
	let bodysend = {
		id_user: getState().auth.user.id,
		link: body
	};
	dispatch({
		type: LOADING_BLSC,
		payload: true
	});
	return new Promise(function(resolve, reject){
		axios.post('/api/checklinkfb/check_video/', bodysend, tokenConfig(getState))
			.then(res => {
				dispatch({
					type: LOADING_BLSC,
					payload: false
				});
				if (res.data.status === 200) {
					resolve(res.data.id);
				} else {
					resolve('');
					Swal.fire({ icon: 'error', title: 'Lỗi', text: res.data.msg });
				}
			});
	});
};

export const getIDPost = (body) => (dispatch, getState) => {
	let bodysend = {
		id_user: getState().auth.user.id,
		link: body
	};
	dispatch({
		type: LOADING_BLSC,
		payload: true
	});
	return new Promise(function(resolve, reject){
		axios.post('/api/checklinkfb/check_post/', bodysend, tokenConfig(getState))
			.then(res => {
				dispatch({
					type: LOADING_BLSC,
					payload: false
				});
				if (res.data.status === 200) {
					resolve(res.data.id);
				} else {
					resolve('');
					Swal.fire({ icon: 'error', title: 'Lỗi', text: res.data.msg });
				}
			});
	});
};

export const tokenConfig = getState => {
	// Get token from localhost
	const token = getState().auth.token;
	// Headers
	const config = {
		headers: {
			"Content-type": "application/json"
		}
	};
	
	// If token, add to headers
	if (token) {
		config.headers['ht-token'] = token;
	}
	
	return config;
};