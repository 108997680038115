import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Swal from 'sweetalert2';
import copy from 'copy-text-to-clipboard';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

class Copyfile extends Component {
	state = {
		noidung: "Nội dung xyzt",
		text_placeholder: "Nội dung xyzt",
		batdautu: 1,
		ketthuc: 100,
		duoifile: "html",
		tenfile: "google-",
	}
	onClick = (e) =>{
		this.setState({
			[e.target.name]: e.target.value
		})
	}
	onChange = (e) =>{
		this.setState({
			[e.target.name]: e.target.value
		})
	}
	onTrigger = (e) => {
		var { noidung, batdautu, ketthuc, duoifile, tenfile } = this.state;
		var zip = new JSZip();
		for(var i = batdautu; i <= ketthuc; i++){
			var file_name = tenfile + i + '.' + duoifile;
			zip.file("copy/" + file_name, noidung);
		}
		zip.generateAsync({type: "blob"}).then(function(content){
			saveAs(content, "copy.zip");
		});
	}
	
	render() {
		return (
			<Fragment>
				<div className="form-group row">
					<label className="col-sm-2 col-form-label edittext-label-right" htmlFor="">Nội dung</label>
					<div className="col-sm-10">
						<textarea rows="5" placeholder={this.state.text_placeholder} className="form-control input-gray edittext-textarea" name="noidung" onChange={this.onChange} value={this.state.noidung} ></textarea>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-2 col-form-label edittext-label-right" htmlFor="">Bắt đầu từ</label>
					<div className="col-sm-10">
						<input type="text" className="form-control" name="batdautu" value={this.state.batdautu} onChange={this.onChange} />
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-2 col-form-label edittext-label-right" htmlFor="">Kết thúc</label>
					<div className="col-sm-10">
						<input type="text" className="form-control" name="ketthuc" value={this.state.ketthuc} onChange={this.onChange} />
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-2 col-form-label edittext-label-right" htmlFor="">Đuôi file</label>
					<div className="col-sm-10">
						<input type="text" className="form-control" name="duoifile" value={this.state.duoifile} onChange={this.onChange} />
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-2 col-form-label edittext-label-right" htmlFor="">Tên file</label>
					<div className="col-sm-10">
						<div className="input-group">
							<input type="text" className="form-control" name="tenfile" value={this.state.tenfile} onChange={this.onChange} />
							<div className="input-group-append">
								<span className="input-group-text" style={{"borderLeft": "0px"}}>
									{this.state.batdautu}->{this.state.ketthuc}.{this.state.duoifile}
								</span>
							</div>
						</div>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-2 col-form-label edittext-label-right" htmlFor=""></label>
					<div className="col-sm-10">
						<button type="button" className="btn btn-rounded btn-hero-primary edittext-btn" onClick={this.onTrigger} >Trigger</button>
					</div>
				</div>
			</Fragment>
		);
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
});
const mapDispatchToProps = {  };

export default connect(mapStateToProps, mapDispatchToProps)(Copyfile);
