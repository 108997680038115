import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import {
    Modal,
    ModalBody,
    ModalFooter
} from 'reactstrap';
import { NhatKyHoatDong, closeModalNhatKy } from '../../../actions/FbvipActions';
import Swal from 'sweetalert2';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import parse from 'html-react-parser';
const { SearchBar } = Search;

class Modal_edit extends Component {
    state = {
		
    }

	componentDidMount() {
		var type = "viplike_clone";
		if (this.props.datalsct == "4") {
			type = "viplike_clone_sv5";
		}
		this.props.NhatKyHoatDong(type, this.props.dataidfb);
	}
	
    render() {
        const {isOpenModal, dataidfb} = this.props;
        var {list_nhatky} = this.props.buffviplikeclone;
		var dataReturn = [];
		// var arrKeyObj = ['username', 'idfb', 'idfb_2', 'idfbtuongtac', 'ngay_tuongtac'];
		var arrKeyObj = ['idfb', 'idfbtuongtac', 'ngay_tuongtac'];
		list_nhatky = list_nhatky.split('\n').reverse();
		list_nhatky.forEach(function(v, i){
			if (v != '') {
				var arrK = v.split("||");
				var arrK_new = [
					arrK[1].split("_")[0],
					arrK[1].split("_")[1],
					arrK[3],
				];
				var objEnd = Object.assign({},...arrK_new.map((key,ik) => ({[arrKeyObj[parseInt(ik)]]: key})));
				dataReturn.push(objEnd);
			}
		});
		function formatFbName(cell, row) {
			return parse('<a href="https://www.facebook.com/'+cell+'" target="_blank" >'+cell+'</a>');
		}
		const columns = [{
			dataField: 'idfbtuongtac',
			text: 'ID Seeding',
			sort: true,
			formatter: formatFbName,
		}, {
			dataField: 'ngay_tuongtac',
			text: 'Thời gian',
			sort: true,
		}];
        return (
            <Fragment>
                <Modal centered={true} size="lg" toggle={(e) => {this.props.closeModalNhatKy('viplike_clone');this.props.openModal()}} isOpen={isOpenModal}>
                    <div className="modal-header">
                        <p className="mb-0 text-dark">Nhật ký hoạt động</p>
                        <button onClick={(e) => {this.props.closeModalNhatKy('viplike_clone');this.props.openModal()}} type="button" className="close">×</button>
                    </div>
                    <ModalBody>
                       <ToolkitProvider keyField="idfbtuongtac" data={ dataReturn } columns={ columns } search >
							{
								props => (
									<div>
										<SearchBar { ...props.searchProps } />
										<BootstrapTable { ...props.baseProps } pagination={ paginationFactory() } wrapperClasses="table-responsive" striped hover condensed />
									</div>
								)
							}
						</ToolkitProvider>
                    </ModalBody>
                </Modal>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    buffviplikeclone: state.buffviplikeclone
});

export default connect(mapStateToProps, {NhatKyHoatDong, closeModalNhatKy}) (Modal_edit);
