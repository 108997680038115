
const initialState = {
	link_fb: '',
	loadding: false,
	id_fb: ''
};
export default function(state = initialState, action) {
  switch (action.type) {
    case 'LOADING_FIND_ID_FACEBOOK':
    	return {
			...state,
			loadding: action.payload
		};
	case 'ADD_ID_FACEBOOK':
    	return {
			...state,
			id_fb: action.payload
		};
    default:
      return state;
  }
}