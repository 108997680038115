import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import store from '../../../store';

import { addBLSC, updateBLSC, getID, getConfigMoDong, getIDVideo, getIDPost } from '../../../actions/bufflikesubcommentActions';
import Swal from 'sweetalert2';
import NumberFormat from 'react-number-format';
import parse from 'html-react-parser';
import ServicesDescription from "../ServicesDescription";
import { isMobile } from 'react-device-detect';

import iconLikeFb from '../../../iconfb/like.svg';
import iconHahaFb from '../../../iconfb/haha.svg';
import iconAngryFb from '../../../iconfb/angry.svg';
import iconLoveFb from '../../../iconfb/love.svg';
import iconSadFb from '../../../iconfb/sad.svg';
import iconWowFb from '../../../iconfb/wow.svg';
import iconCareFb from '../../../iconfb/care.svg';

class taotientrinh extends Component {
	static propTypes =  {
		addBSLV: PropTypes.func.isRequired,
		updateBLSC: PropTypes.func.isRequired,
		getID: PropTypes.func.isRequired,
		getIDVideo: PropTypes.func.isRequired,
		getIDPost: PropTypes.func.isRequired,
	};
	
	state = {
		showDescription: isMobile? false : true
	};
	
	componentDidMount() {
		this.props.getConfigMoDong();
	};
	
    async getIDFacebook(t) {
        let e = null;
        if (t) {
            if (t.indexOf("facebook.com") < 0 && t.indexOf("fb.watch") < 0 && t.indexOf("fb.gg") < 0 && t.indexOf("fb") < 0) e = t;
            else {
                var h = t.match(/substory_index/),
					s = t.match(/(.*)\/posts\/([0-9]{8,})/),
                    q = t.match(/(.*)\/posts\/(.*)/),
                    a = t.match(/(.*)\/photo.php\?fbid=([0-9]{8,})/),
                    k = t.match(/(.*)\/photo\/([0-9]{8,})\?/),
                    i = t.match(/(.*)\/video.php\?v=([0-9]{8,})/),
                    n = t.match(/(.*)\/watch\/\?v=([0-9]{8,})/),
                    o = t.match(/(.*)\/watch\/live\/\?v=([0-9]{8,})/),
                    r = t.match(/story.php/),
                    u = t.match(/story_fbid/),
                    l = t.match(/permalink/),
                    c = t.match(/(.*)\/([0-9]{8,})/),
                    f = t.match(/(.*)fbid=([0-9]{8,})/),
					d = t.match(/(.*)comment_id=([0-9]{8,})/),
					g = t.match(/(.*)set=a.([0-9]{8,})/),
					
					z = t.match(/live\/\?v=([0-9]+)/),
                    x = t.match(/videos\/([0-9]+)/),
                    y = t.match(/watch_permalink&v=([0-9]+)/),
                    m = t.match(/ref=sharing&v=([0-9]+)/),
                    p = t.match(/(.*)?v=([0-9]{8,})/);
                if (h !== null) {
					return await this.props.getIDPost(t);
                } else if (s !== null) {
                    return await this.props.getIDPost(t);
                } else if (q !== null) {
					return await this.props.getIDPost(t);
                } else if (a !== null) {
                    return a[2];
                } else if (k !== null) {
                    return k[2];
                } else if (i !== null) {
                    return i[2];
                } else if (n !== null) {
                    return n[2];
                } else if (o !== null) {
                    return o[2];
                } else if (r !== null) {
					return await this.props.getIDPost(t);
                } else if (l !== null) {
					return await this.props.getIDPost(t);
                } else if (u !== null) {
					return await this.props.getIDPost(t);
                } else if (c !== null) {
                    return c[2];
                } else if (f !== null) {
                    return f[2];
                } else if (d !== null) {
                    return d[2];
                } else if (g !== null) {
                    return g[2];
                } else if (z !== null) {
                    return z[1];
                } else if (x !== null) {
                    return x[1];
                } else if (y !== null) {
                    return y[1];
                } else if (m !== null) {
                    return m[1];
                } else if (p !== null) {
                    return p[2];
                } else if (t.indexOf("fb.watch") > -1 || t.indexOf("fb.gg") > -1) {
					return await this.props.getIDVideo(t);
                } else {
					return await this.props.getIDPost(t);
                }
            }
            return e
        }
    }
	
	onChange = async (e) => {
		if ((e.target.name === "lhi") && (e.target.value.indexOf("facebook.com") > -1 || e.target.value.indexOf("fb.watch") > -1 || e.target.value.indexOf("fb.gg") > -1)) {
			if (e.target.value !== "") {
				const { taotientrinh } = this.props.bufflikesubcomment;
				if (taotientrinh.loai === 'likepost' || taotientrinh.loai === 'comment') {
					var new_taotientrinh = {
						...taotientrinh,
						[e.target.name]: await this.getIDFacebook(e.target.value)
					};
				} else {
					var new_taotientrinh = {
						...taotientrinh,
						[e.target.name]: e.target.value
					};
					this.props.getID(new_taotientrinh);
				}
			}
		} else if (e.target.name === "loai" || e.target.name === "goi") {
			if (e.target.value !== "") {
				var { taotientrinh } = this.props.bufflikesubcomment;
				var value = 0;
				var loai = "";
				var goi = "";
				if (e.target.name === "loai") {
					loai = e.target.value;
					if (e.target.value === "likepost") {
						goi = "0";
					} else if (e.target.value === "sub") {
						goi = "0";
					}  else if (e.target.value === "comment") {
						goi = "0";
					}  else if (e.target.value === "likefanpage") {
						goi = "0";
					} else {
						goi = taotientrinh.goi;
					}
				}
				if (e.target.name === "goi") {
					loai = taotientrinh.loai;
					goi = e.target.value;
				}
				
				if (loai === "likepost" && goi === "0") {
					value = this.props.bufflikesubcomment.price.bufflikesubcomment_likepost_1.prices_web;
					taotientrinh = {
						...taotientrinh,
						"goi": "0",
					};
				}
				if (loai === "sub" && goi === "0") {
					value = this.props.bufflikesubcomment.price.bufflikesubcomment_sub_1.prices_web;
					taotientrinh = {
						...taotientrinh,
						"goi": "0",
					};
				}
				if (loai === "comment") {
					value = this.props.bufflikesubcomment.price.bufflikesubcomment_comment_1.prices_web;
					taotientrinh = {
						...taotientrinh,
						"goi": "0",
					};
				}
				if (loai === "likefanpage") {
					value = this.props.bufflikesubcomment.price.bufflikesubcomment_likefanpage_1.prices_web;
					taotientrinh = {
						...taotientrinh,
						"goi": "0",
					};
				}
				var new_taotientrinh = {
					...taotientrinh,
					[e.target.name]: e.target.value,
					"gtmtt": value,
				};
				this.props.updateBLSC(new_taotientrinh);
			}
		} else {
			const { taotientrinh } = this.props.bufflikesubcomment;
			var new_taotientrinh = {
				...taotientrinh,
				[e.target.name]: e.target.value
			};
		}
		this.props.updateBLSC(new_taotientrinh);
	}
	
	taoTienTrinhfunc = () => {
		const { taotientrinh } = this.props.bufflikesubcomment;
		
		if (taotientrinh.lhi === "") {
			Swal.fire({
				icon: 'error',
				title: 'Lỗi',
				text: 'Link hoặc ID không được để trống',
			});
			return false;
		}
		if (taotientrinh.gtmtt === "") {
			Swal.fire({
				icon: 'error',
				title: 'Lỗi',
				text: 'Chọn giá tiền mỗi tương tác',
			});
			return false;
		}
		if (taotientrinh.slct === "") {
			Swal.fire({
				icon: 'error',
				title: 'Lỗi',
				text: 'Chọn số lượng cần tăng',
			});
			return false;
		}
		var price = Math.round((taotientrinh.slct * taotientrinh.gtmtt + Number.EPSILON) * 100) / 100;
		var new_taotientrinh = {
			...taotientrinh,
			"type_api": "bufflikesubcomment"
		};
		Swal.fire({
			title: 'Bạn có chắc?',
			html: "Bạn có chắc chắn muốn buff "+taotientrinh.slct+" với giá: "+price+" Coin? Khi đã bấm đồng ý đồng nghĩa với việc bạn đã đọc hết mục thông tin dịch vụ!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Đồng ý',
			cancelButtonText: 'Không'
		}).then((result) => {
			if (result.value) {
				this.props.addBLSC(new_taotientrinh);
			}
		})
	}
	
	onClickShowDescription = (data) => {
		this.setState({
			showDescription: data
		});
	}
	
	render() {
		const { lhi, camxuc, ndcmt, loai, goi, slct, gtmtt } = this.props.bufflikesubcomment.taotientrinh;
		const { facebookbuffsubtay_sv1_mo_dong, facebookbufflikefanpagetay_sv1_mo_dong, facebookbufflikeposttay_sv1_mo_dong } = this.props.bufflikesubcomment;
		var price = Math.round((slct * gtmtt + Number.EPSILON) * 100) / 100;
		
		var listDataServicesInfo = {};
		try {
			if (localStorage.getItem("listDataServicesInfo") != null) {
				listDataServicesInfo = JSON.parse(localStorage.getItem("listDataServicesInfo"));
			} else {
				listDataServicesInfo = this.props.adminpage.listDataServicesInfo;
			}
		} catch(e) {
			listDataServicesInfo = {};
		}
		var html_haydoctranhmattien = '';
		var html_haydoctranhmattien_chinh = '';
		var html_haydoctranhmattien_sv = '';
		var html_likesubtay_sub_sv1 = '';
		var html_likesubtay_likepage_sv1 = '';
		var html_likesubtay_likepost_sv1 = '';
		if (typeof(listDataServicesInfo.facebook_buff_likesubtay_tab) !== 'undefined') {
			html_haydoctranhmattien = parse(listDataServicesInfo.facebook_buff_likesubtay_tab.ghichu);
			html_haydoctranhmattien_chinh = parse(listDataServicesInfo.facebook_buff_likesubtay_tab.ghichu_chinh);
		}
		if (typeof(listDataServicesInfo.facebook_buff_likesubtay_sub_sv1) !== 'undefined') {
			html_likesubtay_sub_sv1 = parse(listDataServicesInfo.facebook_buff_likesubtay_sub_sv1.ghichu_sv);
			if (loai+goi === "sub0") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.facebook_buff_likesubtay_sub_sv1.ghichu);
			}
		}
		if (typeof(listDataServicesInfo.facebook_buff_likesubtay_likepage_sv1) !== 'undefined') {
			html_likesubtay_likepage_sv1 = parse(listDataServicesInfo.facebook_buff_likesubtay_likepage_sv1.ghichu_sv);
			if (loai+goi === "likefanpage0") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.facebook_buff_likesubtay_likepage_sv1.ghichu);
			}
		}
		if (typeof(listDataServicesInfo.facebook_buff_likesubtay_likepost_sv1) !== 'undefined') {
			html_likesubtay_likepost_sv1 = parse(listDataServicesInfo.facebook_buff_likesubtay_likepost_sv1.ghichu_sv);
			if (loai+goi === "likepost0") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.facebook_buff_likesubtay_likepost_sv1.ghichu);
			}
		}
		
		return (
			<Fragment>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Link hoặc ID:</label>
					<div className="col-sm-8">
						<input type="text" className="form-control" id="lhi" name="lhi" placeholder="" onChange={this.onChange} value={lhi} />
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Chọn loại cần Buff:</label>
					<div className="col-sm-8">
						<div className="custom-control custom-radio custom-control-inline">
							<input onChange={this.onChange} value="sub" type="radio" className="custom-control-input" id="loai_2" name="loai" checked={loai === "sub"}/>
							<label className="custom-control-label" htmlFor="loai_2" > Buff Sub (Theo dõi nick cá nhân)</label>
						</div>
						{/* <div className="custom-control custom-radio custom-control-inline">
							<input onChange={this.onChange} value="comment" type="radio" className="custom-control-input" id="loai_3" name="loai" checked={loai === "comment"}/>
							<label className="custom-control-label" htmlFor="loai_3" > Buff Comment</label>
						</div> */}
						<div className="custom-control custom-radio custom-control-inline">
							<input onChange={this.onChange} value="likefanpage" type="radio" className="custom-control-input" id="loai_4" name="loai" checked={loai === "likefanpage"} />
							<label className="custom-control-label" htmlFor="loai_4" > Buff Like Fanpage (Theo dõi Page)</label>
						</div>
						<div className="custom-control custom-radio custom-control-inline">
							<input onChange={this.onChange} value="likepost" type="radio" className="custom-control-input" id="loai_1" name="loai" checked={loai === "likepost"} />
							<label className="custom-control-label" htmlFor="loai_1" > Buff Like Post</label>
						</div>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Chọn gói {loai === "likepost" ? 'LIKE POST' : ''} {loai === "sub" ? 'SUB' : ''} {loai === "comment" ? 'COMMENT' : ''} {loai === "likefanpage" ? 'LIKE FANPAGE' : ''} cần Buff:</label>
					<div className="col-sm-8">
						{
							loai === "sub" ? 
								<div className="custom-control custom-radio" style={{opacity: this.props.bufflikesubcomment.price.bufflikesubcomment_sub_1.prices_web == 0 ? '0.3' : '1' }}>
									<input onChange={this.onChange} value="0" type="radio" className="	custom-control-input" id="goi_0" name="goi" checked={goi === "0"} disabled={this.props.bufflikesubcomment.price.bufflikesubcomment_sub_1.prices_web == 0 ? 'disabled' : ''} />
									<label className="custom-control-label" htmlFor="goi_0" >SV1 {html_likesubtay_sub_sv1} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.bufflikesubcomment.price.bufflikesubcomment_sub_1.prices_web} coin</span> {facebookbuffsubtay_sv1_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
								</div>
							: ''
						}
						{
							loai === "comment" ? 
								<div className="custom-control custom-radio" style={{opacity: this.props.bufflikesubcomment.price.bufflikesubcomment_comment_1.prices_web == 0 ? '0.3' : '1' }}>
									<input onChange={this.onChange} value="0" type="radio" className="	custom-control-input" id="goi_0" name="goi" checked={goi === "0"} disabled={this.props.bufflikesubcomment.price.bufflikesubcomment_comment_1.prices_web == 0 ? 'disabled' : ''} />
									<label className="custom-control-label" htmlFor="goi_0" >SV1 <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.bufflikesubcomment.price.bufflikesubcomment_comment_1.prices_web} coin</span> </label>
								</div>
							: ''
						}
						{
							loai === "likefanpage" ? 
								<div className="custom-control custom-radio" style={{opacity: this.props.bufflikesubcomment.price.bufflikesubcomment_likefanpage_1.prices_web == 0 ? '0.3' : '1' }}>
									<input onChange={this.onChange} value="0" type="radio" className="	custom-control-input" id="goi_0" name="goi" checked={goi === "0"} disabled={this.props.bufflikesubcomment.price.bufflikesubcomment_likefanpage_1.prices_web == 0 ? 'disabled' : ''} />
									<label className="custom-control-label" htmlFor="goi_0" >SV1 {html_likesubtay_likepage_sv1} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.bufflikesubcomment.price.bufflikesubcomment_likefanpage_1.prices_web} coin</span> {facebookbufflikefanpagetay_sv1_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
								</div>
							: ''
						}
						{
							loai === "likepost" ? 
								<div className="custom-control custom-radio" style={{opacity: this.props.bufflikesubcomment.price.bufflikesubcomment_likepost_1.prices_web == 0 ? '0.3' : '1' }}>
									<input onChange={this.onChange} value="0" type="radio" className="	custom-control-input" id="goi_0" name="goi" checked={goi === "0"} disabled={this.props.bufflikesubcomment.price.bufflikesubcomment_likepost_1.prices_web == 0 ? 'disabled' : ''} />
									<label className="custom-control-label" htmlFor="goi_0" >SV1 {html_likesubtay_likepost_sv1} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.bufflikesubcomment.price.bufflikesubcomment_likepost_1.prices_web} coin</span> {facebookbufflikeposttay_sv1_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
								</div>
							: ''
						}
					</div>
				</div>
				
				
				<div className="row mt-2">
					<div className="col-md-4">
						<label className="mt-2">Số lượng cần tăng:</label>
					</div>
					<div className="col-md-8">
						<div className="form-group">
							<input type="number" className="form-control" onChange={this.onChange} name="slct" value={slct}/>
						</div>
					</div>
				</div>
				
				{
					loai === "comment" ?
						<div className="form-group row">
							<label className="col-sm-4 col-form-label" htmlFor="">Nội Dung Comment (Mỗi nội dung 1 dòng!):</label>
							<div className="col-sm-8">
								<textarea rows="6" placeholder="Mỗi dòng 1 nội dung. Đừng điền icon, nội dung chữ thôi" className="form-control input-gray" id="ndcmt" name="ndcmt" onChange={this.onChange} ></textarea>
							</div>
						</div>
					: ''
				}
				
				<div className="row mt-2">
					<div className="col-md-4">
						<label className="mt-2">Giá tiền mỗi tương tác:</label>
					</div>
					<div className="col-md-8">
						<div className="form-group">
							<input type="number" className="form-control" onChange={this.onChange} name="gtmtt" value={gtmtt}/>
						</div>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Ghi chú:</label>
					<div className="col-sm-8">
						<textarea rows="2" placeholder="Nhập nội dung ghi chú về tiến trình của bạn" className="form-control input-gray" id="gc" name="gc" onChange={this.onChange} ></textarea>
					</div>
				</div>
				
				<button type="button" className="btn badge-primary-cus btn-rounded btn-show-description-cus" onClick={(e) => {this.onClickShowDescription(!this.state.showDescription)}}>
					{
						this.state.showDescription?
							<i className="fa fa-angle-up" style={{"marginRight": "5px"}} aria-hidden="true"></i>
						:
							<i className="fa fa-angle-down" style={{"marginRight": "5px"}} aria-hidden="true"></i>
					}
					Thông tin dịch vụ
				</button>
				
				<ServicesDescription allservicesinfo={listDataServicesInfo} server={loai+goi} tab="facebook_buff_likesubtay" giatien={gtmtt} baohanh={0} showDescription={this.state.showDescription} />
				
				<div className="row mt-2" style={{"display": this.state.showDescription? "flex" : "none"}}>
				    <div className="col-md-12 bold" style={{"marginBottom": "10px"}}>
						<div className="card card-select-cus">
							<div className="card-body card-body-select-cus">
								<p className="mb-0 mt-3 text-wrap">
									<ul>
										<li>1 ID KHÔNG CÀI 2 ĐƠN CÙNG LÚC TRONG HỆ THỐNG ! ĐƠN CŨ XONG MỚI CÀI ĐƠN MỚI ! CỐ TÌNH CÀI BỊ HỤT SỐ LƯỢNG SẼ KHÔNG XỬ LÝ !</li>
										<li>Nếu ID đang chạy trên hệ thống mà bạn vẫn mua id đó cá hệ thống bên khác, nếu có tình trạng hụt số lượng giữa 2 bên thì sẽ không được xử lí!</li>
										{html_haydoctranhmattien}
										{html_haydoctranhmattien_chinh}
										{html_haydoctranhmattien_sv}
										<li>Nếu gặp lỗi hãy nhắn tin hỗ trợ phía bên phải góc màn hình hoặc vào mục liên hệ hỗ trợ để được hỗ trợ tốt nhất!</li>
									</ul>
								</p>
								<span className="badge badge-cus position-absolute-cus badge-top-left-cus badge-primary-cus badge-pill-cus"> HÃY ĐỌC ĐỂ TRÁNH MẤT TIỀN KHI SỬ DỤNG! </span>
							</div>
						</div>
				    </div>
				</div>
				<div className="row mt-4">
					<div className="col-12 text-center bold">
						<div className="card card-info">
							<div className="card-body">
								<h5 className="font-bold">Tổng: <span className="bold green"><span>{price}</span> Coin</span></h5>
								<h6 className="mb-0">Bạn sẽ buff <span className="bold green">{slct} {loai === "likepost" ? 'LIKE POST' : ''} {loai === "sub" ? 'SUB' : ''} {loai === "comment" ? 'COMMENT' : ''} {loai === "likefanpage" ? 'LIKE FANPAGE' : ''}</span> với giá <span className="green bold">{price}</span> Coin</h6>
							</div>
						</div>
					</div>
				</div>
				<div className="row my-4">
					<div className="col-12 text-center bold">
						<button type="button" className="btn btn-block btn-rounded btn-hero-primary" onClick={this.taoTienTrinhfunc} >Tạo tiến trình</button>
					</div>
				</div>
			</Fragment>
		)
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	bufflikesubcomment: state.bufflikesubcomment,
});
const mapDispatchToProps = { addBLSC, updateBLSC, getID, getConfigMoDong, getIDVideo, getIDPost }

export default connect(mapStateToProps, mapDispatchToProps)(taotientrinh);