import axios from "axios";
import Swal from 'sweetalert2';

export const checkDaiLy = () => (dispatch, getState) => {
	const config = {
		headers: {
			'Content-Type': 'application/json'
		}
	}
	axios.get('/api/server/url', config)
		.then(res => {
			if (res.data.status !== 200) {
				dispatch({
					type: 'UPDATE_ISDAILY',
					payload: 1
				});
			} else {
				dispatch({
					type: 'UPDATE_ISDAILY',
					payload: 0
				});
			}
		});
};

export const nhapTokenDaiLy = (body) => (dispatch, getState) => {
	const config = {
		headers: {
			'Content-Type': 'application/json'
		}
	}
	dispatch({
	  type: 'LOADING_AUTH',
	  payload: true
    });
	var bodysend = {
		"url_daily": window.location.hostname,
		"tokendaily": body
	};
	axios.post('/api/server/nhaptokendaily', bodysend, config)
		.then(res => {
			dispatch({
				type: 'LOADING_AUTH',
				payload: false
			});
			if (res.data.status === 200) {
				Swal.fire({
					icon: 'success',
					html: res.data.message
				});
				dispatch(checkDaiLy());
			} else {
				Swal.fire({
					icon: 'error',
					html: res.data.message
				});
			}
		});
};