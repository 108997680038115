import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Swal from 'sweetalert2';
import copy from 'copy-text-to-clipboard';

class Joinchu extends Component {
	state = {
		noidunga: "ey1\ney2",
		text_placeholdera: "ey1\ney2",
		noidungb: "id1|500\nid2|10000",
		text_placeholderb: "id1|500\nid2|10000",
		subpertoken: "500",
		ketqua: "",
	}
	onClick = (e) =>{
		this.setState({
			[e.target.name]: e.target.value
		})
	}
	onChange = (e) =>{
		this.setState({
			[e.target.name]: e.target.value
		})
	}
	onTrigger = (e) => {
		var { noidunga, noidungb } = this.state;
		noidunga = noidunga.trim();
		noidungb = noidungb.trim();
		noidunga = noidunga
			.split("\n")
			.filter(item => item)
			.join("\n");
		noidungb = noidungb
			.split("\n")
			.filter(item => item)
			.join("\n");
		var noidung = "Chức năng chưa hoạt động!";
		this.setState({
			ketqua: noidung
		});
	}
	
	render() {
		return (
			<Fragment>
				<div className="form-group row">
					<label className="col-sm-2 col-form-label edittext-label-right" htmlFor="">Nội dung A</label>
					<div className="col-sm-10">
						<textarea rows="5" placeholder={this.state.text_placeholdera} className="form-control input-gray edittext-textarea" name="noidunga" onChange={this.onChange} value={this.state.noidunga}  ></textarea>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-2 col-form-label edittext-label-right" htmlFor="">Nội dung B</label>
					<div className="col-sm-10">
						<textarea rows="5" placeholder={this.state.text_placeholderb} className="form-control input-gray edittext-textarea" name="noidungb" onChange={this.onChange} value={this.state.noidungb}  ></textarea>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-2 col-form-label edittext-label-right" htmlFor="">Sub Per Token</label>
					<div className="col-sm-10">
						<input type="text" className="form-control" name="subpertoken" value={this.state.subpertoken} onChange={this.onChange} />
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-2 col-form-label edittext-label-right" htmlFor=""></label>
					<div className="col-sm-10">
						<button type="button" className="btn btn-rounded btn-hero-primary edittext-btn" onClick={this.onTrigger} >Trigger</button>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-2 col-form-label edittext-label-right" htmlFor=""></label>
					<div className="col-sm-10">
						<textarea rows="5" placeholder="" className="form-control input-gray edittext-textarea" name="ketqua" defaultValue={this.state.ketqua} ></textarea>
					</div>
				</div>
			</Fragment>
		);
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
});
const mapDispatchToProps = {  };

export default connect(mapStateToProps, mapDispatchToProps)(Joinchu);
