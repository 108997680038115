import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Userinformation from "../Userinformation";

class sitecontent extends Component {
	render() {
		return (
			<Fragment>
				<Userinformation />
				<div className="card card-orange mt-3">
					<div className="card-body">
						<p className="font-bold text-danger">Lưu ý:</p>
						<p className="font-bold text-danger">- Nghiêm cấm Buff các ID Seeding có nội dung vi phạm pháp luật, chính trị, đồ trụy... Nếu cố tình buff bạn sẽ bị trừ hết tiền và ban khỏi hệ thống vĩnh viễn, và phải chịu hoàn toàn trách nhiệm trước pháp luật.</p>
						<p >Hỗ trợ tăng mắt 24/24h .</p>
						<p >Số lượng mắt sẽ tăng trong khoảng từ 70% -&gt; 100% số lượng mắt đã mua .</p>
						<p >ví dụ bạn mua Thời gian sử dụng: 30 ngày tổng số live trong thời gian dùng là 30 live trong 30 ngày đó họ live 10 lần thì tổng số ngày còn lại khi hết thời gian sử dụng chỉ còn 20 live.</p>
						<p >Mắt live sẽ chạy trên cả trang cá nhân và nhóm với ID nhóm công khai bạn điền vào.</p>
						<p >Cám ơn đã sử dụng dịch vụ của chúng tôi.</p>
					</div>
				</div>
			</Fragment>
		)
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
});
const mapDispatchToProps = { }

export default connect(mapStateToProps, mapDispatchToProps)(sitecontent);