import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Swal from 'sweetalert2';
import copy from 'copy-text-to-clipboard';

class Trunglap extends Component {
	state = {
		loaibotrunglap: true,
		noidunga: "one\ntwo\nthree",
		text_placeholdera: "one\ntwo\nthree",
		noidungb: "one\ntwo",
		text_placeholderb: "one\ntwo",
		thucthi: 1,
		ketqua: "",
	}
	onChangeTrungLap = (e) =>{
		this.setState({
			[e.target.name]: !this.state.loaibotrunglap
		})
	}
	onClick = (e) =>{
		this.setState({
			[e.target.name]: e.target.value
		})
	}
	onChange = (e) =>{
		this.setState({
			[e.target.name]: e.target.value
		})
	}
	onTrigger = (e) => {
		var { loaibotrunglap, noidunga, noidungb, thucthi } = this.state;
		noidunga = noidunga.trim();
		noidungb = noidungb.trim();
		noidunga = noidunga
			.split("\n")
			.filter(item => item)
			.join("\n");
		noidungb = noidungb
			.split("\n")
			.filter(item => item)
			.join("\n");
		var noidung = noidunga.concat(noidungb);
		if (loaibotrunglap) {
			noidunga = noidunga
				.split("\n")
				.filter((item, i, allItems) => {
					return i === allItems.indexOf(item);
				})
				.join("\n");
			noidungb = noidungb
				.split("\n")
				.filter((item, i, allItems) => {
					return i === allItems.indexOf(item);
				})
				.join("\n");
		}
		if (thucthi == 1) {
			var final_noidunga_tmp = noidunga.split("\n");
			var final_noidungb_tmp = noidungb.split("\n");
			var final_noidunga = [];
			var final_noidungb = [];
			final_noidunga = final_noidunga_tmp.filter(function(val) {
				return final_noidungb_tmp.indexOf(val) == -1;
			});
			final_noidungb = final_noidungb_tmp.filter(function(val) {
				return final_noidunga_tmp.indexOf(val) == -1;
			});
			noidung = (final_noidunga.concat(final_noidungb)).join("\n");
		}
		if (thucthi == 2) {
			var final_noidunga = noidunga.split("\n");
			var final_noidungb = noidungb.split("\n");
			final_noidunga = final_noidungb.filter(function(val) {
				return final_noidunga.indexOf(val) != -1;
			});
			noidung = final_noidunga.join("\n");
		}
		this.setState({
			ketqua: noidung.trim()
		});
	}
	
	render() {
		return (
			<Fragment>
				<div className="form-group row">
					<label className="col-sm-2 col-form-label edittext-label-right" htmlFor="">Loại bỏ trùng lặp?</label>
					<div className="col-sm-10" style={{"display": "flex", "alignItems": "center"}}>
						<div className="custom-control custom-switch mb-1">
							<input onChange={(e) => {this.onChangeTrungLap(e)}} type="checkbox" className="custom-control-input" id="loaibotrunglap" name="loaibotrunglap" checked={this.state.loaibotrunglap} />
							<label className="custom-control-label edittext-custom-control-label" htmlFor="loaibotrunglap" ></label>
						</div>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-2 col-form-label edittext-label-right" htmlFor="">Nội dung A</label>
					<div className="col-sm-10">
						<textarea rows="5" placeholder={this.state.text_placeholdera} className="form-control input-gray edittext-textarea" name="noidunga" onChange={this.onChange} value={this.state.noidunga} ></textarea>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-2 col-form-label edittext-label-right" htmlFor="">Nội dung B</label>
					<div className="col-sm-10">
						<textarea rows="5" placeholder={this.state.text_placeholderb} className="form-control input-gray edittext-textarea" name="noidungb" onChange={this.onChange} value={this.state.noidungb} ></textarea>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-2 col-form-label edittext-label-right" htmlFor="">Thực thi</label>
					<div className="col-sm-10">
						<div className="btn-group" role="group" aria-label="">
							<button type="button" className={"btn btn-outline-primary " + (this.state.thucthi == 1 ? "active" : "")} name="thucthi" value="1" onClick={(e) => {this.onClick(e)}}>Bỏ dòng trùng</button>
							<button type="button" className={"btn btn-outline-primary " + (this.state.thucthi == 2 ? "active" : "")} name="thucthi" value="2" onClick={(e) => {this.onClick(e)}}>Giữ dòng trùng</button>
						</div>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-2 col-form-label edittext-label-right" htmlFor=""></label>
					<div className="col-sm-10">
						<button type="button" className="btn btn-rounded btn-hero-primary edittext-btn" onClick={this.onTrigger} >Trigger</button>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-2 col-form-label edittext-label-right" htmlFor=""></label>
					<div className="col-sm-10">
						<textarea rows="5" placeholder="" className="form-control input-gray edittext-textarea" name="ketqua" defaultValue={this.state.ketqua} ></textarea>
					</div>
				</div>
			</Fragment>
		);
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
});
const mapDispatchToProps = {  };

export default connect(mapStateToProps, mapDispatchToProps)(Trunglap);
