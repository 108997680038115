function date7dnext() {
	var result = new Date();
  	result.setDate(result.getDate() + 7);
  	return result;
}

function addDays(date, days) {
  	var result = new Date(date);
  	result.setDate(result.getDate() + days);
  	return result;
}

const initialState = {
	loadingblock: false,
	listBuffSub: [],
	datapricebuffsub: {
		buffsub: {
			min_bh_web: 0,
			prices_web: 0,
		},
		buffsub_sv2: {
			min_bh_web: 0,
			prices_web: 0,
		},
		buffsub_sv3: {
			min_bh_web: 0,
			prices_web: 0,
		},
		buffsub_sv4: {
			min_bh_web: 0,
			prices_web: 0,
		},
		buffsub_sv5: {
			min_bh_web: 0,
			prices_web: 0,
		},
		buffsub_sv6: {
			min_bh_web: 0,
			prices_web: 0,
		},
		buffsub_sv7: {
			min_bh_web: 0,
			prices_web: 0,
		},
		buffsub_sv8: {
			min_bh_web: 0,
			prices_web: 0,
		},
		buffsub_sv9: {
			min_bh_web: 0,
			prices_web: 0,
		},
	},
	formData: {
		locnangcao: 0,
		locnangcao_gt: 0,
		locnangcao_dotuoi_start: 0,
		locnangcao_dotuoi_end: 0,
		locnangcao_banbe_start: 0,
		locnangcao_banbe_end: 0,
		profile_user: '',
		loaiseeding: 2,
		baohanh: 0,
		sltang: 100,
		giatien: 0,
		ghichu: '',
		startDatebh: new Date(),
		EndDatebh: date7dnext(),
	},
	facebookbuffsub_sv1_mo_dong: 1,
	facebookbuffsub_sv2_mo_dong: 1,
	facebookbuffsub_sv3_mo_dong: 1,
	facebookbuffsub_sv4_mo_dong: 1,
	facebookbuffsub_sv5_mo_dong: 1,
	facebookbuffsub_sv6_mo_dong: 1,
	facebookbuffsub_sv7_mo_dong: 1,
	facebookbuffsub_sv8_mo_dong: 1,
	facebookbuffsub_sv9_mo_dong: 1,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case 'LIST_BUFF_SUB_USER':
    	return {
			...state,
			listBuffSub: action.payload
		};
    case 'LOADING_BLOCK_BUFF_SUB':
		return {
			...state,
			loadingblock: action.payload
		};
	case 'DATA_PRICE_IS_FUNCTION_BUFF_SUB':
		return {
			...state,
			datapricebuffsub: mergePrice(action.payload)
		}
	case 'CHANGE_DATA_FORM_FB_BUFFSUB':
		return {
			...state,
			formData: action.payload
		}
	case 'CONFIG_MO_DONG_BUFFSUB':
		return {
			...state,
			facebookbuffsub_sv1_mo_dong: action.payload.facebookbuffsub_sv1_mo_dong,
			facebookbuffsub_sv2_mo_dong: action.payload.facebookbuffsub_sv2_mo_dong,
			facebookbuffsub_sv3_mo_dong: action.payload.facebookbuffsub_sv3_mo_dong,
			facebookbuffsub_sv4_mo_dong: action.payload.facebookbuffsub_sv4_mo_dong,
			facebookbuffsub_sv5_mo_dong: action.payload.facebookbuffsub_sv5_mo_dong,
			facebookbuffsub_sv6_mo_dong: action.payload.facebookbuffsub_sv6_mo_dong,
			facebookbuffsub_sv7_mo_dong: action.payload.facebookbuffsub_sv7_mo_dong,
			facebookbuffsub_sv8_mo_dong: action.payload.facebookbuffsub_sv8_mo_dong,
			facebookbuffsub_sv9_mo_dong: action.payload.facebookbuffsub_sv9_mo_dong,
		};
    default:
      return state;
  }
}

function mergePrice (arr_price) {
	const dataOld = initialState.datapricebuffsub;
	let dataNew = {
		...dataOld
	}
	if (arr_price.length > 0) {
		arr_price.forEach(function(v, i){
			if (v.name_table === 'buffsub') {
				dataNew.buffsub = v;
			} else if (v.name_table === 'buffsub_sv2') {
				initialState.formData.giatien = v.prices_web;
				dataNew.buffsub_sv2 = v;
			} else if (v.name_table === 'buffsub_sv3') {
				dataNew.buffsub_sv3 = v;
			} else if (v.name_table === 'buffsub_sv4') {
				dataNew.buffsub_sv4 = v;
			} else if (v.name_table === 'buffsub_sv5') {
				dataNew.buffsub_sv5 = v;
			} else if (v.name_table === 'buffsub_sv6') {
				dataNew.buffsub_sv6 = v;
			} else if (v.name_table === 'buffsub_sv7') {
				dataNew.buffsub_sv7 = v;
			} else if (v.name_table === 'buffsub_sv8') {
				dataNew.buffsub_sv8 = v;
			} else if (v.name_table === 'buffsub_sv9') {
				dataNew.buffsub_sv9 = v;
			}
		});
		return dataNew;
	}
}
