import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { loadRNF, updateLimitRNF, updateTypeRNF, upHuyOrder } from '../../../actions/renamefanpageActions';
import {
	Modal,
	ModalBody,
	ModalFooter
} from 'reactstrap';
import Swal from 'sweetalert2';
import parse from 'html-react-parser';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import Modaledit from './modalEditAdmin';
import Moment from 'react-moment';
import { RiExchangeDollarLine } from "react-icons/ri";
const { SearchBar } = Search;

class nhatky extends Component {
	static propTypes =  {
		loadRNF: PropTypes.func.isRequired,
		upHuyOrder: PropTypes.func.isRequired,
		updateLimitRNF: PropTypes.func.isRequired,
		updateTypeRNF: PropTypes.func.isRequired,
	};
	
	state = {
		isOpenModalEdit: false,
		dataItemSelect: {},
		page: 1,
		obj_search: "",
	}
	
	componentDidMount() {
		this.props.loadRNF();
	}
	
	onChange = (e) => {
		this.setState({
			"obj_search": e.target.value,
			"page": 1
		});
	}
    getListByLimit = (page, type_button) => {
        var valueChange = parseInt(page);
		if (type_button == "prev") {
			valueChange -= 1;
			if (valueChange <= 0) {
				valueChange = 1;
			}
		}
		if (type_button == "next") {
			valueChange += 1;
		}
		this.setState({
			page: valueChange,
		});
		const { type } = this.props.renamefanpage;
		this.props.loadRNF(type, valueChange, this.state.obj_search);
    }
	getListByView = (type) => {
		this.setState({
			"page": 1,
		});
		this.props.updateTypeRNF(type);
		this.props.loadRNF(type, 1, this.state.obj_search);
	}
    getListBySearch = (e) => {
		this.setState({
			"page": 1,
		});
		const { type } = this.props.renamefanpage;
		this.props.loadRNF(type, 1, this.state.obj_search);
    }
	
	// onChangeLimit = e => {
		// const { type } = this.props.renamefanpage;
		// this.props.updateLimitRNF(e.target.value);
		// this.props.loadRNF(type, e.target.value);
	// };
	
	// onCheckList = (type) => {
		// const { limit_pagi } = this.props.renamefanpage;
		// this.props.updateTypeRNF(type);
		// this.props.loadRNF(type, limit_pagi);
	// }
	
	huyOrder = (id, orders_id) => {
		const swalWithBootstrapButtons = Swal.mixin({
			customClass: {
				confirmButton: 'btn btn-success an-margin-btn',
				cancelButton: 'btn btn-danger an-margin-btn'
				},
			buttonsStyling: false
		})

		swalWithBootstrapButtons.fire({
			title: 'HỦY REVIEW ID: '+orders_id+' ?',
			text: "Sau khi hủy bạn sẽ được hoàn lại số lượng chưa tăng sau 1 đến 3 ngày và trừ thêm 5.000 Coin phí để tránh Spam!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Đồng ý',
			cancelButtonText: 'Không',
			reverseButtons: true
		}).then((result) => {
			if (result.value) {
				let body = {
					"id": id,
					"type_api": "renamefanpage"
				};
				this.props.upHuyOrder(body);
			} else if (result.dismiss === Swal.DismissReason.cancel) {
				
			}
		})
	}
	
	onKeyPress = (e) => {
		if (!(e.charCode >= 48 && e.charCode <= 57)) {
			e.preventDefault();
		}
	}
	
    openModalEdit = (data = null) => {
		this.setState({
			...this.state,
			isOpenModalEdit: !this.state.isOpenModalEdit,
		});
		if (data !== null) {
			this.setState({
				...this.state,
				isOpenModalEdit: !this.state.isOpenModalEdit,
				id: data.id,
				thongbao: data.thongbao,
				status: data.status,
			});
		}
    }
	
	render() {
		const { listtientrinh, limit_pagi } = this.props.renamefanpage;
		const {isOpenModalEditAdmin, dataItemSelect} = this.state;
		// const { username } = this.props.auth.user;
		// var ar_listtientrinh = [];
		// listtientrinh.forEach(function(v, i) {
			// var tmp = {
				// ...v,
				// "site_username": username
			// };
			// ar_listtientrinh.push(tmp);
		// });
		
		function calBwDateToNow(cell, row) {
			if (Number.isInteger(cell)) {
				let date_now = Math.floor(Date.now() / 1000);
				let calDateDay = (date_now - cell)/86400;
				if (calDateDay > 1) {
					return Math.ceil(calDateDay) + ' ngày trước';
				} else {
					let calDatehour = (date_now - cell)/3600;
					if (calDatehour < 0.6) {
						return Math.ceil(calDatehour*100) + ' phút trước';
					} else {
						return Math.ceil(calDatehour) + ' giờ trước';
					}
				}
			}
		}
		
		function formatTrangThai(cell, row) {
			var message = '';
			if (cell === 0) {
				return (
					<span className="badge badge-primary"><i className="fas fa-check mr-1"></i>Hoàn thành</span>
				);
			} else if (cell === 1) {
				return (
					<span className="badge badge-success"><i className="fas fa-sync-alt mr-1"></i>Đang chạy</span>
				);
			} else if (cell === 2) {
				return (
					<span className="badge badge-warning"><i className="fas fa-sync-alt mr-1"></i>Chờ duyệt</span>
				);
			} else if (cell === 3) {
				return (
					<span className="badge badge-danger"><i className="fas fa-ban mr-1"></i>Hủy</span>
				);
			} else if (cell === 5) {
				return (
					<span className="badge badge-info"><RiExchangeDollarLine size={12} className="mr-1" />Hoàn tiền</span>
				);
			} else {
				return ('');
			}
		}
		function filterValueTrangThai(cell, row) {
			if (cell === 0) {
				return ('Hoàn thành');
			} else if (cell === 1) {
				return ('Đang chạy');
			} else if (cell === 2) {
				return ('Chờ duyệt');
			} else if (cell === 3) {
				return ('Hủy đơn');
			} else if (cell === 5) {
				return ('Hoàn tiền');
			} else {
				return ('');
			}
		}
		
		function formatQuaTay(cell, row) {
			if (cell === 0) {
				return ('Chưa');
			} else if (cell === 1) {
				return ('Rồi');
			} else if (cell === 2) {
				return ('Chưa xác định');
			} else {
				return ('');
			}
		}
		function formatThongBao(cell, row) {
			return cell != null ? parse(cell.trim().replace(/\n/g, '<br/>')) : '';
		}
		function formatNamTao(cell, row) {
			if (cell == '') {
				return ('Không biết');
			} else {
				return (cell);
			}
		}
		function formatLienHe(cell, row) {
			return parse('Số điện thoại: ' + row.sdt + '<br/>' + 'Facebook: <a href="https://facebook.com/'+row.fb+'" target="_blank" rel="noopener noreferrer">'+row.fb+'</a>');
		}
		
		const columns = [{
			dataField: 'id',
			text: 'ID',
			sort: true,
		}, {
		  text: 'Thao tác',
		  dataField: '-',
		  style: {
			'white-space' : 'nowrap'
		  },
		  formatter: (cell, row) => 
		  	<Fragment>
				{
					this.props.auth.user.type === 1 ?
						<Fragment>
							<button type="button" onClick={() => {this.openModalEdit(row)}} className="btn btn-hero-success text-center p-2 mr-1"><i className="fa fa-fw fa-pen"></i></button>
						</Fragment>
					: ''
				}
	    	</Fragment>
		}, {
			dataField: 'object_id',
			text: 'ID Fanpage',
			sort: true,
		    formatter: (cell, row) => 
				<Fragment>
					<a href={"https://facebook.com/"+cell} target="_blank" rel="noopener noreferrer">{cell}</a>
				</Fragment>
		}, {
			dataField: 'oldname',
			text: 'Tên Fanpage hiện tại',
			sort: true,
		}, {
			dataField: 'newname',
			text: 'Tên Fanpage cần đổi',
			sort: true,
		},{
			dataField: 'quatay',
			text: 'Qua tay',
			sort: true,
			formatter: formatQuaTay,
			filterValue: formatQuaTay
		},{
			dataField: 'namtao',
			text: 'Năm tạo',
			sort: true,
			formatter: formatNamTao,
			filterValue: formatNamTao
		},{
			dataField: 'ghichu',
			text: 'Ghi chú',
			sort: true,
		},{
			dataField: 'thongbao',
			text: 'Thông báo admin',
			sort: true,
			formatter: formatThongBao,
			filterValue: formatThongBao
		},{
			dataField: 'lienhe',
			text: 'Liên hệ người mua',
			sort: true,
			formatter: formatLienHe,
			filterValue: formatLienHe
		}, {
			dataField: 'prices',
			text: 'Giá',
			sort: true,
		}, {
			dataField: 'created_at',
			text: 'Khởi tạo',
			sort: true,
			formatter: calBwDateToNow,
			filterValue: calBwDateToNow
		}, {
			dataField: 'username',
			text: 'Username',
			sort: true,
		    classes: 'notranslate',
		}, {
			dataField: 'status',
			text: 'Trạng thái',
			sort: true,
			formatter: formatTrangThai,
			filterValue: filterValueTrangThai
		}];
		return (
			<Fragment>
				<ToolkitProvider keyField="id" data={ listtientrinh } columns={ columns } search >
					{
						props => (
							<div>
								<SearchBar { ...props.searchProps } />
								<div className="row mb-4">
									<div className="col-md-12">
										{ this.props.auth.user.type === 1 ?
											<Fragment>
												<button onClick={() => {this.getListByView(1)}} type="button" className="btn btn-primary mr-1">Xem tất cả</button>
												<button onClick={() => {this.getListByView(0)}} type="button" className="btn btn-primary mr-1">Xem riêng</button>
											</Fragment>
										: ''}
										{/* <select className="form-control custom-select select-light col-md-3 custom-limit_pagi" id="limit_pagi" name="limit_pagi" onChange={this.onChangeLimit} value={limit_pagi} >
											<option value="0">Số lịch sử hiển thị (0-500)</option>
											<option value="1">500-1000</option>
											<option value="2">1000-1500</option>
											<option value="3">1500-2000</option>
											<option value="4">2000-2500</option>
											<option value="5">2500-3000</option>
											<option value="7">3000-3500</option>
											<option value="8">3500-4000</option>
											<option value="9">4500-5000</option>
										</select> */}
										<button onClick={(e) => {this.getListByLimit(this.state.page, "prev")}} name="page" value={this.state.page} type="button" className="btn btn-primary mr-1">{"<"}</button>
										<button onClick={(e) => {this.getListByLimit(this.state.page, "next")}} name="page" value={this.state.page} type="button" className="btn btn-primary mr-1">{">"}</button>
										<button onClick={(e) => {this.getListBySearch(e)}} name="view" value="0" type="button" className="btn btn-primary mr-1">Tìm kiếm</button>
										<input  onChange={this.onChange} value={this.state.obj_search}  name="obj_search" type="text" className="form-control mr-1" style={{"width": "100px", "display": "inline-block"}}/>
									</div>
								</div>
								<BootstrapTable { ...props.baseProps } pagination={ paginationFactory() } wrapperClasses="table-responsive" striped hover condensed />
								{this.state.isOpenModalEdit ? <Modaledit openModal={this.openModalEdit} isOpenModal={this.state.isOpenModalEdit} dataid={this.state.id} datathongbao={this.state.thongbao} datastatus={this.state.status} /> : ''}
							</div>
						)
					}
				</ToolkitProvider>
				<div>
					{/*<BootstrapTable keyField='id' data={ ar_listtientrinh } columns={ columns } expandRow={ expandRow } pagination={ paginationFactory() } wrapperClasses="table-responsive" striped hover condensed />*/}
				</div>
			</Fragment>
		)
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	renamefanpage: state.renamefanpage,
});
const mapDispatchToProps = { loadRNF, upHuyOrder, updateLimitRNF, updateTypeRNF }

export default connect(mapStateToProps, mapDispatchToProps)(nhatky);