import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import { getListUser, deleteItemUser, searchDataUser, disableAdmin2FA  } from '../../../actions/adminPageAction';
import BootstrapTable from 'react-bootstrap-table-next';
import Moment from 'react-moment';
import store from '../../../store';
import NumberFormat from 'react-number-format';
import paginationFactory from 'react-bootstrap-table2-paginator';

import ModalRegisterUser from './modal/registerUser';
import ModalRefundPriceUser from './modal/refundpriceuser';
import ModalEditPriceUser from './modal/editpriceuser';
import Modalresetpass from './modal/resetPassWord';
import ModalEditDataUser from './modal/editdatauser';
import ModalAddUserType from './modal/addusertype';

import { RiExchangeDollarLine } from "react-icons/ri";
import { Tb2Fa } from "react-icons/tb";
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
const { SearchBar } = Search;

class Listuser extends Component {

    componentDidMount(){
        this.props.getListUser();
    }

    state = {
        isOpenModalAddUser: false,
        isOpenModalRefundPriceUser: false,
        isOpenModalAddPriceUser: false,
        isOpenModalReset: false,
        isOpenModalChangeDataUser: false,
        isOpenModalAddUserType: false,
		page: 1,
		obj_search: "",
    }

	onChange = (e) => {
		this.setState({
			"obj_search": e.target.value,
			"page": 1
		});
	}
    getListByLimit = (page, type_button) => {
        var valueChange = parseInt(page);
		if (type_button == "prev") {
			valueChange -= 1;
			if (valueChange <= 0) {
				valueChange = 1;
			}
		}
		if (type_button == "next") {
			valueChange += 1;
		}
		this.setState({
			page: valueChange,
		});
		this.props.getListUser(valueChange, this.state.obj_search);
    }
	
    getListBySearch = (e) => {
		this.setState({
			"page": 1,
		});
		this.props.getListUser(1, this.state.obj_search);
    }

    openModalAddUser = () => {
        this.setState({
            isOpenModalAddUser: !this.state.isOpenModalAddUser,
        });
    }

    openModalRefundPrice = (e = null, data = {}) => {
        if (e !== null) {
            e.preventDefault();
        }

        store.dispatch({
            type: 'USER_EDIT_SELECT',
            payload: data
        });
        
        this.setState({
            isOpenModalRefundPriceUser: !this.state.isOpenModalRefundPriceUser,
        });
    }
	
    openModalAddPrice = (e = null, data = {}) => {
        if (e !== null) {
            e.preventDefault();
        }

        store.dispatch({
            type: 'USER_EDIT_SELECT',
            payload: data
        });
        
        this.setState({
            isOpenModalAddPriceUser: !this.state.isOpenModalAddPriceUser,
        });
    }

    openModalReset = (e,data = {}) => {
        store.dispatch({
            type: 'USER_EDIT_SELECT',
            payload: data
        });
        
        this.setState({
            isOpenModalReset: !this.state.isOpenModalReset,
        });
    }

    openModalChangeDataUser = (data = {}) => {
        store.dispatch({
            type: 'USER_EDIT_SELECT',
            payload: data
        });
        
        this.setState({
            isOpenModalChangeDataUser: !this.state.isOpenModalChangeDataUser,
        });
    }

    openModalAddUserType = () => {
        this.setState({
            isOpenModalAddUserType: !this.state.isOpenModalAddUserType,
        });
    }

    searchUser = (e) => {
    	if (e.key === 'Enter') {
	      	let value_search = e.target.value;
	      	this.props.searchDataUser(value_search);
	    }
    }

    render() {
		function formatCheckTime(cell, row) {
			return (
				<Fragment>
					<Moment format="DD-MM-YYYY HH:mm:ss">{cell*1000}</Moment>
				</Fragment>
			);
		}
		function filterValueCheckTime(cell, row) {
			var d = new Date(cell*1000);
			var month = '' + (d.getMonth() + 1);
			var day = '' + d.getDate();
			var year = d.getFullYear();
			var hour = '' + (d.getHours());
			var min = '' + d.getMinutes();
			var sec = '' + d.getSeconds();

			if (month.length < 2)
				month = '0' + month;
			if (day.length < 2)
				day = '0' + day;
			if (hour.length < 2)
				hour = '0' + hour;
			if (min.length < 2)
				min = '0' + min;
			if (sec.length < 2)
				sec = '0' + sec;
			
			return [day, month, year].join('-') + ' ' + [hour, min, sec].join(':');
		}
		function formatPrice(cell, row) {
			return (
				<Fragment>
					<NumberFormat value={ cell } displayType={'text'} thousandSeparator={true} suffix={' vnđ'} />
				</Fragment>
			);
		}
		function filterValuePrice(cell, row) {
			return cell;
		}
        const columns = [{
          dataField: 'id',
          text: 'ID',
          sort: true,
        },{
          dataField: 'username',
          text: 'Tên',
          sort: true,
		  classes: 'notranslate',
        },{
          dataField: 'email',
          text: 'Email',
          sort: true
        },{
          dataField: 'phone',
          text: 'Sdt',
          sort: true
        },{
          dataField: 'name_types',
          text: 'Loại tài khoản',
          sort: true,
          formatter: (cell, row) => 
            <Fragment>
                {cell === null ? 'Chưa cài đặt' : cell}
            </Fragment>

        },{
          dataField: 'money',
          text: 'Số tiền',
          sort: true,
		  formatter: formatPrice,
		  filterValue: filterValuePrice
        },{
          dataField: 'tien_nap',
          text: 'Tiền đã nạp',
          sort: true,
		  formatter: formatPrice,
		  filterValue: filterValuePrice
        },{
          dataField: 'tien_datieu',
          text: 'Tiền đã dùng',
          sort: true,
		  formatter: formatPrice,
		  filterValue: filterValuePrice
        },{
          dataField: 'create_at',
          text: 'Ngày tạo',
          sort: true,
		  formatter: formatCheckTime,
		  filterValue: filterValueCheckTime
        },{
          dataField: 'thaotac',
          text: 'Thao tác',
          sort: true,
          formatter: (cell, row) => 
            <Fragment>
                <a onClick={(e) => {this.openModalRefundPrice(e, row)}} href="/#" className="btn btn-hero-warning text-center mr-1" style={{"padding": ".35rem .25rem"}}>
                    <RiExchangeDollarLine size={25} />
                </a>
                <a onClick={(e) => {this.openModalAddPrice(e, row)}} href="/#" className="btn btn-hero-success text-center p-2 mr-1">
                    <i className="fas fa-fw fa-dollar-sign"></i>
                </a>
                <a onClick={(e) => {e.preventDefault(e);this.openModalReset(e,row)}} href="/#" className="btn btn-hero-primary text-center p-2 mr-1">
                    <i className="fas fa-fw fa-key"></i>
                </a>
                <a onClick={(e) => {e.preventDefault();this.props.disableAdmin2FA(row.id)}} href="/#" className="btn btn-hero-primary text-center p-1 mr-1">
					<Tb2Fa size={30} />
                </a>
                <a onClick={(e) => {e.preventDefault();this.openModalChangeDataUser(row)}} href="/#" className="btn btn-hero-primary text-center p-2 mr-1">
                    <i className="fas fa-fw fa-pen"></i>
                </a>
                <a onClick={(e) => {e.preventDefault();this.props.deleteItemUser(row.id)}} href="/#" className="btn btn-hero-danger text-center p-2 mr-1">
                    <i className="fa fa-fw fa-times"></i>
                </a>
            </Fragment>
        }]

        const {listuser} = this.props.adminpage;

        return (
            <Fragment>
            	{/*<div className="row">
            		<div className="col-md-9">
		                <button onClick={(e) => {this.openModalAddUser(e)}} type="button" className="btn btn-sm btn-success mr-1 mb-3">
		                    <i className="fa fa-fw fa-plus mr-1"></i> Thêm tài khoản
		                </button>

		                <button onClick={(e) => {this.openModalAddUserType()}} type="button" className="btn btn-sm btn-info mr-1 mb-3">
		                    <i className="fa fa-fw fa-plus mr-1"></i> Thêm loại tài khoản
		                </button>
            		</div>
            		<div className="col-md-3">
            			<input onKeyDown={(e) => {this.searchUser(e)}} type="text" name="search_user" className="form-control" placeholder="Tên, email, phone tìm kiếm"/>
            		</div>
            	</div>*/}

                <h3 className="block-title mb-2">Danh sách tài khoản</h3>
				
				<ToolkitProvider keyField="id" data={ listuser } columns={ columns } search >
					{
						props => (
							<div>
								<SearchBar { ...props.searchProps } />
								<div className="row mb-4">
									<div className="col-md-12">
										<button onClick={(e) => {this.openModalAddUser(e)}} type="button" className="btn btn-sm btn-success mr-1">
											<i className="fa fa-fw fa-plus mr-1"></i> Thêm tài khoản
										</button>

										<button onClick={(e) => {this.openModalAddUserType()}} type="button" className="btn btn-sm btn-info mr-1">
											<i className="fa fa-fw fa-plus mr-1"></i> Thêm loại tài khoản
										</button>
										
										<button onClick={(e) => {this.getListByLimit(this.state.page, "prev")}} name="page" value={this.state.page} type="button" className="btn btn-sm btn-primary mr-1">{"<"}</button>
										<button onClick={(e) => {this.getListByLimit(this.state.page, "next")}} name="page" value={this.state.page} type="button" className="btn btn-sm btn-primary mr-1">{">"}</button>
										<button onClick={(e) => {this.getListBySearch(e)}} name="view" value="0" type="button" className="btn btn-sm btn-primary mr-1">Tìm kiếm</button>
										<input  onChange={this.onChange} value={this.state.obj_search}  name="obj_search" type="text" className="form-control mr-1" style={{"width": "100px", "display": "inline-block"}}/>
									</div>
								</div>
								<BootstrapTable
									{ ...props.baseProps }
									bodyStyle={ { border: 'none' } }
									headerStyle={ { border: 'red 1px solid' } }
									striped
									hover
									wrapperClasses="table-responsive"
									pagination={ paginationFactory() } />
							</div>
						)
					}
				</ToolkitProvider>

                {this.state.isOpenModalAddUser ? <ModalRegisterUser openModal={this.openModalAddUser} isOpenModalAddUser={this.state.isOpenModalAddUser} /> : '' }
                {this.state.isOpenModalReset ? <Modalresetpass openModal={this.openModalReset} isOpenModal={this.state.isOpenModalReset} /> : '' }
                {this.state.isOpenModalRefundPriceUser ? <ModalRefundPriceUser openModal={this.openModalRefundPrice} isOpenModalEditUser={this.state.isOpenModalRefundPriceUser} /> : '' }
                {this.state.isOpenModalAddPriceUser ? <ModalEditPriceUser openModal={this.openModalAddPrice} isOpenModalEditUser={this.state.isOpenModalAddPriceUser} /> : '' }
                {this.state.isOpenModalChangeDataUser ? <ModalEditDataUser openModal={this.openModalChangeDataUser} isOpenModalEditUser={this.state.isOpenModalChangeDataUser} /> : '' }
                {this.state.isOpenModalAddUserType ? <ModalAddUserType openModal={this.openModalAddUserType} isOpenModal={this.state.isOpenModalAddUserType} /> : '' }
            </Fragment>
        );
    }

}

const mapStateToProps = state => ({
    auth: state.auth,
    adminpage: state.adminpage
});

export default connect(mapStateToProps, { getListUser, deleteItemUser, searchDataUser, disableAdmin2FA }) (Listuser);
