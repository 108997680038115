import { combineReducers } from "redux";
import errorReducer from "./errorReducer";
import authReducer from "./authReducer";
import buffmatlivestreamReducer from "./buffmatlivestreamReducer";
import bufsubReducer from "./bufsubReducer";
import buffsub_slow from "./buffsubslowReducer";
import bufflikefanpageReducer from "./bufflikefanpageReducer";
import bufflikefanpagesaleReducer from "./bufflikefanpagesaleReducer";
import buffsubfanpageReducer from "./buffsubfanpageReducer";
import buffsubtiktok from "./buffsubtiktok";
import buffcmttiktok from "./buffcmttiktok";
import buffliketiktok from "./buffliketiktok";
import buffviewtiktok from "./buffviewtiktok";
import buffsharetiktok from "./buffsharetiktok";
import rechargeCardPhone from "./rechargecardphoneReducer";
import buyproxy from "./buyproxyReducer";
import buffseedingreviewReducer from "./buffseedingreviewReducer";
import buffseedinglikeinstagramorderReducer from "./buffseedinglikeinstagramorderReducer";
import buffseedingviewinstagramorderReducer from "./buffseedingviewinstagramorderReducer";
import BufflikecommentshareReducer from "./BufflikecommentshareReducer";
import buffsubinstagram from "./buffsubinstagram";
import buffsubyoutube from "./buffsubyoutube";
import buffseedingcommentinstagramorderReducer from "./buffseedingcommentinstagramorderReducer";
import youtubeviewReducer from "./youtubeviewReducer";
import youtubelikeReducer from "./youtubelikeReducer";
import youtubedislikeReducer from "./youtubedislikeReducer";
import youtubetichnghesiReducer from "./youtubetichnghesiReducer";
import youtubelikecommentReducer from "./youtubelikecommentReducer";
import youtubecommentReducer from "./youtubecommentReducer";
import VipcommentthangReducer from "./VipcommentthangReducer";
import ViplikethangReducer from "./ViplikethangReducer";
import buffviplikeclone from "./buffviplikeclone";
import likecommentshare from "./likecommentshareReducer";
import likecommentsharelike from "./likecommentsharelikeReducer";
import likecommentsharecomment from "./likecommentsharecommentReducer";
import likecommentsharelikecomment from "./likecommentsharelikecommentReducer";
import likecommentshareshare from "./likecommentshareshareReducer";
import adminpageReducer from "./adminpageReducer";
import MuabancloneReducer from "./MuabancloneReducer";
import FacebookbotReducer from "./FacebookbotReducer";
import chocbanbe from "./fbchocbanbe";
import vipbotlive from "./fbvipbotlive";
import buffviewsvideo from "./fbbuffviewsvideo";
import historyOrder from "./historyOrderRedycer";
import FacebookvipcommentReducer from "./FacebookvipcommentReducer";
import FacebookvipviewvideoReducer from "./FacebookvipviewvideoReducer";
import FacebooklocbanbekhongtuongtacReducer from "./FacebooklocbanbekhongtuongtacReducer";
import FacebookbotreplyinboxchoReducer from "./FacebookbotreplyinboxchoReducer";
import FacebookvipmatlivestreamReducer from "./FacebookvipmatlivestreamReducer";
import Facebookbotv2Reducer from "./Facebookbotv2Reducer";
import GetcookiefbReducer from "./GetcookiefbReducer";
import FacebookbotcmtReducer from "./FacebookbotcmtReducer";
import ShopeeReducer from "./ShopeeReducer";
import ShopeetimReducer from "./ShopeetimReducer";
import ShopeeseedinglivestreamReducer from "./ShopeeseedinglivestreamReducer";
import ShopeeviplivestreamReducer from "./ShopeeviplivestreamReducer";
import refundOrderReducer from "./refundOrderReducer";
import buffsublikev2Reducer from "./buffsublikev2Reducer";
import buffgroupReducer from "./buffgroupReducer";
import SpamsmsReducer from "./SpamsmsReducer";
import SpamcallReducer from "./SpamcallReducer";
import buffgroupshareliveReducer from "./buffgroupshareliveReducer";
import RipaccReducer from "./RipaccReducer";
import ReportyoutubeReducer from "./ReportyoutubeReducer";
import bufflikesubcommentReducer from "./bufflikesubcommentReducer";
import googlemapReducer from "./googlemapReducer";
import instagramviplikeReducer from "./instagramviplikeReducer";
import renamefanpageReducer from "./renamefanpageReducer";
import buffgroupteleReducer from "./buffgroupteleReducer";
import buffviewstoryReducer from "./buffviewstoryReducer";
import FacebookbotlovestoryReducer from "./FacebookbotlovestoryReducer";
import Facebookvipmatlivestreamv2Reducer from "./Facebookvipmatlivestreamv2Reducer";
import FacebookadbreaksgioxemReducer from "./FacebookadbreaksgioxemReducer";
import FacebookadbreakstuongtacReducer from "./FacebookadbreakstuongtacReducer";
import FacebookadbreaksviewdexuatReducer from "./FacebookadbreaksviewdexuatReducer";
import youtubegioxemReducer from "./youtubegioxemReducer";
import TwitterlikeReducer from "./TwitterlikeReducer";
import TwitterfollowReducer from "./TwitterfollowReducer";
import buffviplikegroup from "./buffviplikegroup";
import SupportReducer from "./SupportReducer";
import EmailtouidReducer from "./EmailtouidReducer";
import MuabanfanpagegroupReducer from "./MuabanfanpagegroupReducer";
import buffsubsaleReducer from "./buffsubsaleReducer";
import vipliketiktok from "./vipliketiktok";
import vipviewtiktok from "./vipviewtiktok";
import buffmattiktok from "./buffmattiktok";
import muafanpageReducer from "./muafanpageReducer";
import SpotifyfollowReducer from "./SpotifyfollowReducer";
import SpotifyplayReducer from "./SpotifyplayReducer";
import SoundcloudfollowReducer from "./SoundcloudfollowReducer";
import SoundcloudplayReducer from "./SoundcloudplayReducer";
import SoundcloudlikeReducer from "./SoundcloudlikeReducer";
import VimeofollowReducer from "./VimeofollowReducer";
import VimeoviewReducer from "./VimeoviewReducer";
import ThuesimReducer from "./ThuesimReducer";

import paymentReducer from "./paymentReducer";
import findmyidReducer from "./findmyidReducer";
export default combineReducers({
  error: errorReducer,
  auth: authReducer,
  buffmatlivestream: buffmatlivestreamReducer,
  buffsub: bufsubReducer,
  buffsub_slow: buffsub_slow,
  bufflikefanpage: bufflikefanpageReducer,
  tiktok_buffsub: buffsubtiktok,
  tiktok_buffcmt: buffcmttiktok,
  tiktok_bufflike: buffliketiktok,
  tiktok_buffview: buffviewtiktok,
  tiktok_buffshare: buffsharetiktok,
  rechargeCardPhone: rechargeCardPhone,
  buyproxy: buyproxy,
  buffseedingreview: buffseedingreviewReducer,
  buffseedinglikeinstagramorder: buffseedinglikeinstagramorderReducer,
  Bufflikecommentshare: BufflikecommentshareReducer,
  buffseedingcommentinstagramorder: buffseedingcommentinstagramorderReducer,
  buffsubinstagram: buffsubinstagram,
  youtubeview: youtubeviewReducer,
  youtubecomment: youtubecommentReducer,
  buffsubyoutube: buffsubyoutube,
  Vipcommentthang: VipcommentthangReducer,
  Viplikethang: ViplikethangReducer,
  buffviplikeclone: buffviplikeclone,
  likecommentshare: likecommentshare,
  likecommentsharelike: likecommentsharelike,
  likecommentsharecomment: likecommentsharecomment,
  likecommentsharelikecomment: likecommentsharelikecomment,
  likecommentshareshare: likecommentshareshare,
  adminpage: adminpageReducer,
  Muabanclone: MuabancloneReducer,
  Facebookbot: FacebookbotReducer,
  chocbanbe: chocbanbe,
  vipbotlive: vipbotlive,
  buffviewsvideo: buffviewsvideo,
  historyOrder: historyOrder,
  Facebookvipcomment: FacebookvipcommentReducer,
  Facebookvipviewvideo: FacebookvipviewvideoReducer,
  Facebooklocbanbekhongtuongtac: FacebooklocbanbekhongtuongtacReducer,
  Facebookbotreplyinboxcho: FacebookbotreplyinboxchoReducer,
  Facebookvipmatlivestream: FacebookvipmatlivestreamReducer,
  Facebookbotv2: Facebookbotv2Reducer,
  Getcookiefb: GetcookiefbReducer,
  Facebookbotcmt: FacebookbotcmtReducer,
  payment: paymentReducer,
  findmyid: findmyidReducer,
  Shopee: ShopeeReducer,
  refundOrder: refundOrderReducer,
  buffsublikev2: buffsublikev2Reducer,
  buffgroup: buffgroupReducer,
  Spamsms: SpamsmsReducer,
  buffgroupsharelive: buffgroupshareliveReducer,
  buffseedingviewinstagramorder: buffseedingviewinstagramorderReducer,
  youtubelike: youtubelikeReducer,
  youtubedislike: youtubedislikeReducer,
  youtubetichnghesi: youtubetichnghesiReducer,
  youtubelikecomment: youtubelikecommentReducer,
  Shopeeseedinglivestream: ShopeeseedinglivestreamReducer,
  Shopeeviplivestream: ShopeeviplivestreamReducer,
  Shopeetim: ShopeetimReducer,
  Ripacc: RipaccReducer,
  Reportyoutube: ReportyoutubeReducer,
  bufflikesubcomment: bufflikesubcommentReducer,
  googlemap: googlemapReducer,
  instagramviplike: instagramviplikeReducer,
  renamefanpage: renamefanpageReducer,
  Spamcall: SpamcallReducer,
  buffgrouptele: buffgroupteleReducer,
  buffviewstory: buffviewstoryReducer,
  Facebookbotlovestory: FacebookbotlovestoryReducer,
  Facebookvipmatlivestreamv2: Facebookvipmatlivestreamv2Reducer,
  Facebookadbreaksgioxem: FacebookadbreaksgioxemReducer,
  Facebookadbreakstuongtac: FacebookadbreakstuongtacReducer,
  Facebookadbreaksviewdexuat: FacebookadbreaksviewdexuatReducer,
  youtubegioxem: youtubegioxemReducer,
  Twitterlike: TwitterlikeReducer,
  Twitterfollow: TwitterfollowReducer,
  buffviplikegroup: buffviplikegroup,
  Support: SupportReducer,
  Emailtouid: EmailtouidReducer,
  Muabanfanpagegroup: MuabanfanpagegroupReducer,
  buffsub_sale: buffsubsaleReducer,
  bufflikefanpagesale: bufflikefanpagesaleReducer,
  buffsubfanpage: buffsubfanpageReducer,
  vipliketiktok: vipliketiktok,
  vipviewtiktok: vipviewtiktok,
  buffmattiktok: buffmattiktok,
  muafanpage: muafanpageReducer,
  Spotifyfollow: SpotifyfollowReducer,
  Spotifyplay: SpotifyplayReducer,
  Soundcloudfollow: SoundcloudfollowReducer,
  Soundcloudplay: SoundcloudplayReducer,
  Soundcloudlike: SoundcloudlikeReducer,
  Vimeofollow: VimeofollowReducer,
  Vimeoview: VimeoviewReducer,
  Thuesim: ThuesimReducer,
});
