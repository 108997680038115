import React from 'react';
import axios from 'axios';
import {
	LOAD_BSCIO,
	UPDATE_BSCIO,
	UPDATE_BSCIO_TAB,
	LOAD_PRICE_BSCIO,
	LIMIT_PAGI_BSCIO,
	TYPE_BSCIO,
	LOADING_BSCIO
} from "../actions/types";
import Swal from 'sweetalert2';
import { loadUser } from "./authActions";

export const loadBSCIO = (type = 0, page = 1, obj_search = '') => (dispatch, getState) => {
	dispatch({
		type: LOADING_BSCIO,
		payload: true
	});
	axios.get('/api/instagram/list?type_api=buff_comment&type='+type+'&page='+page+'&obj_search='+obj_search, tokenConfig(getState))
		.then(res => {
			dispatch({
				type: LOADING_BSCIO,
				payload: false
			});
			if (res.data.status === 200) {
				dispatch({
					type: LOAD_BSCIO,
					payload: res.data.data
				});
			}
		});
};

export const loadPriceBSCIO = () => (dispatch, getState) => {
	axios.get('/api/buffseedingcommentinstagramorder/price', tokenConfig(getState))
		.then(res => {
			if (res.data.status === 200) {
				dispatch({
					type: LOAD_PRICE_BSCIO,
					payload: res.data.data
				});
			}
		});
};

export const updateBSCIO = (body) => (dispatch, getState) => {
	dispatch({
		type: UPDATE_BSCIO,
		payload: body
	});
};

export const updateLimitBSCIO = (body) => (dispatch) => {
	dispatch({
		type: LIMIT_PAGI_BSCIO,
		payload: body
	})
};

export const updateTypeBSCIO = (body) => (dispatch) => {
	dispatch({
		type: TYPE_BSCIO,
		payload: body
	})
};

export const addBSCIO = (body) => (dispatch, getState) => {
	dispatch({
		type: LOADING_BSCIO,
		payload: true
	});
	axios.post('/api/instagram/create', body, tokenConfig(getState))
		.then(res => {
			dispatch({
				type: LOADING_BSCIO,
				payload: false
			});
			dispatch(loadUser());
			if (res.data.status === 200) {
				Swal.fire({
					icon: 'success',
					html: 'Tạo tiến trình seeding thành công, hệ thống sẽ tự động tăng theo thời gian bạn đã chọn <br/><br/> Nếu không đủ lượt seeding hệ thống sẽ tự động hoàn lại số tiền chưa chạy'
				});
			} else {
				var message = '';
				if (res.data.error.object_id) {
					message = res.data.error.object_id[0];
				} else if (res.data.error.quantity) {
					message = res.data.error.quantity[0];
				} else {
					message = res.data.message;
				}
				Swal.fire({
					icon: 'error',
					title: 'Lỗi',
					text: message,
				});
			}
		});
};

export const changeTab = (body) => (dispatch, getState) => {
	dispatch({
		type: UPDATE_BSCIO_TAB,
		payload: body
	});
};

export const upHuyOrder = (body) => (dispatch, getState) => {
	dispatch({
		type: LOADING_BSCIO,
		payload: true
	});
	axios.post('/api/instagram/remove', body, tokenConfig(getState))
		.then(res => {
			dispatch({
				type: LOADING_BSCIO,
				payload: false
			});
			if (res.data.status === 200) {
				Swal.fire({
					icon: 'success',
					text: res.data.message,
				});
				dispatch(loadBSCIO());
			} else {
				var message = '';
				if (res.data.error) {
					if (res.data.error.object_id) {
						message = res.data.error.object_id[0];
					} else if (res.data.error.quantity) {
						message = res.data.error.quantity[0];
					} else if (res.data.error.id) {
						message = res.data.error.id[0];
					} else {
						message = res.data.message;
					}
				} else {
					message = res.data.message;
				}
				Swal.fire({
					icon: 'error',
					title: 'Lỗi',
					text: message,
				});
			}
		});
};

export const saveEditOrderAdmin = (nametb, data, closeM) => (dispatch, getState) => {
	let bodysend = {
		formdata: data,
		type_api: nametb
	};
	dispatch({
		type: LOADING_BSCIO,
		payload: true
	});
	axios.post('/api/facebook_buff/admin_edit_order/', bodysend, tokenConfig(getState))
	.then(res => {
		dispatch({
			type: LOADING_BSCIO,
			payload: false
		});
		if (res.data.status === 200) {
			dispatch(loadBSCIO());
			Swal.fire(
	      		'Thành công!',
	      		'Sửa thành công',
	      		'success'
	    	);
	    	closeM();
		} else {
			Swal.fire( 'Lỗi', res.data.message, 'error' )
		}
	}).catch(err => {
		Swal.fire( 'Lỗi', 'Có lỗi xảy ra!', 'error' );
    });
}

export const reLoadIdFb = (id) => (dispatch, getState) => {
	let bodysend = {
		id: id,
		type: 'buffseedingcommentinstagramorder'
	};
	dispatch({
		type: LOADING_BSCIO,
		payload: true
	});
	axios.post('/api/instagram/checkiddie/', bodysend, tokenConfig(getState))
	.then(res => {
		dispatch({
			type: LOADING_BSCIO,
			payload: false
		});
		dispatch(loadBSCIO());
		if (res.data.status === 200) {
			Swal.fire({icon: 'success', title: 'Thành công', text: 'Check thành công!'});
		} else {
			Swal.fire({icon: 'error', title: 'Lỗi', text: res.data.message});
		}
	});
}

export const getConfigMoDong = (type = '') => (dispatch, getState) => {
	dispatch({
		type: LOADING_BSCIO,
		payload: true
	});

	axios.get('/api/instagram/getconfig?type_api=instagram_buffcmt', tokenConfig(getState))
	.then(res => {
		dispatch({
			type: LOADING_BSCIO,
			payload: false
		});
		if (res.data.status === 200) {
			dispatch({
				type: 'CONFIG_MO_DONG_BSCIO',
				payload: res.data
			});
		}
	});
}

export const tokenConfig = getState => {
	// Get token from localhost
	const token = getState().auth.token;
	// Headers
	const config = {
		headers: {
			"Content-type": "application/json"
		}
	};
	
	// If token, add to headers
	if (token) {
		config.headers['ht-token'] = token;
	}
	
	return config;
};