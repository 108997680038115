import React, { Component, Fragment } from 'react';
import {
  BrowserRouter as Router,
  // Switch,
  // Route,
  Link
} from "react-router-dom";
import {
	// Button,
	// Modal,
	// ModalHeader,
	// ModalBody,
	// Form,
	// FormGroup,
	// Label,
	// Input,
	// NavLink,
	Alert
} from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { register } from '../../actions/authActions';
import { clearErrors } from '../../actions/errorActions';
import contactdecoration from '../../iconlanding/6/contact-decoration.png';
import parse from 'html-react-parser';

class RegisterModal extends Component {
	state = {
		// modal: false,
		username: '',
		email: '',
		password: '',
		repeatpassword: '',
		facebook_id: '4',
		phone: '',
		msg: null,
		menuopen: false,
	}
	
	static propTypes =  {
		auth: PropTypes.object.isRequired,
		error: PropTypes.object.isRequired,
		register: PropTypes.func.isRequired,
		clearErrors: PropTypes.func.isRequired
	}
	
	componentDidUpdate(prevProps) {
		const { error } = this.props;
		if (error !== prevProps.error) {
			// Check for register error
			if (error.id === 'REGISTER_FAIL') {
				this.setState({ msg: error.msg.msg });
			} else {
				this.setState({ msg: null });
			}
		}
	}
	
	clearAlert = () => {
		// Clear errors
		this.props.clearErrors();
	}
	
	onChange = e => {
		this.setState({ [e.target.name]: e.target.value });
	};
	
	onSubmit = e => {
		e.preventDefault();
		
		const { username, email, password, repeatpassword, facebook_id, phone } = this.state;
		
		// Create user object
		const newUser = {
			username,
			email,
			password,
			repeatpassword,
			facebook_id,
			phone
		};
		// console.log(newUser);
		// Attempt to register
		this.props.register(newUser);
	};
	openMenu = (data = null) => {
		if (data !== null) {
			this.setState({
				...this.state,
				menuopen: data,
			});
		}
	}
	
	render() {
		let loading = this.props.auth.loading;
		const domainSite = window.location.hostname;
		var config_website = {};
		if (localStorage.getItem("confignavbar") != null) {
			config_website = JSON.parse(localStorage.getItem("confignavbar"));
		} else {
			config_website = this.props.auth.config_website;
		}
		return (
			<Router>
				<Fragment>
					<div className="landingcus6 notranslate" style={{"zoom": "122%", "backgroundColor": "#fff"}}>
						<header className="header-area header-sticky wow slideInDown background-header" data-wow-duration="0.75s" data-wow-delay="0s">
							<div className="container">
								<div className="row">
									<div className="col-12">
										<nav className="main-nav">
											<a href="/" className="logo" style={{"margin-top": "10px"}}>
												<h4>fb<span>touse</span></h4>
											</a>
											<ul className="nav" style={{"display": this.state.menuopen ? 'block' : 'none'}}>
												<li className="scroll-to-section"><a href="#top" className="active">หน้าหลัก</a></li>
												<li className="scroll-to-section"><a href="#about">เกี่ยวกับเรา</a></li>
												<li className="scroll-to-section"><a href="#services">บริการ</a></li>
												<li className="scroll-to-section"><a href="#question">ถาม-ตอบ</a></li>
												<li className="scroll-to-section"><a href="#howpay">วิธีสั่งซื้อ</a></li> 
												<li className="scroll-to-section"><a href="#contact">ติดต่อเรา</a></li>
												<li className="scroll-to-section"><a href="#">สมัครบริการ</a></li>
												<li className="scroll-to-section">
													<Link to="/" onClick={this.props.action} style={{"marginLeft": "5px"}} className="btn btn-danger">
													  <span>
														เข้าสู่ระบบ
													  </span>
													</Link>
												</li> 
											</ul>        
											<a onClick={(e) => {e.preventDefault();this.openMenu(!this.state.menuopen)}} className={"menu-trigger "+(this.state.menuopen ? ' active' : '')}>
												<span>Menu</span>
											</a>
										</nav>
									</div>
								</div>
							</div>
						</header>
						<div className="main-banner wow fadeIn" id="top" data-wow-duration="1s" data-wow-delay="0.5s">
							<div className="container">
								<div className="row">
									<div className="col-lg-3 col-md-12 col-sm-12 col-xs-12">
									</div>
									<div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
										<div className="contents">
											<div className="auth-login-form-home">
												<div className="form-login" style={{"position": "relative"}}>
													{loading ? (
														<div className="preloader">
															<div className="spinner-grow spinner-grow-sm text-primary" role="status" style={{ "marginTop": "28%" }}>
															  <span className="sr-only"></span>
															</div>
															<div className="spinner-grow spinner-grow-sm text-danger" role="status" style={{ "marginTop": "28%" }}>
															  <span className="sr-only">.</span>
															</div>
															<div className="spinner-grow spinner-grow-sm text-warning" role="status" style={{ "marginTop": "28%" }}>
															  <span className="sr-only"></span>
															</div>
														</div>
													):('')}
													{this.state.msg ? (
														<div className="alert alert-warning d-flex align-items-center justify-content-between" role="alert" >
														  <div className="flex-fill mr-3">
															  {
																this.state.msg.indexOf('Vui lòng điền đầy đủ') > -1 ?
																	<p className="mb-0">กรุณากรอกให้ครบถ้วน</p>
																: ''
															  }
															  {
																this.state.msg.indexOf('Tên đăng nhập đã tồn tại') > -1 ?
																	<p className="mb-0">มีชื่อผู้ใช้</p>
																: ''
															  }
															  {
																this.state.msg.indexOf('Kiểm tra lại mật khẩu') > -1 ?
																	<p className="mb-0">ตรวจสอบรหัสผ่าน</p>
																: ''
															  }
														  </div>
														  <div className="flex-00-auto">
															<i className="fa fa-fw fa-exclamation-circle"></i>
														  </div>
														</div>
													  ) : null}
													<form className="js-validation-signup" onSubmit={this.onSubmit} method="POST" >
														<div className="form-group">
															<div className="input-icon mb-5">
																<span className="input-icon-addon">
																	<i className="fa fa-user"></i>
																</span>
																<input name="username" placeholder="เข้าสู่บัญชีของคุณ" required="" id="username" type="text" className="is-untouched is-pristine av-valid form-control" onChange={this.onChange} />
															</div>
															<div className="input-icon mb-5">
																<span className="input-icon-addon">
																	<i className="fa fa-envelope"></i>
																</span>
																<input name="email" placeholder="อีเมลล์" required="" id="email" type="email" className="is-untouched is-pristine av-valid form-control" onChange={this.onChange} />
															</div>
															<div className="input-icon mb-5">
																<span className="input-icon-addon">
																	<i className="fa fa-phone-alt"></i>
																</span>
																<input name="phone" placeholder="เบอร์โทรศัพท์" required="" id="phone" type="text" className="is-untouched is-pristine av-valid form-control" onChange={this.onChange} />
															</div>
															<div className="input-icon mb-5">
																<span className="input-icon-addon">
																	<i className="fa fa-key"></i>
																</span>
																<input name="password" placeholder="ใส่รหัสผ่าน" required="" id="password" type="password" className="is-untouched is-pristine av-valid form-control" onChange={this.onChange} />
															</div>
															<div className="input-icon mb-5">
																<span className="input-icon-addon">
																	<i className="fa fa-key"></i>
																</span>
																<input name="repeatpassword" placeholder="ป้อนรหัสผ่าน" required="" id="repeatpassword" type="password" className="is-untouched is-pristine av-valid form-control" onChange={this.onChange} />
															</div>
														</div>
														<div className="form-footer">
															<button type="submit" className="btn-block btn-submit btn btn-danger">ลงทะเบียน</button>
														</div>
													</form>
													<div className="text-center text-muted m-t-20">
														คุณมีบัญชีอยู่แล้วหรือไม่?
														<Link to="/" onClick={this.props.action} style={{"marginLeft": "5px"}}>
														  <span>
															เข้าสู่ระบบ
														  </span>
														</Link>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="col-lg-3 col-md-12 col-sm-12 col-xs-12">
									</div>
								</div>
							</div>
						</div>
						<div id="contact" className="contact-us section">
							<div className="container">
								<div className="row">
									<div className="col-lg-6 align-self-center wow fadeInLeft" data-wow-duration="0.5s" data-wow-delay="0.25s">
										<div className="section-heading">
											<h2>มาร่วมงานกับเรา</h2>
											<p>สามารถติดต่อเรา สอบถามบริการต่างๆ </p>
											<div className="phone-info">
												<h4>โทรศัพท์ : <span><i className="fa fa-phone fa-rotate-90"></i> <a href="#">0639467361</a></span></h4>
											</div>
										</div>
									</div>
									<div className="col-lg-6 wow fadeInRight" data-wow-duration="0.5s" data-wow-delay="0.25s">
										<form id="contact" action="" method="post">
											<div className="row">
												<div className="col-lg-3">
													<fieldset>
														Facebook 
													</fieldset>
												</div>
												<div className="col-lg-9">
													<fieldset>
														https://www.facebook.com/adthaigg
													</fieldset>
												</div>
												<div className="col-lg-3">
													<fieldset>
														Line:
													</fieldset>
												</div>
												<div className="col-lg-9">
													<fieldset>
														https://page.line.me/fbtouse 
													</fieldset>
												</div>

											</div>
											<div className="contact-dec">
												<img src={contactdecoration} alt=""/>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
						<footer>
							<div className="container">
								<div className="row">
									<div className="col-lg-12 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.25s">
										<p>© Copyright 2021  All Rights Reserved. 

										</p>
									</div>
								</div>
							</div>
						</footer>
					</div>
				</Fragment>
			</Router>
		);
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	error: state.error
})

export default connect(mapStateToProps, { register, clearErrors })(RegisterModal);