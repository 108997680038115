import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { loadVLT } from '../../../actions/ViplikethangActions';
import Swal from 'sweetalert2';

class nhatky extends Component {
	static propTypes =  {
		loadVLT: PropTypes.func.isRequired,
	};
	
	state = {
		
	}
	
	componentDidMount() {
		this.props.loadVLT();
	}
	
	render() {
		const { listtientrinh } = this.props.Viplikethang;
		function formatTongTien(cell, row) {
			var price = (row.gia_tien_moi_tuong_tac * row.so_luong_like_lon_nhat * row.so_luong_bai_viet_mot_ngay * row.so_ngay_mua_vip);
			return price;
		};
		function formatAvatar(cell, row) {
			var link_avt = "https://graph.facebook.com/"+cell+"/picture?height=100&width=100";
			return (
				<img src={link_avt} className="avatar-user" />
			)
		};
		const columns = [{
			dataField: 'link_hoac_id_profile_vip',
			text: 'Avatar',
			sort: true,
			formatter: formatAvatar,
		}, {
			dataField: 'fb_name',
			text: 'Username',
			sort: true,
		}, {
			dataField: 'id',
			text: 'ID Seeding',
			sort: true,
		}, {
			dataField: 'so_luong_like_nho_nhat',
			text: 'Min Like',
			sort: true,
		}, {
			dataField: 'so_luong_like_lon_nhat',
			text: 'Max Like',
			sort: true,
		}, {
			dataField: 'so_luong_bai_viet_mot_ngay',
			text: 'Post/ngày',
			sort: true,
		}, {
			dataField: 'so_ngay_mua_vip',
			text: 'Số ngày',
			sort: true,
		}, {
			dataField: 'gia_tien_moi_tuong_tac',
			text: 'Giá',
			sort: true,
		}, {
			dataField: '',
			text: 'Tổng tiền',
			sort: true,
			formatter: formatTongTien,
		}, {
			dataField: 'created_at',
			text: 'Khởi tạo',
			sort: true,
		}, {
			dataField: 'time_expired',
			text: 'Hết hạn',
			sort: true,
		}];
		return (
			<Fragment>
				<div>
					<BootstrapTable keyField='id' data={ listtientrinh } columns={ columns } pagination={ paginationFactory() } wrapperClasses="table-responsive" striped hover condensed />
				</div>
			</Fragment>
		)
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	Viplikethang: state.Viplikethang,
});
const mapDispatchToProps = { loadVLT }

export default connect(mapStateToProps, mapDispatchToProps)(nhatky);