import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Userinformation from "../Userinformation";
import { listSupport } from '../../../actions/adminPageAction';

class sitecontent extends Component {
	componentDidMount(){
       this.props.listSupport();
    }
	
	render() {
        const { support } = this.props.adminpage;
		
		return (
			<Fragment>
				<Userinformation />
				<div className="card card-orange mt-3">
					<div className="card-body" style={{"word-break": "break-all"}}>
						{
							support.link_facebook == '' && support.link_zalo == '' && support.link_telegram == '' && support.link_skype == '' && support.link_other == '' && support.phone_1 == '' && support.phone_2 == '' ?
								<Fragment>
									<label className="text-danger" >Hiện không có liên hệ hỗ trợ</label>
								</Fragment>
							: ''
						}
						{
							support.link_facebook != '' ?
								<Fragment>
									<label className="" >Liên hệ trên Facebook:</label>
									<p><a href={support.link_facebook}>{support.link_facebook}</a></p>
								</Fragment>
							: ''
						}
						{
							support.link_zalo != '' ?
								<Fragment>
									<label className="" >Liên hệ trên Zalo:</label>
									<p><a href={support.link_zalo}>{support.link_zalo}</a></p>
								</Fragment>
							: ''
						}
						{
							support.link_telegram != '' ?
								<Fragment>
									<label className="" >Liên hệ trên Telegram:</label>
									<p><a href={support.link_telegram}>{support.link_telegram}</a></p>
								</Fragment>
							: ''
						}
						{
							support.link_skype != '' ?
								<Fragment>
									<label className="" >Liên hệ trên Skype:</label>
									<p><a href={support.link_skype}>{support.link_skype}</a></p>
								</Fragment>
							: ''
						}
						{
							support.link_other != '' ?
								<Fragment>
									<label className="" >Liên hệ khác:</label>
									<p><a href={support.link_other}>{support.link_other}</a></p>
								</Fragment>
							: ''
						}
						{
							support.phone_1 != '' || support.phone_2 != '' ?
								<Fragment>
									<label className="" >Liên hệ qua số điện thoại:</label>
									<div className="">
										<p>{support.phone_1}
										{
											support.phone_1 != '' && support.phone_2 != '' ?
												<Fragment>
													&nbsp;hoặc&nbsp;
												</Fragment>
											: ''
										}
										{support.phone_2}</p>
									</div>
								</Fragment>
							: ''
						}
					</div>
				</div>
				<div className="card card-orange mt-3">
					<div className="card-body" style={{"word-break": "break-all"}}>
						<p className="font-bold text-danger">Chú ý:</p>
						<p className="text-danger">- Hỗ trợ trong giờ hành chính từ Thứ 2 đến Thứ 6.</p>
						<p className="text-danger">- Giờ làm việc từ 9h30 sáng đến 18h30 chiều.</p>
						<p className="text-danger">- Ngoài giờ làm việc sẽ hỗ trợ chậm hơn và phụ thuộc nhân viên hỗ trợ Online.</p>
						<p className="text-danger">- Nếu vấn đề không cần gấp vui lòng chờ đến giờ làm việc để xử lý tốt nhất, nhường cho các bạn cần hỗ trợ gấp ngoài giờ.</p>
					</div>
				</div>
			</Fragment>
		)
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
    adminpage: state.adminpage
});
const mapDispatchToProps = { listSupport }

export default connect(mapStateToProps, mapDispatchToProps)(sitecontent);