import axios from 'axios';
import {
	LOAD_SCL,
	ADD_SCL,
	UPDATE_SCL,
	UPDATE_SCL_TAB,
	LOAD_PRICE_SCL,
	LIMIT_PAGI_SCL,
	TYPE_SCL,
	LOADING_SCL,
	LOADING_SCL_2,
	LOAD_NHATKYHOATDONG_SCL
} from "../actions/types";
import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Swal from 'sweetalert2';
import { loadUser } from "./authActions";

export const loadSCL = (type = 0, limit = 0) => (dispatch, getState) => {
	dispatch({
		type: LOADING_SCL,
		payload: true
	});
	axios.get('/api/soundcloud?type_api=soundcloudlike&type='+type+'&limit='+limit, tokenConfig(getState))
		.then(res => {
			dispatch({
				type: LOADING_SCL,
				payload: false
			});
			if (res.data.status === 200) {
				dispatch({
					type: LOAD_SCL,
					payload: res.data.data
				});
			}
		});
};

export const addSCL = (body) => (dispatch, getState) => {
	dispatch({
		type: LOADING_SCL,
		payload: true
	});
	axios.post('/api/soundcloud/create', body, tokenConfig(getState))
		.then(res => {
			dispatch({
				type: LOADING_SCL,
				payload: false
			});
			dispatch(loadUser());
			if (res.data.status === 200) {
				Swal.fire({
					icon: 'success',
					html: 'Tạo tiến trình thành công'
				});
			} else {
				var message = '';
				if (res.data.message) {
					message = res.data.message;
				} else {
					message = res.data.error;
				}
				Swal.fire({
					icon: 'error',
					title: 'Lỗi',
					text: message,
				});
			}
		});
};

export const deleteSCL = (id) => (dispatch, getState) => {
	let body = {
		id: id,
		'type_api': 'soundcloudlike'
	};
	dispatch({
		type: LOADING_SCL,
		payload: true
	});
	axios.post('/api/soundcloud/delete', body, tokenConfig(getState))
		.then(res => {
			dispatch({
				type: LOADING_SCL,
				payload: false
			});
			dispatch(loadSCL());
			if (res.data.status === 200) {
				Swal.fire({
					icon: 'success',
					html: res.data.message
				});
			} else {
				var message = '';
				if (res.data.error) {
					if (res.data.error.object_id) {
						message = res.data.error.object_id[0];
					} else if (res.data.error.quantity) {
						message = res.data.error.quantity[0];
					} else {
						message = res.data.message;
					}
				} else {
					message = res.data.message;
				}
				Swal.fire({
					icon: 'error',
					title: 'Lỗi',
					text: message,
				});
			}
		});
};

export const UpdateEdit = (status, count_success, dataid, closeM) => (dispatch, getState) => {
	let body = {
		id: dataid,
		status: status,
		count_success: count_success,
		'type_api': 'soundcloudlike'
	};
	dispatch({
		type: LOADING_SCL,
		payload: true
	});
    axios.post('/api/soundcloud/updateedit', body, tokenConfig(getState))
		.then(res => {
			dispatch({
				type: LOADING_SCL,
				payload: false
			});
			closeM();
			if (res.data.status === 200) {
				dispatch(loadSCL());
				Swal.fire({
					icon: 'success',
					html: 'Cập nhật thành công'
				});
			}
			if (res.data.status === 400) {
				var message = '';
				if (res.data.error) {
					if (res.data.error.object_id) {
						message = res.data.error.object_id[0];
					} else if (res.data.error.quantity) {
						message = res.data.error.quantity[0];
					}
				} else {
					message = res.data.message;
				}
				Swal.fire({
					icon: 'error',
					title: 'Lỗi',
					text: message,
				});
			}
		});
};

export const updateSCL = (body) => (dispatch) => {
	dispatch({
		type: UPDATE_SCL,
		payload: body
	})
};

export const updateLimitSCL = (body) => (dispatch) => {
	dispatch({
		type: LIMIT_PAGI_SCL,
		payload: body
	})
};

export const updateTypeSCL = (body) => (dispatch) => {
	dispatch({
		type: TYPE_SCL,
		payload: body
	})
};

export const changeTab = (body) => (dispatch, getState) => {
	dispatch({
		type: UPDATE_SCL_TAB,
		payload: body
	});
};

export const getConfigMoDong = (type = '') => (dispatch, getState) => {
	dispatch({
		type: LOADING_SCL,
		payload: true
	});

	axios.get('/api/soundcloud/getconfig?type_api=soundcloudbuff_like', tokenConfig(getState))
	.then(res => {
		dispatch({
		type: LOADING_SCL,
			payload: false
		});
		if (res.data.status === 200) {
			dispatch({
				type: 'CONFIG_MO_DONG_SCL',
				payload: res.data
			});
		}
	});
}

export const tokenConfig = getState => {
	// Get token from localhost
	const token = getState().auth.token;
	// Headers
	const config = {
		headers: {
			"Content-type": "application/json"
		}
	};
	
	// If token, add to headers
	if (token) {
		config.headers['ht-token'] = token;
	}
	
	return config;
};