import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { loadBSR, updateTangGiaThem, upTangGiaThem, upHuyOrder, updateLimitBSR, updateTypeBSR } from '../../../actions/buffseedingreviewActions';
import {
	Modal,
	ModalBody,
	ModalFooter
} from 'reactstrap';
import Swal from 'sweetalert2';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import ModalEditAdmin from './modalEditAdmin';
import { RiExchangeDollarLine } from "react-icons/ri";
const { SearchBar } = Search;

class nhatky extends Component {
	static propTypes =  {
		loadBSR: PropTypes.func.isRequired,
		updateTangGiaThem: PropTypes.func.isRequired,
		upTangGiaThem: PropTypes.func.isRequired,
		upHuyOrder: PropTypes.func.isRequired,
		updateLimitBSR: PropTypes.func.isRequired,
		updateTypeBSR: PropTypes.func.isRequired,
	};
	
	state = {
		isModalBrowseGroupsOpen: false,
		isOpenModalEditAdmin: false,
		dataItemSelect: {},
		page: 1,
		obj_search: "",
	}
	
	componentDidMount() {
		this.props.loadBSR();
	}
		
	tangGiaThem = (id, object_id, price_per, quantity) => {
		this.setState({ isModalBrowseGroupsOpen: true });
		const { tanggiathem } = this.props.buffseedingreview;
		let new_tanggiathem = {
			...tanggiathem,
			"id": id,
			"lhibv": object_id,
			"price_per": price_per,
			"quantity": quantity,
		};
		this.props.updateTangGiaThem(new_tanggiathem);
	}
	
	openModalTangThemGia = (status, e) => {
		e.preventDefault();
		this.setState({ isModalBrowseGroupsOpen: status });
	}
	
	onChange = (e) => {
		this.setState({
			"obj_search": e.target.value,
			"page": 1
		});
	}
    getListByLimit = (page, type_button) => {
        var valueChange = parseInt(page);
		if (type_button == "prev") {
			valueChange -= 1;
			if (valueChange <= 0) {
				valueChange = 1;
			}
		}
		if (type_button == "next") {
			valueChange += 1;
		}
		this.setState({
			page: valueChange,
		});
		const { type } = this.props.buffseedingreview;
		this.props.loadBSR(type, valueChange, this.state.obj_search);
    }
	getListByView = (type) => {
		this.setState({
			"page": 1,
		});
		this.props.updateTypeBSR(type);
		this.props.loadBSR(type, 1, this.state.obj_search);
	}
    getListBySearch = (e) => {
		this.setState({
			"page": 1,
		});
		const { type } = this.props.buffseedingreview;
		this.props.loadBSR(type, 1, this.state.obj_search);
    }
	
	// onChange = e => {
		// const { tanggiathem } = this.props.buffseedingreview;
		// let new_tanggiathem = {
			// ...tanggiathem,
			// [e.target.name]: e.target.value,
		// };
		// this.props.updateTangGiaThem(new_tanggiathem);
	// };
	
	// onChangeLimit = e => {
		// const { type } = this.props.buffseedingreview;
		// this.props.updateLimitBSR(e.target.value);
		// this.props.loadBSR(type, e.target.value);
	// };
	
	// onCheckList = (type) => {
		// const { limit_pagi } = this.props.buffseedingreview;
		// this.props.updateTypeBSR(type);
		// this.props.loadBSR(type, limit_pagi);
	// }
	
	clickTangGiaThem = () => {
		const { tanggiathem } = this.props.buffseedingreview;
		if (tanggiathem.price_per > tanggiathem.price) {
			Swal.fire({
				icon: 'error',
				title: 'Lỗi',
				text: 'Giá tăng phải cao hơn giá hiện tại!',
			});
			return false;
		}
		this.props.upTangGiaThem(tanggiathem);
	}
	
	huyOrder = (id, object_id) => {
		const swalWithBootstrapButtons = Swal.mixin({
			customClass: {
				confirmButton: 'btn btn-success an-margin-btn',
				cancelButton: 'btn btn-danger an-margin-btn'
				},
			buttonsStyling: false
		})

		swalWithBootstrapButtons.fire({
			title: 'HỦY REVIEW ID: '+id+' ?',
			text: "Sau khi hủy bạn sẽ được hoàn lại số lượng chưa tăng sau 1 đến 3 ngày và trừ thêm 5.000 Coin phí để tránh Spam!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Đồng ý',
			cancelButtonText: 'Không',
			reverseButtons: true
		}).then((result) => {
			if (result.value) {
				let body = {
					"id": id,
					"type_api": "api_remove2"
				};
				this.props.upHuyOrder(body);
			} else if (result.dismiss === Swal.DismissReason.cancel) {
				
			}
		})
	}
	
	onKeyPress = (e) => {
		if (!(e.charCode >= 48 && e.charCode <= 57)) {
			e.preventDefault();
		}
	}
	
	openModalEditAdmin = (data = {}) => {
		this.setState({
			...this.state,
			isOpenModalEditAdmin: !this.state.isOpenModalEditAdmin,
			dataItemSelect: data,
		})
	}
	
	render() {
		const { listtientrinh, tanggiathem, limit_pagi } = this.props.buffseedingreview;
		const {isOpenModalEditAdmin, dataItemSelect} = this.state;
		// const { username } = this.props.auth.user;
		// var ar_listtientrinh = [];
		// listtientrinh.forEach(function(v, i) {
			// var tmp = {
				// ...v,
				// "site_username": username
			// };
			// ar_listtientrinh.push(tmp);
		// });
		
		function formatBaoHanh(cell, row) {
			if (cell === 0) {
				return (
					<span className="badge badge-warning">Không</span>
				)
			} else {
				return (
					<span className="badge badge-success">Có</span>
				)
			}
		}
		function filterValueBaoHanh(cell, row) {
			if (cell === 0) {
				return ("Không")
			} else {
				return ("Có")
			}
		}
		
		function formatTrangThai(cell, row) {
			var message = '';
			if (cell === 0) {
				return (
					<span className="badge badge-primary"><i className="fas fa-check mr-1"></i>Hoàn thành</span>
				);
			} else if (cell === 1) {
				return (
					<span className="badge badge-success"><i className="fas fa-sync-alt mr-1"></i>Đang chạy</span>
				);
			} else if (cell === 2) {
				return (
					<span className="badge badge-danger"><i className="fas fa-ban mr-1"></i>ID die không thể chạy</span>
				);
			} else if (cell === 3) {
				return (
					<span className="badge badge-warning"><i className="fas fa-times mr-1"></i>Hủy đơn</span>
				);
			} else if (cell === 5) {
				return (
					<span className="badge badge-info"><RiExchangeDollarLine size={12} className="mr-1" />Hoàn tiền</span>
				);
			} else {
				return ('');
			}
		}
		function filterValueTrangThai(cell, row) {
			if (cell === 0) {
				return ('Hoàn thành');
			} else if (cell === 1) {
				return ('Đang chạy');
			} else if (cell === 2) {
				return ('ID die không thể chạy');
			} else if (cell === 3) {
				return ('Hủy đơn');
			} else if (cell === 5) {
				return ('Hoàn tiền');
			} else {
				return ('');
			}
		}
		function formatLoai(cell, row) {
			if (cell === 'review_sv1') {
				return ('SV1');
			} else if (cell === 'review') {
				return ('SV2');
			} else if (cell === 'review_sv3') {
				return ('SV3');
			} else if (cell === 'review_sv4') {
				return ('SV4');
			} else if (cell === 'review_sv5') {
				return ('SV5');
			} else {
				return ('');
			}
		}
		function formatStart(cell, row) {
			if (row.type == 'review_sv1' || row.type == 'review' || row.type == 'review_sv5') {
				return (row.start_review);
			} else if (row.type == 'review_sv3' || row.type == 'review_sv4') {
				return (row.start_checkin);
			} else {
				return ('');
			}
		}
		
		const expandRow = {
			renderer: (row) => (
				<div className="col-lg-8 row">
					<div className="col-lg-4">
						<div className="mt-2">
							<h6 className="mb-0">Lọc nâng cao</h6>
							<div className="row mt-3">
								{ row.gender == '0' || row.gender == '3' ?
									<div className="col-4">
										<h6 className="mb-0 font-14"><i className="fas fa-venus-mars font-16"></i> Tất cả</h6>
									</div>
								: '' }
								{ row.gender == '1' ?
									<div className="col-4">
										<h6 className="mb-0 font-14"><i className="fas fa-venus-mars font-16"></i> Nam</h6>
									</div>
								: '' }
								{ row.gender == '2' ?
									<div className="col-4">
										<h6 className="mb-0 font-14"><i className="fas fa-venus-mars font-16"></i> Nữ</h6>
									</div>
								: '' }
								{ row.age_min != '0' ? 
									<div className="col-4">
										<h6 className="mb-0 font-14"><i className="fas fa-birthday-cake"></i> {row.age_min} - {row.age_max}</h6>
									</div>
								: ''
								}
								{ row.friend_min != '0' ? 
									<div className="col-4">
										<h6 className="mb-0 font-14"><i className="fas fa-user-friends"></i> {row.friend_min} - {row.friend_max}</h6>
									</div>
								: ''
								}
							</div>
						</div>
					</div>
					<div className={"col-lg-4 " + ((row.status !== 3 && row.status !== 0) || this.props.auth.user.type === 1 ? '' : 'offset-lg-4')}>
						<div className="form-group">
							<label htmlFor="example-text-input">Nội dung</label>
							<textarea className="form-control" id="nd" name="nd" placeholder="" rows="2" defaultValue={row.ndcmt} ></textarea>
						</div>
					</div>
					<div className="col-lg-4">
						<div className="form-group">
							<label htmlFor="example-text-input">Ghi chú</label>
							<textarea className="form-control" id="gc" name="gc" placeholder="" rows="2" defaultValue={row.notes}></textarea>
						</div>
					</div>
					{/* <div className="col-lg-4">
						<div className="form-group">
							{
								row.status !== 3 && row.status !== 0 ?
									<Fragment>
										<button type="button" name="huy_order" className="btn btn-block btn-sm btn-rounded btn-hero-light" onClick={() => {this.huyOrder(row.id, row.orders_id)}}>Hủy Order</button>
									</Fragment>
								: ''
							}
							{
								this.props.auth.user.type === 1 ?
									<Fragment>
										<button type="button" onClick={() => {this.openModalEditAdmin(row)}} className="btn btn-block btn-sm btn-rounded btn-hero-success">Cài đặt admin</button>
									</Fragment>
								: ''
							}
						</div>
					</div> */}
				</div>
			),
			showExpandColumn: true,
			expandColumnPosition: 'right',
			expandHeaderColumnRenderer: ({ isAnyExpands }) => {
				if (isAnyExpands) {
					return <b>-</b>;
				}
				return <b>+</b>;
			},
			expandColumnRenderer: ({ expanded }) => {
				if (expanded) {
					return (
						<b>-</b>
					);
				}
				return (
					<b>...</b>
				);
			}
		};
		
		const columns = [{
			dataField: 'id',
			text: 'ID',
			sort: true,
		}, {
		  text: 'Thao tác',
		  dataField: '-',
		  style: {
			'white-space' : 'nowrap'
		  },
		  formatter: (cell, row) => 
		  	<Fragment>
				{
					this.props.auth.user.type === 1 ?
						<Fragment>
							<button type="button" onClick={() => {this.openModalEditAdmin(row)}} className="btn btn-hero-success text-center p-2 mr-1"><i className="fa fa-fw fa-pen"></i></button>
						</Fragment>
					: ''
				}
				{
					row.status !== 3 && row.status !== 0 ?
						<Fragment>
							<button type="button" name="huy_order" className="btn btn-hero-danger text-center p-2 mr-1" onClick={() => {this.huyOrder(row.id, 0)}}><i className="fa fa-fw fa-trash"></i></button>
						</Fragment>
					: ''
				}
	    	</Fragment>
		}, {
			dataField: 'object_id',
			text: 'ID Seeding',
			sort: true,
		}, {
			dataField: 'type',
			text: 'Loại',
			sort: true,
			formatter: formatLoai,
			filterValue: formatLoai
		}, {
			dataField: 'is_warranty',
			text: 'Bảo hành',
			sort: true,
			formatter: formatBaoHanh,
			filterValue: filterValueBaoHanh
		}, {
			dataField: 'start_review',
			text: 'Start',
			sort: true,
			formatter: formatStart,
			filterValue: formatStart
		}, {
			dataField: 'quantity',
			text: 'Số lượng',
			sort: true,
		}, {
			dataField: 'count_success',
			text: 'Đã chạy',
			sort: true,
		}, {
			dataField: 'prices',
			text: 'Tổng tiền',
			sort: true,
		}, {
			dataField: 'created_at',
			text: 'Khởi tạo',
			sort: true,
		}, {
			dataField: 'username',
			text: 'Username',
			sort: true,
		    classes: 'notranslate',
		}, {
			dataField: 'status',
			text: 'Trạng thái',
			sort: true,
			formatter: formatTrangThai,
			filterValue: filterValueTrangThai
		}];
		return (
			<Fragment>
				<ToolkitProvider keyField="id" data={ listtientrinh } columns={ columns } search >
					{
						props => (
							<div>
								<SearchBar { ...props.searchProps } />
								<div className="row mb-4">
									<div className="col-md-12">
										{ this.props.auth.user.type === 1 ?
											<Fragment>
												<button onClick={() => {this.getListByView(1)}} type="button" className="btn btn-primary mr-1">Xem tất cả</button>
												<button onClick={() => {this.getListByView(0)}} type="button" className="btn btn-primary mr-1">Xem riêng</button>
											</Fragment>
										: ''}
										{/* <select className="form-control custom-select select-light col-md-3 custom-limit_pagi" id="limit_pagi" name="limit_pagi" onChange={this.onChangeLimit} value={limit_pagi} >
											<option value="0">Số lịch sử hiển thị (0-500)</option>
											<option value="1">500-1000</option>
											<option value="2">1000-1500</option>
											<option value="3">1500-2000</option>
											<option value="4">2000-2500</option>
											<option value="5">2500-3000</option>
											<option value="7">3000-3500</option>
											<option value="8">3500-4000</option>
											<option value="9">4500-5000</option>
										</select> */}
										<button onClick={(e) => {this.getListByLimit(this.state.page, "prev")}} name="page" value={this.state.page} type="button" className="btn btn-primary mr-1">{"<"}</button>
										<button onClick={(e) => {this.getListByLimit(this.state.page, "next")}} name="page" value={this.state.page} type="button" className="btn btn-primary mr-1">{">"}</button>
										<button onClick={(e) => {this.getListBySearch(e)}} name="view" value="0" type="button" className="btn btn-primary mr-1">Tìm kiếm</button>
										<input  onChange={this.onChange} value={this.state.obj_search}  name="obj_search" type="text" className="form-control mr-1" style={{"width": "100px", "display": "inline-block"}}/>
									</div>
								</div>
								<BootstrapTable { ...props.baseProps } expandRow={ expandRow } pagination={ paginationFactory() } wrapperClasses="table-responsive" striped hover condensed />
							</div>
						)
					}
				</ToolkitProvider>
				<div>
					{/*<BootstrapTable keyField='id' data={ ar_listtientrinh } columns={ columns } expandRow={ expandRow } pagination={ paginationFactory() } wrapperClasses="table-responsive" striped hover condensed />*/}
				</div>
				<Modal className="bt-customWidth-dtable" toggle={(e) => {this.openModalTangThemGia(!this.state.isModalBrowseGroupsOpen, e)}} isOpen={this.state.isModalBrowseGroupsOpen}>
					<div className="modal-header">
						<p className="mb-0 text-dark">Tăng giá Job ID { tanggiathem.lhibv }</p>
						<button onClick={(e) => {this.openModalTangThemGia(false, e)}} type="button" className="close">×</button>
					</div>
					<ModalBody>
						<h6>Giá cũ là: <span className="font-bold">{tanggiathem.price_per}</span> Coin. Bạn muốn tăng lên ?</h6>
						<div className="col-md-6 px-0">
							<input type="number" placeholder="Nhập giá cần tăng" className="form-control form-control-line mt-2" name="price" defaultValue={tanggiathem.price} onChange={this.onChange} onKeyPress={this.onKeyPress} />
						</div>
					</ModalBody>
					<ModalFooter>
						<button type="button" className="btn btn-danger" onClick={(e) => {this.openModalTangThemGia(false, e)}} >Huỷ</button>
						<button type="button" className="btn btn-primary" onClick={this.clickTangGiaThem}>Đồng ý</button>
					</ModalFooter>
				</Modal>
				{(this.props.auth.user.type === 1 && isOpenModalEditAdmin) ? <ModalEditAdmin openModal={this.openModalEditAdmin} isOpenModal={isOpenModalEditAdmin} dataItemSelect={dataItemSelect} /> : '' }
			</Fragment>
		)
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	buffseedingreview: state.buffseedingreview,
});
const mapDispatchToProps = { loadBSR, updateTangGiaThem, upTangGiaThem, upHuyOrder, updateLimitBSR, updateTypeBSR }

export default connect(mapStateToProps, mapDispatchToProps)(nhatky);