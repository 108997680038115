import React, { Component, Fragment } from 'react';
import {
  BrowserRouter as Router,
  // Switch,
  // Route,
  Link
} from "react-router-dom";
import {
	// Button,
	// Modal,
	// ModalHeader,
	// ModalBody,
	// Form,
	// FormGroup,
	// Label,
	// Input,
	// NavLink,
	Alert
} from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { forgotpassword, resetpassword, checkTokenReset } from '../../actions/authActions';
import { clearErrors } from '../../actions/errorActions';
import png_5TWfpYt from '../../iconlanding/8/5TWfpYt.png';
import png_pHXlFHT from '../../iconlanding/8/pHXlFHT.png';
import png_PIuYHRp from '../../iconlanding/8/PIuYHRp.png';
import png_xjvImSO from '../../iconlanding/8/xjvImSO.png';
import parse from 'html-react-parser';

class ForgotpasswordModal extends Component {
	state = {
		// modal: false,
		username: '',
		email: '',
		password: '',
		repeatpassword: '',
		msg: null,
		msg2: null,
		checktoken: 1,
	}
	
	static propTypes =  {
		auth: PropTypes.object.isRequired,
		error: PropTypes.object.isRequired,
		register: PropTypes.func.isRequired,
		clearErrors: PropTypes.func.isRequired
	}
	
	componentDidMount() {
		if (this.props.action_reset === '/resetpassword') {
			var search_param = new URLSearchParams(window.location.search);
			var token = search_param.get('token');
			if (token == null) {
				token = '';
			}
			var data = {
				token,
			};
			this.props.checkTokenReset(data);
		}
	}
	
	componentDidUpdate(prevProps) {
		const { error } = this.props;
		if (error !== prevProps.error) {
			// Check for register error
			if (error.id === 'FORGOTPASSWORD_SEND_FAIL' || error.id === 'RESETPASSWORD_SEND_FAIL') {
				this.setState({ msg: error.msg.msg, msg2: null });
			} else if (error.id === 'FORGOTPASSWORD_SEND_SUCCESS' || error.id === 'RESETPASSWORD_SEND_SUCCESS') {
				this.setState({ msg: null, msg2: error.msg.msg });
			} else if (error.id === 'TOKEN_RESET_SUCCESS') {
				this.setState({ checktoken: 1 });
			} else if (error.id === 'TOKEN_RESET_FAIL') {
				this.setState({ checktoken: 2 });
			} else {
				this.setState({ msg: null, msg2: null });
			}
		}
	}
	
	clearAlert = () => {
		// Clear errors
		this.props.clearErrors();
	}
	
	onChange = e => {
		this.setState({ [e.target.name]: e.target.value });
	};
	
	onSubmit = e => {
		e.preventDefault();
		
		const { username, email, password, repeatpassword } = this.state;
		var search_param = new URLSearchParams(window.location.search);
		var token = search_param.get('token');
		if (token == null) {
			token = '';
		}
		
		if (this.props.action_reset === '/resetpassword') {
			var newUser = {
				token,
				password,
				repeatpassword,
			};
			this.props.resetpassword(newUser);
		} else {
			var newUser = {
				username,
				email,
			};
			this.props.forgotpassword(newUser);
		}
	};
	
	render() {
		let loading = this.props.auth.loading;
		const domainSite = window.location.hostname;
		var config_website = {};
		if (localStorage.getItem("confignavbar") != null) {
			config_website = JSON.parse(localStorage.getItem("confignavbar"));
		} else {
			config_website = this.props.auth.config_website;
		}
		var search_param = new URLSearchParams(window.location.search);
		var token = search_param.get('token');
		if (token == null) {
			token = '';
		}
		var html_form = "";
		if ((this.props.action_reset === '/resetpassword' && token.trim() == '') || (this.props.action_reset === '/resetpassword' && this.state.checktoken == 2)) {
			html_form = (
				<Fragment>
					<div className="alert alert-danger d-flex align-items-center justify-content-between" role="alert" >
						<div className="flex-fill mr-3">
							<p className="mb-0">Link đặt lại mật khẩu đã hết hạn! #tk</p>
						</div>
						<div className="flex-00-auto">
							<i className="fa fa-fw fa-exclamation-circle"></i>
						</div>
					</div>
				</Fragment>
			)
		} else {
			html_form = (
				<Fragment>
					{loading ? (
						<div className="preloader">
							<div className="spinner-grow spinner-grow-sm text-primary" role="status" style={{ "marginTop": "50%" }}>
							  <span className="sr-only"></span>
							</div>
							<div className="spinner-grow spinner-grow-sm text-danger" role="status" style={{ "marginTop": "50%" }}>
							  <span className="sr-only">.</span>
							</div>
							<div className="spinner-grow spinner-grow-sm text-warning" role="status" style={{ "marginTop": "50%" }}>
							  <span className="sr-only"></span>
							</div>
						</div>
					):('')}
					{this.state.msg ? (
						<div className="alert alert-warning d-flex align-items-center justify-content-between" role="alert" >
						  <div className="flex-fill mr-3">
							<p className="mb-0">{parse(this.state.msg)}</p>
						  </div>
						  <div className="flex-00-auto">
							<i className="fa fa-fw fa-exclamation-circle"></i>
						  </div>
						</div>
					  ) : null}
					{this.state.msg2 ? (
						<div className="alert d-flex align-items-center justify-content-between" role="alert" style={{"color": "#445e27", "backgroundColor": "#e6f0db", "borderColor": "#dceacd"}} >
							<div className="flex-fill mr-3">
								<p className="mb-0">{parse(this.state.msg2)}</p>
							</div>
						</div>
					) : null}
					<form className="registration-form" onSubmit={this.onSubmit} method="POST" >
						{
							this.props.action_reset === '/resetpassword' ?
								<Fragment>
									<div className="row">
										<div className="col-sm-12">
											<div className="form-group icon-group">
												<input name="password" className="form-control" placeholder="Nhập mật khẩu" required="" id="password" type="password" onChange={this.onChange} />
												<span className="input-icon">
													<img src={png_PIuYHRp} alt="icon"/>
												</span>
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-sm-12">
											<div className="form-group icon-group">
												<input name="repeatpassword" className="form-control" placeholder="Nhập lại mật khẩu" required="" id="repeatpassword" type="password" onChange={this.onChange} />
												<span className="input-icon">
													<img src={png_PIuYHRp} alt="icon"/>
												</span>
											</div>
										</div>
									</div>
									
									<button type="submit" className="btn btn-primary">Đặt lại mật khẩu</button>
								</Fragment>
							:
								<Fragment>
									<div className="row">
										<div className="col-sm-12">
											<div className="form-group icon-group">
												<input name="username" placeholder="Nhập tài khoản" required="" id="username" type="text" className="form-control" onChange={this.onChange} />
												<span className="input-icon">
													<img src={png_pHXlFHT} alt="icon"/>
												</span>
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-sm-12">
											<div className="form-group icon-group">
												<input name="email" className="form-control" placeholder="Nhập email" required="" id="email" type="email" onChange={this.onChange} />
												<span className="input-icon">
													<img src={png_5TWfpYt} alt="icon"/>
												</span>
											</div>
										</div>
									</div>
									
									<button type="submit" className="btn btn-primary">Gửi</button>
								</Fragment>
						}
					</form>
				</Fragment>
			)
		}
		return (
			<Router>
				<Fragment>
					<div className="landingcus8" style={{"zoom": "122%"}}>
						<div className="gustavo gustavo">
							<nav className="navbar navbar-default navbar-static-top ">
								<div className="container">
									<div className="navbar-header">
										<a className="navbar-brand" href="/" style={{"fontSize": "30px"}}>
											{domainSite}
										</a>
										<ul className="nav navbar-nav navbar-right register-btn-cus" style={{"display": "none"}}>
											<li className="signupBtn" style={{"marginLeft": "5px"}}>
												<Link to="/services" onClick={this.props.action_service} className="nav-link">
													Dịch vụ
												</Link>
											</li>
											<li className="signupBtn" style={{"marginLeft": "5px"}}>
												<Link to="/" onClick={this.props.action_register} className="nav_link">
													Đăng ký
												</Link>
											</li>
										</ul>
									</div>
									<div id="navbar" className="collapse navbar-collapse">
										<ul className="nav navbar-nav navbar-right">
											<li className="  signinBtn ">
												<Link to="/services" onClick={this.props.action_service} className="nav-link">
													Dịch vụ
												</Link>
											</li>
											<li className="  signinBtn ">
												<a className="nav_link" href="/" >Đăng nhập</a>
											</li>
											<li className="  signupBtn ">
												<Link to="/" onClick={this.props.action_register} className="nav_link">
													Đăng ký
												</Link>
											</li>
										</ul>
									</div>
								</div>
							</nav>
							<div className="container">
								<div className="row signupFrom">
									<div className="col-md-8 col-md-offset-2">
										<h1 className="main-head text-center" style={{"fontSize": "95px"}}>
											{
												this.props.action_reset === '/resetpassword' ?
													"Đặt lại mật khẩu "
												:
													"Quên mật khẩu "
											}
											<span className="sphere"></span>
										</h1>
										<br/>
										{html_form}
									</div>
								</div>
							</div>
							<footer>
								<div className="footer-top">
									<div className="container">
										<div className="row">
											<div className="col-sm-6">
												<h2>Bạn đã sẵn sàng bắt đầu với chúng tôi?</h2>
											</div>
											<div className="col-sm-6">
												<Link to="#" className="joinBtn" >Tham Gia Ngay</Link>
											</div>
										</div>
									</div>
								</div>
								<div className="footer-main">
									<div className="container">
										<div className="row">
											<div className="col-sm-6">
												<h4 className="widget-title">{domainSite}</h4>
												<p>Đã có 8 năm hoạt động trong lĩnh vực Online Maketing, nắm bắt được sự thay đổi và định hứng được sự phát triển của thị trường, đi đầu trong lĩnh vực online maketing, cung cấp đa dạng các dịch vụ, giúp người dùng luôn tiếp cận được với mức giá phải chăng và hiệu quả maketing tối ưu nhất.</p>
											</div>
											<div className="col-sm-3">
												<h4 className="widget-title">Hữu ích</h4>
												<ul>
													<li>
														<a href="/"><i className="fa fa-angle-right" aria-hidden="true"></i> Sign in</a>
													</li>
													<li>
														<a href="/"><i className="fa fa-angle-right" aria-hidden="true"></i> Sign up</a>
													</li>
												</ul>
											</div>
											<div className="col-sm-3">
												<h4 className="widget-title">Dịch vụ</h4>
												<ul>
													<li>
														<a href=""><i className="fa fa-angle-right" aria-hidden="true"></i> Facebook</a>
													</li>
													<li>
														<a href=""><i className="fa fa-angle-right" aria-hidden="true"></i> Instagram</a>
													</li>
													<li>
														<a href="/"><i className="fa fa-angle-right" aria-hidden="true"></i> Tiktok</a>
													</li>
													<li>
														<a href="/"><i className="fa fa-angle-right" aria-hidden="true"></i> Youtube</a>
													</li>
													<li>
														<a href="/"><i className="fa fa-angle-right" aria-hidden="true"></i> Twitter</a>
													</li>
													<li>
														<a href="/"><i className="fa fa-angle-right" aria-hidden="true"></i> Shopee</a>
													</li>
												</ul>
											</div>
										</div>
									</div>
								</div>
								<div className="footer-bottom">
									<div className="container">
										<div className="row">
											<div className="col-sm-6">© Copyright {domainSite}. All Rights Reserved</div>
											<div className="col-sm-6">
												<ul className="copyright-menu">
													{/* <li>
														<a href="/">Terms of Service</a>
													</li>
													<li>
														<a href="/">Privacy Policy</a>
													</li> */}
												</ul>
											</div>
										</div>
									</div>
								</div>
							</footer>
						</div>
					</div>
				</Fragment>
			</Router>
		);
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	error: state.error
})

export default connect(mapStateToProps, { forgotpassword, resetpassword, checkTokenReset, clearErrors })(ForgotpasswordModal);