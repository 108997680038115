import React, { Component, Fragment } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { creatItemBuffSub,getDataPriceFunction } from '../../../actions/buffSubYoutubeAction';
import { add, getConfigMoDong } from '../../../actions/youtubeActions';
import store from '../../../store';
import { connect } from 'react-redux';
import parse from 'html-react-parser';
import ServicesDescription from "../ServicesDescription";
import { isMobile } from 'react-device-detect';

class Taotientrinh extends Component {
	componentDidMount(){
        // this.props.getDataPriceFunction('youtube_buffsub');
		this.props.getConfigMoDong('youtube_buffsub');
	}

	state = {
		showDescription: isMobile? false : true
	}
	
	handleChangeDateStart = date => {
	    let dataFormOld = this.props.buffsub.formData;
		let dataFormNew = {
			...dataFormOld,
			EndDatebh: this.addDays(date, 7),
			startDatebh: date,
		}

		store.dispatch({
			type: 'CHANGE_DATA_FORM_YTB_BUFFSUB',
			payload: dataFormNew
		});
	}

	handleChangeDateEnd = date => {
	    let dataFormOld = this.props.buffsub.formData;
		let dataFormNew = {
			...dataFormOld,
			EndDatebh: date
		}

		store.dispatch({
			type: 'CHANGE_DATA_FORM_YTB_BUFFSUB',
			payload: dataFormNew
		});
	}

	addDays = (date, days) => {
	  	var result = new Date(date);
	  	result.setDate(result.getDate() + days);
	  	return result;
	}

	onChangeForm = (e) => {
		let valueadd = '';
		if (e.target.type === 'radio' || e.target.type === 'select-one' || e.target.type === 'number') {
			valueadd = Math.abs(parseInt(e.target.value));
			if (isNaN(valueadd)) {
				valueadd = '';
			}
		} else {
			valueadd = e.target.value;
		}

		let namvl = e.target.name;

		let dataFormOld = this.props.buffsub.formData;
		let dataFormNew = {
			...dataFormOld,
			[namvl]: valueadd
		}

		if (namvl === 'loaiseeding') {
			if (valueadd == 1) {
			 	dataFormNew['giatien'] = this.props.buffsub.datapricebuffsub.youtube_buffsub.prices_web;
			} else if (valueadd == 2) {
			 	dataFormNew['giatien'] = this.props.buffsub.datapricebuffsub.youtube_buffsub_sv2.prices_web;
			} else {
			 	dataFormNew['giatien'] = this.props.buffsub.datapricebuffsub.youtube_buffsub_sv3.prices_web;
			}
		}
		
		// if (namvl === 'baohanh') {
			// if (valueadd === 1) {
			 	// dataFormNew['giatien'] = this.props.buffsub.datapricebuffsub.youtube_buffsub.min_bh_web;
			// } else {
			 	// dataFormNew['giatien'] = this.props.buffsub.datapricebuffsub.youtube_buffsub.prices_web;
			// }
		// }

		store.dispatch({
			type: 'CHANGE_DATA_FORM_YTB_BUFFSUB',
			payload: dataFormNew
		});
	}

	replaceIstagram = (e) => {
		let valueS = e.target.value;
		let dataFormOld = this.props.buffsub.formData;
		if (valueS.trim() !== '' && valueS.indexOf("youtube.com") > -1) {
			let vl = valueS.slice(valueS.indexOf("www.youtube.com/channel/") + 24);
			var id = e.target.value.match(/((channel|c|user)\/|\/@)(.*?)(\/|\?|$)/);
			if (id) {
				var dataFormNew = {
					...dataFormOld,
					// 'profile_user': vl.replace("/", "")
					'profile_user': decodeURIComponent(id[3])
				}
			} else {
				var dataFormNew = {
					...dataFormOld,
					// 'profile_user': vl.replace("/", "")
					'profile_user': e.target.value
				}
			}
		} else {
			var dataFormNew = {
				...dataFormOld,
				'profile_user': e.target.value
			}
		}
		store.dispatch({
			type: 'CHANGE_DATA_FORM_YTB_BUFFSUB',
			payload: dataFormNew
		});
	}

	onClickShowDescription = (data) => {
		this.setState({
			showDescription: data
		});
	}
	
 	render() {
 		const { profile_user,loaiseeding,baohanh,sltang,giatien,ghichu,startDatebh,EndDatebh } = this.props.buffsub.formData;
 		const { youtubebuffsub_sv1_mo_dong, youtubebuffsub_sv2_mo_dong, youtubebuffsub_sv3_mo_dong } = this.props.buffsub;
		
		var listDataServicesInfo = {};
		try {
			if (localStorage.getItem("listDataServicesInfo") != null) {
				listDataServicesInfo = JSON.parse(localStorage.getItem("listDataServicesInfo"));
			} else {
				listDataServicesInfo = this.props.adminpage.listDataServicesInfo;
			}
		} catch(e) {
			listDataServicesInfo = {};
		}
		var html_haydoctranhmattien = '';
		var html_haydoctranhmattien_chinh = '';
		var html_haydoctranhmattien_sv = '';
		var html_sub_sv1 = '';
		var html_sub_sv2 = '';
		var html_sub_sv3 = '';
		if (typeof(listDataServicesInfo.youtube_buff_sub_tab) !== 'undefined') {
			html_haydoctranhmattien = parse(listDataServicesInfo.youtube_buff_sub_tab.ghichu);
			html_haydoctranhmattien_chinh = parse(listDataServicesInfo.youtube_buff_sub_tab.ghichu_chinh);
		}
		if (typeof(listDataServicesInfo.youtube_buff_sub_sv1) !== 'undefined') {
			html_sub_sv1 = parse(listDataServicesInfo.youtube_buff_sub_sv1.ghichu_sv);
			if (loaiseeding.toString() === "1") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.youtube_buff_sub_sv1.ghichu);
			}
		}
		if (typeof(listDataServicesInfo.youtube_buff_sub_sv2) !== 'undefined') {
			html_sub_sv2 = parse(listDataServicesInfo.youtube_buff_sub_sv2.ghichu_sv);
			if (loaiseeding.toString() === "2") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.youtube_buff_sub_sv2.ghichu);
			}
		}
		if (typeof(listDataServicesInfo.youtube_buff_sub_sv3) !== 'undefined') {
			html_sub_sv3 = parse(listDataServicesInfo.youtube_buff_sub_sv3.ghichu_sv);
			if (loaiseeding.toString() === "3") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.youtube_buff_sub_sv3.ghichu);
			}
		}
		
 		return (
    		<Fragment>
    			<style>
					{"\
			        	.react-datepicker-wrapper{\
			          		width: 100%;\
			        	}\
			      	"}
		      	</style>

    			<div className="row">
					<div className="col-md-4">
						<label className="mt-2">Link kênh:</label>
					</div>
					<div className="col-md-8">
						<div className="form-group">
                            <input onChange={(e) => {this.replaceIstagram(e)}} value={profile_user} name="profile_user" type="text" className="form-control"/>
                        </div>
					</div>
				</div>

				<div className="row mt-2">
					<div className="col-md-4">
						<label className="mt-2">Loại Seeding cần tăng:</label>
					</div>
					<div className="col-md-8">
						<div className="form-group">
							<div className="custom-control custom-radio custom-control-inline" style={{opacity: this.props.buffsub.datapricebuffsub.youtube_buffsub.prices_web == 0 ? '0.3' : '1' }}>
								<input onChange={(e) => {this.onChangeForm(e)}} value="1" type="radio" className="custom-control-input" id="loaiseeding_1" name="loaiseeding" checked={loaiseeding.toString() === "1"} disabled={this.props.buffsub.datapricebuffsub.youtube_buffsub.prices_web == 0 ? 'disabled' : ''} />
								<label className="custom-control-label" htmlFor="loaiseeding_1" >SV1 {html_sub_sv1} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.buffsub.datapricebuffsub.youtube_buffsub.prices_web} coin</span> {youtubebuffsub_sv1_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
							</div><br/>
							<div className="custom-control custom-radio custom-control-inline" style={{opacity: this.props.buffsub.datapricebuffsub.youtube_buffsub_sv2.prices_web == 0 ? '0.3' : '1' }}>
								<input onChange={(e) => {this.onChangeForm(e)}} value="2" type="radio" className="custom-control-input" id="loaiseeding_2" name="loaiseeding" checked={loaiseeding.toString() === "2"} disabled={this.props.buffsub.datapricebuffsub.youtube_buffsub_sv2.prices_web == 0 ? 'disabled' : ''} />
								<label className="custom-control-label" htmlFor="loaiseeding_2" >SV2 {html_sub_sv2} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.buffsub.datapricebuffsub.youtube_buffsub_sv2.prices_web} coin</span> {youtubebuffsub_sv2_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
							</div><br/>
							<div className="custom-control custom-radio custom-control-inline" style={{opacity: this.props.buffsub.datapricebuffsub.youtube_buffsub_sv3.prices_web == 0 ? '0.3' : '1' }}>
								<input onChange={(e) => {this.onChangeForm(e)}} value="3" type="radio" className="custom-control-input" id="loaiseeding_3" name="loaiseeding" checked={loaiseeding.toString() === "3"} disabled={this.props.buffsub.datapricebuffsub.youtube_buffsub_sv3.prices_web == 0 ? 'disabled' : ''} />
								<label className="custom-control-label" htmlFor="loaiseeding_3" >SV3 {html_sub_sv3} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.buffsub.datapricebuffsub.youtube_buffsub_sv3.prices_web} coin</span> {youtubebuffsub_sv3_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
							</div>
						</div>
					</div>
				</div>

				{/* <div className="row mt-2">
					<div className="col-md-4">
						<label className="mt-2">Chế độ bảo hành:</label>
					</div>
					<div className="col-md-8">
						<div className="form-group">
							<select onChange={(e) => {this.onChangeForm(e)}} value={baohanh.toString()} name="baohanh" className="form-control">
								<option value="0">Không bảo hành</option>
								<option value="1">Bảo hành (Bảo hành nếu tụt quá 10%)</option>
							</select>
						</div>

						{ baohanh === 1 ?
							<div className="form-group">
								<div className="row">
									<div className="col-md-6">
										<p className="mb-0">Chọn ngày bắt đầu</p>
										<DatePicker className="form-control w-100"
										showTimeSelect minDate={new Date()}
										selected={startDatebh}
										onChange={this.handleChangeDateStart} />
									</div>
									<div className="col-md-6">
										<p className="mb-0">Chọn ngày kết thúc</p>
										<DatePicker className="form-control"
										showTimeSelect minDate={startDatebh}
										maxDate={this.addDays(startDatebh, 7)}
										selected={EndDatebh}
										onChange={this.handleChangeDateEnd} />
									</div>
								</div>
							</div>
						:
							''
						}
					</div>
				</div> */}

				<div className="row mt-2">
					<div className="col-md-4">
						<label className="mt-2">Số lượng cần tăng:</label>
					</div>
					<div className="col-md-8">
						<div className="form-group">
                            <input type="number" className="form-control" onChange={(e) => {this.onChangeForm(e)}} name="sltang" value={sltang}/>
                        </div>

						<div className="form-group mt-4">
							<div className="card card-orange">
							    <div className="card-body py-2">
							        <h6 className="font-14 mb-0 text-danger font-bold">Lưu ý: Nên buff dư thêm 30 - 50% trên tổng số lượng để tránh tụt.</h6>
							    </div>
							</div>
						</div>
					</div>
				</div>

				<div className="row mt-2">
					<div className="col-md-4">
						<label className="mt-2">Giá tiền mỗi tương tác:</label>
					</div>
					<div className="col-md-8">
						<div className="form-group">
							<input type="number" className="form-control" onChange={(e) => {this.onChangeForm(e)}} name="giatien" value={giatien}/>
						</div>
					</div>
				</div>
				
				<div className="row mt-2">
					<div className="col-md-4">
						<label className="mt-2">Ghi chú:</label>
					</div>
					<div className="col-md-8">
						<div className="form-group">
                            <textarea onChange={(e) => {this.onChangeForm(e)}} name="ghichu" value={ghichu} className="form-control" rows="4" placeholder="Nhập nội dung ghi chú về tiến trình của bạn"></textarea>
                        </div>
					</div>
				</div>

				<button type="button" className="btn badge-primary-cus btn-rounded btn-show-description-cus" onClick={(e) => {this.onClickShowDescription(!this.state.showDescription)}}>
					{
						this.state.showDescription?
							<i className="fa fa-angle-up" style={{"marginRight": "5px"}} aria-hidden="true"></i>
						:
							<i className="fa fa-angle-down" style={{"marginRight": "5px"}} aria-hidden="true"></i>
					}
					Thông tin dịch vụ
				</button>
				
				<ServicesDescription allservicesinfo={listDataServicesInfo} server={loaiseeding.toString()} tab="youtube_buff_sub" giatien={giatien} baohanh={0} showDescription={this.state.showDescription} />
				
				<div className="row mt-2" style={{"display": this.state.showDescription? "flex" : "none"}}>
				    <div className="col-md-12 bold" style={{"marginBottom": "10px"}}>
						<div className="card card-select-cus">
							<div className="card-body card-body-select-cus">
								<p className="mb-0 mt-3 text-wrap">
									<ul>
										<li>1 ID KHÔNG CÀI 2 ĐƠN CÙNG LÚC TRONG HỆ THỐNG ! ĐƠN CŨ XONG MỚI CÀI ĐƠN MỚI ! CỐ TÌNH CÀI BỊ HỤT SỐ LƯỢNG SẼ KHÔNG XỬ LÝ !</li>
										<li>Nếu ID đang chạy trên hệ thống mà bạn vẫn mua id đó cá hệ thống bên khác, nếu có tình trạng hụt số lượng giữa 2 bên thì sẽ không được xử lí!</li>
										{html_haydoctranhmattien}
										{html_haydoctranhmattien_chinh}
										{html_haydoctranhmattien_sv}
										<li>Nếu gặp lỗi hãy nhắn tin hỗ trợ phía bên phải góc màn hình hoặc vào mục liên hệ hỗ trợ để được hỗ trợ tốt nhất!</li>
									</ul>
								</p>
								<span className="badge badge-cus position-absolute-cus badge-top-left-cus badge-primary-cus badge-pill-cus"> HÃY ĐỌC ĐỂ TRÁNH MẤT TIỀN KHI SỬ DỤNG! </span>
							</div>
						</div>
				    </div>
				</div>
				<div className="row mt-4">
				    <div className="col-12 text-center bold">
				        <div className="card card-info">
				            <div className="card-body">
				                <h5 className="font-bold">Tổng: <span className="bold green"><span>{Math.round((sltang * giatien + Number.EPSILON) * 100) / 100}</span> Coin</span></h5>
				                <h6 className="mb-0">Bạn sẽ buff <span className="bold green">{sltang} sub</span> với giá <span className="green bold">{Math.round((sltang * giatien + Number.EPSILON) * 100) / 100}</span> Coin</h6>
				            </div>
				        </div>
				    </div>
				</div>

				<div className="row mt-4">
					<div className="col-md-12">
						<div className='form-group float-right w-100'>
							<button onClick={()=>{this.props.add('youtube_buffsub', this.props.buffsub.formData)}} type="button" className="btn btn-hero-primary btn-rounded btn-block">
	                            Tạo tiến trình
	                        </button>
                        </div>
					</div>
				</div>
			</Fragment>
		);
  	}
}

const mapStateToProps = state => ({
	buffsub: state.buffsubyoutube,
});

export default connect(mapStateToProps, {creatItemBuffSub,getDataPriceFunction,add,getConfigMoDong})(Taotientrinh);
