import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import store from '../../../store';

import Swal from 'sweetalert2';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { addFBRIC, updateFBRIC, CheckCookie, loadPriceFBRIC } from '../../../actions/FacebookbotreplyinboxchoActions';
import { add, checkCookieFb } from '../../../actions/FbbotActions';
import { LOADING_FBRIC } from "../../../actions/types";
import parse from 'html-react-parser';
import ServicesDescription from "../ServicesDescription";
import { isMobile } from 'react-device-detect';

const date7dnext = () => {
	var result = new Date();
  	result.setDate(result.getDate() + 7);
  	return result;
}

class taotientrinh extends Component {
	static propTypes =  {
		addFBRIC: PropTypes.func.isRequired,
		updateFBRIC: PropTypes.func.isRequired,
		CheckCookie: PropTypes.func.isRequired,
		checkCookieFb: PropTypes.func.isRequired,
		loadPriceFBRIC: PropTypes.func.isRequired,
		add: PropTypes.func.isRequired,
	};
	
	state = {
		showDescription: isMobile? false : true
	}
	
	/*componentDidMount() {
		this.props.loadPriceFBRIC();
	};*/
	
	option_tgsd = () => {
		let ar_option_tgsd = [];
		ar_option_tgsd.push(
			<option value="0.5" key="0.5">
				15 Ngày
			</option>
		);
		for (let i = 1; i <= 6; i++) {
			ar_option_tgsd.push(
				<option value={i} key={i}>
					{i} Tháng
				</option>
			)
		}
		return ar_option_tgsd;
	}
	
	onChange = (e) => {
		const { taotientrinh } = this.props.Facebookbotreplyinboxcho;
		if (e.target.name === 'cookie') {
			var value = e.target.value;
			var fun_check = this.props.checkCookieFb(e.target.value);
			store.dispatch({
				type: LOADING_FBRIC,
				payload: true
			});
			if (fun_check !== false) {
				fun_check.then(res => {
					if (res.data.status === 200) {
						let data_res = res.data.data;
						var new_taotientrinh = {
							...taotientrinh,
							"idfb": data_res.uid,
							"usernamefb": data_res.name,
							"cookie": value,
							"dtsg": data_res.dtsg,
						};
						this.props.updateFBRIC(new_taotientrinh);
						store.dispatch({
							type: LOADING_FBRIC,
							payload: false
						});
						Swal.fire({
							icon: 'success',
							html: 'Lấy thông tin thành công'
						});
					} else {
						store.dispatch({
							type: LOADING_FBRIC,
							payload: false
						});
						if (taotientrinh.cookie === '') {
							Swal.fire({
								icon: 'error',
								title: 'Lỗi',
								text: 'Cookie die hoặc không hợp lệ #2',
							});
							return false;
						}
					}
				})
			} else {
				store.dispatch({
					type: LOADING_FBRIC,
					payload: true
				});
				Swal.fire({
					icon: 'error',
					title: 'Lỗi',
					text: 'Cookie die hoặc không hợp lệ #1',
				});
				store.dispatch({
					type: LOADING_FBRIC,
					payload: false
				});
				return false;
			}
		} else {
			var new_taotientrinh = {
				...taotientrinh,
				[e.target.name]: e.target.value
			};
			this.props.updateFBRIC(new_taotientrinh);
		}
	}
	
	taoTienTrinhfunc = () => {
		const { taotientrinh } = this.props.Facebookbotreplyinboxcho;
		const id_user = this.props.auth.user.id;
		var new_taotientrinh = {
			...taotientrinh,
			"id_user": id_user
		};
		if (taotientrinh.cookie === '') {
			Swal.fire({
				icon: 'error',
				title: 'Lỗi',
				text: 'Cookie cần chạy không được để trống',
			});
			return false;
		}
		if (taotientrinh.ndr === '') {
			Swal.fire({
				icon: 'error',
				title: 'Lỗi',
				text: 'Nội Dung REPLY cần chạy không được để trống',
			});
			return false;
		}
		Swal.fire({
			title: 'Bạn có chắc?',
			html: "Bạn có chắc chắn muốn mua Giá "+taotientrinh.gtmtt * taotientrinh.tgsd * 30+" Coin / 1 tháng? Khi đã bấm đồng ý đồng nghĩa với việc bạn đã đọc hết mục thông tin dịch vụ!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Đồng ý',
			cancelButtonText: 'Không'
		}).then((result) => {
			if (result.value) {
				this.props.add('facebookbotreplyinboxcho', new_taotientrinh);
			}
		})
	}
	
	onClickShowDescription = (data) => {
		this.setState({
			showDescription: data
		});
	}
	
	render() {
 		const { cookie, tgsd, gtmtt, idfb, usernamefb } = this.props.Facebookbotreplyinboxcho.taotientrinh;
		var price = Math.round((gtmtt * tgsd * 30 + Number.EPSILON) * 100) / 100;
		var info = '';
		if (idfb !== '') {
			info = parse('<img src="https://graph.facebook.com/'+idfb+'/picture?height=100&amp;width=100" class="avatar-user" /> ' + usernamefb + ' (' + idfb + ')');
		}
		
		var listDataServicesInfo = {};
		try {
			if (localStorage.getItem("listDataServicesInfo") != null) {
				listDataServicesInfo = JSON.parse(localStorage.getItem("listDataServicesInfo"));
			} else {
				listDataServicesInfo = this.props.adminpage.listDataServicesInfo;
			}
		} catch(e) {
			listDataServicesInfo = {};
		}
		var html_haydoctranhmattien = '';
		var html_haydoctranhmattien_chinh = '';
		var html_haydoctranhmattien_sv = '';
		var html_replyinboxcho = '';
		if (typeof(listDataServicesInfo.facebook_bot_replyinboxcho_tab) !== 'undefined') {
			html_haydoctranhmattien = parse(listDataServicesInfo.facebook_bot_replyinboxcho_tab.ghichu);
			html_haydoctranhmattien_chinh = parse(listDataServicesInfo.facebook_bot_replyinboxcho_tab.ghichu_chinh);
		}
		if (typeof(listDataServicesInfo.facebook_bot_replyinboxcho) !== 'undefined') {
			html_replyinboxcho = parse(listDataServicesInfo.facebook_bot_replyinboxcho.ghichu_sv);
			if ("1" === "1") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.facebook_bot_replyinboxcho.ghichu);
			}
		}
		
		return (
			<Fragment>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Cookie (*):</label>
					<div className="col-sm-8">
						<input type="text" className="form-control" id="cookie" name="cookie" placeholder="Nhập Vào Mã Cookie" onChange={this.onChange} />
						{info}
					</div>
				</div>
				<div className="form-group row mt-4">
					<label className="col-sm-4 col-form-label" htmlFor="">
						Thời gian sử dụng:
					</label>
					<div className="col-sm-8">
						<div className="card card-orange mt-2">
							<select className="form-control custom-select select-light" id="tgsd" name="tgsd" onChange={this.onChange} value={tgsd} >
								<option value="1">30 ngày</option>
								<option value="2">60 ngày</option>
								<option value="3">90 ngày</option>
								<option value="4">120 ngày</option>
								<option value="5">150 ngày</option>
								<option value="6">180 ngày</option>
								<option value="7">210 ngày</option>
								<option value="8">240 ngày</option>
								<option value="9">270 ngày</option>
								<option value="10">300 ngày</option>
								<option value="11">330 ngày</option>
								<option value="12">360 ngày</option>
							</select>
						</div>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Nội Dung REPLY:<br/>=> HDSD: {"{"}icon1{"}"}{"{"}icon2{"}"}->{"{"}icon10{"}"}</label>
					<div className="col-sm-8">
						<textarea rows="6" placeholder="Chào bạn đây là bot reply cmt tự động khi rảnh tôi sẽ trả lời bạn ngay {icon1} !" className="form-control input-gray" id="ndr" name="ndr" onChange={this.onChange} ></textarea>
					</div>
				</div>
				
				<button type="button" className="btn badge-primary-cus btn-rounded btn-show-description-cus" onClick={(e) => {this.onClickShowDescription(!this.state.showDescription)}}>
					{
						this.state.showDescription?
							<i className="fa fa-angle-up" style={{"marginRight": "5px"}} aria-hidden="true"></i>
						:
							<i className="fa fa-angle-down" style={{"marginRight": "5px"}} aria-hidden="true"></i>
					}
					Thông tin dịch vụ
				</button>
				
				<ServicesDescription allservicesinfo={listDataServicesInfo} server={"1"} tab="facebook_bot_replyinboxcho" giatien={gtmtt} baohanh={0} showDescription={this.state.showDescription} />
				
				<div className="row mt-2" style={{"display": this.state.showDescription? "flex" : "none"}}>
				    <div className="col-md-12 bold" style={{"marginBottom": "10px"}}>
						<div className="card card-select-cus">
							<div className="card-body card-body-select-cus">
								<p className="mb-0 mt-3 text-wrap">
									<ul>
										{html_haydoctranhmattien}
										{html_haydoctranhmattien_chinh}
										{html_haydoctranhmattien_sv}
										<li>Nếu gặp lỗi hãy nhắn tin hỗ trợ phía bên phải góc màn hình hoặc vào mục liên hệ hỗ trợ để được hỗ trợ tốt nhất!</li>
									</ul>
								</p>
								<span className="badge badge-cus position-absolute-cus badge-top-left-cus badge-primary-cus badge-pill-cus"> HÃY ĐỌC ĐỂ TRÁNH MẤT TIỀN KHI SỬ DỤNG! </span>
							</div>
						</div>
				    </div>
				</div>
				<div className="row mt-4">
					<div className="col-12 text-center bold">
						<div className="card card-info">
							<div className="card-body">
								<h5 className="font-bold">Tổng: <span className="bold green"><span>{price}</span> Coin</span></h5>
								<h6 className="mb-0 bold"><span>Giá <span className="green">{price} Coin</span> / 1 tháng</span></h6>
							</div>
						</div>
					</div>
				</div>
				<div className="row my-4">
					<div className="col-12 text-center bold">
						<button type="button" className="btn btn-block btn-rounded btn-hero-primary" onClick={this.taoTienTrinhfunc} >Tạo tiến trình</button>
					</div>
				</div>
			</Fragment>
		)
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	Facebookbotreplyinboxcho: state.Facebookbotreplyinboxcho,
});
const mapDispatchToProps = { addFBRIC, updateFBRIC, CheckCookie, loadPriceFBRIC, add, checkCookieFb }

export default connect(mapStateToProps, mapDispatchToProps)(taotientrinh);