import React from 'react';
import axios from 'axios';
import {
	LOAD_FVVV,
	UPDATE_FVVV_TAB,
	UPDATE_FVVV,
	LOAD_PRICE_FVVV,
} from "../actions/types";
import Swal from 'sweetalert2';

export const loadFVVV = () => (dispatch, getState) => {
	axios.get('/api/facebookvipviewvideo', tokenConfig(getState))
		.then(res => {
			if (res.data.status === 200) {
				dispatch({
					type: LOAD_FVVV,
					payload: res.data.data
				});
			}
		});
};

export const loadPriceFVVV = () => (dispatch, getState) => {
	axios.get('/api/facebookvipviewvideo/price', tokenConfig(getState))
		.then(res => {
			if (res.data.status === 200) {
				dispatch({
					type: LOAD_PRICE_FVVV,
					payload: res.data.data
				});
			}
		});
};

export const changeTab = (body) => (dispatch, getState) => {
	dispatch({
		type: UPDATE_FVVV_TAB,
		payload: body
	});
};

export const updateFVVV = (body) => (dispatch, getState) => {
	dispatch({
		type: UPDATE_FVVV,
		payload: body
	});
};

export const updateFVVVactive = (body) => (dispatch, getState) => {
	axios.post('/api/facebookvipviewvideo/active', body, tokenConfig(getState))
		.then(res => {
			dispatch(loadFVVV());
		});
};

export const deleteFVVV = (body) => (dispatch, getState) => {
	axios.post('/api/facebookvipviewvideo/delete', body, tokenConfig(getState))
		.then(res => {
			dispatch(loadFVVV());
		});
};

export const addFVVV = (body) => (dispatch, getState) => {
	axios.post('/api/facebookvipviewvideo/add', body, tokenConfig(getState))
		.then(res => {
			if (res.data.status === 200) {
				Swal.fire({
					icon: 'success',
					html: 'Tạo tiến trình thành công'
				});
			}
			if (res.data.status === 400) {
				var message = '';
				if (res.data.error) {
					if (res.data.error.object_id) {
						message = res.data.error.object_id[0];
					} else if (res.data.error.quantity) {
						message = res.data.error.quantity[0];
					}
				} else {
					message = res.data.message;
				}
				Swal.fire({
					icon: 'error',
					title: 'Lỗi',
					text: message,
				});
			}
		});
};

export const tokenConfig = getState => {
	// Get token from localhost
	const token = getState().auth.token;
	// Headers
	const config = {
		headers: {
			"Content-type": "application/json"
		}
	};
	
	// If token, add to headers
	if (token) {
		config.headers['ht-token'] = token;
	}
	
	return config;
};