import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Userinformation from "../Userinformation";

class sitecontent extends Component {
	render() {
		return (
			<Fragment>
				<Userinformation />
				<div className="card card-orange mt-3">
					<div className="card-body">
						<p className="font-bold">Chú ý:</p>
						<p>Dịch vụ chăm sóc nick hay còn gọi là dịch vụ nuôi tài khoản. Hệ thống sẽ cung cấp các chức năng chăm sóc tài khỏa như tự động like, thả icon cảm xúc, tự động bình luận icon, tự động đồng ý kết bạn, gửi lời mời kết bạn, tự động chọc bạn bè. </p>
						<p>Auto Like, Comment sẽ tương tác với tất cả các bài viết có trên tường cá nhân của bạn.</p><p>Auto tương tác sẽ không thể lọc theo giới tính, độ tuổi, địa điểm...</p>
						<p>Mỗi tính năng này sẽ được làm cách nhau 1 khoản thời gian ngẫu nhiên để tránh Facebook bắt spam.</p>
					</div>
				</div>
			</Fragment>
		)
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
});
const mapDispatchToProps = { }

export default connect(mapStateToProps, mapDispatchToProps)(sitecontent);