import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Swal from 'sweetalert2';
import copy from 'copy-text-to-clipboard';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

class Copyandfeedfile extends Component {
	state = {
		noidung: "${text1} ${text2}",
		text_placeholder: "${text1} ${text2}",
		keyvaluesplitter: [
			{
				"keyvaluesplitter-0": "text1",
				"keyvaluesplitter-1": "line1\nline2\nline3\nline4",
				"keyvaluesplitter-2": "2",
			},
			{
				"keyvaluesplitter-0": "text2",
				"keyvaluesplitter-1": "linea\nlineb\nlinec\nlined",
				"keyvaluesplitter-2": "3",
			}
		],
		batdautu: 1,
		ketthuc: 2,
		duoifile: "html",
		tenfile: "test-",
	}
	onClick = (e) =>{
		this.setState({
			[e.target.name]: e.target.value
		})
	}
	onChange = (e) =>{
		this.setState({
			[e.target.name]: e.target.value
		})
	}
	onChangeKeyValueSplitter = (e) =>{
		var { keyvaluesplitter } = this.state;
		var new_keyvaluesplitter = keyvaluesplitter[e.target.getAttribute('data-index')];
		new_keyvaluesplitter[e.target.name] = e.target.value;
		keyvaluesplitter[e.target.getAttribute('data-index')] = new_keyvaluesplitter;
		this.setState({
			keyvaluesplitter
		})
	}
	onClickKeyValueSplitter = (e) =>{
		var { keyvaluesplitter } = this.state;
		this.setState({
			keyvaluesplitter: [
				...keyvaluesplitter,
				{
					"keyvaluesplitter-0": "",
					"keyvaluesplitter-1": "",
					"keyvaluesplitter-2": "1",
				}
			]
		})
	}
	onChangeDeleteKeyValueSplitter = (e) =>{
		var { keyvaluesplitter } = this.state;
		delete keyvaluesplitter[e.target.getAttribute('data-index')];
		keyvaluesplitter = keyvaluesplitter.filter(item => item);
		this.setState({
			keyvaluesplitter
		})
	}
	onClickCount = (e, type) =>{
		var { keyvaluesplitter } = this.state;
		var arr = keyvaluesplitter[e.target.getAttribute('data-index')];
		var count = parseInt(arr['keyvaluesplitter-2']);
		if (type === "-") {
			count = count - 1;
		}
		if (type === "+") {
			count = count + 1;
		}
		if (count < 0) {
			count = 0;
		}
		arr['keyvaluesplitter-2'] = count;
		keyvaluesplitter[e.target.getAttribute('data-index')] = arr;
		this.setState({
			keyvaluesplitter
		})
	}
	onKeyPress = (e) => {
		if (!(e.charCode >= 48 && e.charCode <= 57)) {
			e.preventDefault();
		}
	}
	onTrigger = (e) => {
		var { noidung, keyvaluesplitter, batdautu, ketthuc, tenfile, duoifile } = this.state;
		noidung = noidung.trim();
		noidung = noidung
			.split("\n")
			.filter(item => item)
			.join("\n");
			
		var zip = new JSZip();
		for(var i = batdautu; i <= ketthuc; i++){
			var final_noidung = noidung;
			keyvaluesplitter.forEach(function(v2, i2) {
				var replace_text = v2["keyvaluesplitter-1"].split("\n");
				replace_text = replace_text.slice(parseInt(i)*parseInt(v2["keyvaluesplitter-2"])-parseInt(v2["keyvaluesplitter-2"]), parseInt(i)*parseInt(v2["keyvaluesplitter-2"])).join(", ");
				final_noidung = final_noidung.replace("${"+v2["keyvaluesplitter-0"]+"}", replace_text);
			});
			
			var file_name = tenfile + i + '.' + duoifile;
			zip.file("copy/" + file_name, final_noidung);
		}
		zip.generateAsync({type: "blob"}).then(function(content){
			saveAs(content, "copy.zip");
		});
		
	}
	
	render() {
		var { keyvaluesplitter } = this.state;
		var that = this;
		var html_keyvaluesplitter = (
			<Fragment>
				{
					keyvaluesplitter.length > 0 ?
						keyvaluesplitter.map(function(value,index){
							return (
								<Fragment key={index}>
									{
										index != 0 ?
											<label className="col-sm-2 col-form-label edittext-label-right" htmlFor=""></label>
										:
											""
									}
									<div className="col-sm-10 row" style={{"marginBottom": "20px"}}>
										<div className="col-sm-2">
											<div className="input-group">
												<label className="col-form-label edittext-label-right mr-2" htmlFor="">Key</label>
												<input type="text" className="form-control" name="keyvaluesplitter-0" value={value["keyvaluesplitter-0"]} onChange={that.onChangeKeyValueSplitter} data-index={index} />
											</div>
										</div>
										<div className="col-sm-6">
											<div className="input-group">
												<label className="col-form-label edittext-label-right mr-2" htmlFor="">Key</label>
												<textarea rows="3" placeholder="" className="form-control input-gray edittext-textarea" name="keyvaluesplitter-1" onChange={that.onChangeKeyValueSplitter} data-index={index} value={value["keyvaluesplitter-1"]}></textarea>
											</div>
										</div>
										<div className="col-sm-2">
											<div className="input-group">
												<div className="input-group-prepend">
													<button className="input-group-text" onClick={(e) => {that.onClickCount(e, '-')}} data-index={index}>-</button>
												</div>
												<input type="text" className="form-control" name="keyvaluesplitter-2" value={value["keyvaluesplitter-2"]} onChange={that.onChangeKeyValueSplitter} style={{"textAlign": "center"}} onKeyPress={that.onKeyPress} data-index={index} />
												<div className="input-group-append">
													<button className="input-group-text" onClick={(e) => {that.onClickCount(e, '+')}} data-index={index}>+</button>
												</div>
											</div>
										</div>
										<div className="col-sm-2">
											<button type="button" className="btn btn-rounded btn-hero-danger edittext-btn" onClick={that.onChangeDeleteKeyValueSplitter} data-index={index} ><i className="fa fa-fw fa-trash"></i></button>
										</div>
									</div>
								</Fragment>
							);
						})
					: ""
  				}
			</Fragment>
		);
		return (
			<Fragment>
				<style>
					{"\
						button > i {\
							pointer-events: none;\
						}\
					"}
				</style>
				<div className="form-group row">
					<label className="col-sm-2 col-form-label edittext-label-right" htmlFor="">Nội dung</label>
					<div className="col-sm-10">
						<textarea rows="5" placeholder={this.state.text_placeholder} className="form-control input-gray edittext-textarea" name="noidung" onChange={this.onChange} value={this.state.noidung} ></textarea>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-2 col-form-label edittext-label-right" htmlFor=""></label>
					{html_keyvaluesplitter}
				</div>
				<div className="form-group row">
					<label className="col-sm-2 col-form-label edittext-label-right" htmlFor=""></label>
					<div className="col-sm-10">
						<button type="button" className="btn btn-rounded btn-hero-primary edittext-btn" onClick={that.onClickKeyValueSplitter} >Add key</button>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-2 col-form-label edittext-label-right" htmlFor="">Bắt đầu từ</label>
					<div className="col-sm-10">
						<input type="text" className="form-control" name="batdautu" value={this.state.batdautu} onChange={this.onChange} />
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-2 col-form-label edittext-label-right" htmlFor="">Kết thúc</label>
					<div className="col-sm-10">
						<input type="text" className="form-control" name="ketthuc" value={this.state.ketthuc} onChange={this.onChange} />
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-2 col-form-label edittext-label-right" htmlFor="">Đuôi file</label>
					<div className="col-sm-10">
						<input type="text" className="form-control" name="duoifile" value={this.state.duoifile} onChange={this.onChange} />
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-2 col-form-label edittext-label-right" htmlFor="">Tên file</label>
					<div className="col-sm-10">
						<div className="input-group">
							<input type="text" className="form-control" name="tenfile" value={this.state.tenfile} onChange={this.onChange} />
							<div className="input-group-append">
								<span className="input-group-text" style={{"borderLeft": "0px"}}>
									{this.state.batdautu}->{this.state.ketthuc}.{this.state.duoifile}
								</span>
							</div>
						</div>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-2 col-form-label edittext-label-right" htmlFor=""></label>
					<div className="col-sm-10">
						<button type="button" className="btn btn-rounded btn-hero-primary edittext-btn" onClick={this.onTrigger} >Trigger</button>
					</div>
				</div>
			</Fragment>
		);
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
});
const mapDispatchToProps = {  };

export default connect(mapStateToProps, mapDispatchToProps)(Copyandfeedfile);
