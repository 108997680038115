import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { login } from "../../actions/authActions";
import { clearErrors } from "../../actions/errorActions";
import RegisterModal from './Register';
import ServiceModal from './Service';
import ForgotPasswordModal from './ForgotPassword';
import {
  BrowserRouter as Router,
  Link
} from "react-router-dom";
class Login extends Component {
  state = {
	modal: 1,
    msg: "",
    username: "",
    password: "",
    maxacminh: "",
    form_maxacminh: false,
    isLoading: false
  };
  componentDidMount(){
	if (this.props.typeform == 3) {
	  this.setState({ modal: 3 });
	}
  }
  componentDidUpdate(prevProps) {
    const { error } = this.props;
    if (error !== prevProps.error) {
      // Check for login error
      if (error.id === "LOGIN_FAIL") {
        this.setState({ msg: error.msg.msg });
      } else if (error.id === "LOGIN_FAIL_2FA") {
        this.setState({ msg: error.msg.msg, form_maxacminh: true });
      } else {
        this.setState({ msg: null });
      }
    }
  }
	clearAlert = () => {
		// Clear errors
		this.props.clearErrors();
		// this.setState({
			// modal: !this.state.modal
		// });
	}
  changeFormLogRegister = (typeform) => {
	this.props.clearErrors();
	this.setState({
		modal: typeform
	});
	
}
  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };
  onSubmit = e => {
    e.preventDefault();
    const { username, password, maxacminh } = this.state;
    this.props.clearErrors();
    this.props.login({ username, password, maxacminh });
  };
  render() {
    let isLoading = this.props.auth.isLoading;
    var domainSite = window.location.hostname;
	var config_website = {};
	try {
		if (localStorage.getItem("confignavbar") != null) {
			config_website = JSON.parse(localStorage.getItem("confignavbar"));
		} else {
			config_website = this.props.auth.config_website;
		}
	} catch(e) {
		// this.props.getDataBtConfigBgNav();
	}
	const formLogin = (
		<Router>
		<Fragment>
			<main id="main-container" style={{"height": "100%"}}>
				<div className="bg-image" style={{"height": "100%"}}>
					<div className="row no-gutters justify-content-center">
						<div className="col-sm-8 col-md-6 col-xl-4 align-items-center p-2 px-sm-0" style={{ "top": "200px" }}>
							<div className="block block-transparent block-rounded w-100 mb-0 overflow-hidden">
								<div className="justify-content-center row">
									<div className="col-lg-9-custom-login col-lg-9">
										<h2 style={{"textAlign":"center", "color": "#"+config_website.title_color_dndk}}>{config_website.title_dndk}</h2>
										<br/><br/>
										<div className="card">
											<div className="card-header card-header-login pt-4 pb-4 text-center bg-primary bt-bg-navbar">
												<a className="link-fx text-white font-w700 font-size-h3" href="index.html" >
												  <span className="text-white text-uppercase">{domainSite.indexOf('tools1s.com') < 0 ? domainSite.slice(0, domainSite.lastIndexOf('.')) : domainSite.slice(0, domainSite.lastIndexOf('.')).replace('vip.', '') }</span>
												  <span className="text-white text-uppercase">{domainSite.indexOf('.') > -1 && domainSite.indexOf('tools1s.com') < 0 ? domainSite.slice(domainSite.lastIndexOf('.')) : ''}</span>
												</a>
											</div>
											<div className="position-relative card-body card-body-login bg-white" style={{ "padding": "36px" }}>
												<div className="text-center w-75 m-auto">
													<h4 className="text-dark-50 text-center mt-0 font-weight-bold" style={{ "color": "#6c757d", "marginBottom": "10px", "fontSize": "1.125rem" }}>Đăng nhập</h4>
													<p className="text-muted mb-4" style={{ "fontSize": "0.9rem" }}>Vui lòng điền Username và mật khẩu để đăng nhập</p>
												</div>
												{isLoading ? (
													<div className="preloader">
														<div className="spinner-grow spinner-grow-sm text-primary" role="status" style={{ "marginTop": "40%" }}>
														  <span className="sr-only"></span>
														</div>
														<div className="spinner-grow spinner-grow-sm text-danger" role="status" style={{ "marginTop": "40%" }}>
														  <span className="sr-only">.</span>
														</div>
														<div className="spinner-grow spinner-grow-sm text-warning" role="status" style={{ "marginTop": "40%" }}>
														  <span className="sr-only"></span>
														</div>
													</div>
												):('')}
												{this.state.msg ? (
													<div className="alert alert-warning d-flex align-items-center justify-content-between" role="alert" >
													  <div className="flex-fill mr-3">
														<p className="mb-0">{this.state.msg}</p>
													  </div>
													  <div className="flex-00-auto">
														<i className="fa fa-fw fa-exclamation-circle"></i>
													  </div>
													</div>
												  ) : null}
												<form className="js-validation-signup" onSubmit={this.onSubmit} method="POST" >
													<div className="form-group">
														<label htmlFor="username" className="">Tên tài khoản</label>
														<input name="username" placeholder="" required="" id="username" type="text" className="is-untouched is-pristine av-valid form-control" onChange={this.onChange} />
													</div>
													<div className="form-group" style={{"marginBottom": "5px"}}>
														<label htmlFor="password" className="">Mật khẩu</label>
														{/*<a href="/account/forget-password" className="text-muted float-right"><small>Forgot your password?</small></a>*/}
														<input name="password" placeholder="" required="" id="password" type="password" className="is-untouched is-pristine av-valid form-control" onChange={this.onChange} />
													</div>
													{
														this.state.form_maxacminh ?
															<div className="form-group">
																<label htmlFor="maxacminh" className="">Mã xác minh</label>
																<input name="maxacminh" placeholder="" required="" id="maxacminh" type="text" className="is-untouched is-pristine av-valid form-control" onChange={this.onChange} />
															</div>
														: ""
													}
													<div className="form-group" style={{"textAlign": "right"}}>
														<Link to="/" onClick={(e) => {this.changeFormLogRegister(4)}} style={{"color": "#1f2324"}}>
															Quên mật khẩu?
														</Link>
													</div>
													<div className="form-group">
														<button type="submit" className="btn btn-success" style={{ "boxShadow": "0px 2px 6px 0px rgba(10, 207, 151, 0.5)", "color": "#fff", "backgroundColor": "#0acf97", "borderColor": "#0acf97" }}>
														  <span>
															Đăng nhập
														  </span>
														</button>
														<Link to="/" onClick={(e) => {this.changeFormLogRegister(2)}} className="btn btn-success float-right" style={{ "boxShadow": "0px 2px 6px 0px rgba(207, 10, 10, 0.5)", "color": "#fff", "backgroundColor": "#f00", "borderColor": "#f00" }}>
														  <span>
															Đăng ký
														  </span>
														</Link>
													</div>
												</form>
											</div>
										</div>
									</div>
								</div>
								<div className="mt-4 row">
									<div className="col-12 text-center col">
										<p className="text-muted">
											Bạn chưa có tài khoản?
											<Link to="/" className="text-danger ml-1 font-bold" onClick={(e) => {this.changeFormLogRegister(2)}} style={{"fontSize": "20px"}}>Đăng ký</Link>
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</main>
		</Fragment>
		</Router>
	);
	var path_check = window.location.pathname;
	if (window.location.pathname.length > 1) {
		path_check = window.location.pathname.replace(/\/+$/g, '');
	}
	const formRegister = (
		<Fragment>
			<RegisterModal action_login={(e) => {this.changeFormLogRegister(1)}} action_service={(e) => {this.changeFormLogRegister(3)}} />
		</Fragment>
	);
	const formService = (
		<Fragment>
			<ServiceModal action_login={(e) => {this.changeFormLogRegister(1)}} action_register={(e) => {this.changeFormLogRegister(2)}} />
		</Fragment>
	);
	const formForgotPassword = (
		<Fragment>
			<ForgotPasswordModal action_login={(e) => {this.changeFormLogRegister(1)}} action_register={(e) => {this.changeFormLogRegister(2)}} action_service={(e) => {this.changeFormLogRegister(3)}} action_reset={path_check} />
		</Fragment>
	);
	return (
		<Fragment>
			{
				path_check === '/resetpassword' ?
					formForgotPassword
				: this.state.modal == 1 ?
					formLogin
				: this.state.modal == 2 ?
					formRegister
				: this.state.modal == 3 ?
					formService
				: this.state.modal == 4 ?
					formForgotPassword
				: ''
			}
		</Fragment>
	);
  }
}
Login.propTypes = {
  auth: PropTypes.object.isRequired,
  error: PropTypes.object,
  login: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  error: state.error
});
export default connect(mapStateToProps, { login, clearErrors })(Login);
