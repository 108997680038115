import axios from 'axios';
import Swal from 'sweetalert2';
import { loadUser } from "./authActions";

export const creatItemBuffSub = (data) => (dispatch, getState) => {
	let bodysend = {
		dataform: data,
	};
	var text_loaiseeding = '';
	if (data.loaiseeding == 'like' || data.loaiseeding == 'like_sv2' || data.loaiseeding == 'like_sv3' || data.loaiseeding == 'like_sv4' || data.loaiseeding == 'like_sv5') {
		text_loaiseeding = 'like';
	}
	if (data.loaiseeding == 'follow' || data.loaiseeding == 'follow_sv2' || data.loaiseeding == 'follow_sv3' || data.loaiseeding == 'follow_sv4' || data.loaiseeding == 'follow_sv5') {
		text_loaiseeding = 'follow';
	}
	if (data.loaiseeding == 'comment' || data.loaiseeding == 'comment_sv2') {
		text_loaiseeding = 'comment';
	}
	if (data.loaiseeding == 'view' || data.loaiseeding == 'view_sv2' || data.loaiseeding == 'view_sv3') {
		text_loaiseeding = 'view';
	}
	if (data.loaiseeding == 'share' || data.loaiseeding == 'share_sv2' || data.loaiseeding == 'share_sv3') {
		text_loaiseeding = 'share';
	}
	if (data.loaiseeding == 'tiktok_buffmat' || data.loaiseeding == 'tiktok_buffmat_sv2') {
		text_loaiseeding = 'tiktok_buffmat';
	}
	Swal.fire({
	  	title: 'Bạn có chắc?',
	  	text: "Bạn có chắc chắn muốn Order "+data.sltang + " " + text_loaiseeding + " với giá: "+data.giatien+" Coin/1 "+text_loaiseeding+" ? Khi đã bấm đồng ý đồng nghĩa với việc bạn đã đọc hết mục thông tin dịch vụ!",
	  	icon: 'warning',
	  	showCancelButton: true,
	  	confirmButtonColor: '#3085d6',
	  	cancelButtonColor: '#d33',
	  	confirmButtonText: 'Đồng ý',
	  	cancelButtonText: 'Không'
	}).then((result) => {
	  	if (result.value) {

			dispatch({
				type: 'LOADING_BLOCK_BUFF_SUB_TIK_TOK',
				payload: true
			});
			axios.post('/api/tiktok_buff/create', bodysend, tokenConfig(getState))
			.then(res => {
				dispatch(loadUser());
				dispatch({
					type: 'LOADING_BLOCK_BUFF_SUB_TIK_TOK',
					payload: false
				});
				if (res.data.status !== 200) {
					Swal.fire({icon: 'error', title: 'Lỗi', text: res.data.message});
				} else {
					Swal.fire({icon: 'success', title: 'Thành công', text: 'Thêm thành công!'});
				}
			});
	  	}
  	})
}

export const getListBuffSub = (viewadmin = 0, page = "", obj_search = '', type = '') => (dispatch, getState) => {
	dispatch({
		type: 'LOADING_BLOCK_BUFF_SUB_TIK_TOK',
		payload: true
	});
	let param_url = '';
	if (viewadmin === 1) {
		param_url = '&viewall=1';
	}

	if (page !== "") {
		param_url += '&page='+page;
	}

	// var path_check = window.location.pathname;
	// if (window.location.pathname.length > 1) {
		// path_check = window.location.pathname.replace(/\/+$/g, '');
	// }
	// if (obj_search.trim() !== '') {
		// param_url += '&obj_search='+obj_search;
	// } else {
		// if (path_check.indexOf('tiktokbufflike') > -1) {
			// param_url += '&obj_search=like';
		// } else if (path_check.indexOf('tiktokbuffcmt') > -1) {
			// param_url += '&obj_search=comment';
		// } else if (path_check.indexOf('tiktokbuffview') > -1) {
			// param_url += '&obj_search=view';
		// } else if (path_check.indexOf('tiktokbuffshare') > -1) {
			// param_url += '&obj_search=share';
		// } else if (path_check.indexOf('tiktokbuffmat') > -1) {
			// param_url += '&obj_search=tiktok_buffmat';
		// } else if (path_check.indexOf('tiktokbuff') > -1) {
			// param_url += '&obj_search=follow';
		// }
	// }
	param_url += '&type='+type+'&obj_search='+obj_search;
	axios.get('/api/tiktok_buff/list/?type_api=tiktok'+param_url, tokenConfig(getState))
	.then(res => {
		dispatch({
			type: 'LOADING_BLOCK_BUFF_SUB_TIK_TOK',
			payload: false
		});
		if (res.data.status === 200) {
			dispatch({
				type: 'LIST_BUFF_SUB_TIK_TOK_USER',
				payload: res.data.data
			});
		}
	});
}


export const cancelOrderBuffsub = (id, type, id_remove) => (dispatch, getState) => {
	var text_loaiseeding = 'Sau khi hủy bạn sẽ được hoàn lại số lượng chưa tăng sau 1 đến 3 ngày và trừ thêm 5.000 Coin phí để tránh Spam!';
	if (type == 'view' || type == 'view_sv2' || type == 'view_sv3' || type == 'tiktok_buffmat' || type == 'tiktok_buffmat_sv2') {
		text_loaiseeding = 'Sau khi hủy bạn sẽ không được hoàn lại tiền!';
	}
	Swal.fire({
	  	title: 'Bạn có chắc?',
	  	text: text_loaiseeding,
	  	icon: 'warning',
	  	showCancelButton: true,
	  	confirmButtonColor: '#3085d6',
	  	cancelButtonColor: '#d33',
	  	confirmButtonText: 'Đồng ý',
	  	cancelButtonText: 'Không'
	}).then((result) => {
	  	if (result.value) {
	  		let bodysend = {
	  			id: id,
	  			type: type,
	  		};
	  		dispatch({
				type: 'LOADING_BLOCK_BUFF_SUB_TIK_TOK',
				payload: true
			});
	  		axios.post('/api/tiktok_buff/removeorder/', bodysend, tokenConfig(getState))
			.then(res => {
				dispatch(getListBuffSub());
				dispatch({
					type: 'LOADING_BLOCK_BUFF_SUB_TIK_TOK',
					payload: false
				});
				if (res.data.status === 200) {
					Swal.fire(
			      		'Thành công!',
			      		'Hủy đơn thành công',
			      		'success'
			    	)
				} else {
					Swal.fire(
			      		'Lỗi',
		      			res.data.message,
			      		'error'
			    	)
				}
			});
	  	}
	})
}

export const reLoadIdFb = (id) => (dispatch, getState) => {
	let bodysend = {
		id: id
	};
	dispatch({
		type: 'LOADING_BLOCK_BUFF_SUB_TIK_TOK',
		payload: true
	});
	axios.post('/api/tiktok_buff/checkiddie/', bodysend, tokenConfig(getState))
	.then(res => {
		dispatch({
			type: 'LOADING_BLOCK_BUFF_SUB_TIK_TOK',
			payload: false
		});
		dispatch(getListBuffSub());
		if (res.data.status === 200) {
			Swal.fire({icon: 'success', title: 'success', text: 'Check ID thành công!'});
		} else {
			Swal.fire({icon: 'error', title: 'Lỗi', text: res.data.message});
		}
	}).catch(err => {
		dispatch({
			type: 'LOADING_BLOCK_BUFF_SUB_TIK_TOK',
			payload: false
		});
		Swal.fire( 'Lỗi', 'Có lỗi xảy ra!', 'error' );
    });
}

export const saveEditOrderAdmin = (nametb, data, closeM) => (dispatch, getState) => {
	let bodysend = {
		formdata: data,
		type_api: nametb
	};
	dispatch({
		type: 'LOADING_BLOCK_BUFF_SUB_TIK_TOK',
		payload: true
	});
	var url = '/api/facebook_buff/admin_edit_order/';
	if (nametb == 'tiktokbuffmat') {
		url = '/api/tiktok_buff/admin_edit_order/';
	}
	axios.post(url, bodysend, tokenConfig(getState))
	.then(res => {
		dispatch({
			type: 'LOADING_BLOCK_BUFF_SUB_TIK_TOK',
			payload: false
		});
		if (res.data.status === 200) {
			dispatch(getListBuffSub());
			Swal.fire(
	      		'Thành công!',
	      		'Sửa thành công',
	      		'success'
	    	);
	    	closeM();
		} else {
			Swal.fire( 'Lỗi', res.data.message, 'error' )
		}
	}).catch(err => {
		console.log(err);
		Swal.fire( 'Lỗi', 'Có lỗi xảy ra!', 'error' );
    });
}


export const getInfoTiktok = (loaiseeding, data) => (dispatch, getState) => {
	var loaigetinfo = loaiseeding;
	// if (loaiseeding == 'liketosub') {
		// loaigetinfo = 'like';
	// }
	let bodysend = {
		"data": data,
		"loaiseeding": loaigetinfo,
	};
	dispatch({
		type: 'LOADING_BLOCK_BUFF_SUB_TIK_TOK',
		payload: true
	});
	axios.post('/api/tiktok_buff/getinfotiktok', bodysend, tokenConfig(getState))
		.then(res => {
			dispatch({
				type: 'LOADING_BLOCK_BUFF_SUB_TIK_TOK',
				payload: false
			});
			if (res.data.status === 200) {
				Swal.fire(
					'Thành công!',
					'Lấy thông tin thành công!',
					'success'
				);
				if (loaiseeding == 'follow' || loaiseeding == 'follow_sv2' || loaiseeding == 'follow_sv3' || loaiseeding == 'follow_sv4' || loaiseeding == 'follow_sv5' || loaiseeding == 'tiktok_buffmat' || loaiseeding == 'tiktok_buffmat_sv2' || loaiseeding == 'share_sv3') {
					dispatch({
						type: 'UPDATE_INFO_TIKTOK',
						payload: res.data.data
					});
				}
				if (loaiseeding == 'liketosub' || loaiseeding == 'liketosub_viet') {
					dispatch({
						type: 'UPDATE_INFO_TIKTOK',
						payload: res.data.data
					});
				}
				if (loaiseeding == 'comment' || loaiseeding == 'comment_sv2') {
					dispatch({
						type: 'UPDATE_INFO_TIKTOK_CMT',
						payload: res.data.data
					});
				}
				if (loaiseeding == 'like' || loaiseeding == 'like_sv2' || loaiseeding == 'like_sv3' || loaiseeding == 'like_sv4' || loaiseeding == 'like_sv5') {
					dispatch({
						type: 'UPDATE_INFO_TIKTOK_LIKE',
						payload: res.data.data
					});
				}
				if (loaiseeding == 'view' || loaiseeding == 'view_sv2' || loaiseeding == 'view_sv3') {
					dispatch({
						type: 'UPDATE_INFO_TIKTOK_VIEW',
						payload: res.data.data
					});
				}
				if (loaiseeding == 'share' || loaiseeding == 'share_sv2') {
					dispatch({
						type: 'UPDATE_INFO_TIKTOK_SHARE',
						payload: res.data.data
					});
				}
			} else {
				Swal.fire({icon: 'error', title: 'Lỗi', html: res.data.message});
			}
		}).catch(err => {
			Swal.fire( 'Lỗi', 'Có lỗi xảy ra!', 'error' );
		});
}

export const NhatKyHoatDong = (id) => (dispatch, getState) => {
	dispatch({
		type: 'LOADING_BLOCK_BUFF_SUB_TIK_TOK_2',
		payload: true
	});
	
	axios.get('/api/tiktok_buff/nhatkyhoatdong?type_api=tiktok_buffmat&id='+id, tokenConfig(getState))
		.then(res => {
			dispatch({
				type: 'LOADING_BLOCK_BUFF_SUB_TIK_TOK_2',
				payload: false
			});
			if (res.data.status === 200) {
				dispatch({
					type: 'LOAD_NHATKYHOATDONG_TIKTOK_BUFF_MAT',
					payload: res.data.data
				});
			}
			if (res.data.status === 400) {
				dispatch({
					type: 'LOAD_NHATKYHOATDONG_TIKTOK_BUFF_MAT',
					payload: []
				});
			}
		});
};

export const getConfigMoDong = (type = '') => (dispatch, getState) => {
	dispatch({
		type: 'LOADING_BLOCK_BUFF_SUB_TIK_TOK',
		payload: true
	});

	axios.get('/api/tiktok_buff/getconfig?type_api='+type, tokenConfig(getState))
	.then(res => {
		dispatch({
			type: 'LOADING_BLOCK_BUFF_SUB_TIK_TOK',
			payload: false
		});
		if (res.data.status === 200) {
			if (type == 'tiktokbuff_like') {
				dispatch({
					type: 'CONFIG_MO_DONG_TIKTOK_LIKE',
					payload: res.data
				});
			}
			if (type == 'tiktokbuff_cmt') {
				dispatch({
					type: 'CONFIG_MO_DONG_TIKTOK_CMT',
					payload: res.data
				});
			}
			if (type == 'tiktokbuff_follow') {
				dispatch({
					type: 'CONFIG_MO_DONG_TIKTOK_BUFF_FOLLOW',
					payload: res.data
				});
			}
			if (type == 'tiktokbuff_share') {
				dispatch({
					type: 'CONFIG_MO_DONG_TIKTOK_SHARE',
					payload: res.data
				});
			}
			if (type == 'tiktokbuff_view') {
				dispatch({
					type: 'CONFIG_MO_DONG_TIKTOK_VIEW',
					payload: res.data
				});
			}
			if (type == 'tiktokbuff_mat') {
				dispatch({
					type: 'CONFIG_MO_DONG_TIKTOK_BUFF_MAT',
					payload: res.data
				});
			}
		}
	});
}

export const tokenConfig = getState => {
	// Get token from localhost
	const token = getState().auth.token;
	
	// Headers
	const config = {
		headers: {
			"Content-type": "application/json"
		}
	};
	
	// If token, add to headers
	if (token) {
		config.headers['ht-token'] = token;
	}
	
	return config;
};