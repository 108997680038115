import React, { Component, Fragment } from 'react';
import {
  BrowserRouter as Router,
  // Switch,
  // Route,
  Link
} from "react-router-dom";
import {
	// Button,
	// Modal,
	// ModalHeader,
	// ModalBody,
	// Form,
	// FormGroup,
	// Label,
	// Input,
	// NavLink,
	Alert
} from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { register } from '../../actions/authActions';
import { clearErrors } from '../../actions/errorActions';
import VwxYEYx from '../../iconlanding/4/VwxYEYx.png';
import parse from 'html-react-parser';

class RegisterModal extends Component {
	state = {
		// modal: false,
		username: '',
		email: '',
		password: '',
		repeatpassword: '',
		facebook_id: '4',
		phone: '',
		msg: null,
	}
	
	static propTypes =  {
		auth: PropTypes.object.isRequired,
		error: PropTypes.object.isRequired,
		register: PropTypes.func.isRequired,
		clearErrors: PropTypes.func.isRequired
	}
	
	componentDidUpdate(prevProps) {
		const { error } = this.props;
		if (error !== prevProps.error) {
			// Check for register error
			if (error.id === 'REGISTER_FAIL') {
				this.setState({ msg: error.msg.msg });
			} else {
				this.setState({ msg: null });
			}
		}
	}
	
	clearAlert = () => {
		// Clear errors
		this.props.clearErrors();
	}
	
	onChange = e => {
		this.setState({ [e.target.name]: e.target.value });
	};
	
	onSubmit = e => {
		e.preventDefault();
		
		const { username, email, password, repeatpassword, facebook_id, phone } = this.state;
		
		// Create user object
		const newUser = {
			username,
			email,
			password,
			repeatpassword,
			facebook_id,
			phone
		};
		// console.log(newUser);
		// Attempt to register
		this.props.register(newUser);
	};
	
	render() {
		let loading = this.props.auth.loading;
		var domainSite = window.location.hostname;
		var config_website = {};
		if (localStorage.getItem("confignavbar") != null) {
			config_website = JSON.parse(localStorage.getItem("confignavbar"));
		} else {
			config_website = this.props.auth.config_website;
		}
		return (
			<Router>
				<Fragment>
					<div className="landingcus4" style={{"zoom": "122%"}}>
						<header className="header-section no-header">
							<div className="header-bottom">
								<div className="container">
									<nav className="navbar navbar-expand-xl p-0 align-items-start">
										<a className="text-white font-w700 font-size-h3 site-logo site-title" href="/" style={{"padding": "0", "wordBreak": "break-all", "width": "40%"}}>
										  <span className="text-white text-uppercase">{domainSite.indexOf('tools1s.com') < 0 ? domainSite.slice(0, domainSite.lastIndexOf('.')) : domainSite.slice(0, domainSite.lastIndexOf('.')).replace('vip.', '') }</span>
										  <span className="text-white text-uppercase">{domainSite.indexOf('.') > -1 && domainSite.indexOf('tools1s.com') < 0 ? domainSite.slice(domainSite.lastIndexOf('.')) : ''}</span>
										</a>
										{/* <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
											<Link to="/services" onClick={this.props.action_service} className="btn btn-pill sign-up registercus" style={{"color": "white", "padding": "13px 15px"}}>
												Dịch vụ
											</Link>
										</button> */}
										<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
											<Link to="/" onClick={this.props.action_login} className="btn btn-pill sign-up registercus" style={{"color": "white", "padding": "13px 15px"}}>
												Đăng nhập
											</Link>
										</button>
										<div className="collapse navbar-collapse" id="navbarSupportedContent">
											<ul className="navbar-nav main-menu ml-auto guest-menu">
												<li className="active">
													<Link to="/services" onClick={this.props.action_service}>
														Dịch vụ
													</Link>
												</li>
												<li className="active">
													<Link to="/" onClick={this.props.action_login}>
														Đăng nhập
													</Link>
												</li>
												<li>
													<Link to="#">
														Đăng ký
													</Link>
												</li>
												{/* <li><a href="/services">Services</a></li>
												<li><a href="/api">API</a></li>
												<li><a href="/terms">Terms</a></li>
												<li><a href="/faq">FAQ</a></li>
												<li><a href="/blog">Blog</a></li>
												<li><a href="/about">About</a></li>
												<li><a href="/howitworks">How it Works</a></li> */}
											</ul>
											<div className="nav-right">
											</div>
										</div>
									</nav>
								</div>
							</div>
						</header>
						<section className="inner-banner-section">
							<div className="inner-banner-el"><img src={VwxYEYx} alt="image"/></div>
							<div className="container">
								<div className="row">
									<div className="col-lg-12">
										<div className="inner-banner-content text-center">
											<h2 className="page-title">Tạo một tài khoản</h2>
											<ol className="page-list">
												<li><a href="/"><i className="fa fa-home"></i> {domainSite}</a></li>
												<li>Đăng ký</li>
											</ol>
										</div>
									</div>
								</div>
							</div>
						</section>
						<section className="registration-section pt-120 pb-120">
							<div className="container">
								<div className="row">
									<div className="col-lg-12">
										<div className="registration-block text-center">
											<div className="registration-block-inner">
												<h3 className="title">Tạo tài khoản của bạn</h3>
												{loading ? (
													<div className="preloader">
														<div className="spinner-grow spinner-grow-sm text-primary" role="status" style={{ "marginTop": "20%" }}>
														  <span className="sr-only"></span>
														</div>
														<div className="spinner-grow spinner-grow-sm text-danger" role="status" style={{ "marginTop": "20%" }}>
														  <span className="sr-only">.</span>
														</div>
														<div className="spinner-grow spinner-grow-sm text-warning" role="status" style={{ "marginTop": "20%" }}>
														  <span className="sr-only"></span>
														</div>
													</div>
												):('')}
												{this.state.msg ? (
													<div className="alert alert-warning d-flex align-items-center justify-content-between" role="alert" >
													  <div className="flex-fill mr-3">
														<p className="mb-0">{parse(this.state.msg)}</p>
													  </div>
													  <div className="flex-00-auto">
														<i className="fa fa-fw fa-exclamation-circle"></i>
													  </div>
													</div>
												  ) : null}
												<form className="registration-form" onSubmit={this.onSubmit} method="POST" >
													<div className="frm-group">
														<input name="username" placeholder="Nhập tài khoản" required="" id="username" type="text" onChange={this.onChange} />
													</div>
													<div className="frm-group">
														<input name="email" placeholder="Nhập email" required="" id="email" type="email" onChange={this.onChange} />
													</div>
													<div className="frm-group">
														<input name="phone" placeholder="Nhập số điện thoại" required="" id="phone" type="text" onChange={this.onChange} />
													</div>
													<div className="frm-group">
														<input name="password" placeholder="Nhập mật khẩu" required="" id="password" type="password" onChange={this.onChange} />
													</div>
													<div className="frm-group">
														<input name="repeatpassword" placeholder="Nhập lại mật khẩu" required="" id="repeatpassword" type="password" onChange={this.onChange} />
													</div>

													<div className="frm-group">
														<input type="submit" value="Đăng ký"/>
													</div>
													<span className="pull-right pull-right-middle">Bạn đã có tài khoản?
														<Link to="/" onClick={this.props.action_login} style={{"marginLeft": "5px"}}>
															Đăng nhập
														</Link>
													</span>
												</form>
											</div>
										</div>
									</div>
								</div>
							</div>
						</section>
						<footer className="footer-section">
							<div className="footer-top">
								<div className="container">
									<div className="row mb-none-30">
										<div className="col-xl-4 col-lg-6 col-md-8">
											<div className="widget footer-widget mb-30">
												<h4 className="widget-title">về chúng tôi</h4>
												<div className="widget-body">
													<p>Tiếp thị Internet SEO &amp; Chuyên gia Tiếp thị Truyền thông Xã hội. Chúng tôi đã làm việc trong lĩnh vực này từ 2 năm qua. Vì vậy, theo chất lượng cao và dịch vụ làm việc 100% của chúng tôi, chúng tôi sẽ cố gắng hết sức cùng doanh nghiệp của bạn để phát triển lên Cấp độ tiếp theo.</p>
												</div>
											</div>
										</div>
										<div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
											<div className="widget footer-widget mb-30">
											</div>
										</div>
										<div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
											<div className="widget footer-widget mb-30">
											</div>
										</div>
										<div className="col-xl-4 col-lg-12 col-md-8">
											<div className="widget footer-widget mb-30">
												<h4 className="widget-title">Dịch vụ của chúng tôi</h4>
												<div className="widget-body">
													<ul className="menu-list" style={{"listStyle": "circle", "paddingLeft": "15px"}}>
														<li className="text-white">Facebook</li>
														<li className="text-white">Youtube</li>
														<li className="text-white">Instagram</li>
														<li className="text-white">Tiktok </li>
														<li className="text-white">Youtube</li>
														<li className="text-white">Shopee</li>
														<li className="text-white">Và nhiều dịch vụ khác</li>
													</ul>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="footer-bottom">
								<div className="container">
									<div className="row">
										<div className="col-lg-6 col-sm-8 text-sm-left text-center">
											<p>© Copyright {domainSite}. All Rights Reserved.</p>
										</div>
									</div>
								</div>
							</div>
						</footer>
					</div>
				</Fragment>
			</Router>
		);
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	error: state.error
})

export default connect(mapStateToProps, { register, clearErrors })(RegisterModal);