import {
	LOAD_IVL,
	UPDATE_IVL,
	UPDATE_BSR_GETID,
	UPDATE_IVL_TAB,
	UPDATE_BSR_TGT,
	LOAD_PRICE_IVL,
	LIMIT_PAGI_BSR,
	TYPE_IVL,
	LOADING_IVL
} from "../actions/types";

const initialState = {
	"taotientrinh": {
		"lhibv": "",
		"lsct": "1",
		"sllnnct": "40",
		"slllnct": "50",
		"slbvtmn": "2",
		"sncmv": "30",
		"gtmtt": "0",
		"gc": "",
	},
	"changetab": 0,
	"listtientrinh": [],
	"tanggiathem": {
		"id": "",
		"lhibv": "",
		"price_per": 0,
		"price": 0,
		"quantity": 0
	},
	"price": {
		instagrameviplike_viet: {
			min_bh_web: 0,
			prices_web: 0,
		},
		instagrameviplike_tay: {
			min_bh_web: 0,
			prices_web: 0,
		},
	},
	"limit_pagi": 0,
	"type": 0,
	"loading": false,
	instagramviplike_viet_mo_dong: 1,
	instagramviplike_tay_mo_dong: 1,
};

export default function(state = initialState, action) {
	switch (action.type) {
		case LOAD_IVL:
			return {
				...state,
				"listtientrinh": action.payload
			};
		case UPDATE_IVL:
			return {
				...state,
				"taotientrinh": action.payload
			};
		case UPDATE_BSR_GETID:
			return {
				...state,
				"taotientrinh": {
					...state.taotientrinh,
					"lhibv": action.payload
				}
			};
		case UPDATE_IVL_TAB:
			return {
				...state,
				"changetab": action.payload
			};
		case UPDATE_BSR_TGT:
			return {
				...state,
				"tanggiathem": {
					...state.tanggiathem,
					"id": action.payload.id,
					"lhibv": action.payload.lhibv,
					"price_per": action.payload.price_per,
					"price": action.payload.price,
				}
			};
		case LOAD_PRICE_IVL:
			return {
				...state,
				"price": merDataPricePage(action.payload),
			};
		case LIMIT_PAGI_BSR:
			return {
				...state,
				"limit_pagi": action.payload
			};
		case TYPE_IVL:
			return {
				...state,
				"type": action.payload
			};
		case LOADING_IVL:
			return {
				...state,
				"loading": action.payload
			};
		case 'CONFIG_MO_DONG_IVL':
			return {
				...state,
				instagramviplike_viet_mo_dong: action.payload.instagramviplike_viet_mo_dong,
				instagramviplike_tay_mo_dong: action.payload.instagramviplike_tay_mo_dong,
			};
		default:
		  return state;
	}
}

function merDataPricePage(data) {
	let dataNew = {
		...initialState.price
	}
	if (data.length > 0) {
		data.forEach(function(v, i){
			if (v.name_table === 'instagrameviplike_viet') {
				dataNew['instagrameviplike_viet'] = v;
				initialState.taotientrinh.gtmtt = v.prices_web;
			} else if (v.name_table === 'instagrameviplike_tay') {
				dataNew['instagrameviplike_tay'] = v;
			}
		});
	};
	return dataNew;
}
