import {
	LOAD_FVMLSV,
	UPDATE_FVMLSV_TAB,
	UPDATE_FVMLSV,
	LOAD_PRICE_FVMLSV,
	LIMIT_PAGI_FVMLSV,
	TYPE_FVMLSV,
	LOAD_NHATKYHOATDONG_FVMLSV,
	LOADING_FVMLSV,
	UPDATE_FVMLSV_GETID
} from "../actions/types";

const date7dnext = () => {
	var result = new Date();
  	result.setDate(result.getDate() + 7);
  	return result;
}

const initialState = {
	"taotientrinh": {
		"idfb": "",
		"usernamefb": "",
		"lsct": "1",
		"goivip": "50",
		// "tgdtm": "30",
		"time": "7",
		"timelive": "1",
		"ghltmn": "1",
		"ln": "1",
		"inck": "",
		// "gc": "",
		"gtmtt": "0",
	},
	datapricebuffMat: {
		facebookvipmatlivestreamv2: {
			min_bh_web: 0,
			prices_web: 0,
		},
	},
	"changetab": 0,
	"listtientrinh": [],
	"price": [],
	"limit_pagi": 0,
	"type": 0,
	"list_nhatky":"",
	"loading": false,
	facebookvipmatlivestream_new_sv1_mo_dong: 1,
};

export default function(state = initialState, action) {
	switch (action.type) {
		case LOAD_FVMLSV:
			return {
				...state,
				"listtientrinh": action.payload
			};
		case UPDATE_FVMLSV_TAB:
			return {
				...state,
				"changetab": action.payload
			};
		case UPDATE_FVMLSV:
			return {
				...state,
				"taotientrinh": action.payload
			};
		case LOAD_PRICE_FVMLSV:
			return {
				...state,
				datapricebuffMat: merDataPricePage(action.payload),
			};
		case LIMIT_PAGI_FVMLSV:
			return {
				...state,
				"limit_pagi": action.payload
			};
		case TYPE_FVMLSV:
			return {
				...state,
				"type": action.payload
			};
		case LOAD_NHATKYHOATDONG_FVMLSV:
			return {
				...state,
				"list_nhatky": action.payload
			};
		case LOADING_FVMLSV:
			return {
				...state,
				"loading": action.payload
			};
		case UPDATE_FVMLSV_GETID:
			return {
				...state,
				"taotientrinh": {
					...state.taotientrinh,
					"idfb": action.payload.id,
					"usernamefb": action.payload.name
				}
			};
		case 'CONFIG_MO_DONG_VIP_LIVESTREAM_NEW':
			return {
				...state,
				facebookvipmatlivestream_new_sv1_mo_dong: action.payload.facebookvipmatlivestream_new_sv1_mo_dong,
			};
		default:
		  return state;
	}
}
function merDataPricePage(data) {
	const dataOld = initialState.datapricebuffMat;
	let dataNew = {
		...dataOld
	}
	if (data.length > 0) {
		data.forEach(function(v, i){
			if (v.name_table === 'facebookvipmatlivestreamv2') {
				dataNew['facebookvipmatlivestreamv2'] = v;
				initialState.price = v;
				initialState.taotientrinh['gtmtt'] = v.prices_web;
			}
		});
	};
	return dataNew;
}
