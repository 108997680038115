import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Swal from 'sweetalert2';
import copy from 'copy-text-to-clipboard';
import Cookie from './cookie';
import Catchuoi from './catchuoi';
import Ghepdong from './ghepdong';
import Trunglap from './trunglap';
import Joinchu from './joinchu';
import Daotu from './daotu';
import Ghepchuoi from './ghepchuoi';
import Chiacatdong from './chiacatdong';
import Locchuoi from './locchuoi';
import Locchu from './locchu';
import Copyfile from './copyfile';
import Copyandfeedfile from './copyandfeedfile';
import Splitfile from './splitfile';
import Anhhtml from './anhhtml';
import Linkhtml from './linkhtml';
import Loccsv from './loccsv';
import Ghepfile from './ghepfile';
import Json from './json';
import Loctag from './loctag';
import Account from './account';
import Loaitext from './loaitext';

class ToolEditText extends Component {
	state = {
		tab: 1,
	}
	onClickTab = (value) =>{
		this.setState({
			tab: value
		})
	}
	
	render() {
		return (
			<Fragment>
				<div className="row">
				<div className="col-md-12">
				<div className="block block-bordered shadow">
				<div className="block-content tab-content overflow-hidden" style={{"padding": "1.85rem"}}>
				<div className="row edittext-row">
					<div className="edittext-col-lg-1">
						<a className={"edittext-nav-link nav-link " + (this.state.tab == 1 ? "active" : "")} id="cookie" name="tab" onClick={(e) => {this.onClickTab(1)}} >Cookie</a>
						<a className={"edittext-nav-link nav-link " + (this.state.tab == 2 ? "active" : "")} id="catchuoi" name="tab" onClick={(e) => {this.onClickTab(2)}}>Cắt chuỗi</a>
						<a className={"edittext-nav-link nav-link " + (this.state.tab == 3 ? "active" : "")} id="ghepdong" name="tab" onClick={(e) => {this.onClickTab(3)}}>Ghép dòng</a>
						<a className={"edittext-nav-link nav-link " + (this.state.tab == 4 ? "active" : "")} id="trunglap" name="tab" onClick={(e) => {this.onClickTab(4)}}>Trùng lặp</a>
						{/* <a className={"edittext-nav-link nav-link " + (this.state.tab == 5 ? "active" : "")} id="joinchu" name="tab" onClick={(e) => {this.onClickTab(5)}}>Join chữ</a> */}
						<a className={"edittext-nav-link nav-link " + (this.state.tab == 6 ? "active" : "")} id="daotu" name="tab" onClick={(e) => {this.onClickTab(6)}}>Đảo từ</a>
						<a className={"edittext-nav-link nav-link " + (this.state.tab == 7 ? "active" : "")} id="ghepchuoi" name="tab" onClick={(e) => {this.onClickTab(7)}}>Ghép chuỗi</a>
						<a className={"edittext-nav-link nav-link " + (this.state.tab == 8 ? "active" : "")} id="chiacatdong" name="tab" onClick={(e) => {this.onClickTab(8)}}>Chia cắt dòng</a>
						<a className={"edittext-nav-link nav-link " + (this.state.tab == 9 ? "active" : "")} id="locchuoi" name="tab" onClick={(e) => {this.onClickTab(9)}}>Lọc chuỗi</a>
						<a className={"edittext-nav-link nav-link " + (this.state.tab == 10 ? "active" : "")} id="locchu" name="tab" onClick={(e) => {this.onClickTab(10)}}>Lọc chữ</a>
						<a className={"edittext-nav-link nav-link " + (this.state.tab == 11 ? "active" : "")} id="copyfile" name="tab" onClick={(e) => {this.onClickTab(11)}}>Copy file</a>
						<a className={"edittext-nav-link nav-link " + (this.state.tab == 12 ? "active" : "")} id="copyandfeedfile" name="tab" onClick={(e) => {this.onClickTab(12)}}>Copy and Feed file</a>
						<a className={"edittext-nav-link nav-link " + (this.state.tab == 13 ? "active" : "")} id="splitfile" name="tab" onClick={(e) => {this.onClickTab(13)}}>Split file</a>
						<a className={"edittext-nav-link nav-link " + (this.state.tab == 14 ? "active" : "")} id="anhhtml" name="tab" onClick={(e) => {this.onClickTab(14)}}>Ảnh html</a>
						<a className={"edittext-nav-link nav-link " + (this.state.tab == 15 ? "active" : "")} id="linkhtml" name="tab" onClick={(e) => {this.onClickTab(15)}}>Link html</a>
						<a className={"edittext-nav-link nav-link " + (this.state.tab == 16 ? "active" : "")} id="loccsv" name="tab" onClick={(e) => {this.onClickTab(16)}}>Lọc csv</a>
						<a className={"edittext-nav-link nav-link " + (this.state.tab == 17 ? "active" : "")} id="ghepfile" name="tab" onClick={(e) => {this.onClickTab(17)}}>Ghép file</a>
						<a className={"edittext-nav-link nav-link " + (this.state.tab == 18 ? "active" : "")} id="json" name="tab" onClick={(e) => {this.onClickTab(18)}}>JSON</a>
						<a className={"edittext-nav-link nav-link " + (this.state.tab == 19 ? "active" : "")} id="logtag" name="tab" onClick={(e) => {this.onClickTab(19)}}>Lọc tag</a>
						<a className={"edittext-nav-link nav-link " + (this.state.tab == 20 ? "active" : "")} id="account" name="tab" onClick={(e) => {this.onClickTab(20)}}>Account</a>
						<a className={"edittext-nav-link nav-link " + (this.state.tab == 21 ? "active" : "")} id="loaitext" name="tab" onClick={(e) => {this.onClickTab(21)}}>Loại text</a>
					</div>
					<div className="edittext-col-lg-11">
						{
							this.state.tab == 1 ?
								<Cookie />
							: this.state.tab == 2 ?
								<Catchuoi />
							: this.state.tab == 3 ?
								<Ghepdong />
							: this.state.tab == 4 ?
								<Trunglap />
							: this.state.tab == 5 ?
								<Joinchu />
							: this.state.tab == 6 ?
								<Daotu />
							: this.state.tab == 7 ?
								<Ghepchuoi />
							: this.state.tab == 8 ?
								<Chiacatdong />
							: this.state.tab == 9 ?
								<Locchuoi />
							: this.state.tab == 10 ?
								<Locchu />
							: this.state.tab == 11 ?
								<Copyfile />
							: this.state.tab == 12 ?
								<Copyandfeedfile />
							: this.state.tab == 13 ?
								<Splitfile />
							: this.state.tab == 14 ?
								<Anhhtml />
							: this.state.tab == 15 ?
								<Linkhtml />
							: this.state.tab == 16 ?
								<Loccsv />
							: this.state.tab == 17 ?
								<Ghepfile />
							: this.state.tab == 18 ?
								<Json />
							: this.state.tab == 19 ?
								<Loctag />
							: this.state.tab == 20 ?
								<Account />
							: this.state.tab == 21 ?
								<Loaitext />
							: ""
						}
					</div>
				</div>
				</div>
				</div>
				</div>
				</div>
			</Fragment>
		);
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
});
const mapDispatchToProps = {  };

export default connect(mapStateToProps, mapDispatchToProps)(ToolEditText);
