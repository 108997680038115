import {
	LOAD_MBC,
	LOADING_MBC,
	UPDATE_MBC,
	LIST_MBC,
} from "../actions/types";

const initialState = {
	"muaclone": {
		"id_llc": "0",
		"llc": "--",
		"price": "0",
		"slm": "0",
	},
	"listloaiclone": [],
	"listclone": [],
	"loading": false,
};

export default function(state = initialState, action) {
	switch (action.type) {
		case LOAD_MBC:
			return {
				...state,
				"listloaiclone": action.payload
			};
		case LOADING_MBC:
			return {
				...state,
				"loading": action.payload
			};
		case UPDATE_MBC:
			return {
				...state,
				"muaclone": action.payload
			};
		case LIST_MBC:
			return {
				...state,
				"listclone": action.payload
			};
		default:
		  return state;
	}
}
