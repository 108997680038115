function date7dnext() {
	var result = new Date();
  	result.setDate(result.getDate() + 7);
  	return result;
}

function addDays(date, days) {
  	var result = new Date(date);
  	result.setDate(result.getDate() + days);
  	return result;
}

const initialState = {
	loadingblock: false,
	listBuffSub: [],
	datapricebuffsub: {
		sv1: {
			min_bh_web: 0,
			prices_web: 0,
		},
		sv2: {
			min_bh_web: 0,
			prices_web: 0,
		},
	},
	formData: {
		locnangcao: 0,
		locnangcao_gt: 0,
		locnangcao_dotuoi_start: 0,
		locnangcao_dotuoi_end: 0,
		locnangcao_banbe_start: 0,
		locnangcao_banbe_end: 0,
		profile_user: '',
		loaiseeding: 1,
		baohanh: 0,
		sltang: 100,
		giatien: 0,
		ghichu: '',
		startDatebh: new Date(),
		EndDatebh: date7dnext(),
	},
	facebookbuffsubfanpage_sv1_mo_dong: 1,
	facebookbuffsubfanpage_sv2_mo_dong: 1,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case 'LIST_BUFF_SUB_FANPAGE_USER':
    	return {
			...state,
			listBuffSub: action.payload
		};
    case 'LOADING_BLOCK_BUFF_SUB_FANPAGE':
		return {
			...state,
			loadingblock: action.payload
		};
	case 'DATA_PRICE_IS_FUNCTION_BUFF_SUB_FANPAGE':
		return {
			...state,
			datapricebuffsub: mergePrice(action.payload)
		}
	case 'CHANGE_DATA_FORM_FB_SUB_FANPAGE':
		return {
			...state,
			formData: action.payload
		}
	case 'CHANGE_DATA_FORM_FB_SUB_FANPAGE_PROFILE':
		return {
			...state,
			formData: {
				...state.formData,
				profile_user: action.payload
			}
		}
	case 'CONFIG_MO_DONG_SUB_FANPAGE':
		return {
			...state,
			facebookbuffsubfanpage_sv1_mo_dong: action.payload.facebookbuffsubfanpage_sv1_mo_dong,
			facebookbuffsubfanpage_sv2_mo_dong: action.payload.facebookbuffsubfanpage_sv2_mo_dong,
		};
    default:
      return state;
  }
}

function mergePrice (arr_price) {
	const dataOld = initialState.datapricebuffsub;
	let dataNew = {
		...dataOld
	}
	if (arr_price.length > 0) {
		arr_price.forEach(function(v, i){
			if (v.name_table === 'buffsubfanpage_sv1') {
				initialState.formData.giatien = v.prices_web;
				dataNew.sv1 = v;
			} else if (v.name_table === 'buffsubfanpage_sv2') {
				dataNew.sv2 = v;
			}
		});
		return dataNew;
	}
}
