import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import store from '../../../store';
import { getTokenSiteBase, saveTokenSiteBase, saveConfigColorNav, getAPITrumThe, saveAPITrumThe, saveAPITheSieuRe } from '../../../actions/adminPageAction';

class tag_tokensitebase extends Component {
	state = {
		token_site: '',
		api_key_trumthe247: '',
		api_secret_trumthe247: '',
		chietkhau_thap_trumthe247: '34',
		chietkhau_cao_trumthe247: '31',
		servernapthe: '1',
		partner_id_thesieure: '',
		partner_key_thesieure: '',
		vi_thesieure: '',
		chietkhau_thap_thesieure: '34',
		chietkhau_cao_thesieure: '31',
        support_id_fanpage: this.props.auth.config_website.support_id_fanpage,
        bg_navbar_site: this.props.auth.config_website.bg_navbar,
        cf_title: this.props.auth.config_website.cf_title,
        cf_icon: this.props.auth.config_website.cf_icon,
        cf_description: this.props.auth.config_website.cf_description,
        cf_og_title: this.props.auth.config_website.cf_og_title,
        cf_og_site_name: this.props.auth.config_website.cf_og_site_name,
        cf_og_description: this.props.auth.config_website.cf_og_description,
        cf_og_type: this.props.auth.config_website.cf_og_type,
        cf_og_url: this.props.auth.config_website.cf_og_url,
        cf_og_keywords: this.props.auth.config_website.cf_og_keywords,
        cf_og_image: this.props.auth.config_website.cf_og_image,
        title_dndk: this.props.auth.config_website.title_dndk,
        title_color_dndk: this.props.auth.config_website.title_color_dndk,
        landingpage: this.props.auth.config_website.landingpage,
        displaytabmuabanfanpagegroup: this.props.auth.config_website.displaytabmuabanfanpagegroup,
        defaultlanguage: this.props.auth.config_website.defaultlanguage,
        naptien_mota: this.props.auth.config_website.naptien_mota,
        naptien_chuy: this.props.auth.config_website.naptien_chuy,
        usertype_default: this.props.auth.config_website.usertype_default,
		"copySuccessDomain": false,
		"copySuccessUrl": false,
		"copySuccessUrlV2": false,
	}
	
	copyToClipboardDomain = (final_data) => {
		var dummy = document.createElement("textarea");
		document.body.appendChild(dummy);
		dummy.value = final_data;
		dummy.select();
		document.execCommand("copy");
		document.body.removeChild(dummy);
		this.setState({ "copySuccessDomain": true });
	};
	
	copyToClipboardUrl = (final_data) => {
		var dummy = document.createElement("textarea");
		document.body.appendChild(dummy);
		dummy.value = final_data;
		dummy.select();
		document.execCommand("copy");
		document.body.removeChild(dummy);
		this.setState({ "copySuccessUrl": true });
	};
	
	copyToClipboardUrlV2 = (final_data) => {
		var dummy = document.createElement("textarea");
		document.body.appendChild(dummy);
		dummy.value = final_data;
		dummy.select();
		document.execCommand("copy");
		document.body.removeChild(dummy);
		this.setState({ "copySuccessUrlV2": true });
	};

	async componentDidMount() {
        await this.props.getTokenSiteBase();
        await this.props.getAPITrumThe();
		if (this.props.adminpage.chietkhau_thap_trumthe247 != '') {
			this.setState({
				chietkhau_thap_trumthe247: this.props.adminpage.chietkhau_thap_trumthe247,
			})
		}
		if (this.props.adminpage.chietkhau_cao_trumthe247 != '') {
			this.setState({
				chietkhau_cao_trumthe247: this.props.adminpage.chietkhau_cao_trumthe247,
			})
		}
		if (this.props.adminpage.servernapthe != '') {
			this.setState({
				servernapthe: this.props.adminpage.servernapthe,
			})
		}
		if (this.props.adminpage.chietkhau_thap_thesieure != '') {
			this.setState({
				chietkhau_thap_thesieure: this.props.adminpage.chietkhau_thap_thesieure,
			})
		}
		if (this.props.adminpage.chietkhau_cao_thesieure != '') {
			this.setState({
				chietkhau_cao_thesieure: this.props.adminpage.chietkhau_cao_thesieure,
			})
		}
        this.setState({
        	token_site: this.props.adminpage.tokensitebase,
        	api_key_trumthe247: this.props.adminpage.api_key_trumthe247,
        	api_secret_trumthe247: this.props.adminpage.api_secret_trumthe247,
        	partner_id_thesieure: this.props.adminpage.partner_id_thesieure,
        	partner_key_thesieure: this.props.adminpage.partner_key_thesieure,
        	vi_thesieure: this.props.adminpage.vi_thesieure,
        })
    }

    ChangeToken = (e) => {
    	this.setState({
    		[e.target.name]: e.target.value
    	})
    }
	
    onchangeData = (e) => {
		if (e.target.name === 'bg_navbar_site' || e.target.name === 'title_color_dndk') {
			this.setState({
				...this.state,
				[e.target.name]: e.target.value.replace("#", ""),
			});
		} else {
			this.setState({
				...this.state,
				[e.target.name]: e.target.value,
			});
		}
    }
	
	onKeyPress = (e) => {
		if (!(e.charCode >= 48 && e.charCode <= 57)) {
			e.preventDefault();
		}
	}

	render() {
		const {listdatausertype} = this.props.adminpage;
		
		var that = this;
		var default_type_newuser = (
            <Fragment>
				<div className="row">
					<div className="col-md-4">
						<div className="form-group">
							<label>Loại tài khoản mặc định khi tài khoản mới đăng ký:</label>
						</div>
					</div>
					<div className="col-md-8">
						{
							listdatausertype.map(function (dataList, index) {
								if (dataList.id != 1) {
									return (
										<div className="custom-control custom-radio custom-control-inline" key={index} >
											<input onChange={(e) => {that.onchangeData(e)}} value={dataList.id} type="radio" className="custom-control-input" id={"usertype_default"+index} name="usertype_default" checked={that.state.usertype_default.toString() === dataList.id.toString()} />
											<label className="custom-control-label" htmlFor={"usertype_default"+index} >{dataList.name}</label>
										</div>
									);
								}
							})
						}
					</div>
				</div>
            </Fragment>
        )
		
		return (
			<Fragment>
				<div className="block block-rounded block-bordered">
					<div className="block-header block-header-default">
						<h3 className="block-title">Token site</h3>
					</div>
					<div className="block-content">
						<div className="row">
							<div className="col-md-12">
								<div className="form-group">
									<input type="text" name="token_site" value={this.state.token_site} onChange={(e) => {this.ChangeToken(e)}} className="form-control" />
								</div>
							</div>
						</div>
						<button type="button" onClick={(e) => {this.props.saveTokenSiteBase(this.state.token_site)}} className="btn btn-success mt-3 mb-3">Lưu TOKEN</button>
					</div>
				</div>
				<div className="block block-rounded block-bordered">
					<div className="block-header block-header-default">
						<h3 className="block-title">API nạp thẻ điện thoại <span className="text-danger font-bold">(cài đặt xong thì mục nạp thẻ mới hoạt động)</span></h3>
					</div>
					<div className="block-content">
						<div className="row">
							<div className="col-md-4">
								<div className="form-group">
									<label>Server</label>
								</div>
							</div>
							<div className="col-md-6">
								<div className="form-group">
									<div className="custom-control custom-radio custom-control-inline" >
										<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="servernapthe_1" name="servernapthe" checked={this.state.servernapthe == "1"} />
										<label className="custom-control-label" htmlFor="servernapthe_1" >trumthe247.com</label>
									</div>
									<div className="custom-control custom-radio custom-control-inline" >
										<input onChange={(e) => {this.onchangeData(e)}} value="2" type="radio" className="custom-control-input" id="servernapthe_2" name="servernapthe" checked={this.state.servernapthe == "2"} />
										<label className="custom-control-label" htmlFor="servernapthe_2" >thesieure.com</label>
									</div>
								</div>
							</div>
						</div>
						{
							this.state.servernapthe == "1" ?
								<Fragment>
									<div className="row">
										<div className="col-md-4">
											<div className="form-group">
												<label>API Key</label>
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<input type="text" name="api_key_trumthe247" value={this.state.api_key_trumthe247} onChange={(e) => {this.ChangeToken(e)}} className="form-control" />
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-md-4">
											<div className="form-group">
												<label>API Secret</label>
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<input type="text" name="api_secret_trumthe247" value={this.state.api_secret_trumthe247} onChange={(e) => {this.ChangeToken(e)}} className="form-control" />
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-md-4">
											<div className="form-group">
												<label>Chiết khấu (%) thẻ mệnh giá thấp: 10k, 20k, 30k</label>
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<input type="number" className="form-control" id="chietkhau_thap_trumthe247" name="chietkhau_thap_trumthe247" placeholder="34" onChange={(e) => {this.ChangeToken(e)}} value={this.state.chietkhau_thap_trumthe247} onKeyPress={this.onKeyPress} />
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-md-4">
											<div className="form-group">
												<label>Chiết khấu (%) thẻ mệnh giá cao: 50k trở lên</label>
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<input type="number" className="form-control" id="chietkhau_cao_trumthe247" name="chietkhau_cao_trumthe247" placeholder="31" onChange={(e) => {this.ChangeToken(e)}} value={this.state.chietkhau_cao_trumthe247} onKeyPress={this.onKeyPress} />
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-md-4">
											<div className="form-group">
												<label>Tên miền</label>
											</div>
										</div>
										<div className="col-md-4">
											<div className="form-group">
												<input type="text" name="huongdan_1" value={window.location.hostname} className="form-control" disabled />
											</div>
										</div>
										<div className="col-md-2">
											<div className="form-group">
												<Fragment>
													{
														this.state.copySuccessDomain === true ?
															<Fragment>
																 <button className="btn btn-success" style={{"margin-left":"3px"}} onClick={()=>{this.copyToClipboardDomain(window.location.hostname)}}>
																	Đã copy <i className="fa fa-fw fa-check"></i>
																</button>
															</Fragment>
														:
															<Fragment>
																 <button className="btn btn-warning" style={{"margin-left":"3px"}} onClick={()=>{this.copyToClipboardDomain(window.location.hostname)}}>
																	Copy
																</button>
															</Fragment>
													}
												</Fragment>
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-md-4">
											<div className="form-group">
												<label>URL callback</label>
											</div>
										</div>
										<div className="col-md-4">
											<div className="form-group">
												<input type="text" name="huongdan_1" value={window.location.protocol + '//' + window.location.hostname + '/api/napthe/callbacknaptienthe'} className="form-control" disabled />
											</div>
										</div>
										<div className="col-md-2">
											<div className="form-group">
												<Fragment>
													{
														this.state.copySuccessUrl === true ?
															<Fragment>
																 <button className="btn btn-success" style={{"margin-left":"3px"}} onClick={()=>{this.copyToClipboardUrl(window.location.protocol + '//' + window.location.hostname + '/api/napthe/callbacknaptienthe')}}>
																	Đã copy <i className="fa fa-fw fa-check"></i>
																</button>
															</Fragment>
														:
															<Fragment>
																 <button className="btn btn-warning" style={{"margin-left":"3px"}} onClick={()=>{this.copyToClipboardUrl(window.location.protocol + '//' + window.location.hostname + '/api/napthe/callbacknaptienthe')}}>
																	Copy
																</button>
															</Fragment>
													}
												</Fragment>
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-md-4">
											<label className="mt-2"></label>
										</div>
										<div className="col-md-6 bold">
											<div className="card card-orange">
												<div className="card-body py-2">
													<h3 className="mb-0 text-danger">Hướng dẫn cài đặt trumthe247.com: <a href="https://i.imgur.com/ym9Usgx.png" target="_blank">Tại đây</a></h3>
												</div>
											</div>
										</div>
									</div>
									<button type="button" onClick={(e) => {this.props.saveAPITrumThe(this.state.servernapthe, this.state.api_key_trumthe247, this.state.api_secret_trumthe247, this.state.chietkhau_thap_trumthe247, this.state.chietkhau_cao_trumthe247)}} className="btn btn-success mt-3 mb-3">Lưu API</button>
								</Fragment>
							: ""
						}
						{
							this.state.servernapthe == "2" ?
								<Fragment>
									<div className="row">
										<div className="col-md-4">
											<div className="form-group">
												<label>Partner ID</label>
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<input type="text" name="partner_id_thesieure" value={this.state.partner_id_thesieure} onChange={(e) => {this.ChangeToken(e)}} className="form-control" />
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-md-4">
											<div className="form-group">
												<label>Partner Key</label>
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<input type="text" name="partner_key_thesieure" value={this.state.partner_key_thesieure} onChange={(e) => {this.ChangeToken(e)}} className="form-control" />
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-md-4">
											<div className="form-group">
												<label>Ví</label>
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<input type="text" name="vi_thesieure" value={this.state.vi_thesieure} onChange={(e) => {this.ChangeToken(e)}} className="form-control" />
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-md-4">
											<div className="form-group">
												<label>Chiết khấu (%) thẻ mệnh giá thấp: 10k, 20k, 30k</label>
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<input type="number" className="form-control" id="chietkhau_thap_thesieure" name="chietkhau_thap_thesieure" placeholder="34" onChange={(e) => {this.ChangeToken(e)}} value={this.state.chietkhau_thap_thesieure} onKeyPress={this.onKeyPress} />
												<h6 className="text-danger" style={{"marginTop": "5px"}}>Lưu ý: riêng mạng Mobifone sẽ cộng thêm 10% chiết khấu so với chiết khấu mặc định</h6>
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-md-4">
											<div className="form-group">
												<label>Chiết khấu (%) thẻ mệnh giá cao: 50k trở lên</label>
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<input type="number" className="form-control" id="chietkhau_cao_thesieure" name="chietkhau_cao_thesieure" placeholder="31" onChange={(e) => {this.ChangeToken(e)}} value={this.state.chietkhau_cao_thesieure} onKeyPress={this.onKeyPress} />
												<h6 className="text-danger" style={{"marginTop": "5px"}}>Lưu ý: riêng mạng Mobifone sẽ cộng thêm 10% chiết khấu so với chiết khấu mặc định</h6>
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-md-4">
											<div className="form-group">
												<label>URL callback</label>
											</div>
										</div>
										<div className="col-md-4">
											<div className="form-group">
												<input type="text" name="huongdan_1" value={window.location.protocol + '//' + window.location.hostname + '/api/napthe/callbacknaptienthethesieure'} className="form-control" disabled />
											</div>
										</div>
										<div className="col-md-2">
											<div className="form-group">
												<Fragment>
													{
														this.state.copySuccessUrlV2 === true ?
															<Fragment>
																 <button className="btn btn-success" style={{"margin-left":"3px"}} onClick={()=>{this.copyToClipboardUrlV2(window.location.protocol + '//' + window.location.hostname + '/api/napthe/callbacknaptienthethesieure')}}>
																	Đã copy <i className="fa fa-fw fa-check"></i>
																</button>
															</Fragment>
														:
															<Fragment>
																 <button className="btn btn-warning" style={{"margin-left":"3px"}} onClick={()=>{this.copyToClipboardUrlV2(window.location.protocol + '//' + window.location.hostname + '/api/napthe/callbacknaptienthethesieure')}}>
																	Copy
																</button>
															</Fragment>
													}
												</Fragment>
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-md-4">
											<label className="mt-2"></label>
										</div>
										<div className="col-md-6 bold">
											<div className="card card-orange">
												<div className="card-body py-2">
													<h3 className="mb-0 text-danger">Hướng dẫn cài đặt thesieure.com: <a href="https://i.imgur.com/zHUUKIq.png" target="_blank">Tại đây</a></h3>
												</div>
											</div>
										</div>
									</div>
									<button type="button" onClick={(e) => {this.props.saveAPITheSieuRe(this.state.servernapthe, this.state.partner_id_thesieure, this.state.partner_key_thesieure, this.state.vi_thesieure, this.state.chietkhau_thap_thesieure, this.state.chietkhau_cao_thesieure)}} className="btn btn-success mt-3 mb-3">Lưu API</button>
								</Fragment>
							: ""
						}
					</div>
				</div>
				<div className="block block-rounded block-bordered">
					<div className="block-header block-header-default">
						<h3 className="block-title">Cấu hình website</h3>
					</div>
					<div className="block-content">
						<div className="row">
							<div className="col-md-4">
								<div className="form-group">
									<label>ID Fanpage hỗ trợ</label>
								</div>
							</div>
							<div className="col-md-6">
								<div className="form-group">
									<input name="support_id_fanpage" onChange={(e) => {this.onchangeData(e)}} value={this.state.support_id_fanpage} className="form-control" type="text" />
								</div>
							</div>
							<div className="col-md-2">
								<div className="form-group">
									<label className="font-bold">Hướng dẫn cài đặt: </label>
									<a href="https://i.imgur.com/wdUCRFR.png" target="_blank" className="font-bold"> Tại đây</a>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-md-4">
								<div className="form-group">
									<label>Màu sắc menu navbar</label>
								</div>
							</div>
							<div className="col-md-8">
								<div className="form-group">
									<input name="bg_navbar_site" onChange={(e) => {this.onchangeData(e)}} value={'#'+this.state.bg_navbar_site} className="form-control" type="color" />
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-md-4">
								<div className="form-group">
									<label>Title</label>
								</div>
							</div>
							<div className="col-md-8">
								<div className="form-group">
									<input name="cf_title" onChange={(e) => {this.onchangeData(e)}} value={this.state.cf_title} className="form-control" type="text" />
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-md-4">
								<div className="form-group">
									<label>Link Icon</label>
								</div>
							</div>
							<div className="col-md-8">
								<div className="form-group">
									<input name="cf_icon" onChange={(e) => {this.onchangeData(e)}} value={this.state.cf_icon} className="form-control" type="text" />
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-md-4">
								<div className="form-group">
									<label>Description</label>
								</div>
							</div>
							<div className="col-md-8">
								<div className="form-group">
									<input name="cf_description" onChange={(e) => {this.onchangeData(e)}} value={this.state.cf_description} className="form-control" type="text" />
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-md-4">
								<div className="form-group">
									<label>Meta og:title</label>
								</div>
							</div>
							<div className="col-md-8">
								<div className="form-group">
									<input name="cf_og_title" onChange={(e) => {this.onchangeData(e)}} value={this.state.cf_og_title} className="form-control" type="text" />
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-md-4">
								<div className="form-group">
									<label>Meta og:site_name</label>
								</div>
							</div>
							<div className="col-md-8">
								<div className="form-group">
									<input name="cf_og_site_name" onChange={(e) => {this.onchangeData(e)}} value={this.state.cf_og_site_name} className="form-control" type="text" />
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-md-4">
								<div className="form-group">
									<label>Meta og:description</label>
								</div>
							</div>
							<div className="col-md-8">
								<div className="form-group">
									<input name="cf_og_description" onChange={(e) => {this.onchangeData(e)}} value={this.state.cf_og_description} className="form-control" type="text" />
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-md-4">
								<div className="form-group">
									<label>Meta og:type</label>
								</div>
							</div>
							<div className="col-md-8">
								<div className="form-group">
									<input name="cf_og_type" onChange={(e) => {this.onchangeData(e)}} value={this.state.cf_og_type} className="form-control" type="text" />
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-md-4">
								<div className="form-group">
									<label>Meta og:url</label>
								</div>
							</div>
							<div className="col-md-8">
								<div className="form-group">
									<input name="cf_og_url" onChange={(e) => {this.onchangeData(e)}} value={this.state.cf_og_url} className="form-control" type="text" />
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-md-4">
								<div className="form-group">
									<label>Meta og:keywords</label>
								</div>
							</div>
							<div className="col-md-8">
								<div className="form-group">
									<input name="cf_og_keywords" onChange={(e) => {this.onchangeData(e)}} value={this.state.cf_og_keywords} className="form-control" type="text" />
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-md-4">
								<div className="form-group">
									<label>Meta og:image</label>
								</div>
							</div>
							<div className="col-md-8">
								<div className="form-group">
									<input name="cf_og_image" onChange={(e) => {this.onchangeData(e)}} value={this.state.cf_og_image} className="form-control" type="text" />
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-md-4">
								<div className="form-group">
									<label>Tiêu đề Đăng nhập/Đăng ký</label>
								</div>
							</div>
							<div className="col-md-8">
								<div className="form-group">
									<input name="title_dndk" onChange={(e) => {this.onchangeData(e)}} value={this.state.title_dndk} className="form-control" type="text" placeholder="Chào mừng bạn đến với hệ thống tăng like sub Facebook, Tiktok, Youtube, Instagram..." />
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-md-4">
								<div className="form-group">
									<label>Màu sắc tiêu đề Đăng nhập/Đăng ký</label>
								</div>
							</div>
							<div className="col-md-8">
								<div className="form-group">
									<input name="title_color_dndk" onChange={(e) => {this.onchangeData(e)}} value={'#'+this.state.title_color_dndk} className="form-control" type="color" />
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-md-4">
								<div className="form-group">
									<label>Landing page:</label>
								</div>
							</div>
							<div className="col-md-8">
								<div className="custom-control custom-radio" style={{"display": "inline-block", "marginRight": "15px"}}>
									<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="landingpage_1" name="landingpage" checked={this.state.landingpage.toString() === "1"} />
									<label className="custom-control-label" htmlFor="landingpage_1" > Mặc định</label>
								</div>
								<div className="custom-control custom-radio" style={{"display": "inline-block", "marginRight": "15px"}}>
									<input onChange={(e) => {this.onchangeData(e)}} value="2" type="radio" className="custom-control-input" id="landingpage_2" name="landingpage" checked={this.state.landingpage.toString() === "2"} />
									<label className="custom-control-label" htmlFor="landingpage_2" > Landing 1</label>
								</div>
								<div className="custom-control custom-radio" style={{"display": "inline-block", "marginRight": "15px"}}>
									<input onChange={(e) => {this.onchangeData(e)}} value="3" type="radio" className="custom-control-input" id="landingpage_3" name="landingpage" checked={this.state.landingpage.toString() === "3"} />
									<label className="custom-control-label" htmlFor="landingpage_3" > Landing 2</label>
								</div>
								<div className="custom-control custom-radio" style={{"display": "inline-block", "marginRight": "15px"}}>
									<input onChange={(e) => {this.onchangeData(e)}} value="4" type="radio" className="custom-control-input" id="landingpage_4" name="landingpage" checked={this.state.landingpage.toString() === "4"} />
									<label className="custom-control-label" htmlFor="landingpage_4" > Landing 3</label>
								</div>
								<div className="custom-control custom-radio" style={{"display": "inline-block", "marginRight": "15px"}}>
									<input onChange={(e) => {this.onchangeData(e)}} value="5" type="radio" className="custom-control-input" id="landingpage_5" name="landingpage" checked={this.state.landingpage.toString() === "5"} />
									<label className="custom-control-label" htmlFor="landingpage_5" > Landing 4</label>
								</div>
								{
									window.location.hostname === 'hacklikepro.net' ?
										<div className="custom-control custom-radio" style={{"display": "inline-block", "marginRight": "15px"}}>
											<input onChange={(e) => {this.onchangeData(e)}} value="6" type="radio" className="custom-control-input" id="landingpage_6" name="landingpage" checked={this.state.landingpage.toString() === "6"} />
											<label className="custom-control-label" htmlFor="landingpage_6" > Landing 5</label>
										</div>
									: ''
								}
								{
									window.location.hostname === 'fbtouse.com' ?
										<div className="custom-control custom-radio" style={{"display": "inline-block", "marginRight": "15px"}}>
											<input onChange={(e) => {this.onchangeData(e)}} value="7" type="radio" className="custom-control-input" id="landingpage_7" name="landingpage" checked={this.state.landingpage.toString() === "7"} />
											<label className="custom-control-label" htmlFor="landingpage_7" > Landing 6 (Thailand)</label>
										</div>
									: ''
								}
								{
									window.location.hostname === 'dv.banvia.net' ?
										<div className="custom-control custom-radio" style={{"display": "inline-block", "marginRight": "15px"}}>
											<input onChange={(e) => {this.onchangeData(e)}} value="8" type="radio" className="custom-control-input" id="landingpage_8" name="landingpage" checked={this.state.landingpage.toString() === "8"} />
											<label className="custom-control-label" htmlFor="landingpage_8" > Landing 7</label>
										</div>
									: ''
								}
								{
									window.location.hostname === 'thegioifanpage.com' ?
										<div className="custom-control custom-radio" style={{"display": "inline-block", "marginRight": "15px"}}>
											<input onChange={(e) => {this.onchangeData(e)}} value="9" type="radio" className="custom-control-input" id="landingpage_9" name="landingpage" checked={this.state.landingpage.toString() === "9"} />
											<label className="custom-control-label" htmlFor="landingpage_9" > Landing 8</label>
										</div>
									: ''
								}
								{
									window.location.hostname === 'vipfb18.com' ?
										<div className="custom-control custom-radio" style={{"display": "inline-block", "marginRight": "15px"}}>
											<input onChange={(e) => {this.onchangeData(e)}} value="10" type="radio" className="custom-control-input" id="landingpage_10" name="landingpage" checked={this.state.landingpage.toString() === "10"} />
											<label className="custom-control-label" htmlFor="landingpage_10" > Landing 9</label>
										</div>
									: ''
								}
								{
									window.location.hostname === 'ctvsubvn.com' ?
										<div className="custom-control custom-radio" style={{"display": "inline-block", "marginRight": "15px"}}>
											<input onChange={(e) => {this.onchangeData(e)}} value="11" type="radio" className="custom-control-input" id="landingpage_11" name="landingpage" checked={this.state.landingpage.toString() === "11"} />
											<label className="custom-control-label" htmlFor="landingpage_11" > Landing 10</label>
										</div>
									: ''
								}
							</div>
						</div>
						<div className="row">
							<div className="col-md-4">
								<div className="form-group">
									<label>Default language:</label>
								</div>
							</div>
							<div className="col-md-8">
								<select className="form-control" id="defaultlanguage" name="defaultlanguage" onChange={(e) => {this.onchangeData(e)}} value={this.state.defaultlanguage} >
									<option value="-1">Vietnam</option>
									<option value="en">English</option>
									<option value="af">Afrikaans</option>
									<option value="sq">Albanian</option>
									<option value="am">Amharic</option>
									<option value="ar">Arabic</option>
									<option value="hy">Armenian</option>
									<option value="az">Azerbaijani</option>
									<option value="eu">Basque</option>
									<option value="be">Belarusian</option>
									<option value="bn">Bengali</option>
									<option value="bs">Bosnian</option>
									<option value="bg">Bulgarian</option>
									<option value="ca">Catalan</option>
									<option value="ceb">Cebuano</option>
									<option value="ny">Chichewa</option>
									<option value="zh-CN">Chinese (Simplified)</option>
									<option value="zh-TW">Chinese (Traditional)</option>
									<option value="co">Corsican</option>
									<option value="hr">Croatian</option>
									<option value="cs">Czech</option>
									<option value="da">Danish</option>
									<option value="nl">Dutch</option>
									<option value="eo">Esperanto</option>
									<option value="et">Estonian</option>
									<option value="tl">Filipino</option>
									<option value="fi">Finnish</option>
									<option value="fr">French</option>
									<option value="fy">Frisian</option>
									<option value="gl">Galician</option>
									<option value="ka">Georgian</option>
									<option value="de">German</option>
									<option value="el">Greek</option>
									<option value="gu">Gujarati</option>
									<option value="ht">Haitian Creole</option>
									<option value="ha">Hausa</option>
									<option value="haw">Hawaiian</option>
									<option value="iw">Hebrew</option>
									<option value="hi">Hindi</option>
									<option value="hmn">Hmong</option>
									<option value="hu">Hungarian</option>
									<option value="is">Icelandic</option>
									<option value="ig">Igbo</option>
									<option value="id">Indonesian</option>
									<option value="ga">Irish</option>
									<option value="it">Italian</option>
									<option value="ja">Japanese</option>
									<option value="jw">Javanese</option>
									<option value="kn">Kannada</option>
									<option value="kk">Kazakh</option>
									<option value="km">Khmer</option>
									<option value="rw">Kinyarwanda</option>
									<option value="ko">Korean</option>
									<option value="ku">Kurdish (Kurmanji)</option>
									<option value="ky">Kyrgyz</option>
									<option value="lo">Lao</option>
									<option value="la">Latin</option>
									<option value="lv">Latvian</option>
									<option value="lt">Lithuanian</option>
									<option value="lb">Luxembourgish</option>
									<option value="mk">Macedonian</option>
									<option value="mg">Malagasy</option>
									<option value="ms">Malay</option>
									<option value="ml">Malayalam</option>
									<option value="mt">Maltese</option>
									<option value="mi">Maori</option>
									<option value="mr">Marathi</option>
									<option value="mn">Mongolian</option>
									<option value="my">Myanmar (Burmese)</option>
									<option value="ne">Nepali</option>
									<option value="no">Norwegian</option>
									<option value="or">Odia (Oriya)</option>
									<option value="ps">Pashto</option>
									<option value="fa">Persian</option>
									<option value="pl">Polish</option>
									<option value="pt">Portuguese</option>
									<option value="pa">Punjabi</option>
									<option value="ro">Romanian</option>
									<option value="ru">Russian</option>
									<option value="sm">Samoan</option>
									<option value="gd">Scots Gaelic</option>
									<option value="sr">Serbian</option>
									<option value="st">Sesotho</option>
									<option value="sn">Shona</option>
									<option value="sd">Sindhi</option>
									<option value="si">Sinhala</option>
									<option value="sk">Slovak</option>
									<option value="sl">Slovenian</option>
									<option value="so">Somali</option>
									<option value="es">Spanish</option>
									<option value="su">Sundanese</option>
									<option value="sw">Swahili</option>
									<option value="sv">Swedish</option>
									<option value="tg">Tajik</option>
									<option value="ta">Tamil</option>
									<option value="tt">Tatar</option>
									<option value="te">Telugu</option>
									<option value="th">Thai</option>
									<option value="tr">Turkish</option>
									<option value="tk">Turkmen</option>
									<option value="uk">Ukrainian</option>
									<option value="ur">Urdu</option>
									<option value="ug">Uyghur</option>
									<option value="uz">Uzbek</option>
									<option value="cy">Welsh</option>
									<option value="xh">Xhosa</option>
									<option value="yi">Yiddish</option>
									<option value="yo">Yoruba</option>
									<option value="zu">Zulu</option>
								</select>
							</div>
						</div>
						<div className="row">
							<div className="col-md-4">
								<div className="form-group">
									<label>Mục mô tả trang nạp tiền</label>
									<h6 className="font-bold mb-0 font-13 text-danger">(Nếu để trống sẽ hiện mô tả mặc định của hệ thống)</h6>
								</div>
							</div>
							<div className="col-md-8">
								<div className="form-group">
									<textarea rows="3" name="naptien_mota" onChange={(e) => {this.onchangeData(e)}} value={this.state.naptien_mota} className="form-control" placeholder="Tỷ giá: 1 VND = 1 Coin (bạn nạp vào tài khoản..."></textarea>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-md-4">
								<div className="form-group">
									<label>Mục chú ý trang nạp tiền</label>
									<h6 className="font-bold mb-0 font-13 text-danger">(Nếu để trống sẽ hiện chú ý mặc định của hệ thống)</h6>
								</div>
							</div>
							<div className="col-md-8">
								<div className="form-group">
									<textarea rows="3" name="naptien_chuy" onChange={(e) => {this.onchangeData(e)}} value={this.state.naptien_chuy} className="form-control" placeholder="Chú ý: - Nạp sai cú pháp...."></textarea>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-md-4">
								<div className="form-group">
									<label>Hiển thị mục Mua bán Fanpage/Group:</label>
								</div>
							</div>
							<div className="col-md-8">
								<div className="custom-control custom-radio" style={{"display": "inline-block", "marginRight": "15px"}}>
									<input onChange={(e) => {this.onchangeData(e)}} value="1" type="radio" className="custom-control-input" id="displaytabmuabanfanpagegroup_1" name="displaytabmuabanfanpagegroup" checked={this.state.displaytabmuabanfanpagegroup.toString() === "1"} />
									<label className="custom-control-label" htmlFor="displaytabmuabanfanpagegroup_1" > Bật</label>
								</div>
								<div className="custom-control custom-radio" style={{"display": "inline-block", "marginRight": "15px"}}>
									<input onChange={(e) => {this.onchangeData(e)}} value="0" type="radio" className="custom-control-input" id="displaytabmuabanfanpagegroup_0" name="displaytabmuabanfanpagegroup" checked={this.state.displaytabmuabanfanpagegroup.toString() === "0"} />
									<label className="custom-control-label" htmlFor="displaytabmuabanfanpagegroup_0" > Tắt</label>
								</div>
							</div>
						</div>
						{/* default_type_newuser */}
						<button type="button" onClick={(e) => {this.props.saveConfigColorNav(this.state)}} className="btn btn-success mt-3 mb-3">Lưu CẤU HÌNH</button>
					</div>
				</div>
			</Fragment>
		);
	}
}

const mapStateToProps = state => ({
    adminpage: state.adminpage,
    auth: state.auth
});

export default connect(mapStateToProps, { getTokenSiteBase, saveTokenSiteBase, saveConfigColorNav, getAPITrumThe, saveAPITrumThe, saveAPITheSieuRe }) (tag_tokensitebase);