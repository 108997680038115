import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Swal from 'sweetalert2';
import copy from 'copy-text-to-clipboard';

class Ghepfile extends Component {
	state = {
		loaibotrunglap: true,
		noidunga: "A\nC",
		text_placeholdera: "A\nC",
		noidungb: "B\nD",
		text_placeholderb: "B\nD",
		noidungngancach2dong: "+",
		ketqua: "",
	}
	onChangeTrungLap = (e) =>{
		this.setState({
			[e.target.name]: !this.state.loaibotrunglap
		})
	}
	onClick = (e) =>{
		this.setState({
			[e.target.name]: e.target.value
		})
	}
	onChange = (e) =>{
		this.setState({
			[e.target.name]: e.target.value
		})
	}
	onTrigger = (e) => {
		var { loaibotrunglap, noidunga, noidungb, noidungngancach2dong } = this.state;
		noidunga = noidunga.trim();
		noidungb = noidungb.trim();
		noidunga = noidunga
			.split("\n")
			.filter(item => item)
			.join("\n");
		noidungb = noidungb
			.split("\n")
			.filter(item => item)
			.join("\n");
		
		var noidung = "";
		var final_noidung = [];
		var noidunga = noidunga.split("\n");
		var noidungb = noidungb.split("\n");
		var length_noidunga = noidunga.length;
		var length_noidungb = noidungb.length;
		
		var max_len = length_noidunga;
		if (length_noidungb > length_noidunga) {
			max_len = length_noidungb;
		}
		
		for (var i = 0; i < max_len; i++) {
			var first_str = "";
			if (typeof noidunga[i] !== "undefined") {
				first_str = noidunga[i];
			}
			var last_str = "";
			if (typeof noidungb[i] !== "undefined") {
				last_str = noidungb[i];
			}
			final_noidung[i] = first_str + " " + noidungngancach2dong + " " + last_str;
		}
		
		if (loaibotrunglap) {
			final_noidung = final_noidung
				.filter((item, i, allItems) => {
					return i === allItems.indexOf(item);
				});
		}
		
		noidung = final_noidung.join("\n");
		
		this.setState({
			ketqua: noidung.trim()
		});
	}
	
	render() {
		return (
			<Fragment>
				<div className="form-group row">
					<label className="col-sm-2 col-form-label edittext-label-right" htmlFor="">Loại bỏ trùng lặp?</label>
					<div className="col-sm-10" style={{"display": "flex", "alignItems": "center"}}>
						<div className="custom-control custom-switch mb-1">
							<input onChange={(e) => {this.onChangeTrungLap(e)}} type="checkbox" className="custom-control-input" id="loaibotrunglap" name="loaibotrunglap" checked={this.state.loaibotrunglap} />
							<label className="custom-control-label edittext-custom-control-label" htmlFor="loaibotrunglap" ></label>
						</div>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-2 col-form-label edittext-label-right" htmlFor="">Nội dung A</label>
					<div className="col-sm-10">
						<textarea rows="5" placeholder={this.state.text_placeholdera} className="form-control input-gray edittext-textarea" name="noidunga" onChange={this.onChange} value={this.state.noidunga} ></textarea>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-2 col-form-label edittext-label-right" htmlFor="">Nội dung B</label>
					<div className="col-sm-10">
						<textarea rows="5" placeholder={this.state.text_placeholderb} className="form-control input-gray edittext-textarea" name="noidungb" onChange={this.onChange} value={this.state.noidungb} ></textarea>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-2 col-form-label edittext-label-right" htmlFor="">Nội dung ngắn cách 2 dòng</label>
					<div className="col-sm-10">
						<input type="text" className="form-control" name="noidungngancach2dong" value={this.state.noidungngancach2dong} onChange={this.onChange} />
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-2 col-form-label edittext-label-right" htmlFor=""></label>
					<div className="col-sm-10">
						<button type="button" className="btn btn-rounded btn-hero-primary edittext-btn" onClick={this.onTrigger} >Trigger</button>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-2 col-form-label edittext-label-right" htmlFor=""></label>
					<div className="col-sm-10">
						<textarea rows="5" placeholder="" className="form-control input-gray edittext-textarea" name="ketqua" defaultValue={this.state.ketqua} ></textarea>
					</div>
				</div>
			</Fragment>
		);
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
});
const mapDispatchToProps = {  };

export default connect(mapStateToProps, mapDispatchToProps)(Ghepfile);
