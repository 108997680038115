import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Swal from 'sweetalert2';
import copy from 'copy-text-to-clipboard';

class Linkhtml extends Component {
	state = {
		loaibotrunglap: true,
		linkdomainwebsite: "https://google.com/",
		noidung: "<a href='http://google.com/1.html'>1.html</a>\n<a href='/2.html'>2.html</a>",
		text_placeholder: "<a href='http://google.com/1.html'>1.html</a>\n<a href='/2.html'>2.html</a>",
		ketqua: "",
	}
	onChangeTrungLap = (e) =>{
		this.setState({
			[e.target.name]: !this.state.loaibotrunglap
		})
	}
	onClick = (e) =>{
		this.setState({
			[e.target.name]: e.target.value
		})
	}
	onChange = (e) =>{
		this.setState({
			[e.target.name]: e.target.value
		})
	}
	onTrigger = (e) => {
		var { loaibotrunglap, noidung, linkdomainwebsite } = this.state;
		var final_noidung = [];
		var matches_a = noidung.match(/\<a.+href\=(?:\"|\')(.+?)(?:\"|\')(?:.+?)\>/g);
		if (matches_a !== null) {
			if (matches_a.length > 0) {
				matches_a = matches_a.join("\n");
				var matches_href = matches_a.match(/href\=(?:\"|\')(.+?)(?:\"|\')/g);
				if (matches_href !== null) {
					if (matches_href.length > 0) {
						matches_href.forEach(function(v, i) {
							v = v.replace(/\"|\'|href=/g, "");
							if (v.indexOf("//") > -1 && v.indexOf("http") === -1) {
								final_noidung.push("https:"+v);
							} else if (v.indexOf("//") === -1 && v.indexOf("http") === -1) {
								final_noidung.push(linkdomainwebsite+v);
							} else {
								final_noidung.push(v);
							}
						});
					}
				}
			}
		}
		
		if (loaibotrunglap) {
			final_noidung = final_noidung
				.filter((item, i, allItems) => {
					return i === allItems.indexOf(item);
				});
		}
		
		noidung = final_noidung.join("\n");
		
		this.setState({
			ketqua: noidung
		});
	}
	
	render() {
		var { ketqua } = this.state;
		if (ketqua == "") {
			ketqua = [];
		} else {
			ketqua = ketqua.split("\n");
		}
		var html_all_link = (
  			<Fragment>
  				{
  					ketqua.map(function(value,index){
						return (
							<Fragment>
								<a href={value} target="_blank">{value}</a><br/>
							</Fragment>
						);
  					})
  				}
  			</Fragment>
  		);
		return (
			<Fragment>
				<div className="form-group row">
					<label className="col-sm-2 col-form-label edittext-label-right" htmlFor="">Loại bỏ trùng lặp?</label>
					<div className="col-sm-10" style={{"display": "flex", "alignItems": "center"}}>
						<div className="custom-control custom-switch mb-1">
							<input onChange={(e) => {this.onChangeTrungLap(e)}} type="checkbox" className="custom-control-input" id="loaibotrunglap" name="loaibotrunglap" checked={this.state.loaibotrunglap} />
							<label className="custom-control-label edittext-custom-control-label" htmlFor="loaibotrunglap" ></label>
						</div>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-2 col-form-label edittext-label-right" htmlFor="">Link domain website</label>
					<div className="col-sm-10">
						<input type="text" className="form-control" name="linkdomainwebsite" value={this.state.linkdomainwebsite} onChange={this.onChange} />
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-2 col-form-label edittext-label-right" htmlFor="">Nội dung</label>
					<div className="col-sm-10">
						<textarea rows="5" placeholder={this.state.text_placeholder} className="form-control input-gray edittext-textarea" name="noidung" onChange={this.onChange} value={this.state.noidung} ></textarea>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-2 col-form-label edittext-label-right" htmlFor=""></label>
					<div className="col-sm-10">
						<button type="button" className="btn btn-rounded btn-hero-primary edittext-btn" onClick={this.onTrigger} >Trigger</button>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-2 col-form-label edittext-label-right" htmlFor=""></label>
					<div className="col-sm-10">
						<textarea rows="5" placeholder="" className="form-control input-gray edittext-textarea" name="ketqua" defaultValue={this.state.ketqua} ></textarea>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-2 col-form-label edittext-label-right" htmlFor=""></label>
					<div className="col-sm-10">
						<div className="col-sm-12" style={{"height": "calc(100vh - 660px)", "overflow": "auto", "border": (ketqua.length > 0 != "") ? "1px solid #d8dfed" : ""}}>
							{html_all_link}
						</div>
					</div>
				</div>
			</Fragment>
		);
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
});
const mapDispatchToProps = {  };

export default connect(mapStateToProps, mapDispatchToProps)(Linkhtml);
