import {
	LOAD_FVVV,
	UPDATE_FVVV_TAB,
	UPDATE_FVVV,
	LOAD_PRICE_FVVV,
	LIMIT_PAGI_FVVV,
	TYPE_FVVV,
	LOADING_FVVV,
	UPDATE_FVVV_GETID
} from "../actions/types";

const date7dnext = () => {
	var result = new Date();
  	result.setDate(result.getDate() + 7);
  	return result;
}

const initialState = {
	"taotientrinh": {
		"idfb": "",
		"usernamefb": "",
		"goiview": "2000",
		"tgsd": "0.5",
		"gtmtt": "0",
	},
	"changetab": 0,
	"listtientrinh": [],
	"price": [],
	"limit_pagi": 0,
	"type": 0,
	"loading": false,
};

export default function(state = initialState, action) {
	switch (action.type) {
		case LOAD_FVVV:
			return {
				...state,
				"listtientrinh": action.payload
			};
		case UPDATE_FVVV_TAB:
			return {
				...state,
				"changetab": action.payload
			};
		case UPDATE_FVVV:
			return {
				...state,
				"taotientrinh": action.payload
			};
		case LOAD_PRICE_FVVV:
			return {
				...state,
				"price": action.payload,
				"taotientrinh": {
					...state.taotientrinh,
					"gtmtt": action.payload.prices_web
				}
			};
		case LIMIT_PAGI_FVVV:
			return {
				...state,
				"limit_pagi": action.payload
			};
		case TYPE_FVVV:
			return {
				...state,
				"type": action.payload
			};
		case LOADING_FVVV:
			return {
				...state,
				"loading": action.payload
			};
		case UPDATE_FVVV_GETID:
			return {
				...state,
				"taotientrinh": {
					...state.taotientrinh,
					"idfb": action.payload.id,
					"usernamefb": action.payload.username
				}
			};
		default:
		  return state;
	}
}
