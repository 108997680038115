import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import {
    Modal,
    ModalBody,
    ModalFooter
} from 'reactstrap';
import { saveAddAccClone } from '../../../../actions/adminPageAction';


class addaccountclone extends Component {

    state = {
        formdata: {
            uidfb: '',
            password: '',
            loaigoi: this.props.adminpage.list_speciesclone[0].id,
        }
    }

    changeValueRegister = (e) => {
        this.setState({
            formdata: {
                ...this.state.formdata,
                [e.target.name]: e.target.value
            }
        });
    }

    render() {
        const {isOpenModal,adminpage} = this.props;
        const {uidfb,loaigoi,password} = this.state.formdata;
        const { loadingblock } = this.props.adminpage;
        const html_selectSpecies = (
            <Fragment>
                <select onChange={(e) => {this.changeValueRegister(e)}} value={loaigoi} name="loaigoi" className="form-control">
                    {
                        adminpage.list_speciesclone.map(function (dataList, index) {
                            return (
                                <option value={dataList.id} key={index}>{dataList.name}</option>
                            );
                        })
                    }
                </select>
            </Fragment>
        )

        return (
            <Fragment>
                <Modal centered={true} size="lg" toggle={(e) => {this.props.actionOpenModal(e)}} isOpen={isOpenModal} className={(loadingblock? ' block block-mode-loading' : '')} style={{"backgroundColor": "inherit", "boxShadow": "none"}}>
                    <div className="modal-header">
                        <p className="mb-0 text-dark">Thêm tài khoản clone</p>
                        <button onClick={(e) => {this.props.actionOpenModal(e)}} type="button" className="close">×</button>
                    </div>
                    <ModalBody style={{opacity: loadingblock? '.05':'1'}}>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Uid</label>
                                    <input type="text" onChange={(e) => {this.changeValueRegister(e)}} value={uidfb} name="uidfb" className="form-control"  placeholder="Tên loại" />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Mật khẩu</label>
                                    <input type="text" onChange={(e) => {this.changeValueRegister(e)}} value={password} name="password" className="form-control"  placeholder="Mật khẩu" />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Loại gói</label>
                                    {html_selectSpecies}
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter style={{opacity: loadingblock? '.05':'1'}}>
                        <button type="button" className="btn btn-primary" onClick={() => {this.props.saveAddAccClone(this.state.formdata, this.props.actionOpenModal)}}>Lưu</button>
                        <button type="button" className="btn btn-danger" onClick={(e) => {this.props.actionOpenModal(e)}}>Huỷ</button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        );
    }

}

const mapStateToProps = state => ({
    adminpage: state.adminpage
});

export default connect(mapStateToProps, { saveAddAccClone }) (addaccountclone);
