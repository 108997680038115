import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import {
    Modal,
    ModalBody,
    ModalFooter
} from 'reactstrap';
import { NhatKyHoatDong, closeModalNhatKy } from '../../../actions/SpamcallActions';
import Swal from 'sweetalert2';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import parse from 'html-react-parser';
const { SearchBar } = Search;

class Modal_edit extends Component {
    state = {
		
    }

	componentDidMount() {
		this.props.NhatKyHoatDong(this.props.datasdt);
	}
	
    render() {
        const {isOpenModal, datasdt} = this.props;
        var {list_nhatky} = this.props.Spamcall;
		var dataReturn = [];
		var arrKeyObj = ['sdt', 'sogoiden', 'thoigiangoi', 'status', 'timegoithanhcong', 'id'];
		list_nhatky = list_nhatky.split('\n').reverse();
		list_nhatky.forEach(function(v, i){
			if (v != '') {
				var arrK = v.split("||");
				arrK.push(i);
				var objEnd = Object.assign({},...arrK.map((key,ik) => ({[arrKeyObj[parseInt(ik)]]: key})));
				dataReturn.push(objEnd);
			}
		});
		function formatThoigiangoi(cell, row) {
			return parse(cell+'s');
		}
		const columns = [{
			dataField: 'sogoiden',
			text: 'Số gọi đến',
			sort: true,
		}, {
			dataField: 'thoigiangoi',
			text: 'Thời gian gọi',
			sort: true,
			formatter: formatThoigiangoi,
			filterValue: formatThoigiangoi,
		}, {
			dataField: 'timegoithanhcong',
			text: 'Time gọi thành công',
			sort: true,
		}];
        return (
            <Fragment>
                <Modal centered={true} size="lg" toggle={(e) => {this.props.closeModalNhatKy();this.props.openModal()}} isOpen={isOpenModal}>
                    <div className="modal-header">
                        <p className="mb-0 text-dark">Nhật ký hoạt động</p>
                        <button onClick={(e) => {this.props.closeModalNhatKy();this.props.openModal()}} type="button" className="close">×</button>
                    </div>
                    <ModalBody>
                       <ToolkitProvider keyField="id" data={ dataReturn } columns={ columns } search >
							{
								props => (
									<div>
										<SearchBar { ...props.searchProps } />
										<BootstrapTable { ...props.baseProps } pagination={ paginationFactory() } wrapperClasses="table-responsive" striped hover condensed />
									</div>
								)
							}
						</ToolkitProvider>
                    </ModalBody>
                </Modal>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    Spamcall: state.Spamcall
});

export default connect(mapStateToProps, {NhatKyHoatDong, closeModalNhatKy}) (Modal_edit);
