import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import TaoTientrinh from "./tabVimeofollow/taotientrinh";
import NhatKy from "./tabVimeofollow/nhatky";

import { changeTab, loadVF } from '../../actions/VimeofollowActions';

class Vimeofollow extends Component {
	static propTypes =  {
		changeTab: PropTypes.func.isRequired,
	};
	componentDidMount() {
		this.props.changeTab(0);
	};
	onClick = (value) => {
		this.props.changeTab(value);
		this.props.loadVF();
	};
	render() {
		const { changetab } = this.props.Vimeofollow;
		return (
		  <Fragment>
			<div className={"block block-bordered shadow"+ (this.props.Vimeofollow.loading? ' block-mode-loading' : '')}>
				<ul className="nav nav-tabs nav-tabs-block" data-toggle="tabs" role="tablist" >
					<li className="nav-item col-lg-6 licus">
						<Link className={"nav-link " + (changetab === 0 ? "active": "" )} to="/tool/vimeofollow" onClick={() => { this.onClick(0) }} >
							Tạo Tiến Trình
						</Link>
					</li>
					<li className="nav-item col-lg-6 licus">
						<Link className={"nav-link " + (changetab === 1 ? "active": "" )} to="/tool/vimeofollow" onClick={() => { this.onClick(1) }} >
							Danh Sách Order
						</Link>
					</li>
				</ul>
				<div className="block-content tab-content overflow-hidden">
					<div className={"tab-pane fade fade-left " + (changetab === 0 ? "show active": "" )} id="btabs-animated-slideleft-home" role="tabpanel" >
						<TaoTientrinh />
					</div>
					<div className={"tab-pane fade fade-left " + (changetab === 1 ? "show active": "" )} id="btabs-animated-slideleft-profile" role="tabpanel" >
						<NhatKy />
					</div>
				</div>
			</div>
		  </Fragment>
		);
	}
}

const mapStateToProps = state => ({
  auth: state.auth,
  Vimeofollow: state.Vimeofollow,
});
const mapDispatchToProps = { changeTab, loadVF };

export default connect(mapStateToProps, mapDispatchToProps)(Vimeofollow);
