import React, { Component, Fragment } from 'react';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import store from '../../../store';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { loadRipacc, updateTypeRipacc, updateLimitRipacc, deleteSpamsms } from '../../../actions/RipaccActions';
import Swal from 'sweetalert2';
import parse from 'html-react-parser';
import { confirmAlert } from 'react-confirm-alert';
import Modaledit from './modal_edit';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { RiExchangeDollarLine } from "react-icons/ri";
const { SearchBar } = Search;

class nhatky extends Component {
	static propTypes =  {
		loadRipacc: PropTypes.func.isRequired,
		deleteSpamsms: PropTypes.func.isRequired,
		updateLimitRipacc: PropTypes.func.isRequired,
		updateTypeRipacc: PropTypes.func.isRequired,
	};
	
	state = {
		isOpenModalEdit: false,
		id: 0,
		thongbao: "",
		status: "",
		idfb: 0,
	}
	
	componentDidMount() {
		this.props.loadRipacc();
	}
	
	onChangeLimit = e => {
		const { type } = this.props.Ripacc;
		this.props.updateLimitRipacc(e.target.value);
		this.props.loadRipacc(type, e.target.value);
	};
	
	onCheckList = (type) => {
		const { limit_pagi } = this.props.Ripacc;
		this.props.updateTypeRipacc(type);
		this.props.loadRipacc(type, limit_pagi);
	}
	
    openModalEdit = (data = null) => {
		this.setState({
			...this.state,
			isOpenModalEdit: !this.state.isOpenModalEdit,
		});
		if (data !== null) {
			this.setState({
				...this.state,
				isOpenModalEdit: !this.state.isOpenModalEdit,
				id: data.id,
				thongbao: data.thongbao,
				status: data.status,
			});
		}
    }
	
	calBwDateToNow = (date_check = null) => {
		if (Number.isInteger(date_check)) {
			let date_now = Math.floor(Date.now() / 1000);
			let calDateDay = (date_now - date_check)/86400;
			if (calDateDay > 1) {
				return Math.ceil(calDateDay) + ' ngày trước';
			} else {
				let calDatehour = (date_now - date_check)/3600;
				if (calDatehour < 0.6) {
					return Math.ceil(calDatehour*100) + ' phút trước';
				} else {
					return Math.ceil(calDatehour) + ' giờ trước';
				}
			}
		}
	}
	
	render() {
		const { listtientrinh, limit_pagi } = this.props.Ripacc;
		const { username } = this.props.auth.user;
		var ar_listtientrinh = [];
		listtientrinh.forEach(function(v, i) {
			ar_listtientrinh.push(v);
		});
		
		function onClickXoa(row) {
			confirmAlert({
				// closeOnClickOutside: false,
				customUI: ({ onClose }) => {
					return	(
						<div aria-labelledby="swal2-title" aria-describedby="swal2-content" className="swal2-popup swal2-modal swal2-icon-warning swal2-show an-confirm-alert" tabIndex="-1" role="dialog" aria-live="assertive" aria-modal="true" style={{"display": "flex"}}>
							<div className="swal2-header">
								<div className="swal2-icon swal2-warning swal2-icon-show" style={{"display": "flex"}}>
									<div className="swal2-icon-content">?</div>
								</div>
							</div>
							<div className="swal2-content">
								<div id="swal2-content" className="swal2-html-container" style={{"display": "block"}}>Sau khi hủy bạn sẽ được hoàn lại số lượng chưa tăng sau 1 đến 3 ngày và trừ thêm 5.000 Coin phí để tránh Spam!</div>
								<div className="swal2-validation-message" id="swal2-validation-message"></div>
							</div>
							<div className="swal2-actions">
								<button type="button" className="swal2-confirm btn btn-danger m-1" aria-label="" style={{"display": "inline-block"}} onClick={() => { store.dispatch(deleteSpamsms(row)); onClose(); }} >Đồng ý</button>
								<button type="button" className="swal2-cancel btn btn-secondary m-1" aria-label="" style={{"display": "inline-block"}} onClick={() => { onClose(); }} >Hủy</button>
							</div>
						</div>
					)
				}
			});
		}
		function formatTrangThai(cell, row) {
			var message = '';
			if (cell === 0) {
				return (
					<span className="badge badge-primary"><i className="fas fa-check mr-1"></i>Hoàn thành</span>
				);
			} else if (cell === 1) {
				return (
					<span className="badge badge-success"><i className="fas fa-sync-alt mr-1"></i>Đang chạy</span>
				);
			} else if (cell === 2) {
				return (
					<span className="badge badge-warning"><i className="fas fa-sync-alt mr-1"></i>Chờ duyệt</span>
				);
			} else if (cell === 3) {
				return (
					<span className="badge badge-danger"><i className="fas fa-ban mr-1"></i>Hủy</span>
				);
			} else if (cell === 5) {
				return (
					<span className="badge badge-info"><RiExchangeDollarLine size={12} className="mr-1" />Hoàn tiền</span>
				);
			} else {
				return ('');
			}
		}
		function filterValueTrangThai(cell, row) {
			if (cell === 0) {
				return ('Hoàn thành');
			} else if (cell === 1) {
				return ('Đang chạy');
			} else if (cell === 2) {
				return ('Chờ duyệt');
			} else if (cell === 3) {
				return ('Hủy đơn');
			} else if (cell === 5) {
				return ('Hoàn tiền');
			} else {
				return ('');
			}
		}
		function formatCheckTime(cell, row) {
			return (
				<Fragment>
					<Moment format="DD-MM-YYYY HH:mm:ss">{cell}</Moment>
				</Fragment>
			);
		}
		function filterValueCheckTime(cell, row) {
			var d = new Date(cell);
			var month = '' + (d.getMonth() + 1);
			var day = '' + d.getDate();
			var year = d.getFullYear();
			var hour = '' + (d.getHours());
			var min = '' + d.getMinutes();
			var sec = '' + d.getSeconds();

			if (month.length < 2)
				month = '0' + month;
			if (day.length < 2)
				day = '0' + day;
			if (hour.length < 2)
				hour = '0' + hour;
			if (min.length < 2)
				min = '0' + min;
			if (sec.length < 2)
				sec = '0' + sec;
			
			return [day, month, year].join('-') + ' ' + [hour, min, sec].join(':');
		}
		function formatNoiDung(cell, row) {
			return cell.length > 45 ? cell.slice(0, 45 - 1) + "…" : cell;
		}
		function filterValueNoiDung(cell, row) {
			return cell;
		}
		function formatQuaTay(cell, row) {
			if (cell === 0) {
				return ('Chưa');
			} else if (cell === 1) {
				return ('Rồi');
			} else if (cell === 2) {
				return ('Chưa xác định');
			} else {
				return ('');
			}
		}
		function formatThongBao(cell, row) {
			return cell != null ? parse(cell.trim().replace(/\n/g, '<br/>')) : '';
		}
		function formatLoai(cell, row) {
			if (cell === 0) {
				return ('Facebook cá nhân');
			} else if (cell === 1) {
				return ('Fanpage');
			} else {
				return ('');
			}
		}
		function formatNamTao(cell, row) {
			if (cell == '') {
				return ('Không biết');
			} else {
				return (cell);
			}
		}
		function formatLienHe(cell, row) {
			return parse('Số điện thoại: ' + row.sdt + '<br/>' + 'Facebook: <a href="https://facebook.com/'+row.fb+'" target="_blank" rel="noopener noreferrer">'+row.fb+'</a>');
		}
		const columns = [{
			dataField: 'id',
			text: 'STT',
			sort: true,
		}, {
			text: 'Thao tác',
			dataField: '-',
		    style: {
			  'white-space' : 'nowrap'
		    },
			formatter: (cell, row) => 
				<Fragment>
					{
						this.props.auth.user.type === 1 ?
							<Link to="/tool/ripacc" className="btn btn-sm btn-success text-white text-white mr-1" onClick={() => {this.openModalEdit(row)}}>
								<i className="fa fa-fw fa-pen"></i>
							</Link>
						: ''
					}
				</Fragment>
		},{
			dataField: 'object_id',
			text: 'ID FB',
			sort: true,
		    formatter: (cell, row) => 
			  <Fragment>
				  <a href={"https://facebook.com/"+cell} target="_blank" rel="noopener noreferrer">{cell}</a>
			  </Fragment>
		},{
			dataField: 'loairip',
			text: 'Loại',
			sort: true,
			formatter: formatLoai,
			filterValue: formatLoai
		},{
			dataField: 'quatay',
			text: 'Qua tay',
			sort: true,
			formatter: formatQuaTay,
			filterValue: formatQuaTay
		},{
			dataField: 'namtao',
			text: 'Năm tạo nick',
			sort: true,
			formatter: formatNamTao,
			filterValue: formatNamTao
		},{
			dataField: 'ghichu',
			text: 'Ghi chú',
			sort: true,
		},{
			dataField: 'thongbao',
			text: 'Thông báo admin',
			sort: true,
			formatter: formatThongBao,
			filterValue: formatThongBao
		},{
			dataField: 'lienhe',
			text: 'Liên hệ người mua',
			sort: true,
			formatter: formatLienHe,
			filterValue: formatLienHe
		}, {
			dataField: 'prices',
			text: 'Giá',
			sort: true,
		}, {
			dataField: 'created_at',
			text: 'Ngày mua',
			sort: true,
			formatter: (cell, row) => 
				<Fragment>
					{this.calBwDateToNow(cell)}
				</Fragment>
		}, {
			dataField: 'username',
			text: 'Người Add',
			sort: true,
		    classes: 'notranslate',
		},{
			dataField: 'status',
			text: 'Trạng thái',
			sort: true,
			formatter: formatTrangThai,
			filterValue: filterValueTrangThai
		}];

		return (
			<Fragment>
				<ToolkitProvider keyField="id" data={ ar_listtientrinh } columns={ columns } search >
					{
						props => (
							<div>
								<SearchBar { ...props.searchProps } />
								<div className="row mb-4">
									<div className="col-md-12">
										{ this.props.auth.user.type === 1 ?
											<Fragment>
												<button onClick={() => {this.onCheckList(1)}} type="button" className="btn btn-primary mr-1">Xem tất cả</button>
												<button onClick={() => {this.onCheckList(0)}} type="button" className="btn btn-primary mr-1">Xem riêng</button>
											</Fragment>
										: ''}
										<select className="form-control custom-select select-light col-md-3 custom-limit_pagi" id="limit_pagi" name="limit_pagi" onChange={this.onChangeLimit} value={limit_pagi} >
											<option value="0">Số lịch sử hiển thị tối đa</option>
											<option value="50">50</option>
											<option value="100">100</option>
											<option value="200">200</option>
											<option value="500">500</option>
											<option value="1000">1000</option>
										</select>
									</div>
								</div>
								<BootstrapTable { ...props.baseProps } pagination={ paginationFactory() } wrapperClasses="table-responsive" striped hover condensed />
								{this.state.isOpenModalEdit ? <Modaledit openModal={this.openModalEdit} isOpenModal={this.state.isOpenModalEdit} dataid={this.state.id} datathongbao={this.state.thongbao} datastatus={this.state.status} /> : ''}
							</div>
						)
					}
				</ToolkitProvider>
				<div>
					{/*<BootstrapTable keyField='id' data={ ar_listtientrinh } columns={ columns } pagination={ paginationFactory() } wrapperClasses="table-responsive" striped hover condensed />
					{this.state.isOpenModalEdit ? <Modaledit openModal={this.openModalEdit} isOpenModal={this.state.isOpenModalEdit} dataid={this.state.id} datacookie={this.state.cookie} /> : ''}*/}
				</div>
			</Fragment>
		)
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	Ripacc: state.Ripacc,
});
const mapDispatchToProps = { loadRipacc, deleteSpamsms, updateTypeRipacc, updateLimitRipacc }

export default connect(mapStateToProps, mapDispatchToProps)(nhatky);