import React from 'react';
import axios from 'axios';
import {
	LOAD_EMAILTOUID,
	UPDATE_EMAILTOUID_TAB,
	UPDATE_EMAILTOUID,
	LIMIT_PAGI_EMAILTOUID,
	TYPE_EMAILTOUID,
	LOADING_EMAILTOUID,
	LOAD_NHATKYHOATDONG_EMAILTOUID,
	UPDATE_UID_EMAILTOUID,
} from "../actions/types";
import Swal from 'sweetalert2';
import { loadUser } from "./authActions";

export const loadEmailtouid = (type = 0, limit = 0) => (dispatch, getState) => {
	dispatch({
		type: LOADING_EMAILTOUID,
		payload: true
	});
	axios.get('/api/emailtouid?type='+type+'&limit='+limit, tokenConfig(getState))
		.then(res => {
			dispatch({
				type: LOADING_EMAILTOUID,
				payload: false
			});
			if (res.data.status === 200) {
				dispatch({
					type: LOAD_EMAILTOUID,
					payload: res.data.data
				});
			}
		});
};

export const changeTab = (body) => (dispatch, getState) => {
	dispatch({
		type: UPDATE_EMAILTOUID_TAB,
		payload: body
	});
};

export const updateEmailtouid = (body) => (dispatch, getState) => {
	dispatch({
		type: UPDATE_EMAILTOUID,
		payload: body
	});
};

export const updateLimitEmailtouid = (body) => (dispatch) => {
	dispatch({
		type: LIMIT_PAGI_EMAILTOUID,
		payload: body
	})
};

export const updateTypeEmailtouid = (body) => (dispatch) => {
	dispatch({
		type: TYPE_EMAILTOUID,
		payload: body
	})
};

export const addEmailtouid = (body) => (dispatch, getState) => {
	dispatch({
		type: LOADING_EMAILTOUID,
		payload: true
	});
	axios.post('/api/emailtouid/add', body, tokenConfig(getState))
		.then(res => {
			dispatch({
				type: LOADING_EMAILTOUID,
				payload: false
			});
			dispatch(loadUser());
			if (res.data.status === 200) {
				dispatch({
					type: UPDATE_UID_EMAILTOUID,
					payload: res.data.data
				});
				Swal.fire({
					icon: 'success',
					html: res.data.message
				});
			} else {
				var message = '';
				if (res.data.error) {
					if (res.data.error.object_id) {
						message = res.data.error.object_id[0];
					} else if (res.data.error.quantity) {
						message = res.data.error.quantity[0];
					} else {
						message = res.data.message;
					}
				} else {
					message = res.data.message;
				}
				Swal.fire({
					icon: 'error',
					title: 'Lỗi',
					text: message,
				});
			}
		});
};

export const tokenConfig = getState => {
	// Get token from localhost
	const token = getState().auth.token;
	// Headers
	const config = {
		headers: {
			"Content-type": "application/json"
		}
	};
	
	// If token, add to headers
	if (token) {
		config.headers['ht-token'] = token;
	}
	
	return config;
};