import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import store from '../../../store';

import ModalAddAccountClone from './modal/addaccountclone';
import ModalEditAccountClone from './modal/editaccountclone';

import { getListBankHistory } from '../../../actions/adminPageAction';
import BootstrapTable from 'react-bootstrap-table-next';
import Moment from 'react-moment';
import paginationFactory from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
const { SearchBar } = Search;

class TagBankPaymentHistoy extends Component {
	state = {
        countItemNow: 0,
        isOpenModalAddAcc: false,
        isOpenModalEditAcc: false,
        limit: 50,
        search: ''
    }

    componentDidMount(){
        this.props.getListBankHistory(50,'');
    }

    getNameSpecies = (cell) => {
    	let name = 'Không có gói';
    	this.props.adminpage.list_speciesclone.forEach(function(v, i){
    		if (cell === v.id) {
    			name = v.name;
    		}
    	});
    	return name;
    }
    formatMoney(amount, decimalCount = 0, decimal = ".", thousands = ",") {
        try {
          decimalCount = Math.abs(decimalCount);
          decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

          const negativeSign = amount < 0 ? "-" : "";

          let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
          let j = (i.length > 3) ? i.length % 3 : 0;

          return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
        } catch (e) {
          console.log(e)
        }
    }
    getListByLimit = (e) => {
      let valueChange = e.target.value;
      if (valueChange !== '') {
        this.setState({
          [e.target.name]: e.target.value
        });
        this.props.getListBankHistory(valueChange, this.state.search);
      }
    }
    searchData(e){
      this.setState({
        [e.target.name]: e.target.value
      });
      this.props.getListBankHistory(this.state.limit, e.target.value);
    }
    render() {
		function formatCheckTime(cell, row) {
			return (
				<Fragment>
					<Moment format="DD-MM-YYYY HH:mm:ss">{parseInt(cell)}</Moment>
				</Fragment>
			);
		}
		function filterValueCheckTime(cell, row) {
			var d = new Date(parseInt(cell));
			var month = '' + (d.getMonth() + 1);
			var day = '' + d.getDate();
			var year = d.getFullYear();
			var hour = '' + (d.getHours());
			var min = '' + d.getMinutes();
			var sec = '' + d.getSeconds();

			if (month.length < 2)
				month = '0' + month;
			if (day.length < 2)
				day = '0' + day;
			if (hour.length < 2)
				hour = '0' + hour;
			if (min.length < 2)
				min = '0' + min;
			if (sec.length < 2)
				sec = '0' + sec;
			
			return [day, month, year].join('-') + ' ' + [hour, min, sec].join(':');
		}
    	const columns = [
        {
          dataField: 'id',
          text: 'STT',
          sort: true,
        },{
          text: 'Tên tài khoản',
          dataField: 'username',
          sort: true,
		  classes: 'notranslate',
        },
        {
          dataField: 'noidung',
          text: 'Nội dung',
          sort: true,
          formatter: (cell, row) =>
            <span> {row.noidung.substring(0, 45)}...</span>
        },
        {
          dataField: 'tien_nap',
          text: 'Tiền nạp',
          sort: true,
          formatter: (cell, row) =>
            <span> {this.formatMoney(row.tien_nap)} VND</span>
        },
        {
          dataField: 'khuyen_mai',
          text: 'Khuyến mãi',
          sort: true,
          formatter: (cell, row) =>
            <span> {row.khuyen_mai} %</span>
        },
        {
          dataField: 'thoigian_nap',
          text: 'Thời gian nạp',
          sort: true,
		  formatter: formatCheckTime,
		  filterValue: filterValueCheckTime
        }
      ];

        const changePagi = {
            onPageChange: (sizePerPage, page) => {
                this.setState({
                    countItemNow: (page - 1) * sizePerPage
                });
            },
        };
        const {listbankpaymenthistory} = this.props.adminpage;
        return (
            <Fragment>
                {/*<div className="row mb-4">
                  <div className="col-md-3">
                    <select onChange={(e) => {this.getListByLimit(e)}} className="form-control" defaultValue={this.state.limit}>
                      <option value="0">Số lịch sử hiển thị tối đa</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="200">200</option>
                        <option value="500">500</option>
                        <option value="1000">1000</option>
                    </select>
                  </div>
                  <div className="col-md-9">
                    <div className="col-md-4" style={{"float":"right"}}>
                        <input type="text" onChange={(e)=>{this.searchData(e)}} name="search" class="form-control" placeholder="Nhập dữ liệu cần tìm kiếm"/>
                    </div>
                  </div>
                </div>*/}
				<ToolkitProvider keyField="id" data={ listbankpaymenthistory } columns={ columns } search >
					{
						props => (
							<div>
								<SearchBar { ...props.searchProps } />
								<div className="row mb-4">
									<div className="col-md-12">
										<select onChange={(e) => {this.getListByLimit(e)}} className="form-control custom-select select-light col-md-3 custom-limit_pagi" defaultValue={this.state.limit}>
										  <option value="0">Số lịch sử hiển thị tối đa</option>
											<option value="50">50</option>
											<option value="100">100</option>
											<option value="200">200</option>
											<option value="500">500</option>
											<option value="1000">1000</option>
										</select>
									</div>
								</div>
								<BootstrapTable
									{ ...props.baseProps }
									bodyStyle={ { border: 'none' } }
									headerStyle={ { border: 'red 1px solid' } }
									striped
									hover
									wrapperClasses="table-responsive"
									pagination={ paginationFactory() } />
							</div>
						)
					}
				</ToolkitProvider>
            </Fragment>
        );
    }

}

const mapStateToProps = state => ({
	adminpage: state.adminpage,
});

export default connect(mapStateToProps, { getListBankHistory }) (TagBankPaymentHistoy);
