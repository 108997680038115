import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import store from '../../../store';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

import { getDataPriceAllFunction,deleteItemPriceFunc,saveChenhLech,getChenhLech,updateChenhLech } from '../../../actions/adminPageAction';
import ModalEditPriceFunction from './modal/editpricefunction';
import ModalAddPriceFunction from './modal/addpricefunction';
import ModalAddPriceSpeedFunction from './modal/addpricespeedfunction';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
const { SearchBar } = Search;

class tag_setpricesfunc extends Component {
    state = {
        isOpenModal: false,
        isOpenModalAdd: false,
        isOpenModalAddSpeed: false,
    }

    componentDidMount(){
        this.props.getDataPriceAllFunction();
        this.props.getChenhLech();
    }

    openModalEditPrice = (data = null) => {
        if (data !== null) {
            store.dispatch({
                type: 'DATA_ITEM_PRICE_FUNCTION_SELECT',
                payload: data
            });
        }

        this.setState({
            ...this.state,
            isOpenModal: !this.state.isOpenModal
        });
    }

    openModalAddPrice = () => {
        this.setState({
            ...this.state,
            isOpenModalAdd: !this.state.isOpenModalAdd
        });
    }

    openModalAddPriceSpeed = () => {
        this.setState({
            ...this.state,
            isOpenModalAddSpeed: !this.state.isOpenModalAddSpeed
        });
    }

    onChange = (e) => {
		this.props.updateChenhLech(e.target.name, e.target.value);
    }

    searchDataPrice = (e) => {
      if (e.key === 'Enter') {
        let value_search = e.target.value;
        this.props.getDataPriceAllFunction(value_search.trim());
      }
    }
	
	onKeyPress = (e) => {
		if (!(e.charCode >= 48 && e.charCode <= 57)) {
			e.preventDefault();
		}
	}

    render() {
        const {isOpenModal,isOpenModalAdd,isOpenModalAddSpeed} = this.state;
		function formatPriceApi(cell, row) {
			return (
				<span>{row.prices_api_min} - {row.prices_api_max}</span>
			);
		}
		function filterValuePriceApi(cell, row) {
			return (row.prices_api_min + ' - ' + row.prices_api_max);
		}
        const columns = [{
          dataField: 'id',
          text: 'STT',
          sort: true,
        },{
          dataField: 'name',
          text: 'Tên chức năng',
          sort: true
        },{
          dataField: 'prices_web',
          text: 'Giá website',
          sort: true,
          formatter: (cell, row, rowIndex) =>
            <span>{cell}</span>
        },{
          dataField: 'length_price_api',
          text: 'Giá api',
          sort: true,
		  formatter: formatPriceApi,
		  filterValue: filterValuePriceApi
        },{
          dataField: 'name_types_user',
          text: 'Loại tài khoản',
          sort: true,
        },{
          dataField: 'thao tac',
          text: 'Hành động',
          sort: true,
          formatter: (cell, row) => 
            <Fragment>
                <a onClick={(e) => {e.preventDefault();this.openModalEditPrice(row)}} href="/#" className="btn btn-hero-primary text-center p-2 mr-1">
                    <i className="fas fa-fw fa-pen"></i>
                </a>
                <a onClick={(e) => {e.preventDefault();this.props.deleteItemPriceFunc(row.id)}} href="/#" className="btn btn-hero-danger text-center p-2 mr-1">
                    <i className="fas fa-fw fa-times"></i>
                </a>
            </Fragment>
        }];
        
        const {listPriceAllFunction, chenhlech_5, chenhlech_5_20, chenhlech_20_50, chenhlech_50_100, chenhlech_100} = this.props.adminpage;

        return (
            <Fragment>
                {/*<div className="row">
                  <div className="col-md-9">
                    <h3 className="block-title mb-2">Danh sách chức năng</h3>
                  </div>
                  <div className="col-md-3">
                    <input onKeyDown={(e) => {this.searchDataPrice(e)}} placeholder="Tìm kiếm tên chức năng" className="form-control" />
                  </div>
                </div>
                <button onClick={() => {this.openModalAddPrice()}} type="button" className="btn btn-sm btn-success mr-1 mb-3"><i className="fa fa-fw fa-plus mr-1"></i> Thêm chức năng</button>*/}
				
				<ToolkitProvider keyField="id" data={ listPriceAllFunction } columns={ columns } search >
					{
						props => (
							<div>
								<SearchBar { ...props.searchProps } />
								<div className="row mb-4">
									<div className="col-md-12">
										<button onClick={() => {this.openModalAddPrice()}} type="button" className="btn btn-sm btn-success mr-1 mb-3"><i className="fa fa-fw fa-plus mr-1"></i> Thêm giá chức năng</button>
										<button onClick={() => {this.openModalAddPriceSpeed()}} type="button" className="btn btn-sm btn-danger mr-1 mb-3"><i className="fa fa-fw fa-plus mr-1"></i> Thêm giá chức năng (tự động)</button>
										<h6 className="text-danger" style={{"display": "inline-block", "verticalAlign": "super"}}>(Giá mới ở trình duyệt này sẽ hiển thị sau 5-10 phút, nếu muốn kiểm tra giá mới ngay hãy bật trình duyệt ẩn danh)</h6>
									</div>
								</div>
								<BootstrapTable
									{ ...props.baseProps }
									bodyStyle={ { border: 'none' } }
									headerStyle={ { border: 'red 1px solid' } }
									striped
									hover
									wrapperClasses="table-responsive"
									pagination={ paginationFactory() } />
							</div>
						)
					}
				</ToolkitProvider>
				
				<hr/>
				
                <div className="block block-rounded block-bordered">
					<div className="block-header block-header-default">
						<h3 className="block-title">Giá chênh lệch so với SITE CHÍNH của mua bán Fanpage/Group</h3>
					</div>
					<div className="block-content">
						<div className="row">
							<div className="col-md-4">
								<div className="form-group">
									<label>Fanpage/Group <span className="text-danger">dưới hoặc bằng</span> 500k coin:</label>
								</div>
							</div>
							<div className="col-md-6">
								<div className="form-group">
									<div className="input-group">
										<input type="number" name="chenhlech_5" value={chenhlech_5} onChange={(e) => {this.onChange(e)}} className="form-control" onKeyPress={this.onKeyPress} />
										<div className="input-group-append">
											<span className="input-group-text">
												Coin
											</span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-md-4">
								<div className="form-group">
									<label>Fanpage/Group <span className="text-success">trên</span> 500k coin đến <span className="text-danger">dưới hoặc bằng</span> 2tr coin:</label>
								</div>
							</div>
							<div className="col-md-6">
								<div className="form-group">
									<div className="input-group">
										<input type="number" name="chenhlech_5_20" value={chenhlech_5_20} onChange={(e) => {this.onChange(e)}} className="form-control" onKeyPress={this.onKeyPress} />
										<div className="input-group-append">
											<span className="input-group-text">
												Coin
											</span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-md-4">
								<div className="form-group">
									<label>Fanpage/Group <span className="text-success">trên</span> 2tr coin đến <span className="text-danger">dưới hoặc bằng</span> 5tr coin:</label>
								</div>
							</div>
							<div className="col-md-6">
								<div className="form-group">
									<div className="input-group">
										<input type="number" name="chenhlech_20_50" value={chenhlech_20_50} onChange={(e) => {this.onChange(e)}} className="form-control" onKeyPress={this.onKeyPress} />
										<div className="input-group-append">
											<span className="input-group-text">
												Coin
											</span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-md-4">
								<div className="form-group">
									<label>Fanpage/Group <span className="text-success">trên</span> 5tr coin đến <span className="text-danger">dưới hoặc bằng</span> 10tr coin:</label>
								</div>
							</div>
							<div className="col-md-6">
								<div className="form-group">
									<div className="input-group">
										<input type="number" name="chenhlech_50_100" value={chenhlech_50_100} onChange={(e) => {this.onChange(e)}} className="form-control" onKeyPress={this.onKeyPress} />
										<div className="input-group-append">
											<span className="input-group-text">
												Coin
											</span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-md-4">
								<div className="form-group">
									<label>Fanpage/Group <span className="text-success">trên</span> 10tr coin:</label>
								</div>
							</div>
							<div className="col-md-6">
								<div className="form-group">
									<div className="input-group">
										<input type="number" name="chenhlech_100" value={chenhlech_100} onChange={(e) => {this.onChange(e)}} className="form-control" onKeyPress={this.onKeyPress} />
										<div className="input-group-append">
											<span className="input-group-text">
												Coin
											</span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<button type="button" onClick={(e) => {this.props.saveChenhLech(chenhlech_5, chenhlech_5_20, chenhlech_20_50, chenhlech_50_100, chenhlech_100)}} className="btn btn-success mt-3 mb-3">Lưu Giá</button>
					</div>
				</div>

                {isOpenModal ? <ModalEditPriceFunction actionOpenModal={this.openModalEditPrice} isOpenModal={isOpenModal} /> : '' }
                <ModalAddPriceFunction actionOpenModal={this.openModalAddPrice} isOpenModal={isOpenModalAdd} />
                <ModalAddPriceSpeedFunction actionOpenModal={this.openModalAddPriceSpeed} isOpenModal={isOpenModalAddSpeed} />
            </Fragment>
        );
    }

}

const mapStateToProps = state => ({
    adminpage: state.adminpage,
});

export default connect(mapStateToProps, {getDataPriceAllFunction,deleteItemPriceFunc,saveChenhLech,getChenhLech,updateChenhLech}) (tag_setpricesfunc);
