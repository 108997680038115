import axios from 'axios';
import {
	LOAD_STFP,
	ADD_STFP,
	UPDATE_STFP,
	UPDATE_STFP_TAB,
	LOAD_PRICE_STFP,
	LIMIT_PAGI_STFP,
	TYPE_STFP,
	LOADING_STFP,
	LOADING_STFP_2,
	LOAD_NHATKYHOATDONG_STFP
} from "../actions/types";
import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Swal from 'sweetalert2';
import { loadUser } from "./authActions";

export const loadSTFP = (type = 0, limit = 0) => (dispatch, getState) => {
	dispatch({
		type: LOADING_STFP,
		payload: true
	});
	axios.get('/api/spotify?type_api=spotifyplay&type='+type+'&limit='+limit, tokenConfig(getState))
		.then(res => {
			dispatch({
				type: LOADING_STFP,
				payload: false
			});
			if (res.data.status === 200) {
				dispatch({
					type: LOAD_STFP,
					payload: res.data.data
				});
			}
		});
};

export const addSTFP = (body) => (dispatch, getState) => {
	dispatch({
		type: LOADING_STFP,
		payload: true
	});
	axios.post('/api/spotify/create', body, tokenConfig(getState))
		.then(res => {
			dispatch({
				type: LOADING_STFP,
				payload: false
			});
			dispatch(loadUser());
			if (res.data.status === 200) {
				Swal.fire({
					icon: 'success',
					html: 'Tạo tiến trình thành công'
				});
			} else {
				var message = '';
				if (res.data.message) {
					message = res.data.message;
				} else {
					message = res.data.error;
				}
				Swal.fire({
					icon: 'error',
					title: 'Lỗi',
					text: message,
				});
			}
		});
};

export const deleteSTFP = (id) => (dispatch, getState) => {
	let body = {
		id: id,
		'type_api': 'spotifyplay'
	};
	dispatch({
		type: LOADING_STFP,
		payload: true
	});
	axios.post('/api/spotify/delete', body, tokenConfig(getState))
		.then(res => {
			dispatch({
				type: LOADING_STFP,
				payload: false
			});
			dispatch(loadSTFP());
			if (res.data.status === 200) {
				Swal.fire({
					icon: 'success',
					html: res.data.message
				});
			} else {
				var message = '';
				if (res.data.error) {
					if (res.data.error.object_id) {
						message = res.data.error.object_id[0];
					} else if (res.data.error.quantity) {
						message = res.data.error.quantity[0];
					} else {
						message = res.data.message;
					}
				} else {
					message = res.data.message;
				}
				Swal.fire({
					icon: 'error',
					title: 'Lỗi',
					text: message,
				});
			}
		});
};

export const UpdateEdit = (status, count_success, dataid, closeM) => (dispatch, getState) => {
	let body = {
		id: dataid,
		status: status,
		count_success: count_success,
		'type_api': 'spotifyplay'
	};
	dispatch({
		type: LOADING_STFP,
		payload: true
	});
    axios.post('/api/spotify/updateedit', body, tokenConfig(getState))
		.then(res => {
			dispatch({
				type: LOADING_STFP,
				payload: false
			});
			closeM();
			if (res.data.status === 200) {
				dispatch(loadSTFP());
				Swal.fire({
					icon: 'success',
					html: 'Cập nhật thành công'
				});
			}
			if (res.data.status === 400) {
				var message = '';
				if (res.data.error) {
					if (res.data.error.object_id) {
						message = res.data.error.object_id[0];
					} else if (res.data.error.quantity) {
						message = res.data.error.quantity[0];
					}
				} else {
					message = res.data.message;
				}
				Swal.fire({
					icon: 'error',
					title: 'Lỗi',
					text: message,
				});
			}
		});
};

export const updateSTFP = (body) => (dispatch) => {
	dispatch({
		type: UPDATE_STFP,
		payload: body
	})
};

export const updateLimitSTFP = (body) => (dispatch) => {
	dispatch({
		type: LIMIT_PAGI_STFP,
		payload: body
	})
};

export const updateTypeSTFP = (body) => (dispatch) => {
	dispatch({
		type: TYPE_STFP,
		payload: body
	})
};

export const changeTab = (body) => (dispatch, getState) => {
	dispatch({
		type: UPDATE_STFP_TAB,
		payload: body
	});
};

export const getConfigMoDong = (type = '') => (dispatch, getState) => {
	dispatch({
		type: LOADING_STFP,
		payload: true
	});

	axios.get('/api/spotify/getconfig?type_api=spotifybuff_play', tokenConfig(getState))
	.then(res => {
		dispatch({
		type: LOADING_STFP,
			payload: false
		});
		if (res.data.status === 200) {
			dispatch({
				type: 'CONFIG_MO_DONG_STFP',
				payload: res.data
			});
		}
	});
}

export const tokenConfig = getState => {
	// Get token from localhost
	const token = getState().auth.token;
	// Headers
	const config = {
		headers: {
			"Content-type": "application/json"
		}
	};
	
	// If token, add to headers
	if (token) {
		config.headers['ht-token'] = token;
	}
	
	return config;
};