import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import Moment from 'react-moment';
import ModalAddPriceFunction from './modal/addpricefunction';
import paginationFactory from 'react-bootstrap-table2-paginator';
import store from '../../../store';

import ModalAddBank from './modal/addbankpayment';
import ModalEditBank from './modal/editbankpayment';

import { listBankPayment, editbankpayment, saveAddBankPayment} from '../../../actions/adminPageAction';

class tagBankPayment extends Component {
	state = {
        isOpenModalAdd: false,
        isOpenModalEdit: false,
    }
    saveConfig(){
      var adminConfig = this.props.item.adminConfig;
      this.props.saveConfig(adminConfig);
    }
    componentDidMount(){
        this.props.listBankPayment();
    }
    onChange = (e) =>{
      const { listbankpayment } = this.props.adminpage;
      var data = {
        ...listbankpayment,
        [e.target.name]: e.target.value
      }
      this.props.editbankpayment(data);
    }
    saveConfig(){
       const { listbankpayment } = this.props.adminpage;
      this.props.saveAddBankPayment(listbankpayment);
    }
    render() {
        const { listbankpayment } = this.props.adminpage;
        return (
            <Fragment>
              <div>
                <h2>Tài khoản ngân hàng</h2>
                <div style={{"padding":"0px 20px 0px 20px"}}>
                  <div className="form-group">
                    <label>Tên tài khoản</label>
                    <input className="form-control" name="tentaikhoan" value={listbankpayment.tentaikhoan} onChange={this.onChange} placeholder="Ví dụ: PHAN DUY LE" />
                  </div>
                  <div className="form-group">
                    <label>Số tài khoản</label>
                    <input className="form-control" name="sotaikhoan" value={listbankpayment.sotaikhoan} onChange={this.onChange} placeholder="Ví dụ: 1000212152100512" />
                  </div>
                  <div className="form-group">
                    <label>Ngân hàng</label>
                    <input className="form-control" name="nganhang" value={listbankpayment.nganhang} onChange={this.onChange} type="text" list="listDataBank" />
					<datalist onChange={this.onChange} id="listDataBank">
					  <option value="Vietcombank" />
					  <option value="Techcombank" />
					  <option value="Agribank" />
					  <option value="Ngân hàng Á châu, ACB" />
					  <option value="KienLongBank, KLB" />
					  <option value="HDBank, HDB" />
					  <option value="Ngân hàng Quốc tế, VIB" />
					  <option value="VietBank, VBB" />
					  <option value="Eximbank, EIB" />
					  <option value="Sacombank, STB" />
					  <option value="VietCapitalBank, BVB" />
					  <option value="Đông Á Bank" />
					  <option value="Nam A Bank" />
					  <option value="OCB" />
					  <option value="SCB" />
					  <option value="Saigonbank" />
					  <option value="TPBank, TPB" />
					  <option value="BacABank, BAB" />
					  <option value="National Citizen Bank, NVB" />
					  <option value="VPBank, VPB" />
					  <option value="Ngân hàng Sài Gòn-Hà Nội, SHB" />
					  <option value="LienVietPostBank, LPB" />
					  <option value="Military Bank, MBB" />
					  <option value="BIDV, BID" />
					  <option value="SeABank" />
					  <option value="ABBANK" />
					  <option value="MSB" />
					  <option value="VietABank" />
					  <option value="PVcombank" />
					  <option value="BaoVietBank" />
					  <option value="PG Bank" />
					  <option value="MB Bank" />
					  <option value="VietinBank, CTG" />
					</datalist>
                  </div>
                  <div className="form-group">
                    <label>Tỉ lệ <span className="text-danger">(giá trị là 1 nghĩa là khi bạn nạp cho ctv 100k tk ctv sẽ đc nhận 100k. còn bạn set giá trị là: 1.1 thì ctv sẽ đc nhận 110k trong đó sẽ đc cộng thêm 10% khuyến mãi)</span></label>
                    <input className="form-control" name="tile" value={listbankpayment.tile} onChange={this.onChange} />
                  </div>
                  <div className="form-group">
                    <label>Chi nhánh ngân hàng</label>
                    <input className="form-control" name="address_bank" value={listbankpayment.address_bank} onChange={this.onChange} placeholder="Ví dụ: Đội cấn - Ba đình" />
                  </div>
                  <div className="form-group">
                    <label>Nội dung chuyển khoản</label>
                    <textarea className="form-control" name="noidung" value={listbankpayment.noidung} onChange={this.onChange} placeholder="Ví dụ: naptien {user_name}"></textarea>
                  </div>
                  <div className="form-group">
                    <label>Số tiền nạp tối thiểu</label>
                    <input className="form-control" name="minmoney" value={listbankpayment.minmoney} onChange={this.onChange} placeholder="50000" />
                  </div>
                  {/*<div className="form-group">
                    <label>Số điện thoại đăng ký VCB</label>
                    <textarea className="form-control" name="data_banking" value={listbankpayment.data_banking} onChange={this.onChange}></textarea>
                  </div>
                  <div className="form-group">
                    <label>TOKEN TÀI KHOẢN VCB (LOGIN SITE VCB F12 RỒI LẤY)</label>
                    <textarea className="form-control" name="url_banking" value={listbankpayment.url_banking} onChange={this.onChange}></textarea>
                  </div>
                  <div className="form-group">
                    <label>Cookie banking</label>
                    <textarea className="form-control" name="cookie_banking" value={listbankpayment.cookie_banking} onChange={this.onChange}></textarea>
                  </div>*/}
                </div>
              </div>
              <div>
                <h2>Tài khoản Momo</h2>
                <div style={{"padding":"0px 20px 0px 20px"}}>
                  <div className="form-group">
                    <label>Tên tài khoản</label>
                    <input className="form-control" name="tentaikhoan_momo" value={listbankpayment.tentaikhoan_momo} onChange={this.onChange} placeholder="Ví dụ: PHAN DUY LE" />
                  </div>
                  <div className="form-group">
                    <label>Số điện thoại</label>
                    <input className="form-control" name="phone_momo" value={listbankpayment.phone_momo} onChange={this.onChange} placeholder="Ví dụ: 0964926085" />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <button className="btn btn-success" onClick={()=>{this.saveConfig()}}>Lưu</button>
                 <button className="btn btn-primary" onClick={()=>{this.saveConfig()}} style={{"marginLeft":"5px","display":"none"}}>Check payment</button>
              </div>
            </Fragment>
        );
    }

}

const mapStateToProps = state => ({
    adminpage: state.adminpage,
});

export default connect(mapStateToProps, {listBankPayment, editbankpayment, saveAddBankPayment}) (tagBankPayment);
