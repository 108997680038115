import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Swal from 'sweetalert2';
import copy from 'copy-text-to-clipboard';

class Loccsv extends Component {
	state = {
		loaibotrunglap: true,
		dongdautien: true,
		noidung: '"id";"name"\n"1";"What The"\n"12";"It is"',
		text_placeholder: '"id";"name"\n"1";"What The"\n"12";"It is"',
		cotdulieu: [
			"id",
			"name"
		],
		"cotdulieu-0": true,
		"cotdulieu-1": true,
		"cotdulieu-all": true,
		dieukien: [{
			"dieukien-0": "0",
			"dieukien-1": "1",
			"dieukien-2": "",
		}],
		ketqua: "",
	}
	componentDidMount() {
		// var { noidung } = this.state;
		// if (noidung != "") {
			// noidung = noidung.split("\n");
			// this.setState({
				// cotdulieu: noidung[0].split(";")
			// });
		// }
	};
	onChangeTrungLap = (e) =>{
		this.setState({
			[e.target.name]: !this.state.loaibotrunglap
		})
	}
	onChangeDongDauTien = (e) =>{
		this.setState({
			[e.target.name]: !this.state.dongdautien
		})
	}
	onClick = (e) =>{
		this.setState({
			[e.target.name]: e.target.value
		})
	}
	onClickDieuKien = (e) =>{
		var { dieukien } = this.state;
		this.setState({
			dieukien: [
				...dieukien,
				{
					"dieukien-0": "0",
					"dieukien-1": "1",
					"dieukien-2": "",
				}
			]
		})
	}
	onClickDeleteDieuKien = (e) =>{
		var { dieukien } = this.state;
		delete dieukien[e.target.getAttribute('data-index')];
		dieukien = dieukien.filter(item => item);
		this.setState({
			dieukien
		})
	}
	onChangeDieuKien = (e) =>{
		var { dieukien } = this.state;
		var new_dieukien = dieukien[e.target.getAttribute('data-index')];
		new_dieukien[e.target.name] = e.target.value;
		dieukien[e.target.getAttribute('data-index')] = new_dieukien;
		this.setState({
			dieukien
		})
	}
	onChange = (e) =>{
		var { cotdulieu, noidung } = this.state;
		var that = this;
		if (e.target.name == "noidung") {
			if (e.target.value != "") {
				var noidung_tmp = e.target.value.split("\n");
				noidung_tmp[0].split(";").forEach(function(v, i) {
					var obj = {["cotdulieu-"+i]: true};
					that.setState(obj);
				});
				this.setState({
					cotdulieu: noidung_tmp[0].split(";"),
					[e.target.name]: e.target.value
				});
			} else {
				this.setState({
					cotdulieu: [],
					[e.target.name]: e.target.value
				});
			}
		} else if (e.target.name == "cotdulieu-all") {
			var arr = {};
			var noidung = noidung.split("\n");
			noidung[0].split(";").forEach(function(v, i) {
				arr = {
					...arr,
					["cotdulieu-"+i]: e.target.checked
				};
			});
			this.setState({
				...arr,
				[e.target.name]: e.target.checked
			})
		} else if (e.target.name.indexOf("cotdulieu-") > -1) {
			var check_all = true;
			if (!e.target.checked) {
				check_all = false;
			} else {
				var noidung = noidung.split("\n");
				noidung[0].split(";").forEach(function(v, i) {
					if ((that.state["cotdulieu-"+i] == false) && (("cotdulieu-"+i) != e.target.name)) {
						check_all = false;
					}
				});
			}
			this.setState({
				"cotdulieu-all": check_all,
				[e.target.name]: e.target.checked
			})
		} else {
			this.setState({
				[e.target.name]: e.target.value
			})
		}
	}
	onTrigger = (e) => {
		var { loaibotrunglap, noidung, dongdautien, dieukien, cotdulieu } = this.state;
		var that = this;
		noidung = noidung.trim();
		noidung = noidung
			.split("\n")
			.filter(item => item)
			.join("\n");
		if (loaibotrunglap) {
			noidung = noidung
				.split("\n")
				.filter((item, i, allItems) => {
					return i === allItems.indexOf(item);
				})
				.join("\n");
		}
		
		var final_noidung_tmp = noidung.split("\n");
		var final_noidung = [];
		final_noidung_tmp.forEach(function(v, i) {
			if (dongdautien && i == 0) {
				final_noidung.push(v);
				return;
			}
			var v_tmp = v.split(";");
			var passed = 0;
			v_tmp.forEach(function(v2, i2) {
				v2 = v2.replace(/^\"+|\"+$/g, "");
				dieukien.forEach(function(v3, i3) {
					if (v3["dieukien-0"] == i2) {
						if (v3["dieukien-2"] == "") {
							passed++;
							return;
						}
						if (v3["dieukien-1"] == 1) {
							if (v2 == v3["dieukien-2"]) {
								passed++;
							}
						}
						if (v3["dieukien-1"] == 2) {
							if (v2 != v3["dieukien-2"]) {
								passed++;
							}
						}
						if (v3["dieukien-1"] == 3) {
							if (parseFloat(v2) < parseFloat(v3["dieukien-2"])) {
								passed++;
							}
						}
						if (v3["dieukien-1"] == 4) {
							if (parseFloat(v2) > parseFloat(v3["dieukien-2"])) {
								passed++;
							}
						}
						if (v3["dieukien-1"] == 5) {
							if (parseFloat(v2) <= parseFloat(v3["dieukien-2"])) {
								passed++;
							}
						}
						if (v3["dieukien-1"] == 6) {
							if (parseFloat(v2) >= parseFloat(v3["dieukien-2"])) {
								passed++;
							}
						}
						if (v3["dieukien-1"] == 7) {
							if (v2.indexOf(v3["dieukien-2"]) > -1) {
								passed++;
							}
						}
						if (v3["dieukien-1"] == 8) {
							if (v2.indexOf(v3["dieukien-2"]) < 0) {
								passed++;
							}
						}
						if (v3["dieukien-1"] == 9) {
							var regex_str = v3["dieukien-2"];
							var matches = new RegExp(regex_str,"g");
							var matches_dieukien = v2.match(matches);
							if (matches_dieukien !== null) {
								if (matches_dieukien.length > 0) {
									passed++;
								}
							}
						}
					}
				});
			});
			if (dieukien.length == passed) {
				var new_v = [];
				cotdulieu.forEach(function(v4, i4) {
					if (that.state["cotdulieu-"+i4]) {
						if (typeof v_tmp[i4] !== "undefined") {
							new_v.push(v_tmp[i4]);
						}
					}
				});
				final_noidung.push(new_v.join(";"));
			}
		});
		
		noidung = final_noidung.join("\n");
		
		this.setState({
			ketqua: noidung
		});
	}

	
	render() {
		var { cotdulieu, dieukien } = this.state;
		var that = this;
		var html_cotdulieu = (
			<Fragment>
				{
  					cotdulieu.map(function(value,index){
						return (
							<div className="custom-control custom-checkbox custom-control-inline" key={index}>
								<input onChange={(e) => {that.onChange(e)}} type="checkbox" className="custom-control-input" id={"cotdulieu-" + index} name={"cotdulieu-" + index} checked={that.state["cotdulieu-" + index]}/>
								<label className="custom-control-label edittext-custom-control-label" htmlFor={"cotdulieu-" + index} >{value.replace(/^\"+|\"+$/g, "")}</label>
							</div>
						);
  					})
  				}
			</Fragment>
		);
		var html_select = (
			<Fragment>
				{
  					cotdulieu.map(function(value,index){
						return (
							<option value={index} key={index}>{value.replace(/^\"+|\"+$/g, "")}</option>
						);
  					})
  				}
			</Fragment>
		);
		var html_dieukien = (
			<Fragment>
				{
					dieukien.length > 0 ?
						dieukien.map(function(value,index){
							return (
								<Fragment key={index}>
									{
										index != 0 ?
											<label className="col-sm-2 col-form-label edittext-label-right" htmlFor=""></label>
										:
											""
									}
									<div className="col-sm-10 row" style={{"marginBottom": "20px"}}>
										<div className="col-sm-2">
											<div className="input-group">
												<select className="form-control" name="dieukien-0" onChange={that.onChangeDieuKien} value={value["dieukien-0"]} data-index={index} >
													{html_select}
												</select>
											</div>
										</div>
										<div className="col-sm-2">
											<div className="input-group">
												<select className="form-control" name="dieukien-1" onChange={that.onChangeDieuKien} value={value["dieukien-1"]} data-index={index} >
													<option value="1">=</option>
													<option value="2">!=</option>
													<option value="3">{"<"}</option>
													<option value="4">{">"}</option>
													<option value="5">{"<="}</option>
													<option value="6">{">="}</option>
													<option value="7">chứa</option>
													<option value="8">không chứa</option>
													<option value="9">regex</option>
												</select>
											</div>
										</div>
										<div className="col-sm-2">
											<div className="input-group">
												<input type="text" className="form-control" name="dieukien-2" value={value["dieukien-2"]} onChange={that.onChangeDieuKien} data-index={index} />
											</div>
										</div>
										<div className="col-sm-4">
											<button type="button" className="btn btn-rounded btn-hero-primary edittext-btn mr-2" onClick={that.onClickDieuKien} >+</button>
											<button type="button" className="btn btn-rounded btn-hero-danger edittext-btn" onClick={that.onClickDeleteDieuKien} data-index={index} ><i className="fa fa-fw fa-trash"></i></button>
										</div>
									</div>
								</Fragment>
							);
						})
					:
						(
							<div className="col-sm-10 row">
								<div className="col-sm-2">
									<button type="button" className="btn btn-rounded btn-hero-primary edittext-btn" onClick={that.onClickDieuKien} >+</button>
								</div>
							</div>
						)
  				}
			</Fragment>
		);
		return (
			<Fragment>
				<style>
					{"\
						button > i {\
							pointer-events: none;\
						}\
					"}
				</style>
				<div className="form-group row">
					<label className="col-sm-2 col-form-label edittext-label-right" htmlFor="">Loại bỏ trùng lặp?</label>
					<div className="col-sm-10" style={{"display": "flex", "alignItems": "center"}}>
						<div className="custom-control custom-switch mb-1">
							<input onChange={(e) => {this.onChangeTrungLap(e)}} type="checkbox" className="custom-control-input" id="loaibotrunglap" name="loaibotrunglap" checked={this.state.loaibotrunglap} />
							<label className="custom-control-label edittext-custom-control-label" htmlFor="loaibotrunglap" ></label>
						</div>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-2 col-form-label edittext-label-right" htmlFor="">Dòng đầu tiên là Tiêu đề file CSV?</label>
					<div className="col-sm-10" style={{"display": "flex", "alignItems": "center"}}>
						<div className="custom-control custom-switch mb-1">
							<input onChange={(e) => {this.onChangeDongDauTien(e)}} type="checkbox" className="custom-control-input" id="dongdautien" name="dongdautien" checked={this.state.dongdautien} />
							<label className="custom-control-label edittext-custom-control-label" htmlFor="dongdautien" ></label>
						</div>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-2 col-form-label edittext-label-right" htmlFor="">Lấy cột dữ liệu nào?</label>
					<div className="col-sm-10" style={{"display": "flex", "alignItems": "center", "flexWrap": "wrap"}}>
						<div className="custom-control custom-checkbox custom-control-inline">
							<input onChange={(e) => {that.onChange(e)}} type="checkbox" className="custom-control-input" id={"cotdulieu-all"} name={"cotdulieu-all"} checked={that.state["cotdulieu-all"]}/>
							<label className="custom-control-label edittext-custom-control-label" htmlFor={"cotdulieu-all"} >Tất cả</label>
						</div>
						{html_cotdulieu}
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-2 col-form-label edittext-label-right" htmlFor="">Chỉ lấy các cột thỏa mã các điều kiện sau</label>
					{html_dieukien}
				</div>
				<div className="form-group row">
					<label className="col-sm-2 col-form-label edittext-label-right" htmlFor="">Nội dung file CSV</label>
					<div className="col-sm-10">
						<textarea rows="5" placeholder={this.state.text_placeholder} className="form-control input-gray edittext-textarea" name="noidung" onChange={this.onChange} value={this.state.noidung} ></textarea>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-2 col-form-label edittext-label-right" htmlFor=""></label>
					<div className="col-sm-10">
						<button type="button" className="btn btn-rounded btn-hero-primary edittext-btn" onClick={this.onTrigger} >Trigger</button>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-2 col-form-label edittext-label-right" htmlFor=""></label>
					<div className="col-sm-10">
						<textarea rows="5" placeholder="" className="form-control input-gray edittext-textarea" name="ketqua" defaultValue={this.state.ketqua} ></textarea>
					</div>
				</div>
			</Fragment>
		);
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
});
const mapDispatchToProps = {  };

export default connect(mapStateToProps, mapDispatchToProps)(Loccsv);
