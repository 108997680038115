import {
	LOAD_MUABANFANPAGEGROUP,
	LOAD_BUY_MUABANFANPAGEGROUP,
	UPDATE_MUABANFANPAGEGROUP_TAB,
	UPDATE_MUABANFANPAGEGROUP,
	LOADING_MUABANFANPAGEGROUP,
	LIMIT_PAGI_MUABANFANPAGEGROUP,
	TYPE_MUABANFANPAGEGROUP,
} from "../actions/types";

const date7dnext = () => {
	var result = new Date();
  	result.setDate(result.getDate() + 7);
  	return result;
}

const initialState = {
	"taotientrinh": {
		"id": "",
	},
	"changetab": 0,
	"listtientrinh": [],
	"listtientrinh_buy": [],
	"loading": false,
	"limit_pagi": 0,
	"type": 0,
	"list_nhatky":"",
};

export default function(state = initialState, action) {
	switch (action.type) {
		case LOAD_MUABANFANPAGEGROUP:
			return {
				...state,
				"listtientrinh": action.payload
			};
		case LOAD_BUY_MUABANFANPAGEGROUP:
			return {
				...state,
				"listtientrinh_buy": action.payload
			};
		case UPDATE_MUABANFANPAGEGROUP_TAB:
			return {
				...state,
				"changetab": action.payload
			};
		case UPDATE_MUABANFANPAGEGROUP:
			return {
				...state,
				"taotientrinh": action.payload
			};
		case LOADING_MUABANFANPAGEGROUP:
			return {
				...state,
				"loading": action.payload
			};
		case LIMIT_PAGI_MUABANFANPAGEGROUP:
			return {
				...state,
				"limit_pagi": action.payload
			};
		case TYPE_MUABANFANPAGEGROUP:
			return {
				...state,
				"type": action.payload
			};
		default:
		  return state;
	}
}
