import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Swal from 'sweetalert2';
import copy from 'copy-text-to-clipboard';
// import LichSu from "./tabMuabanclone/lichsu";

import { getIDFacebook } from '../../actions/findmyidActions';
class FindMyId extends Component {
	state = {
		link_fb: ''
	}
	onChange = (e) =>{
		this.setState({
			[e.target.name]:e.target.value
		})
	}
	findId(){
		this.props.getIDFacebook(this.state.link_fb);
	}
	copyID(id) {
		copy(id);
		Swal.fire({
			icon: 'success',
			title: '',
			text: 'Đã copy ID',
		});
		return false;
	}
	render() {
		const findmyid = this.props.findmyid;
		var text = '';
		if (findmyid.id_fb !== '') {
			text = (<div className="form-group" style={{"display":"flex","justifyContent":"center"}}>
							<div className="input-group mb-3" style={{"width":"50%"}}>
						    <input type="text" className="form-control" value={findmyid.id_fb} disabled id="myInput"/>
						    <div className="input-group-append">
						      <span className="input-group-text" style={{"background":"#4CAF50","color":"white"}}  onClick={()=>{this.copyID(findmyid.id_fb)}}>Copy</span>
						    </div>
						  </div>
						</div>)
		}
		return (
			<Fragment>
				<div className={"block block-bordered shadow"+ (findmyid.loadding? ' block-mode-loading' : '')}>
					<div className="block-header block-header-default">
						<h3 className="block-title">Find Id</h3>
					</div>
					<div className="block-content">
						<div className="form-group">
							<label>Link facebook,instagram muốn lấy id:</label>
							<input className="form-control" name="link_fb" onChange={this.onChange}/>
						</div>
						<div className="form-group"  style={{"textAlign":"center"}}>
							<button className="btn btn-primary" style={{"width":"35%"}} onClick={()=>{this.findId()}}>FIND ID</button>
						</div>
						{text}
					</div>
				</div>
			</Fragment>
		);
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	findmyid: state.findmyid,
});
const mapDispatchToProps = { getIDFacebook };

export default connect(mapStateToProps, mapDispatchToProps)(FindMyId);
