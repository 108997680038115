import {
	LOAD_YTNS,
	UPDATE_YTNS,
	UPDATE_YTNS_TAB,
	LOAD_PRICE_YTNS,
	LIMIT_PAGI_YV,
	TYPE_YTNS,
	LOADING_YTNS,
} from "../actions/types";

const date7dnext = () => {
	var result = new Date();
  	result.setDate(result.getDate() + 7);
  	return result;
}

const initialState = {
	"taotientrinh": {
		"lhibv": "",
		"mail": "1",
		"gtmtt": "0",
		"gc": "",
	},
	"changetab": 0,
	"listtientrinh": [],
	// "tanggiathem": {
		// "id": "",
		// "lhibv": "",
		// "price_per": 0,
		// "price": 0
	// },
	"price": {
		youtubetichnghesi: {
			min_bh_web: 0,
			prices_web: 0,
		},
	},
	"limit_pagi": 0,
	"type": 0,
	"loading": false,
	youtubetichnghesi_mo_dong: 1,
};

export default function(state = initialState, action) {
	switch (action.type) {
		case LOAD_YTNS:
			return {
				...state,
				"listtientrinh": action.payload
			};
		case UPDATE_YTNS:
			return {
				...state,
				"taotientrinh": action.payload
			}
		case UPDATE_YTNS_TAB:
			return {
				...state,
				"changetab": action.payload
			};
		case LOAD_PRICE_YTNS:
			return {
				...state,
				"price": merDataPricePage(action.payload),
			};
		case LIMIT_PAGI_YV:
			return {
				...state,
				"limit_pagi": action.payload
			};
		case TYPE_YTNS:
			return {
				...state,
				"type": action.payload
			};
		case LOADING_YTNS:
			return {
				...state,
				"loading": action.payload
			};
		case 'CONFIG_MO_DONG_YTNS':
			return {
				...state,
				youtubetichnghesi_mo_dong: action.payload.youtubetichnghesi_mo_dong,
			};
		default:
		  return state;
	}
}

function merDataPricePage(data) {
	let dataNew = {
		...initialState.price
	}
	if (data.length > 0) {
		data.forEach(function(v, i){
			if (v.name_table === 'youtubetichnghesi') {
				initialState.taotientrinh.gtmtt = v.prices_web;
				dataNew['youtubetichnghesi'] = v;
			}
		});
	};
	return dataNew;
}
