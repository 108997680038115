export const GET_ITEMS = "GET_ITEMS";
export const ADD_ITEMS = "ADD_ITEMS";
export const DELETE_ITEMS = "DELETE_ITEMS";
export const USER_LOADING = "USER_LOADING";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const GET_ERRORS = "GET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const GET_DATA_USER = "GET_DATA_USER";
export const LOAD_BMLS = "LOAD_BMLS";
export const ADD_BMLS = "ADD_BMLS";
export const UPDATE_BMLS = "UPDATE_BMLS";
export const LOAD_BSR = "LOAD_BSR";
export const ADD_BSR = "ADD_BSR";
export const UPDATE_BSR = "UPDATE_BSR";
export const UPDATE_BSR_TGT = "UPDATE_BSR_TGT";
export const UPDATE_BSR_GETID = "UPDATE_BSR_GETID";
export const UPDATE_BSR_TAB = "UPDATE_BSR_TAB";
export const LOAD_BSLIO = "LOAD_BSLIO";
export const UPDATE_BSLIO = "UPDATE_BSLIO";
export const UPDATE_BSLIO_TAB = "UPDATE_BSLIO_TAB";
export const LOAD_BSCIO = "LOAD_BSCIO";
export const UPDATE_BSCIO = "UPDATE_BSCIO";
export const UPDATE_BSCIO_TAB = "UPDATE_BSCIO_TAB";
export const LOAD_YV = "LOAD_YV";
export const UPDATE_YV = "UPDATE_YV";
export const UPDATE_YV_TAB = "UPDATE_YV_TAB";
export const LOAD_YC = "LOAD_YC";
export const UPDATE_YC = "UPDATE_YC";
export const UPDATE_YC_TAB = "UPDATE_YC_TAB";
export const LOAD_VBLT = "LOAD_VBLT";
export const UPDATE_VBLT = "UPDATE_VBLT";
export const UPDATE_VBLT_TAB = "UPDATE_VBLT_TAB";
export const UPDATE_VBLT_GETID = "UPDATE_VBLT_GETID";
export const LOAD_VLT = "LOAD_VLT";
export const UPDATE_VLT = "UPDATE_VLT";
export const UPDATE_VLT_TAB = "UPDATE_VLT_TAB";
export const UPDATE_VLT_GETID = "UPDATE_VLT_GETID";
export const CHANGE_SIDEBAR = "CHANGE_SIDEBAR";
export const CHANGE_MENUPROFILE = "CHANGE_MENUPROFILE";
export const CHANGE_MENUNOTI = "CHANGE_MENUNOTI";
export const NOTI_LOADED = "NOTI_LOADED";
export const CHANGE_OPENFBBUFF = "CHANGE_OPENFBBUFF";
export const CHANGE_OPENFBVIP = "CHANGE_OPENFBVIP";
export const CHANGE_OPENFB = "CHANGE_OPENFB";
export const CHANGE_OPENFBBOT = "CHANGE_OPENFBBOT";
export const CHANGE_OPENINSTAGRAM = "CHANGE_OPENINSTAGRAM";
export const CHANGE_OPENYOUTUBE = "CHANGE_OPENYOUTUBE";
export const CHANGE_OPENSHOPEE = "CHANGE_OPENSHOPEE";
export const CHANGE_OPENTIKTOK = "CHANGE_OPENTIKTOK";
export const CHANGE_CLOSEALL = "CHANGE_CLOSEALL";
export const LOAD_MBC = "LOAD_MBC";
export const UPDATE_MBC = "UPDATE_MBC";
export const LIST_MBC = "LIST_MBC";
export const LOAD_FBB = "LOAD_FBB";
export const UPDATE_FBB_TAB = "UPDATE_FBB_TAB";
export const UPDATE_FBB = "UPDATE_FBB";
export const LOAD_FVC = "LOAD_FVC";
export const UPDATE_FVC_TAB = "UPDATE_FVC_TAB";
export const UPDATE_FVC = "UPDATE_FVC";
export const LOAD_FVVV = "LOAD_FVVV";
export const UPDATE_FVVV_TAB = "UPDATE_FVVV_TAB";
export const UPDATE_FVVV = "UPDATE_FVVV";
export const LOAD_FLBBKTT = "LOAD_FLBBKTT";
export const UPDATE_FLBBKTT_TAB = "UPDATE_FLBBKTT_TAB";
export const UPDATE_FLBBKTT = "UPDATE_FLBBKTT";
export const UPDATE_COOKIE_FBB = "UPDATE_COOKIE_FBB";
export const LOAD_PRICE_BSR = "LOAD_PRICE_BSR";
export const LOAD_PRICE_BSCIO = "LOAD_PRICE_BSCIO";
export const LOAD_PRICE_BSLIO = "LOAD_PRICE_BSLIO";
export const LOAD_PRICE_YV = "LOAD_PRICE_YV";
export const LOAD_PRICE_YC = "LOAD_PRICE_YC";
export const LOAD_PRICE_VLT = "LOAD_PRICE_VLT";
export const LOAD_PRICE_VBLT = "LOAD_PRICE_VBLT";
export const LOADING_FBB = "LOADING_FBB";
export const LOAD_PRICE_FVC = "LOAD_PRICE_FVC";
export const LOAD_PRICE_FVVV = "LOAD_PRICE_FVVV";
export const LOAD_PRICE_FLBBKTT = "LOAD_PRICE_FLBBKTT";
export const LOAD_PRICE_FBB = "LOAD_PRICE_FBB";
export const UPDATE_BMLS_TAB = "UPDATE_BMLS_TAB";
export const LOAD_PRICE_BMLS = "LOAD_PRICE_BMLS";
export const LOAD_FBRIC = "LOAD_FBRIC";
export const UPDATE_FBRIC_TAB = "UPDATE_FBRIC_TAB";
export const UPDATE_FBRIC = "UPDATE_FBRIC";
export const LOAD_PRICE_FBRIC = "LOAD_PRICE_FBRIC";
export const LOAD_FVMLS = "LOAD_FVMLS";
export const UPDATE_FVMLS_TAB = "UPDATE_FVMLS_TAB";
export const UPDATE_FVMLS = "UPDATE_FVMLS";
export const LOAD_PRICE_FVMLS = "LOAD_PRICE_FVMLS";
export const LOAD_FBBV = "LOAD_FBBV";
export const UPDATE_FBBV_TAB = "UPDATE_FBBV_TAB";
export const UPDATE_FBBV = "UPDATE_FBBV";
export const UPDATE_COOKIE_FBBV = "UPDATE_COOKIE_FBBV";
export const LOADING_FBBV = "LOADING_FBBV";
export const LOAD_PRICE_FBBV = "LOAD_PRICE_FBBV";
export const LOADING_GCF = "LOADING_GCF";
export const UPDATE_COOKIE_GCF = "UPDATE_COOKIE_GCF";
export const LOAD_PROXY_GCF = "LOAD_PROXY_GCF";
export const LOAD_FBBC = "LOAD_FBBC";
export const UPDATE_FBBC_TAB = "UPDATE_FBBC_TAB";
export const UPDATE_FBBC = "UPDATE_FBBC";
export const UPDATE_COOKIE_FBBC = "UPDATE_COOKIE_FBBC";
export const LOADING_FBBC = "LOADING_FBBC";
export const LOAD_PRICE_FBBC = "LOAD_PRICE_FBBC";
export const LOAD_PROXY_FBBC = "LOAD_PROXY_FBBC";
export const LOAD_PROXY_EDIT_FBBC = "LOAD_PROXY_EDIT_FBBC";
export const LOADING_FLBBKTT = "LOADING_FLBBKTT";
export const LOADING_FBRIC = "LOADING_FBRIC";
export const LIMIT_PAGI_BSR = "LIMIT_PAGI_BSR";
export const TYPE_BSR = "TYPE_BSR";
export const LIMIT_PAGI_BMLS = "LIMIT_PAGI_BMLS";
export const TYPE_BMLS = "TYPE_BMLS";
export const LIMIT_PAGI_FLBBKTT = "LIMIT_PAGI_FLBBKTT";
export const TYPE_FLBBKTT = "TYPE_FLBBKTT";
export const LIMIT_PAGI_FBB = "LIMIT_PAGI_FBB";
export const TYPE_FBB = "TYPE_FBB";
export const LIMIT_PAGI_FBBV = "LIMIT_PAGI_FBBV";
export const TYPE_FBBV = "TYPE_FBBV";
export const LIMIT_PAGI_FBBC = "LIMIT_PAGI_FBBC";
export const TYPE_FBBC = "TYPE_FBBC";
export const LIMIT_PAGI_FBRIC = "LIMIT_PAGI_FBRIC";
export const TYPE_FBRIC = "TYPE_FBRIC";
export const LIMIT_PAGI_BSCIO = "LIMIT_PAGI_BSCIO";
export const TYPE_BSCIO = "TYPE_BSCIO";
export const LIMIT_PAGI_BSLIO = "LIMIT_PAGI_BSLIO";
export const TYPE_BSLIO = "TYPE_BSLIO";
export const LIMIT_PAGI_YC = "LIMIT_PAGI_YC";
export const TYPE_YC = "TYPE_YC";
export const LIMIT_PAGI_YV = "LIMIT_PAGI_YV";
export const TYPE_YV = "TYPE_YV";
export const LIMIT_PAGI_VBLT = "LIMIT_PAGI_VBLT";
export const TYPE_VBLT = "TYPE_VBLT";
export const LIMIT_PAGI_VLT = "LIMIT_PAGI_VLT";
export const TYPE_VLT = "TYPE_VLT";
export const LIMIT_PAGI_FVC = "LIMIT_PAGI_FVC";
export const TYPE_FVC = "TYPE_FVC";
export const LIMIT_PAGI_FVVV = "LIMIT_PAGI_FVVV";
export const TYPE_FVVV = "TYPE_FVVV";
export const LIMIT_PAGI_FVMLS = "LIMIT_PAGI_FVMLS";
export const TYPE_FVMLS = "TYPE_FVMLS";
export const LOAD_NHATKYHOATDONG_FBBC = "LOAD_NHATKYHOATDONG_FBBC";
export const LOAD_NHATKYHOATDONG_FBBV = "LOAD_NHATKYHOATDONG_FBBV";
export const LOAD_SHOPEE = "LOAD_SHOPEE";
export const UPDATE_SHOPEE_TAB = "UPDATE_SHOPEE_TAB";
export const LOAD_PRICE_SHOPEE = "LOAD_PRICE_SHOPEE";
export const UPDATE_SHOPEE = "UPDATE_SHOPEE";
export const LOADING_SHOPEE = "LOADING_SHOPEE";
export const LIMIT_PAGI_SHOPEE = "LIMIT_PAGI_SHOPEE";
export const TYPE_SHOPEE = "TYPE_SHOPEE";
export const UPDATE_PRICE_BAOHANH_SHOPEE = "UPDATE_PRICE_BAOHANH_SHOPEE";
export const LOAD_NHATKYHOATDONG_FVMLS = "LOAD_NHATKYHOATDONG_FVMLS";
export const LOADING_VLT = "LOADING_VLT";
export const LOADING_VBLT = "LOADING_VBLT";
export const LOADING_FVC = "LOADING_FVC";
export const LOADING_FVVV = "LOADING_FVVV";
export const LOADING_FVMLS = "LOADING_FVMLS";
export const LOADING_YC = "LOADING_YC";
export const LOADING_YV = "LOADING_YV";
export const LOADING_BSCIO = "LOADING_BSCIO";
export const LOADING_BSLIO = "LOADING_BSLIO";
export const UPDATE_FVC_GETID = "UPDATE_FVC_GETID";
export const UPDATE_FVVV_GETID = "UPDATE_FVVV_GETID";
export const UPDATE_FVMLS_GETID = "UPDATE_FVMLS_GETID";
export const LOADING_BSR = "LOADING_BSR";
export const LOAD_BSLV = "LOAD_BSLV";
export const UPDATE_BSLV = "UPDATE_BSLV";
export const UPDATE_BSLV_GETID = "UPDATE_BSLV_GETID";
export const UPDATE_BSLV_TAB = "UPDATE_BSLV_TAB";
export const LOAD_PRICE_BSLV = "LOAD_PRICE_BSLV";
export const LOADING_BSLV = "LOADING_BSLV";
export const UPDATE_BSLV_TGT = "UPDATE_BSLV_TGT";
export const LOADING_BMLS = "LOADING_BMLS";
export const LOAD_PRICE_BG = "LOAD_PRICE_BG";
export const LOADING_BG = "LOADING_BG";
export const UPDATE_BG = "UPDATE_BG";
export const UPDATE_BG_GETID = "UPDATE_BG_GETID";
export const LOAD_BG = "LOAD_BG";
export const UPDATE_BG_TAB = "UPDATE_BG_TAB";
export const UPDATE_BG_TGT = "UPDATE_BG_TGT";
export const LOAD_SPAMSMS = "LOAD_SPAMSMS";
export const UPDATE_SPAMSMS_TAB = "UPDATE_SPAMSMS_TAB";
export const UPDATE_SPAMSMS = "UPDATE_SPAMSMS";
export const LIMIT_PAGI_SPAMSMS = "LIMIT_PAGI_SPAMSMS";
export const TYPE_SPAMSMS = "TYPE_SPAMSMS";
export const LOADING_SPAMSMS = "LOADING_SPAMSMS";
export const LOAD_PRICE_SPAMSMS = "LOAD_PRICE_SPAMSMS";
export const LOAD_PRICE_BGSL = "LOAD_PRICE_BGSL";
export const UPDATE_BGSL = "UPDATE_BGSL";
export const LOAD_BGSL = "LOAD_BGSL";
export const UPDATE_BGSL_TAB = "UPDATE_BGSL_TAB";
export const LOADING_BGSL = "LOADING_BGSL";
export const UPDATE_BGSL_TGT = "UPDATE_BGSL_TGT";
export const UPDATE_BSVIO = "UPDATE_BSVIO";
export const LOAD_PRICE_BSVIO = "LOAD_PRICE_BSVIO";
export const UPDATE_BSVIO_TAB = "UPDATE_BSVIO_TAB";
export const TYPE_BSVIO = "TYPE_BSVIO";
export const LOAD_BSVIO = "LOAD_BSVIO";
export const LOADING_BSVIO = "LOADING_BSVIO";
export const UPDATE_YL_TAB = "UPDATE_YL_TAB";
export const LOAD_PRICE_YL = "LOAD_PRICE_YL";
export const UPDATE_YL = "UPDATE_YL";
export const LOAD_YL = "LOAD_YL";
export const TYPE_YL = "TYPE_YL";
export const LOADING_YL = "LOADING_YL";
export const LOAD_SHOPEESEEDINGLIVESTREAM = "LOAD_SHOPEESEEDINGLIVESTREAM";
export const UPDATE_SHOPEESEEDINGLIVESTREAM = "UPDATE_SHOPEESEEDINGLIVESTREAM";
export const UPDATE_SHOPEESEEDINGLIVESTREAM_TAB = "UPDATE_SHOPEESEEDINGLIVESTREAM_TAB";
export const LOADING_SHOPEESEEDINGLIVESTREAM = "LOADING_SHOPEESEEDINGLIVESTREAM";
export const LOAD_PRICE_SHOPEESEEDINGLIVESTREAM = "LOAD_PRICE_SHOPEESEEDINGLIVESTREAM";
export const UPDATE_PRICE_BAOHANH_SHOPEESEEDINGLIVESTREAM = "UPDATE_PRICE_BAOHANH_SHOPEESEEDINGLIVESTREAM";
export const LOAD_SHOPEEVIPLIVESTREAM = "LOAD_SHOPEEVIPLIVESTREAM";
export const UPDATE_SHOPEEVIPLIVESTREAM = "UPDATE_SHOPEEVIPLIVESTREAM";
export const UPDATE_SHOPEEVIPLIVESTREAM_TAB = "UPDATE_SHOPEEVIPLIVESTREAM_TAB";
export const LOADING_SHOPEEVIPLIVESTREAM = "LOADING_SHOPEEVIPLIVESTREAM";
export const LOAD_PRICE_SHOPEEVIPLIVESTREAM = "LOAD_PRICE_SHOPEEVIPLIVESTREAM";
export const UPDATE_PRICE_BAOHANH_SHOPEEVIPLIVESTREAM = "UPDATE_PRICE_BAOHANH_SHOPEEVIPLIVESTREAM";
export const LOAD_SHOPEETIM = "LOAD_SHOPEETIM";
export const UPDATE_SHOPEETIM_TAB = "UPDATE_SHOPEETIM_TAB";
export const UPDATE_SHOPEETIM = "UPDATE_SHOPEETIM";
export const LOADING_SHOPEETIM = "LOADING_SHOPEETIM";
export const LOAD_PRICE_SHOPEETIM = "LOAD_PRICE_SHOPEETIM";
export const UPDATE_PRICE_BAOHANH_SHOPEETIM = "UPDATE_PRICE_BAOHANH_SHOPEETIM";
export const LOAD_PROXY_FBBV = "LOAD_PROXY_FBBV";
export const LOAD_PROXY_EDIT_FBBV = "LOAD_PROXY_EDIT_FBBV";
export const LOAD_RIPACC = "LOAD_RIPACC";
export const UPDATE_RIPACC_TAB = "UPDATE_RIPACC_TAB";
export const UPDATE_RIPACC = "UPDATE_RIPACC";
export const LOADING_RIPACC = "LOADING_RIPACC";
export const UPDATE_RIPACC_IDFB = "UPDATE_RIPACC_IDFB";
export const LOAD_PRICE_RIPACC = "LOAD_PRICE_RIPACC";
export const LIMIT_PAGI_RIPACC = "LIMIT_PAGI_RIPACC";
export const TYPE_RIPACC = "TYPE_RIPACC";
export const UPDATE_RIPACC_IDFB_LIENHE = "UPDATE_RIPACC_IDFB_LIENHE";
export const LOADING_MBC = "LOADING_MBC";
export const LOAD_BLSC = "LOAD_BLSC";
export const UPDATE_BLSC_GETID = "UPDATE_BLSC_GETID";
export const UPDATE_BLSC = "UPDATE_BLSC";
export const UPDATE_BLSC_TAB = "UPDATE_BLSC_TAB";
export const LOADING_BLSC = "LOADING_BLSC";
export const LOAD_PRICE_BLSC = "LOAD_PRICE_BLSC";
export const LOAD_PRICE_GM = "LOAD_PRICE_GM";
export const UPDATE_GM = "UPDATE_GM";
export const TYPE_GM = "TYPE_GM";
export const LOADING_GM = "LOADING_GM";
export const LOAD_GM = "LOAD_GM";
export const UPDATE_GM_TAB = "UPDATE_GM_TAB";
export const CHANGE_OPENGOOGLE = "CHANGE_OPENGOOGLE";
export const LOAD_PRICE_IVL = "LOAD_PRICE_IVL";
export const LOAD_IVL = "LOAD_IVL";
export const UPDATE_IVL = "UPDATE_IVL";
export const UPDATE_IVL_TAB = "UPDATE_IVL_TAB";
export const TYPE_IVL = "TYPE_IVL";
export const LOADING_IVL = "LOADING_IVL";
export const LOAD_PRICE_REPORTYOUTUBE = "LOAD_PRICE_REPORTYOUTUBE";
export const LOAD_REPORTYOUTUBE = "LOAD_REPORTYOUTUBE";
export const UPDATE_REPORTYOUTUBE = "UPDATE_REPORTYOUTUBE";
export const UPDATE_REPORTYOUTUBE_TAB = "UPDATE_REPORTYOUTUBE_TAB";
export const UPDATE_REPORTYOUTUBE_IDFB = "UPDATE_REPORTYOUTUBE_IDFB";
export const UPDATE_RIPACC_REPORTYOUTUBE_LIENHE = "UPDATE_RIPACC_REPORTYOUTUBE_LIENHE";
export const LIMIT_PAGI_REPORTYOUTUBE = "LIMIT_PAGI_REPORTYOUTUBE";
export const TYPE_REPORTYOUTUBE = "TYPE_REPORTYOUTUBE";
export const LOADING_REPORTYOUTUBE = "LOADING_REPORTYOUTUBE";
export const LOAD_PRICE_RNF = "LOAD_PRICE_RNF";
export const UPDATE_RNF = "UPDATE_RNF";
export const UPDATE_RNF_GETID = "UPDATE_RNF_GETID";
export const LOAD_RNF = "LOAD_RNF";
export const UPDATE_RNF_TAB = "UPDATE_RNF_TAB";
export const UPDATE_RNF_IDFB_LIENHE = "UPDATE_RNF_IDFB_LIENHE";
export const LOADING_RNF = "LOADING_RNF";
export const LOAD_NHATKYHOATDONG_BMLS = "LOAD_NHATKYHOATDONG_BMLS";
export const LOADING_BMLS_2 = "LOADING_BMLS_2";
export const LOAD_SPAMCALL = "LOAD_SPAMCALL";
export const UPDATE_SPAMCALL_TAB = "UPDATE_SPAMCALL_TAB";
export const UPDATE_SPAMCALL = "UPDATE_SPAMCALL";
export const LOAD_PRICE_SPAMCALL = "LOAD_PRICE_SPAMCALL";
export const LOADING_SPAMCALL = "LOADING_SPAMCALL";
export const TYPE_SPAMCALL = "TYPE_SPAMCALL";
export const LIMIT_PAGI_SPAMCALL = "LIMIT_PAGI_SPAMCALL";
export const LOAD_NHATKYHOATDONG_SPAMCALL = "LOAD_NHATKYHOATDONG_SPAMCALL";
export const LOAD_PRICE_BGT = "LOAD_PRICE_BGT";
export const UPDATE_BGT_TAB = "UPDATE_BGT_TAB";
export const UPDATE_BGT = "UPDATE_BGT";
export const LOAD_BGT = "LOAD_BGT";
export const LOADING_BGT = "LOADING_BGT";
export const UPDATE_BGT_IDFB_LIENHE = "UPDATE_BGT_IDFB_LIENHE";
export const LIMIT_PAGI_BGT = "LIMIT_PAGI_BGT";
export const TYPE_BGT = "TYPE_BGT";
export const CHANGE_OPENTELEGRAM = "CHANGE_OPENTELEGRAM";
export const TYPE_BGSL = "TYPE_BGSL";
export const LIMIT_PAGI_BGSL = "LIMIT_PAGI_BGSL";
export const TYPE_BG = "TYPE_BG";
export const LIMIT_PAGI_BG = "LIMIT_PAGI_BG";
export const TYPE_BLSC = "TYPE_BLSC";
export const LIMIT_PAGI_BLSC = "LIMIT_PAGI_BLSC";
export const TYPE_BSLV = "TYPE_BSLV";
export const LIMIT_PAGI_BSLV = "LIMIT_PAGI_BSLV";
export const TYPE_RNF = "TYPE_RNF";
export const LIMIT_PAGI_RNF = "LIMIT_PAGI_RNF";
export const LOAD_PRICE_BVS = "LOAD_PRICE_BVS";
export const UPDATE_BVS = "UPDATE_BVS";
export const LOADING_BVS = "LOADING_BVS";
export const LOAD_BVS = "LOAD_BVS";
export const UPDATE_BVS_TAB = "UPDATE_BVS_TAB";
export const LIMIT_PAGI_BVS = "LIMIT_PAGI_BVS";
export const TYPE_BVS = "TYPE_BVS";
export const UPDATE_STICKER = "UPDATE_STICKER";
export const LOAD_STICKER = "LOAD_STICKER";
export const UPDATE_LIST_STICKER = "UPDATE_LIST_STICKER";
export const LOAD_FBBLS = "LOAD_FBBLS";
export const UPDATE_FBBLS_TAB = "UPDATE_FBBLS_TAB";
export const UPDATE_FBBLS = "UPDATE_FBBLS";
export const UPDATE_COOKIE_FBBLS = "UPDATE_COOKIE_FBBLS";
export const LOADING_FBBLS = "LOADING_FBBLS";
export const LOAD_PRICE_FBBLS = "LOAD_PRICE_FBBLS";
export const LIMIT_PAGI_FBBLS = "LIMIT_PAGI_FBBLS";
export const TYPE_FBBLS = "TYPE_FBBLS";
export const LOAD_NHATKYHOATDONG_FBBLS = "LOAD_NHATKYHOATDONG_FBBLS";
export const LOAD_PROXY_FBBLS = "LOAD_PROXY_FBBLS";
export const LOAD_PROXY_EDIT_FBBLS = "LOAD_PROXY_EDIT_FBBLS";
export const LOAD_FVMLSV = "LOAD_FVMLSV";
export const UPDATE_FVMLSV_TAB = "UPDATE_FVMLSV_TAB";
export const UPDATE_FVMLSV = "UPDATE_FVMLSV";
export const LOAD_PRICE_FVMLSV = "LOAD_PRICE_FVMLSV";
export const LIMIT_PAGI_FVMLSV = "LIMIT_PAGI_FVMLSV";
export const TYPE_FVMLSV = "TYPE_FVMLSV";
export const LOAD_NHATKYHOATDONG_FVMLSV = "LOAD_NHATKYHOATDONG_FVMLSV";
export const LOADING_FVMLSV = "LOADING_FVMLSV";
export const UPDATE_FVMLSV_GETID = "UPDATE_FVMLSV_GETID";
export const LOAD_FABGX = "LOAD_FABGX";
export const ADD_FABGX = "ADD_FABGX";
export const UPDATE_FABGX = "UPDATE_FABGX";
export const UPDATE_FABGX_TAB = "UPDATE_FABGX_TAB";
export const LOAD_PRICE_FABGX = "LOAD_PRICE_FABGX";
export const LIMIT_PAGI_FABGX = "LIMIT_PAGI_FABGX";
export const TYPE_FABGX = "TYPE_FABGX";
export const LOADING_FABGX = "LOADING_FABGX";
export const LOADING_FABGX_2 = "LOADING_FABGX_2";
export const LOAD_NHATKYHOATDONG_FABGX = "LOAD_NHATKYHOATDONG_FABGX";
export const LOAD_FABTT = "LOAD_FABTT";
export const ADD_FABTT = "ADD_FABTT";
export const UPDATE_FABTT = "UPDATE_FABTT";
export const UPDATE_FABTT_TAB = "UPDATE_FABTT_TAB";
export const LOAD_PRICE_FABTT = "LOAD_PRICE_FABTT";
export const LIMIT_PAGI_FABTT = "LIMIT_PAGI_FABTT";
export const TYPE_FABTT = "TYPE_FABTT";
export const LOADING_FABTT = "LOADING_FABTT";
export const LOADING_FABTT_2 = "LOADING_FABTT_2";
export const LOAD_NHATKYHOATDONG_FABTT = "LOAD_NHATKYHOATDONG_FABTT";
export const LOAD_FABVDX = "LOAD_FABVDX";
export const ADD_FABVDX = "ADD_FABVDX";
export const UPDATE_FABVDX = "UPDATE_FABVDX";
export const UPDATE_FABVDX_TAB = "UPDATE_FABVDX_TAB";
export const LOAD_PRICE_FABVDX = "LOAD_PRICE_FABVDX";
export const LIMIT_PAGI_FABVDX = "LIMIT_PAGI_FABVDX";
export const TYPE_FABVDX = "TYPE_FABVDX";
export const LOADING_FABVDX = "LOADING_FABVDX";
export const LOADING_FABVDX_2 = "LOADING_FABVDX_2";
export const LOAD_NHATKYHOATDONG_FABVDX = "LOAD_NHATKYHOATDONG_FABVDX";
export const CHANGE_OPENFBADBREAKS = "CHANGE_OPENFBADBREAKS";
export const LOAD_YGX = "LOAD_YGX";
export const UPDATE_YGX = "UPDATE_YGX";
export const UPDATE_YGX_TAB = "UPDATE_YGX_TAB";
export const LOAD_PRICE_YGX = "LOAD_PRICE_YGX";
export const LOADING_YGX = "LOADING_YGX";
export const LIMIT_PAGI_YGX = "LIMIT_PAGI_YGX";
export const TYPE_YGX = "TYPE_YGX";
export const LOAD_TWL = "LOAD_TWL";
export const UPDATE_TWL = "UPDATE_TWL";
export const UPDATE_TWL_TAB = "UPDATE_TWL_TAB";
export const LOAD_PRICE_TWL = "LOAD_PRICE_TWL";
export const LIMIT_PAGI_TWL = "LIMIT_PAGI_TWL";
export const TYPE_TWL = "TYPE_TWL";
export const LOADING_TWL = "LOADING_TWL";
export const LOADING_TWL_2 = "LOADING_TWL_2";
export const LOAD_NHATKYHOATDONG_TWL = "LOAD_NHATKYHOATDONG_TWL";
export const LOAD_TWF = "LOAD_TWF";
export const UPDATE_TWF = "UPDATE_TWF";
export const UPDATE_TWF_TAB = "UPDATE_TWF_TAB";
export const LOAD_PRICE_TWF = "LOAD_PRICE_TWF";
export const LIMIT_PAGI_TWF = "LIMIT_PAGI_TWF";
export const TYPE_TWF = "TYPE_TWF";
export const LOADING_TWF = "LOADING_TWF";
export const LOADING_TWF_2 = "LOADING_TWF_2";
export const LOAD_NHATKYHOATDONG_TWF = "LOAD_NHATKYHOATDONG_TWF";
export const CHANGE_OPENTWITTER = "CHANGE_OPENTWITTER";
export const LOAD_SP = "LOAD_SP";
export const UPDATE_SP = "UPDATE_SP";
export const LIMIT_PAGI_SP = "LIMIT_PAGI_SP";
export const TYPE_SP = "TYPE_SP";
export const LOADING_SP = "LOADING_SP";
export const LOAD_EDIT_SP = "LOAD_EDIT_SP";
export const NOTI_SP_LOADED = "NOTI_SP_LOADED";
export const LOAD_EMAILTOUID = "LOAD_EMAILTOUID";
export const UPDATE_EMAILTOUID_TAB = "UPDATE_EMAILTOUID_TAB";
export const UPDATE_EMAILTOUID = "UPDATE_EMAILTOUID";
export const LOAD_PRICE_EMAILTOUID = "LOAD_PRICE_EMAILTOUID";
export const LOADING_EMAILTOUID = "LOADING_EMAILTOUID";
export const LIMIT_PAGI_EMAILTOUID = "LIMIT_PAGI_EMAILTOUID";
export const TYPE_EMAILTOUID = "TYPE_EMAILTOUID";
export const LOAD_NHATKYHOATDONG_EMAILTOUID = "LOAD_NHATKYHOATDONG_EMAILTOUID";
export const UPDATE_UID_EMAILTOUID = "UPDATE_UID_EMAILTOUID";
export const LOAD_MUABANFANPAGEGROUP = "LOAD_MUABANFANPAGEGROUP";
export const UPDATE_MUABANFANPAGEGROUP_TAB = "UPDATE_MUABANFANPAGEGROUP_TAB";
export const UPDATE_MUABANFANPAGEGROUP = "UPDATE_MUABANFANPAGEGROUP";
export const LOADING_MUABANFANPAGEGROUP = "LOADING_MUABANFANPAGEGROUP";
export const LIMIT_PAGI_MUABANFANPAGEGROUP = "LIMIT_PAGI_MUABANFANPAGEGROUP";
export const TYPE_MUABANFANPAGEGROUP = "TYPE_MUABANFANPAGEGROUP";
export const LOAD_BUY_MUABANFANPAGEGROUP = "LOAD_BUY_MUABANFANPAGEGROUP";
export const LOAD_MF = "LOAD_MF";
export const UPDATE_MF = "UPDATE_MF";
export const UPDATE_MF_GETID = "UPDATE_MF_GETID";
export const UPDATE_MF_TAB = "UPDATE_MF_TAB";
export const UPDATE_MF_IDFB_LIENHE = "UPDATE_MF_IDFB_LIENHE";
export const UPDATE_MF_IDFB_ADMIN = "UPDATE_MF_IDFB_ADMIN";
export const LOAD_PRICE_MF = "LOAD_PRICE_MF";
export const LIMIT_PAGI_MF = "LIMIT_PAGI_MF";
export const LOADING_MF = "LOADING_MF";
export const LOAD_STFF = "LOAD_STFF";
export const UPDATE_STFF = "UPDATE_STFF";
export const UPDATE_STFF_TAB = "UPDATE_STFF_TAB";
export const LOAD_PRICE_STFF = "LOAD_PRICE_STFF";
export const LIMIT_PAGI_STFF = "LIMIT_PAGI_STFF";
export const TYPE_STFF = "TYPE_STFF";
export const LOADING_STFF = "LOADING_STFF";
export const LOADING_STFF_2 = "LOADING_STFF_2";
export const LOAD_NHATKYHOATDONG_STFF = "LOAD_NHATKYHOATDONG_STFF";
export const LOAD_STFP = "LOAD_STFP";
export const UPDATE_STFP = "UPDATE_STFP";
export const UPDATE_STFP_TAB = "UPDATE_STFP_TAB";
export const LOAD_PRICE_STFP = "LOAD_PRICE_STFP";
export const LIMIT_PAGI_STFP = "LIMIT_PAGI_STFP";
export const TYPE_STFP = "TYPE_STFP";
export const LOADING_STFP = "LOADING_STFP";
export const LOADING_STFP_2 = "LOADING_STFP_2";
export const LOAD_NHATKYHOATDONG_STFP = "LOAD_NHATKYHOATDONG_STFP";
export const LOAD_SCF = "LOAD_SCF";
export const UPDATE_SCF = "UPDATE_SCF";
export const UPDATE_SCF_TAB = "UPDATE_SCF_TAB";
export const LOAD_PRICE_SCF = "LOAD_PRICE_SCF";
export const LIMIT_PAGI_SCF = "LIMIT_PAGI_SCF";
export const TYPE_SCF = "TYPE_SCF";
export const LOADING_SCF = "LOADING_SCF";
export const LOADING_SCF_2 = "LOADING_SCF_2";
export const LOAD_NHATKYHOATDONG_SCF = "LOAD_NHATKYHOATDONG_SCF";
export const LOAD_SCP = "LOAD_SCP";
export const UPDATE_SCP = "UPDATE_SCP";
export const UPDATE_SCP_TAB = "UPDATE_SCP_TAB";
export const LOAD_PRICE_SCP = "LOAD_PRICE_SCP";
export const LIMIT_PAGI_SCP = "LIMIT_PAGI_SCP";
export const TYPE_SCP = "TYPE_SCP";
export const LOADING_SCP = "LOADING_SCP";
export const LOADING_SCP_2 = "LOADING_SCP_2";
export const LOAD_NHATKYHOATDONG_SCP = "LOAD_NHATKYHOATDONG_SCP";
export const LOAD_SCL = "LOAD_SCL";
export const UPDATE_SCL = "UPDATE_SCL";
export const UPDATE_SCL_TAB = "UPDATE_SCL_TAB";
export const LOAD_PRICE_SCL = "LOAD_PRICE_SCL";
export const LIMIT_PAGI_SCL = "LIMIT_PAGI_SCL";
export const TYPE_SCL = "TYPE_SCL";
export const LOADING_SCL = "LOADING_SCL";
export const LOADING_SCL_2 = "LOADING_SCL_2";
export const LOAD_NHATKYHOATDONG_SCL = "LOAD_NHATKYHOATDONG_SCL";
export const LOAD_VF = "LOAD_VF";
export const UPDATE_VF = "UPDATE_VF";
export const UPDATE_VF_TAB = "UPDATE_VF_TAB";
export const LOAD_PRICE_VF = "LOAD_PRICE_VF";
export const LIMIT_PAGI_VF = "LIMIT_PAGI_VF";
export const TYPE_VF = "TYPE_VF";
export const LOADING_VF = "LOADING_VF";
export const LOADING_VF_2 = "LOADING_VF_2";
export const LOAD_NHATKYHOATDONG_VF = "LOAD_NHATKYHOATDONG_VF";
export const LOAD_VV = "LOAD_VV";
export const UPDATE_VV = "UPDATE_VV";
export const UPDATE_VV_TAB = "UPDATE_VV_TAB";
export const LOAD_PRICE_VV = "LOAD_PRICE_VV";
export const LIMIT_PAGI_VV = "LIMIT_PAGI_VV";
export const TYPE_VV = "TYPE_VV";
export const LOADING_VV = "LOADING_VV";
export const LOADING_VV_2 = "LOADING_VV_2";
export const LOAD_NHATKYHOATDONG_VV = "LOAD_NHATKYHOATDONG_VV";
export const LOAD_THUESIM = "LOAD_THUESIM";
export const UPDATE_THUESIM_TAB = "UPDATE_THUESIM_TAB";
export const UPDATE_THUESIM = "UPDATE_THUESIM";
export const LOAD_PRICE_THUESIM = "LOAD_PRICE_THUESIM";
export const LOADING_THUESIM = "LOADING_THUESIM";
export const LIMIT_PAGI_THUESIM = "LIMIT_PAGI_THUESIM";
export const TYPE_THUESIM = "TYPE_THUESIM";
export const LOAD_NHATKYHOATDONG_THUESIM = "LOAD_NHATKYHOATDONG_THUESIM";
export const UPDATE_IDFB_FLBBKTT = "UPDATE_IDFB_FLBBKTT";
export const FORGOTPASSWORD_SEND_SUCCESS = "FORGOTPASSWORD_SEND_SUCCESS";
export const FORGOTPASSWORD_SEND_FAIL = "FORGOTPASSWORD_SEND_FAIL";
export const RESETPASSWORD_SEND_SUCCESS = "RESETPASSWORD_SEND_SUCCESS";
export const RESETPASSWORD_SEND_FAIL = "RESETPASSWORD_SEND_FAIL";
export const TOKEN_RESET_SUCCESS = "TOKEN_RESET_SUCCESS";
export const TOKEN_RESET_FAIL = "TOKEN_RESET_FAIL";
export const UPDATE_YDL_TAB = "UPDATE_YDL_TAB";
export const LOAD_PRICE_YDL = "LOAD_PRICE_YDL";
export const UPDATE_YDL = "UPDATE_YDL";
export const LOAD_YDL = "LOAD_YDL";
export const TYPE_YDL = "TYPE_YDL";
export const LOADING_YDL = "LOADING_YDL";
export const UPDATE_YLC_TAB = "UPDATE_YLC_TAB";
export const LOAD_PRICE_YLC = "LOAD_PRICE_YLC";
export const UPDATE_YLC = "UPDATE_YLC";
export const LOAD_YLC = "LOAD_YLC";
export const TYPE_YLC = "TYPE_YLC";
export const LOADING_YLC = "LOADING_YLC";
export const UPDATE_YTNS_TAB = "UPDATE_YTNS_TAB";
export const LOAD_PRICE_YTNS = "LOAD_PRICE_YTNS";
export const UPDATE_YTNS = "UPDATE_YTNS";
export const LOAD_YTNS = "LOAD_YTNS";
export const TYPE_YTNS = "TYPE_YTNS";
export const LOADING_YTNS = "LOADING_YTNS";
export const LOAD_NHATKYHOATDONG_VLT = "LOAD_NHATKYHOATDONG_VLT";
