import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { loadVLT, upHuyOrder } from '../../../actions/ViplikethangActions';
import Swal from 'sweetalert2';

class nhatky extends Component {
	static propTypes =  {
		loadVLT: PropTypes.func.isRequired,
		upHuyOrder: PropTypes.func.isRequired,
	};
	
	state = {
		
	}
	
	componentDidMount() {
		this.props.loadVLT();
	}
	
	huyOrder = (id, object_id) => {
		const swalWithBootstrapButtons = Swal.mixin({
			customClass: {
				confirmButton: 'btn btn-success an-margin-btn',
				cancelButton: 'btn btn-danger an-margin-btn'
				},
			buttonsStyling: false
		})

		swalWithBootstrapButtons.fire({
			title: 'HỦY LIKE ID: '+object_id+' ?',
			text: "Sau khi hủy bạn sẽ bị trừ 7 ngày chưa sử dụng và 5000 phí!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Đồng ý',
			cancelButtonText: 'Không',
			reverseButtons: true
		}).then((result) => {
			if (result.value) {
				let body = {
					"id": id
				};
				this.props.upHuyOrder(body);
			} else if (result.dismiss === Swal.DismissReason.cancel) {
				
			}
		})
	}
	
	render() {
		const { listtientrinh } = this.props.Viplikethang;
		const { username } = this.props.auth.user;
		var ar_listtientrinh = [];
		listtientrinh.forEach(function(v, i) {
			var tmp = {
				...v,
				"site_username": username
			};
			ar_listtientrinh.push(tmp);
		});
		
		function formatBaoHanh(cell, row) {
			if (cell === 0) {
				return (
					<span className="badge badge-warning">Không</span>
				)
			} else {
				return (
					<span className="badge badge-success">Có</span>
				)
			}
		}
		
		function formatTrangThai(cell, row) {
			return (
				<span className="badge badge-warning"><i className="fas fa-sync-alt"></i> { cell }</span>
			)
		}
		
		const expandRow = {
			renderer: (row) => (
				<div className="offset-lg-4 col-lg-8 row">
					<div className={"col-lg-4 " + (row.is_removed === 0 ? '' : 'offset-lg-4')}>
						<div className="form-group">
							<label htmlFor="example-text-input">Nội dung</label>
							<textarea className="form-control" id="nd" name="nd" placeholder="" rows="2" ></textarea>
						</div>
					</div>
					<div className="col-lg-4">
						<div className="form-group">
							<label htmlFor="example-text-input">Ghi chú</label>
							<textarea className="form-control" id="gc" name="gc" placeholder="" rows="2" defaultValue={row.notes}></textarea>
						</div>
					</div>
					{
						row.is_removed === 0 ?
							<div className="col-lg-4">
								<div className="form-group">
									<button type="button" name="huy_order" className="btn btn-block btn-sm btn-rounded btn-hero-light" onClick={() => {this.huyOrder(row.id, row.object_id)}}>Hủy Order</button>
								</div>
							</div>
						: ''
					}
				</div>
			),
			showExpandColumn: true,
			expandColumnPosition: 'right',
			expandHeaderColumnRenderer: ({ isAnyExpands }) => {
				if (isAnyExpands) {
					return <b>-</b>;
				}
				return <b>+</b>;
			},
			expandColumnRenderer: ({ expanded }) => {
				if (expanded) {
					return (
						<b>-</b>
					);
				}
				return (
					<b>...</b>
				);
			}
		};
		
		const columns = [{
			dataField: 'id',
			text: 'ID',
			sort: true,
		}, {
			dataField: 'site_username',
			text: 'Username',
			sort: true,
		}, {
			dataField: 'object_id',
			text: 'Instagram',
			sort: true,
		}, {
			dataField: 'is_warranty',
			text: 'Bảo hành',
			sort: true,
			formatter: formatBaoHanh
		}, {
			dataField: 'start_like',
			text: 'Start',
			sort: true,
		}, {
			dataField: 'quantity',
			text: 'Số lượng',
			sort: true,
		}, {
			dataField: 'cost',
			text: 'Giá',
			sort: true,
		}, {
			dataField: 'prices',
			text: 'Tổng tiền',
			sort: true,
		}, {
			dataField: 'created_at',
			text: 'Khởi tạo',
			sort: true,
		}, {
			dataField: 'time_expired_format',
			text: 'Hết hạn',
			sort: true,
		}, {
			dataField: 'status_message',
			text: 'Trạng thái',
			sort: true,
			formatter: formatTrangThai
		}];
		return (
			<Fragment>
				<div>
					<BootstrapTable keyField='id' data={ ar_listtientrinh } columns={ columns } expandRow={ expandRow } pagination={ paginationFactory() } wrapperClasses="table-responsive" striped hover condensed />
				</div>
			</Fragment>
		)
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	Viplikethang: state.Viplikethang,
});
const mapDispatchToProps = { loadVLT, upHuyOrder }

export default connect(mapStateToProps, mapDispatchToProps)(nhatky);