import {
	LOAD_BGSL,
	UPDATE_BGSL,
	// UPDATE_BG_GETID,
	UPDATE_BGSL_TAB,
	UPDATE_BGSL_TGT,
	LOAD_PRICE_BGSL,
	LIMIT_PAGI_BGSL,
	TYPE_BGSL,
	LOADING_BGSL
} from "../actions/types";

const initialState = {
	"taotientrinh": {
		"lhi": "",
		"loaiseeding": "2",
		"slct": "100",
		"gtmtt": "0",
		"gc": "",
	},
	"changetab": 0,
	"listtientrinh": [],
	"tanggiathem": {
		"id": "",
		"orders_id": "",
		"per_price": 0,
		"price": 0,
		"quantity": 0,
		"loai": "",
	},
	"price": {
		buffgroupsharelive_sv1: {
			min_bh_web: 0,
			prices_web: 0,
		},
		buffgroupsharelive_sv2: {
			min_bh_web: 0,
			prices_web: 0,
		},
		buffgroupsharelive_svvip: {
			min_bh_web: 0,
			prices_web: 0,
		},
	},
	"limit_pagi": 0,
	"type": 0,
	"loading": false,
	facebookbuffsharegroup_sv1_mo_dong: 1,
	facebookbuffsharegroup_sv2_mo_dong: 1,
	facebookbuffsharegroup_vip_mo_dong: 1,
};

export default function(state = initialState, action) {
	switch (action.type) {
		case LOAD_BGSL:
			return {
				...state,
				"listtientrinh": action.payload.data,
			};
		case UPDATE_BGSL:
			return {
				...state,
				"taotientrinh": action.payload
			};
		// case UPDATE_BG_GETID:
			// return {
				// ...state,
				// "taotientrinh": {
					// ...state.taotientrinh,
					// "lhi": action.payload.data.group_id,
					// "tennhom": action.payload.data.group_name,
				// }
			// };
		case UPDATE_BGSL_TAB:
			return {
				...state,
				"changetab": action.payload
			};
		case UPDATE_BGSL_TGT:
			return {
				...state,
				"tanggiathem": {
					...state.tanggiathem,
					"id": action.payload.id,
					"orders_id": action.payload.orders_id,
					"per_price": action.payload.per_price,
					"price": action.payload.price,
					"quantity": action.payload.quantity,
					"loai": action.payload.loai,
				}
			};
		case LOAD_PRICE_BGSL:
			return {
				...state,
				"price": merDataPricePage(action.payload),
			};
		case LIMIT_PAGI_BGSL:
			return {
				...state,
				"limit_pagi": action.payload
			};
		case TYPE_BGSL:
			return {
				...state,
				"type": action.payload
			};
		case LOADING_BGSL:
			return {
				...state,
				"loading": action.payload
			};
		case 'CONFIG_MO_DONG_BGSL':
			return {
				...state,
				facebookbuffsharegroup_sv1_mo_dong: action.payload.facebookbuffsharegroup_sv1_mo_dong,
				facebookbuffsharegroup_sv2_mo_dong: action.payload.facebookbuffsharegroup_sv2_mo_dong,
				facebookbuffsharegroup_vip_mo_dong: action.payload.facebookbuffsharegroup_vip_mo_dong,
			};
		default:
		  return state;
	}
}

function merDataPricePage(data) {
	let dataNew = {
		...initialState.price
	}
	if (data.length > 0) {
		data.forEach(function(v, i){
			if (v.name_table === 'buffgroupsharelive_sv1') {
				dataNew['buffgroupsharelive_sv1'] = v;
			} else if (v.name_table === 'buffgroupsharelive_sv2') {
				dataNew['buffgroupsharelive_sv2'] = v;
				initialState.taotientrinh.gtmtt = v.prices_web;
			} else if (v.name_table === 'buffgroupsharelive_svvip') {
				dataNew['buffgroupsharelive_svvip'] = v;
			}
		});
	};
	return dataNew;
}