import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { login } from "../../actions/authActions";
import { clearErrors } from "../../actions/errorActions";
import RegisterModal5 from './Register5';
import ServiceModal5 from './Service5';
import ForgotPasswordModal5 from './ForgotPassword5';
import iconVCB from '../../iconlanding/5/vcb.png';
import iconMB from '../../iconlanding/5/mb.png';
import iconACB from '../../iconlanding/5/ACB.png';
import iconTechcombank from '../../iconlanding/5/Techcombank.png';
import iconMomo from '../../iconlanding/5/momo.png';
import job2 from '../../iconlanding/5/job2.png';
import Layer2 from '../../iconlanding/5/Layer-2.png';
import Layer9 from '../../iconlanding/5/Layer-9.png';
import Layer10 from '../../iconlanding/5/Layer-10.png';
import Layer11 from '../../iconlanding/5/Layer-11.png';
import Layer18 from '../../iconlanding/5/Layer-18.png';
import Layer19 from '../../iconlanding/5/Layer-19.png';
import Layer20 from '../../iconlanding/5/Layer-20.png';
import Layer23 from '../../iconlanding/5/Layer-23.png';
import {
  BrowserRouter as Router,
  Link
} from "react-router-dom";
class Login extends Component {
  state = {
	modal: 1,
    msg: "",
    username: "",
    password: "",
    maxacminh: "",
    form_maxacminh: false,
    isLoading: false,
	tabopen: 'fb',
	menuopen: false,
  };
  componentDidMount(){
	if (this.props.typeform == 3) {
	  this.setState({ modal: 3 });
	}
  }
  componentDidUpdate(prevProps) {
    const { error } = this.props;
    if (error !== prevProps.error) {
      // Check for login error
      if (error.id === "LOGIN_FAIL") {
        this.setState({ msg: error.msg.msg });
      } else if (error.id === "LOGIN_FAIL_2FA") {
        this.setState({ msg: error.msg.msg, form_maxacminh: true });
      } else {
        this.setState({ msg: null });
      }
    }
  }
	clearAlert = () => {
		// Clear errors
		this.props.clearErrors();
		// this.setState({
			// modal: !this.state.modal
		// });
	}
  changeFormLogRegister = (typeform) => {
	this.props.clearErrors();
	this.setState({
		modal: typeform
	});
	
}
  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };
  onSubmit = e => {
    e.preventDefault();
    const { username, password, maxacminh } = this.state;
    this.props.clearErrors();
    this.props.login({ username, password, maxacminh });
  };
  openTab = (data = null) => {
	if (data !== null) {
		this.setState({
			...this.state,
			tabopen: data,
		});
	}
  }
  openMenu = (data = null) => {
	if (data !== null) {
		this.setState({
			...this.state,
			menuopen: data,
		});
	}
  }
  render() {
    let isLoading = this.props.auth.isLoading;
    const domainSite = window.location.hostname;
	var config_website = {};
	if (localStorage.getItem("confignavbar") != null) {
		config_website = JSON.parse(localStorage.getItem("confignavbar"));
	} else {
		config_website = this.props.auth.config_website;
	}
	const formLogin = (
		<Router>
			<Fragment>
				<div className="landingcus5" style={{"zoom": "122%", "backgroundColor": "#fff"}}>
					<nav className="navbar navbar-default navbar-static-top">
						<div className="container">
							<div className="navbar-header">
								<a className="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar" aria-expanded="false" aria-controls="navbar" onClick={(e) => {e.preventDefault();this.openMenu(!this.state.menuopen)}}>
									<span className="sr-only"></span>
									<span className="icon-bar"></span>
									<span className="icon-bar"></span>
									<span className="icon-bar"></span>
								</a>
								<a className="link-fx font-w700 fontSize-h3 site-logo site-title" href="/" style={{"padding": "0", "top": "12px", "color": "#fa329c", "position": "relative"}}>
								  <span className="text-uppercase">{domainSite.slice(0, domainSite.lastIndexOf('.'))}</span>
								  <span className="text-uppercase">{domainSite.indexOf('.') > -1 ? domainSite.slice(domainSite.lastIndexOf('.')) : ''}</span>
								</a>
							</div>
							<div id="navbar" className={"navbar-collapse collapse "+(this.state.menuopen ? ' in' : '')}>
								<ul className="nav navbar-nav navbar-right">
									<li className="nav-item  menu_item_14 " id="menu_item_14">
										<Link to="/services" onClick={(e) => {this.changeFormLogRegister(3)}} className="nav-link">
											Dịch vụ
										</Link>
									</li>
									<li className="nav-item  menu_item_14 " id="menu_item_14">
										<Link to="/" className="nav-link" onClick={(e) => {this.changeFormLogRegister(2)}}>
											Đăng ký
										</Link>
									</li>
									<li className="nav-item active menu_item_13 " id="menu_item_13">
										<a className="nav-link" href="/">
											Đăng nhập
										</a>
									</li>
								</ul>
							</div>
						</div>
					</nav>
					<div className="top-content-area">	
						<div className="container">
							<div className="row">
								<div className="col-sm-6 ">
									<p>Tiếp thị truyền thông xã hội tất cả trong một </p>
									<h1>Giải pháp truyền thông <br/>tốt nhất hiện tại</h1>
									<hr/>
									<div className="frm">
										<h2 className="login-area-head">Đăng nhập</h2>
										<h2>Đăng nhập vào tài khoản</h2>
										<p>Sử dụng thông tin đăng nhập của bạn để truy cập tài khoản của bạn.</p>
										{isLoading ? (
											<div className="preloader">
												<div className="spinner-grow spinner-grow-sm text-primary" role="status" style={{ "marginTop": "10%" }}>
												  <span className="sr-only"></span>
												</div>
												<div className="spinner-grow spinner-grow-sm text-danger" role="status" style={{ "marginTop": "10%" }}>
												  <span className="sr-only">.</span>
												</div>
												<div className="spinner-grow spinner-grow-sm text-warning" role="status" style={{ "marginTop": "10%" }}>
												  <span className="sr-only"></span>
												</div>
											</div>
										):('')}
										{this.state.msg ? (
											<div className="alert alert-warning d-flex align-items-center justify-content-between" role="alert" >
											  <div className="flex-fill mr-3">
												<p className="mb-0">{this.state.msg}</p>
											  </div>
											  <div className="flex-00-auto">
												<i className="fa fa-fw fa-exclamation-circle"></i>
											  </div>
											</div>
										  ) : null}
										<form className="js-validation-signup" onSubmit={this.onSubmit} method="POST" >
											<div className="form-group">
												<span className="input-icon">
													<input name="username" className="form-control" id="username" type="text" placeholder="Nhập tên tài khoản" onChange={this.onChange} />
													<i className="fa fa-user" style={{"top": "-5px", "left": "20px"}}></i>
												</span>
												<p className="help-block help-block-error"></p>
											</div>
											<div className="form-group">
												<span className="input-icon">
													<input name="password" className="form-control" id="password" type="password" placeholder="Nhập mật khẩu" onChange={this.onChange} />
													<i className="fa fa-key" style={{"top": "-5px", "left": "20px"}}></i>
												</span>
											</div>
											{
												this.state.form_maxacminh ?
													<div className="form-group">
														<span className="input-icon">
															<input name="maxacminh" className="form-control" id="maxacminh" type="text" placeholder="Nhập mã xác minh" onChange={this.onChange} />
															<i className="fa fa-lock" style={{"top": "-5px", "left": "20px"}}></i>
														</span>
													</div>
												: ""
											}
											<div className="form-group" style={{"textAlign": "right"}}>
												<Link to="/" onClick={(e) => {this.changeFormLogRegister(4)}} style={{"color": "#1f2324"}}>
													Quên mật khẩu?
												</Link>
											</div>
											<div className="formlast">
												<button type="submit" className="btn btn-fans">Đăng nhập</button>
											</div>
										</form>
										<div className="have-acc">
											<span>Bạn chưa có tài khoản? </span>
											<Link to="/signup" onClick={(e) => {this.changeFormLogRegister(2)}}>
												Đăng ký
											</Link>
										</div>
									</div>
								</div>
								<div className="col-sm-6 text-right">
									<div className="img-wrap">
										<img className="" src={Layer2} alt="Social Media Services " />
									</div>
								</div>
							</div>
							<p><br/></p>
						</div>
					</div>
					<section id="engage" className="text-center">
						<div className="container">
							<div className="row title-row">
								<div className="col-sm-12">
									<h4>Bảng tiếp thị truyền thông xã hội</h4>
								</div>
							</div>
							<div className="row">
								<div className="col-sm-12">
									<p>Dịch vụ Tiếp thị Truyền thông Xã hội giúp doanh nghiệp cải thiện phạm vi tiếp thị, thu hút nhiều người hơn và tạo ra doanh số bán hàng lớn trong quá trình này.
									</p>
									<br/>
									<p>Với các dịch vụ của Hacklikepro, bạn có thể mua các dịch vụ như lượt thích trên Facebook, người hâm mộ trên Instagram, lượt xem và đăng ký trên YouTube, thu được lưu lượng truy cập trang web và nhiều dịch vụ khác với mức giá rẻ nhất có thể.
									</p>
									<br/>
									<p>Bạn có mệt mỏi khi tìm kiếm các dịch vụ rẻ nhất? Hacklikepro mang đến cho bạn cơ hội kiếm lợi nhuận bằng cách mua các dịch vụ giá cả phải chăng, giúp bạn tạo liên kết xã hội, tăng mức độ tương tác của khán giả, v.v. Điều này giúp bạn cải thiện sự hiện diện trực tuyến của mình trên mọi nền tảng truyền thông xã hội lớn.
									</p>
									<br/>
									<p>Các dịch vụ của Hacklikepro bao gồm-</p>
								</div>
							</div>
						</div>
					</section>
					<section id="tabs">
						<div className="container">
							<div className="row">
								<div className="col-sm-12">
									<ul className="nav nav-tabs" id="myTab" role="tablist" style={{"justifyContent": "center"}}>
										<li className={"nav-item "+(this.state.tabopen === 'fb' ? ' active' : '')}>
											<a className={"nav-link fb "+(this.state.tabopen === 'fb' ? ' active' : '')} onClick={(e) => {e.preventDefault();this.openTab('fb')}} id="fb-tab" data-toggle="tab" href="#fb" role="tab" aria-controls="fb" aria-selected="true" aria-expanded="false"><div className="tab-style"><span className="pull-left">facebook</span><span className="center-txt"><i className="fab fa-facebook-f"></i></span></div></a>
										</li>
										<li className={"nav-item "+(this.state.tabopen === 'insta' ? ' active' : '')}>
											<a className={"nav-link insta "+(this.state.tabopen === 'insta' ? ' active' : '')} onClick={(e) => {e.preventDefault();this.openTab('insta')}} id="insta-tab" data-toggle="tab" href="#insta" role="tab" aria-controls="insta" aria-selected="false" aria-expanded="true"><div className="tab-style"><span className="pull-left">Instagram</span><span className="center-txt"><i className="fab fa-instagram"></i></span></div></a>
										</li>
										<li className={"nav-item "+(this.state.tabopen === 'youtube' ? ' active' : '')}>
											<a className={"nav-link youtube "+(this.state.tabopen === 'youtube' ? ' active' : '')} onClick={(e) => {e.preventDefault();this.openTab('youtube')}} id="youtube-tab" data-toggle="tab" href="#youtube" role="tab" aria-controls="youtube" aria-selected="false" aria-expanded="true"><div className="tab-style"><span className="pull-left">youtube</span><span className="center-txt"><i className="fab fa-youtube"></i></span></div></a>
										</li>
										<li className={"nav-item "+(this.state.tabopen === 'tiktok' ? ' active' : '')}>
											<a  className={"nav-link tiktok "+(this.state.tabopen === 'tiktok' ? ' active' : '')} onClick={(e) => {e.preventDefault();this.openTab('tiktok')}} id="tiktok-tab" data-toggle="tab" href="#tiktok" role="tab" aria-controls="tiktok" aria-selected="false" aria-expanded="false">
												<div className="tab-style"><span className="pull-left">TikTok</span>
													<span className="center-txt">
														<svg id="douyin" t="1570181112474" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2916" width="128" height="128">
															<path d="M937.386667 423.850667a387.84 387.84 0 0 1-232.874667-77.824v352.341333C704.512 878.250667 565.930667 1024 394.922667 1024S85.333333 878.250667 85.333333 698.368c0-179.882667 138.581333-325.632 309.589334-325.632 17.066667 0 33.706667 1.450667 49.92 4.266667v186.624a131.754667 131.754667 0 0 0-48.64-9.216c-76.288 0-138.154667 65.024-138.154667 145.322666 0 80.213333 61.866667 145.322667 138.24 145.322667 76.202667 0 138.069333-65.109333 138.069333-145.322667V0h172.714667c0 134.485333 103.68 243.541333 231.594667 243.541333v180.309334h-1.28" p-id="2917"></path>
														</svg>
													</span>
												</div>
											</a>
										</li>
									</ul>
									<div className="tab-content" id="myTabContent">
										<div className={"tab-pane fade "+(this.state.tabopen === 'fb' ? ' active in' : '')} id="fb" role="tabpanel" aria-labelledby="fb-tab">
											<div className="row">
												<div className="col-sm-6 text-left">
													<img className="" alt="Rocket social media presence" src={Layer18}/>
												</div>
												<div className="col-sm-6">
													<h5>Facebook-</h5>
													<p>Là một nền tảng truyền thông xã hội phổ biến nhất, Facebook đã trở thành trung tâm tiếp thị cho mọi doanh nghiệp. Bạn càng nổi tiếng trên Facebook thì bạn càng tạo ra nhiều doanh nghiệp. Tại hacklikepro, chúng tôi cung cấp cho bạn các dịch vụ FB chất lượng cao được đánh giá cao giúp bạn quảng bá trang Facebook và / hoặc Tài khoản Facebook của mình.</p>
													<br/>
													<p>Bạn có thể mua lượt thích FB, người hâm mộ FB, người theo dõi FB, Lượt thích trang FB, Bình luận FB, v.v. Cho dù bạn có trang cá nhân hay trang kinh doanh, các dịch vụ của chúng tôi đều đáp ứng mọi nhu cầu của bạn. Tại hacklikepro, trọng tâm của chúng tôi là phát triển tài khoản và hồ sơ doanh nghiệp của bạn và tăng mức độ phổ biến của nó.</p>
													<Link to="/signup" className="btn btn-fans" onClick={(e) => {this.changeFormLogRegister(2)}}>
														Đăng ký
													</Link>

													<div className="row">
														<div className="col-sm-6 inner-stat">
															<h6>5,253,697 <span>+</span></h6>
															<p>Đơn hàng thành công</p>
														</div>
														<div className="col-sm-6 inner-stat">
															<h6>3,867 <span>+</span></h6>
															<p>Khách hàng hài lòng</p>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className={"tab-pane fade "+(this.state.tabopen === 'insta' ? ' active in' : '')} id="insta" role="tabpanel" aria-labelledby="insta-tab">
											<div className="row">
												<div className="col-sm-6">
													<h5>Instagram-</h5>
													<p>Instagram là một phần không thể thiếu trong mọi dịch vụ. Tăng lượng người theo dõi và mức độ phổ biến của bạn trên Instagram có thể giúp doanh nghiệp của bạn có lợi thế hơn các đối thủ cạnh tranh.
													</p>
													<br/>
													<p>Dịch vụ trên instagram của Hacklikepro cho phép bạn mua lượt thích thực, lượt xem luồng trực tiếp và nhận xét tùy chỉnh cho hình ảnh và video trên Instagram của bạn.
													</p>
													<br/>
													<p>Dịch vụ Instagram giúp nâng cao nhận thức và lòng trung thành của thương hiệu và giúp doanh nghiệp của bạn tăng cường quan hệ với khách hàng. Với dịch vụ instagram của hacklikepro, bạn có thể đưa các chiến lược tiếp thị trên Instagram của mình lên một cấp độ khác, làm cho nó hiệu quả hơn và đồng thời giữ được chi phí hiệu quả.</p>
													<Link to="/signup" className="btn btn-fans" onClick={(e) => {this.changeFormLogRegister(2)}}>
														Đăng ký
													</Link>

													<div className="row">
														<div className="col-sm-6 inner-stat">
															<h6>4,003,323 <span>+</span></h6>
															<p>Đơn hàng thành công</p>
														</div>
														<div className="col-sm-6 inner-stat">
															<h6>2,665 <span>+</span></h6>
															<p>Khách hàng hài lòng</p>
														</div>
													</div>
												</div>
												<div className="col-sm-6 text-right">
													<img className="" alt="instagram" src={Layer18}/>
												</div>
											</div>
										</div>
										<div className={"tab-pane fade "+(this.state.tabopen === 'youtube' ? ' active in' : '')} id="youtube" role="tabpanel" aria-labelledby="youtube-tab">
											<div className="row">
												<div className="col-sm-6 text-left">
													<img className="" alt="youtube" src={Layer18}/>
												</div>
												<div className="col-sm-6">
													<h5>Youtube-</h5>
													<p>Trong vài năm qua, tỷ lệ tiêu thụ nội dung video đã tăng nhanh chóng trong người dùng. Xét về số lượng Youtuber ngày càng tăng, sự cạnh tranh để có được nhiều lượt xem, lượt thích và đăng ký cũng tăng lên.
													</p>
													<br/>
													<p>Hacklikepro cung cấp cho bạn dịch vụ Youtube chất lượng tốt nhất, giúp bạn quảng bá cả kênh Youtube cá nhân và kênh kinh doanh của bạn, dịch vụ của chúng tôi đáp ứng mọi nhu cầu của bạn.
													</p>
													<br/>
													<p>Tại hacklikepro, bạn có thể mua Lượt xem Youtube, đăng ký Youtube, lượt thích và bình luận trên youtube với mọi giá rẻ nhất có thể. Bạn nhận được lượt xem YouTube không giảm và không phải trả tiền cho video của mình, để tăng tương tác của người xem hàng ngày.</p>
													<Link to="/signup" className="btn btn-fans" onClick={(e) => {this.changeFormLogRegister(2)}}>
														Đăng ký
													</Link>

													<div className="row">
														<div className="col-sm-6 inner-stat">
															<h6>3,953,402 <span>+</span></h6>
															<p>Đơn hàng thành công</p>
														</div>
														<div className="col-sm-6 inner-stat">
															<h6>1,529 <span>+</span></h6>
															<p>Khách hàng hài lòng</p>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className={"tab-pane fade "+(this.state.tabopen === 'tiktok' ? ' active in' : '')} id="tiktok" role="tabpanel" aria-labelledby="tiktok-tab">
											<div className="row">
												<div className="col-sm-6">
													<h5>Tiktok-</h5>
													<p>Tiktok gần đây đã trở thành một trong những nền tảng truyền thông xã hội phổ biến nhất và nó có hàng triệu người dùng từ khắp nơi trên thế giới. Tiktok cung cấp cho người dùng của họ một phương tiện để kết nối với người hâm mộ của họ thông qua các video tương tác và giải trí.</p>
													<br/>
													<p>Tiktok giúp bạn xây dựng khán giả của riêng mình và nổi tiếng trên toàn thế giới. Nếu bạn đã cân nhắc tham gia Tiktok, bạn cũng có thể muốn mua một số người hâm mộ Tiktok thực sự và những người theo dõi Tiktok.</p>
													<br/>
													<p>Hacklikepro giúp bạn mua những người theo dõi tiktok thực sự, người hâm mộ, lượt thích cho hồ sơ của bạn và xây dựng trạng thái của bạn. Mua người theo dõi từ hacklikepro giúp bạn tạo ra một lượng lớn người theo dõi, điều này giúp bạn trở nên nổi tiếng hơn trên ứng dụng. Trở nên nổi tiếng tất nhiên có những đặc quyền của riêng nó !!!</p>
													<Link to="/signup" className="btn btn-fans" onClick={(e) => {this.changeFormLogRegister(2)}}>
														Đăng ký
													</Link>

													<div className="row">
														<div className="col-sm-6 inner-stat">
															<h6>8,153,627 <span>+</span></h6>
															<p>Đơn hàng thành công</p>
														</div>
														<div className="col-sm-6 inner-stat">
															<h6>7,869 <span>+</span></h6>
															<p>Khách hàng hài lòng</p>
														</div>
													</div>
												</div>
												<div className="col-sm-6 text-left">
													<img className="" alt="Tiktok" src={Layer18}/>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					<section id="why">
						<div className="container">
							<div className="row title-row">
								<div className="col-sm-12">
									<h3 style={{"fontSize": "92px"}}>CHỌN CHÚNG TÔI</h3>
								</div>
							</div>
							<div className="row">
								<div className="col-sm-4">
									<div className="stat-wrap text-center">
										<div className="stat-img">
											<img className="" src={Layer18} alt=""/>
										</div>
										<div className="stat-desc">
											<h2>0.12 giây</h2>
											<p>Mỗi đơn hàng được thực hiện</p>
										</div>
									</div>
								</div>
								<div className="col-sm-4">
									<div className="stat-wrap text-center">
										<div className="stat-img">
											<img className="" alt="" src={Layer10}/>
										</div>
										<div className="stat-desc">
											<h2>15,917,802
											</h2>
											<p>Đơn hàng thành công</p>
										</div>
									</div>
								</div>
								<div className="col-sm-4">
									<div className="stat-wrap text-center">
										<div className="stat-img">
											<img className="" alt="" src={Layer11}/>
										</div>
										<div className="stat-desc">
											<h2>10 VNĐ
											</h2>
											<p>Giá chỉ từ</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					<section id="sign-sec">
						<div className="container">
							<div className="row">
								<div className="col-sm-9">
									<h4>ĐĂNG KÝ NGAY</h4>
									<p>Và nhận nhiều ưu đãi khi trở thành thành viên</p>
								</div>
								<div className="col-sm-3">
									<Link to="/signup" className="btn btn-fans" onClick={(e) => {this.changeFormLogRegister(2)}}>
										Đăng ký ngay
									</Link>
								</div>
							</div>
						</div>
					</section>
					<section id="engage" className="text-center">
						<div className="container">
							<div className="row title-row">
								<div className="col-sm-12">
									<h4>Tại sao nên mua Dịch vụ từ hacklikepro?</h4>
								</div>
							</div>
							<div className="row">
								<div className="col-sm-12">
									<p>Hacklikepro cung cấp cho bạn các dịch vụ chất lượng hàng đầu. Chúng tôi tập trung vào việc cung cấp cho bạn chất lượng dịch vụ tốt nhất và an toàn nhất với chi phí hợp lý nhất.</p>
									<br/>
									<p>Khách hàng của chúng tôi bị choáng ngợp với giá của các dịch vụ do chúng tôi cung cấp so với các đối thủ cạnh tranh của chúng tôi. Với chất lượng của các dịch vụ tiếp thị truyền thông xã hội được cung cấp ở mức giá thấp như vậy, hacklikepro trở thành lựa chọn tốt nhất của bạn.</p>
									<br/>
									<p>Các gói đang được cung cấp trở nên tiết kiệm chi phí hơn cho các đơn hàng số lượng lớn hoặc cho các đơn hàng được thực hiện với số lượng lớn.</p>
									<br/>
									<p>Cùng với đó, bạn được cung cấp dịch vụ hỗ trợ khách hàng tốt nhất 24/7, ở đó để giúp bạn giải quyết tất cả các thắc mắc và vấn đề của bạn.</p>
								</div>
							</div>
						</div>
					</section>
					<section id="worth">
						<div className="container">
							<div className="col-sm-6">
								<h5>Thương hiệu của bạn đáp ứng với các mục tiêu của bạn</h5>
								<p>Với các dịch vụ do (Hacklikepro) cung cấp, chúng tôi mang khách hàng tiềm năng đến gần hơn với thương hiệu của bạn.</p>
								<br/>
								<p>Chúng tôi coi thương hiệu của bạn là thương hiệu tốt nhất cho các dịch vụ mà nó cung cấp. Các dịch vụ của chúng tôi đã mang lại kết quả trên toàn cầu và đã thành công trong việc đưa một số thương hiệu đạt đến đỉnh cao thành công.</p>
								<br/>
								<p>Ý nghĩa và việc thực hiện các chiến lược được người mua sử dụng rất hiệu quả và cho kết quả nhanh chóng và hiệu quả.</p>
								<Link to="/signup" className="btn btn-fans" onClick={(e) => {this.changeFormLogRegister(2)}}>
									Đăng ký ngay
								</Link>

								<div className="row">
									<div className="col-sm-6 inner-stat">
										<h6>15,917,802 <span>+</span></h6>
										<p>Đơn hàng thành công</p>
									</div>
									<div className="col-sm-6 inner-stat">
										<h6>17,129 <span>+</span></h6>
										<p>Khách hàng hài lòng</p>
									</div>
								</div>
							</div>
							<div className="col-sm-6 text-right">
								<img className="" src={Layer19} alt="Boost brand through SMM" />
							</div>
						</div>
					</section>
					<section className="payment-icons">
						<p className="sec-heading"> <i className="fa fa-star"></i>Hỗ trợ thanh toán </p>
						<div className="container">
							<ul>
								<li>
									<img src={iconVCB} />
								</li>
								<li>
									<img src={iconMB} />
								</li>
								<li>
									<img src={iconACB} />
								</li>
								<li>
									<img src={iconTechcombank} />
								</li>
								<li>
									<span>Tiền điện tử</span>
									<ul className="list">
										<li>
											<img src={iconMomo} /> 
										</li>
									</ul>
								</li>               
							</ul>
						</div> 

						<div className="support-panel">
							<div className="bg-graphic wave" style={{"marginTop": "-255px"}}></div>
							<div className="container position-relative">
								<h4 className="title">
									<i className="fa fa-star"></i><span> Hỗ trợ tại đây 24/7 để giúp bạn luôn hoạt động và hiệu quả!</span>
								</h4>
								<div className="row">
									<div className="col-md">
										<div className="card"> <a href="https://anon.ws/?https://www.facebook.com/100010750668234" className="card-body hvr-icon-pop" target="_blank"><i className="fa fa-comments hvr-icon"></i> Fanpage hỗ trợ</a>
										</div>
									</div>
									<div className="col-md">
										<div className="card"> <a href="mailto:hacklikepro8888@gmail.com" className="card-body hvr-icon-pop" style={{"paddingLeft": "10px", "paddingRight": "10px"}}><i className="fa fa-envelope hvr-icon"></i> hacklikepro8888@gmail.com</a>
										</div>
									</div>
									<div className="col-md">
										<div className="card"> <a href="tel:0849.00.8888" className="card-body hvr-icon-pop"><i className="fa fa-phone-alt hvr-icon"></i> 0849.00.8888</a>
										</div>
									</div>
								</div>
							</div>
						</div> 
					</section>
					<section id="engage" className="text-center">
						<div className="container">
							<div className="row title-row">
								<div className="col-sm-12">
									<h4>Tăng mức độ phổ biến của bạn trên mạng xã hội</h4>
								</div>
							</div>
							<div className="row">
								<div className="col-sm-12">
									<p>Sử dụng dịch vụ của chúng tôi, bạn sẽ hiểu các dịch vụ tiếp thị truyền thông xã hội của chúng tôi có giá trị và hợp pháp như thế nào !!! Mọi người trên khắp thế giới đều hiểu giá trị thực của các sản phẩm và dịch vụ do bạn cung cấp.
									</p>
									<br/>
									<p>Sự gia tăng mức độ phổ biến và công việc kinh doanh mà bạn nhận được sau khi sử dụng các dịch vụ của hacklikepro sẽ thôi thúc bạn quay lại với chúng tôi nhiều lần để biết thêm.</p>
								</div>
							</div>
						</div>
					</section>
					<section id="worth">
						<div className="container">
							<div className="col-sm-6 text-right">
								<img className="" src={Layer20} alt="Cheap SMM Services"/>
							</div>
							<div className="col-sm-6">
								<h5>Bắt đầu nào</h5>
								<p>Bạn không còn phải chờ đợi để có được những người theo dõi thực không giới hạn cho hồ sơ mạng xã hội của mình. Chỉ cần đăng ký HACKLIKEPRO và tận hưởng ngay các dịch vụ chất lượng cao và giá rẻ. Nhận được những người theo dõi AN TOÀN, CÓ THỂ và THẬT với mức giá hợp lý nhất.</p>
								<br/>
								<p>Bạn có bất kỳ câu hỏi nào, chúng tôi có hỗ trợ khách hàng 24/7…</p>
								<Link to="/signup" className="btn btn-fans" onClick={(e) => {this.changeFormLogRegister(2)}}>
									Đăng ký ngay
								</Link>

								<div className="row">
									<div className="col-sm-6 inner-stat">
										<h6>15,917,802 <span>+</span></h6>
										<p>Đơn hàng thành công</p>
									</div>
									<div className="col-sm-6 inner-stat">
										<h6>17,129 <span>+</span></h6>
										<p>Khách hàng hài lòng</p>
									</div>
								</div>
							</div>
						</div>
					</section>
					<section id="hme-slider">
						<div className="container">
							<div className="row">
								<div className="col-sm-12">
									<div className="testi-slider">
										<div className="test-wrap">
											<div className="test-pic">
												<img className="" alt="" src={job2} style={{"width": "100px", "height": "100px"}}/>
												<h6 className="pic-title">Đỗ <span>Cường</span></h6>
												<p className="pic-smm">Facebook</p>
											</div>
											<div className="test-cmt">
												<img className="rating-star" src={Layer23} alt="rating-star"/>
												<p>Cảm ơn các bạn rất nhiều!! </p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					<footer>
						<div className="container">
							<div className="col-sm-6 ftr-left text-left">
								<p>copyright 2021&nbsp;&nbsp;<span>HackLikePro</span>. All Rights Reserved.</p>
							</div>
							<div className="col-sm-6 ftr-right text-right">
								<ul className="social-icon">
									<li><a href="https://anon.ws/?https://www.facebook.com/100010750668234" target="_blank" className=""><i className="fab fa-facebook-f"></i></a></li>
								</ul>
							</div>
						</div>
					</footer>
				</div>
			</Fragment>
		</Router>
	);
	var path_check = window.location.pathname;
	if (window.location.pathname.length > 1) {
		path_check = window.location.pathname.replace(/\/+$/g, '');
	}
	const formRegister = (
		<Fragment>
			<RegisterModal5 action_login={(e) => {this.changeFormLogRegister(1)}} action_service={(e) => {this.changeFormLogRegister(3)}} />
		</Fragment>
	);
	const formService = (
		<Fragment>
			<ServiceModal5 action_login={(e) => {this.changeFormLogRegister(1)}} action_register={(e) => {this.changeFormLogRegister(2)}} />
		</Fragment>
	);
	const formForgotPassword = (
		<Fragment>
			<ForgotPasswordModal5 action_login={(e) => {this.changeFormLogRegister(1)}} action_register={(e) => {this.changeFormLogRegister(2)}} action_service={(e) => {this.changeFormLogRegister(3)}} action_reset={path_check} />
		</Fragment>
	);
	return (
		<Fragment>
			{
				path_check === '/resetpassword' ?
					formForgotPassword
				: this.state.modal == 1 ?
					formLogin
				: this.state.modal == 2 ?
					formRegister
				: this.state.modal == 3 ?
					formService
				: this.state.modal == 4 ?
					formForgotPassword
				: ''
			}
		</Fragment>
	);
  }
}
Login.propTypes = {
  auth: PropTypes.object.isRequired,
  error: PropTypes.object,
  login: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  error: state.error
});
export default connect(mapStateToProps, { login, clearErrors })(Login);
