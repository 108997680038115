import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Userinformation from "../Userinformation";

class sitecontent extends Component {
	render() {
		const { listloaiclone } = this.props.Muabanclone;
		var info_loaiclone = '';
		if (listloaiclone.length > 0) {
			info_loaiclone = (
				<Fragment>
					{
						listloaiclone.map(function(v, i) {
							return (
								<p key={ v.id }>- { v.name }: { v.notes }</p>
							);
						})
					}
				</Fragment>
			);
		}
		return (
			<Fragment>
				<Userinformation />
				<div className="card card-orange mt-3">
					<div className="card-body">
						<p className="font-bold text-danger">Loại tài khoản hiện có:</p>
						{info_loaiclone}
					</div>
				</div>
			</Fragment>
		)
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	Muabanclone: state.Muabanclone,
});
const mapDispatchToProps = { }

export default connect(mapStateToProps, mapDispatchToProps)(sitecontent);