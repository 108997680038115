import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import TaoTientrinh from "./tabFacebookvipmatlivestreamv2/taotientrinh";
import NhatKy from "./tabFacebookvipmatlivestreamv2/nhatky";
import DanhSach from "./tabFacebookvipmatlivestreamv2/danhsach";

import { changeTab, loadFVMLSV } from '../../actions/Facebookvipmatlivestreamv2Actions';
import { load, changeTabFbVip } from '../../actions/FbvipActions';

class Facebookvipmatlivestreamv2 extends Component {
	static propTypes =  {
		changeTab: PropTypes.func.isRequired,
		loadFVMLSV: PropTypes.func.isRequired,
		changeTabFbVip: PropTypes.func.isRequired,
	};
	componentDidMount() {
		this.props.changeTabFbVip('facebookvipmatlivestreamv2', 0);
	};
	onClick = (value) => {
		this.props.changeTabFbVip('facebookvipmatlivestreamv2', value);
		// this.props.loadFVMLSV();
		this.props.load('facebookvipmatlivestreamv2');
	};
	render() {
		const { changetab, loading } = this.props.Facebookvipmatlivestreamv2;
		return (
			<Fragment>
				<div className={"block block-bordered shadow"+ (loading? ' block-mode-loading' : '')}>
					<ul className="nav nav-tabs nav-tabs-block" data-toggle="tabs" role="tablist" >
						<li className="nav-item col-lg-6 licus">
							<Link className={"nav-link " + (changetab === 0 ? "active": "" )} to="/tool/facebookvipmatlivestreamv2" onClick={() => { this.onClick(0) }} >
								Tạo Tiến Trình
							</Link>
						</li>
						{/*<li className="nav-item col-lg-6 licus">
							<Link className={"nav-link " + (changetab === 2 ? "active": "" )} to="/tool/facebookvipmatlivestreamv2" onClick={() => { this.onClick(2) }} >
								Danh sách tài khoản
							</Link>
						</li>*/}
						<li className="nav-item col-lg-6 licus">
							<Link className={"nav-link " + (changetab === 1 ? "active": "" )} to="/tool/facebookvipmatlivestreamv2" onClick={() => { this.onClick(1) }} >
								Danh Sách Order
							</Link>
						</li>
					</ul>
					<div className="block-content tab-content overflow-hidden">
						<div className={"tab-pane fade fade-left " + (changetab === 0 ? "show active": "" )} id="btabs-animated-slideleft-home" role="tabpanel" >
							<TaoTientrinh />
						</div>
						{/*<div className={"tab-pane fade fade-left " + (changetab === 2 ? "show active": "" )} id="btabs-animated-slideleft-list" role="tabpanel" >
							<DanhSach />
						</div>*/}
						<div className={"tab-pane fade fade-left " + (changetab === 1 ? "show active": "" )} id="btabs-animated-slideleft-profile" role="tabpanel" >
							<NhatKy />
						</div>
					</div>
				</div>
			</Fragment>
		);
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	Facebookvipmatlivestreamv2: state.Facebookvipmatlivestreamv2,
});
const mapDispatchToProps = { changeTab, loadFVMLSV, load, changeTabFbVip };

export default connect(mapStateToProps, mapDispatchToProps)(Facebookvipmatlivestreamv2);
