import React, { Component, Fragment } from 'react';
import {
  BrowserRouter as Router,
  // Switch,
  // Route,
  Link
} from "react-router-dom";
import {
	// Button,
	// Modal,
	// ModalHeader,
	// ModalBody,
	// Form,
	// FormGroup,
	// Label,
	// Input,
	// NavLink,
	Alert
} from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { forgotpassword, resetpassword, checkTokenReset } from '../../actions/authActions';
import { clearErrors } from '../../actions/errorActions';
import json_lf20_dbqrrD from '../../iconlanding/9/lf20_dbqrrD.json';
import png_7p0egmj9o1tdri34 from '../../iconlanding/9/7p0egmj9o1tdri34.png';
import png_hnozb6cxgibbay25 from '../../iconlanding/9/hnozb6cxgibbay25.png';
import Lottie from 'react-lottie-player';
import { RiShieldCheckLine, RiMoneyDollarBoxLine, RiHeadphoneLine, RiShoppingBasket2Line } from "react-icons/ri";
import { AiOutlineUserAdd, AiOutlineUser } from "react-icons/ai";
import parse from 'html-react-parser';

class ForgotpasswordModal extends Component {
	state = {
		// modal: false,
		username: '',
		email: '',
		password: '',
		repeatpassword: '',
		msg: null,
		msg2: null,
		checktoken: 1,
	}
	
	static propTypes =  {
		auth: PropTypes.object.isRequired,
		error: PropTypes.object.isRequired,
		register: PropTypes.func.isRequired,
		clearErrors: PropTypes.func.isRequired
	}
	
	componentDidMount() {
		if (this.props.action_reset === '/resetpassword') {
			var search_param = new URLSearchParams(window.location.search);
			var token = search_param.get('token');
			if (token == null) {
				token = '';
			}
			var data = {
				token,
			};
			this.props.checkTokenReset(data);
		}
	}
	
	componentDidUpdate(prevProps) {
		const { error } = this.props;
		if (error !== prevProps.error) {
			// Check for register error
			if (error.id === 'FORGOTPASSWORD_SEND_FAIL' || error.id === 'RESETPASSWORD_SEND_FAIL') {
				this.setState({ msg: error.msg.msg, msg2: null });
			} else if (error.id === 'FORGOTPASSWORD_SEND_SUCCESS' || error.id === 'RESETPASSWORD_SEND_SUCCESS') {
				this.setState({ msg: null, msg2: error.msg.msg });
			} else if (error.id === 'TOKEN_RESET_SUCCESS') {
				this.setState({ checktoken: 1 });
			} else if (error.id === 'TOKEN_RESET_FAIL') {
				this.setState({ checktoken: 2 });
			} else {
				this.setState({ msg: null, msg2: null });
			}
		}
	}
	
	clearAlert = () => {
		// Clear errors
		this.props.clearErrors();
	}
	
	onChange = e => {
		this.setState({ [e.target.name]: e.target.value });
	};
	
	onSubmit = e => {
		e.preventDefault();
		
		const { username, email, password, repeatpassword } = this.state;
		var search_param = new URLSearchParams(window.location.search);
		var token = search_param.get('token');
		if (token == null) {
			token = '';
		}
		
		if (this.props.action_reset === '/resetpassword') {
			var newUser = {
				token,
				password,
				repeatpassword,
			};
			this.props.resetpassword(newUser);
		} else {
			var newUser = {
				username,
				email,
			};
			this.props.forgotpassword(newUser);
		}
	};
	
	render() {
		let loading = this.props.auth.loading;
		const domainSite = window.location.hostname;
		var config_website = {};
		if (localStorage.getItem("confignavbar") != null) {
			config_website = JSON.parse(localStorage.getItem("confignavbar"));
		} else {
			config_website = this.props.auth.config_website;
		}
		var search_param = new URLSearchParams(window.location.search);
		var token = search_param.get('token');
		if (token == null) {
			token = '';
		}
		var html_form = "";
		if ((this.props.action_reset === '/resetpassword' && token.trim() == '') || (this.props.action_reset === '/resetpassword' && this.state.checktoken == 2)) {
			html_form = (
				<Fragment>
					<div className="alert alert-danger d-flex align-items-center justify-content-between" role="alert" >
						<div className="flex-fill mr-3">
							<p className="mb-0">Link đặt lại mật khẩu đã hết hạn! #tk</p>
						</div>
						<div className="flex-00-auto">
							<i className="fa fa-fw fa-exclamation-circle"></i>
						</div>
					</div>
				</Fragment>
			)
		} else {
			html_form = (
				<Fragment>
					<div className="row">
						<div className="col-lg-12 lgb-title">
							<Lottie loop animationData={json_lf20_dbqrrD} play style={{"width": "65px", "height": "65px"}} />
						</div>
						<div className="lgb-text ml-2">
							<h3 style={{"color": "#fff"}}>
								{
									this.props.action_reset === '/resetpassword' ?
										"Đặt lại mật khẩu "
									:
										"Quên mật khẩu "
								}
							</h3>
							<span>Xin vui lòng điền đầy đủ thông tin.</span>
						</div>
					</div>
					
					{loading ? (
						<div className="preloader">
							<div className="spinner-grow spinner-grow-sm text-primary" role="status" style={{ "marginTop": "50%" }}>
							  <span className="sr-only"></span>
							</div>
							<div className="spinner-grow spinner-grow-sm text-danger" role="status" style={{ "marginTop": "50%" }}>
							  <span className="sr-only">.</span>
							</div>
							<div className="spinner-grow spinner-grow-sm text-warning" role="status" style={{ "marginTop": "50%" }}>
							  <span className="sr-only"></span>
							</div>
						</div>
					):('')}
					{this.state.msg ? (
						<div className="alert alert-warning d-flex align-items-center justify-content-between" role="alert" >
						  <div className="flex-fill mr-3">
							<p className="mb-0">{parse(this.state.msg)}</p>
						  </div>
						  <div className="flex-00-auto">
							<i className="fa fa-fw fa-exclamation-circle"></i>
						  </div>
						</div>
					  ) : null}
					{this.state.msg2 ? (
						<div className="alert d-flex align-items-center justify-content-between" role="alert" style={{"color": "#445e27", "backgroundColor": "#e6f0db", "borderColor": "#dceacd"}} >
							<div className="flex-fill mr-3">
								<p className="mb-0">{parse(this.state.msg2)}</p>
							</div>
						</div>
					) : null}
					<form className="registration-form" onSubmit={this.onSubmit} method="POST" >
						{
							this.props.action_reset === '/resetpassword' ?
								<Fragment>
									<div className="form-group">
										<label>Mật khẩu</label>
										<input name="password" placeholder="Nhập mật khẩu" required="" id="password" type="password" className="form-control" onChange={this.onChange} />
									</div>
									<div className="form-group">
										<label>Xác nhận mật khẩu</label>
										<input name="repeatpassword" placeholder="Nhập lại mật khẩu" required="" id="repeatpassword" type="password" className="form-control" onChange={this.onChange} />
									</div>
									
									<button type="submit" name="login_btn" className="btn btn-secondary btn-lg btn-block mt-3" style={{"marginTop":"16px"}}>Đặt lại mật khẩu</button>
								</Fragment>
							:
								<Fragment>
									<div className="form-group">
										<label>Tài khoản</label>
										<input name="username" placeholder="Nhập tài khoản" required="" id="username" type="text" className="form-control" onChange={this.onChange} />
									</div>
									<div className="form-group">
										<label>Email</label>
										<input name="email" placeholder="Nhập email" required="" id="email" type="email" className="form-control" onChange={this.onChange} />
									</div>
									
									<button type="submit" name="login_btn" className="btn btn-secondary btn-lg btn-block mt-3" style={{"marginTop":"16px"}}>Gửi</button>
								</Fragment>
						}
					</form>
				</Fragment>
			)
		}
		return (
			<Router>
				<Fragment>
					<div className="landingcus9" style={{"zoom": "122%"}}>
						<div className="guest-body">
							<div className="header">
								<div className="container m-flex">
									<div className="row" style={{"height": "99px"}}>
										<div className="col-auto align-self-center">
											<div className="row">
												<div className="col align-self-center">
													<div className="site-name">
														<a className="navbar-brand" href="/" style={{"fontSize": "30px"}}>
															{domainSite}
														</a>
													</div>
												</div>
											</div>
										</div>
										<div className="col d-none d-lg-block">
											<div className="head-menu">
												<div className="row">
													<div className="col text-lg-right mh-fullw">
														<div className="header-menu">
															<ul>
																<li className="active">
																	<Link to="/services" onClick={this.props.action_service}>Dịch vụ</Link>
																</li>
															</ul>
														</div>
													</div>
													<div className="col-auto align-self-center mh-fullw">
														<a href="/" className="btn btn-outline">
															Đăng nhập
														</a>
													</div>
													<div className="col-auto align-self-center mh-fullw">
														<Link to="/" className="btn btn-secondary" onClick={this.props.action_register}>
															<RiShieldCheckLine/> Đăng ký
														</Link>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="app-navbar">
								<div className="row">
									<div className="col text-center align-self-center">
										<Link to="/services" onClick={this.props.action_service}>
											<RiShoppingBasket2Line />
											<div className="navbar-texts">Dịch vụ</div>
										</Link>
									</div>
									<div className="col text-center align-self-center">
										<Link to="/" onClick={this.props.action_register}>
											<AiOutlineUserAdd />
											<div className="navbar-texts">Đăng ký</div>
										</Link>
									</div>
									<div className="col text-center align-self-center">
										<a href="/">
											<AiOutlineUser />
											<div className="navbar-texts">Đăng nhập</div>
										</a>
									</div>
								</div>
							</div>
							<div className="home-head">
								<div className="home-head-bg" style={{"height": "125px"}}></div>
							</div>
							<section className="main-box">
								<div className="container">
									<div className="row align-items-center">
										<div className="col-lg-6 d-none d-md-block">
											<div className="main-box-img d-none d-md-block" style={{"background": "url(" + png_7p0egmj9o1tdri34 + ") no-repeat center center"}}>
												<img src={png_hnozb6cxgibbay25} className="img-fluid floating" />
											</div>
										</div>
										<div className="col-lg-6">
											<div className="loginSide">
												<div className="login-box">
													{html_form}
												</div>
											</div>
										</div>
									</div>
								</div>
							</section>
							<footer className="footer">
								<div className="container">
									<div className="footer-top">
										<p>Bạn đã sẵn sàng bắt đầu với chúng tôi?
										</p>
										<Link to="/" className="btn btn-outline" onClick={this.props.action_register} >ĐĂNG KÝ <i className="fas fa-angle-right ml-2"></i>
										</Link>
									</div>
									<div className="container">
										<div className="row">
											<div className="col-md-6">
												<span className="logo">
													<div className="navbar-brand" style={{"paddingTop": "7.5px"}}>
														<a className="link-fx text-white font-w700 font-size-h3" href="/">
															<span className="text-white">VIPFB18</span>
															<span className="text-white">.COM</span>
														</a>
													</div>
												</span>
												<p className="font-italc" style={{"color": "#fff", "display": "inline-block", "fontSize": "15px", "opacity": ".8"}}>Vipfb18.com đã có nhiều năm hoạt động trong lĩnh vực Online Maketing, nắm bắt được sự thay đổi và định hứng được sự phát triển của thị trường, VIPFB18.COM đi đầu trong lĩnh vực online maketing, cung cấp đa dạng các dịch vụ, giúp người dùng luôn tiếp cận được với mức giá phải chăng và hiệu quả maketing tối ưu nhất. </p>
											</div>
											<div className="col-md-6 text-center-md">
												<div className="footer-support-panel" style={{"width": "50%", "margin": "0 auto"}}>
													<h3 className="title text-white">Hỗ trợ</h3>
													<ul>
														<li>
															<a href="https://facebook.com/102716672125708" className="card-body hvr-icon-pop" target="_blank" style={{"color": "#fff", "padding": "10px 0","display": "inline-block", "fontSize": "15px", "opacity": ".8"}}>
																<i className="fa fa-comments"></i> Fanpage hỗ trợ
															</a>
														</li>
														<li>
															<a href="tel:0389718987" target="_blank" style={{"color": "#fff", "padding": "10px 0","display": "inline-block", "fontSize": "15px", "opacity": ".8"}}>
																<i className="fa fa-phone-alt"></i> 0389718987 (Mr. Thành)
															</a>
														</li>
													</ul>
												</div>
											</div>
										</div>
										<div className="footer-bottom"> Copyright 2021 © Ngô Văn Thành  - All rights reserved. <br/>
										</div>
									</div>
								</div>
							</footer>
						</div>
					</div>
				</Fragment>
			</Router>
		);
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	error: state.error
})

export default connect(mapStateToProps, { forgotpassword, resetpassword, checkTokenReset, clearErrors })(ForgotpasswordModal);