import {
	LOAD_BSVIO,
	UPDATE_BSVIO,
	UPDATE_BSVIO_TAB,
	LOAD_PRICE_BSVIO,
	LIMIT_PAGI_BSLIO,
	TYPE_BSVIO,
	LOADING_BSVIO
} from "../actions/types";

const date7dnext = () => {
	var result = new Date();
  	result.setDate(result.getDate() + 7);
  	return result;
}

const initialState = {
	"taotientrinh": {
		"lhibv": "",
		"lsct": "1",
		"cdbh": "false",
		"slct": "100",
		"gtmtt": "0",
		"gc": "",
	},
	"changetab": 0,
	"listtientrinh": [],
	// "tanggiathem": {
		// "id": "",
		// "lhibv": "",
		// "price_per": 0,
		// "price": 0
	// },
	"price": {
		buffseedingviewinstagramorder: {
			min_bh_web: 0,
			prices_web: 0,
		},
		buffseedingviewinstagramorder_sv2: {
			min_bh_web: 0,
			prices_web: 0,
		},
		buffseedingviewinstagramorder_sv3: {
			min_bh_web: 0,
			prices_web: 0,
		},
		buffseedingviewinstagramorder_sv4: {
			min_bh_web: 0,
			prices_web: 0,
		},
	},
	"limit_pagi": 0,
	"type": 0,
	"loading": false,
	instagrambuffview_sv1_mo_dong: 1,
	instagrambuffview_sv2_mo_dong: 1,
	instagrambuffview_sv3_mo_dong: 1,
	instagrambuffview_sv4_mo_dong: 1,
};

export default function(state = initialState, action) {
	switch (action.type) {
		case LOAD_BSVIO:
			return {
				...state,
				"listtientrinh": action.payload
			};
		case UPDATE_BSVIO:
			return {
				...state,
				"taotientrinh": action.payload
			}
			
		case UPDATE_BSVIO_TAB:
			return {
				...state,
				"changetab": action.payload
			};
		case LOAD_PRICE_BSVIO:
			return {
				...state,
				"price": merDataPricePage(action.payload),
			};
		case LIMIT_PAGI_BSLIO:
			return {
				...state,
				"limit_pagi": action.payload
			};
		case TYPE_BSVIO:
			return {
				...state,
				"type": action.payload
			};
		case LOADING_BSVIO:
			return {
				...state,
				"loading": action.payload
			};
		case 'CONFIG_MO_DONG_BSVIO':
			return {
				...state,
				instagrambuffview_sv1_mo_dong: action.payload.instagrambuffview_sv1_mo_dong,
				instagrambuffview_sv2_mo_dong: action.payload.instagrambuffview_sv2_mo_dong,
				instagrambuffview_sv3_mo_dong: action.payload.instagrambuffview_sv3_mo_dong,
				instagrambuffview_sv4_mo_dong: action.payload.instagrambuffview_sv4_mo_dong,
			};
		default:
		  return state;
	}
}

function merDataPricePage(data) {
	let dataNew = {
		...initialState.price
	}
	if (data.length > 0) {
		data.forEach(function(v, i){
			if (v.name_table === 'buffseedingviewinstagramorder') {
				dataNew['buffseedingviewinstagramorder'] = v;
				initialState.taotientrinh.gtmtt = v.prices_web;
			} else if (v.name_table === 'buffseedingviewinstagramorder_sv2') {
				dataNew['buffseedingviewinstagramorder_sv2'] = v;
			} else if (v.name_table === 'buffseedingviewinstagramorder_sv3') {
				dataNew['buffseedingviewinstagramorder_sv3'] = v;
			} else if (v.name_table === 'buffseedingviewinstagramorder_sv4') {
				dataNew['buffseedingviewinstagramorder_sv4'] = v;
			}
		});
	};
	return dataNew;
}
