import {
	LOAD_BSLV,
	UPDATE_BSLV,
	UPDATE_BSLV_GETID,
	UPDATE_BSLV_TAB,
	UPDATE_BSLV_TGT,
	LOAD_PRICE_BSLV,
	LIMIT_PAGI_BSLV,
	TYPE_BSLV,
	LOADING_BSLV
} from "../actions/types";

const initialState = {
	"taotientrinh": {
		"lhi": "",
		"loai": "sub",
		"goi": "3",
		"slct": "100",
		"gtmtt": "0",
		"gc": "",
		"camxuc": "like",
		"ndcmt": "",
	},
	"changetab": 0,
	"listtientrinh": [],
	"tanggiathem": {
		"id": "",
		"orders_id": "",
		"per_price": 0,
		"price": 0,
		"quantity": 0,
		"loai": "",
	},
	"price": {
		sub_3k: {
			min_bh_web: 0,
			prices_web: 0,
		},
		sub_5k: {
			min_bh_web: 0,
			prices_web: 0,
		},
		sub_10k: {
			min_bh_web: 0,
			prices_web: 0,
		},
		like_page_3k: {
			min_bh_web: 0,
			prices_web: 0,
		},
		like_page_5k: {
			min_bh_web: 0,
			prices_web: 0,
		},
		like_page_10k: {
			min_bh_web: 0,
			prices_web: 0,
		},
		like_post: {
			min_bh_web: 0,
			prices_web: 0,
		},
		like_post_sv2: {
			min_bh_web: 0,
			prices_web: 0,
		},
		like_post_sv3: {
			min_bh_web: 0,
			prices_web: 0,
		},
		comment_post: {
			min_bh_web: 0,
			prices_web: 0,
		},
		sub_vip: {
			min_bh_web: 0,
			prices_web: 0,
		}
	},
	"limit_pagi": 0,
	"type": 0,
	"loading": false,
	"data_sub_like_v2": [],
	facebookbuffsubv2sale_sv1_mo_dong: 1,
	facebookbuffsubv2sale_sv3_mo_dong: 1,
	facebookbuffsubv2sale_vip_mo_dong: 1,
	facebookbufflikefanpagev2sale_sv1_mo_dong: 1,
	facebookbufflikefanpagev2sale_sv3_mo_dong: 1,
	facebookbufflikepostv2sale_sv1_mo_dong: 1,
	facebookbufflikepostv2sale_sv2_mo_dong: 1,
	facebookbufflikepostv2sale_sv3_mo_dong: 1,
	facebookbuffcommentpostv2sale_sv1_mo_dong: 1,
};

export default function(state = initialState, action) {
	switch (action.type) {
		case LOAD_BSLV:
			return {
				...state,
				"listtientrinh": action.payload.data,
				"data_sub_like_v2": action.payload.data_sub_like_v2
			};
		case UPDATE_BSLV:
			return {
				...state,
				"taotientrinh": action.payload
			};
		case UPDATE_BSLV_GETID:
			return {
				...state,
				"taotientrinh": {
					...state.taotientrinh,
					"lhi": action.payload
				}
			};
		case UPDATE_BSLV_TAB:
			return {
				...state,
				"changetab": action.payload
			};
		case UPDATE_BSLV_TGT:
			return {
				...state,
				"tanggiathem": {
					...state.tanggiathem,
					"id": action.payload.id,
					"orders_id": action.payload.orders_id,
					"per_price": action.payload.per_price,
					"price": action.payload.price,
					"quantity": action.payload.quantity,
					"loai": action.payload.loai,
				}
			};
		case LOAD_PRICE_BSLV:
			return {
				...state,
				"price": merDataPricePage(action.payload),
			};
		case LIMIT_PAGI_BSLV:
			return {
				...state,
				"limit_pagi": action.payload
			};
		case TYPE_BSLV:
			return {
				...state,
				"type": action.payload
			};
		case LOADING_BSLV:
			return {
				...state,
				"loading": action.payload
			};
		case 'CONFIG_MO_DONG_BSLV':
			return {
				...state,
				facebookbuffsubv2sale_sv1_mo_dong: action.payload.facebookbuffsubv2sale_sv1_mo_dong,
				facebookbuffsubv2sale_sv3_mo_dong: action.payload.facebookbuffsubv2sale_sv3_mo_dong,
				facebookbuffsubv2sale_vip_mo_dong: action.payload.facebookbuffsubv2sale_vip_mo_dong,
				facebookbufflikefanpagev2sale_sv1_mo_dong: action.payload.facebookbufflikefanpagev2sale_sv1_mo_dong,
				facebookbufflikefanpagev2sale_sv3_mo_dong: action.payload.facebookbufflikefanpagev2sale_sv3_mo_dong,
				facebookbufflikepostv2sale_sv1_mo_dong: action.payload.facebookbufflikepostv2sale_sv1_mo_dong,
				facebookbufflikepostv2sale_sv2_mo_dong: action.payload.facebookbufflikepostv2sale_sv2_mo_dong,
				facebookbufflikepostv2sale_sv3_mo_dong: action.payload.facebookbufflikepostv2sale_sv3_mo_dong,
				facebookbuffcommentpostv2sale_sv1_mo_dong: action.payload.facebookbuffcommentpostv2sale_sv1_mo_dong,
			};
		default:
		  return state;
	}
}

function merDataPricePage(data) {
	let dataNew = {
		...initialState.price
	}
	if (data.length > 0) {
		data.forEach(function(v, i){
			if (v.name_table === 'buffsublikev2_sub_3k') {
				dataNew['sub_3k'] = v;
			} else if (v.name_table === 'buffsublikev2_sub_5k') {
				dataNew['sub_5k'] = v;
			} else if (v.name_table === 'buffsublikev2_sub_10k') {
				dataNew['sub_10k'] = v;
			} else if (v.name_table === 'buffsublikev2_like_page_3k') {
				dataNew['like_page_3k'] = v;
			} else if (v.name_table === 'buffsublikev2_like_page_5k') {
				dataNew['like_page_5k'] = v;
			} else if (v.name_table === 'buffsublikev2_like_page_10k') {
				dataNew['like_page_10k'] = v;
			} else if (v.name_table === 'buffsublikev2_like_post_sv1') {
				dataNew['like_post'] = v;
			} else if (v.name_table === 'buffsublikev2_like_post_sv2') {
				dataNew['like_post_sv2'] = v;
			} else if (v.name_table === 'buffsublikev2_like_post_sv3') {
				dataNew['like_post_sv3'] = v;
			} else if (v.name_table === 'buffsublikev2_comment_post_sv1') {
				dataNew['comment_post'] = v;
			} else if (v.name_table === 'buffsublikev2_sub_vip') {
				dataNew['sub_vip'] = v;
				initialState.taotientrinh.gtmtt = v.prices_web;
			}
		});
	};
	return dataNew;
}
