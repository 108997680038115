import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import store from '../../../store';
import {
    Modal,
    ModalBody,
    ModalFooter
} from 'reactstrap';
import {EditItem} from '../../../actions/buyproxyAction';

class ModalEditData extends Component {
    state = {
        formdata: {
            notes: this.props.buyproxy.datadetailselect.note,
            id_proxy: this.props.buyproxy.datadetailselect.id_proxy
        }
    }

    changeForm = (e) => {
        this.setState({
            ...this.state,
            formdata: {
                ...this.state.formdata,
                [e.target.name]: e.target.value
            }
        });
    }

    render() {
        const {notes} = this.state.formdata;
        const {loadingblock} = this.props.buyproxy;

        return (
            <Fragment>
                <Modal centered={true} size="lg" toggle={(e) => {this.props.openModal()}} isOpen={this.props.isOpenModal} className={(loadingblock? ' block block-mode-loading' : '')} style={{"backgroundColor": "inherit", "boxShadow": "none"}}>
                    <div className="modal-header">
                        <p className="mb-0 text-dark">Sửa proxy</p>
                        <button onClick={(e) => {this.props.openModal()}} type="button" className="close">×</button>
                    </div>
                    <ModalBody style={{opacity: loadingblock? '.05':'1'}}>
                        <div className="row mb-4">
                            <div className="col-md-12">
                                <label>Tên Proxy (ghi chú):</label>
                                <input name="notes" value={notes} onChange={(e) => {this.changeForm(e)}} className="form-control" placeholder="Nhập tên proxy (ghi chú) để dễ phân biệt các proxy"/> 
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter style={{opacity: loadingblock? '.05':'1'}}>
                        <button type="button" className="btn btn-danger" onClick={(e) => {this.props.openModal()}} >Huỷ</button>
                        <button type="button" className="btn btn-success" onClick={(e) => {this.props.EditItem(this.state.formdata,this.props.openModal)}} >Sửa</button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    buyproxy: state.buyproxy,
});

export default connect(mapStateToProps, {EditItem}) (ModalEditData);
