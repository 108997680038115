import axios from 'axios';
import Swal from 'sweetalert2';

export const creatItemBuffSub = (data) => (dispatch, getState) => {

	let bodysend = {
		dataform: data,
		id_user: getState().auth.user.id,
	};

	Swal.fire({
	  	title: 'Bạn có chắc?',
	  	text: "Bạn có chắc chắn muốn Order "+data.sltang+" FOLLOW với giá: "+data.giatien+" Coin/1 FOLLOW ?",
	  	icon: 'warning',
	  	showCancelButton: true,
	  	confirmButtonColor: '#3085d6',
	  	cancelButtonColor: '#d33',
	  	confirmButtonText: 'Đồng ý',
	  	cancelButtonText: 'Không'
	}).then((result) => {
	  	if (result.value) {
	  		dispatch({
				type: 'LOADING_BLOCK_BUFF_SUB_YT',
				payload: true
			});
			
	  		axios.post('/api/youtube_buffsub/add/', bodysend, tokenConfig(getState))
			.then(res => {
				dispatch({
					type: 'LOADING_BLOCK_BUFF_SUB_YT',
					payload: false
				});
				if (res.data.status === 400) {
					if (Array.isArray(res.data.error.object_id)) {
						openBtComfirmAlert(res.data.error.object_id[0], 'error', 'Lỗi');
					} else {
						openBtComfirmAlert(res.data.message, 'error', 'Lỗi');
					}
				} else {
					openBtComfirmAlert('Thêm thành công', 'success', '');
				}
			});
	  	}
  	});
};

export const getListItemBuffSub = (data) => (dispatch, getState) => {
	const token = getState().auth.token;
	const dataPass = {
	    params: {
	      	id_user: getState().auth.user.id
	    },
	    headers: {
	    	"Content-type": "application/json",
	    }
  	}

	if (token) {
		dataPass.headers['ht-token'] = token;
	}

	dispatch({
		type: 'LOADING_BLOCK_BUFF_SUB_YT',
		payload: true
	});

	axios.get('/api/youtube_buffsub/list/', dataPass)
	.then(res => {
		dispatch({
			type: 'LOADING_BLOCK_BUFF_SUB_YT',
			payload: false
		});
		if (res.data.status === 200) {
			dispatch({
				type: 'LIST_BUFF_SUB_USER_YT',
				payload: res.data.data
			});
		}
	});
}

export const cancelOrderBuffSub = (id_order) => (dispatch, getState) => {
	if (id_order === null) {
		openBtComfirmAlert('Không có order được chọn!', 'error', 'Lỗi');
		return false;
	}

	Swal.fire({
	  	title: 'Bạn có chắc?',
	  	text: "Sau khi hủy bạn sẽ được hoàn lại số lượng chưa tăng sau 1 đến 3 ngày và trừ thêm 5.000 Coin phí để tránh Spam!",
	  	icon: 'warning',
	  	showCancelButton: true,
	  	confirmButtonColor: '#3085d6',
	  	cancelButtonColor: '#d33',
	  	confirmButtonText: 'Đồng ý',
	  	cancelButtonText: 'Không'
	}).then((result) => {
	  	if (result.value) {
	  		let bodysend = {
	  			id_remove: id_order,
	  			id_user: getState().auth.user.id
	  		};
	  		dispatch({
				type: 'LOADING_BLOCK_BUFF_SUB_YT',
				payload: true
			});

	  		axios.post('/api/youtube_buffsub/removeorder/', bodysend, tokenConfig(getState))
			.then(res => {
				dispatch({
					type: 'LOADING_BLOCK_BUFF_SUB_YT',
					payload: false
				});
				if (res.data.status === 200) {
					Swal.fire(
			      		'Thành công!',
			      		'Hủy đơn thành công',
			      		'success'
			    	)
				} else {
					openBtComfirmAlert(res.data.message, 'error', 'Lỗi');
				}
			});
	  	}
	})
}

export const searchDataBuffSub = (params) => (dispatch, getState) => {
	dispatch({
		type: 'LOADING_BLOCK_BUFF_SUB_YT',
		payload: true
	});

	axios.get('/api/youtube_buffsub/filter_data/?object_id='+params, tokenConfig(getState))
	.then(res => {
		dispatch({
			type: 'LOADING_BLOCK_BUFF_SUB_YT',
			payload: false
		});
		if (res.data.status === 200) {
			dispatch({
				type: 'LIST_BUFF_SUB_USER_YT',
				payload: res.data.data
			});
		}
	});
}

export const getDataPriceFunction = (params) => (dispatch, getState) => {
	dispatch({
		type: 'LOADING_BLOCK_BUFF_SUB_YT',
		payload: true
	});
	let dataApi =  axios.get('/api/admin/get_price_function/?name='+params, tokenConfig(getState));
	dataApi.then(res => {
		dispatch({
			type: 'LOADING_BLOCK_BUFF_SUB_YT',
			payload: false
		});
		if (res.data.status === 200) {
			if (res.data.data.length > 0) {
				let dataPrice = res.data.data[0];
				let dataFormOld = getState().buffsubyoutube.formData;
				dispatch({
					type: 'CHANGE_DATA_FORM_YTB_BUFFSUB',
					payload: {
						...dataFormOld,
						giatien: dataPrice.prices_web
					}
				});
				dispatch({
					type: 'DATA_PRICE_IS_FUNCTION_BUFFSUB_YTB',
					payload: dataPrice
				});
			}
		}
	});
}

export const openBtComfirmAlert = (message, type, title = '') => {
	Swal.fire({
		icon: type,
		title: title,
		text: message,
	});
}

export const tokenConfig = getState => {
	// Get token from localhost
	const token = getState().auth.token;
	
	// Headers
	const config = {
		headers: {
			"Content-type": "application/json"
		}
	};
	
	// If token, add to headers
	if (token) {
		config.headers['ht-token'] = token;
	}
	
	return config;
};