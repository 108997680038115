import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import {getDataListItem,DeleteItem,searchDataBuffSub} from '../../../actions/rechargeCardPhoneAction';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

class HistoryRechanrgeCardPhone extends Component {
    state = {
        viewall: 0
    }

    componentDidMount(){
        this.props.getDataListItem();
    }

    getListByLimit = (e) => {
        let valueChange = e.target.value;
        if (valueChange !== '') {
            this.props.getDataListItem(this.state.viewall, valueChange);
        }
    }

    searchDataBuff = (e) => {
      if (e.key === 'Enter') {
            let value_search = e.target.value;
            this.props.searchDataBuffSub(value_search);
        }
    }

    render() {
        const {listItem} = this.props.rechargeCardPhone;

        const columns = [{
          dataField: '',
          text: 'STT',
          sort: true,
          formatter: (cell, row, rowIndex) =>
            <span>{rowIndex+1}</span>
        },{
          dataField: 'name_telecom',
          text: 'Nhà mạng',
          sort: true,
          formatter: (cell, row, rowIndex) => 
            <Fragment>
                {cell === 'VTT' ? 'Viettel' : cell === 'MBS' ? 'Mobilefone' : cell === 'VNP' ? 'Vina' : '' }

            </Fragment>
        },{
          dataField: 'level_up',
          text: 'Cấp ưu tiên',
          sort: true,
        },{
          dataField: 'price',
          text: 'Mệnh giá',
          sort: true,
        },{
          dataField: 'phone',
          text: 'Số điện thoại',
          sort: true,
        },{
          dataField: 'type_phone',
          text: 'Loại thuê bao',
          sort: true,
          formatter: (cell, row, rowIndex) => 
            <Fragment>
                {cell === 1 ? 'Trả trước' : 'Trả sau'}
            </Fragment>
        },{
          dataField: 'type_create',
          text: 'Loại nạp',
          sort: true,
          formatter: (cell, row, rowIndex) => 
            <Fragment>
                {cell === 1 ? 'Nạp thẻ bội 50k' : 'Nạp tất cả mệnh giá'}
            </Fragment>
        },{
          dataField: 'type',
          text: 'Trạng thái',
          sort: true,
          formatter: (cell, row, rowIndex) => 
            <Fragment>
                {cell === 1 ? 
                    <span className="badge badge-success"><i className="fas fa-sync-alt mr-1"></i>Đang chạy</span>
                 : cell === 3 ? 
                    <span className="badge badge-warning"><i className="fas fa-sync-alt mr-1"></i>Đang hủy</span>
                 : <span className="badge badge-danger"><i className="fas fa-sync-alt mr-1"></i>Có lỗi</span>}
            </Fragment>
        },{
          dataField: 'thaotac',
          text: 'Thao tác',
          sort: true,
          formatter: (cell, row) => 
            <Fragment>
                <a onClick={(e) => {e.preventDefault();this.props.DeleteItem(row.id)}} href="/#" className="btn btn-hero-danger text-center p-2 mr-1">
                    <i className="fa fa-fw fa-times"></i>
                </a>
            </Fragment>
        }];

        return (
            <Fragment>
                <div className="row mb-4">
                    <div className="col-md-5">
                        { this.props.auth.user.type === 1 ?
                            <Fragment>
                                    <button onClick={() => {this.props.getDataListItem(1);this.setState({...this.state,viewall: 1})}} type="button" className="btn btn-primary mr-1">Xem tất cả</button>
                                    <button onClick={() => {this.props.getDataListItem();this.setState({...this.state,viewall: 0})}} type="button" className="btn btn-primary mr-1">Xem riêng</button>
                            </Fragment>
                        : ''}
                    </div>
                    
                    <div className="col-md-3">
                        <select onChange={(e) => {this.getListByLimit(e)}} className="form-control">
                            <option value="">Số lịch sử hiển thị tối đa</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="200">200</option>
                            <option value="500">500</option>
                            <option value="1000">1000</option>
                        </select>
                    </div>

                    <div className="col-md-4">
                      <input onKeyDown={(e) => {this.searchDataBuff(e)}} type="text" name="search_user" className="form-control" placeholder="Nhập sdt tìm kiếm"/>
                    </div>
                </div>

                <BootstrapTable
                keyField='id'
                bodyStyle={ { border: 'none' } }
                headerStyle={ { border: 'red 1px solid' } }
                data={ listItem }
                striped
                hover
                wrapperClasses="table-responsive"
                columns={ columns }
                pagination={ paginationFactory() } />
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    rechargeCardPhone: state.rechargeCardPhone,
    auth: state.auth
});

export default connect(mapStateToProps, {getDataListItem,DeleteItem,searchDataBuffSub}) (HistoryRechanrgeCardPhone);
