import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import { saveNewPassUser } from '../../../../actions/adminPageAction';
import {
    Modal,
    ModalBody,
    ModalFooter
} from 'reactstrap';

class EditResetPass extends Component {

    state = {
        formdata: {
            newPass: '',
            rePass: '',
            id_user: this.props.adminpage.userEditSelect.id
        }
    }

    changeValue = (e) => {
        this.setState({
            formdata: {
                ...this.state.formdata,
                [e.target.name]: e.target.value
            }
        });
    }

    render() {
        const {newPass,rePass} = this.state.formdata;
        const {isOpenModal} = this.props;
        const {userEditSelect} = this.props.adminpage;
        return (
            <Fragment>
                <Modal centered={true} size="lg" toggle={(e) => {this.props.openModal(e)}} isOpen={isOpenModal}>
                    <div className="modal-header">
                        <p className="mb-0 text-dark">Sửa mật khẩu tài khoản {userEditSelect.username}</p>
                        <button onClick={(e) => {this.props.openModal(e)}} type="button" className="close">×</button>
                    </div>
                    <ModalBody>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Mật khẩu mới</label>
                                    <input autoComplete="new-password" value={newPass} type="password" onChange={(e) => {this.changeValue(e)}} name="newPass" className="form-control" />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Nhập lại mật khẩu</label>
                                    <input autoComplete="new-password" value={rePass} type="password" onChange={(e) => {this.changeValue(e)}} name="rePass" className="form-control" />
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button type="button" className="btn btn-primary" onClick={(e) => {this.props.saveNewPassUser(this.state.formdata, this.props, e)}} >Lưu</button>
                        <button type="button" className="btn btn-danger" onClick={(e) => {this.props.openModal(e)}} >Huỷ</button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        );
    }

}

const mapStateToProps = state => ({
    adminpage: state.adminpage
});

export default connect(mapStateToProps, {saveNewPassUser}) (EditResetPass);
