import React from 'react';
import axios from 'axios';
import {
	LOAD_SHOPEE,
	UPDATE_SHOPEE_TAB,
	UPDATE_SHOPEE,
	// LOAD_PRICE_SHOPEE,
	LIMIT_PAGI_SHOPEE,
	TYPE_SHOPEE,
	LOADING_SHOPEE,
	UPDATE_PRICE_BAOHANH_SHOPEE
} from "../actions/types";
import Swal from 'sweetalert2';
import { loadUser } from "./authActions";

export const loadShopee = (type = 0, page = 1, obj_search = '') => (dispatch, getState) => {
	dispatch({
		type: LOADING_SHOPEE,
		payload: true
	});
	axios.get('/api/shopee?shopee_type=shopee&type='+type+'&page='+page+'&obj_search='+obj_search, tokenConfig(getState))
		.then(res => {
			dispatch({
				type: LOADING_SHOPEE,
				payload: false
			});
			if (res.data.status === 200) {
				dispatch({
					type: LOAD_SHOPEE,
					payload: res.data.data
				});
			}
		});
};

// export const loadPriceFLBBKTT = () => (dispatch, getState) => {
	// axios.get('/api/facebooklocbanbekhongtuongtac/price', tokenConfig(getState))
		// .then(res => {
			// if (res.data.status === 200) {
				// dispatch({
					// type: LOAD_PRICE_FLBBKTT,
					// payload: res.data.data
				// });
			// }
		// });
// };

export const changeTab = (body) => (dispatch, getState) => {
	dispatch({
		type: UPDATE_SHOPEE_TAB,
		payload: body
	});
};

export const updateShopee = (body) => (dispatch, getState) => {
	dispatch({
		type: UPDATE_SHOPEE,
		payload: body
	});
};

export const updateLimitShopee = (body) => (dispatch) => {
	dispatch({
		type: LIMIT_PAGI_SHOPEE,
		payload: body
	})
};

export const updateTypeShopee = (body) => (dispatch) => {
	dispatch({
		type: TYPE_SHOPEE,
		payload: body
	})
};

export const updatePriceBaoHanh = (body) => (dispatch) => {
	dispatch({
		type: UPDATE_PRICE_BAOHANH_SHOPEE,
		payload: body
	})
};

export const addShopee = (body) => (dispatch, getState) => {
	dispatch({
		type: LOADING_SHOPEE,
		payload: true
	});
	axios.post('/api/shopee/add?shopee_type=shopee', body, tokenConfig(getState))
		.then(res => {
			dispatch({
				type: LOADING_SHOPEE,
				payload: false
			});
			dispatch(loadUser());
			if (res.data.status === 200) {
				Swal.fire({
					icon: 'success',
					html: 'Tạo tiến trình thành công'
				});
			} else {
				var message = '';
				if (res.data.error) {
					if (res.data.error.object_id) {
						message = res.data.error.object_id[0];
					} else if (res.data.error.quantity) {
						message = res.data.error.quantity[0];
					} else {
						message = res.data.message;
					}
				} else {
					message = res.data.message;
				}
				Swal.fire({
					icon: 'error',
					title: 'Lỗi',
					text: message,
				});
			}
		});
};

// export const updateFLBBKTTactive = (body) => (dispatch, getState) => {
	// axios.post('/api/facebooklocbanbekhongtuongtac/active', body, tokenConfig(getState))
		// .then(res => {
			// dispatch(loadFLBBKTT());
		// });
// };


export const deleteShopee = (body) => (dispatch, getState) => {
	dispatch({
		type: LOADING_SHOPEE,
		payload: true
	});
	axios.post('/api/shopee/delete?shopee_type=shopee', body, tokenConfig(getState))
		.then(res => {
			dispatch({
				type: LOADING_SHOPEE,
				payload: false
			});
			dispatch(loadShopee());
			if (res.data.status === 200) {
				Swal.fire({
					icon: 'success',
					html: 'Hủy thành công'
				});
			} else {
				var message = '';
				if (res.data.error) {
					if (res.data.error.object_id) {
						message = res.data.error.object_id[0];
					} else if (res.data.error.quantity) {
						message = res.data.error.quantity[0];
					} else {
						message = res.data.message;
					}
				} else {
					message = res.data.message;
				}
				Swal.fire({
					icon: 'error',
					title: 'Lỗi',
					text: message,
				});
			}
		});
};

export const editShopee = (shopee_type, id, status, closeM) => (dispatch, getState) => {
	var body = {};
	if (shopee_type === 'shopee') {
		body = {
			id: id,
			status: status,
		};
	} else {
		Swal.fire({
			icon: 'error',
			html: 'Sửa không hợp lệ'
		});
		return false;
	}
	dispatch({
		type: LOADING_SHOPEE,
		payload: true
	});
	axios.post('/api/shopee/edit?shopee_type=shopee', body, tokenConfig(getState))
		.then(res => {
			dispatch({
				type: LOADING_SHOPEE,
				payload: false
			});
			dispatch(loadShopee());
			closeM();
			if (res.data.status === 200) {
				Swal.fire({
					icon: 'success',
					html: 'Cập nhật thành công'
				});
			} else {
				var message = '';
				if (res.data.error) {
					if (res.data.error.object_id) {
						message = res.data.error.object_id[0];
					} else if (res.data.error.quantity) {
						message = res.data.error.quantity[0];
					} else {
						message = res.data.message;
					}
				} else {
					message = res.data.message;
				}
				Swal.fire({
					icon: 'error',
					title: 'Lỗi',
					text: message,
				});
			}
		});
};

export const getInfoShopee = (linkshop) => (dispatch, getState) => {
	let bodysend = {
		linkshop: linkshop,
	}
	return axios.post('/api/shopee/getinfo', bodysend, tokenConfig(getState));
};

// export const UpdateCookie = (cookiefb, dataidfb, dataid, closeM) => (dispatch, getState) => {
	// let body = {
		// id: dataid,
		// id_user: getState().auth.user.id,
		// cookie: cookiefb,
		// idfb: dataidfb
	// };
	// dispatch({
		// type: LOADING_FLBBKTT,
		// payload: true
	// });
    // axios.post('/api/facebooklocbanbekhongtuongtac/updatecookie', body, tokenConfig(getState))
		// .then(res => {
			// dispatch({
				// type: LOADING_FLBBKTT,
				// payload: false
			// });
			// closeM();
			// if (res.data.status === 200) {
				// dispatch(loadFLBBKTT());
				// Swal.fire({
					// icon: 'success',
					// html: 'Cập nhật thành công'
				// });
			// }
			// if (res.data.status === 400) {
				// var message = '';
				// if (res.data.error) {
					// if (res.data.error.object_id) {
						// message = res.data.error.object_id[0];
					// } else if (res.data.error.quantity) {
						// message = res.data.error.quantity[0];
					// }
				// } else {
					// message = res.data.message;
				// }
				// Swal.fire({
					// icon: 'error',
					// title: 'Lỗi',
					// text: message,
				// });
			// }
		// });
// };

export const tokenConfig = getState => {
	// Get token from localhost
	const token = getState().auth.token;
	// Headers
	const config = {
		headers: {
			"Content-type": "application/json"
		}
	};
	
	// If token, add to headers
	if (token) {
		config.headers['ht-token'] = token;
	}
	
	return config;
};