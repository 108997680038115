import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import MuaClone from "./tabMuabanclone/muaclone";
import LichSu from "./tabMuabanclone/lichsu";

class Muabanclone extends Component {
	static propTypes =  {
		
	};
	render() {
		const { loading } = this.props.Muabanclone;
		return (
			<Fragment>
				<div className={"block block-bordered shadow"+ (loading? ' block-mode-loading' : '')}>
					<div className="block-header block-header-default">
						<h3 className="block-title">Mua Clone</h3>
					</div>
					<div className="block-content">
						<MuaClone />
					</div>
				</div>
				<div className={"block block-bordered shadow"+ (loading? ' block-mode-loading' : '')}>
					<div className="block-header block-header-default">
						<h3 className="block-title">Lịch sử</h3>
					</div>
					<div className="block-content">
						<LichSu />
					</div>
				</div>
			</Fragment>
		);
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	Muabanclone: state.Muabanclone,
});
const mapDispatchToProps = {  };

export default connect(mapStateToProps, mapDispatchToProps)(Muabanclone);
