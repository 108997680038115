import React, { Component, Fragment } from 'react';
import {
  BrowserRouter as Router,
  // Switch,
  // Route,
  Link
} from "react-router-dom";
import {
	// Button,
	// Modal,
	// ModalHeader,
	// ModalBody,
	// Form,
	// FormGroup,
	// Label,
	// Input,
	// NavLink,
	Alert
} from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { register, getSevices } from '../../actions/authActions';
import { clearErrors } from '../../actions/errorActions';
import parse from 'html-react-parser';

class RegisterModal extends Component {
	state = {
		// modal: false,
		username: '',
		email: '',
		password: '',
		repeatpassword: '',
		facebook_id: '4',
		phone: '',
		msg: null,
	}
	
	static propTypes =  {
		auth: PropTypes.object.isRequired,
		error: PropTypes.object.isRequired,
		register: PropTypes.func.isRequired,
		clearErrors: PropTypes.func.isRequired
	}
	componentDidMount(){
		this.props.getSevices();
	}
	
	componentDidUpdate(prevProps) {
		const { error } = this.props;
		if (error !== prevProps.error) {
			// Check for register error
			if (error.id === 'REGISTER_FAIL') {
				this.setState({ msg: error.msg.msg });
			} else {
				this.setState({ msg: null });
			}
		}
	}
	
	clearAlert = () => {
		// Clear errors
		this.props.clearErrors();
	}
	
	onChange = e => {
		this.setState({ [e.target.name]: e.target.value });
	};
	
	onSubmit = e => {
		e.preventDefault();
		
		const { username, email, password, repeatpassword, facebook_id, phone } = this.state;
		
		// Create user object
		const newUser = {
			username,
			email,
			password,
			repeatpassword,
			facebook_id,
			phone
		};
		// console.log(newUser);
		// Attempt to register
		this.props.register(newUser);
	};
	
	// sortArrayByKey(allservices, p) {
		// return allservices.slice(0).sort(function(a,b) {
			// return (a[p] > b[p]) ? 1 : (a[p] < b[p]) ? -1 : 0;
		// });
	// }
	
	onRenderTr = (typetr) => {
		let { allservices } = this.props.auth;
		// var new_allservices = this.sortArrayByKey(allservices, 'name');
		return allservices.map(function(value,index){
			if (value.name.toLowerCase().indexOf(typetr.toLowerCase()) > -1) {
				return (
					<Fragment key={index}>
						<tr className="">
							<td className="text-center text-muted" style={{"width": "10%"}}>{index}</td>
							<td>
								<div className="title"> {value.name} </div>
							</td>
							<td className="text-center" style={{"width": "10%"}}>
								<div> {value.price} </div>
							</td>
							<td className="text-center" style={{"width": "15%"}}>
								{
									value.name === 'Facebook ad breaks giờ xem' ?
										'5000 / 1000000'
									: value.name === 'Facebook ad breaks tương tác' ?
										'15000 / 1000000'
									: value.name === 'Facebook ad breaks view đề xuất' ?
										'1000 / 1000000'
									: value.name === 'Facebook buff comment post v2 (sv1)' ?
										'10 / 100'
									: value.name === 'Facebook buff like fanpage' ?
										'200 / 20000'
									: value.name === 'Facebook buff like fanpage (sv3)' ?
										'100 / 20000'
									: value.name === 'Facebook buff like fanpage (sv4)' ?
										'100 / 20000'
									: value.name === 'Facebook buff like fanpage (sv5)' ?
										'500 / 50000'
									: value.name === 'Facebook buff like fanpage pro' ?
										'100 / 100000'
									: value.name === 'Facebook buff like fanpage sale (sv1)' ?
										'100 / 50000'
									: value.name === 'Facebook buff like fanpage sale (sv2)' ?
										'100 / 50000'
									: value.name === 'Facebook buff like fanpage sale (sv3)' ?
										'100 / 50000'
									: value.name === 'Facebook buff like fanpage v2 (sv1)' ?
										'100 / 200000'
									: value.name === 'Facebook buff like fanpage v2 (sv2)' ?
										'100 / 20000'
									: value.name === 'Facebook buff like fanpage v2 (sv3)' ?
										'100 / 5000'
									: value.name === 'Facebook buff like post v2 (sv1)' ?
										'50 / 1000'
									: value.name === 'Facebook buff like post v2 (sv2)' ?
										'50 / 3000'
									: value.name === 'Facebook buff like post v2 (sv3)' ?
										'50 / 100000'
									: value.name === 'Facebook buff mắt livestream (sv1)' ?
										'50 / 240'
									: value.name === 'Facebook buff mắt livestream (sv2)' ?
										'50 / 240'
									: value.name === 'Facebook buff mắt livestream (sv3)' ?
										'50 / 240'
									: value.name === 'Facebook buff mem group (sv1)' ?
										'500 / 50000'
									: value.name === 'Facebook buff mem group (sv2)' ?
										'1000 / 50000'
									: value.name === 'Facebook buff mem group (sv3)' ?
										'1000 / 50000'
									: value.name === 'Facebook buff mem group (sv4)' ?
										'1000 / 50000'
									: value.name === 'Facebook buff seeding checkin (sv3)' ?
										'1 / 150'
									: value.name === 'Facebook buff seeding checkin (sv4)' ?
										'1 / 150'
									: value.name === 'Facebook buff seeding review (sv1)' ?
										'5 / 150'
									: value.name === 'Facebook buff seeding review (sv2)' ?
										'5 / 60'
									: value.name === 'Facebook buff share live group (sv vip)' ?
										'100 / 50000'
									: value.name === 'Facebook buff share live group (sv1)' ?
										'100 / 50000'
									: value.name === 'Facebook buff share live group (sv2)' ?
										'100 / 50000'
									: value.name === 'Facebook buff sub chậm (basic)' ?
										'1000 / 500000'
									: value.name === 'Facebook buff sub chậm (v1)' ?
										'1000 / 500000'
									: value.name === 'Facebook buff sub chậm (v2)' ?
										'500 / 20000'
									: value.name === 'Facebook buff sub chậm (v3)' ?
										'500 / 100000'
									: value.name === 'Facebook buff sub sale (sv1)' ?
										'100 / 50000'
									: value.name === 'Facebook buff sub sale (sv2)' ?
										'100 / 50000'
									: value.name === 'Facebook buff sub sale (sv3)' ?
										'500 / 1000'
									: value.name === 'Facebook buff sub sale (sv4)' ?
										'1000 / 10000'
									: value.name === 'Facebook buff sub speed (sv1)' ?
										'100 / 50000'
									: value.name === 'Facebook buff sub speed (sv2)' ?
										'1000 / 1000000'
									: value.name === 'Facebook buff sub speed (sv3)' ?
										'100 / 250000'
									: value.name === 'Facebook buff sub speed (sv4)' ?
										'1000 / 100000'
									: value.name === 'Facebook buff sub speed (sv5)' ?
										'100 / 50000'
									: value.name === 'Facebook buff sub speed (sv5)' ?
										'100 / 50000'
									: value.name === 'Facebook buff sub speed (sv6)' ?
										'100 / 15000'
									: value.name === 'Facebook buff sub speed (sv7)' ?
										'100 / 20000'
									: value.name === 'Facebook buff sub speed (sv8)' ?
										'1000 / 60000'
									: value.name === 'Facebook buff sub speed (sv9)' ?
										'1000 / 50000'
									: value.name === 'Facebook buff sub v2 (sv vip)' ?
										'100 / 40000'
									: value.name === 'Facebook buff sub v2 (sv1)' ?
										'500 / 50000'
									: value.name === 'Facebook buff sub v2 (sv2)' ?
										'200 / 50000'
									: value.name === 'Facebook buff sub v2 (sv3)' ?
										'1000 / 10000'
									: value.name === 'Facebook buff view story (sv1)' ?
										'100 / 10000'
									: value.name === 'Facebook buff view video (1p)' ?
										'500 / 1000000'
									: value.name === 'Facebook buff view video (3s nhanh)' ?
										'10000 / 1000000'
									: value.name === 'Facebook buff view video (3s)' ?
										'500 / 1000000'
									: value.name === 'Facebook like comment share (angry clone)' ?
										'50 / 1000'
									: value.name === 'Facebook like comment share (angry)' ?
										'10 / 10000'
									: value.name === 'Facebook like comment share (care clone)' ?
										'10 / 1000'
									: value.name === 'Facebook like comment share (care)' ?
										'10 / 10000'
									: value.name === 'Facebook like comment share (comment PRO)' ?
										'10 / 200'
									: value.name === 'Facebook like comment share (comment SV3)' ?
										'10 / 30'
									: value.name === 'Facebook like comment share (comment SV4)' ?
										'10 / 200'
									: value.name === 'Facebook like comment share (comment)' ?
										'5 / 100'
									: value.name === 'Facebook like comment share (haha clone)' ?
										'10 / 1000'
									: value.name === 'Facebook like comment share (haha)' ?
										'10 / 10000'
									: value.name === 'Facebook like comment share (like clone)' ?
										'10 / 1000'
									: value.name === 'Facebook like comment share (like Comment SV2)' ?
										'50 / 20000'
									: value.name === 'Facebook like comment share (like Comment SV3)' ?
										'50 / 20000'
									: value.name === 'Facebook like comment share (like Comment)' ?
										'50 / 20000'
									: value.name === 'Facebook like comment share (like Việt V2)' ?
										'100 / 250000'
									: value.name === 'Facebook like comment share (like Việt V3)' ?
										'50 / 50000'
									: value.name === 'Facebook like comment share (like Việt V4)' ?
										'50 / 50000'
									: value.name === 'Facebook like comment share (like)' ?
										'10 / 10000'
									: value.name === 'Facebook like comment share (love clone)' ?
										'10 / 1000'
									: value.name === 'Facebook like comment share (love)' ?
										'10 / 10000'
									: value.name === 'Facebook like comment share (sad clone)' ?
										'10 / 1000'
									: value.name === 'Facebook like comment share (sad)' ?
										'10 / 10000'
									: value.name === 'Facebook like comment share (share SV2)' ?
										'10 / 10000'
									: value.name === 'Facebook like comment share (share SV3)' ?
										'100 / 10000'
									: value.name === 'Facebook like comment share (share)' ?
										'10 / 10000'
									: value.name === 'Facebook like comment share (wow clone)' ?
										'10 / 1000'
									: value.name === 'Facebook like comment share (wow)' ?
										'10 / 10000'
									: value.name === 'Facebook like page tây (sv1)' ?
										'100 / 100000'
									: value.name === 'Facebook like post tây (sv1)' ?
										'100 / 10000'
									: value.name === 'Facebook sub tây (sv1)' ?
										'100 / 10000'
									: value.name === 'Instagram buff seeding comment (sv1)' ?
										'5 / 60'
									: value.name === 'Instagram buff seeding comment (sv2)' ?
										'5 / 100'
									: value.name === 'Instagram buff seeding like (sv1)' ?
										'50 / 50000'
									: value.name === 'Instagram buff seeding like (sv2)' ?
										'100 / 5000'
									: value.name === 'Instagram buff seeding like (sv3)' ?
										'20 / 10000'
									: value.name === 'Instagram buff seeding like (sv4)' ?
										'50 / 50000'
									: value.name === 'Instagram buff seeding like (sv5)' ?
										'50 / 50000'
									: value.name === 'Instagram buff seeding view (sv1)' ?
										'200 / 10000000'
									: value.name === 'Instagram buff seeding view (sv2)' ?
										'200 / 10000000'
									: value.name === 'Instagram buff seeding view (sv3)' ?
										'500 / 50000'
									: value.name === 'Instagram buff seeding view (sv4)' ?
										'500 / 10000000'
									: value.name === 'Instagram buff sub (sv1)' ?
										'100 / 40000'
									: value.name === 'Instagram buff sub (sv2)' ?
										'100 / 200000'
									: value.name === 'Instagram buff sub (sv3)' ?
										'100 / 500000'
									: value.name === 'Instagram buff sub (sv4)' ?
										'500 / 50000'
									: value.name === 'Instagram buff sub (sv5)' ?
										'500 / 50000'
									: value.name === 'Instagram buff sub (sv6)' ?
										'100 / 40000'
									: value.name === 'Youtube buff comment video (sv1)' ?
										'10 / 6000'
									: value.name === 'Youtube buff comment video (sv2)' ?
										'10 / 6000'
									: value.name === 'Youtube buff giờ xem video (sv1)' ?
										'500 / 4000'
									: value.name === 'Youtube buff giờ xem video (sv2)' ?
										'500 / 4000'
									: value.name === 'Youtube buff like video (sv1)' ?
										'100 / 25000'
									: value.name === 'Youtube buff like video (sv2)' ?
										'20 / 25000'
									: value.name === 'Youtube buff sub (sv1)' ?
										'100 / 1000'
									: value.name === 'Youtube buff sub (sv2)' ?
										'100 / 1000'
									: value.name === 'Youtube buff view video (sv1)' ?
										'1000 / 10000000'
									: value.name === 'Youtube buff view video (sv2)' ?
										'1000 / 30000'
									: value.name === 'Youtube buff view video (sv3)' ?
										'1000 / 30000'
									: value.name === 'Tiktok buff comment' ?
										'5 / 10'
									: value.name === 'Tiktok Buff like (sv1)' ?
										'10 / 10000'
									: value.name === 'Tiktok Buff like (sv2)' ?
										'100 / 10000'
									: value.name === 'Tiktok Buff mắt (sv1)' ?
										'100 / 50000'
									: value.name === 'Tiktok Buff mắt (sv2)' ?
										'100 / 50000'
									: value.name === 'Tiktok Buff share (sv1)' ?
										'10 / 10000'
									: value.name === 'Tiktok Buff share (sv2)' ?
										'10 / 10000'
									: value.name === 'Tiktok Buff sub (sv1)' ?
										'10 / 10000'
									: value.name === 'Tiktok Buff sub (sv2)' ?
										'100 / 5000'
									: value.name === 'Tiktok Buff sub (sv3)' ?
										'100 / 100000'
									: value.name === 'Tiktok Buff view (sv1)' ?
										'1000 / 1000000'
									: value.name === 'Tiktok Buff view (sv2)' ?
										'1000 / 1000000'
									: value.name === 'Tiktok Buff view (sv3)' ?
										'1000 / 1000000'
									: value.name === 'Shopee sub' ?
										'500 / 20000'
									: value.name === 'Shopee tim' ?
										'100 / 10000'
									: value.name === 'Google map đánh giá 1 sao' ?
										'10 / 1000'
									: value.name === 'Google map đánh giá 5 sao' ?
										'10 / 1000'
									: value.name === 'Telegram buff group (random sv1)' ?
										'1000 / 100000'
									: value.name === 'Telegram buff group (random sv2)' ?
										'1000 / 100000'
									: value.name === 'Telegram buff group (theo yêu cầu)' ?
										'1000 / 100000'
									: value.name === 'Twitter follow' ?
										'100 / 20000'
									: value.name === 'Twitter like' ?
										'50 / 20000'
									: '- / -'
								}
							</td>
							{/* <td style={{"width": "6%"}}>
								<button className="btn btn-info btn-sm" type="button" data-toggle="modal" data-target="#service-3829">Details</button>
								<div id="service-3829" className="modal fade" tabindex="-1" role="dialog" style={{"display": "none"}} aria-hidden="true">
									<div id="main-modal-content">
										<div className="modal-right">
											<div className="modal-dialog modal-lg" role="document">
												<div className="modal-content">
													<div className="modal-header bg-pantone">
														<h4 className="modal-title">
															<i className="fe fe-book-open"></i> Instagram Likes 100% Real [ Country - Turkey + Asia] [ 0.2$/K] [ 1k-2k/hour]🔥
														</h4>
														<button type="button" className="close" data-dismiss="modal" aria-label="Close"></button>
													</div>
													<div className="modal-body">
														<div className="form-body">
															<div className="row justify-content-md-center">
																<div className="col-md-12 col-sm-12 col-xs-12">
																	<div className="form-group">
																		<div className="content"> 1k-3k/hour <br/>turkey- asia targeted </div>
																	</div>
																</div>
															</div>
														</div>
													</div>
													<div className="modal-footer">
														<button type="button" className="btn round btn-default btn-min-width mr-1 mb-1" data-dismiss="modal">Cancel</button>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</td> */}
						</tr>
					</Fragment>
				)
			}
		})
	}
	
	onRenderTable = (nametable) => {
		return (
			<Fragment>
				<div className={"col-md-12 col-xl-12 table-cus-" + nametable.toLowerCase()}>
					<div className="card card-services-cus">
						<div className="card-header">
							<h3 className="card-title" style={{"padding": "20px 0px 0px 10px"}}>➡️ {nametable}</h3>
							<div className="card-options">
								<a href="#" className="card-options-collapse" data-toggle="card-collapse">
									<i className="fe fe-chevron-up"></i>
								</a>
								<a href="#" className="card-options-remove" data-toggle="card-remove">
									<i className="fe fe-x"></i>
								</a>
							</div>
						</div>
						<div className="table-responsive">
							<table className="table table-hover table-bordered table-outline table-vcenter card-table" style={{"marginBottom": "0px"}}>
								<thead>
									<tr>
										<th className="text-center w-1">ID</th>
										<th>Name</th>
										<th className="text-center">Giá (vnđ)</th>
										<th className="text-center">Số lượng Min / Max</th>
										{/* <th className="text-center">Description</th> */}
									</tr>
								</thead>
								<tbody>
									{this.onRenderTr(nametable)}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</Fragment>
		)
	}
	
	onChangeServices = e => {
		var key_select = e.target.value;
		if (key_select == 'facebook') {
			document.querySelectorAll('.table-cus-instagram, .table-cus-youtube, .table-cus-tiktok, .table-cus-shopee, .table-cus-google, .table-cus-telegram, .table-cus-twitter').forEach( ele => {
				ele.style.display = "none";
			});
			document.querySelector('.table-cus-facebook').style.display = "block";
		}
		if (key_select == 'instagram') {
			document.querySelectorAll('.table-cus-facebook, .table-cus-youtube, .table-cus-tiktok, .table-cus-shopee, .table-cus-google, .table-cus-telegram, .table-cus-twitter').forEach( ele => {
				ele.style.display = "none";
			});
			document.querySelector('.table-cus-instagram').style.display = "block";
		}
		if (key_select == 'youtube') {
			document.querySelectorAll('.table-cus-facebook, .table-cus-instagram, .table-cus-tiktok, .table-cus-shopee, .table-cus-google, .table-cus-telegram, .table-cus-twitter').forEach( ele => {
				ele.style.display = "none";
			});
			document.querySelector('.table-cus-youtube').style.display = "block";
		}
		if (key_select == 'tiktok') {
			document.querySelectorAll('.table-cus-facebook, .table-cus-instagram, .table-cus-youtube, .table-cus-shopee, .table-cus-google, .table-cus-telegram, .table-cus-twitter').forEach( ele => {
				ele.style.display = "none";
			});
			document.querySelector('.table-cus-tiktok').style.display = "block";
		}
		if (key_select == 'shopee') {
			document.querySelectorAll('.table-cus-facebook, .table-cus-instagram, .table-cus-youtube, .table-cus-tiktok, .table-cus-google, .table-cus-telegram, .table-cus-twitter').forEach( ele => {
				ele.style.display = "none";
			});
			document.querySelector('.table-cus-shopee').style.display = "block";
		}
		if (key_select == 'google') {
			document.querySelectorAll('.table-cus-facebook, .table-cus-instagram, .table-cus-youtube, .table-cus-tiktok, .table-cus-shopee, .table-cus-telegram, .table-cus-twitter').forEach( ele => {
				ele.style.display = "none";
			});
			document.querySelector('.table-cus-google').style.display = "block";
		}
		if (key_select == 'telegram') {
			document.querySelectorAll('.table-cus-facebook, .table-cus-instagram, .table-cus-youtube, .table-cus-tiktok, .table-cus-shopee, .table-cus-google, .table-cus-twitter').forEach( ele => {
				ele.style.display = "none";
			});
			document.querySelector('.table-cus-telegram').style.display = "block";
		}
		if (key_select == 'twitter') {
			document.querySelectorAll('.table-cus-facebook, .table-cus-instagram, .table-cus-youtube, .table-cus-tiktok, .table-cus-shopee, .table-cus-google, .table-cus-telegram').forEach( ele => {
				ele.style.display = "none";
			});
			document.querySelector('.table-cus-twitter').style.display = "block";
		}
		if (key_select == 'all') {
			document.querySelectorAll('.table-cus-facebook, .table-cus-instagram, .table-cus-youtube, .table-cus-tiktok, .table-cus-shopee, .table-cus-google, .table-cus-telegram, .table-cus-twitter').forEach( ele => {
				ele.style.display = "block";
			});
		}
	};
	
	render() {
		let loading = this.props.auth.loading;
		let isAuthenticated = this.props.auth.isAuthenticated;
		const domainSite = window.location.hostname;
		var config_website = {};
		if (localStorage.getItem("confignavbar") != null) {
			config_website = JSON.parse(localStorage.getItem("confignavbar"));
		} else {
			config_website = this.props.auth.config_website;
		}
		
		var html_facebook = this.onRenderTable('Facebook');
		var html_instagram = this.onRenderTable('Instagram');
		var html_youtube = this.onRenderTable('Youtube');
		var html_tiktok = this.onRenderTable('Tiktok');
		var html_shopee = this.onRenderTable('Shopee');
		var html_google = this.onRenderTable('Google');
		var html_telegram = this.onRenderTable('Telegram');
		var html_twitter = this.onRenderTable('Twitter');
		
		
		return (
			<Router>
				<Fragment>
					<div className="landingcus8" style={{"zoom": "122%"}}>
						<div className="gustavo gustavo">
							<nav className="navbar navbar-default navbar-static-top ">
								<div className="container">
									<div className="navbar-header">
										<a className="navbar-brand" href="/" style={{"fontSize": "30px"}}>
											{domainSite}
										</a>
										<ul className="nav navbar-nav navbar-right register-btn-cus" style={{"display": "none"}}>
											{
												!isAuthenticated ?
													<Fragment>
														<li className="signupBtn">
															<Link to="/" className="nav_link" onClick={this.props.action_register}>
																Đăng ký
															</Link>
														</li>
													</Fragment>
												:
													<Fragment>
														<li className="signupBtn">
															<a href="/" className="nav_link" >
																Quay lại
															</a>
														</li>
													</Fragment>
											}
										</ul>
									</div>
									<div id="navbar" className="collapse navbar-collapse">
										<ul className="nav navbar-nav navbar-right">
											{
												!isAuthenticated ?
													<Fragment>
														<li className="  signinBtn ">
															<a className="nav_link" href="/" >Đăng nhập</a>
														</li>
														<li className="  signupBtn ">
															<Link to="/" className="nav_link" onClick={this.props.action_register}>
																Đăng ký
															</Link>
														</li>
													</Fragment>
												:
													<Fragment>
														<li className="signupBtn">
															<a href="/" className="nav_link" >
																Quay lại
															</a>
														</li>
													</Fragment>
											}
										</ul>
									</div>
								</div>
							</nav>
							<div className="top-banner" style={{"paddingBottom": "0px", "minHeight": "860px", "height": "auto", "background": "#fff"}}>
								<div className="container">
									<div className="row" style={{"paddingTop": "20px"}}>
										<div className="col-md-3">
											<h2 className="page-title" style={{"color": "#495057"}}>
												<i className="fa fa-list" aria-hidden="true" style={{"fontSize": "1.7rem", "marginRight": "10px"}}> </i> 
												Dịch vụ
											</h2>
										</div>
										<div className="col-md-6"></div>
										<div className="col-md-3">
											<div className="form-group ">
												<select name="select_services" className="form-control" onChange={this.onChangeServices}>
													<option value="all">--Chọn dịch vụ--</option>
													<option value="facebook">➡️ Facebook</option>
													<option value="instagram">➡ Instagram</option>
													<option value="youtube">➡️ Youtube</option>
													<option value="tiktok">➡️ Tiktok</option>
													<option value="shopee">➡️ Shopee</option>
													<option value="google">➡️ Google</option>
													<option value="telegram">➡️ Telegram</option>
													<option value="twitter">➡️ Twitter</option>
												</select>
											</div>
										</div>
										{html_facebook}
										{html_instagram}
										{html_youtube}
										{html_tiktok}
										{html_shopee}
										{html_google}
										{html_telegram}
										{html_twitter}
									</div>
								</div>
							</div>
							<footer>
								<div className="footer-top">
									<div className="container">
										<div className="row">
											<div className="col-sm-6">
												<h2>Bạn đã sẵn sàng bắt đầu với chúng tôi?</h2>
											</div>
											<div className="col-sm-6">
												<Link to="/" className="joinBtn" onClick={this.props.action_register} >Tham Gia Ngay</Link>
											</div>
										</div>
									</div>
								</div>
								<div className="footer-main">
									<div className="container">
										<div className="row">
											<div className="col-sm-6">
												<h4 className="widget-title">{domainSite}</h4>
												<p>Đã có 8 năm hoạt động trong lĩnh vực Online Maketing, nắm bắt được sự thay đổi và định hứng được sự phát triển của thị trường, đi đầu trong lĩnh vực online maketing, cung cấp đa dạng các dịch vụ, giúp người dùng luôn tiếp cận được với mức giá phải chăng và hiệu quả maketing tối ưu nhất.</p>
											</div>
											<div className="col-sm-3">
												<h4 className="widget-title">Hữu ích</h4>
												<ul>
													<li>
														<a href="/"><i className="fa fa-angle-right" aria-hidden="true"></i> Sign in</a>
													</li>
													<li>
														<a href="/"><i className="fa fa-angle-right" aria-hidden="true"></i> Sign up</a>
													</li>
												</ul>
											</div>
											<div className="col-sm-3">
												<h4 className="widget-title">Dịch vụ</h4>
												<ul>
													<li>
														<a href=""><i className="fa fa-angle-right" aria-hidden="true"></i> Facebook</a>
													</li>
													<li>
														<a href=""><i className="fa fa-angle-right" aria-hidden="true"></i> Instagram</a>
													</li>
													<li>
														<a href="/"><i className="fa fa-angle-right" aria-hidden="true"></i> Tiktok</a>
													</li>
													<li>
														<a href="/"><i className="fa fa-angle-right" aria-hidden="true"></i> Youtube</a>
													</li>
													<li>
														<a href="/"><i className="fa fa-angle-right" aria-hidden="true"></i> Twitter</a>
													</li>
													<li>
														<a href="/"><i className="fa fa-angle-right" aria-hidden="true"></i> Shopee</a>
													</li>
												</ul>
											</div>
										</div>
									</div>
								</div>
								<div className="footer-bottom">
									<div className="container">
										<div className="row">
											<div className="col-sm-6">© Copyright {domainSite}. All Rights Reserved</div>
											<div className="col-sm-6">
												<ul className="copyright-menu">
													{/* <li>
														<a href="/">Terms of Service</a>
													</li>
													<li>
														<a href="/">Privacy Policy</a>
													</li> */}
												</ul>
											</div>
										</div>
									</div>
								</div>
							</footer>
						</div>
					</div>
				</Fragment>
			</Router>
		);
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	error: state.error
})

export default connect(mapStateToProps, { register, clearErrors, getSevices })(RegisterModal);