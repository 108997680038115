import {
	LOAD_FVC,
	UPDATE_FVC_TAB,
	UPDATE_FVC,
	LOAD_PRICE_FVC,
	LIMIT_PAGI_FVC,
	TYPE_FVC,
	LOADING_FVC,
	UPDATE_FVC_GETID,
	LOAD_STICKER,
	UPDATE_STICKER,
	UPDATE_LIST_STICKER
} from "../actions/types";

const date7dnext = () => {
	var result = new Date();
  	result.setDate(result.getDate() + 7);
  	return result;
}

const initialState = {
	"taotientrinh": {
		"idfb": "",
		"usernamefb": "",
		"lsct": "0",
		"ndcmt": "",
		"goicmt": "5",
		"tgsd": "30",
		"gioitinh": "all",
		"tocdocmt": "5",
		"gtmtt": "0",
		"sticker": [],
		"sticker_pack":{},
		"commentanh": "",
	},
	datapricebuffComment: {
		facebookvipcomment: {
			min_bh_web: 0,
			prices_web: 0,
		},
		facebookvipcomment_sv2: {
			min_bh_web: 0,
			prices_web: 0,
		},
	},
	"changetab": 0,
	"listtientrinh": [],
	"price": [],
	"limit_pagi": 0,
	"type": 0,
	"loading": false,
	"list_sticker":[],
	facebookvipcommentclone_mo_dong: 1,
	facebookvipcommentclone_sv2_mo_dong: 1,
};

export default function(state = initialState, action) {
	switch (action.type) {
		case LOAD_FVC:
			return {
				...state,
				"listtientrinh": action.payload
			};
		case UPDATE_FVC_TAB:
			return {
				...state,
				"changetab": action.payload
			};
		case UPDATE_FVC:
			return {
				...state,
				"taotientrinh": action.payload
			};
		case LOAD_PRICE_FVC:
			return {
				...state,
				datapricebuffComment: merDataPricePage(action.payload),
			};
		case LIMIT_PAGI_FVC:
			return {
				...state,
				"limit_pagi": action.payload
			};
		case TYPE_FVC:
			return {
				...state,
				"type": action.payload
			};
		case LOADING_FVC:
			return {
				...state,
				"loading": action.payload
			};
		case UPDATE_FVC_GETID:
			return {
				...state,
				"taotientrinh": {
					...state.taotientrinh,
					"idfb": action.payload.id,
					"usernamefb": action.payload.username
				}
			};
		case 'CONFIG_MO_DONG_FVC':
			return {
				...state,
				facebookvipcommentclone_mo_dong: action.payload.facebookvipcommentclone_mo_dong,
				facebookvipcommentclone_sv2_mo_dong: action.payload.facebookvipcommentclone_sv2_mo_dong,
			};
		case UPDATE_STICKER:
			// return {
				// ...state,
				// "sticker_pack": action.payload
			// };
			return {
				...state,
				"taotientrinh": {
					...state.taotientrinh,
					"sticker_pack": action.payload,
				}
			};
		case LOAD_STICKER:
			return {
				...state,
				"list_sticker": action.payload
			};
		case UPDATE_LIST_STICKER:
			return {
				...state,
				"list_sticker": action.payload
			};
		default:
		  return state;
	}
}
function merDataPricePage(data) {
	const dataOld = initialState.datapricebuffComment;
	let dataNew = {
		...dataOld
	}
	if (data.length > 0) {
		data.forEach(function(v, i){
			if (v.name_table === 'facebookvipcomment') {
				dataNew['facebookvipcomment'] = v;
				initialState.price = v;
				initialState.taotientrinh['gtmtt'] = v.prices_web;
			} else if (v.name_table === 'facebookvipcomment_sv2') {
				dataNew['facebookvipcomment_sv2'] = v;
			}
		});
	};
	return dataNew;
}
