import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import { CheckCookie } from '../../../actions/chocbanbeAction';
import { CreateBot } from '../../../actions/vipbotliveAction';
import NumberFormat from 'react-number-format';
import store from '../../../store';
import Swal from 'sweetalert2';
import { add, checkCookieFbVip } from '../../../actions/FbvipActions';
import parse from 'html-react-parser';
import ServicesDescription from "../ServicesDescription";
import { isMobile } from 'react-device-detect';

class CreatBot extends Component {
 	state = {
        cookiefb: '',
        dataform: {
            idfb: '',
            namefb: '',
            dtsg: '',
            cookie: '',
    		timerun:1,
    		idEx: ''
        },
		showDescription: isMobile? false : true
    }

    checkCookie(e){
        let cookiefb = e.target.value;
        let fun_check = this.props.checkCookieFbVip(cookiefb);
        let ToastNotice = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        });

        if (fun_check !== false) {
            store.dispatch({
                type: 'LOADING_DATA_FB_VIPBOTLIKE',
                payload: true
            });

            fun_check.then(res => {
                store.dispatch({
                    type: 'LOADING_DATA_FB_VIPBOTLIKE',
                    payload: false
                });

                if (res.data.status === 200) {
                    let data_res = res.data.data;
                    this.setState({
                        cookiefb: cookiefb,
                        dataform: {
							...this.state.dataform,
                            idfb: data_res.uid,
                            namefb: data_res.name,
                            dtsg: data_res.dtsg,
                            cookie: cookiefb
                        }
                    });
                    ToastNotice.fire({
                        icon: 'success',
                        title: 'Lấy thông tin cookie thành công!'
                    });
                } else {
                    this.resetData();
                    ToastNotice.fire({
                        icon: 'error',
                        title: 'không lấy được thông tin cookie!'
                    });
                }
            })
        } else {
            this.setState({
    			...this.state,
                cookiefb: cookiefb,
                dataform: {
					...this.state.dataform,
                    idfb: '',
                    namefb: '',
                    dtsg: '',
                    cookie: ''
                }
            });
        }
    }

    changeValueForm = (e) => {
    	this.setState({
            dataform: {
				...this.state.dataform,
                [e.target.name]: e.target.value,
            }
        });
    }

    resetData = () => {
        this.setState({
            cookiefb: '',
            dataform: {
                idfb: '',
                namefb: '',
                dtsg: '',
                cookie: '',
            	timerun: 1,
            	idEx: '',
            }
        });
    }

	onClickShowDescription = (data) => {
		this.setState({
			showDescription: data
		});
	}
	
    render() {
        const {cookiefb,dataform,idEx} = this.state;
		
		var listDataServicesInfo = {};
		try {
			if (localStorage.getItem("listDataServicesInfo") != null) {
				listDataServicesInfo = JSON.parse(localStorage.getItem("listDataServicesInfo"));
			} else {
				listDataServicesInfo = this.props.adminpage.listDataServicesInfo;
			}
		} catch(e) {
			listDataServicesInfo = {};
		}
		var html_haydoctranhmattien = '';
		var html_haydoctranhmattien_chinh = '';
		var html_haydoctranhmattien_sv = '';
		var html_invitelivestream = '';
		if (typeof(listDataServicesInfo.facebook_bot_invitelivestream_tab) !== 'undefined') {
			html_haydoctranhmattien = parse(listDataServicesInfo.facebook_bot_invitelivestream_tab.ghichu);
			html_haydoctranhmattien_chinh = parse(listDataServicesInfo.facebook_bot_invitelivestream_tab.ghichu_chinh);
		}
		if (typeof(listDataServicesInfo.facebook_bot_invitelivestream) !== 'undefined') {
			html_invitelivestream = parse(listDataServicesInfo.facebook_bot_invitelivestream.ghichu_sv);
			if ("1" === "1") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.facebook_bot_invitelivestream.ghichu);
			}
		}
		
        return (
            <Fragment>
				<div className="col-md-12">
					<div className="form-group focused">
						<label className="control-label">Cookie</label>
						<input className="form-control" placeholder="Nhập cookie" value={cookiefb} onChange={(e)=>{this.checkCookie(e)}} />
						{ dataform.idfb !== '' ?
							<div className="mt-3">
								<img src={"https://graph.facebook.com/"+dataform.idfb+"/picture?height=100&amp;width=100"} className="avatar-user" />
								{dataform.namefb} ({dataform.idfb})
							</div>
						: ''
						}
					</div>
				</div>

				<div className="col-md-12">
					<div className="form-group focused">
						<label className="control-label">Lọc ID không muốn mời (id1|id2|id3) </label>
						<textarea className="form-control" placeholder="Nhập vào ID,ví dụ 100000000000000|100xxx" name="idEx" value={dataform.idEx} onChange={(e)=>{this.changeValueForm(e)}}>
						</textarea>
					</div>
				</div>

				<div className="col-md-12">
					<div className="form-group focused">
						<label className="control-label">Thời Gian Sử Dụng</label>
						<select onChange={(e) => {this.changeValueForm(e)}} value={dataform.timerun} name="timerun" className="form-control">
							<option value="1">1 Tháng</option>
							<option value="2">2 Tháng</option>
							<option value="3">3 Tháng</option>
							<option value="4">4 Tháng</option>
							<option value="5">5 Tháng</option>
							<option value="6">6 Tháng</option>
							<option value="7">7 Tháng</option>
							<option value="8">8 Tháng</option>
							<option value="9">9 Tháng</option>
							<option value="10">10 Tháng</option>
							<option value="11">11 Tháng</option>
							<option value="12">12 Tháng</option>
						</select>
					</div>
				</div>

				{/*<div className="col-md-6">
					<div className="block block-rounded block-bordered">
						<div className="block-header border-bottom">
							<h3 className="block-title">
								<i className="fa fa-desktop" aria-hidden="true"></i> Chi Tiết Thanh Toán
							</h3>
						</div>
						<div className="block-content pb-3">
							<ul className="list-group">
								<li className="list-group-item">Tên Người Dùng:<span className="badge bg-info text-light float-right mt-1" >{dataform.namefb !== '' ? dataform.namefb : 'null'}</span></li>
								<li className="list-group-item">ID Người Dùng:<span className="badge bg-info text-light float-right mt-1" >{dataform.idfb !== '' ? dataform.idfb : 'null'}</span></li>
								<li className="list-group-item">Thời Gian Sử Dụng:<span className="badge bg-info text-light float-right mt-1" >{dataform.timerun + ' tháng'}</span></li>
								<li className="list-group-item">
									Thanh Toán
									<span className="badge bg-info text-light float-right mt-1">
										<NumberFormat value={ dataform.timerun*60000 } displayType={'text'} thousandSeparator={true} suffix={' coin'} />
									</span>
								</li>
							</ul>
						</div>
					</div>
				</div>

				<div className="col-md-6">
					<ul className="mt-4">
						<li>
							<p className="font-bold font-underline text-danger">Lưu ý:</p>
							<ul className="p-0">
								<li>
									<p>Bot tự động mời bạn bè của bạn khi bạn livestream.</p>
								</li>
								<li>
									<p>Chúng Tôi không hoàn lại gói VIP đã thanh toán kể cả vừa cài được vài giờ.</p>
								</li>
								<li>
									<p>Cám ơn đã sử dụng dịch vụ của chúng tôi.</p>
								</li>
							</ul>
						</li>
					</ul>
				</div>*/}
				
				<button type="button" className="btn badge-primary-cus btn-rounded btn-show-description-cus" onClick={(e) => {this.onClickShowDescription(!this.state.showDescription)}}>
					{
						this.state.showDescription?
							<i className="fa fa-angle-up" style={{"marginRight": "5px"}} aria-hidden="true"></i>
						:
							<i className="fa fa-angle-down" style={{"marginRight": "5px"}} aria-hidden="true"></i>
					}
					Thông tin dịch vụ
				</button>
				
				<ServicesDescription allservicesinfo={listDataServicesInfo} server={"1"} tab="facebook_bot_invitelivestream" giatien={this.props.vipbotlive.dataprice.prices_web} baohanh={0} showDescription={this.state.showDescription} />
				
				<div className="row mt-2" style={{"display": this.state.showDescription? "flex" : "none"}}>
					<div className="col-md-12 bold" style={{"marginBottom": "10px"}}>
						<div className="card card-select-cus">
							<div className="card-body card-body-select-cus">
								<p className="mb-0 mt-3 text-wrap">
									<ul>
										{html_haydoctranhmattien}
										{html_haydoctranhmattien_chinh}
										{html_haydoctranhmattien_sv}
										<li>Nếu gặp lỗi hãy nhắn tin hỗ trợ phía bên phải góc màn hình hoặc vào mục liên hệ hỗ trợ để được hỗ trợ tốt nhất!</li>
									</ul>
								</p>
								<span className="badge badge-cus position-absolute-cus badge-top-left-cus badge-primary-cus badge-pill-cus"> HÃY ĐỌC ĐỂ TRÁNH MẤT TIỀN KHI SỬ DỤNG! </span>
							</div>
						</div>
					</div>
				</div>

				<div className="col-12 text-center bold mt-4">
					<div className="card card-info">
						<div className="card-body">
							<h5 className="font-bold">Gói bot invite friends view livestream</h5>
							<h6 className="mb-0">Bạn sẽ mất <span className="green bold"><NumberFormat value={ Math.round((dataform.timerun*this.props.vipbotlive.dataprice.prices_web + Number.EPSILON) * 100) / 100 } displayType={'text'} thousandSeparator={true} suffix={' coin'} /></span></h6>
						</div>
					</div>
				</div>

				<div className="col-md-12 mt-4">
					<div className='form-group float-right w-100'>
						<button onClick={() => {this.props.add('buffvipbotlive', dataform, this.resetData)}} type="button" className="btn btn-hero-primary btn-rounded btn-block">
							Tạo tiến trình
						</button>
					</div>
				</div>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    vipbotlive: state.vipbotlive,
});

export default connect(mapStateToProps, {CheckCookie,CreateBot,add,checkCookieFbVip}) (CreatBot);
