import React, { Component, Fragment } from 'react';
import {
  BrowserRouter as Router,
  // Switch,
  // Route,
  Link
} from "react-router-dom";
import {
	// Button,
	// Modal,
	// ModalHeader,
	// ModalBody,
	// Form,
	// FormGroup,
	// Label,
	// Input,
	// NavLink,
	Alert
} from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { register } from '../../actions/authActions';
import { clearErrors } from '../../actions/errorActions';
import Lottie from 'react-lottie-player';
import { RiShieldCheckLine, RiMoneyDollarBoxLine, RiHeadphoneLine, RiShoppingBasket2Line } from "react-icons/ri";
import { AiOutlineUserAdd, AiOutlineUser } from "react-icons/ai";
import parse from 'html-react-parser';

class RegisterModal extends Component {
	state = {
		// modal: false,
		username: '',
		email: '',
		password: '',
		repeatpassword: '',
		facebook_id: '4',
		phone: '',
		msg: null,
	}
	
	static propTypes =  {
		auth: PropTypes.object.isRequired,
		error: PropTypes.object.isRequired,
		register: PropTypes.func.isRequired,
		clearErrors: PropTypes.func.isRequired
	}
	
	componentDidUpdate(prevProps) {
		const { error } = this.props;
		if (error !== prevProps.error) {
			// Check for register error
			if (error.id === 'REGISTER_FAIL') {
				this.setState({ msg: error.msg.msg });
			} else {
				this.setState({ msg: null });
			}
		}
	}
	
	clearAlert = () => {
		// Clear errors
		this.props.clearErrors();
	}
	
	onChange = e => {
		this.setState({ [e.target.name]: e.target.value });
	};
	
	onSubmit = e => {
		e.preventDefault();
		
		const { username, email, password, repeatpassword, facebook_id, phone } = this.state;
		
		// Create user object
		const newUser = {
			username,
			email,
			password,
			repeatpassword,
			facebook_id,
			phone
		};
		// console.log(newUser);
		// Attempt to register
		this.props.register(newUser);
	};
	
	render() {
		let loading = this.props.auth.loading;
		const domainSite = window.location.hostname;
		var config_website = {};
		if (localStorage.getItem("confignavbar") != null) {
			config_website = JSON.parse(localStorage.getItem("confignavbar"));
		} else {
			config_website = this.props.auth.config_website;
		}
		return (
			<Router>
				<Fragment>
					<div className="landingcus10" style={{"zoom": "122%"}}>
						<div className="guest nav-bg">
							<nav className="navbar navbar-default navbar-inverse navbar-static-top ">
								<div className="container">
									<div className="navbar-header">
										<a className="navbar-brand" href="/" style={{"fontSize": "30px"}}>
											{domainSite}
										</a>
										<ul className="nav navbar-nav navbar-toggle-cus">
											<li >
												<Link to="/services" onClick={this.props.action_service}>Dịch vụ</Link>
											</li>
											<li >
												<Link to="/" >Đăng ký</Link>
											</li>
										</ul>
									</div>
									<div id="navbar" className="collapse navbar-collapse">
										<ul className="nav navbar-nav navbar-right">
											<li className="active">
												<a href="/">Đăng nhập</a>
											</li>
											<li>
												<Link to="/" >Đăng ký</Link>
											</li>
											<li>
												<Link to="/services" onClick={this.props.action_service}>Dịch vụ</Link>
											</li>
										</ul>
									</div>
								</div>
							</nav>
							<section id="signup">
								<div className="container">
									<div className="row">
										<div className="col-md-8 col-md-offset-2">
											{loading ? (
												<div className="preloader">
													<div className="spinner-grow spinner-grow-sm text-primary" role="status" style={{ "marginTop": "25%" }}>
													  <span className="sr-only"></span>
													</div>
													<div className="spinner-grow spinner-grow-sm text-danger" role="status" style={{ "marginTop": "25%" }}>
													  <span className="sr-only">.</span>
													</div>
													<div className="spinner-grow spinner-grow-sm text-warning" role="status" style={{ "marginTop": "25%" }}>
													  <span className="sr-only"></span>
													</div>
												</div>
											):('')}
											{this.state.msg ? (
												<div className="alert alert-warning d-flex align-items-center justify-content-between" role="alert" >
												  <div className="flex-fill mr-3">
													<p className="mb-0">{parse(this.state.msg)}</p>
												  </div>
												  <div className="flex-00-auto">
													<i className="fa fa-fw fa-exclamation-circle"></i>
												  </div>
												</div>
											  ) : null}
											
											<form className="registration-form" onSubmit={this.onSubmit} method="POST" >
												<div className="form-group">
													<span className="inpt-icon">
														<i className="fas fa-user-edit" aria-hidden="true"></i>
													</span>
													<input name="username" placeholder="Tài khoản" required="" id="username" type="text" className="form-control" onChange={this.onChange} />
												</div>
												<div className="form-group">
													<span className="inpt-icon">
														<i className="fas fa-envelope" aria-hidden="true"></i>
													</span>
													<input name="email" placeholder="Nhập email" required="" id="email" type="email" className="form-control" onChange={this.onChange} />
												</div>
												<div className="form-group">
													<span className="inpt-icon">
														<i className="fa fa-phone fa-rotate-90" aria-hidden="true"></i>
													</span>
													<input name="phone" placeholder="Nhập số điện thoại" required="" id="phone" type="text" className="form-control" onChange={this.onChange} />
												</div>
												<div className="form-group">
													<span className="inpt-icon">
														<i className="fas fa-lock" aria-hidden="true"></i>
													</span>
													<input name="password" placeholder="Nhập mật khẩu" required="" id="password" type="password" className="form-control" onChange={this.onChange} />
												</div>
												<div className="form-group">
													<span className="inpt-icon">
														<i className="fas fa-lock" aria-hidden="true"></i>
													</span>
													<input name="repeatpassword" placeholder="Nhập lại mật khẩu" required="" id="repeatpassword" type="password" className="form-control" onChange={this.onChange} />
												</div>
												<br/>
												<button type="submit" name="login_btn" className="btn btn-primary-cus">Đăng ký</button>
												<br/>
												<span className="pull-right-middle" style={{"color": "#fff"}}>Bạn đã có tài khoản? <Link to="/" onClick={this.props.action_login} style={{"color": "#fff"}}>Đăng nhập</Link>
												</span>
											</form>
										</div>
									</div>
								</div>
							</section>
							<footer>
								<div className="container">
									<div className="ftr-top">
										<div className="row">
											<div className="col-sm-9">
												<div className="ftr-logo"> {domainSite} </div>
												<div className="ftr-txt">
													<p>
														{domainSite} đã có nhiều năm hoạt động trong lĩnh vực Online Maketing, nắm bắt được sự thay đổi và định hứng được sự phát triển của thị trường, {domainSite} đi đầu trong lĩnh vực online maketing, cung cấp đa dạng các dịch vụ, giúp người dùng luôn tiếp cận được với mức giá phải chăng và hiệu quả maketing tối ưu nhất.
													</p>
												</div>
											</div>
											<div className="col-sm-3">
												<h2 className="ftr-title">Liên kết</h2>
												<ul className="ftr-nav">
													<li className="active">
														<a href="/">Đăng nhập</a>
													</li>
													<li>
														<Link to="/" >Đăng ký</Link>
													</li>
													<li>
														<Link to="/services" onClick={this.props.action_service}>Dịch vụ</Link>
													</li>
												</ul>
											</div>
											{/* <div className="col-sm-3">
												<h2 className="ftr-title">Quick Links</h2>
												<ul className="ftr-nav">
													<li>
														<a href="#">Facebook</a>
													</li>
													<li>
														<a href="#">Twitter</a>
													</li>
													<li>
														<a href="https://instagram.com/socialmatrixofficial">Instagram</a>
													</li>
													<li>
														<a href="#">Youtube</a>
													</li>
													<li>
														<a href="#">Vimeo</a>
													</li>
													<li>
														<a href="#">Linkedin</a>
													</li>
												</ul>
											</div>
											<div className="col-sm-3">
												<h2 className="ftr-title">Subscribe </h2>
												<div className="newsletter">
													<div className="input-group">
														<input type="email" className="form-control" placeholder="Email" aria-describedby="basic-addon2"/>
														<span className="input-group-addon" id="basic-addon2">
															<i className="far fa-arrow-alt-circle-right" aria-hidden="true"></i>
														</span>
													</div>
												</div>
												<div className="follow-us">
													<h2 className="ftr-title">Follow Us </h2>
													<ul>
														<li>
															<a href="#">
																<i className="fa fa-facebook-f" aria-hidden="true"></i>
															</a>
														</li>
														<li>
															<a href="https://instagram.com/socialmatrixofficial">
																<i className="fab fa-instagram" aria-hidden="true"></i>
															</a>
														</li>
														<li>
															<a href="#">
																<i className="fab fa-twitter" aria-hidden="true"></i>
															</a>
														</li>
														<li>
															<a href="#">
																<i className="fab fa-google-plus-g" aria-hidden="true"></i>
															</a>
														</li>
													</ul>
												</div>
											</div> */}
										</div>
									</div>
									<div className="ftr-btm">
										<p className="text-center">Copyright 2021 © {domainSite} - All rights reserved.</p>
									</div>
								</div>
							</footer>
						</div>
					</div>
				</Fragment>
			</Router>
		);
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	error: state.error
})

export default connect(mapStateToProps, { register, clearErrors })(RegisterModal);