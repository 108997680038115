import React, { Component, Fragment } from "react";
import { getListItemBuffSub,cancelOrderBuffSub,searchDataBuffSub,reLoadIdFb } from '../../../actions/buffSubInstagramAction';
import { connect } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import Moment from 'react-moment';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ModalEditAdmin from './modalEditAdmin';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { RiExchangeDollarLine } from "react-icons/ri";
const { SearchBar } = Search;

class Nhatkyorder extends Component {
	state = {
		limit: 0,
		viewall: 0,
		page: 1,
		obj_search: "",
        dataItemSelect: {},
        isOpenModalEditAdmin: false,
	}

	componentDidMount(){
        this.props.getListItemBuffSub(0, 1, '');
	}

	searchDataBuff = (e) => {
		if (e.key === 'Enter') {
	      	let value_search = e.target.value;
	      	this.props.searchDataBuffSub(value_search);
	    }
	}

	onChange = (e) => {
		this.setState({
			"obj_search": e.target.value,
			"page": 1
		});
	}
    getListByLimit = (page, type_button) => {
        var valueChange = parseInt(page);
		if (type_button == "prev") {
			valueChange -= 1;
			if (valueChange <= 0) {
				valueChange = 1;
			}
		}
		if (type_button == "next") {
			valueChange += 1;
		}
		this.setState({
			page: valueChange,
		});
		this.props.getListItemBuffSub(this.state.viewall, valueChange, this.state.obj_search);
    }
	
    getListByView = (e) => {
        let valueChange = parseInt(e);
		this.setState({
			viewall: valueChange,
			"page": 1,
		});
		this.props.getListItemBuffSub(valueChange, 1, this.state.obj_search);
    }
	
    getListBySearch = (e) => {
		this.setState({
			"page": 1,
		});
		this.props.getListItemBuffSub(this.state.viewall, 1, this.state.obj_search);
    }

    openModalEditAdmin = (data = {}) => {
        this.setState({
            ...this.state,
            isOpenModalEditAdmin: !this.state.isOpenModalEditAdmin,
            dataItemSelect: data,
        })
    }

    calBwDateToNow = (date_check = null) => {
		if (Number.isInteger(date_check)) {
			let date_now = Math.floor(Date.now() / 1000);
			let calDateDay = (date_now - date_check)/86400;
			if (calDateDay > 1) {
				return Math.ceil(calDateDay) + ' ngày trước';
			} else {
				let calDatehour = (date_now - date_check)/3600;
				if (calDatehour < 0.6) {
					return Math.ceil(calDatehour*100) + ' phút trước';
				} else {
					return Math.ceil(calDatehour) + ' giờ trước';
				}
			}
		}
	}
	
 	render() {
		function formatBaoHanh(cell, row) {
			if (cell === 0) {
				return (
					<span className="badge badge-warning">Không</span>
				)
			} else {
				return (
					<span className="badge badge-success">Có</span>
				)
			}
		}
		function filterValueBaoHanh(cell, row) {
			if (cell === 0) {
				return ("Không")
			} else {
				return ("Có")
			}
		}
		function formatTrangThai(cell, row) {
			var message = '';
			if (cell === 0) {
				return (
					<span className="badge badge-primary"><i className="fas fa-check mr-1"></i>Hoàn thành</span>
				);
			} else if (cell === 1) {
				return (
					<span className="badge badge-success"><i className="fas fa-sync-alt mr-1"></i>Đang chạy</span>
				);
			} else if (cell === 2) {
				return (
					<span className="badge badge-danger"><i className="fas fa-ban mr-1"></i>ID die không thể chạy</span>
				);
			} else if (cell === 3) {
				return (
					<span className="badge badge-warning"><i className="fas fa-times mr-1"></i>Hủy đơn</span>
				);
			} else if (cell === 4) {
				return (
					<span className="badge badge-warning"><i className="fas fa-sync-alt mr-1"></i>Chờ duyệt tay</span>
				);
			} else if (cell === 5) {
				return (
					<span className="badge badge-info"><RiExchangeDollarLine size={12} className="mr-1" />Hoàn tiền</span>
				);
			} else {
				return ('');
			}
		}
		function filterValueTrangThai(cell, row) {
			if (cell === 0) {
				return ('Hoàn thành');
			} else if (cell === 1) {
				return ('Đang chạy');
			} else if (cell === 2) {
				return ('ID die không thể chạy');
			} else if (cell === 3) {
				return ('Hủy đơn');
			} else if (cell === 4) {
				return ('Chờ duyệt tay');
			} else if (cell === 5) {
				return ('Hoàn tiền');
			} else {
				return ('');
			}
		}
		
		function formatCheckTime(cell, row) {
			let date_now = Math.floor(Date.now() / 1000);
			let calDateDay = (cell - date_now);
			if (calDateDay >= 0) {
				if (calDateDay/86400 < 1) {
					return Math.floor(calDateDay/3600) + ' giờ';
				}
				return (
					<Fragment>
						<Moment diff={date_now*1000} unit="days">{cell*1000}</Moment> ngày
					</Fragment>
				);
			} else {
				return <span className="badge badge-danger">Hết hạn</span>
			}
		}
		function filterValueCheckTime(cell, row) {
			let date_now = Math.floor(Date.now() / 1000);
			let calDateDay = (cell - date_now);
			if (calDateDay >= 0) {
				if (calDateDay/86400 < 1) {
					return Math.floor(calDateDay/3600) + ' giờ';
				}
				var diffDays = Math.floor(calDateDay / (60 * 60 * 24)) + ' ngày';
				return diffDays;
			} else {
				return ('Hết hạn');
			}
		}
		
		function formatGoi(cell, row) {
			if (cell == "1") {
				return ('SV1');
			} else if (cell == "2") {
				return ('SV2');
			} else if (cell == "3") {
				return ('SV3');
			} else if (cell == "4") {
				return ('SV4');
			} else if (cell == "5") {
				return ('SV5');
			} else if (cell == "6") {
				return ('SV6');
			} else if (cell == "7") {
				return ('SV7');
			} else if (cell == "8") {
				return ('SV8');
			} else {
				return ('');
			}
		}
		
 		const columns = [{
		  dataField: '',
		  text: 'STT',
	   	  sort: true,
		  formatter: (cell, row, rowIndex) =>
		    <span>{rowIndex+1}</span>
		}, {
		    text: 'Thao tác',
		    dataField: '-',
		    style: {
			  'white-space' : 'nowrap'
		    },
			formatter: (cell, row) => 
				<Fragment>
					{ this.props.auth.user.type === 1 ?
						<button type="button" onClick={() => {this.openModalEditAdmin(row)}} className="btn btn-hero-success text-center p-2 mr-1"><i className="fa fa-fw fa-pen"></i></button>
						: ''
					}
					{row.is_refund !== 1 && row.status !== 4 && row.time_expired > Math.floor(Date.now() / 1000) ?
							<Fragment>
								{row.status === 2 ?
									<button onClick={() => {this.props.reLoadIdFb(row.id)}} type="button" name="re_id_die" className="btn btn-hero-warning text-center p-2 mr-1">
										<i className="far fa-check-circle"></i>
									</button>
								: ''}
							</Fragment>
						: ''
					}
					{row.status !== 3 && row.time_expired > Math.floor(Date.now() / 1000) ?
						<Fragment>
							<button onClick={()=>{this.props.cancelOrderBuffSub(row.id)}} type="button" name="huy_order" className="btn btn-hero-danger text-center p-2 mr-1"><i className="fa fa-fw fa-trash"></i></button>
						</Fragment>
						: ''
					}
				</Fragment>
		},{
		  dataField: 'object_id',
		  text: 'ID Seeding',
		  sort: true
		},{
		  dataField: 'instagram_username',
		  text: 'Instagram',
		  sort: true,
		  formatter: (cell, row) => 
		  	<Fragment>
	    	 	<a href={"https://www.instagram.com/"+cell} target="_blank" rel="noopener noreferrer">{cell}</a>
	    	</Fragment>
		},/*{
		  dataField: 'object_id',
		  text: 'ID seeding',
		  sort: true,
		  formatter: (cell, row) => 
		  	<Fragment>
	    	 	{cell}
	    	</Fragment>
		},*/{
		  text: 'Loại',
		  dataField: 'type',
		  sort: true,
		  classes: 'text-center',
		  formatter: formatGoi,
		  filterValue: formatGoi
		   
		},{
		  text: 'Bảo hành',
		  dataField: 'is_warranty',
		  sort: true,
		  classes: 'text-center',
		  formatter: formatBaoHanh,
		  filterValue: filterValueBaoHanh
		   
		},{
		  dataField: 'start',
		  text: 'Start',
		  sort: true
		},{
		  dataField: 'quantity',
		  text: 'Số lượng',
		  sort: true
		},{
		  dataField: 'price_per',
		  text: 'Giá',
		  sort: true
		},{
		  dataField: 'prices',
		  text: 'Tổng tiền',
		  sort: true
		},{
          dataField: 'count_success',
          text: 'Đã chạy',
          sort: true,
        },{
		  dataField: 'created_at',
		  text: 'Khởi tạo',
		  sort: true,
		  formatter: (cell, row) => 
		  	<Fragment>
	    	 	{this.calBwDateToNow(cell)}
	    	</Fragment>
		},{
		  dataField: 'time_expired',
		  text: 'Hết hạn',
		  sort: true,
		  formatter: formatCheckTime,
		  filterValue: filterValueCheckTime
		},{
		  dataField: 'user_username',
		  text: 'username',
		  sort: true,
		},{
		  dataField: 'status',
		  text: 'Trạng thái',
		  sort: true,
		  formatter: formatTrangThai,
		  filterValue: filterValueTrangThai
		}];

 		const expandRow = {
			renderer: (row) => (
				<Fragment>
					<div className="row">
						<div className="offset-lg-3 col-lg-8 row">
							<div className="col-lg-4">
								<div className="form-group">
									<label htmlFor="example-text-input">Nội dung</label>
									<textarea className="form-control" id="nd" name="nd" placeholder="" rows="2" ></textarea>
								</div>
							</div>
							<div className="col-lg-4">
								<div className="form-group">
									<label htmlFor="example-text-input">Ghi chú</label>
									<textarea className="form-control" id="gc" name="gc" placeholder="" rows="2" defaultValue={row.notes}></textarea>
								</div>
							</div>
							{/* <div className="col-lg-4 d-flex align-items-center justify-content-center">
								<div className="form-group">
									{row.is_refund !== 1 && row.id_api !== 0 && row.status !== 4 && row.time_expired > Math.floor(Date.now() / 1000) ?
											<Fragment>
												{row.status === 2 ?
													<button onClick={() => {this.props.reLoadIdFb(row.id)}} type="button" name="re_id_die" className="btn btn-block btn-sm btn-rounded btn-hero-light text-warning">
														Check id
													</button>
												: ''}
											</Fragment>
										: ''
									}
									{row.status !== 3 && row.time_expired > Math.floor(Date.now() / 1000) ?
										<Fragment>
											<button onClick={()=>{this.props.cancelOrderBuffSub(row.id_api)}} type="button" name="huy_order" className="btn btn-block btn-sm btn-rounded btn-hero-light">Hủy Order</button>
										</Fragment>
										: ''
									}
									{ this.props.auth.user.type === 1 ?
										<button type="button" onClick={() => {this.openModalEditAdmin(row)}} className="btn btn-block btn-sm btn-rounded btn-hero-success">Cài đặt admin</button>
										: ''
									}
								</div>
							</div> */}
						</div>
					</div>
				</Fragment>
			),
			showExpandColumn: true,
			expandColumnPosition: 'right',
			expandHeaderColumnRenderer: ({ isAnyExpands }) => {
				if (isAnyExpands) {
					return <i className="fas fa-caret-up"></i>;
				}
				return <b><i className="fas fa-caret-down"></i></b>;
			},
			expandColumnRenderer: ({ expanded }) => {
				if (expanded) {
					return (
						<b><i className="fas fa-caret-up"></i></b>
					);
				}
				return (
					<b><i className="fas fa-caret-down"></i></b>
				);
			}
		};

 		const {listBuffSub} = this.props.buffsub;
 		const {isOpenModalEditAdmin,dataItemSelect} = this.state;

    	return (
    		<Fragment>
				{/*<div className="row mb-4">
    				<div className="col-md-5">
						{ this.props.auth.user.type === 1 ?
							<Fragment>
								<button onClick={() => {this.props.getListItemBuffSub(1);this.setState({...this.state,viewall: 1})}} type="button" className="btn btn-primary mr-1">Xem tất cả</button>
								<button onClick={() => {this.props.getListItemBuffSub();this.setState({...this.state,viewall: 0})}} type="button" className="btn btn-primary mr-1">Xem riêng</button>
							</Fragment>
						: ''}
					</div>
					<div className="col-md-3">
						<select onChange={(e) => {this.getListByLimit(e)}} className="form-control">
							<option value="">Số lịch sử hiển thị tối đa</option>
						    <option value="50">50</option>
						    <option value="100">100</option>
						    <option value="200">200</option>
						    <option value="500">500</option>
						    <option value="1000">1000</option>
						</select>
					</div>
					
					<div className="col-md-4">
	        			<input onKeyDown={(e) => {this.searchDataBuff(e)}} type="text" name="search_user" className="form-control" placeholder="Nhập id tìm kiếm"/>
            		</div>
				</div>*/}
				
				<ToolkitProvider keyField="id" data={ listBuffSub } columns={ columns } search >
					{
						props => (
							<div>
								<SearchBar { ...props.searchProps } />
								<div className="row mb-4">
									<div className="col-md-12">
										{ this.props.auth.user.type === 1 ?
											<Fragment>
												<button onClick={(e) => {this.getListByView(1)}} name="view" value="1" type="button" className="btn btn-primary mr-1">Xem tất cả</button>
												<button onClick={(e) => {this.getListByView(0)}} name="view" value="0" type="button" className="btn btn-primary mr-1">Xem riêng</button>
											</Fragment>
										: ''}
										{/* <select onChange={(e) => {this.getListByLimit(e)}} className="form-control custom-select select-light col-md-3 custom-limit_pagi">
											<option value="0">Số lịch sử hiển thị (0-500)</option>
											<option value="1">500-1000</option>
											<option value="2">1000-1500</option>
											<option value="3">1500-2000</option>
											<option value="4">2000-2500</option>
											<option value="5">2500-3000</option>
											<option value="7">3000-3500</option>
											<option value="8">3500-4000</option>
											<option value="9">4500-5000</option>
										</select> */}
										<button onClick={(e) => {this.getListByLimit(this.state.page, "prev")}} name="page" value={this.state.page} type="button" className="btn btn-primary mr-1">{"<"}</button>
										<button onClick={(e) => {this.getListByLimit(this.state.page, "next")}} name="page" value={this.state.page} type="button" className="btn btn-primary mr-1">{">"}</button>
										<button onClick={(e) => {this.getListBySearch(e)}} name="view" value="0" type="button" className="btn btn-primary mr-1">Tìm kiếm</button>
										<input  onChange={this.onChange} value={this.state.obj_search}  name="obj_search" type="text" className="form-control mr-1" style={{"width": "100px", "display": "inline-block"}}/>
									</div>
								</div>
								<BootstrapTable
									{ ...props.baseProps }
									bodyStyle={ { border: 'none' } }
									headerStyle={ { border: 'red 1px solid' } }
									striped
									hover
									expandRow={ expandRow }
									wrapperClasses="table-responsive"
									pagination={ paginationFactory() } />
							</div>
						)
					}
				</ToolkitProvider>
                {(this.props.auth.user.type === 1 && isOpenModalEditAdmin) ? <ModalEditAdmin openModal={this.openModalEditAdmin} isOpenModal={isOpenModalEditAdmin} dataItemSelect={dataItemSelect} /> : '' }
			</Fragment>
		);
  	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	buffsub: state.buffsubinstagram,
});

export default connect(mapStateToProps, {getListItemBuffSub,cancelOrderBuffSub,searchDataBuffSub,reLoadIdFb})(Nhatkyorder);
