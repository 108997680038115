import axios from 'axios';
import Swal from 'sweetalert2';
import { loadUser } from "./authActions";

export const creatItemBuffSub = (data) => (dispatch, getState) => {
	const { locnangcao_dotuoi_start,locnangcao_dotuoi_end,locnangcao_banbe_start,locnangcao_banbe_end,profile_user,baohanh } = data;
	
	if (!Number.isInteger(parseInt(profile_user))) {
		openBtComfirmAlert('Trường id phải là số!', 'error', 'Lỗi');
		return false;
	}

	if (locnangcao_dotuoi_start > locnangcao_dotuoi_end) {
		openBtComfirmAlert('Số tuổi bắt đầu phải nhỏ hơn số tuổi kết thúc!', 'error', 'Lỗi');
		return false;
	}

	if (locnangcao_banbe_start > locnangcao_banbe_end) {
		openBtComfirmAlert('Số bạn bè bắt đầu phải nhỏ hơn số bạn bè kết thúc!', 'error', 'Lỗi');
		return false;
	}

	let bodysend = {
		dataform: data,
		type_api: 'like_page',
	};

	Swal.fire({
	  	title: 'Bạn có chắc?',
	  	text: "Bạn có chắc chắn muốn Order "+data.sltang+" LIKE với giá: "+data.giatien+" Coin/1 LIKE ? Khi đã bấm đồng ý đồng nghĩa với việc bạn đã đọc hết mục thông tin dịch vụ!",
	  	icon: 'warning',
	  	showCancelButton: true,
	  	confirmButtonColor: '#3085d6',
	  	cancelButtonColor: '#d33',
	  	confirmButtonText: 'Đồng ý',
	  	cancelButtonText: 'Không'
	}).then((result) => {
	  	if (result.value) {
			dispatch({
				type: 'LOADING_BLOCK_BUFF_LIKE_FANPAGE',
				payload: true
			});
			axios.post('/api/facebook_buff/create', bodysend, tokenConfig(getState))
			.then(res => {
				dispatch(loadUser());
				dispatch({
					type: 'LOADING_BLOCK_BUFF_LIKE_FANPAGE',
					payload: false
				});
				if (res.data.status === 200) {
					openBtComfirmAlert('Thêm thành công', 'success', '');
				} else {
					if (Array.isArray(res.data.error.object_id)) {
						openBtComfirmAlert(res.data.error.object_id[0], 'error', 'Lỗi');
					} else {
						openBtComfirmAlert(res.data.message, 'error', 'Lỗi');
					}
				}
			});
	  	}
  	})
}

export const getListBuffSub = (viewadmin = 0, page = "", obj_search = '') => (dispatch, getState) => {
	dispatch({
		type: 'LOADING_BLOCK_BUFF_LIKE_FANPAGE',
		payload: true
	});

	let param_url = '';
	if (viewadmin === 1) {
		param_url += '&viewall=1';
	}

	if (page !== "") {
		param_url += '&page='+page;
	}
	param_url += '&obj_search='+obj_search;
	axios.get('/api/facebook_buff/list/?type_api=like_page'+param_url, tokenConfig(getState))
	.then(res => {
		dispatch({
			type: 'LOADING_BLOCK_BUFF_LIKE_FANPAGE',
			payload: false
		});
		if (res.data.status === 200) {
			dispatch({
				type: 'LIST_BUFF_LIKE_FANPAGE_USER',
				payload: res.data.data
			});
		}
	});
}

export const searchIdFb = (link) => (dispatch, getState) => {
	let bodysend = {
		id_user: getState().auth.user.id,
		link: link
	};
	// var dataFormOld = getState().buffsub.formData;
	dispatch({
		type: 'LOADING_BLOCK_BUFF_LIKE_FANPAGE',
		payload: true
	});
	axios.post('/api/checklinkfb/check/', bodysend, tokenConfig(getState))
	.then(res => {
		dispatch({
			type: 'LOADING_BLOCK_BUFF_LIKE_FANPAGE',
			payload: false
		});
		if (res.data.status === 200) {
			// let dataFormNew = {
				// ...dataFormOld,
				// profile_user: res.data.id
			// }
			dispatch({
				type: 'CHANGE_DATA_FORM_FB_LIKE_FANPAGE_PROFILE',
				payload: res.data.id
			});
		} else {
			Swal.fire({
				icon: 'error',
				title: 'Lỗi',
				text: res.data.msg,
			});
		}
	}).catch(err => {
		openBtComfirmAlert('Có lỗi xảy ra!', 'error', 'Lỗi');
    });
}

export const openBtComfirmAlert = (message, type, title = '') => {
	Swal.fire({
		icon: type,
		title: title,
		text: message,
	});
	return false;
}

export const cancelOrderBuffsub = (id_remove) => (dispatch, getState) => {
	if (id_remove === null) {
		openBtComfirmAlert('Không có order được chọn!', 'error', 'Lỗi');
		return false;
	}

	Swal.fire({
	  	title: 'Bạn có chắc?',
	  	text: "Sau khi hủy bạn sẽ được hoàn lại số lượng chưa tăng sau 1 đến 3 ngày và trừ thêm 5.000 Coin phí để tránh Spam!",
	  	icon: 'warning',
	  	showCancelButton: true,
	  	confirmButtonColor: '#3085d6',
	  	cancelButtonColor: '#d33',
	  	confirmButtonText: 'Đồng ý',
	  	cancelButtonText: 'Không'
	}).then((result) => {
	  	if (result.value) {
	  		let bodysend = {
	  			id_remove: id_remove,
	  			type_api: 'bufflikefanpage',
	  		};
	  		dispatch({
				type: 'LOADING_BLOCK_BUFF_LIKE_FANPAGE',
				payload: true
			});

	  		axios.post('/api/facebook_buff/removeorder/', bodysend, tokenConfig(getState))
			.then(res => {
				dispatch({
					type: 'LOADING_BLOCK_BUFF_LIKE_FANPAGE',
					payload: false
				});
				dispatch(getListBuffSub());
				if (res.data.status === 200) {
					Swal.fire(
			      		'Thành công!',
			      		'Hủy đơn thành công',
			      		'success'
			    	)
				} else {
					openBtComfirmAlert(res.data.message, 'error', 'Lỗi');
				}
			});
	  	}
	})
}

export const updatePriceOrBuffSub = (dataItem, price) => (dispatch, getState) => {
	let bodysend = {
		price_per: price,
		id_order: dataItem.id,
		mer: dataItem.quantity * (price-dataItem.price_per),
		type_api: "buff_like_page"
	};
	axios.post('/api/facebook_buff/updatepriceorder/', bodysend, tokenConfig(getState))
	.then(res => {
		if (res.data.status === 200) {
			dispatch(getListBuffSub());
			Swal.fire(
	      		'Thành công!',
	      		'Tăng giá đơn thành công',
	      		'success'
	    	)
		} else {
			openBtComfirmAlert(res.data.message, 'error', 'Lỗi');
		}
	}).catch(err => {
		openBtComfirmAlert('Có lỗi xảy ra!', 'error', 'Lỗi');
    });
}

export const reLoadIdFb = (id_order, id_key) => (dispatch, getState) => {
	dispatch({
		type: 'LOADING_BLOCK_BUFF_LIKE_FANPAGE',
		payload: true
	});

	let bodysend = {
		id_key: id_key,
		type: 'like_page',
	};

	axios.post('/api/facebook_buff/reloadidfb/', bodysend, tokenConfig(getState))
	.then(res => {
		dispatch({
			type: 'LOADING_BLOCK_BUFF_LIKE_FANPAGE',
			payload: false
		});
		dispatch(getListBuffSub());
		if (res.data.status === 200) {
			Swal.fire(
	      		'Thành công!',
	      		'ID fix thành công',
	      		'success'
	    	)
		} else {
			openBtComfirmAlert(res.data.message, 'error', 'Lỗi');
		}
	}).catch(err => {
		openBtComfirmAlert('Có lỗi xảy ra!', 'error', 'Lỗi');
    });
}

export const searchDataBuffSub = (params) => (dispatch, getState) => {
	dispatch({
		type: 'LOADING_BLOCK_BUFF_LIKE_FANPAGE',
		payload: true
	});

	axios.get('/api/facebook_buff/list/?type_api=like_page&object_id='+params, tokenConfig(getState))
	.then(res => {
		dispatch({
			type: 'LOADING_BLOCK_BUFF_LIKE_FANPAGE',
			payload: false
		});
		if (res.data.status === 200) {
			dispatch({
				type: 'LIST_BUFF_LIKE_FANPAGE_USER',
				payload: res.data.data
			});
		}
	});
}

export const saveEditOrderAdmin = (data, closeM) => (dispatch, getState) => {
	dispatch({
		type: 'LOADING_BLOCK_BUFF_LIKE_FANPAGE',
		payload: true
	});
	let bodysend = {
		formdata: data,
		type_api: 'bufflikefanpage'
	};
	axios.post('/api/facebook_buff/admin_edit_order/', bodysend, tokenConfig(getState))
	.then(res => {
		dispatch({
			type: 'LOADING_BLOCK_BUFF_LIKE_FANPAGE',
			payload: false
		});
		dispatch(getListBuffSub());
		closeM();
		if (res.data.status === 200) {
			Swal.fire(
	      		'Thành công!',
	      		'Sửa thành công',
	      		'success'
	    	)
		} else {
			openBtComfirmAlert(res.data.message, 'error', 'Lỗi');
		}
	}).catch(err => {
		openBtComfirmAlert('Có lỗi xảy ra!', 'error', 'Lỗi');
    });
}

export const getDataPriceFunction = (params) => (dispatch, getState) => {
	dispatch({
		type: 'LOADING_BLOCK_BUFF_LIKE_FANPAGE',
		payload: true
	});
	let dataApi =  axios.get('/api/admin/get_price_function/?name='+params+'&type='+getState().auth.user.type, tokenConfig(getState));
	dataApi.then(res => {
		dispatch({
			type: 'LOADING_BLOCK_BUFF_LIKE_FANPAGE',
			payload: false
		});
		if (res.data.status === 200) {
			if (res.data.data.length > 0) {
				let dataPrice = res.data.data[0];
				let dataFormOld = getState().bufflikefanpage.formData;
				dispatch({
					type: 'CHANGE_DATA_FORM_FB_LIKE_FANPAGE',
					payload: {
						...dataFormOld,
						giatien: dataPrice.prices_web
					}
				});
				dispatch({
					type: 'DATA_PRICE_IS_FUNCTION_BUFF_LIKE_FANPAGE',
					payload: dataPrice
				});
			}
		}
	});
}

export const getConfigMoDong = (type = '') => (dispatch, getState) => {
	dispatch({
		type: 'LOADING_BLOCK_BUFF_LIKE_FANPAGE',
		payload: true
	});

	axios.get('/api/facebook_buff/getconfig?type_api=buff_like_page', tokenConfig(getState))
	.then(res => {
		dispatch({
			type: 'LOADING_BLOCK_BUFF_LIKE_FANPAGE',
			payload: false
		});
		if (res.data.status === 200) {
			dispatch({
				type: 'CONFIG_MO_DONG_LIKE_FANPAGE',
				payload: res.data
			});
		}
	});
}

export const tokenConfig = getState => {
	// Get token from localhost
	const token = getState().auth.token;
	
	// Headers
	const config = {
		headers: {
			"Content-type": "application/json"
		}
	};
	
	// If token, add to headers
	if (token) {
		config.headers['ht-token'] = token;
	}
	
	return config;
};