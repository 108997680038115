import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import store from '../../../store';

import Swal from 'sweetalert2';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { updateEmailtouid, addEmailtouid } from '../../../actions/EmailtouidActions';
import parse from 'html-react-parser';
import copy from 'copy-text-to-clipboard';
import ServicesDescription from "../ServicesDescription";
import { isMobile } from 'react-device-detect';

const date7dnext = () => {
	var result = new Date();
  	result.setDate(result.getDate() + 7);
  	return result;
}

class taotientrinh extends Component {
	static propTypes =  {
		updateEmailtouid: PropTypes.func.isRequired,
		addEmailtouid: PropTypes.func.isRequired,
	};
	
	state = {
		showDescription: isMobile? false : true
	}
	
	componentDidMount() {
		// this.props.loadPriceFLBBKTT();
	};
	
	handleChangeDateStart = date => {
		const { taotientrinh, price } = this.props.Emailtouid;
		
		var new_taotientrinh = {
			...taotientrinh,
			startDatebh: date
		};
		this.props.updateEmailtouid(new_taotientrinh);
	}
	
	onChange = (e) => {
		const { taotientrinh } = this.props.Emailtouid;
		var new_taotientrinh = {
			...taotientrinh,
			[e.target.name]: e.target.value
		};
		this.props.updateEmailtouid(new_taotientrinh);
	}
	
	taoTienTrinhfunc = () => {
		const { taotientrinh } = this.props.Emailtouid;
		var new_taotientrinh = {
			...taotientrinh,
		};
		Swal.fire({
			title: 'Bạn có chắc?',
			html: "Bạn có chắc chắn muốn mua Giá "+taotientrinh.gtmtt+" Coin / 1 lần? Khi đã bấm đồng ý đồng nghĩa với việc bạn đã đọc hết mục thông tin dịch vụ!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Đồng ý',
			cancelButtonText: 'Không'
		}).then((result) => {
			if (result.value) {
				this.props.addEmailtouid(new_taotientrinh);
			}
		})
	}
	
	onKeyPress = (e) => {
		if (!(e.charCode >= 48 && e.charCode <= 57)) {
			e.preventDefault();
		}
	}
	
	copyUID(uid) {
		copy(uid);
		Swal.fire({
			icon: 'success',
			title: '',
			text: 'Đã copy UID',
		});
		return false;
	}
	
	onClickShowDescription = (data) => {
		this.setState({
			showDescription: data
		});
	}
	
	render() {
		var token_avt = {};
		try {
			if (localStorage.getItem("token_avt") != null) {
				token_avt = JSON.parse(localStorage.getItem("token_avt"))[0].token_avt;
			} else {
				token_avt = this.props.auth.token_avt[0].token_avt;
			}
		} catch(e) {
			token_avt = localStorage.getItem("token_avt");
		}
 		const { email, gtmtt } = this.props.Emailtouid.taotientrinh;
 		const { uid, usernamefb } = this.props.Emailtouid;
		var price = Math.round((gtmtt*1 + Number.EPSILON) * 100) / 100;
		
		var listDataServicesInfo = {};
		try {
			if (localStorage.getItem("listDataServicesInfo") != null) {
				listDataServicesInfo = JSON.parse(localStorage.getItem("listDataServicesInfo"));
			} else {
				listDataServicesInfo = this.props.adminpage.listDataServicesInfo;
			}
		} catch(e) {
			listDataServicesInfo = {};
		}
		var html_haydoctranhmattien = '';
		var html_haydoctranhmattien_chinh = '';
		var html_haydoctranhmattien_sv = '';
		if (typeof(listDataServicesInfo.emailtouid_tab) !== 'undefined') {
			html_haydoctranhmattien = parse(listDataServicesInfo.emailtouid_tab.ghichu);
			html_haydoctranhmattien_chinh = parse(listDataServicesInfo.emailtouid_tab.ghichu_chinh);
		}
		if (typeof(listDataServicesInfo.emailtouid) !== 'undefined') {
			if ("1" === "1") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.emailtouid.ghichu);
			}
		}
		
		return (
			<Fragment>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Email:</label>
					<div className="col-sm-8">
						<input type="text" type="email" className="form-control" id="email" name="email" placeholder="Nhập email" onChange={this.onChange} value={email} />
					</div>
				</div>
				
				<button type="button" className="btn badge-primary-cus btn-rounded btn-show-description-cus" onClick={(e) => {this.onClickShowDescription(!this.state.showDescription)}}>
					{
						this.state.showDescription?
							<i className="fa fa-angle-up" style={{"marginRight": "5px"}} aria-hidden="true"></i>
						:
							<i className="fa fa-angle-down" style={{"marginRight": "5px"}} aria-hidden="true"></i>
					}
					Thông tin dịch vụ
				</button>
				
				<div className="row mt-2" style={{"display": this.state.showDescription? "flex" : "none"}}>
				    <div className="col-md-12 bold" style={{"marginBottom": "10px"}}>
						<div className="card card-select-cus">
							<div className="card-body card-body-select-cus">
								<p className="mb-0 mt-3 text-wrap">
									<ul>
										{html_haydoctranhmattien}
										{html_haydoctranhmattien_chinh}
										{html_haydoctranhmattien_sv}
										<li>Nếu gặp lỗi hãy nhắn tin hỗ trợ phía bên phải góc màn hình hoặc vào mục liên hệ hỗ trợ để được hỗ trợ tốt nhất!</li>
									</ul>
								</p>
								<span className="badge badge-cus position-absolute-cus badge-top-left-cus badge-primary-cus badge-pill-cus"> HÃY ĐỌC ĐỂ TRÁNH MẤT TIỀN KHI SỬ DỤNG! </span>
							</div>
						</div>
				    </div>
				</div>
				
				<div className="row mt-4">
					<div className="col-12 text-center bold">
						<div className="card card-info">
							<div className="card-body">
								<h5 className="font-bold">Tổng: <span className="bold green"><span>{price}</span> Coin</span></h5>
								<h6 className="mb-0 bold"><span>Giá <span className="green">{gtmtt} Coin</span> / 1 lần</span></h6>
							</div>
						</div>
					</div>
				</div>
				<div className="row my-4">
					<div className="col-12 text-center bold">
						<button type="button" className="btn btn-block btn-rounded btn-hero-primary" onClick={this.taoTienTrinhfunc} >Tạo tiến trình</button>
					</div>
				</div>
				
				{
					uid !== "" ?
						<Fragment>
							<div className="react-bootstrap-table table-responsive">
								<table className="table table-striped table-hover table-bordered table-condensed">
									<thead>
										<tr>
											<th tabindex="0" style={{"width": "30%"}}>Copy</th>
											<th tabindex="0">UID</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>
												<button onClick={() => {this.copyUID(uid)}} className="btn btn-hero-primary text-center p-2 mr-1">
													<i className="fa fa-fw fa-clipboard"></i>
												</button>
											</td>
											<td style={{"overflow-wrap": "anywhere"}}>
												<img src={"https://graph.facebook.com/"+uid+"/picture?height=100&amp;width=100&access_token="+token_avt+""} className="avatar-user" /> <a target="_blank" href={"https://www.facebook.com/"+uid+""}>{usernamefb} - {uid}</a>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</Fragment>
					:
						''
				}
			</Fragment>
		)
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	Emailtouid: state.Emailtouid,
});
const mapDispatchToProps = { updateEmailtouid, addEmailtouid }

export default connect(mapStateToProps, mapDispatchToProps)(taotientrinh);