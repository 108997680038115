import {
	LOAD_BGT,
	UPDATE_BGT_TAB,
	UPDATE_BGT,
	LOAD_PRICE_BGT,
	LOADING_BGT,
	LIMIT_PAGI_BGT,
	TYPE_BGT,
	UPDATE_BGT_IDFB_LIENHE,
} from "../actions/types";

const date7dnext = () => {
	var result = new Date();
  	result.setDate(result.getDate() + 7);
  	return result;
}

const initialState = {
	"taotientrinh": {
		"lgt": "",
		"loaikeomem": "0",
		"lgtlm": "",
		"slct": "1000",
		"fb": "",
		"gc": "",
		"gtmtt": "0",
	},
	"changetab": 0,
	"listtientrinh": [],
	"price": {
		buffgrouptele_random: {
			min_bh_web: 0,
			prices_web: 0,
		},
		buffgrouptele_random_sv2: {
			min_bh_web: 0,
			prices_web: 0,
		},
		buffgrouptele_theoyeucau: {
			min_bh_web: 0,
			prices_web: 0,
		},
	},
	"loading": false,
	"limit_pagi": 0,
	"type": 0,
	telegrambuffmembergroup_random_mo_dong: 1,
	telegrambuffmembergroup_random_sv2_mo_dong: 1,
	telegrambuffmembergroup_yeucau_mo_dong: 1,
};

export default function(state = initialState, action) {
	switch (action.type) {
		case LOAD_BGT:
			return {
				...state,
				"listtientrinh": action.payload
			};
		case UPDATE_BGT_TAB:
			return {
				...state,
				"changetab": action.payload
			};
		case UPDATE_BGT:
			return {
				...state,
				"taotientrinh": action.payload
			};
		case LOAD_PRICE_BGT:
			return {
				...state,
				"price": merDataPricePage(action.payload),
			};
		case LOADING_BGT:
			return {
				...state,
				"loading": action.payload
			};
		case LIMIT_PAGI_BGT:
			return {
				...state,
				"limit_pagi": action.payload
			};
		case TYPE_BGT:
			return {
				...state,
				"type": action.payload
			};
		case UPDATE_BGT_IDFB_LIENHE:
			return {
				...state,
				"taotientrinh": {
					...state.taotientrinh,
					"fb": action.payload
				}
			};
		case 'CONFIG_MO_DONG_BGT':
			return {
				...state,
				telegrambuffmembergroup_random_mo_dong: action.payload.telegrambuffmembergroup_random_mo_dong,
				telegrambuffmembergroup_random_sv2_mo_dong: action.payload.telegrambuffmembergroup_random_sv2_mo_dong,
				telegrambuffmembergroup_yeucau_mo_dong: action.payload.telegrambuffmembergroup_yeucau_mo_dong,
			};
		default:
		  return state;
	}
}

function merDataPricePage(data) {
	let dataNew = {
		...initialState.price
	}
	if (data.length > 0) {
		data.forEach(function(v, i){
			if (v.name_table === 'buffgrouptele_random') {
				initialState.taotientrinh.gtmtt = v.prices_web;
				dataNew['buffgrouptele_random'] = v;
			} else if (v.name_table === 'buffgrouptele_random_sv2') {
				dataNew['buffgrouptele_random_sv2'] = v;
			} else if (v.name_table === 'buffgrouptele_theoyeucau') {
				dataNew['buffgrouptele_theoyeucau'] = v;
			}
		});
	};
	return dataNew;
}
