import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Getcookie from "./tabGetcookiefb/getcookie";

class Getcookiefb extends Component {
	static propTypes =  {
		
	};
	render() {
		const { loading } = this.props.Getcookiefb;
		return (
			<Fragment>
				<div className={"block block-bordered shadow"+ (loading? ' block-mode-loading' : '')}>
					<div className="block-header block-header-default">
						<h3 className="block-title">Lấy Cookie</h3>
					</div>
					<div className="block-content">
						<Getcookie />
					</div>
				</div>
			</Fragment>
		);
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	Getcookiefb: state.Getcookiefb,
});
const mapDispatchToProps = {  };

export default connect(mapStateToProps, mapDispatchToProps)(Getcookiefb);
