import React from 'react';
import axios from 'axios';
import { loadUser } from "./authActions";
import { GetListData } from "./vipbotliveAction";

import {
	LOAD_FBB,
	UPDATE_FBB_TAB,
	UPDATE_FBB,
	UPDATE_COOKIE_FBB,
	LOADING_FBB,
	LOAD_PRICE_FBB,
	LOAD_FBBV,
	UPDATE_FBBV_TAB,
	UPDATE_FBBV,
	UPDATE_COOKIE_FBBV,
	LOADING_FBBV,
	LOAD_PRICE_FBBV,
	LOAD_FBBC,
	UPDATE_FBBC_TAB,
	UPDATE_FBBC,
	UPDATE_COOKIE_FBBC,
	LOADING_FBBC,
	LOAD_PRICE_FBBC,
	LOAD_PROXY_FBBC,
	LOAD_PROXY_EDIT_FBBC,
	LOAD_FBRIC,
	UPDATE_FBRIC_TAB,
	UPDATE_FBRIC,
	LOAD_PRICE_FBRIC,
	LIMIT_PAGI_FBB,
	TYPE_FBB,
	LIMIT_PAGI_FBBV,
	TYPE_FBBV,
	LIMIT_PAGI_FBBC,
	TYPE_FBBC,
	LIMIT_PAGI_FBRIC,
	TYPE_FBRIC,
	LOAD_NHATKYHOATDONG_FBBC,
	LOAD_NHATKYHOATDONG_FBBV,
	LOADING_FBRIC,
	LOAD_PROXY_FBBV,
	LOAD_PROXY_EDIT_FBBV,
	UPDATE_STICKER,
	LOAD_STICKER,
	UPDATE_LIST_STICKER,
	LOADING_FBBLS,
	LOAD_FBBLS,
	LOAD_PROXY_FBBLS,
	LOAD_NHATKYHOATDONG_FBBLS,
	LOAD_PROXY_EDIT_FBBLS,
	LIMIT_PAGI_FBBLS,
	TYPE_FBBLS,
} from "../actions/types";
import Swal from 'sweetalert2';

export const load = (fbbot_type, type = 0, page = 1, obj_search = '') => (dispatch, getState) => {
	if (fbbot_type === 'facebookbot' || fbbot_type === 'facebookbotv2') {
		dispatch({
			type: LOADING_FBB,
			payload: true
		});
		dispatch({
			type: LOADING_FBBV,
			payload: true
		});
	}
	if (fbbot_type === 'facebookbotcmt') {
		dispatch({
			type: LOADING_FBBC,
			payload: true
		});
	}
	if (fbbot_type === 'facebookbotreplyinboxcho') {
		dispatch({
			type: LOADING_FBRIC,
			payload: true
		});
	}
	if (fbbot_type === 'facebookbotlovestory') {
		dispatch({
			type: LOADING_FBBLS,
			payload: true
		});
	}
	axios.get('/api/fbbot?fbbot_type='+fbbot_type+'&type='+type+'&page='+page+'&obj_search='+obj_search, tokenConfig(getState))
		.then(res => {
			if (fbbot_type === 'facebookbot' || fbbot_type === 'facebookbotv2') {
				dispatch({
					type: LOADING_FBB,
					payload: false
				});
				dispatch({
					type: LOADING_FBBV,
					payload: false
				});
			}
			if (fbbot_type === 'facebookbotcmt') {
				dispatch({
					type: LOADING_FBBC,
					payload: false
				});
			}
			if (fbbot_type === 'facebookbotreplyinboxcho') {
				dispatch({
					type: LOADING_FBRIC,
					payload: false
				});
			}
			if (fbbot_type === 'facebookbotlovestory') {
				dispatch({
					type: LOADING_FBBLS,
					payload: false
				});
			}
			if (res.data.status === 200 && fbbot_type === 'facebookbot') {
				dispatch({
					type: LOAD_FBB,
					payload: res.data.data
				});
			}
			if (res.data.status === 200 && fbbot_type === 'facebookbotv2') {
				dispatch({
					type: LOAD_FBBV,
					payload: res.data.data
				});
			}
			if (res.data.status === 200 && fbbot_type === 'facebookbotcmt') {
				dispatch({
					type: LOAD_FBBC,
					payload: res.data.data
				});
			}
			if (res.data.status === 200 && fbbot_type === 'facebookbotreplyinboxcho') {
				dispatch({
					type: LOAD_FBRIC,
					payload: res.data.data
				});
			}
			if (res.data.status === 200 && fbbot_type === 'facebookbotlovestory') {
				dispatch({
					type: LOAD_FBBLS,
					payload: res.data.data
				});
			}
		});
};

export const add = (fbbot_type, body) => (dispatch, getState) => {
	if (fbbot_type === 'facebookbot' || fbbot_type === 'facebookbotv2' || fbbot_type === 'facebookbotcmt' || fbbot_type === 'facebookbotreplyinboxcho' || fbbot_type === 'facebookbotlovestory') {
		if (fbbot_type === 'facebookbot' || fbbot_type === 'facebookbotv2') {
			dispatch({
				type: LOADING_FBB,
				payload: true
			});
			dispatch({
				type: LOADING_FBBV,
				payload: true
			});
		}
		if (fbbot_type === 'facebookbotcmt') {
			dispatch({
				type: LOADING_FBBC,
				payload: true
			});
		}
		if (fbbot_type === 'facebookbotreplyinboxcho') {
			dispatch({
				type: LOADING_FBRIC,
				payload: true
			});
		}
		if (fbbot_type === 'facebookbotlovestory') {
			dispatch({
				type: LOADING_FBBLS,
				payload: true
			});
		}
		axios.post('/api/fbbot/add?fbbot_type='+fbbot_type, body, tokenConfig(getState))
			.then(res => {
				dispatch(loadUser());
				if (fbbot_type === 'facebookbot' || fbbot_type === 'facebookbotv2') {
					dispatch({
						type: LOADING_FBB,
						payload: false
					});
					dispatch({
						type: LOADING_FBBV,
						payload: false
					});
				}
				if (fbbot_type === 'facebookbotcmt') {
					dispatch({
						type: LOADING_FBBC,
						payload: false
					});
				}
				if (fbbot_type === 'facebookbotreplyinboxcho') {
					dispatch({
						type: LOADING_FBRIC,
						payload: false
					});
				}
				if (fbbot_type === 'facebookbotlovestory') {
					dispatch({
						type: LOADING_FBBLS,
						payload: false
					});
				}
				if (res.data.status === 200) {
					Swal.fire({
						icon: 'success',
						html: 'Tạo tiến trình thành công'
					});
				}
				if (res.data.status === 400) {
					var message = '';
					if (res.data.error) {
						if (res.data.error.object_id) {
							message = res.data.error.object_id[0];
						} else if (res.data.error.quantity) {
							message = res.data.error.quantity[0];
						} else {
							message = res.data.message;
						}
					} else {
						message = res.data.message;
					}
					Swal.fire({
						icon: 'error',
						title: 'Lỗi',
						text: message,
					});
				}
			});
	} else {
		return false;
	}
};

export const checkCookieFb = (cookiefb) => (dispatch, getState) => {
    let checkcookiefb = cookiefb.indexOf('c_user');
    if (checkcookiefb > -1) {
        let cookieRmFirst = cookiefb.slice(checkcookiefb+7);
        let cookieEndToken = cookieRmFirst.indexOf(';');
        const cookie_success = cookieRmFirst.substr(0, cookieEndToken);

        let bodysend = {
            cookie: cookiefb,
        }
        return axios.post('/api/fbbot/checkcookie', bodysend, tokenConfig(getState));
    } else {
    	return false;
    }
};

export const deleteFbbot = (fbbot_type, id) => (dispatch, getState) => {
	if (fbbot_type === 'facebookbot' || fbbot_type === 'facebookbotv2' || fbbot_type === 'facebookbotcmt' || fbbot_type === 'facebookbotreplyinboxcho' || fbbot_type === 'facebookbotlovestory') {
		Swal.fire({
			title: 'Bạn có chắc?',
			text: "Sau khi hủy bạn sẽ bị trừ 7 ngày chưa sử dụng và 5000 phí!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Đồng ý',
			cancelButtonText: 'Không'
		}).then((result) => {
			if (result.value) {
				if (fbbot_type === 'facebookbot' || fbbot_type === 'facebookbotv2') {
					dispatch({
						type: LOADING_FBB,
						payload: true
					});
					dispatch({
						type: LOADING_FBBV,
						payload: true
					});
				}
				if (fbbot_type === 'facebookbotcmt') {
					dispatch({
						type: LOADING_FBBC,
						payload: true
					});
				}
				if (fbbot_type === 'facebookbotreplyinboxcho') {
					dispatch({
						type: LOADING_FBRIC,
						payload: true
					});
				}
				if (fbbot_type === 'facebookbotlovestory') {
					dispatch({
						type: LOADING_FBBLS,
						payload: true
					});
				}
				
				let bodysend = {
					id: id,
					id_user: getState().auth.user.id
				};

				axios.post('/api/fbbot/delete?fbbot_type='+fbbot_type, bodysend, tokenConfig(getState))
					.then(res => {
						if (fbbot_type === 'facebookbot' || fbbot_type === 'facebookbotv2') {
							dispatch({
								type: LOADING_FBB,
								payload: false
							});
							dispatch({
								type: LOADING_FBBV,
								payload: false
							});
						}
						if (fbbot_type === 'facebookbotcmt') {
							dispatch({
								type: LOADING_FBBC,
								payload: false
							});
						}
						if (fbbot_type === 'facebookbotreplyinboxcho') {
							dispatch({
								type: LOADING_FBRIC,
								payload: false
							});
						}
						if (fbbot_type === 'facebookbotlovestory') {
							dispatch({
								type: LOADING_FBBLS,
								payload: false
							});
						}
						
						if (res.data.status === 200) {
							dispatch(load(fbbot_type));
							Swal.fire({
								icon: 'success',
								html: 'Xóa thành công'
							});
						}
						if (res.data.status === 400) {
							var message = '';
							if (res.data.error) {
								if (res.data.error.object_id) {
									message = res.data.error.object_id[0];
								} else if (res.data.error.quantity) {
									message = res.data.error.quantity[0];
								} else {
									message = res.data.message;
								}
							} else {
								message = res.data.message;
							}
							Swal.fire({
								icon: 'error',
								title: 'Lỗi',
								text: message,
							});
						}
				});
			}
		});
	} else {
		return false;
	}
};

export const updateCookieFb = (fbbot_type, cookiefb, lnncx_type, dataidfb, dataid, ndrfb, enable_cmt, closeM, id_proxy = 0, state = "") => (dispatch, getState) => {
	if (fbbot_type === 'facebookbot' || fbbot_type === 'facebookbotv2' || fbbot_type === 'facebookbotcmt' || fbbot_type === 'facebookbotreplyinboxcho' || fbbot_type === 'facebookbotlovestory') {
		if (fbbot_type === 'facebookbot' || fbbot_type === 'facebookbotv2') {
			dispatch({
				type: LOADING_FBB,
				payload: true
			});
			dispatch({
				type: LOADING_FBBV,
				payload: true
			});
		}
		if (fbbot_type === 'facebookbotcmt') {
			dispatch({
				type: LOADING_FBBC,
				payload: true
			});
		}
		if (fbbot_type === 'facebookbotreplyinboxcho') {
			dispatch({
				type: LOADING_FBRIC,
				payload: true
			});
		}
		if (fbbot_type === 'facebookbotlovestory') {
			dispatch({
				type: LOADING_FBBLS,
				payload: true
			});
		}
		var fun_check = dispatch(checkCookieFb(cookiefb));
		if (fun_check !== false) {
			fun_check.then( res1 => {
				if (res1.data.status === 200) {
					var body = {};
					if (fbbot_type === 'facebookbot') {
						body = {
							id: dataid,
							id_user: getState().auth.user.id,
							cookie: cookiefb,
							lnncx_type: lnncx_type,
							idfb: dataidfb
						};
					}
					if (fbbot_type === 'facebookbotv2') {
						body = {
							id: state.id,
							idfb: state.idfb,
							cookie: state.cookiefb,
							lnncx_type: state.lnncx_type,
							lnncx_tdmn: state.lnncx_tdmn,
							id_proxy: state.id_proxy,
							tgctt_tu: state.tgctt_tu,
							tgctt_den: state.tgctt_den,
							ttv: state.ttv,
							gioitinh_edit: state.gioitinh_edit,
							blacklistid: state.blacklistid,
							bvtp: state.bvtp,
							listid: state.listid,
							ghichu: state.ghichu,
						};
					}
					if (fbbot_type === 'facebookbotcmt') {
						body = {
							id: state.id,
							idfb: state.idfb,
							cookie: state.cookiefb,
							lnncx: state.lnncx,
							lnncx_type: state.lnncx_type,
							blbv_cmt: state.blbv_cmt,
							blbv: state.blbv,
							id_proxy: id_proxy,
							tgctt_tu: state.tgctt_tu,
							tgctt_den: state.tgctt_den,
							blbv_tdmn: state.blbv_tdmn,
							lnncx_tdmn: state.lnncx_tdmn,
							ttv: state.ttv,
							gioitinh_edit: state.gioitinh_edit,
							blacklisttukhoa: state.blacklisttukhoa,
							blacklistid: state.blacklistid,
							bvtp: state.bvtp,
							listid: state.listid,
							sticker: state.sticker,
							commentanh: state.commentanh,
							s_check_edit: state.s_check_edit,
							ca_check_edit: state.ca_check_edit,
							sticker_pack: state.sticker_pack,
							ghichu: state.ghichu,
							newapi: state.newapi,
						};
					}
					if (fbbot_type === 'facebookbotreplyinboxcho') {
						body = {
							id: dataid,
							id_user: getState().auth.user.id,
							cookie: cookiefb,
							idfb: dataidfb,
							ndr: ndrfb
						};
					}
					if (fbbot_type === 'facebookbotlovestory') {
						body = {
							id: state.id,
							idfb: state.idfb,
							cookie: state.cookiefb,
							lnncx_type: state.lnncx_type,
							lnncx_tdmn: state.lnncx_tdmn,
							id_proxy: state.id_proxy,
							tgctt_tu: state.tgctt_tu,
							tgctt_den: state.tgctt_den,
							ttv: state.ttv,
							gioitinh_edit: state.gioitinh_edit,
							blacklistid: state.blacklistid,
							bvtp: state.bvtp,
							listid: state.listid,
							ghichu: state.ghichu,
							ttms: state.ttms,
							blbv: state.blbv,
							blbv_cmt: state.blbv_cmt,
							blbv_tdmn: state.blbv_tdmn,
						};
					}
					axios.post('/api/fbbot/updatecookie?fbbot_type='+fbbot_type, body, tokenConfig(getState))
						.then(res => {
							if (fbbot_type === 'facebookbot' || fbbot_type === 'facebookbotv2') {
								dispatch({
									type: LOADING_FBB,
									payload: false
								});
								dispatch({
									type: LOADING_FBBV,
									payload: false
								});
							}
							if (fbbot_type === 'facebookbotcmt') {
								dispatch({
									type: LOADING_FBBC,
									payload: false
								});
							}
							if (fbbot_type === 'facebookbotreplyinboxcho') {
								dispatch({
									type: LOADING_FBRIC,
									payload: false
								});
							}
							if (fbbot_type === 'facebookbotlovestory') {
								dispatch({
									type: LOADING_FBBLS,
									payload: false
								});
							}
							if (res.data.status === 200) {
								closeM();
								dispatch(load(fbbot_type));
								Swal.fire({
									icon: 'success',
									html: 'Cập nhật thành công'
								});
							}
							if (res.data.status === 400) {
								var message = '';
								if (res.data.error) {
									if (res.data.error.object_id) {
										message = res.data.error.object_id[0];
									} else if (res.data.error.quantity) {
										message = res.data.error.quantity[0];
									} else {
										message = res.data.message;
									}
								} else {
									message = res.data.message;
								}
								Swal.fire({
									icon: 'error',
									title: 'Lỗi',
									text: message,
								});
							}
						});
				} else {
					if (fbbot_type === 'facebookbot' || fbbot_type === 'facebookbotv2') {
						dispatch({
							type: LOADING_FBB,
							payload: false
						});
						dispatch({
							type: LOADING_FBBV,
							payload: false
						});
					}
					if (fbbot_type === 'facebookbotcmt') {
						dispatch({
							type: LOADING_FBBC,
							payload: false
						});
					}
					if (fbbot_type === 'facebookbotreplyinboxcho') {
						dispatch({
							type: LOADING_FBRIC,
							payload: false
						});
					}
					if (fbbot_type === 'facebookbotlovestory') {
						dispatch({
							type: LOADING_FBBLS,
							payload: false
						});
					}
					Swal.fire({
						icon: 'error',
						title: 'Lỗi',
						text: 'Cookie die hoặc không hợp lệ #2',
					});
					return false;
				}
			})
		} else {
			if (fbbot_type === 'facebookbot' || fbbot_type === 'facebookbotv2') {
				dispatch({
					type: LOADING_FBB,
					payload: false
				});
				dispatch({
					type: LOADING_FBBV,
					payload: false
				});
			}
			if (fbbot_type === 'facebookbotcmt') {
				dispatch({
					type: LOADING_FBBC,
					payload: false
				});
			}
			if (fbbot_type === 'facebookbotreplyinboxcho') {
				dispatch({
					type: LOADING_FBRIC,
					payload: false
				});
			}
			if (fbbot_type === 'facebookbotlovestory') {
				dispatch({
					type: LOADING_FBBLS,
					payload: false
				});
			}
			Swal.fire({
				icon: 'error',
				title: 'Lỗi',
				text: 'Cookie die hoặc không hợp lệ #1',
			});
			return false;
		}
	} else {
		return false;
	}
};

export const GiaHanFb = (fbbot_type, giahanthem, dataid, gtmtt, closeM) => (dispatch, getState) => {
	if (fbbot_type === 'facebookbot' || fbbot_type === 'facebookbotv2' || fbbot_type === 'facebookbotcmt' || fbbot_type === 'facebookbotreplyinboxcho' || fbbot_type === 'bufffbvipbotlive' || fbbot_type === 'facebookbotlovestory') {
		let body = {
			id: dataid,
			id_user: getState().auth.user.id,
			giahanthem: giahanthem,
			gtmtt: gtmtt,
		};
		if (fbbot_type === 'facebookbot' || fbbot_type === 'facebookbotv2') {
			dispatch({
				type: LOADING_FBB,
				payload: true
			});
			dispatch({
				type: LOADING_FBBV,
				payload: true
			});
		}
		if (fbbot_type === 'facebookbotcmt') {
			dispatch({
				type: LOADING_FBBC,
				payload: true
			});
		}
		if (fbbot_type === 'facebookbotreplyinboxcho') {
			dispatch({
				type: LOADING_FBRIC,
				payload: true
			});
		}
		if (fbbot_type === 'bufffbvipbotlive') {
			dispatch({
				type: 'LOADING_DATA_FB_VIPBOTLIKE',
				payload: true
			});
		}
		if (fbbot_type === 'facebookbotlovestory') {
			dispatch({
				type: LOADING_FBBLS,
				payload: true
			});
		}
		axios.post('/api/fbbot/giahanthem?fbbot_type='+fbbot_type, body, tokenConfig(getState))
			.then(res => {
				if (fbbot_type === 'facebookbot' || fbbot_type === 'facebookbotv2') {
					dispatch({
						type: LOADING_FBB,
						payload: false
					});
					dispatch({
						type: LOADING_FBBV,
						payload: false
					});
				}
				if (fbbot_type === 'facebookbotcmt') {
					dispatch({
						type: LOADING_FBBC,
						payload: false
					});
				}
				if (fbbot_type === 'facebookbotreplyinboxcho') {
					dispatch({
						type: LOADING_FBRIC,
						payload: false
					});
				}
				if (fbbot_type === 'bufffbvipbotlive') {
					dispatch({
						type: 'LOADING_DATA_FB_VIPBOTLIKE',
						payload: false
					});
				}
				if (fbbot_type === 'facebookbotlovestory') {
					dispatch({
						type: LOADING_FBBLS,
						payload: false
					});
				}
				dispatch(GetListData());
				dispatch(load(fbbot_type));
				dispatch(loadUser());
				closeM();
				if (res.data.status === 200) {
					Swal.fire({
						icon: 'success',
						html: 'Cập nhật thành công'
					});
				}
				if (res.data.status === 400) {
					var message = '';
					if (res.data.error) {
						if (res.data.error.object_id) {
							message = res.data.error.object_id[0];
						} else if (res.data.error.quantity) {
							message = res.data.error.quantity[0];
						} else {
							message = res.data.message;
						}
					} else {
						message = res.data.message;
					}
					Swal.fire({
						icon: 'error',
						title: 'Lỗi',
						text: message,
					});
				}
			});
	} else {
		return false;
	}
};

export const updateActive = (fbbot_type, body) => (dispatch, getState) => {
	if (fbbot_type === 'facebookbot' || fbbot_type === 'facebookbotv2' || fbbot_type === 'facebookbotcmt' || fbbot_type === 'facebookbotreplyinboxcho' || fbbot_type === 'facebookbotlovestory') {
		axios.post('/api/fbbot/active?fbbot_type='+fbbot_type, body, tokenConfig(getState))
			.then(res => {
				dispatch(load(fbbot_type));
			});
	} else {
		return false;
	}
};

export const updateLimit = (fbbot_type, body) => (dispatch) => {
	if (fbbot_type === 'facebookbot') {
		dispatch({
			type: LIMIT_PAGI_FBB,
			payload: body
		});
	}
	if (fbbot_type === 'facebookbotv2') {
		dispatch({
			type: LIMIT_PAGI_FBBV,
			payload: body
		});
	}
	if (fbbot_type === 'facebookbotcmt') {
		dispatch({
			type: LIMIT_PAGI_FBBC,
			payload: body
		});
	}
	if (fbbot_type === 'facebookbotreplyinboxcho') {
		dispatch({
			type: LIMIT_PAGI_FBRIC,
			payload: body
		});
	}
	if (fbbot_type === 'facebookbotlovestory') {
		dispatch({
			type: LIMIT_PAGI_FBBLS,
			payload: body
		});
	}
};

export const updateType = (fbbot_type, body) => (dispatch) => {
	if (fbbot_type === 'facebookbot') {
		dispatch({
			type: TYPE_FBB,
			payload: body
		});
	}
	if (fbbot_type === 'facebookbotv2') {
		dispatch({
			type: TYPE_FBBV,
			payload: body
		});
	}
	if (fbbot_type === 'facebookbotcmt') {
		dispatch({
			type: TYPE_FBBC,
			payload: body
		});
	}
	if (fbbot_type === 'facebookbotreplyinboxcho') {
		dispatch({
			type: TYPE_FBRIC,
			payload: body
		});
	}
	if (fbbot_type === 'facebookbotlovestory') {
		dispatch({
			type: TYPE_FBBLS,
			payload: body
		});
	}
};

export const loadProxyFb = (fbbot_type) => (dispatch, getState) => {
	if (fbbot_type === 'facebookbotcmt' || fbbot_type === 'facebookbotv2' || fbbot_type === 'facebookbotlovestory') {
		axios.get('/api/fbbot/loadproxy?fbbot_type='+fbbot_type, tokenConfig(getState))
			.then(res => {
				if (res.data.status === 200) {
					dispatch({
						type: LOAD_PROXY_FBBC,
						payload: res.data.data
					});
					dispatch({
						type: LOAD_PROXY_FBBV,
						payload: res.data.data
					});
					dispatch({
						type: LOAD_PROXY_FBBLS,
						payload: res.data.data
					});
				}
			});
	} else {
		return false;
	}
};

export const loadProxyFbEdit = (fbbot_type, id_user) => (dispatch, getState) => {
	if (fbbot_type === 'facebookbotcmt' || fbbot_type === 'facebookbotv2' || fbbot_type === 'facebookbotlovestory') {
		axios.get('/api/fbbot/loadproxyedit?fbbot_type='+fbbot_type+'&id_user='+id_user, tokenConfig(getState))
			.then(res => {
				if (res.data.status === 200) {
					dispatch({
						type: LOAD_PROXY_EDIT_FBBC,
						payload: res.data.data
					});
					dispatch({
						type: LOAD_PROXY_EDIT_FBBV,
						payload: res.data.data
					});
					dispatch({
						type: LOAD_PROXY_EDIT_FBBLS,
						payload: res.data.data
					});
				}
			});
	} else {
		return false;
	}
};

export const NhatKyHoatDong = (fbbot_type, idfb) => (dispatch, getState) => {
	axios.get('/api/fbbot/nhatkyhoatdong?fbbot_type='+fbbot_type+'&idfb='+idfb, tokenConfig(getState))
		.then(res => {
			if (res.data.status === 200) {
				if (fbbot_type === 'facebookbotv2') {
					dispatch({
						type: LOAD_NHATKYHOATDONG_FBBV,
						payload: res.data.data
					});
				}
				if (fbbot_type === 'facebookbotcmt') {
					dispatch({
						type: LOAD_NHATKYHOATDONG_FBBC,
						payload: res.data.data
					});
				}
				if (fbbot_type === 'facebookbotlovestory') {
					dispatch({
						type: LOAD_NHATKYHOATDONG_FBBLS,
						payload: res.data.data
					});
				}
			}
			if (res.data.status === 400) {
				if (fbbot_type === 'facebookbotv2') {
					dispatch({
						type: LOAD_NHATKYHOATDONG_FBBV,
						payload: ""
					});
				}
				if (fbbot_type === 'facebookbotcmt') {
					dispatch({
						type: LOAD_NHATKYHOATDONG_FBBC,
						payload: ""
					});
				}
				if (fbbot_type === 'facebookbotlovestory') {
					dispatch({
						type: LOAD_NHATKYHOATDONG_FBBLS,
						payload: ""
					});
				}
			}
		});
};

export const closeModalNhatKy = (fbbot_type) => (dispatch, getState) => {
	if (fbbot_type === 'facebookbotv2') {
		dispatch({
			type: LOAD_NHATKYHOATDONG_FBBV,
			payload: ""
		});
	}
	if (fbbot_type === 'facebookbotcmt') {
		dispatch({
			type: LOAD_NHATKYHOATDONG_FBBC,
			payload: ""
		});
	}
};

export const updateSticker = (fbbot_type, sticker = []) => (dispatch, getState) => {
	if (fbbot_type === 'facebookbotcmt') {
		dispatch({
			type: UPDATE_STICKER,
			payload: sticker
		});
	} else {
		return false;
	}
};

export const clearListSticker = (fbbot_type) => (dispatch, getState) => {
	if (fbbot_type === 'facebookbotcmt') {
		dispatch({
			type: UPDATE_LIST_STICKER,
			payload: []
		});
	} else {
		return false;
	}
};

export const loadSticker = (fbbot_type, id = 0) => (dispatch, getState) => {
	if (fbbot_type === 'facebookbotcmt') {
		dispatch({
			type: LOADING_FBBC,
			payload: true
		});
		axios.get('/api/fbbot/loadsticker?fbbot_type='+fbbot_type+'&id='+id, tokenConfig(getState))
			.then(res => {
				dispatch({
					type: LOADING_FBBC,
					payload: false
				});
				if (res.data.status === 200) {
					if (fbbot_type === 'facebookbotcmt') {
						dispatch({
							type: LOAD_STICKER,
							payload: res.data.data
						});
					}
				}
			});
	} else {
		return false;
	}
};

export const tokenConfig = getState => {
	// Get token from localhost
	const token = getState().auth.token;
	// Headers
	const config = {
		headers: {
			"Content-type": "application/json"
		}
	};
	
	// If token, add to headers
	if (token) {
		config.headers['ht-token'] = token;
	}
	
	return config;
};