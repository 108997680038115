import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import { add_task_like_comment_share,getDataPriceFunction,getConfigMoDong, getIDVideo, getIDPost } from "../../../actions/BufflikecommentshareActions";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Swal from 'sweetalert2';
import axios from 'axios';
import store from '../../../store';
import parse from 'html-react-parser';

import iconLikeFb from '../../../iconfb/like.svg';
import iconHahaFb from '../../../iconfb/haha.svg';
import iconAngryFb from '../../../iconfb/angry.svg';
import iconLoveFb from '../../../iconfb/love.svg';
import iconSadFb from '../../../iconfb/sad.svg';
import iconWowFb from '../../../iconfb/wow.svg';
import iconCareFb from '../../../iconfb/care.svg';
import ServicesDescription from "../ServicesDescription";
import { isMobile } from 'react-device-detect';

class Taotientrinh extends Component {
	componentDidMount(){
		// this.props.getDataPriceFunction();
        this.props.getConfigMoDong('bufflike');
	}

	state = {
		showDescription: isMobile? false : true,
		showDanger: false,
	}
	
	handleChangeDateStart = date => {
	    let dataFormOld = this.props.buffsub.formData;
		let dataFormNew = {
			...dataFormOld,
			EndDatebh: this.addDays(date, 7),
			startDatebh: date,
		}

		store.dispatch({
			type: 'CHANGE_DATA_FORM_YTB_LCS_LIKE',
			payload: dataFormNew
		});
	}

	handleChangeDateEnd = date => {
	    let dataFormOld = this.props.buffsub.formData;
		let dataFormNew = {
			...dataFormOld,
			EndDatebh: date
		}

		store.dispatch({
			type: 'CHANGE_DATA_FORM_YTB_LCS_LIKE',
			payload: dataFormNew
		});
	}

	addDays = (date, days) => {
	  	var result = new Date(date);
	  	result.setDate(result.getDate() + days);
	  	return result;
	}

	searchUidFacebook = async (e) => {
        let valueS = e.target.value;
        let dataFormOld = this.props.buffsub.formData;
        var id = await this.getIDFacebook(valueS);
        let dataFormNew = {
            ...dataFormOld,
            profile_user: id
        }
        store.dispatch({
            type: 'CHANGE_DATA_FORM_YTB_LCS_LIKE',
            payload: dataFormNew
        });
    }
    async getIDFacebook(t) {
        let e = null;
        if (t) {
            if (t.indexOf("facebook.com") < 0 && t.indexOf("fb.watch") < 0 && t.indexOf("fb.gg") < 0 && t.indexOf("fb") < 0) e = t;
            else {
                var h = t.match(/substory_index/),
					s = t.match(/(.*)\/posts\/([0-9]{8,})/),
                    q = t.match(/(.*)\/posts\/(.*)/),
                    a = t.match(/(.*)\/photo.php\?fbid=([0-9]{8,})/),
                    k = t.match(/(.*)\/photo\/([0-9]{8,})\?/),
                    i = t.match(/(.*)\/video.php\?v=([0-9]{8,})/),
                    n = t.match(/(.*)\/watch\/\?v=([0-9]{8,})/),
                    o = t.match(/(.*)\/watch\/live\/\?v=([0-9]{8,})/),
                    r = t.match(/story.php/),
                    u = t.match(/story_fbid/),
                    l = t.match(/permalink/),
                    c = t.match(/(.*)\/([0-9]{8,})/),
                    f = t.match(/(.*)fbid=([0-9]{8,})/),
					d = t.match(/(.*)comment_id=([0-9]{8,})/),
					g = t.match(/(.*)set=a.([0-9]{8,})/),
					
					z = t.match(/live\/\?v=([0-9]+)/),
                    x = t.match(/videos\/([0-9]+)/),
                    y = t.match(/watch_permalink&v=([0-9]+)/),
                    m = t.match(/ref=sharing&v=([0-9]+)/),
                    p = t.match(/(.*)?v=([0-9]{8,})/);
                if (h !== null) {
					return await this.props.getIDPost(t);
                } else if (s !== null) {
                    return await this.props.getIDPost(t);
                } else if (q !== null) {
					return await this.props.getIDPost(t);
                } else if (a !== null) {
                    return a[2];
                } else if (k !== null) {
                    return k[2];
                } else if (i !== null) {
                    return i[2];
                } else if (n !== null) {
                    return n[2];
                } else if (o !== null) {
                    return o[2];
                } else if (r !== null) {
					return await this.props.getIDPost(t);
                } else if (l !== null) {
					return await this.props.getIDPost(t);
                } else if (u !== null) {
					return await this.props.getIDPost(t);
                } else if (c !== null) {
                    return c[2];
                } else if (f !== null) {
                    return f[2];
                } else if (d !== null) {
                    return d[2];
                } else if (g !== null) {
                    return g[2];
                } else if (z !== null) {
                    return z[1];
                } else if (x !== null) {
                    return x[1];
                } else if (y !== null) {
                    return y[1];
                } else if (m !== null) {
                    return m[1];
                } else if (p !== null) {
                    return p[2];
                } else if (t.indexOf("fb.watch") > -1 || t.indexOf("fb.gg") > -1) {
					return await this.props.getIDVideo(t);
                } else {
					return await this.props.getIDPost(t);
                }
            }
            return e
        }
    }

	onChangeFormCheckbox = (e) => {
		let valueC = '';
		let pricest = 0;
		let {baohanh, loaiseeding} = this.props.buffsub.formData;

		if (e.target.checked) {
			valueC = e.target.value;
		}
		if (valueC === 'care' || valueC === 'love' ||
			valueC === 'haha' || valueC === 'wow' ||
			valueC === 'sad' || valueC === 'angry' ||
			valueC === 'like') {
			let nameVlKey = valueC;
			if (loaiseeding === 'like_clone') {
				nameVlKey += '_clone';
			}
			if (loaiseeding === 'like_v2') {
				// nameVlKey += '_v2';
				nameVlKey = 'like_v2';
			}
			if (loaiseeding === 'like_v3') {
				// nameVlKey += '_v3';
				nameVlKey = 'like_v3';
			}
			if (loaiseeding === 'like_cmt') {
				// nameVlKey += '_cmt';
				nameVlKey = 'like_cmt';
			}
			if (loaiseeding === 'like_v4') {
				// nameVlKey += '_cmt';
				nameVlKey = 'like_v4';
			}
			if (loaiseeding === 'like_v5') {
				// nameVlKey += '_cmt';
				nameVlKey = 'like_v5';
			}
			if (loaiseeding === 'like_v6') {
				nameVlKey = 'like_v6';
			}
			if (loaiseeding === 'like_v7') {
				nameVlKey = 'like_v7';
			}
			if (loaiseeding === 'like_v8') {
				nameVlKey = 'like_v8';
			}
			if (loaiseeding === 'like_v9') {
				nameVlKey = 'like_v9';
			}
			if (loaiseeding === 'like_v10') {
				nameVlKey = 'like_v10';
			}
			if (loaiseeding === 'like_v11') {
				nameVlKey = 'like_v11';
			}
			if (baohanh===1) {
				pricest = this.props.buffsub.datapricebuffsub[nameVlKey].min_bh_web;
			} else {
				pricest = this.props.buffsub.datapricebuffsub[nameVlKey].prices_web;
			}
		} else {
			var nameloai = 'like';
			if (loaiseeding === 'like_clone') {
				nameloai = 'like_clone';
			}
			if (loaiseeding === 'like_v2') {
				nameloai = 'like_v2';
			}
			if (loaiseeding === 'like_v3') {
				nameloai = 'like_v3';
			}
			if (loaiseeding === 'like_cmt') {
				nameloai = 'like_cmt';
			}
			if (loaiseeding === 'like_v4') {
				nameloai = 'like_v4';
			}
			if (loaiseeding === 'like_v5') {
				nameloai = 'like_v5';
			}
			if (loaiseeding === 'like_v6') {
				nameloai = 'like_v6';
			}
			if (loaiseeding === 'like_v7') {
				nameloai = 'like_v7';
			}
			if (loaiseeding === 'like_v8') {
				nameloai = 'like_v8';
			}
			if (loaiseeding === 'like_v9') {
				nameloai = 'like_v9';
			}
			if (loaiseeding === 'like_v10') {
				nameloai = 'like_v10';
			}
			if (loaiseeding === 'like_v11') {
				nameloai = 'like_v11';
			}
			if (baohanh===1) {
				pricest = this.props.buffsub.datapricebuffsub[nameloai].min_bh_web;
			} else {
				pricest = this.props.buffsub.datapricebuffsub[nameloai].prices_web;
			}
		}

		let dataFormOld = this.props.buffsub.formData;
		let dataFormNew = {
			...dataFormOld,
			type: valueC,
			giatien: pricest
		}
		store.dispatch({
			type: 'CHANGE_DATA_FORM_YTB_LCS_LIKE',
			payload: dataFormNew
		});
	}

	onChangeForm = (e) => {
		let valueadd = '';
		if (e.target.type === 'radio' || e.target.type === 'select-one' || e.target.type === 'number') {
			if (e.target.value.trim() !== '' || e.target.value !== '') {
				valueadd = Math.abs(parseInt(e.target.value));
				if (isNaN(valueadd)) {
					valueadd = '';
				}
			} else {
				valueadd = '';
			}
		} else {
			valueadd = e.target.value;
		}

		let dataFormOld = this.props.buffsub.formData;
		let dataFormNew = {
			...dataFormOld,
			[e.target.name]: valueadd
		}

		store.dispatch({
			type: 'CHANGE_DATA_FORM_YTB_LCS_LIKE',
			payload: dataFormNew
		});
	}

	onChangeSpeciesSend = (e) => {
		let pricest = 0;
		let {baohanh} = this.props.buffsub.formData;
		if (e.target.value === 'share') {
			if (baohanh===1) {
				pricest = this.props.buffsub.datapricebuffsub.share.min_bh_web;
			} else {
				pricest = this.props.buffsub.datapricebuffsub.share.prices_web;
			}
		} else if (e.target.value === 'comment') {
			if (baohanh===1) {
				pricest = this.props.buffsub.datapricebuffsub.comment.min_bh_web;
			} else {
				pricest = this.props.buffsub.datapricebuffsub.comment.prices_web;
			}
		} else if (e.target.value === 'like_clone') {
			if (baohanh===1) {
				pricest = this.props.buffsub.datapricebuffsub.like_clone.min_bh_web;
			} else {
				pricest = this.props.buffsub.datapricebuffsub.like_clone.prices_web;
			}
		} else if (e.target.value === 'like_v4') {
			baohanh = 0;
			if (baohanh===1) {
				pricest = this.props.buffsub.datapricebuffsub.like_v4.min_bh_web;
			} else {
				pricest = this.props.buffsub.datapricebuffsub.like_v4.prices_web;
			}
		} else if (e.target.value === 'like_v5') {
			baohanh = 0;
			if (baohanh===1) {
				pricest = this.props.buffsub.datapricebuffsub.like_v5.min_bh_web;
			} else {
				pricest = this.props.buffsub.datapricebuffsub.like_v5.prices_web;
			}
		} else if (e.target.value === 'like_v6') {
			if (baohanh===1) {
				pricest = this.props.buffsub.datapricebuffsub.like_v6.min_bh_web;
			} else {
				pricest = this.props.buffsub.datapricebuffsub.like_v6.prices_web;
			}
		} else if (e.target.value === 'like_v7') {
			if (baohanh===1) {
				pricest = this.props.buffsub.datapricebuffsub.like_v7.min_bh_web;
			} else {
				pricest = this.props.buffsub.datapricebuffsub.like_v7.prices_web;
			}
		} else if (e.target.value === 'like_v8') {
			if (baohanh===1) {
				pricest = this.props.buffsub.datapricebuffsub.like_v8.min_bh_web;
			} else {
				pricest = this.props.buffsub.datapricebuffsub.like_v8.prices_web;
			}
		} else if (e.target.value === 'like_v9') {
			if (baohanh===1) {
				pricest = this.props.buffsub.datapricebuffsub.like_v9.min_bh_web;
			} else {
				pricest = this.props.buffsub.datapricebuffsub.like_v9.prices_web;
			}
		} else if (e.target.value === 'like_v10') {
			if (baohanh===1) {
				pricest = this.props.buffsub.datapricebuffsub.like_v10.min_bh_web;
			} else {
				pricest = this.props.buffsub.datapricebuffsub.like_v10.prices_web;
			}
		} else if (e.target.value === 'like_v11') {
			if (baohanh===1) {
				pricest = this.props.buffsub.datapricebuffsub.like_v11.min_bh_web;
			} else {
				pricest = this.props.buffsub.datapricebuffsub.like_v11.prices_web;
			}
		} else if (e.target.value === 'comment_pro') {
			if (baohanh===1) {
				pricest = this.props.buffsub.datapricebuffsub.comment_pro.min_bh_web;
			} else {
				pricest = this.props.buffsub.datapricebuffsub.comment_pro.prices_web;
			}
		} else if (e.target.value === 'like_v2') {
			if (baohanh===1) {
				pricest = this.props.buffsub.datapricebuffsub.like_v2.min_bh_web;
			} else {
				pricest = this.props.buffsub.datapricebuffsub.like_v2.prices_web;
			}
		} else if (e.target.value === 'like_v3') {
			if (baohanh===1) {
				pricest = this.props.buffsub.datapricebuffsub.like_v3.min_bh_web;
			} else {
				pricest = this.props.buffsub.datapricebuffsub.like_v3.prices_web;
			}
		} else if (e.target.value === 'like_cmt') {
			baohanh = 0;
			if (baohanh===1) {
				pricest = this.props.buffsub.datapricebuffsub.like_cmt.min_bh_web;
			} else {
				pricest = this.props.buffsub.datapricebuffsub.like_cmt.prices_web;
			}
		} else {
			if (baohanh===1) {
				pricest = this.props.buffsub.datapricebuffsub.like.min_bh_web;
			} else {
				pricest = this.props.buffsub.datapricebuffsub.like.prices_web;
			}
		}

		let dataFormOld = this.props.buffsub.formData;
		let dataFormNew = {
			...dataFormOld,
			[e.target.name]: e.target.value,
			giatien: pricest,
			baohanh: baohanh,
			type: ''
		}

		store.dispatch({
			type: 'CHANGE_DATA_FORM_YTB_LCS_LIKE',
			payload: dataFormNew
		});
	}

	onChangeBH = (e) => {
		let {loaiseeding, giatien, type} = this.props.buffsub.formData;

		let pricest = giatien;

		if (e.target.value === '1') {
			if (loaiseeding=== 'share') {
				pricest = this.props.buffsub.datapricebuffsub.share.min_bh_web;
			} else if (loaiseeding === 'comment') {
				pricest = this.props.buffsub.datapricebuffsub.comment.min_bh_web;
			} else if (loaiseeding === 'comment_pro') {
				pricest = this.props.buffsub.datapricebuffsub.comment_pro.min_bh_web;
			} else if (loaiseeding === 'like') {
				if (type.trim() == '') {
					type = 'like';
				}
				pricest = this.props.buffsub.datapricebuffsub[type].min_bh_web;
			} else if (loaiseeding === 'like_clone') {
				if (type.trim() == '') {
					type = 'like';
				}
				pricest = this.props.buffsub.datapricebuffsub[type+'_clone'].min_bh_web;
			} else if (loaiseeding === 'like_v4') {
				if (type.trim() == '') {
					type = 'like';
				}
				pricest = this.props.buffsub.datapricebuffsub[type+'_v4'].min_bh_web;
			} else if (loaiseeding === 'like_v5') {
				if (type.trim() == '') {
					type = 'like';
				}
				pricest = this.props.buffsub.datapricebuffsub[type+'_v5'].min_bh_web;
			} else if (loaiseeding === 'like_v6') {
				if (type.trim() == '') {
					type = 'like';
				}
				pricest = this.props.buffsub.datapricebuffsub[type+'_v6'].min_bh_web;
			} else if (loaiseeding === 'like_v7') {
				if (type.trim() == '') {
					type = 'like';
				}
				pricest = this.props.buffsub.datapricebuffsub[type+'_v7'].min_bh_web;
			} else if (loaiseeding === 'like_v8') {
				if (type.trim() == '') {
					type = 'like';
				}
				pricest = this.props.buffsub.datapricebuffsub[type+'_v8'].min_bh_web;
			} else if (loaiseeding === 'like_v9') {
				if (type.trim() == '') {
					type = 'like';
				}
				pricest = this.props.buffsub.datapricebuffsub[type+'_v9'].min_bh_web;
			} else if (loaiseeding === 'like_v10') {
				if (type.trim() == '') {
					type = 'like';
				}
				pricest = this.props.buffsub.datapricebuffsub[type+'_v10'].min_bh_web;
			} else if (loaiseeding === 'like_v11') {
				if (type.trim() == '') {
					type = 'like';
				}
				pricest = this.props.buffsub.datapricebuffsub[type+'_v11'].min_bh_web;
			} else if (loaiseeding === 'like_v2') {
				// if (type.trim() == '') {
					type = 'like';
				// }
				pricest = this.props.buffsub.datapricebuffsub[type+'_v2'].min_bh_web;
			} else if (loaiseeding === 'like_v3') {
				// if (type.trim() == '') {
					type = 'like';
				// }
				pricest = this.props.buffsub.datapricebuffsub[type+'_v3'].min_bh_web;
			} else if (loaiseeding === 'like_cmt') {
				// if (type.trim() == '') {
					type = 'like';
				// }
				pricest = this.props.buffsub.datapricebuffsub[type+'_cmt'].min_bh_web;
			} else {
				pricest = this.props.buffsub.datapricebuffsub.like.min_bh_web;
			}
		} else {
			if (loaiseeding=== 'share') {
				pricest = this.props.buffsub.datapricebuffsub.share.prices_web;
			} else if (loaiseeding === 'comment') {
				pricest = this.props.buffsub.datapricebuffsub.comment.prices_web;
			} else if (loaiseeding === 'comment_pro') {
				pricest = this.props.buffsub.datapricebuffsub.comment_pro.prices_web;
			} else if (loaiseeding === 'like') {
				if (type.trim() == '') {
					type = 'like';
				}
				pricest = this.props.buffsub.datapricebuffsub[type].prices_web;
			} else if (loaiseeding === 'like_clone') {
				if (type.trim() == '') {
					type = 'like';
				}
				pricest = this.props.buffsub.datapricebuffsub[type+'_clone'].prices_web;
			} else if (loaiseeding === 'like_v4') {
				if (type.trim() == '') {
					type = 'like';
				}
				pricest = this.props.buffsub.datapricebuffsub[type+'_v4'].prices_web;
			} else if (loaiseeding === 'like_v5') {
				if (type.trim() == '') {
					type = 'like';
				}
				pricest = this.props.buffsub.datapricebuffsub[type+'_v5'].prices_web;
			} else if (loaiseeding === 'like_v6') {
				if (type.trim() == '') {
					type = 'like';
				}
				pricest = this.props.buffsub.datapricebuffsub[type+'_v6'].prices_web;
			} else if (loaiseeding === 'like_v7') {
				if (type.trim() == '') {
					type = 'like';
				}
				pricest = this.props.buffsub.datapricebuffsub[type+'_v7'].prices_web;
			} else if (loaiseeding === 'like_v8') {
				if (type.trim() == '') {
					type = 'like';
				}
				pricest = this.props.buffsub.datapricebuffsub[type+'_v8'].prices_web;
			} else if (loaiseeding === 'like_v9') {
				if (type.trim() == '') {
					type = 'like';
				}
				pricest = this.props.buffsub.datapricebuffsub[type+'_v9'].prices_web;
			} else if (loaiseeding === 'like_v10') {
				if (type.trim() == '') {
					type = 'like';
				}
				pricest = this.props.buffsub.datapricebuffsub[type+'_v10'].prices_web;
			} else if (loaiseeding === 'like_v11') {
				if (type.trim() == '') {
					type = 'like';
				}
				pricest = this.props.buffsub.datapricebuffsub[type+'_v11'].prices_web;
			} else if (loaiseeding === 'like_v2') {
				// if (type.trim() == '') {
					type = 'like';
				// }
				pricest = this.props.buffsub.datapricebuffsub[type+'_v2'].prices_web;
			} else if (loaiseeding === 'like_v3') {
				// if (type.trim() == '') {
					type = 'like';
				// }
				pricest = this.props.buffsub.datapricebuffsub[type+'_v3'].prices_web;
			} else if (loaiseeding === 'like_cmt') {
				// if (type.trim() == '') {
					type = 'like';
				// }
				pricest = this.props.buffsub.datapricebuffsub[type+'_cmt'].prices_web;
			} else {
				pricest = this.props.buffsub.datapricebuffsub.like.prices_web;
			}
		}

		let dataFormOld = this.props.buffsub.formData;
		let dataFormNew = {
			...dataFormOld,
			[e.target.name]: parseInt(e.target.value),
			giatien: pricest
		}

		store.dispatch({
			type: 'CHANGE_DATA_FORM_YTB_LCS_LIKE',
			payload: dataFormNew
		});
	}

	getSltangComment = (e) => {
		let el = e.target;

		let dataFormOld = this.props.buffsub.formData;
		let countEnd = el.value.split("\n").length;
		let dataFormNew = {
			...dataFormOld,
			list_messages: el.value.split("\n"),
			sltang: countEnd
		}

		store.dispatch({
			type: 'CHANGE_DATA_FORM_YTB_LCS_LIKE',
			payload: dataFormNew
		});
	}

	createElementsSelect(start, end, prefix = ''){
	    var elements = [];
	    for(let i = start; i < end; i++){
	        elements.push(<option key={i} value={i+prefix}>{i}{prefix}</option>);
	    }
	    return elements;
	}
	
	onChangeTocDoLike = (e) => {
		var dataFormOld = this.props.buffsub.formData;
		var dataFormNew = {};
		if (e.target.checked) {
			dataFormNew = {
				...dataFormOld,
				[e.target.name]: 1,
			}
		} else {
			dataFormNew = {
				...dataFormOld,
				[e.target.name]: 0,
			}
		}

		store.dispatch({
			type: 'CHANGE_DATA_FORM_YTB_LCS_LIKE',
			payload: dataFormNew
		});
	}

	caculPriceEnd = () => {
		const {locnangcao,locnangcao_gt,locnangcao_dotuoi_start,locnangcao_banbe_start,sltang,giatien} = this.props.buffsub.formData;
		let priceShow = sltang*giatien;
		let priceAdvan = sltang*giatien*20/100;
		if (locnangcao === 1) {
			if (locnangcao_gt !== 0) {
				priceShow = priceShow + priceAdvan;
			}

			if (locnangcao_dotuoi_start !== 0) {
				priceShow = priceShow + priceAdvan;
			}

			if (locnangcao_banbe_start !== 0) {
				priceShow = priceShow + priceAdvan;
			}
		}
		return Math.round((priceShow + Number.EPSILON) * 100) / 100;
	}

	onClickShowDescription = (data) => {
		this.setState({
			showDescription: data
		});
	}
	
	onClickShowDanger = (data) => {
		this.setState({
			showDanger: data
		});
	}
	
 	render() {
 		const { locnangcao,locnangcao_gt,locnangcao_dotuoi_start,locnangcao_dotuoi_end,locnangcao_banbe_start,locnangcao_banbe_end,profile_user,loaiseeding,baohanh,sltang,giatien,ghichu,startDatebh,EndDatebh,type } = this.props.buffsub.formData;
		const { facebookbufflike_mo_dong, facebookbufflike_clone_mo_dong, facebookbufflike_v2_mo_dong, facebookbufflike_v3_mo_dong, facebookbufflike_v4_mo_dong, facebookbufflike_v5_mo_dong, facebookbufflike_v6_mo_dong, facebookbufflike_v7_mo_dong, facebookbufflike_v8_mo_dong, facebookbufflike_v9_mo_dong, facebookbufflike_v10_mo_dong, facebookbufflike_v11_mo_dong } = this.props.buffsub;
		
		var listDataServicesInfo = {};
		try {
			if (localStorage.getItem("listDataServicesInfo") != null) {
				listDataServicesInfo = JSON.parse(localStorage.getItem("listDataServicesInfo"));
			} else {
				listDataServicesInfo = this.props.adminpage.listDataServicesInfo;
			}
		} catch(e) {
			listDataServicesInfo = {};
		}
		var html_haydoctranhmattien = '';
		var html_haydoctranhmattien_chinh = '';
		var html_haydoctranhmattien_sv = '';
		var html_like = '';
		var html_like_v3 = '';
		var html_like_clone = '';
		var html_like_v2 = '';
		var html_like_v4 = '';
		var html_like_v5 = '';
		var html_like_v6 = '';
		var html_like_v7 = '';
		var html_like_v8 = '';
		var html_like_v9 = '';
		var html_like_v10 = '';
		var html_like_v11 = '';
		if (typeof(listDataServicesInfo.facebook_buff_like_tab) !== 'undefined') {
			html_haydoctranhmattien = parse(listDataServicesInfo.facebook_buff_like_tab.ghichu);
			html_haydoctranhmattien_chinh = parse(listDataServicesInfo.facebook_buff_like_tab.ghichu_chinh);
		}
		if (typeof(listDataServicesInfo.facebook_buff_like) !== 'undefined') {
			html_like = parse(listDataServicesInfo.facebook_buff_like.ghichu_sv);
			if (loaiseeding === "like") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.facebook_buff_like.ghichu);
			}
		}
		if (typeof(listDataServicesInfo.facebook_buff_like_v3) !== 'undefined') {
			html_like_v3 = parse(listDataServicesInfo.facebook_buff_like_v3.ghichu_sv);
			if (loaiseeding === "like_v3") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.facebook_buff_like_v3.ghichu);
			}
		}
		if (typeof(listDataServicesInfo.facebook_buff_like_clone) !== 'undefined') {
			html_like_clone = parse(listDataServicesInfo.facebook_buff_like_clone.ghichu_sv);
			if (loaiseeding === "like_clone") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.facebook_buff_like_clone.ghichu);
			}
		}
		if (typeof(listDataServicesInfo.facebook_buff_like_v2) !== 'undefined') {
			html_like_v2 = parse(listDataServicesInfo.facebook_buff_like_v2.ghichu_sv);
			if (loaiseeding === "like_v2") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.facebook_buff_like_v2.ghichu);
			}
		}
		if (typeof(listDataServicesInfo.facebook_buff_like_v4) !== 'undefined') {
			html_like_v4 = parse(listDataServicesInfo.facebook_buff_like_v4.ghichu_sv);
			if (loaiseeding === "like_v4") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.facebook_buff_like_v4.ghichu);
			}
		}
		if (typeof(listDataServicesInfo.facebook_buff_like_v5) !== 'undefined') {
			html_like_v5 = parse(listDataServicesInfo.facebook_buff_like_v5.ghichu_sv);
			if (loaiseeding === "like_v5") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.facebook_buff_like_v5.ghichu);
			}
		}
		if (typeof(listDataServicesInfo.facebook_buff_like_v6) !== 'undefined') {
			html_like_v6 = parse(listDataServicesInfo.facebook_buff_like_v6.ghichu_sv);
			if (loaiseeding === "like_v6") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.facebook_buff_like_v6.ghichu);
			}
		}
		if (typeof(listDataServicesInfo.facebook_buff_like_v7) !== 'undefined') {
			html_like_v7 = parse(listDataServicesInfo.facebook_buff_like_v7.ghichu_sv);
			if (loaiseeding === "like_v7") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.facebook_buff_like_v7.ghichu);
			}
		}
		if (typeof(listDataServicesInfo.facebook_buff_like_v8) !== 'undefined') {
			html_like_v8 = parse(listDataServicesInfo.facebook_buff_like_v8.ghichu_sv);
			if (loaiseeding === "like_v8") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.facebook_buff_like_v8.ghichu);
			}
		}
		if (typeof(listDataServicesInfo.facebook_buff_like_v9) !== 'undefined') {
			html_like_v9 = parse(listDataServicesInfo.facebook_buff_like_v9.ghichu_sv);
			if (loaiseeding === "like_v9") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.facebook_buff_like_v9.ghichu);
			}
		}
		if (typeof(listDataServicesInfo.facebook_buff_like_v10) !== 'undefined') {
			html_like_v10 = parse(listDataServicesInfo.facebook_buff_like_v10.ghichu_sv);
			if (loaiseeding === "like_v10") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.facebook_buff_like_v10.ghichu);
			}
		}
		if (typeof(listDataServicesInfo.facebook_buff_like_v11) !== 'undefined') {
			html_like_v11 = parse(listDataServicesInfo.facebook_buff_like_v11.ghichu_sv);
			if (loaiseeding === "like_v11") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.facebook_buff_like_v11.ghichu);
			}
		}
		
    	return (
    		<Fragment>
    			<style>
					{"\
			        	.react-datepicker-wrapper{\
			          		width: 100%;\
			        	}\
			      	"}
		      	</style>
				<div className="row">
					<div className="col-md-3">
						<label className="mt-2">Link hoặc ID bài viết:</label>
					</div>
					<div className="col-md-9">
						<div className="form-group">
                            <input onChange={(e) => {this.searchUidFacebook(e)}} value={profile_user} name="profile_user" type="text" className="form-control"/>
							{
								loaiseeding === "like" || loaiseeding === "like_v3" || loaiseeding === "like_clone" || loaiseeding === "like_v2" || loaiseeding === "like_v4" || loaiseeding === "like_v5" || loaiseeding === "like_v6" || loaiseeding === "like_v7" || loaiseeding === "like_v8" || loaiseeding === "like_v9" || loaiseeding === "like_v10" || loaiseeding === "like_v11" ?
									<Fragment>
										{/* <h6 style={{"marginTop":"5px"}}>Lưu ý: Riêng đối với buff like cho Avatar và ảnh Bìa hãy ấn thẳng vào ảnh rồi copy link, hoặc có thể xem hướng dẫn: <a href="https://i.imgur.com/QE8JFYT.png" target="_blank" className="font-bold"> Tại đây</a></h6> */}
										<h6 className="text-danger" style={{"marginTop":"15px", "marginRight":"5px", "display": "inline-block"}}>Lưu ý: Nếu buff like cho avata ảnh bìa</h6>
										<button type="button" className="btn badge-danger btn-rounded btn-show-danger-cus" onClick={(e) => {this.onClickShowDanger(!this.state.showDanger)}}>
											{
												this.state.showDanger?
													<i className="fa fa-angle-up" style={{"marginRight": "5px"}} aria-hidden="true"></i>
												:
													<i className="fa fa-angle-down" style={{"marginRight": "5px"}} aria-hidden="true"></i>
											}
											Hãy ấn vào đây
										</button>
										<div className="alert alert-danger" style={{"marginTop": "5px", "display": this.state.showDanger? "block" : "none"}}>
											<p>- Đối với buff like cho Avatar và ảnh Bìa hãy ấn thẳng vào ảnh rồi copy link, hoặc có thể xem hướng dẫn: <a href="https://i.imgur.com/QE8JFYT.png" target="_blank" className="font-bold"> Tại đây</a></p>
											<p>- Đối với buff like avatar: một số bạn không biết gì chưa mở công khai thì mở lên (setting => bài viết công khai => thông tin cá nhân công khai chọn công khai, thấy mấy mục đó cứ chọn công khai hết là được)</p>
											<p style={{"marginBottom": "0px"}}>- Buff like avatar: một số các bạn nhầm tưởng biểu tượng quả cầu là công khai nhưng nó chỉ công khai bạn bè thôi nhé nên vào cài đặt sửa lại là được</p>
										</div>
									</Fragment>
								: ''
							}
                        </div>
					</div>
				</div>
				<div className="row mt-2">
					<div className="col-md-3">
						<label className="mt-2">Loại Seeding cần tăng:</label>
					</div>
					{/*<div className="col-md-8">
						<div className="form-group">
							<select onChange={(e) => {this.onChangeSpeciesSend(e)}} value={loaiseeding} name="loaiseeding" className="form-control">
								<option value="share">Chia sẻ bài viết Facebook</option>
								<option value="like">Like bài viết Facebook</option>
								<option value="like_clone">Like CLONE bài viết Facebook </option>
								<option value="comment">Bình luận bài viết Facebook</option>
								<option value="comment_pro">Bình luận nhanh (buff được cho video)</option>
							</select>
						</div>
					</div>*/}
					<div className="col-sm-9">
						<div className="custom-control custom-radio custom-control-inline" style={{opacity: this.props.buffsub.datapricebuffsub['like'].prices_web == 0 ? '0.3' : '1', "marginRight": "65px"}} >
							<input onChange={(e) => {this.onChangeSpeciesSend(e)}} value="like" type="radio" className="custom-control-input" id="loaiseeding_1" name="loaiseeding" checked={loaiseeding === "like"} disabled={this.props.buffsub.datapricebuffsub['like'].prices_web == 0 ? 'disabled' : ''} />
							<label className="custom-control-label" htmlFor="loaiseeding_1" > Tăng <span style={{"color": "red"}}>Like Bài Viết</span> {html_like} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.buffsub.datapricebuffsub['like'].prices_web} coin</span> {facebookbufflike_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
						</div>
						<br/>
						<div className="custom-control custom-radio custom-control-inline" style={{opacity: this.props.buffsub.datapricebuffsub['like_v3'].prices_web == 0 ? '0.3' : '1' }} >
							<input onChange={(e) => {this.onChangeSpeciesSend(e)}} value="like_v3" type="radio" className="custom-control-input" id="loaiseeding_7" name="loaiseeding" checked={loaiseeding === "like_v3"} disabled={this.props.buffsub.datapricebuffsub['like_v3'].prices_web == 0 ? 'disabled' : ''} />
							<label className="custom-control-label" htmlFor="loaiseeding_7" > Tăng <span style={{"color": "red"}}>Like Bài Viết</span> V3 {html_like_v3} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.buffsub.datapricebuffsub['like_v3'].prices_web} coin</span> {facebookbufflike_v3_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
						</div>
						<br/>
						<div className="custom-control custom-radio custom-control-inline" style={{opacity: this.props.buffsub.datapricebuffsub['like_clone'].prices_web == 0 ? '0.3' : '1' }}>
							<input onChange={(e) => {this.onChangeSpeciesSend(e)}} value="like_clone" type="radio" className="custom-control-input" id="loaiseeding_2" name="loaiseeding" checked={loaiseeding === "like_clone"} disabled={this.props.buffsub.datapricebuffsub['like_clone'].prices_web == 0 ? 'disabled' : ''} />
							<label className="custom-control-label" htmlFor="loaiseeding_2" > Tăng <span style={{"color": "red"}}>Like Bài Viết</span> {html_like_clone} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.buffsub.datapricebuffsub['like_clone'].prices_web} coin</span> {facebookbufflike_clone_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
						</div>
						<br/>
						<div className="custom-control custom-radio custom-control-inline" style={{opacity: this.props.buffsub.datapricebuffsub['like_v2'].prices_web == 0 ? '0.3' : '1', "marginRight": "75px" }} >
							<input onChange={(e) => {this.onChangeSpeciesSend(e)}} value="like_v2" type="radio" className="custom-control-input" id="loaiseeding_6" name="loaiseeding" checked={loaiseeding === "like_v2"} disabled={this.props.buffsub.datapricebuffsub['like_v2'].prices_web == 0 ? 'disabled' : ''} />
							<label className="custom-control-label" htmlFor="loaiseeding_6" > Tăng <span style={{"color": "red"}}>Like Bài Viết</span> V2 {html_like_v2} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.buffsub.datapricebuffsub['like_v2'].prices_web} coin</span> {facebookbufflike_v2_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
						</div>
						<br/>
						<div className="custom-control custom-radio custom-control-inline" style={{opacity: this.props.buffsub.datapricebuffsub['like_v4'].prices_web == 0 ? '0.3' : '1', "marginRight": "75px" }} >
							<input onChange={(e) => {this.onChangeSpeciesSend(e)}} value="like_v4" type="radio" className="custom-control-input" id="loaiseeding_8" name="loaiseeding" checked={loaiseeding === "like_v4"} disabled={this.props.buffsub.datapricebuffsub['like_v4'].prices_web == 0 ? 'disabled' : ''} />
							<label className="custom-control-label" htmlFor="loaiseeding_8" > Tăng <span style={{"color": "red"}}>Like Bài Viết</span> V4 {html_like_v4} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.buffsub.datapricebuffsub['like_v4'].prices_web} coin</span> {facebookbufflike_v4_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
						</div>
						<br/>
						<div className="custom-control custom-radio custom-control-inline" style={{opacity: this.props.buffsub.datapricebuffsub['like_v5'].prices_web == 0 ? '0.3' : '1', "marginRight": "75px" }} >
							<input onChange={(e) => {this.onChangeSpeciesSend(e)}} value="like_v5" type="radio" className="custom-control-input" id="loaiseeding_9" name="loaiseeding" checked={loaiseeding === "like_v5"} disabled={this.props.buffsub.datapricebuffsub['like_v5'].prices_web == 0 ? 'disabled' : ''} />
							<label className="custom-control-label" htmlFor="loaiseeding_9" > Tăng <span style={{"color": "red"}}>Like Bài Viết</span> V5 {html_like_v5} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.buffsub.datapricebuffsub['like_v5'].prices_web} coin</span> {facebookbufflike_v5_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
						</div>
						<br/>
						<div className="custom-control custom-radio custom-control-inline" style={{opacity: this.props.buffsub.datapricebuffsub['like_v6'].prices_web == 0 ? '0.3' : '1', "marginRight": "75px" }} >
							<input onChange={(e) => {this.onChangeSpeciesSend(e)}} value="like_v6" type="radio" className="custom-control-input" id="loaiseeding_10" name="loaiseeding" checked={loaiseeding === "like_v6"} disabled={this.props.buffsub.datapricebuffsub['like_v6'].prices_web == 0 ? 'disabled' : ''} />
							<label className="custom-control-label" htmlFor="loaiseeding_10" > Tăng <span style={{"color": "red"}}>Like Bài Viết</span> V6 {html_like_v6} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.buffsub.datapricebuffsub['like_v6'].prices_web} coin</span> {facebookbufflike_v6_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
						</div>
						<br/>
						<div className="custom-control custom-radio custom-control-inline" style={{opacity: this.props.buffsub.datapricebuffsub['like_v7'].prices_web == 0 ? '0.3' : '1', "marginRight": "75px" }} >
							<input onChange={(e) => {this.onChangeSpeciesSend(e)}} value="like_v7" type="radio" className="custom-control-input" id="loaiseeding_11" name="loaiseeding" checked={loaiseeding === "like_v7"} disabled={this.props.buffsub.datapricebuffsub['like_v7'].prices_web == 0 ? 'disabled' : ''} />
							<label className="custom-control-label" htmlFor="loaiseeding_11" > Tăng <span style={{"color": "red"}}>Like Bài Viết</span> V7 {html_like_v7} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.buffsub.datapricebuffsub['like_v7'].prices_web} coin</span> {facebookbufflike_v7_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
						</div>
						<br/>
						<div className="custom-control custom-radio custom-control-inline" style={{opacity: this.props.buffsub.datapricebuffsub['like_v8'].prices_web == 0 ? '0.3' : '1', "marginRight": "75px" }} >
							<input onChange={(e) => {this.onChangeSpeciesSend(e)}} value="like_v8" type="radio" className="custom-control-input" id="loaiseeding_12" name="loaiseeding" checked={loaiseeding === "like_v8"} disabled={this.props.buffsub.datapricebuffsub['like_v8'].prices_web == 0 ? 'disabled' : ''} />
							<label className="custom-control-label" htmlFor="loaiseeding_12" > Tăng <span style={{"color": "red"}}>Like Bài Viết</span> V8 {html_like_v8} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.buffsub.datapricebuffsub['like_v8'].prices_web} coin</span> {facebookbufflike_v8_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
						</div>
						<br/>
						<div className="custom-control custom-radio custom-control-inline" style={{opacity: this.props.buffsub.datapricebuffsub['like_v9'].prices_web == 0 ? '0.3' : '1', "marginRight": "75px" }} >
							<input onChange={(e) => {this.onChangeSpeciesSend(e)}} value="like_v9" type="radio" className="custom-control-input" id="loaiseeding_13" name="loaiseeding" checked={loaiseeding === "like_v9"} disabled={this.props.buffsub.datapricebuffsub['like_v9'].prices_web == 0 ? 'disabled' : ''} />
							<label className="custom-control-label" htmlFor="loaiseeding_13" > Tăng <span style={{"color": "red"}}>Like Bài Viết</span> V9 {html_like_v9} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.buffsub.datapricebuffsub['like_v9'].prices_web} coin</span> {facebookbufflike_v9_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
						</div>
						<br/>
						<div className="custom-control custom-radio custom-control-inline" style={{opacity: this.props.buffsub.datapricebuffsub['like_v10'].prices_web == 0 ? '0.3' : '1', "marginRight": "75px" }} >
							<input onChange={(e) => {this.onChangeSpeciesSend(e)}} value="like_v10" type="radio" className="custom-control-input" id="loaiseeding_14" name="loaiseeding" checked={loaiseeding === "like_v10"} disabled={this.props.buffsub.datapricebuffsub['like_v10'].prices_web == 0 ? 'disabled' : ''} />
							<label className="custom-control-label" htmlFor="loaiseeding_14" > Tăng <span style={{"color": "red"}}>Like Bài Viết</span> V10 {html_like_v10} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.buffsub.datapricebuffsub['like_v10'].prices_web} coin</span> {facebookbufflike_v10_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
						</div>
						<br/>
						<div className="custom-control custom-radio custom-control-inline" style={{opacity: this.props.buffsub.datapricebuffsub['like_v11'].prices_web == 0 ? '0.3' : '1', "marginRight": "75px" }} >
							<input onChange={(e) => {this.onChangeSpeciesSend(e)}} value="like_v11" type="radio" className="custom-control-input" id="loaiseeding_15" name="loaiseeding" checked={loaiseeding === "like_v11"} disabled={this.props.buffsub.datapricebuffsub['like_v11'].prices_web == 0 ? 'disabled' : ''} />
							<label className="custom-control-label" htmlFor="loaiseeding_15" > Tăng <span style={{"color": "red"}}>Like Bài Viết</span> V11 {html_like_v11} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.buffsub.datapricebuffsub['like_v11'].prices_web} coin</span> {facebookbufflike_v11_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
						</div>
					</div>
				</div>

				<div style={{display:loaiseeding === 'like' || loaiseeding === 'like_clone' || loaiseeding === 'like_v2' || loaiseeding === 'like_cmt' || loaiseeding === 'like_v4' || loaiseeding === 'like_v5' || loaiseeding === 'like_v6' || loaiseeding === 'like_v7' || loaiseeding === 'like_v8' || loaiseeding === 'like_v9' || loaiseeding === 'like_v10' || loaiseeding === 'like_v11' ? 'flex' : 'none'}} className="row mt-2">
					<div className="col-md-3">
						<label className="mt-2">Chọn cảm xúc:</label>
					</div>
					<div className="col-md-9">
						<div className="form-group">
						    <label className="icon_feed_fb">
						    	<input style={{display: 'none'}} value="like" checked={type === 'like' || type === ""} onChange={(e) => {this.onChangeFormCheckbox(e)}} type="checkbox" name="type" />
						    	<img src={iconLikeFb} alt="icon fb" className="img-responsive image-reaction ml-2" />
					    	</label>
					    	<label className="icon_feed_fb" style={{display: (loaiseeding === 'like_v2' || loaiseeding === 'like_v5' || loaiseeding === 'like_v6' || loaiseeding === 'like_v7' || loaiseeding === 'like_v8' || loaiseeding === 'like_v9' || loaiseeding === 'like_v10' || loaiseeding === 'like_v11' || loaiseeding === 'like_clone') ? 'none' : 'inline-block'}}>
						    	<input style={{display: 'none'}} value="care" checked={type === 'care'} onChange={(e) => {this.onChangeFormCheckbox(e)}} type="checkbox" name="type" />
						    	<img style={{width: '44px'}} src={iconCareFb} alt="icon fb" className="img-responsive image-reaction ml-2" />
					    	</label>
						    <label className="icon_feed_fb" style={{display: (loaiseeding === 'like_v2' || loaiseeding === 'like_v5' || loaiseeding === 'like_v7' || loaiseeding === 'like_v8' || loaiseeding === 'like_v9' || loaiseeding === 'like_v10' || loaiseeding === 'like_v11') ? 'none' : 'inline-block'}}>
						    	<input style={{display: 'none'}} value="love" checked={type === 'love'} onChange={(e) => {this.onChangeFormCheckbox(e)}} type="checkbox" name="type" />
						    	<img src={iconLoveFb} alt="icon fb" className="img-responsive image-reaction ml-2" />
					    	</label>
						    <label className="icon_feed_fb" style={{display: (loaiseeding === 'like_v2' || loaiseeding === 'like_v5' || loaiseeding === 'like_v7' || loaiseeding === 'like_v8' || loaiseeding === 'like_v9' || loaiseeding === 'like_v10' || loaiseeding === 'like_v11') ? 'none' : 'inline-block'}}>
						    	<input style={{display: 'none'}} value="haha" checked={type === 'haha'} onChange={(e) => {this.onChangeFormCheckbox(e)}} type="checkbox" name="type" />
						    	<img src={iconHahaFb} alt="icon fb" className="img-responsive image-reaction ml-2" />
					    	</label>
						    <label className="icon_feed_fb" style={{display: (loaiseeding === 'like_v2' || loaiseeding === 'like_v5' || loaiseeding === 'like_v7' || loaiseeding === 'like_v8' || loaiseeding === 'like_v9' || loaiseeding === 'like_v10' || loaiseeding === 'like_v11') ? 'none' : 'inline-block'}}>
						    	<input style={{display: 'none'}} value="wow" checked={type === 'wow'} onChange={(e) => {this.onChangeFormCheckbox(e)}} type="checkbox" name="type" />
						    	<img src={iconWowFb} alt="icon fb" className="img-responsive image-reaction ml-2" />
					    	</label>
						    <label className="icon_feed_fb" style={{display: (loaiseeding === 'like_v2' || loaiseeding === 'like_v5' || loaiseeding === 'like_v7' || loaiseeding === 'like_v8' || loaiseeding === 'like_v9' || loaiseeding === 'like_v10' || loaiseeding === 'like_v11') ? 'none' : 'inline-block'}}>
						    	<input style={{display: 'none'}} value="sad" checked={type === 'sad'} onChange={(e) => {this.onChangeFormCheckbox(e)}} type="checkbox" name="type" />
						    	<img src={iconSadFb} alt="icon fb" className="img-responsive image-reaction ml-2" />
					    	</label>
						    <label className="icon_feed_fb" style={{display: (loaiseeding === 'like_v2' || loaiseeding === 'like_v5' || loaiseeding === 'like_v7' || loaiseeding === 'like_v8' || loaiseeding === 'like_v9' || loaiseeding === 'like_v10' || loaiseeding === 'like_v11') ? 'none' : 'inline-block'}}>
						    	<input style={{display: 'none'}} value="angry" checked={type === 'angry'} onChange={(e) => {this.onChangeFormCheckbox(e)}} type="checkbox" name="type" />
						    	<img src={iconAngryFb} alt="icon fb" className="img-responsive image-reaction ml-2" />
					    	</label>
						</div>
					</div>
				</div>
				
				{
					loaiseeding === 'like' ?
						<div className="row mt-2">
							<div className="col-md-3">
								<label className="mt-2">Tốc độ like:</label>
							</div>
							<div className="col-sm-9">
								<div className="custom-control custom-checkbox custom-control-inline" >
									<input onChange={(e) => {this.onChangeTocDoLike(e)}} value="1" type="checkbox" className="custom-control-input" id="tocdolike" name="tocdolike"  />
									<label className="custom-control-label" htmlFor="tocdolike" > Lên like cực nhanh (<span style={{"color": "red"}}>bạn muốn lên like cực nhanh có thể tích vào đây lên nhanh đồng nghĩa việc có tụt mạnh hơn</span>)</label>
								</div>
							</div>
						</div>
					: ''
				}
				{ loaiseeding === 'like_v4' || loaiseeding === 'like_v5' ? '' :
					<div className="row mt-2">
						<div className="col-md-3">
							<label className="mt-2">Chế độ bảo hành:</label>
						</div>
						<div className="col-md-9">
							<div className="form-group">
								<select onChange={(e) => {this.onChangeBH(e)}} value={baohanh.toString()} name="baohanh" className="form-control">
									<option value="0">Không bảo hành</option>
									<option value="1">Bảo hành (Bảo hành nếu tụt quá 10%)</option>
								</select>
							</div>

							<div style={{display: baohanh === 1 ? 'block':'none'}} className="form-group">
								<div className="row">
									<div className="col-md-6">
										<p className="mb-0">Chọn ngày bắt đầu</p>
										<DatePicker className="form-control w-100"
										showTimeSelect minDate={new Date()}
										selected={startDatebh}
										onChange={this.handleChangeDateStart} />
									</div>
									<div className="col-md-6">
										<p className="mb-0">Chọn ngày kết thúc</p>
										<DatePicker className="form-control"
										showTimeSelect minDate={startDatebh}
										maxDate={this.addDays(startDatebh, 7)}
										selected={EndDatebh}
										onChange={this.handleChangeDateEnd} />
									</div>
								</div>
							</div>
						</div>
					</div>
				}

				{/*  loaiseeding === 'like_clone' || loaiseeding === 'like_v2' || loaiseeding === 'like_v3' || loaiseeding === 'like_cmt' || loaiseeding === 'comment_pro' || loaiseeding === 'like_v4' || loaiseeding === 'like_v5' || loaiseeding === 'like_v6' || loaiseeding === 'like_v7' || loaiseeding === 'like_v8' || loaiseeding === 'like_v9' || loaiseeding === 'like_v10' || loaiseeding === 'like_v11' ? '' :
					<div className="row mt-2">
						<div className="col-md-3">
							<label className="mt-2">Lọc nâng cao:</label>
						</div>
						<div className="col-md-9">
							<div className="form-group">
							    <div className="custom-control custom-radio custom-control-inline">
							        <input onChange={(e) => {this.onChangeForm(e)}} value="1" type="radio" className="custom-control-input" id="locnangcao_1" name="locnangcao" checked={locnangcao === 1} />
							        <label className="custom-control-label" htmlFor="locnangcao_1" >Bật</label>
							    </div>
							    <div className="custom-control custom-radio custom-control-inline">
							        <input onChange={(e) => {this.onChangeForm(e)}} value="0" type="radio" className="custom-control-input" id="locnangcao_0" name="locnangcao" checked={locnangcao === 0}/>
							        <label className="custom-control-label" htmlFor="locnangcao_0" >Tắt</label>
							    </div>
							</div>
							{
								locnangcao === 1 ?
									<div className="form-group">
										<p className="font-15 mt-2">
											Hệ thống sẽ <span style={{color:'#1FAB89'}} className="font-weight-bold">thu phí thêm 20%</span> cho <span style={{color:'#1FAB89'}} className="font-weight-bold">1 lựa chọn nâng cao</span>
										</p>
										<div className="block block-bordered rounded">
											<div className="block-content">
												<div className="row">
													<div className="col-md-2">
														<label>Giới tính:</label>
													</div>
													<div className="col-md-8">
														<div className="form-group">
														    <div className="custom-control custom-radio custom-control-inline">
														        <input onChange={(e) => {this.onChangeForm(e)}} value="2" type="radio" className="custom-control-input" id="locnangcao_gt_2" name="locnangcao_gt" checked={locnangcao_gt === 2}/>
														        <label className="custom-control-label" htmlFor="locnangcao_gt_2" >Nam</label>
														    </div>
															<div className="custom-control custom-radio custom-control-inline">
														        <input onChange={(e) => {this.onChangeForm(e)}} value="1" type="radio" className="custom-control-input" id="locnangcao_gt_1" name="locnangcao_gt" checked={locnangcao_gt === 1}/>
														        <label className="custom-control-label" htmlFor="locnangcao_gt_1" >Nữ</label>
														    </div>
														    <div className="custom-control custom-radio custom-control-inline">
														        <input onChange={(e) => {this.onChangeForm(e)}} value="0" type="radio" className="custom-control-input" id="locnangcao_gt_0" name="locnangcao_gt" checked={locnangcao_gt === 0}/>
														        <label className="custom-control-label" htmlFor="locnangcao_gt_0" >Tất cả</label>
														    </div>
														</div>
													</div>
												</div>
												{ loaiseeding === 'comment_pro' ? '' :
													<Fragment>
														<div className="row">
															<div className="col-md-2">
																<label className="mt-2">Độ tuổi:</label>
															</div>
															<div className="col-md-4">
																<div className="form-group">
																	<select onChange={(e) => {this.onChangeForm(e)}} name="locnangcao_dotuoi_start" value={locnangcao_dotuoi_start} className="form-control">
																		<option value="0">không lọc</option>
																		{this.createElementsSelect(13,66)}
																	</select>
																</div>
															</div>
															<div className="col-md-2 text-center">
																<label className="mt-1">Đến</label>
															</div>
															<div className="col-md-4">
																<div className="form-group">
																	<select disabled={locnangcao_dotuoi_start===0} onChange={(e) => {this.onChangeForm(e)}} name="locnangcao_dotuoi_end" value={locnangcao_dotuoi_end} className="form-control">
																		{this.createElementsSelect(13,66)}
																	</select>
																</div>
															</div>
														</div>

														<div className="row">
															<div className="col-md-2">
																<label className="mt-2">Số bạn bè:</label>
															</div>
															<div className="col-md-4">
																<div className="form-group">
																	<select onChange={(e) => {this.onChangeForm(e)}} name="locnangcao_banbe_start" value={locnangcao_banbe_start.toString()} className="form-control">
																		<option value="0">không lọc</option>
																		{this.createElementsSelect(1,51,'00')}
																	</select>
																</div>
															</div>
															<div className="col-md-2 text-center">
																<label className="mt-1">Đến</label>
															</div>
															<div className="col-md-4">
																<div className="form-group">
																	<select disabled={locnangcao_banbe_start===0} onChange={(e) => {this.onChangeForm(e)}} name="locnangcao_banbe_end" value={locnangcao_banbe_end.toString()} className="form-control">
																		{this.createElementsSelect(1,51,'00')}
																	</select>
																</div>
															</div>
														</div>
													</Fragment>
												}
											</div>
										</div>
									</div>
								: ''
							}
						</div>
					</div>
				 */}

				{ loaiseeding === 'comment' || loaiseeding === 'comment_pro' ?
					'' :
					<div className="row mt-2">
						<div className="col-md-3">
							<label className="mt-2">Số lượng cần tăng:</label>
						</div>
						<div className="col-md-9">
							<div className="form-group">
	                            <input type="number" className="form-control" onChange={(e) => {this.onChangeForm(e)}} name="sltang" value={sltang}/>
	                        </div>
						</div>
					</div>
				}
				{/*  loaiseeding === 'like_clone' || loaiseeding === 'comment_pro' || loaiseeding === 'like_v2' || loaiseeding === 'like_v3' || loaiseeding === 'like_cmt' || loaiseeding === 'like_v4' || loaiseeding === 'like_v5' || loaiseeding === 'like_v6' || loaiseeding === 'like_v7' || loaiseeding === 'like_v8' || loaiseeding === 'like_v9' || loaiseeding === 'like_v10' || loaiseeding === 'like_v11' ?
					'' :
					<div className="row mt-2">
						<div className="col-md-9 offset-md-3">
							<div className="card card-orange">
								<div className="form-group">
									<div className="card-body py-2">
										<h6 className="font-14 mb-0">
											<label className="mb-0 text-danger font-bold mr-1">
												Giá thấp nhất: {loaiseeding === 'like' ? this.props.buffsub.datapricebuffsub.like.prices_web
												 : (loaiseeding === 'comment') ? this.props.buffsub.datapricebuffsub.comment.prices_web 
												 : (loaiseeding === 'share') ? this.props.buffsub.datapricebuffsub.share.prices_web 
												 : '' } Coin
											</label>

											<label className="mb-0 ml-1 green font-bold mr-1">
												- Giá trung bình: {loaiseeding === 'like' ? this.props.auth.priceMedium.like.price_medium
												 : (loaiseeding === 'comment') ? this.props.auth.priceMedium.comment.price_medium 
												 : (loaiseeding === 'share') ? this.props.auth.priceMedium.share.price_medium
												 : '' } Coin
											</label>

											{loaiseeding !== 'share' ? <label className="ml-1 text-primary font-bold mr-1"> - Giá bảo hành: {(loaiseeding === 'like') ? this.props.buffsub.datapricebuffsub.like.min_bh_web : this.props.buffsub.datapricebuffsub.share.min_bh_web} Coin</label> : ''}
										</h6>
										<h6 className="font-14 mb-0 text-danger font-bold">Lưu ý: Nên buff dư thêm 20 - 30% trên tổng số lượng để tránh tụt.</h6>
									</div>
								</div>
							</div>
						</div>
					</div>
				 */}
				{ loaiseeding === 'like_v2' ?
					<div className="row mt-2">
						<div className="col-md-3">
							<label className="mt-2"></label>
						</div>
						<div className="col-md-9 bold">
							<div className="alert alert-danger" style={{ "margin-top": "5px" }}>
								- Lưu ý: hệ thống hoạt động theo hình thức order tức là bạn order số lượng bao nhiêu thì hệ thống sẽ tự động lấy trong cơ sở dữ liệu ra số lượng người tương ứng để like, follow,... cho bạn.<br/>
								- Nên nếu nick của họ bị khóa (checkpoint) trong lúc tăng sẽ gây tụt trong lúc buff làm lượng like lên không đủ số lượng cần tăng là bình thường.<br/>
								- Do đó, vui lòng buff dư thêm 30 - 50% trên tổng số lượng để tránh tụt hoặc chọn gói bảo hành nếu có để được hoàn tiền nếu tụt. Chúng tôi sẽ không bảo hành với bất cứ lý do nào.<br/>
								- Không thể chạy like được cho bài viết dạng share (bài viết này share bài viết khác)
							</div>
						</div>
					</div>
					: ''
				}

				<div className="row mt-2">
					<div className="col-md-3">
						<label className="mt-2">Giá tiền mỗi tương tác:</label>
					</div>
					<div className="col-md-9">
						<div className="form-group">
							<input type="number" className="form-control" onChange={(e) => {this.onChangeForm(e)}} name="giatien" value={giatien}/>
						</div>
					</div>
				</div>

				{ loaiseeding === 'comment' ?
						<div className="row mt-2">
							<div className="col-md-12">
								<div className="card card-orange mb-3">
									<div className="card-body py-3">
										<h6 className="font-14 mb-0 text-danger font-bold mt-2">Nghiêm cấm bình luận các nội dung có biểu tượng Icon.</h6>
										<h6 className="font-14 mb-0 text-danger font-bold mt-2">Nghiêm cấm bình luận những nội có cử chỉ, lời nói thô bạo, khiêu khích, trêu ghẹo, xúc phạm nhân phẩm, danh dự của Cá nhân hoặc Tổ chức.
Nếu cố tình buff bạn sẽ bị trừ hết tiền và ban khỏi hệ thống api vĩnh viễn, và phải chịu hoàn toàn trách nhiệm trước pháp luật.</h6>
										<h6 className="font-14 mb-0 text-danger font-bold mt-2">Các ngôn từ bị cấm: dm|đm|đ m|d m|địt mẹ|dit me|lol|lừađảo|conchó|trảtiền|mấtdạy|lừa đảo|con chó|trả tiền|mất dạy|lua dao|con cho|tra tien|mat day</h6>
									</div>
								</div>

								<span className="font-13 ml-2">Nhập nội dung Comment (<label className="font-bold font-13">Mỗi dòng tương đương với 1 Comment</label>):</span>
								<span className="badge badge-primary text-white py-1">Số lượng: {sltang}</span>

								<div className="form-group mt-2">
									<textarea rows="10" onChange={(e) => this.getSltangComment(e)} placeholder="Nhập nội dung seeding, mỗi dòng 1 nội dung được tính tiền là 1 lần seeding" className="form-control input-gray">
									</textarea>
								</div>
							</div>
						</div>
					: ''
				}
				{ loaiseeding === 'comment_pro' ?
						<div className="row mt-2">
							<div className="col-md-12">
								<div className="card card-orange mb-3">
									<div className="card-body py-3">
										<h3 className="mb-0 text-danger">Chú ý : </h3>
										<h4 className="mb-0" style={{ "color": "#0014ff" }}>Bình luận nhanh là loại buff tốc độ cao có thể số lượng không lên đủ, khi thanh toán vui lòng cân nhắc trước khi sử dụng! Và có thể buff cho video và tối đa là 50 bình luận</h4>
									</div>
								</div>

								<span className="font-13 ml-2">Nhập nội dung Comment (<label className="font-bold font-13">Mỗi dòng tương đương với 1 Comment</label>):</span>
								<span className="badge badge-primary text-white py-1">Số lượng: {sltang}</span>

								<div className="form-group mt-2">
									<textarea rows="10" onChange={(e) => this.getSltangComment(e)} placeholder="Nhập nội dung seeding, mỗi dòng 1 nội dung được tính tiền là 1 lần seeding" className="form-control input-gray">
									</textarea>
								</div>
							</div>
						</div>
					: ''
				}

				<div className="row mt-2">
					<div className="col-md-3">
						<label className="mt-2">Ghi chú:</label>
					</div>
					<div className="col-md-9">
						<div className="form-group">
                            <textarea onChange={(e) => {this.onChangeForm(e)}} name="ghichu" value={ghichu} className="form-control" rows="4" placeholder="Nhập nội dung ghi chú về tiến trình của bạn"></textarea>
                        </div>
					</div>
				</div>

				<button type="button" className="btn badge-primary-cus btn-rounded btn-show-description-cus" onClick={(e) => {this.onClickShowDescription(!this.state.showDescription)}}>
					{
						this.state.showDescription?
							<i className="fa fa-angle-up" style={{"marginRight": "5px"}} aria-hidden="true"></i>
						:
							<i className="fa fa-angle-down" style={{"marginRight": "5px"}} aria-hidden="true"></i>
					}
					Thông tin dịch vụ
				</button>
				
				<ServicesDescription allservicesinfo={listDataServicesInfo} server={loaiseeding} tab="facebook_buff_like" giatien={giatien} baohanh={baohanh} showDescription={this.state.showDescription} />

				<div className="row mt-2" style={{"display": this.state.showDescription? "flex" : "none"}}>
				    <div className="col-md-12 bold" style={{"marginBottom": "10px"}}>
						<div className="card card-select-cus">
							<div className="card-body card-body-select-cus">
								<p className="mb-0 mt-3 text-wrap">
									<ul>
										<li>1 ID KHÔNG CÀI 2 ĐƠN CÙNG LÚC TRONG HỆ THỐNG ! ĐƠN CŨ XONG MỚI CÀI ĐƠN MỚI ! CỐ TÌNH CÀI BỊ HỤT SỐ LƯỢNG SẼ KHÔNG XỬ LÝ !</li>
										<li>Nếu ID đang chạy trên hệ thống mà bạn vẫn mua id đó cá hệ thống bên khác, nếu có tình trạng hụt số lượng giữa 2 bên thì sẽ không được xử lí!</li>
										{html_haydoctranhmattien}
										{html_haydoctranhmattien_chinh}
										{html_haydoctranhmattien_sv}
										<li>Nếu gặp lỗi hãy nhắn tin hỗ trợ phía bên phải góc màn hình hoặc vào mục liên hệ hỗ trợ để được hỗ trợ tốt nhất!</li>
									</ul>
								</p>
								<span className="badge badge-cus position-absolute-cus badge-top-left-cus badge-primary-cus badge-pill-cus"> HÃY ĐỌC ĐỂ TRÁNH MẤT TIỀN KHI SỬ DỤNG! </span>
							</div>
						</div>
				    </div>
				</div>
				<div className="row mt-4">
				    <div className="col-12 text-center bold">
				        <div className="card card-info">
				            <div className="card-body">
				                <h5 className="font-bold">Tổng: <span className="bold green"><span>{this.caculPriceEnd()}</span> Coin</span></h5>
				                <h6 className="mb-0">Bạn sẽ buff <span className="bold green">{sltang} {loaiseeding}</span> với giá <span className="green bold">{this.caculPriceEnd()}</span> Coin</h6>
				            </div>
				        </div>
				    </div>
				</div>

				<div className="row mt-4">
					<div className="col-md-12">
						<div className='form-group float-right w-100'>
							<button onClick={(e) => {this.props.add_task_like_comment_share(this.props.buffsub.formData)}} type="button" className="btn btn-hero-primary btn-rounded btn-block">
	                            Tạo tiến trình
	                        </button>
                        </div>
					</div>
				</div>
			</Fragment>
		);
  	}
}

const mapStateToProps = state => ({
	buffsub: state.likecommentsharelike,
	auth: state.auth
});

export default connect(mapStateToProps, {add_task_like_comment_share,getDataPriceFunction,getConfigMoDong,getIDVideo,getIDPost})(Taotientrinh);
