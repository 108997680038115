import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import {
    Modal,
    ModalBody,
    ModalFooter
} from 'reactstrap';
import { UpdateCookie } from '../../../actions/FacebookbotActions';
import { updateCookieFb } from '../../../actions/FbbotActions';
import iconLikeFb from '../../../iconfb/like.svg';
import iconCareFb from '../../../iconfb/care.svg';
import iconHahaFb from '../../../iconfb/haha.svg';
import iconAngryFb from '../../../iconfb/angry.svg';
import iconLoveFb from '../../../iconfb/love.svg';
import iconSadFb from '../../../iconfb/sad.svg';
import iconWowFb from '../../../iconfb/wow.svg';

class Modal_edit extends Component {
    state = {
        cookiefb: this.props.datacookie,
        lnncx_type: JSON.parse(this.props.datalnncx_type),
    }

    changeValueCookie = (e) => {
        this.setState({
            cookiefb: e.target.value
        });
    }
	
	onChange = (e) => {
		var type = this.state.lnncx_type;
		if (type.indexOf(e.target.value) === -1) {
			type.push(e.target.value);
		} else {
			var position = type.indexOf(e.target.value);
			type.splice(position, 1);
		}
		this.setState({
			lnncx_type: type
		});
	}

    render() {
        const {isOpenModal, dataid, dataidfb} = this.props;
        const {cookiefb, lnncx_type} = this.state;
		const { changetab, loading } = this.props.Facebookbot;
		// console.log(lnncx_type);

        return (
            <Fragment>
                <Modal centered={true} size="lg" toggle={(e) => {this.props.openModal()}} isOpen={isOpenModal} className={(loading? ' block block-mode-loading' : '')} style={{"backgroundColor": "inherit", "boxShadow": "none"}}>
                    <div className="modal-header">
                        <p className="mb-0 text-dark">Sửa cookie</p>
                        <button onClick={(e) => {this.props.openModal()}} type="button" className="close">×</button>
                    </div>
                    <ModalBody style={{opacity: loading? '.05':'1'}}>
                        <div className="row">
                            <div className="col-md-12">
								<div className="form-group">
									<label className="control-label">Cảm xúc:</label>
									<label className="icon_feed_fb">
										<input style={{display: 'none'}} value="like" checked={lnncx_type.indexOf("like") !== -1} onChange={this.onChange} type="checkbox" name="lnncx_type" />
										<img src={iconLikeFb} alt="icon fb" className="img-responsive image-reaction ml-2" />
									</label>
									<label className="icon_feed_fb"  style={{"width": "52px"}}>
										<input style={{display: 'none'}} value="care" checked={lnncx_type.indexOf("care") !== -1} onChange={this.onChange} type="checkbox" name="lnncx_type" />
										<img src={iconCareFb} alt="icon fb" className="img-responsive image-reaction ml-2" />
									</label>
									<label className="icon_feed_fb">
										<input style={{display: 'none'}} value="love" checked={lnncx_type.indexOf("love") !== -1} onChange={this.onChange} type="checkbox" name="lnncx_type" />
										<img src={iconLoveFb} alt="icon fb" className="img-responsive image-reaction ml-2" />
									</label>
									<label className="icon_feed_fb">
										<input style={{display: 'none'}} value="haha" checked={lnncx_type.indexOf("haha") !== -1} onChange={this.onChange} type="checkbox" name="lnncx_type" />
										<img src={iconHahaFb} alt="icon fb" className="img-responsive image-reaction ml-2" />
									</label>
									<label className="icon_feed_fb">
										<input style={{display: 'none'}} value="wow" checked={lnncx_type.indexOf("wow") !== -1} onChange={this.onChange} type="checkbox" name="lnncx_type" />
										<img src={iconWowFb} alt="icon fb" className="img-responsive image-reaction ml-2" />
									</label>
									<label className="icon_feed_fb">
										<input style={{display: 'none'}} value="sad" checked={lnncx_type.indexOf("sad") !== -1} onChange={this.onChange} type="checkbox" name="lnncx_type" />
										<img src={iconSadFb} alt="icon fb" className="img-responsive image-reaction ml-2" />
									</label>
									<label className="icon_feed_fb">
										<input style={{display: 'none'}} value="angry" checked={lnncx_type.indexOf("angry") !== -1} onChange={this.onChange} type="checkbox" name="lnncx_type" />
										<img src={iconAngryFb} alt="icon fb" className="img-responsive image-reaction ml-2" />
									</label>
								</div>
							</div>
                            <div className="col-md-12">
                                <div className="form-group focused">
                                    <label className="control-label">Cookie</label>
                                    <input className="form-control" placeholder="Nhập cookie" value={cookiefb} onChange={(e)=>{this.changeValueCookie(e)}} />
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter style={{opacity: loading? '.05':'1'}}>
                        <button onClick={() => {this.props.updateCookieFb('facebookbot', cookiefb, lnncx_type, dataidfb, dataid, '', '', this.props.openModal)}} type="button" className="btn btn-primary">Lưu</button>
                        <button onClick={(e) => {this.props.openModal()}} type="button" className="btn btn-danger">Huỷ</button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    Facebookbot: state.Facebookbot
});

export default connect(mapStateToProps, {UpdateCookie, updateCookieFb}) (Modal_edit);
