import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import {
    Modal,
    ModalBody,
    ModalFooter
} from 'reactstrap';
import { UpdateItemBot } from '../../../actions/vipbotliveAction';
import { editFbVip } from '../../../actions/FbvipActions';
import {
  BrowserRouter as Router,
  Link
} from "react-router-dom";
import ModalSticker from './modal_sticker_edit';

class Modal_edit extends Component {
	componentDidMount() {
		var datasticker = [];
		if (this.state.sticker.length > 0) {
			datasticker = this.state.sticker.split(',');
		}
        this.setState({
            ndr: Buffer.from(this.props.datandr, 'base64').toString('utf8'),
			sticker: datasticker,
        });
	}
    state = {
        id: this.props.dataid,
        ndr: this.props.datandr,
        tocdocmt: this.props.datatocdocmt,
        sticker: this.props.datasticker,
        commentanh: this.props.datacommentanh,
        sticker_pack: JSON.parse(this.props.datasticker_pack),
        lsct: this.props.datalsct,
        status: this.props.datastatus,
    }

    changeValueNdr = (e) => {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value
        });
		// console.log(typeof JSON.parse(e.target.value));
    }
	
    openModalSticker = (data = null) => {
		this.setState({
			...this.state,
			isOpenModalSticker: !this.state.isOpenModalSticker,
		});
		if (data !== null) {
			this.setState({
				...this.state,
				isOpenModalSticker: !this.state.isOpenModalSticker,
				id_sticker_package: data,
			});
		}
    }
	
	updateParentSticker = (sticker) => {
		this.setState({
			sticker: sticker
		});
	}
	
	updateParentStickerPack = (sticker_pack) => {
		this.setState({
			sticker_pack: sticker_pack
		});
	}
	
    onChange = (e) => {
        this.setState({
            ...this.state,
            tocdocmt: e.target.value
        });
    }

    render() {
        const {isOpenModal} = this.props;
        const {loading} = this.props.Facebookvipcomment;
        var {ndr, id, tocdocmt, sticker, commentanh, sticker_pack, lsct, status} = this.state;
		
		var phut = [5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20];
		var selectPhut =(
			<Fragment>
				{
					phut.map(function(value, index){
						return(<option value={value} key={index}>{value} phút</option>)
					})
				}
			</Fragment>
		)
		
		var listStickerPackage = {};
		try {
			if (localStorage.getItem("listStickerPackage") != null) {
				listStickerPackage = JSON.parse(localStorage.getItem("listStickerPackage"));
			} else {
				listStickerPackage = this.props.adminpage.listStickerPackage;
			}
		} catch(e) {
			listStickerPackage = [];
		}
		var sticker_html = '';
		var that = this;
		if (listStickerPackage.length > 0) {
			listStickerPackage.pop();
			sticker_html = listStickerPackage.map(function(v, i) {
				return <Link to='/tool/facebookvipcomment' key={i} onClick={() => {that.openModalSticker(v.id_sticker_package)}} className="btn text-center p-2 mr-1 col-lg-2" style={{"border": (typeof sticker_pack[v.id_sticker_package] !== 'undefined') ? "3px solid green" : '0px solid green'}}><img src={process.env.PUBLIC_URL + '/images/stickerfb/package_sticker/'+v.id_sticker_package+'.png'} width="50" height="50" /><br/>{v.name}</Link>
			})
		}
		
        return (
            <Fragment>
                <Modal centered={true} size="lg" toggle={(e) => {this.props.openModal()}} isOpen={isOpenModal} className={(loading? ' block block-mode-loading' : '')} style={{"backgroundColor": "inherit", "boxShadow": "none"}}>
                    <div className="modal-header">
                        <p className="mb-0 text-dark">Sửa nội dung</p>
                        <button onClick={(e) => {this.props.openModal()}} type="button" className="close">×</button>
                    </div>
                    <ModalBody style={{opacity: loading? '.05':'1'}}>
						{
							this.props.auth.user.type === 1 ?
								<div className="row">
									<div className="col-md-12">
										<div className="form-group focused">
											<label>Trạng thái</label>
											<select name="status" onChange={this.changeValueNdr} value={status} className="form-control custom-select select-light">
												<option value="0">Hoàn thành</option>
												<option value="1">Đang chạy</option>
												<option value="2">Id lỗi</option>
												<option value="3">Hủy đơn</option>
												<option value="5">Hoàn tiền</option>
											</select>
										</div>
									</div>
								</div>
							: ''
						}
						{
							lsct == 0 ?
								<Fragment>
									<div className="row">
										<div className="col-md-12">
											<div className="form-group focused">
												<label className="control-label">Tốc độ cmt:</label>
												<select className="form-control custom-select select-light" id="tocdocmt" name="tocdocmt" onChange={this.onChange} value={tocdocmt} >
													{selectPhut}
												</select>
											</div>
										</div>
									</div>
								</Fragment>
							: ''
						}
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group focused">
                                    <label className="control-label">Nội dung cmt:</label>
                                    <textarea rows="2" type="text" className="form-control" id="ndr" name="ndr" placeholder="Mỗi nội dung comment 1 dòng, nội dung khác nhau" onChange={this.changeValueNdr} value={ndr}></textarea>
                                </div>
                            </div>
                        </div>
						{
							lsct == 1 ?
								<Fragment>
									<div className="row">
										<div className="col-md-12">
											<div className="form-group focused">
												<label className="control-label">Sticker tùy chỉnh:</label>
												<div className="col-md-12" style={{"height": "200px", "overflow": "auto", "border": "1px solid #cecece"}}>
													{sticker_html}
												</div>
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-md-12">
											<div className="form-group focused">
												<label className="control-label">Danh sách link ảnh (link1|link2|link3..):</label>
												<input type="text" className="form-control" id="commentanh" name="commentanh" placeholder="Nhập danh sách link ảnh?" onChange={this.changeValueNdr} value={commentanh} />
											</div>
										</div>
									</div>
								</Fragment>
							: ''
						}
                    </ModalBody>
                    <ModalFooter style={{opacity: loading? '.05':'1'}}>
                        <button onClick={() => {this.props.editFbVip('facebookvipcomment', id, '', '', ndr, tocdocmt, this.props.openModal, 0, 0, "", status, this.state)}} type="button" className="btn btn-primary">Lưu</button>
                        <button onClick={(e) => {this.props.openModal()}} type="button" className="btn btn-danger">Huỷ</button>
                    </ModalFooter>
                </Modal>
				{this.state.isOpenModalSticker ? <ModalSticker openModal={this.openModalSticker} isOpenModal={this.state.isOpenModalSticker} updateParentSticker={this.updateParentSticker} updateParentStickerPack={this.updateParentStickerPack} dataid={this.state.id_sticker_package} datasticker={this.state.sticker} datasticker_pack={this.state.sticker_pack} /> : ''}
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
	auth: state.auth,
    Facebookvipcomment: state.Facebookvipcomment
});

export default connect(mapStateToProps, {UpdateItemBot, editFbVip}) (Modal_edit);
