import {
	LOAD_BSCIO,
	UPDATE_BSCIO,
	UPDATE_BSCIO_TAB,
	LOAD_PRICE_BSCIO,
	LIMIT_PAGI_BSCIO,
	TYPE_BSCIO,
	LOADING_BSCIO
} from "../actions/types";

const date7dnext = () => {
	var result = new Date();
  	result.setDate(result.getDate() + 7);
  	return result;
}

const initialState = {
	"taotientrinh": {
		"lhibv": "",
		"lsct": "1",
		"cdbh": "false",
		"cnbd": new Date(),
		"cnkt": date7dnext(),
		"ndr": "",
		"gtmtt": "0",
		"gc": "",
	},
	"changetab": 0,
	"listtientrinh": [],
	// "tanggiathem": {
		// "id": "",
		// "lhibv": "",
		// "price_per": 0,
		// "price": 0
	// },
	"price": {
		buffseedingcommentinstagramorder: {
			min_bh_web: 0,
			prices_web: 0,
		},
		buffseedingcommentinstagramorder_sv2: {
			min_bh_web: 0,
			prices_web: 0,
		},
	},
	"limit_pagi": 0,
	"type": 0,
	"loading": false,
	instagrambuffcmt_sv1_mo_dong: 1,
	instagrambuffcmt_sv2_mo_dong: 1,
};

export default function(state = initialState, action) {
	switch (action.type) {
		case LOAD_BSCIO:
			return {
				...state,
				"listtientrinh": action.payload
			};
		case UPDATE_BSCIO:
			return {
				...state,
				"taotientrinh": action.payload
			}
		case UPDATE_BSCIO_TAB:
			return {
				...state,
				"changetab": action.payload
			};
		case LOAD_PRICE_BSCIO:
			return {
				...state,
				"price": merDataPricePage(action.payload),
			};
		case LIMIT_PAGI_BSCIO:
			return {
				...state,
				"limit_pagi": action.payload
			};
		case TYPE_BSCIO:
			return {
				...state,
				"type": action.payload
			};
		case LOADING_BSCIO:
			return {
				...state,
				"loading": action.payload
			};
		case 'CONFIG_MO_DONG_BSCIO':
			return {
				...state,
				instagrambuffcmt_sv1_mo_dong: action.payload.instagrambuffcmt_sv1_mo_dong,
				instagrambuffcmt_sv2_mo_dong: action.payload.instagrambuffcmt_sv2_mo_dong,
			};
		default:
		  return state;
	}
}

function merDataPricePage(data) {
	let dataNew = {
		...initialState.price
	}
	if (data.length > 0) {
		data.forEach(function(v, i){
			if (v.name_table === 'buffseedingcommentinstagramorder') {
				dataNew['buffseedingcommentinstagramorder'] = v;
				initialState.taotientrinh.gtmtt = v.prices_web;
			} else if (v.name_table === 'buffseedingcommentinstagramorder_sv2') {
				dataNew['buffseedingcommentinstagramorder_sv2'] = v;
			}
		});
	};
	return dataNew;
}
