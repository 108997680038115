import {
	LOAD_SPAMSMS,
	UPDATE_SPAMSMS_TAB,
	UPDATE_SPAMSMS,
	LOAD_PRICE_SPAMSMS,
	LOADING_SPAMSMS,
	LIMIT_PAGI_SPAMSMS,
	TYPE_SPAMSMS,
} from "../actions/types";

const date7dnext = () => {
	var result = new Date();
  	result.setDate(result.getDate() + 7);
  	return result;
}

const initialState = {
	"taotientrinh": {
		"sdtn": "",
		"type": "",
		"hgg": "0",
		"startDatebh": new Date(),
		"ndr": "",
		"sl": "10",
		"mlg": "1",
		"tgn": "3",
		"gtmtt": "0",
	},
	"changetab": 0,
	"listtientrinh": [],
	"price": [],
	"loading": false,
	"limit_pagi": 0,
	"type": 0,
};

export default function(state = initialState, action) {
	switch (action.type) {
		case LOAD_SPAMSMS:
			return {
				...state,
				"listtientrinh": action.payload
			};
		case UPDATE_SPAMSMS_TAB:
			return {
				...state,
				"changetab": action.payload
			};
		case UPDATE_SPAMSMS:
			return {
				...state,
				"taotientrinh": action.payload
			};
		case LOAD_PRICE_SPAMSMS:
			return {
				...state,
				"price": action.payload,
				"taotientrinh": {
					...state.taotientrinh,
					"gtmtt": action.payload.prices_web
				}
			};
		case LOADING_SPAMSMS:
			return {
				...state,
				"loading": action.payload
			};
		case LIMIT_PAGI_SPAMSMS:
			return {
				...state,
				"limit_pagi": action.payload
			};
		case TYPE_SPAMSMS:
			return {
				...state,
				"type": action.payload
			};
		default:
		  return state;
	}
}
