import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Swal from 'sweetalert2';
import copy from 'copy-text-to-clipboard';

class Locchuoi extends Component {
	state = {
		loaibotrunglap: true,
		noidung: "good1 good1 good1 good1 good1\ngood2 good2 good2 good2 good2\ngood3 good3 good3 good3 good3\nbad bad bad bad bad bad",
		text_placeholder: "good1 good1 good1 good1 good1\ngood2 good2 good2 good2 good2\ngood3 good3 good3 good3 good3\nbad bad bad bad bad bad",
		dongchua: "good\ngood1",
		text_placeholder_dongchua: "good\ngood1",
		dongkhongchua: "bad",
		text_placeholder_dongkhongchua: "bad",
		ketqua: "",
	}
	onChangeTrungLap = (e) =>{
		this.setState({
			[e.target.name]: !this.state.loaibotrunglap
		})
	}
	onClick = (e) =>{
		this.setState({
			[e.target.name]: e.target.value
		})
	}
	onChange = (e) =>{
		this.setState({
			[e.target.name]: e.target.value
		})
	}
	onTrigger = (e) => {
		var { loaibotrunglap, noidung, dongchua, dongkhongchua } = this.state;
		noidung = noidung.trim();
		noidung = noidung
			.split("\n")
			.filter(item => item)
			.join("\n");
		dongchua = dongchua
			.split("\n")
			.filter(item => item)
			.join("\n");
		dongkhongchua = dongkhongchua
			.split("\n")
			.filter(item => item)
			.join("\n");
		if (loaibotrunglap) {
			noidung = noidung
				.split("\n")
				.filter((item, i, allItems) => {
					return i === allItems.indexOf(item);
				})
				.join("\n");
		}
		var final_noidung_tmp = noidung.split("\n");
		var final_noidung = [];
		if (dongchua == '') {
			final_noidung = final_noidung_tmp;
		}
		var dongchua = dongchua.split("\n");
		var dongkhongchua = dongkhongchua.split("\n");
		final_noidung_tmp.forEach(function(v, i) {
			dongchua.forEach(function(v2, i2) {
				if (v.indexOf(v2) > -1 && v2 != '') {
					final_noidung[i] = v;
				}
			});
		});
		final_noidung.forEach(function(v, i) {
			dongkhongchua.forEach(function(v2, i2) {
				if (v.indexOf(v2) > -1 && v2 != '') {
					delete final_noidung[i];
				}
			});
		});
		noidung = final_noidung.filter(item => item).join("\n");
		
		
		this.setState({
			ketqua: noidung
		});
	}
	
	render() {
		return (
			<Fragment>
				<div className="form-group row">
					<label className="col-sm-2 col-form-label edittext-label-right" htmlFor="">Loại bỏ trùng lặp?</label>
					<div className="col-sm-10" style={{"display": "flex", "alignItems": "center"}}>
						<div className="custom-control custom-switch mb-1">
							<input onChange={(e) => {this.onChangeTrungLap(e)}} type="checkbox" className="custom-control-input" id="loaibotrunglap" name="loaibotrunglap" checked={this.state.loaibotrunglap} />
							<label className="custom-control-label edittext-custom-control-label" htmlFor="loaibotrunglap" ></label>
						</div>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-2 col-form-label edittext-label-right" htmlFor="">Nội dung</label>
					<div className="col-sm-10">
						<textarea rows="5" placeholder={this.state.text_placeholder} className="form-control input-gray edittext-textarea" name="noidung" onChange={this.onChange} value={this.state.noidung} ></textarea>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-2 col-form-label edittext-label-right" htmlFor="">Lấy dòng chứa một trong các từ (Mỗi từ trên 1 dòng)</label>
					<div className="col-sm-10">
						<textarea rows="5" placeholder={this.state.text_placeholder_dongchua} className="form-control input-gray edittext-textarea" name="dongchua" onChange={this.onChange} value={this.state.dongchua} ></textarea>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-2 col-form-label edittext-label-right" htmlFor="">Lấy dòng không chứa tất cả các từ (Mỗi từ trên một dòng)</label>
					<div className="col-sm-10">
						<textarea rows="5" placeholder={this.state.text_placeholder_dongkhongchua} className="form-control input-gray edittext-textarea" name="dongkhongchua" onChange={this.onChange} value={this.state.dongkhongchua} ></textarea>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-2 col-form-label edittext-label-right" htmlFor=""></label>
					<div className="col-sm-10">
						<button type="button" className="btn btn-rounded btn-hero-primary edittext-btn" onClick={this.onTrigger} >Trigger</button>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-2 col-form-label edittext-label-right" htmlFor=""></label>
					<div className="col-sm-10">
						<textarea rows="5" placeholder="" className="form-control input-gray edittext-textarea" name="ketqua" defaultValue={this.state.ketqua} ></textarea>
					</div>
				</div>
			</Fragment>
		);
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
});
const mapDispatchToProps = {  };

export default connect(mapStateToProps, mapDispatchToProps)(Locchuoi);
