import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import store from '../../../store';

import Swal from 'sweetalert2';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { updateRipacc, addRipacc, searchIdFb } from '../../../actions/RipaccActions';
import { LOADING_RIPACC } from "../../../actions/types";
import parse from 'html-react-parser';

const date7dnext = () => {
	var result = new Date();
  	result.setDate(result.getDate() + 7);
  	return result;
}

class taotientrinh extends Component {
	static propTypes =  {
		updateRipacc: PropTypes.func.isRequired,
		addRipacc: PropTypes.func.isRequired,
	};
	
	state = {
		
	}
	
	componentDidMount() {
		// this.props.loadPriceFLBBKTT();
	};
	
	searchUidFacebook = (e) => {
		let valueS = e.target.value;
		let indexofLinkFb = valueS.lastIndexOf('facebook.com');
		if (indexofLinkFb > -1) {
			this.props.searchIdFb(valueS, e.target.name);
		} else {
			const { taotientrinh, price } = this.props.Ripacc;
			var new_taotientrinh = {
				...taotientrinh,
				[e.target.name]: e.target.value
			};
			this.props.updateRipacc(new_taotientrinh);
		}
	}
	
	onChange = (e) => {
		const { taotientrinh, price } = this.props.Ripacc;
		var new_taotientrinh = {
			...taotientrinh,
			[e.target.name]: e.target.value
		};
		this.props.updateRipacc(new_taotientrinh);
	}
	
	taoTienTrinhfunc = () => {
		const { taotientrinh } = this.props.Ripacc;
		var new_taotientrinh = {
			...taotientrinh,
		};
		if (taotientrinh.loairip.toString() === "1") {			
			Swal.fire({
				icon: 'error',
				title: 'Lỗi',
				text: 'Inbox admin để biết chi tiết về giá rip Fanpage!',
			});
			return false;
		}
		Swal.fire({
			title: 'Bạn có chắc?',
			html: "Bạn có chắc chắn muốn mua Giá "+taotientrinh.gtmtt+" Coin / 1 lần?",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Đồng ý',
			cancelButtonText: 'Không'
		}).then((result) => {
			if (result.value) {
				this.props.addRipacc(new_taotientrinh);
			}
		})
	}
	
	option_namtao = () => {
		var date = new Date();
		let ar_option_namtao = [];

		for (let i = 2004; i <= date.getFullYear(); i+=1) {
			ar_option_namtao.unshift(
				<option value={i} key={i}>
					{i}
				</option>
			)
		}
		return ar_option_namtao;
	}
	
	onKeyPress = (e) => {
		if (!(e.charCode >= 48 && e.charCode <= 57)) {
			e.preventDefault();
		}
	}
	
	render() {
 		const { profile_user, loairip, quatay, namtao, sdt, fb, ghichu, gtmtt } = this.props.Ripacc.taotientrinh;
		var price = gtmtt;
		return (
			<Fragment>
				<div className="row">
					<div className="col-md-4">
						<label className="mt-2">Link hoặc ID trang cá nhân:</label>
					</div>
					<div className="col-md-8">
						<div className="form-group">
                            <input onChange={(e) => {this.searchUidFacebook(e)}} value={profile_user} name="profile_user" type="text" className="form-control"/>
                        </div>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Loại rip:</label>
					<div className="col-sm-8">
						<div className="custom-control custom-radio custom-control-inline" style={{opacity: this.props.Ripacc.price.prices_web == 0 ? '0.3' : '1' }}>
							<input onChange={this.onChange} value="0" type="radio" className="custom-control-input" id="loairip_0" name="loairip" checked={loairip.toString() === "0"} disabled={this.props.Ripacc.price.prices_web == 0 ? 'disabled' : ''} />
							<label className="custom-control-label" htmlFor="loairip_0" > Facebook cá nhân</label>
						</div>
						<div className="custom-control custom-radio custom-control-inline">
							<input onChange={this.onChange} value="1" type="radio" className="custom-control-input" id="loairip_1" name="loairip" checked={loairip.toString() === "1"}/>
							<label className="custom-control-label" htmlFor="loairip_1" > Fanpage (inbox admin để biết chi tiết về giá)</label>
						</div>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Qua tay hay chưa:</label>
					<div className="col-sm-8">
						<div className="custom-control custom-radio custom-control-inline">
							<input onChange={this.onChange} value="0" type="radio" className="custom-control-input" id="loai_0" name="quatay" checked={quatay.toString() === "0"} />
							<label className="custom-control-label" htmlFor="loai_0" > Chưa</label>
						</div>
						<div className="custom-control custom-radio custom-control-inline">
							<input onChange={this.onChange} value="1" type="radio" className="custom-control-input" id="loai_1" name="quatay" checked={quatay.toString() === "1"}/>
							<label className="custom-control-label" htmlFor="loai_1" > Rồi</label>
						</div>
						<div className="custom-control custom-radio custom-control-inline">
							<input onChange={this.onChange} value="2" type="radio" className="custom-control-input" id="loai_2" name="quatay" checked={quatay.toString() === "2"}/>
							<label className="custom-control-label" htmlFor="loai_2" > Chưa xác định</label>
						</div>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Năm tạo nick:</label>
					<div className="col-sm-8">
						<select className="form-control custom-select select-light" id="namtao" name="namtao" onChange={this.onChange} value={namtao} >
							<option value="">Không biết</option>
							{this.option_namtao()}
						</select>
					</div>
				</div>
				<div className="row mt-2">
					<div className="col-md-4">
						<label className="mt-2">Số điện thoại liên hệ (có vấn đề bên admin sẽ thông báo):</label>
					</div>
					<div className="col-md-8">
						<div className="form-group">
							<input type="number" className="form-control" id="sdt" name="sdt" placeholder="Nhập số điện thoại" onChange={this.onChange} value={sdt} onKeyPress={this.onKeyPress} />
                        </div>
					</div>
				</div>
				<div className="row mt-2">
					<div className="col-md-4">
						<label className="mt-2">Link Facebook liên hệ (có vấn đề bên admin sẽ thông báo):</label>
					</div>
					<div className="col-md-8">
						<div className="form-group">
							<input type="text" className="form-control" id="fb" name="fb" placeholder="Nhập link facebook" onChange={(e) => {this.searchUidFacebook(e)}} value={fb}  />
                        </div>
					</div>
				</div>

				<div className="row mt-2">
					<div className="col-md-4">
						<label className="mt-2">Giá tiền rip acc:</label>
					</div>
					<div className="col-md-8">
						<div className="form-group">
							<input type="number" className="form-control" onChange={this.onChange} name="gtmtt" value={price}/>
						</div>
					</div>
				</div>
				
				<div className="row mt-2">
					<div className="col-md-4">
						<label className="mt-2"></label>
					</div>
				    <div className="col-md-8 bold">
				        <div className="card card-orange">
				        	<div className="card-body py-2">
								<h3 className="mb-0 text-danger">HÃY ĐỌC ĐỂ TRÁNH MẤT TIỀN KHI SỬ DỤNG!</h3>
								<ul>
									<li>Thời gian xử lí RIP từ 12h - 7 ngày nếu không RIP được chúng tôi sẽ hoàn tiền về tài khoản luôn!</li>
								</ul>
			        		</div>
		        		</div>
				    </div>
				</div>
				<div className="row mt-2">
					<div className="col-md-4">
						<label className="mt-2">Ghi chú:</label>
					</div>
					<div className="col-md-8">
						<div className="form-group">
                            <textarea onChange={this.onChange} name="ghichu" value={ghichu} className="form-control" rows="4" placeholder="Nhập nội dung ghi chú về tiến trình của bạn"></textarea>
                        </div>
					</div>
				</div>
				<div className="row mt-4">
					<div className="col-12 text-center bold">
						<div className="card card-info">
							<div className="card-body">
								<h5 className="font-bold">Tổng: <span className="bold green"><span>{price}</span> Coin</span></h5>
								<h6 className="mb-0 bold"><span>Giá <span className="green">{gtmtt} Coin</span> / 1 lần</span></h6>
							</div>
						</div>
					</div>
				</div>
				<div className="row my-4">
					<div className="col-12 text-center bold">
						<button type="button" className="btn btn-block btn-rounded btn-hero-primary" onClick={this.taoTienTrinhfunc} >Tạo tiến trình</button>
					</div>
				</div>
			</Fragment>
		)
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	Ripacc: state.Ripacc,
});
const mapDispatchToProps = { updateRipacc, addRipacc, searchIdFb }

export default connect(mapStateToProps, mapDispatchToProps)(taotientrinh);