import React, { Component, Fragment } from 'react';
import {
  BrowserRouter as Router,
  // Switch,
  // Route,
  Link
} from "react-router-dom";
import {
	// Button,
	// Modal,
	// ModalHeader,
	// ModalBody,
	// Form,
	// FormGroup,
	// Label,
	// Input,
	// NavLink,
	Alert
} from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { register } from '../../actions/authActions';
import { clearErrors } from '../../actions/errorActions';
import Layer2 from '../../iconlanding/5/Layer-2.png';
import parse from 'html-react-parser';

class RegisterModal extends Component {
	state = {
		// modal: false,
		username: '',
		email: '',
		password: '',
		repeatpassword: '',
		facebook_id: '4',
		phone: '',
		msg: null,
		menuopen: false,
	}
	
	static propTypes =  {
		auth: PropTypes.object.isRequired,
		error: PropTypes.object.isRequired,
		register: PropTypes.func.isRequired,
		clearErrors: PropTypes.func.isRequired
	}
	
	componentDidUpdate(prevProps) {
		const { error } = this.props;
		if (error !== prevProps.error) {
			// Check for register error
			if (error.id === 'REGISTER_FAIL') {
				this.setState({ msg: error.msg.msg });
			} else {
				this.setState({ msg: null });
			}
		}
	}
	
	clearAlert = () => {
		// Clear errors
		this.props.clearErrors();
	}
	
	onChange = e => {
		this.setState({ [e.target.name]: e.target.value });
	};
	
	onSubmit = e => {
		e.preventDefault();
		
		const { username, email, password, repeatpassword, facebook_id, phone } = this.state;
		
		// Create user object
		const newUser = {
			username,
			email,
			password,
			repeatpassword,
			facebook_id,
			phone
		};
		// console.log(newUser);
		// Attempt to register
		this.props.register(newUser);
	};
	openMenu = (data = null) => {
		if (data !== null) {
			this.setState({
				...this.state,
				menuopen: data,
			});
		}
	}
	
	render() {
		let loading = this.props.auth.loading;
		const domainSite = window.location.hostname;
		var config_website = {};
		if (localStorage.getItem("confignavbar") != null) {
			config_website = JSON.parse(localStorage.getItem("confignavbar"));
		} else {
			config_website = this.props.auth.config_website;
		}
		return (
			<Router>
				<Fragment>
					<div className="landingcus5" style={{"zoom": "122%", "backgroundColor": "#fff"}}>
						<nav className="navbar navbar-default navbar-static-top">
							<div className="container">
								<div className="navbar-header">
									<a className="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar" aria-expanded="false" aria-controls="navbar" onClick={(e) => {e.preventDefault();this.openMenu(!this.state.menuopen)}}>
										<span className="sr-only"></span>
										<span className="icon-bar"></span>
										<span className="icon-bar"></span>
										<span className="icon-bar"></span>
									</a>
									<a className="link-fx font-w700 fontSize-h3 site-logo site-title" href="/" style={{"padding": "0", "top": "12px", "color": "#fa329c"}}>
									  <span className="text-uppercase">{domainSite.slice(0, domainSite.lastIndexOf('.'))}</span>
									  <span className="text-uppercase">{domainSite.indexOf('.') > -1 ? domainSite.slice(domainSite.lastIndexOf('.')) : ''}</span>
									</a>
								</div>
								<div id="navbar" className={"navbar-collapse collapse "+(this.state.menuopen ? ' in' : '')}>
									<ul className="nav navbar-nav navbar-right">
										<li className="nav-item menu_item_14 " id="menu_item_14">
											<Link to="/services" onClick={this.props.action_service} className="nav-link">
												Dịch vụ
											</Link>
										</li>
										<li className="nav-item active menu_item_14 " id="menu_item_14">
											<Link to="#" className="nav-link">
												Đăng ký
											</Link>
										</li>
										<li className="nav-item menu_item_13 " id="menu_item_13">
											<Link to="/" className="nav-link" onClick={this.props.action_login}>
												Đăng nhập
											</Link>
										</li>
									</ul>
								</div>
							</div>
						</nav>
						<div className="top-content-area">	
							<div className="container">
								<div className="row">
									<div className="col-sm-6 ">
										<div className="frm">
											<h2 className="login-area-head">Sign In</h2>
											<h2>Tạo tài khoản của bạn</h2>
											<p>Sử dụng thông tin đăng nhập của bạn để truy cập tài khoản của bạn.</p>
											{loading ? (
												<div className="preloader">
													<div className="spinner-grow spinner-grow-sm text-primary" role="status" style={{ "marginTop": "20%" }}>
													  <span className="sr-only"></span>
													</div>
													<div className="spinner-grow spinner-grow-sm text-danger" role="status" style={{ "marginTop": "20%" }}>
													  <span className="sr-only">.</span>
													</div>
													<div className="spinner-grow spinner-grow-sm text-warning" role="status" style={{ "marginTop": "20%" }}>
													  <span className="sr-only"></span>
													</div>
												</div>
											):('')}
											{this.state.msg ? (
												<div className="alert alert-warning d-flex align-items-center justify-content-between" role="alert" >
												  <div className="flex-fill mr-3">
													<p className="mb-0">{parse(this.state.msg)}</p>
												  </div>
												  <div className="flex-00-auto">
													<i className="fa fa-fw fa-exclamation-circle"></i>
												  </div>
												</div>
											  ) : null}
											<form className="js-validation-signup" onSubmit={this.onSubmit} method="POST" >
												<div className="form-group">
													<span className="input-icon">
														<input name="username" className="form-control" id="username" type="text" placeholder="Nhập tên tài khoản" onChange={this.onChange} />
														<i className="fa fa-user" style={{"top": "-5px", "left": "20px"}}></i>
													</span>
													<p className="help-block help-block-error"></p>
												</div>
												<div className="form-group">
													<span className="input-icon">
														<input name="email" className="form-control" id="email" type="email" placeholder="Nhập email" onChange={this.onChange} />
														<i className="fa fa-envelope" style={{"top": "-5px", "left": "20px"}}></i>
													</span>
													<p className="help-block help-block-error"></p>
												</div>
												<div className="form-group">
													<span className="input-icon">
														<input name="phone" className="form-control" id="phone" type="text" placeholder="Nhập số điện thoại" onChange={this.onChange} />
														<i className="fa fa-phone" style={{"top": "-5px", "left": "20px", "transform": "translateY(100%) rotate(90deg)"}}></i>
													</span>
													<p className="help-block help-block-error"></p>
												</div>
												<div className="form-group">
													<span className="input-icon">
														<input name="password" className="form-control" id="password" type="password" placeholder="Nhập mật khẩu" onChange={this.onChange} />
														<i className="fa fa-lock" style={{"top": "-5px", "left": "20px"}}></i>
													</span>
												</div>
												<div className="form-group">
													<span className="input-icon">
														<input name="repeatpassword" className="form-control" id="repeatpassword" type="password" placeholder="Nhập lại mật khẩu" onChange={this.onChange} />
														<i className="fa fa-lock" style={{"top": "-5px", "left": "20px"}}></i>
													</span>
												</div>
												<div className="formlast">
													<input type="submit" className="btn btn-fans" value="Đăng ký"/>
												</div>
											</form>
											<div className="have-acc">
												<span>Bạn đã có tài khoản? </span>
												<Link to="/" onClick={this.props.action_login}>
													Đăng nhập
												</Link>
											</div>
										</div>
									</div>
									<div className="col-sm-6 text-right">
										<div className="img-wrap">
											<img className="" src={Layer2} alt="Social Media Services " />
										</div>
									</div>
								</div>
								<p><br/></p>
							</div>
						</div>
						<footer>
							<div className="container">
								<div className="col-sm-6 ftr-left text-left">
									<p>copyright 2021&nbsp;&nbsp;<span>HackLikePro</span>. All Rights Reserved.</p>
								</div>
								<div className="col-sm-6 ftr-right text-right">
									<ul className="social-icon">
										<li><a href="https://anon.ws/?https://www.facebook.com/100010750668234" target="_blank" className=""><i className="fab fa-facebook-f"></i></a></li>
									</ul>
								</div>
							</div>
						</footer>
					</div>
				</Fragment>
			</Router>
		);
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	error: state.error
})

export default connect(mapStateToProps, { register, clearErrors })(RegisterModal);