import {
	LOAD_BVS,
	UPDATE_BVS_TAB,
	UPDATE_BVS,
	LOAD_PRICE_BVS,
	LOADING_BVS,
	LIMIT_PAGI_BVS,
	TYPE_BVS,
} from "../actions/types";

const date7dnext = () => {
	var result = new Date();
  	result.setDate(result.getDate() + 7);
  	return result;
}

const initialState = {
	"taotientrinh": {
		"lhi": "",
		"url": "",
		"lsct": "0",
		"slct": "100",
		"gc": "",
		"gtmtt": "0",
	},
	"changetab": 0,
	"listtientrinh": [],
	"price": {
		buffviewstory: {
			min_bh_web: 0,
			prices_web: 0,
		},
	},
	"loading": false,
	"limit_pagi": 0,
	"type": 0,
	facebookbuffviewstory_sv1_mo_dong: 1,
};

export default function(state = initialState, action) {
	switch (action.type) {
		case LOAD_BVS:
			return {
				...state,
				"listtientrinh": action.payload
			};
		case UPDATE_BVS_TAB:
			return {
				...state,
				"changetab": action.payload
			};
		case UPDATE_BVS:
			return {
				...state,
				"taotientrinh": action.payload
			};
		case LOAD_PRICE_BVS:
			return {
				...state,
				"price": merDataPricePage(action.payload),
			};
		case LOADING_BVS:
			return {
				...state,
				"loading": action.payload
			};
		case LIMIT_PAGI_BVS:
			return {
				...state,
				"limit_pagi": action.payload
			};
		case TYPE_BVS:
			return {
				...state,
				"type": action.payload
			};
		case 'CONFIG_MO_DONG_BVS':
			return {
				...state,
				facebookbuffviewstory_sv1_mo_dong: action.payload.facebookbuffviewstory_sv1_mo_dong,
			};
		default:
		  return state;
	}
}

function merDataPricePage(data) {
	let dataNew = {
		...initialState.price
	}
	if (data.length > 0) {
		data.forEach(function(v, i){
			if (v.name_table === 'buffviewstory') {
				initialState.taotientrinh.gtmtt = v.prices_web;
				dataNew['buffviewstory'] = v;
			}
		});
	};
	return dataNew;
}
