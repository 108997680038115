import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import {
    Modal,
    ModalBody,
    ModalFooter
} from 'reactstrap';
import { editFbVip } from '../../../actions/FbvipActions';

class Modal_edit extends Component {
    state = {
        id: this.props.dataid,
        livenhom: this.props.datalivenhom,
        idnhomcongkhai: this.props.dataidnhomcongkhai,
        status: this.props.datastatus,
    }
	
	onChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value
		});
	}

    render() {
        const {isOpenModal, dataid} = this.props;
        const {livenhom, idnhomcongkhai, status} = this.state;
		const { changetab, loading } = this.props.Facebookvipmatlivestreamv2;
		// console.log(livenhom);
		// console.log(idnhomcongkhai);

        return (
            <Fragment>
                <Modal centered={true} size="lg" toggle={(e) => {this.props.openModal()}} isOpen={isOpenModal} className={(loading? ' block block-mode-loading' : '')} style={{"backgroundColor": "inherit", "boxShadow": "none"}}>
                    <div className="modal-header">
                        <p className="mb-0 text-dark">Sửa cookie</p>
                        <button onClick={(e) => {this.props.openModal()}} type="button" className="close">×</button>
                    </div>
                    <ModalBody style={{opacity: loading? '.05':'1'}}>
                        <div className="row">
							{ this.props.auth.user.type === 1 ?
								<div className="col-md-12">
									<div className="form-group">
										<label>Trạng thái</label>
										<select name="status" onChange={this.onChange} value={status} className="form-control">
											<option value="0">Hoàn thành</option>
											<option value="1">Đang chạy</option>
											<option value="2">Id lỗi</option>
											<option value="3">Hủy đơn</option>
											<option value="5">Hoàn tiền</option>
										</select>
									</div>
								</div>
							: '' }
                            <div className="col-md-12">
                                <div className="form-group focused">
                                    <label className="control-label">Live Nhóm:</label>
									<select className="form-control" id="livenhom" name="livenhom" onChange={this.onChange} value={livenhom} >
										<option value="1">Trang cá nhân, Page</option>
										<option value="2">Nhóm</option>
										<option value="3">Cả (trang cá nhân, Page) và nhóm</option>
									</select>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group focused">
                                    <label className="control-label">ID Nhóm Công Khai (ví dụ:213|2412|251|192|146|122) (tối đa 6 id nhóm):</label>
                                    <input className="form-control" placeholder="Ví dụ: 2332|1213|1212|1232|1321|213 đây là 6 id nhóm!" value={idnhomcongkhai} name="idnhomcongkhai" id="idnhomcongkhai" onChange={(e)=>{this.onChange(e)}} disabled={livenhom == 2 || livenhom == 3 ? '' : 'disabled'} />
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter style={{opacity: loading? '.05':'1'}}>
                        <button onClick={() => {this.props.editFbVip('facebookvipmatlivestreamv2', dataid, livenhom, idnhomcongkhai, '', '', this.props.openModal, 0, 0, "", status)}} type="button" className="btn btn-primary">Lưu</button>
                        <button onClick={(e) => {this.props.openModal()}} type="button" className="btn btn-danger">Huỷ</button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
	auth: state.auth,
    Facebookvipmatlivestreamv2: state.Facebookvipmatlivestreamv2
});

export default connect(mapStateToProps, {editFbVip}) (Modal_edit);
