import {
	LOAD_MF,
	UPDATE_MF,
	UPDATE_MF_GETID,
	UPDATE_MF_TAB,
	UPDATE_MF_IDFB_LIENHE,
	UPDATE_MF_IDFB_ADMIN,
	LOAD_PRICE_MF,
	LIMIT_PAGI_MF,
	LOADING_MF
} from "../actions/types";

const initialState = {
	"taotientrinh": {
		"newname": "",
		"quantity": "50000",
		"sdt": "",
		"fb": "",
		"fb_admin": "",
		"gtmtt": "0",
		"gc": "",
	},
	"changetab": 0,
	"listtientrinh": [],
	"tanggiathem": {
		"id": "",
		"orders_id": "",
		"per_price": 0,
		"price": 0,
		"quantity": 0,
		"loai": "",
	},
	"price": {
		min_bh_web: 0,
		prices_web: 0,
	},
	"limit_pagi": 0,
	"loading": false,
};

export default function(state = initialState, action) {
	switch (action.type) {
		case LOAD_MF:
			return {
				...state,
				"listtientrinh": action.payload.data,
			};
		case UPDATE_MF:
			return {
				...state,
				"taotientrinh": action.payload
			};
		case UPDATE_MF_GETID:
			return {
				...state,
				"taotientrinh": {
					...state.taotientrinh,
					"lhi": action.payload.id,
					"oldname": action.payload.name,
				}
			};
		case UPDATE_MF_TAB:
			return {
				...state,
				"changetab": action.payload
			};
		case UPDATE_MF_IDFB_LIENHE:
			return {
				...state,
				"taotientrinh": {
					...state.taotientrinh,
					"fb": action.payload
				}
			};
		case UPDATE_MF_IDFB_ADMIN:
			return {
				...state,
				"taotientrinh": {
					...state.taotientrinh,
					"fb_admin": action.payload
				}
			};
		case LOAD_PRICE_MF:
			return {
				...state,
				"price": action.payload,
				"taotientrinh": {
					...state.taotientrinh,
					"gtmtt": action.payload.prices_web
				}
			};
		case LIMIT_PAGI_MF:
			return {
				...state,
				"limit_pagi": action.payload
			};
		case LOADING_MF:
			return {
				...state,
				"loading": action.payload
			};
		default:
		  return state;
	}
}