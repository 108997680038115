import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import store from '../../../store';

import Swal from 'sweetalert2';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { updateThuesim, addThuesim, getConfigMoDong } from '../../../actions/ThuesimActions';
import parse from 'html-react-parser';
import ServicesDescription from "../ServicesDescription";
import { isMobile } from 'react-device-detect';

const date7dnext = () => {
	var result = new Date();
  	result.setDate(result.getDate() + 7);
  	return result;
}

class taotientrinh extends Component {
	static propTypes =  {
		updateThuesim: PropTypes.func.isRequired,
		addThuesim: PropTypes.func.isRequired,
	};
	
	state = {
		showDescription: isMobile? false : true
	}
	
	componentDidMount() {
		// this.props.loadPriceFLBBKTT();
		this.props.getConfigMoDong();
	};
	
	handleChangeDateStart = date => {
		const { taotientrinh, price } = this.props.Thuesim;
		
		var new_taotientrinh = {
			...taotientrinh,
			startDatebh: date
		};
		this.props.updateThuesim(new_taotientrinh);
	}
	
	onChange = (e) => {
		const { taotientrinh } = this.props.Thuesim;
		var new_taotientrinh = {
			...taotientrinh,
			[e.target.name]: e.target.value
		};
		this.props.updateThuesim(new_taotientrinh);
	}
	
	taoTienTrinhfunc = () => {
		const { taotientrinh } = this.props.Thuesim;
		var new_taotientrinh = {
			...taotientrinh,
		};
		Swal.fire({
			title: 'Bạn có chắc?',
			html: "Bạn có chắc chắn muốn mua Giá "+taotientrinh.gtmtt+" Coin / 1 lần? Khi đã bấm đồng ý đồng nghĩa với việc bạn đã đọc hết mục thông tin dịch vụ!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Đồng ý',
			cancelButtonText: 'Không'
		}).then((result) => {
			if (result.value) {
				this.props.addThuesim(new_taotientrinh);
			}
		})
	}
	
	onKeyPress = (e) => {
		if (!(e.charCode >= 48 && e.charCode <= 57)) {
			e.preventDefault();
		}
	}
	
	onClickShowDescription = (data) => {
		this.setState({
			showDescription: data
		});
	}
	
	render() {
 		const { lsct, gtmtt } = this.props.Thuesim.taotientrinh;
 		const { thuesim_sv1_mo_dong } = this.props.Thuesim;
		var price = Math.round((gtmtt*1 + Number.EPSILON) * 100) / 100;
		
		var listDataServicesInfo = {};
		try {
			if (localStorage.getItem("listDataServicesInfo") != null) {
				listDataServicesInfo = JSON.parse(localStorage.getItem("listDataServicesInfo"));
			} else {
				listDataServicesInfo = this.props.adminpage.listDataServicesInfo;
			}
		} catch(e) {
			listDataServicesInfo = {};
		}
		var html_haydoctranhmattien = '';
		var html_haydoctranhmattien_chinh = '';
		var html_haydoctranhmattien_sv = '';
		var html_thuesim_sv1 = '';
		if (typeof(listDataServicesInfo.thuesim_tab) !== 'undefined') {
			html_haydoctranhmattien = parse(listDataServicesInfo.thuesim_tab.ghichu);
			html_haydoctranhmattien_chinh = parse(listDataServicesInfo.thuesim_tab.ghichu_chinh);
		}
		if (typeof(listDataServicesInfo.thuesim_sv1) !== 'undefined') {
			html_thuesim_sv1 = parse(listDataServicesInfo.thuesim_sv1.ghichu_sv);
			if (lsct.toString() === "1") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.thuesim_sv1.ghichu);
			}
		}
		
		return (
			<Fragment>
				{/* <div className="form-group row mt-4">
					<label className="col-sm-4 col-form-label" htmlFor="">
						Số lượng cần tăng:
					</label>
					<div className="col-sm-8">
						<input type="number" className="form-control" id="slct" name="slct" placeholder="" value={slct} onChange={this.onChange} onKeyPress={this.onKeyPress} />
					</div>
				</div> */}
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Loại Seeding cần tăng:</label>
					<div className="col-sm-8">
						<div className="custom-control custom-radio custom-control-inline">
							<input onChange={this.onChange} value="1" type="radio" className="custom-control-input" id="loai_1" name="lsct" checked={lsct.toString() === "1"} disabled={this.props.Thuesim.price.thuesim_sv1.prices_web == 0 ? 'disabled' : ''} />
							<label className="custom-control-label" htmlFor="loai_1" > SV1 - OTP Facebook {html_thuesim_sv1} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.Thuesim.price.thuesim_sv1.prices_web} coin</span> {thuesim_sv1_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
						</div>
					</div>
				</div>
				
				<button type="button" className="btn badge-primary-cus btn-rounded btn-show-description-cus" onClick={(e) => {this.onClickShowDescription(!this.state.showDescription)}}>
					{
						this.state.showDescription?
							<i className="fa fa-angle-up" style={{"marginRight": "5px"}} aria-hidden="true"></i>
						:
							<i className="fa fa-angle-down" style={{"marginRight": "5px"}} aria-hidden="true"></i>
					}
					Thông tin dịch vụ
				</button>
				
				<div className="row mt-2" style={{"display": this.state.showDescription? "flex" : "none"}}>
				    <div className="col-md-12 bold" style={{"marginBottom": "10px"}}>
						<div className="card card-select-cus">
							<div className="card-body card-body-select-cus">
								<p className="mb-0 mt-3 text-wrap">
									<ul>
										{html_haydoctranhmattien}
										{html_haydoctranhmattien_chinh}
										{html_haydoctranhmattien_sv}
										<li>Nếu gặp lỗi hãy nhắn tin hỗ trợ phía bên phải góc màn hình hoặc vào mục liên hệ hỗ trợ để được hỗ trợ tốt nhất!</li>
									</ul>
								</p>
								<span className="badge badge-cus position-absolute-cus badge-top-left-cus badge-primary-cus badge-pill-cus"> HÃY ĐỌC ĐỂ TRÁNH MẤT TIỀN KHI SỬ DỤNG! </span>
							</div>
						</div>
				    </div>
				</div>
				<div className="row mt-4">
					<div className="col-12 text-center bold">
						<div className="card card-info">
							<div className="card-body">
								<h5 className="font-bold">Tổng: <span className="bold green"><span>{price}</span> Coin</span></h5>
								<h6 className="mb-0 bold"><span>Giá <span className="green">{gtmtt} Coin</span> / 1 lần</span></h6>
							</div>
						</div>
					</div>
				</div>
				<div className="row my-4">
					<div className="col-12 text-center bold">
						<button type="button" className="btn btn-block btn-rounded btn-hero-primary" onClick={this.taoTienTrinhfunc} >Tạo tiến trình</button>
					</div>
				</div>
			</Fragment>
		)
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	Thuesim: state.Thuesim,
});
const mapDispatchToProps = { updateThuesim, addThuesim, getConfigMoDong }

export default connect(mapStateToProps, mapDispatchToProps)(taotientrinh);