const date7dnext = () => {
	var result = new Date();
  	result.setDate(result.getDate() + 7);
  	return result;
}

const initialState = {
	loadingblock: false,
	listBuffSub: [],
	datapricebuffsub: {
		youtube_buffsub: {
			min_bh_web: 0,
			prices_web: 0,
		},
		youtube_buffsub_sv2: {
			min_bh_web: 0,
			prices_web: 0,
		},
		youtube_buffsub_sv3: {
			min_bh_web: 0,
			prices_web: 0,
		},
	},
	formData: {
		profile_user: '',
		loaiseeding: 1,
		startDatebh: new Date(),
		EndDatebh: date7dnext(),
		baohanh: 0,
		sltang: 10,
		giatien: 0,
		ghichu: '',
	},
	youtubebuffsub_sv1_mo_dong: 1,
	youtubebuffsub_sv2_mo_dong: 1,
	youtubebuffsub_sv3_mo_dong: 1,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case 'LIST_BUFF_SUB_USER_YT':
    	return {
			...state,
			listBuffSub: action.payload
		};
    case 'LOADING_BLOCK_BUFF_SUB_YT':
		return {
			...state,
			loadingblock: action.payload
		};
	case 'DATA_PRICE_IS_FUNCTION_BUFFSUB_YTB':
		return {
			...state,
			datapricebuffsub: merDataPricePage(action.payload)
		}
	case 'CHANGE_DATA_FORM_YTB_BUFFSUB':
		return {
			...state,
			formData: action.payload
		}
	case 'CONFIG_MO_DONG_YOUTUBE_BUFF_SUB':
		return {
			...state,
			youtubebuffsub_sv1_mo_dong: action.payload.youtubebuffsub_sv1_mo_dong,
			youtubebuffsub_sv2_mo_dong: action.payload.youtubebuffsub_sv2_mo_dong,
			youtubebuffsub_sv3_mo_dong: action.payload.youtubebuffsub_sv3_mo_dong,
		};
    default:
      return state;
  }
}

function merDataPricePage(data) {
	const dataOld = initialState.datapricebuffsub;
	let dataNew = {
		...dataOld
	}
	if (data.length > 0) {
		data.forEach(function(v, i){
			if (v.name_table === 'youtube_buffsub') {
				dataNew['youtube_buffsub'] = v;
				initialState.formData['giatien'] = v.prices_web;
			} else if (v.name_table === 'youtube_buffsub_sv2') {
				dataNew['youtube_buffsub_sv2'] = v;
			} else if (v.name_table === 'youtube_buffsub_sv3') {
				dataNew['youtube_buffsub_sv3'] = v;
			}
		});
	};
	return dataNew;
}
