import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Userinformation from "../Userinformation";

class rightContent extends Component {
	render() {
		return (
			<Fragment>
				<Userinformation />
				<div className="card card-orange mt-3">
					<div className="card-body">
						<p className="font-bold">Chú ý:</p>
						<p>- Proxy riêng để chạy BOT hạn chế checkpoint hơn.</p>
						<p>- Nếu cảm thấy quốc gia nào ổn định thì chọn.</p>
						<p>- Tìm hiểu kỹ về Proxyv6 trước khi thanh toán.</p>
						<p>- Nên gia hạn Proxy trước lúc hết hạn để tránh việc bị hủy Proxy.</p>
						<p>- Chúng tôi không hoàn lại Proxy đã Thanh Toán.</p>
						<p>- Video hướng dẫn fake ip để Facebook trust ip riêng cài BOT Tương Tác không bị Checkpoint : <a target="_blank" href="https://google.com">Tại đây</a></p>
					</div>
				</div>
			</Fragment>
		)
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
});
const mapDispatchToProps = { }

export default connect(mapStateToProps, mapDispatchToProps)(rightContent);