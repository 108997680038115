import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import {
    Modal,
    ModalBody,
    ModalFooter
} from 'reactstrap';
import { UpdateNdr } from '../../../actions/RipaccActions';

class Modal_edit extends Component {
	componentDidMount() {
        this.setState({
            thongbao: this.props.datathongbao,
            status: this.props.datastatus,
        });
	}
    state = {
        thongbao: this.props.thongbao,
        status: this.props.status,    
    }

    changeValueNdr = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    render() {
        const {isOpenModal, dataid, dataidfb} = this.props;
        const {thongbao, status} = this.state;
		const { changetab, loading } = this.props.Ripacc;

        return (
            <Fragment>
                <Modal centered={true} size="lg" toggle={(e) => {this.props.openModal()}} isOpen={isOpenModal} className={(loading? ' block block-mode-loading' : '')} style={{"backgroundColor": "inherit", "boxShadow": "none"}}>
                    <div className="modal-header">
                        <p className="mb-0 text-dark">Sửa</p>
                        <button onClick={(e) => {this.props.openModal()}} type="button" className="close">×</button>
                    </div>
                    <ModalBody style={{opacity: loading? '.05':'1'}}>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group focused">
									<label className="control-label">Thông báo admin:</label>
									<textarea rows="5" type="text" className="form-control" id="thongbao" name="thongbao" placeholder="" onChange={this.changeValueNdr} value={thongbao}></textarea>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
									<label>Trạng thái</label>
									<select name="status" onChange={this.changeValueNdr} value={status} className="form-control">
										<option value="0">Hoàn thành</option>
										<option value="1">Đang chạy</option>
										<option value="2">Chờ duyệt</option>
										<option value="3">Hủy</option>
										<option value="5">Hoàn tiền</option>
									</select>
								</div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter style={{opacity: loading? '.05':'1'}}>
                        <button onClick={() => {this.props.UpdateNdr(thongbao, status, dataid, this.props.openModal)}} type="button" className="btn btn-primary">Lưu</button>
                        <button onClick={(e) => {this.props.openModal()}} type="button" className="btn btn-danger">Huỷ</button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    Ripacc: state.Ripacc
});

export default connect(mapStateToProps, {UpdateNdr}) (Modal_edit);
