import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import {
    Modal,
    ModalBody,
    ModalFooter
} from 'reactstrap';
import { editShopee } from '../../../actions/ShopeeseedinglivestreamActions';

class Modal_edit extends Component {
	componentDidMount() {
        // this.setState({
            // ndr: Buffer.from(this.props.datandr, 'base64').toString('utf8')
        // });
	}
    state = {
        id: this.props.dataid,
        ndcmt: this.props.datandcmt,
        tocdocmt: this.props.datatocdocmt,
    }

    changeValueNdcmt = (e) => {
        this.setState({
            ...this.state,
            ndcmt: e.target.value
        });
		// console.log(typeof JSON.parse(e.target.value));
    }
	
    onChange = (e) => {
        this.setState({
            ...this.state,
            tocdocmt: e.target.value
        });
    }

    render() {
        const {isOpenModal} = this.props;
        const {loading} = this.props.Shopeeseedinglivestream;
        var {ndcmt, id, tocdocmt} = this.state;
		
		var phut = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20];
		var selectPhut =(
			<Fragment>
				{
					phut.map(function(value, index){
						return(<option value={value} key={index}>{value} phút</option>)
					})
				}
			</Fragment>
		)
        return (
            <Fragment>
                <Modal centered={true} size="lg" toggle={(e) => {this.props.openModal()}} isOpen={isOpenModal} className={(loading? ' block block-mode-loading' : '')} style={{"backgroundColor": "inherit", "boxShadow": "none"}}>
                    <div className="modal-header">
                        <p className="mb-0 text-dark">Sửa nội dung</p>
                        <button onClick={(e) => {this.props.openModal()}} type="button" className="close">×</button>
                    </div>
                    <ModalBody style={{opacity: loading? '.05':'1'}}>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group focused">
                                    <label className="control-label">Tốc độ cmt:</label>
									<select className="form-control custom-select select-light" id="tocdocmt" name="tocdocmt" onChange={this.onChange} value={tocdocmt} >
										{selectPhut}
									</select>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group focused">
                                    <label className="control-label">Nội dung cmt:</label>
                                    <textarea rows="2" type="text" className="form-control" id="ndcmt" name="ndcmt" placeholder="Mỗi nội dung comment 1 dòng, nội dung khác nhau" onChange={this.changeValueNdcmt} value={ndcmt}></textarea>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter style={{opacity: loading? '.05':'1'}}>
                        <button onClick={() => {this.props.editShopee('shopeeseedinglivestream', id, ndcmt, tocdocmt, this.props.openModal)}} type="button" className="btn btn-primary">Lưu</button>
                        <button onClick={(e) => {this.props.openModal()}} type="button" className="btn btn-danger">Huỷ</button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    Shopeeseedinglivestream: state.Shopeeseedinglivestream
});

export default connect(mapStateToProps, {editShopee}) (Modal_edit);
