import {
	LOAD_RIPACC,
	UPDATE_RIPACC_TAB,
	UPDATE_RIPACC,
	UPDATE_RIPACC_IDFB,
	UPDATE_RIPACC_IDFB_LIENHE,
	LOAD_PRICE_RIPACC,
	LOADING_RIPACC,
	LIMIT_PAGI_RIPACC,
	TYPE_RIPACC,
} from "../actions/types";

const date7dnext = () => {
	var result = new Date();
  	result.setDate(result.getDate() + 7);
  	return result;
}

const initialState = {
	"taotientrinh": {
		"profile_user": "",
		"loairip": "0",
		"quatay": "0",
		"namtao": "",
		"sdt": "",
		"fb": "",
		"ghichu": "",
		"gtmtt": "0",
	},
	"changetab": 0,
	"listtientrinh": [],
	"price": {
		min_bh_web: 0,
		prices_web: 0,
	},
	"loading": false,
	"limit_pagi": 0,
	"type": 0,
};

export default function(state = initialState, action) {
	switch (action.type) {
		case LOAD_RIPACC:
			return {
				...state,
				"listtientrinh": action.payload
			};
		case UPDATE_RIPACC_TAB:
			return {
				...state,
				"changetab": action.payload
			};
		case UPDATE_RIPACC:
			return {
				...state,
				"taotientrinh": action.payload
			};
		case UPDATE_RIPACC_IDFB:
			return {
				...state,
				"taotientrinh": {
					...state.taotientrinh,
					"profile_user": action.payload
				}
			};
		case UPDATE_RIPACC_IDFB_LIENHE:
			return {
				...state,
				"taotientrinh": {
					...state.taotientrinh,
					"fb": action.payload
				}
			};
		case LOAD_PRICE_RIPACC:
			return {
				...state,
				"price": action.payload,
				"taotientrinh": {
					...state.taotientrinh,
					"gtmtt": action.payload.prices_web
				}
			};
		case LOADING_RIPACC:
			return {
				...state,
				"loading": action.payload
			};
		case LIMIT_PAGI_RIPACC:
			return {
				...state,
				"limit_pagi": action.payload
			};
		case TYPE_RIPACC:
			return {
				...state,
				"type": action.payload
			};
		default:
		  return state;
	}
}
