import {
	LOAD_RNF,
	UPDATE_RNF,
	UPDATE_RNF_GETID,
	UPDATE_RNF_TAB,
	UPDATE_RNF_IDFB_LIENHE,
	LOAD_PRICE_RNF,
	LIMIT_PAGI_RNF,
	TYPE_RNF,
	LOADING_RNF
} from "../actions/types";

const initialState = {
	"taotientrinh": {
		"lhi": "",
		"oldname": "",
		"newname": "",
		"quatay": "0",
		"namtao": "",
		"sdt": "",
		"fb": "",
		"gtmtt": "0",
		"gc": "",
	},
	"changetab": 0,
	"listtientrinh": [],
	"tanggiathem": {
		"id": "",
		"orders_id": "",
		"per_price": 0,
		"price": 0,
		"quantity": 0,
		"loai": "",
	},
	"price": {
		min_bh_web: 0,
		prices_web: 0,
	},
	"limit_pagi": 0,
	"type": 0,
	"loading": false,
};

export default function(state = initialState, action) {
	switch (action.type) {
		case LOAD_RNF:
			return {
				...state,
				"listtientrinh": action.payload.data,
			};
		case UPDATE_RNF:
			return {
				...state,
				"taotientrinh": action.payload
			};
		case UPDATE_RNF_GETID:
			return {
				...state,
				"taotientrinh": {
					...state.taotientrinh,
					"lhi": action.payload.id,
					"oldname": action.payload.name,
				}
			};
		case UPDATE_RNF_TAB:
			return {
				...state,
				"changetab": action.payload
			};
		case UPDATE_RNF_IDFB_LIENHE:
			return {
				...state,
				"taotientrinh": {
					...state.taotientrinh,
					"fb": action.payload
				}
			};
		case LOAD_PRICE_RNF:
			return {
				...state,
				"price": action.payload,
				"taotientrinh": {
					...state.taotientrinh,
					"gtmtt": action.payload.prices_web
				}
			};
		case LIMIT_PAGI_RNF:
			return {
				...state,
				"limit_pagi": action.payload
			};
		case TYPE_RNF:
			return {
				...state,
				"type": action.payload
			};
		case LOADING_RNF:
			return {
				...state,
				"loading": action.payload
			};
		default:
		  return state;
	}
}