
const initialState = {
	loadingblock: false,
	listBuffSub: [],
	datapricebuffsub: {
		viplike_group: {
			min_bh_web: 0,
			prices_web: 0,
		},
	},
	formData: {
		lsct: 1,
		lhi: '',
		name: '',
		slbv: 5,
		day_sale: 30,
		min_like: 50,
		max_like: 50,
		bltk: '',
		dsif: '',
		ghichu: '',
		giatien: 0
	},
	facebookviplikegroup_sv1_mo_dong: 1,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case 'LIST_BUFF_VIP_GROUP':
    	return {
			...state,
			listBuffSub: action.payload
		};
    case 'LOADING_BLOCK_BUFF_VIP_GROUP':
		return {
			...state,
			loadingblock: action.payload
		};
	case 'CHANGE_DATA_FORM_BUFF_VIP_GROUP':
		return {
			...state,
			formData: action.payload
		}
	case 'GET_ID_BUFF_VIP_GROUP':
		return {
			...state,
			formData: {
				...state.formData,
				lhi: action.payload.data.group_id,
				name: action.payload.data.group_name,
			}
		}
	case 'DATA_PRICE_IS_FUNCTION_BUFF_VIP_GROUP':
		return {
			...state,
			datapricebuffsub: mergePrice(action.payload)
		}
    case 'LOADING_BLOCK_BUFF_SUB':
		return {
			...state,
			loadingblock: action.payload
		};
	case 'CONFIG_MO_DONG_VIP_LIKE_CLONE':
		return {
			...state,
			facebookviplikegroup_sv1_mo_dong: action.payload.facebookviplikegroup_sv1_mo_dong,
		};
    default:
      return state;
  }
}

function mergePrice (arr_price) {
	const dataOld = initialState.datapricebuffsub;
	let dataNew = {
		...dataOld
	}
	if (arr_price.length > 0) {
		arr_price.forEach(function(v, i){
			if (v.name_table === 'viplike_group') {
				initialState.formData.giatien = v.prices_web;
				dataNew.viplike_group = v;
			}
		});
		return dataNew;
	}
}
