import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import BootstrapTable from 'react-bootstrap-table-next';
import { connect } from 'react-redux';
import {
	Modal,
	ModalBody,
	ModalFooter
} from 'reactstrap';
import Moment from 'react-moment';
import Swal from 'sweetalert2';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { laylichsumuathe } from '../../../../actions/authActions';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import NumberFormat from 'react-number-format';
import Modaledit from './modal_edit';
const { SearchBar } = Search;


class History extends Component {
	componentDidMount(){
        this.props.laylichsumuathe(0);
	}
	
	state = {
		isOpenModalEdit: false,
	}
	
	onCheckList = (type) => {
		this.props.laylichsumuathe(type);
	}
	
	convertDate(date_create){
		var date = new Date(date_create);
		var date_cv = date.getDate() + '-' + (date.getMonth()+1) + '-'+date.getFullYear()+ ' '+date.getHours()+':'+date.getMinutes();
		return date_cv;
	}
	
    openModalEdit = (data = null) => {
		this.setState({
			...this.state,
			isOpenModalEdit: !this.state.isOpenModalEdit,
		});
		if (data !== null) {
			this.setState({
				...this.state,
				isOpenModalEdit: !this.state.isOpenModalEdit,
				id: data.id,
				status: data.status,
			});
		}
    }
	
 	render() {
		function formatNhaMang(cell, row) {
			if (cell === 'VMS') {
				return ('Mobifone');
			} else if (cell === 'VTT') {
				return ('Viettel');
			} else if (cell === 'VNP') {
				return ('Vina');
			} else {
				return ('');
			}
		}
		function formatPrice(cell, row) {
			return (
				<Fragment>
					<NumberFormat value={ cell } displayType={'text'} thousandSeparator={true} suffix={' vnđ'} />
				</Fragment>
			);
		}
		function filterValuePrice(cell, row) {
			return cell;
		}
		function formatKhuyenMai(cell, row) {
			return (cell + '%');
		}
		function formatTrangThai(cell, row) {
			var message = '';
			if (cell == 0) {
				return (
					<span className="badge badge-primary"><i className="fas fa-check mr-1"></i>Đã nạp</span>
				);
			} else if (cell == 1) {
				return (
					<span className="badge badge-warning"><i className="fas fa-sync-alt mr-1"></i>Chờ duyệt</span>
				);
			} else if (cell == 2) {
				return (
					<span className="badge badge-danger"><i className="fas fa-ban mr-1"></i>Hủy</span>
				);
			} else {
				return ('');
			}
		}
		function filterValueTrangThai(cell, row) {
			if (cell == 0) {
				return ('Đã nạp');
			} else if (cell == 1) {
				return ('Chờ duyệt');
			} else if (cell == 2) {
				return ('Hủy');
			} else {
				return ('');
			}
		}
		const columns = [
			{
			  dataField: 'id',
			  text: 'STT',
		   	  sort: true,
			},
			{
			  dataField: 'loaithe',
			  text: 'Nhà mạng',
			  sort: true,
			  formatter: formatNhaMang,
			  filterValue: formatNhaMang
			},
			{
			  dataField: 'seri',
			  text: 'Seri',
			  sort: true
			},
			{
			  dataField: 'mathe',
			  text: 'Mã thẻ',
			  sort: true
			},
			{
			  dataField: 'menhgia',
			  text: 'Mệnh giá',
			  sort: true,
			  formatter: formatPrice,
			  filterValue: filterValuePrice
			},
			{
			  dataField: 'khuyen_mai',
			  text: 'Khuyến mãi',
			  sort: true,
			  formatter: formatKhuyenMai,
			  filterValue: formatKhuyenMai
			},{
			  dataField: 'username',
			  text: 'Tên tài khoản',
			  sort: true,
			  classes: 'notranslate',
			},
			{
			  dataField: 'thoigian_nap',
			  text: 'Thời gian nạp',
			  sort: true,
			  formatter: (cell, row) => 
			  	<Fragment>
		    	 <Moment format="DD-MM-YYYY HH:mm:ss">{parseInt(cell)}</Moment>
		    	</Fragment>
			},
			{
				dataField: 'status',
				text: 'Trạng thái',
				sort: true,
				formatter: formatTrangThai,
				filterValue: filterValueTrangThai
			}, {
				dataField: '',
				text: '',
				formatter: (cell, row) => 
					<Fragment>
						{
							this.props.auth.user.type === 1 ?
								<Link to="/naptienthe" className="btn btn-sm btn-primary text-white text-white mr-1" onClick={() => {this.openModalEdit(row)}}>
									<i className="fa fa-fw fa-pen"></i>
								</Link>
							: ''
						}
					</Fragment>
			}
		];

		var {list_history_the} = this.props.auth;
    	return (
    		<Fragment>
			
				<ToolkitProvider keyField="id" data={ list_history_the } columns={ columns } search >
					{
						props => (
							<div>
								<SearchBar { ...props.searchProps } />
								<div className="row mb-4">
									<div className="col-md-12">
										{ this.props.auth.user.type === 1 ?
											<Fragment>
												<button onClick={() => {this.onCheckList(1)}} type="button" className="btn btn-primary mr-1">Xem tất cả</button>
												<button onClick={() => {this.onCheckList(0)}} type="button" className="btn btn-primary mr-1">Xem riêng</button>
											</Fragment>
										: ''}
									</div>
								</div>
								<BootstrapTable { ...props.baseProps } pagination={ paginationFactory() } wrapperClasses="table-responsive" striped hover condensed />
								{this.state.isOpenModalEdit ? <Modaledit openModal={this.openModalEdit} isOpenModal={this.state.isOpenModalEdit} dataid={this.state.id} datastatus={this.state.status} /> : ''}
							</div>
						)
					}
				</ToolkitProvider>
			</Fragment>
			
		);
  	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	payment: state.payment
});

export default connect(mapStateToProps, {laylichsumuathe})(History);
