import axios from 'axios';
import {
	LOAD_BMLS,
	ADD_BMLS,
	UPDATE_BMLS,
	UPDATE_BMLS_TAB,
	LOAD_PRICE_BMLS,
	LIMIT_PAGI_BMLS,
	TYPE_BMLS,
	LOADING_BMLS,
	LOADING_BMLS_2,
	LOAD_NHATKYHOATDONG_BMLS
} from "../actions/types";
import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Swal from 'sweetalert2';
import { loadUser } from "./authActions";

export const loadBMLS = (type = 0, page = 1, obj_search = '') => (dispatch, getState) => {
	dispatch({
		type: LOADING_BMLS,
		payload: true
	});
	axios.get('/api/facebook_buff/list?type_api=Buff_view_livestrean&type='+type+'&page='+page+'&obj_search='+obj_search, tokenConfig(getState))
		.then(res => {
			dispatch({
				type: LOADING_BMLS,
				payload: false
			});
			if (res.data.status === 200) {
				dispatch({
					type: LOAD_BMLS,
					payload: res.data.data
				});
			}
		});
};
export const loadPriceBMLS = () => (dispatch, getState) => {
	axios.get('/api/buffmatlivestream/price', tokenConfig(getState))
		.then(res => {
			if (res.data.status === 200) {
				dispatch({
					type: LOAD_PRICE_BMLS,
					payload: res.data.data
				});
			}
		});
};

export const addBMLS = (body) => (dispatch, getState) => {
	dispatch({
		type: LOADING_BMLS,
		payload: true
	});
	axios.post('/api/facebook_buff/create', body, tokenConfig(getState))
		.then(res => {
			dispatch({
				type: LOADING_BMLS,
				payload: false
			});
			dispatch(loadUser());
			if (res.data.status === 200) {
				Swal.fire({
					icon: 'success',
					html: 'Tạo tiến trình seeding thành công, hệ thống sẽ tự động tăng theo thời gian bạn đã chọn <br/><br/> Số lượng mắt hiện tại là: <span class="text-danger"><strong>'+res.data.data.mat_video+'</strong></span>'
				});
			} else {
				var message = '';
				if (res.data.message) {
					message = res.data.message;
				} else {
					message = res.data.error;
				}
				Swal.fire({
					icon: 'error',
					title: 'Lỗi',
					text: message,
				});
			}
		});
};

export const updateBMLS = (body) => (dispatch) => {
	dispatch({
		type: UPDATE_BMLS,
		payload: body
	})
};

export const updateLimitBMLS = (body) => (dispatch) => {
	dispatch({
		type: LIMIT_PAGI_BMLS,
		payload: body
	})
};

export const updateTypeBMLS = (body) => (dispatch) => {
	dispatch({
		type: TYPE_BMLS,
		payload: body
	})
};

export const changeTab = (body) => (dispatch, getState) => {
	dispatch({
		type: UPDATE_BMLS_TAB,
		payload: body
	});
};

export const getConfigMoDong = (type = '') => (dispatch, getState) => {
	dispatch({
		type: LOADING_BMLS,
		payload: true
	});

	axios.get('/api/facebook_buff/getconfig?type_api=buffmatlivestream', tokenConfig(getState))
	.then(res => {
		dispatch({
			type: LOADING_BMLS,
			payload: false
		});
		if (res.data.status === 200) {
			dispatch({
				type: 'CONFIG_MO_DONG_BUFF_MAT_LIVESTREAM',
				payload: res.data
			});
		}
	});
}

export const NhatKyHoatDong = (idfb) => (dispatch, getState) => {
	dispatch({
		type: LOADING_BMLS_2,
		payload: true
	});
	
	axios.get('/api/facebook_buff/nhatkyhoatdong?type_api=buffmatlivestream&idfb='+idfb, tokenConfig(getState))
		.then(res => {
			dispatch({
				type: LOADING_BMLS_2,
				payload: false
			});
			if (res.data.status === 200) {
				dispatch({
					type: LOAD_NHATKYHOATDONG_BMLS,
					payload: res.data.data
				});
			}
			if (res.data.status === 400) {
				dispatch({
					type: LOAD_NHATKYHOATDONG_BMLS,
					payload: []
				});
			}
		});
};

export const getID = (body) => (dispatch, getState) => {
	let bodysend = {
		id_user: getState().auth.user.id,
		link: body
	};
	dispatch({
		type: LOADING_BMLS,
		payload: true
	});
	return new Promise(function(resolve, reject){
		axios.post('/api/checklinkfb/check_video/', bodysend, tokenConfig(getState))
			.then(res => {
				dispatch({
					type: LOADING_BMLS,
					payload: false
				});
				if (res.data.status === 200) {
					resolve(res.data.id);
				} else {
					resolve('');
					Swal.fire({ icon: 'error', title: 'Lỗi', text: res.data.msg });
				}
			});
	});
};

export const upStopOrder = (body) => (dispatch, getState) => {
	dispatch({
		type: LOADING_BMLS,
		payload: true
	});
	axios.post('/api/facebook_buff/stoporder', body, tokenConfig(getState))
		.then(res => {
			dispatch({
				type: LOADING_BMLS,
				payload: false
			});
			if (res.data.status === 200) {
				Swal.fire({
					icon: 'success',
					text: res.data.message,
				});
				dispatch(loadBMLS());
			}
			if (res.data.status === 400) {
				var message = '';
				if (res.data) {
					message = res.data.message;
				}
				Swal.fire({
					icon: 'error',
					title: 'Lỗi',
					text: message,
				});
			}
		});
};

export const saveEditOrderAdmin = (data, closeM) => (dispatch, getState) => {
	let bodysend = {
		formdata: data,
		type_api: 'buffmatlivestream'
	};
	dispatch({
		type: LOADING_BMLS,
		payload: true
	});
	axios.post('/api/facebook_buff/admin_edit_order/', bodysend, tokenConfig(getState))
	.then(res => {
		dispatch({
			type: LOADING_BMLS,
			payload: false
		});
		if (res.data.status === 200) {
			dispatch(loadBMLS());
			closeM();
			Swal.fire(
	      		'Thành công!',
	      		'Sửa thành công',
	      		'success'
	    	)
		} else {
			openBtComfirmAlert(res.data.message, 'error', 'Lỗi');
		}
	}).catch(err => {
		dispatch({
			type: LOADING_BMLS,
			payload: false
		});
		console.log(err);
		openBtComfirmAlert('Có lỗi xảy ra!', 'error', 'Lỗi');
    });
};

export const openBtComfirmAlert = (message, type, title = '') => {
	Swal.fire({
		icon: type,
		title: title,
		text: message,
	});
	return false;
}

export const tokenConfig = getState => {
	// Get token from localhost
	const token = getState().auth.token;
	// Headers
	const config = {
		headers: {
			"Content-type": "application/json"
		}
	};
	
	// If token, add to headers
	if (token) {
		config.headers['ht-token'] = token;
	}
	
	return config;
};