import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Userinformation from "../Userinformation";

class rightContent extends Component {
	render() {
		return (
			<Fragment>
				<Userinformation />
				<div className="card card-orange mt-3">
					<div className="card-body">
						<p className="font-bold text-danger">Lưu ý:</p>
						<p>- Cấp ưu tiên càng cao đơn sẽ được xử lý càng nhanh, mỗi cấp ưu tiên sẽ trừ % chiết khấu tương ứng.</p>
						<p>- Nếu lượng đơn quá tải, hệ thống sẽ tạm đóng đơn.</p>
						<p>- Trường hợp đóng đơn, quý khách không chọn được nhà mạng.</p>
						<p>- Quý khách có thể chờ đến khi mở đơn để gạch cước.</p>
					</div>
				</div>
			</Fragment>
		)
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
});
const mapDispatchToProps = { }

export default connect(mapStateToProps, mapDispatchToProps)(rightContent);