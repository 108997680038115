import axios from 'axios';
import Swal from 'sweetalert2';
import { loadUser } from "./authActions";

export const creatItem = (data) => (dispatch, getState) => {
	let bodysend = {
		formdata: data
	};
	Swal.fire({
	  	title: 'Bạn có chắc?',
	  	text: "Bạn có chắc chắn với điều này ? Khi đã bấm đồng ý đồng nghĩa với việc bạn đã đọc hết mục thông tin dịch vụ!",
	  	icon: 'warning',
	  	showCancelButton: true,
	  	confirmButtonColor: '#3085d6',
	  	cancelButtonColor: '#d33',
	  	confirmButtonText: 'Đồng ý',
	  	cancelButtonText: 'Không'
	}).then((result) => {
	  	if (result.value) {
			dispatch({
				type: 'LOADING_TAG_BUY_PROXY',
				payload: true
			});
	  		axios.post('/api/buyproxy/add/', bodysend, tokenConfig(getState)).then(res => {
				dispatch(loadUser());
	  			if (res.data.status === 200) {
	  				Swal.fire('Success', 'Thêm thành công', 'success');
	  			} else {
	  				Swal.fire('Lỗi', res.data.message, 'error');
	  			}

				dispatch({
					type: 'LOADING_TAG_BUY_PROXY',
					payload: false
				});
	  		});
	  	}
	})
}

export const GetListItem = (viewadmin, page = "", obj_search = '') => (dispatch, getState) => {
	dispatch({
		type: 'LOADING_TAG_BUY_PROXY',
		payload: true
	});
	let param_url = '';
	if (page !== "") {
		param_url += '&page='+page;
	}
	param_url += '&obj_search='+obj_search;
	axios.get('/api/buyproxy/list?viewall='+viewadmin+param_url, tokenConfig(getState)).then(res => {
		dispatch({
			type: 'LOADING_TAG_BUY_PROXY',
			payload: false
		});
  		if (res.data.status === 200) {
	  		dispatch({
				type: 'LIST_DATA_PROXY',
				payload: res.data.data
			});
  		}
	});
}

export const searchDataProxy = (data) => (dispatch, getState) => {
	dispatch({
		type: 'LOADING_TAG_BUY_PROXY',
		payload: true
	});
	axios.get('/api/buyproxy/list/?object_search='+data, tokenConfig(getState)).then(res => {
		dispatch({
			type: 'LOADING_TAG_BUY_PROXY',
			payload: false
		});
  		if (res.data.status === 200) {
	  		dispatch({
				type: 'LIST_DATA_PROXY',
				payload: res.data.data
			});
  		}
	});
};

export const checkProxyStatus = (data) => (dispatch, getState) => {
	dispatch({
		type: 'LOADING_TAG_BUY_PROXY',
		payload: true
	});
	axios.get('/api/buyproxy/check/?id='+data, tokenConfig(getState)).then(res => {
		dispatch({
			type: 'LOADING_TAG_BUY_PROXY',
			payload: false
		});
  		if (res.data.status === 200) {
			Swal.fire('Success', res.data.message, 'success');
		} else {
			Swal.fire('Lỗi', res.data.message, 'error');
		}
	});
};

export const ProlongItemProxy = (data, period, id_proxy, closeM) => (dispatch, getState) => {
	Swal.fire({
	  	title: 'Bạn có chắc?',
	  	text: "Bạn có chắc chắn với điều này ?",
	  	icon: 'warning',
	  	showCancelButton: true,
	  	confirmButtonColor: '#3085d6',
	  	cancelButtonColor: '#d33',
	  	confirmButtonText: 'Đồng ý',
	  	cancelButtonText: 'Không'
	}).then((result) => {
	  	if (result.value) {
			dispatch({
				type: 'LOADING_TAG_BUY_PROXY',
				payload: true
			});
			if (data.length < 1) {
				Swal.fire('Lỗi', 'Không có proxy nào được chọn để gia hạn!', 'error');
				return false;
			}
			let bodysend = {
				arrId: data,
				period: period,
				id_proxy: id_proxy
			};
			axios.post('/api/buyproxy/prolong/', bodysend, tokenConfig(getState)).then(res => {
				dispatch({
					type: 'LOADING_TAG_BUY_PROXY',
					payload: false
				});
				if (res.data.status === 200) {
					dispatch(loadUser());
					dispatch(GetListItem());
					closeM();
					Swal.fire('Success', 'Gia hạn thành công!', 'success');
				} else {
					Swal.fire('Lỗi', res.data.message, 'error');
				}
			});
		}
	});
}

export const EditItem = (data, closeM) => (dispatch, getState) => {
	Swal.fire({
	  	title: 'Bạn có chắc?',
	  	text: "Bạn có chắc chắn với điều này ?",
	  	icon: 'warning',
	  	showCancelButton: true,
	  	confirmButtonColor: '#3085d6',
	  	cancelButtonColor: '#d33',
	  	confirmButtonText: 'Đồng ý',
	  	cancelButtonText: 'Không'
	}).then((result) => {
	  	if (result.value) {
			dispatch({
				type: 'LOADING_TAG_BUY_PROXY',
				payload: true
			});
			let bodysend = {
				formdata: data,
			};
			axios.post('/api/buyproxy/edit/', bodysend, tokenConfig(getState)).then(res => {
				dispatch({
					type: 'LOADING_TAG_BUY_PROXY',
					payload: false
				});
				if (res.data.status === 200) {
					dispatch(GetListItem());
					closeM();
					Swal.fire('Success', 'Sửa thành công!', 'success');
				} else {
					Swal.fire('Lỗi', res.data.message, 'error');
				}
			});
		}
	});
}

export const getConfigMoDong = (type = '') => (dispatch, getState) => {
	dispatch({
		type: 'LOADING_TAG_BUY_PROXY',
		payload: true
	});

	axios.get('/api/buyproxy/getconfig', tokenConfig(getState))
	.then(res => {
		dispatch({
			type: 'LOADING_TAG_BUY_PROXY',
			payload: false
		});
		if (res.data.status === 200) {
			dispatch({
				type: 'CONFIG_MO_DONG_PROXY',
				payload: res.data
			});
		}
	});
}

export const tokenConfig = getState => {
	// Get token from localhost
	const token = getState().auth.token;
	
	// Headers
	const config = {
		headers: {
			"Content-type": "application/json"
		}
	};
	
	// If token, add to headers
	if (token) {
		config.headers['ht-token'] = token;
	}
	
	return config;
};