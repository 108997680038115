const date7dnext = () => {
	var result = new Date();
  	result.setDate(result.getDate() + 7);
  	return result;
}

const initialState = {
	loadingblock: false,
	listBuffSub: [],
	datapricebuffsub: {
		// like: {
			// min_bh_web: 0,
			// prices_web: 0,
		// },
		follow: {
			min_bh_web: 0,
			prices_web: 0,
		},
		// comment: {
			// min_bh_web: 0,
			// prices_web: 0,
		// },
		// view: {
			// min_bh_web: 0,
			// prices_web: 0,
		// }
		follow_sv2: {
			min_bh_web: 0,
			prices_web: 0,
		},
		follow_sv3: {
			min_bh_web: 0,
			prices_web: 0,
		},
		follow_sv4: {
			min_bh_web: 0,
			prices_web: 0,
		},
		follow_sv5: {
			min_bh_web: 0,
			prices_web: 0,
		},
	},
	formData: {
		link: '',
		profile_user: '',
		loaiseeding: 'follow',
		startDatebh: new Date(),
		EndDatebh: date7dnext(),
		baohanh: 0,
		sltang: 10,
		giatien: 0,
		ghichu: '',
		list_messages: [],
		infoTiktok: {
			userid_tiktok: "",
			uniqueId_tiktok: "",
			avatarThumb_tiktok: "",
			avatarMedium_tiktok: "",
			verified_tiktok: "",
			secret_tiktok: "",
			secUid_tiktok: "",
			openFavorite_tiktok: "",
			relation_tiktok: "",
			following_tiktok: "",
			fans_tiktok: "",
			heart_tiktok: "",
			video_tiktok: "",
			like_tiktok: "",
		}
	},
	tiktokbufffollow_sv1_mo_dong: 1,
	tiktokbufffollow_sv2_mo_dong: 1,
	tiktokbufffollow_sv3_mo_dong: 1,
	tiktokbufffollow_sv4_mo_dong: 1,
	tiktokbufffollow_sv5_mo_dong: 1,
};

export default function(state = initialState, action) {
  	switch (action.type) {
	    case 'LIST_BUFF_SUB_TIK_TOK_USER':
	    	return {
				...state,
				listBuffSub: action.payload
			};
	    case 'LOADING_BLOCK_BUFF_SUB_TIK_TOK':
			return {
				...state,
				loadingblock: action.payload
			};
    	case 'LIST_BUFF_SUB_USER_TIKTOK':
    		return {
				...state,
				listBuffSub: action.payload
			};
    	case 'DATA_PRICE_IS_FUNCTION_TIKTOK_BUFF':
    		return {
				...state,
				datapricebuffsub: merDataPricePage(action.payload)
			};
    	case 'CHANGE_DATA_FORM_BUFF_SUB_TIKTOK':
    		return {
				...state,
				formData: {...state.formData, [action.payload.name]: action.payload.value }
			};
    	case 'UPDATE_INFO_TIKTOK':
    		return {
				...state,
				formData: {
					...state.formData,
					infoTiktok: action.payload
				}
			};
		case 'CONFIG_MO_DONG_TIKTOK_BUFF_FOLLOW':
			return {
				...state,
				tiktokbufffollow_sv1_mo_dong: action.payload.tiktokbufffollow_sv1_mo_dong,
				tiktokbufffollow_sv2_mo_dong: action.payload.tiktokbufffollow_sv2_mo_dong,
				tiktokbufffollow_sv3_mo_dong: action.payload.tiktokbufffollow_sv3_mo_dong,
				tiktokbufffollow_sv4_mo_dong: action.payload.tiktokbufffollow_sv4_mo_dong,
				tiktokbufffollow_sv5_mo_dong: action.payload.tiktokbufffollow_sv5_mo_dong,
			};
    	default:
      		return state;
  	}
}

function merDataPricePage(data) {
	const dataOld = initialState.datapricebuffsub;
	let dataNew = {
		...dataOld
	}
	if (data.length > 0) {
		data.forEach(function(v, i){
			// if (v.name_table === 'tiktok_bufflike') {
				// dataNew['like'] = v;
				// initialState.formData['giatien'] = v.prices_web;
			// } else if (v.name_table === 'tiktok_buff') {
				// dataNew['follow'] = v;
			// } else if (v.name_table === 'tiktok_buffcomment') {
				// dataNew['comment'] = v;
				// initialState.formData['giatien'] = v.prices_web;
			// } else if (v.name_table === 'tiktok_buffview') {
				// dataNew['view'] = v;
			// }
			if (v.name_table === 'tiktok_buff') {
				dataNew['follow'] = v;
				initialState.formData['giatien'] = v.prices_web;
			} else if (v.name_table === 'tiktok_buff_sv2') {
				dataNew['follow_sv2'] = v;
			} else if (v.name_table === 'tiktok_buff_sv3') {
				dataNew['follow_sv3'] = v;
			} else if (v.name_table === 'tiktok_buff_sv4') {
				dataNew['follow_sv4'] = v;
			} else if (v.name_table === 'tiktok_buff_sv5') {
				dataNew['follow_sv5'] = v;
			}
		});
	};
	return dataNew;
}
