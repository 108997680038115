import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import {
    Modal,
    ModalBody,
    ModalFooter
} from 'reactstrap';
import { acceptRefund } from '../../../actions/refundOrderAction';

class Modal_edit extends Component {
    state = {
		value: this.props.datavalue
    }

    changeValue = (e) => {
        this.setState({
            value: e.target.value
        });
    }
	onKeyPress = (e) => {
		if (!(e.charCode >= 48 && e.charCode <= 57)) {
			e.preventDefault();
		}
	}

    render() {
        const {isOpenModal, dataid} = this.props;
		const { changetab, loadding } = this.props.refundOrder;
		var { value } = this.state;

        return (
            <Fragment>
                <Modal centered={true} size="lg" toggle={(e) => {this.props.openModal()}} isOpen={isOpenModal} className={(loadding? ' block block-mode-loading' : '')} style={{"backgroundColor": "inherit", "boxShadow": "none"}}>
                    <div className="modal-header">
                        <p className="mb-0 text-dark">Hoàn tiền</p>
                        <button onClick={(e) => {this.props.openModal()}} type="button" className="close">×</button>
                    </div>
                    <ModalBody style={{opacity: loadding? '.05':'1'}}>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group focused">
                                    <label className="control-label">Hoàn tiền</label>
                                    <input className="form-control" placeholder="Tiền hoàn" value={value} onChange={(e)=>{this.changeValue(e)}} onKeyPress={this.onKeyPress} />
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter style={{opacity: loadding? '.05':'1'}}>
                        <button onClick={(e) => {this.props.acceptRefund(dataid, value, this.props.openModal)}} type="button" className="btn btn-primary">Lưu</button>
                        <button onClick={(e) => {this.props.openModal()}} type="button" className="btn btn-danger">Huỷ</button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    refundOrder: state.refundOrder
});

export default connect(mapStateToProps, { acceptRefund }) (Modal_edit);
