import React, { Component, Fragment, useRef } from "react";
import { Route, Redirect, Link } from "react-router-dom";
import { connect } from 'react-redux';
import { updateSP, addSP } from '../../../../actions/SupportActions';
// import { Editor } from "react-draft-wysiwyg";
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Swal from 'sweetalert2';
import SelectSearch from 'react-select-search';

class Add extends Component {
    state = {
		
    }
	constructor(props) {
		super(props);

		this.state = {
			file: ""
		};

		this.onChangeFile = this.onChangeFile.bind(this);
	}
	
    componentDidMount(){
		
    }
	
	onChange = (e) => {
		const { taotientrinh } = this.props.Support;
		
		var new_taotientrinh = {
			...taotientrinh,
			[e.target.name]: e.target.value
		};
		
		this.props.updateSP(new_taotientrinh);
	}
	
	onClick = (e) => {
		this.setState({
			file: ""
		});
		
		const { taotientrinh } = this.props.Support;
		
		var new_taotientrinh = {
			...taotientrinh,
			file: ""
		};
		
		this.props.updateSP(new_taotientrinh);
	}
	
	onClickCreate = (e) => {
		const { taotientrinh } = this.props.Support;
		Swal.fire({
			title: 'Bạn có chắc?',
			html: "Bạn có chắc chắn muốn gửi hỗ trợ này?",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Đồng ý',
			cancelButtonText: 'Không'
		}).then((result) => {
			if (result.value) {
				this.props.addSP(taotientrinh);
			}
		});
	}

	onChangeFile(e) {
		this.setState({
			file: URL.createObjectURL(e.target.files[0])
		});
		
		const { taotientrinh } = this.props.Support;
		
		var new_taotientrinh = {
			...taotientrinh,
			file: e.target.files[0]
		};
		
		this.props.updateSP(new_taotientrinh);
	}
	
	handleChange = selectedOption => {
		const { taotientrinh } = this.props.Support;
		
		var new_taotientrinh = {
			...taotientrinh,
			"vande": selectedOption
		};
		
		this.props.updateSP(new_taotientrinh);
	};
	
	option_proxy = () => {
		var ar_option_vande = [{
			"name": "Nạp tiền",
			"value": "Nạp tiền",
		},{
			"name": "Facebook buff like",
			"value": "Facebook buff like",
		},{
			"name": "Facebook buff like comment",
			"value": "Facebook buff like comment",
		},{
			"name": "Facebook buff mắt (livestream)",
			"value": "Facebook buff mắt (livestream)",
		},{
			"name": "Facebook buff comment",
			"value": "Facebook buff comment",
		},{
			"name": "Facebook buff sub speed",
			"value": "Facebook buff sub speed",
		},{
			"name": "Facebook buff sub đề xuất",
			"value": "Facebook buff sub đề xuất",
		},{
			"name": "Facebook buff like page",
			"value": "Facebook buff like page",
		},{
			"name": "Facebook buff đánh giá & checkin page",
			"value": "Facebook buff đánh giá & checkin page",
		},{
			"name": "Facebook buff share profile",
			"value": "Facebook buff share profile",
		},{
			"name": "Facebook buff share group",
			"value": "Facebook buff share group",
		},{
			"name": "Facebook buff member group",
			"value": "Facebook buff member group",
		},{
			"name": "Facebook buff view video",
			"value": "Facebook buff view video",
		},{
			"name": "Facebook buff view story",
			"value": "Facebook buff view story",
		},{
			"name": "Facebook buff like, sub, cmt SALE",
			"value": "Facebook buff like, sub, cmt SALE",
		},{
			"name": "Facebook vip like clone",
			"value": "Facebook vip like clone",
		},{
			"name": "Facebook vip cmt pro",
			"value": "Facebook vip cmt pro",
		},{
			"name": "Facebook vip like pro",
			"value": "Facebook vip like pro",
		},{
			"name": "Facebook vip like group",
			"value": "Facebook vip like group",
		},{
			"name": "Facebook vip comment clone",
			"value": "Facebook vip comment clone",
		},{
			"name": "Facebook vip view",
			"value": "Facebook vip view",
		},{
			"name": "Facebook vip livestream",
			"value": "Facebook vip livestream",
		},{
			"name": "Facebook vip livestream new",
			"value": "Facebook vip livestream new",
		},{
			"name": "Facebook lọc bạn bè",
			"value": "Facebook lọc bạn bè",
		},{
			"name": "Facebook chọc bạn bè",
			"value": "Facebook chọc bạn bè",
		},{
			"name": "Facebook đổi tên fanpage",
			"value": "Facebook đổi tên fanpage",
		},{
			"name": "Facebook mua bán clone",
			"value": "Facebook mua bán clone",
		},{
			"name": "Facebook buff like sub tây",
			"value": "Facebook buff like sub tây",
		},{
			"name": "Facebook bot love",
			"value": "Facebook bot love",
		},{
			"name": "Facebook bot love story",
			"value": "Facebook bot love story",
		},{
			"name": "Facebook bot cmt",
			"value": "Facebook bot cmt",
		},{
			"name": "Facebook bot reply inbox",
			"value": "Facebook bot reply inbox",
		},{
			"name": "Facebook bot invite livestream",
			"value": "Facebook bot invite livestream",
		},{
			"name": "Mua proxy",
			"value": "Mua proxy",
		},{
			"name": "Get cookie facebook",
			"value": "Get cookie facebook",
		},{
			"name": "Facebook ad breaks buff 600k giờ xem",
			"value": "Facebook ad breaks buff 600k giờ xem",
		},{
			"name": "Facebook ad breaks buff 15k tương tác",
			"value": "Facebook ad breaks buff 15k tương tác",
		},{
			"name": "Facebook ad breaks buff view đề xuất",
			"value": "Facebook ad breaks buff view đề xuất",
		},{
			"name": "Instagram buff like",
			"value": "Instagram buff like",
		},{
			"name": "Instagram buff sub",
			"value": "Instagram buff sub",
		},{
			"name": "Instagram buff cmt",
			"value": "Instagram buff cmt",
		},{
			"name": "Instagram buff view",
			"value": "Instagram buff view",
		},{
			"name": "Instagram vip like",
			"value": "Instagram vip like",
		},{
			"name": "Youtube buff view",
			"value": "Youtube buff view",
		},{
			"name": "Youtube buff like",
			"value": "Youtube buff like",
		},{
			"name": "Youtube buff cmt",
			"value": "Youtube buff cmt",
		},{
			"name": "Youtube buff sub",
			"value": "Youtube buff sub",
		},{
			"name": "Youtube buff 4k giờ xem",
			"value": "Youtube buff 4k giờ xem",
		},{
			"name": "Youtube đánh gậy",
			"value": "Youtube đánh gậy",
		},{
			"name": "Tiktok buff like",
			"value": "Tiktok buff like",
		},{
			"name": "Tiktok buff cmt",
			"value": "Tiktok buff cmt",
		},{
			"name": "Tiktok buff follow",
			"value": "Tiktok buff follow",
		},{
			"name": "Tiktok buff view",
			"value": "Tiktok buff view",
		},{
			"name": "Tiktok buff share",
			"value": "Tiktok buff share",
		},{
			"name": "Shopee buff sub",
			"value": "Shopee buff sub",
		},{
			"name": "Shopee buff seeding livestream",
			"value": "Shopee buff seeding livestream",
		},{
			"name": "Shopee buff vip livestream",
			"value": "Shopee buff vip livestream",
		},{
			"name": "Shopee buff tim",
			"value": "Shopee buff tim",
		},{
			"name": "Google map review",
			"value": "Google map review",
		},{
			"name": "Telegram buff member group",
			"value": "Telegram buff member group",
		},{
			"name": "Twitter buff like",
			"value": "Twitter buff like",
		},{
			"name": "Twitter buff follow",
			"value": "Twitter buff follow",
		},{
			"name": "Hỗ trợ nhắn tin",
			"value": "Hỗ trợ nhắn tin",
		},{
			"name": "Hỗ trợ cuộc gọi",
			"value": "Hỗ trợ cuộc gọi",
		},{
			"name": "Hoàn tiền",
			"value": "Hoàn tiền",
		},{
			"name": "Khác",
			"value": "Khác",
		}];
		return ar_option_vande;
	}
	
    render() {
		var { vande, tieude, idbv, mota } = this.props.Support.taotientrinh;
		var { selectedOption } = this.state;
        return (
            <Fragment>
                <div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">
						Vấn đề cần hỗ trợ:
					</label>
					<div className="col-sm-8">
						{/* <select className="form-control custom-select select-light" id="vande" name="vande" onChange={this.onChange} value={vande} >
							<option value="zxczxc">zxczxc</option>
							<option value="dfgdfgd">dfgdfgd</option>
							<option value="eretet">eretet</option>
							<option value="erhehr">erhehr</option>
						</select> */}
						<SelectSearch options={this.option_proxy()} value={selectedOption} search placeholder="Chọn vấn đề cần hỗ trợ..." id="vande" name="vande" onChange={this.handleChange} />
					</div>
				</div>
                <div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">
						Tiêu đề hỗ trợ:
					</label>
					<div className="col-sm-8">
						<input type="text" className="form-control" id="tieude" name="tieude" placeholder="Nhập tiêu đề bạn muốn hỗ trợ" onChange={this.onChange} value={tieude} />
					</div>
				</div>
                <div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">
						ID bài viết nếu có:
					</label>
					<div className="col-sm-8">
						<input type="text" className="form-control" id="idbv" name="idbv" placeholder="ID bài viết nếu có" onChange={this.onChange} value={idbv} />
					</div>
				</div>
                <div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">
						Mô tả chi tiết:
					</label>
					<div className="col-sm-8">
						<textarea rows="10" onChange={this.onChange} placeholder="Nhập mô tả chi tiết vấn đề đang gặp phải" className="form-control input-gray" name="mota" defaultValue={mota}></textarea>
					</div>
				</div>
                <div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">
						Thêm hình ảnh mô tả<span className="text-danger">{(this.state.file !== "" ? ' (Click vào ảnh để xóa ảnh)' : '')}</span>:
					</label>
					<div className="col-sm-8">
						<div className="form-group row">
							<div className="col-sm-9">
								<img src={this.state.file} style={{"maxWidth": "300px"}} onClick={this.onClick} />
							</div>
							<div className="col-sm-3">
								<input type="file" onChange={this.onChangeFile} style={{ display: "none" }} ref="fileInput" />
								<button type="button" className="btn btn-hero-secondary btn-rounded btn-block" onClick={() => this.refs.fileInput.click()} >
									<i className="fas fa-cloud-upload-alt text-white"></i> Tải ảnh lên
								</button>
							</div>
						</div>
					</div>
				</div>
                <div className="form-group row">
					<div className="col-md-6">
						<div className='form-group float-right w-100'>
							<button type="button" className="btn btn-hero-primary btn-rounded btn-block" onClick={this.onClickCreate} >
	                            Gửi hỗ trợ
	                        </button>
                        </div>
					</div>
					<div className="col-md-6">
						<div className='form-group float-right w-100'>
							<Link to="/support" className="btn btn-hero-danger btn-rounded btn-block" >
								Hủy bỏ
							</Link>
                        </div>
					</div>
				</div>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    adminpage: state.adminpage,
    Support: state.Support,
});

export default connect(mapStateToProps, { updateSP, addSP }) (Add);
