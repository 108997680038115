import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import { CreateBot, CheckCookie } from '../../../actions/chocbanbeAction';
import store from '../../../store';
import Swal from 'sweetalert2';
import parse from 'html-react-parser';
import ServicesDescription from "../ServicesDescription";
import { isMobile } from 'react-device-detect';

class CreateBotChoc extends Component {
    state = {
        cookiefb: '',
        dataform: {
            idfb: '',
            namefb: '',
            dtsg: '',
            cookie: ''
        },
		showDescription: isMobile? false : true
    }

    checkCookie(e){
        let cookiefb = e.target.value;
        let fun_check = this.props.CheckCookie(cookiefb);

        let ToastNotice = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        });

        if (fun_check !== false) {
            store.dispatch({
                type: 'LOADING_DATA_FB_CHOCBANBE',
                payload: true
            });

            fun_check.then(res => {
                store.dispatch({
                    type: 'LOADING_DATA_FB_CHOCBANBE',
                    payload: false
                });

                if (res.data.status === 200) {
                    let data_res = res.data.data;
                    this.setState({
                        cookiefb: cookiefb,
                        dataform: {
                            idfb: data_res.uid,
                            namefb: data_res.name,
                            dtsg: data_res.dtsg,
                            cookie: cookiefb
                        }
                    });

                    ToastNotice.fire({
                        icon: 'success',
                        title: 'Lấy thông tin cookie thành công!'
                    });
                } else {
                    ToastNotice.fire({
                        icon: 'error',
                        title: 'không lấy được thông tin cookie!'
                    });
                    this.resetData();
                }
            })
        } else {
            this.setState({
                cookiefb: cookiefb,
                dataform: {
                    idfb: '',
                    namefb: '',
                    dtsg: '',
                    cookie: ''
                }
            });
        }
    }

    resetData = () => {
        this.setState({
            cookiefb: '',
            dataform: {
                idfb: '',
                namefb: '',
                dtsg: '',
                cookie: ''
            }
        });
    }

	onClickShowDescription = (data) => {
		this.setState({
			showDescription: data
		});
	}
	
    render() {
		var token_avt = {};
		try {
			if (localStorage.getItem("token_avt") != null) {
				token_avt = JSON.parse(localStorage.getItem("token_avt"))[0].token_avt;
			} else {
				token_avt = this.props.auth.token_avt[0].token_avt;
			}
		} catch(e) {
			token_avt = localStorage.getItem("token_avt");
		}
        const {cookiefb,dataform} = this.state;
		
		var listDataServicesInfo = {};
		try {
			if (localStorage.getItem("listDataServicesInfo") != null) {
				listDataServicesInfo = JSON.parse(localStorage.getItem("listDataServicesInfo"));
			} else {
				listDataServicesInfo = this.props.adminpage.listDataServicesInfo;
			}
		} catch(e) {
			listDataServicesInfo = {};
		}
		var html_haydoctranhmattien = '';
		var html_haydoctranhmattien_chinh = '';
		var html_haydoctranhmattien_sv = '';
		var html_chocbanbe = '';
		if (typeof(listDataServicesInfo.facebook_vip_chocbanbe_tab) !== 'undefined') {
			html_haydoctranhmattien = parse(listDataServicesInfo.facebook_vip_chocbanbe_tab.ghichu);
			html_haydoctranhmattien_chinh = parse(listDataServicesInfo.facebook_vip_chocbanbe_tab.ghichu_chinh);
		}
		if (typeof(listDataServicesInfo.facebook_vip_chocbanbe) !== 'undefined') {
			html_chocbanbe = parse(listDataServicesInfo.facebook_vip_chocbanbe.ghichu_sv);
			if ("1" === "1") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.facebook_vip_chocbanbe.ghichu);
			}
		}
		
        return (
            <Fragment>
				<div className="col-md-12">
					<div className="form-group focused">
						<label className="control-label">Cookie</label>
						<input className="form-control" placeholder="Nhập cookie" value={cookiefb} onChange={(e)=>{this.checkCookie(e)}} />
						{
							dataform.idfb !== '' ?
								<div className="mt-3">
									<img src={"https://graph.facebook.com/"+dataform.idfb+"/picture?height=100&amp;width=100&access_token="+token_avt} className="avatar-user" />
									{' ' + dataform.namefb} ({dataform.idfb})
								</div>
							: ''
						}
					</div>
				</div>
				<div className="col-md-12">
					<div className="form-group focused">
						<label className="control-label">Thời Gian Sử Dụng</label>
						<select className="form-control">
							<option value="1">1 Lần</option>
						</select>
					</div>
				</div>

				{/*<div className="col-md-6">
					<div className="block block-rounded block-bordered">
						<div className="block-header border-bottom">
							<h3 className="block-title">
								<i className="fa fa-desktop" aria-hidden="true"></i> Chi Tiết Thanh Toán
							</h3>
						</div>
						<div className="block-content pb-3">
							<ul className="list-group">
								<li className="list-group-item">Tên Người Dùng:<span className="badge bg-info text-light float-right mt-1" >{dataform.namefb !== '' ? dataform.namefb : 'null'}</span></li>
								<li className="list-group-item">ID Người Dùng:<span className="badge bg-info text-light float-right mt-1" >{dataform.idfb !== '' ? dataform.idfb : 'null'}</span></li>
								<li className="list-group-item">Thời Gian Sử Dụng:<span className="badge bg-info text-light float-right mt-1" >{dataform.idfb !== '' ? '1 tháng' : 'null'}</span></li>
								<li className="list-group-item">Thanh Toán<span className="badge bg-info text-light float-right mt-1">{dataform.idfb !== '' ? '2000 coin' : 'null'}</span></li>
							</ul>
						</div>
					</div>
				</div>

				<div className="col-md-6">
					<ul className="mt-4">
						<li>
							<p className="font-bold font-underline text-danger">Lưu ý:</p>
							<ul className="p-0">
								<li>
									<p>Chúng Tôi không hoàn lại gói VIP.</p>
								</li>
								<li>
									<p>Khi nick cài đặt bị checkpoint bạn vào danh sách và update lại cookie để chạy lại (trong 6h từ lúc cài đặt) .</p>
								</li>
								<li>
									<p>Cám ơn đã sử dụng dịch vụ của chúng tôi.</p>
								</li>
							</ul>
						</li>
					</ul>
				</div>*/}

				<button type="button" className="btn badge-primary-cus btn-rounded btn-show-description-cus" onClick={(e) => {this.onClickShowDescription(!this.state.showDescription)}}>
					{
						this.state.showDescription?
							<i className="fa fa-angle-up" style={{"marginRight": "5px"}} aria-hidden="true"></i>
						:
							<i className="fa fa-angle-down" style={{"marginRight": "5px"}} aria-hidden="true"></i>
					}
					Thông tin dịch vụ
				</button>
				
				<ServicesDescription allservicesinfo={listDataServicesInfo} server={"1"} tab="facebook_vip_chocbanbe" giatien={this.props.chocbanbe.dataprice.prices_web} baohanh={0} showDescription={this.state.showDescription} />
			
				<div className="row mt-2" style={{"display": this.state.showDescription? "flex" : "none"}}>
					<div className="col-md-12 bold" style={{"marginBottom": "20px"}}>
						<div className="card card-select-cus">
							<div className="card-body card-body-select-cus">
								<p className="mb-0 mt-3 text-wrap">
									<ul>
										{html_haydoctranhmattien}
										{html_haydoctranhmattien_chinh}
										{html_haydoctranhmattien_sv}
										<li>Nếu gặp lỗi hãy nhắn tin hỗ trợ phía bên phải góc màn hình hoặc vào mục liên hệ hỗ trợ để được hỗ trợ tốt nhất!</li>
									</ul>
								</p>
								<span className="badge badge-cus position-absolute-cus badge-top-left-cus badge-primary-cus badge-pill-cus"> HÃY ĐỌC ĐỂ TRÁNH MẤT TIỀN KHI SỬ DỤNG! </span>
							</div>
						</div>
					</div>
				</div>
				<div className="col-12 text-center bold mt-4">
					<div className="card card-info">
						<div className="card-body">
							<h5 className="font-bold">Gói chọc bạn bè</h5>
							<h6 className="mb-0">Bạn sẽ mất <span className="green bold">{Math.round((this.props.chocbanbe.dataprice.prices_web + Number.EPSILON) * 100) / 100} coin</span></h6>
						</div>
					</div>
				</div>

				<div className="col-md-12 mt-4">
					<div className='form-group float-right w-100'>
						<button onClick={() => {this.props.CreateBot(dataform, this.resetData)}} type="button" className="btn btn-hero-primary btn-rounded btn-block">
							Tạo tiến trình
						</button>
					</div>
				</div>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    chocbanbe: state.chocbanbe,
});

export default connect(mapStateToProps, {CreateBot,CheckCookie}) (CreateBotChoc);
