import React from 'react';
import axios from 'axios';
import {
	LOADING_GCF,
	UPDATE_COOKIE_GCF,
	LOAD_PROXY_GCF
} from "../actions/types";
import Swal from 'sweetalert2';

export const loadProxyGCF = () => (dispatch, getState) => {
	axios.get('/api/get-cookie/loadproxy', tokenConfig(getState))
		.then(res => {
			if (res.data.status === 200) {
				dispatch({
					type: LOAD_PROXY_GCF,
					payload: res.data.data
				});
			}
		});
};

export const dangnhapGCF = (body) => (dispatch, getState) => {
	dispatch({
		type: LOADING_GCF,
		payload: true
	});
	axios.post('/api/get-cookie/dangnhap', body, tokenConfig(getState))
		.then(res => {
			dispatch({
				type: LOADING_GCF,
				payload: false
			});
			if (res.data.status === 200) {
				dispatch({
					type: UPDATE_COOKIE_GCF,
					payload: res.data
				});
			}
			if (res.data.status === 400) {
				Swal.fire({
					icon: 'error',
					title: 'Lỗi',
					text: res.data.msg,
				});
			}
		});
};

export const tokenConfig = getState => {
	// Get token from localhost
	const token = getState().auth.token;
	
	// Headers
	const config = {
		headers: {
			"Content-type": "application/json"
		}
	};
	
	// If token, add to headers
	if (token) {
		config.headers['ht-token'] = token;
	}
	
	return config;
};