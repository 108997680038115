import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Swal from 'sweetalert2';
import copy from 'copy-text-to-clipboard';

class Account extends Component {
	state = {
		loaibotrunglap: true,
		noidung: "email=nukeal@gmail.com\npass=1243\n\nemail=haivu@gmail.com\npass=elooslge\n\n\nemail=sequiz@gmail.com\npass=suka\n\nemail=entry@gmail.com\npass=\n\nemail=\npass=",
		text_placeholder: "email=nukeal@gmail.com\npass=1243\n\nemail=haivu@gmail.com\npass=elooslge\n\n\nemail=sequiz@gmail.com\npass=suka\n\nemail=entry@gmail.com\npass=\n\nemail=\npass=",
		ngancachboikytu: "|",
		ketqua: "",
	}
	onChangeTrungLap = (e) =>{
		this.setState({
			[e.target.name]: !this.state.loaibotrunglap
		})
	}
	onClick = (e) =>{
		this.setState({
			[e.target.name]: e.target.value
		})
	}
	onChange = (e) =>{
		this.setState({
			[e.target.name]: e.target.value
		})
	}
	onTrigger = (e) => {
		var { loaibotrunglap, noidung, ngancachboikytu } = this.state;
		noidung = noidung.trim();
		noidung = noidung
			.split("\n")
			.filter(item => item)
			.join("\n");
			
		var final_noidung_tmp = noidung.split("\n");
		var final_noidung_tmp_2 = [];
		var final_noidung = [];
		final_noidung_tmp.forEach(function(v, i) {
			if(i % 2 == 0){
				v = v.replace("email=", "");
			}else{
				v = v.replace("pass=", "");
			}
			final_noidung_tmp_2.push(v);
		});
		var str = '';
		for (var i = 0; i < final_noidung_tmp_2.length; i++) {
			var cur_string = final_noidung_tmp_2[i].toString().trim();
			if (i%2 == 0) {
				if (cur_string && final_noidung_tmp_2[i+1]) {
					if (str) {
						str += '\n' + cur_string + ngancachboikytu;		
					} else {
						str += cur_string + ngancachboikytu;	
					}
				}
			} else {
				if (cur_string && final_noidung_tmp_2[i-1]) {
					str += cur_string;
				}
			}
		}
		final_noidung = str;
		if (loaibotrunglap) {
			final_noidung = final_noidung
				.split("\n")
				.filter((item, i, allItems) => {
					return i === allItems.indexOf(item);
				})
				.join("\n");
		}
		noidung = final_noidung;
		
		this.setState({
			ketqua: noidung
		});
	}
	
	render() {
		return (
			<Fragment>
				<div className="form-group row">
					<label className="col-sm-2 col-form-label edittext-label-right" htmlFor="">Loại bỏ trùng lặp?</label>
					<div className="col-sm-10" style={{"display": "flex", "alignItems": "center"}}>
						<div className="custom-control custom-switch mb-1">
							<input onChange={(e) => {this.onChangeTrungLap(e)}} type="checkbox" className="custom-control-input" id="loaibotrunglap" name="loaibotrunglap" checked={this.state.loaibotrunglap} />
							<label className="custom-control-label edittext-custom-control-label" htmlFor="loaibotrunglap" ></label>
						</div>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-2 col-form-label edittext-label-right" htmlFor="">Nội dung</label>
					<div className="col-sm-10">
						<textarea rows="5" placeholder={this.state.text_placeholder} className="form-control input-gray edittext-textarea" name="noidung" onChange={this.onChange} value={this.state.noidung} ></textarea>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-2 col-form-label edittext-label-right" htmlFor="">Ngăn cách bởi ký tự</label>
					<div className="col-sm-10">
						<input type="text" className="form-control" name="ngancachboikytu" value={this.state.ngancachboikytu} onChange={this.onChange} />
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-2 col-form-label edittext-label-right" htmlFor=""></label>
					<div className="col-sm-10">
						<button type="button" className="btn btn-rounded btn-hero-primary edittext-btn" onClick={this.onTrigger} >Trigger</button>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-2 col-form-label edittext-label-right" htmlFor=""></label>
					<div className="col-sm-10">
						<textarea rows="5" placeholder="" className="form-control input-gray edittext-textarea" name="ketqua" defaultValue={this.state.ketqua} ></textarea>
					</div>
				</div>
			</Fragment>
		);
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
});
const mapDispatchToProps = {  };

export default connect(mapStateToProps, mapDispatchToProps)(Account);
