import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { listMBC } from '../../../actions/MuabancloneActions';
import Moment from 'react-moment';
import Swal from 'sweetalert2';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
const { SearchBar } = Search;

class lichsu extends Component {
	static propTypes =  {
		listMBC: PropTypes.func.isRequired,
	};
	
	componentDidMount() {
		this.props.listMBC();
	}
	
    getNameSpecies = (cell) => {
    	let name = 'Không có gói';
		const { listloaiclone } = this.props.Muabanclone;
    	listloaiclone.forEach(function(v, i){
    		if (cell === v.id) {
    			name = v.name;
    		}
    	});
    	return name;
    }
	
	render() {
		const { listclone } = this.props.Muabanclone;
		const columns = [{
			dataField: 'uid',
			text: 'UID',
			sort: true,
		},{
			dataField: 'password',
			text: 'Mật khẩu',
			sort: true,
		}, {
			dataField: 'id_loai',
			text: 'Loại gói',
			sort: true,
			formatter: (cell, row) => 
				<Fragment>
					{ cell !== 0 ?
						this.getNameSpecies(cell)
						: 'Không có gói'
					}
				</Fragment>
		}, {
			dataField: 'date_sale',
			text: 'Ngày mua',
			sort: true,
			formatter: (cell, row) => 
				<Fragment>
					<Moment format="DD-MM-YYYY">{cell*1000}</Moment>
				</Fragment>
		}];
		return (
			<Fragment>
				<ToolkitProvider keyField="id" data={ listclone } columns={ columns } search >
					{
						props => (
							<div>
								<SearchBar { ...props.searchProps } />
								<BootstrapTable { ...props.baseProps } pagination={ paginationFactory() } wrapperClasses="table-responsive" striped hover condensed />
							</div>
						)
					}
				</ToolkitProvider>
				<div>
					{/*<BootstrapTable keyField='id' data={ listclone } columns={ columns } pagination={ paginationFactory() } wrapperClasses="table-responsive" striped hover condensed />*/}
				</div>
			</Fragment>
		)
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	Muabanclone: state.Muabanclone,
});
const mapDispatchToProps = { listMBC }

export default connect(mapStateToProps, mapDispatchToProps)(lichsu);