const initialState = {
	loadingblock: false,
	listData: [],
	datadetailselect: [],
	muaproxy_global_mo_dong: 1,
	muaproxy_global_ipv4_mo_dong: 1,
	muaproxy_vn_mo_dong: 1,
};

export default function(state = initialState, action) {
  switch (action.type) {
  	case 'LOADING_TAG_BUY_PROXY':
    	return {
			...state,
			loadingblock: action.payload
		};
    case 'LIST_DATA_PROXY':
    	return {
			...state,
			listData: action.payload
		};
	case 'DATA_DETAIL_BUY_PROXY_SELECT':
    	return {
			...state,
			datadetailselect: action.payload
		};
	case 'CONFIG_MO_DONG_PROXY':
		return {
			...state,
			muaproxy_global_mo_dong: action.payload.muaproxy_global_mo_dong,
			muaproxy_global_ipv4_mo_dong: action.payload.muaproxy_global_ipv4_mo_dong,
			muaproxy_vn_mo_dong: action.payload.muaproxy_vn_mo_dong,
		};
    default:
      return state;
  }
}
