import React, { Component, Fragment } from 'react';
import {
  BrowserRouter as Router,
  // Switch,
  // Route,
  Link
} from "react-router-dom";
import {
	// Button,
	// Modal,
	// ModalHeader,
	// ModalBody,
	// Form,
	// FormGroup,
	// Label,
	// Input,
	// NavLink,
	Alert
} from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { forgotpassword, resetpassword, checkTokenReset } from '../../actions/authActions';
import { clearErrors } from '../../actions/errorActions';
import parse from 'html-react-parser';

class ForgotpasswordModal extends Component {
	state = {
		// modal: false,
		username: '',
		email: '',
		password: '',
		repeatpassword: '',
		msg: null,
		msg2: null,
		checktoken: 1,
	}
	
	static propTypes =  {
		auth: PropTypes.object.isRequired,
		error: PropTypes.object.isRequired,
		register: PropTypes.func.isRequired,
		clearErrors: PropTypes.func.isRequired
	}
	
	componentDidMount() {
		if (this.props.action_reset === '/resetpassword') {
			var search_param = new URLSearchParams(window.location.search);
			var token = search_param.get('token');
			if (token == null) {
				token = '';
			}
			var data = {
				token,
			};
			this.props.checkTokenReset(data);
		}
	}
	
	componentDidUpdate(prevProps) {
		const { error } = this.props;
		if (error !== prevProps.error) {
			// Check for register error
			if (error.id === 'FORGOTPASSWORD_SEND_FAIL' || error.id === 'RESETPASSWORD_SEND_FAIL') {
				this.setState({ msg: error.msg.msg, msg2: null });
			} else if (error.id === 'FORGOTPASSWORD_SEND_SUCCESS' || error.id === 'RESETPASSWORD_SEND_SUCCESS') {
				this.setState({ msg: null, msg2: error.msg.msg });
			} else if (error.id === 'TOKEN_RESET_SUCCESS') {
				this.setState({ checktoken: 1 });
			} else if (error.id === 'TOKEN_RESET_FAIL') {
				this.setState({ checktoken: 2 });
			} else {
				this.setState({ msg: null, msg2: null });
			}
		}
	}
	
	clearAlert = () => {
		// Clear errors
		this.props.clearErrors();
	}
	
	onChange = e => {
		this.setState({ [e.target.name]: e.target.value });
	};
	
	onSubmit = e => {
		e.preventDefault();
		
		const { username, email, password, repeatpassword } = this.state;
		var search_param = new URLSearchParams(window.location.search);
		var token = search_param.get('token');
		if (token == null) {
			token = '';
		}
		
		if (this.props.action_reset === '/resetpassword') {
			var newUser = {
				token,
				password,
				repeatpassword,
			};
			this.props.resetpassword(newUser);
		} else {
			var newUser = {
				username,
				email,
			};
			this.props.forgotpassword(newUser);
		}
	};
	
	render() {
		let loading = this.props.auth.loading;
		var domainSite = window.location.hostname;
		var search_param = new URLSearchParams(window.location.search);
		var token = search_param.get('token');
		if (token == null) {
			token = '';
		}
		var html_form = "";
		if ((this.props.action_reset === '/resetpassword' && token.trim() == '') || (this.props.action_reset === '/resetpassword' && this.state.checktoken == 2)) {
			html_form = (
				<Fragment>
					<div className="alert alert-danger d-flex align-items-center justify-content-between" role="alert" >
						<div className="flex-fill mr-3">
							<p className="mb-0">Link đặt lại mật khẩu đã hết hạn! #tk</p>
						</div>
						<div className="flex-00-auto">
							<i className="fa fa-fw fa-exclamation-circle"></i>
						</div>
					</div>
				</Fragment>
			)
		} else {
			html_form = (
				<Fragment>
					<div className={'card' + (this.props.auth.loading? ' block block-bordered shadow block-mode-loading' : '')}>
						<div className="card-header card-header-login pt-4 pb-4 text-center bg-primary bt-bg-navbar">
							<a className="link-fx text-white font-w700 font-size-h3" href="index.html" >
							  <span className="text-white text-uppercase">{domainSite.indexOf('tools1s.com') < 0 ? domainSite.slice(0, domainSite.lastIndexOf('.')) : domainSite.slice(0, domainSite.lastIndexOf('.')).replace('vip.', '') }</span>
							  <span className="text-white text-uppercase">{domainSite.indexOf('.') > -1 && domainSite.indexOf('tools1s.com') < 0 ? domainSite.slice(domainSite.lastIndexOf('.')) : ''}</span>
							</a>
						</div>
						<div className="position-relative card-body card-body-login bg-white" style={{ "padding": "36px" }}>
							<div className="text-center w-75 m-auto" style={{opacity: this.props.auth.loading? '.05':'1'}}>
								<h4 className="text-dark-50 text-center mt-0 font-weight-bold" style={{ "color": "#6c757d", "marginBottom": "10px", "fontSize": "1.125rem" }}>
									{
										this.props.action_reset === '/resetpassword' ?
											"Đặt lại mật khẩu"
										:
											"Quên mật khẩu"
									}
								</h4>
								<p className="text-muted mb-4" style={{ "fontSize": "0.9rem" }}>Vui lòng điền đầy đủ thông tin</p>
							</div>
							{loading ? (
								<div className="preloader">
									<div className="spinner-grow spinner-grow-sm text-primary" role="status" style={{ "marginTop": "40%" }}>
									  <span className="sr-only"></span>
									</div>
									<div className="spinner-grow spinner-grow-sm text-danger" role="status" style={{ "marginTop": "40%" }}>
									  <span className="sr-only">.</span>
									</div>
									<div className="spinner-grow spinner-grow-sm text-warning" role="status" style={{ "marginTop": "40%" }}>
									  <span className="sr-only"></span>
									</div>
								</div>
							):('')}
							{this.state.msg ? (
								<div className="alert alert-warning d-flex align-items-center justify-content-between" role="alert" >
								  <div className="flex-fill mr-3">
									<p className="mb-0">{parse(this.state.msg)}</p>
								  </div>
								  <div className="flex-00-auto">
									<i className="fa fa-fw fa-exclamation-circle"></i>
								  </div>
								</div>
							  ) : null}
							{this.state.msg2 ? (
								<div className="alert d-flex align-items-center justify-content-between" role="alert" style={{"color": "#445e27", "backgroundColor": "#e6f0db", "borderColor": "#dceacd"}} >
									<div className="flex-fill mr-3">
										<p className="mb-0">{parse(this.state.msg2)}</p>
									</div>
								</div>
							) : null}
							<form className="js-validation-signup" onSubmit={this.onSubmit} method="POST" style={{opacity: this.props.auth.loading? '.05':'1'}}>
								{
									this.props.action_reset === '/resetpassword' ?
										<Fragment>
											<div className="form-group">
												<label htmlFor="password" className="">Mật khẩu</label>
												{/*<a href="/account/forget-password" className="text-muted float-right"><small>Forgot your password?</small></a>*/}
												<input name="password" placeholder="" required="" id="password" type="password" className="is-untouched is-pristine av-valid form-control" onChange={this.onChange} />
											</div>
											<div className="form-group">
												<label htmlFor="password" className="">Nhập lại mật khẩu</label>
												{/*<a href="/account/forget-password" className="text-muted float-right"><small>Forgot your password?</small></a>*/}
												<input name="repeatpassword" placeholder="" required="" id="repeatpassword" type="password" className="is-untouched is-pristine av-valid form-control" onChange={this.onChange} />
											</div>
											
											<br/>
											<div className="form-group">
												<button type="submit" className="btn btn-success" style={{ "boxShadow": "0px 2px 6px 0px rgba(10, 207, 151, 0.5)", "color": "#fff", "backgroundColor": "#0acf97", "borderColor": "#0acf97" }}>
												  <span>
													Đặt lại mật khẩu
												  </span>
												</button>
											</div>
										</Fragment>
									:
										<Fragment>
											<div className="form-group">
												<label htmlFor="username" className="">Tên tài khoản</label>
												<input name="username" placeholder="" required="" id="username" type="text" className="is-untouched is-pristine av-valid form-control" onChange={this.onChange} />
											</div>
											<div className="form-group">
												<label htmlFor="email" className="">Email</label>
												<input name="email" placeholder="" required="" id="email" type="email" className="is-untouched is-pristine av-valid form-control" onChange={this.onChange} />
											</div>
											
											<br/>
											<div className="form-group">
												<button type="submit" className="btn btn-success" style={{ "boxShadow": "0px 2px 6px 0px rgba(10, 207, 151, 0.5)", "color": "#fff", "backgroundColor": "#0acf97", "borderColor": "#0acf97" }}>
												  <span>
													Gửi
												  </span>
												</button>
											</div>
										</Fragment>
								}
							</form>
						</div>
					</div>
				</Fragment>
			)
		}
		return (
			<Router>
			<Fragment>
				<main id="main-container" style={{"height": "100%"}}>
					<div className="bg-image" style={{"height": "100%"}}>
						<div className="row no-gutters justify-content-center">
							<div className="col-sm-8 col-md-6 col-xl-4 align-items-center p-2 px-sm-0" style={{ "top": "100px" }}>
								<div className="block block-transparent block-rounded w-100 mb-0 overflow-hidden">
									<div className="justify-content-center row">
										<div className="col-lg-9-custom-login col-lg-9">
											<h2 style={{"textAlign":"center", "color": "#"+this.props.auth.config_website.title_color_dndk}}>{this.props.auth.config_website.title_dndk}</h2>
											<br/><br/>
											{html_form}
										</div>
									</div>
									<div className="mt-4 row">
										<div className="col-12 text-center col">
											<p className="text-muted">
												Bạn đã có tài khoản?
												<Link to="/" className="text-danger ml-1 font-bold" onClick={this.props.action_login} style={{"fontSize": "20px"}}>Đăng nhập</Link>
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</main>
			</Fragment>
			</Router>
		);
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	error: state.error
})

export default connect(mapStateToProps, { forgotpassword, resetpassword, checkTokenReset, clearErrors })(ForgotpasswordModal);