import React, { Component } from "react";
import Maincontainer from "./components/Maincontainer";
import { Provider } from "react-redux";
import store from "./store";
import { loadUser } from "./actions/authActions";
import { checkDaiLy } from "./actions/dailyActions";

store.dispatch(checkDaiLy());
store.dispatch(loadUser());
class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Maincontainer />
      </Provider>
    );
  }
}

export default App;
