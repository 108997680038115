import {
	LOAD_GM,
	UPDATE_GM,
	UPDATE_BSR_GETID,
	UPDATE_GM_TAB,
	UPDATE_BSR_TGT,
	LOAD_PRICE_GM,
	LIMIT_PAGI_BSR,
	TYPE_GM,
	LOADING_GM
} from "../actions/types";

const initialState = {
	"taotientrinh": {
		"lhibv": "",
		"lsct": "1",
		"gtmtt": "0",
		"ndr": "",
		"gc": "",
	},
	"changetab": 0,
	"listtientrinh": [],
	"tanggiathem": {
		"id": "",
		"lhibv": "",
		"price_per": 0,
		"price": 0,
		"quantity": 0
	},
	"price": {
		googlemapdanhgia5sao: {
			min_bh_web: 0,
			prices_web: 0,
		},
		googlemapdanhgia1sao: {
			min_bh_web: 0,
			prices_web: 0,
		},
	},
	"limit_pagi": 0,
	"type": 0,
	"loading": false,
	googlemapreview_5sao_mo_dong: 1,
	googlemapreview_1sao_mo_dong: 1,
};

export default function(state = initialState, action) {
	switch (action.type) {
		case LOAD_GM:
			return {
				...state,
				"listtientrinh": action.payload
			};
		case UPDATE_GM:
			return {
				...state,
				"taotientrinh": action.payload
			};
		case UPDATE_BSR_GETID:
			return {
				...state,
				"taotientrinh": {
					...state.taotientrinh,
					"lhibv": action.payload
				}
			};
		case UPDATE_GM_TAB:
			return {
				...state,
				"changetab": action.payload
			};
		case UPDATE_BSR_TGT:
			return {
				...state,
				"tanggiathem": {
					...state.tanggiathem,
					"id": action.payload.id,
					"lhibv": action.payload.lhibv,
					"price_per": action.payload.price_per,
					"price": action.payload.price,
				}
			};
		case LOAD_PRICE_GM:
			return {
				...state,
				"price": merDataPricePage(action.payload),
			};
		case LIMIT_PAGI_BSR:
			return {
				...state,
				"limit_pagi": action.payload
			};
		case TYPE_GM:
			return {
				...state,
				"type": action.payload
			};
		case LOADING_GM:
			return {
				...state,
				"loading": action.payload
			};
		case 'CONFIG_MO_DONG_GM':
			return {
				...state,
				googlemapreview_5sao_mo_dong: action.payload.googlemapreview_5sao_mo_dong,
				googlemapreview_1sao_mo_dong: action.payload.googlemapreview_1sao_mo_dong,
			};
		default:
		  return state;
	}
}

function merDataPricePage(data) {
	let dataNew = {
		...initialState.price
	}
	if (data.length > 0) {
		data.forEach(function(v, i){
			if (v.name_table === 'googlemapdanhgia5sao') {
				dataNew['googlemapdanhgia5sao'] = v;
				initialState.taotientrinh.gtmtt = v.prices_web;
			} else if (v.name_table === 'googlemapdanhgia1sao') {
				dataNew['googlemapdanhgia1sao'] = v;
			}
		});
	};
	return dataNew;
}
