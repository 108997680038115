import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import {
    Modal,
    ModalBody,
    ModalFooter
} from 'reactstrap';
import { UpdateItem } from '../../../actions/chocbanbeAction';

class Modal_edit extends Component {
    state = {
        cookiefb: this.props.chocbanbe.itemSelect.cookie,    
    }

    changeValueCookie = (e) => {
        this.setState({
            cookiefb: e.target.value
        });
    }

    render() {
        const {isOpenModal} = this.props;
        const {cookiefb} = this.state;
		const { loadding } = this.props.chocbanbe;

        return (
            <Fragment>
                <Modal centered={true} size="lg" toggle={(e) => {this.props.openModal()}} isOpen={isOpenModal} className={(loadding? ' block block-mode-loading' : '')} style={{"backgroundColor": "inherit", "boxShadow": "none"}}>
                    <div className="modal-header">
                        <p className="mb-0 text-dark">Sửa cookie</p>
                        <button onClick={(e) => {this.props.openModal()}} type="button" className="close">×</button>
                    </div>
                    <ModalBody style={{opacity: loadding? '.05':'1'}}>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group focused">
                                    <label className="control-label">Cookie</label>
                                    <input className="form-control" placeholder="Nhập cookie" value={cookiefb} onChange={(e)=>{this.changeValueCookie(e)}} />
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter style={{opacity: loadding? '.05':'1'}}>
                        <button onClick={() => {this.props.UpdateItem(cookiefb, this.props.chocbanbe.itemSelect.id , this.props.chocbanbe.itemSelect.id_fb, this.props.openModal)}} type="button" className="btn btn-primary">Lưu</button>
                        <button onClick={(e) => {this.props.openModal()}} type="button" className="btn btn-danger">Huỷ</button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    chocbanbe: state.chocbanbe
});

export default connect(mapStateToProps, {UpdateItem}) (Modal_edit);
