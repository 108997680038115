import {
	LOAD_YV,
	UPDATE_YV,
	UPDATE_YV_TAB,
	LOAD_PRICE_YV,
	LIMIT_PAGI_YV,
	TYPE_YV,
	LOADING_YV
} from "../actions/types";

const date7dnext = () => {
	var result = new Date();
  	result.setDate(result.getDate() + 7);
  	return result;
}

const initialState = {
	"taotientrinh": {
		"lhibv": "",
		"lsct": "1",
		"cdbh": "false",
		"cnbd": new Date(),
		"cnkt": date7dnext(),
		"slct": "10",
		"gtmtt": "0",
		"gc": "",
	},
	"changetab": 0,
	"listtientrinh": [],
	// "tanggiathem": {
		// "id": "",
		// "lhibv": "",
		// "price_per": 0,
		// "price": 0
	// },
	"price": {
		youtubeview: {
			min_bh_web: 0,
			prices_web: 0,
		},
		youtubeview_sv2: {
			min_bh_web: 0,
			prices_web: 0,
		},
		youtubeview_sv3: {
			min_bh_web: 0,
			prices_web: 0,
		},
		youtubeview_sv4: {
			min_bh_web: 0,
			prices_web: 0,
		},
		youtubeview_sv5: {
			min_bh_web: 0,
			prices_web: 0,
		},
		youtubeview_sv6: {
			min_bh_web: 0,
			prices_web: 0,
		},
		youtubeview_sv7: {
			min_bh_web: 0,
			prices_web: 0,
		},
		youtubeview_sv8: {
			min_bh_web: 0,
			prices_web: 0,
		},
		youtubeview_sv9: {
			min_bh_web: 0,
			prices_web: 0,
		},
	},
	"limit_pagi": 0,
	"type": 0,
	"loading": false,
	youtubebuffview_sv1_mo_dong: 1,
	youtubebuffview_sv2_mo_dong: 1,
	youtubebuffview_sv3_mo_dong: 1,
	youtubebuffview_sv4_mo_dong: 1,
	youtubebuffview_sv5_mo_dong: 1,
	youtubebuffview_sv6_mo_dong: 1,
	youtubebuffview_sv7_mo_dong: 1,
	youtubebuffview_sv8_mo_dong: 1,
	youtubebuffview_sv9_mo_dong: 1,
};

export default function(state = initialState, action) {
	switch (action.type) {
		case LOAD_YV:
			return {
				...state,
				"listtientrinh": action.payload
			};
		case UPDATE_YV:
			return {
				...state,
				"taotientrinh": action.payload
			}
		case UPDATE_YV_TAB:
			return {
				...state,
				"changetab": action.payload
			};
		case LOAD_PRICE_YV:
			return {
				...state,
				"price": merDataPricePage(action.payload),
			};
		case LIMIT_PAGI_YV:
			return {
				...state,
				"limit_pagi": action.payload
			};
		case TYPE_YV:
			return {
				...state,
				"type": action.payload
			};
		case LOADING_YV:
			return {
				...state,
				"loading": action.payload
			};
		case 'CONFIG_MO_DONG_YV':
			return {
				...state,
				youtubebuffview_sv1_mo_dong: action.payload.youtubebuffview_sv1_mo_dong,
				youtubebuffview_sv2_mo_dong: action.payload.youtubebuffview_sv2_mo_dong,
				youtubebuffview_sv3_mo_dong: action.payload.youtubebuffview_sv3_mo_dong,
				youtubebuffview_sv4_mo_dong: action.payload.youtubebuffview_sv4_mo_dong,
				youtubebuffview_sv5_mo_dong: action.payload.youtubebuffview_sv5_mo_dong,
				youtubebuffview_sv6_mo_dong: action.payload.youtubebuffview_sv6_mo_dong,
				youtubebuffview_sv7_mo_dong: action.payload.youtubebuffview_sv7_mo_dong,
				youtubebuffview_sv8_mo_dong: action.payload.youtubebuffview_sv8_mo_dong,
				youtubebuffview_sv9_mo_dong: action.payload.youtubebuffview_sv9_mo_dong,
			};
		default:
		  return state;
	}
}

function merDataPricePage(data) {
	let dataNew = {
		...initialState.price
	}
	if (data.length > 0) {
		data.forEach(function(v, i){
			if (v.name_table === 'youtubeview') {
				dataNew['youtubeview'] = v;
				initialState.taotientrinh.gtmtt = v.prices_web;
			} else if (v.name_table === 'youtubeview_sv2') {
				dataNew['youtubeview_sv2'] = v;
			} else if (v.name_table === 'youtubeview_sv3') {
				dataNew['youtubeview_sv3'] = v;
			} else if (v.name_table === 'youtubeview_sv4') {
				dataNew['youtubeview_sv4'] = v;
			} else if (v.name_table === 'youtubeview_sv5') {
				dataNew['youtubeview_sv5'] = v;
			} else if (v.name_table === 'youtubeview_sv6') {
				dataNew['youtubeview_sv6'] = v;
			} else if (v.name_table === 'youtubeview_sv7') {
				dataNew['youtubeview_sv7'] = v;
			} else if (v.name_table === 'youtubeview_sv8') {
				dataNew['youtubeview_sv8'] = v;
			} else if (v.name_table === 'youtubeview_sv9') {
				dataNew['youtubeview_sv9'] = v;
			}
		});
	};
	return dataNew;
}
