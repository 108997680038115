import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import store from '../../../store';

import Swal from 'sweetalert2';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { addFLBBKTT, updateFLBBKTT, CheckCookie, loadPriceFLBBKTT, searchIdFb } from '../../../actions/FacebooklocbanbekhongtuongtacActions';
import { LOADING_FLBBKTT } from "../../../actions/types";
import parse from 'html-react-parser';
import ServicesDescription from "../ServicesDescription";
import { isMobile } from 'react-device-detect';

const date7dnext = () => {
	var result = new Date();
  	result.setDate(result.getDate() + 7);
  	return result;
}

class taotientrinh extends Component {
	static propTypes =  {
		addFLBBKTT: PropTypes.func.isRequired,
		updateFLBBKTT: PropTypes.func.isRequired,
		CheckCookie: PropTypes.func.isRequired,
	};
	
	state = {
		showDescription: isMobile? false : true
	}
	
	componentDidMount() {
		// this.props.loadPriceFLBBKTT();
	};
	
	option_tgsd = () => {
		let ar_option_tgsd = [];
		ar_option_tgsd.push(
			<option value="0.5" key="0.5">
				15 Ngày
			</option>
		);
		for (let i = 1; i <= 6; i++) {
			ar_option_tgsd.push(
				<option value={i} key={i}>
					{i} Tháng
				</option>
			)
		}
		return ar_option_tgsd;
	}
	
	// onChange = (e) => {
		// const { taotientrinh } = this.props.Facebooklocbanbekhongtuongtac;
		// var new_taotientrinh = {
			// ...taotientrinh,
			// [e.target.name]: e.target.value
		// };
		// var value = e.target.value;
		// store.dispatch({
			// type: LOADING_FLBBKTT,
			// payload: true
		// });
		// var fun_check = this.props.CheckCookie(e.target.value);
		// if (fun_check !== false) {
			
            // fun_check.then(res => {
                // if (res.data.status === 200) {
                    // let data_res = res.data.data;
					// var new_taotientrinh = {
						// ...taotientrinh,
						// "idfb": data_res.uid,
						// "usernamefb": data_res.name,
						// "cookie": value,
						// "dtsg": data_res.dtsg,
					// };
					// this.props.updateFLBBKTT(new_taotientrinh);
					// store.dispatch({
						// type: LOADING_FLBBKTT,
						// payload: false
					// });
					// Swal.fire({
						// icon: 'success',
						// html: 'Lấy thông tin thành công'
					// });
                // } else {
					// if (taotientrinh.cookie === '') {
						// store.dispatch({
							// type: LOADING_FLBBKTT,
							// payload: false
						// });
						// Swal.fire({
							// icon: 'error',
							// title: 'Lỗi',
							// text: 'Cookie die hoặc không hợp lệ #2',
						// });
						// return false;
					// }
				// }
            // })
        // } else {
			// if (taotientrinh.cookie === '') {
				// store.dispatch({
					// type: LOADING_FLBBKTT,
					// payload: false
				// });
				// Swal.fire({
					// icon: 'error',
					// title: 'Lỗi',
					// text: 'Cookie die hoặc không hợp lệ #1',
				// });
				// return false;
			// }
		// }
	// }
	
	searchUidFacebook = (e) => {
		const { taotientrinh } = this.props.Facebooklocbanbekhongtuongtac;
		var value = e.target.value;
		var new_taotientrinh = {
			...taotientrinh,
			[e.target.name]: value
		};
		let indexofLinkFb = value.lastIndexOf('facebook.com');
		let checkcookiefb = value.indexOf('c_user');
		if (indexofLinkFb > -1) {
			this.props.searchIdFb(value);
		} else if (checkcookiefb > -1) {
			var matches_idfb = value.match(/c_user=(.*?);/);
			if (matches_idfb != null) {
				if (typeof matches_idfb[1] != 'undefined') {
					new_taotientrinh = {
						...taotientrinh,
						[e.target.name]: matches_idfb[1]
					};
				}
			}
			this.props.updateFLBBKTT(new_taotientrinh);
		} else {
			this.props.updateFLBBKTT(new_taotientrinh);
		}
	}
	
	taoTienTrinhfunc = () => {
		const { taotientrinh } = this.props.Facebooklocbanbekhongtuongtac;
		const id_user = this.props.auth.user.id;
		var new_taotientrinh = {
			...taotientrinh,
			"id_user": id_user
		};
		// if (taotientrinh.cookie === '') {
			// Swal.fire({
				// icon: 'error',
				// title: 'Lỗi',
				// text: 'Cookie cần chạy không được để trống',
			// });
			// return false;
		// }
		if (taotientrinh.idfb === '') {
			Swal.fire({
				icon: 'error',
				title: 'Lỗi',
				text: 'ID trang cá nhân không được để trống!',
			});
			return false;
		}
		Swal.fire({
			title: 'Bạn có chắc?',
			html: "Bạn có chắc chắn muốn mua Giá "+Math.round((taotientrinh.gtmtt*1 + Number.EPSILON) * 100) / 100+" Coin / 1 lần? Khi đã bấm đồng ý đồng nghĩa với việc bạn đã đọc hết mục thông tin dịch vụ!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Đồng ý',
			cancelButtonText: 'Không'
		}).then((result) => {
			if (result.value) {
				this.props.addFLBBKTT(new_taotientrinh);
			}
		})
	}
	
	onClickShowDescription = (data) => {
		this.setState({
			showDescription: data
		});
	}
	
	render() {
 		const { cookie, gtmtt, idfb, usernamefb } = this.props.Facebooklocbanbekhongtuongtac.taotientrinh;
		var price = Math.round((gtmtt*1 + Number.EPSILON) * 100) / 100;
		var info = '';
		if (idfb !== '') {
			info = parse('<img src="https://graph.facebook.com/'+idfb+'/picture?height=100&amp;width=100" class="avatar-user" /> ' + usernamefb + ' (' + idfb + ')');
		}
		
		var listDataServicesInfo = {};
		try {
			if (localStorage.getItem("listDataServicesInfo") != null) {
				listDataServicesInfo = JSON.parse(localStorage.getItem("listDataServicesInfo"));
			} else {
				listDataServicesInfo = this.props.adminpage.listDataServicesInfo;
			}
		} catch(e) {
			listDataServicesInfo = {};
		}
		var html_haydoctranhmattien = '';
		var html_haydoctranhmattien_chinh = '';
		var html_haydoctranhmattien_sv = '';
		var html_locbanbe = '';
		if (typeof(listDataServicesInfo.facebook_vip_locbanbe_tab) !== 'undefined') {
			html_haydoctranhmattien = parse(listDataServicesInfo.facebook_vip_locbanbe_tab.ghichu);
			html_haydoctranhmattien_chinh = parse(listDataServicesInfo.facebook_vip_locbanbe_tab.ghichu_chinh);
		}
		if (typeof(listDataServicesInfo.facebook_vip_locbanbe) !== 'undefined') {
			html_locbanbe = parse(listDataServicesInfo.facebook_vip_locbanbe.ghichu_sv);
			if ("1" === "1") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.facebook_vip_locbanbe.ghichu);
			}
		}
		
		return (
			<Fragment>
				{/* <div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Cookie (*):</label>
					<div className="col-sm-8">
						<input type="text" className="form-control" id="cookie" name="cookie" placeholder="Nhập Vào Mã Cookie" onChange={this.onChange} />
						{info}
						<h6 style={{"marginTop":"5px", "marginBottom":"0px"}}>Tải tiện ích lọc bạn bè: <a href="https://drive.google.com/file/d/14xAijo0ANsEd8Ww9F19DuJIMIZ4LknQi/view?usp=sharing" target="_blank" className="font-bold"> Tại đây</a></h6>
						<h6 style={{"marginTop":"0px", "marginBottom":"0px"}}>Hướng dẫn cài tiện ích vào trình duyệt Chrome: <a href="https://i.imgur.com/RPbb2DA.png" target="_blank" className="font-bold"> Tại đây</a></h6>
					</div>
				</div> */}
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">ID trang cá nhân:</label>
					<div className="col-sm-8">
						<input type="text" className="form-control" id="idfb" name="idfb" placeholder="" onChange={(e) => {this.searchUidFacebook(e)}} value={idfb} />
						<h6 style={{"marginTop":"5px", "marginBottom":"0px"}}>Tải tiện ích lọc bạn bè: <a href="https://locbanbe.net/upload/EX_LOCBANBE.NET.zip" target="_blank" className="font-bold"> Tại đây</a></h6>
						<h6 style={{"marginTop":"0px", "marginBottom":"0px"}}>Hướng dẫn cài tiện ích vào trình duyệt Chrome: <a href="https://i.imgur.com/RPbb2DA.png" target="_blank" className="font-bold"> Tại đây</a></h6>
					</div>
				</div>
				
				<button type="button" className="btn badge-primary-cus btn-rounded btn-show-description-cus" onClick={(e) => {this.onClickShowDescription(!this.state.showDescription)}}>
					{
						this.state.showDescription?
							<i className="fa fa-angle-up" style={{"marginRight": "5px"}} aria-hidden="true"></i>
						:
							<i className="fa fa-angle-down" style={{"marginRight": "5px"}} aria-hidden="true"></i>
					}
					Thông tin dịch vụ
				</button>
				
				<ServicesDescription allservicesinfo={listDataServicesInfo} server={"1"} tab="facebook_vip_locbanbe" giatien={gtmtt} baohanh={0} showDescription={this.state.showDescription} />
				
				<div className="row mt-2" style={{"display": this.state.showDescription? "flex" : "none"}}>
				    <div className="col-md-12 bold" style={{"marginBottom": "10px"}}>
						<div className="card card-select-cus">
							<div className="card-body card-body-select-cus">
								<p className="mb-0 mt-3 text-wrap">
									<ul>
										{html_haydoctranhmattien}
										{html_haydoctranhmattien_chinh}
										{html_haydoctranhmattien_sv}
										<li>Nếu gặp lỗi hãy nhắn tin hỗ trợ phía bên phải góc màn hình hoặc vào mục liên hệ hỗ trợ để được hỗ trợ tốt nhất!</li>
									</ul>
								</p>
								<span className="badge badge-cus position-absolute-cus badge-top-left-cus badge-primary-cus badge-pill-cus"> HÃY ĐỌC ĐỂ TRÁNH MẤT TIỀN KHI SỬ DỤNG! </span>
							</div>
						</div>
				    </div>
				</div>
				<div className="row mt-4">
					<div className="col-12 text-center bold">
						<div className="card card-info">
							<div className="card-body">
								<h5 className="font-bold">Tổng: <span className="bold green"><span>{price}</span> Coin</span></h5>
								<h6 className="mb-0 bold"><span>Giá <span className="green">{price} Coin</span> / 1 lần</span></h6>
							</div>
						</div>
					</div>
				</div>
				<div className="row my-4">
					<div className="col-12 text-center bold">
						<button type="button" className="btn btn-block btn-rounded btn-hero-primary" onClick={this.taoTienTrinhfunc} >Tạo tiến trình</button>
					</div>
				</div>
			</Fragment>
		)
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	Facebooklocbanbekhongtuongtac: state.Facebooklocbanbekhongtuongtac,
});
const mapDispatchToProps = { addFLBBKTT, updateFLBBKTT, CheckCookie, loadPriceFLBBKTT, searchIdFb }

export default connect(mapStateToProps, mapDispatchToProps)(taotientrinh);