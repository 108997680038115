import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import {
    Modal,
    ModalBody,
    ModalFooter
} from 'reactstrap';
import { editHistoryThe } from '../../../../actions/authActions';

class Modal_edit extends Component {
    state = {
        id: this.props.dataid,
        status: this.props.datastatus,
    }
	
	onChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value
		});
	}

    render() {
        const {isOpenModal, dataid} = this.props;
        const {id, status} = this.state;
		const { loadingblock } = this.props.adminpage;

        return (
            <Fragment>
                <Modal centered={true} size="lg" toggle={(e) => {this.props.openModal()}} isOpen={isOpenModal} className={(loadingblock? ' block block-mode-loading' : '')} style={{"backgroundColor": "inherit", "boxShadow": "none"}}>
                    <div className="modal-header">
                        <p className="mb-0 text-dark">Sửa</p>
                        <button onClick={(e) => {this.props.openModal()}} type="button" className="close">×</button>
                    </div>
                    <ModalBody style={{opacity: loadingblock? '.05':'1'}}>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
									<label>Trạng thái</label>
									<select name="status" onChange={this.onChange} value={status} className="form-control">
										<option value="0">Đã nạp</option>
										<option value="1">Chờ duyệt</option>
										<option value="2">Hủy</option>
									</select>
								</div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter style={{opacity: loadingblock? '.05':'1'}}>
                        <button onClick={() => {this.props.editHistoryThe(dataid, status, this.props.openModal)}} type="button" className="btn btn-primary">Lưu</button>
                        <button onClick={(e) => {this.props.openModal()}} type="button" className="btn btn-danger">Huỷ</button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    adminpage: state.adminpage
});

export default connect(mapStateToProps, {editHistoryThe}) (Modal_edit);
