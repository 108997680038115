import {
	LOAD_SHOPEESEEDINGLIVESTREAM,
	UPDATE_SHOPEESEEDINGLIVESTREAM_TAB,
	UPDATE_SHOPEESEEDINGLIVESTREAM,
	LOAD_PRICE_SHOPEESEEDINGLIVESTREAM,
	LOADING_SHOPEESEEDINGLIVESTREAM,
	LIMIT_PAGI_SHOPEE,
	TYPE_SHOPEE,
	UPDATE_PRICE_BAOHANH_SHOPEESEEDINGLIVESTREAM
} from "../actions/types";

const date7dnext = () => {
	var result = new Date();
  	result.setDate(result.getDate() + 7);
  	return result;
}

const initialState = {
	"taotientrinh": {
		"linkshop": "",
		"usernameshopee": "",
		"portrait": "",
		"follower_count": "0",
		"shopid": "0",
		"lsct": "seedinglivestream",
		"cdbh": "0",
		"slct": "50",
		"tgsd": "30",
		"gtmtt": "0",
		"tocdocmt": "5",
		"ndcmt": "",
		"gc": "",
	},
	"changetab": 0,
	"listtientrinh": [],
	"price": [],
	"loading": false,
	"limit_pagi": 0,
	"type": 0,
};

export default function(state = initialState, action) {
	switch (action.type) {
		case LOAD_SHOPEESEEDINGLIVESTREAM:
			return {
				...state,
				"listtientrinh": action.payload
			};
		case UPDATE_SHOPEESEEDINGLIVESTREAM_TAB:
			return {
				...state,
				"changetab": action.payload
			};
		case UPDATE_SHOPEESEEDINGLIVESTREAM:
			return {
				...state,
				"taotientrinh": action.payload
			};
		case LOAD_PRICE_SHOPEESEEDINGLIVESTREAM:
			return {
				...state,
				"price": action.payload,
				"taotientrinh": {
					...state.taotientrinh,
					"gtmtt": action.payload.prices_web
				}
			};
		// case LOAD_PRICE_FLBBKTT:
			// return {
				// ...state,
				// "price": action.payload,
				// "taotientrinh": {
					// ...state.taotientrinh,
					// "gtmtt": action.payload.prices_web
				// }
			// };
		case LOADING_SHOPEESEEDINGLIVESTREAM:
			return {
				...state,
				"loading": action.payload
			};
		case LIMIT_PAGI_SHOPEE:
			return {
				...state,
				"limit_pagi": action.payload
			};
		case TYPE_SHOPEE:
			return {
				...state,
				"type": action.payload
			};
		case UPDATE_PRICE_BAOHANH_SHOPEESEEDINGLIVESTREAM:
			return {
				...state,
				"taotientrinh": {
					...state.taotientrinh,
					"cdbh": action.payload.type,
					"gtmtt": action.payload.gtmtt
				}
			};
		default:
		  return state;
	}
}
