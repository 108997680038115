import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import {
    Modal,
    ModalBody,
    ModalFooter
} from 'reactstrap';
import { saveEditAccClone } from '../../../../actions/adminPageAction';


class editaccountclone extends Component {

    state = {
        formdata: {
            uidfb: this.props.adminpage.accclone_select.uid,
            loaigoi: this.props.adminpage.accclone_select.id_loai.toString(),
            id_item: this.props.adminpage.accclone_select.id,
        }
    }

    changeValueRegister = (e) => {
        this.setState({
            formdata: {
                ...this.state.formdata,
                [e.target.name]: e.target.value
            }
        });
    }

    render() {
        const {isOpenModal,adminpage} = this.props;
        const {uidfb,loaigoi} = this.state.formdata;
        const html_selectSpecies = (
            <Fragment>
                <select onChange={(e) => {this.changeValueRegister(e)}} value={loaigoi} name="loaigoi" className="form-control">
                    {
                        adminpage.list_speciesclone.map(function (dataList, index) {
                            return (
                                <option value={dataList.id} key={index}>{dataList.name}</option>
                            );
                        })
                    }
                </select>
            </Fragment>
        )

        return (
            <Fragment>
                <Modal centered={true} size="lg" toggle={() => {this.props.actionOpenModal()}} isOpen={isOpenModal}>
                    <div className="modal-header">
                        <p className="mb-0 text-dark">Sửa tài khoản clone</p>
                        <button onClick={() => {this.props.actionOpenModal()}} type="button" className="close">×</button>
                    </div>
                    <ModalBody>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Uid</label>
                                    <input type="text" onChange={(e) => {this.changeValueRegister(e)}} value={uidfb} name="uidfb" className="form-control"  placeholder="Tên loại" />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Loại gói</label>
                                    {html_selectSpecies}
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button type="button" className="btn btn-primary" onClick={() => {this.props.saveEditAccClone(this.state.formdata, adminpage.accclone_select, this.props.actionOpenModal)}}>Lưu</button>
                        <button type="button" className="btn btn-danger" onClick={() => {this.props.actionOpenModal()}}>Huỷ</button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        );
    }

}

const mapStateToProps = state => ({
    adminpage: state.adminpage
});

export default connect(mapStateToProps, { saveEditAccClone }) (editaccountclone);
