
const date7dnext = () => {
	var result = new Date();
  	result.setDate(result.getDate() + 7);
  	return result;
}

const initialState = {
	loadingblock: false,
	listBuffSub: [],
	datapricebuffsub: {
		like: {
			min_bh_web: 0,
			prices_web: 0,
		},
		care: {
			min_bh_web: 0,
			prices_web: 0,
		},
		love: {
			min_bh_web: 0,
			prices_web: 0,
		},
		haha: {
			min_bh_web: 0,
			prices_web: 0,
		},
		wow: {
			min_bh_web: 0,
			prices_web: 0,
		},
		sad: {
			min_bh_web: 0,
			prices_web: 0,
		},
		angry: {
			min_bh_web: 0,
			prices_web: 0,
		},
		like_clone: {
			min_bh_web: 0,
			prices_web: 0,
		},
		care_clone: {
			min_bh_web: 0,
			prices_web: 0,
		},
		love_clone: {
			min_bh_web: 0,
			prices_web: 0,
		},
		haha_clone: {
			min_bh_web: 0,
			prices_web: 0,
		},
		wow_clone: {
			min_bh_web: 0,
			prices_web: 0,
		},
		sad_clone: {
			min_bh_web: 0,
			prices_web: 0,
		},
		angry_clone: {
			min_bh_web: 0,
			prices_web: 0,
		},
		like_v2: {
			min_bh_web: 0,
			prices_web: 0,
		},
		care_v2: {
			min_bh_web: 0,
			prices_web: 0,
		},
		love_v2: {
			min_bh_web: 0,
			prices_web: 0,
		},
		haha_v2: {
			min_bh_web: 0,
			prices_web: 0,
		},
		wow_v2: {
			min_bh_web: 0,
			prices_web: 0,
		},
		sad_v2: {
			min_bh_web: 0,
			prices_web: 0,
		},
		angry_v2: {
			min_bh_web: 0,
			prices_web: 0,
		},
		like_cmt: {
			min_bh_web: 0,
			prices_web: 0,
		},
		care_cmt: {
			min_bh_web: 0,
			prices_web: 0,
		},
		love_cmt: {
			min_bh_web: 0,
			prices_web: 0,
		},
		haha_cmt: {
			min_bh_web: 0,
			prices_web: 0,
		},
		wow_cmt: {
			min_bh_web: 0,
			prices_web: 0,
		},
		sad_cmt: {
			min_bh_web: 0,
			prices_web: 0,
		},
		angry_cmt: {
			min_bh_web: 0,
			prices_web: 0,
		},
		comment: {
			min_bh_web: 0,
			prices_web: 0,
		},
		share: {
			min_bh_web: 0,
			prices_web: 0,
		},
		comment_pro: {
			min_bh_web: 0,
			prices_web: 0,
		},
		like_v3: {
			min_bh_web: 0,
			prices_web: 0,
		},
		care_v3: {
			min_bh_web: 0,
			prices_web: 0,
		},
		love_v3: {
			min_bh_web: 0,
			prices_web: 0,
		},
		haha_v3: {
			min_bh_web: 0,
			prices_web: 0,
		},
		wow_v3: {
			min_bh_web: 0,
			prices_web: 0,
		},
		sad_v3: {
			min_bh_web: 0,
			prices_web: 0,
		},
		angry_v3: {
			min_bh_web: 0,
			prices_web: 0,
		},
		like_v4: {
			min_bh_web: 0,
			prices_web: 0,
		},
		care_v4: {
			min_bh_web: 0,
			prices_web: 0,
		},
		love_v4: {
			min_bh_web: 0,
			prices_web: 0,
		},
		haha_v4: {
			min_bh_web: 0,
			prices_web: 0,
		},
		wow_v4: {
			min_bh_web: 0,
			prices_web: 0,
		},
		sad_v4: {
			min_bh_web: 0,
			prices_web: 0,
		},
		angry_v4: {
			min_bh_web: 0,
			prices_web: 0,
		},
		like_v5: {
			min_bh_web: 0,
			prices_web: 0,
		},
		care_v5: {
			min_bh_web: 0,
			prices_web: 0,
		},
		love_v5: {
			min_bh_web: 0,
			prices_web: 0,
		},
		haha_v5: {
			min_bh_web: 0,
			prices_web: 0,
		},
		wow_v5: {
			min_bh_web: 0,
			prices_web: 0,
		},
		sad_v5: {
			min_bh_web: 0,
			prices_web: 0,
		},
		angry_v5: {
			min_bh_web: 0,
			prices_web: 0,
		},
		like_v6: {
			min_bh_web: 0,
			prices_web: 0,
		},
		care_v6: {
			min_bh_web: 0,
			prices_web: 0,
		},
		love_v6: {
			min_bh_web: 0,
			prices_web: 0,
		},
		haha_v6: {
			min_bh_web: 0,
			prices_web: 0,
		},
		wow_v6: {
			min_bh_web: 0,
			prices_web: 0,
		},
		sad_v6: {
			min_bh_web: 0,
			prices_web: 0,
		},
		angry_v6: {
			min_bh_web: 0,
			prices_web: 0,
		},
		like_v7: {
			min_bh_web: 0,
			prices_web: 0,
		},
		care_v7: {
			min_bh_web: 0,
			prices_web: 0,
		},
		love_v7: {
			min_bh_web: 0,
			prices_web: 0,
		},
		haha_v7: {
			min_bh_web: 0,
			prices_web: 0,
		},
		wow_v7: {
			min_bh_web: 0,
			prices_web: 0,
		},
		sad_v7: {
			min_bh_web: 0,
			prices_web: 0,
		},
		angry_v7: {
			min_bh_web: 0,
			prices_web: 0,
		},
		like_v8: {
			min_bh_web: 0,
			prices_web: 0,
		},
		care_v8: {
			min_bh_web: 0,
			prices_web: 0,
		},
		love_v8: {
			min_bh_web: 0,
			prices_web: 0,
		},
		haha_v8: {
			min_bh_web: 0,
			prices_web: 0,
		},
		wow_v8: {
			min_bh_web: 0,
			prices_web: 0,
		},
		sad_v8: {
			min_bh_web: 0,
			prices_web: 0,
		},
		angry_v8: {
			min_bh_web: 0,
			prices_web: 0,
		},
		like_v9: {
			min_bh_web: 0,
			prices_web: 0,
		},
		care_v9: {
			min_bh_web: 0,
			prices_web: 0,
		},
		love_v9: {
			min_bh_web: 0,
			prices_web: 0,
		},
		haha_v9: {
			min_bh_web: 0,
			prices_web: 0,
		},
		wow_v9: {
			min_bh_web: 0,
			prices_web: 0,
		},
		sad_v9: {
			min_bh_web: 0,
			prices_web: 0,
		},
		angry_v9: {
			min_bh_web: 0,
			prices_web: 0,
		},
		like_v10: {
			min_bh_web: 0,
			prices_web: 0,
		},
		care_v10: {
			min_bh_web: 0,
			prices_web: 0,
		},
		love_v10: {
			min_bh_web: 0,
			prices_web: 0,
		},
		haha_v10: {
			min_bh_web: 0,
			prices_web: 0,
		},
		wow_v10: {
			min_bh_web: 0,
			prices_web: 0,
		},
		sad_v10: {
			min_bh_web: 0,
			prices_web: 0,
		},
		angry_v10: {
			min_bh_web: 0,
			prices_web: 0,
		},
		like_v11: {
			min_bh_web: 0,
			prices_web: 0,
		},
		care_v11: {
			min_bh_web: 0,
			prices_web: 0,
		},
		love_v11: {
			min_bh_web: 0,
			prices_web: 0,
		},
		haha_v11: {
			min_bh_web: 0,
			prices_web: 0,
		},
		wow_v11: {
			min_bh_web: 0,
			prices_web: 0,
		},
		sad_v11: {
			min_bh_web: 0,
			prices_web: 0,
		},
		angry_v11: {
			min_bh_web: 0,
			prices_web: 0,
		},
	},
	formData: {
		locnangcao: 0,
		locnangcao_gt: 0,
		locnangcao_dotuoi_start: 0,
		locnangcao_dotuoi_end: 13,
		locnangcao_banbe_start: 0,
		locnangcao_banbe_end: 100,
		profile_user: '',
		loaiseeding: 'like',
		baohanh: 0,
		sltang: 1,
		giatien: 0,
		ghichu: '',
		startDatebh: new Date(),
		EndDatebh: date7dnext(),
		type: '',
		list_messages: [],
		tocdolike: 0,
	},
	facebookbufflike_mo_dong: 1,
	facebookbufflike_clone_mo_dong: 1,
	facebookbufflike_v2_mo_dong: 1,
	facebookbufflike_v3_mo_dong: 1,
	facebookbufflike_v4_mo_dong: 1,
	facebookbufflike_v5_mo_dong: 1,
	facebookbufflike_v6_mo_dong: 1,
	facebookbufflike_v7_mo_dong: 1,
	facebookbufflike_v8_mo_dong: 1,
	facebookbufflike_v9_mo_dong: 1,
	facebookbufflike_v10_mo_dong: 1,
	facebookbufflike_v11_mo_dong: 1,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case 'LIST_BUFF_ALL_FB':
    	return {
			...state,
			listBuffSub: action.payload
		};
    case 'LOADING_BLOCK_ALL_FB':
		return {
			...state,
			loadingblock: action.payload
		};
	case 'DATA_PRICE_IS_FUNCTION_BUFFSUB_LCS':
		return {
			...state,
			datapricebuffsub: merDataPricePage(action.payload)
		}
	case 'CHANGE_DATA_FORM_YTB_LCS_LIKE':
		return {
			...state,
			formData: action.payload
		}
	case 'CONFIG_MO_DONG_LCS_LIKE':
		return {
			...state,
			facebookbufflike_mo_dong: action.payload.facebookbufflike_mo_dong,
			facebookbufflike_clone_mo_dong: action.payload.facebookbufflike_clone_mo_dong,
			facebookbufflike_v2_mo_dong: action.payload.facebookbufflike_v2_mo_dong,
			facebookbufflike_v3_mo_dong: action.payload.facebookbufflike_v3_mo_dong,
			facebookbufflike_v4_mo_dong: action.payload.facebookbufflike_v4_mo_dong,
			facebookbufflike_v5_mo_dong: action.payload.facebookbufflike_v5_mo_dong,
			facebookbufflike_v6_mo_dong: action.payload.facebookbufflike_v6_mo_dong,
			facebookbufflike_v7_mo_dong: action.payload.facebookbufflike_v7_mo_dong,
			facebookbufflike_v8_mo_dong: action.payload.facebookbufflike_v8_mo_dong,
			facebookbufflike_v9_mo_dong: action.payload.facebookbufflike_v9_mo_dong,
			facebookbufflike_v10_mo_dong: action.payload.facebookbufflike_v10_mo_dong,
			facebookbufflike_v11_mo_dong: action.payload.facebookbufflike_v11_mo_dong,
		};
    default:
      return state;
  }
}

function merDataPricePage(data) {
	const dataOld = initialState.datapricebuffsub;
	let dataNew = {
		...dataOld
	}
	if (data.length > 0) {
		data.forEach(function(v, i){
			if (v.name_table === 'fb_likecommentshare_like') {
				dataNew['like'] = v;
				initialState.formData['giatien'] = v.prices_web;
			} else if (v.name_table === 'fb_likecommentshare_care') {
				dataNew['care'] = v;
			} else if (v.name_table === 'fb_likecommentshare_love') {
				dataNew['love'] = v;
			} else if (v.name_table === 'fb_likecommentshare_haha') {
				dataNew['haha'] = v;
			} else if (v.name_table === 'fb_likecommentshare_wow') {
				dataNew['wow'] = v;
			} else if (v.name_table === 'fb_likecommentshare_sad') {
				dataNew['sad'] = v;
			} else if (v.name_table === 'fb_likecommentshare_angry') {
				dataNew['angry'] = v;
			} else if (v.name_table === 'fb_likecommentshare_comment') {
				dataNew['comment'] = v;
			} else if (v.name_table === 'fb_likecommentshare_comment_pro') {
				dataNew['comment_pro'] = v;
			} else if (v.name_table === 'fb_likecommentshare_share') {
				dataNew['share'] = v;
			} else if (v.name_table === 'fb_likecommentshare_like_clone') {
				dataNew['like_clone'] = v;
			} else if (v.name_table === 'fb_likecommentshare_care_clone') {
				dataNew['care_clone'] = v;
			} else if (v.name_table === 'fb_likecommentshare_love_clone') {
				dataNew['love_clone'] = v;
			} else if (v.name_table === 'fb_likecommentshare_haha_clone') {
				dataNew['haha_clone'] = v;
			} else if (v.name_table === 'fb_likecommentshare_wow_clone') {
				dataNew['wow_clone'] = v;
			} else if (v.name_table === 'fb_likecommentshare_sad_clone') {
				dataNew['sad_clone'] = v;
			} else if (v.name_table === 'fb_likecommentshare_angry_clone') {
				dataNew['angry_clone'] = v;
			} else if (v.name_table === 'fb_likecommentshare_like_v2') {
				dataNew['like_v2'] = v;
			} else if (v.name_table === 'fb_likecommentshare_care_v2') {
				dataNew['care_v2'] = v;
			} else if (v.name_table === 'fb_likecommentshare_love_v2') {
				dataNew['love_v2'] = v;
			} else if (v.name_table === 'fb_likecommentshare_haha_v2') {
				dataNew['haha_v2'] = v;
			} else if (v.name_table === 'fb_likecommentshare_wow_v2') {
				dataNew['wow_v2'] = v;
			} else if (v.name_table === 'fb_likecommentshare_sad_v2') {
				dataNew['sad_v2'] = v;
			} else if (v.name_table === 'fb_likecommentshare_angry_v2') {
				dataNew['angry_v2'] = v;
			} else if (v.name_table === 'fb_likecommentshare_like_cmt') {
				dataNew['like_cmt'] = v;
			} else if (v.name_table === 'fb_likecommentshare_care_cmt') {
				dataNew['care_cmt'] = v;
			} else if (v.name_table === 'fb_likecommentshare_love_cmt') {
				dataNew['love_cmt'] = v;
			} else if (v.name_table === 'fb_likecommentshare_haha_cmt') {
				dataNew['haha_cmt'] = v;
			} else if (v.name_table === 'fb_likecommentshare_wow_cmt') {
				dataNew['wow_cmt'] = v;
			} else if (v.name_table === 'fb_likecommentshare_sad_cmt') {
				dataNew['sad_cmt'] = v;
			} else if (v.name_table === 'fb_likecommentshare_angry_cmt') {
				dataNew['angry_cmt'] = v;
			} else if (v.name_table === 'fb_likecommentshare_like_v3') {
				dataNew['like_v3'] = v;
			} else if (v.name_table === 'fb_likecommentshare_care_v3') {
				dataNew['care_v3'] = v;
			} else if (v.name_table === 'fb_likecommentshare_love_v3') {
				dataNew['love_v3'] = v;
			} else if (v.name_table === 'fb_likecommentshare_haha_v3') {
				dataNew['haha_v3'] = v;
			} else if (v.name_table === 'fb_likecommentshare_wow_v3') {
				dataNew['wow_v3'] = v;
			} else if (v.name_table === 'fb_likecommentshare_sad_v3') {
				dataNew['sad_v3'] = v;
			} else if (v.name_table === 'fb_likecommentshare_angry_v3') {
				dataNew['angry_v3'] = v;
			} else if (v.name_table === 'fb_likecommentshare_like_v4') {
				dataNew['like_v4'] = v;
			} else if (v.name_table === 'fb_likecommentshare_care_v4') {
				dataNew['care_v4'] = v;
			} else if (v.name_table === 'fb_likecommentshare_love_v4') {
				dataNew['love_v4'] = v;
			} else if (v.name_table === 'fb_likecommentshare_haha_v4') {
				dataNew['haha_v4'] = v;
			} else if (v.name_table === 'fb_likecommentshare_wow_v4') {
				dataNew['wow_v4'] = v;
			} else if (v.name_table === 'fb_likecommentshare_sad_v4') {
				dataNew['sad_v4'] = v;
			} else if (v.name_table === 'fb_likecommentshare_angry_v4') {
				dataNew['angry_v4'] = v;
			} else if (v.name_table === 'fb_likecommentshare_like_v5') {
				dataNew['like_v5'] = v;
			} else if (v.name_table === 'fb_likecommentshare_care_v5') {
				dataNew['care_v5'] = v;
			} else if (v.name_table === 'fb_likecommentshare_love_v5') {
				dataNew['love_v5'] = v;
			} else if (v.name_table === 'fb_likecommentshare_haha_v5') {
				dataNew['haha_v5'] = v;
			} else if (v.name_table === 'fb_likecommentshare_wow_v5') {
				dataNew['wow_v5'] = v;
			} else if (v.name_table === 'fb_likecommentshare_sad_v5') {
				dataNew['sad_v5'] = v;
			} else if (v.name_table === 'fb_likecommentshare_angry_v5') {
				dataNew['angry_v5'] = v;
			} else if (v.name_table === 'fb_likecommentshare_like_v6') {
				dataNew['like_v6'] = v;
			} else if (v.name_table === 'fb_likecommentshare_care_v6') {
				dataNew['care_v6'] = v;
			} else if (v.name_table === 'fb_likecommentshare_love_v6') {
				dataNew['love_v6'] = v;
			} else if (v.name_table === 'fb_likecommentshare_haha_v6') {
				dataNew['haha_v6'] = v;
			} else if (v.name_table === 'fb_likecommentshare_wow_v6') {
				dataNew['wow_v6'] = v;
			} else if (v.name_table === 'fb_likecommentshare_sad_v6') {
				dataNew['sad_v6'] = v;
			} else if (v.name_table === 'fb_likecommentshare_angry_v6') {
				dataNew['angry_v6'] = v;
			} else if (v.name_table === 'fb_likecommentshare_like_v7') {
				dataNew['like_v7'] = v;
			} else if (v.name_table === 'fb_likecommentshare_care_v7') {
				dataNew['care_v7'] = v;
			} else if (v.name_table === 'fb_likecommentshare_love_v7') {
				dataNew['love_v7'] = v;
			} else if (v.name_table === 'fb_likecommentshare_haha_v7') {
				dataNew['haha_v7'] = v;
			} else if (v.name_table === 'fb_likecommentshare_wow_v7') {
				dataNew['wow_v7'] = v;
			} else if (v.name_table === 'fb_likecommentshare_sad_v7') {
				dataNew['sad_v7'] = v;
			} else if (v.name_table === 'fb_likecommentshare_angry_v7') {
				dataNew['angry_v7'] = v;
			} else if (v.name_table === 'fb_likecommentshare_like_v8') {
				dataNew['like_v8'] = v;
			} else if (v.name_table === 'fb_likecommentshare_care_v8') {
				dataNew['care_v8'] = v;
			} else if (v.name_table === 'fb_likecommentshare_love_v8') {
				dataNew['love_v8'] = v;
			} else if (v.name_table === 'fb_likecommentshare_haha_v8') {
				dataNew['haha_v8'] = v;
			} else if (v.name_table === 'fb_likecommentshare_wow_v8') {
				dataNew['wow_v8'] = v;
			} else if (v.name_table === 'fb_likecommentshare_sad_v8') {
				dataNew['sad_v8'] = v;
			} else if (v.name_table === 'fb_likecommentshare_angry_v8') {
				dataNew['angry_v8'] = v;
			} else if (v.name_table === 'fb_likecommentshare_like_v9') {
				dataNew['like_v9'] = v;
			} else if (v.name_table === 'fb_likecommentshare_care_v9') {
				dataNew['care_v9'] = v;
			} else if (v.name_table === 'fb_likecommentshare_love_v9') {
				dataNew['love_v9'] = v;
			} else if (v.name_table === 'fb_likecommentshare_haha_v9') {
				dataNew['haha_v9'] = v;
			} else if (v.name_table === 'fb_likecommentshare_wow_v9') {
				dataNew['wow_v9'] = v;
			} else if (v.name_table === 'fb_likecommentshare_sad_v9') {
				dataNew['sad_v9'] = v;
			} else if (v.name_table === 'fb_likecommentshare_angry_v9') {
				dataNew['angry_v9'] = v;
			} else if (v.name_table === 'fb_likecommentshare_like_v10') {
				dataNew['like_v10'] = v;
			} else if (v.name_table === 'fb_likecommentshare_care_v10') {
				dataNew['care_v10'] = v;
			} else if (v.name_table === 'fb_likecommentshare_love_v10') {
				dataNew['love_v10'] = v;
			} else if (v.name_table === 'fb_likecommentshare_haha_v10') {
				dataNew['haha_v10'] = v;
			} else if (v.name_table === 'fb_likecommentshare_wow_v10') {
				dataNew['wow_v10'] = v;
			} else if (v.name_table === 'fb_likecommentshare_sad_v10') {
				dataNew['sad_v10'] = v;
			} else if (v.name_table === 'fb_likecommentshare_angry_v10') {
				dataNew['angry_v10'] = v;
			} else if (v.name_table === 'fb_likecommentshare_like_v11') {
				dataNew['like_v11'] = v;
			} else if (v.name_table === 'fb_likecommentshare_care_v11') {
				dataNew['care_v11'] = v;
			} else if (v.name_table === 'fb_likecommentshare_love_v11') {
				dataNew['love_v11'] = v;
			} else if (v.name_table === 'fb_likecommentshare_haha_v11') {
				dataNew['haha_v11'] = v;
			} else if (v.name_table === 'fb_likecommentshare_wow_v11') {
				dataNew['wow_v11'] = v;
			} else if (v.name_table === 'fb_likecommentshare_sad_v11') {
				dataNew['sad_v11'] = v;
			} else if (v.name_table === 'fb_likecommentshare_angry_v11') {
				dataNew['angry_v11'] = v;
			}
		});
	};
	return dataNew;
}