import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { login } from "../../actions/authActions";
import { clearErrors } from "../../actions/errorActions";
import RegisterModal10 from './Register10';
import ServiceModal10 from './Service10';
import ForgotPasswordModal10 from './ForgotPassword10';
import png_top_img_zmjes3 from '../../iconlanding/10/top_img_zmjes3.png';
import png_amazing_girl_x6aihr from '../../iconlanding/10/amazing_girl_x6aihr.png';
import png_Vector_Smart_Object1_lvoahj from '../../iconlanding/10/Vector_Smart_Object1_lvoahj.png';
import png_Vector_Smart_Object_copy_7_gowfnr from '../../iconlanding/10/Vector_Smart_Object_copy_7_gowfnr.png';
import png_Vector_Smart_Object4 from '../../iconlanding/10/Vector_Smart_Object4.png';
import png_Vector_Smart_Object3 from '../../iconlanding/10/Vector_Smart_Object3.png';
import png_feel_img_kholvz from '../../iconlanding/10/feel_img_kholvz.png';
import png_engaging_mob from '../../iconlanding/10/engaging_mob.png';
import png_provider_img from '../../iconlanding/10/provider_img.png';
import png_qzB2zKk from '../../iconlanding/10/qzB2zKk.jpg';
import png_testi_1 from '../../iconlanding/10/testi_1.jpg';
import png_testi_3 from '../../iconlanding/10/testi_33.png';
import png_testi_4 from '../../iconlanding/10/testi_4.jpg';
import png_testi_5 from '../../iconlanding/10/testi_5.jpg';
import png_testi_6 from '../../iconlanding/10/testi_6.jpg';
import png_testi_7 from '../../iconlanding/10/testi_7.jpg';
import png_testi_8 from '../../iconlanding/10/testi_8.jpg';
import png_testi_9 from '../../iconlanding/10/testi_9.jpg';
import png_testi_10 from '../../iconlanding/10/testi_10.jpg';
import png_testi_11 from '../../iconlanding/10/testi_11.jpeg';
import png_testi_12 from '../../iconlanding/10/testi_12.jpg';
import png_faq from '../../iconlanding/10/faq.png';
import Lottie from 'react-lottie-player';
import { RiShieldCheckLine, RiMoneyDollarBoxLine, RiHeadphoneLine, RiShoppingBasket2Line } from "react-icons/ri";
import { VscRocket } from "react-icons/vsc";
import { BiCrown } from "react-icons/bi";
import { AiOutlineUserAdd, AiOutlineUser } from "react-icons/ai";
import {
  BrowserRouter as Router,
  Link
} from "react-router-dom";
class Login extends Component {
  state = {
	modal: 1,
    msg: "",
    username: "",
    password: "",
    maxacminh: "",
    form_maxacminh: false,
    isLoading: false,
	showsteps: 1,
	showtabdes: "facebook",
	showaskquestion: 1,
	showaskquestion2: 1,
  };
  componentDidMount(){
	if (this.props.typeform == 3) {
	  this.setState({ modal: 3 });
	}
  }
  componentDidUpdate(prevProps) {
    const { error } = this.props;
    if (error !== prevProps.error) {
      // Check for login error
      if (error.id === "LOGIN_FAIL") {
        this.setState({ msg: error.msg.msg });
      } else if (error.id === "LOGIN_FAIL_2FA") {
        this.setState({ msg: error.msg.msg, form_maxacminh: true });
      } else {
        this.setState({ msg: null });
      }
    }
  }
	clearAlert = () => {
		// Clear errors
		this.props.clearErrors();
		// this.setState({
			// modal: !this.state.modal
		// });
	}
  changeFormLogRegister = (typeform) => {
	this.props.clearErrors();
	this.setState({
		modal: typeform
	});
	
}
  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };
  onSubmit = e => {
    e.preventDefault();
    const { username, password, maxacminh } = this.state;
    this.props.clearErrors();
    this.props.login({ username, password, maxacminh });
  };
  changeShowSteps = (data) => {
	this.setState({
		showsteps: data
	});
  };
  changeShowTabDes = (data) => {
	this.setState({
		showtabdes: data
	});
  };
  changeShowAskQuestion = (data) => {
	this.setState({
		showaskquestion: data
	});
  };
  changeShowAskQuestion2 = (data) => {
	this.setState({
		showaskquestion2: data
	});
  };
  render() {
    let isLoading = this.props.auth.isLoading;
    const domainSite = window.location.hostname;
	var config_website = {};
	if (localStorage.getItem("confignavbar") != null) {
		config_website = JSON.parse(localStorage.getItem("confignavbar"));
	} else {
		config_website = this.props.auth.config_website;
	}
	const formLogin = (
		<Router>
			<Fragment>
				<div className="landingcus10" style={{"zoom": "122%"}}>
					<div className="guest nav-bg">
						<nav className="navbar navbar-default navbar-inverse navbar-static-top ">
							<div className="container">
								<div className="navbar-header">
									<a className="navbar-brand" href="/" style={{"fontSize": "30px"}}>
										{domainSite}
									</a>
									<ul className="nav navbar-nav navbar-toggle-cus">
										<li >
											<Link to="/services" onClick={(e) => {this.changeFormLogRegister(3)}}>Dịch vụ</Link>
										</li>
										<li >
											<Link to="/" onClick={(e) => {this.changeFormLogRegister(2)}}>Đăng ký</Link>
										</li>
									</ul>
								</div>
								<div id="navbar" className="collapse navbar-collapse">
									<ul className="nav navbar-nav navbar-right">
										<li className="active">
											<a href="/">Đăng nhập</a>
										</li>
										<li>
											<Link to="/" onClick={(e) => {this.changeFormLogRegister(2)}}>Đăng ký</Link>
										</li>
										<li>
											<Link to="/services" onClick={(e) => {this.changeFormLogRegister(3)}}>Dịch vụ</Link>
										</li>
									</ul>
								</div>
							</div>
						</nav>
						<section id="top-sec">
							<div className="container">
								<div className="row text-center">
									<div className="col-sm-12">
										<h1>Dịch vụ mạng xã hội tốt nhất - rẻ nhất hiện nay</h1>
										<p>Chúng tôi chuyên cung cấp các dịch vụ, giải pháp và tiện ích kinh doanh</p>
									</div>
								</div>
								<div className="row frm-row">
									<div className="col-sm-12">
										{isLoading ? (
											<div className="preloader">
												<div className="spinner-grow spinner-grow-sm text-primary" role="status" style={{ "marginTop": "5%" }}>
												  <span className="sr-only"></span>
												</div>
												<div className="spinner-grow spinner-grow-sm text-danger" role="status" style={{ "marginTop": "5%" }}>
												  <span className="sr-only">.</span>
												</div>
												<div className="spinner-grow spinner-grow-sm text-warning" role="status" style={{ "marginTop": "5%" }}>
												  <span className="sr-only"></span>
												</div>
											</div>
										):('')}
										{this.state.msg ? (
											<div className="alert alert-warning d-flex align-items-center justify-content-between" role="alert" >
											  <div className="flex-fill mr-3">
												<p className="mb-0">{this.state.msg}</p>
											  </div>
											  <div className="flex-00-auto">
												<i className="fa fa-fw fa-exclamation-circle"></i>
											  </div>
											</div>
										  ) : null}
										
										<form className="js-validation-signup" onSubmit={this.onSubmit} method="POST" >
											<div className="row error-row">
												<div className="col-sm-12"></div>
											</div>
											<div className="row frm-inpt">
												<div className="col-sm-5">
													<div className="form-group">
														<span className="inpt-icon">
															<i className="fas fa-user-edit" aria-hidden="true"></i>
														</span>
														<input name="username" placeholder="Tài khoản" required="" id="username" type="text" className="form-control" onChange={this.onChange} />
													</div>
												</div>
												<div className="col-sm-5">
													<div className="form-group form-group__password">
														<span className="inpt-icon">
															<i className="fas fa-key" aria-hidden="true"></i>
														</span>
														<input name="password" placeholder="Mật khẩu" required="" id="password" type="password" className="form-control" onChange={this.onChange} />
													</div>
												</div>
												<div className="col-sm-2">
													<button type="submit" className="btn btn-primary">
														<i className="far fa-arrow-alt-circle-right" aria-hidden="true" ></i>
													</button>
												</div>
											</div>
											{
												this.state.form_maxacminh ?
													<div className="row frm-inpt" style={{"marginTop": "15px"}}>
														<div className="col-sm-12">
															<div className="form-group form-group__maxacminh">
																<span className="inpt-icon">
																	<i className="fas fa-lock" aria-hidden="true"></i>
																</span>
																<input name="maxacminh" placeholder="Mã xác minh" required="" id="maxacminh" type="text" className="form-control" onChange={this.onChange} />
															</div>
														</div>
													</div>
												: ""
											}
											<div className="row mt-15">
												<div className="col-sm-12">
													<Link to="/" onClick={(e) => {this.changeFormLogRegister(2)}} className="sign-txt"> Bạn chưa có tài khoản? Đăng ký </Link>
													<Link to="/" onClick={(e) => {this.changeFormLogRegister(4)}} className="sign-txt">
														Quên mật khẩu?
													</Link>
												</div>
											</div>
										</form>
									</div>
								</div>
								<div className="row">
									<div className="col-sm-12">
										<div className="top-img">
											<img className="img-responsive" src={png_top_img_zmjes3} alt="top-img"/>
										</div>
									</div>
								</div>
							</div>
						</section>
						<section id="amazing">
							<div className="container">
								<div className="row">
									<div className="col-sm-6">
										<div className="card-wrap">
											<div className="row">
												<div className="col-sm-6">
													<div className="single-card fb">
														<div className="card-icon">
															<span></span>
														</div>
														<div className="card-txt">
															<p>Facebook </p>
														</div>
													</div>
												</div>
												<div className="col-sm-6">
													<div className="single-card ytube">
														<div className="card-icon">
															<span></span>
														</div>
														<div className="card-txt">
															<p>Youtube </p>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className="card-wrap mt-15">
											<div className="row">
												<div className="col-sm-6">
													<div className="single-card insta">
														<div className="card-icon">
															<span></span>
														</div>
														<div className="card-txt">
															<p>Instagram </p>
														</div>
													</div>
												</div>
												<div className="col-sm-6">
													<div className="single-card twiter">
														<div className="card-icon">
															<span></span>
														</div>
														<div className="card-txt">
															<p>Twitter </p>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className="card-wrap mt-15">
											<div className="row">
												<div className="col-sm-6">
													<div className="single-card tiktok">
														<div className="card-icon">
															<span></span>
														</div>
														<div className="card-txt">
															<p>Tiktok </p>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="col-sm-6">
										<div className="amazing-girl">
											<img className="img-responsive" src={png_amazing_girl_x6aihr} alt="amazing-girl" style={{"width": "40%"}}/>
										</div>
										<div className="amazing-content">
											<h2 className="title">Dịch vụ <span>Tốt Nhất</span>
											</h2>
											<p>Tại {domainSite}, ưu tiên hàng đầu của chúng tôi là sự hài lòng của khách hàng và chất lượng hoạt động. Dịch vụ mạng xã hội nhanh nhất và an toàn duy nhất. Tất cả Dịch vụ Bảo đảm được cung cấp đầy đủ đúng thời hạn. Nếu bất kỳ sự cố vẫn tiếp diễn, chúng tôi luôn sẵn sàng hỗ trợ Chat 24 * 7. Với hơn 250.000 khách hàng, chúng tôi là nhà cung cấp dịch vụ mạng xã hội tốt nhất và rẻ nhất hiện nay.</p>
											<Link to="/" className="btn btn-primary-cus" onClick={(e) => {this.changeFormLogRegister(2)}}>ĐĂNG KÝ</Link>
										</div>
									</div>
								</div>
								<div className="row tick-row">
									<div className="col-sm-2">
										<div className="tick">
											<span></span>
										</div>
										<div className="tick-content">
											<p>Nhiều đơn hàng</p>
										</div>
									</div>
									<div className="col-sm-2">
										<div className="tick">
											<span></span>
										</div>
										<div className="tick-content">
											<p>Dịch vụ đăng ký</p>
										</div>
									</div>
									<div className="col-sm-2">
										<div className="tick">
											<span></span>
										</div>
										<div className="tick-content">
											<p>Đại lý</p>
										</div>
									</div>
									<div className="col-sm-2">
										<div className="tick">
											<span></span>
										</div>
										<div className="tick-content">
											<p>Máy chủ giám sát</p>
										</div>
									</div>
									<div className="col-sm-2">
										<div className="tick">
											<span></span>
										</div>
										<div className="tick-content">
											<p>Khuyến mãi</p>
										</div>
									</div>
									<div className="col-sm-2">
										<div className="tick">
											<span></span>
										</div>
										<div className="tick-content">
											<p>Hỗ trợ 24/7</p>
										</div>
									</div>
								</div>
							</div>
						</section>
						<section id="engaging">
							<div className="container">
								<div className="row">
									<div className="col-sm-6">
										<h2 className="title">Hơn 7 Năm Kinh Nghiệm </h2>
										<p>{domainSite} với hơn 7 năm kinh nghiệm trong lĩnh vực Online Maketing. Với phương châm làm việc 'We work for your faith', chúng tôi cam kết không ngừng cải tiến chất lượng sản phẩm và dịch vụ.</p>
										<div className="engaging-imgs">
											<img className="engaging-img shocking-boy" src={png_Vector_Smart_Object1_lvoahj}/>
											<img className="engaging-img girlonknee" src={png_Vector_Smart_Object_copy_7_gowfnr}/>
										</div>
									</div>
									<div className="col-sm-6">
										<div className="engaging-imgs">
											<img className="engaging-img engaging-mob" src={png_engaging_mob}/>
										</div>
									</div>
								</div>
							</div>
						</section>
						<section id="love">
							<div className="container">
								<div className="row">
									<div className="col-sm-12 text-center">
										<h2 className="title">
											Các <span>Mạng Xã Hội</span> Nổi Tiếng
										</h2>
										<p>Một số lý do bạn cần biết tại sao nên dùng các nền tảng truyền thông xã hội để tiếp thị.<br/>
										</p>
									</div>
								</div>
								<div className="row tab-row">
									<div className="col-sm-12">
										<ul className="nav nav-tabs" id="myTab" role="tablist">
											<li className={"nav-item " + (this.state.showtabdes === 'facebook'? "active" : "")} role="presentation">
												<Link className={"nav-link " + (this.state.showtabdes === 'facebook'? "active" : "")} id="home-tab" data-toggle="tab" to="/" role="tab" aria-controls="home" aria-selected="true" onClick={(e) => {this.changeShowTabDes("facebook")}}>
													<div className="single-card fb">
														<div className="card-icon">
															<span></span>
														</div>
														<div className="card-txt">
															<p>Facebook <br/>Đọc thêm </p>
															<span className="tab-icon">
																<i aria-hidden="true" className="far fa-arrow-alt-circle-up"></i>
															</span>
														</div>
													</div>
												</Link>
											</li>
											<li className={"nav-item " + (this.state.showtabdes === 'instagram'? "active" : "")} role="presentation">
												<Link className={"nav-link " + (this.state.showtabdes === 'instagram'? "active" : "")} id="profile-tab" data-toggle="tab" href="/" role="tab" aria-controls="profile" aria-selected="false" onClick={(e) => {this.changeShowTabDes("instagram")}}>
													<div className="single-card insta">
														<div className="card-icon">
															<span></span>
														</div>
														<div className="card-txt">
															<p>Instagram <br/>Đọc thêm </p>
															<span className="tab-icon">
																<i aria-hidden="true" className="far fa-arrow-alt-circle-up"></i>
															</span>
														</div>
													</div>
												</Link>
											</li>
											<li className={"nav-item " + (this.state.showtabdes === 'twitter'? "active" : "")} role="presentation">
												<Link className={"nav-link " + (this.state.showtabdes === 'twitter'? "active" : "")} id="contact-tab" data-toggle="tab" href="/" role="tab" aria-controls="contact" aria-selected="false" onClick={(e) => {this.changeShowTabDes("twitter")}}>
													<div className="single-card twiter">
														<div className="card-icon">
															<span></span>
														</div>
														<div className="card-txt">
															<p>Twitter <br/>Đọc thêm </p>
															<span className="tab-icon">
																<i aria-hidden="true" className="far fa-arrow-alt-circle-up"></i>
															</span>
														</div>
													</div>
												</Link>
											</li>
											<li className={"nav-item " + (this.state.showtabdes === 'youtube'? "active" : "")} role="presentation">
												<Link className={"nav-link " + (this.state.showtabdes === 'youtube'? "active" : "")} id="youtube" data-toggle="tab" href="/" role="tab" aria-controls="youtube" aria-selected="false" onClick={(e) => {this.changeShowTabDes("youtube")}}>
													<div className="single-card ytube">
														<div className="card-icon">
															<span></span>
														</div>
														<div className="card-txt">
															<p>Youtube <br/>Đọc thêm </p>
															<span className="tab-icon">
																<i aria-hidden="true" className="far fa-arrow-alt-circle-up"></i>
															</span>
														</div>
													</div>
												</Link>
											</li>
											<li className={"nav-item " + (this.state.showtabdes === 'tiktok'? "active" : "")} role="presentation">
												<Link className={"nav-link " + (this.state.showtabdes === 'tiktok'? "active" : "")} id="tiktok" data-toggle="tab" href="/" role="tab" aria-controls="tiktok" aria-selected="false" onClick={(e) => {this.changeShowTabDes("tiktok")}}>
													<div className="single-card tiktok">
														<div className="card-icon">
															<span></span>
														</div>
														<div className="card-txt">
															<p>Tiktok <br/>Đọc thêm </p>
															<span className="tab-icon">
																<i aria-hidden="true" className="far fa-arrow-alt-circle-up"></i>
															</span>
														</div>
													</div>
												</Link>
											</li>
										</ul>
										<div className="tab-content" id="myTabContent">
											<div className={"tab-pane fade " + (this.state.showtabdes === 'facebook'? "active in" : "")} id="home" role="tabpanel" aria-labelledby="home-tab">
												<div className="row">
													<div className="col-sm-6">
														<div className="tab-img-wrap">
															<span className="tab-img-icon">
																<i className="fa fa-facebook-f" aria-hidden="true"></i>
															</span>
															<img className="img-responsive" src={png_Vector_Smart_Object3} alt="tab-img"/>
														</div>
													</div>
													<div className="col-sm-6">
														<div className="tab-content-txt">
															<img className="img-responsive" src={png_Vector_Smart_Object4}/>
															<div className="tab-content-slider slick-initialized slick-slider slick-dotted">
																<div className="slick-list draggable">
																	<div className="slick-track" style={{"opacity": "1", "width": "3640px"}}>
																		<div className="slick-slide slick-cloned" data-slick-index="-1" aria-hidden="true" tabindex="-1" style={{"width": "510px"}}>
																			<h2 className="title">Dịch vụ <span>Facebook</span>
																			</h2>
																			<p>Facebook chắc chắn là nền tảng truyền thông xã hội phổ biến nhất hiện có với nhiều ưu điểm đi kèm với nó. Nó chủ yếu là một trang mạng xã hội, tuy nhiên nó có thể được sử dụng như một công cụ hữu ích để quảng bá và quảng cáo cho một doanh nghiệp. Chúng ta có thể sử dụng Facebook để quảng bá thương hiệu, tiếp thị công ty hoặc tăng tiếp cận về dịch vụ hoặc sản phẩm. .. Ở đây tại {domainSite}, chúng tôi không chỉ cung cấp cho bạn số lượng người theo dõi lớn mà còn rất nhiều dịch vụ khác liên quan đến Facebook.</p>
																			<Link to="/" className="btn btn-primary-cus" onClick={(e) => {this.changeFormLogRegister(2)}}>ĐĂNG KÝ</Link>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div className={"tab-pane fade " + (this.state.showtabdes === 'instagram'? "active in" : "")} id="profile" role="tabpanel" aria-labelledby="profile-tab">
												<div className="row">
													<div className="col-sm-6">
														<div className="tab-img-wrap">
															<span className="tab-img-icon">
																<i className="fa fa-facebook-f" aria-hidden="true"></i>
															</span>
															<img className="img-responsive" src={png_Vector_Smart_Object3} alt="tab-img"/>
														</div>
													</div>
													<div className="col-sm-6">
														<div className="tab-content-txt">
															<img className="img-responsive" src={png_Vector_Smart_Object4}/>
															<div className="tab-content-slider slick-initialized slick-slider slick-dotted">
																<div className="slick-list draggable">
																	<div className="slick-track" style={{"opacity": "1", "width": "3640px"}}>
																		<div className="slick-slide slick-cloned" data-slick-index="-1" aria-hidden="true" tabindex="-1" style={{"width": "510px"}}>
																			<h2 className="title">Dịch vụ <span>Instagram</span>
																			</h2>
																			<p>Instagram bao gồm các dịch vụ tăng theo dõi, tăng tim, tăng bình luận sử dụng tài khoản Instagram Việt đang hoạt động để tăng giúp bạn cải thiện tương tác, giúp tăng độ uy tín, thu hút lượt tiếp cận và khách hàng hoặc để phục vụ với mục đích cá nhân</p>
																			<Link to="/" className="btn btn-primary-cus" onClick={(e) => {this.changeFormLogRegister(2)}}>ĐĂNG KÝ</Link>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div className={"tab-pane fade " + (this.state.showtabdes === 'twitter'? "active in" : "")} id="contact" role="tabpanel" aria-labelledby="youtube">
												<div className="row">
													<div className="col-sm-6">
														<div className="tab-img-wrap">
															<span className="tab-img-icon">
																<i className="fa fa-facebook-f" aria-hidden="true"></i>
															</span>
															<img className="img-responsive" src={png_Vector_Smart_Object3} alt="tab-img"/>
														</div>
													</div>
													<div className="col-sm-6">
														<div className="tab-content-txt">
															<img className="img-responsive" src={png_Vector_Smart_Object4}/>
															<div className="tab-content-slider slick-initialized slick-slider slick-dotted">
																<div className="slick-list draggable">
																	<div className="slick-track" style={{"opacity": "1", "width": "3640px"}}>
																		<div className="slick-slide slick-cloned" data-slick-index="-1" aria-hidden="true" tabindex="-1" style={{"width": "510px"}}>
																			<h2 className="title">Dịch vụ <span>Twitter</span>
																			</h2>
																			<p>Twitter bao gồm các dịch vụ tăng theo dõi, tăng tim, tăng bình luận sử dụng tài khoản Twitter Việt đang hoạt động để tăng giúp bạn cải thiện tương tác, giúp tăng độ uy tín, thu hút lượt tiếp cận và khách hàng hoặc để phục vụ với mục đích cá nhân</p>
																			<Link to="/" className="btn btn-primary-cus" onClick={(e) => {this.changeFormLogRegister(2)}}>ĐĂNG KÝ</Link>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div className={"tab-pane fade " + (this.state.showtabdes === 'youtube'? "active in" : "")} id="youtube" role="tabpanel" aria-labelledby="tiktok">
												<div className="row">
													<div className="col-sm-6">
														<div className="tab-img-wrap">
															<span className="tab-img-icon">
																<i className="fa fa-facebook-f" aria-hidden="true"></i>
															</span>
															<img className="img-responsive" src={png_Vector_Smart_Object3} alt="tab-img"/>
														</div>
													</div>
													<div className="col-sm-6">
														<div className="tab-content-txt">
															<img className="img-responsive" src={png_Vector_Smart_Object4}/>
															<div className="tab-content-slider slick-initialized slick-slider slick-dotted">
																<div className="slick-list draggable">
																	<div className="slick-track" style={{"opacity": "1", "width": "3640px"}}>
																		<div className="slick-slide slick-cloned" data-slick-index="-1" aria-hidden="true" tabindex="-1" style={{"width": "510px"}}>
																			<h2 className="title">Dịch vụ <span>Youtube</span>
																			</h2>
																			<p>Youtube bao gồm các dịch vụ tăng theo dõi, tăng tim, tăng bình luận, tăng view sử dụng tài khoản Youtube Việt đang hoạt động để tăng giúp bạn cải thiện tương tác, giúp tăng độ uy tín, thu hút lượt tiếp cận và khách hàng hoặc để phục vụ với mục đích cá nhân</p>
																			<Link to="/" className="btn btn-primary-cus" onClick={(e) => {this.changeFormLogRegister(2)}}>ĐĂNG KÝ</Link>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div className={"tab-pane fade " + (this.state.showtabdes === 'tiktok'? "active in" : "")} id="tiktok" role="tabpanel" aria-labelledby="sound">
												<div className="row">
													<div className="col-sm-6">
														<div className="tab-img-wrap">
															<span className="tab-img-icon">
																<i className="fa fa-facebook-f" aria-hidden="true"></i>
															</span>
															<img className="img-responsive" src={png_Vector_Smart_Object3} alt="tab-img"/>
														</div>
													</div>
													<div className="col-sm-6">
														<div className="tab-content-txt">
															<img className="img-responsive" src={png_Vector_Smart_Object4}/>
															<div className="tab-content-slider slick-initialized slick-slider slick-dotted">
																<div className="slick-list draggable">
																	<div className="slick-track" style={{"opacity": "1", "width": "3640px"}}>
																		<div className="slick-slide slick-cloned" data-slick-index="-1" aria-hidden="true" tabindex="-1" style={{"width": "510px"}}>
																			<h2 className="title">Dịch vụ <span>Tiktok</span>
																			</h2>
																			<p>Tiktok bao gồm các dịch vụ tăng theo dõi, tăng tim, tăng bình luận, tăng view sử dụng tài khoản Tiktok Việt đang hoạt động để tăng giúp bạn cải thiện tương tác, giúp tăng độ uy tín, thu hút lượt tiếp cận và khách hàng hoặc để phục vụ với mục đích cá nhân</p>
																			<Link to="/" className="btn btn-primary-cus" onClick={(e) => {this.changeFormLogRegister(2)}}>ĐĂNG KÝ</Link>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</section>
						<section id="feel">
							<div className="container">
								<div className="row">
									<div className="col-sm-6">
										<h2 className="title">Tại sao chọn chúng tôi</h2>
										<br/>
										<p style={{"color": "#fff"}}>Chúng tôi cung cấp cho bạn dịch vụ ổn định và giá cả phải chăng. Bằng cách này, bạn sẽ dễ dàng tăng mức độ phổ biến của mình trên mạng xã hội hơn rất nhiều! Chúng tôi là {domainSite}. Thực hiện tiếp thị trên mạng xã hội hoặc quảng cáo trên mạng xã hội sẽ giúp ích cho phương tiện truyền thông xã hội của bạn. Chúng tôi có một số nhà tiếp thị truyền thông xã hội trong đại lý tiếp thị truyền thông xã hội của chúng tôi. Chiến lược tiếp thị truyền thông xã hội này sẽ thay đổi quan điểm của bạn!</p>
									</div>
									<div className="col-sm-6">
										<img className="feel-animate-img img-responsive" src={png_feel_img_kholvz}/>
									</div>
								</div>
								<div className="row feel-btn-row text-center">
									<div className="col-sm-12">
										<Link to="/" className="btn btn-primary-cus" onClick={(e) => {this.changeFormLogRegister(2)}}>ĐĂNG KÝ</Link>
									</div>
								</div>
							</div>
						</section>
						<section id="provide">
							<div className="container">
								<div className="row">
									<div className="col-sm-12 text-center">
										<h2 className="title">
											<span>Dịch vụ</span> chúng tôi cung cấp
										</h2>
										<p>{domainSite} cung cấp hầu hết tất cả các dịch vụ mạng xã hội rẻ nhất và nhanh nhất trên thế giới với tất cả các phương thức thanh toán được hỗ trợ.</p>
									</div>
								</div>
								<div className="row provide-row">
									<div className="col-sm-6">
										<div className="provide-wrap provide-left text-right">
											<div className="single-provider">
												<h2>Facebook</h2>
												<p>Facebook Page Like Thật <br/>Facebook Post Like <br/>Facebook Follow <br/>Facebook Auto Like <br/>và nhiều nữa... </p>
												<div className="provider-img">
													<span className="provider-icon fb">
														<i className="fa fa-facebook-f" aria-hidden="true"></i>
													</span>
													<img className="img-responsive" src={png_provider_img}/>
												</div>
											</div>
											<div className="single-provider">
												<h2>Instagram</h2>
												<p>Instagram Page Likes Thật <br/>Instagram Post Like <br/>Instagram Follow <br/>Instagram Auto Like <br/>và nhiều nữa... </p>
												<div className="provider-img">
													<span className="provider-icon insta">
														<i className="fa fa-instagram" aria-hidden="true"></i>
													</span>
													<img className="img-responsive" src={png_provider_img}/>
												</div>
											</div>
										</div>
									</div>
									<div className="col-sm-6">
										<div className="provide-wrap provide-right text-left">
											<div className="single-provider">
												<div className="provider-img">
													<span className="provider-icon twit">
														<i className="fa fa-twitter" aria-hidden="true"></i>
													</span>
													<img className="img-responsive" src={png_provider_img}/>
												</div>
												<h2>Twitter</h2>
												<p>Twitter Page Likes Thật <br/>Twitter Post Like <br/>Twitter Follow <br/>Twitter Auto Like <br/>và nhiều nữa... </p>
											</div>
											<div className="single-provider">
												<div className="provider-img">
													<span className="provider-icon yt">
														<i className="fa fa-youtube" aria-hidden="true"></i>
													</span>
													<img className="img-responsive" src={png_provider_img}/>
												</div>
												<h2>Youtube</h2>
												<p>Youtube Page Likes Thật <br/>Youtube Post Like <br/>Youtube Follow <br/>Youtube Auto Like <br/>và nhiều nữa... </p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</section>
						<section id="says">
							<div className="container-fluid">
								<div className="row">
									<div className="col-sm-12 text-center">
										<h2 className="title">
											Mọi người nói gì
										</h2>
									</div>
								</div>
								<div className="row testi-row">
									<div className="col-sm-2">
										<div className="test-wrap">
											<img className="img-responsive testi-img" src={png_testi_1}/>
											<div className="test-desc text-center">
												<h3 className="testi-name">Thảo My</h3>
												<span className="test-stars"></span>
												<p>Họ có nhiều dịch vụ phù hợp với tôi và giá cả phải chăng. Đã chi 1.000.000 vnđ và kênh của tôi đã mang lại cho tôi nhiều hơn thế nữa. Cảm ơn các bạn!</p>
											</div>
										</div>
										<div className="test-wrap">
											<img className="img-responsive testi-img" src={png_qzB2zKk}/>
											<div className="test-desc text-center">
												<h3 className="testi-name">Huyền Trang</h3>
												<span className="test-stars"></span>
												<p>Tôi đã sử dụng hơn 10 dịch vụ các loại và bây giờ tôi có thể đảm bảo rằng công ty này là tốt nhất. Giao hàng nhanh chóng và hỗ trợ là rất hỗ trợ.</p>
											</div>
										</div>
									</div>
									<div className="col-sm-2">
										<div className="test-wrap">
											<img className="img-responsive testi-img" src={png_testi_3}/>
											<div className="test-desc text-center">
												<h3 className="testi-name">Thu An </h3>
												<span className="test-stars"></span>
												<p>Tôi muốn bày tỏ lòng biết ơn đối với chất lượng và dịch vụ tuyệt vời mà bạn đã cung cấp trên facebook của tôi!</p>
											</div>
										</div>
										<div className="test-wrap">
											<img className="img-responsive testi-img" src={png_testi_4}/>
											<div className="test-desc text-center">
												<h3 className="testi-name">Anh Tuấn</h3>
												<span className="test-stars"></span>
												<p>Dịch vụ thật tuyệt vời! Tôi đã xác minh được id trên insta của mình bằng cách sử dụng các dịch vụ tương tác. Cảm ơn rất nhiều vì các dịch vụ tuyệt vời của Instagram</p>
											</div>
										</div>
									</div>
									<div className="col-sm-2">
										<div className="test-wrap">
											<img className="img-responsive testi-img" src={png_testi_5}/>
											<div className="test-desc text-center">
												<h3 className="testi-name">Bảo Khánh</h3>
												<span className="test-stars"></span>
												<p>Hiệu quả cuối cùng cao và giá rất tốt! tốt nhất!</p>
											</div>
										</div>
										<div className="test-wrap">
											<img className="img-responsive testi-img" src={png_testi_6}/>
											<div className="test-desc text-center">
												<h3 className="testi-name">Quang Chính</h3>
												<span className="test-stars"></span>
												<p>Trong vài phút, tôi đã đạt được số lượng người đăng ký cần thiết cho kênh Youtube của mình. Cảm ơn bạn vì dịch vụ tuyệt vời! </p>
											</div>
										</div>
									</div>
									<div className="col-sm-2">
										<div className="test-wrap">
											<img className="img-responsive testi-img" src={png_testi_7}/>
											<div className="test-desc text-center">
												<h3 className="testi-name">Trịnh Huy </h3>
												<span className="test-stars"></span>
												<p>Đây là trang web đáng tin cậy. Tôi đã giành được nhiều quà của Facebook, Instagram nhờ sử dụng dịch vụ {domainSite}.</p>
											</div>
										</div>
										<div className="test-wrap">
											<img className="img-responsive testi-img" src={png_testi_8}/>
											<div className="test-desc text-center">
												<h3 className="testi-name">Nguyễn Hà</h3>
												<span className="test-stars"></span>
												<p>Tôi đã tăng số lượng người theo dõi và lượt thích trên tất cả các hồ sơ người mẫu trên instagram của mình, điều này đã giúp tôi trở nên nổi tiếng và được nhiều người biết đến hơn. Cảm ơn {domainSite} vì một dịch vụ tuyệt vời như vậy </p>
											</div>
										</div>
									</div>
									<div className="col-sm-2">
										<div className="test-wrap">
											<img className="img-responsive testi-img" src={png_testi_9}/>
											<div className="test-desc text-center">
												<h3 className="testi-name">Anh Quân</h3>
												<span className="test-stars"></span>
												<p>Các quản trị viên luôn sẵn sàng và rất hỗ trợ rất tốt.</p>
											</div>
										</div>
										<div className="test-wrap">
											<img className="img-responsive testi-img" src={png_testi_10}/>
											<div className="test-desc text-center">
												<h3 className="testi-name">Thu Thủy</h3>
												<span className="test-stars"></span>
												<p>Trải nghiệm tuyệt vời. Tất cả các dịch vụ đang hoạt động tốt. Hỗ trợ giải đáp mọi thắc mắc cực nhanh.</p>
											</div>
										</div>
									</div>
									<div className="col-sm-2">
										<div className="test-wrap">
											<img className="img-responsive testi-img" src={png_testi_11}/>
											<div className="test-desc text-center">
												<h3 className="testi-name">Hà Trân</h3>
												<span className="test-stars"></span>
												<p>Tôi đã mua 1 triệu người theo dõi trên trang cá nhân Instagram từ {domainSite}. Không có vấn đề gì và tất cả đều hoàn thành đúng thời hạn. Cảm ơn vì các dịch vụ</p>
											</div>
										</div>
										<div className="test-wrap">
											<img className="img-responsive testi-img" src={png_testi_12}/>
											<div className="test-desc text-center">
												<h3 className="testi-name">Thu Vân </h3>
												<span className="test-stars"></span>
												<p>Tôi giới thiệu cho tất cả mọi người! Chỉ với dịch vụ của {domainSite}, tôi đã nhanh chóng có thêm một số lượng lớn người đăng ký trên Telegram</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</section>
						<section id="organic">
							<div className="container">
								<div className="row">
									<div className="col-sm-12 text-center">
										<h2 className="title">Tăng lưu lượng truy cập &amp; <br/> Nâng cao uy tín sản phẩm, doanh nghiệp của bạn </h2>
									</div>
								</div>
								<div className="real-row">
									<div className="col-sm-4">
										<div className="real-wrap">
											<div className="real-icon">
												<span>
													<i className="far fa-thumbs-up" aria-hidden="true"></i>
												</span>
											</div>
											<div className="real-card">
												<h3 className="real-title">Like Thật</h3>
												<p className="real-desc"> Mua lượt thích người thực và phát triển phạm vi tiếp cận của bạn một cách hiệu quả với chúng tôi .. </p>
											</div>
										</div>
									</div>
									<div className="col-sm-4">
										<div className="real-wrap">
											<div className="real-icon">
												<span>
													<i className="fas fa-user-plus" aria-hidden="true"></i>
												</span>
											</div>
											<div className="real-card">
												<h3 className="real-title">Follow Thật</h3>
												<p className="real-desc"> Mua những người theo dõi thực và có được phạm vi tiếp cận của bạn một cách hiệu quả với chúng tôi. </p>
											</div>
										</div>
									</div>
									<div className="col-sm-4">
										<div className="real-wrap">
											<div className="real-icon">
												<span>
													<i className="far fa-eye" aria-hidden="true"></i>
												</span>
											</div>
											<div className="real-card">
												<h3 className="real-title">View thực</h3>
												<p className="real-desc"> Mua lượt xem thực ngay lập tức cho tất cả các nền tảng truyền thông xã hội. </p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</section>
						<section id="faq">
							<div className="container">
								<div className="row">
									<div className="col-sm-12 text-center">
										<h2 className="title">
											Các câu hỏi <span>thường gặp</span>
										</h2>
									</div>
								</div>
								<div className="row faq-row">
									<div className="col-sm-6">
										<div className="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
											<div className="panel panel-default">
												<div className="panel-heading" role="tab" id="headingOne">
													<h4 className="panel-title">
														<Link role="button" data-toggle="collapse" data-parent="#accordion" href="/`" aria-expanded={(this.state.showaskquestion == 1 ? "true" : "false")} aria-controls="collapseOne" onClick={(e) => {this.changeShowAskQuestion(1)}}>
															<img className="img-responsive" src={png_faq}/> Dịch vụ Mạng Xã hội là gì? </Link>
													</h4>
												</div>
												<div id="collapseOne" className={"panel-collapse collapse " + (this.state.showaskquestion == 1 ? "in" : "")} role="tabpanel" aria-labelledby="headingOne">
													<div className="panel-body"> Dịch vụ Mạng Xã hội là việc sử dụng các nền tảng và trang web truyền thông xã hội để quảng bá sản phẩm hoặc dịch vụ. </div>
												</div>
											</div>
											<div className="panel panel-default">
												<div className="panel-heading" role="tab" id="headingTwo">
													<h4 className="panel-title">
														<Link className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="/" aria-expanded={(this.state.showaskquestion == 2 ? "true" : "false")} aria-controls="collapseTwo" onClick={(e) => {this.changeShowAskQuestion(2)}}>
															<img className="img-responsive" src={png_faq}/> Tại sao chọn {domainSite}? </Link>
													</h4>
												</div>
												<div id="collapseTwo" className={"panel-collapse collapse " + (this.state.showaskquestion == 2 ? "in" : "")} role="tabpanel" aria-labelledby="headingTwo">
													<div className="panel-body"> Chúng tôi cung cấp dịch vụ mạng xã hội tốt nhất trong số các đối thủ cạnh tranh của chúng tôi, với hỗ trợ 24 * 7 </div>
												</div>
											</div>
											<div className="panel panel-default">
												<div className="panel-heading" role="tab" id="headingThree">
													<h4 className="panel-title">
														<Link className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="/" aria-expanded={(this.state.showaskquestion == 3 ? "true" : "false")} aria-controls="collapseThree" onClick={(e) => {this.changeShowAskQuestion(3)}}>
															<img className="img-responsive" src={png_faq}/> Có an toàn để sử dụng {domainSite} không? </Link>
													</h4>
												</div>
												<div id="collapseThree" className={"panel-collapse collapse " + (this.state.showaskquestion == 3 ? "in" : "")} role="tabpanel" aria-labelledby="headingThree">
													<div className="panel-body"> Có, an toàn khi sử dụng {domainSite} vì tất cả thông tin của bạn bảo mật an toàn và chúng tôi chỉ yêu cầu thông tin cần thiết cơ bản để thực hiện đơn đặt hàng của bạn. </div>
												</div>
											</div>
											<div className="panel panel-default">
												<div className="panel-heading" role="tab" id="headingfour">
													<h4 className="panel-title">
														<Link className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="/" aria-expanded={(this.state.showaskquestion == 4 ? "true" : "false")} aria-controls="collapseThree" onClick={(e) => {this.changeShowAskQuestion(4)}}>
															<img className="img-responsive" src={png_faq}/> Có thể mua nhiều đơn hàng 1 lúc? </Link>
													</h4>
												</div>
												<div id="collapsefour" className={"panel-collapse collapse " + (this.state.showaskquestion == 4 ? "in" : "")} role="tabpanel" aria-labelledby="headingThree">
													<div className="panel-body"> Có, bạn có thể đặt nhiều dịch vụ, hệ thống sẽ xử lý tự động tất cả. </div>
												</div>
											</div>
										</div>
									</div>
									<div className="col-sm-6">
										<div className="panel-group" id="accordion1" role="tablist" aria-multiselectable="true">
											<div className="panel panel-default">
												<div className="panel-heading" role="tab" id="headingOne">
													<h4 className="panel-title">
														<Link role="button" data-toggle="collapse" data-parent="#accordion1" href="/" aria-expanded={(this.state.showaskquestion2 == 1 ? "true" : "false")} aria-controls="collapseOne" onClick={(e) => {this.changeShowAskQuestion2(1)}}>
															<img className="img-responsive" src={png_faq}/> Hệ Thống mua bán</Link>
													</h4>
												</div>
												<div id="collapseOne1" className={"panel-collapse collapse " + (this.state.showaskquestion2 == 1 ? "in" : "")} role="tabpanel" aria-labelledby="headingOne">
													<div className="panel-body"> Chúng tôi cung cấp cho bạn một giao diện tối ưu nhất giúp bạn có thể mua hoặc bán lại các dịch vụ mạng xã hội (bao gồm người theo dõi, lượt thích, nhận xét, người đăng ký và lượt xem) cho tất cả các nền tảng mạng xã hội với mức giá phù hợp. </div>
												</div>
											</div>
											<div className="panel panel-default">
												<div className="panel-heading" role="tab" id="headingTwo">
													<h4 className="panel-title">
														<Link className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion1" href="/" aria-expanded={(this.state.showaskquestion2 == 2 ? "true" : "false")} aria-controls="collapseTwo" onClick={(e) => {this.changeShowAskQuestion2(2)}}>
															<img className="img-responsive" src={png_faq}/> Làm sao tôi có thể trở thành đại lý</Link>
													</h4>
												</div>
												<div id="collapseTwo2" className={"panel-collapse collapse " + (this.state.showaskquestion2 == 2 ? "in" : "")} role="tabpanel" aria-labelledby="headingTwo">
													<div className="panel-body"> Bạn chỉ cần mua 1 tên miền riêng là đã có thể trở thành đại lý của chúng tôi </div>
												</div>
											</div>
											<div className="panel panel-default">
												<div className="panel-heading" role="tab" id="headingThree3">
													<h4 className="panel-title">
														<Link className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion1" href="/" aria-expanded={(this.state.showaskquestion2 == 3 ? "true" : "false")} aria-controls="collapseThree" onClick={(e) => {this.changeShowAskQuestion2(3)}}>
															<img className="img-responsive" src={png_faq}/> Các Phương thức Thanh toán </Link>
													</h4>
												</div>
												<div id="collapseThree3" className={"panel-collapse collapse " + (this.state.showaskquestion2 == 3 ? "in" : "")} role="tabpanel" aria-labelledby="headingThree">
													<div className="panel-body"> Chúng tôi chấp nhận chuyển khoản ngân hàng, momo, thẻ cào điện thoại... </div>
												</div>
											</div>
											<div className="panel panel-default">
												<div className="panel-heading" role="tab" id="headingfour">
													<h4 className="panel-title">
														<Link className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion1" href="/" aria-expanded={(this.state.showaskquestion2 == 4 ? "true" : "false")} aria-controls="collapseThree" onClick={(e) => {this.changeShowAskQuestion2(4)}}>
															<img className="img-responsive" src={png_faq}/> Tôi có được giảm giá không? </Link>
													</h4>
												</div>
												<div id="collapsefour4" className={"panel-collapse collapse " + (this.state.showaskquestion2 == 4 ? "in" : "")} role="tabpanel" aria-labelledby="headingThree">
													<div className="panel-body"> Vì giá rẻ nhất & tốt nhất với túi tiền, giá bạn thấy là giá tốt nhất thị trường hiện nay. </div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</section>
						<footer>
							<div className="container">
								<div className="ftr-top">
									<div className="row">
										<div className="col-sm-9">
											<div className="ftr-logo"> {domainSite} </div>
											<div className="ftr-txt">
												<p>
													{domainSite} đã có nhiều năm hoạt động trong lĩnh vực Online Maketing, nắm bắt được sự thay đổi và định hứng được sự phát triển của thị trường, {domainSite} đi đầu trong lĩnh vực online maketing, cung cấp đa dạng các dịch vụ, giúp người dùng luôn tiếp cận được với mức giá phải chăng và hiệu quả maketing tối ưu nhất.
												</p>
											</div>
										</div>
										<div className="col-sm-3">
											<h2 className="ftr-title">Liên kết</h2>
											<ul className="ftr-nav">
												<li className="active">
													<a href="/">Đăng nhập</a>
												</li>
												<li>
													<Link to="/" onClick={(e) => {this.changeFormLogRegister(2)}}>Đăng ký</Link>
												</li>
												<li>
													<Link to="/services" onClick={(e) => {this.changeFormLogRegister(3)}}>Dịch vụ</Link>
												</li>
											</ul>
										</div>
										{/* <div className="col-sm-3">
											<h2 className="ftr-title">Quick Links</h2>
											<ul className="ftr-nav">
												<li>
													<a href="#">Facebook</a>
												</li>
												<li>
													<a href="#">Twitter</a>
												</li>
												<li>
													<a href="https://instagram.com/socialmatrixofficial">Instagram</a>
												</li>
												<li>
													<a href="#">Youtube</a>
												</li>
												<li>
													<a href="#">Vimeo</a>
												</li>
												<li>
													<a href="#">Linkedin</a>
												</li>
											</ul>
										</div>
										<div className="col-sm-3">
											<h2 className="ftr-title">Subscribe </h2>
											<div className="newsletter">
												<div className="input-group">
													<input type="email" className="form-control" placeholder="Email" aria-describedby="basic-addon2"/>
													<span className="input-group-addon" id="basic-addon2">
														<i className="far fa-arrow-alt-circle-right" aria-hidden="true"></i>
													</span>
												</div>
											</div>
											<div className="follow-us">
												<h2 className="ftr-title">Follow Us </h2>
												<ul>
													<li>
														<a href="#">
															<i className="fa fa-facebook-f" aria-hidden="true"></i>
														</a>
													</li>
													<li>
														<a href="https://instagram.com/socialmatrixofficial">
															<i className="fab fa-instagram" aria-hidden="true"></i>
														</a>
													</li>
													<li>
														<a href="#">
															<i className="fab fa-twitter" aria-hidden="true"></i>
														</a>
													</li>
													<li>
														<a href="#">
															<i className="fab fa-google-plus-g" aria-hidden="true"></i>
														</a>
													</li>
												</ul>
											</div>
										</div> */}
									</div>
								</div>
								<div className="ftr-btm">
									<p className="text-center">Copyright 2021 © {domainSite} - All rights reserved.</p>
								</div>
							</div>
						</footer>
					</div>
				</div>
			</Fragment>
		</Router>
	);
	var path_check = window.location.pathname;
	if (window.location.pathname.length > 1) {
		path_check = window.location.pathname.replace(/\/+$/g, '');
	}
	const formRegister = (
		<Fragment>
			<RegisterModal10 action_login={(e) => {this.changeFormLogRegister(1)}} action_service={(e) => {this.changeFormLogRegister(3)}} />
		</Fragment>
	);
	const formService = (
		<Fragment>
			<ServiceModal10 action_login={(e) => {this.changeFormLogRegister(1)}} action_register={(e) => {this.changeFormLogRegister(2)}} />
		</Fragment>
	);
	const formForgotPassword = (
		<Fragment>
			<ForgotPasswordModal10 action_login={(e) => {this.changeFormLogRegister(1)}} action_register={(e) => {this.changeFormLogRegister(2)}} action_service={(e) => {this.changeFormLogRegister(3)}} action_reset={path_check} />
		</Fragment>
	);
	return (
		<Fragment>
			{
				path_check === '/resetpassword' ?
					formForgotPassword
				: this.state.modal == 1 ?
					formLogin
				: this.state.modal == 2 ?
					formRegister
				: this.state.modal == 3 ?
					formService
				: this.state.modal == 4 ?
					formForgotPassword
				: ''
			}
		</Fragment>
	);
  }
}
Login.propTypes = {
  auth: PropTypes.object.isRequired,
  error: PropTypes.object,
  login: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  error: state.error
});
export default connect(mapStateToProps, { login, clearErrors })(Login);
