import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import axios from  'axios';
import store from '../../../store';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { creatItemBuffSub, getInfoTiktok, getConfigMoDong } from '../../../actions/vipviewTiktok';
import parse from 'html-react-parser';
import Swal from 'sweetalert2';
import ServicesDescription from "../ServicesDescription";
import { isMobile } from 'react-device-detect';

class Taotientrinh extends Component {
	componentDidMount() {
        this.props.getConfigMoDong('tiktokvipview');
	};
	
	state = {
		showDescription: isMobile? false : true
	}
	
	searchIdTiktok = (e) => {
		let valueChange = e.target.value;
		let link = valueChange;
		let dataForm = this.props.buffsub.formData;
		let checkerr = 0;
		var loaigetinfo = dataForm.loaiseeding;
		
		var matches = valueChange.match(/video\/+[0-9]+/g);
		if (matches != null) {
			valueChange = matches[0].replace("video/", "");
			loaigetinfo = 'liketosub';
		} else {
			var matches = valueChange.match(/@+(.*\?|.*)/g);
			if (matches != null) {
				valueChange = matches[0].replace(/[@//?]/g, "");
			}
		}
		
		if (checkerr === 0 && valueChange !== '') {
			this.props.getInfoTiktok(loaigetinfo, valueChange);
		}
		
		store.dispatch({
			type: 'CHANGE_DATA_FORM_BUFF_SUB_TIKTOK',
			payload: {name: e.target.name, value: valueChange}
		});

		if (checkerr === 0) {
			store.dispatch({
				type: 'CHANGE_DATA_FORM_BUFF_SUB_TIKTOK',
				payload: {name: 'link', value: link}
			});
		}
	}

	handleChangeDateStart = date => {
	    let dataFormOld = this.props.buffsub.formData;

		store.dispatch({
			type: 'CHANGE_DATA_FORM_BUFF_SUB_TIKTOK',
			payload: {name: 'EndDatebh', value: this.addDays(date, 7)}
		});
		store.dispatch({
			type: 'CHANGE_DATA_FORM_BUFF_SUB_TIKTOK',
			payload: {name: 'startDatebh', value: date}
		});
	}

	handleChangeDateEnd = date => {
	    let dataFormOld = this.props.buffsub.formData;

		store.dispatch({
			type: 'CHANGE_DATA_FORM_BUFF_SUB_TIKTOK',
			payload: {name: 'EndDatebh', value: date}
		});
	}

	addDays = (date, days) => {
	  	var result = new Date(date);
	  	result.setDate(result.getDate() + days);
	  	return result;
	}

	onChangeForm = (e, checknumber = 0) => {
		let valueChange = e.target.value;
		if (checknumber === 1 && e.target.name === 'sltang') {
			if (valueChange.trim() !== '') {
				valueChange = Math.abs(parseInt(valueChange));
				if (isNaN(valueChange)) {
					valueChange = '';
				}
			}
		}
		store.dispatch({
			type: 'CHANGE_DATA_FORM_BUFF_SUB_TIKTOK',
			payload: {name: e.target.name, value: valueChange}
		});
	}

	onChangeLoaiSeeding = (e) => {
		let valueChange = e.target.value;
		let dataForm = this.props.buffsub.formData;
		let dataPriceAll = this.props.buffsub.datapricebuffsub;
		let getStPriceEnd = this.props.buffsub.datapricebuffsub[valueChange].prices_web;
		
		store.dispatch({
			type: 'CHANGE_DATA_FORM_BUFF_SUB_TIKTOK',
			payload: {name: 'giatien', value: getStPriceEnd}
		});

		store.dispatch({
			type: 'CHANGE_DATA_FORM_BUFF_SUB_TIKTOK',
			payload: {name: e.target.name, value: valueChange}
		});
	}
	
	onKeyPress = (e) => {
		if (!(e.charCode >= 48 && e.charCode <= 57)) {
			e.preventDefault();
		}
	}
	
	option_tgsd = () => {
		let ar_option_tgsd = [];
		ar_option_tgsd.push(
			<option value="0.5" key="0.5">
				15 Ngày
			</option>
		);
		for (let i = 1; i <= 6; i++) {
			ar_option_tgsd.push(
				<option value={i*30} key={i}>
					{i} Tháng
				</option>
			)
		}
		return ar_option_tgsd;
	}

	onClickShowDescription = (data) => {
		this.setState({
			showDescription: data
		});
	}
	
 	render() {
 		const {formData, tiktokvipview_sv1_mo_dong} = this.props.buffsub;
		var price = Math.round((formData.goiview * formData.tgsd * formData.giatien + Number.EPSILON) * 100) / 100;
		var info = '';
		if (formData.infoTiktok.userid_tiktok !== '') {
			info = parse('<img src="'+formData.infoTiktok.avatarMedium_tiktok+'" class="avatar-user" /> ' + formData.infoTiktok.uniqueId_tiktok + ' ( sub: ' + formData.infoTiktok.fans_tiktok + ')');
		}
		
		var listDataServicesInfo = {};
		try {
			if (localStorage.getItem("listDataServicesInfo") != null) {
				listDataServicesInfo = JSON.parse(localStorage.getItem("listDataServicesInfo"));
			} else {
				listDataServicesInfo = this.props.adminpage.listDataServicesInfo;
			}
		} catch(e) {
			listDataServicesInfo = {};
		}
		var html_haydoctranhmattien = '';
		var html_haydoctranhmattien_chinh = '';
		var html_haydoctranhmattien_sv = '';
		var html_view_sv1 = '';
		if (typeof(listDataServicesInfo.tiktok_vip_view_tab) !== 'undefined') {
			html_haydoctranhmattien = parse(listDataServicesInfo.tiktok_vip_view_tab.ghichu);
			html_haydoctranhmattien_chinh = parse(listDataServicesInfo.tiktok_vip_view_tab.ghichu_chinh);
		}
		if (typeof(listDataServicesInfo.tiktok_vip_view_sv1) !== 'undefined') {
			html_view_sv1 = parse(listDataServicesInfo.tiktok_vip_view_sv1.ghichu_sv);
			if (formData.loaiseeding === "tiktokvipview_sv1") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.tiktok_vip_view_sv1.ghichu);
			}
		}
		
 		return (
    		<Fragment>
    			<div className="row">
					<div className="col-md-4">
						<label className="mt-2">Link hoặc ID trang cá nhân:</label>
					</div>
					<div className="col-md-8">
						<div className="form-group">
                            <input onChange={(e) => {this.searchIdTiktok(e)}} value={formData.profile_user} name="profile_user" type="text" className="form-control"/>
							{info}
							<h6 style={{"marginTop":"5px", "marginBottom":"0px"}}><span className="text-danger">Hãy tắt chế độ tài khoản riêng tư (Bắt buộc)</span>: <a href="https://i.imgur.com/g5ebGqF.png" target="_blank" className="font-bold"> Tại đây</a></h6>
							<h6 style={{"marginTop":"5px", "marginBottom":"0px"}}>Hướng dẫn link tiktok: <a href="https://i.imgur.com/uoLshF4.png" target="_blank" className="font-bold"> Tại đây(1)</a> hoặc <a href="https://i.imgur.com/A22d32Q.png" target="_blank" className="font-bold"> Tại đây(2)</a></h6>
							<h6 style={{"marginTop":"5px","marginBottom":"0px"}}>Link chuẩn để buff theo dõi: <span className="text-danger"> https://www.tiktok.com/@username</span> hoặc <span className="text-danger"> https://www.tiktok.com/@username/video/id_video</span></h6>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-4">
						<label className="mt-2">Loại Seeding cần tăng:</label>
					</div>
					<div className="col-sm-8">
						<div className="custom-control custom-radio custom-control-inline" style={{opacity: this.props.buffsub.datapricebuffsub['tiktokvipview_sv1'].prices_web == 0 ? '0.3' : '1' }}>
							<input onChange={(e) => {this.onChangeLoaiSeeding(e)}} value="tiktokvipview_sv1" type="radio" className="custom-control-input" id="loaiseeding_1" name="loaiseeding" checked={formData.loaiseeding === "tiktokvipview_sv1"} disabled={this.props.buffsub.datapricebuffsub['tiktokvipview_sv1'].prices_web == 0 ? 'disabled' : ''} />
							<label className="custom-control-label" htmlFor="loaiseeding_1" > SV1 {html_view_sv1} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.buffsub.datapricebuffsub['tiktokvipview_sv1'].prices_web} coin</span> {tiktokvipview_sv1_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
						</div>
					</div>
				</div>
				
				
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Gói View (là bội số của 1000 min:1000 max:100000) (*):</label>
					<div className="col-sm-8">
						<input type="number" className="form-control" id="goiview" name="goiview" placeholder="Vui Lòng Nhập Chính Xác" onChange={(e) => {this.onChangeForm(e)}} value={formData.goiview} onKeyPress={this.onKeyPress} />
					</div>
				</div>
				<div className="form-group row mt-4">
					<label className="col-sm-4 col-form-label" htmlFor="">
						Thời Gian Sử Dụng:
					</label>
					<div className="col-sm-8">
						<div className="card card-orange mt-2">
							<select className="form-control custom-select select-light" id="tgsd" name="tgsd" onChange={(e) => {this.onChangeForm(e)}} value={formData.tgsd} >
								{this.option_tgsd()}
							</select>
						</div>
					</div>
				</div>
				
				<div className="row mt-2">
					<div className="col-md-4">
						<label className="mt-2">Ghi chú:</label>
					</div>
					<div className="col-md-8">
						<div className="form-group">
                            <textarea onChange={(e) => {this.onChangeForm(e)}} name="ghichu" value={formData.ghichu} className="form-control" rows="2" placeholder="Nhập nội dung ghi chú về tiến trình của bạn"></textarea>
                        </div>
					</div>
				</div>
				
				<button type="button" className="btn badge-primary-cus btn-rounded btn-show-description-cus" onClick={(e) => {this.onClickShowDescription(!this.state.showDescription)}}>
					{
						this.state.showDescription?
							<i className="fa fa-angle-up" style={{"marginRight": "5px"}} aria-hidden="true"></i>
						:
							<i className="fa fa-angle-down" style={{"marginRight": "5px"}} aria-hidden="true"></i>
					}
					Thông tin dịch vụ
				</button>
				
				<ServicesDescription allservicesinfo={listDataServicesInfo} server={formData.loaiseeding} tab="tiktok_vip_view" giatien={formData.giatien} baohanh={formData.baohanh} showDescription={this.state.showDescription} />
				
				<div className="row mt-2" style={{"display": this.state.showDescription? "flex" : "none"}}>
				    <div className="col-md-12 bold" style={{"marginBottom": "10px"}}>
						<div className="card card-select-cus">
							<div className="card-body card-body-select-cus">
								<p className="mb-0 mt-3 text-wrap">
									<ul>
										<li>1 ID KHÔNG CÀI 2 ĐƠN CÙNG LÚC TRONG HỆ THỐNG ! ĐƠN CŨ XONG MỚI CÀI ĐƠN MỚI ! CỐ TÌNH CÀI BỊ HỤT SỐ LƯỢNG SẼ KHÔNG XỬ LÝ !</li>
										<li>Nếu ID đang chạy trên hệ thống mà bạn vẫn mua id đó cá hệ thống bên khác, nếu có tình trạng hụt số lượng giữa 2 bên thì sẽ không được xử lí!</li>
										{html_haydoctranhmattien}
										{html_haydoctranhmattien_chinh}
										{html_haydoctranhmattien_sv}
										<li>Nếu gặp lỗi hãy nhắn tin hỗ trợ phía bên phải góc màn hình hoặc vào mục liên hệ hỗ trợ để được hỗ trợ tốt nhất!</li>
									</ul>
								</p>
								<span className="badge badge-cus position-absolute-cus badge-top-left-cus badge-primary-cus badge-pill-cus"> HÃY ĐỌC ĐỂ TRÁNH MẤT TIỀN KHI SỬ DỤNG! </span>
							</div>
						</div>
				    </div>
				</div>
				<div className="row mt-4">
					<div className="col-12 text-center bold">
						<div className="card card-info">
							<div className="card-body">
								<h5 className="font-bold">Tổng: <span className="bold green"><span>{price}</span> Coin</span></h5>
								<h6 className="mb-0 bold"><span>Giá <span className="green">1 view</span> / 1 ngày: <span className="green">{formData.giatien} Coin</span></span></h6>
							</div>
						</div>
					</div>
				</div>

				<div className="row mt-4">
					<div className="col-md-12">
						<div className='form-group float-right w-100'>
							<button onClick={(e) => {this.props.creatItemBuffSub(formData)}} type="button" className="btn btn-hero-primary btn-rounded btn-block">
	                            Tạo tiến trình
	                        </button>
                        </div>
					</div>
				</div>
			</Fragment>
		);
  	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	buffsub: state.vipviewtiktok
});

export default connect(mapStateToProps, {creatItemBuffSub, getInfoTiktok, getConfigMoDong})(Taotientrinh);
