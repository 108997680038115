import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import {
    Modal,
    ModalBody,
    ModalFooter
} from 'reactstrap';
import { UpdateNdr } from '../../../actions/SpamsmsActions';

class Modal_edit extends Component {
	componentDidMount() {
        this.setState({
            ndr: Buffer.from(this.props.datandr, 'base64').toString('utf8')
        });
	}
    state = {
        ndr: this.props.datandr,    
    }

    changeValueNdr = (e) => {
        this.setState({
            ndr: e.target.value
        });
    }

    render() {
        const {isOpenModal, dataid, dataidfb} = this.props;
        const {ndr} = this.state;
		const { changetab, loading } = this.props.Spamsms;
		var count_review = 0;
		if (ndr.trim() !== "") {
			count_review = ndr.trim().split('\n').length;
		}

        return (
            <Fragment>
                <Modal centered={true} size="lg" toggle={(e) => {this.props.openModal()}} isOpen={isOpenModal} className={(loading? ' block block-mode-loading' : '')} style={{"backgroundColor": "inherit", "boxShadow": "none"}}>
                    <div className="modal-header">
                        <p className="mb-0 text-dark">Sửa nội dung tin nhắn</p>
                        <button onClick={(e) => {this.props.openModal()}} type="button" className="close">×</button>
                    </div>
                    <ModalBody style={{opacity: loading? '.05':'1'}}>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group focused">
									<label className="control-label">Nhập nội dung (<label className="font-bold font-13">Mỗi dòng tương đương với 1 Nội dung</label>):</label>
									<span className="badge badge-primary text-white py-1">Số lượng: {count_review}</span>
                                    <textarea rows="5" type="text" className="form-control" id="ndr" name="ndr" placeholder="Nhập nội dung, mỗi dòng tương đương với 1 nội dung" onChange={this.changeValueNdr} value={ndr}></textarea>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter style={{opacity: loading? '.05':'1'}}>
                        <button onClick={() => {this.props.UpdateNdr(ndr, dataid, this.props.openModal)}} type="button" className="btn btn-primary">Lưu</button>
                        <button onClick={(e) => {this.props.openModal()}} type="button" className="btn btn-danger">Huỷ</button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    Spamsms: state.Spamsms
});

export default connect(mapStateToProps, {UpdateNdr}) (Modal_edit);
