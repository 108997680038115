import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import store from '../../../store';

import Swal from 'sweetalert2';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { updateSpamcall, addSpamcall, getInfoSpamcall } from '../../../actions/SpamcallActions';
import { LOADING_SPAMCALL } from "../../../actions/types";
import parse from 'html-react-parser';
import ServicesDescription from "../ServicesDescription";
import { isMobile } from 'react-device-detect';

const date7dnext = () => {
	var result = new Date();
  	result.setDate(result.getDate() + 7);
  	return result;
}

class taotientrinh extends Component {
	static propTypes =  {
		updateSpamcall: PropTypes.func.isRequired,
		addSpamcall: PropTypes.func.isRequired,
		getInfoSpamcall: PropTypes.func.isRequired,
	};
	
	state = {
		showDescription: isMobile? false : true
	}
	
	componentDidMount() {
		// this.props.loadPriceFLBBKTT();
	};
	
	handleChangeDateStart = date => {
		const { taotientrinh, price } = this.props.Spamcall;
		
		var new_taotientrinh = {
			...taotientrinh,
			startDatebh: date
		};
		this.props.updateSpamcall(new_taotientrinh);
	}
	
	onChange = (e) => {
		const { taotientrinh, price } = this.props.Spamcall;
		if (e.target.name === 'sdtn') {
			var number = e.target.value;
			if (parseInt(number) < 0 || (number.length != 10 && number.length != 11)) {
				console.log(number.toString().length);
				var new_taotientrinh = {
					...taotientrinh,
					[e.target.name]: e.target.value
				};
				this.props.updateSpamcall(new_taotientrinh);
				return false;
			}
			store.dispatch({
				type: LOADING_SPAMCALL,
				payload: true
			});
			var fun_check = this.props.getInfoSpamcall(e.target.value);
			if (fun_check !== false) {
				fun_check.then(res => {
					store.dispatch({
						type: LOADING_SPAMCALL,
						payload: false
					});
					if (res.data.status === 200) {
						let data_res = res.data.data;
						if (data_res != null) {
							var new_taotientrinh = {
								...taotientrinh,
								"sdtn": number,
								"type": data_res,
							};
							this.props.updateSpamcall(new_taotientrinh);
							Swal.fire({
								icon: 'success',
								html: 'Lấy thông tin thành công'
							});
						} else {
							var new_taotientrinh = {
								...taotientrinh,
								"sdtn": number,
								"type": "",
							};
							this.props.updateSpamcall(new_taotientrinh);
							Swal.fire({
								icon: 'error',
								title: 'Lỗi',
								text: "Có lỗi xảy ra! ##",
							});
							return false;
						}
					} else {
						var new_taotientrinh = {
							...taotientrinh,
							"sdtn": number,
							"type": "",
						};
						this.props.updateSpamcall(new_taotientrinh);
						Swal.fire({
							icon: 'error',
							title: 'Lỗi',
							text: res.data.message,
						});
						return false;
					}
				})
			} else {
				store.dispatch({
					type: LOADING_SPAMCALL,
					payload: false
				});
				Swal.fire({
					icon: 'error',
					title: 'Lỗi',
					text: "Có lỗi xảy ra!",
				});
				return false;
			}
		} else {
			var new_taotientrinh = {
				...taotientrinh,
				[e.target.name]: e.target.value
			};
			this.props.updateSpamcall(new_taotientrinh);
		}
	}
	
	taoTienTrinhfunc = () => {
		const { taotientrinh } = this.props.Spamcall;
		var new_taotientrinh = {
			...taotientrinh,
		};
		if (taotientrinh.sdtn.length != 10) {
			Swal.fire({
				icon: 'error',
				title: 'Lỗi',
				text: 'Số điện thoại gọi phải đủ 10 số!',
			});
			return false;
		}
		Swal.fire({
			title: 'Bạn có chắc?',
			html: "Bạn có chắc chắn muốn mua Giá "+taotientrinh.gtmtt+" Coin / 1 lần? Khi đã bấm đồng ý đồng nghĩa với việc bạn đã đọc hết mục thông tin dịch vụ!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Đồng ý',
			cancelButtonText: 'Không'
		}).then((result) => {
			if (result.value) {
				this.props.addSpamcall(new_taotientrinh);
			}
		})
	}
	
	onKeyPress = (e) => {
		if (!(e.charCode >= 48 && e.charCode <= 57)) {
			e.preventDefault();
		}
	}
	
	onClickShowDescription = (data) => {
		this.setState({
			showDescription: data
		});
	}
	
	render() {
 		const { sdtn, hgg, startDatebh, sl, tgn, gtmtt } = this.props.Spamcall.taotientrinh;
		var price = Math.round((gtmtt * sl + Number.EPSILON) * 100) / 100;
		
		var listDataServicesInfo = {};
		try {
			if (localStorage.getItem("listDataServicesInfo") != null) {
				listDataServicesInfo = JSON.parse(localStorage.getItem("listDataServicesInfo"));
			} else {
				listDataServicesInfo = this.props.adminpage.listDataServicesInfo;
			}
		} catch(e) {
			listDataServicesInfo = {};
		}
		var html_haydoctranhmattien = '';
		var html_haydoctranhmattien_chinh = '';
		var html_haydoctranhmattien_sv = '';
		var html_hotrocuocgoi = '';
		if (typeof(listDataServicesInfo.hotrocuocgoi_tab) !== 'undefined') {
			html_haydoctranhmattien = parse(listDataServicesInfo.hotrocuocgoi_tab.ghichu);
			html_haydoctranhmattien_chinh = parse(listDataServicesInfo.hotrocuocgoi_tab.ghichu_chinh);
		}
		if (typeof(listDataServicesInfo.hotrocuocgoi) !== 'undefined') {
			html_hotrocuocgoi = parse(listDataServicesInfo.hotrocuocgoi.ghichu_sv);
			if ("1" === "1") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.hotrocuocgoi.ghichu);
			}
		}
		
		return (
			<Fragment>
				<div className="form-group">
					<div className="alert alert-success d-flex align-items-center" role="alert">
						<div className="flex-00-auto">
							<i className="fa fa-fw fa-bullhorn"></i>
						</div>
						<div className="flex-fill ml-3">
							<p className="mb-0">Đây là dịch vụ cuộc gọi hỗ trợ quảng bá, quảng cáo thương hiệu!</p>
						</div>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Số điện thoại gọi:</label>
					<div className="col-sm-8">
						<input type="text" type="number" className="form-control" id="sdtn" name="sdtn" placeholder="Nhập số điện thoại" onChange={this.onChange} value={sdtn} />
					</div>
				</div>
				<div className="form-group row">
					<div className="col-sm-4">
						 <div className="custom-control custom-checkbox custom-control-inline">
							<input value={hgg === "1" ? "0" : "1"} type="checkbox" className="custom-control-input" id="hgg" name="hgg" onChange={this.onChange} checked={hgg === "1"} />
							<label className="custom-control-label" htmlFor="hgg" >Đặt lịch gọi:</label>
						</div>	
					</div>
					<div className="col-sm-8" style={{ "marginBottom" : "0px", "display": hgg === '1' ? 'flex' : 'none' }}>
						<DatePicker className="form-control w-100"
						showTimeSelect minDate={new Date()}
						selected={startDatebh}
						onChange={this.handleChangeDateStart} />
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-4" htmlFor="">Hành động:</label>
					<div className="col-sm-8 form-inline">
						Gọi <input type="number" style={{"marginLeft": "5px", "marginRight": "5px"}} className="form-control col-sm-2" id="sl" name="sl" placeholder="Số lần gọi" onChange={this.onChange} value={sl} onKeyPress={this.onKeyPress} /> lần,
						cách nhau <input type="number" style={{"marginLeft": "5px", "marginRight": "5px"}} className="form-control col-sm-2" id="tgn" name="tgn" placeholder="Thời gian nghỉ" onChange={this.onChange} value={tgn} onKeyPress={this.onKeyPress} /> phút
					</div>
				</div>
				
				<button type="button" className="btn badge-primary-cus btn-rounded btn-show-description-cus" onClick={(e) => {this.onClickShowDescription(!this.state.showDescription)}}>
					{
						this.state.showDescription?
							<i className="fa fa-angle-up" style={{"marginRight": "5px"}} aria-hidden="true"></i>
						:
							<i className="fa fa-angle-down" style={{"marginRight": "5px"}} aria-hidden="true"></i>
					}
					Thông tin dịch vụ
				</button>
				
				<ServicesDescription allservicesinfo={listDataServicesInfo} server={"1"} tab="hotrocuocgoi" giatien={gtmtt} baohanh={0} showDescription={this.state.showDescription} />
				
				<div className="row mt-2" style={{"display": this.state.showDescription? "flex" : "none"}}>
				    <div className="col-md-12 bold" style={{"marginBottom": "10px"}}>
						<div className="card card-select-cus">
							<div className="card-body card-body-select-cus">
								<p className="mb-0 mt-3 text-wrap">
									<ul>
										{html_haydoctranhmattien}
										{html_haydoctranhmattien_chinh}
										{html_haydoctranhmattien_sv}
										<li>Nếu gặp lỗi hãy nhắn tin hỗ trợ phía bên phải góc màn hình hoặc vào mục liên hệ hỗ trợ để được hỗ trợ tốt nhất!</li>
									</ul>
								</p>
								<span className="badge badge-cus position-absolute-cus badge-top-left-cus badge-primary-cus badge-pill-cus"> HÃY ĐỌC ĐỂ TRÁNH MẤT TIỀN KHI SỬ DỤNG! </span>
							</div>
						</div>
				    </div>
				</div>
				<div className="row mt-4">
					<div className="col-12 text-center bold">
						<div className="card card-info">
							<div className="card-body">
								<h5 className="font-bold">Tổng: <span className="bold green"><span>{price}</span> Coin</span></h5>
								<h6 className="mb-0 bold"><span>Giá <span className="green">{gtmtt} Coin</span> / 1 lần</span></h6>
							</div>
						</div>
					</div>
				</div>
				<div className="row my-4">
					<div className="col-12 text-center bold">
						<button type="button" className="btn btn-block btn-rounded btn-hero-primary" onClick={this.taoTienTrinhfunc} >Tạo tiến trình</button>
					</div>
				</div>
			</Fragment>
		)
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	Spamcall: state.Spamcall,
});
const mapDispatchToProps = { updateSpamcall, addSpamcall, getInfoSpamcall }

export default connect(mapStateToProps, mapDispatchToProps)(taotientrinh);