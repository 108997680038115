import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { login } from "../../actions/authActions";
import { clearErrors } from "../../actions/errorActions";
import RegisterModal9 from './Register9';
import ServiceModal9 from './Service9';
import ForgotPasswordModal9 from './ForgotPassword9';
import json_lf20_wxkz3g7u from '../../iconlanding/9/lf20_wxkz3g7u.json';
import json_lf20_bHVEVT from '../../iconlanding/9/lf20_bHVEVT.json';
import png_7p0egmj9o1tdri34 from '../../iconlanding/9/7p0egmj9o1tdri34.png';
import png_s0sx266kgwcied2w from '../../iconlanding/9/s0sx266kgwcied2w.png';
import png_dd3agtwrp649ysi8 from '../../iconlanding/9/dd3agtwrp649ysi8.png';
import png_a8zjir979ozfs2ac from '../../iconlanding/9/a8zjir979ozfs2ac.png';
import png_ebhs51uj9se521nc from '../../iconlanding/9/ebhs51uj9se521nc.png';
import png_yx3d11cg51dfxqsk from '../../iconlanding/9/yx3d11cg51dfxqsk.png';
import Lottie from 'react-lottie-player';
import { RiShieldCheckLine, RiMoneyDollarBoxLine, RiHeadphoneLine, RiShoppingBasket2Line } from "react-icons/ri";
import { VscRocket } from "react-icons/vsc";
import { BiCrown } from "react-icons/bi";
import { AiOutlineUserAdd, AiOutlineUser } from "react-icons/ai";
import {
  BrowserRouter as Router,
  Link
} from "react-router-dom";
class Login extends Component {
  state = {
	modal: 1,
    msg: "",
    username: "",
    password: "",
    maxacminh: "",
    form_maxacminh: false,
    isLoading: false,
	showsteps: 1
  };
  componentDidMount(){
	if (this.props.typeform == 3) {
	  this.setState({ modal: 3 });
	}
  }
  componentDidUpdate(prevProps) {
    const { error } = this.props;
    if (error !== prevProps.error) {
      // Check for login error
      if (error.id === "LOGIN_FAIL") {
        this.setState({ msg: error.msg.msg });
      } else if (error.id === "LOGIN_FAIL_2FA") {
        this.setState({ msg: error.msg.msg, form_maxacminh: true });
      } else {
        this.setState({ msg: null });
      }
    }
  }
	clearAlert = () => {
		// Clear errors
		this.props.clearErrors();
		// this.setState({
			// modal: !this.state.modal
		// });
	}
  changeFormLogRegister = (typeform) => {
	this.props.clearErrors();
	this.setState({
		modal: typeform
	});
	
}
  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };
  onSubmit = e => {
    e.preventDefault();
    const { username, password, maxacminh } = this.state;
    this.props.clearErrors();
    this.props.login({ username, password, maxacminh });
  };
  changeShowSteps = (data) => {
	this.setState({
		showsteps: data
	});
  };
  render() {
    let isLoading = this.props.auth.isLoading;
    const domainSite = window.location.hostname;
	var config_website = {};
	if (localStorage.getItem("confignavbar") != null) {
		config_website = JSON.parse(localStorage.getItem("confignavbar"));
	} else {
		config_website = this.props.auth.config_website;
	}
	const formLogin = (
		<Router>
			<Fragment>
				<div className="landingcus9" style={{"zoom": "122%"}}>
					<div className="guest-body">
						<div className="header">
							<div className="container m-flex">
								<div className="row" style={{"height": "99px"}}>
									<div className="col-auto align-self-center">
										<div className="row">
											<div className="col align-self-center">
												<div className="site-name">
													<a className="navbar-brand" href="/" style={{"fontSize": "30px"}}>
														{domainSite}
													</a>
												</div>
											</div>
										</div>
									</div>
									<div className="col d-none d-lg-block">
										<div className="head-menu">
											<div className="row">
												<div className="col text-lg-right mh-fullw">
													<div className="header-menu">
														<ul>
															<li className="active">
																<Link to="/services" onClick={(e) => {this.changeFormLogRegister(3)}}>Dịch vụ</Link>
															</li>
														</ul>
													</div>
												</div>
												<div className="col-auto align-self-center mh-fullw">
													<a href="/" className="btn btn-outline">
														Đăng nhập
													</a>
												</div>
												<div className="col-auto align-self-center mh-fullw">
													<Link to="/" className="btn btn-secondary" onClick={(e) => {this.changeFormLogRegister(2)}}>
														<RiShieldCheckLine/> Đăng ký
													</Link>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="app-navbar">
							<div className="row">
								<div className="col text-center align-self-center">
									<Link to="/services" onClick={(e) => {this.changeFormLogRegister(3)}}>
										<RiShoppingBasket2Line />
										<div className="navbar-texts">Dịch vụ</div>
									</Link>
								</div>
								<div className="col text-center align-self-center">
									<Link to="/" onClick={(e) => {this.changeFormLogRegister(2)}}>
										<AiOutlineUserAdd />
										<div className="navbar-texts">Đăng ký</div>
									</Link>
								</div>
								<div className="col text-center align-self-center">
									<a href="/">
										<AiOutlineUser />
										<div className="navbar-texts">Đăng nhập</div>
									</a>
								</div>
							</div>
						</div>
						<div className="home-head">
							<div className="home-head-bg"></div>
							<div className="home-head-content">
								<div className="container" style={{"position": "relative"}}>
									<div className="row">
										<div className="col-lg-6 col-12 d-none d-lg-block">
											<div className="hh-left-badge">
												<Lottie loop animationData={json_lf20_wxkz3g7u} play style={{"width": "550px", "height": "550px"}} />
											</div>
										</div>
										<div className="col-lg-5 offset-lg-1">
											<div className="loginSide">
												<div className="login-box">
													<div className="row">
														<div className="lgb-title col-lg-12">
															<Lottie loop animationData={json_lf20_bHVEVT} play style={{"width": "60px", "height": "60px"}} />
														</div>
														<div className="lgb-text ml-2">
															<h3 style={{"color": "#fff"}}>Đăng nhập</h3>
															<span>Tuyệt đối không chia sẻ mật khẩu với bên thứ 3.</span>
														</div>
													</div>
													{isLoading ? (
														<div className="preloader">
															<div className="spinner-grow spinner-grow-sm text-primary" role="status" style={{ "marginTop": "50%" }}>
															  <span className="sr-only"></span>
															</div>
															<div className="spinner-grow spinner-grow-sm text-danger" role="status" style={{ "marginTop": "50%" }}>
															  <span className="sr-only">.</span>
															</div>
															<div className="spinner-grow spinner-grow-sm text-warning" role="status" style={{ "marginTop": "50%" }}>
															  <span className="sr-only"></span>
															</div>
														</div>
													):('')}
													{this.state.msg ? (
														<div className="alert alert-warning d-flex align-items-center justify-content-between" role="alert" >
														  <div className="flex-fill mr-3">
															<p className="mb-0">{this.state.msg}</p>
														  </div>
														  <div className="flex-00-auto">
															<i className="fa fa-fw fa-exclamation-circle"></i>
														  </div>
														</div>
													  ) : null}
													
													<form className="js-validation-signup" onSubmit={this.onSubmit} method="POST" >
														<div className="form-group">
															<label>Tài khoản</label>
															<input name="username" placeholder="Nhập tài khoản" required="" id="username" type="text" className="form-control" onChange={this.onChange} />
														</div>
														<div className="form-group">
															<label>Mật khẩu</label>
															<input name="password" placeholder="Nhập mật khẩu" required="" id="password" type="password" className="form-control" onChange={this.onChange} />
														</div>
														{
															this.state.form_maxacminh ?
																<div className="form-group">
																	<label>Mã xác minh</label>
																	<input name="maxacminh" placeholder="Nhập mã xác minh" required="" id="maxacminh" type="text" className="form-control" onChange={this.onChange} />
																</div>
															: ""
														}
														<div className="form-group">
															<Link to="/" onClick={(e) => {this.changeFormLogRegister(4)}} style={{"float": "right", "marginBottom": "15px"}}>
																Quên mật khẩu?
															</Link>
														</div>
														<button type="submit" className="btn btn-secondary btn-lg btn-block mt-3">Đăng nhập</button>
														<div className="text-center mt-4 pt-3 d-md-block">
															<Link to="/" onClick={(e) => {this.changeFormLogRegister(2)}} className="frgpass"> Bạn chưa có tài khoản? Đăng ký </Link>
														</div>
													</form>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<section className="main-box">
							<div className="container">
								<div className="row align-items-center">
									<div className="col-lg-6">
										<div className="main-box-content">
											<h2>{domainSite}</h2>
											<p>Dịch vụ sử dụng các nền tảng truyền thông xã hội như Instagram, Facebook, Twitter, Youtube và nhiều nền tảng khác để quảng bá bản thân hoặc công ty của bạn. Nếu bạn đang tìm cách để tăng sự hiện diện trực tuyến của mình, bạn có thể sử dụng dịch vụ của chúng tôi để có giá tốt nhất và rẻ nhất.</p>
											<div className="mt-4">
												<Link to="/services" className="btn btn-primary" style={{"marginRight": "5px"}} onClick={(e) => {this.changeFormLogRegister(3)}}>Dịch vụ</Link>
												<Link to="/" className="btn btn-outline" onClick={(e) => {this.changeFormLogRegister(2)}}>Đăng ký</Link>
											</div>
										</div>
									</div>
									<div className="col-lg-6">
										<div className="main-box-img d-none d-md-block" style={{"background": "url(" + png_7p0egmj9o1tdri34 + ") no-repeat center center"}}>
											<img alt="smm services" src={png_s0sx266kgwcied2w} className="img-fluid floating"/>
										</div>
									</div>
								</div>
							</div>
						</section>
						<section className="main-count">
							<div className="container">
								<div className="main-count-inn">
									<div className="mc-inn-box">
										<div className="mc-box">
											<img src={png_dd3agtwrp649ysi8} alt=""/>
											<h6>332235826+</h6>
											<small>Tổng Đơn</small>
										</div>
									</div>
									<div className="mc-inn-box">
										<div className="mc-box">
											<img src={png_a8zjir979ozfs2ac} alt=""/>
											<h6>12624+</h6>
											<small>Khách Hàng Hài Lòng</small>
										</div>
									</div>
									<div className="mc-inn-box">
										<div className="mc-box">
											<img src={png_ebhs51uj9se521nc} alt=""/>
											<h6>50+</h6>
											<small>Dịch vụ</small>
										</div>
									</div>
								</div>
							</div>
						</section>
						<section className="main-steps">
							<div className="container">
								<div className="steps-head text-center">
									<h2>Tại sao là chúng tôi?</h2>
									<p>Chúng tôi cung cấp cho bạn dịch vụ ổn định và giá cả phải chăng. Bằng cách này, bạn sẽ dễ dàng tăng mức độ phổ biến của mình trên mạng xã hội hơn rất nhiều! Chúng tôi là {domainSite}. Thực hiện tiếp thị trên mạng xã hội hoặc quảng cáo trên mạng xã hội sẽ giúp ích cho phương tiện truyền thông xã hội của bạn. Chúng tôi có một số nhà tiếp thị truyền thông xã hội trong đại lý tiếp thị truyền thông xã hội của chúng tôi. Chiến lược tiếp thị truyền thông xã hội này sẽ thay đổi quan điểm của bạn!</p>
								</div>
								<div className="row mt-5 align-items-center">
									<div className="col-lg-6">
										<div className="main-steps-img">
											<img alt="cheapest smm panel" src={png_yx3d11cg51dfxqsk} className="img-fluid"/>
										</div>
									</div>
									<div className="col-lg-6 d_flex">
										<div id="accordion" className="ms-steps">
											<div className={"card "+ (this.state.showsteps == 1? "active": "")}>
												<div className="card-header" id="heading1">
													<h5 className="mb-0">
														<button className={"btn btn-link "+ (this.state.showsteps == 1? "": "collapsed")} data-toggle="collapse" data-target="#collapse0" aria-expanded="true" aria-controls="collapse0" onClick={(e) => {this.changeShowSteps(1)}} style={{"boxShadow": "none"}}>
															<VscRocket style={{"marginRight": "25px", "fontSize": "30px"}}/> Đơn tự động </button>
													</h5>
												</div>
												<div id="collapse0" className={"collapse "+ (this.state.showsteps == 1? "show": "")} aria-labelledby="heading1" data-parent="#accordion">
													<div className="card-body">
														<p> Hệ thống của chúng tôi hoàn toàn tự động, các đơn đặt hàng của bạn đặt được xử lý và hoàn thành tự động.</p>
													</div>
												</div>
											</div>
											<div className={"card "+ (this.state.showsteps == 2? "active": "")}>
												<div className="card-header" id="heading2">
													<h5 className="mb-0">
														<button className={"btn btn-link "+ (this.state.showsteps == 2? "": "collapsed")} data-toggle="collapse" data-target="#collapse1" aria-expanded="true" aria-controls="collapse1" onClick={(e) => {this.changeShowSteps(2)}} style={{"boxShadow": "none"}}>
															<RiMoneyDollarBoxLine style={{"marginRight": "25px", "fontSize": "30px"}}/>Thanh toán an toàn </button>
													</h5>
												</div>
												<div id="collapse1" className={"collapse "+ (this.state.showsteps == 2? "show": "")} aria-labelledby="heading2" data-parent="#accordion">
													<div className="card-body">
														<p>Bạn có thể thanh toán 24/7 bằng bank, momo, thẻ cào...</p>
													</div>
												</div>
											</div>
											<div className={"card "+ (this.state.showsteps == 3? "active": "")}>
												<div className="card-header" id="heading3">
													<h5 className="mb-0">
														<button className={"btn btn-link "+ (this.state.showsteps == 3? "": "collapsed")} data-toggle="collapse" data-target="#collapse2" aria-expanded="true" aria-controls="collapse2" onClick={(e) => {this.changeShowSteps(3)}} style={{"boxShadow": "none"}}>
															<RiHeadphoneLine style={{"marginRight": "25px", "fontSize": "30px"}}/>Hỗ trợ khách hàng trực tiếp 24/7</button>
													</h5>
												</div>
												<div id="collapse2" className={"collapse "+ (this.state.showsteps == 3? "show": "")} aria-labelledby="heading3" data-parent="#accordion">
													<div className="card-body">
														<p>Hệ thống hỗ trợ chuyên nghiệp của chúng tôi luôn đồng hành cùng bạn.</p>
													</div>
												</div>
											</div>
											<div className={"card "+ (this.state.showsteps == 4? "active": "")}>
												<div className="card-header" id="heading4">
													<h5 className="mb-0">
														<button className={"btn btn-link "+ (this.state.showsteps == 4? "": "collapsed")} data-toggle="collapse" data-target="#collapse3" aria-expanded="true" aria-controls="collapse3" onClick={(e) => {this.changeShowSteps(4)}} style={{"boxShadow": "none"}}>
															<BiCrown style={{"marginRight": "25px", "fontSize": "30px"}}/>Dịch vụ chất lượng</button>
													</h5>
												</div>
												<div id="collapse3" className={"collapse "+ (this.state.showsteps == 4? "show": "")} aria-labelledby="heading4" data-parent="#accordion">
													<div className="card-body">
														<p>Chúng tôi luôn nỗ lực để tăng tỷ lệ hài lòng bằng cách cung cấp các dịch vụ giá rẻ và chất lượng.</p>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</section>
						<footer className="footer">
							<div className="container">
								<div className="footer-top">
									<p>Bạn đã sẵn sàng bắt đầu với chúng tôi?
									</p>
									<Link to="/" className="btn btn-outline" onClick={(e) => {this.changeFormLogRegister(2)}}>ĐĂNG KÝ <i className="fas fa-angle-right ml-2"></i>
									</Link>
								</div>
								<div className="container">
									<div className="row">
										<div className="col-md-6">
											<span className="logo">
												<div className="navbar-brand" style={{"paddingTop": "7.5px"}}>
													<a className="link-fx text-white font-w700 font-size-h3" href="/">
														<span className="text-white">VIPFB18</span>
														<span className="text-white">.COM</span>
													</a>
												</div>
											</span>
											<p className="font-italc" style={{"color": "#fff", "display": "inline-block", "fontSize": "15px", "opacity": ".8"}}>Vipfb18.com đã có nhiều năm hoạt động trong lĩnh vực Online Maketing, nắm bắt được sự thay đổi và định hứng được sự phát triển của thị trường, VIPFB18.COM đi đầu trong lĩnh vực online maketing, cung cấp đa dạng các dịch vụ, giúp người dùng luôn tiếp cận được với mức giá phải chăng và hiệu quả maketing tối ưu nhất. </p>
										</div>
										<div className="col-md-6 text-center-md">
											<div className="footer-support-panel" style={{"width": "50%", "margin": "0 auto"}}>
												<h3 className="title text-white">Hỗ trợ</h3>
												<ul>
													<li>
														<a href="https://facebook.com/102716672125708" className="card-body hvr-icon-pop" target="_blank" style={{"color": "#fff", "padding": "10px 0","display": "inline-block", "fontSize": "15px", "opacity": ".8"}}>
															<i className="fa fa-comments"></i> Fanpage hỗ trợ
														</a>
													</li>
													<li>
														<a href="tel:0389718987" target="_blank" style={{"color": "#fff", "padding": "10px 0","display": "inline-block", "fontSize": "15px", "opacity": ".8"}}>
															<i className="fa fa-phone-alt"></i> 0389718987 (Mr. Thành)
														</a>
													</li>
												</ul>
											</div>
										</div>
									</div>
									<div className="footer-bottom"> Copyright 2021 © Ngô Văn Thành  - All rights reserved. <br/>
									</div>
								</div>
							</div>
						</footer>
					</div>
				</div>
			</Fragment>
		</Router>
	);
	var path_check = window.location.pathname;
	if (window.location.pathname.length > 1) {
		path_check = window.location.pathname.replace(/\/+$/g, '');
	}
	const formRegister = (
		<Fragment>
			<RegisterModal9 action_login={(e) => {this.changeFormLogRegister(1)}} action_service={(e) => {this.changeFormLogRegister(3)}} />
		</Fragment>
	);
	const formService = (
		<Fragment>
			<ServiceModal9 action_login={(e) => {this.changeFormLogRegister(1)}} action_register={(e) => {this.changeFormLogRegister(2)}} />
		</Fragment>
	);
	const formForgotPassword = (
		<Fragment>
			<ForgotPasswordModal9 action_login={(e) => {this.changeFormLogRegister(1)}} action_register={(e) => {this.changeFormLogRegister(2)}} action_service={(e) => {this.changeFormLogRegister(3)}} action_reset={path_check} />
		</Fragment>
	);
	return (
		<Fragment>
			{
				path_check === '/resetpassword' ?
					formForgotPassword
				: this.state.modal == 1 ?
					formLogin
				: this.state.modal == 2 ?
					formRegister
				: this.state.modal == 3 ?
					formService
				: this.state.modal == 4 ?
					formForgotPassword
				: ''
			}
		</Fragment>
	);
  }
}
Login.propTypes = {
  auth: PropTypes.object.isRequired,
  error: PropTypes.object,
  login: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  error: state.error
});
export default connect(mapStateToProps, { login, clearErrors })(Login);
