import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import {
    Modal,
    ModalBody,
    ModalFooter
} from 'reactstrap';

import { RegisterUserRun } from '../../../../actions/adminPageAction';

class RegisterUser extends Component {

    state = {
        formdata: {
            nameUser: '',
            phoneUser: '',
            emailUser: '',
            passUser: '',
            rePass: ''
        }
    }

    changeValueRegister = (e) => {
        this.setState({
            formdata: {
                ...this.state.formdata,
                [e.target.name]: e.target.value
            }
        });
    }

    render() {
        const {isOpenModalAddUser} = this.props;
        const { nameUser,phoneUser,emailUser,passUser,rePass } = this.state.formdata;
        const { loadingblock } = this.props.adminpage;

        return (
            <Fragment>
                <Modal centered={true} size="lg" toggle={(e) => {this.props.openModal(e)}} isOpen={isOpenModalAddUser} className={(loadingblock? ' block block-mode-loading' : '')} style={{"backgroundColor": "inherit", "boxShadow": "none"}}>
                    <div className="modal-header">
                        <p className="mb-0 text-dark">Thêm tài khoản</p>
                        <button onClick={(e) => {this.props.openModal(e)}} type="button" className="close">×</button>
                    </div>
                    <ModalBody style={{opacity: loadingblock? '.05':'1'}}>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Tên tài khoản</label>
                                    <input type="text" onChange={(e) => {this.changeValueRegister(e)}} value={nameUser} name="nameUser" className="form-control"  placeholder="Tên" />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Email</label>
                                    <input type="text" onChange={(e) => {this.changeValueRegister(e)}} value={emailUser} name="emailUser" className="form-control"  placeholder="Email" />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Số điện thoại</label>
                                    <input type="number" onChange={(e) => {this.changeValueRegister(e)}} value={phoneUser} name="phoneUser" className="form-control"  placeholder="Số điện thoại" />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Mật khẩu</label>
                                    <input type="password" onChange={(e) => {this.changeValueRegister(e)}} value={passUser} name="passUser" className="form-control"  placeholder="Mật khẩu" />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Nhập lại mật khẩu</label>
                                    <input type="password" onChange={(e) => {this.changeValueRegister(e)}} value={rePass} name="rePass" className="form-control"  placeholder="Nhập lại mật khẩu" />
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter style={{opacity: loadingblock? '.05':'1'}}>
                        <button type="button" className="btn btn-primary" onClick={(e) => {this.props.RegisterUserRun(this.state.formdata, this.props)}} >Lưu</button>
                        <button type="button" className="btn btn-danger" onClick={(e) => {this.props.openModal(e)}} >Huỷ</button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        );
    }

}

const mapStateToProps = state => ({
    adminpage: state.adminpage
});

export default connect(mapStateToProps, { RegisterUserRun }) (RegisterUser);
