import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { updateBSLIO, addBSLIO, loadPriceBSLIO, getConfigMoDong } from '../../../actions/buffseedinglikeinstagramorderActions';
import Swal from 'sweetalert2';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import parse from 'html-react-parser';
import ServicesDescription from "../ServicesDescription";
import { isMobile } from 'react-device-detect';

const date7dnext = () => {
	var result = new Date();
  	result.setDate(result.getDate() + 7);
  	return result;
}

class taotientrinh extends Component {
	static propTypes =  {
		updateBSLIO: PropTypes.func.isRequired,
		addBSLIO: PropTypes.func.isRequired,
		loadPriceBSLIO: PropTypes.func.isRequired,
	};
	
	state = {
		showDescription: isMobile? false : true
	}
	
	componentDidMount() {
		// this.props.loadPriceBSLIO();
		this.props.getConfigMoDong();
	};
	
 	handleChangeDateStart = date => {
		const { taotientrinh } = this.props.buffseedinglikeinstagramorder;
		var new_taotientrinh = {
			...taotientrinh,
			"cnbd": date,
			"cnkt": this.addDays(date, 7)
		};
		this.props.updateBSLIO(new_taotientrinh);
	}

	handleChangeDateEnd = date => {
		const { taotientrinh } = this.props.buffseedinglikeinstagramorder;
		var new_taotientrinh = {
			...taotientrinh,
			"cnkt": date
		};
		this.props.updateBSLIO(new_taotientrinh);
	}
	
	addDays = (date, days) => {
	  	var result = new Date(date);
	  	result.setDate(result.getDate() + days);
	  	return result;
	}
	
	// option_gtmtt = () => {
		// const { percent } = this.props.auth.user;
		// var prices_api_min = '';
		// var price_web = '';
		// var excl_price_diff = '';
		// var price_diff = '';
		// if (this.props.buffseedinglikeinstagramorder.price.prices_api_min && this.props.buffseedinglikeinstagramorder.price.price_web) {
			// prices_api_min = JSON.parse(this.props.buffseedinglikeinstagramorder.price.prices_api_min);
			// price_web = JSON.parse(this.props.buffseedinglikeinstagramorder.price.price_web);
			// price_diff = price_web[0] - prices_api_min[0];
		// }
		
		// let ar_option_gtmtt = [];
		
		// if (prices_api_min !== '' && price_web !== '') {
			// for (let i = prices_api_min[0]; i <= prices_api_min[1]; i++) {
				// var excl_i = (i+price_diff) * percent / 100;
				// var final_i = Math.ceil(i+price_diff - excl_i);
				// ar_option_gtmtt.push(
					// <option value={i} key={i}>
						// {final_i}
					// </option>
				// )
			// }
		// }
		// return ar_option_gtmtt;
	// }
	
	onChange = (e) => {
		if ((e.target.name === "lhibv") && (e.target.value.indexOf("instagram.com/") > -1)) {
			if (e.target.value !== "") {
				const { taotientrinh } = this.props.buffseedinglikeinstagramorder;
				var id_link = e.target.value.replace('https://www.instagram.com/p', '').replace('https://instagram.com/p', '').replace('http://www.instagram.com/p', '').replace('http://instagram.com/p', '').replace('instagram.com/p', '').replace('https://www.instagram.com/reel', '').replace('https://instagram.com/reel', '').replace('http://www.instagram.com/reel', '').replace('http://instagram.com/reel', '').replace('instagram.com/reel', '').replace(/\//g, '');
				var new_taotientrinh = {
					...taotientrinh,
					[e.target.name]: id_link.split('?')[0]
				};
				this.props.updateBSLIO(new_taotientrinh);
			}
		} else if (e.target.name === "lsct") {
			var { taotientrinh } = this.props.buffseedinglikeinstagramorder;
			if (e.target.value === "1") {
				var value = this.props.buffseedinglikeinstagramorder.price.buffseedinglikeinstagramorder.prices_web;
				// if (taotientrinh.cdbh === "true") {
					// value = this.props.buffseedinglikeinstagramorder.price.buffseedinglikeinstagramorder.min_bh_web;
				// }
				taotientrinh = {
					...taotientrinh,
					"cdbh": "false",
					"gtmtt": value,
				};
			}
			if (e.target.value === "2") {
				var value = this.props.buffseedinglikeinstagramorder.price.buffseedinglikeinstagramorder_sv2.prices_web;
				taotientrinh = {
					...taotientrinh,
					"cdbh": "false",
					"gtmtt": value,
				};
			}
			if (e.target.value === "3") {
				var value = this.props.buffseedinglikeinstagramorder.price.buffseedinglikeinstagramorder_sv3.prices_web;
				taotientrinh = {
					...taotientrinh,
					"cdbh": "false",
					"gtmtt": value,
				};
			}
			if (e.target.value === "4") {
				var value = this.props.buffseedinglikeinstagramorder.price.buffseedinglikeinstagramorder_sv4.prices_web;
				taotientrinh = {
					...taotientrinh,
					"cdbh": "false",
					"gtmtt": value,
				};
			}
			if (e.target.value === "5") {
				var value = this.props.buffseedinglikeinstagramorder.price.buffseedinglikeinstagramorder_sv5.prices_web;
				taotientrinh = {
					...taotientrinh,
					"cdbh": "false",
					"gtmtt": value,
				};
			}
			if (e.target.value === "6") {
				var value = this.props.buffseedinglikeinstagramorder.price.buffseedinglikeinstagramorder_sv6.prices_web;
				taotientrinh = {
					...taotientrinh,
					"cdbh": "false",
					"gtmtt": value,
				};
			}
			var new_taotientrinh = {
				...taotientrinh,
				[e.target.name]: e.target.value,
			};
			this.props.updateBSLIO(new_taotientrinh);
		} else if (e.target.name === "cdbh") {
			var { taotientrinh } = this.props.buffseedinglikeinstagramorder;
			if (taotientrinh.lsct === "1") {
				var value = this.props.buffseedinglikeinstagramorder.price.buffseedinglikeinstagramorder.prices_web;
				if (e.target.value === "true") {
					value = this.props.buffseedinglikeinstagramorder.price.buffseedinglikeinstagramorder.min_bh_web;
				}
				taotientrinh = {
					...taotientrinh,
					"gtmtt": value,
				};
			}
			if (taotientrinh.lsct === "2") {
				var value = this.props.buffseedinglikeinstagramorder.price.buffseedinglikeinstagramorder.prices_web;
				taotientrinh = {
					...taotientrinh,
					"cdbh": "false",
					"gtmtt": value,
				};
			}
			if (taotientrinh.lsct === "3") {
				var value = this.props.buffseedinglikeinstagramorder.price.buffseedinglikeinstagramorder.prices_web;
				taotientrinh = {
					...taotientrinh,
					"cdbh": "false",
					"gtmtt": value,
				};
			}
			if (taotientrinh.lsct === "4") {
				var value = this.props.buffseedinglikeinstagramorder.price.buffseedinglikeinstagramorder_sv4.prices_web;
				if (e.target.value === "true") {
					value = this.props.buffseedinglikeinstagramorder.price.buffseedinglikeinstagramorder_sv4.min_bh_web;
				}
				taotientrinh = {
					...taotientrinh,
					"gtmtt": value,
				};
			}
			if (taotientrinh.lsct === "5") {
				var value = this.props.buffseedinglikeinstagramorder.price.buffseedinglikeinstagramorder_sv5.prices_web;
				if (e.target.value === "true") {
					value = this.props.buffseedinglikeinstagramorder.price.buffseedinglikeinstagramorder_sv5.min_bh_web;
				}
				taotientrinh = {
					...taotientrinh,
					"gtmtt": value,
				};
			}
			if (taotientrinh.lsct === "6") {
				var value = this.props.buffseedinglikeinstagramorder.price.buffseedinglikeinstagramorder_sv6.prices_web;
				if (e.target.value === "true") {
					value = this.props.buffseedinglikeinstagramorder.price.buffseedinglikeinstagramorder_sv6.min_bh_web;
				}
				taotientrinh = {
					...taotientrinh,
					"gtmtt": value,
				};
			}
			var new_taotientrinh = {
				...taotientrinh,
				[e.target.name]: e.target.value,
			};
			this.props.updateBSLIO(new_taotientrinh);
		} else {
			const { taotientrinh } = this.props.buffseedinglikeinstagramorder;
			var new_taotientrinh = {
				...taotientrinh,
				[e.target.name]: e.target.value
			};
			this.props.updateBSLIO(new_taotientrinh);
		}
	}
	
	taoTienTrinhfunc = () => {
		const { taotientrinh } = this.props.buffseedinglikeinstagramorder;
		const { money, percent } = this.props.auth.user;
		const { min_bh_web, prices_web } = this.props.buffseedinglikeinstagramorder.price;
		var min_price = prices_web;
		if (taotientrinh.cdbh === 'true') {
			min_price = min_bh_web;
		}
		
		if (taotientrinh.gtmtt === "") {
			Swal.fire({
				icon: 'error',
				title: 'Lỗi',
				text: 'Chọn giá tiền mỗi tương tác',
			});
			return false;
		}
		if (taotientrinh.gtmtt < min_price) {
			Swal.fire({
				icon: 'error',
				title: 'Lỗi',
				text: 'Giá tiền mỗi tương tác thấp nhất: '+min_price+' Coin',
			});
			return false;
		}
		
		var final_price = Math.ceil((taotientrinh.gtmtt * taotientrinh.slct));
		
		if (money < final_price) {
			Swal.fire({
				icon: 'error',
				title: 'Lỗi',
				text: 'Không đủ tiền trong tài khoản',
			});
			return false;
		}
		
		const id_user = this.props.auth.user.id;
		var new_taotientrinh = {
			...taotientrinh,
			"id_user": id_user,
			type_api: 'buff_like'
		};
		Swal.fire({
			title: 'Bạn có chắc?',
			html: "Bạn có chắc chắn muốn mua buff "+taotientrinh.slct+" like với giá "+taotientrinh.gtmtt+" Coin / like? Khi đã bấm đồng ý đồng nghĩa với việc bạn đã đọc hết mục thông tin dịch vụ!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Đồng ý',
			cancelButtonText: 'Không'
		}).then((result) => {
			if (result.value) {
				this.props.addBSLIO(new_taotientrinh);
			}
		})
	}
	
	onKeyPress = (e) => {
		if (!(e.charCode >= 48 && e.charCode <= 57)) {
			e.preventDefault();
		}
	}
	
	onClickShowDescription = (data) => {
		this.setState({
			showDescription: data
		});
	}
	
	render() {
 		var { lhibv, lsct, cnbd, cnkt, slct, gtmtt, cdbh } = this.props.buffseedinglikeinstagramorder.taotientrinh;
 		var { instagrambufflike_sv1_mo_dong, instagrambufflike_sv2_mo_dong, instagrambufflike_sv3_mo_dong, instagrambufflike_sv4_mo_dong, instagrambufflike_sv5_mo_dong, instagrambufflike_sv6_mo_dong } = this.props.buffseedinglikeinstagramorder;
		const { min_bh_web, prices_web } = this.props.buffseedinglikeinstagramorder.price;
		var min_price = prices_web;
		var html_baohanh = '';
		if (cdbh === 'true') {
			min_price = min_bh_web;
			html_baohanh = (
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor=""></label>
					<div className="col-sm-8">
						<div className="form-group">
							<div className="row">
								<div className="col-md-6">
									<p className="mb-0">Chọn ngày bắt đầu</p>
									<DatePicker className="form-control w-100"
										showTimeSelect minDate={new Date()}
										selected={cnbd}
										onChange={this.handleChangeDateStart}
									/>
								</div>
								<div className="col-md-6">
									<p className="mb-0">Chọn ngày kết thúc</p>
									<DatePicker className="form-control"
										showTimeSelect minDate={cnbd}
										maxDate={this.addDays(cnbd, 7)}
										selected={cnkt}
										onChange={this.handleChangeDateEnd}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			)
		}
		
		var listDataServicesInfo = {};
		try {
			if (localStorage.getItem("listDataServicesInfo") != null) {
				listDataServicesInfo = JSON.parse(localStorage.getItem("listDataServicesInfo"));
			} else {
				listDataServicesInfo = this.props.adminpage.listDataServicesInfo;
			}
		} catch(e) {
			listDataServicesInfo = {};
		}
		var html_haydoctranhmattien = '';
		var html_haydoctranhmattien_chinh = '';
		var html_haydoctranhmattien_sv = '';
		var html_like_sv1 = '';
		var html_like_sv2 = '';
		var html_like_sv3 = '';
		var html_like_sv4 = '';
		var html_like_sv5 = '';
		var html_like_sv6 = '';
		if (typeof(listDataServicesInfo.instagram_buff_like_tab) !== 'undefined') {
			html_haydoctranhmattien = parse(listDataServicesInfo.instagram_buff_like_tab.ghichu);
			html_haydoctranhmattien_chinh = parse(listDataServicesInfo.instagram_buff_like_tab.ghichu_chinh);
		}
		if (typeof(listDataServicesInfo.instagram_buff_like_sv1) !== 'undefined') {
			html_like_sv1 = parse(listDataServicesInfo.instagram_buff_like_sv1.ghichu_sv);
			if (lsct.toString() === "1") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.instagram_buff_like_sv1.ghichu);
			}
		}
		if (typeof(listDataServicesInfo.instagram_buff_like_sv2) !== 'undefined') {
			html_like_sv2 = parse(listDataServicesInfo.instagram_buff_like_sv2.ghichu_sv);
			if (lsct.toString() === "2") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.instagram_buff_like_sv2.ghichu);
			}
		}
		if (typeof(listDataServicesInfo.instagram_buff_like_sv3) !== 'undefined') {
			html_like_sv3 = parse(listDataServicesInfo.instagram_buff_like_sv3.ghichu_sv);
			if (lsct.toString() === "3") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.instagram_buff_like_sv3.ghichu);
			}
		}
		if (typeof(listDataServicesInfo.instagram_buff_like_sv4) !== 'undefined') {
			html_like_sv4 = parse(listDataServicesInfo.instagram_buff_like_sv4.ghichu_sv);
			if (lsct.toString() === "4") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.instagram_buff_like_sv4.ghichu);
			}
		}
		if (typeof(listDataServicesInfo.instagram_buff_like_sv5) !== 'undefined') {
			html_like_sv5 = parse(listDataServicesInfo.instagram_buff_like_sv5.ghichu_sv);
			if (lsct.toString() === "5") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.instagram_buff_like_sv5.ghichu);
			}
		}
		if (typeof(listDataServicesInfo.instagram_buff_like_sv6) !== 'undefined') {
			html_like_sv6 = parse(listDataServicesInfo.instagram_buff_like_sv6.ghichu_sv);
			if (lsct.toString() === "6") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.instagram_buff_like_sv6.ghichu);
			}
		}
		
		const { money, percent } = this.props.auth.user;
		var price = Math.round((gtmtt * slct + Number.EPSILON) * 100) / 100;
		return (
			<Fragment>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Link hoặc ID Bài viết:</label>
					<div className="col-sm-8">
						<input type="text" className="form-control" id="lhibv" name="lhibv" placeholder="" onChange={this.onChange} value={lhibv} />
						<h6 style={{"marginTop":"5px", "marginBottom":"0px"}}>Hướng dẫn link instagram: <a href="https://i.imgur.com/z88UVW6.png" target="_blank" className="font-bold"> Tại đây</a></h6>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Loại Seeding cần tăng:</label>
					<div className="col-sm-8">
						<div className="custom-control custom-radio custom-control-inline" style={{opacity: this.props.buffseedinglikeinstagramorder.price.buffseedinglikeinstagramorder.prices_web == 0 ? '0.3' : '1' }}>
							<input onChange={this.onChange} value="1" type="radio" className="custom-control-input" id="loaiseeding_1" name="lsct" checked={lsct.toString() === "1"} disabled={this.props.buffseedinglikeinstagramorder.price.buffseedinglikeinstagramorder.prices_web == 0 ? 'disabled' : ''} />
							<label className="custom-control-label" htmlFor="loaiseeding_1" >SV1 {html_like_sv1} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.buffseedinglikeinstagramorder.price.buffseedinglikeinstagramorder.prices_web} coin</span> {instagrambufflike_sv1_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
						</div><br/>
						<div className="custom-control custom-radio custom-control-inline" style={{opacity: this.props.buffseedinglikeinstagramorder.price.buffseedinglikeinstagramorder_sv2.prices_web == 0 ? '0.3' : '1' }}>
							<input onChange={this.onChange} value="2" type="radio" className="custom-control-input" id="loaiseeding_2" name="lsct" checked={lsct.toString() === "2"} disabled={this.props.buffseedinglikeinstagramorder.price.buffseedinglikeinstagramorder_sv2.prices_web == 0 ? 'disabled' : ''} />
							<label className="custom-control-label" htmlFor="loaiseeding_2" >SV2 {html_like_sv2} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.buffseedinglikeinstagramorder.price.buffseedinglikeinstagramorder_sv2.prices_web} coin</span> {instagrambufflike_sv2_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
						</div><br/>
						<div className="custom-control custom-radio custom-control-inline" style={{opacity: this.props.buffseedinglikeinstagramorder.price.buffseedinglikeinstagramorder_sv3.prices_web == 0 ? '0.3' : '1' }}>
							<input onChange={this.onChange} value="3" type="radio" className="custom-control-input" id="loaiseeding_3" name="lsct" checked={lsct.toString() === "3"} disabled={this.props.buffseedinglikeinstagramorder.price.buffseedinglikeinstagramorder_sv3.prices_web == 0 ? 'disabled' : ''} />
							<label className="custom-control-label" htmlFor="loaiseeding_3" >SV3 {html_like_sv3} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.buffseedinglikeinstagramorder.price.buffseedinglikeinstagramorder_sv3.prices_web} coin</span> {instagrambufflike_sv3_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
						</div><br/>
						<div className="custom-control custom-radio custom-control-inline" style={{opacity: this.props.buffseedinglikeinstagramorder.price.buffseedinglikeinstagramorder_sv4.prices_web == 0 ? '0.3' : '1' }}>
							<input onChange={this.onChange} value="4" type="radio" className="custom-control-input" id="loaiseeding_4" name="lsct" checked={lsct.toString() === "4"} disabled={this.props.buffseedinglikeinstagramorder.price.buffseedinglikeinstagramorder_sv4.prices_web == 0 ? 'disabled' : ''} />
							<label className="custom-control-label" htmlFor="loaiseeding_4" >SV4 {html_like_sv4} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.buffseedinglikeinstagramorder.price.buffseedinglikeinstagramorder_sv4.prices_web} coin</span> {instagrambufflike_sv4_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
						</div><br/>
						<div className="custom-control custom-radio custom-control-inline" style={{opacity: this.props.buffseedinglikeinstagramorder.price.buffseedinglikeinstagramorder_sv5.prices_web == 0 ? '0.3' : '1' }}>
							<input onChange={this.onChange} value="5" type="radio" className="custom-control-input" id="loaiseeding_5" name="lsct" checked={lsct.toString() === "5"} disabled={this.props.buffseedinglikeinstagramorder.price.buffseedinglikeinstagramorder_sv5.prices_web == 0 ? 'disabled' : ''} />
							<label className="custom-control-label" htmlFor="loaiseeding_5" >SV5 {html_like_sv5} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.buffseedinglikeinstagramorder.price.buffseedinglikeinstagramorder_sv5.prices_web} coin</span> {instagrambufflike_sv5_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
						</div><br/>
						<div className="custom-control custom-radio custom-control-inline" style={{opacity: this.props.buffseedinglikeinstagramorder.price.buffseedinglikeinstagramorder_sv6.prices_web == 0 ? '0.3' : '1' }}>
							<input onChange={this.onChange} value="6" type="radio" className="custom-control-input" id="loaiseeding_6" name="lsct" checked={lsct.toString() === "6"} disabled={this.props.buffseedinglikeinstagramorder.price.buffseedinglikeinstagramorder_sv6.prices_web == 0 ? 'disabled' : ''} />
							<label className="custom-control-label" htmlFor="loaiseeding_6" >SV6 {html_like_sv6} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.buffseedinglikeinstagramorder.price.buffseedinglikeinstagramorder_sv6.prices_web} coin</span> {instagrambufflike_sv6_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
						</div>
					</div>
				</div>
				<div className="form-group row" style={{display:(lsct.toString() === "1" || lsct.toString() === "4" || lsct.toString() === "5") ? 'flex' : 'none'}}>
					<label className="col-sm-4 col-form-label" htmlFor="">Chế độ bảo hành:</label>
					<div className="col-sm-8">
						<select className="form-control" id="cdbh" name="cdbh" onChange={this.onChange} value={cdbh} >
							<option value="false">Không bảo hành</option>
							<option value="true">Bảo hành (Bảo hành nếu tụt quá 10%)</option>
						</select>
					</div>
				</div>
				{html_baohanh}
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Số lượng cần tăng:</label>
					<div className="col-sm-8">
						<input type="number" className="form-control" id="slct" name="slct" onChange={this.onChange} value={ slct } onKeyPress={this.onKeyPress} />
						<div className="card card-orange mt-2">
							<div className="card-body py-2">
								<h6 className="font-14 mb-0 text-danger font-bold">
									Lưu ý: Buff không bảo hành nên buff thừa 20 - 30% để đủ số lượng
								</h6>
							</div>
						</div>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">
						Giá tiền mỗi tương tác:
						<h6 className="font-bold mb-0 font-13">Giá thấp nhất: <span className="bold green">{min_price} Coin</span></h6>
					</label>
					<div className="col-sm-8">
						<div className="card card-orange mt-2">
							<input type="number" className="form-control" id="gtmtt" name="gtmtt" placeholder="" onChange={this.onChange} value={gtmtt} onKeyPress={this.onKeyPress} />
						</div>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Ghi chú:</label>
					<div className="col-sm-8">
						<textarea rows="2" placeholder="Nhập nội dung ghi chú về tiến trình của bạn" className="form-control input-gray" id="gc" name="gc" onChange={this.onChange} ></textarea>
					</div>
				</div>
				
				<button type="button" className="btn badge-primary-cus btn-rounded btn-show-description-cus" onClick={(e) => {this.onClickShowDescription(!this.state.showDescription)}}>
					{
						this.state.showDescription?
							<i className="fa fa-angle-up" style={{"marginRight": "5px"}} aria-hidden="true"></i>
						:
							<i className="fa fa-angle-down" style={{"marginRight": "5px"}} aria-hidden="true"></i>
					}
					Thông tin dịch vụ
				</button>
				
				<ServicesDescription allservicesinfo={listDataServicesInfo} server={lsct.toString()} tab="instagram_buff_like" giatien={gtmtt} baohanh={cdbh === 'true'? 1 : 0} showDescription={this.state.showDescription} />
				
				<div className="row mt-2" style={{"display": this.state.showDescription? "flex" : "none"}}>
				    <div className="col-md-12 bold" style={{"marginBottom": "10px"}}>
						<div className="card card-select-cus">
							<div className="card-body card-body-select-cus">
								<p className="mb-0 mt-3 text-wrap">
									<ul>
										<li>1 ID KHÔNG CÀI 2 ĐƠN CÙNG LÚC TRONG HỆ THỐNG ! ĐƠN CŨ XONG MỚI CÀI ĐƠN MỚI ! CỐ TÌNH CÀI BỊ HỤT SỐ LƯỢNG SẼ KHÔNG XỬ LÝ !</li>
										<li>Nếu ID đang chạy trên hệ thống mà bạn vẫn mua id đó cá hệ thống bên khác, nếu có tình trạng hụt số lượng giữa 2 bên thì sẽ không được xử lí!</li>
										{html_haydoctranhmattien}
										{html_haydoctranhmattien_chinh}
										{html_haydoctranhmattien_sv}
										<li>Nếu gặp lỗi hãy nhắn tin hỗ trợ phía bên phải góc màn hình hoặc vào mục liên hệ hỗ trợ để được hỗ trợ tốt nhất!</li>
									</ul>
								</p>
								<span className="badge badge-cus position-absolute-cus badge-top-left-cus badge-primary-cus badge-pill-cus"> HÃY ĐỌC ĐỂ TRÁNH MẤT TIỀN KHI SỬ DỤNG! </span>
							</div>
						</div>
				    </div>
				</div>
				<div className="row mt-4">
					<div className="col-12 text-center bold">
						<div className="card card-info">
							<div className="card-body">
								<h5 className="font-bold">Tổng: <span className="bold green"><span>{price}</span> Coin</span></h5>
								<h6 className="mb-0">Bạn sẽ buff <span className="bold green">{slct} like</span> với giá <span className="green bold">{price}</span> Coin</h6>
							</div>
						</div>
					</div>
				</div>
				<div className="row my-4">
					<div className="col-12 text-center bold">
						<button type="button" className="btn btn-block btn-rounded btn-hero-primary" onClick={this.taoTienTrinhfunc} >Tạo tiến trình</button>
					</div>
				</div>
			</Fragment>
		)
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	buffseedinglikeinstagramorder: state.buffseedinglikeinstagramorder,
});
const mapDispatchToProps = { updateBSLIO, addBSLIO, loadPriceBSLIO, getConfigMoDong }

export default connect(mapStateToProps, mapDispatchToProps)(taotientrinh);