import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import axios from  'axios';
import store from '../../../store';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { creatItemBuffSub, getInfoTiktok, getConfigMoDong } from '../../../actions/buffsubTiktok';
import parse from 'html-react-parser';
import Swal from 'sweetalert2';
import ServicesDescription from "../ServicesDescription";
import { isMobile } from 'react-device-detect';

class Taotientrinh extends Component {
	componentDidMount() {
        this.props.getConfigMoDong('tiktokbuff_mat');
	};
	
	state = {
		showDescription: isMobile? false : true
	}
	
	searchIdTiktok = (e) => {
		let valueChange = e.target.value;
		let link = valueChange;
		let dataForm = this.props.buffsub.formData;
		let checkerr = 0;
		var loaigetinfo = dataForm.loaiseeding;
		if (dataForm.loaiseeding === 'follow' || dataForm.loaiseeding === 'follow_sv2' || dataForm.loaiseeding === 'tiktok_buffmat' || dataForm.loaiseeding === 'tiktok_buffmat_sv2') {
			// if (valueChange.indexOf('tiktok.com/@') > -1) {
				// valueChange = valueChange.slice(valueChange.indexOf('tiktok.com/@'));
				// valueChange = valueChange.slice(valueChange.indexOf('@') + 1);
			// }
			
			var matches = valueChange.match(/video\/+[0-9]+/g);
			if (matches != null) {
				valueChange = matches[0].replace("video/", "");
				loaigetinfo = 'liketosub';
			} else {
				// // valueChange='';
				// if (valueChange !== '' && isNaN(valueChange)) {
					// Swal.fire({
						// icon: 'error',
						// html: 'Không lấy được ID TikTok'
					// });
				// }
				var matches = valueChange.match(/@+(.*\/|.*\?|.*)/g);
				if (matches != null) {
					valueChange = matches[0].replace(/[@//?]/g, "");
				} else {
					// checkerr++;
					// // valueChange='';
					// if (valueChange !== '') {
						// Swal.fire({
							// icon: 'error',
							// html: 'Không lấy được ID TikTok'
						// });
					// }
				}
			}
		} else if (dataForm.loaiseeding === 'comment' || dataForm.loaiseeding === 'like' || dataForm.loaiseeding === 'view') {
			// if (valueChange.indexOf('tiktok.com/@') > -1) {
				// if (valueChange.indexOf('video/') > -1) {
					// valueChange = valueChange.slice(valueChange.indexOf('video/') + 6);
				// } else {
					// checkerr++;
					// valueChange='';
					// alert('không lấy dược id');
				// }
			// }
			var matches = valueChange.match(/video\/+[0-9]+/g);
			if (matches != null) {
				valueChange = matches[0].replace("video/", "");
			} else {
				// // valueChange='';
				// if (valueChange !== '' && isNaN(valueChange)) {
					// Swal.fire({
						// icon: 'error',
						// html: 'Không lấy được ID TikTok'
					// });
				// }
			}
		}
		
		if (checkerr === 0 && valueChange !== '') {
			this.props.getInfoTiktok(loaigetinfo, valueChange);
		}
		
		store.dispatch({
			type: 'CHANGE_DATA_FORM_BUFF_SUB_TIKTOK',
			payload: {name: e.target.name, value: valueChange}
		});

		if (checkerr === 0) {
			store.dispatch({
				type: 'CHANGE_DATA_FORM_BUFF_SUB_TIKTOK',
				payload: {name: 'link', value: link}
			});
		}
	}

	handleChangeDateStart = date => {
	    let dataFormOld = this.props.buffsub.formData;

		store.dispatch({
			type: 'CHANGE_DATA_FORM_BUFF_SUB_TIKTOK',
			payload: {name: 'EndDatebh', value: this.addDays(date, 7)}
		});
		store.dispatch({
			type: 'CHANGE_DATA_FORM_BUFF_SUB_TIKTOK',
			payload: {name: 'startDatebh', value: date}
		});
	}

	handleChangeDateEnd = date => {
	    let dataFormOld = this.props.buffsub.formData;

		store.dispatch({
			type: 'CHANGE_DATA_FORM_BUFF_SUB_TIKTOK',
			payload: {name: 'EndDatebh', value: date}
		});
	}

	addDays = (date, days) => {
	  	var result = new Date(date);
	  	result.setDate(result.getDate() + days);
	  	return result;
	}

	onChangeForm = (e, checknumber = 0) => {
		let valueChange = e.target.value;
		if (checknumber === 1 && e.target.name === 'sltang') {
			if (valueChange.trim() !== '') {
				valueChange = Math.abs(parseInt(valueChange));
				if (isNaN(valueChange)) {
					valueChange = '';
				}
			}
		}
		store.dispatch({
			type: 'CHANGE_DATA_FORM_BUFF_SUB_TIKTOK',
			payload: {name: e.target.name, value: valueChange}
		});
	}

	onChangeLoaiSeeding = (e) => {
		let valueChange = e.target.value;
		let dataForm = this.props.buffsub.formData;
		let dataPriceAll = this.props.buffsub.datapricebuffsub;
		let getStPriceEnd = this.props.buffsub.datapricebuffsub[valueChange].prices_web;
		if (dataForm.baohanh == 1) {
			getStPriceEnd = this.props.buffsub.datapricebuffsub[valueChange].min_bh_web;
		}
		store.dispatch({
			type: 'CHANGE_DATA_FORM_BUFF_SUB_TIKTOK',
			payload: {name: 'giatien', value: getStPriceEnd}
		});

		let sltang_rt = 100;
		if (valueChange === 'comment') {
			sltang_rt = 0;
		}
		store.dispatch({
			type: 'CHANGE_DATA_FORM_BUFF_SUB_TIKTOK',
			payload: {name: 'sltang', value: sltang_rt}
		});

		store.dispatch({
			type: 'CHANGE_DATA_FORM_BUFF_SUB_TIKTOK',
			payload: {name: e.target.name, value: valueChange}
		});
	}

	onChangeBh = (e, checknumber = 0) => {
		let valueChange = e.target.value;
		if (checknumber === 1) {
			if (valueChange.trim() !== '') {
				valueChange = Math.abs(parseInt(valueChange));
				if (isNaN(valueChange)) {
					valueChange = '';
				}
			}
		}
		let dataForm = this.props.buffsub.formData;
		let dataPriceAll = this.props.buffsub.datapricebuffsub;
		let getStPriceEnd = this.props.buffsub.datapricebuffsub[dataForm.loaiseeding].prices_web;

		if (valueChange == 1) {
			getStPriceEnd = this.props.buffsub.datapricebuffsub[dataForm.loaiseeding].min_bh_web;
		}

		store.dispatch({
			type: 'CHANGE_DATA_FORM_BUFF_SUB_TIKTOK',
			payload: {name: 'giatien', value: getStPriceEnd}
		});

		store.dispatch({
			type: 'CHANGE_DATA_FORM_BUFF_SUB_TIKTOK',
			payload: {name: e.target.name, value: valueChange}
		});
	}

	getSltangComment = (e) => {
		let el = e.target;
		let countEnd = el.value.split("\n").length;
		if (el.value.trim() === '') {
			countEnd = 0;
		}

		store.dispatch({
			type: 'CHANGE_DATA_FORM_BUFF_SUB_TIKTOK',
			payload: {name: 'sltang', value: countEnd}
		});
		store.dispatch({
			type: 'CHANGE_DATA_FORM_BUFF_SUB_TIKTOK',
			payload: {name: 'list_messages', value: el.value.split("\n")}
		});
	}
	option_slmct = () => {
		let ar_option_slmct = [];

		for (let i = 100; i <= 50000; i += 100) {
			ar_option_slmct.push(
				<option value={i} key={i}>
					{i}
				</option>
			)
		}
		return ar_option_slmct;
	}
	option_ctgdtmtvtt = (lsct) => {
		let ar_option_ctgdtmtvtt = [];

		// if (lsct.toString() === "1") {
			var i = 30;
			for (i; i < 60; i += 15) {
				ar_option_ctgdtmtvtt.push(
					<option value={i} key={i}>
						{i} phút
					</option>
				)
			}
			for (i = 60; i <= 240; i += 30) {
				ar_option_ctgdtmtvtt.push(
					<option value={i} key={i}>
						{i} phút
					</option>
				)
			}
		// } else {
			// ar_option_ctgdtmtvtt.push(
				// <option value="40" key="40">
					// 40 phút
				// </option>
			// )
		// }
		return ar_option_ctgdtmtvtt;
	}
	
	onKeyPress = (e) => {
		if (!(e.charCode >= 48 && e.charCode <= 57)) {
			e.preventDefault();
		}
	}

	onClickShowDescription = (data) => {
		this.setState({
			showDescription: data
		});
	}
	
 	render() {
 		const {formData, tiktokbuffmat_sv1_mo_dong, tiktokbuffmat_sv2_mo_dong} = this.props.buffsub;
		var info = '';
		if ((formData.loaiseeding === 'follow' || formData.loaiseeding === 'follow_sv2' || formData.loaiseeding === 'tiktok_buffmat' || formData.loaiseeding === 'tiktok_buffmat_sv2') && formData.infoTiktok.userid_tiktok !== '') {
			info = parse('<img src="'+formData.infoTiktok.avatarMedium_tiktok+'" class="avatar-user" /> ' + formData.infoTiktok.uniqueId_tiktok + ' ( sub: ' + formData.infoTiktok.fans_tiktok + ')');
		}
		if ((formData.loaiseeding === 'comment' || formData.loaiseeding === 'like' || formData.loaiseeding === 'view') && formData.infoTiktok.userid_tiktok !== '') {
			let param_view = "";
			if (formData.loaiseeding === 'view') {
				if (typeof formData.infoTiktok.playCount !== 'undefined') {
					param_view += ' ( View: ' + formData.infoTiktok.playCount + ')';
				}
			} else if (formData.loaiseeding === 'comment') {
				if (typeof formData.infoTiktok.cmt_count !== 'undefined') {
					param_view += ' ( Comment: ' + formData.infoTiktok.cmt_count + ')';
				}
			} else {
				if (typeof formData.infoTiktok.like_tiktok !== 'undefined') {
					param_view += ' ( Like: ' + formData.infoTiktok.like_tiktok + ')';
				}
			}
			info = parse('<img src="'+formData.infoTiktok.avatarMedium_tiktok+'" class="avatar-user" /> ' + formData.infoTiktok.uniqueId_tiktok + param_view);
		}
		var price = Math.round((formData.sltang * formData.tgdtm * formData.giatien + Number.EPSILON) * 100) / 100;
		if (formData.sltang == 30) {
			price = Math.round((50 * formData.tgdtm * formData.giatien + Number.EPSILON) * 100) / 100;
		}
		
		var listDataServicesInfo = {};
		try {
			if (localStorage.getItem("listDataServicesInfo") != null) {
				listDataServicesInfo = JSON.parse(localStorage.getItem("listDataServicesInfo"));
			} else {
				listDataServicesInfo = this.props.adminpage.listDataServicesInfo;
			}
		} catch(e) {
			listDataServicesInfo = {};
		}
		var html_haydoctranhmattien = '';
		var html_haydoctranhmattien_chinh = '';
		var html_haydoctranhmattien_sv = '';
		var html_mat_sv1 = '';
		var html_mat_sv2 = '';
		if (typeof(listDataServicesInfo.tiktok_buff_mat_tab) !== 'undefined') {
			html_haydoctranhmattien = parse(listDataServicesInfo.tiktok_buff_mat_tab.ghichu);
			html_haydoctranhmattien_chinh = parse(listDataServicesInfo.tiktok_buff_mat_tab.ghichu_chinh);
		}
		if (typeof(listDataServicesInfo.tiktok_buff_mat_sv1) !== 'undefined') {
			html_mat_sv1 = parse(listDataServicesInfo.tiktok_buff_mat_sv1.ghichu_sv);
			if (formData.loaiseeding === "tiktok_buffmat") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.tiktok_buff_mat_sv1.ghichu);
			}
		}
		if (typeof(listDataServicesInfo.tiktok_buff_mat_sv2) !== 'undefined') {
			html_mat_sv2 = parse(listDataServicesInfo.tiktok_buff_mat_sv2.ghichu_sv);
			if (formData.loaiseeding === "tiktok_buffmat_sv2") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.tiktok_buff_mat_sv2.ghichu);
			}
		}
		
 		return (
    		<Fragment>
    			<div className="row">
					<div className="col-md-4">
						<label className="mt-2">Link hoặc ID trang cá nhân:</label>
					</div>
					<div className="col-md-8">
						<div className="form-group">
                            <input onChange={(e) => {this.searchIdTiktok(e)}} value={formData.profile_user} name="profile_user" type="text" className="form-control"/>
							{info}
							<h6 style={{"marginTop":"5px", "marginBottom":"0px"}}><span className="text-danger">Hãy tắt chế độ tài khoản riêng tư (Bắt buộc)</span>: <a href="https://i.imgur.com/g5ebGqF.png" target="_blank" className="font-bold"> Tại đây</a></h6>
							<h6 style={{"marginTop":"5px", "marginBottom":"0px"}}>Hướng dẫn link tiktok: <a href="https://i.imgur.com/uoLshF4.png" target="_blank" className="font-bold"> Tại đây(1)</a> hoặc <a href="https://i.imgur.com/A22d32Q.png" target="_blank" className="font-bold"> Tại đây(2)</a></h6>
							{
								formData.loaiseeding === 'view' || formData.loaiseeding === 'comment' || formData.loaiseeding === 'like' ?
									<h6 style={{"marginTop":"5px","marginBottom":"0px"}}>Link chuẩn để buff like, cmt, view: <span className="text-danger"> https://www.tiktok.com/@username/video/id_video</span></h6>
								: ''
							}
							{
								formData.loaiseeding === 'follow' || formData.loaiseeding === 'follow_sv2' || formData.loaiseeding === 'tiktok_buffmat' || formData.loaiseeding === 'tiktok_buffmat_sv2' ?
									<h6 style={{"marginTop":"5px","marginBottom":"0px"}}>Link chuẩn để buff mắt: <span className="text-danger"> https://www.tiktok.com/@username</span> hoặc <span className="text-danger"> https://www.tiktok.com/@username/video/id_video</span></h6>
								: ''
							}
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-4">
						<label className="mt-2">Loại Seeding cần tăng:</label>
					</div>
					<div className="col-sm-8">
						<div className="custom-control custom-radio custom-control-inline" style={{opacity: this.props.buffsub.datapricebuffsub['tiktok_buffmat'].prices_web == 0 ? '0.3' : '1' }}>
							<input onChange={(e) => {this.onChangeLoaiSeeding(e)}} value="tiktok_buffmat" type="radio" className="custom-control-input" id="loaiseeding_1" name="loaiseeding" checked={formData.loaiseeding === "tiktok_buffmat"} disabled={this.props.buffsub.datapricebuffsub['tiktok_buffmat'].prices_web == 0 ? 'disabled' : ''} />
							<label className="custom-control-label" htmlFor="loaiseeding_1" > SV1 {html_mat_sv1} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.buffsub.datapricebuffsub['tiktok_buffmat'].prices_web} coin/mắt/phút</span> {tiktokbuffmat_sv1_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
						</div>
						<div className="custom-control custom-radio custom-control-inline" style={{opacity: this.props.buffsub.datapricebuffsub['tiktok_buffmat_sv2'].prices_web == 0 ? '0.3' : '1' }}>
							<input onChange={(e) => {this.onChangeLoaiSeeding(e)}} value="tiktok_buffmat_sv2" type="radio" className="custom-control-input" id="loaiseeding_2" name="loaiseeding" checked={formData.loaiseeding === "tiktok_buffmat_sv2"} disabled={this.props.buffsub.datapricebuffsub['tiktok_buffmat_sv2'].prices_web == 0 ? 'disabled' : ''} />
							<label className="custom-control-label" htmlFor="loaiseeding_2" > SV2 {html_mat_sv2} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.buffsub.datapricebuffsub['tiktok_buffmat_sv2'].prices_web} coin/mắt/phút</span> {tiktokbuffmat_sv2_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
						</div>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Số lượng mắt cần tăng:</label>
					<div className="col-sm-8">
						{/* <select className="form-control" id="sltang" name="sltang" onChange={(e) => {this.onChangeForm(e)}} value={formData.sltang} >
							{this.option_slmct()}
						</select> */}
						<input type="number" className="form-control" onChange={(e) => {this.onChangeForm(e)}} name="sltang" value={formData.sltang} onKeyPress={this.onKeyPress} />
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Thời gian duy trì mắt:</label>
					<div className="col-sm-8">
						<select className="form-control" id="tgdtm" name="tgdtm" onChange={(e) => {this.onChangeForm(e)}} value={formData.tgdtm} >
							{/* this.option_ctgdtmtvtt(formData.loaiseeding.toString()) */}
							<option value="30">30 phút</option>
							<option value="60">60 phút</option>
							<option value="90">90 phút</option>
							<option value="120">120 phút</option>
							<option value="180">180 phút</option>
							<option value="240">240 phút</option>
						</select>
					</div>
				</div>
				<div className="row mt-2">
					<div className="col-md-4">
						<label className="mt-2">Ghi chú:</label>
					</div>
					<div className="col-md-8">
						<div className="form-group">
                            <textarea onChange={(e) => {this.onChangeForm(e)}} name="ghichu" value={formData.ghichu} className="form-control" rows="2" placeholder="Nhập nội dung ghi chú về tiến trình của bạn"></textarea>
                        </div>
					</div>
				</div>
				<div className="form-group row">
					<div className="col-sm-12">
						<div className="card card-orange text-center">
							<div className="card-body">
								<p className="text-danger mt-2 mb-0"><b className="font-bold">Tổng tiền = (Số lượng mắt) x (Số phút) x (Giá tiền order mỗi mắt)</b></p>
							</div>
						</div>
					</div>
				</div>

				<button type="button" className="btn badge-primary-cus btn-rounded btn-show-description-cus" onClick={(e) => {this.onClickShowDescription(!this.state.showDescription)}}>
					{
						this.state.showDescription?
							<i className="fa fa-angle-up" style={{"marginRight": "5px"}} aria-hidden="true"></i>
						:
							<i className="fa fa-angle-down" style={{"marginRight": "5px"}} aria-hidden="true"></i>
					}
					Thông tin dịch vụ
				</button>
				
				<ServicesDescription allservicesinfo={listDataServicesInfo} server={formData.loaiseeding} tab="tiktok_buff_mat" giatien={formData.giatien} baohanh={formData.baohanh} showDescription={this.state.showDescription} />
				
				<div className="row mt-2" style={{"display": this.state.showDescription? "flex" : "none"}}>
				    <div className="col-md-12 bold" style={{"marginBottom": "10px"}}>
						<div className="card card-select-cus">
							<div className="card-body card-body-select-cus">
								<p className="mb-0 mt-3 text-wrap">
									<ul>
										<li>1 ID KHÔNG CÀI 2 ĐƠN CÙNG LÚC TRONG HỆ THỐNG ! ĐƠN CŨ XONG MỚI CÀI ĐƠN MỚI ! CỐ TÌNH CÀI BỊ HỤT SỐ LƯỢNG SẼ KHÔNG XỬ LÝ !</li>
										<li>Nếu ID đang chạy trên hệ thống mà bạn vẫn mua id đó cá hệ thống bên khác, nếu có tình trạng hụt số lượng giữa 2 bên thì sẽ không được xử lí!</li>
										{html_haydoctranhmattien}
										{html_haydoctranhmattien_chinh}
										{html_haydoctranhmattien_sv}
										<li>Nếu gặp lỗi hãy nhắn tin hỗ trợ phía bên phải góc màn hình hoặc vào mục liên hệ hỗ trợ để được hỗ trợ tốt nhất!</li>
									</ul>
								</p>
								<span className="badge badge-cus position-absolute-cus badge-top-left-cus badge-primary-cus badge-pill-cus"> HÃY ĐỌC ĐỂ TRÁNH MẤT TIỀN KHI SỬ DỤNG! </span>
							</div>
						</div>
				    </div>
				</div>
				<div className="row mt-4">
					<div className="col-12 text-center bold">
						<div className="card card-info">
							<div className="card-body">
								<h5 className="font-bold">Tổng: <span className="bold green"><span>{price}</span> Coin</span></h5>
								<h6 className="mb-0">Bạn sẽ buff <span className="bold green">{formData.sltang == 30 ? "20-30": formData.sltang} Mắt</span> trong <span className="green bold">{formData.tgdtm} Phút</span> với giá <span className="green bold">{formData.giatien}</span> Coin / Mắt</h6>
							</div>
						</div>
					</div>
				</div>

				<div className="row mt-4">
					<div className="col-md-12">
						<div className='form-group float-right w-100'>
							<button onClick={(e) => {this.props.creatItemBuffSub(formData)}} type="button" className="btn btn-hero-primary btn-rounded btn-block">
	                            Tạo tiến trình
	                        </button>
                        </div>
					</div>
				</div>
			</Fragment>
		);
  	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	buffsub: state.buffmattiktok
});

export default connect(mapStateToProps, {creatItemBuffSub, getInfoTiktok, getConfigMoDong})(Taotientrinh);
