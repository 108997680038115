import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import store from '../../../store';

import ModalAddAccountClone from './modal/addaccountclone';
import ModalEditAccountClone from './modal/editaccountclone';

import { getListDaiLy, deleteDaiLy } from '../../../actions/adminPageAction';
import BootstrapTable from 'react-bootstrap-table-next';
import Moment from 'react-moment';
import paginationFactory from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import NumberFormat from 'react-number-format';
import Modaledit from './modal/editcardhistory';
const { SearchBar } = Search;

class TagBankPaymentHistoy extends Component {
	state = {
		
    }

    componentDidMount(){
        this.props.getListDaiLy();
    }
	
    render() {
		const columns = [
			{
			  dataField: 'id',
			  text: 'STT',
		   	  sort: true,
			},
			{
			  dataField: 'url_daily',
			  text: 'URL Đại Lý',
			  sort: true
			},
			{
			  dataField: 'username',
			  text: 'Tài Khoản Đại Lý',
			  sort: true,
			  classes: 'notranslate',
			}
			/*
				dataField: '',
				text: '',
				formatter: (cell, row) => 
					<Fragment>
						<Link to="/admin" className="btn btn-hero-danger text-center p-2 mr-1" onClick={() => {this.props.deleteDaiLy(row.id)}}>
							<i className="fa fa-fw fa-times"></i>
						</Link>
					</Fragment>
			*/
		];

        const {listdaily} = this.props.adminpage;
    	return (
    		<Fragment>
			
				<ToolkitProvider keyField="id" data={ listdaily } columns={ columns } search >
					{
						props => (
							<div>
								<SearchBar { ...props.searchProps } />
								<BootstrapTable { ...props.baseProps } pagination={ paginationFactory() } wrapperClasses="table-responsive" striped hover condensed />
							</div>
						)
					}
				</ToolkitProvider>
			</Fragment>
			
		);
    }

}

const mapStateToProps = state => ({
	adminpage: state.adminpage,
});

export default connect(mapStateToProps, { getListDaiLy, deleteDaiLy }) (TagBankPaymentHistoy);
