import {
	LOAD_VV,
	UPDATE_VV,
	UPDATE_VV_TAB,
	LOAD_PRICE_VV,
	LIMIT_PAGI_VV,
	TYPE_VV,
	LOADING_VV,
	LOADING_VV_2,
	LOAD_NHATKYHOATDONG_VV
} from "../actions/types";

const initialState = {
	"taotientrinh": {
		"lhi": "",
		"lsct": "sv1",
		"slct": "10",
		"gc": "",
		"gtmtt": "0",
	},
	"changetab": 0,
	"listtientrinh": [],
	"tanggiathem": {
		"id": "",
		"lhibv": "",
		"price_per": 0,
		"price": 0
	},
	"price": {
		Vimeoview: {
			min_bh_web: 0,
			prices_web: 0,
		},
	},
	"limit_pagi": 0,
	"type": 0,
	"loading": false,
	"loading2": false,
	"nhatkyhoatdong": [],
	vimeobuffview_mo_dong: 1,
};

export default function(state = initialState, action) {
	switch (action.type) {
		case LOAD_VV:
			return {
				...state,
				"listtientrinh": action.payload
			};
		case UPDATE_VV:
			return {
				...state,
				"taotientrinh": action.payload
			};
		case UPDATE_VV_TAB:
			return {
				...state,
				"changetab": action.payload
			};
		case LOAD_PRICE_VV:
			return {
				...state,
				"price": merDataPricePage(action.payload),
			};
		case LIMIT_PAGI_VV:
			return {
				...state,
				"limit_pagi": action.payload
			};
		case TYPE_VV:
			return {
				...state,
				"type": action.payload
			};
		case LOADING_VV:
			return {
				...state,
				"loading": action.payload
			};
		case LOADING_VV_2:
			return {
				...state,
				"loading2": action.payload
			};
		case LOAD_NHATKYHOATDONG_VV:
			return {
				...state,
				"nhatkyhoatdong": action.payload
			};
		case 'CONFIG_MO_DONG_VV':
			return {
				...state,
				vimeobuffview_mo_dong: action.payload.vimeobuffview_mo_dong,
			};
		default:
		  return state;
	}
}

function merDataPricePage(data) {
	let dataNew = {
		...initialState.price
	}
	if (data.length > 0) {
		data.forEach(function(v, i){
			if (v.name_table === 'vimeoview') {
				initialState.taotientrinh.gtmtt = v.prices_web;
				dataNew['Vimeoview'] = v;
			}
		});
	};
	return dataNew;
}
