import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import TaoTientrinh from "./tabViplikethang/taotientrinh";
import NhatKy from "./tabViplikethang/nhatky";
import DanhSach from "./tabViplikethang/danhsach";

import { loadVLT, changeTab } from '../../actions/ViplikethangActions';
import { load, changeTabFbVip } from '../../actions/FbvipActions';

class Viplikethang extends Component {
	static propTypes =  {
		loadVLT: PropTypes.func.isRequired,
		changeTab: PropTypes.func.isRequired,
		changeTabFbVip: PropTypes.func.isRequired,
	};
	componentDidMount() {
		this.props.changeTabFbVip('viplikethang', 0);
	};
	onClick = (value) => {
		this.props.changeTabFbVip('viplikethang', value);
		// this.props.loadVLT();
		this.props.load('viplikethang');
	};
	render() {
		const { changetab, loading } = this.props.Viplikethang;
		return (
			<Fragment>
				<div className={"block block-bordered shadow"+ (loading? ' block-mode-loading' : '')}>
					<ul className="nav nav-tabs nav-tabs-block" data-toggle="tabs" role="tablist" >
						<li className="nav-item col-lg-6 licus">
							<Link className={"nav-link " + (changetab === 0 ? "active": "" )} to="/tool/vip-seeding-order" onClick={() => { this.onClick(0) }} >
								Tạo Tiến Trình
							</Link>
						</li>
						<li className="nav-item col-lg-6 licus">
							<Link className={"nav-link " + (changetab === 2 ? "active": "" )} to="/tool/vip-seeding-order" onClick={() => { this.onClick(2) }} >
								Danh Sách Order
							</Link>
						</li>
						{/*<li className="nav-item col-lg-6 licus">
							<Link className={"nav-link " + (changetab === 1 ? "active": "" )} to="/tool/vip-seeding-order" onClick={() => { this.onClick(1) }} >
								Nhật ký chạy VIP
							</Link>
						</li>*/}
					</ul>
					<div className="block-content tab-content overflow-hidden">
						<div className={"tab-pane fade fade-left " + (changetab === 0 ? "show active": "" )} id="btabs-animated-slideleft-home" role="tabpanel" >
							<TaoTientrinh />
						</div>
						<div className={"tab-pane fade fade-left " + (changetab === 2 ? "show active": "" )} id="btabs-animated-slideleft-list" role="tabpanel" >
							<DanhSach />
						</div>
						{/*<div className={"tab-pane fade fade-left " + (changetab === 1 ? "show active": "" )} id="btabs-animated-slideleft-profile" role="tabpanel" >
							<NhatKy />
						</div>*/}
					</div>
				</div>
			</Fragment>
		);
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	Viplikethang: state.Viplikethang,
});
const mapDispatchToProps = { loadVLT, changeTab, load, changeTabFbVip };

export default connect(mapStateToProps, mapDispatchToProps)(Viplikethang);
