import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import {
    Modal,
    ModalBody,
    ModalFooter
} from 'reactstrap';
import { saveAddPriceFunction } from '../../../../actions/adminPageAction';
import parse from 'html-react-parser';

class ModalAddPriceFunction extends Component {
    state = {
        formdata: {
            start_price_api: 0,
            end_price_api: 0,
            start_price_web: 0,
            price_bh_web: 0,
            price_bh_api:0,
            usertype: this.props.adminpage.listdatausertype[0].id,
            nameusertype: 'user',
            name: '',
            name_value: ''
        }
    }

    onChangeForm = (e) => {
        let value_add = e.target.value;
        if (isNaN(value_add)) {
            value_add = '';
        }
        this.setState({
            formdata: {
                ...this.state.formdata,
                [e.target.name]: value_add
            }
        });
    }

    onChangeFormFunction = (e) => {
        let name_function = e.target.options[e.target.selectedIndex].text;
        if (e.target.name === 'name_function_web') {
            var datanew = {
                ...this.state.formdata,
                name_value: e.target.value,
                name: name_function,
            }
        } else if (e.target.name === 'usertype') {
            var datanew = {
                ...this.state.formdata,
                usertype: parseInt(e.target.value),
                nameusertype: name_function,
            }
        }
        this.setState({
            formdata: datanew
        });
    }
	resetFormdata = (e) => {
        this.setState({
            formdata: {
				start_price_api: 0,
				end_price_api: 0,
				start_price_web: 0,
				price_bh_web: 0,
				price_bh_api:0,
				usertype: this.props.adminpage.listdatausertype[0].id,
				nameusertype: 'user',
				name: '',
				name_value: ''
			}
        });
	}

    render() {
        const {isOpenModal} = this.props;
        const {formdata} = this.state;
        const { listdatausertype, loadingblock } = this.props.adminpage;
        const optionUserType = (
            <Fragment>
				{
					listdatausertype.map(function (dataList, index) {
						return (
							<option value={dataList.id} key={index}>{dataList.name}</option>
						);
					})
				}
            </Fragment>
        )
		var lv2 = '&nbsp;&nbsp;&nbsp;&nbsp;';
		var lv3 = '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;';
        return (
            <Fragment>
                <Modal centered={true} size="lg" toggle={(e) => {this.resetFormdata(e); this.props.actionOpenModal()}} isOpen={isOpenModal} className={(loadingblock? ' block block-mode-loading' : '')} style={{"backgroundColor": "inherit", "boxShadow": "none"}}>
                    <div className="modal-header">
                        <p className="mb-0 text-dark">Thêm giá chức năng</p>
                        <button onClick={(e) => {this.props.actionOpenModal()}} type="button" className="close">×</button>
                    </div>
                    <ModalBody style={{opacity: loadingblock? '.05':'1'}}>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label className="mr-4">Chức năng:</label>
                                    <select onChange={(e) => {this.onChangeFormFunction(e)}} name="name_function_web" className="form-control">
										<option value="" selected>--</option>
										<optgroup label="Facebook BUFF">
											<option value="" className="font-bold" style={{"color": "#000"}} disabled>{parse(lv2)}Facebook like comment share (CẢM XÚC)</option>
												<option value="fb_likecommentshare_like">{parse(lv3)}Facebook like comment share (like)</option>
												<option value="fb_likecommentshare_care">{parse(lv3)}Facebook like comment share (care)</option>
												<option value="fb_likecommentshare_love">{parse(lv3)}Facebook like comment share (love)</option>
												<option value="fb_likecommentshare_haha">{parse(lv3)}Facebook like comment share (haha)</option>
												<option value="fb_likecommentshare_sad">{parse(lv3)}Facebook like comment share (sad)</option>
												<option value="fb_likecommentshare_wow">{parse(lv3)}Facebook like comment share (wow)</option>
												<option value="fb_likecommentshare_angry">{parse(lv3)}Facebook like comment share (angry)</option>
												<option value="fb_likecommentshare_like_clone">{parse(lv3)}Facebook like comment share (like clone)</option>
												<option value="fb_likecommentshare_care_clone">{parse(lv3)}Facebook like comment share (care clone)</option>
												<option value="fb_likecommentshare_love_clone">{parse(lv3)}Facebook like comment share (love clone)</option>
												<option value="fb_likecommentshare_haha_clone">{parse(lv3)}Facebook like comment share (haha clone)</option>
												<option value="fb_likecommentshare_sad_clone">{parse(lv3)}Facebook like comment share (sad clone)</option>
												<option value="fb_likecommentshare_wow_clone">{parse(lv3)}Facebook like comment share (wow clone)</option>
												<option value="fb_likecommentshare_angry_clone">{parse(lv3)}Facebook like comment share (angry clone)</option>
												<option value="fb_likecommentshare_like_v2">{parse(lv3)}Facebook like comment share (like Việt V2)</option>
												{/* <option value="fb_likecommentshare_care_v2">{parse(lv3)}Facebook like comment share (care Việt V2)</option>
												<option value="fb_likecommentshare_love_v2">{parse(lv3)}Facebook like comment share (love Việt V2)</option>
												<option value="fb_likecommentshare_haha_v2">{parse(lv3)}Facebook like comment share (haha Việt V2)</option>
												<option value="fb_likecommentshare_sad_v2">{parse(lv3)}Facebook like comment share (sad Việt V2)</option>
												<option value="fb_likecommentshare_wow_v2">{parse(lv3)}Facebook like comment share (wow Việt V2)</option>
												<option value="fb_likecommentshare_angry_v2">{parse(lv3)}Facebook like comment share (angry Việt V2)</option> */}
												<option value="fb_likecommentshare_like_v3">{parse(lv3)}Facebook like comment share (like Việt V3)</option>
												{/* <option value="fb_likecommentshare_care_v3">{parse(lv3)}Facebook like comment share (care Việt V3)</option>
												<option value="fb_likecommentshare_love_v3">{parse(lv3)}Facebook like comment share (love Việt V3)</option>
												<option value="fb_likecommentshare_haha_v3">{parse(lv3)}Facebook like comment share (haha Việt V3)</option>
												<option value="fb_likecommentshare_sad_v3">{parse(lv3)}Facebook like comment share (sad Việt V3)</option>
												<option value="fb_likecommentshare_wow_v3">{parse(lv3)}Facebook like comment share (wow Việt V3)</option>
												<option value="fb_likecommentshare_angry_v3">{parse(lv3)}Facebook like comment share (angry Việt V3)</option> */}
												<option value="fb_likecommentshare_like_v4">{parse(lv3)}Facebook like comment share (like Việt V4)</option>
												{/* <option value="fb_likecommentshare_care_v4">{parse(lv3)}Facebook like comment share (care V4)</option>
												<option value="fb_likecommentshare_love_v4">{parse(lv3)}Facebook like comment share (love V4)</option>
												<option value="fb_likecommentshare_haha_v4">{parse(lv3)}Facebook like comment share (haha V4)</option>
												<option value="fb_likecommentshare_sad_v4">{parse(lv3)}Facebook like comment share (sad V4)</option>
												<option value="fb_likecommentshare_wow_v4">{parse(lv3)}Facebook like comment share (wow V4)</option>
												<option value="fb_likecommentshare_angry_v4">{parse(lv3)}Facebook like comment share (angry V4)</option> */}
												<option value="fb_likecommentshare_like_v5">{parse(lv3)}Facebook like comment share (like Việt V5)</option>
												{/* <option value="fb_likecommentshare_care_v5">{parse(lv3)}Facebook like comment share (care V5)</option>
												<option value="fb_likecommentshare_love_v5">{parse(lv3)}Facebook like comment share (love V5)</option>
												<option value="fb_likecommentshare_haha_v5">{parse(lv3)}Facebook like comment share (haha V5)</option>
												<option value="fb_likecommentshare_sad_v5">{parse(lv3)}Facebook like comment share (sad V5)</option>
												<option value="fb_likecommentshare_wow_v5">{parse(lv3)}Facebook like comment share (wow V5)</option>
												<option value="fb_likecommentshare_angry_v5">{parse(lv3)}Facebook like comment share (angry V5)</option> */}
												<option value="fb_likecommentshare_like_v6">{parse(lv3)}Facebook like comment share (like Việt V6)</option>
												{/* <option value="fb_likecommentshare_care_v6">{parse(lv3)}Facebook like comment share (care V6)</option>
												<option value="fb_likecommentshare_love_v6">{parse(lv3)}Facebook like comment share (love V6)</option>
												<option value="fb_likecommentshare_haha_v6">{parse(lv3)}Facebook like comment share (haha V6)</option>
												<option value="fb_likecommentshare_sad_v6">{parse(lv3)}Facebook like comment share (sad V6)</option>
												<option value="fb_likecommentshare_wow_v6">{parse(lv3)}Facebook like comment share (wow V6)</option>
												<option value="fb_likecommentshare_angry_v6">{parse(lv3)}Facebook like comment share (angry V6)</option> */}
												<option value="fb_likecommentshare_like_v7">{parse(lv3)}Facebook like comment share (like Việt V7)</option>
												{/* <option value="fb_likecommentshare_care_v7">{parse(lv3)}Facebook like comment share (care V7)</option>
												<option value="fb_likecommentshare_love_v7">{parse(lv3)}Facebook like comment share (love V7)</option>
												<option value="fb_likecommentshare_haha_v7">{parse(lv3)}Facebook like comment share (haha V7)</option>
												<option value="fb_likecommentshare_sad_v7">{parse(lv3)}Facebook like comment share (sad V7)</option>
												<option value="fb_likecommentshare_wow_v7">{parse(lv3)}Facebook like comment share (wow V7)</option>
												<option value="fb_likecommentshare_angry_v7">{parse(lv3)}Facebook like comment share (angry V7)</option> */}
												<option value="fb_likecommentshare_like_v8">{parse(lv3)}Facebook like comment share (like Việt V8)</option>
												{/* <option value="fb_likecommentshare_care_v8">{parse(lv3)}Facebook like comment share (care V8)</option>
												<option value="fb_likecommentshare_love_v8">{parse(lv3)}Facebook like comment share (love V8)</option>
												<option value="fb_likecommentshare_haha_v8">{parse(lv3)}Facebook like comment share (haha V8)</option>
												<option value="fb_likecommentshare_sad_v8">{parse(lv3)}Facebook like comment share (sad V8)</option>
												<option value="fb_likecommentshare_wow_v8">{parse(lv3)}Facebook like comment share (wow V8)</option>
												<option value="fb_likecommentshare_angry_v8">{parse(lv3)}Facebook like comment share (angry V8)</option> */}
												<option value="fb_likecommentshare_like_v9">{parse(lv3)}Facebook like comment share (like Việt V9)</option>
												{/* <option value="fb_likecommentshare_care_v9">{parse(lv3)}Facebook like comment share (care V9)</option>
												<option value="fb_likecommentshare_love_v9">{parse(lv3)}Facebook like comment share (love V9)</option>
												<option value="fb_likecommentshare_haha_v9">{parse(lv3)}Facebook like comment share (haha V9)</option>
												<option value="fb_likecommentshare_sad_v9">{parse(lv3)}Facebook like comment share (sad V9)</option>
												<option value="fb_likecommentshare_wow_v9">{parse(lv3)}Facebook like comment share (wow V9)</option>
												<option value="fb_likecommentshare_angry_v9">{parse(lv3)}Facebook like comment share (angry V9)</option> */}
												<option value="fb_likecommentshare_like_v10">{parse(lv3)}Facebook like comment share (like Việt V10)</option>
												{/* <option value="fb_likecommentshare_care_v10">{parse(lv3)}Facebook like comment share (care V10)</option>
												<option value="fb_likecommentshare_love_v10">{parse(lv3)}Facebook like comment share (love V10)</option>
												<option value="fb_likecommentshare_haha_v10">{parse(lv3)}Facebook like comment share (haha V10)</option>
												<option value="fb_likecommentshare_sad_v10">{parse(lv3)}Facebook like comment share (sad V10)</option>
												<option value="fb_likecommentshare_wow_v10">{parse(lv3)}Facebook like comment share (wow V10)</option>
												<option value="fb_likecommentshare_angry_v10">{parse(lv3)}Facebook like comment share (angry V10)</option> */}
												<option value="fb_likecommentshare_like_v11">{parse(lv3)}Facebook like comment share (like Việt V11)</option>
												{/* <option value="fb_likecommentshare_care_v11">{parse(lv3)}Facebook like comment share (care V11)</option>
												<option value="fb_likecommentshare_love_v11">{parse(lv3)}Facebook like comment share (love V11)</option>
												<option value="fb_likecommentshare_haha_v11">{parse(lv3)}Facebook like comment share (haha V11)</option>
												<option value="fb_likecommentshare_sad_v11">{parse(lv3)}Facebook like comment share (sad V11)</option>
												<option value="fb_likecommentshare_wow_v11">{parse(lv3)}Facebook like comment share (wow V11)</option>
												<option value="fb_likecommentshare_angry_v11">{parse(lv3)}Facebook like comment share (angry V11)</option> */}
												<option value="fb_likecommentshare_like_cmt">{parse(lv3)}Facebook like comment share (like Comment)</option>
												{/* <option value="fb_likecommentshare_care_cmt">{parse(lv3)}Facebook like comment share (care Comment)</option>
												<option value="fb_likecommentshare_love_cmt">{parse(lv3)}Facebook like comment share (love Comment)</option>
												<option value="fb_likecommentshare_haha_cmt">{parse(lv3)}Facebook like comment share (haha Comment)</option>
												<option value="fb_likecommentshare_sad_cmt">{parse(lv3)}Facebook like comment share (sad Comment)</option>
												<option value="fb_likecommentshare_wow_cmt">{parse(lv3)}Facebook like comment share (wow Comment)</option>
												<option value="fb_likecommentshare_angry_cmt">{parse(lv3)}Facebook like comment share (angry Comment)</option> */}
												<option value="fb_likecommentshare_like_cmt_sv2">{parse(lv3)}Facebook like comment share (like Comment SV2)</option>
												{/* <option value="fb_likecommentshare_care_cmt_sv2">{parse(lv3)}Facebook like comment share (care Comment SV2)</option>
												<option value="fb_likecommentshare_love_cmt_sv2">{parse(lv3)}Facebook like comment share (love Comment SV2)</option>
												<option value="fb_likecommentshare_haha_cmt_sv2">{parse(lv3)}Facebook like comment share (haha Comment SV2)</option>
												<option value="fb_likecommentshare_sad_cmt_sv2">{parse(lv3)}Facebook like comment share (sad Comment SV2)</option>
												<option value="fb_likecommentshare_wow_cmt_sv2">{parse(lv3)}Facebook like comment share (wow Comment SV2)</option>
												<option value="fb_likecommentshare_angry_cmt_sv2">{parse(lv3)}Facebook like comment share (angry Comment SV2)</option> */}
												<option value="fb_likecommentshare_like_cmt_sv3">{parse(lv3)}Facebook like comment share (like Comment SV3)</option>
												{/* <option value="fb_likecommentshare_care_cmt_sv3">{parse(lv3)}Facebook like comment share (care Comment SV3)</option>
												<option value="fb_likecommentshare_love_cmt_sv3">{parse(lv3)}Facebook like comment share (love Comment SV3)</option>
												<option value="fb_likecommentshare_haha_cmt_sv3">{parse(lv3)}Facebook like comment share (haha Comment SV3)</option>
												<option value="fb_likecommentshare_sad_cmt_sv3">{parse(lv3)}Facebook like comment share (sad Comment SV3)</option>
												<option value="fb_likecommentshare_wow_cmt_sv3">{parse(lv3)}Facebook like comment share (wow Comment SV3)</option>
												<option value="fb_likecommentshare_angry_cmt_sv3">{parse(lv3)}Facebook like comment share (angry Comment SV3)</option> */}
												
											<option value="" className="font-bold" style={{"color": "#000"}} disabled>{parse(lv2)}Facebook like comment share (BÌNH LUẬN)</option>
												<option value="fb_likecommentshare_comment">{parse(lv3)}Facebook like comment share (comment)</option>
												<option value="fb_likecommentshare_comment_pro">{parse(lv3)}Facebook like comment share (comment PRO)</option>
												<option value="fb_likecommentshare_comment_sv3">{parse(lv3)}Facebook like comment share (comment SV3)</option>
												<option value="fb_likecommentshare_comment_sv4">{parse(lv3)}Facebook like comment share (comment SV4)</option>
												<option value="fb_likecommentshare_comment_sv5">{parse(lv3)}Facebook like comment share (comment SV5)</option>
												<option value="fb_likecommentshare_comment_sv6">{parse(lv3)}Facebook like comment share (comment SV6)</option>
												<option value="fb_likecommentshare_comment_sv7">{parse(lv3)}Facebook like comment share (comment SV7)</option>
												<option value="fb_likecommentshare_comment_sv8">{parse(lv3)}Facebook like comment share (comment SV8)</option>
												
											<option value="" className="font-bold" style={{"color": "#000"}} disabled>{parse(lv2)}Facebook like comment share (CHIA SẺ)</option>
												<option value="fb_likecommentshare_share">{parse(lv3)}Facebook like comment share (share)</option>
												<option value="fb_likecommentshare_share_sv2">{parse(lv3)}Facebook like comment share (share SV2)</option>
												<option value="fb_likecommentshare_share_sv3">{parse(lv3)}Facebook like comment share (share SV3)</option>
												<option value="fb_likecommentshare_share_sv4">{parse(lv3)}Facebook like comment share (share SV4)</option>
												<option value="fb_likecommentshare_share_sv5">{parse(lv3)}Facebook like comment share (share SV5)</option>
												<option value="fb_likecommentshare_share_sv6">{parse(lv3)}Facebook like comment share (share SV6)</option>
												
											<option value="" className="font-bold" style={{"color": "#000"}} disabled>{parse(lv2)}Facebook buff sub speed (LOẠI)</option>
												<option value="buffsub">{parse(lv3)}Facebook buff sub speed (sv1)</option>
												<option value="buffsub_sv2">{parse(lv3)}Facebook buff sub speed (sv2)</option>
												<option value="buffsub_sv3">{parse(lv3)}Facebook buff sub speed (sv3)</option>
												<option value="buffsub_sv4">{parse(lv3)}Facebook buff sub speed (sv4)</option>
												<option value="buffsub_sv5">{parse(lv3)}Facebook buff sub speed (sv5)</option>
												<option value="buffsub_sv6">{parse(lv3)}Facebook buff sub speed (sv6)</option>
												<option value="buffsub_sv7">{parse(lv3)}Facebook buff sub speed (sv7)</option>
												<option value="buffsub_sv8">{parse(lv3)}Facebook buff sub speed (sv8)</option>
												<option value="buffsub_sv9">{parse(lv3)}Facebook buff sub speed (sv9)</option>
											
											<option value="" className="font-bold" style={{"color": "#000"}} disabled>{parse(lv2)}Facebook buff sub đề xuất (LOẠI)</option>
												<option value="buffsub_slow_basic">{parse(lv3)}Facebook buff sub đề xuất (basic)</option>
												<option value="buffsub_slow_v1">{parse(lv3)}Facebook buff sub đề xuất (v1)</option>
												<option value="buffsub_slow_v2">{parse(lv3)}Facebook buff sub đề xuất (v2)</option>
												<option value="buffsub_slow_offer">{parse(lv3)}Facebook buff sub đề xuất (v3)</option>
											
											<option value="" className="font-bold" style={{"color": "#000"}} disabled>{parse(lv2)}Facebook buff sub sale (LOẠI)</option>
												<option value="buffsub_sale_sv1">{parse(lv3)}Facebook buff sub sale (sv1)</option>
												<option value="buffsub_sale_sv2">{parse(lv3)}Facebook buff sub sale (sv2)</option>
												<option value="buffsub_sale_sv3">{parse(lv3)}Facebook buff sub sale (sv3)</option>
												<option value="buffsub_sale_sv4">{parse(lv3)}Facebook buff sub sale (sv4)</option>
												
											<option value="" className="font-bold" style={{"color": "#000"}} disabled>{parse(lv2)}Facebook buff like fanpage (LOẠI)</option>
												<option value="bufflikefanpage">{parse(lv3)}Facebook buff like fanpage</option>
												<option value="bufflikefanpage_pro">{parse(lv3)}Facebook buff like fanpage (pro)</option>
												<option value="bufflikefanpage_sv3">{parse(lv3)}Facebook buff like fanpage (sv3)</option>
												<option value="bufflikefanpage_sv4">{parse(lv3)}Facebook buff like fanpage (sv4)</option>
												<option value="bufflikefanpage_sv5">{parse(lv3)}Facebook buff like fanpage (sv5)</option>
												<option value="bufflikefanpage_sv6">{parse(lv3)}Facebook buff like fanpage (sv6)</option>
												<option value="bufflikefanpage_sv7">{parse(lv3)}Facebook buff like fanpage (sv7)</option>
												<option value="bufflikefanpage_sv8">{parse(lv3)}Facebook buff like fanpage (sv8)</option>
												<option value="bufflikefanpage_sv9">{parse(lv3)}Facebook buff like fanpage (sv9)</option>
												
											<option value="" className="font-bold" style={{"color": "#000"}} disabled>{parse(lv2)}Facebook buff like fanpage sale (LOẠI)</option>
												<option value="bufflikefanpagesale_sv1">{parse(lv3)}Facebook buff like fanpage sale (sv1)</option>
												<option value="bufflikefanpagesale_sv2">{parse(lv3)}Facebook buff like fanpage sale (sv2)</option>
												<option value="bufflikefanpagesale_sv3">{parse(lv3)}Facebook buff like fanpage sale (sv3)</option>
												
											<option value="" className="font-bold" style={{"color": "#000"}} disabled>{parse(lv2)}Facebook buff sub fanpage (LOẠI)</option>
												<option value="buffsubfanpage_sv1">{parse(lv3)}Facebook buff sub fanpage (sv1)</option>
												<option value="buffsubfanpage_sv2">{parse(lv3)}Facebook buff sub fanpage (sv2)</option>
												
											<option value="" className="font-bold" style={{"color": "#000"}} disabled>{parse(lv2)}Facebook buff like sub (SALE) (LOẠI)</option>
												<option value="buffsublikev2_sub_3k">{parse(lv3)}Facebook buff sub v2 (sv1)</option>
												<option value="buffsublikev2_sub_5k">{parse(lv3)}Facebook buff sub v2 (sv2)</option>
												<option value="buffsublikev2_sub_10k">{parse(lv3)}Facebook buff sub v2 (sv3)</option>
												<option value="buffsublikev2_sub_vip">{parse(lv3)}Facebook buff sub v2 (sv vip)</option>
												<option value="buffsublikev2_like_page_3k">{parse(lv3)}Facebook buff like fanpage v2 (sv1)</option>
												<option value="buffsublikev2_like_page_5k">{parse(lv3)}Facebook buff like fanpage v2 (sv2)</option>
												<option value="buffsublikev2_like_page_10k">{parse(lv3)}Facebook buff like fanpage v2 (sv3)</option>
												<option value="buffsublikev2_like_post_sv1">{parse(lv3)}Facebook buff like post v2 (sv1)</option>
												<option value="buffsublikev2_like_post_sv2">{parse(lv3)}Facebook buff like post v2 (sv2)</option>
												<option value="buffsublikev2_like_post_sv3">{parse(lv3)}Facebook buff like post v2 (sv3)</option>
												<option value="buffsublikev2_comment_post_sv1">{parse(lv3)}Facebook buff comment post v2 (sv1)</option>
												
											<option value="" className="font-bold" style={{"color": "#000"}} disabled>{parse(lv2)}Facebook buff đánh giá & check in (LOẠI)</option>
												<option value="buffseedingreview_sv1">{parse(lv3)}Facebook buff seeding review (sv1)</option>
												<option value="buffseedingreview">{parse(lv3)}Facebook buff seeding review (sv2)</option>
												<option value="buffseedingreview_sv3">{parse(lv3)}Facebook buff seeding checkin (sv3)</option>
												<option value="buffseedingreview_sv4">{parse(lv3)}Facebook buff seeding checkin (sv4)</option>
												<option value="buffseedingreview_sv5">{parse(lv3)}Facebook buff seeding review (sv5)</option>
												
											<option value="" className="font-bold" style={{"color": "#000"}} disabled>{parse(lv2)}Facebook buff view video (LOẠI)</option>
												<option value="fbbuffviewsvideo_0">{parse(lv3)}Facebook buff view video (3s)</option>
												<option value="fbbuffviewsvideo_1">{parse(lv3)}Facebook buff view video (1p)</option>
												<option value="fbbuffviewsvideo_99">{parse(lv3)}Facebook buff view video (3s nhanh)</option>
												<option value="fbbuffviewsvideo_4">{parse(lv3)}Facebook buff view video (SV4)</option>
												<option value="fbbuffviewsvideo_5">{parse(lv3)}Facebook buff view video (SV5)</option>
												{/* <option value="fbbuffviewsvideo_2">{parse(lv3)}Facebook buff view video (3p)</option>
												<option value="fbbuffviewsvideo_3">{parse(lv3)}Facebook buff view video (10p)</option>
												<option value="fbbuffviewsvideo_4">{parse(lv3)}Facebook buff view video (30p)</option>
												<option value="fbbuffviewsvideo_5">{parse(lv3)}Facebook buff view video (live)</option> */}
												
											<option value="" className="font-bold" style={{"color": "#000"}} disabled>{parse(lv2)}Facebook buff mắt livestream (LOẠI)</option>
												<option value="buffmatlivestream">{parse(lv3)}Facebook buff mắt livestream (sv1)</option>
												<option value="buffmatlivestream_sv2">{parse(lv3)}Facebook buff mắt livestream (sv2)</option>
												<option value="buffmatlivestream_sv3">{parse(lv3)}Facebook buff mắt livestream (sv3)</option>
												<option value="buffmatlivestream_sv4">{parse(lv3)}Facebook buff mắt livestream (sv4)</option>
												
											<option value="" className="font-bold" style={{"color": "#000"}} disabled>{parse(lv2)}Facebook buff mem group (LOẠI)</option>
												<option value="buffgroup">{parse(lv3)}Facebook buff mem group (sv1)</option>
												<option value="buffgroup_sv2">{parse(lv3)}Facebook buff mem group (sv2)</option>
												<option value="buffgroup_sv3">{parse(lv3)}Facebook buff mem group (sv3)</option>
												<option value="buffgroup_sv4">{parse(lv3)}Facebook buff mem group (sv4)</option>
												<option value="buffgroup_sv5">{parse(lv3)}Facebook buff mem group (sv5)</option>
												<option value="buffgroup_sv6">{parse(lv3)}Facebook buff mem group (sv6)</option>
												
											<option value="" className="font-bold" style={{"color": "#000"}} disabled>{parse(lv2)}Facebook buff share live group (LOẠI)</option>
												<option value="buffgroupsharelive_sv1">{parse(lv3)}Facebook buff share live group (sv1)</option>
												<option value="buffgroupsharelive_sv2">{parse(lv3)}Facebook buff share live group (sv2)</option>
												<option value="buffgroupsharelive_svvip">{parse(lv3)}Facebook buff share live group (sv vip)</option>
											
											<option value="" className="font-bold" style={{"color": "#000"}} disabled>{parse(lv2)}Facebook buff view story (LOẠI)</option>
												<option value="buffviewstory">{parse(lv3)}Facebook buff view story (sv1)</option>
													
											<option value="" className="font-bold" style={{"color": "#000"}} disabled>{parse(lv2)}Facebook like sub comment tây (loại)</option>
												<option value="bufflikesubcomment_sub_1">{parse(lv3)}Facebook sub tây (sv1)</option>
												<option value="bufflikesubcomment_likefanpage_1">{parse(lv3)}Facebook like page tây (sv1)</option>
												<option value="bufflikesubcomment_likepost_1">{parse(lv3)}Facebook like post tây (sv1)</option>
												{/* <option value="bufflikesubcomment_comment_1">{parse(lv3)}Facebook comment tây (sv1)</option> */}
										</optgroup>
										<optgroup label="Facebook VIP">
											<option value="" className="font-bold" style={{"color": "#000"}} disabled>{parse(lv2)}Facebook vip like clone (LOẠI)</option>
												<option value="viplike_clone_via_ava">{parse(lv3)}Facebook vip like clone giá rẻ via ava (sv1)</option>
												<option value="viplike_clone_speed">{parse(lv3)}Facebook vip like clone giá rẻ nhanh (sv2)</option>
												<option value="viplike_clone_ava">{parse(lv3)}Facebook vip like clone giá rẻ ava (sv3)</option>
												<option value="viplike_clone">{parse(lv3)}Facebook vip like clone giá rẻ (sv4)</option>
												<option value="viplike_clone_sv5">{parse(lv3)}Facebook vip like clone (sv5)</option>
												
											<option value="" className="font-bold" style={{"color": "#000"}} disabled>{parse(lv2)}Facebook vip like pro (LOẠI)</option>
												<option value="viplikethang_speed">{parse(lv3)}Facebook vip like pro speed (sv1)</option>
												<option value="viplikethang">{parse(lv3)}Facebook vip like pro (sv2)</option>
												<option value="viplikethang_likevia">{parse(lv3)}Facebook vip like pro via (sv3)</option>
												
											<option value="" className="font-bold" style={{"color": "#000"}} disabled>{parse(lv2)}Facebook vip like group (LOẠI)</option>
												<option value="viplike_group">{parse(lv3)}Facebook vip like group (sv1)</option>
											
											<option value="" className="font-bold" style={{"color": "#000"}} disabled>{parse(lv2)}Facebook vip comment</option>
												<option value="vipcommentthang">{parse(lv3)}Facebook vip CMT PRO</option>
												<option value="facebookvipcomment">{parse(lv3)}Facebook vip Comment Clone (sv1)</option>
												<option value="facebookvipcomment_sv2">{parse(lv3)}Facebook vip Comment Clone (sv2)</option>
												
											<option value="" className="font-bold" style={{"color": "#000"}} disabled>{parse(lv2)}Facebook vip view video</option>
												<option value="facebookvipviewvideo">{parse(lv3)}Facebook vip view video</option>
												
											<option value="" className="font-bold" style={{"color": "#000"}} disabled>{parse(lv2)}Facebook vip livestream v2 (LOẠI)</option>
												<option value="facebookvipmatlivestream">{parse(lv3)}Facebook vip livestream v2 (sv1)</option>
												<option value="facebookvipmatlivestream_sv2">{parse(lv3)}Facebook vip livestream v2 (sv2)</option>
												<option value="facebookvipmatlivestream_sv3">{parse(lv3)}Facebook vip livestream v2 (sv3)</option>
												
											<option value="" className="font-bold" style={{"color": "#000"}} disabled>{parse(lv2)}Facebook vip livestream new</option>
												<option value="facebookvipmatlivestreamv2">{parse(lv3)}Facebook vip livestream new</option>
										</optgroup>
										<optgroup label="Facebook BOT">
											{/*<option value="facebookbot">Facebook bot</option>*/}
											<option value="facebookbotv2">Facebook bot v2</option>
											<option value="facebookbotlovestory">Facebook bot love story</option>
											<option value="facebookbotcmt">Facebook bot comment</option>
											<option value="facebookbotreplyinboxcho">Facebook bot reply inbox chờ</option>
											<option value="facebooklocbanbekhongtuongtac">Facebook bot lọc bạn bè không tương tác</option>
											<option value="buffchocbanbe">Facebook bot chọc bạn bè</option>
											<option value="bufffbvipbotlive">Facebook bot vip bot livestream</option>
										</optgroup>
										<optgroup label="Facebook AD Breaks">
											<option value="facebookadbreaksgioxem">Facebook ad breaks giờ xem</option>
											<option value="facebookadbreakstuongtac">Facebook ad breaks tương tác</option>
											<option value="facebookadbreaksviewdexuat">Facebook ad breaks view đề xuất</option>
										</optgroup>
										<optgroup label="INSTAGRAM">
											<option value="" className="font-bold" style={{"color": "#000"}} disabled>{parse(lv2)}Instagram buff sub (LOẠI)</option>
												<option value="instagram_buffsub">{parse(lv3)}Instagram buff sub (sv1)</option>
												<option value="instagram_buffsub_sv2">{parse(lv3)}Instagram buff sub (sv2)</option>
												<option value="instagram_buffsub_sv3">{parse(lv3)}Instagram buff sub (sv3)</option>
												<option value="instagram_buffsub_sv4">{parse(lv3)}Instagram buff sub (sv4)</option>
												<option value="instagram_buffsub_sv5">{parse(lv3)}Instagram buff sub (sv5)</option>
												<option value="instagram_buffsub_sv6">{parse(lv3)}Instagram buff sub (sv6)</option>
												<option value="instagram_buffsub_sv7">{parse(lv3)}Instagram buff sub (sv7)</option>
												<option value="instagram_buffsub_sv8">{parse(lv3)}Instagram buff sub (sv8)</option>
											<option value="" className="font-bold" style={{"color": "#000"}} disabled>{parse(lv2)}Instagram buff seeding comment (LOẠI)</option>
												<option value="buffseedingcommentinstagramorder">{parse(lv3)}Instagram buff seeding comment (sv1)</option>
												<option value="buffseedingcommentinstagramorder_sv2">{parse(lv3)}Instagram buff seeding comment (sv2)</option>
											<option value="" className="font-bold" style={{"color": "#000"}} disabled>{parse(lv2)}Instagram buff seeding like (LOẠI)</option>
												<option value="buffseedinglikeinstagramorder">{parse(lv3)}Instagram buff seeding like (sv1)</option>
												<option value="buffseedinglikeinstagramorder_sv2">{parse(lv3)}Instagram buff seeding like (sv2)</option>
												<option value="buffseedinglikeinstagramorder_sv3">{parse(lv3)}Instagram buff seeding like (sv3)</option>
												<option value="buffseedinglikeinstagramorder_sv4">{parse(lv3)}Instagram buff seeding like (sv4)</option>
												<option value="buffseedinglikeinstagramorder_sv5">{parse(lv3)}Instagram buff seeding like (sv5)</option>
												<option value="buffseedinglikeinstagramorder_sv6">{parse(lv3)}Instagram buff seeding like (sv6)</option>
											<option value="" className="font-bold" style={{"color": "#000"}} disabled>{parse(lv2)}Instagram buff seeding view (LOẠI)</option>
												<option value="buffseedingviewinstagramorder">{parse(lv3)}Instagram buff seeding view (sv1)</option>
												<option value="buffseedingviewinstagramorder_sv2">{parse(lv3)}Instagram buff seeding view (sv2)</option>
												<option value="buffseedingviewinstagramorder_sv3">{parse(lv3)}Instagram buff seeding view (sv3)</option>
												<option value="buffseedingviewinstagramorder_sv4">{parse(lv3)}Instagram buff seeding view (sv4)</option>
											<option value="" className="font-bold" style={{"color": "#000"}} disabled>{parse(lv2)}Instagram vip like (LOẠI)</option>
												<option value="instagrameviplike_viet">{parse(lv3)}Instagram vip like (việt)</option>
												<option value="instagrameviplike_tay">{parse(lv3)}Instagram vip like (tây)</option>
										</optgroup>
										<optgroup label="YOUTUBE">
											<option value="" className="font-bold" style={{"color": "#000"}} disabled>{parse(lv2)}Youtube buff view video (LOẠI)</option>
												<option value="youtubeview">{parse(lv3)}Youtube buff view video (sv1)</option>
												<option value="youtubeview_sv2">{parse(lv3)}Youtube buff view video (sv2)</option>
												<option value="youtubeview_sv3">{parse(lv3)}Youtube buff view video (sv3)</option>
												<option value="youtubeview_sv4">{parse(lv3)}Youtube buff view video (sv4)</option>
												<option value="youtubeview_sv5">{parse(lv3)}Youtube buff view video (sv5)</option>
												<option value="youtubeview_sv6">{parse(lv3)}Youtube buff view video (sv6)</option>
												<option value="youtubeview_sv7">{parse(lv3)}Youtube buff view video (sv7)</option>
												<option value="youtubeview_sv8">{parse(lv3)}Youtube buff view video (sv8)</option>
												<option value="youtubeview_sv9">{parse(lv3)}Youtube buff view video (sv9)</option>
											<option value="" className="font-bold" style={{"color": "#000"}} disabled>{parse(lv2)}Youtube buff like video (LOẠI)</option>
												<option value="youtubelike">{parse(lv3)}Youtube buff like video (sv1)</option>
												<option value="youtubelike_sv2">{parse(lv3)}Youtube buff like video (sv2)</option>
											<option value="" className="font-bold" style={{"color": "#000"}} disabled>{parse(lv2)}Youtube buff comment video (LOẠI)</option>
												<option value="youtubecomment">{parse(lv3)}Youtube buff comment video (sv1)</option>
												<option value="youtubecomment_sv2">{parse(lv3)}Youtube buff comment video (sv2)</option>
												<option value="youtubecomment_sv3">{parse(lv3)}Youtube buff comment video (sv3)</option>
											<option value="" className="font-bold" style={{"color": "#000"}} disabled>{parse(lv2)}Youtube buff sub (LOẠI)</option>
												<option value="youtube_buffsub">{parse(lv3)}Youtube buff sub (sv1)</option>
												<option value="youtube_buffsub_sv2">{parse(lv3)}Youtube buff sub (sv2)</option>
												<option value="youtube_buffsub_sv3">{parse(lv3)}Youtube buff sub (sv3)</option>
											<option value="" className="font-bold" style={{"color": "#000"}} disabled>{parse(lv2)}Youtube buff giờ xem video (LOẠI)</option>
												<option value="youtubegioxem_sv1">{parse(lv3)}Youtube buff giờ xem video (sv1)</option>
												<option value="youtubegioxem_sv2">{parse(lv3)}Youtube buff giờ xem video (sv2)</option>
												<option value="youtubegioxem_sv3">{parse(lv3)}Youtube buff giờ xem video (sv3)</option>
												<option value="youtubegioxem_sv4">{parse(lv3)}Youtube buff giờ xem video (sv4)</option>
												<option value="youtubegioxem_sv5">{parse(lv3)}Youtube buff giờ xem video (sv5)</option>
											<option value="" className="font-bold" style={{"color": "#000"}} disabled>{parse(lv2)}Report Youtube</option>
												<option value="reportyoutube">{parse(lv3)}Report Youtube</option>
											<option value="" className="font-bold" style={{"color": "#000"}} disabled>{parse(lv2)}Youtube buff dislike video (LOẠI)</option>
												<option value="youtubedislike_sv1">{parse(lv3)}Youtube buff dislike video (sv1)</option>
												<option value="youtubedislike_sv2">{parse(lv3)}Youtube buff dislike video (sv2)</option>
											<option value="" className="font-bold" style={{"color": "#000"}} disabled>{parse(lv2)}Youtube buff like comment video (LOẠI)</option>
												<option value="youtubelikecomment_sv1">{parse(lv3)}Youtube buff like comment video (sv1)</option>
												<option value="youtubelikecomment_sv2">{parse(lv3)}Youtube buff like comment video (sv2)</option>
											<option value="youtubetichnghesi">Youtube tích nghệ sĩ</option>
										</optgroup>
										<optgroup label="TIKTOK">
											<option value="" className="font-bold" style={{"color": "#000"}} disabled>{parse(lv2)}Tiktok Buff sub (LOẠI)</option>
												<option value="tiktok_buff">{parse(lv3)}Tiktok Buff sub (sv1)</option>
												<option value="tiktok_buff_sv2">{parse(lv3)}Tiktok Buff sub (sv2)</option>
												<option value="tiktok_buff_sv3">{parse(lv3)}Tiktok Buff sub (sv3)</option>
												<option value="tiktok_buff_sv4">{parse(lv3)}Tiktok Buff sub (sv4)</option>
												<option value="tiktok_buff_sv5">{parse(lv3)}Tiktok Buff sub (sv5)</option>
											<option value="" className="font-bold" style={{"color": "#000"}} disabled>{parse(lv2)}Tiktok Buff like (LOẠI)</option>
												<option value="tiktok_bufflike">{parse(lv3)}Tiktok Buff like (sv1)</option>
												<option value="tiktok_bufflike_sv2">{parse(lv3)}Tiktok Buff like (sv2)</option>
												<option value="tiktok_bufflike_sv3">{parse(lv3)}Tiktok Buff like (sv3)</option>
												<option value="tiktok_bufflike_sv4">{parse(lv3)}Tiktok Buff like (sv4)</option>
												<option value="tiktok_bufflike_sv5">{parse(lv3)}Tiktok Buff like (sv5)</option>
											<option value="" className="font-bold" style={{"color": "#000"}} disabled>{parse(lv2)}Tiktok Buff comment (LOẠI)</option>
												<option value="tiktok_buffcomment">{parse(lv3)}Tiktok Buff comment (sv1)</option>
												<option value="tiktok_buffcomment_sv2">{parse(lv3)}Tiktok Buff comment (sv2)</option>
											<option value="" className="font-bold" style={{"color": "#000"}} disabled>{parse(lv2)}Tiktok Buff view (LOẠI)</option>
												<option value="tiktok_buffview">{parse(lv3)}Tiktok Buff view (sv1)</option>
												<option value="tiktok_buffview_sv2">{parse(lv3)}Tiktok Buff view (sv2)</option>
												<option value="tiktok_buffview_sv3">{parse(lv3)}Tiktok Buff view (sv3)</option>
												<option value="tiktok_buffview">{parse(lv3)}Tiktok Buff view</option>
											<option value="" className="font-bold" style={{"color": "#000"}} disabled>{parse(lv2)}Tiktok Buff share (LOẠI)</option>
												<option value="tiktok_buffshare">{parse(lv3)}Tiktok Buff share (sv1)</option>
												<option value="tiktok_buffshare_sv2">{parse(lv3)}Tiktok Buff share (sv2)</option>
												<option value="tiktok_buffshare_sv3">{parse(lv3)}Tiktok Buff share (sv3)</option>
											<option value="" className="font-bold" style={{"color": "#000"}} disabled>{parse(lv2)}Tiktok Buff mắt (LOẠI)</option>
												<option value="tiktok_buffmat">{parse(lv3)}Tiktok Buff mắt (sv1)</option>
												<option value="tiktok_buffmat_sv2">{parse(lv3)}Tiktok Buff mắt (sv2)</option>
											<option value="" className="font-bold" style={{"color": "#000"}} disabled>{parse(lv2)}Tiktok Vip like (LOẠI)</option>
												<option value="tiktokviplike_sv1">{parse(lv3)}Tiktok Vip like (sv1)</option>
											<option value="" className="font-bold" style={{"color": "#000"}} disabled>{parse(lv2)}Tiktok Vip view (LOẠI)</option>
												<option value="tiktokvipview_sv1">{parse(lv3)}Tiktok Vip view (sv1)</option>
										</optgroup>
										<optgroup label="SHOPEE">
											<option value="shopee_sub">Shopee sub</option>
											<option value="shopee_seedinglivestream">Shopee seeding livestream</option>
											<option value="shopee_viplivestream">Shopee vip livestream</option>
											<option value="shopee_tim">Shopee tim</option>
										</optgroup>
										<optgroup label="GOOGLE">
											<option value="googlemapdanhgia5sao">Google map đánh giá 5 sao</option>
											<option value="googlemapdanhgia1sao">Google map đánh giá 1 sao</option>
										</optgroup>
										<optgroup label="TELEGRAM">
											<option value="buffgrouptele_random">Telegram buff group (random sv1)</option>
											<option value="buffgrouptele_random_sv2">Telegram buff group (random sv2)</option>
											<option value="buffgrouptele_theoyeucau">Telegram buff group (theo yêu cầu)</option>
										</optgroup>
										<optgroup label="TWITTER">
											<option value="" className="font-bold" style={{"color": "#000"}} disabled>{parse(lv2)}Twitter like (LOẠI)</option>
												<option value="twitterlike">{parse(lv3)}Twitter like (sv1)</option>
												<option value="twitterlike_sv2">{parse(lv3)}Twitter like (sv2)</option>
											<option value="" className="font-bold" style={{"color": "#000"}} disabled>{parse(lv2)}Twitter follow (LOẠI)</option>
												<option value="twitterfollow">{parse(lv3)}Twitter follow (sv1)</option>
												<option value="twitterfollow_sv2">{parse(lv3)}Twitter follow (sv2)</option>
										</optgroup>
										{/* <optgroup label="SPOTIFY">
											<option value="spotifyfollow">Spotify follow</option>
											<option value="spotifyplay">Spotify play</option>
										</optgroup>
										<optgroup label="SOUNDCLOUD">
											<option value="soundcloudfollow">Soundcloud follow</option>
											<option value="soundcloudplay">Soundcloud play</option>
											<option value="soundcloudlike">Soundcloud like</option>
										</optgroup>
										<optgroup label="VIMEO">
											<option value="vimeofollow">Vimeo follow</option>
											<option value="vimeoview">Vimeo view</option>
										</optgroup> */}
										<optgroup label="Khác">
											<option value="spamsms">Hỗ trợ nhắn tin</option>
											<option value="spamcall">Hỗ trợ cuộc gọi</option>
											<option value="ripacc">Rip acc</option>
											<option value="renamefanpage">Rename Fanpage</option>
											<option value="muafanpage">Mua Fanpage có sẵn sub</option>
										</optgroup>
										<optgroup label="Công cụ">
											<option value="emailtouid">Email to uid</option>
											<option value="thuesim_sv1">Thuê sim (sv1)</option>
										</optgroup>
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label className="mr-4">Loại tài khoản:</label>
                                    <select onChange={(e) => {this.onChangeFormFunction(e)}} name="usertype" className="form-control">
                                        {optionUserType}
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group form-inline">
									<div className="col-md-5" style={{"padding": "0px", "height": "60px"}}>
										<span className="mr-4" style={{"fontWeight": "600"}}>Giá gốc:</span>
										<input onChange={(e) => {this.onChangeForm(e)}} value={formdata.start_price_api} type="number" name="start_price_api" className="form-control" />
									</div>
									<div className="col-md-1 custom-range-price">
										<span >-</span>
									</div>
									<div className="col-md-6" style={{"padding": "0px", "height": "60px"}}>
										<input onChange={(e) => {this.onChangeForm(e)}} value={formdata.end_price_api} type="number" name="end_price_api" className="form-control mr-9" />
										<span className="text-danger">Nên để giá gấp 10-20 lần giá gốc</span>
									</div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label className="mr-4">Giá bán:</label>
                                    <input onChange={(e) => {this.onChangeForm(e)}} value={formdata.start_price_web} type="number" name="start_price_web" className="form-control" />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label className="mr-4">Giá gốc (có bảo hành):</label>
                                    <input onChange={(e) => {this.onChangeForm(e)}} value={formdata.price_bh_api} type="number" name="price_bh_api" className="form-control" />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label className="mr-4">Giá bán (có bảo hành):</label>
                                    <input onChange={(e) => {this.onChangeForm(e)}} value={formdata.price_bh_web} type="number" name="price_bh_web" className="form-control" />
									<h6 className="text-danger" style={{"marginTop": "15px", "marginRight": "5px", "display": "inline-block"}}>(Giá mới ở trình duyệt này sẽ hiển thị sau 5-10 phút, nếu muốn kiểm tra giá mới ngay hãy bật trình duyệt ẩn danh)</h6>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter style={{opacity: loadingblock? '.05':'1'}}>
                        <button type="button" className="btn btn-primary" onClick={(e) => {this.resetFormdata(e); this.props.saveAddPriceFunction(formdata, this.props.actionOpenModal)}} >Lưu</button>
                        <button type="button" className="btn btn-danger" onClick={(e) => {this.resetFormdata(e); this.props.actionOpenModal()}} >Huỷ</button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        );
    }

}

const mapStateToProps = state => ({
    adminpage: state.adminpage
});

export default connect(mapStateToProps, {saveAddPriceFunction}) (ModalAddPriceFunction);
