import axios from 'axios';
import Swal from 'sweetalert2';
import { loadUser } from "./authActions";

export const creatItemBuffSub = (data) => (dispatch, getState) => {
	let bodysend = {
		dataform: data,
	};
	var text_loaiseeding = 'vip view';
	Swal.fire({
	  	title: 'Bạn có chắc?',
	  	text: "Bạn có chắc chắn muốn Order " + text_loaiseeding + " với giá: "+data.giatien+" Coin/1 "+text_loaiseeding+" ? Khi đã bấm đồng ý đồng nghĩa với việc bạn đã đọc hết mục thông tin dịch vụ!",
	  	icon: 'warning',
	  	showCancelButton: true,
	  	confirmButtonColor: '#3085d6',
	  	cancelButtonColor: '#d33',
	  	confirmButtonText: 'Đồng ý',
	  	cancelButtonText: 'Không'
	}).then((result) => {
	  	if (result.value) {

			dispatch({
				type: 'LOADING_BLOCK_BUFF_SUB_TIK_TOK',
				payload: true
			});
			axios.post('/api/tiktok_buff/create', bodysend, tokenConfig(getState))
			.then(res => {
				dispatch(loadUser());
				dispatch({
					type: 'LOADING_BLOCK_BUFF_SUB_TIK_TOK',
					payload: false
				});
				if (res.data.status !== 200) {
					if (Array.isArray(res.data.error.object_id)) {
						Swal.fire({icon: 'error', title: 'Lỗi', text: res.data.error.object_id[0]});
					} else {
						Swal.fire({icon: 'error', title: 'Lỗi', text: res.data.message});
					}
				} else {
					Swal.fire({icon: 'success', title: 'Thành công', text: 'Thêm thành công!'});
				}
			});
	  	}
  	})
}

export const getListBuffSub = (viewadmin = 0, page = "", obj_search = '', type = '') => (dispatch, getState) => {
	dispatch({
		type: 'LOADING_BLOCK_BUFF_SUB_TIK_TOK',
		payload: true
	});
	let param_url = '';
	if (viewadmin === 1) {
		param_url = '&viewall=1';
	}

	if (page !== "") {
		param_url += '&page='+page;
	}
	
	// var path_check = window.location.pathname;
	// if (window.location.pathname.length > 1) {
		// path_check = window.location.pathname.replace(/\/+$/g, '');
	// }
	// if (obj_search.trim() !== '') {
		// param_url += '&obj_search='+obj_search;
	// } else {
		// param_url += '&obj_search=tiktokvipview';
	// }
	param_url += '&type='+type+'&obj_search='+obj_search;
	axios.get('/api/tiktok_buff/list/?type_api=tiktok'+param_url, tokenConfig(getState))
	.then(res => {
		dispatch({
			type: 'LOADING_BLOCK_BUFF_SUB_TIK_TOK',
			payload: false
		});
		if (res.data.status === 200) {
			dispatch({
				type: 'LIST_BUFF_SUB_TIK_TOK_USER',
				payload: res.data.data
			});
		}
	});
}


export const cancelOrderBuffsub = (id, type, id_remove) => (dispatch, getState) => {

	Swal.fire({
	  	title: 'Bạn có chắc?',
	  	text: "Sau khi hủy bạn sẽ bị trừ 7 ngày chưa sử dụng và 5000 phí!",
	  	icon: 'warning',
	  	showCancelButton: true,
	  	confirmButtonColor: '#3085d6',
	  	cancelButtonColor: '#d33',
	  	confirmButtonText: 'Đồng ý',
	  	cancelButtonText: 'Không'
	}).then((result) => {
	  	if (result.value) {
	  		let bodysend = {
	  			id: id,
	  			type: type,
	  		};
	  		dispatch({
				type: 'LOADING_BLOCK_BUFF_SUB_TIK_TOK',
				payload: true
			});
	  		axios.post('/api/tiktok_buff/removeorder/', bodysend, tokenConfig(getState))
			.then(res => {
				dispatch(getListBuffSub(0, 0, 'tiktokvipview'));
				dispatch({
					type: 'LOADING_BLOCK_BUFF_SUB_TIK_TOK',
					payload: false
				});
				if (res.data.status === 200) {
					Swal.fire(
			      		'Thành công!',
			      		'Hủy đơn thành công',
			      		'success'
			    	)
				} else {
					Swal.fire(
			      		'Lỗi',
		      			res.data.message,
			      		'error'
			    	)
				}
			});
	  	}
	})
}

export const reLoadIdFb = (id) => (dispatch, getState) => {
	let bodysend = {
		id: id,
		type: 'tiktokvipview'
	};
	dispatch({
		type: 'LOADING_BLOCK_BUFF_SUB_TIK_TOK',
		payload: true
	});
	axios.post('/api/tiktok_buff/checkiddie/', bodysend, tokenConfig(getState))
	.then(res => {
		dispatch({
			type: 'LOADING_BLOCK_BUFF_SUB_TIK_TOK',
			payload: false
		});
		dispatch(getListBuffSub(0, 0, 'tiktokvipview'));
		if (res.data.status === 200) {
			Swal.fire({icon: 'success', title: 'success', text: 'Check ID thành công!'});
		} else {
			Swal.fire({icon: 'error', title: 'Lỗi', text: res.data.message});
		}
	}).catch(err => {
		dispatch({
			type: 'LOADING_BLOCK_BUFF_SUB_TIK_TOK',
			payload: false
		});
		Swal.fire( 'Lỗi', 'Có lỗi xảy ra!', 'error' );
    });
}

export const saveEditOrderAdmin = (nametb, data, closeM) => (dispatch, getState) => {
	let bodysend = {
		formdata: data,
		type_api: nametb
	};
	dispatch({
		type: 'LOADING_BLOCK_BUFF_SUB_TIK_TOK',
		payload: true
	});
	axios.post('/api/tiktok_buff/admin_edit_order/', bodysend, tokenConfig(getState))
	.then(res => {
		dispatch({
			type: 'LOADING_BLOCK_BUFF_SUB_TIK_TOK',
			payload: false
		});
		if (res.data.status === 200) {
			dispatch(getListBuffSub(0, 0, 'tiktokvipview'));
			Swal.fire(
	      		'Thành công!',
	      		'Sửa thành công',
	      		'success'
	    	);
	    	closeM();
		} else {
			Swal.fire( 'Lỗi', res.data.message, 'error' )
		}
	}).catch(err => {
		console.log(err);
		Swal.fire( 'Lỗi', 'Có lỗi xảy ra!', 'error' );
    });
}


export const getInfoTiktok = (loaiseeding, data) => (dispatch, getState) => {
	var loaigetinfo = loaiseeding;
	// if (loaiseeding == 'liketosub') {
		// loaigetinfo = 'like';
	// }
	let bodysend = {
		"data": data,
		"loaiseeding": loaigetinfo,
	};
	dispatch({
		type: 'LOADING_BLOCK_BUFF_SUB_TIK_TOK',
		payload: true
	});
	axios.post('/api/tiktok_buff/getinfotiktok', bodysend, tokenConfig(getState))
		.then(res => {
			dispatch({
				type: 'LOADING_BLOCK_BUFF_SUB_TIK_TOK',
				payload: false
			});
			if (res.data.status === 200) {
				Swal.fire(
					'Thành công!',
					'Lấy thông tin thành công!',
					'success'
				);
				if (loaiseeding == 'follow' || loaiseeding == 'follow_sv2' || loaiseeding == 'tiktokvipview_sv1') {
					dispatch({
						type: 'UPDATE_INFO_TIKTOK',
						payload: res.data.data
					});
				}
				if (loaiseeding == 'liketosub') {
					dispatch({
						type: 'UPDATE_INFO_TIKTOK',
						payload: res.data.data
					});
				}
				if (loaiseeding == 'comment') {
					dispatch({
						type: 'UPDATE_INFO_TIKTOK_CMT',
						payload: res.data.data
					});
				}
				if (loaiseeding == 'like' || loaiseeding == 'like_sv2') {
					dispatch({
						type: 'UPDATE_INFO_TIKTOK_LIKE',
						payload: res.data.data
					});
				}
				if (loaiseeding == 'view' || loaiseeding == 'view_sv2' || loaiseeding == 'view_sv3') {
					dispatch({
						type: 'UPDATE_INFO_TIKTOK_VIEW',
						payload: res.data.data
					});
				}
				if (loaiseeding == 'share') {
					dispatch({
						type: 'UPDATE_INFO_TIKTOK_SHARE',
						payload: res.data.data
					});
				}
			} else {
				Swal.fire({icon: 'error', title: 'Lỗi', html: res.data.message});
			}
		}).catch(err => {
			Swal.fire( 'Lỗi', 'Có lỗi xảy ra!', 'error' );
		});
}

export const getConfigMoDong = (type = '') => (dispatch, getState) => {
	dispatch({
		type: 'LOADING_BLOCK_BUFF_SUB_TIK_TOK',
		payload: true
	});

	axios.get('/api/tiktok_buff/getconfig?type_api='+type, tokenConfig(getState))
	.then(res => {
		dispatch({
			type: 'LOADING_BLOCK_BUFF_SUB_TIK_TOK',
			payload: false
		});
		if (res.data.status === 200) {
			dispatch({
				type: 'CONFIG_MO_DONG_TIKTOK_VIP_VIEW',
				payload: res.data
			});
		}
	});
}

export const GiaHan = (giahanthem, dataid, closeM) => (dispatch, getState) => {
	var body = {
		id: dataid,
		giahanthem: giahanthem,
		type_api: 'tiktokvipview'
	};
			
	dispatch({
		type: 'LOADING_BLOCK_BUFF_SUB_TIK_TOK',
		payload: true
	});
	axios.post('/api/tiktok_buff/giahanthem', body, tokenConfig(getState))
		.then(res => {
			dispatch({
				type: 'LOADING_BLOCK_BUFF_SUB_TIK_TOK',
				payload: false
			});
			dispatch(loadUser());
			closeM();
			if (res.data.status === 200) {
				dispatch(getListBuffSub(0, 0, 'tiktokvipview'));
				Swal.fire({
					icon: 'success',
					html: 'Cập nhật thành công'
				});
			}
			if (res.data.status === 400) {
				var message = '';
				if (res.data.error) {
					if (res.data.error.object_id) {
						message = res.data.error.object_id[0];
					} else if (res.data.error.quantity) {
						message = res.data.error.quantity[0];
					} else {
						message = res.data.message;
					}
				} else {
					message = res.data.message;
				}
				Swal.fire({
					icon: 'error',
					title: 'Lỗi',
					text: message,
				});
			}
		});
};

export const tokenConfig = getState => {
	// Get token from localhost
	const token = getState().auth.token;
	
	// Headers
	const config = {
		headers: {
			"Content-type": "application/json"
		}
	};
	
	// If token, add to headers
	if (token) {
		config.headers['ht-token'] = token;
	}
	
	return config;
};