const date7dnext = () => {
	var result = new Date();
  	result.setDate(result.getDate() + 7);
  	return result;
}

const initialState = {
	loadingblock: false,
	listBuffSub: [],
	datapricebuffsub: {
		tiktokvipview_sv1: {
			min_bh_web: 0,
			prices_web: 0,
		},
	},
	formData: {
		link: '',
		profile_user: '',
		loaiseeding: 'tiktokvipview_sv1',
		goiview: 2000,
		tgsd: 15,
		giatien: 0,
		ghichu: '',
		infoTiktok: {
			userid_tiktok: "",
			uniqueId_tiktok: "",
			avatarThumb_tiktok: "",
			avatarMedium_tiktok: "",
			verified_tiktok: "",
			secret_tiktok: "",
			secUid_tiktok: "",
			openFavorite_tiktok: "",
			relation_tiktok: "",
			following_tiktok: "",
			fans_tiktok: "",
			heart_tiktok: "",
			video_tiktok: "",
			like_tiktok: "",
		}
	},
	tiktokvipview_sv1_mo_dong: 1,
};

export default function(state = initialState, action) {
  	switch (action.type) {
	    case 'LIST_BUFF_SUB_TIK_TOK_USER':
	    	return {
				...state,
				listBuffSub: action.payload
			};
	    case 'LOADING_BLOCK_BUFF_SUB_TIK_TOK':
			return {
				...state,
				loadingblock: action.payload
			};
    	case 'LIST_BUFF_SUB_USER_TIKTOK':
    		return {
				...state,
				listBuffSub: action.payload
			};
    	case 'DATA_PRICE_IS_FUNCTION_TIKTOK_BUFF':
    		return {
				...state,
				datapricebuffsub: merDataPricePage(action.payload)
			};
    	case 'CHANGE_DATA_FORM_BUFF_SUB_TIKTOK':
    		return {
				...state,
				formData: {...state.formData, [action.payload.name]: action.payload.value }
			};
    	case 'UPDATE_INFO_TIKTOK':
    		return {
				...state,
				formData: {
					...state.formData,
					infoTiktok: action.payload
				}
			};
		case 'CONFIG_MO_DONG_TIKTOK_VIP_VIEW':
			return {
				...state,
				tiktokvipview_sv1_mo_dong: action.payload.tiktokvipview_sv1_mo_dong,
			};
    	default:
      		return state;
  	}
}

function merDataPricePage(data) {
	const dataOld = initialState.datapricebuffsub;
	let dataNew = {
		...dataOld
	}
	if (data.length > 0) {
		data.forEach(function(v, i){
			if (v.name_table === 'tiktokvipview_sv1') {
				dataNew['tiktokvipview_sv1'] = v;
				initialState.formData['giatien'] = v.prices_web;
			}
		});
	};
	return dataNew;
}
