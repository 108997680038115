import React, { Component, Fragment } from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import { connect } from 'react-redux';
import Moment from 'react-moment';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { getListBuffSub,cancelOrderBuffsub,reLoadIdFb } from '../../../actions/vipviewTiktok';
import ModalEditAdmin from './modalEditAdmin';
import Modalgiahan from './modal_giahan';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import parse from 'html-react-parser';
import { RiExchangeDollarLine } from "react-icons/ri";
const { SearchBar } = Search;

class Nhatkyorder extends Component {
    state = {
        limit: 0,
        viewall: 0,
		page: 1,
		obj_search: "",
        dataItemSelect: {},
        isOpenModalEditAdmin: false,
		isOpenModalGiahan: false,
    }

    componentDidMount(){
        this.props.getListBuffSub(0, 1, '', 'tiktokvipview');
    }

    calBwDateToNow = (date_check = null) => {
        if (Number.isInteger(date_check)) {
            let date_now = Math.floor(Date.now() / 1000);
            let calDateDay = (date_now - date_check)/86400;
            if (calDateDay > 1) {
                return Math.ceil(calDateDay) + ' ngày trước';
            } else {
                let calDatehour = (date_now - date_check)/3600;
                if (calDatehour < 0.6) {
                    return Math.ceil(calDatehour*100) + ' phút trước';
                } else {
                    return Math.ceil(calDatehour) + ' giờ trước';
                }
            }
        }
    }

	onChange = (e) => {
		this.setState({
			"obj_search": e.target.value,
			"page": 1
		});
	}
    getListByLimit = (page, type_button) => {
        var valueChange = parseInt(page);
		if (type_button == "prev") {
			valueChange -= 1;
			if (valueChange <= 0) {
				valueChange = 1;
			}
		}
		if (type_button == "next") {
			valueChange += 1;
		}
		this.setState({
			page: valueChange,
		});
		this.props.getListBuffSub(this.state.viewall, valueChange, this.state.obj_search, 'tiktokvipview');
    }
	
    getListByView = (e) => {
        let valueChange = parseInt(e);
		this.setState({
			viewall: valueChange,
			"page": 1,
		});
		this.props.getListBuffSub(valueChange, 1, this.state.obj_search, 'tiktokvipview');
    }
	
    getListBySearch = (e) => {
		this.setState({
			"page": 1,
		});
		this.props.getListBuffSub(this.state.viewall, 1, this.state.obj_search, 'tiktokvipview');
    }

    searchDataBuff = (e) => {
        if (e.key === 'Enter') {
            let value_search = e.target.value;
            this.props.getListBuffSub(this.state.viewall, 50, 'tiktokvipview');
        }
    }

    openModalEditAdmin = (data = {}) => {
        this.setState({
            ...this.state,
            isOpenModalEditAdmin: !this.state.isOpenModalEditAdmin,
            dataItemSelect: data,
        })
    }
	
    openModalGiahan = (data = null) => {
		this.setState({
			...this.state,
			isOpenModalGiahan: !this.state.isOpenModalGiahan,
		});
		if (data !== null) {
			this.setState({
				...this.state,
				isOpenModalGiahan: !this.state.isOpenModalGiahan,
				dataItemSelect: data,
			});
		}
    }

    render() {
		function formatBaoHanh(cell, row) {
			if (cell === 0) {
				return (
					<span className="badge badge-warning">Không</span>
				)
			} else {
				return (
					<span className="badge badge-success">Có</span>
				)
			}
		}
		function filterValueBaoHanh(cell, row) {
			if (cell === 0) {
				return ("Không")
			} else {
				return ("Có")
			}
		}
		function formatTrangThai(cell, row) {
			var message = '';
			if (cell === 0) {
				return (
					<span className="badge badge-primary"><i className="fas fa-check mr-1"></i>Hoàn thành</span>
				);
			} else if (cell === 1) {
				return (
					<span className="badge badge-success"><i className="fas fa-sync-alt mr-1"></i>Đang chạy</span>
				);
			} else if (cell === 2) {
				return (
					<span className="badge badge-danger"><i className="fas fa-ban mr-1"></i>ID die không thể chạy</span>
				);
			} else if (cell === 3) {
				return (
					<span className="badge badge-warning"><i className="fas fa-times mr-1"></i>Hủy đơn</span>
				);
			} else if (cell === 5) {
				return (
					<span className="badge badge-info"><RiExchangeDollarLine size={12} className="mr-1" />Hoàn tiền</span>
				);
			} else {
				return ('');
			}
		}
		function filterValueTrangThai(cell, row) {
			if (cell === 0) {
				return ('Hoàn thành');
			} else if (cell === 1) {
				return ('Đang chạy');
			} else if (cell === 2) {
				return ('ID die không thể chạy');
			} else if (cell === 3) {
				return ('Hủy đơn');
			} else if (cell === 5) {
				return ('Hoàn tiền');
			} else {
				return ('');
			}
		}
		
		function formatCheckTime(cell, row) {
			let date_now = Math.floor(Date.now() / 1000);
			let calDateDay = (cell - date_now);
			if (calDateDay >= 0) {
				if (calDateDay/86400 < 1) {
					return Math.floor(calDateDay/3600) + ' giờ';
				}
				return (
					<Fragment>
						<Moment diff={date_now*1000} unit="days">{cell*1000}</Moment> ngày
					</Fragment>
				);
			} else {
				return <span className="badge badge-danger">Hết hạn</span>
			}
		}
		function filterValueCheckTime(cell, row) {
			let date_now = Math.floor(Date.now() / 1000);
			let calDateDay = (cell - date_now);
			if (calDateDay >= 0) {
				if (calDateDay/86400 < 1) {
					return Math.floor(calDateDay/3600) + ' giờ';
				}
				var diffDays = Math.floor(calDateDay / (60 * 60 * 24)) + ' ngày';
				return diffDays;
			} else {
				return ('Hết hạn');
			}
		}
		function formatAVT(cell, row) {
			if (cell !== null) {
				if (typeof JSON.parse(cell).avatarMedium_tiktok === 'string') {
					// return parse('<img src='+JSON.parse(cell).avatarMedium_tiktok.replace("p16-sign-sg.tiktokcdn.com", "p16-amd-va.tiktokcdn.com").replace("p16-sign-va.tiktokcdn.com", "p16-amd-va.tiktokcdn.com").replace("p9-sign-sg.tiktokcdn.com", "p16-amd-va.tiktokcdn.com")+' class="avatar-user" /> <a href="https://www.tiktok.com/@'+JSON.parse(cell).uniqueId_tiktok+'" target="_blank" rel="noopener noreferrer">'+JSON.parse(cell).uniqueId_tiktok+'</a>');
					if (JSON.parse(cell).avatarMedium_tiktok.indexOf('300x400') > -1) {
						return parse('<img src='+JSON.parse(cell).avatarMedium_tiktok+' class="avatar-user" /> <a href="https://www.tiktok.com/@'+JSON.parse(cell).uniqueId_tiktok+'" target="_blank" rel="noopener noreferrer">'+JSON.parse(cell).uniqueId_tiktok+'</a>');
					} else {
						return parse('<img src='+JSON.parse(cell).avatarMedium_tiktok.replace("p16-sign-sg.tiktokcdn.com", "p16-amd-va.tiktokcdn.com").replace("p16-sign-va.tiktokcdn.com", "p16-amd-va.tiktokcdn.com").replace("p9-sign-sg.tiktokcdn.com", "p16-amd-va.tiktokcdn.com")+' class="avatar-user" /> <a href="https://www.tiktok.com/@'+JSON.parse(cell).uniqueId_tiktok+'" target="_blank" rel="noopener noreferrer">'+JSON.parse(cell).uniqueId_tiktok+'</a>');
					}
				} else {
					// return parse('<img src='+JSON.parse(cell).avatarMedium_tiktok[0].replace("p16-sign-sg.tiktokcdn.com", "p16-amd-va.tiktokcdn.com").replace("p16-sign-va.tiktokcdn.com", "p16-amd-va.tiktokcdn.com").replace("p9-sign-sg.tiktokcdn.com", "p16-amd-va.tiktokcdn.com")+' class="avatar-user" /> <a href="https://www.tiktok.com/@'+JSON.parse(cell).uniqueId_tiktok+'" target="_blank" rel="noopener noreferrer">'+JSON.parse(cell).uniqueId_tiktok+'</a>');
					if (JSON.parse(cell).avatarMedium_tiktok[0].indexOf('300x400') > -1) {
						return parse('<img src='+JSON.parse(cell).avatarMedium_tiktok[0]+' class="avatar-user" /> <a href="https://www.tiktok.com/@'+JSON.parse(cell).uniqueId_tiktok+'" target="_blank" rel="noopener noreferrer">'+JSON.parse(cell).uniqueId_tiktok+'</a>');
					} else {
						return parse('<img src='+JSON.parse(cell).avatarMedium_tiktok[0].replace("p16-sign-sg.tiktokcdn.com", "p16-amd-va.tiktokcdn.com").replace("p16-sign-va.tiktokcdn.com", "p16-amd-va.tiktokcdn.com").replace("p9-sign-sg.tiktokcdn.com", "p16-amd-va.tiktokcdn.com")+' class="avatar-user" /> <a href="https://www.tiktok.com/@'+JSON.parse(cell).uniqueId_tiktok+'" target="_blank" rel="noopener noreferrer">'+JSON.parse(cell).uniqueId_tiktok+'</a>');
					}
				}
			}
		}
		function formatObjID(cell, row) {
			return parse('<a href="https://www.tiktok.com/@'+JSON.parse(row.object_data_tiktok).uniqueId_tiktok+'" target="_blank" rel="noopener noreferrer">'+cell+'</a>');
		}
		function calBwDateToNow(cell, row) {
			let date_now = Math.floor(Date.now() / 1000);
			let calDateDay = (date_now - cell)/86400;
			if (calDateDay > 1) {
				return Math.ceil(calDateDay) + ' ngày trước';
			} else {
				let calDatehour = (date_now - cell)/3600;
				if (calDatehour < 0.6) {
					return Math.ceil(calDatehour*100) + ' phút trước';
				} else {
					return Math.ceil(calDatehour) + ' giờ trước';
				}
			}
		}
		function formatCheckTimeUse(cell, row) {
			var result = new Date(row.created_at*1000);
			result.setDate(result.getDate() + parseInt(row.days));
			var result_now = new Date();
			
			var difference = (result - result_now)/1000;
			if (difference >= 0) {
				// if (difference/86400 < 1) {
		          	// return Math.floor(difference/3600) + ' giờ';
		        // }
				const days = Math.floor(difference / 86400);
				const hours = Math.floor(difference / 3600) - (days * 24);
				const minutes = Math.floor(difference / 60) - (days * 24 * 60) - (hours * 60);
				const seconds = Math.floor(difference) - (days * 24 * 60 * 60) - (hours * 60 * 60) - (minutes * 60);
				var datediff = "";
				if (days > 0) {
					datediff += days + ' ngày ';
				}
				if (hours > 0) {
					datediff += hours + ' giờ ';
				}
				if (minutes > 0) {
					datediff += minutes + ' phút';
				}
				return datediff;
				{/* return (
					<Fragment>
						<Moment diff={result_now} unit="days">
								{result}
							</Moment>
						{' '} ngày
					</Fragment>
				); */}
			} else {
				return <span className="badge badge-danger">Hết hạn</span>
			}
		}
		function filterValueCheckTimeUse(cell, row) {
			var result = new Date(row.created_at*1000);
			result.setDate(result.getDate() + parseInt(row.days));
			var result_now = new Date();
			
			var diffTime = (result - result_now)/1000;
			if (diffTime >= 0) {
				if (diffTime/86400 < 1) {
		          	return Math.floor(diffTime/3600) + ' giờ';
		        }
				var diffDays = Math.floor(diffTime / (60 * 60 * 24)) + ' ngày';
				return diffDays;
			} else {
				return ('Hết hạn');
			}
		}
		function formatGoi(cell, row) {
			if (cell == 'tiktokvipview_sv1') {
				return ('SV1');
			} else {
				return ('');
			}
		}
        const columns = [{
          dataField: '',
          text: 'STT',
          sort: true,
          formatter: (cell, row, rowIndex) =>
            <span>{rowIndex+1}</span>
        }, {
		    text: 'Thao tác',
		    dataField: '-',
		    style: {
			  'white-space' : 'nowrap'
		    },
			formatter: (cell, row) => 
				<Fragment>
					{ this.props.auth.user.type === 1 ?
						<button type="button" onClick={() => {this.openModalEditAdmin(row)}} className="btn btn-hero-success text-center p-2 mr-1"><i className="fa fa-fw fa-pen"></i></button>
						: ''
					}
					{row.status === 2 && row.time_expired > Math.floor(Date.now() / 1000) ?
						<Fragment>
							<button onClick={() => {this.props.reLoadIdFb(row.id)}} type="button" name="re_id_die" className="btn btn-hero-warning text-center p-2 mr-1">
								<i className="far fa-check-circle"></i>
							</button>
						</Fragment>
						: ''
					}
					{row.status !== 3 && row.status !== 0  ?
						<Fragment>
							<button type="button" name="giahan" className="btn btn-hero-primary text-center p-2 mr-1" onClick={() => {this.openModalGiahan(row)}}><i className="fa fa-fw fa-clock"></i></button>
						</Fragment>
						: ''
					}
					{row.status === 2 || row.status === 1 || (row.status === 0 && row.time_expired > Math.floor(Date.now() / 1000)) ?
						<Fragment>
							<button type="button" onClick={() => {this.props.cancelOrderBuffsub(row.id, row.type, 0)}} name="huy_order" className="btn btn-hero-danger text-center p-2 mr-1"><i className="fa fa-fw fa-trash"></i></button>
						</Fragment>
						: ''
					}
				</Fragment>
		},{
          dataField: 'object_data_tiktok',
          text: 'Tiktok',
          sort: true,
		  formatter: formatAVT,
        },{
          text: 'type',
          dataField: 'type',
		  sort: true,
		  formatter: formatGoi,
		  filterValue: formatGoi
        },{
          dataField: 'object_id',
          text: 'ID seeding',
          sort: true,
		  formatter: formatObjID,
		}, {
			dataField: 'quantity',
			text: 'Gói view',
			sort: true,
		}, {
			dataField: 'days',
			text: 'Số ngày',
			sort: true,
        },{
          dataField: 'price_per',
          text: 'Giá',
          sort: true
        },{
          dataField: 'prices',
          text: 'Tổng tiền',
          sort: true
		}, {
			dataField: 'created_at',
			text: 'Khởi tạo',
			sort: true,
			formatter: calBwDateToNow,
			filterValue: calBwDateToNow
		}, {
			dataField: 'time_expired',
			text: 'Hết hạn',
			sort: true,
			formatter: formatCheckTimeUse,
			filterValue: formatCheckTimeUse
        },{
          text: 'username',
          dataField: 'username',
          sort: true,
		  classes: 'notranslate',
        },{
          dataField: 'status',
          text: 'Trạng thái',
          sort: true,
		  formatter: formatTrangThai,
		  filterValue: filterValueTrangThai
        }];

        const expandRow = {
            renderer: (row) => (
                <Fragment>
                    <div className="row">
                        {/* <div className="col-lg-4">
                            <div className="mt-2">
                                <h6 className="mb-0 font-14">Lọc nâng cao</h6>
                                <div className="row mt-3">
							    	{ row.gender == '0' ?
								        <div className="col-4">
								            <h6 className="mb-0 font-14"><i className="fas fa-venus-mars font-16"></i> Tất cả</h6>
								        </div>
									: '' }
							    	{ row.gender == '1' ?
								        <div className="col-4">
								            <h6 className="mb-0 font-14"><i className="fas fa-venus-mars font-16"></i> Nữ</h6>
								        </div>
									: '' }
							    	{ row.gender == '2' ?
								        <div className="col-4">
								            <h6 className="mb-0 font-14"><i className="fas fa-venus-mars font-16"></i> Nam</h6>
								        </div>
									: '' }
                                    { row.age_min !== '0' ? 
                                        <div className="col-4">
                                            <h6 className="mb-0 font-14"><i className="fas fa-birthday-cake"></i> {row.age_min} - {row.age_max}</h6>
                                        </div>
                                    : ''
                                    }
                                    { row.friend_min !== '0' ? 
                                        <div className="col-4">
                                            <h6 className="mb-0 font-14"><i className="fas fa-user-friends"></i> {row.friend_min} - {row.friend_max}</h6>
                                        </div>
                                    : ''
                                    }
                                </div>
                            </div>
                        </div> */}
                        <div className="col-lg-8 row">
                            <div className="col-lg-4">
                                <div className="form-group">
                                    <label htmlFor="example-text-input">Ghi chú</label>
                                    <textarea className="form-control" id="gc" name="gc" placeholder="" rows="2" defaultValue={row.notes}></textarea>
                                </div>
                            </div>
							{/* <div className="col-lg-4 d-flex align-items-center justify-content-center">
								<div className="form-group">
									{row.status === 2 || row.status === 1 || (row.status === 0 && row.time_expired > Math.floor(Date.now() / 1000)) ?
										<Fragment>
											<button type="button" onClick={() => {this.props.cancelOrderBuffsub(row.id, row.type, row.id_api)}} name="huy_order" className="btn btn-block btn-sm btn-rounded btn-hero-light">Hủy Order</button>
										</Fragment>
										: ''
									}
									{row.status === 2 && row.time_expired > Math.floor(Date.now() / 1000) ?
										<Fragment>
											<button onClick={() => {this.props.reLoadIdFb(row.id)}} type="button" name="re_id_die" className="btn btn-block btn-sm btn-rounded btn-hero-light text-warning">
												Check id
											</button>
										</Fragment>
										: ''
									}
									{ this.props.auth.user.type === 1 ?
										<button type="button" onClick={() => {this.openModalEditAdmin(row)}} className="btn btn-block btn-sm btn-rounded btn-hero-success">Cài đặt admin</button>
										: ''
									}
								</div>
							</div> */}
                        </div>
                    </div>
                </Fragment>
            ),
            showExpandColumn: true,
            expandColumnPosition: 'right',
            expandHeaderColumnRenderer: ({ isAnyExpands }) => {
                if (isAnyExpands) {
                    return <i className="fas fa-caret-up"></i>;
                }
                return <b><i className="fas fa-caret-down"></i></b>;
            },
            expandColumnRenderer: ({ expanded }) => {
                if (expanded) {
                    return (
                        <b><i className="fas fa-caret-up"></i></b>
                    );
                }
                return (
                    <b><i className="fas fa-caret-down"></i></b>
                );
            }
        };
        const {listBuffSub} = this.props.buffsub;
        const {isOpenModalEditAdmin,isOpenModalGiahan,dataItemSelect} = this.state;
        return (
            <Fragment>
                {/*<div className="row mb-4">
                    <div className="col-md-5">
                        { this.props.auth.user.type === 1 ?
                            <Fragment>
                                <button onClick={() => {this.props.getListBuffSub(1);this.setState({...this.state,viewall: 1})}} type="button" className="btn btn-primary mr-1">Xem tất cả</button>
                                <button onClick={() => {this.props.getListBuffSub();this.setState({...this.state,viewall: 0})}} type="button" className="btn btn-primary mr-1">Xem riêng</button>
                            </Fragment>
                        : ''}
                    </div>

                    <div className="col-md-3">
                        <select onChange={(e) => {this.getListByLimit(e)}} className="form-control">
                            <option value="">Số lịch sử hiển thị tối đa</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="200">200</option>
                            <option value="500">500</option>
                            <option value="1000">1000</option>
                        </select>
                    </div>
                    <div className="col-md-4">
                        <input onKeyDown={(e) => {this.searchDataBuff(e)}} type="text" name="search_user" className="form-control" placeholder="Nhập id tìm kiếm"/>
                    </div>
                </div>*/}
					
				<ToolkitProvider keyField="id" data={ listBuffSub } columns={ columns } search >
					{
						props => (
							<div>
								<SearchBar { ...props.searchProps } />
								<div className="row mb-4">
									<div className="col-md-12">
										{ this.props.auth.user.type === 1 ?
											<Fragment>
												<button onClick={(e) => {this.getListByView(1)}} name="view" value="1" type="button" className="btn btn-primary mr-1">Xem tất cả</button>
												<button onClick={(e) => {this.getListByView(0)}} name="view" value="0" type="button" className="btn btn-primary mr-1">Xem riêng</button>
											</Fragment>
										: ''}
										{/* <select onChange={(e) => {this.getListByLimit(e)}} className="form-control custom-select select-light col-md-3 custom-limit_pagi">
											<option value="0">Số lịch sử hiển thị (0-500)</option>
											<option value="1">500-1000</option>
											<option value="2">1000-1500</option>
											<option value="3">1500-2000</option>
											<option value="4">2000-2500</option>
											<option value="5">2500-3000</option>
											<option value="7">3000-3500</option>
											<option value="8">3500-4000</option>
											<option value="9">4500-5000</option>
										</select> */}
										<button onClick={(e) => {this.getListByLimit(this.state.page, "prev")}} name="page" value={this.state.page} type="button" className="btn btn-primary mr-1">{"<"}</button>
										<button onClick={(e) => {this.getListByLimit(this.state.page, "next")}} name="page" value={this.state.page} type="button" className="btn btn-primary mr-1">{">"}</button>
										<button onClick={(e) => {this.getListBySearch(e)}} name="view" value="0" type="button" className="btn btn-primary mr-1">Tìm kiếm</button>
										<input  onChange={this.onChange} value={this.state.obj_search}  name="obj_search" type="text" className="form-control mr-1" style={{"width": "100px", "display": "inline-block"}}/>
									</div>
								</div>
							<BootstrapTable
								{ ...props.baseProps }
								bodyStyle={ { border: 'none' } }
								headerStyle={ { border: 'red 1px solid' } }
								striped
								hover
								expandRow={ expandRow }
								wrapperClasses="table-responsive"
								pagination={ paginationFactory() } />
							</div>
						)
					}
				</ToolkitProvider>
                {(this.props.auth.user.type === 1 && isOpenModalEditAdmin) ? <ModalEditAdmin openModal={this.openModalEditAdmin} isOpenModal={isOpenModalEditAdmin} dataItemSelect={dataItemSelect} /> : '' }
				{isOpenModalGiahan ? <Modalgiahan openModal={this.openModalGiahan} isOpenModal={isOpenModalGiahan} dataItemSelect={dataItemSelect} /> : '' }
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    buffsub: state.vipviewtiktok
});

export default connect(mapStateToProps, {getListBuffSub,cancelOrderBuffsub,reLoadIdFb})(Nhatkyorder);
