import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import store from '../../../store';

import Swal from 'sweetalert2';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { updateShopee, getInfoShopee, addShopee, updatePriceBaoHanh } from '../../../actions/ShopeeActions';
import { LOADING_SHOPEE } from "../../../actions/types";
import parse from 'html-react-parser';
import ServicesDescription from "../ServicesDescription";
import { isMobile } from 'react-device-detect';

const date7dnext = () => {
	var result = new Date();
  	result.setDate(result.getDate() + 7);
  	return result;
}

class taotientrinh extends Component {
	static propTypes =  {
		updateShopee: PropTypes.func.isRequired,
		getInfoShopee: PropTypes.func.isRequired,
		addShopee: PropTypes.func.isRequired,
	};
	
	state = {
		showDescription: isMobile? false : true
	}
	
	componentDidMount() {
		// this.props.loadPriceFLBBKTT();
	};
	
	option_tgsd = () => {
		let ar_option_tgsd = [];
		ar_option_tgsd.push(
			<option value="0.5" key="0.5">
				15 Ngày
			</option>
		);
		for (let i = 1; i <= 6; i++) {
			ar_option_tgsd.push(
				<option value={i} key={i}>
					{i} Tháng
				</option>
			)
		}
		return ar_option_tgsd;
	}
	
	onChange = (e) => {
		const { taotientrinh, price } = this.props.Shopee;
		if (e.target.name === 'linkshop' && e.target.value != "") {
			store.dispatch({
				type: LOADING_SHOPEE,
				payload: true
			});
			var linkshop_fixed = e.target.value.replace('https://shopee.vn/', '');
			var linkshop_tmp = e.target.value;
			linkshop_fixed =  linkshop_fixed.replace('/', '');
			if (linkshop_fixed.indexOf("?")) {
				linkshop_fixed = linkshop_fixed.split("?")[0];
			}
			if (e.target.value.indexOf('/shop/') > -1) {
				var linkshop_fixed = e.target.value.replace('https://shopee.vn/shop/', '');
				var linkshop_tmp = e.target.value;
				linkshop_fixed =  linkshop_fixed.replace('/', '');
				if (linkshop_fixed.indexOf("?")) {
					linkshop_fixed = linkshop_fixed.split("?")[0];
				}
			}
			var fun_check = this.props.getInfoShopee(linkshop_fixed);
			if (fun_check !== false) {
				fun_check.then(res => {
					store.dispatch({
						type: LOADING_SHOPEE,
						payload: false
					});
					if (res.data.status === 200) {
						let data_res = res.data.data.data;
						var new_taotientrinh = {
							...taotientrinh,
							"linkshop": data_res.name,
							"usernameshopee": data_res.account.username,
							"portrait": data_res.account.portrait,
							"follower_count": data_res.follower_count,
							"shopid": data_res.shopid,
						};
						this.props.updateShopee(new_taotientrinh);
						Swal.fire({
							icon: 'success',
							html: 'Lấy thông tin thành công'
						});
					} else {
						var new_taotientrinh = {
							...taotientrinh,
							"linkshop": linkshop_fixed,
							"usernameshopee": "",
							"portrait": "",
							"follower_count": "0",
							"shopid": "0",
						};
						this.props.updateShopee(new_taotientrinh);
						Swal.fire({
							icon: 'error',
							title: 'Lỗi',
							text: res.data.message,
						});
						return false;
					}
				})
			} else {
				store.dispatch({
					type: LOADING_SHOPEE,
					payload: false
				});
				Swal.fire({
					icon: 'error',
					title: 'Lỗi',
					text: "Có lỗi xảy ra!",
				});
				return false;
			}
		} else if (e.target.name === 'cdbh')  {
			var array = {};
			if (e.target.value === '0') {
				array = {
					"type": "0",
					"gtmtt": price.prices_web
				};
			}
			if (e.target.value === '1') {
				array = {
					"type": "1",
					"gtmtt": price.min_bh_web
				};
			}
			this.props.updatePriceBaoHanh(array);
		} else {
			var new_taotientrinh = {
				...taotientrinh,
				[e.target.name]: e.target.value
			};
			this.props.updateShopee(new_taotientrinh);
		}
	}
	
	taoTienTrinhfunc = () => {
		const { taotientrinh } = this.props.Shopee;
		var new_taotientrinh = {
			...taotientrinh,
		};
		if (taotientrinh.linkshop === '') {
			Swal.fire({
				icon: 'error',
				title: 'Lỗi',
				text: 'Link shop cần chạy không được để trống!',
			});
			return false;
		}
		Swal.fire({
			title: 'Bạn có chắc?',
			html: "Bạn có chắc chắn muốn mua 1 sub với giá: "+taotientrinh.gtmtt+" Coin/1 sub ? Khi đã bấm đồng ý đồng nghĩa với việc bạn đã đọc hết mục thông tin dịch vụ!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Đồng ý',
			cancelButtonText: 'Không'
		}).then((result) => {
			if (result.value) {
				this.props.addShopee(new_taotientrinh);
			}
		})
	}
	
	onKeyPress = (e) => {
		if (!(e.charCode >= 48 && e.charCode <= 57)) {
			e.preventDefault();
		}
	}
	
	onClickShowDescription = (data) => {
		this.setState({
			showDescription: data
		});
	}
	
	render() {
 		const { linkshop, usernameshopee, portrait, follower_count, shopid, lsct, cdbh, slct, gtmtt, gc } = this.props.Shopee.taotientrinh;
		var price = Math.round((gtmtt * slct + Number.EPSILON) * 100) / 100;
		if (cdbh === "1") {
			price = Math.round((gtmtt * slct + Number.EPSILON) * 100) / 100;
		}
		var info = '';
		if (portrait !== '') {
			info = parse('<img src="https://cf.shopee.vn/file/'+portrait+'" class="avatar-user" /> ' + usernameshopee + ' (sub hiện tại: ' + follower_count + ')');
		}
		
		var listDataServicesInfo = {};
		try {
			if (localStorage.getItem("listDataServicesInfo") != null) {
				listDataServicesInfo = JSON.parse(localStorage.getItem("listDataServicesInfo"));
			} else {
				listDataServicesInfo = this.props.adminpage.listDataServicesInfo;
			}
		} catch(e) {
			listDataServicesInfo = {};
		}
		var html_haydoctranhmattien = '';
		var html_haydoctranhmattien_chinh = '';
		var html_haydoctranhmattien_sv = '';
		var html_sub = '';
		if (typeof(listDataServicesInfo.shopee_buff_sub_tab) !== 'undefined') {
			html_haydoctranhmattien = parse(listDataServicesInfo.shopee_buff_sub_tab.ghichu);
			html_haydoctranhmattien_chinh = parse(listDataServicesInfo.shopee_buff_sub_tab.ghichu_chinh);
		}
		if (typeof(listDataServicesInfo.shopee_buff_sub) !== 'undefined') {
			html_sub = parse(listDataServicesInfo.shopee_buff_sub.ghichu_sv);
			if (lsct.toString() === "sub") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.shopee_buff_sub.ghichu);
			}
		}
		
		return (
			<Fragment>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Link Shop (*):</label>
					<div className="col-sm-8">
						<input type="text" className="form-control" id="linkshop" name="linkshop" placeholder="Nhập Link Shop" onChange={this.onChange} value={linkshop} />
						{info}
						<h6 style={{"marginTop":"5px", "marginBottom":"0px"}}>Hướng dẫn link shopee: <a href="https://i.imgur.com/xcHxDLB.png" target="_blank" className="font-bold"> Tại đây</a></h6>
					</div>
				</div>
				<div className="form-group row mt-4">
					<label className="col-sm-4 col-form-label" htmlFor="">
						Loại Seeding cần tăng:
					</label>
					<div className="col-sm-8">
						<div className="card card-orange mt-2">
							<select className="form-control custom-select select-light" id="lsct" name="lsct" onChange={this.onChange} value={lsct} >
								<option value="sub">Tăng sub - basic {html_sub}</option>
							</select>
						</div>
					</div>
				</div>
				<div className="form-group row mt-4">
					<label className="col-sm-4 col-form-label" htmlFor="">
						Chế độ bảo hành:
					</label>
					<div className="col-sm-8">
						<div className="card card-orange mt-2">
							<select className="form-control custom-select select-light" id="cdbh" name="cdbh" onChange={this.onChange} value={cdbh} >
								<option value="0">Không bảo hành</option>
								<option value="1">Bảo hành (Bảo hành nếu tụt quá 10%)</option>
							</select>
						</div>
					</div>
				</div>
				<div className="form-group row mt-4">
					<label className="col-sm-4 col-form-label" htmlFor="">
						Số lượng cần tăng:
					</label>
					<div className="col-sm-8">
						<div className="card card-orange mt-2">
							<input type="number" className="form-control" id="slct" name="slct" placeholder="" value={slct} onChange={this.onChange} onKeyPress={this.onKeyPress} />
						</div>
					</div>
				</div>
				<div className="form-group row mt-4">
					<label className="col-sm-4 col-form-label" htmlFor="">
						Giá tiền mỗi tương tác:
					</label>
					<div className="col-sm-8">
						<div className="form-group">
							<input type="number" className="form-control" id="gtmtt" name="gtmtt" placeholder="" value={gtmtt} onChange={this.onChange} onKeyPress={this.onKeyPress} />
						</div>
					</div>
				</div>
				<div className="row mt-2">
					<div className="col-md-4">
						<label className="mt-2">Ghi chú:</label>
					</div>
					<div className="col-md-8">
						<div className="form-group">
                            <textarea onChange={(e) => {this.onChange(e)}} name="gc" value={gc} className="form-control" rows="4" placeholder="Nhập nội dung ghi chú về tiến trình của bạn"></textarea>
                        </div>
					</div>
				</div>
				
				<button type="button" className="btn badge-primary-cus btn-rounded btn-show-description-cus" onClick={(e) => {this.onClickShowDescription(!this.state.showDescription)}}>
					{
						this.state.showDescription?
							<i className="fa fa-angle-up" style={{"marginRight": "5px"}} aria-hidden="true"></i>
						:
							<i className="fa fa-angle-down" style={{"marginRight": "5px"}} aria-hidden="true"></i>
					}
					Thông tin dịch vụ
				</button>
				
				<ServicesDescription allservicesinfo={listDataServicesInfo} server={lsct.toString()} tab="shopee_buff_sub" giatien={gtmtt} baohanh={parseInt(cdbh)} showDescription={this.state.showDescription} />
				
				<div className="row mt-2" style={{"display": this.state.showDescription? "flex" : "none"}}>
				    <div className="col-md-12 bold" style={{"marginBottom": "10px"}}>
						<div className="card card-select-cus">
							<div className="card-body card-body-select-cus">
								<p className="mb-0 mt-3 text-wrap">
									<ul>
										<li>1 ID KHÔNG CÀI 2 ĐƠN CÙNG LÚC TRONG HỆ THỐNG ! ĐƠN CŨ XONG MỚI CÀI ĐƠN MỚI ! CỐ TÌNH CÀI BỊ HỤT SỐ LƯỢNG SẼ KHÔNG XỬ LÝ !</li>
										<li>Nếu ID đang chạy trên hệ thống mà bạn vẫn mua id đó cá hệ thống bên khác, nếu có tình trạng hụt số lượng giữa 2 bên thì sẽ không được xử lí!</li>
										{html_haydoctranhmattien}
										{html_haydoctranhmattien_chinh}
										{html_haydoctranhmattien_sv}
										<li>Nếu gặp lỗi hãy nhắn tin hỗ trợ phía bên phải góc màn hình hoặc vào mục liên hệ hỗ trợ để được hỗ trợ tốt nhất!</li>
									</ul>
								</p>
								<span className="badge badge-cus position-absolute-cus badge-top-left-cus badge-primary-cus badge-pill-cus"> HÃY ĐỌC ĐỂ TRÁNH MẤT TIỀN KHI SỬ DỤNG! </span>
							</div>
						</div>
				    </div>
				</div>
				<div className="row mt-4">
					<div className="col-12 text-center bold">
						<div className="card card-info">
							<div className="card-body">
								<h5 className="font-bold">Tổng: <span className="bold green"><span>{price}</span> Coin</span></h5>
								<h6 className="mb-0 bold"><span>Bạn sẽ buff 1 sub với giá <span className="green">{gtmtt} Coin</span></span></h6>
							</div>
						</div>
					</div>
				</div>
				<div className="row my-4">
					<div className="col-12 text-center bold">
						<button type="button" className="btn btn-block btn-rounded btn-hero-primary" onClick={this.taoTienTrinhfunc} >Tạo tiến trình</button>
					</div>
				</div>
			</Fragment>
		)
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	Shopee: state.Shopee,
});
const mapDispatchToProps = { updateShopee, getInfoShopee, addShopee, updatePriceBaoHanh }

export default connect(mapStateToProps, mapDispatchToProps)(taotientrinh);