import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { loadIVL, upHuyOrder, updateLimitBSR, updateTypeIVL, reLoadIdFb } from '../../../actions/instagramviplikeActions';
import {
	Modal,
	ModalBody,
	ModalFooter
} from 'reactstrap';
import Swal from 'sweetalert2';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import ModalEditAdmin from './modalEditAdmin';
import Modalgiahan from './modal_giahan';
import Moment from 'react-moment';
import { RiExchangeDollarLine } from "react-icons/ri";
const { SearchBar } = Search;

class nhatky extends Component {
	static propTypes =  {
		loadIVL: PropTypes.func.isRequired,
		upHuyOrder: PropTypes.func.isRequired,
		updateLimitBSR: PropTypes.func.isRequired,
		updateTypeIVL: PropTypes.func.isRequired,
	};
	
	state = {
		isOpenModalGiahan: false,
		isOpenModalEditAdmin: false,
		dataItemSelect: {},
		page: 1,
		obj_search: "",
	}
	
	componentDidMount() {
		this.props.loadIVL();
	}
	
	onChange = (e) => {
		this.setState({
			"obj_search": e.target.value,
			"page": 1
		});
	}
    getListByLimit = (page, type_button) => {
        var valueChange = parseInt(page);
		if (type_button == "prev") {
			valueChange -= 1;
			if (valueChange <= 0) {
				valueChange = 1;
			}
		}
		if (type_button == "next") {
			valueChange += 1;
		}
		this.setState({
			page: valueChange,
		});
		const { type } = this.props.instagramviplike;
		this.props.loadIVL(type, valueChange, this.state.obj_search);
    }
	getListByView = (type) => {
		this.setState({
			"page": 1,
		});
		this.props.updateTypeIVL(type);
		this.props.loadIVL(type, 1, this.state.obj_search);
	}
    getListBySearch = (e) => {
		this.setState({
			"page": 1,
		});
		const { type } = this.props.instagramviplike;
		this.props.loadIVL(type, 1, this.state.obj_search);
    }
	
	// onChangeLimit = e => {
		// const { type } = this.props.instagramviplike;
		// this.props.updateLimitBSR(e.target.value);
		// this.props.loadIVL(type, e.target.value);
	// };
	
	// onCheckList = (type) => {
		// const { limit_pagi } = this.props.instagramviplike;
		// this.props.updateTypeIVL(type);
		// this.props.loadIVL(type, limit_pagi);
	// }
	
	huyOrder = (id, object_id) => {
		const swalWithBootstrapButtons = Swal.mixin({
			customClass: {
				confirmButton: 'btn btn-success an-margin-btn',
				cancelButton: 'btn btn-danger an-margin-btn'
				},
			buttonsStyling: false
		})

		swalWithBootstrapButtons.fire({
			title: 'HỦY ID: '+id+' ?',
			text: "Sau khi hủy bạn sẽ bị trừ 7 ngày chưa sử dụng và 5000 phí!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Đồng ý',
			cancelButtonText: 'Không',
			reverseButtons: true
		}).then((result) => {
			if (result.value) {
				let body = {
					"id": id,
					"type_api": "instagramviplike"
				};
				this.props.upHuyOrder(body);
			} else if (result.dismiss === Swal.DismissReason.cancel) {
				
			}
		})
	}
	
	onKeyPress = (e) => {
		if (!(e.charCode >= 48 && e.charCode <= 57)) {
			e.preventDefault();
		}
	}
	
	openModalEditAdmin = (data = {}) => {
		this.setState({
			...this.state,
			isOpenModalEditAdmin: !this.state.isOpenModalEditAdmin,
			dataItemSelect: data,
		})
	}
	
    openModalGiahan = (data = null) => {
		this.setState({
			...this.state,
			isOpenModalGiahan: !this.state.isOpenModalGiahan,
		});
		if (data !== null) {
			this.setState({
				...this.state,
				isOpenModalGiahan: !this.state.isOpenModalGiahan,
				dataItemSelect: data,
			});
		}
    }
	
	render() {
		const { listtientrinh, tanggiathem, limit_pagi } = this.props.instagramviplike;
		const {isOpenModalEditAdmin, isOpenModalGiahan, dataItemSelect} = this.state;
		
		function formatTrangThai(cell, row) {
			var message = '';
			if (cell === 0) {
				return (
					<span className="badge badge-primary"><i className="fas fa-check mr-1"></i>Hoàn thành</span>
				);
			} else if (cell === 1) {
				return (
					<span className="badge badge-success"><i className="fas fa-sync-alt mr-1"></i>Đang chạy</span>
				);
			} else if (cell === 2) {
				return (
					<span className="badge badge-danger"><i className="fas fa-ban mr-1"></i>ID die không thể chạy</span>
				);
			} else if (cell === 3) {
				return (
					<span className="badge badge-warning"><i className="fas fa-times mr-1"></i>Hủy đơn</span>
				);
			} else if (cell === 5) {
				return (
					<span className="badge badge-info"><RiExchangeDollarLine size={12} className="mr-1" />Hoàn tiền</span>
				);
			} else {
				return ('');
			}
		}
		function filterValueTrangThai(cell, row) {
			if (cell === 0) {
				return ('Hoàn thành');
			} else if (cell === 1) {
				return ('Đang chạy');
			} else if (cell === 2) {
				return ('ID die không thể chạy');
			} else if (cell === 3) {
				return ('Hủy đơn');
			} else if (cell === 5) {
				return ('Hoàn tiền');
			} else {
				return ('');
			}
		}
		
		function calBwDateToNow(cell, row) {
			let date_now = Math.floor(Date.now() / 1000);
			let calDateDay = (date_now - cell)/86400;
			if (calDateDay > 1) {
				return Math.ceil(calDateDay) + ' ngày trước';
			} else {
				let calDatehour = (date_now - cell)/3600;
				if (calDatehour < 0.6) {
					return Math.ceil(calDatehour*100) + ' phút trước';
				} else {
					return Math.ceil(calDatehour) + ' giờ trước';
				}
			}
		}
		
		function formatCheckTimeUse(cell, row) {
			var result = new Date(row.created_at*1000);
			result.setDate(result.getDate() + parseInt(row.days));
			var result_now = new Date();
			
			var difference = (result - result_now)/1000;
			if (difference >= 0) {
				// if (difference/86400 < 1) {
		          	// return Math.floor(difference/3600) + ' giờ';
		        // }
				const days = Math.floor(difference / 86400);
				const hours = Math.floor(difference / 3600) - (days * 24);
				const minutes = Math.floor(difference / 60) - (days * 24 * 60) - (hours * 60);
				const seconds = Math.floor(difference) - (days * 24 * 60 * 60) - (hours * 60 * 60) - (minutes * 60);
				var datediff = "";
				if (days > 0) {
					datediff += days + ' ngày ';
				}
				if (hours > 0) {
					datediff += hours + ' giờ ';
				}
				if (minutes > 0) {
					datediff += minutes + ' phút';
				}
				return datediff;
				{/* return (
					<Fragment>
						<Moment diff={result_now} unit="days">
								{result}
							</Moment>
						{' '} ngày
					</Fragment>
				); */}
			} else {
				return <span className="badge badge-danger">Hết hạn</span>
			}
		}
		function filterValueCheckTimeUse(cell, row) {
			var result = new Date(row.created_at*1000);
			result.setDate(result.getDate() + parseInt(row.days));
			var result_now = new Date();
			
			var diffTime = (result - result_now)/1000;
			if (diffTime >= 0) {
				if (diffTime/86400 < 1) {
		          	return Math.floor(diffTime/3600) + ' giờ';
		        }
				var diffDays = Math.floor(diffTime / (60 * 60 * 24)) + ' ngày';
				return diffDays;
			} else {
				return ('Hết hạn');
			}
		}
		
		function formatGoi(cell, row) {
			if (cell == 1) {
				return ('Like Việt');
			} else if (cell == 2) {
				return ('Like Tây');
			} else {
				return ('');
			}
		}
		
		const expandRow = {
			renderer: (row) => (
				<div className="col-lg-8 row">
					{/* <div className={"col-lg-4 " + ((row.status !== 3 && row.status !== 0) || this.props.auth.user.type === 1 ? '' : 'offset-lg-4')}>
						<div className="form-group">
							<label htmlFor="example-text-input">Nội dung</label>
							<textarea className="form-control" id="nd" name="nd" placeholder="" rows="2" defaultValue={row.ndcmt} ></textarea>
						</div>
					</div> */}
					<div className="col-lg-4">
						<div className="form-group">
							<label htmlFor="example-text-input">Ghi chú</label>
							<textarea className="form-control" id="gc" name="gc" placeholder="" rows="2" defaultValue={row.notes}></textarea>
						</div>
					</div>
				</div>
			),
			showExpandColumn: true,
			expandColumnPosition: 'right',
			expandHeaderColumnRenderer: ({ isAnyExpands }) => {
				if (isAnyExpands) {
					return <b>-</b>;
				}
				return <b>+</b>;
			},
			expandColumnRenderer: ({ expanded }) => {
				if (expanded) {
					return (
						<b>-</b>
					);
				}
				return (
					<b>...</b>
				);
			}
		};
		
		function formatLimitPost(cell, row) {
			return (row.limit_post + '/' + row.quantity);
		}
		
		const columns = [{
			dataField: 'id',
			text: 'ID',
			sort: true,
		}, {
		  text: 'Thao tác',
		  dataField: '-',
		  style: {
			'white-space' : 'nowrap'
		  },
		  formatter: (cell, row) => 
		  	<Fragment>
				{
					this.props.auth.user.type === 1 ?
						<Fragment>
							<button type="button" onClick={() => {this.openModalEditAdmin(row)}} className="btn btn-hero-success text-center p-2 mr-1"><i className="fa fa-fw fa-pen"></i></button>
						</Fragment>
					: ''
				}
				{
					/*row.status !== 3 && row.status !== 0 && row.time_expired > Math.floor(Date.now() / 1000)  ?*/
					row.status !== 3 && row.status !== 0  ?
						<Fragment>
							{row.status === 2 ?
								<button onClick={() => {this.props.reLoadIdFb(row.id)}} type="button" name="re_id_die" className="btn btn-hero-warning text-center p-2 mr-1">
									<i className="far fa-check-circle"></i>
								</button>
							: ''}
							<button type="button" name="giahan" className="btn btn-hero-primary text-center p-2 mr-1" onClick={() => {this.openModalGiahan(row)}}><i className="fa fa-fw fa-clock"></i></button>
							<button type="button" name="huy_order" className="btn btn-hero-danger text-center p-2 mr-1" onClick={() => {this.huyOrder(row.id, row.orders_id)}}><i className="fa fa-fw fa-trash"></i></button>
						</Fragment>
					: ''
				}
	    	</Fragment>
		}, {
			dataField: 'instagram_username',
			text: 'Link',
			sort: true,
			formatter: (cell, row) => 
				<Fragment>
					<a href={"https://www.instagram.com/"+cell} target="_blank" rel="noopener noreferrer">{cell}</a>
				</Fragment>
		}, {
			dataField: 'type',
			text: 'Loại',
			sort: true,
			formatter: formatGoi,
			filterValue: formatGoi
		}, {
			dataField: 'min_like',
			text: 'Min Like',
			sort: true,
		}, {
			dataField: 'max_like',
			text: 'Max Like',
			sort: true,
		}, {
			dataField: 'quantity',
			text: 'Số lượng bài viết',
			sort: true,
		}, {
			dataField: 'limit_post',
			text: 'Post/Ngày',
			sort: true,
			formatter: formatLimitPost,
			filterValue: formatLimitPost
		}, {
			dataField: 'days',
			text: 'Số ngày',
			sort: true,
		}, {
			dataField: 'price_per',
			text: 'Giá',
			sort: true,
		}, {
			dataField: 'created_at',
			text: 'Khởi tạo',
			sort: true,
			formatter: calBwDateToNow,
			filterValue: calBwDateToNow
		}, {
			dataField: 'time_expired',
			text: 'Hết hạn',
			sort: true,
			formatter: formatCheckTimeUse,
			filterValue: formatCheckTimeUse
		}, {
			dataField: 'username',
			text: 'Username',
			sort: true,
		    classes: 'notranslate',
		}, {
			dataField: 'status',
			text: 'Trạng thái',
			sort: true,
			formatter: formatTrangThai,
			filterValue: filterValueTrangThai
		}];
		return (
			<Fragment>
				<ToolkitProvider keyField="id" data={ listtientrinh } columns={ columns } search >
					{
						props => (
							<div>
								<SearchBar { ...props.searchProps } />
								<div className="row mb-4">
									<div className="col-md-12">
										{ this.props.auth.user.type === 1 ?
											<Fragment>
												<button onClick={() => {this.getListByView(1)}} type="button" className="btn btn-primary mr-1">Xem tất cả</button>
												<button onClick={() => {this.getListByView(0)}} type="button" className="btn btn-primary mr-1">Xem riêng</button>
											</Fragment>
										: ''}
										{/* <select className="form-control custom-select select-light col-md-3 custom-limit_pagi" id="limit_pagi" name="limit_pagi" onChange={this.onChangeLimit} value={limit_pagi} >
											<option value="0">Số lịch sử hiển thị (0-500)</option>
											<option value="1">500-1000</option>
											<option value="2">1000-1500</option>
											<option value="3">1500-2000</option>
											<option value="4">2000-2500</option>
											<option value="5">2500-3000</option>
											<option value="7">3000-3500</option>
											<option value="8">3500-4000</option>
											<option value="9">4500-5000</option>
										</select> */}
										<button onClick={(e) => {this.getListByLimit(this.state.page, "prev")}} name="page" value={this.state.page} type="button" className="btn btn-primary mr-1">{"<"}</button>
										<button onClick={(e) => {this.getListByLimit(this.state.page, "next")}} name="page" value={this.state.page} type="button" className="btn btn-primary mr-1">{">"}</button>
										<button onClick={(e) => {this.getListBySearch(e)}} name="view" value="0" type="button" className="btn btn-primary mr-1">Tìm kiếm</button>
										<input  onChange={this.onChange} value={this.state.obj_search}  name="obj_search" type="text" className="form-control mr-1" style={{"width": "100px", "display": "inline-block"}}/>
									</div>
								</div>
								<BootstrapTable { ...props.baseProps } expandRow={ expandRow } pagination={ paginationFactory() } wrapperClasses="table-responsive" striped hover condensed />
							</div>
						)
					}
				</ToolkitProvider>
				{(this.props.auth.user.type === 1 && isOpenModalEditAdmin) ? <ModalEditAdmin openModal={this.openModalEditAdmin} isOpenModal={isOpenModalEditAdmin} dataItemSelect={dataItemSelect} /> : '' }
				{isOpenModalGiahan ? <Modalgiahan openModal={this.openModalGiahan} isOpenModal={isOpenModalGiahan} dataItemSelect={dataItemSelect} /> : '' }
			</Fragment>
		)
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	instagramviplike: state.instagramviplike,
});
const mapDispatchToProps = { loadIVL, upHuyOrder, updateLimitBSR, updateTypeIVL, reLoadIdFb }

export default connect(mapStateToProps, mapDispatchToProps)(nhatky);