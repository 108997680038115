import axios from 'axios';
import {
	LOAD_TWL,
	ADD_TWL,
	UPDATE_TWL,
	UPDATE_TWL_TAB,
	LOAD_PRICE_TWL,
	LIMIT_PAGI_TWL,
	TYPE_TWL,
	LOADING_TWL,
	LOADING_TWL_2,
	LOAD_NHATKYHOATDONG_TWL
} from "../actions/types";
import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Swal from 'sweetalert2';
import { loadUser } from "./authActions";

export const loadTWL = (type = 0, page = 1, obj_search = '') => (dispatch, getState) => {
	dispatch({
		type: LOADING_TWL,
		payload: true
	});
	axios.get('/api/twitter?type_api=twitterlike&type='+type+'&page='+page+'&obj_search='+obj_search, tokenConfig(getState))
		.then(res => {
			dispatch({
				type: LOADING_TWL,
				payload: false
			});
			if (res.data.status === 200) {
				dispatch({
					type: LOAD_TWL,
					payload: res.data.data
				});
			}
		});
};

export const addTWL = (body) => (dispatch, getState) => {
	dispatch({
		type: LOADING_TWL,
		payload: true
	});
	axios.post('/api/twitter/create', body, tokenConfig(getState))
		.then(res => {
			dispatch({
				type: LOADING_TWL,
				payload: false
			});
			dispatch(loadUser());
			if (res.data.status === 200) {
				Swal.fire({
					icon: 'success',
					html: 'Tạo tiến trình thành công'
				});
			} else {
				var message = '';
				if (res.data.message) {
					message = res.data.message;
				} else {
					message = res.data.error;
				}
				Swal.fire({
					icon: 'error',
					title: 'Lỗi',
					text: message,
				});
			}
		});
};

export const deleteTWL = (id) => (dispatch, getState) => {
	let body = {
		id: id,
		'type_api': 'twitterlike'
	};
	dispatch({
		type: LOADING_TWL,
		payload: true
	});
	axios.post('/api/twitter/delete', body, tokenConfig(getState))
		.then(res => {
			dispatch({
				type: LOADING_TWL,
				payload: false
			});
			dispatch(loadTWL());
			if (res.data.status === 200) {
				Swal.fire({
					icon: 'success',
					html: res.data.message
				});
			} else {
				var message = '';
				if (res.data.error) {
					if (res.data.error.object_id) {
						message = res.data.error.object_id[0];
					} else if (res.data.error.quantity) {
						message = res.data.error.quantity[0];
					} else {
						message = res.data.message;
					}
				} else {
					message = res.data.message;
				}
				Swal.fire({
					icon: 'error',
					title: 'Lỗi',
					text: message,
				});
			}
		});
};

export const UpdateEdit = (status, count_success, dataid, closeM) => (dispatch, getState) => {
	let body = {
		id: dataid,
		status: status,
		count_success: count_success,
		'type_api': 'twitterlike'
	};
	dispatch({
		type: LOADING_TWL,
		payload: true
	});
    axios.post('/api/twitter/updateedit', body, tokenConfig(getState))
		.then(res => {
			dispatch({
				type: LOADING_TWL,
				payload: false
			});
			closeM();
			if (res.data.status === 200) {
				dispatch(loadTWL());
				Swal.fire({
					icon: 'success',
					html: 'Cập nhật thành công'
				});
			}
			if (res.data.status === 400) {
				var message = '';
				if (res.data.error) {
					if (res.data.error.object_id) {
						message = res.data.error.object_id[0];
					} else if (res.data.error.quantity) {
						message = res.data.error.quantity[0];
					}
				} else {
					message = res.data.message;
				}
				Swal.fire({
					icon: 'error',
					title: 'Lỗi',
					text: message,
				});
			}
		});
};

export const updateTWL = (body) => (dispatch) => {
	dispatch({
		type: UPDATE_TWL,
		payload: body
	})
};

export const updateLimitTWL = (body) => (dispatch) => {
	dispatch({
		type: LIMIT_PAGI_TWL,
		payload: body
	})
};

export const updateTypeTWL = (body) => (dispatch) => {
	dispatch({
		type: TYPE_TWL,
		payload: body
	})
};

export const changeTab = (body) => (dispatch, getState) => {
	dispatch({
		type: UPDATE_TWL_TAB,
		payload: body
	});
};

export const getConfigMoDong = (type = '') => (dispatch, getState) => {
	dispatch({
		type: LOADING_TWL,
		payload: true
	});

	axios.get('/api/twitter/getconfig?type_api=twitterbuff_like', tokenConfig(getState))
	.then(res => {
		dispatch({
		type: LOADING_TWL,
			payload: false
		});
		if (res.data.status === 200) {
			dispatch({
				type: 'CONFIG_MO_DONG_TWL',
				payload: res.data
			});
		}
	});
}

export const tokenConfig = getState => {
	// Get token from localhost
	const token = getState().auth.token;
	// Headers
	const config = {
		headers: {
			"Content-type": "application/json"
		}
	};
	
	// If token, add to headers
	if (token) {
		config.headers['ht-token'] = token;
	}
	
	return config;
};