import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import {
    Modal,
    ModalBody,
    ModalFooter
} from 'reactstrap';
import { saveAddBankPayment } from '../../../../actions/adminPageAction';

class ModalAddBankPayment extends Component {
    state = {
        formdata: {
            namebank: '',
            name_account: '',
            id_account: '',
            branch: ''
        }
    }

    onChangeForm = (e) => {
        this.setState({
            formdata: {
                ...this.state.formdata,
                [e.target.name]: e.target.value
            }
        });
    }

    render() {
        const {isOpenModal} = this.props;
        const {formdata} = this.state;
        return (
            <Fragment>
                <Modal centered={true} size="lg" toggle={(e) => {this.props.actionOpenModal()}} isOpen={isOpenModal}>
                    <div className="modal-header">
                        <p className="mb-0 text-dark">Thêm bank</p>
                        <button onClick={(e) => {this.props.actionOpenModal()}} type="button" className="close">×</button>
                    </div>
                    <ModalBody>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label className="mr-4">Tên ngân hàng:</label>
                                    <input onChange={(e) => {this.onChangeForm(e)}} value={formdata.namebank} type="text" name="namebank" className="form-control" />
                                </div>
                                <div className="form-group">
                                    <label className="mr-4">Tên tài khoản:</label>
                                    <input onChange={(e) => {this.onChangeForm(e)}} value={formdata.name_account} type="text" name="name_account" className="form-control" />
                                </div>
                                <div className="form-group">
                                    <label className="mr-4">Mã tài khoản:</label>
                                    <input onChange={(e) => {this.onChangeForm(e)}} value={formdata.id_account} type="number" name="id_account" className="form-control" />
                                </div>
                                <div className="form-group">
                                    <label className="mr-4">Chi nhánh:</label>
                                    <input onChange={(e) => {this.onChangeForm(e)}} value={formdata.branch} type="text" name="branch" className="form-control" />
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button type="button" className="btn btn-primary" onClick={() => {this.props.saveAddBankPayment(formdata,this.props.actionOpenModal)}} >Lưu</button>
                        <button type="button" className="btn btn-danger" onClick={(e) => {this.props.actionOpenModal()}} >Huỷ</button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        );
    }

}

const mapStateToProps = state => ({
    adminpage: state.adminpage
});

export default connect(mapStateToProps, {saveAddBankPayment}) (ModalAddBankPayment);
