import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import {
    Modal,
    ModalBody,
    ModalFooter
} from 'reactstrap';
import { updateSticker, loadSticker, clearListSticker } from '../../../actions/BufflikecommentshareActions';
import {
  BrowserRouter as Router,
  Link
} from "react-router-dom";
import store from '../../../store';

class Modal_edit extends Component {
    state = {
        id: this.props.dataid,
		sticker: ""
    }
	componentDidMount() {
		this.props.loadSticker(this.state.id);
	}

    onChange = (e) => {
 		var { formData } = this.props.buffsub;
		if (e.target.name === "sticker") {
			var type = formData.sticker;
			if (type.indexOf(e.target.value) === -1) {
				type.push(e.target.value);
			} else {
				var position = type.indexOf(e.target.value);
				type.splice(position, 1);
			}
			var new_formData = {
				...formData,
				"sticker": type
			};
			store.dispatch({
				type: 'CHANGE_DATA_FORM_YTB_LCS_COMMENT',
				payload: new_formData
			});
			
			var type = formData.sticker_pack[this.state.id];
			if (typeof formData.sticker_pack[this.state.id] === 'undefined') {
				formData.sticker_pack[this.state.id] = [];
				formData.sticker_pack[this.state.id].push(e.target.value);
			} else {
				if (type.indexOf(e.target.value) === -1) {
					type.push(e.target.value);
				} else {
					var position = type.indexOf(e.target.value);
					type.splice(position, 1);
				}
				formData.sticker_pack[this.state.id] = type;
				if (formData.sticker_pack[this.state.id].length <= 0) {
					delete formData.sticker_pack[this.state.id];
				}
			}
			this.props.updateSticker(formData.sticker_pack);
		}
    }

    render() {
        const {isOpenModal} = this.props;
        const {id, sticker} = this.state;
		const { loadingblock, list_sticker } = this.props.buffsub;
		const ar_sticker = this.props.buffsub.formData.sticker;
		
		var sticker_modal = '';
		var that = this;
		if (list_sticker.length > 0) {
			sticker_modal = list_sticker.map(function(v, i) {
				var sticker_label = (
					<label className="btn text-center p-2 mr-1 col-lg-2" key={i} style={{"border": (ar_sticker.indexOf(v.id_sticker_child) > -1) ? "3px solid green" : '0px solid green'}}>
						<input type="checkbox" name="sticker" onChange={that.onChange} value={v.id_sticker_child} checked="" style={{"display": "none"}} />
						<img src={process.env.PUBLIC_URL + '/images/stickerfb/sticker/'+v.id_sticker_package+'/'+v.id_sticker_child+'.png'} width="64" height="64" /><br/>{v.name}
					</label>
				);
				return sticker_label;
			})
		}
        return (
            <Fragment>
                <Modal centered={true} size="lg" toggle={(e) => {this.props.clearListSticker(); this.props.openModal()}} isOpen={isOpenModal} className={(loadingblock? ' block block-mode-loading' : '')} style={{"backgroundColor": "inherit", "boxShadow": "none"}}>
                    <div className="modal-header">
                        <p className="mb-0 text-dark">List Sticker</p>
                        <button onClick={(e) => {this.props.clearListSticker(); this.props.openModal()}} type="button" className="close">×</button>
                    </div>
                    <ModalBody style={{opacity: loadingblock? '.05':'1'}}>
                        <div className="row">
                            <div className="col-md-12">
								{sticker_modal}
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter style={{opacity: loadingblock? '.05':'1'}}>
                        <button onClick={(e) => {this.props.clearListSticker(); this.props.openModal()}} type="button" className="btn btn-danger">Đóng</button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
	buffsub: state.likecommentsharecomment,
});

export default connect(mapStateToProps, { updateSticker, loadSticker, clearListSticker }) (Modal_edit);
