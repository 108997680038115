import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { nhapTokenDaiLy } from "../actions/dailyActions";
import {
  BrowserRouter as Router,
  Link
} from "react-router-dom";

class Daily extends Component {
	state = {
		token_daily: "",
	};
	onChange = e => {
		this.setState({ [e.target.name]: e.target.value });
	};
	onSubmit = e => {
		e.preventDefault();
		const { token_daily } = this.state;
		this.props.nhapTokenDaiLy(token_daily);
	};
	render() {
		let loading = this.props.auth.loading;
    	const domainSite = window.location.hostname;
		return (
			<Fragment>
				<main id="main-container" style={{"height": "100%"}}>
					<div className="bg-image" style={{"height": "100%"}}>
						<div className="row no-gutters justify-content-center">
							<div className="col-sm-8 col-md-6 col-xl-4 align-items-center p-2 px-sm-0" style={{ "top": "255px" }}>
								<div className="block block-transparent block-rounded w-100 mb-0 overflow-hidden">
									<div className="justify-content-center row">
										<div className="col-lg-9-custom-login col-lg-9">
											<div className="card">
												<div className="card-header card-header-login pt-4 pb-4 text-center bg-primary bt-bg-navbar">
													<a className="link-fx text-white font-w700 font-size-h3" href="index.html" >
													  <span className="text-white">{domainSite.slice(0, domainSite.lastIndexOf('.'))}</span>
												  	  <span className="text-white">{domainSite.indexOf('.') > -1 ? domainSite.slice(domainSite.lastIndexOf('.')) : ''}</span>
													</a>
												</div>
												<div className="position-relative card-body card-body-login bg-white" style={{ "padding": "36px" }}>
													<div className="text-center w-75 m-auto">
														<h4 className="text-dark-50 text-center mt-0 font-weight-bold" style={{ "color": "#6c757d", "marginBottom": "10px", "fontSize": "1.125rem" }}>Nhập token Đại lý</h4>
													</div>
													{loading ? (
														<div className="preloader">
															<div className="spinner-grow spinner-grow-sm text-primary" role="status" style={{ "marginTop": "20%" }}>
															  <span className="sr-only"></span>
															</div>
															<div className="spinner-grow spinner-grow-sm text-danger" role="status" style={{ "marginTop": "20%" }}>
															  <span className="sr-only">.</span>
															</div>
															<div className="spinner-grow spinner-grow-sm text-warning" role="status" style={{ "marginTop": "20%" }}>
															  <span className="sr-only"></span>
															</div>
														</div>
													):('')}
													<form className="js-validation-signup" onSubmit={this.onSubmit} method="POST" >
														<div className="form-group">
															<label htmlFor="token_daily" className="">Token Đại lý</label>
															<input name="token_daily" placeholder="" required="" id="token_daily" type="text" className="is-untouched is-pristine av-valid form-control" onChange={this.onChange} />
														</div>
														<div className="form-group">
															<button type="submit" className="btn btn-success" style={{ "boxShadow": "0px 2px 6px 0px rgba(10, 207, 151, 0.5)", "color": "#fff", "backgroundColor": "#0acf97", "borderColor": "#0acf97" }}>
																<span>
																	Nhập
																</span>
															</button>
														</div>
													</form>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</main>
			</Fragment>
		);
	}
}

Daily.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
});
export default connect(mapStateToProps, { nhapTokenDaiLy })(Daily);
