import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { loadBSLV, updateLimitBSLV, updateTypeBSLV, updateTangGiaThem, upTangGiaThem, upHuyOrder, reLoadIdFb } from '../../../actions/buffsublikev2Actions';
import iconLikeFb from '../../../iconfb/like.svg';
import iconCareFb from '../../../iconfb/care.svg';
import iconHahaFb from '../../../iconfb/haha.svg';
import iconAngryFb from '../../../iconfb/angry.svg';
import iconLoveFb from '../../../iconfb/love.svg';
import iconSadFb from '../../../iconfb/sad.svg';
import iconWowFb from '../../../iconfb/wow.svg';
import parse from 'html-react-parser';
import {
	Modal,
	ModalBody,
	ModalFooter
} from 'reactstrap';
import Swal from 'sweetalert2';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import ModalEditAdmin from './modalEditAdmin';
import Moment from 'react-moment';
import { RiExchangeDollarLine } from "react-icons/ri";
const { SearchBar } = Search;

class nhatky extends Component {
	static propTypes =  {
		loadBSLV: PropTypes.func.isRequired,
		updateTangGiaThem: PropTypes.func.isRequired,
		upTangGiaThem: PropTypes.func.isRequired,
		upHuyOrder: PropTypes.func.isRequired,
		updateLimitBSLV: PropTypes.func.isRequired,
		updateTypeBSLV: PropTypes.func.isRequired,
		reLoadIdFb: PropTypes.func.isRequired,
	};
	
	state = {
		isModalBrowseGroupsOpen: false,
		isOpenModalEditAdmin: false,
		dataItemSelect: {},
		page: 1,
		obj_search: "",
	}
	
	componentDidMount() {
		this.props.loadBSLV();
	}
		
	tangGiaThem = (id, orders_id, per_price, quantity, loai) => {
		this.setState({ isModalBrowseGroupsOpen: true });
		const { tanggiathem } = this.props.buffsublikev2;
		let new_tanggiathem = {
			...tanggiathem,
			"id": id,
			"orders_id": orders_id,
			"per_price": per_price,
			"quantity": quantity,
			"loai": loai,
		};
		this.props.updateTangGiaThem(new_tanggiathem);
	}
	
	openModalTangThemGia = (status, e) => {
		e.preventDefault();
		this.setState({ isModalBrowseGroupsOpen: status });
	}
	
	onChange = (e) => {
		this.setState({
			"obj_search": e.target.value,
			"page": 1
		});
	}
    getListByLimit = (page, type_button) => {
        var valueChange = parseInt(page);
		if (type_button == "prev") {
			valueChange -= 1;
			if (valueChange <= 0) {
				valueChange = 1;
			}
		}
		if (type_button == "next") {
			valueChange += 1;
		}
		this.setState({
			page: valueChange,
		});
		const { type } = this.props.buffsublikev2;
		this.props.loadBSLV(type, valueChange, this.state.obj_search);
    }
	getListByView = (type) => {
		this.setState({
			"page": 1,
		});
		this.props.updateTypeBSLV(type);
		this.props.loadBSLV(type, 1, this.state.obj_search);
	}
    getListBySearch = (e) => {
		this.setState({
			"page": 1,
		});
		const { type } = this.props.buffsublikev2;
		this.props.loadBSLV(type, 1, this.state.obj_search);
    }
	
	// onChange = e => {
		// const { tanggiathem } = this.props.buffsublikev2;
		// let new_tanggiathem = {
			// ...tanggiathem,
			// [e.target.name]: e.target.value,
		// };
		// this.props.updateTangGiaThem(new_tanggiathem);
	// };
	
	// onChangeLimit = e => {
		// const { type } = this.props.buffsublikev2;
		// this.props.updateLimitBSLV(e.target.value);
		// this.props.loadBSLV(type, e.target.value);
	// };
	
	// onCheckList = (type) => {
		// const { limit_pagi } = this.props.buffsublikev2;
		// this.props.updateTypeBSLV(type);
		// this.props.loadBSLV(type, limit_pagi);
	// }
	
	clickTangGiaThem = () => {
		const { tanggiathem } = this.props.buffsublikev2;
		if (tanggiathem.per_price > tanggiathem.price) {
			Swal.fire({
				icon: 'error',
				title: 'Lỗi',
				text: 'Giá tăng phải cao hơn giá hiện tại!',
			});
			return false;
		}
		let new_tanggiathem = {
			...tanggiathem,
			"type_api": "buffsublikev2",
		};
		this.props.upTangGiaThem(new_tanggiathem);
	}
	
	huyOrder = (id, orders_id, type) => {
		const swalWithBootstrapButtons = Swal.mixin({
			customClass: {
				confirmButton: 'btn btn-success an-margin-btn',
				cancelButton: 'btn btn-danger an-margin-btn'
				},
			buttonsStyling: false
		})

		swalWithBootstrapButtons.fire({
			title: 'HỦY REVIEW ID: '+orders_id+' ?',
			text: "Sau khi hủy bạn sẽ được hoàn lại số lượng chưa tăng sau 1 đến 3 ngày và trừ thêm 5.000 Coin phí để tránh Spam!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Đồng ý',
			cancelButtonText: 'Không',
			reverseButtons: true
		}).then((result) => {
			if (result.value) {
				let body = {
					"id": orders_id,
					"type_api": "buffsublikev2",
					"type": type,
				};
				this.props.upHuyOrder(body);
			} else if (result.dismiss === Swal.DismissReason.cancel) {
				
			}
		})
	}
	
	onKeyPress = (e) => {
		if (!(e.charCode >= 48 && e.charCode <= 57)) {
			e.preventDefault();
		}
	}
	
	openModalEditAdmin = (data = {}) => {
		this.setState({
			...this.state,
			isOpenModalEditAdmin: !this.state.isOpenModalEditAdmin,
			dataItemSelect: data,
		})
	}
	
	render() {
		const { listtientrinh, tanggiathem, limit_pagi } = this.props.buffsublikev2;
		const {isOpenModalEditAdmin, dataItemSelect} = this.state;
		// const { username } = this.props.auth.user;
		// var ar_listtientrinh = [];
		// listtientrinh.forEach(function(v, i) {
			// var tmp = {
				// ...v,
				// "site_username": username
			// };
			// ar_listtientrinh.push(tmp);
		// });
		
		function calBwDateToNow(cell, row) {
			if (Number.isInteger(cell)) {
				let date_now = Math.floor(Date.now() / 1000);
				let calDateDay = (date_now - cell)/86400;
				if (calDateDay > 1) {
					return Math.ceil(calDateDay) + ' ngày trước';
				} else {
					let calDatehour = (date_now - cell)/3600;
					if (calDatehour < 0.6) {
						return Math.ceil(calDatehour*100) + ' phút trước';
					} else {
						return Math.ceil(calDatehour) + ' giờ trước';
					}
				}
			}
		}
		
		function formatGoi(cell, row) {
			if (cell === "sub" && row.goi === 0) {
				return ('SUB - SV1');
			} else if (cell === "sub" && row.goi === 1) {
				return ('SUB - SV2');
			} else if (cell === "sub" && row.goi === 2) {
				return ('SUB - SV3');
			} else if (cell === "sub" && row.goi === 3) {
				return ('SUB - SV VIP');
			} else if (cell === "like_page" && row.goi === 0) {
				return ('LIKE PAGE - SV1');
			} else if (cell === "like_page" && row.goi === 1) {
				return ('LIKE PAGE - SV2');
			} else if (cell === "like_page" && row.goi === 2) {
				return ('LIKE PAGE - SV3');
			} else if (cell === "like_post" && row.goi === 0) {
				return ('LIKE POST - SV1');
			} else if (cell === "like_post" && row.goi === 1) {
				return ('LIKE POST - SV2');
			} else if (cell === "like_post" && row.goi === 2) {
				return ('LIKE POST - SV3');
			} else if (cell === "comment_post" && row.goi === 0) {
				return ('COMMENT POST - SV1');
			} else {
				return ('');
			}
		}
		
		function formatTrangThai(cell, row) {
			var message = '';
			if (cell === 0) {
				return (
					<span className="badge badge-primary"><i className="fas fa-check mr-1"></i>Hoàn thành</span>
				);
			} else if (cell === 1) {
				return (
					<span className="badge badge-success"><i className="fas fa-sync-alt mr-1"></i>Đang chạy</span>
				);
			} else if (cell === 2) {
				return (
					<span className="badge badge-danger"><i className="fas fa-ban mr-1"></i>ID die không thể chạy</span>
				);
			} else if (cell === 3) {
				return (
					<span className="badge badge-warning"><i className="fas fa-times mr-1"></i>Hủy đơn</span>
				);
			} else if (cell === 5) {
				return (
					<span className="badge badge-info"><RiExchangeDollarLine size={12} className="mr-1" />Hoàn tiền</span>
				);
			} else {
				return ('');
			}
		}
		function filterValueTrangThai(cell, row) {
			if (cell === 0) {
				return ('Hoàn thành');
			} else if (cell === 1) {
				return ('Đang chạy');
			} else if (cell === 2) {
				return ('ID die không thể chạy');
			} else if (cell === 3) {
				return ('Hủy đơn');
			} else if (cell === 5) {
				return ('Hoàn tiền');
			} else {
				return ('');
			}
		}
		
		function formatCheckTime(cell, row) {
			let date_now = Math.floor(Date.now() / 1000);
			let calDateDay = (cell - date_now);
			if (calDateDay >= 0) {
				if (calDateDay/86400 < 1) {
					return Math.floor(calDateDay/3600) + ' giờ';
				}
				return (
					<Fragment>
						<Moment diff={date_now*1000} unit="days">{cell*1000}</Moment> ngày
					</Fragment>
				);
			} else {
				return <span className="badge badge-danger">Hết hạn</span>
			}
		}

		function filterValueCheckTime(cell, row) {
			let date_now = Math.floor(Date.now() / 1000);
			let calDateDay = (cell - date_now);
			if (calDateDay >= 0) {
				if (calDateDay/86400 < 1) {
					return Math.floor(calDateDay/3600) + ' giờ';
				}
				var diffDays = Math.floor(calDateDay / (60 * 60 * 24)) + ' ngày';
				return diffDays;
			} else {
				return ('Hết hạn');
			}
		}
		
		function formatNoiDung(cell, row) {
			if (cell != null) {
				return cell.length > 45 ? cell.slice(0, 45 - 1) + "…" : cell;
			}
			return '';
		}
		function filterValueNoiDung(cell, row) {
			return cell;
		}
		
		function formatCamXuc(cell, row) {
			var list_camxuc = '';
			if (cell === "like") {
				list_camxuc += '<label class="icon_feed_fb"><img src='+iconLikeFb+' alt="icon fb" class="img-responsive image-reaction ml-2 image-reaction-danhsach" /></label>';
			}
			if (cell === "care") {
				list_camxuc += '<label class="icon_feed_fb"><img src='+iconCareFb+' alt="icon fb" class="img-responsive image-reaction ml-2 image-reaction-danhsach" /></label>';
			}
			if (cell === "love") {
				list_camxuc += '<label class="icon_feed_fb"><img src='+iconLoveFb+' alt="icon fb" class="img-responsive image-reaction ml-2 image-reaction-danhsach" /></label>';
			}
			if (cell === "haha") {
				list_camxuc += '<label class="icon_feed_fb"><img src='+iconHahaFb+' alt="icon fb" class="img-responsive image-reaction ml-2 image-reaction-danhsach" /></label>';
			}
			if (cell === "wow") {
				list_camxuc += '<label class="icon_feed_fb"><img src='+iconWowFb+' alt="icon fb" class="img-responsive image-reaction ml-2 image-reaction-danhsach" /></label>';
			}
			if (cell === "sad") {
				list_camxuc += '<label class="icon_feed_fb"><img src='+iconSadFb+' alt="icon fb" class="img-responsive image-reaction ml-2 image-reaction-danhsach" /></label>';
			}
			if (cell === "angry") {
				list_camxuc += '<label class="icon_feed_fb"><img src='+iconAngryFb+' alt="icon fb" class="img-responsive image-reaction ml-2 image-reaction-danhsach" /></label>';
			}
			return parse(list_camxuc);
		}
		
		const expandRow = {
			renderer: (row) => (
				<div className="offset-lg-4 col-lg-8 row">
					{/* <div className={"col-lg-4 " + ((row.status !== 3 && row.status !== 0) || this.props.auth.user.type === 1 ? '' : 'offset-lg-4')}>
					</div> */}
					<div className="col-lg-4">
						<div className="form-group">
							<label htmlFor="example-text-input">CMT</label>
							<textarea className="form-control" id="ndcmt" name="ndcmt" placeholder="" rows="2" defaultValue={row.ndcmt}></textarea>
						</div>
					</div>
					<div className="col-lg-4">
						<div className="form-group">
							<label htmlFor="example-text-input">Ghi chú</label>
							<textarea className="form-control" id="gc" name="gc" placeholder="" rows="2" defaultValue={row.notes}></textarea>
						</div>
					</div>
					{/* <div className="col-lg-4">
						<div className="form-group">
							{
								row.status !== 3 && row.status !== 0 && row.time_expired_format > Math.floor(Date.now() / 1000) ?
									<Fragment>
										{
											(row.loai === 'sub' || row.loai === 'like_page') && row.goi == '0' ?
													<button type="button" name="huy_order" className="btn btn-block btn-sm btn-rounded btn-hero-light" onClick={() => {this.huyOrder(row.id, row.id, 'sub_like_page_sv1')}}>Hủy Order</button>
												:
													row.loai === 'like_post' && row.goi == '0' ? 
														<button type="button" name="huy_order" className="btn btn-block btn-sm btn-rounded btn-hero-light" onClick={() => {this.huyOrder(row.id, row.id, 'like_post')}}>Hủy Order</button>
													:
														row.loai === 'like_post' && row.goi == '1' ? 
															<button type="button" name="huy_order" className="btn btn-block btn-sm btn-rounded btn-hero-light" onClick={() => {this.huyOrder(row.id, row.id, 'like_post_v2')}}>Hủy Order</button>
														:
															row.loai === 'comment_post' && row.goi == '0' ? 
																<button type="button" name="huy_order" className="btn btn-block btn-sm btn-rounded btn-hero-light" onClick={() => {this.huyOrder(row.id, row.id, 'comment_post')}}>Hủy Order</button>
															:
																(row.loai === 'sub' || row.loai === 'like_page') && row.goi == '2' ? 
																	<button type="button" name="huy_order" className="btn btn-block btn-sm btn-rounded btn-hero-light" onClick={() => {this.huyOrder(row.id, row.id, 'sub_like_page_sv3')}}>Hủy Order</button>
																:
																	(row.loai === 'sub') && row.goi == '3' ? 
																		<button type="button" name="huy_order" className="btn btn-block btn-sm btn-rounded btn-hero-light" onClick={() => {this.huyOrder(row.id, row.id, 'sub_sv_vip')}}>Hủy Order</button>
																	:
																		<button type="button" name="huy_order" className="btn btn-block btn-sm btn-rounded btn-hero-light" onClick={() => {this.huyOrder(row.id, row.orders_id, '')}}>Hủy Order</button>
										}
										{row.status === 2 ?
											<button onClick={() => {this.props.reLoadIdFb(row.orders_id, row.id)}} type="button" name="re_id_die" className="btn btn-block btn-sm btn-rounded btn-hero-light text-warning">
												Check id
											</button>
										: ''}
									</Fragment>
								: ''
							}
							{
								this.props.auth.user.type === 1 ?
									<Fragment>
										<button type="button" onClick={() => {this.openModalEditAdmin(row)}} className="btn btn-block btn-sm btn-rounded btn-hero-success">Cài đặt admin</button>
									</Fragment>
								: ''
							}
						</div>
					</div> */}
				</div>
			),
			showExpandColumn: true,
			expandColumnPosition: 'right',
			expandHeaderColumnRenderer: ({ isAnyExpands }) => {
				if (isAnyExpands) {
					return <b>-</b>;
				}
				return <b>+</b>;
			},
			expandColumnRenderer: ({ expanded }) => {
				if (expanded) {
					return (
						<b>-</b>
					);
				}
				return (
					<b>...</b>
				);
			}
		};
		
		const columns = [{
			dataField: 'id',
			text: 'ID',
			sort: true,
		}, {
		  text: 'Thao tác',
		  dataField: '-',
		  style: {
			'white-space' : 'nowrap'
		  },
		  formatter: (cell, row) => 
		  	<Fragment>
				{
					this.props.auth.user.type === 1 ?
						<Fragment>
							<button type="button" onClick={() => {this.openModalEditAdmin(row)}} className="btn btn-hero-success text-center p-2 mr-1"><i className="fa fa-fw fa-pen"></i></button>
						</Fragment>
					: ''
				}
				{
					row.status !== 3 && row.status !== 0 && row.time_expired_format > Math.floor(Date.now() / 1000) ?
						<Fragment>
							{row.status === 2 ?
								<button onClick={() => {this.props.reLoadIdFb(0, row.id)}} type="button" name="re_id_die" className="btn btn-hero-warning text-center p-2 mr-1">
									<i className="far fa-check-circle"></i>
								</button>
							: ''}
							{
								(row.loai === 'sub' || row.loai === 'like_page') && row.goi == '0' ?
										<button type="button" name="huy_order" className="btn btn-hero-danger text-center p-2 mr-1" onClick={() => {this.huyOrder(row.id, row.id, 'sub_like_page_sv1')}}><i className="fa fa-fw fa-trash"></i></button>
									:
										row.loai === 'like_post' && row.goi == '0' ? 
											<button type="button" name="huy_order" className="btn btn-hero-danger text-center p-2 mr-1" onClick={() => {this.huyOrder(row.id, row.id, 'like_post')}}><i className="fa fa-fw fa-trash"></i></button>
										:
											row.loai === 'like_post' && row.goi == '1' ? 
												<button type="button" name="huy_order" className="btn btn-hero-danger text-center p-2 mr-1" onClick={() => {this.huyOrder(row.id, row.id, 'like_post_v2')}}><i className="fa fa-fw fa-trash"></i></button>
											:
												row.loai === 'like_post' && row.goi == '2' ? 
													<button type="button" name="huy_order" className="btn btn-hero-danger text-center p-2 mr-1" onClick={() => {this.huyOrder(row.id, row.id, 'like_post_v3')}}><i className="fa fa-fw fa-trash"></i></button>
												:
													row.loai === 'comment_post' && row.goi == '0' ? 
														<button type="button" name="huy_order" className="btn btn-hero-danger text-center p-2 mr-1" onClick={() => {this.huyOrder(row.id, row.id, 'comment_post')}}><i className="fa fa-fw fa-trash"></i></button>
													:
														(row.loai === 'sub' || row.loai === 'like_page') && row.goi == '2' ? 
															<button type="button" name="huy_order" className="btn btn-hero-danger text-center p-2 mr-1" onClick={() => {this.huyOrder(row.id, row.id, 'sub_like_page_sv3')}}><i className="fa fa-fw fa-trash"></i></button>
														:
															(row.loai === 'sub') && row.goi == '3' ? 
																<button type="button" name="huy_order" className="btn btn-hero-danger text-center p-2 mr-1" onClick={() => {this.huyOrder(row.id, row.id, 'sub_sv_vip')}}><i className="fa fa-fw fa-trash"></i></button>
															:
																<button type="button" name="huy_order" className="btn btn-hero-danger text-center p-2 mr-1" onClick={() => {this.huyOrder(row.id, row.id, '')}}><i className="fa fa-fw fa-trash"></i></button>
							}
						</Fragment>
					: ''
				}
	    	</Fragment>
		}, {
			dataField: 'object_id',
			text: 'ID Seeding',
			sort: true,
			formatter: (cell, row) => 
				<Fragment>
					<a href={"https://facebook.com/"+cell} target="_blank" rel="noopener noreferrer">{cell}</a>
				</Fragment>
		}, {
			dataField: 'loai',
			text: 'Loại',
			sort: true,
			formatter: formatGoi,
			filterValue: formatGoi
		}, {
			dataField: 'camxuc',
			text: 'React',
			sort: true,
			formatter: formatCamXuc,
		}, {
			dataField: 'ndcmt',
			text: 'CMT',
			sort: true,
			formatter: formatNoiDung,
			filterValue: filterValueNoiDung
		}, {
			dataField: 'start_like',
			text: 'Start',
			sort: true,
		}, {
			dataField: 'quantity',
			text: 'Số lượng',
			sort: true,
		}, {
			dataField: 'per_price',
			text: 'Giá',
			sort: true,
		}, {
			dataField: 'prices',
			text: 'Tổng tiền',
			sort: true,
		}, {
			dataField: 'count_success',
			text: 'Đã chạy',
			sort: true,
		}, {
			dataField: 'created_at',
			text: 'Khởi tạo',
			sort: true,
			formatter: calBwDateToNow,
			filterValue: calBwDateToNow
		}, {
			dataField: 'time_expired_format',
			text: 'Hết hạn',
			sort: true,
			formatter: formatCheckTime,
			filterValue: filterValueCheckTime
		}, {
			dataField: 'username',
			text: 'Username',
			sort: true,
		    classes: 'notranslate',
		}, {
			dataField: 'status',
			text: 'Trạng thái',
			sort: true,
			formatter: formatTrangThai,
			filterValue: filterValueTrangThai
		}];
		return (
			<Fragment>
				<ToolkitProvider keyField="id" data={ listtientrinh } columns={ columns } search >
					{
						props => (
							<div>
								<SearchBar { ...props.searchProps } />
								<div className="row mb-4">
									<div className="col-md-12">
										{ this.props.auth.user.type === 1 ?
											<Fragment>
												<button onClick={() => {this.getListByView(1)}} type="button" className="btn btn-primary mr-1">Xem tất cả</button>
												<button onClick={() => {this.getListByView(0)}} type="button" className="btn btn-primary mr-1">Xem riêng</button>
											</Fragment>
										: ''}
										{/* <select className="form-control custom-select select-light col-md-3 custom-limit_pagi" id="limit_pagi" name="limit_pagi" onChange={this.onChangeLimit} value={limit_pagi} >
											<option value="0">Số lịch sử hiển thị (0-500)</option>
											<option value="1">500-1000</option>
											<option value="2">1000-1500</option>
											<option value="3">1500-2000</option>
											<option value="4">2000-2500</option>
											<option value="5">2500-3000</option>
											<option value="7">3000-3500</option>
											<option value="8">3500-4000</option>
											<option value="9">4500-5000</option>
										</select> */}
										<button onClick={(e) => {this.getListByLimit(this.state.page, "prev")}} name="page" value={this.state.page} type="button" className="btn btn-primary mr-1">{"<"}</button>
										<button onClick={(e) => {this.getListByLimit(this.state.page, "next")}} name="page" value={this.state.page} type="button" className="btn btn-primary mr-1">{">"}</button>
										<button onClick={(e) => {this.getListBySearch(e)}} name="view" value="0" type="button" className="btn btn-primary mr-1">Tìm kiếm</button>
										<input  onChange={this.onChange} value={this.state.obj_search}  name="obj_search" type="text" className="form-control mr-1" style={{"width": "100px", "display": "inline-block"}}/>
									</div>
								</div>
								<BootstrapTable { ...props.baseProps } expandRow={ expandRow } pagination={ paginationFactory() } wrapperClasses="table-responsive" striped hover condensed />
							</div>
						)
					}
				</ToolkitProvider>
				<div>
					{/*<BootstrapTable keyField='id' data={ ar_listtientrinh } columns={ columns } expandRow={ expandRow } pagination={ paginationFactory() } wrapperClasses="table-responsive" striped hover condensed />*/}
				</div>
				<Modal className="bt-customWidth-dtable" toggle={(e) => {this.openModalTangThemGia(!this.state.isModalBrowseGroupsOpen, e)}} isOpen={this.state.isModalBrowseGroupsOpen}>
					<div className="modal-header">
						<p className="mb-0 text-dark">Tăng giá Job ID { tanggiathem.lhibv }</p>
						<button onClick={(e) => {this.openModalTangThemGia(false, e)}} type="button" className="close">×</button>
					</div>
					<ModalBody>
						<h6>Giá cũ là: <span className="font-bold">{tanggiathem.per_price}</span> Coin. Bạn muốn tăng lên ?</h6>
						<div className="col-md-6 px-0">
							<input type="number" placeholder="Nhập giá cần tăng" className="form-control form-control-line mt-2" name="price" defaultValue={tanggiathem.price} onChange={this.onChange} onKeyPress={this.onKeyPress} />
						</div>
						{ tanggiathem.price > tanggiathem.per_price
						?
							<div>
							    <h6 className="mt-3">
							    	Bạn muốn tăng giá từ 
							    	<span className="font-bold"> {tanggiathem.per_price}</span> lên <span className="font-bold">{tanggiathem.price}</span> Coin ?
						    	</h6>
							    <h6 className="mt-3">
							    	Phí tăng giá thành công: 
							    	<span className="font-bold"> 5,000</span> Coin
						    	</h6>
							    <h6 className="mt-3">
							    	Tổng phí tăng giá cho <span className="font-bold">{tanggiathem.quantity}
							    	<span className="text-uppercase text-danger"> {tanggiathem.loai === 'sub' ? 'sub' : 'like page'} </span></span>
							    	là: <span className="font-bold text-info">{5000 + (tanggiathem.price - tanggiathem.per_price)*tanggiathem.quantity}</span> Coin
						    	</h6>
							</div>
						: ''
						}
					</ModalBody>
					<ModalFooter>
						<button type="button" className="btn btn-danger" onClick={(e) => {this.openModalTangThemGia(false, e)}} >Huỷ</button>
						<button type="button" className="btn btn-primary" onClick={this.clickTangGiaThem}>Đồng ý</button>
					</ModalFooter>
				</Modal>
				{(this.props.auth.user.type === 1 && isOpenModalEditAdmin) ? <ModalEditAdmin openModal={this.openModalEditAdmin} isOpenModal={isOpenModalEditAdmin} dataItemSelect={dataItemSelect} /> : '' }
			</Fragment>
		)
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	buffsublikev2: state.buffsublikev2,
});
const mapDispatchToProps = { loadBSLV, updateTangGiaThem, upTangGiaThem, upHuyOrder, updateLimitBSLV, updateTypeBSLV, reLoadIdFb }

export default connect(mapStateToProps, mapDispatchToProps)(nhatky);