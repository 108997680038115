import React, { Component, Fragment } from 'react';
import {
  BrowserRouter as Router,
  // Switch,
  // Route,
  Link
} from "react-router-dom";
import {
	// Button,
	// Modal,
	// ModalHeader,
	// ModalBody,
	// Form,
	// FormGroup,
	// Label,
	// Input,
	// NavLink,
	Alert
} from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { register } from '../../actions/authActions';
import { clearErrors } from '../../actions/errorActions';
import main_features_lock from '../../iconlanding/3/main_features_lock.svg';
import main_features_person from '../../iconlanding/3/main_features_person.svg';
import bg_full from '../../iconlanding/3/bg_full.svg';
import vEdmaFB from '../../iconlanding/3/vEdmaFB.png';
import MiroJ6t from '../../iconlanding/3/MiroJ6t.png';
import bannerheader from '../../iconlanding/3/bannerheader.png';
import parse from 'html-react-parser';

class RegisterModal extends Component {
	state = {
		// modal: false,
		username: '',
		email: '',
		password: '',
		repeatpassword: '',
		facebook_id: '4',
		phone: '',
		msg: null,
	}
	
	static propTypes =  {
		auth: PropTypes.object.isRequired,
		error: PropTypes.object.isRequired,
		register: PropTypes.func.isRequired,
		clearErrors: PropTypes.func.isRequired
	}
	
	componentDidUpdate(prevProps) {
		const { error } = this.props;
		if (error !== prevProps.error) {
			// Check for register error
			if (error.id === 'REGISTER_FAIL') {
				this.setState({ msg: error.msg.msg });
			} else {
				this.setState({ msg: null });
			}
		}
	}
	
	clearAlert = () => {
		// Clear errors
		this.props.clearErrors();
	}
	
	onChange = e => {
		this.setState({ [e.target.name]: e.target.value });
	};
	
	onSubmit = e => {
		e.preventDefault();
		
		const { username, email, password, repeatpassword, facebook_id, phone } = this.state;
		
		// Create user object
		const newUser = {
			username,
			email,
			password,
			repeatpassword,
			facebook_id,
			phone
		};
		// console.log(newUser);
		// Attempt to register
		this.props.register(newUser);
	};
	
	render() {
		let loading = this.props.auth.loading;
		var domainSite = window.location.hostname;
		var config_website = {};
		if (localStorage.getItem("confignavbar") != null) {
			config_website = JSON.parse(localStorage.getItem("confignavbar"));
		} else {
			config_website = this.props.auth.config_website;
		}
		return (
			<Router>
				<Fragment>
					<style>
						{"\
							.landingcus3 {\
								 background-image: url("+bg_full+");\
								 background-position: center top;\
								 background-origin: padding-box;\
								 background-repeat: no-repeat;\
								 background-size: cover;\
								 background-color: #292946;\
							}\
							.landingcus3 .inner-form-all .single-form-blk:first-child:after {\
								background: #8641bf url("+main_features_person+");\
								background-size: 23px;\
								background-repeat: no-repeat;\
								background-position-x: 12px;\
								background-position-y: 8px;\
							}\
							.landingcus3 .inner-form-all .single-form-blk:nth-child(2):after {\
								background-image: url("+main_features_lock+");\
								background-size: 24px;\
								background-repeat: no-repeat;\
								background-position-x: 12px;\
								background-position-y: 8px;\
							}\
							.landingcus3 .inner-form-all .single-form-blk:first-child:after {\
								background-image: url("+vEdmaFB+");\
								background-position-x: 9px;\
							}\
							.landingcus3 .inner-form-all .single-form-blk:nth-child(2):after {\
								background-image: url("+MiroJ6t+");\
								background-position-x: 9px;\
							}\
						"}
					</style>
					<div className="landingcus3" style={{"zoom": "122%"}}>
						<div id="backClick" className="relative">
							<div ng-controller="landingController" className="top-background ng-scope">
								<nav className="navbar navbar-expand-lg navbar-dark fixed-top navbar-height" id="mainNav">
									<div className="container padding_0">
										<a className="text-white font-w700 font-size-h3" href="/" style={{"width": "55%", "wordBreak": "break-all"}} >
										  <span className="text-white text-uppercase">{domainSite.indexOf('tools1s.com') < 0 ? domainSite.slice(0, domainSite.lastIndexOf('.')) : domainSite.slice(0, domainSite.lastIndexOf('.')).replace('vip.', '') }</span>
										  <span className="text-white text-uppercase">{domainSite.indexOf('.') > -1 && domainSite.indexOf('tools1s.com') < 0 ? domainSite.slice(domainSite.lastIndexOf('.')) : ''}</span>
										</a>
										<Link to="/" onClick={this.props.action_login} className="btn btn-pill sign-up registercus" style={{"background": "#8641bf", "color": "white"}}>
											Đăng nhập
										</Link>
										<div className="collapse navbar-collapse navbar-opened-plashka">
											<ul className="navbar-nav text-uppercase ml-auto">
												{/* <li className="nav-item">
													<a className="nav-link js-scroll-trigger navbar-text ng-binding" href="/"><font style={{"verticalAlign": "inherit"}}>
														<font style={{"verticalAlign": "inherit"}}>HOME</font></font>
													</a>
												</li>
												<li className="nav-item">
													<a className="nav-link js-scroll-trigger navbar-text ng-binding" href="#how-it-works"><font style={{"verticalAlign": "inherit"}}><font style={{"verticalAlign": "inherit"}}>HOW IT WORKS</font></font></a>
												</li>
												<li className="nav-item">
													<a className="nav-link js-scroll-trigger navbar-text" href="#why-us"><font style={{"verticalAlign": "inherit"}}><font style={{"verticalAlign": "inherit"}}>WHY TO CHOOSE</font></font></a>
												</li>
												<li className="nav-item">
													<a className="nav-link js-scroll-trigger navbar-text ng-binding" href="/services"><font style={{"verticalAlign": "inherit"}}><font style={{"verticalAlign": "inherit"}}>SERVICES</font></font></a>
												</li>

												<li className="nav-item">
													<a className="nav-link js-scroll-trigger navbar-text ng-binding" href="/terms"><font style={{"verticalAlign": "inherit"}}><font style={{"verticalAlign": "inherit"}}>TERMS</font></font></a>
												</li>
												<li className="nav-item">
												</li> */}
												<li className="nav-item">
													<Link to="/services" onClick={this.props.action_service} className="nav-link js-scroll-trigger navbar-text ng-binding">
														Dịch vụ
													</Link>
												</li>
												<li className="nav-item">
													<Link to="/" onClick={this.props.action_login} className="nav-link js-scroll-trigger navbar-text ng-binding">
														Đăng nhập
													</Link>
												</li>
												<li className="nav-item">
													<Link to="#" className="btn nav-link js-scroll-trigger  my-button-wrap  ng-binding">
														Đăng ký
													</Link>
												</li>
											</ul>
										</div>
									</div>
								</nav>
								<header className="masthead header-background back-section">
									<div className="container padding_0">
										<h1 className="insta_get_like ng-binding"><font style={{"verticalAlign": "inherit"}}><font style={{"verticalAlign": "inherit"}}>Dịch vụ số 1 và DUY NHẤT mà bạn sẽ cần!</font></font></h1>
										<div className="top-container">
											<div className="top-site-description" style={{"height": "100px"}}>
												<div className="intro-text text-left">
													
												</div>
											</div>
											<div className="top-site-description" style={{"height": "100px"}}>
												<div className="intro-text text-left">
													<div className="video_box">
														
													</div>
												</div>
											</div>
											<div className="text-left intro-text-mobile">
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-lg-12 col-md-12">
											<div className="sign-in-blk sign-in-blk-register">
												{loading ? (
													<div className="preloader">
														<div className="spinner-grow spinner-grow-sm text-primary" role="status" style={{ "marginTop": "15%" }}>
														  <span className="sr-only"></span>
														</div>
														<div className="spinner-grow spinner-grow-sm text-danger" role="status" style={{ "marginTop": "15%" }}>
														  <span className="sr-only">.</span>
														</div>
														<div className="spinner-grow spinner-grow-sm text-warning" role="status" style={{ "marginTop": "15%" }}>
														  <span className="sr-only"></span>
														</div>
													</div>
												):('')}
												{this.state.msg ? (
													<div className="alert alert-warning d-flex align-items-center justify-content-between" role="alert" >
													  <div className="flex-fill mr-3">
														<p className="mb-0">{parse(this.state.msg)}</p>
													  </div>
													  <div className="flex-00-auto">
														<i className="fa fa-fw fa-exclamation-circle"></i>
													  </div>
													</div>
												  ) : null}
												<form className="js-validation-signup" onSubmit={this.onSubmit} method="POST" >
													<div className="">
														<div className="form-group">
															<label htmlFor="username" className="control-label" style={{"float": "left"}}>Tên tài khoản</label>
															<input name="username" placeholder="" required="" id="username" type="text" className="form-control" onChange={this.onChange} />
														</div>
														<div className="form-group">
															<label htmlFor="email" className="control-label" style={{"float": "left"}}>Email</label>
															<input name="email" placeholder="" required="" id="email" type="email" className="form-control" onChange={this.onChange} />
														</div>
														<div className="form-group">
															<label htmlFor="phone" className="control-label" style={{"float": "left"}}>Số điện thoại</label>
															<input name="phone" placeholder="" required="" id="phone" type="text" className="form-control" onChange={this.onChange} />
														</div>
														<div className="form-group">
															<label htmlFor="password" className="control-label" style={{"float": "left"}}>Mật khẩu</label>
															<input name="password" placeholder="" required="" id="password" type="password" className="form-control" onChange={this.onChange} />
														</div>
														<div className="form-group">
															<label htmlFor="confirm" className="control-label" style={{"float": "left"}}>Nhập lại mật khẩu</label>
															<input name="repeatpassword" placeholder="" required="" id="repeatpassword" type="password" className="form-control" onChange={this.onChange} />	
														</div>
													</div>
													
												<div className="inner-form-all">
													<div className="single-form-blk-register">
														<button type="submit" className="" style={{"padding": "0px 40px"}}>Đăng ký</button>
													</div>
												</div>
													<div className="text-center">Bạn đã có tài khoản?
														<Link to="/" onClick={this.props.action_login} amino-styles="color: #aa6dff;" style={{"marginLeft": "5px"}}>
														  <span>
															Đăng nhập
														  </span>
														</Link>
													</div>
												</form>
											</div>
										</div>
									</div>
								</header>
							</div>
							<div className="main-background">
								<section className="back-section padding_0" id="cases">
									<div className="stars-wrap-acc">
										<img className="left-stars" src="https://mediaman.ma/autosm/icons/star_left.svg" alt=""/>
										<div className="stars-text">
											<p className="ng-binding"><font style={{"verticalAlign": "inherit"}}><font style={{"verticalAlign": "inherit"}}>Sẵn sàng để bắt đầu với chúng tôi?
												</font></font>
											</p>
											<Link to="/" onClick={this.changeFormLogRegister} style={{"background": "#8641bf", "color": "white", "borderRadius": "6px"}} className="btn top-32 width_50 ng-binding">
												<font style={{"verticalAlign": "inherit"}}><font style={{"verticalAlign": "inherit"}}>THAM GIA NGAY</font></font>
											</Link>
										</div>
										<img className="right-stars" src="https://mediaman.ma/autosm/icons/star_right.svg" alt=""/>
									</div>
								</section>
							</div>
						</div>
					</div>
				</Fragment>
			</Router>
		);
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	error: state.error
})

export default connect(mapStateToProps, { register, clearErrors })(RegisterModal);