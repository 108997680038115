import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Userinformation from "../Userinformation";

class sitecontent extends Component {
	render() {
		return (
			<Fragment>
				<Userinformation />
				<div className="card card-info mt-3">
					<div className="card-body">
						<p className="font-bold mb-1">Lưu ý:</p>
						<p>Hiện tại Facebook quét hệ thống tăng <code>mắt livestream</code> rất mạnh nên hệ thống <code>mắt livestream</code> không ổn đinh, vui lòng <code>cài đặt số lượng nhỏ</code> để test trước để tránh không tăng được mắt. </p>
					</div>
				</div>
				<div className="card card-orange mt-3">
					<div className="card-body">
						<p className="font-bold mb-1">Lưu ý:</p>
						<p className="font-bold text-danger">- Nghiêm cấm Buff các ID Seeding có nội dung vi phạm pháp luật, chính trị, đồ trụy... Nếu cố tình buff bạn sẽ bị trừ hết tiền và ban khỏi hệ thống vĩnh viễn, và phải chịu hoàn toàn trách nhiệm trước pháp luật.</p>
						- Là dịch vụ hỗ trợ chạy tăng <code>mắt live stream</code> của bạn<br/>
						<strong><b><i className="mr-1"></i>Hướng dẫn: </b></strong><br/>
						- Số lượng mắt tối thiểu mỗi lần mua là <code>50 mắt </code>, tối đa là <code>20,000 mắt</code> (đặt nhiều đơn 1-3k mắt để đc đơn to).<br/>
						- Hệ thống <code>chỉ tăng mắt /</code> không xem video. <br/>
						- Để giống người xem thật, số lượng mắt sẽ được <code>điều chỉnh lên xuống ngẫu nhiên</code> quanh số mắt của bạn mua.
					</div>
				</div>
			</Fragment>
		)
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
});
const mapDispatchToProps = { }

export default connect(mapStateToProps, mapDispatchToProps)(sitecontent);