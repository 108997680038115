import {
	LOAD_VBLT,
	UPDATE_VBLT,
	UPDATE_VBLT_TAB,
	UPDATE_VBLT_GETID,
	LOAD_PRICE_VBLT,
	LIMIT_PAGI_VBLT,
	TYPE_VBLT,
	LOADING_VBLT
} from "../actions/types";

const date7dnext = () => {
	var result = new Date();
  	result.setDate(result.getDate() + 7);
  	return result;
}

const initialState = {
	"taotientrinh": {
		"lhipcv": "",
		"slcnnct": "2",
		"slclnct": "5",
		"slbvtmn": "2",
		"sncmv": "30",
		"bltk": "",
		"lnc_battat": "2",
		"lnc_namnu": "0",
		"lnc_dotuoi_tu": "",
		"lnc_dotuoi_den": "",
		"lnc_sobanbe_tu": "",
		"lnc_sobanbe_den": "",
		"ndr": "",
		"gtmtt": "0",
		"gc": "",
		"usernamefb": "",
	},
	"changetab": 0,
	"listtientrinh": [],
	// "tanggiathem": {
		// "id": "",
		// "lhibv": "",
		// "price_per": 0,
		// "price": 0
	// },
	"price": {
		min_bh_web: 0,
		prices_web: 0,
	},
	"limit_pagi": 0,
	"type": 0,
	"loading": false,
};

export default function(state = initialState, action) {
	switch (action.type) {
		case LOAD_VBLT:
			return {
				...state,
				"listtientrinh": action.payload
			};
		case UPDATE_VBLT:
			return {
				...state,
				"taotientrinh": action.payload
			};
		case UPDATE_VBLT_TAB:
			return {
				...state,
				"changetab": action.payload
			};
		case UPDATE_VBLT_GETID:
			return {
				...state,
				"taotientrinh": {
					...state.taotientrinh,
					"lhipcv": action.payload.id,
					"fb_name": action.payload.username
				}
			};
		case LOAD_PRICE_VBLT:
			return {
				...state,
				"price": action.payload,
				"taotientrinh": {
					...state.taotientrinh,
					"gtmtt": action.payload.prices_web
				}
			};
		case LIMIT_PAGI_VBLT:
			return {
				...state,
				"limit_pagi": action.payload
			};
		case TYPE_VBLT:
			return {
				...state,
				"type": action.payload
			};
		case LOADING_VBLT:
			return {
				...state,
				"loading": action.payload
			};
		default:
		  return state;
	}
}
