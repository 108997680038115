import {
	LOAD_FBRIC,
	UPDATE_FBRIC_TAB,
	UPDATE_FBRIC,
	LOAD_PRICE_FBRIC,
	LOADING_FBRIC,
	LIMIT_PAGI_FBRIC,
	TYPE_FBRIC
} from "../actions/types";

const date7dnext = () => {
	var result = new Date();
  	result.setDate(result.getDate() + 7);
  	return result;
}

const initialState = {
	"taotientrinh": {
		"idfb": "",
		"usernamefb": "",
		"cookie": "",
		"dtsg": "",
		"tgsd": "1",
		"ndr": "",
		"gtmtt": "0",
	},
	"changetab": 0,
	"listtientrinh": [],
	"price": [],
	"loading": false,
	"limit_pagi": 0,
	"type": 0,
};

export default function(state = initialState, action) {
	switch (action.type) {
		case LOAD_FBRIC:
			return {
				...state,
				"listtientrinh": action.payload
			};
		case UPDATE_FBRIC_TAB:
			return {
				...state,
				"changetab": action.payload
			};
		case UPDATE_FBRIC:
			return {
				...state,
				"taotientrinh": action.payload
			};
		case LOAD_PRICE_FBRIC:
			return {
				...state,
				"price": action.payload,
				"taotientrinh": {
					...state.taotientrinh,
					"gtmtt": action.payload.prices_web
				}
			};
		case LOADING_FBRIC:
			return {
				...state,
				"loading": action.payload
			};
		case LIMIT_PAGI_FBRIC:
			return {
				...state,
				"limit_pagi": action.payload
			};
		case TYPE_FBRIC:
			return {
				...state,
				"type": action.payload
			};
		default:
		  return state;
	}
}
