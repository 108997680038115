import React from 'react';
import axios from 'axios';
import {
	LOAD_FLBBKTT,
	UPDATE_FLBBKTT_TAB,
	UPDATE_FLBBKTT,
	LOAD_PRICE_FLBBKTT,
	LIMIT_PAGI_FLBBKTT,
	TYPE_FLBBKTT,
	LOADING_FLBBKTT,
	UPDATE_IDFB_FLBBKTT
} from "../actions/types";
import Swal from 'sweetalert2';
import { loadUser } from "./authActions";

export const loadFLBBKTT = (type = 0, page = 1, obj_search = '') => (dispatch, getState) => {
	dispatch({
		type: LOADING_FLBBKTT,
		payload: true
	});
	axios.get('/api/facebooklocbanbekhongtuongtac?type='+type+'&page='+page+'&obj_search='+obj_search, tokenConfig(getState))
		.then(res => {
			dispatch({
				type: LOADING_FLBBKTT,
				payload: false
			});
			if (res.data.status === 200) {
				dispatch({
					type: LOAD_FLBBKTT,
					payload: res.data.data
				});
			}
		});
};

export const loadPriceFLBBKTT = () => (dispatch, getState) => {
	axios.get('/api/facebooklocbanbekhongtuongtac/price', tokenConfig(getState))
		.then(res => {
			if (res.data.status === 200) {
				dispatch({
					type: LOAD_PRICE_FLBBKTT,
					payload: res.data.data
				});
			}
		});
};

export const changeTab = (body) => (dispatch, getState) => {
	dispatch({
		type: UPDATE_FLBBKTT_TAB,
		payload: body
	});
};

export const updateFLBBKTT = (body) => (dispatch, getState) => {
	dispatch({
		type: UPDATE_FLBBKTT,
		payload: body
	});
};

export const updateLimitFLBBKTT = (body) => (dispatch) => {
	dispatch({
		type: LIMIT_PAGI_FLBBKTT,
		payload: body
	})
};

export const updateTypeFLBBKTT = (body) => (dispatch) => {
	dispatch({
		type: TYPE_FLBBKTT,
		payload: body
	})
};

export const addFLBBKTT = (body) => (dispatch, getState) => {
	dispatch({
		type: LOADING_FLBBKTT,
		payload: true
	});
	axios.post('/api/facebooklocbanbekhongtuongtac/add', body, tokenConfig(getState))
		.then(res => {
			dispatch(loadUser());
			dispatch({
				type: LOADING_FLBBKTT,
				payload: false
			});
			if (res.data.status === 200) {
				Swal.fire({
					icon: 'success',
					html: 'Tạo tiến trình thành công'
				});
			}
			if (res.data.status === 400) {
				var message = '';
				if (res.data.error) {
					if (res.data.error.object_id) {
						message = res.data.error.object_id[0];
					} else if (res.data.error.quantity) {
						message = res.data.error.quantity[0];
					} else {
						message = res.data.message;
					}
				} else {
					message = res.data.message;
				}
				Swal.fire({
					icon: 'error',
					title: 'Lỗi',
					text: message,
				});
			}
		});
};

export const updateFLBBKTTactive = (body) => (dispatch, getState) => {
	axios.post('/api/facebooklocbanbekhongtuongtac/active', body, tokenConfig(getState))
		.then(res => {
			dispatch(loadFLBBKTT());
		});
};


export const deleteFLBBKTT = (body) => (dispatch, getState) => {
	dispatch({
		type: LOADING_FLBBKTT,
		payload: true
	});
	axios.post('/api/facebooklocbanbekhongtuongtac/delete', body, tokenConfig(getState))
		.then(res => {
			dispatch({
				type: LOADING_FLBBKTT,
				payload: false
			});
			dispatch(loadFLBBKTT());
			if (res.data.status === 200) {
				Swal.fire({
					icon: 'success',
					html: 'Hủy đơn thành công'
				});
			}
			if (res.data.status === 400) {
				var message = '';
				if (res.data.error) {
					if (res.data.error.object_id) {
						message = res.data.error.object_id[0];
					} else if (res.data.error.quantity) {
						message = res.data.error.quantity[0];
					} else {
						message = res.data.message;
					}
				} else {
					message = res.data.message;
				}
				Swal.fire({
					icon: 'error',
					title: 'Lỗi',
					text: message,
				});
			}
		});
};

export const CheckCookie = (cookiefb) => (dispatch, getState) => {
    let checkcookiefb = cookiefb.indexOf('c_user');
    if (checkcookiefb > -1) {
        let cookieRmFirst = cookiefb.slice(checkcookiefb+7);
        let cookieEndToken = cookieRmFirst.indexOf(';');
        const cookie_success = cookieRmFirst.substr(0, cookieEndToken);

        let bodysend = {
            cookie: cookiefb,
        }
        return axios.post('/api/fbbot/checkcookie', bodysend, tokenConfig(getState));
    } else {
    	return false;
    }
};

export const UpdateCookie = (cookiefb, dataidfb, dataid, closeM) => (dispatch, getState) => {
	let body = {
		id: dataid,
		id_user: getState().auth.user.id,
		cookie: cookiefb,
		idfb: dataidfb
	};
	dispatch({
		type: LOADING_FLBBKTT,
		payload: true
	});
	var fun_check = dispatch(CheckCookie(cookiefb));
	if (fun_check !== false) {
		fun_check.then( res1 => {
			if (res1.data.status === 200) {
				axios.post('/api/facebooklocbanbekhongtuongtac/updatecookie', body, tokenConfig(getState))
					.then(res => {
						dispatch({
							type: LOADING_FLBBKTT,
							payload: false
						});
						closeM();
						if (res.data.status === 200) {
							dispatch(loadFLBBKTT());
							Swal.fire({
								icon: 'success',
								html: 'Cập nhật thành công'
							});
						}
						if (res.data.status === 400) {
							var message = '';
							if (res.data.error) {
								if (res.data.error.object_id) {
									message = res.data.error.object_id[0];
								} else if (res.data.error.quantity) {
									message = res.data.error.quantity[0];
								}
							} else {
								message = res.data.message;
							}
							Swal.fire({
								icon: 'error',
								title: 'Lỗi',
								text: message,
							});
						}
					});
			} else {
				dispatch({
					type: LOADING_FLBBKTT,
					payload: false
				});
				Swal.fire({
					icon: 'error',
					title: 'Lỗi',
					text: 'Cookie die hoặc không hợp lệ #2',
				});
			}
		})
	}
    
};

export const searchIdFb = (link) => (dispatch, getState) => {
	let bodysend = {
		id_user: getState().auth.user.id,
		link: link
	};
	var dataFormOld = getState().buffsub.formData;
	dispatch({
		type: LOADING_FLBBKTT,
		payload: true
	});
	axios.post('/api/checklinkfb/check/', bodysend, tokenConfig(getState))
	.then(res => {
		dispatch({
			type: LOADING_FLBBKTT,
			payload: false
		});
		if (res.data.status === 200) {
			dispatch({
				type: UPDATE_IDFB_FLBBKTT,
				payload: res.data.id
			})
		} else {
			Swal.fire({
				icon: 'error',
				title: 'Lỗi',
				text: res.data.msg,
			});
		}
	}).catch(err => {
		dispatch({
			type: LOADING_FLBBKTT,
			payload: false
		});
		Swal.fire({
			icon: 'error',
			title: 'Lỗi',
			text: 'Có lỗi xảy ra!',
		});
    });
}

export const tokenConfig = getState => {
	// Get token from localhost
	const token = getState().auth.token;
	// Headers
	const config = {
		headers: {
			"Content-type": "application/json"
		}
	};
	
	// If token, add to headers
	if (token) {
		config.headers['ht-token'] = token;
	}
	
	return config;
};