import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import Moment from 'react-moment';
import ModalAddPriceFunction from './modal/addpricefunction';
import paginationFactory from 'react-bootstrap-table2-paginator';
import store from '../../../store';

import {editConfigSite, saveConfigSite, getConfigSite} from '../../../actions/adminPageAction';
class TagConfigSite extends Component {
	state = {
        isOpenModalAdd: false,
        isOpenModalEdit: false,
        link_youtube1: '',
        link_youtube2: ''
    }
    componentDidMount(){
      this.props.getConfigSite();
    }
    SaveConfig(){
      const { configSite } = this.props.adminpage;
      this.props.saveConfigSite(configSite);
    }
	  onChange = (e) =>{
      var link = e.target.value;
      const { configSite } = this.props.adminpage;
      if (e.target.name === 'link_youtube1' || e.target.name === 'link_youtube2') {
        var id = e.target.value.match(/v=(.*)/);
        if (id) {
          link = 'https://www.youtube.com/embed/'+id[1];
        }
      }
      var data = {
        ...configSite,
        [e.target.name]: link
      }
      this.props.editConfigSite(data);
    }
    render() {
        const { configSite } = this.props.adminpage;
        return (
            <Fragment>
              <div className="form-group">
                <label>Link nhúng video youtube 1</label>
                <textarea className="form-control" name="link_youtube1" placeholder="Link nhúng youtube" value={configSite.link_youtube1} onChange={this.onChange}>
                </textarea>
              </div>
              <div className="form-group">
                <label>Link nhúng video youtube 2</label>
                <textarea className="form-control" name="link_youtube2" placeholder="Link nhúng youtube" value={configSite.link_youtube2} onChange={this.onChange}>
                </textarea>
              </div>
              <div className="form-group">
                <button className="btn btn-success" onClick={()=>{this.SaveConfig()}}>Lưu</button>
              </div>
            </Fragment>
        );
    }

}

const mapStateToProps = state => ({
    adminpage: state.adminpage,
});

export default connect(mapStateToProps, {editConfigSite, saveConfigSite, getConfigSite}) (TagConfigSite);
