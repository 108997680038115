import React from 'react';
import axios from 'axios';
import {
	LOAD_FBBC,
	UPDATE_FBBC_TAB,
	UPDATE_FBBC,
	UPDATE_COOKIE_FBBC,
	LOADING_FBBC,
	LOAD_PRICE_FBBC,
	LOAD_PROXY_FBBC,
} from "../actions/types";
import Swal from 'sweetalert2';

export const loadFBBC = () => (dispatch, getState) => {
	axios.get('/api/facebookbotcmt', tokenConfig(getState))
		.then(res => {
			if (res.data.status === 200) {
				dispatch({
					type: LOAD_FBBC,
					payload: res.data.data
				});
			}
		});
};

export const loadPriceFBBC = () => (dispatch, getState) => {
	axios.get('/api/facebookbotcmt/price', tokenConfig(getState))
		.then(res => {
			if (res.data.status === 200) {
				dispatch({
					type: LOAD_PRICE_FBBC,
					payload: res.data.data
				});
			}
		});
};

export const loadProxyFBBC = () => (dispatch, getState) => {
	axios.get('/api/facebookbotcmt/loadproxy', tokenConfig(getState))
		.then(res => {
			if (res.data.status === 200) {
				dispatch({
					type: LOAD_PROXY_FBBC,
					payload: res.data.data
				});
			}
		});
};

export const changeTab = (body) => (dispatch, getState) => {
	dispatch({
		type: UPDATE_FBBC_TAB,
		payload: body
	});
};

export const updateFBBC = (body) => (dispatch, getState) => {
	dispatch({
		type: UPDATE_FBBC,
		payload: body
	});
};

export const addFBBC = (body) => (dispatch, getState) => {
	axios.post('/api/facebookbotcmt/add', body, tokenConfig(getState))
		.then(res => {
			if (res.data.status === 200) {
				Swal.fire({
					icon: 'success',
					html: 'Tạo tiến trình thành công'
				});
			}
			if (res.data.status === 400) {
				var message = '';
				if (res.data.error) {
					if (res.data.error.object_id) {
						message = res.data.error.object_id[0];
					} else if (res.data.error.quantity) {
						message = res.data.error.quantity[0];
					}
				} else {
					message = res.data.message;
				}
				Swal.fire({
					icon: 'error',
					title: 'Lỗi',
					text: message,
				});
			}
		});
};

export const updateFBBCactive = (body) => (dispatch, getState) => {
	axios.post('/api/facebookbotcmt/active', body, tokenConfig(getState))
		.then(res => {
			dispatch(loadFBBC());
		});
};

export const dangnhapFBBC = (body) => (dispatch, getState) => {
	dispatch({
		type: LOADING_FBBC,
		payload: true
	});
	axios.post('/api/facebookbotcmt/dangnhap', body, tokenConfig(getState))
		.then(res => {
			dispatch({
				type: UPDATE_COOKIE_FBBC,
				payload: res.data
			});
			dispatch({
				type: LOADING_FBBC,
				payload: false
			});
			if (res.data.status === 400) {
				Swal.fire({
					icon: 'error',
					title: 'Lỗi',
					text: res.data.msg,
				});
			}
		});
};

export const CheckCookie = (cookiefb) => (dispatch, getState) => {
    let checkcookiefb = cookiefb.indexOf('c_user');
    if (checkcookiefb > -1) {
        let cookieRmFirst = cookiefb.slice(checkcookiefb+7);
        let cookieEndToken = cookieRmFirst.indexOf(';');
        const cookie_success = cookieRmFirst.substr(0, cookieEndToken);

        let bodysend = {
            cookie: cookiefb,
        }
        return axios.post('/api/facebookbotcmt/checkcookie', bodysend, tokenConfig(getState));
    } else {
    	return false;
    }
};

export const UpdateCookie = (cookiefb, lnncx_type, dataidfb, dataid, closeM) => (dispatch, getState) => {
	var fun_check = dispatch(CheckCookie(cookiefb));
	if (fun_check !== false) {
		fun_check.then(res1 => {
			if (res1.data.status === 200) {
				let body = {
					id: dataid,
					id_user: getState().auth.user.id,
					cookie: cookiefb,
					lnncx_type: lnncx_type,
					idfb: dataidfb
				};
				axios.post('/api/facebookbotcmt/updatecookie', body, tokenConfig(getState))
					.then(res => {
						closeM();
						if (res.data.status === 200) {
							dispatch(loadFBBC());
							Swal.fire({
								icon: 'success',
								html: 'Cập nhật thành công'
							});
						}
						if (res.data.status === 400) {
							var message = '';
							if (res.data.error) {
								if (res.data.error.object_id) {
									message = res.data.error.object_id[0];
								} else if (res.data.error.quantity) {
									message = res.data.error.quantity[0];
								}
							} else {
								message = res.data.message;
							}
							Swal.fire({
								icon: 'error',
								title: 'Lỗi',
								text: message,
							});
						}
					});
			} else {
				Swal.fire({
					icon: 'error',
					title: 'Lỗi',
					text: 'Cookie die hoặc không hợp lệ #2',
				});
				return false;
			}
		})
	} else {
		Swal.fire({
			icon: 'error',
			title: 'Lỗi',
			text: 'Cookie die hoặc không hợp lệ #1',
		});
		return false;
	}
};

export const GiaHan = (giahanthem, dataid, gtmtt, closeM) => (dispatch, getState) => {
	let body = {
		id: dataid,
		id_user: getState().auth.user.id,
		giahanthem: giahanthem,
		gtmtt: gtmtt,
	};
    axios.post('/api/facebookbotcmt/giahanthem', body, tokenConfig(getState))
		.then(res => {
			closeM();
			if (res.data.status === 200) {
				dispatch(loadFBBC());
				Swal.fire({
					icon: 'success',
					html: 'Cập nhật thành công'
				});
			}
			if (res.data.status === 400) {
				var message = '';
				if (res.data.error) {
					if (res.data.error.object_id) {
						message = res.data.error.object_id[0];
					} else if (res.data.error.quantity) {
						message = res.data.error.quantity[0];
					}
				} else {
					message = res.data.message;
				}
				Swal.fire({
					icon: 'error',
					title: 'Lỗi',
					text: message,
				});
			}
		});
};

export const DeleteCookie = (id) => (dispatch, getState) => {
	Swal.fire({
	  	title: 'Bạn có chắc?',
	  	text: "Sau khi hủy bạn sẽ bị trừ 7 ngày chưa sử dụng và 5000 phí!",
	  	icon: 'warning',
	  	showCancelButton: true,
	  	confirmButtonColor: '#3085d6',
	  	cancelButtonColor: '#d33',
	  	confirmButtonText: 'Đồng ý',
	  	cancelButtonText: 'Không'
	}).then((result) => {
	  	if (result.value) {
			let bodysend = {
				id: id,
				id_user: getState().auth.user.id
			};

			axios.post('/api/facebookbotcmt/deletecookie', bodysend, tokenConfig(getState))
				.then(res => {
					if (res.data.status === 200) {
						dispatch(loadFBBC());
						Swal.fire({
							icon: 'success',
							html: 'Xóa thành công'
						});
					}
					if (res.data.status === 400) {
						var message = '';
						if (res.data.error) {
							if (res.data.error.object_id) {
								message = res.data.error.object_id[0];
							} else if (res.data.error.quantity) {
								message = res.data.error.quantity[0];
							}
						} else {
							message = res.data.message;
						}
						Swal.fire({
							icon: 'error',
							title: 'Lỗi',
							text: message,
						});
					}
			});
	  	}
  	});
}

export const tokenConfig = getState => {
	// Get token from localhost
	const token = getState().auth.token;
	// Headers
	const config = {
		headers: {
			"Content-type": "application/json"
		}
	};
	
	// If token, add to headers
	if (token) {
		config.headers['ht-token'] = token;
	}
	
	return config;
};