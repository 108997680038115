import {
	LOAD_SCF,
	UPDATE_SCF,
	UPDATE_SCF_TAB,
	LOAD_PRICE_SCF,
	LIMIT_PAGI_SCF,
	TYPE_SCF,
	LOADING_SCF,
	LOADING_SCF_2,
	LOAD_NHATKYHOATDONG_SCF
} from "../actions/types";

const initialState = {
	"taotientrinh": {
		"lhi": "",
		"lsct": "sv1",
		"slct": "10",
		"gc": "",
		"gtmtt": "0",
	},
	"changetab": 0,
	"listtientrinh": [],
	"tanggiathem": {
		"id": "",
		"lhibv": "",
		"price_per": 0,
		"price": 0
	},
	"price": {
		Soundcloudfollow: {
			min_bh_web: 0,
			prices_web: 0,
		},
	},
	"limit_pagi": 0,
	"type": 0,
	"loading": false,
	"loading2": false,
	"nhatkyhoatdong": [],
	soundcloudbufffollow_mo_dong: 1,
};

export default function(state = initialState, action) {
	switch (action.type) {
		case LOAD_SCF:
			return {
				...state,
				"listtientrinh": action.payload
			};
		case UPDATE_SCF:
			return {
				...state,
				"taotientrinh": action.payload
			};
		case UPDATE_SCF_TAB:
			return {
				...state,
				"changetab": action.payload
			};
		case LOAD_PRICE_SCF:
			return {
				...state,
				"price": merDataPricePage(action.payload),
			};
		case LIMIT_PAGI_SCF:
			return {
				...state,
				"limit_pagi": action.payload
			};
		case TYPE_SCF:
			return {
				...state,
				"type": action.payload
			};
		case LOADING_SCF:
			return {
				...state,
				"loading": action.payload
			};
		case LOADING_SCF_2:
			return {
				...state,
				"loading2": action.payload
			};
		case LOAD_NHATKYHOATDONG_SCF:
			return {
				...state,
				"nhatkyhoatdong": action.payload
			};
		case 'CONFIG_MO_DONG_SCF':
			return {
				...state,
				soundcloudbufffollow_mo_dong: action.payload.soundcloudbufffollow_mo_dong,
			};
		default:
		  return state;
	}
}

function merDataPricePage(data) {
	let dataNew = {
		...initialState.price
	}
	if (data.length > 0) {
		data.forEach(function(v, i){
			if (v.name_table === 'soundcloudfollow') {
				initialState.taotientrinh.gtmtt = v.prices_web;
				dataNew['Soundcloudfollow'] = v;
			}
		});
	};
	return dataNew;
}
