import {
	LOAD_FBBV,
	UPDATE_FBBV_TAB,
	UPDATE_FBBV,
	UPDATE_COOKIE_FBBV,
	LOADING_FBBV,
	LOAD_PRICE_FBBV,
	LIMIT_PAGI_FBBV,
	TYPE_FBBV,
	LOAD_NHATKYHOATDONG_FBBV,
	LOAD_PROXY_FBBV,
	LOAD_PROXY_EDIT_FBBV
} from "../actions/types";

const date7dnext = () => {
	var result = new Date();
  	result.setDate(result.getDate() + 7);
  	return result;
}

const initialState = {
	"taotientrinh": {
		"ctkfcc": "",
		"tgctt_tu": "7",
		"tgctt_den": "22",
		// "tgdctt_tu": "60",
		// "tgdctt_den": "600",
		"lnncx_type": ["love"],
		"lnncx_tdmn": "200",
		"snmcatt": "30",
		"gc": "",
		"userAgent": "",
		"gtmtt": "0",
		"idfb": "",
		"usernamefb": "",
		"dtsg": "",
		"id_proxy": "0",
		"ttv": "FRIEND",
		"gioitinh": "all",
		"bvtp": "1",
		"blacklistid": "",
	},
	"changetab": 0,
	"listtientrinh": [],
	"loading": false,
	"price": [],
	"listproxy": [],
	"listproxy_edit": [],
	"limit_pagi": 0,
	"type": 0,
	"list_nhatky":""
};

export default function(state = initialState, action) {
	switch (action.type) {
		case LOAD_FBBV:
			return {
				...state,
				"listtientrinh": action.payload
			};
		case UPDATE_FBBV_TAB:
			return {
				...state,
				"changetab": action.payload
			};
		case UPDATE_FBBV:
			return {
				...state,
				"taotientrinh": action.payload
			};
		case UPDATE_COOKIE_FBBV:
			return {
				...state,
				"taotientrinh": {
					...state.taotientrinh,
					"ctkfcc": action.payload.cookie,
					"userAgent": action.payload.userAgent,
				}
			};
		case LOADING_FBBV:
			return {
				...state,
				"loading": action.payload
			};
		case LOAD_PRICE_FBBV:
			return {
				...state,
				"price": action.payload,
				"taotientrinh": {
					...state.taotientrinh,
					"gtmtt": action.payload.prices_web
				}
			};
		case LOAD_PROXY_FBBV:
			return {
				...state,
				"listproxy": action.payload,
			};
		case LOAD_PROXY_EDIT_FBBV:
			return {
				...state,
				"listproxy_edit": action.payload,
			};
		case LIMIT_PAGI_FBBV:
			return {
				...state,
				"limit_pagi": action.payload
			};
		case TYPE_FBBV:
			return {
				...state,
				"type": action.payload
			};
		case LOAD_NHATKYHOATDONG_FBBV:
			return {
				...state,
				"list_nhatky": action.payload
			};
		default:
		  return state;
	}
}
