import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import {
	Modal,
	ModalBody,
	ModalFooter
} from 'reactstrap';

import { saveEditOrderAdmin } from '../../../actions/buffsub';

class ModalEditAdmin extends Component {
	state = {
		formdata: {
			E_status: this.props.dataItemSelect.status,
			E_count: this.props.dataItemSelect.count_success,
			E_notes: this.props.dataItemSelect.notes,
			id_edit: this.props.dataItemSelect.id,
		}
	}

	changeDataForm = (e, C_number = 0) => {
		let valueChange = e.target.value;
		if (C_number === 1) {
			valueChange = parseInt(valueChange);
		}

		this.setState({
			formdata: {
				...this.state.formdata,
				[e.target.name]: valueChange
			}
		});
	}

	render() {
		const {dataItemSelect,isOpenModal} = this.props;
		const {formdata} = this.state;
		return (
			<Fragment>
				{dataItemSelect !== {} ?
					<Fragment>
						<Modal centered={true} size="lg" toggle={(e) => {this.props.openModal()}} isOpen={isOpenModal}>
		                    <div className="modal-header">
		                        <p className="mb-0 text-dark">Sửa</p>
		                        <button onClick={(e) => {this.props.openModal()}} type="button" className="close">×</button>
		                    </div>
		                    <ModalBody>
		                        <div className="row">
		                            <div className="col-md-12">
		                                <div className="form-group">
		                                    <label>Trạng thái</label>
		                                    <select name="E_status" value={formdata.E_status} onChange={(e) => {this.changeDataForm(e, 1)}} className="form-control">
		                                    	<option value="0">Hoàn thành</option>
		                                    	<option value="1">Đang chạy</option>
		                                    	<option value="2">Id lỗi</option>
		                                    	<option value="3">Hủy đơn</option>
		                                    	<option value="5">Hoàn tiền</option>
		                                    </select>
		                                </div>

		                                <div className="form-group">
		                                    <label>Số lượng đã chạy</label>
		                                    <input type="number" name="E_count" onChange={(e) => {this.changeDataForm(e, 1)}} value={formdata.E_count} className="form-control" />
		                                </div>

		                                <div className="form-group">
		                                    <label>Ghi chú</label>
		                                    <input type="text" name="E_notes" onChange={(e) => {this.changeDataForm(e)}} value={formdata.E_notes} className="form-control" />
		                                </div>
		                            </div>
		                        </div>
		                    </ModalBody>
		                    <ModalFooter>
		                        <button type="button" className="btn btn-primary" onClick={(e) => {this.props.saveEditOrderAdmin(formdata, this.props.openModal)}}>Lưu</button>
		                        <button type="button" className="btn btn-danger" onClick={(e) => {this.props.openModal()}}>Huỷ</button>
		                    </ModalFooter>
		                </Modal>
					</Fragment>
				: ''}
    		</Fragment>
		);
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	buffsub: state.likecommentshare,
});

export default connect(mapStateToProps, {saveEditOrderAdmin})(ModalEditAdmin);