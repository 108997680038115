import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Moment from 'react-moment';
import store from '../../../store';
import parse from 'html-react-parser';

import Modaledit from './modal_edit';
import { GetListData,DeleteItem } from '../../../actions/buffviewsvideoAction';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { RiExchangeDollarLine } from "react-icons/ri";
const { SearchBar } = Search;

class listBot extends Component {
    state = {
		isOpenModalEdit: false,
		limit: 0,
        viewall: 0,
		page: 1,
		obj_search: "",
		id: 0,
		view_now: 0,
		status: 0,
    }
    componentDidMount(){
        this.props.GetListData(0, 1, '');
    }

    openModalEdit = (id = 0, view_now = 0, status = 0) => {
        this.setState({
            ...this.state,
            isOpenModalEdit: !this.state.isOpenModalEdit,
			id: id,
			view_now: view_now,
			status: status,
        });
    }
    
	onChange = (e) => {
		this.setState({
			"obj_search": e.target.value,
			"page": 1
		});
	}
    getListByLimit = (page, type_button) => {
        var valueChange = parseInt(page);
		if (type_button == "prev") {
			valueChange -= 1;
			if (valueChange <= 0) {
				valueChange = 1;
			}
		}
		if (type_button == "next") {
			valueChange += 1;
		}
		this.setState({
			page: valueChange,
		});
		this.props.GetListData(this.state.viewall, valueChange, this.state.obj_search);
    }
	
    getListByView = (e) => {
        let valueChange = parseInt(e);
		this.setState({
			viewall: valueChange,
			"page": 1,
		});
		this.props.GetListData(valueChange, 1, this.state.obj_search);
    }
	
    getListBySearch = (e) => {
		this.setState({
			"page": 1,
		});
		this.props.GetListData(this.state.viewall, 1, this.state.obj_search);
    }

    calBwDateToNow = (date_check = null) => {
      if (Number.isInteger(date_check)) {
        let date_now = Math.floor(Date.now() / 1000);
        let calDateDay = (date_now - date_check)/86400;
        if (calDateDay > 1) {
          return Math.ceil(calDateDay) + ' ngày trước';
        } else {
          let calDatehour = (date_now - date_check)/3600;
          console.log(calDatehour);
          if (calDatehour < 0.6) {
            return Math.ceil(calDatehour*100) + ' phút trước';
          } else {
            return Math.ceil(calDatehour) + ' giờ trước';
          }
        }
      }
    }

    render() {
		function formatTrangThai(cell, row) {
			var message = '';
			if (cell === 0) {
				return (
					<span className="badge badge-primary"><i className="fas fa-check mr-1"></i>Hoàn thành</span>
				);
			} else if (cell === 1) {
				return (
					<span className="badge badge-success"><i className="fas fa-sync-alt mr-1"></i>Đang chạy</span>
				);
			} else if (cell === 2) {
				return (
					<span className="badge badge-danger"><i className="fas fa-ban mr-1"></i>ID die không thể chạy</span>
				);
			} else if (cell === 3) {
				return (
					<span className="badge badge-warning"><i className="fas fa-times mr-1"></i>Hủy đơn</span>
				);
			} else if (cell === 5) {
				return (
					<span className="badge badge-info"><RiExchangeDollarLine size={12} className="mr-1" />Hoàn tiền</span>
				);
			} else {
				return ('');
			}
		}
		function filterValueTrangThai(cell, row) {
			if (cell === 0) {
				return ('Hoàn thành');
			} else if (cell === 1) {
				return ('Đang chạy');
			} else if (cell === 2) {
				return ('ID die không thể chạy');
			} else if (cell === 3) {
				return ('Hủy đơn');
			} else if (cell === 5) {
				return ('Hoàn tiền');
			} else {
				return ('');
			}
		}
		function formatTypeView(cell, row) {
			var message = '';
			if (cell === 1) {
				return ('SV2 - VIEW 1P' );
			} else if (cell === 2) {
				return ('VIEW 3P' );
			} else if (cell === 3) {
				return ('VIEW 10P' );
			} else if (cell === 4) {
				return ('SV4' );
			} else if (cell === 5) {
				return ('SV5' );
			} else if (cell === 99) {
				return ('SV3 - VIEW 3s' );
			} else {
				return ('SV1 - VIEW 3s');
			}
		}
		function calPrices(cell, row) {
			return Math.round((row.per_price*row.soluong + Number.EPSILON) * 100) / 100;
		}
        const columns = [{
          dataField: '',
          text: 'STT',
          sort: true,
          formatter: (cell, row, rowIndex) =>
            <span>{rowIndex+1}</span>
        },{
          text: 'THAO TÁC',
          dataField: '-',
		  style: {
			'white-space' : 'nowrap'
		  },
          formatter: (cell, row) => 
            <Fragment>
				{this.props.auth.user.type === 1 ?
					<a onClick={(e) => {e.preventDefault();this.openModalEdit(row.id, row.view_now, row.status)}} href="/#" className="btn btn-hero-primary text-center p-2 mr-1">
						<i className="fa fa-fw fa-pen"></i>
					</a>
				: ''}
				{ row.status === 1 || row.status === 2 ?
						<a onClick={(e) => {e.preventDefault();this.props.DeleteItem(row.id)}} href="/#" className="btn btn-hero-danger text-center p-2 mr-1">
							<i className="fa fa-fw fa-trash"></i>
						</a>
					: ''
				}
            </Fragment>
        },{
          dataField: 'id_fb',
          text: 'ID VIDEO',
          sort: true,
        },{
          dataField: 'type',
          text: 'TYPE VIEWS',
          sort: true,
		  formatter: formatTypeView,
		  filterValue: formatTypeView
        },{
          dataField: 'soluong',
          text: 'SỐ LƯỢNG',
          sort: true,
        },{
          dataField: 'start',
          text: 'VIEW START',
          sort: true,
        },{
          dataField: 'view_now',
          text: 'VIEW NOW',
          sort: true,
        },{
          dataField: 'per_price',
          text: 'PRICE',
          sort: true,
		  formatter: calPrices,
		  filterValue: calPrices
        },{
          dataField: 'create_at',
          text: 'NGÀY TẠO',
          sort: true,
          formatter: (cell, row) => 
            <Fragment>
                {this.calBwDateToNow(cell)}
            </Fragment>
        },{
          dataField: 'username',
          text: 'username',
          sort: true,
		  classes: 'notranslate',
        },{
          dataField: 'status',
          text: 'TRẠNG THÁI',
          sort: true,
		  formatter: formatTrangThai,
		  filterValue: filterValueTrangThai
        }];

        const {data_list} = this.props.buffviewsvideo;

        return (
            <Fragment>
                {/*<div className="row mb-4">
                    <div className="col-md-9">
                        { this.props.auth.user.type === 1 ?
                            <Fragment>
                              <button onClick={() => {this.props.GetListData(1);this.setState({...this.state,viewall: 1})}} type="button" className="btn btn-primary mr-1">Xem tất cả</button>
                              <button onClick={() => {this.props.GetListData();this.setState({...this.state,viewall: 0})}} type="button" className="btn btn-primary mr-1">Xem riêng</button>
                            </Fragment>
                        : ''}
                    </div>
                    <div className="col-md-3">
                        <select onChange={(e) => {this.getListByLimit(e)}} className="form-control">
                            <option value="">Số lịch sử hiển thị tối đa</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="200">200</option>
                            <option value="500">500</option>
                            <option value="1000">1000</option>
                        </select>
                    </div>
                </div>*/}
				<ToolkitProvider keyField="id" data={ data_list } columns={ columns } search >
					{
						props => (
							<div>
								<SearchBar { ...props.searchProps } />
								<div className="row mb-4">
									<div className="col-md-12">
										{ this.props.auth.user.type === 1 ?
											<Fragment>
												<button onClick={(e) => {this.getListByView(1)}} name="view" value="1" type="button" className="btn btn-primary mr-1">Xem tất cả</button>
												<button onClick={(e) => {this.getListByView(0)}} name="view" value="0" type="button" className="btn btn-primary mr-1">Xem riêng</button>
											</Fragment>
										: ''}
										{/* <select onChange={(e) => {this.getListByLimit(e)}} className="form-control custom-select select-light col-md-3 custom-limit_pagi">
											<option value="0">Số lịch sử hiển thị (0-500)</option>
											<option value="1">500-1000</option>
											<option value="2">1000-1500</option>
											<option value="3">1500-2000</option>
											<option value="4">2000-2500</option>
											<option value="5">2500-3000</option>
											<option value="7">3000-3500</option>
											<option value="8">3500-4000</option>
											<option value="9">4500-5000</option>
										</select> */}
										<button onClick={(e) => {this.getListByLimit(this.state.page, "prev")}} name="page" value={this.state.page} type="button" className="btn btn-primary mr-1">{"<"}</button>
										<button onClick={(e) => {this.getListByLimit(this.state.page, "next")}} name="page" value={this.state.page} type="button" className="btn btn-primary mr-1">{">"}</button>
										<button onClick={(e) => {this.getListBySearch(e)}} name="view" value="0" type="button" className="btn btn-primary mr-1">Tìm kiếm</button>
										<input  onChange={this.onChange} value={this.state.obj_search}  name="obj_search" type="text" className="form-control mr-1" style={{"width": "100px", "display": "inline-block"}}/>
									</div>
								</div>
								<BootstrapTable
									{ ...props.baseProps }
									bodyStyle={ { border: 'none' } }
									headerStyle={ { border: 'red 1px solid' } }
									striped
									hover
									wrapperClasses="table-responsive"
									pagination={ paginationFactory() } />
							</div>
						)
					}
				</ToolkitProvider>
				
                {this.state.isOpenModalEdit ? <Modaledit openModal={this.openModalEdit} isOpenModal={this.state.isOpenModalEdit} dataid={this.state.id} dataview_now={this.state.view_now} datastatus={this.state.status} /> : ''}
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    buffviewsvideo: state.buffviewsvideo,
    auth: state.auth
});

export default connect(mapStateToProps, {GetListData,DeleteItem}) (listBot);
