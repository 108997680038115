import React, { Component, Fragment } from "react";
import { Route, Redirect, Link } from "react-router-dom";
import { connect } from 'react-redux';
import { loadSP, updateLimitSP, updateTypeSP } from '../../../../actions/SupportActions';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import { ImClock } from "react-icons/im";
import { FaRegCheckCircle, FaRegQuestionCircle } from "react-icons/fa";
const { SearchBar } = Search;

class List extends Component {
    state = {
		
    }
	
    componentDidMount(){
		this.props.loadSP();
    }
	
	onChangeLimit = e => {
		const { type } = this.props.Support;
		this.props.updateLimitSP(e.target.value);
		this.props.loadSP(type, e.target.value);
	};
	
	onCheckList = (type) => {
		const { limit_pagi } = this.props.Support;
		this.props.updateTypeSP(type);
		this.props.loadSP(type, limit_pagi);
	}

    render() {
		var { listtientrinh, limit_pagi } = this.props.Support;
		var count_chohotro = 0;
		var count_danghotro = 0;
		var count_dahoanthanh = 0;
		listtientrinh.forEach(function(value,index){
			if (value.status == 0) {
				count_dahoanthanh++;
			}
			if (value.status == 1) {
				count_danghotro++;
			}
			if (value.status == 2) {
				count_chohotro++;
			}
			
		});
		
		function formatTrangThai(cell, row) {
			var message = '';
			if (cell === 0) {
				return (
					<span className="badge badge-primary"><i className="fas fa-check mr-1"></i>Đã hoàn thành</span>
				);
			} else if (cell === 1) {
				return (
					<span className="badge badge-success"><FaRegQuestionCircle className=" mr-1" /><span style={{"verticalAlign": "middle"}}>Đang hỗ trợ</span></span>
				);
			} else if (cell === 2) {
				return (
					<span className="badge badge-warning"><ImClock className=" mr-1" /><span style={{"verticalAlign": "middle"}}>Chờ hỗ trợ</span></span>
				);
			} else {
				return ('');
			}
		}
		function filterValueTrangThai(cell, row) {
			if (cell === 0) {
				return ('Đã hoàn thành');
			} else if (cell === 1) {
				return ('Đang hỗ trợ');
			} else if (cell === 2) {
				return ('Chờ hỗ trợ');
			} else {
				return ('');
			}
		}
		
		function calBwDateToNow(cell, row) {
			if (Number.isInteger(cell)) {
				let date_now = Math.floor(Date.now() / 1000);
				let calDateDay = (date_now - cell)/86400;
				if (calDateDay > 1) {
					return Math.ceil(calDateDay) + ' ngày trước';
				} else {
					let calDatehour = (date_now - cell)/3600;
					if (calDatehour < 0.6) {
						return Math.ceil(calDatehour*100) + ' phút trước';
					} else {
						return Math.ceil(calDatehour) + ' giờ trước';
					}
				}
			}
		}
		
		function selectOptions(cell) {
			var ar_option_vande = {};
			listtientrinh.forEach(function(value,index){
				ar_option_vande[value.vande] = value.vande;
			});
			return ar_option_vande;
		}
		
		const columns = [{
			dataField: 'id',
			text: 'ID',
			sort: true,
		}, {
			text: 'Thao tác',
			dataField: '-',
			style: {
			'white-space' : 'nowrap'
			},
			formatter: (cell, row) => 
				<Fragment>
					<Link to={"/support/"+row.id} className="btn btn-hero-primary text-center p-2 mr-1" >
						Chi tiết
					</Link>
				</Fragment>
		}, {
			dataField: 'tieude',
			text: 'Tiêu đề hỗ trợ',
			sort: true,
		}, {
			dataField: 'vande',
			text: 'Vấn đề hỗ trợ',
			sort: true,
			headerStyle: (colum, colIndex) => {
			  return { display: 'grid' };
			},
			filter: selectFilter({
				options: selectOptions
			})
		}, {
			dataField: 'status',
			text: 'Trạng thái',
			sort: true,
			formatter: formatTrangThai,
			filterValue: filterValueTrangThai
		}, {
			dataField: 'username',
			text: 'Username',
			sort: true,
			classes: 'notranslate',
		}, {
			dataField: 'created_at',
			text: 'Ngày tạo',
			sort: true,
			formatter: calBwDateToNow,
			filterValue: calBwDateToNow
		}];
        return (
            <Fragment>
				<div className="row">
					<div className="col-md-4 col-12 form-group">
						<div className="bg-warning-3 h-100" style={{"backgroundColor": "#fff8eb", "borderRadius": ".25rem"}}>
							<div className="card-body py-2">
								<div className="row align-items-center">
									<div className="col-auto pr-0">
										<ImClock className=" mr-1" style={{"color": "#ff9a02", "fontSize": "23px"}} />
									</div>
									<div className="col text-right pl-0">
										<h6 className="bold mb-0 cl-warning" style={{"color": "#ff9a02"}}>Chờ hỗ trợ</h6>
										<h3 className="bold mb-0"><span>{count_chohotro}</span></h3>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-4 col-12 form-group">
						<div className="bg-warning-3 h-100" style={{"backgroundColor": "#e1ffe5", "borderRadius": ".25rem"}}>
							<div className="card-body py-2">
								<div className="row align-items-center">
									<div className="col-auto pr-0">
										<FaRegQuestionCircle className=" mr-1" style={{"color": "#82b54b", "fontSize": "23px"}} />
									</div>
									<div className="col text-right pl-0">
										<h6 className="bold mb-0 cl-warning" style={{"color": "#82b54b"}}>Đang hỗ trợ</h6>
										<h3 className="bold mb-0"><span>{count_danghotro}</span></h3>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-4 col-12 form-group">
						<div className="bg-warning-3 h-100" style={{"backgroundColor": "#e1edff", "borderRadius": ".25rem"}}>
							<div className="card-body py-2">
								<div className="row align-items-center">
									<div className="col-auto pr-0">
										<FaRegCheckCircle className=" mr-1" style={{"color": "#0665d0", "fontSize": "23px"}} />
									</div>
									<div className="col text-right pl-0">
										<h6 className="bold mb-0 cl-warning" style={{"color": "#0665d0"}}>Đã hoàn thành</h6>
										<h3 className="bold mb-0"><span>{count_dahoanthanh}</span></h3>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<ToolkitProvider keyField="id" data={ listtientrinh } columns={ columns } search >
					{
						props => (
							<div>
								<SearchBar { ...props.searchProps } />
								<div className="row mb-4">
									<div className="col-md-12">
										{/*  this.props.auth.user.type === 1 ?
											<Fragment>
												<button onClick={() => {this.onCheckList(1)}} type="button" className="btn btn-primary mr-1">Xem tất cả</button>
												<button onClick={() => {this.onCheckList(0)}} type="button" className="btn btn-primary mr-1">Xem riêng</button>
											</Fragment>
										: '' */}
										<Link to="/support/add" className="btn btn-primary mr-1"><i className="fas fa-plus-circle mr-1"></i> Tạo hỗ trợ</Link>
										<select className="form-control custom-select select-light col-md-3 custom-limit_pagi" id="limit_pagi" name="limit_pagi" onChange={this.onChangeLimit} value={limit_pagi} >
											<option value="0">Số lịch sử hiển thị (0-500)</option>
											<option value="1">500-1000</option>
											<option value="2">1000-1500</option>
											<option value="3">1500-2000</option>
											<option value="4">2000-2500</option>
											<option value="5">2500-3000</option>
											<option value="7">3000-3500</option>
											<option value="8">3500-4000</option>
											<option value="9">4500-5000</option>
										</select>
									</div>
								</div>
								<BootstrapTable { ...props.baseProps } pagination={ paginationFactory() } filter={ filterFactory() } wrapperClasses="table-responsive" striped hover condensed />
							</div>
						)
					}
				</ToolkitProvider>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    adminpage: state.adminpage,
    Support: state.Support,
});

export default connect(mapStateToProps, { loadSP, updateLimitSP, updateTypeSP }) (List);
