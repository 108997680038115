import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import {
    Modal,
    ModalBody,
    ModalFooter
} from 'reactstrap';
import { saveEditPriceFunction } from '../../../../actions/adminPageAction';

class ModalEditPriceFunction extends Component {
    constructor(props) {
        super(props);
        let dataPrice = this.props.adminpage.itempricefunction_select;

        this.state = {
            formdata: {
                start_price_api: dataPrice.prices_api_min,
                end_price_api: dataPrice.prices_api_max,
                start_price_web: dataPrice.prices_web,
                price_bh_web: dataPrice.min_bh_web,
                price_bh_api: dataPrice.min_bh_api,
                id_edit: this.props.adminpage.itempricefunction_select.id
            }
        }
    }

    onChangeForm = (e) => {
        this.setState({
            formdata: {
                ...this.state.formdata,
                [e.target.name]: e.target.value
            }
        });
    }

    render() {
        const {isOpenModal} = this.props;
        const {formdata} = this.state;
        return (
            <Fragment>
                <Modal centered={true} size="lg" toggle={(e) => {this.props.actionOpenModal()}} isOpen={isOpenModal}>
                    <div className="modal-header">
                        <p className="mb-0 text-dark">Sửa giá chức năng</p>
                        <button onClick={(e) => {this.props.actionOpenModal()}} type="button" className="close">×</button>
                    </div>
                    <ModalBody>
                        <div className="row">
							<div className="col-md-12">
                                <div className="form-group form-inline">
									<div className="col-md-5" style={{"padding": "0px", "height": "60px"}}>
										<span className="mr-4" style={{"fontWeight": "600"}}>Giá gốc:</span>
										<input onChange={(e) => {this.onChangeForm(e)}} value={formdata.start_price_api} type="number" name="start_price_api" className="form-control" />
									</div>
									<div className="col-md-1 custom-range-price">
										<span >-</span>
									</div>
									<div className="col-md-6" style={{"padding": "0px", "height": "60px"}}>
										<input onChange={(e) => {this.onChangeForm(e)}} value={formdata.end_price_api} type="number" name="end_price_api" className="form-control mr-9" />
										<span className="text-danger">Nên để giá gấp 10-20 lần giá gốc</span>
									</div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label className="mr-4">Giá bán:</label>
                                    <input onChange={(e) => {this.onChangeForm(e)}} value={formdata.start_price_web} type="number" name="start_price_web" className="form-control" />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label className="mr-4">Giá gốc (có bảo hành):</label>
                                    <input onChange={(e) => {this.onChangeForm(e)}} value={formdata.price_bh_api} type="number" name="price_bh_api" className="form-control" />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label className="mr-4">Giá bán (có bảo hành):</label>
                                    <input onChange={(e) => {this.onChangeForm(e)}} value={formdata.price_bh_web} type="number" name="price_bh_web" className="form-control" />
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button type="button" className="btn btn-primary" onClick={(e) => {this.props.saveEditPriceFunction(formdata, this.props.actionOpenModal)}} >Lưu</button>
                        <button type="button" className="btn btn-danger" onClick={(e) => {this.props.actionOpenModal()}} >Huỷ</button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        );
    }

}

const mapStateToProps = state => ({
    adminpage: state.adminpage
});

export default connect(mapStateToProps, {saveEditPriceFunction}) (ModalEditPriceFunction);
