import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { login } from "../../actions/authActions";
import { clearErrors } from "../../actions/errorActions";
import RegisterModal8 from './Register8';
import ServiceModal8 from './Service8';
import ForgotPasswordModal8 from './ForgotPassword8';
import png_2lky6BC from '../../iconlanding/8/2lky6BC.png';
import png_5IMnvJo from '../../iconlanding/8/5IMnvJo.png';
import png_83jvWnJ from '../../iconlanding/8/83jvWnJ.png';
import png_ebt7S8i from '../../iconlanding/8/ebt7S8i.png';
import png_HvW7lO9 from '../../iconlanding/8/HvW7lO9.png';
import png_lL29Pma from '../../iconlanding/8/lL29Pma.png';
import png_ndOZRaL from '../../iconlanding/8/ndOZRaL.png';
import png_pHXlFHT from '../../iconlanding/8/pHXlFHT.png';
import png_PIuYHRp from '../../iconlanding/8/PIuYHRp.png';
import png_pODqo7I from '../../iconlanding/8/pODqo7I.png';
import png_T8PKPLR from '../../iconlanding/8/T8PKPLR.png';
import png_tAV9Acc from '../../iconlanding/8/tAV9Acc.png';
import png_tID812f from '../../iconlanding/8/tID812f.png';
import png_XBjYfP1 from '../../iconlanding/8/XBjYfP1.png';
import png_xOIyeYo from '../../iconlanding/8/xOIyeYo.png';
import png_YePyzWc from '../../iconlanding/8/YePyzWc.png';
import png_T5LSLFW from '../../iconlanding/8/T5LSLFW.png';
import {
  BrowserRouter as Router,
  Link
} from "react-router-dom";
class Login extends Component {
  state = {
	modal: 1,
    msg: "",
    username: "",
    password: "",
    maxacminh: "",
    form_maxacminh: false,
    isLoading: false
  };
  componentDidMount(){
	if (this.props.typeform == 3) {
	  this.setState({ modal: 3 });
	}
  }
  componentDidUpdate(prevProps) {
    const { error } = this.props;
    if (error !== prevProps.error) {
      // Check for login error
      if (error.id === "LOGIN_FAIL") {
        this.setState({ msg: error.msg.msg });
      } else if (error.id === "LOGIN_FAIL_2FA") {
        this.setState({ msg: error.msg.msg, form_maxacminh: true });
      } else {
        this.setState({ msg: null });
      }
    }
  }
	clearAlert = () => {
		// Clear errors
		this.props.clearErrors();
		// this.setState({
			// modal: !this.state.modal
		// });
	}
  changeFormLogRegister = (typeform) => {
	this.props.clearErrors();
	this.setState({
		modal: typeform
	});
	
}
  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };
  onSubmit = e => {
    e.preventDefault();
    const { username, password, maxacminh } = this.state;
    this.props.clearErrors();
    this.props.login({ username, password, maxacminh });
  };
  render() {
    let isLoading = this.props.auth.isLoading;
    const domainSite = window.location.hostname;
	var config_website = {};
	if (localStorage.getItem("confignavbar") != null) {
		config_website = JSON.parse(localStorage.getItem("confignavbar"));
	} else {
		config_website = this.props.auth.config_website;
	}
	const formLogin = (
		<Router>
			<Fragment>
				<div className="landingcus8" style={{"zoom": "122%"}}>
					<div className="gustavo gustavo">
						<nav className="navbar navbar-default navbar-static-top ">
							<div className="container">
								<div className="navbar-header">
									<a className="navbar-brand" href="/" style={{"fontSize": "30px"}}>
										{domainSite}
									</a>
									<ul className="nav navbar-nav navbar-right register-btn-cus" style={{"display": "none"}}>
										<li className="signupBtn" style={{"marginLeft": "5px"}}>
											<Link to="/services" onClick={(e) => {this.changeFormLogRegister(3)}} className="nav-link">
												Dịch vụ
											</Link>
										</li>
										<li className="signupBtn" style={{"marginLeft": "5px"}}>
											<Link to="/" className="nav_link" onClick={(e) => {this.changeFormLogRegister(2)}}>
												Đăng ký
											</Link>
										</li>
									</ul>
								</div>
								<div id="navbar" className="collapse navbar-collapse">
									<ul className="nav navbar-nav navbar-right">
										<li className="  signinBtn ">
											<Link to="/services" onClick={(e) => {this.changeFormLogRegister(3)}} className="nav-link">
												Dịch vụ
											</Link>
										</li>
										<li className="  signinBtn ">
											<a className="nav_link" href="/" >Đăng nhập</a>
										</li>
										<li className="  signupBtn ">
											<Link to="/" className="nav_link" onClick={(e) => {this.changeFormLogRegister(2)}}>
												Đăng ký
											</Link>
										</li>
									</ul>
								</div>
							</div>
						</nav>
						<div className="container">
							<section id="top-banner" style={{"backgroundImage": "url("+png_T5LSLFW+")"}}>
								<div className="container">
									<div className="row">
										<div className="col-sm-5">
											<div className="icon-box">
												<img className="icon-1 abs" src={png_XBjYfP1} alt="icon"/>
												<img className="icon-2 abs" src={png_T8PKPLR} alt="icon"/>
												<img className="icon-3 abs" src={png_HvW7lO9} alt="icon"/>
												<img className="icon-4 abs" src={png_HvW7lO9} alt="icon"/>
											</div>
											<h1 className="main-head">An toàn <br/>tin cậy <span className="sphere"></span>
											</h1>
											<p>Chúng tôi đã dẫn đầu trong lĩnh vực mạng xã hội trong<br/>hơn 8 năm nay. Chúng tôi biết, điều gì hiệu quả và điều gì không. </p>
											<div className="form-wrap">
												{isLoading ? (
													<div className="preloader">
														<div className="spinner-grow spinner-grow-sm text-primary" role="status" style={{ "marginTop": "50%" }}>
														  <span className="sr-only"></span>
														</div>
														<div className="spinner-grow spinner-grow-sm text-danger" role="status" style={{ "marginTop": "50%" }}>
														  <span className="sr-only">.</span>
														</div>
														<div className="spinner-grow spinner-grow-sm text-warning" role="status" style={{ "marginTop": "50%" }}>
														  <span className="sr-only"></span>
														</div>
													</div>
												):('')}
												{this.state.msg ? (
													<div className="alert alert-warning d-flex align-items-center justify-content-between" role="alert" >
													  <div className="flex-fill mr-3">
														<p className="mb-0">{this.state.msg}</p>
													  </div>
													  <div className="flex-00-auto">
														<i className="fa fa-fw fa-exclamation-circle"></i>
													  </div>
													</div>
												  ) : null}
												  
												<form className="js-validation-signup" onSubmit={this.onSubmit} method="POST" >
													<div className="form-group icon-group">
														<input name="username" placeholder="Nhập tài khoản" required="" id="username" type="text" className="form-control" onChange={this.onChange} />
														<span className="input-icon">
															<img src={png_pHXlFHT} alt="icon"/>
														</span>
													</div>
													<div className="form-group form-group__password icon-group">
														<input name="password" placeholder="Nhập mật khẩu" required="" id="password" type="password" className="form-control" onChange={this.onChange} />
														<span className="input-icon">
															<img src={png_PIuYHRp} alt="icon"/>
														</span>
														{
															!this.state.form_maxacminh ?
																<Link to="/" onClick={(e) => {this.changeFormLogRegister(4)}} style={{"color": "#1f2324", "float": "right", "marginTop": "5px", "marginRight": "20px"}}>
																	Quên mật khẩu?
																</Link>
															: ""
														}
													</div>
													{
														this.state.form_maxacminh ?
															<div className="form-group form-group__maxacminh icon-group">
																<input name="maxacminh" placeholder="Nhập mã xác minh" required="" id="maxacminh" type="text" className="form-control" onChange={this.onChange} />
																<span className="input-icon">
																	<img src={png_PIuYHRp} alt="icon"/>
																</span>
																<Link to="/" onClick={(e) => {this.changeFormLogRegister(4)}} style={{"color": "#1f2324", "float": "right", "marginTop": "5px", "marginRight": "20px"}}>
																	Quên mật khẩu?
																</Link>
															</div>
														: ""
													}
													<div className="row">
														<div className="col-xs-6">
															<button type="submit" className="btn btn-primary">Đăng nhập</button>
														</div>
													</div>
													<br/>
													<div className="row">
														<div className="col-sm-12">
															Bạn chưa có tài khoản? <Link to="/" onClick={(e) => {this.changeFormLogRegister(2)}}> Đăng ký</Link>
														</div>
													</div>
												</form>
											</div>
										</div>
									</div>
								</div>
							</section>
							<section id="three-wrap">
								<div className="container">
									<div className="row">
										<div className="col-sm-4">
											<div className="threeBox">
												<div className="threeBoxIcon">
													<img src={png_pODqo7I} alt="icon"/>
												</div>
												<h2>0.12 GIÂY</h2>
												<h5>Mỗi đơn hàng được tạo ra</h5>
												<p>Đơn hàng đều đều, dịch vụ đáng tin cậy ! Sự lựa chọn đầu tiên của mọi người.</p>
											</div>
										</div>
										<div className="col-sm-4">
											<div className="threeBox active" style={{"backgroundImage": "url("+png_2lky6BC+")"}}>
												<div className="threeBoxIcon">
													<img src={png_ndOZRaL} alt="icon"/>
												</div>
												<h2>11308654</h2>
												<h5>Đơn hàng hoàn thành</h5>
												<p>Tốc độ hoàn thành đơn rất nhanh, và hỗ trợ khách hàng 24/7</p>
											</div>
										</div>
										<div className="col-sm-4">
											<div className="threeBox">
												<div className="threeBoxIcon">
													<img src={png_83jvWnJ} alt="icon"/>
												</div>
												<h2>10 VNĐ</h2>
												<h5>Đã có thể sử dụng dịch vụ</h5>
												<p>Giá thành rẻ nhất trên thị trường hiện nay, có thể sử dụng dịch vụ chỉ từ 10 VNĐ</p>
											</div>
										</div>
									</div>
								</div>
							</section>
							<section id="how">
								<div className="container">
									<div className="row">
										<div className="col-sm-12">
											<h2 className="main-head">Quy trình làm việc <span className="sphere"></span>
											</h2>
											<p>Hãy tham gia với chúng, hệ thống hơn 15.000 thành viên trên mọi nơi. Là dịch vụ tốt nhất trên thị trường và rẻ nhất. Tham gia với chúng tôi ngay bây giờ. Rất nhiều ưu đãi dành cho các thành viên tham gia </p>
											<img src={png_lL29Pma}/>
										</div>
									</div>
								</div>
							</section>
							<section id="happy">
								<div className="container">
									<div className="row">
										<div className="col-sm-12">
											<h2 className="main-head">Đánh giá của khách hàng về chúng tôi <span className="sphere"></span>
											</h2>
											<p>Tại {domainSite}, sự hài lòng được đảm bảo. Nếu khách hàng hài lòng thì chúng tôi mới có động lực để phát triển, do đó chúng tôi rất quan tâm đến việc làm bạn HÀI LÒNG! </p>
										</div>
									</div>
									<div className="row">
										<div className="col-sm-4">
											<div className="client-box">
												<div className="client-img-wrap">
													<img src={png_YePyzWc} alt="client-img"/>
												</div>
												<div className="client-content">
													<div className="stars">
														<i className="fas fa-star"></i>
														<i className="fas fa-star"></i>
														<i className="fas fa-star"></i>
														<i className="fas fa-star"></i>
														<i className="fas fa-star"></i>
													</div>
													<p>Dịch vụ này hoàn toàn tuyệt vời, một trong những dịch vụ quan trọng nhất mà người ta có thể có đối với công việc kinh doanh của họ. Tôi luôn tin rằng dịch vụ của {domainSite} là tốt nhất, tạo ra một sự thay đổi cực nhanh. Cảm ơn tất cả các bạn! </p>
													<h4>Đỗ Quang Sơn</h4>
												</div>
											</div>
										</div>
										<div className="col-sm-4">
											<div className="client-box">
												<div className="client-img-wrap">
													<img src={png_ebt7S8i} alt="client-img"/>
												</div>
												<div className="client-content">
													<div className="stars">
														<i className="fas fa-star"></i>
														<i className="fas fa-star"></i>
														<i className="fas fa-star"></i>
														<i className="fas fa-star"></i>
														<i className="fas fa-star"></i>
													</div>
													<p>Tôi đánh giá cao các dịch vụ của {domainSite} và rất cảm ơn. Thông tin liên lạc 24/7 và hoàn thành đơn nhanh chóng và đúng giờ. </p>
													<h4>Bùi Thu Trang</h4>
												</div>
											</div>
										</div>
										<div className="col-sm-4">
											<div className="client-box">
												<div className="client-img-wrap">
													<img src={png_5IMnvJo} alt="client-img"/>
												</div>
												<div className="client-content">
													<div className="stars">
														<i className="fas fa-star"></i>
														<i className="fas fa-star"></i>
														<i className="fas fa-star"></i>
														<i className="fas fa-star"></i>
														<i className="fas fa-star"></i>
													</div>
													<p>{domainSite} đã thực sự giúp tôi trong công việc của tôi. Tôi sử dụng dịch vụ để thúc đẩy SEO của khách hàng trên Trang web, {domainSite} đã khiến công việc này trở nên dễ dàng hơn gấp 1000 lần đối với tôi. </p>
													<h4>Trần Bảo Khánh</h4>
												</div>
											</div>
										</div>
									</div>
								</div>
							</section>
							<section id="power">
								<div className="container">
									<div className="row">
										<div className="col-sm-12">
											<h2 className="main-head">Tối ưu giao diện sử dụng <span className="sphere"></span>
											</h2>
											<p> Sử dụng để quảng bá thương hiệu trên các nền tảng truyền thông xã hội như Facebook, Instagram, Youtube, Twitter và nhiều hơn nữa. Nếu bạn quan tâm đến việc mở rộng quy mô bán hàng trực tuyến của mình trên các nền tảng truyền thông xã hội nổi bật, thì lựa chọn tốt nhất của bạn là sử dụng BOP (BESTOFPANEL), nơi chúng tôi cung cấp các dịch vụ để giúp bạn tăng quy mô bán hàng trực tuyến của mình trên TẤT CẢ các nền tảng với mức giá rẻ nhất. </p>
										</div>
									</div>
									<div className="row">
										<div className="col-sm-6">
											<img src={png_tAV9Acc} alt="power-img"/>
										</div>
										<div className="col-sm-6">
											<h4>Tại sao bạn nên quan tâm đến trang web của chúng tôi? <span className="sphere"></span>
											</h4>
											<p> Chúng tôi cảm thấy tự hào về {domainSite} đã đạt được thành công trong 8 năm. Sự tận tâm, làm việc chăm chỉ, kỹ năng, sự chân thành và lòng trung thành của chúng tôi đối với khách hàng khiến chúng tôi cảm thấy tự hào về thành quả tuyệt vời này. Chúng tôi luôn cố gắng cung cấp cho bạn những dịch vụ tốt hơn với giá rẻ hơn so với các đối thủ cạnh tranh và các trang web khác. </p>
											<p>Chúng tôi cung cấp cho bạn mọi dịch vụ trên các nền tảng xã hội PHỔ BIẾN hoặc chưa, có tỷ lệ tụt rất thấp.</p>
											<p>Chúng tôi hướng đến mục tiêu lâu dài, vì vậy chúng tôi sẽ không làm bạn thất vọng dù là dịch vụ nhỏ nhất.</p>
											<ul className="btn-wrap">
												<li>
													<Link to="/" className="btn btn-primary" onClick={(e) => {this.changeFormLogRegister(2)}}>Đăng ký ngay</Link>
												</li>
											</ul>
										</div>
									</div>
									<div className="row">
										<div className="col-sm-6">
											<h4>Hỗ trợ 247/ <span className="sphere"></span>
											</h4>
											<p> </p>
											<p>Chúng tôi cũng cung cấp dịch vụ hỗ trợ khách hàng 24 / 7 thông qua nhiều phương tiện khác nhau như Fanpage, Zalo, Holine, v.v. Chúng tôi cũng có các tùy chọn hoàn tiền hoặc hủy đơn đặt hàng, nếu có vấn đề phát sinh. Vì vậy, chúng tôi cam kết rằng tiền của bạn sẽ luôn nằm trong tay an toàn nhất. </p>
											<ul className="btn-wrap">
												<li>
													<Link to="/" className="btn btn-primary" onClick={(e) => {this.changeFormLogRegister(2)}}>Đăng ký ngay</Link>
												</li>
											</ul>
										</div>
										<div className="col-sm-6">
											<img src={png_tID812f} alt="power-img"/>
										</div>
									</div>
									<div className="row">
										<div className="col-sm-6">
											<img src={png_xOIyeYo} alt="power-img"/>
										</div>
										<div className="col-sm-6">
											<h4>Hệ thống tích hợp trang web đại lý <span className="sphere"></span>
											</h4>
											<p>Chúng tôi có đầy đủ chức năng cho những bạn muốn trở thành đại lý của chúng tôi. Chỉ với 1 tên miền của bạn và vài cú click chuột là bạn đã có thể mở 1 trang web đại lý để kiếm tiền từ dịch vụ của chúng tôi </p>
											<ul className="btn-wrap">
												<li>
													<Link to="/" className="btn btn-primary" onClick={(e) => {this.changeFormLogRegister(2)}}>Đăng ký ngay</Link>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</section>
						</div>
						<footer>
							<div className="footer-top">
								<div className="container">
									<div className="row">
										<div className="col-sm-6">
											<h2>Bạn đã sẵn sàng bắt đầu với chúng tôi?</h2>
										</div>
										<div className="col-sm-6">
											<Link to="/" className="joinBtn" onClick={(e) => {this.changeFormLogRegister(2)}}>Tham Gia Ngay</Link>
										</div>
									</div>
								</div>
							</div>
							<div className="footer-main">
								<div className="container">
									<div className="row">
										<div className="col-sm-6">
											<h4 className="widget-title">{domainSite}</h4>
											<p>Đã có 8 năm hoạt động trong lĩnh vực Online Maketing, nắm bắt được sự thay đổi và định hứng được sự phát triển của thị trường, đi đầu trong lĩnh vực online maketing, cung cấp đa dạng các dịch vụ, giúp người dùng luôn tiếp cận được với mức giá phải chăng và hiệu quả maketing tối ưu nhất.</p>
										</div>
										<div className="col-sm-3">
											<h4 className="widget-title">Hữu ích</h4>
											<ul>
												<li>
													<a href="/"><i className="fa fa-angle-right" aria-hidden="true"></i> Sign in</a>
												</li>
												<li>
													<a href="/"><i className="fa fa-angle-right" aria-hidden="true"></i> Sign up</a>
												</li>
											</ul>
										</div>
										<div className="col-sm-3">
											<h4 className="widget-title">Dịch vụ</h4>
											<ul>
												<li>
													<a href=""><i className="fa fa-angle-right" aria-hidden="true"></i> Facebook</a>
												</li>
												<li>
													<a href=""><i className="fa fa-angle-right" aria-hidden="true"></i> Instagram</a>
												</li>
												<li>
													<a href="/"><i className="fa fa-angle-right" aria-hidden="true"></i> Tiktok</a>
												</li>
												<li>
													<a href="/"><i className="fa fa-angle-right" aria-hidden="true"></i> Youtube</a>
												</li>
												<li>
													<a href="/"><i className="fa fa-angle-right" aria-hidden="true"></i> Twitter</a>
												</li>
												<li>
													<a href="/"><i className="fa fa-angle-right" aria-hidden="true"></i> Shopee</a>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
							<div className="footer-bottom">
								<div className="container">
									<div className="row">
										<div className="col-sm-6">© Copyright {domainSite}. All Rights Reserved</div>
										<div className="col-sm-6">
											<ul className="copyright-menu">
												{/* <li>
													<a href="/">Terms of Service</a>
												</li>
												<li>
													<a href="/">Privacy Policy</a>
												</li> */}
											</ul>
										</div>
									</div>
								</div>
							</div>
						</footer>
					</div>
				</div>
			</Fragment>
		</Router>
	);
	var path_check = window.location.pathname;
	if (window.location.pathname.length > 1) {
		path_check = window.location.pathname.replace(/\/+$/g, '');
	}
	const formRegister = (
		<Fragment>
			<RegisterModal8 action_login={(e) => {this.changeFormLogRegister(1)}} action_service={(e) => {this.changeFormLogRegister(3)}} />
		</Fragment>
	);
	const formService = (
		<Fragment>
			<ServiceModal8 action_login={(e) => {this.changeFormLogRegister(1)}} action_register={(e) => {this.changeFormLogRegister(2)}} />
		</Fragment>
	);
	const formForgotPassword = (
		<Fragment>
			<ForgotPasswordModal8 action_login={(e) => {this.changeFormLogRegister(1)}} action_register={(e) => {this.changeFormLogRegister(2)}} action_service={(e) => {this.changeFormLogRegister(3)}} action_reset={path_check} />
		</Fragment>
	);
	return (
		<Fragment>
			{
				path_check === '/resetpassword' ?
					formForgotPassword
				: this.state.modal == 1 ?
					formLogin
				: this.state.modal == 2 ?
					formRegister
				: this.state.modal == 3 ?
					formService
				: this.state.modal == 4 ?
					formForgotPassword
				: ''
			}
		</Fragment>
	);
  }
}
Login.propTypes = {
  auth: PropTypes.object.isRequired,
  error: PropTypes.object,
  login: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  error: state.error
});
export default connect(mapStateToProps, { login, clearErrors })(Login);
