import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import axios from 'axios';
import {
	LOAD_BGSL,
	// ADD_BSR,
	UPDATE_BGSL,
	// UPDATE_BG_GETID,
	UPDATE_BGSL_TAB,
	UPDATE_BGSL_TGT,
	LOAD_PRICE_BGSL,
	LIMIT_PAGI_BGSL,
	TYPE_BGSL,
	LOADING_BGSL
} from "../actions/types";
import Swal from 'sweetalert2';
import { loadUser } from "./authActions";

export const loadBGSL = (type = 0, page = 1, obj_search = '') => (dispatch, getState) => {
	dispatch({
		type: LOADING_BGSL,
		payload: true
	});
	axios.get('/api/facebook_buff/list?type_api=buffgroupsharelive&type='+type+'&page='+page+'&obj_search='+obj_search, tokenConfig(getState))
		.then(res => {
			dispatch({
				type: LOADING_BGSL,
				payload: false
			});
			if (res.data.status === 200) {
				dispatch({
					type: LOAD_BGSL,
					payload: res.data
				});
			}
		});
};

export const loadPriceBGSL = () => (dispatch, getState) => {
	axios.get('/api/buffseedingreview/price', tokenConfig(getState))
		.then(res => {
			if (res.data.status === 200) {
				dispatch({
					type: LOAD_PRICE_BGSL,
					payload: res.data.data
				});
			}
		});
};

export const addBGSL = (body) => (dispatch, getState) => {
	dispatch({
		type: LOADING_BGSL,
		payload: true
	});
	axios.post('/api/facebook_buff/create', body, tokenConfig(getState))
		.then(res => {
			dispatch({
				type: LOADING_BGSL,
				payload: false
			});
			dispatch(loadUser());
			if (res.data.status === 200) {
				Swal.fire({
					icon: 'success',
					html: 'Tạo tiến trình seeding thành công, hệ thống sẽ tự động tăng theo thời gian bạn đã chọn <br/><br/> Nếu không đủ lượt seeding hệ thống sẽ tự động hoàn lại số tiền chưa chạy'
				});
			}
			if (res.data.status === 400) {
				var message = '';
				if (res.data.error) {
					if (res.data.error.object_id) {
						message = res.data.error.object_id[0];
					} else if (res.data.error.quantity) {
						message = res.data.error.quantity[0];
					} else {
						message = res.data.message;
					}
				} else {
					message = res.data.message;
				}
				
				Swal.fire({
					icon: 'error',
					title: 'Lỗi',
					text: message,
				});
			}
		}).catch(err => {
			dispatch({
				type: LOADING_BGSL,
				payload: false
			});
			console.log(err);
			openBtComfirmAlert('Có lỗi xảy ra!', 'error', 'Lỗi');
		});
};

export const updateBGSL = (body) => (dispatch) => {
	dispatch({
		type: UPDATE_BGSL,
		payload: body
	})
};

export const updateLimitBGSL = (body) => (dispatch) => {
	dispatch({
		type: LIMIT_PAGI_BGSL,
		payload: body
	})
};

export const updateTypeBGSL = (body) => (dispatch) => {
	dispatch({
		type: TYPE_BGSL,
		payload: body
	})
};

// export const getID = (body) => (dispatch, getState) => {
	// let bodysend = {
		// id_user: getState().auth.user.id,
		// link: body.lhi
	// };
	// dispatch({
		// type: LOADING_BGSL,
		// payload: true
	// });
	// axios.post('/api/checklinkfb/check/', bodysend, tokenConfig(getState))
	// .then(res => {
		// dispatch({
			// type: LOADING_BGSL,
			// payload: false
		// });
		// if (res.data.status === 200) {
			// dispatch({
				// type: UPDATE_BG_GETID,
				// payload: res.data
			// });
		// } else {
			// Swal.fire({
				// icon: 'error',
				// title: 'Lỗi',
				// text: res.data.msg,
			// });
		// }
	// }).catch(err => {
		// dispatch({
			// type: LOADING_BGSL,
			// payload: false
		// });
		// openBtComfirmAlert('Có lỗi xảy ra!', 'error', 'Lỗi');
    // });
// };

export const changeTab = (body) => (dispatch, getState) => {
	dispatch({
		type: UPDATE_BGSL_TAB,
		payload: body
	});
};

export const updateTangGiaThem = (body) => (dispatch) => {
	dispatch({
		type: UPDATE_BGSL_TGT,
		payload: body
	})
};

export const openBtComfirmAlert = (message, type, title = '') => {
	Swal.fire({
		icon: type,
		title: title,
		text: message,
	});
	return false;
}
export const upTangGiaThem = (body) => (dispatch, getState) => {
	dispatch({
		type: LOADING_BGSL,
		payload: true
	});
	axios.post('/api/facebook_buff/updatepriceorder', body, tokenConfig(getState))
		.then(res => {
			dispatch({
				type: LOADING_BGSL,
				payload: false
			});
			if (res.data.status === 200) {
				Swal.fire({
					icon: 'success',
					html: 'Tạo tiến trình seeding thành công, hệ thống sẽ tự động tăng theo thời gian bạn đã chọn <br/><br/> Nếu không đủ lượt seeding hệ thống sẽ tự động hoàn lại số tiền chưa chạy'
				});
				dispatch(loadBGSL());
			}
			if (res.data.status === 400) {
				var message = '';
				if (res.data.error.object_id) {
					message = res.data.error.object_id[0];
				} else {
					message = res.data.message;
				}
				Swal.fire({
					icon: 'error',
					title: 'Lỗi',
					text: message,
				});
			}
		});
};

export const upHuyOrder = (body) => (dispatch, getState) => {
	dispatch({
		type: LOADING_BGSL,
		payload: true
	});
	axios.post('/api/facebook_buff/removeorder', body, tokenConfig(getState))
		.then(res => {
			dispatch({
				type: LOADING_BGSL,
				payload: false
			});
			if (res.data.status === 200) {
				Swal.fire({
					icon: 'success',
					text: res.data.message,
				});
				dispatch(loadBGSL());
			}
			if (res.data.status === 400) {
				var message = '';
				if (res.data) {
					message = res.data.message;
				}
				Swal.fire({
					icon: 'error',
					title: 'Lỗi',
					text: message,
				});
			}
		});
};

export const saveEditOrderAdmin = (data, closeM) => (dispatch, getState) => {
	let bodysend = {
		formdata: data,
		type_api: 'buffgroupsharelive'
	};
	dispatch({
		type: LOADING_BGSL,
		payload: true
	});
	axios.post('/api/facebook_buff/admin_edit_order/', bodysend, tokenConfig(getState))
	.then(res => {
		dispatch({
			type: LOADING_BGSL,
			payload: false
		});
		if (res.data.status === 200) {
			dispatch(loadBGSL());
			closeM();
			Swal.fire(
	      		'Thành công!',
	      		'Sửa thành công',
	      		'success'
	    	)
		} else {
			openBtComfirmAlert(res.data.message, 'error', 'Lỗi');
		}
	}).catch(err => {
		dispatch({
			type: LOADING_BGSL,
			payload: false
		});
		console.log(err);
		openBtComfirmAlert('Có lỗi xảy ra!', 'error', 'Lỗi');
    });
};

export const reLoadIdFb = (id_order, id_key) => (dispatch, getState) => {
	if (id_order === null || typeof id_order === undefined) {
		Swal.fire(
      		'Lỗi!',
      		'Không có id order',
      		'error'
    	);
		return false;
	}

	let bodysend = {
		id: id_order,
		id_key: id_key,
		type: 'buffgroupsharelive'
	};

	dispatch({
		type: LOADING_BGSL,
		payload: true
	});
	axios.post('/api/facebook_buff/reloadidfb/', bodysend, tokenConfig(getState))
	.then(res => {
		dispatch({
			type: LOADING_BGSL,
			payload: false
		});
		if (res.data.status === 200) {
			dispatch(loadBGSL());
			Swal.fire(
	      		'Thành công!',
	      		'ID fix thành công',
	      		'success'
	    	)
		} else {
			openBtComfirmAlert(res.data.message, 'error', 'Lỗi');
		}
	}).catch(err => {
		dispatch({
			type: LOADING_BGSL,
			payload: false
		});
		openBtComfirmAlert('Có lỗi xảy ra!', 'error', 'Lỗi');
    });
}

export const getConfigMoDong = (type = '') => (dispatch, getState) => {
	dispatch({
		type: LOADING_BGSL,
		payload: true
	});

	axios.get('/api/facebook_buff/getconfig?type_api=buffsharegroup', tokenConfig(getState))
	.then(res => {
		dispatch({
			type: LOADING_BGSL,
			payload: false
		});
		if (res.data.status === 200) {
			dispatch({
				type: 'CONFIG_MO_DONG_BGSL',
				payload: res.data
			});
		}
	});
}

export const getIDVideo = (body) => (dispatch, getState) => {
	let bodysend = {
		id_user: getState().auth.user.id,
		link: body
	};
	dispatch({
		type: LOADING_BGSL,
		payload: true
	});
	return new Promise(function(resolve, reject){
		axios.post('/api/checklinkfb/check_video/', bodysend, tokenConfig(getState))
			.then(res => {
				dispatch({
					type: LOADING_BGSL,
					payload: false
				});
				if (res.data.status === 200) {
					resolve(res.data.id);
				} else {
					resolve('');
					Swal.fire({ icon: 'error', title: 'Lỗi', text: res.data.msg });
				}
			});
	});
};

export const getIDPost = (body) => (dispatch, getState) => {
	let bodysend = {
		id_user: getState().auth.user.id,
		link: body
	};
	dispatch({
		type: LOADING_BGSL,
		payload: true
	});
	return new Promise(function(resolve, reject){
		axios.post('/api/checklinkfb/check_post/', bodysend, tokenConfig(getState))
			.then(res => {
				dispatch({
					type: LOADING_BGSL,
					payload: false
				});
				if (res.data.status === 200) {
					resolve(res.data.id);
				} else {
					resolve('');
					Swal.fire({ icon: 'error', title: 'Lỗi', text: res.data.msg });
				}
			});
	});
};

export const tokenConfig = getState => {
	// Get token from localhost
	const token = getState().auth.token;
	// Headers
	const config = {
		headers: {
			"Content-type": "application/json"
		}
	};
	
	// If token, add to headers
	if (token) {
		config.headers['ht-token'] = token;
	}
	
	return config;
};