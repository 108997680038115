
const date7dnext = () => {
	var result = new Date();
  	result.setDate(result.getDate() + 7);
  	return result;
}

const initialState = {
	loadingblock: false,
	listBuffSub: [],
	"list_sticker":[],
	datapricebuffsub: {
		like: {
			min_bh_web: 0,
			prices_web: 0,
		},
		care: {
			min_bh_web: 0,
			prices_web: 0,
		},
		love: {
			min_bh_web: 0,
			prices_web: 0,
		},
		haha: {
			min_bh_web: 0,
			prices_web: 0,
		},
		wow: {
			min_bh_web: 0,
			prices_web: 0,
		},
		sad: {
			min_bh_web: 0,
			prices_web: 0,
		},
		angry: {
			min_bh_web: 0,
			prices_web: 0,
		},
		like_clone: {
			min_bh_web: 0,
			prices_web: 0,
		},
		care_clone: {
			min_bh_web: 0,
			prices_web: 0,
		},
		love_clone: {
			min_bh_web: 0,
			prices_web: 0,
		},
		haha_clone: {
			min_bh_web: 0,
			prices_web: 0,
		},
		wow_clone: {
			min_bh_web: 0,
			prices_web: 0,
		},
		sad_clone: {
			min_bh_web: 0,
			prices_web: 0,
		},
		angry_clone: {
			min_bh_web: 0,
			prices_web: 0,
		},
		like_v2: {
			min_bh_web: 0,
			prices_web: 0,
		},
		care_v2: {
			min_bh_web: 0,
			prices_web: 0,
		},
		love_v2: {
			min_bh_web: 0,
			prices_web: 0,
		},
		haha_v2: {
			min_bh_web: 0,
			prices_web: 0,
		},
		wow_v2: {
			min_bh_web: 0,
			prices_web: 0,
		},
		sad_v2: {
			min_bh_web: 0,
			prices_web: 0,
		},
		angry_v2: {
			min_bh_web: 0,
			prices_web: 0,
		},
		like_cmt: {
			min_bh_web: 0,
			prices_web: 0,
		},
		care_cmt: {
			min_bh_web: 0,
			prices_web: 0,
		},
		love_cmt: {
			min_bh_web: 0,
			prices_web: 0,
		},
		haha_cmt: {
			min_bh_web: 0,
			prices_web: 0,
		},
		wow_cmt: {
			min_bh_web: 0,
			prices_web: 0,
		},
		sad_cmt: {
			min_bh_web: 0,
			prices_web: 0,
		},
		angry_cmt: {
			min_bh_web: 0,
			prices_web: 0,
		},
		comment: {
			min_bh_web: 0,
			prices_web: 0,
		},
		share: {
			min_bh_web: 0,
			prices_web: 0,
		},
		comment_pro: {
			min_bh_web: 0,
			prices_web: 0,
		},
		comment_sv3: {
			min_bh_web: 0,
			prices_web: 0,
		},
		comment_sv4: {
			min_bh_web: 0,
			prices_web: 0,
		},
		comment_sv5: {
			min_bh_web: 0,
			prices_web: 0,
		},
		comment_sv6: {
			min_bh_web: 0,
			prices_web: 0,
		},
		comment_sv7: {
			min_bh_web: 0,
			prices_web: 0,
		},
		comment_sv8: {
			min_bh_web: 0,
			prices_web: 0,
		},
		like_v3: {
			min_bh_web: 0,
			prices_web: 0,
		},
		care_v3: {
			min_bh_web: 0,
			prices_web: 0,
		},
		love_v3: {
			min_bh_web: 0,
			prices_web: 0,
		},
		haha_v3: {
			min_bh_web: 0,
			prices_web: 0,
		},
		wow_v3: {
			min_bh_web: 0,
			prices_web: 0,
		},
		sad_v3: {
			min_bh_web: 0,
			prices_web: 0,
		},
		angry_v3: {
			min_bh_web: 0,
			prices_web: 0,
		},
	},
	formData: {
		locnangcao: 0,
		locnangcao_gt: 0,
		locnangcao_dotuoi_start: 0,
		locnangcao_dotuoi_end: 13,
		locnangcao_banbe_start: 0,
		locnangcao_banbe_end: 100,
		profile_user: '',
		loaiseeding: 'comment',
		baohanh: 0,
		sltang: 0,
		tocdocomment: 40,
		giatien: 0,
		ghichu: '',
		startDatebh: new Date(),
		EndDatebh: date7dnext(),
		type: '',
		list_messages: [],
		"sticker": [],
		"sticker_pack":{},
		"commentanh": "",
	},
	facebookbuffcomment_mo_dong: 1,
	facebookbuffcomment_pro_mo_dong: 1,
	facebookbuffcomment_sv3_mo_dong: 1,
	facebookbuffcomment_sv4_mo_dong: 1,
	facebookbuffcomment_sv5_mo_dong: 1,
	facebookbuffcomment_sv6_mo_dong: 1,
	facebookbuffcomment_sv7_mo_dong: 1,
	facebookbuffcomment_sv8_mo_dong: 1,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case 'LIST_BUFF_ALL_FB':
    	return {
			...state,
			listBuffSub: action.payload
		};
    case 'LOADING_BLOCK_ALL_FB':
		return {
			...state,
			loadingblock: action.payload
		};
	case 'DATA_PRICE_IS_FUNCTION_BUFFSUB_LCS':
		return {
			...state,
			datapricebuffsub: merDataPricePage(action.payload)
		}
	case 'CHANGE_DATA_FORM_YTB_LCS_COMMENT':
		return {
			...state,
			formData: action.payload
		}
	case 'CONFIG_MO_DONG_LCS_COMMENT':
		return {
			...state,
			facebookbuffcomment_mo_dong: action.payload.facebookbuffcomment_mo_dong,
			facebookbuffcomment_pro_mo_dong: action.payload.facebookbuffcomment_pro_mo_dong,
			facebookbuffcomment_sv3_mo_dong: action.payload.facebookbuffcomment_sv3_mo_dong,
			facebookbuffcomment_sv4_mo_dong: action.payload.facebookbuffcomment_sv4_mo_dong,
			facebookbuffcomment_sv5_mo_dong: action.payload.facebookbuffcomment_sv5_mo_dong,
			facebookbuffcomment_sv6_mo_dong: action.payload.facebookbuffcomment_sv6_mo_dong,
			facebookbuffcomment_sv7_mo_dong: action.payload.facebookbuffcomment_sv7_mo_dong,
			facebookbuffcomment_sv8_mo_dong: action.payload.facebookbuffcomment_sv8_mo_dong,
		};
	case 'UPDATE_STICKER':
		// return {
			// ...state,
			// "sticker_pack": action.payload
		// };
		return {
			...state,
			"formData": {
				...state.formData,
				"sticker_pack": action.payload,
			}
		};
	case 'LOAD_STICKER':
		return {
			...state,
			"list_sticker": action.payload
		};
	case 'UPDATE_LIST_STICKER':
		return {
			...state,
			"list_sticker": action.payload
		};
    default:
      return state;
  }
}

function merDataPricePage(data) {
	const dataOld = initialState.datapricebuffsub;
	let dataNew = {
		...dataOld
	}
	if (data.length > 0) {
		data.forEach(function(v, i){
			if (v.name_table === 'fb_likecommentshare_like') {
				dataNew['like'] = v;
			} else if (v.name_table === 'fb_likecommentshare_care') {
				dataNew['care'] = v;
			} else if (v.name_table === 'fb_likecommentshare_love') {
				dataNew['love'] = v;
			} else if (v.name_table === 'fb_likecommentshare_haha') {
				dataNew['haha'] = v;
			} else if (v.name_table === 'fb_likecommentshare_wow') {
				dataNew['wow'] = v;
			} else if (v.name_table === 'fb_likecommentshare_sad') {
				dataNew['sad'] = v;
			} else if (v.name_table === 'fb_likecommentshare_angry') {
				dataNew['angry'] = v;
			} else if (v.name_table === 'fb_likecommentshare_comment') {
				dataNew['comment'] = v;
				initialState.formData['giatien'] = v.prices_web;
			} else if (v.name_table === 'fb_likecommentshare_comment_pro') {
				dataNew['comment_pro'] = v;
			} else if (v.name_table === 'fb_likecommentshare_comment_sv3') {
				dataNew['comment_sv3'] = v;
			} else if (v.name_table === 'fb_likecommentshare_comment_sv4') {
				dataNew['comment_sv4'] = v;
			} else if (v.name_table === 'fb_likecommentshare_comment_sv5') {
				dataNew['comment_sv5'] = v;
			} else if (v.name_table === 'fb_likecommentshare_comment_sv6') {
				dataNew['comment_sv6'] = v;
			} else if (v.name_table === 'fb_likecommentshare_comment_sv7') {
				dataNew['comment_sv7'] = v;
			} else if (v.name_table === 'fb_likecommentshare_comment_sv8') {
				dataNew['comment_sv8'] = v;
			} else if (v.name_table === 'fb_likecommentshare_share') {
				dataNew['share'] = v;
			} else if (v.name_table === 'fb_likecommentshare_like_clone') {
				dataNew['like_clone'] = v;
			} else if (v.name_table === 'fb_likecommentshare_care_clone') {
				dataNew['care_clone'] = v;
			} else if (v.name_table === 'fb_likecommentshare_love_clone') {
				dataNew['love_clone'] = v;
			} else if (v.name_table === 'fb_likecommentshare_haha_clone') {
				dataNew['haha_clone'] = v;
			} else if (v.name_table === 'fb_likecommentshare_wow_clone') {
				dataNew['wow_clone'] = v;
			} else if (v.name_table === 'fb_likecommentshare_sad_clone') {
				dataNew['sad_clone'] = v;
			} else if (v.name_table === 'fb_likecommentshare_angry_clone') {
				dataNew['angry_clone'] = v;
			} else if (v.name_table === 'fb_likecommentshare_like_v2') {
				dataNew['like_v2'] = v;
			} else if (v.name_table === 'fb_likecommentshare_care_v2') {
				dataNew['care_v2'] = v;
			} else if (v.name_table === 'fb_likecommentshare_love_v2') {
				dataNew['love_v2'] = v;
			} else if (v.name_table === 'fb_likecommentshare_haha_v2') {
				dataNew['haha_v2'] = v;
			} else if (v.name_table === 'fb_likecommentshare_wow_v2') {
				dataNew['wow_v2'] = v;
			} else if (v.name_table === 'fb_likecommentshare_sad_v2') {
				dataNew['sad_v2'] = v;
			} else if (v.name_table === 'fb_likecommentshare_angry_v2') {
				dataNew['angry_v2'] = v;
			} else if (v.name_table === 'fb_likecommentshare_like_cmt') {
				dataNew['like_cmt'] = v;
			} else if (v.name_table === 'fb_likecommentshare_care_cmt') {
				dataNew['care_cmt'] = v;
			} else if (v.name_table === 'fb_likecommentshare_love_cmt') {
				dataNew['love_cmt'] = v;
			} else if (v.name_table === 'fb_likecommentshare_haha_cmt') {
				dataNew['haha_cmt'] = v;
			} else if (v.name_table === 'fb_likecommentshare_wow_cmt') {
				dataNew['wow_cmt'] = v;
			} else if (v.name_table === 'fb_likecommentshare_sad_cmt') {
				dataNew['sad_cmt'] = v;
			} else if (v.name_table === 'fb_likecommentshare_angry_cmt') {
				dataNew['angry_cmt'] = v;
			} else if (v.name_table === 'fb_likecommentshare_like_v3') {
				dataNew['like_v3'] = v;
			} else if (v.name_table === 'fb_likecommentshare_care_v3') {
				dataNew['care_v3'] = v;
			} else if (v.name_table === 'fb_likecommentshare_love_v3') {
				dataNew['love_v3'] = v;
			} else if (v.name_table === 'fb_likecommentshare_haha_v3') {
				dataNew['haha_v3'] = v;
			} else if (v.name_table === 'fb_likecommentshare_wow_v3') {
				dataNew['wow_v3'] = v;
			} else if (v.name_table === 'fb_likecommentshare_sad_v3') {
				dataNew['sad_v3'] = v;
			} else if (v.name_table === 'fb_likecommentshare_angry_v3') {
				dataNew['angry_v3'] = v;
			}
		});
	};
	return dataNew;
}