
const initialState = {
	loadingblock: false,
	listBuffSub: [],
	datapricebuffsub: {
		viplike_clone: {
			min_bh_web: 0,
			prices_web: 0,
		},
		viplike_clone_ava: {
			min_bh_web: 0,
			prices_web: 0,
		},
		viplike_clone_speed: {
			min_bh_web: 0,
			prices_web: 0,
		},
		viplike_clone_via_ava: {
			min_bh_web: 0,
			prices_web: 0,
		},
		viplike_clone_sv5: {
			min_bh_web: 0,
			prices_web: 0,
		},
	},
	formData: {
		lsct: 3,
		profile_user: '',
		usernamefb: '',
		day_sale: 30,
		min_like: 50,
		max_like: 50,
		slbv: 5,
		ghichu: '',
		giatien: 0
	},
	"list_nhatky":"",
	facebookviplikeclone_sv1_mo_dong: 1,
	facebookviplikeclone_sv2_mo_dong: 1,
	facebookviplikeclone_sv3_mo_dong: 1,
	facebookviplikeclone_sv4_mo_dong: 1,
	facebookviplikeclone_sv5_mo_dong: 1,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case 'LIST_BUFF_VIP_CLONE':
    	return {
			...state,
			listBuffSub: action.payload
		};
    case 'LOADING_BLOCK_BUFF_VIP_CLONE':
		return {
			...state,
			loadingblock: action.payload
		};
	case 'CHANGE_DATA_FORM_BUFF_VIP_CLONE':
		return {
			...state,
			formData: action.payload
		}
	case 'DATA_PRICE_IS_FUNCTION_BUFF_VIP_CLONE':
		return {
			...state,
			datapricebuffsub: mergePrice(action.payload)
		}
    case 'LOADING_BLOCK_BUFF_SUB':
		return {
			...state,
			loadingblock: action.payload
		};
    case 'LOAD_NHATKYHOATDONG_BUFF_VIP_CLONE':
		return {
			...state,
			list_nhatky: action.payload
		};
	case 'CONFIG_MO_DONG_VIP_LIKE_CLONE':
		return {
			...state,
			facebookviplikeclone_sv1_mo_dong: action.payload.facebookviplikeclone_sv1_mo_dong,
			facebookviplikeclone_sv2_mo_dong: action.payload.facebookviplikeclone_sv2_mo_dong,
			facebookviplikeclone_sv3_mo_dong: action.payload.facebookviplikeclone_sv3_mo_dong,
			facebookviplikeclone_sv4_mo_dong: action.payload.facebookviplikeclone_sv4_mo_dong,
			facebookviplikeclone_sv5_mo_dong: action.payload.facebookviplikeclone_sv5_mo_dong,
		};
    default:
      return state;
  }
}

function mergePrice (arr_price) {
	const dataOld = initialState.datapricebuffsub;
	let dataNew = {
		...dataOld
	}
	if (arr_price.length > 0) {
		arr_price.forEach(function(v, i){
			if (v.name_table === 'viplike_clone') {
				dataNew.viplike_clone = v;
			} else if (v.name_table === 'viplike_clone_ava') {
				dataNew.viplike_clone_ava = v;
			} else if (v.name_table === 'viplike_clone_speed') {
				dataNew.viplike_clone_speed = v;
			} else if (v.name_table === 'viplike_clone_via_ava') {
				initialState.formData.giatien = v.prices_web;
				dataNew.viplike_clone_via_ava = v;
			} else if (v.name_table === 'viplike_clone_sv5') {
				dataNew.viplike_clone_sv5 = v;
			}
		});
		return dataNew;
	}
}