import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { addMF, updateMF, getNameFanpage, loadPriceMF, searchIdFb } from '../../../actions/muafanpageActions';
import Swal from 'sweetalert2';
import NumberFormat from 'react-number-format';
import copy from 'copy-text-to-clipboard';

class taotientrinh extends Component {
	static propTypes =  {
		addMF: PropTypes.func.isRequired,
		updateMF: PropTypes.func.isRequired,
		getNameFanpage: PropTypes.func.isRequired,
		loadPriceMF: PropTypes.func.isRequired,
	};
	
	state = {
		"copySuccess": false,
	};
	
	/*componentDidMount() {
		this.props.loadPriceMF();
	};*/
	isNumber(n) {
		return !isNaN(parseFloat(n)) && !isNaN(n - 0)
	}
	onChange = (e) => {
		var { taotientrinh } = this.props.muafanpage;
		if ((e.target.name === "lhi") && ((e.target.value.indexOf("facebook.com") > -1) || (this.isNumber(e.target.value) && e.target.value.length > 6))) {
			if (e.target.value !== "") {
				var new_taotientrinh = {
					...taotientrinh,
					[e.target.name]: e.target.value
				};
				this.props.getNameFanpage(new_taotientrinh);
			}
		} else {
			var new_taotientrinh = {
				...taotientrinh,
				[e.target.name]: e.target.value
			};
		}
		this.props.updateMF(new_taotientrinh);
	}
	
	taoTienTrinhfunc = () => {
		const { taotientrinh } = this.props.muafanpage;
		
		if (taotientrinh.lhi === "") {
			Swal.fire({
				icon: 'error',
				title: 'Lỗi',
				text: 'Link hoặc ID không được để trống',
			});
			return false;
		}
		var new_taotientrinh = {
			...taotientrinh,
		};
		Swal.fire({
			title: 'Bạn có chắc?',
			html: "Bạn có chắc chắn muốn mua Giá "+taotientrinh.gtmtt+" Coin / 1 sub? Khi đã bấm đồng ý đồng nghĩa với việc bạn đã đọc hết mục thông tin dịch vụ!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Đồng ý',
			cancelButtonText: 'Không'
		}).then((result) => {
			if (result.value) {
				this.props.addMF(new_taotientrinh);
			}
		})
	}
	
	onKeyPress = (e) => {
		if (!(e.charCode >= 48 && e.charCode <= 57)) {
			e.preventDefault();
		}
	}
	
	option_namtao = () => {
		var date = new Date();
		let ar_option_namtao = [];

		for (let i = 2004; i <= date.getFullYear(); i+=1) {
			ar_option_namtao.unshift(
				<option value={i} key={i}>
					{i}
				</option>
			)
		}
		return ar_option_namtao;
	}
	
	searchUidFacebook = (e) => {
		let valueS = e.target.value;
		let indexofLinkFb = valueS.lastIndexOf('facebook.com');
		if (indexofLinkFb > -1) {
			this.props.searchIdFb(valueS, e.target.name);
		} else {
			const { taotientrinh, price } = this.props.muafanpage;
			var new_taotientrinh = {
				...taotientrinh,
				[e.target.name]: e.target.value
			};
			this.props.updateMF(new_taotientrinh);
		}
	}
	
    copyID(text) {
        copy(text);
		this.setState({ "copySuccess": true });
        Swal.fire({
            icon: 'success',
            title: '',
            text: 'Đã copy link nick!',
        });
        return false;
    }

	render() {
		const { newname, sdt, fb, fb_admin, quantity, gtmtt } = this.props.muafanpage.taotientrinh;
		
		var price = Math.round((gtmtt * quantity + Number.EPSILON) * 100) / 100;
		return (
			<Fragment>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Tên Fanpage cần mua:</label>
					<div className="col-sm-8">
						<input type="text" className="form-control" id="newname" name="newname" placeholder="" onChange={this.onChange} value={newname} />
						<div className="alert alert-danger" style={{"marginTop": "5px"}}>
							<i className="fa fa-exclamation-triangle"></i> <strong>Lưu ý:</strong> Khi oder thành công hãy vào mục danh sách oder kiểm tra lại tên fanpage đã chuẩn chưa nếu có lỗi báo lại admin, nếu báo quá chậm chúng tôi không xử lí!
						</div>
					</div>
				</div>
				<div className="row mt-2">
					<div className="col-md-4">
						<label className="mt-2">Số lượng sub sẵn cần mua:</label>
					</div>
					<div className="col-md-8">
						<div className="form-group">
							<input type="number" className="form-control" onChange={this.onChange} name="quantity" value={quantity} onKeyPress={this.onKeyPress} />
							<h6 className="text-danger" style={{"marginTop": "5px"}}>Số lượng sub có sẵn MIN: 50000 sub!</h6>
						</div>
					</div>
				</div>
				<div className="row mt-2">
					<div className="col-md-4">
						<label className="mt-2">Link Facebook để set làm admin:</label>
					</div>
					<div className="col-md-8">
						<div className="form-group">
							<input type="text" className="form-control" id="fb_admin" name="fb_admin" placeholder="Nhập link facebook" onChange={(e) => {this.searchUidFacebook(e)}} value={fb_admin}  />
							<h5 className="text-danger" style={{"marginTop": "5px"}}>Dùng nick admin hãy ấn <a href="https://www.facebook.com/pages/?category=your_pages&ref=bookmarks" target="_blank">vào LINK này</a> check sau 12-48h sau khi mua có thể nick set admin nhưng không có thông báo</h5>
                        </div>
					</div>
				</div>
				<div className="row mt-2">
					<div className="col-md-4">
						<label className="mt-2">Số điện thoại liên hệ (có vấn đề bên admin sẽ thông báo):</label>
					</div>
					<div className="col-md-8">
						<div className="form-group">
							<input type="number" className="form-control" id="sdt" name="sdt" placeholder="Nhập số điện thoại" onChange={this.onChange} value={sdt} onKeyPress={this.onKeyPress} />
                        </div>
					</div>
				</div>
				<div className="row mt-2">
					<div className="col-md-4">
						<label className="mt-2">Link Facebook liên hệ (có vấn đề bên admin sẽ thông báo):</label>
					</div>
					<div className="col-md-8">
						<div className="form-group">
							<input type="text" className="form-control" id="fb" name="fb" placeholder="Nhập link facebook" onChange={(e) => {this.searchUidFacebook(e)}} value={fb}  />
                        </div>
					</div>
				</div>
				<div className="row mt-2">
					<div className="col-md-4">
						<label className="mt-2">Giá tiền:</label>
					</div>
					<div className="col-md-8">
						<div className="form-group">
							<input type="number" className="form-control" onChange={this.onChange} name="gtmtt" value={gtmtt} onKeyPress={this.onKeyPress} />
						</div>
					</div>
				</div>
				<div className="form-group row">
					<div className="col-md-4">
						<label className="mt-2"></label>
					</div>
				    <div className="col-md-8 bold">
				        <div className="card card-orange">
				        	<div className="card-body py-2">
								<h3 className="mb-0 text-danger">HÃY ĐỌC ĐỂ TRÁNH MẤT TIỀN KHI SỬ DỤNG!</h3>
								<ul>
									<li>Tên page chỉ được chữ cái đầu viết hoa, tên không được in hoa hết hoặc viết hoa ở giữa hoặc kí tự đặc biệt.</li>
									<li>Đây là page có sẵn số lượng theo dõi chứ không có sẵn like</li>
									<li>Giao page sau 12-72h kể từ khi đặt mua</li>
									<li>Đây là fanpage bình thường <span className="text-primary font-weight-bold">(không phải page profile)</span></li>
									<li>Khi bạn thanh toán đồng nghĩa với việc bạn đồng ý với yêu cầu trên của chúng tôi !</li>
								</ul>
			        		</div>
		        		</div>
				    </div>
				</div>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Ghi chú:</label>
					<div className="col-sm-8">
						<textarea rows="2" placeholder="Nhập nội dung ghi chú về tiến trình của bạn" className="form-control input-gray" id="gc" name="gc" onChange={this.onChange} ></textarea>
					</div>
				</div>
				<div className="row mt-4">
					<div className="col-12 text-center bold">
						<div className="card card-info">
							<div className="card-body">
								<h5 className="font-bold">Tổng: <span className="bold green"><span>{price}</span> Coin</span></h5>
								<h6 className="mb-0 bold"><span>Giá <span className="green">{gtmtt} Coin</span> / 1 sub</span></h6>
							</div>
						</div>
					</div>
				</div>
				<div className="row my-4">
					<div className="col-12 text-center bold">
						<button type="button" className="btn btn-block btn-rounded btn-hero-primary" onClick={this.taoTienTrinhfunc} >Tạo tiến trình</button>
					</div>
				</div>
			</Fragment>
		)
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	muafanpage: state.muafanpage,
});
const mapDispatchToProps = { addMF, updateMF, getNameFanpage, loadPriceMF, searchIdFb }

export default connect(mapStateToProps, mapDispatchToProps)(taotientrinh);