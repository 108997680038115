const initialState = {
	history_depost : []
};

export default function(state = initialState, action) {
	switch (action.type) {
		case 'GET_HISTORY_DEPOST':
			return {
				...state,
				history_depost: action.payload
			};
		default:
		  return state;
	}
}
