import axios from 'axios';
import Swal from 'sweetalert2';

export const GetListData = (data, clearform) => (dispatch, getState) => {
	dispatch({
		type: 'LOADING_DATA_FB_VIPBOTLIKE',
		payload: true
	});
	axios.get('/api/fbvip?fbvip_type=buffvipbotlive&type=0&limit=0', tokenConfig(getState))
		.then(res => {
			if (res.data.status === 200) {
				dispatch({
					type: 'LIST_DATA_FB_VIPBOTLIKE',
					payload: res.data.data
				});
			}
			dispatch({
				type: 'LOADING_DATA_FB_VIPBOTLIKE',
				payload: false
			});
		});
}

export const CreateBot = (data, clearform) => (dispatch, getState) => {
	let bodysend = {
		dataform: data,
		id: getState().auth.user.id
	};

	Swal.fire({
	  	title: 'Bạn có chắc?',
	  	text: "Bạn có chắc muốn mua gói này?",
	  	icon: 'warning',
	  	showCancelButton: true,
	  	confirmButtonColor: '#3085d6',
	  	cancelButtonColor: '#d33',
	  	confirmButtonText: 'Đồng ý',
	  	cancelButtonText: 'Không'
	}).then((result) => {
	  	if (result.value) {
			axios.post('/api/fbvipbotlive/createbot/', bodysend, tokenConfig(getState))
			.then(res => {
				if (res.data.status === 200) {
					clearform();
					Swal.fire({ icon: 'success', title: 'Thành công', text: 'Tạo thành công!' });
				} else {
					Swal.fire({ icon: 'error', title: 'Lỗi', text: res.data.message });
				}
			});
	  	}
  	});
}

export const UpdateItemBot = (cookie, idE, uidfb, closeM) => (dispatch, getState) => {
	let bodysend = {
		id: idE,
		id_user: getState().auth.user.id,
		cookie: cookie,
		uidfb: uidfb
	};
	if (cookie.trim() === '') {
		Swal.fire({ icon: 'error', title: 'Lỗi', text: 'cookie không được để trống!' });
		return false;
	}

	axios.post('/api/fbvipbotlive/edit/', bodysend, tokenConfig(getState))
	.then(res => {
		if (res.data.status === 200) {
			closeM();
			dispatch({
				type: 'EDIT_FB_VIPBOTLIKE',
				payload: {id: idE, cookie: cookie}
			});
			Swal.fire({ icon: 'success', title: 'Thành công', text: 'Sửa thành công!' });
		} else {
			Swal.fire({ icon: 'error', title: 'Lỗi', text: res.data.message });
		}
	});
}

export const tokenConfig = getState => {
	// Get token from localhost
	const token = getState().auth.token;
	
	// Headers
	const config = {
		headers: {
			"Content-type": "application/json"
		},
	};
	
	// If token, add to headers
	if (token) {
		config.headers['ht-token'] = token;
	}
	
	return config;
};