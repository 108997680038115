import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';

import Listuser from './tag_listuser';
import OverviewUser from './tag_overviewuser';
/*import Dataweb from './tag_dataweb';*/
import TagTokenSiteBase from './tag_tokensitebase';
import Notification from './tag_notification';
/*import ListAccClone from './tag_accclone';
import ListspeciesClone from './tag_speciesclone';*/
import TagSettingPriceFunc from './tag_setpricesfunc';
import TagBankPayment from './tag_bankpayment';
import TagBankPaymentHistoy from './tag_bankpaymenthistory';
import TagBankPaymentHistoyThe from './tag_bankpaymenthistorythe';
/* import TagFeature from './tag_feature'; */
import TagSupport from './tag_support';
import TagConfigSite from './tag_configsite';
import TagBankInfo from './tag_bankinfo';
import TagQuanLyDaiLy from './tag_quanlydaily';
import { getDataBtConfigBgNav } from '../../../actions/authActions';
class AdminBody extends Component {
	
	componentDidMount() {
		this.props.getDataBtConfigBgNav();
 	}
	
    state = {
        checkTab: 0
    }

    openTabMenu = (e, data) => {
        e.preventDefault();
        this.setState({
            checkTab: data,
        });
    }

    render() {
        const {checkTab} = this.state;
        return (
            <Fragment>
                <div className="row">
                    <div className="col-md-12">
                        <div className={"block block-bordered shadow" + (this.props.adminpage.loadingblock? ' block-mode-loading' : '')}>
                            <ul className="nav nav-tabs nav-tabs-block">
                                <li onClick={(e) => {this.openTabMenu(e, 0)}} className="nav-item">
                                    <a className={'nav-link'+ (checkTab === 0 ? ' active' : '')} href="/#">
                                        Tài khoản
                                    </a>
                                </li>
                                <li onClick={(e) => {this.openTabMenu(e, 1)}} className="nav-item">
                                    <a className={'nav-link' + (checkTab === 1 ? ' active' : '')} href="/#">
                                        Thông số 
                                    </a>
                                </li>
                                <li onClick={(e) => {this.openTabMenu(e, 2)}} className="nav-item">
                                    {/*<a className={'nav-link' + (checkTab === 2 ? ' active' : '')} href="/#">
                                        Dữ liệu
                                    </a>*/}
                                    <a className={'nav-link' + (checkTab === 2 ? ' active' : '')} href="/#">
                                        Cấu hình website
                                    </a>
                                </li>
                                <li onClick={(e) => {this.openTabMenu(e, 3)}} className="nav-item">
                                    <a className={'nav-link' + (checkTab === 3 ? ' active' : '')} href="/#">
                                        Thông báo
                                    </a>
                                </li>
                                {/*<li onClick={(e) => {this.openTabMenu(e, 4)}} className="nav-item">
                                    <a className={'nav-link' + (checkTab === 4 ? ' active' : '')} href="/#">
                                        Account clone
                                    </a>
                                </li>
                                <li onClick={(e) => {this.openTabMenu(e, 5)}} className="nav-item">
                                    <a className={'nav-link' + (checkTab === 5 ? ' active' : '')} href="/#">
                                        Loại clone
                                    </a>
                                </li>*/}
                                <li onClick={(e) => {this.openTabMenu(e, 6)}} className="nav-item">
                                    <a className={'nav-link' + (checkTab === 6 ? ' active' : '')} href="/#">
                                        Setting price
                                    </a>
                                </li>
                                {/* <li onClick={(e) => {this.openTabMenu(e, 10)}} className="nav-item">
                                    <a className={'nav-link' + (checkTab === 10 ? ' active' : '')} href="/#">
                                        Config feature
                                    </a>
                                </li> */}
                                <li onClick={(e) => {this.openTabMenu(e, 11)}} className="nav-item">
                                    <a className={'nav-link' + (checkTab === 11 ? ' active' : '')} href="/#">
                                        Config link
                                    </a>
                                </li>
                                <li onClick={(e) => {this.openTabMenu(e, 7)}} className="nav-item">
                                    <a className={'nav-link' + (checkTab === 7 ? ' active' : '')} href="/#">
                                        Bank
                                    </a>
                                </li>
                                <li onClick={(e) => {this.openTabMenu(e, 12)}} className="nav-item">
                                    <a className={'nav-link' + (checkTab === 12 ? ' active' : '')} href="/#">
                                        Bank Info
                                    </a>
                                </li>
                                <li onClick={(e) => {this.openTabMenu(e, 8)}} className="nav-item">
                                    <a className={'nav-link' + (checkTab === 8 ? ' active' : '')} href="/#">
                                        Bank history
                                    </a>
                                </li>
                                <li onClick={(e) => {this.openTabMenu(e, 13)}} className="nav-item">
                                    <a className={'nav-link' + (checkTab === 13 ? ' active' : '')} href="/#">
                                        Card history
                                    </a>
                                </li>
                                <li onClick={(e) => {this.openTabMenu(e, 14)}} className="nav-item">
                                    <a className={'nav-link' + (checkTab === 14 ? ' active' : '')} href="/#">
                                        Đại lý
                                    </a>
                                </li>
                                <li onClick={(e) => {this.openTabMenu(e, 9)}} className="nav-item">
                                    <a className={'nav-link' + (checkTab === 9 ? ' active' : '')} href="/#">
                                        Hỗ trợ
                                    </a>
                                </li>
                            </ul>
                            <div className="block-content tab-content overflow-hidden">
                                {checkTab === 0 ? 
                                    <div className='tab-pane fade fade-left show active'>
                                        <Listuser />
                                    </div>
                                : (checkTab === 1) ?
                                    <div className='tab-pane fade fade-left show active show active'>
                                        <OverviewUser />
                                    </div>
                                : (checkTab === 2) ?
                                    <div className='tab-pane fade fade-left show active show active'>
                                        <TagTokenSiteBase />
                                    </div>
                                : (checkTab === 3) ?
                                    <div className='tab-pane fade fade-left show active show active'>
                                        <Notification />
                                    </div>
                                : (checkTab === 4) ? ''
                                    /*<div className='tab-pane fade fade-left show active show active'>
                                        <ListAccClone />
                                    </div>*/
                                : (checkTab === 5) ? ''
                                    /*<div className='tab-pane fade fade-left show active show active'>
                                        <ListspeciesClone />
                                    </div>*/
                                : (checkTab === 6) ?
                                    <div className='tab-pane fade fade-left show active show active'>
                                        <TagSettingPriceFunc />
                                    </div>
                                : (checkTab === 7) ?
                                    <div className='tab-pane fade fade-left show active show active'>
                                        <TagBankPayment />
                                    </div>
                                : (checkTab === 8) ?
                                    <div className='tab-pane fade fade-left show active show active'>
                                        <TagBankPaymentHistoy />
                                    </div>
                                :  (checkTab === 9) ?
                                    <div className='tab-pane fade fade-left show active show active'>
                                        <TagSupport />
                                    </div>
                                : (checkTab === 13) ?
                                    <div className='tab-pane fade fade-left show active show active'>
                                        <TagBankPaymentHistoyThe />
                                    </div>
                                :  (checkTab === 10) ? ''
                                    /* <div className='tab-pane fade fade-left show active show active'>
                                        <TagFeature />
                                    </div> */
                                :  (checkTab === 12) ?
                                    <div className='tab-pane fade fade-left show active show active'>
                                        <TagBankInfo />
                                    </div>
                                :  (checkTab === 14) ?
                                    <div className='tab-pane fade fade-left show active show active'>
                                        <TagQuanLyDaiLy />
                                    </div>
                                :
                                    <div className='tab-pane fade fade-left show active show active'>
                                        <TagConfigSite />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    adminpage: state.adminpage
});

export default connect(mapStateToProps, {getDataBtConfigBgNav}) (AdminBody);
