import React from 'react';
import axios from 'axios';
import {
	LOAD_RIPACC,
	UPDATE_RIPACC_TAB,
	UPDATE_RIPACC,
	UPDATE_RIPACC_IDFB,
	UPDATE_RIPACC_IDFB_LIENHE,
	// LOAD_PRICE_RIPACC,
	LIMIT_PAGI_RIPACC,
	TYPE_RIPACC,
	LOADING_RIPACC,
	UPDATE_PRICE_BAOHANH_SHOPEE
} from "../actions/types";
import Swal from 'sweetalert2';
import { loadUser } from "./authActions";

export const loadRipacc = (type = 0, limit = 0) => (dispatch, getState) => {
	axios.get('/api/ripacc?type='+type+'&limit='+limit, tokenConfig(getState))
		.then(res => {
			if (res.data.status === 200) {
				dispatch({
					type: LOAD_RIPACC,
					payload: res.data.data
				});
			}
		});
};

// export const loadPriceFLBBKTT = () => (dispatch, getState) => {
	// axios.get('/api/facebooklocbanbekhongtuongtac/price', tokenConfig(getState))
		// .then(res => {
			// if (res.data.status === 200) {
				// dispatch({
					// type: LOAD_PRICE_FLBBKTT,
					// payload: res.data.data
				// });
			// }
		// });
// };

export const changeTab = (body) => (dispatch, getState) => {
	dispatch({
		type: UPDATE_RIPACC_TAB,
		payload: body
	});
};

export const updateRipacc = (body) => (dispatch, getState) => {
	dispatch({
		type: UPDATE_RIPACC,
		payload: body
	});
};

export const updateLimitRipacc = (body) => (dispatch) => {
	dispatch({
		type: LIMIT_PAGI_RIPACC,
		payload: body
	})
};

export const updateTypeRipacc = (body) => (dispatch) => {
	dispatch({
		type: TYPE_RIPACC,
		payload: body
	})
};

export const updatePriceBaoHanh = (body) => (dispatch) => {
	dispatch({
		type: UPDATE_PRICE_BAOHANH_SHOPEE,
		payload: body
	})
};

export const addRipacc = (body) => (dispatch, getState) => {
	dispatch({
		type: LOADING_RIPACC,
		payload: true
	});
	axios.post('/api/ripacc/add', body, tokenConfig(getState))
		.then(res => {
			dispatch({
				type: LOADING_RIPACC,
				payload: false
			});
			dispatch(loadUser());
			if (res.data.status === 200) {
				Swal.fire({
					icon: 'success',
					html: res.data.message
				});
			} else {
				var message = '';
				if (res.data.error) {
					if (res.data.error.object_id) {
						message = res.data.error.object_id[0];
					} else if (res.data.error.quantity) {
						message = res.data.error.quantity[0];
					} else {
						message = res.data.message;
					}
				} else {
					message = res.data.message;
				}
				Swal.fire({
					icon: 'error',
					title: 'Lỗi',
					text: message,
				});
			}
		});
};

// export const updateFLBBKTTactive = (body) => (dispatch, getState) => {
	// axios.post('/api/facebooklocbanbekhongtuongtac/active', body, tokenConfig(getState))
		// .then(res => {
			// dispatch(loadFLBBKTT());
		// });
// };


export const deleteSpamsms = (body) => (dispatch, getState) => {
	dispatch({
		type: LOADING_RIPACC,
		payload: true
	});
	axios.post('/api/ho-tro-nhan-tin/delete', body, tokenConfig(getState))
		.then(res => {
			dispatch({
				type: LOADING_RIPACC,
				payload: false
			});
			dispatch(loadRipacc());
			if (res.data.status === 200) {
				Swal.fire({
					icon: 'success',
					html: res.data.message
				});
			} else {
				var message = '';
				if (res.data.error) {
					if (res.data.error.object_id) {
						message = res.data.error.object_id[0];
					} else if (res.data.error.quantity) {
						message = res.data.error.quantity[0];
					} else {
						message = res.data.message;
					}
				} else {
					message = res.data.message;
				}
				Swal.fire({
					icon: 'error',
					title: 'Lỗi',
					text: message,
				});
			}
		});
};

export const UpdateNdr = (thongbao, status, dataid, closeM) => (dispatch, getState) => {
	let body = {
		id: dataid,
		thongbao: thongbao,
		status: status,
	};
	dispatch({
		type: LOADING_RIPACC,
		payload: true
	});
    axios.post('/api/ripacc/updatendr', body, tokenConfig(getState))
		.then(res => {
			dispatch({
				type: LOADING_RIPACC,
				payload: false
			});
			closeM();
			if (res.data.status === 200) {
				dispatch(loadRipacc());
				Swal.fire({
					icon: 'success',
					html: 'Cập nhật thành công'
				});
			}
			if (res.data.status === 400) {
				var message = '';
				if (res.data.error) {
					if (res.data.error.object_id) {
						message = res.data.error.object_id[0];
					} else if (res.data.error.quantity) {
						message = res.data.error.quantity[0];
					}
				} else {
					message = res.data.message;
				}
				Swal.fire({
					icon: 'error',
					title: 'Lỗi',
					text: message,
				});
			}
		});
};

export const searchIdFb = (link, type) => (dispatch, getState) => {
	let bodysend = {
		id_user: getState().auth.user.id,
		link: link
	};
	var dataFormOld = getState().buffsub.formData;
	dispatch({
		type: LOADING_RIPACC,
		payload: true
	});
	axios.post('/api/checklinkfb/check/', bodysend, tokenConfig(getState))
	.then(res => {
		dispatch({
			type: LOADING_RIPACC,
			payload: false
		});
		if (res.data.status === 200) {
			if (type == 'fb') {
				dispatch({
					type: UPDATE_RIPACC_IDFB_LIENHE,
					payload: res.data.id
				});
			} else {
				dispatch({
					type: UPDATE_RIPACC_IDFB,
					payload: res.data.id
				});
			}
		} else {
			Swal.fire({
				icon: 'error',
				title: 'Lỗi',
				text: res.data.msg,
			});
		}
	}).catch(err => {
		dispatch({
			type: LOADING_RIPACC,
			payload: false
		});
		openBtComfirmAlert('Có lỗi xảy ra!', 'error', 'Lỗi');
    });
}

export const openBtComfirmAlert = (message, type, title = '') => {
	Swal.fire({
		icon: type,
		title: title,
		text: message,
	});
	return false;
}

export const tokenConfig = getState => {
	// Get token from localhost
	const token = getState().auth.token;
	// Headers
	const config = {
		headers: {
			"Content-type": "application/json"
		}
	};
	
	// If token, add to headers
	if (token) {
		config.headers['ht-token'] = token;
	}
	
	return config;
};