import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

class ServicesDescription extends Component {
	render() {
		var { allservicesinfo, server, tab, giatien, baohanh, showDescription } = this.props;
		
		var tocdo = '';
		var min = '';
		var max = '';
		var thoigianxuly = '';
		
		if (tab === "facebook_buff_like") {
			if (typeof(allservicesinfo.facebook_buff_like) !== 'undefined' && server === "like") {
				tocdo = allservicesinfo.facebook_buff_like.tocdo;
				min = allservicesinfo.facebook_buff_like.min;
				max = allservicesinfo.facebook_buff_like.max;
				thoigianxuly = allservicesinfo.facebook_buff_like.thoigianxuly;
			}
			if (typeof(allservicesinfo.facebook_buff_like_v3) !== 'undefined' && server === "like_v3") {
				tocdo = allservicesinfo.facebook_buff_like_v3.tocdo;
				min = allservicesinfo.facebook_buff_like_v3.min;
				max = allservicesinfo.facebook_buff_like_v3.max;
				thoigianxuly = allservicesinfo.facebook_buff_like_v3.thoigianxuly;
			}
			if (typeof(allservicesinfo.facebook_buff_like_clone) !== 'undefined' && server === "like_clone") {
				tocdo = allservicesinfo.facebook_buff_like_clone.tocdo;
				min = allservicesinfo.facebook_buff_like_clone.min;
				max = allservicesinfo.facebook_buff_like_clone.max;
				thoigianxuly = allservicesinfo.facebook_buff_like_clone.thoigianxuly;
			}
			if (typeof(allservicesinfo.facebook_buff_like_v2) !== 'undefined' && server === "like_v2") {
				tocdo = allservicesinfo.facebook_buff_like_v2.tocdo;
				min = allservicesinfo.facebook_buff_like_v2.min;
				max = allservicesinfo.facebook_buff_like_v2.max;
				thoigianxuly = allservicesinfo.facebook_buff_like_v2.thoigianxuly;
			}
			if (typeof(allservicesinfo.facebook_buff_like_v4) !== 'undefined' && server === "like_v4") {
				tocdo = allservicesinfo.facebook_buff_like_v4.tocdo;
				min = allservicesinfo.facebook_buff_like_v4.min;
				max = allservicesinfo.facebook_buff_like_v4.max;
				thoigianxuly = allservicesinfo.facebook_buff_like_v4.thoigianxuly;
			}
			if (typeof(allservicesinfo.facebook_buff_like_v5) !== 'undefined' && server === "like_v5") {
				tocdo = allservicesinfo.facebook_buff_like_v5.tocdo;
				min = allservicesinfo.facebook_buff_like_v5.min;
				max = allservicesinfo.facebook_buff_like_v5.max;
				thoigianxuly = allservicesinfo.facebook_buff_like_v5.thoigianxuly;
			}
			if (typeof(allservicesinfo.facebook_buff_like_v6) !== 'undefined' && server === "like_v6") {
				tocdo = allservicesinfo.facebook_buff_like_v6.tocdo;
				min = allservicesinfo.facebook_buff_like_v6.min;
				max = allservicesinfo.facebook_buff_like_v6.max;
				thoigianxuly = allservicesinfo.facebook_buff_like_v6.thoigianxuly;
			}
			if (typeof(allservicesinfo.facebook_buff_like_v7) !== 'undefined' && server === "like_v7") {
				tocdo = allservicesinfo.facebook_buff_like_v7.tocdo;
				min = allservicesinfo.facebook_buff_like_v7.min;
				max = allservicesinfo.facebook_buff_like_v7.max;
				thoigianxuly = allservicesinfo.facebook_buff_like_v7.thoigianxuly;
			}
			if (typeof(allservicesinfo.facebook_buff_like_v8) !== 'undefined' && server === "like_v8") {
				tocdo = allservicesinfo.facebook_buff_like_v8.tocdo;
				min = allservicesinfo.facebook_buff_like_v8.min;
				max = allservicesinfo.facebook_buff_like_v8.max;
				thoigianxuly = allservicesinfo.facebook_buff_like_v8.thoigianxuly;
			}
			if (typeof(allservicesinfo.facebook_buff_like_v9) !== 'undefined' && server === "like_v9") {
				tocdo = allservicesinfo.facebook_buff_like_v9.tocdo;
				min = allservicesinfo.facebook_buff_like_v9.min;
				max = allservicesinfo.facebook_buff_like_v9.max;
				thoigianxuly = allservicesinfo.facebook_buff_like_v9.thoigianxuly;
			}
			if (typeof(allservicesinfo.facebook_buff_like_v10) !== 'undefined' && server === "like_v10") {
				tocdo = allservicesinfo.facebook_buff_like_v10.tocdo;
				min = allservicesinfo.facebook_buff_like_v10.min;
				max = allservicesinfo.facebook_buff_like_v10.max;
				thoigianxuly = allservicesinfo.facebook_buff_like_v10.thoigianxuly;
			}
		}
		
		if (tab === "facebook_buff_likecomment") {
			if (typeof(allservicesinfo.facebook_buff_likecomment_sv1) !== 'undefined' && server === "like_cmt") {
				tocdo = allservicesinfo.facebook_buff_likecomment_sv1.tocdo;
				min = allservicesinfo.facebook_buff_likecomment_sv1.min;
				max = allservicesinfo.facebook_buff_likecomment_sv1.max;
				thoigianxuly = allservicesinfo.facebook_buff_likecomment_sv1.thoigianxuly;
			}
			if (typeof(allservicesinfo.facebook_buff_likecomment_sv2) !== 'undefined' && server === "like_cmt_sv2") {
				tocdo = allservicesinfo.facebook_buff_likecomment_sv2.tocdo;
				min = allservicesinfo.facebook_buff_likecomment_sv2.min;
				max = allservicesinfo.facebook_buff_likecomment_sv2.max;
				thoigianxuly = allservicesinfo.facebook_buff_likecomment_sv2.thoigianxuly;
			}
			if (typeof(allservicesinfo.facebook_buff_likecomment_sv3) !== 'undefined' && server === "like_cmt_sv3") {
				tocdo = allservicesinfo.facebook_buff_likecomment_sv3.tocdo;
				min = allservicesinfo.facebook_buff_likecomment_sv3.min;
				max = allservicesinfo.facebook_buff_likecomment_sv3.max;
				thoigianxuly = allservicesinfo.facebook_buff_likecomment_sv3.thoigianxuly;
			}
		}
		
		if (tab === "facebook_buff_matlivestream") {
			if (typeof(allservicesinfo.facebook_buff_matlivestream_sv1) !== 'undefined' && server === "1") {
				tocdo = allservicesinfo.facebook_buff_matlivestream_sv1.tocdo;
				min = allservicesinfo.facebook_buff_matlivestream_sv1.min;
				max = allservicesinfo.facebook_buff_matlivestream_sv1.max;
				thoigianxuly = allservicesinfo.facebook_buff_matlivestream_sv1.thoigianxuly;
			}
			if (typeof(allservicesinfo.facebook_buff_matlivestream_sv2) !== 'undefined' && server === "2") {
				tocdo = allservicesinfo.facebook_buff_matlivestream_sv2.tocdo;
				min = allservicesinfo.facebook_buff_matlivestream_sv2.min;
				max = allservicesinfo.facebook_buff_matlivestream_sv2.max;
				thoigianxuly = allservicesinfo.facebook_buff_matlivestream_sv2.thoigianxuly;
			}
			if (typeof(allservicesinfo.facebook_buff_matlivestream_sv3) !== 'undefined' && server === "3") {
				tocdo = allservicesinfo.facebook_buff_matlivestream_sv3.tocdo;
				min = allservicesinfo.facebook_buff_matlivestream_sv3.min;
				max = allservicesinfo.facebook_buff_matlivestream_sv3.max;
				thoigianxuly = allservicesinfo.facebook_buff_matlivestream_sv3.thoigianxuly;
			}
			if (typeof(allservicesinfo.facebook_buff_matlivestream_sv4) !== 'undefined' && server === "4") {
				tocdo = allservicesinfo.facebook_buff_matlivestream_sv4.tocdo;
				min = allservicesinfo.facebook_buff_matlivestream_sv4.min;
				max = allservicesinfo.facebook_buff_matlivestream_sv4.max;
				thoigianxuly = allservicesinfo.facebook_buff_matlivestream_sv4.thoigianxuly;
			}
		}
		
		if (tab === "facebook_buff_comment") {
			if (typeof(allservicesinfo.facebook_buff_comment) !== 'undefined' && server === "comment") {
				tocdo = allservicesinfo.facebook_buff_comment.tocdo;
				min = allservicesinfo.facebook_buff_comment.min;
				max = allservicesinfo.facebook_buff_comment.max;
				thoigianxuly = allservicesinfo.facebook_buff_comment.thoigianxuly;
			}
			if (typeof(allservicesinfo.facebook_buff_comment_pro) !== 'undefined' && server === "comment_pro") {
				tocdo = allservicesinfo.facebook_buff_comment_pro.tocdo;
				min = allservicesinfo.facebook_buff_comment_pro.min;
				max = allservicesinfo.facebook_buff_comment_pro.max;
				thoigianxuly = allservicesinfo.facebook_buff_comment_pro.thoigianxuly;
			}
			if (typeof(allservicesinfo.facebook_buff_comment_sv3) !== 'undefined' && server === "comment_sv3") {
				tocdo = allservicesinfo.facebook_buff_comment_sv3.tocdo;
				min = allservicesinfo.facebook_buff_comment_sv3.min;
				max = allservicesinfo.facebook_buff_comment_sv3.max;
				thoigianxuly = allservicesinfo.facebook_buff_comment_sv3.thoigianxuly;
			}
			if (typeof(allservicesinfo.facebook_buff_comment_sv4) !== 'undefined' && server === "comment_sv4") {
				tocdo = allservicesinfo.facebook_buff_comment_sv4.tocdo;
				min = allservicesinfo.facebook_buff_comment_sv4.min;
				max = allservicesinfo.facebook_buff_comment_sv4.max;
				thoigianxuly = allservicesinfo.facebook_buff_comment_sv4.thoigianxuly;
			}
			if (typeof(allservicesinfo.facebook_buff_comment_sv5) !== 'undefined' && server === "comment_sv5") {
				tocdo = allservicesinfo.facebook_buff_comment_sv5.tocdo;
				min = allservicesinfo.facebook_buff_comment_sv5.min;
				max = allservicesinfo.facebook_buff_comment_sv5.max;
				thoigianxuly = allservicesinfo.facebook_buff_comment_sv5.thoigianxuly;
			}
			if (typeof(allservicesinfo.facebook_buff_comment_sv6) !== 'undefined' && server === "comment_sv6") {
				tocdo = allservicesinfo.facebook_buff_comment_sv6.tocdo;
				min = allservicesinfo.facebook_buff_comment_sv6.min;
				max = allservicesinfo.facebook_buff_comment_sv6.max;
				thoigianxuly = allservicesinfo.facebook_buff_comment_sv6.thoigianxuly;
			}
			if (typeof(allservicesinfo.facebook_buff_comment_sv7) !== 'undefined' && server === "comment_sv7") {
				tocdo = allservicesinfo.facebook_buff_comment_sv7.tocdo;
				min = allservicesinfo.facebook_buff_comment_sv7.min;
				max = allservicesinfo.facebook_buff_comment_sv7.max;
				thoigianxuly = allservicesinfo.facebook_buff_comment_sv7.thoigianxuly;
			}
			if (typeof(allservicesinfo.facebook_buff_comment_sv8) !== 'undefined' && server === "comment_sv8") {
				tocdo = allservicesinfo.facebook_buff_comment_sv8.tocdo;
				min = allservicesinfo.facebook_buff_comment_sv8.min;
				max = allservicesinfo.facebook_buff_comment_sv8.max;
				thoigianxuly = allservicesinfo.facebook_buff_comment_sv8.thoigianxuly;
			}
		}
		
		if (tab === "facebook_buff_subspeed") {
			if (typeof(allservicesinfo.facebook_buff_subspeed_sv1) !== 'undefined' && server === "1") {
				tocdo = allservicesinfo.facebook_buff_subspeed_sv1.tocdo;
				min = allservicesinfo.facebook_buff_subspeed_sv1.min;
				max = allservicesinfo.facebook_buff_subspeed_sv1.max;
				thoigianxuly = allservicesinfo.facebook_buff_subspeed_sv1.thoigianxuly;
			}
			if (typeof(allservicesinfo.facebook_buff_subspeed_sv2) !== 'undefined' && server === "2") {
				tocdo = allservicesinfo.facebook_buff_subspeed_sv2.tocdo;
				min = allservicesinfo.facebook_buff_subspeed_sv2.min;
				max = allservicesinfo.facebook_buff_subspeed_sv2.max;
				thoigianxuly = allservicesinfo.facebook_buff_subspeed_sv2.thoigianxuly;
			}
			if (typeof(allservicesinfo.facebook_buff_subspeed_sv3) !== 'undefined' && server === "3") {
				tocdo = allservicesinfo.facebook_buff_subspeed_sv3.tocdo;
				min = allservicesinfo.facebook_buff_subspeed_sv3.min;
				max = allservicesinfo.facebook_buff_subspeed_sv3.max;
				thoigianxuly = allservicesinfo.facebook_buff_subspeed_sv3.thoigianxuly;
			}
			if (typeof(allservicesinfo.facebook_buff_subspeed_sv4) !== 'undefined' && server === "4") {
				tocdo = allservicesinfo.facebook_buff_subspeed_sv4.tocdo;
				min = allservicesinfo.facebook_buff_subspeed_sv4.min;
				max = allservicesinfo.facebook_buff_subspeed_sv4.max;
				thoigianxuly = allservicesinfo.facebook_buff_subspeed_sv4.thoigianxuly;
			}
			if (typeof(allservicesinfo.facebook_buff_subspeed_sv5) !== 'undefined' && server === "5") {
				tocdo = allservicesinfo.facebook_buff_subspeed_sv5.tocdo;
				min = allservicesinfo.facebook_buff_subspeed_sv5.min;
				max = allservicesinfo.facebook_buff_subspeed_sv5.max;
				thoigianxuly = allservicesinfo.facebook_buff_subspeed_sv5.thoigianxuly;
			}
			if (typeof(allservicesinfo.facebook_buff_subspeed_sv6) !== 'undefined' && server === "6") {
				tocdo = allservicesinfo.facebook_buff_subspeed_sv6.tocdo;
				min = allservicesinfo.facebook_buff_subspeed_sv6.min;
				max = allservicesinfo.facebook_buff_subspeed_sv6.max;
				thoigianxuly = allservicesinfo.facebook_buff_subspeed_sv6.thoigianxuly;
			}
			if (typeof(allservicesinfo.facebook_buff_subspeed_sv7) !== 'undefined' && server === "7") {
				tocdo = allservicesinfo.facebook_buff_subspeed_sv7.tocdo;
				min = allservicesinfo.facebook_buff_subspeed_sv7.min;
				max = allservicesinfo.facebook_buff_subspeed_sv7.max;
				thoigianxuly = allservicesinfo.facebook_buff_subspeed_sv7.thoigianxuly;
			}
			if (typeof(allservicesinfo.facebook_buff_subspeed_sv8) !== 'undefined' && server === "8") {
				tocdo = allservicesinfo.facebook_buff_subspeed_sv8.tocdo;
				min = allservicesinfo.facebook_buff_subspeed_sv8.min;
				max = allservicesinfo.facebook_buff_subspeed_sv8.max;
				thoigianxuly = allservicesinfo.facebook_buff_subspeed_sv8.thoigianxuly;
			}
			if (typeof(allservicesinfo.facebook_buff_subspeed_sv9) !== 'undefined' && server === "9") {
				tocdo = allservicesinfo.facebook_buff_subspeed_sv9.tocdo;
				min = allservicesinfo.facebook_buff_subspeed_sv9.min;
				max = allservicesinfo.facebook_buff_subspeed_sv9.max;
				thoigianxuly = allservicesinfo.facebook_buff_subspeed_sv9.thoigianxuly;
			}
		}
		
		if (tab === "facebook_buff_subdexuat") {
			if (typeof(allservicesinfo.facebook_buff_subdexuat_basic) !== 'undefined' && server === "1") {
				tocdo = allservicesinfo.facebook_buff_subdexuat_basic.tocdo;
				min = allservicesinfo.facebook_buff_subdexuat_basic.min;
				max = allservicesinfo.facebook_buff_subdexuat_basic.max;
				thoigianxuly = allservicesinfo.facebook_buff_subdexuat_basic.thoigianxuly;
			}
			if (typeof(allservicesinfo.facebook_buff_subdexuat_v3) !== 'undefined' && server === "2") {
				tocdo = allservicesinfo.facebook_buff_subdexuat_v3.tocdo;
				min = allservicesinfo.facebook_buff_subdexuat_v3.min;
				max = allservicesinfo.facebook_buff_subdexuat_v3.max;
				thoigianxuly = allservicesinfo.facebook_buff_subdexuat_v3.thoigianxuly;
			}
			if (typeof(allservicesinfo.facebook_buff_subdexuat_v1) !== 'undefined' && server === "3") {
				tocdo = allservicesinfo.facebook_buff_subdexuat_v1.tocdo;
				min = allservicesinfo.facebook_buff_subdexuat_v1.min;
				max = allservicesinfo.facebook_buff_subdexuat_v1.max;
				thoigianxuly = allservicesinfo.facebook_buff_subdexuat_v1.thoigianxuly;
			}
			if (typeof(allservicesinfo.facebook_buff_subdexuat_v2) !== 'undefined' && server === "4") {
				tocdo = allservicesinfo.facebook_buff_subdexuat_v2.tocdo;
				min = allservicesinfo.facebook_buff_subdexuat_v2.min;
				max = allservicesinfo.facebook_buff_subdexuat_v2.max;
				thoigianxuly = allservicesinfo.facebook_buff_subdexuat_v2.thoigianxuly;
			}
		}
		
		if (tab === "facebook_buff_subsale") {
			if (typeof(allservicesinfo.facebook_buff_subsale_sv1) !== 'undefined' && server === "1") {
				tocdo = allservicesinfo.facebook_buff_subsale_sv1.tocdo;
				min = allservicesinfo.facebook_buff_subsale_sv1.min;
				max = allservicesinfo.facebook_buff_subsale_sv1.max;
				thoigianxuly = allservicesinfo.facebook_buff_subsale_sv1.thoigianxuly;
			}
			if (typeof(allservicesinfo.facebook_buff_subsale_sv2) !== 'undefined' && server === "2") {
				tocdo = allservicesinfo.facebook_buff_subsale_sv2.tocdo;
				min = allservicesinfo.facebook_buff_subsale_sv2.min;
				max = allservicesinfo.facebook_buff_subsale_sv2.max;
				thoigianxuly = allservicesinfo.facebook_buff_subsale_sv2.thoigianxuly;
			}
			if (typeof(allservicesinfo.facebook_buff_subsale_sv3) !== 'undefined' && server === "3") {
				tocdo = allservicesinfo.facebook_buff_subsale_sv3.tocdo;
				min = allservicesinfo.facebook_buff_subsale_sv3.min;
				max = allservicesinfo.facebook_buff_subsale_sv3.max;
				thoigianxuly = allservicesinfo.facebook_buff_subsale_sv3.thoigianxuly;
			}
			if (typeof(allservicesinfo.facebook_buff_subsale_sv4) !== 'undefined' && server === "4") {
				tocdo = allservicesinfo.facebook_buff_subsale_sv4.tocdo;
				min = allservicesinfo.facebook_buff_subsale_sv4.min;
				max = allservicesinfo.facebook_buff_subsale_sv4.max;
				thoigianxuly = allservicesinfo.facebook_buff_subsale_sv4.thoigianxuly;
			}
		}
		
		if (tab === "facebook_buff_likepage") {
			if (typeof(allservicesinfo.facebook_buff_likepage_basic) !== 'undefined' && server === "1") {
				tocdo = allservicesinfo.facebook_buff_likepage_basic.tocdo;
				min = allservicesinfo.facebook_buff_likepage_basic.min;
				max = allservicesinfo.facebook_buff_likepage_basic.max;
				thoigianxuly = allservicesinfo.facebook_buff_likepage_basic.thoigianxuly;
			}
			if (typeof(allservicesinfo.facebook_buff_likepage_pro) !== 'undefined' && server === "2") {
				tocdo = allservicesinfo.facebook_buff_likepage_pro.tocdo;
				min = allservicesinfo.facebook_buff_likepage_pro.min;
				max = allservicesinfo.facebook_buff_likepage_pro.max;
				thoigianxuly = allservicesinfo.facebook_buff_likepage_pro.thoigianxuly;
			}
			if (typeof(allservicesinfo.facebook_buff_likepage_sv3) !== 'undefined' && server === "3") {
				tocdo = allservicesinfo.facebook_buff_likepage_sv3.tocdo;
				min = allservicesinfo.facebook_buff_likepage_sv3.min;
				max = allservicesinfo.facebook_buff_likepage_sv3.max;
				thoigianxuly = allservicesinfo.facebook_buff_likepage_sv3.thoigianxuly;
			}
			if (typeof(allservicesinfo.facebook_buff_likepage_sv4) !== 'undefined' && server === "4") {
				tocdo = allservicesinfo.facebook_buff_likepage_sv4.tocdo;
				min = allservicesinfo.facebook_buff_likepage_sv4.min;
				max = allservicesinfo.facebook_buff_likepage_sv4.max;
				thoigianxuly = allservicesinfo.facebook_buff_likepage_sv4.thoigianxuly;
			}
			if (typeof(allservicesinfo.facebook_buff_likepage_sv5) !== 'undefined' && server === "5") {
				tocdo = allservicesinfo.facebook_buff_likepage_sv5.tocdo;
				min = allservicesinfo.facebook_buff_likepage_sv5.min;
				max = allservicesinfo.facebook_buff_likepage_sv5.max;
				thoigianxuly = allservicesinfo.facebook_buff_likepage_sv5.thoigianxuly;
			}
			if (typeof(allservicesinfo.facebook_buff_likepage_sv6) !== 'undefined' && server === "6") {
				tocdo = allservicesinfo.facebook_buff_likepage_sv6.tocdo;
				min = allservicesinfo.facebook_buff_likepage_sv6.min;
				max = allservicesinfo.facebook_buff_likepage_sv6.max;
				thoigianxuly = allservicesinfo.facebook_buff_likepage_sv6.thoigianxuly;
			}
			if (typeof(allservicesinfo.facebook_buff_likepage_sv7) !== 'undefined' && server === "7") {
				tocdo = allservicesinfo.facebook_buff_likepage_sv7.tocdo;
				min = allservicesinfo.facebook_buff_likepage_sv7.min;
				max = allservicesinfo.facebook_buff_likepage_sv7.max;
				thoigianxuly = allservicesinfo.facebook_buff_likepage_sv7.thoigianxuly;
			}
			if (typeof(allservicesinfo.facebook_buff_likepage_sv8) !== 'undefined' && server === "8") {
				tocdo = allservicesinfo.facebook_buff_likepage_sv8.tocdo;
				min = allservicesinfo.facebook_buff_likepage_sv8.min;
				max = allservicesinfo.facebook_buff_likepage_sv8.max;
				thoigianxuly = allservicesinfo.facebook_buff_likepage_sv8.thoigianxuly;
			}
			if (typeof(allservicesinfo.facebook_buff_likepage_sv9) !== 'undefined' && server === "9") {
				tocdo = allservicesinfo.facebook_buff_likepage_sv9.tocdo;
				min = allservicesinfo.facebook_buff_likepage_sv9.min;
				max = allservicesinfo.facebook_buff_likepage_sv9.max;
				thoigianxuly = allservicesinfo.facebook_buff_likepage_sv9.thoigianxuly;
			}
		}
		
		if (tab === "facebook_buff_likepagesale") {
			if (typeof(allservicesinfo.facebook_buff_likepagesale_sv1) !== 'undefined' && server === "1") {
				tocdo = allservicesinfo.facebook_buff_likepagesale_sv1.tocdo;
				min = allservicesinfo.facebook_buff_likepagesale_sv1.min;
				max = allservicesinfo.facebook_buff_likepagesale_sv1.max;
				thoigianxuly = allservicesinfo.facebook_buff_likepagesale_sv1.thoigianxuly;
			}
			if (typeof(allservicesinfo.facebook_buff_likepagesale_sv2) !== 'undefined' && server === "2") {
				tocdo = allservicesinfo.facebook_buff_likepagesale_sv2.tocdo;
				min = allservicesinfo.facebook_buff_likepagesale_sv2.min;
				max = allservicesinfo.facebook_buff_likepagesale_sv2.max;
				thoigianxuly = allservicesinfo.facebook_buff_likepagesale_sv2.thoigianxuly;
			}
			if (typeof(allservicesinfo.facebook_buff_likepagesale_sv3) !== 'undefined' && server === "3") {
				tocdo = allservicesinfo.facebook_buff_likepagesale_sv3.tocdo;
				min = allservicesinfo.facebook_buff_likepagesale_sv3.min;
				max = allservicesinfo.facebook_buff_likepagesale_sv3.max;
				thoigianxuly = allservicesinfo.facebook_buff_likepagesale_sv3.thoigianxuly;
			}
		}
		
		if (tab === "facebook_buff_subpage") {
			if (typeof(allservicesinfo.facebook_buff_subpage_sv1) !== 'undefined' && server === "1") {
				tocdo = allservicesinfo.facebook_buff_subpage_sv1.tocdo;
				min = allservicesinfo.facebook_buff_subpage_sv1.min;
				max = allservicesinfo.facebook_buff_subpage_sv1.max;
				thoigianxuly = allservicesinfo.facebook_buff_subpage_sv1.thoigianxuly;
			}
			if (typeof(allservicesinfo.facebook_buff_subpage_sv2) !== 'undefined' && server === "2") {
				tocdo = allservicesinfo.facebook_buff_subpage_sv2.tocdo;
				min = allservicesinfo.facebook_buff_subpage_sv2.min;
				max = allservicesinfo.facebook_buff_subpage_sv2.max;
				thoigianxuly = allservicesinfo.facebook_buff_subpage_sv2.thoigianxuly;
			}
		}
		
		if (tab === "facebook_buff_danhgiacheckinpage") {
			if (typeof(allservicesinfo.facebook_buff_danhgiacheckinpage_sv1) !== 'undefined' && server === "review_sv1") {
				tocdo = allservicesinfo.facebook_buff_danhgiacheckinpage_sv1.tocdo;
				min = allservicesinfo.facebook_buff_danhgiacheckinpage_sv1.min;
				max = allservicesinfo.facebook_buff_danhgiacheckinpage_sv1.max;
				thoigianxuly = allservicesinfo.facebook_buff_danhgiacheckinpage_sv1.thoigianxuly;
			}
			if (typeof(allservicesinfo.facebook_buff_danhgiacheckinpage_sv2) !== 'undefined' && server === "review") {
				tocdo = allservicesinfo.facebook_buff_danhgiacheckinpage_sv2.tocdo;
				min = allservicesinfo.facebook_buff_danhgiacheckinpage_sv2.min;
				max = allservicesinfo.facebook_buff_danhgiacheckinpage_sv2.max;
				thoigianxuly = allservicesinfo.facebook_buff_danhgiacheckinpage_sv2.thoigianxuly;
			}
			if (typeof(allservicesinfo.facebook_buff_danhgiacheckinpage_sv3) !== 'undefined' && server === "review_sv3") {
				tocdo = allservicesinfo.facebook_buff_danhgiacheckinpage_sv3.tocdo;
				min = allservicesinfo.facebook_buff_danhgiacheckinpage_sv3.min;
				max = allservicesinfo.facebook_buff_danhgiacheckinpage_sv3.max;
				thoigianxuly = allservicesinfo.facebook_buff_danhgiacheckinpage_sv3.thoigianxuly;
			}
			if (typeof(allservicesinfo.facebook_buff_danhgiacheckinpage_sv4) !== 'undefined' && server === "review_sv4") {
				tocdo = allservicesinfo.facebook_buff_danhgiacheckinpage_sv4.tocdo;
				min = allservicesinfo.facebook_buff_danhgiacheckinpage_sv4.min;
				max = allservicesinfo.facebook_buff_danhgiacheckinpage_sv4.max;
				thoigianxuly = allservicesinfo.facebook_buff_danhgiacheckinpage_sv4.thoigianxuly;
			}
			if (typeof(allservicesinfo.facebook_buff_danhgiacheckinpage_sv5) !== 'undefined' && server === "review_sv5") {
				tocdo = allservicesinfo.facebook_buff_danhgiacheckinpage_sv5.tocdo;
				min = allservicesinfo.facebook_buff_danhgiacheckinpage_sv5.min;
				max = allservicesinfo.facebook_buff_danhgiacheckinpage_sv5.max;
				thoigianxuly = allservicesinfo.facebook_buff_danhgiacheckinpage_sv5.thoigianxuly;
			}
		}
		
		if (tab === "facebook_buff_share") {
			if (typeof(allservicesinfo.facebook_buff_share_sv1) !== 'undefined' && server === "share") {
				tocdo = allservicesinfo.facebook_buff_share_sv1.tocdo;
				min = allservicesinfo.facebook_buff_share_sv1.min;
				max = allservicesinfo.facebook_buff_share_sv1.max;
				thoigianxuly = allservicesinfo.facebook_buff_share_sv1.thoigianxuly;
			}
			if (typeof(allservicesinfo.facebook_buff_share_sv2) !== 'undefined' && server === "share_sv2") {
				tocdo = allservicesinfo.facebook_buff_share_sv2.tocdo;
				min = allservicesinfo.facebook_buff_share_sv2.min;
				max = allservicesinfo.facebook_buff_share_sv2.max;
				thoigianxuly = allservicesinfo.facebook_buff_share_sv2.thoigianxuly;
			}
			if (typeof(allservicesinfo.facebook_buff_share_sv3) !== 'undefined' && server === "share_sv3") {
				tocdo = allservicesinfo.facebook_buff_share_sv3.tocdo;
				min = allservicesinfo.facebook_buff_share_sv3.min;
				max = allservicesinfo.facebook_buff_share_sv3.max;
				thoigianxuly = allservicesinfo.facebook_buff_share_sv3.thoigianxuly;
			}
			if (typeof(allservicesinfo.facebook_buff_share_sv4) !== 'undefined' && server === "share_sv4") {
				tocdo = allservicesinfo.facebook_buff_share_sv4.tocdo;
				min = allservicesinfo.facebook_buff_share_sv4.min;
				max = allservicesinfo.facebook_buff_share_sv4.max;
				thoigianxuly = allservicesinfo.facebook_buff_share_sv4.thoigianxuly;
			}
			if (typeof(allservicesinfo.facebook_buff_share_sv5) !== 'undefined' && server === "share_sv5") {
				tocdo = allservicesinfo.facebook_buff_share_sv5.tocdo;
				min = allservicesinfo.facebook_buff_share_sv5.min;
				max = allservicesinfo.facebook_buff_share_sv5.max;
				thoigianxuly = allservicesinfo.facebook_buff_share_sv5.thoigianxuly;
			}
			if (typeof(allservicesinfo.facebook_buff_share_sv6) !== 'undefined' && server === "share_sv6") {
				tocdo = allservicesinfo.facebook_buff_share_sv6.tocdo;
				min = allservicesinfo.facebook_buff_share_sv6.min;
				max = allservicesinfo.facebook_buff_share_sv6.max;
				thoigianxuly = allservicesinfo.facebook_buff_share_sv6.thoigianxuly;
			}
		}
		
		if (tab === "facebook_buff_sharegroup") {
			if (typeof(allservicesinfo.facebook_buff_sharegroup_sv1) !== 'undefined' && server === "1") {
				tocdo = allservicesinfo.facebook_buff_sharegroup_sv1.tocdo;
				min = allservicesinfo.facebook_buff_sharegroup_sv1.min;
				max = allservicesinfo.facebook_buff_sharegroup_sv1.max;
				thoigianxuly = allservicesinfo.facebook_buff_sharegroup_sv1.thoigianxuly;
			}
			if (typeof(allservicesinfo.facebook_buff_sharegroup_sv2) !== 'undefined' && server === "2") {
				tocdo = allservicesinfo.facebook_buff_sharegroup_sv2.tocdo;
				min = allservicesinfo.facebook_buff_sharegroup_sv2.min;
				max = allservicesinfo.facebook_buff_sharegroup_sv2.max;
				thoigianxuly = allservicesinfo.facebook_buff_sharegroup_sv2.thoigianxuly;
			}
			if (typeof(allservicesinfo.facebook_buff_sharegroup_svvip) !== 'undefined' && server === "3") {
				tocdo = allservicesinfo.facebook_buff_sharegroup_svvip.tocdo;
				min = allservicesinfo.facebook_buff_sharegroup_svvip.min;
				max = allservicesinfo.facebook_buff_sharegroup_svvip.max;
				thoigianxuly = allservicesinfo.facebook_buff_sharegroup_svvip.thoigianxuly;
			}
		}
		
		if (tab === "facebook_buff_memgroup") {
			if (typeof(allservicesinfo.facebook_buff_memgroup_sv1) !== 'undefined' && server === "1") {
				tocdo = allservicesinfo.facebook_buff_memgroup_sv1.tocdo;
				min = allservicesinfo.facebook_buff_memgroup_sv1.min;
				max = allservicesinfo.facebook_buff_memgroup_sv1.max;
				thoigianxuly = allservicesinfo.facebook_buff_memgroup_sv1.thoigianxuly;
			}
			if (typeof(allservicesinfo.facebook_buff_memgroup_sv2) !== 'undefined' && server === "2") {
				tocdo = allservicesinfo.facebook_buff_memgroup_sv2.tocdo;
				min = allservicesinfo.facebook_buff_memgroup_sv2.min;
				max = allservicesinfo.facebook_buff_memgroup_sv2.max;
				thoigianxuly = allservicesinfo.facebook_buff_memgroup_sv2.thoigianxuly;
			}
			if (typeof(allservicesinfo.facebook_buff_memgroup_sv3) !== 'undefined' && server === "3") {
				tocdo = allservicesinfo.facebook_buff_memgroup_sv3.tocdo;
				min = allservicesinfo.facebook_buff_memgroup_sv3.min;
				max = allservicesinfo.facebook_buff_memgroup_sv3.max;
				thoigianxuly = allservicesinfo.facebook_buff_memgroup_sv3.thoigianxuly;
			}
			if (typeof(allservicesinfo.facebook_buff_memgroup_sv4) !== 'undefined' && server === "4") {
				tocdo = allservicesinfo.facebook_buff_memgroup_sv4.tocdo;
				min = allservicesinfo.facebook_buff_memgroup_sv4.min;
				max = allservicesinfo.facebook_buff_memgroup_sv4.max;
				thoigianxuly = allservicesinfo.facebook_buff_memgroup_sv4.thoigianxuly;
			}
			if (typeof(allservicesinfo.facebook_buff_memgroup_sv5) !== 'undefined' && server === "5") {
				tocdo = allservicesinfo.facebook_buff_memgroup_sv5.tocdo;
				min = allservicesinfo.facebook_buff_memgroup_sv5.min;
				max = allservicesinfo.facebook_buff_memgroup_sv5.max;
				thoigianxuly = allservicesinfo.facebook_buff_memgroup_sv5.thoigianxuly;
			}
			if (typeof(allservicesinfo.facebook_buff_memgroup_sv6) !== 'undefined' && server === "6") {
				tocdo = allservicesinfo.facebook_buff_memgroup_sv6.tocdo;
				min = allservicesinfo.facebook_buff_memgroup_sv6.min;
				max = allservicesinfo.facebook_buff_memgroup_sv6.max;
				thoigianxuly = allservicesinfo.facebook_buff_memgroup_sv6.thoigianxuly;
			}
		}
		
		if (tab === "facebook_buff_viewvideo") {
			if (typeof(allservicesinfo.facebook_buff_viewvideo_3s) !== 'undefined' && server === "0") {
				tocdo = allservicesinfo.facebook_buff_viewvideo_3s.tocdo;
				min = allservicesinfo.facebook_buff_viewvideo_3s.min;
				max = allservicesinfo.facebook_buff_viewvideo_3s.max;
				thoigianxuly = allservicesinfo.facebook_buff_viewvideo_3s.thoigianxuly;
			}
			if (typeof(allservicesinfo.facebook_buff_viewvideo_1p) !== 'undefined' && server === "1") {
				tocdo = allservicesinfo.facebook_buff_viewvideo_1p.tocdo;
				min = allservicesinfo.facebook_buff_viewvideo_1p.min;
				max = allservicesinfo.facebook_buff_viewvideo_1p.max;
				thoigianxuly = allservicesinfo.facebook_buff_viewvideo_1p.thoigianxuly;
			}
			if (typeof(allservicesinfo.facebook_buff_viewvideo_3snhanh) !== 'undefined' && server === "99") {
				tocdo = allservicesinfo.facebook_buff_viewvideo_3snhanh.tocdo;
				min = allservicesinfo.facebook_buff_viewvideo_3snhanh.min;
				max = allservicesinfo.facebook_buff_viewvideo_3snhanh.max;
				thoigianxuly = allservicesinfo.facebook_buff_viewvideo_3snhanh.thoigianxuly;
			}
			if (typeof(allservicesinfo.facebook_buff_viewvideo_sv4) !== 'undefined' && server === "4") {
				tocdo = allservicesinfo.facebook_buff_viewvideo_sv4.tocdo;
				min = allservicesinfo.facebook_buff_viewvideo_sv4.min;
				max = allservicesinfo.facebook_buff_viewvideo_sv4.max;
				thoigianxuly = allservicesinfo.facebook_buff_viewvideo_sv4.thoigianxuly;
			}
			if (typeof(allservicesinfo.facebook_buff_viewvideo_sv5) !== 'undefined' && server === "5") {
				tocdo = allservicesinfo.facebook_buff_viewvideo_sv5.tocdo;
				min = allservicesinfo.facebook_buff_viewvideo_sv5.min;
				max = allservicesinfo.facebook_buff_viewvideo_sv5.max;
				thoigianxuly = allservicesinfo.facebook_buff_viewvideo_sv5.thoigianxuly;
			}
		}
		
		if (tab === "facebook_buff_viewstory") {
			if (typeof(allservicesinfo.facebook_buff_viewstory_sv1) !== 'undefined' && server === "0") {
				tocdo = allservicesinfo.facebook_buff_viewstory_sv1.tocdo;
				min = allservicesinfo.facebook_buff_viewstory_sv1.min;
				max = allservicesinfo.facebook_buff_viewstory_sv1.max;
				thoigianxuly = allservicesinfo.facebook_buff_viewstory_sv1.thoigianxuly;
			}
		}
		
		if (tab === "facebook_buff_likesubcmtsale") {
			if (typeof(allservicesinfo.facebook_buff_likesubcmtsale_sub_sv1) !== 'undefined' && server === "sub0") {
				tocdo = allservicesinfo.facebook_buff_likesubcmtsale_sub_sv1.tocdo;
				min = allservicesinfo.facebook_buff_likesubcmtsale_sub_sv1.min;
				max = allservicesinfo.facebook_buff_likesubcmtsale_sub_sv1.max;
				thoigianxuly = allservicesinfo.facebook_buff_likesubcmtsale_sub_sv1.thoigianxuly;
			}
			if (typeof(allservicesinfo.facebook_buff_likesubcmtsale_sub_sv3) !== 'undefined' && server === "sub2") {
				tocdo = allservicesinfo.facebook_buff_likesubcmtsale_sub_sv3.tocdo;
				min = allservicesinfo.facebook_buff_likesubcmtsale_sub_sv3.min;
				max = allservicesinfo.facebook_buff_likesubcmtsale_sub_sv3.max;
				thoigianxuly = allservicesinfo.facebook_buff_likesubcmtsale_sub_sv3.thoigianxuly;
			}
			if (typeof(allservicesinfo.facebook_buff_likesubcmtsale_sub_svvip) !== 'undefined' && server === "sub3") {
				tocdo = allservicesinfo.facebook_buff_likesubcmtsale_sub_svvip.tocdo;
				min = allservicesinfo.facebook_buff_likesubcmtsale_sub_svvip.min;
				max = allservicesinfo.facebook_buff_likesubcmtsale_sub_svvip.max;
				thoigianxuly = allservicesinfo.facebook_buff_likesubcmtsale_sub_svvip.thoigianxuly;
			}
			if (typeof(allservicesinfo.facebook_buff_likesubcmtsale_likepage_sv1) !== 'undefined' && server === "like_page0") {
				tocdo = allservicesinfo.facebook_buff_likesubcmtsale_likepage_sv1.tocdo;
				min = allservicesinfo.facebook_buff_likesubcmtsale_likepage_sv1.min;
				max = allservicesinfo.facebook_buff_likesubcmtsale_likepage_sv1.max;
				thoigianxuly = allservicesinfo.facebook_buff_likesubcmtsale_likepage_sv1.thoigianxuly;
			}
			if (typeof(allservicesinfo.facebook_buff_likesubcmtsale_likepage_sv3) !== 'undefined' && server === "like_page2") {
				tocdo = allservicesinfo.facebook_buff_likesubcmtsale_likepage_sv3.tocdo;
				min = allservicesinfo.facebook_buff_likesubcmtsale_likepage_sv3.min;
				max = allservicesinfo.facebook_buff_likesubcmtsale_likepage_sv3.max;
				thoigianxuly = allservicesinfo.facebook_buff_likesubcmtsale_likepage_sv3.thoigianxuly;
			}
			if (typeof(allservicesinfo.facebook_buff_likesubcmtsale_likepost_sv1) !== 'undefined' && server === "like_post0") {
				tocdo = allservicesinfo.facebook_buff_likesubcmtsale_likepost_sv1.tocdo;
				min = allservicesinfo.facebook_buff_likesubcmtsale_likepost_sv1.min;
				max = allservicesinfo.facebook_buff_likesubcmtsale_likepost_sv1.max;
				thoigianxuly = allservicesinfo.facebook_buff_likesubcmtsale_likepost_sv1.thoigianxuly;
			}
			if (typeof(allservicesinfo.facebook_buff_likesubcmtsale_likepost_sv2) !== 'undefined' && server === "like_post1") {
				tocdo = allservicesinfo.facebook_buff_likesubcmtsale_likepost_sv2.tocdo;
				min = allservicesinfo.facebook_buff_likesubcmtsale_likepost_sv2.min;
				max = allservicesinfo.facebook_buff_likesubcmtsale_likepost_sv2.max;
				thoigianxuly = allservicesinfo.facebook_buff_likesubcmtsale_likepost_sv2.thoigianxuly;
			}
			if (typeof(allservicesinfo.facebook_buff_likesubcmtsale_likepost_sv3) !== 'undefined' && server === "like_post2") {
				tocdo = allservicesinfo.facebook_buff_likesubcmtsale_likepost_sv3.tocdo;
				min = allservicesinfo.facebook_buff_likesubcmtsale_likepost_sv3.min;
				max = allservicesinfo.facebook_buff_likesubcmtsale_likepost_sv3.max;
				thoigianxuly = allservicesinfo.facebook_buff_likesubcmtsale_likepost_sv3.thoigianxuly;
			}
			if (typeof(allservicesinfo.facebook_buff_likesubcmtsale_commentpost_sv1) !== 'undefined' && server === "comment_post0") {
				tocdo = allservicesinfo.facebook_buff_likesubcmtsale_commentpost_sv1.tocdo;
				min = allservicesinfo.facebook_buff_likesubcmtsale_commentpost_sv1.min;
				max = allservicesinfo.facebook_buff_likesubcmtsale_commentpost_sv1.max;
				thoigianxuly = allservicesinfo.facebook_buff_likesubcmtsale_commentpost_sv1.thoigianxuly;
			}
		}
		
		if (tab === "facebook_buff_likesubtay") {
			if (typeof(allservicesinfo.facebook_buff_likesubtay_sub_sv1) !== 'undefined' && server === "sub0") {
				tocdo = allservicesinfo.facebook_buff_likesubtay_sub_sv1.tocdo;
				min = allservicesinfo.facebook_buff_likesubtay_sub_sv1.min;
				max = allservicesinfo.facebook_buff_likesubtay_sub_sv1.max;
				thoigianxuly = allservicesinfo.facebook_buff_likesubtay_sub_sv1.thoigianxuly;
			}
			if (typeof(allservicesinfo.facebook_buff_likesubtay_likepage_sv1) !== 'undefined' && server === "likefanpage0") {
				tocdo = allservicesinfo.facebook_buff_likesubtay_likepage_sv1.tocdo;
				min = allservicesinfo.facebook_buff_likesubtay_likepage_sv1.min;
				max = allservicesinfo.facebook_buff_likesubtay_likepage_sv1.max;
				thoigianxuly = allservicesinfo.facebook_buff_likesubtay_likepage_sv1.thoigianxuly;
			}
			if (typeof(allservicesinfo.facebook_buff_likesubtay_likepost_sv1) !== 'undefined' && server === "likepost0") {
				tocdo = allservicesinfo.facebook_buff_likesubtay_likepost_sv1.tocdo;
				min = allservicesinfo.facebook_buff_likesubtay_likepost_sv1.min;
				max = allservicesinfo.facebook_buff_likesubtay_likepost_sv1.max;
				thoigianxuly = allservicesinfo.facebook_buff_likesubtay_likepost_sv1.thoigianxuly;
			}
		}
		
		if (tab === "facebook_vip_likeclone") {
			if (typeof(allservicesinfo.facebook_vip_likeclone_sv1) !== 'undefined' && server === "3") {
				tocdo = allservicesinfo.facebook_vip_likeclone_sv1.tocdo;
				min = allservicesinfo.facebook_vip_likeclone_sv1.min;
				max = allservicesinfo.facebook_vip_likeclone_sv1.max;
				thoigianxuly = allservicesinfo.facebook_vip_likeclone_sv1.thoigianxuly;
			}
			if (typeof(allservicesinfo.facebook_vip_likeclone_sv2) !== 'undefined' && server === "2") {
				tocdo = allservicesinfo.facebook_vip_likeclone_sv2.tocdo;
				min = allservicesinfo.facebook_vip_likeclone_sv2.min;
				max = allservicesinfo.facebook_vip_likeclone_sv2.max;
				thoigianxuly = allservicesinfo.facebook_vip_likeclone_sv2.thoigianxuly;
			}
			if (typeof(allservicesinfo.facebook_vip_likeclone_sv3) !== 'undefined' && server === "1") {
				tocdo = allservicesinfo.facebook_vip_likeclone_sv3.tocdo;
				min = allservicesinfo.facebook_vip_likeclone_sv3.min;
				max = allservicesinfo.facebook_vip_likeclone_sv3.max;
				thoigianxuly = allservicesinfo.facebook_vip_likeclone_sv3.thoigianxuly;
			}
			if (typeof(allservicesinfo.facebook_vip_likeclone_sv4) !== 'undefined' && server === "0") {
				tocdo = allservicesinfo.facebook_vip_likeclone_sv4.tocdo;
				min = allservicesinfo.facebook_vip_likeclone_sv4.min;
				max = allservicesinfo.facebook_vip_likeclone_sv4.max;
				thoigianxuly = allservicesinfo.facebook_vip_likeclone_sv4.thoigianxuly;
			}
			if (typeof(allservicesinfo.facebook_vip_likeclone_sv5) !== 'undefined' && server === "4") {
				tocdo = allservicesinfo.facebook_vip_likeclone_sv5.tocdo;
				min = allservicesinfo.facebook_vip_likeclone_sv5.min;
				max = allservicesinfo.facebook_vip_likeclone_sv5.max;
				thoigianxuly = allservicesinfo.facebook_vip_likeclone_sv5.thoigianxuly;
			}
		}
		
		if (tab === "facebook_vip_cmtpro") {
			if (typeof(allservicesinfo.facebook_vip_cmtpro) !== 'undefined' && server === "1") {
				tocdo = allservicesinfo.facebook_vip_cmtpro.tocdo;
				min = allservicesinfo.facebook_vip_cmtpro.min;
				max = allservicesinfo.facebook_vip_cmtpro.max;
				thoigianxuly = allservicesinfo.facebook_vip_cmtpro.thoigianxuly;
			}
		}
		
		if (tab === "facebook_vip_likepro") {
			if (typeof(allservicesinfo.facebook_vip_likepro_sv1) !== 'undefined' && server === "viplikethang_speed") {
				tocdo = allservicesinfo.facebook_vip_likepro_sv1.tocdo;
				min = allservicesinfo.facebook_vip_likepro_sv1.min;
				max = allservicesinfo.facebook_vip_likepro_sv1.max;
				thoigianxuly = allservicesinfo.facebook_vip_likepro_sv1.thoigianxuly;
			}
			if (typeof(allservicesinfo.facebook_vip_likepro_sv2) !== 'undefined' && server === "viplikethang") {
				tocdo = allservicesinfo.facebook_vip_likepro_sv2.tocdo;
				min = allservicesinfo.facebook_vip_likepro_sv2.min;
				max = allservicesinfo.facebook_vip_likepro_sv2.max;
				thoigianxuly = allservicesinfo.facebook_vip_likepro_sv2.thoigianxuly;
			}
			if (typeof(allservicesinfo.facebook_vip_likepro_sv3) !== 'undefined' && server === "viplikethang_likevia") {
				tocdo = allservicesinfo.facebook_vip_likepro_sv3.tocdo;
				min = allservicesinfo.facebook_vip_likepro_sv3.min;
				max = allservicesinfo.facebook_vip_likepro_sv3.max;
				thoigianxuly = allservicesinfo.facebook_vip_likepro_sv3.thoigianxuly;
			}
		}
		
		if (tab === "facebook_vip_likegroup") {
			if (typeof(allservicesinfo.facebook_vip_likegroup_sv1) !== 'undefined' && server === "1") {
				tocdo = allservicesinfo.facebook_vip_likegroup_sv1.tocdo;
				min = allservicesinfo.facebook_vip_likegroup_sv1.min;
				max = allservicesinfo.facebook_vip_likegroup_sv1.max;
				thoigianxuly = allservicesinfo.facebook_vip_likegroup_sv1.thoigianxuly;
			}
		}
		
		if (tab === "facebook_vip_commentclone") {
			if (typeof(allservicesinfo.facebook_vip_commentclone_sv1) !== 'undefined' && server === "0") {
				tocdo = allservicesinfo.facebook_vip_commentclone_sv1.tocdo;
				min = allservicesinfo.facebook_vip_commentclone_sv1.min;
				max = allservicesinfo.facebook_vip_commentclone_sv1.max;
				thoigianxuly = allservicesinfo.facebook_vip_commentclone_sv1.thoigianxuly;
			}
			if (typeof(allservicesinfo.facebook_vip_commentclone_sv2) !== 'undefined' && server === "1") {
				tocdo = allservicesinfo.facebook_vip_commentclone_sv2.tocdo;
				min = allservicesinfo.facebook_vip_commentclone_sv2.min;
				max = allservicesinfo.facebook_vip_commentclone_sv2.max;
				thoigianxuly = allservicesinfo.facebook_vip_commentclone_sv2.thoigianxuly;
			}
		}
		
		if (tab === "facebook_vip_view") {
			if (typeof(allservicesinfo.facebook_vip_view) !== 'undefined' && server === "1") {
				tocdo = allservicesinfo.facebook_vip_view.tocdo;
				min = allservicesinfo.facebook_vip_view.min;
				max = allservicesinfo.facebook_vip_view.max;
				thoigianxuly = allservicesinfo.facebook_vip_view.thoigianxuly;
			}
		}
		
		if (tab === "facebook_vip_livestream") {
			if (typeof(allservicesinfo.facebook_vip_livestream_sv1) !== 'undefined' && server === "1") {
				tocdo = allservicesinfo.facebook_vip_livestream_sv1.tocdo;
				min = allservicesinfo.facebook_vip_livestream_sv1.min;
				max = allservicesinfo.facebook_vip_livestream_sv1.max;
				thoigianxuly = allservicesinfo.facebook_vip_livestream_sv1.thoigianxuly;
			}
			if (typeof(allservicesinfo.facebook_vip_livestream_sv2) !== 'undefined' && server === "2") {
				tocdo = allservicesinfo.facebook_vip_livestream_sv2.tocdo;
				min = allservicesinfo.facebook_vip_livestream_sv2.min;
				max = allservicesinfo.facebook_vip_livestream_sv2.max;
				thoigianxuly = allservicesinfo.facebook_vip_livestream_sv2.thoigianxuly;
			}
			if (typeof(allservicesinfo.facebook_vip_livestream_sv3) !== 'undefined' && server === "3") {
				tocdo = allservicesinfo.facebook_vip_livestream_sv3.tocdo;
				min = allservicesinfo.facebook_vip_livestream_sv3.min;
				max = allservicesinfo.facebook_vip_livestream_sv3.max;
				thoigianxuly = allservicesinfo.facebook_vip_livestream_sv3.thoigianxuly;
			}
		}
		
		if (tab === "facebook_vip_livestreamnew") {
			if (typeof(allservicesinfo.facebook_vip_livestreamnew_sv1) !== 'undefined' && server === "1") {
				tocdo = allservicesinfo.facebook_vip_livestreamnew_sv1.tocdo;
				min = allservicesinfo.facebook_vip_livestreamnew_sv1.min;
				max = allservicesinfo.facebook_vip_livestreamnew_sv1.max;
				thoigianxuly = allservicesinfo.facebook_vip_livestreamnew_sv1.thoigianxuly;
			}
		}
		
		if (tab === "facebook_vip_locbanbe") {
			if (typeof(allservicesinfo.facebook_vip_locbanbe) !== 'undefined' && server === "1") {
				tocdo = allservicesinfo.facebook_vip_locbanbe.tocdo;
				min = allservicesinfo.facebook_vip_locbanbe.min;
				max = allservicesinfo.facebook_vip_locbanbe.max;
				thoigianxuly = allservicesinfo.facebook_vip_locbanbe.thoigianxuly;
			}
		}
		
		if (tab === "facebook_vip_chocbanbe") {
			if (typeof(allservicesinfo.facebook_vip_chocbanbe) !== 'undefined' && server === "1") {
				tocdo = allservicesinfo.facebook_vip_chocbanbe.tocdo;
				min = allservicesinfo.facebook_vip_chocbanbe.min;
				max = allservicesinfo.facebook_vip_chocbanbe.max;
				thoigianxuly = allservicesinfo.facebook_vip_chocbanbe.thoigianxuly;
			}
		}
		
		if (tab === "facebook_vip_doitenfanpage") {
			if (typeof(allservicesinfo.facebook_vip_doitenfanpage) !== 'undefined' && server === "1") {
				tocdo = allservicesinfo.facebook_vip_doitenfanpage.tocdo;
				min = allservicesinfo.facebook_vip_doitenfanpage.min;
				max = allservicesinfo.facebook_vip_doitenfanpage.max;
				thoigianxuly = allservicesinfo.facebook_vip_doitenfanpage.thoigianxuly;
			}
		}
		
		if (tab === "facebook_bot_love") {
			if (typeof(allservicesinfo.facebook_bot_love) !== 'undefined' && server === "1") {
				tocdo = allservicesinfo.facebook_bot_love.tocdo;
				min = allservicesinfo.facebook_bot_love.min;
				max = allservicesinfo.facebook_bot_love.max;
				thoigianxuly = allservicesinfo.facebook_bot_love.thoigianxuly;
			}
		}
		
		if (tab === "facebook_bot_lovestory") {
			if (typeof(allservicesinfo.facebook_bot_lovestory) !== 'undefined' && server === "1") {
				tocdo = allservicesinfo.facebook_bot_lovestory.tocdo;
				min = allservicesinfo.facebook_bot_lovestory.min;
				max = allservicesinfo.facebook_bot_lovestory.max;
				thoigianxuly = allservicesinfo.facebook_bot_lovestory.thoigianxuly;
			}
		}
		
		if (tab === "facebook_bot_cmt") {
			if (typeof(allservicesinfo.facebook_bot_cmt) !== 'undefined' && server === "1") {
				tocdo = allservicesinfo.facebook_bot_cmt.tocdo;
				min = allservicesinfo.facebook_bot_cmt.min;
				max = allservicesinfo.facebook_bot_cmt.max;
				thoigianxuly = allservicesinfo.facebook_bot_cmt.thoigianxuly;
			}
		}
		
		if (tab === "facebook_bot_replyinboxcho") {
			if (typeof(allservicesinfo.facebook_bot_replyinboxcho) !== 'undefined' && server === "1") {
				tocdo = allservicesinfo.facebook_bot_replyinboxcho.tocdo;
				min = allservicesinfo.facebook_bot_replyinboxcho.min;
				max = allservicesinfo.facebook_bot_replyinboxcho.max;
				thoigianxuly = allservicesinfo.facebook_bot_replyinboxcho.thoigianxuly;
			}
		}
		
		if (tab === "facebook_bot_invitelivestream") {
			if (typeof(allservicesinfo.facebook_bot_invitelivestream) !== 'undefined' && server === "1") {
				tocdo = allservicesinfo.facebook_bot_invitelivestream.tocdo;
				min = allservicesinfo.facebook_bot_invitelivestream.min;
				max = allservicesinfo.facebook_bot_invitelivestream.max;
				thoigianxuly = allservicesinfo.facebook_bot_invitelivestream.thoigianxuly;
			}
		}
		
		if (tab === "muaproxy") {
			if (typeof(allservicesinfo.muaproxy_globalipv6) !== 'undefined' && server === "global") {
				tocdo = allservicesinfo.muaproxy_globalipv6.tocdo;
				min = allservicesinfo.muaproxy_globalipv6.min;
				max = allservicesinfo.muaproxy_globalipv6.max;
				thoigianxuly = allservicesinfo.muaproxy_globalipv6.thoigianxuly;
			}
			if (typeof(allservicesinfo.muaproxy_globalipv4) !== 'undefined' && server === "global_ipv4") {
				tocdo = allservicesinfo.muaproxy_globalipv4.tocdo;
				min = allservicesinfo.muaproxy_globalipv4.min;
				max = allservicesinfo.muaproxy_globalipv4.max;
				thoigianxuly = allservicesinfo.muaproxy_globalipv4.thoigianxuly;
			}
			if (typeof(allservicesinfo.muaproxy_vnipv6) !== 'undefined' && server === "vn") {
				tocdo = allservicesinfo.muaproxy_vnipv6.tocdo;
				min = allservicesinfo.muaproxy_vnipv6.min;
				max = allservicesinfo.muaproxy_vnipv6.max;
				thoigianxuly = allservicesinfo.muaproxy_vnipv6.thoigianxuly;
			}
		}
		
		if (tab === "facebook_buff_adbreaks_600kphutxem") {
			if (typeof(allservicesinfo.facebook_buff_adbreaks_600kphutxem_sv1) !== 'undefined' && server === "gioxem") {
				tocdo = allservicesinfo.facebook_buff_adbreaks_600kphutxem_sv1.tocdo;
				min = allservicesinfo.facebook_buff_adbreaks_600kphutxem_sv1.min;
				max = allservicesinfo.facebook_buff_adbreaks_600kphutxem_sv1.max;
				thoigianxuly = allservicesinfo.facebook_buff_adbreaks_600kphutxem_sv1.thoigianxuly;
			}
		}
		
		if (tab === "facebook_buff_adbreaks_15ktuongtac") {
			if (typeof(allservicesinfo.facebook_buff_adbreaks_15ktuongtac_sv1) !== 'undefined' && server === "tuongtac") {
				tocdo = allservicesinfo.facebook_buff_adbreaks_15ktuongtac_sv1.tocdo;
				min = allservicesinfo.facebook_buff_adbreaks_15ktuongtac_sv1.min;
				max = allservicesinfo.facebook_buff_adbreaks_15ktuongtac_sv1.max;
				thoigianxuly = allservicesinfo.facebook_buff_adbreaks_15ktuongtac_sv1.thoigianxuly;
			}
		}
		
		if (tab === "facebook_buff_adbreaks_viewdexuat") {
			if (typeof(allservicesinfo.facebook_buff_adbreaks_viewdexuat_sv1) !== 'undefined' && server === "viewdexuat") {
				tocdo = allservicesinfo.facebook_buff_adbreaks_viewdexuat_sv1.tocdo;
				min = allservicesinfo.facebook_buff_adbreaks_viewdexuat_sv1.min;
				max = allservicesinfo.facebook_buff_adbreaks_viewdexuat_sv1.max;
				thoigianxuly = allservicesinfo.facebook_buff_adbreaks_viewdexuat_sv1.thoigianxuly;
			}
		}
		
		if (tab === "instagram_buff_like") {
			if (typeof(allservicesinfo.instagram_buff_like_sv1) !== 'undefined' && server === "1") {
				tocdo = allservicesinfo.instagram_buff_like_sv1.tocdo;
				min = allservicesinfo.instagram_buff_like_sv1.min;
				max = allservicesinfo.instagram_buff_like_sv1.max;
				thoigianxuly = allservicesinfo.instagram_buff_like_sv1.thoigianxuly;
			}
			if (typeof(allservicesinfo.instagram_buff_like_sv2) !== 'undefined' && server === "2") {
				tocdo = allservicesinfo.instagram_buff_like_sv2.tocdo;
				min = allservicesinfo.instagram_buff_like_sv2.min;
				max = allservicesinfo.instagram_buff_like_sv2.max;
				thoigianxuly = allservicesinfo.instagram_buff_like_sv2.thoigianxuly;
			}
			if (typeof(allservicesinfo.instagram_buff_like_sv3) !== 'undefined' && server === "3") {
				tocdo = allservicesinfo.instagram_buff_like_sv3.tocdo;
				min = allservicesinfo.instagram_buff_like_sv3.min;
				max = allservicesinfo.instagram_buff_like_sv3.max;
				thoigianxuly = allservicesinfo.instagram_buff_like_sv3.thoigianxuly;
			}
			if (typeof(allservicesinfo.instagram_buff_like_sv4) !== 'undefined' && server === "4") {
				tocdo = allservicesinfo.instagram_buff_like_sv4.tocdo;
				min = allservicesinfo.instagram_buff_like_sv4.min;
				max = allservicesinfo.instagram_buff_like_sv4.max;
				thoigianxuly = allservicesinfo.instagram_buff_like_sv4.thoigianxuly;
			}
			if (typeof(allservicesinfo.instagram_buff_like_sv5) !== 'undefined' && server === "5") {
				tocdo = allservicesinfo.instagram_buff_like_sv5.tocdo;
				min = allservicesinfo.instagram_buff_like_sv5.min;
				max = allservicesinfo.instagram_buff_like_sv5.max;
				thoigianxuly = allservicesinfo.instagram_buff_like_sv5.thoigianxuly;
			}
			if (typeof(allservicesinfo.instagram_buff_like_sv6) !== 'undefined' && server === "6") {
				tocdo = allservicesinfo.instagram_buff_like_sv6.tocdo;
				min = allservicesinfo.instagram_buff_like_sv6.min;
				max = allservicesinfo.instagram_buff_like_sv6.max;
				thoigianxuly = allservicesinfo.instagram_buff_like_sv6.thoigianxuly;
			}
		}
		
		if (tab === "instagram_buff_sub") {
			if (typeof(allservicesinfo.instagram_buff_sub_sv1) !== 'undefined' && server === "1") {
				tocdo = allservicesinfo.instagram_buff_sub_sv1.tocdo;
				min = allservicesinfo.instagram_buff_sub_sv1.min;
				max = allservicesinfo.instagram_buff_sub_sv1.max;
				thoigianxuly = allservicesinfo.instagram_buff_sub_sv1.thoigianxuly;
			}
			if (typeof(allservicesinfo.instagram_buff_sub_sv2) !== 'undefined' && server === "2") {
				tocdo = allservicesinfo.instagram_buff_sub_sv2.tocdo;
				min = allservicesinfo.instagram_buff_sub_sv2.min;
				max = allservicesinfo.instagram_buff_sub_sv2.max;
				thoigianxuly = allservicesinfo.instagram_buff_sub_sv2.thoigianxuly;
			}
			if (typeof(allservicesinfo.instagram_buff_sub_sv3) !== 'undefined' && server === "3") {
				tocdo = allservicesinfo.instagram_buff_sub_sv3.tocdo;
				min = allservicesinfo.instagram_buff_sub_sv3.min;
				max = allservicesinfo.instagram_buff_sub_sv3.max;
				thoigianxuly = allservicesinfo.instagram_buff_sub_sv3.thoigianxuly;
			}
			if (typeof(allservicesinfo.instagram_buff_sub_sv4) !== 'undefined' && server === "4") {
				tocdo = allservicesinfo.instagram_buff_sub_sv4.tocdo;
				min = allservicesinfo.instagram_buff_sub_sv4.min;
				max = allservicesinfo.instagram_buff_sub_sv4.max;
				thoigianxuly = allservicesinfo.instagram_buff_sub_sv4.thoigianxuly;
			}
			if (typeof(allservicesinfo.instagram_buff_sub_sv5) !== 'undefined' && server === "5") {
				tocdo = allservicesinfo.instagram_buff_sub_sv5.tocdo;
				min = allservicesinfo.instagram_buff_sub_sv5.min;
				max = allservicesinfo.instagram_buff_sub_sv5.max;
				thoigianxuly = allservicesinfo.instagram_buff_sub_sv5.thoigianxuly;
			}
			if (typeof(allservicesinfo.instagram_buff_sub_sv6) !== 'undefined' && server === "6") {
				tocdo = allservicesinfo.instagram_buff_sub_sv6.tocdo;
				min = allservicesinfo.instagram_buff_sub_sv6.min;
				max = allservicesinfo.instagram_buff_sub_sv6.max;
				thoigianxuly = allservicesinfo.instagram_buff_sub_sv6.thoigianxuly;
			}
			if (typeof(allservicesinfo.instagram_buff_sub_sv7) !== 'undefined' && server === "7") {
				tocdo = allservicesinfo.instagram_buff_sub_sv7.tocdo;
				min = allservicesinfo.instagram_buff_sub_sv7.min;
				max = allservicesinfo.instagram_buff_sub_sv7.max;
				thoigianxuly = allservicesinfo.instagram_buff_sub_sv7.thoigianxuly;
			}
			if (typeof(allservicesinfo.instagram_buff_sub_sv8) !== 'undefined' && server === "8") {
				tocdo = allservicesinfo.instagram_buff_sub_sv8.tocdo;
				min = allservicesinfo.instagram_buff_sub_sv8.min;
				max = allservicesinfo.instagram_buff_sub_sv8.max;
				thoigianxuly = allservicesinfo.instagram_buff_sub_sv8.thoigianxuly;
			}
		}
		
		if (tab === "instagram_buff_cmt") {
			if (typeof(allservicesinfo.instagram_buff_cmt_sv1) !== 'undefined' && server === "1") {
				tocdo = allservicesinfo.instagram_buff_cmt_sv1.tocdo;
				min = allservicesinfo.instagram_buff_cmt_sv1.min;
				max = allservicesinfo.instagram_buff_cmt_sv1.max;
				thoigianxuly = allservicesinfo.instagram_buff_cmt_sv1.thoigianxuly;
			}
			if (typeof(allservicesinfo.instagram_buff_cmt_sv2) !== 'undefined' && server === "2") {
				tocdo = allservicesinfo.instagram_buff_cmt_sv2.tocdo;
				min = allservicesinfo.instagram_buff_cmt_sv2.min;
				max = allservicesinfo.instagram_buff_cmt_sv2.max;
				thoigianxuly = allservicesinfo.instagram_buff_cmt_sv2.thoigianxuly;
			}
		}
		
		if (tab === "instagram_buff_view") {
			if (typeof(allservicesinfo.instagram_buff_view_sv1) !== 'undefined' && server === "1") {
				tocdo = allservicesinfo.instagram_buff_view_sv1.tocdo;
				min = allservicesinfo.instagram_buff_view_sv1.min;
				max = allservicesinfo.instagram_buff_view_sv1.max;
				thoigianxuly = allservicesinfo.instagram_buff_view_sv1.thoigianxuly;
			}
			if (typeof(allservicesinfo.instagram_buff_view_sv2) !== 'undefined' && server === "2") {
				tocdo = allservicesinfo.instagram_buff_view_sv2.tocdo;
				min = allservicesinfo.instagram_buff_view_sv2.min;
				max = allservicesinfo.instagram_buff_view_sv2.max;
				thoigianxuly = allservicesinfo.instagram_buff_view_sv2.thoigianxuly;
			}
			if (typeof(allservicesinfo.instagram_buff_view_sv3) !== 'undefined' && server === "3") {
				tocdo = allservicesinfo.instagram_buff_view_sv3.tocdo;
				min = allservicesinfo.instagram_buff_view_sv3.min;
				max = allservicesinfo.instagram_buff_view_sv3.max;
				thoigianxuly = allservicesinfo.instagram_buff_view_sv3.thoigianxuly;
			}
			if (typeof(allservicesinfo.instagram_buff_view_sv4) !== 'undefined' && server === "4") {
				tocdo = allservicesinfo.instagram_buff_view_sv4.tocdo;
				min = allservicesinfo.instagram_buff_view_sv4.min;
				max = allservicesinfo.instagram_buff_view_sv4.max;
				thoigianxuly = allservicesinfo.instagram_buff_view_sv4.thoigianxuly;
			}
		}
		
		if (tab === "instagram_vip_like") {
			if (typeof(allservicesinfo.instagram_vip_like_sv1) !== 'undefined' && server === "1") {
				tocdo = allservicesinfo.instagram_vip_like_sv1.tocdo;
				min = allservicesinfo.instagram_vip_like_sv1.min;
				max = allservicesinfo.instagram_vip_like_sv1.max;
				thoigianxuly = allservicesinfo.instagram_vip_like_sv1.thoigianxuly;
			}
			if (typeof(allservicesinfo.instagram_vip_like_sv2) !== 'undefined' && server === "2") {
				tocdo = allservicesinfo.instagram_vip_like_sv2.tocdo;
				min = allservicesinfo.instagram_vip_like_sv2.min;
				max = allservicesinfo.instagram_vip_like_sv2.max;
				thoigianxuly = allservicesinfo.instagram_vip_like_sv2.thoigianxuly;
			}
		}
		
		if (tab === "youtube_buff_view") {
			if (typeof(allservicesinfo.youtube_buff_view_sv1) !== 'undefined' && server === "1") {
				tocdo = allservicesinfo.youtube_buff_view_sv1.tocdo;
				min = allservicesinfo.youtube_buff_view_sv1.min;
				max = allservicesinfo.youtube_buff_view_sv1.max;
				thoigianxuly = allservicesinfo.youtube_buff_view_sv1.thoigianxuly;
			}
			if (typeof(allservicesinfo.youtube_buff_view_sv2) !== 'undefined' && server === "2") {
				tocdo = allservicesinfo.youtube_buff_view_sv2.tocdo;
				min = allservicesinfo.youtube_buff_view_sv2.min;
				max = allservicesinfo.youtube_buff_view_sv2.max;
				thoigianxuly = allservicesinfo.youtube_buff_view_sv2.thoigianxuly;
			}
			if (typeof(allservicesinfo.youtube_buff_view_sv3) !== 'undefined' && server === "3") {
				tocdo = allservicesinfo.youtube_buff_view_sv3.tocdo;
				min = allservicesinfo.youtube_buff_view_sv3.min;
				max = allservicesinfo.youtube_buff_view_sv3.max;
				thoigianxuly = allservicesinfo.youtube_buff_view_sv3.thoigianxuly;
			}
			if (typeof(allservicesinfo.youtube_buff_view_sv4) !== 'undefined' && server === "4") {
				tocdo = allservicesinfo.youtube_buff_view_sv4.tocdo;
				min = allservicesinfo.youtube_buff_view_sv4.min;
				max = allservicesinfo.youtube_buff_view_sv4.max;
				thoigianxuly = allservicesinfo.youtube_buff_view_sv4.thoigianxuly;
			}
			if (typeof(allservicesinfo.youtube_buff_view_sv5) !== 'undefined' && server === "5") {
				tocdo = allservicesinfo.youtube_buff_view_sv5.tocdo;
				min = allservicesinfo.youtube_buff_view_sv5.min;
				max = allservicesinfo.youtube_buff_view_sv5.max;
				thoigianxuly = allservicesinfo.youtube_buff_view_sv5.thoigianxuly;
			}
			if (typeof(allservicesinfo.youtube_buff_view_sv6) !== 'undefined' && server === "6") {
				tocdo = allservicesinfo.youtube_buff_view_sv6.tocdo;
				min = allservicesinfo.youtube_buff_view_sv6.min;
				max = allservicesinfo.youtube_buff_view_sv6.max;
				thoigianxuly = allservicesinfo.youtube_buff_view_sv6.thoigianxuly;
			}
			if (typeof(allservicesinfo.youtube_buff_view_sv7) !== 'undefined' && server === "7") {
				tocdo = allservicesinfo.youtube_buff_view_sv7.tocdo;
				min = allservicesinfo.youtube_buff_view_sv7.min;
				max = allservicesinfo.youtube_buff_view_sv7.max;
				thoigianxuly = allservicesinfo.youtube_buff_view_sv7.thoigianxuly;
			}
			if (typeof(allservicesinfo.youtube_buff_view_sv8) !== 'undefined' && server === "8") {
				tocdo = allservicesinfo.youtube_buff_view_sv8.tocdo;
				min = allservicesinfo.youtube_buff_view_sv8.min;
				max = allservicesinfo.youtube_buff_view_sv8.max;
				thoigianxuly = allservicesinfo.youtube_buff_view_sv8.thoigianxuly;
			}
			if (typeof(allservicesinfo.youtube_buff_view_sv9) !== 'undefined' && server === "9") {
				tocdo = allservicesinfo.youtube_buff_view_sv9.tocdo;
				min = allservicesinfo.youtube_buff_view_sv9.min;
				max = allservicesinfo.youtube_buff_view_sv9.max;
				thoigianxuly = allservicesinfo.youtube_buff_view_sv9.thoigianxuly;
			}
		}
		
		if (tab === "youtube_buff_like") {
			if (typeof(allservicesinfo.youtube_buff_like_sv1) !== 'undefined' && server === "1") {
				tocdo = allservicesinfo.youtube_buff_like_sv1.tocdo;
				min = allservicesinfo.youtube_buff_like_sv1.min;
				max = allservicesinfo.youtube_buff_like_sv1.max;
				thoigianxuly = allservicesinfo.youtube_buff_like_sv1.thoigianxuly;
			}
			if (typeof(allservicesinfo.youtube_buff_like_sv2) !== 'undefined' && server === "3") {
				tocdo = allservicesinfo.youtube_buff_like_sv2.tocdo;
				min = allservicesinfo.youtube_buff_like_sv2.min;
				max = allservicesinfo.youtube_buff_like_sv2.max;
				thoigianxuly = allservicesinfo.youtube_buff_like_sv2.thoigianxuly;
			}
		}
		
		if (tab === "youtube_buff_cmt") {
			if (typeof(allservicesinfo.youtube_buff_cmt_sv1) !== 'undefined' && server === "1") {
				tocdo = allservicesinfo.youtube_buff_cmt_sv1.tocdo;
				min = allservicesinfo.youtube_buff_cmt_sv1.min;
				max = allservicesinfo.youtube_buff_cmt_sv1.max;
				thoigianxuly = allservicesinfo.youtube_buff_cmt_sv1.thoigianxuly;
			}
			if (typeof(allservicesinfo.youtube_buff_cmt_sv2) !== 'undefined' && server === "2") {
				tocdo = allservicesinfo.youtube_buff_cmt_sv2.tocdo;
				min = allservicesinfo.youtube_buff_cmt_sv2.min;
				max = allservicesinfo.youtube_buff_cmt_sv2.max;
				thoigianxuly = allservicesinfo.youtube_buff_cmt_sv2.thoigianxuly;
			}
			if (typeof(allservicesinfo.youtube_buff_cmt_sv3) !== 'undefined' && server === "3") {
				tocdo = allservicesinfo.youtube_buff_cmt_sv3.tocdo;
				min = allservicesinfo.youtube_buff_cmt_sv3.min;
				max = allservicesinfo.youtube_buff_cmt_sv3.max;
				thoigianxuly = allservicesinfo.youtube_buff_cmt_sv3.thoigianxuly;
			}
		}
		
		if (tab === "youtube_buff_sub") {
			if (typeof(allservicesinfo.youtube_buff_sub_sv1) !== 'undefined' && server === "1") {
				tocdo = allservicesinfo.youtube_buff_sub_sv1.tocdo;
				min = allservicesinfo.youtube_buff_sub_sv1.min;
				max = allservicesinfo.youtube_buff_sub_sv1.max;
				thoigianxuly = allservicesinfo.youtube_buff_sub_sv1.thoigianxuly;
			}
			if (typeof(allservicesinfo.youtube_buff_sub_sv2) !== 'undefined' && server === "2") {
				tocdo = allservicesinfo.youtube_buff_sub_sv2.tocdo;
				min = allservicesinfo.youtube_buff_sub_sv2.min;
				max = allservicesinfo.youtube_buff_sub_sv2.max;
				thoigianxuly = allservicesinfo.youtube_buff_sub_sv2.thoigianxuly;
			}
			if (typeof(allservicesinfo.youtube_buff_sub_sv3) !== 'undefined' && server === "3") {
				tocdo = allservicesinfo.youtube_buff_sub_sv3.tocdo;
				min = allservicesinfo.youtube_buff_sub_sv3.min;
				max = allservicesinfo.youtube_buff_sub_sv3.max;
				thoigianxuly = allservicesinfo.youtube_buff_sub_sv3.thoigianxuly;
			}
		}
		
		if (tab === "youtube_buff_4kgioxem") {
			if (typeof(allservicesinfo.youtube_buff_4kgioxem_sv1) !== 'undefined' && server === "1") {
				tocdo = allservicesinfo.youtube_buff_4kgioxem_sv1.tocdo;
				min = allservicesinfo.youtube_buff_4kgioxem_sv1.min;
				max = allservicesinfo.youtube_buff_4kgioxem_sv1.max;
				thoigianxuly = allservicesinfo.youtube_buff_4kgioxem_sv1.thoigianxuly;
			}
			if (typeof(allservicesinfo.youtube_buff_4kgioxem_sv2) !== 'undefined' && server === "2") {
				tocdo = allservicesinfo.youtube_buff_4kgioxem_sv2.tocdo;
				min = allservicesinfo.youtube_buff_4kgioxem_sv2.min;
				max = allservicesinfo.youtube_buff_4kgioxem_sv2.max;
				thoigianxuly = allservicesinfo.youtube_buff_4kgioxem_sv2.thoigianxuly;
			}
			if (typeof(allservicesinfo.youtube_buff_4kgioxem_sv3) !== 'undefined' && server === "3") {
				tocdo = allservicesinfo.youtube_buff_4kgioxem_sv3.tocdo;
				min = allservicesinfo.youtube_buff_4kgioxem_sv3.min;
				max = allservicesinfo.youtube_buff_4kgioxem_sv3.max;
				thoigianxuly = allservicesinfo.youtube_buff_4kgioxem_sv3.thoigianxuly;
			}
			if (typeof(allservicesinfo.youtube_buff_4kgioxem_sv4) !== 'undefined' && server === "4") {
				tocdo = allservicesinfo.youtube_buff_4kgioxem_sv4.tocdo;
				min = allservicesinfo.youtube_buff_4kgioxem_sv4.min;
				max = allservicesinfo.youtube_buff_4kgioxem_sv4.max;
				thoigianxuly = allservicesinfo.youtube_buff_4kgioxem_sv4.thoigianxuly;
			}
			if (typeof(allservicesinfo.youtube_buff_4kgioxem_sv5) !== 'undefined' && server === "5") {
				tocdo = allservicesinfo.youtube_buff_4kgioxem_sv5.tocdo;
				min = allservicesinfo.youtube_buff_4kgioxem_sv5.min;
				max = allservicesinfo.youtube_buff_4kgioxem_sv5.max;
				thoigianxuly = allservicesinfo.youtube_buff_4kgioxem_sv5.thoigianxuly;
			}
		}
		
		if (tab === "youtube_danhgay") {
			if (typeof(allservicesinfo.youtube_danhgay_video) !== 'undefined' && server === "0") {
				tocdo = allservicesinfo.youtube_danhgay_video.tocdo;
				min = allservicesinfo.youtube_danhgay_video.min;
				max = allservicesinfo.youtube_danhgay_video.max;
				thoigianxuly = allservicesinfo.youtube_danhgay_video.thoigianxuly;
			}
		}
		
		if (tab === "youtube_buff_dislike") {
			if (typeof(allservicesinfo.youtube_buff_dislike_sv1) !== 'undefined' && server === "1") {
				tocdo = allservicesinfo.youtube_buff_dislike_sv1.tocdo;
				min = allservicesinfo.youtube_buff_dislike_sv1.min;
				max = allservicesinfo.youtube_buff_dislike_sv1.max;
				thoigianxuly = allservicesinfo.youtube_buff_dislike_sv1.thoigianxuly;
			}
			if (typeof(allservicesinfo.youtube_buff_dislike_sv2) !== 'undefined' && server === "2") {
				tocdo = allservicesinfo.youtube_buff_dislike_sv2.tocdo;
				min = allservicesinfo.youtube_buff_dislike_sv2.min;
				max = allservicesinfo.youtube_buff_dislike_sv2.max;
				thoigianxuly = allservicesinfo.youtube_buff_dislike_sv2.thoigianxuly;
			}
		}
		
		if (tab === "youtube_buff_likecomment") {
			if (typeof(allservicesinfo.youtube_buff_likecomment_sv1) !== 'undefined' && server === "1") {
				tocdo = allservicesinfo.youtube_buff_likecomment_sv1.tocdo;
				min = allservicesinfo.youtube_buff_likecomment_sv1.min;
				max = allservicesinfo.youtube_buff_likecomment_sv1.max;
				thoigianxuly = allservicesinfo.youtube_buff_likecomment_sv1.thoigianxuly;
			}
			if (typeof(allservicesinfo.youtube_buff_likecomment_sv2) !== 'undefined' && server === "2") {
				tocdo = allservicesinfo.youtube_buff_likecomment_sv2.tocdo;
				min = allservicesinfo.youtube_buff_likecomment_sv2.min;
				max = allservicesinfo.youtube_buff_likecomment_sv2.max;
				thoigianxuly = allservicesinfo.youtube_buff_likecomment_sv2.thoigianxuly;
			}
		}
		
		if (tab === "tiktok_buff_like") {
			if (typeof(allservicesinfo.tiktok_buff_like_sv1) !== 'undefined' && server === "like") {
				tocdo = allservicesinfo.tiktok_buff_like_sv1.tocdo;
				min = allservicesinfo.tiktok_buff_like_sv1.min;
				max = allservicesinfo.tiktok_buff_like_sv1.max;
				thoigianxuly = allservicesinfo.tiktok_buff_like_sv1.thoigianxuly;
			}
			if (typeof(allservicesinfo.tiktok_buff_like_sv2) !== 'undefined' && server === "like_sv2") {
				tocdo = allservicesinfo.tiktok_buff_like_sv2.tocdo;
				min = allservicesinfo.tiktok_buff_like_sv2.min;
				max = allservicesinfo.tiktok_buff_like_sv2.max;
				thoigianxuly = allservicesinfo.tiktok_buff_like_sv2.thoigianxuly;
			}
			if (typeof(allservicesinfo.tiktok_buff_like_sv3) !== 'undefined' && server === "like_sv3") {
				tocdo = allservicesinfo.tiktok_buff_like_sv3.tocdo;
				min = allservicesinfo.tiktok_buff_like_sv3.min;
				max = allservicesinfo.tiktok_buff_like_sv3.max;
				thoigianxuly = allservicesinfo.tiktok_buff_like_sv3.thoigianxuly;
			}
			if (typeof(allservicesinfo.tiktok_buff_like_sv4) !== 'undefined' && server === "like_sv4") {
				tocdo = allservicesinfo.tiktok_buff_like_sv4.tocdo;
				min = allservicesinfo.tiktok_buff_like_sv4.min;
				max = allservicesinfo.tiktok_buff_like_sv4.max;
				thoigianxuly = allservicesinfo.tiktok_buff_like_sv4.thoigianxuly;
			}
			if (typeof(allservicesinfo.tiktok_buff_like_sv5) !== 'undefined' && server === "like_sv5") {
				tocdo = allservicesinfo.tiktok_buff_like_sv5.tocdo;
				min = allservicesinfo.tiktok_buff_like_sv5.min;
				max = allservicesinfo.tiktok_buff_like_sv5.max;
				thoigianxuly = allservicesinfo.tiktok_buff_like_sv5.thoigianxuly;
			}
		}
		
		if (tab === "tiktok_buff_cmt") {
			if (typeof(allservicesinfo.tiktok_buff_cmt_sv1) !== 'undefined' && server === "comment") {
				tocdo = allservicesinfo.tiktok_buff_cmt_sv1.tocdo;
				min = allservicesinfo.tiktok_buff_cmt_sv1.min;
				max = allservicesinfo.tiktok_buff_cmt_sv1.max;
				thoigianxuly = allservicesinfo.tiktok_buff_cmt_sv1.thoigianxuly;
			}
			if (typeof(allservicesinfo.tiktok_buff_cmt_sv2) !== 'undefined' && server === "comment_sv2") {
				tocdo = allservicesinfo.tiktok_buff_cmt_sv2.tocdo;
				min = allservicesinfo.tiktok_buff_cmt_sv2.min;
				max = allservicesinfo.tiktok_buff_cmt_sv2.max;
				thoigianxuly = allservicesinfo.tiktok_buff_cmt_sv2.thoigianxuly;
			}
		}
		
		if (tab === "tiktok_buff_follow") {
			if (typeof(allservicesinfo.tiktok_buff_follow_sv1) !== 'undefined' && server === "follow") {
				tocdo = allservicesinfo.tiktok_buff_follow_sv1.tocdo;
				min = allservicesinfo.tiktok_buff_follow_sv1.min;
				max = allservicesinfo.tiktok_buff_follow_sv1.max;
				thoigianxuly = allservicesinfo.tiktok_buff_follow_sv1.thoigianxuly;
			}
			if (typeof(allservicesinfo.tiktok_buff_follow_sv2) !== 'undefined' && server === "follow_sv2") {
				tocdo = allservicesinfo.tiktok_buff_follow_sv2.tocdo;
				min = allservicesinfo.tiktok_buff_follow_sv2.min;
				max = allservicesinfo.tiktok_buff_follow_sv2.max;
				thoigianxuly = allservicesinfo.tiktok_buff_follow_sv2.thoigianxuly;
			}
			if (typeof(allservicesinfo.tiktok_buff_follow_sv3) !== 'undefined' && server === "follow_sv3") {
				tocdo = allservicesinfo.tiktok_buff_follow_sv3.tocdo;
				min = allservicesinfo.tiktok_buff_follow_sv3.min;
				max = allservicesinfo.tiktok_buff_follow_sv3.max;
				thoigianxuly = allservicesinfo.tiktok_buff_follow_sv3.thoigianxuly;
			}
			if (typeof(allservicesinfo.tiktok_buff_follow_sv4) !== 'undefined' && server === "follow_sv4") {
				tocdo = allservicesinfo.tiktok_buff_follow_sv4.tocdo;
				min = allservicesinfo.tiktok_buff_follow_sv4.min;
				max = allservicesinfo.tiktok_buff_follow_sv4.max;
				thoigianxuly = allservicesinfo.tiktok_buff_follow_sv4.thoigianxuly;
			}
			if (typeof(allservicesinfo.tiktok_buff_follow_sv5) !== 'undefined' && server === "follow_sv5") {
				tocdo = allservicesinfo.tiktok_buff_follow_sv5.tocdo;
				min = allservicesinfo.tiktok_buff_follow_sv5.min;
				max = allservicesinfo.tiktok_buff_follow_sv5.max;
				thoigianxuly = allservicesinfo.tiktok_buff_follow_sv5.thoigianxuly;
			}
		}
		
		if (tab === "tiktok_buff_view") {
			if (typeof(allservicesinfo.tiktok_buff_view_sv1) !== 'undefined' && server === "view") {
				tocdo = allservicesinfo.tiktok_buff_view_sv1.tocdo;
				min = allservicesinfo.tiktok_buff_view_sv1.min;
				max = allservicesinfo.tiktok_buff_view_sv1.max;
				thoigianxuly = allservicesinfo.tiktok_buff_view_sv1.thoigianxuly;
			}
			if (typeof(allservicesinfo.tiktok_buff_view_sv2) !== 'undefined' && server === "view_sv2") {
				tocdo = allservicesinfo.tiktok_buff_view_sv2.tocdo;
				min = allservicesinfo.tiktok_buff_view_sv2.min;
				max = allservicesinfo.tiktok_buff_view_sv2.max;
				thoigianxuly = allservicesinfo.tiktok_buff_view_sv2.thoigianxuly;
			}
			if (typeof(allservicesinfo.tiktok_buff_view_sv3) !== 'undefined' && server === "view_sv3") {
				tocdo = allservicesinfo.tiktok_buff_view_sv3.tocdo;
				min = allservicesinfo.tiktok_buff_view_sv3.min;
				max = allservicesinfo.tiktok_buff_view_sv3.max;
				thoigianxuly = allservicesinfo.tiktok_buff_view_sv3.thoigianxuly;
			}
		}
		
		if (tab === "tiktok_buff_share") {
			if (typeof(allservicesinfo.tiktok_buff_share_sv1) !== 'undefined' && server === "share") {
				tocdo = allservicesinfo.tiktok_buff_share_sv1.tocdo;
				min = allservicesinfo.tiktok_buff_share_sv1.min;
				max = allservicesinfo.tiktok_buff_share_sv1.max;
				thoigianxuly = allservicesinfo.tiktok_buff_share_sv1.thoigianxuly;
			}
			if (typeof(allservicesinfo.tiktok_buff_share_sv2) !== 'undefined' && server === "share_sv2") {
				tocdo = allservicesinfo.tiktok_buff_share_sv2.tocdo;
				min = allservicesinfo.tiktok_buff_share_sv2.min;
				max = allservicesinfo.tiktok_buff_share_sv2.max;
				thoigianxuly = allservicesinfo.tiktok_buff_share_sv2.thoigianxuly;
			}
			if (typeof(allservicesinfo.tiktok_buff_share_sv3) !== 'undefined' && server === "share_sv3") {
				tocdo = allservicesinfo.tiktok_buff_share_sv3.tocdo;
				min = allservicesinfo.tiktok_buff_share_sv3.min;
				max = allservicesinfo.tiktok_buff_share_sv3.max;
				thoigianxuly = allservicesinfo.tiktok_buff_share_sv3.thoigianxuly;
			}
		}
		
		if (tab === "tiktok_buff_mat") {
			if (typeof(allservicesinfo.tiktok_buff_mat_sv1) !== 'undefined' && server === "tiktok_buffmat") {
				tocdo = allservicesinfo.tiktok_buff_mat_sv1.tocdo;
				min = allservicesinfo.tiktok_buff_mat_sv1.min;
				max = allservicesinfo.tiktok_buff_mat_sv1.max;
				thoigianxuly = allservicesinfo.tiktok_buff_mat_sv1.thoigianxuly;
			}
			if (typeof(allservicesinfo.tiktok_buff_mat_sv2) !== 'undefined' && server === "tiktok_buffmat_sv2") {
				tocdo = allservicesinfo.tiktok_buff_mat_sv2.tocdo;
				min = allservicesinfo.tiktok_buff_mat_sv2.min;
				max = allservicesinfo.tiktok_buff_mat_sv2.max;
				thoigianxuly = allservicesinfo.tiktok_buff_mat_sv2.thoigianxuly;
			}
		}
		
		if (tab === "tiktok_vip_like") {
			if (typeof(allservicesinfo.tiktok_vip_like_sv1) !== 'undefined' && server === "tiktokviplike_sv1") {
				tocdo = allservicesinfo.tiktok_vip_like_sv1.tocdo;
				min = allservicesinfo.tiktok_vip_like_sv1.min;
				max = allservicesinfo.tiktok_vip_like_sv1.max;
				thoigianxuly = allservicesinfo.tiktok_vip_like_sv1.thoigianxuly;
			}
		}
		
		if (tab === "tiktok_vip_view") {
			if (typeof(allservicesinfo.tiktok_vip_view_sv1) !== 'undefined' && server === "tiktokvipview_sv1") {
				tocdo = allservicesinfo.tiktok_vip_view_sv1.tocdo;
				min = allservicesinfo.tiktok_vip_view_sv1.min;
				max = allservicesinfo.tiktok_vip_view_sv1.max;
				thoigianxuly = allservicesinfo.tiktok_vip_view_sv1.thoigianxuly;
			}
		}
		
		if (tab === "shopee_buff_sub") {
			if (typeof(allservicesinfo.shopee_buff_sub) !== 'undefined' && server === "sub") {
				tocdo = allservicesinfo.shopee_buff_sub.tocdo;
				min = allservicesinfo.shopee_buff_sub.min;
				max = allservicesinfo.shopee_buff_sub.max;
				thoigianxuly = allservicesinfo.shopee_buff_sub.thoigianxuly;
			}
		}
		
		if (tab === "shopee_seeding_livestream") {
			if (typeof(allservicesinfo.shopee_seeding_livestream) !== 'undefined' && server === "1") {
				tocdo = allservicesinfo.shopee_seeding_livestream.tocdo;
				min = allservicesinfo.shopee_seeding_livestream.min;
				max = allservicesinfo.shopee_seeding_livestream.max;
				thoigianxuly = allservicesinfo.shopee_seeding_livestream.thoigianxuly;
			}
		}
		
		if (tab === "shopee_vip_livestream") {
			if (typeof(allservicesinfo.shopee_vip_livestream) !== 'undefined' && server === "1") {
				tocdo = allservicesinfo.shopee_vip_livestream.tocdo;
				min = allservicesinfo.shopee_vip_livestream.min;
				max = allservicesinfo.shopee_vip_livestream.max;
				thoigianxuly = allservicesinfo.shopee_vip_livestream.thoigianxuly;
			}
		}
		
		if (tab === "shopee_buff_tim") {
			if (typeof(allservicesinfo.shopee_buff_tim) !== 'undefined' && server === "1") {
				tocdo = allservicesinfo.shopee_buff_tim.tocdo;
				min = allservicesinfo.shopee_buff_tim.min;
				max = allservicesinfo.shopee_buff_tim.max;
				thoigianxuly = allservicesinfo.shopee_buff_tim.thoigianxuly;
			}
		}
		
		if (tab === "google_map_review") {
			if (typeof(allservicesinfo.google_map_review_5sao) !== 'undefined' && server === "1") {
				tocdo = allservicesinfo.google_map_review_5sao.tocdo;
				min = allservicesinfo.google_map_review_5sao.min;
				max = allservicesinfo.google_map_review_5sao.max;
				thoigianxuly = allservicesinfo.google_map_review_5sao.thoigianxuly;
			}
			if (typeof(allservicesinfo.google_map_review_1sao) !== 'undefined' && server === "2") {
				tocdo = allservicesinfo.google_map_review_1sao.tocdo;
				min = allservicesinfo.google_map_review_1sao.min;
				max = allservicesinfo.google_map_review_1sao.max;
				thoigianxuly = allservicesinfo.google_map_review_1sao.thoigianxuly;
			}
		}
		
		if (tab === "telegram_buff_membergroup") {
			if (typeof(allservicesinfo.telegram_buff_membergroup_sv1) !== 'undefined' && server === "0") {
				tocdo = allservicesinfo.telegram_buff_membergroup_sv1.tocdo;
				min = allservicesinfo.telegram_buff_membergroup_sv1.min;
				max = allservicesinfo.telegram_buff_membergroup_sv1.max;
				thoigianxuly = allservicesinfo.telegram_buff_membergroup_sv1.thoigianxuly;
			}
			if (typeof(allservicesinfo.telegram_buff_membergroup_sv2) !== 'undefined' && server === "2") {
				tocdo = allservicesinfo.telegram_buff_membergroup_sv2.tocdo;
				min = allservicesinfo.telegram_buff_membergroup_sv2.min;
				max = allservicesinfo.telegram_buff_membergroup_sv2.max;
				thoigianxuly = allservicesinfo.telegram_buff_membergroup_sv2.thoigianxuly;
			}
			if (typeof(allservicesinfo.telegram_buff_membergroup_theoyeucau) !== 'undefined' && server === "1") {
				tocdo = allservicesinfo.telegram_buff_membergroup_theoyeucau.tocdo;
				min = allservicesinfo.telegram_buff_membergroup_theoyeucau.min;
				max = allservicesinfo.telegram_buff_membergroup_theoyeucau.max;
				thoigianxuly = allservicesinfo.telegram_buff_membergroup_theoyeucau.thoigianxuly;
			}
		}
		
		if (tab === "twitter_buff_like") {
			if (typeof(allservicesinfo.twitter_buff_like_sv1) !== 'undefined' && server === "sv1") {
				tocdo = allservicesinfo.twitter_buff_like_sv1.tocdo;
				min = allservicesinfo.twitter_buff_like_sv1.min;
				max = allservicesinfo.twitter_buff_like_sv1.max;
				thoigianxuly = allservicesinfo.twitter_buff_like_sv1.thoigianxuly;
			}
			if (typeof(allservicesinfo.twitter_buff_like_sv2) !== 'undefined' && server === "sv2") {
				tocdo = allservicesinfo.twitter_buff_like_sv2.tocdo;
				min = allservicesinfo.twitter_buff_like_sv2.min;
				max = allservicesinfo.twitter_buff_like_sv2.max;
				thoigianxuly = allservicesinfo.twitter_buff_like_sv2.thoigianxuly;
			}
		}
		
		if (tab === "twitter_buff_follow") {
			if (typeof(allservicesinfo.twitter_buff_follow_sv1) !== 'undefined' && server === "sv1") {
				tocdo = allservicesinfo.twitter_buff_follow_sv1.tocdo;
				min = allservicesinfo.twitter_buff_follow_sv1.min;
				max = allservicesinfo.twitter_buff_follow_sv1.max;
				thoigianxuly = allservicesinfo.twitter_buff_follow_sv1.thoigianxuly;
			}
			if (typeof(allservicesinfo.twitter_buff_follow_sv2) !== 'undefined' && server === "sv2") {
				tocdo = allservicesinfo.twitter_buff_follow_sv2.tocdo;
				min = allservicesinfo.twitter_buff_follow_sv2.min;
				max = allservicesinfo.twitter_buff_follow_sv2.max;
				thoigianxuly = allservicesinfo.twitter_buff_follow_sv2.thoigianxuly;
			}
		}
		
		if (tab === "hotronhantin") {
			if (typeof(allservicesinfo.hotronhantin) !== 'undefined' && server === "1") {
				tocdo = allservicesinfo.hotronhantin.tocdo;
				min = allservicesinfo.hotronhantin.min;
				max = allservicesinfo.hotronhantin.max;
				thoigianxuly = allservicesinfo.hotronhantin.thoigianxuly;
			}
		}
		
		if (tab === "hotrocuocgoi") {
			if (typeof(allservicesinfo.hotrocuocgoi) !== 'undefined' && server === "1") {
				tocdo = allservicesinfo.hotrocuocgoi.tocdo;
				min = allservicesinfo.hotrocuocgoi.min;
				max = allservicesinfo.hotrocuocgoi.max;
				thoigianxuly = allservicesinfo.hotrocuocgoi.thoigianxuly;
			}
		}
		
		return (
			<Fragment>
				<div className="row mt-2" style={{"display": showDescription? "flex" : "none"}}>
					<div className="col-md-4" style={{"margin": "20px 0px"}}>
						<div className="card card-select-cus">
							<div className="card-body card-body-select-cus">
								<p className="mb-0 mt-3 text-wrap">
									- Đơn giá: <span style={{"fontWeight": "bolder"}}>{giatien} Coin</span>
								</p>
								<span className="badge badge-cus position-absolute-cus badge-top-left-cus badge-primary-cus badge-pill-cus"> Đơn giá </span>
							</div>
						</div>
					</div>
					<div className="col-md-4" style={{"margin": "20px 0px"}}>
						<div className="card card-select-cus">
							<div className="card-body card-body-select-cus">
								<p className="mb-0 mt-3 text-wrap">
									- Bảo hành: <span style={{"fontWeight": "bolder"}}>{ baohanh === 1 ? "Có bảo hành" : "Không bảo hành" }</span>
								</p>
								<span className="badge badge-cus position-absolute-cus badge-top-left-cus badge-primary-cus badge-pill-cus"> Bảo hành </span>
							</div>
						</div>
					</div>
					<div className="col-md-4" style={{"margin": "20px 0px"}}>
						<div className="card card-select-cus">
							<div className="card-body card-body-select-cus">
								<p className="mb-0 mt-3 text-wrap">
									- Tốc độ: <span style={{"fontWeight": "bolder"}}>{tocdo}</span>
								</p>
								<span className="badge badge-cus position-absolute-cus badge-top-left-cus badge-primary-cus badge-pill-cus"> Tốc độ </span>
							</div>
						</div>
					</div>
					<div className="col-md-4" style={{"margin": "20px 0px"}}>
						<div className="card card-select-cus">
							<div className="card-body card-body-select-cus">
								<p className="mb-0 mt-3 text-wrap">
									- Mua tối thiểu: <span style={{"fontWeight": "bolder"}}>{min}</span>
								</p>
								<span className="badge badge-cus position-absolute-cus badge-top-left-cus badge-primary-cus badge-pill-cus"> Mua tối thiểu </span>
							</div>
						</div>
					</div>
					<div className="col-md-4" style={{"margin": "20px 0px"}}>
						<div className="card card-select-cus">
							<div className="card-body card-body-select-cus">
								<p className="mb-0 mt-3 text-wrap">
									- Mua tối đa: <span style={{"fontWeight": "bolder"}}>{max}</span>
								</p>
								<span className="badge badge-cus position-absolute-cus badge-top-left-cus badge-primary-cus badge-pill-cus"> Mua tối đa </span>
							</div>
						</div>
					</div>
					<div className="col-md-4" style={{"margin": "20px 0px"}}>
						<div className="card card-select-cus">
							<div className="card-body card-body-select-cus">
								<p className="mb-0 mt-3 text-wrap">
									- Đơn hàng được thực hiện trong khoảng <span className="font-bold">{thoigianxuly}</span> tính từ lúc tạo đơn
								</p>
								<span className="badge badge-cus position-absolute-cus badge-top-left-cus badge-primary-cus badge-pill-cus"> Thời gian xủ lý </span>
							</div>
						</div>
					</div>
				</div>
			</Fragment>
		);
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
});
const mapDispatchToProps = { };

export default connect(mapStateToProps, mapDispatchToProps)(ServicesDescription);
