import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import store from '../../../store';
import NumberFormat from 'react-number-format';

import { getOverviewUsers, saveConfigColorNav } from '../../../actions/adminPageAction';

class tag_overviewuser extends Component {
    state = {
        bg_navbar: this.props.auth.config_website.bg_navbar
    }

    componentDidMount(){
        this.props.getOverviewUsers();
    }

    onchangeData = (e) => {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value.replace("#", ""),
        });
    }

    render() {

        const {overviewUser} = this.props.adminpage;

        return (
            <Fragment>
                <div className="row">
                    <div className="col-md-6 col-xl-4 js-appear-enabled animated fadeIn" data-toggle="appear">
                        <a className="block block-link-pop bt-edit-block" href="/#">
                            <div className="block-content block-content-full d-flex align-items-center justify-content-between">
                                <div>
                                    <i style={{fontSize: '28px'}} className="fa fa-user"></i>
                                </div>
                                <div className="ml-3 text-right">
                                    <p className="text-muted mb-0">
                                        Số tài khoản
                                    </p>
                                    <p className="font-size-h3 mb-0">
                                        {overviewUser.total.count}
                                    </p>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div className="col-md-6 col-xl-4 js-appear-enabled animated fadeIn" data-toggle="appear">
                        <a className="block block-link-pop bt-edit-block" href="/#">
                            <div className="block-content block-content-full d-flex align-items-center justify-content-between">
                                <div>
                                    <i style={{fontSize: '28px'}} className="fa fa-cubes"></i>
                                </div>
                                <div className="ml-3 text-right">
                                    <p className="text-muted mb-0">
                                        Tài khoản thẻ nạp
                                    </p>
                                    <p className="font-size-h3 mb-0">
                                        <NumberFormat value={ overviewUser.total.moneycard } displayType={'text'} thousandSeparator={true} suffix={' vnđ'} />
                                    </p>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div className="col-md-6 col-xl-4 js-appear-enabled animated fadeIn" data-toggle="appear">
                        <a className="block block-link-pop bt-edit-block" href="/#">
                            <div className="block-content block-content-full d-flex align-items-center justify-content-between">
                                <div>
                                    <i style={{fontSize: '28px'}} className="fa fa-university"></i>
                                </div>
                                <div className="ml-3 text-right">
                                    <p className="text-muted mb-0">
                                        Tổng tiền hệ thống
                                    </p>
                                    <p className="font-size-h3 mb-0">
                                        <NumberFormat value={ overviewUser.total.moneyall } displayType={'text'} thousandSeparator={true} suffix={' vnđ'} />
                                    </p>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
                {/* <div className="form-group">
                    <label>Màu sắc menu navbar</label>
                    <input name="bg_navbar" onChange={(e) => {this.onchangeData(e)}} value={'#'+this.state.bg_navbar} className="form-control" type="color" />
                    <button type="button" onClick={(e) => {this.props.saveConfigColorNav(this.state.bg_navbar)}} className="btn btn-success mt-4">Lưu</button>
				</div> */}
            </Fragment>
        );
    }

}

const mapStateToProps = state => ({
    auth: state.auth,
    adminpage: state.adminpage
});

export default connect(mapStateToProps, { getOverviewUsers,saveConfigColorNav }) (tag_overviewuser);
