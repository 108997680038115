import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Swal from 'sweetalert2';
import copy from 'copy-text-to-clipboard';

class Anhhtml extends Component {
	state = {
		loaibotrunglap: true,
		linkwebsitelayanh: "https://i.imgur.com/",
		noidung: "<img src='http://i.imgur.com/l35eOVBb.jpg'/>\n<img src='/KBWh5jOb.jpg'/>",
		text_placeholder: "<img src='http://i.imgur.com/l35eOVBb.jpg'/>\n<img src='/KBWh5jOb.jpg'/>",
		ketqua: "",
	}
	onChangeTrungLap = (e) =>{
		this.setState({
			[e.target.name]: !this.state.loaibotrunglap
		})
	}
	onClick = (e) =>{
		this.setState({
			[e.target.name]: e.target.value
		})
	}
	onChange = (e) =>{
		this.setState({
			[e.target.name]: e.target.value
		})
	}
	onTrigger = (e) => {
		var { loaibotrunglap, noidung, linkwebsitelayanh } = this.state;
		var final_noidung = [];
		var matches_img = noidung.match(/\<img.+src\=(?:\"|\')(.+?)(?:\"|\')(?:.+?)\>/g);
		if (matches_img !== null) {
			if (matches_img.length > 0) {
				matches_img = matches_img.join("\n");
				var matches_src = matches_img.match(/src\=(?:\"|\')(.+?)(?:\"|\')/g);
				if (matches_src !== null) {
					if (matches_src.length > 0) {
						matches_src.forEach(function(v, i) {
							v = v.replace(/\"|\'|src=/g, "");
							if (v.indexOf("//") > -1 && v.indexOf("http") === -1) {
								final_noidung.push("https:"+v);
							} else if (v.indexOf("//") === -1 && v.indexOf("http") === -1) {
								final_noidung.push(linkwebsitelayanh+v);
							} else {
								final_noidung.push(v);
							}
						});
					}
				}
			}
		}
		
		if (loaibotrunglap) {
			final_noidung = final_noidung
				.filter((item, i, allItems) => {
					return i === allItems.indexOf(item);
				});
		}
		
		noidung = final_noidung.join("\n");
		
		this.setState({
			ketqua: noidung
		});
	}
	
	render() {
		var { ketqua } = this.state;
		if (ketqua == "") {
			ketqua = [];
		} else {
			ketqua = ketqua.split("\n");
		}
		var html_all_anh = (
  			<Fragment>
  				{
  					ketqua.map(function(value,index){
						return (
							<img src={value} />
						);
  					})
  				}
  			</Fragment>
  		);
		return (
			<Fragment>
				<div className="form-group row">
					<label className="col-sm-2 col-form-label edittext-label-right" htmlFor="">Loại bỏ trùng lặp?</label>
					<div className="col-sm-10" style={{"display": "flex", "alignItems": "center"}}>
						<div className="custom-control custom-switch mb-1">
							<input onChange={(e) => {this.onChangeTrungLap(e)}} type="checkbox" className="custom-control-input" id="loaibotrunglap" name="loaibotrunglap" checked={this.state.loaibotrunglap} />
							<label className="custom-control-label edittext-custom-control-label" htmlFor="loaibotrunglap" ></label>
						</div>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-2 col-form-label edittext-label-right" htmlFor="">Link website lấy ảnh</label>
					<div className="col-sm-10">
						<input type="text" className="form-control" name="linkwebsitelayanh" value={this.state.linkwebsitelayanh} onChange={this.onChange} />
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-2 col-form-label edittext-label-right" htmlFor="">Nội dung</label>
					<div className="col-sm-10">
						<textarea rows="5" placeholder={this.state.text_placeholder} className="form-control input-gray edittext-textarea" name="noidung" onChange={this.onChange} value={this.state.noidung} ></textarea>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-2 col-form-label edittext-label-right" htmlFor=""></label>
					<div className="col-sm-10">
						<button type="button" className="btn btn-rounded btn-hero-primary edittext-btn" onClick={this.onTrigger} >Trigger</button>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-2 col-form-label edittext-label-right" htmlFor=""></label>
					<div className="col-sm-10">
						<textarea rows="5" placeholder="" className="form-control input-gray edittext-textarea" name="ketqua" defaultValue={this.state.ketqua} ></textarea>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-2 col-form-label edittext-label-right" htmlFor=""></label>
					<div className="col-sm-10">
						<div className="col-sm-12" style={{"height": "calc(100vh - 660px)", "overflow": "auto", "border": (ketqua.length > 0 != "") ? "1px solid #d8dfed" : ""}}>
							{html_all_anh}
						</div>
					</div>
				</div>
			</Fragment>
		);
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
});
const mapDispatchToProps = {  };

export default connect(mapStateToProps, mapDispatchToProps)(Anhhtml);
