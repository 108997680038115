import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Swal from 'sweetalert2';
import copy from 'copy-text-to-clipboard';

class Ghepdong extends Component {
	state = {
		loaibotrunglap: true,
		mode: 1,
		noidung: "a\nb",
		text_placeholder: "a\nb",
		ngancachboikytu: "|",
		ketqua: "",
	}
	onChangeTrungLap = (e) =>{
		this.setState({
			[e.target.name]: !this.state.loaibotrunglap
		})
	}
	onClick = (e) =>{
		this.setState({
			[e.target.name]: e.target.value
		})
	}
	onChange = (e) =>{
		this.setState({
			[e.target.name]: e.target.value
		})
	}
	onTrigger = (e) => {
		var { loaibotrunglap, noidung, mode, ngancachboikytu } = this.state;
		noidung = noidung.trim();
		noidung = noidung
			.split("\n")
			.filter(item => item)
			.join("\n");
		if (loaibotrunglap) {
			noidung = noidung
				.split("\n")
				.filter((item, i, allItems) => {
					return i === allItems.indexOf(item);
				})
				.join("\n");
		}
		if (mode == 1) {
			var final_noidung = noidung.split("\n");
			noidung = final_noidung.join(ngancachboikytu);
		}
		if (mode == 2) {
			var final_noidung_tmp = noidung.split("\n");
			var final_noidung = [];
			final_noidung_tmp.forEach(function(v, i) {
				var v_tmp = [];
				if (i%2 === 0) {
					v_tmp.push(final_noidung_tmp[i]);
					v_tmp.push(final_noidung_tmp[i+1]);
					final_noidung.push(v_tmp.join(ngancachboikytu));
				}
			});
			noidung = final_noidung.join("\n");
		}
		this.setState({
			ketqua: noidung
		});
	}
	
	render() {
		return (
			<Fragment>
				<div className="form-group row">
					<label className="col-sm-2 col-form-label edittext-label-right" htmlFor="">Loại bỏ trùng lặp?</label>
					<div className="col-sm-10" style={{"display": "flex", "alignItems": "center"}}>
						<div className="custom-control custom-switch mb-1">
							<input onChange={(e) => {this.onChangeTrungLap(e)}} type="checkbox" className="custom-control-input" id="loaibotrunglap" name="loaibotrunglap" checked={this.state.loaibotrunglap} />
							<label className="custom-control-label edittext-custom-control-label" htmlFor="loaibotrunglap" ></label>
						</div>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-2 col-form-label edittext-label-right" htmlFor="">Mode</label>
					<div className="col-sm-10">
						<select className="form-control" name="mode" onChange={this.onChange} value={this.state.mode} >
							<option value="1">Tất các dòng</option>
							<option value="2">Liên tiếp</option>
						</select>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-2 col-form-label edittext-label-right" htmlFor="">Nội dung</label>
					<div className="col-sm-10">
						<textarea rows="5" placeholder={this.state.text_placeholder} className="form-control input-gray edittext-textarea" name="noidung" onChange={this.onChange} value={this.state.noidung} ></textarea>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-2 col-form-label edittext-label-right" htmlFor="">Ngăn cách bởi ký tự</label>
					<div className="col-sm-10">
						<input type="text" className="form-control" name="ngancachboikytu" value={this.state.ngancachboikytu} onChange={this.onChange} />
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-2 col-form-label edittext-label-right" htmlFor=""></label>
					<div className="col-sm-10">
						<button type="button" className="btn btn-rounded btn-hero-primary edittext-btn" onClick={this.onTrigger} >Trigger</button>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-2 col-form-label edittext-label-right" htmlFor=""></label>
					<div className="col-sm-10">
						<textarea rows="5" placeholder="" className="form-control input-gray edittext-textarea" name="ketqua" defaultValue={this.state.ketqua} ></textarea>
					</div>
				</div>
			</Fragment>
		);
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
});
const mapDispatchToProps = {  };

export default connect(mapStateToProps, mapDispatchToProps)(Ghepdong);
