import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { dangnhapGCF, loadProxyGCF } from '../../../actions/GetcookiefbActions';
import Swal from 'sweetalert2';
import BootstrapTable from 'react-bootstrap-table-next';
import SelectSearch from 'react-select-search';

class getcookie extends Component {
	static propTypes =  {
		dangnhapGCF: PropTypes.func.isRequired,
	};
	
	state = {
		"dntkf": "",
		"host": "",
		"port": "",
		"username": "",
		"password": "",
		"ip": "",
		"copySuccess": false,
	}
	
	handleChange = selectedOption => {
		var arr_selectedOption = selectedOption.split('z!@#z');
		// this.setState({
			// "host": arr_selectedOption[0],
			// "port": arr_selectedOption[1],
			// "username": arr_selectedOption[2],
			// "password": arr_selectedOption[3],
			// "ip": arr_selectedOption[4],
		// }, () => console.log(this.state));
		this.setState({
			"host": arr_selectedOption[0],
			"port": arr_selectedOption[1],
			"username": arr_selectedOption[2],
			"password": arr_selectedOption[3],
			"ip": arr_selectedOption[4],
		});
	};
	
	componentDidMount() {
		this.props.loadProxyGCF();
	};
	
	formatCheckTime(date_end) {
		var result = new Date(date_end* 1000);
		var result_now = new Date();
		
		var difference = result - result_now;
		if (difference >= 0) {
			var daysDifference = Math.floor(difference/1000/60/60/24);
			return 'Còn ' + daysDifference + ' ngày';
		} else {
			return 'Hết hạn';
		}
	}
	
	option_proxy2 = () => {
		const { listproxy } = this.props.Getcookiefb;
		let ar_option_proxy = [];

		for (let i = 0; i < listproxy.length; i++) {
			ar_option_proxy.push(
				<option value={listproxy[i].host} key={i} data-port={listproxy[i].port} data-username={listproxy[i].user} data-password={listproxy[i].pass} data-ip={listproxy[i].ip}>
					{listproxy[i].note} ({listproxy[i].host}) ({this.formatCheckTime(listproxy[i].date_end)})
				</option>
			)
		}
		return ar_option_proxy;
	}
	
	option_proxy = () => {
		const { listproxy } = this.props.Getcookiefb;
		let ar_option_proxy = [];

		for (let i = 0; i < listproxy.length; i++) {
			ar_option_proxy.push(
				{
					"name": listproxy[i].note+' ('+listproxy[i].host+') ('+this.formatCheckTime(listproxy[i].date_end)+')',
					"value": listproxy[i].host+'z!@#z'+listproxy[i].port+'z!@#z'+listproxy[i].user+'z!@#z'+listproxy[i].pass+'z!@#z'+listproxy[i].ip,
				}
			)
		}
		return ar_option_proxy;
	}
	
	onChangeDangNhap = (e) => {
		if (this.state.dntkf === "") {
			Swal.fire({
				icon: 'error',
				title: 'Lỗi',
				text: 'Đăng nhập tài khoản facebook không được để trống!',
			});
			return false;
		}
		var dangnhap = {
			"dntkf": this.state.dntkf,
			"host": this.state.host,
			"port": this.state.port,
			"username": this.state.username,
			"password": this.state.password,
			"ip": this.state.ip,
		};
		this.props.dangnhapGCF(dangnhap);
	}
	
	onChangedntkfcc = (e) => {
		this.setState({
			"dntkf": e.target.value
		});
	}
	onChange = (e) => {
		var index = e.target.selectedIndex;
		var optionElement = e.target.childNodes[index]
		var port =  optionElement.getAttribute('data-port');
		var username =  optionElement.getAttribute('data-username');
		var password =  optionElement.getAttribute('data-password');
		var ip =  optionElement.getAttribute('data-ip');
		this.setState({
			"host": e.target.value,
			"port": port,
			"username": username,
			"password": password,
			"ip": ip,
		});
	}
	copyToClipboard = (final_data) => {
		var dummy = document.createElement("textarea");
		document.body.appendChild(dummy);
		dummy.value = final_data;
		dummy.select();
		document.execCommand("copy");
		document.body.removeChild(dummy);
		this.setState({ "copySuccess": true });
	};
	
	render() {
		var { selectedOption } = this.state;
		var { cookie, userAgent } = this.props.Getcookiefb;
		var final_data = cookie+'|'+userAgent;
		return (
			<Fragment>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Đăng nhập tài khoản facebook:</label>
					<div className="col-sm-6">
						<input type="text" className="form-control" id="dntkfcc" name="dntkfcc" placeholder="username|password|private_key hoặc mã bảo mật 2 lớp" onChange={this.onChangedntkfcc} />
						<h6 className="mb-0 font-13 text-muted">Nhập tài khoản Facebook theo định dạng <span className="text-danger">username|password|private_key hoặc mã bảo mật 2 lớp</span></h6>
					</div>
					<div className="col-sm-2">
						<button type="button" className="btn btn-block btn-rounded btn-hero-primary" onClick={this.onChangeDangNhap} >Lấy Cookie</button>
					</div>
				</div>
				<div className="form-group row mt-4">
					<label className="col-sm-4 col-form-label" htmlFor="">
						Proxy:
					</label>
					<div className="col-sm-8">
						<div className="card mt-2">
							{/* <select className="form-control custom-select select-light" id="proxy" name="proxy" onChange={this.onChange} >
								<option value="" data-port="" data-username="" data-password="" data-ip="">--</option>
								{this.option_proxy2()}
							</select> */}
							<SelectSearch options={this.option_proxy()} value={selectedOption} search placeholder="Chọn proxy..." id="proxy" name="proxy" onChange={this.handleChange} />
						</div>
					</div>
				</div>
				{
					cookie !== "" ?
						<Fragment>
							<div className="react-bootstrap-table table-responsive">
								<table className="table table-striped table-hover table-bordered table-condensed">
									<thead>
										<tr>
											<th tabindex="0">Copy</th>
											<th tabindex="0">Cookie|User-Agent</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>
												<Fragment>
													{
														this.state.copySuccess === true ?
															<Fragment>
																<button onClick={() => {this.copyToClipboard(final_data)}} className="btn btn-hero-success text-center p-2 mr-1">
																	<i className="fa fa-fw fa-check"></i>
																</button>
															</Fragment>
														:
															<Fragment>
																<button onClick={() => {this.copyToClipboard(final_data)}} className="btn btn-hero-primary text-center p-2 mr-1">
																	<i className="fa fa-fw fa-clipboard"></i>
																</button>
															</Fragment>
													}
												</Fragment>
											</td>
											<td style={{"overflow-wrap": "anywhere"}}>{final_data}</td>
										</tr>
									</tbody>
								</table>
							</div>
						</Fragment>
					:
						''
				}
			</Fragment>
		)
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	Getcookiefb: state.Getcookiefb,
});
const mapDispatchToProps = { dangnhapGCF, loadProxyGCF }

export default connect(mapStateToProps, mapDispatchToProps)(getcookie);